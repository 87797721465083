import React from "react";
import {
  number,
  bool,
  string,
  func,
  arrayOf,
  object,
  objectOf,
} from "prop-types";

import "./ActivitiesDashboard.scss";

import ActivitiesDashboard from "./ActivitiesDashboard";
import LastComments from "./LastComments";
import { capitalize } from "lodash";
import { t } from "i18next";

function WrapperActivitiesDashboard({
  defaultCommentsCount = {},
  entityType = "",
  parentUUID,
  partition,
  tabs,
  showLastComments = true,
  clickDashboardButton,
}) {
  return (
    <>
      <div className="wrapper-title">
        {capitalize(
          t("wms.labels.activities_dashboard", "activities_dashboard")
        )}
      </div>
      {showLastComments && (
        <LastComments
          key={parentUUID}
          partition={partition}
          parentUUID={parentUUID}
          readOnlyComments
          showMoreComments={clickDashboardButton}
        />
      )}
      <ActivitiesDashboard
        defaultCommentsCount={defaultCommentsCount}
        entityType={entityType}
        parentUUID={parentUUID}
        partition={partition}
        tabs={tabs}
        clickDashboardButton={clickDashboardButton}
      />
    </>
  );
}

WrapperActivitiesDashboard.propTypes = {
  defaultCommentsCount: objectOf(number),
  entityType: string,
  parentUUID: string,
  partition: string,
  tabs: arrayOf(object).isRequired,
  showLastComments: bool,
  clickDashboardButton: func,
};

export default WrapperActivitiesDashboard;

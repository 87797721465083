// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2024.01.24 //Format YYYY|MM|DD

import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Button, Popconfirm } from 'antd';
import Icon from '@mdi/react';

const ButtonWithPopConfirmAntDesign54origins = memo(({
  className,
  disabled,
  type,
  danger,
  pathForMdi,
  sizeMdi = 1,
  label,
  onClick,
  iconClassName,
  size,
  popConfirmTitle,
  popConfirmPlacement = 'top',
  popConfirmColor,
}) => (
  <Popconfirm
    placement={popConfirmPlacement}
    title={popConfirmTitle}
    onConfirm={onClick}
    okText="Yes"
    cancelText="No"
    color={popConfirmColor}
  >
    <Button
      type={type}
      danger={danger}
      className={className}
      size={size}
      disabled={disabled}
      icon={pathForMdi && (
        <Icon
          className={iconClassName}
          path={pathForMdi}
          size={sizeMdi}
        />
      )}
    >
      {label}
    </Button>
  </Popconfirm>
));

ButtonWithPopConfirmAntDesign54origins.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
  pathForMdi: PropTypes.string,
  popConfirmColor: PropTypes.string,
  popConfirmPlacement: PropTypes.string,
  popConfirmTitle: PropTypes.string,
  size: PropTypes.number,
  sizeMdi: PropTypes.number,
  type: PropTypes.string,
  danger: PropTypes.bool,
  label: PropTypes.any,
};

export default ButtonWithPopConfirmAntDesign54origins;

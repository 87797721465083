import {EntityConstants} from "../constants/Constants";

const initialState = {
  fetching: false,
  message: '',
  error: ''
};


export default (state=initialState, action) => {
  switch (action.type) {
    case EntityConstants.CREATE_ENTITY_PERM_REQUEST:
      return {
        fetching: true,
        message: state.message
      };
    case EntityConstants.CREATE_ENTITY_PERM_SUCCESS:
      return {
        ...state,
        fetching: false,
        message:  action.payload
      };
    case EntityConstants.CREATE_ENTITY_PERM_FAILURE:
      return {
        ...state,
        fetching: false,
        message: '',
        error:  action.payload
      };

    default:  return state;
  }
};

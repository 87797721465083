import React, { useEffect, useMemo, useState } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next/';
import {
  path,
} from 'ramda';
import moment from 'moment';
import {
  mdiTextBoxOutline,
  mdiCalendarRange,
  mdiAccountCheck,
  mdiFormatIndentDecrease,
  mdiFormatListBulletedType,
} from '@mdi/js';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import BaseSelect from '../../../../components/_ui/BaseSelect/BaseSelect';
import BlockInfoCard from './BlockInfoCard';

import { capitalize } from 'lodash';
import { defaultArrayTypeOfTesting } from '../QaUtils';
import { getUsersByUUID } from '../../../../userFlow/store/action-creators/userActions';

function TestCaseInfoCard({
  data,
  typeOfParent,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [actors, setActors] = useState([]);

  const executedByFunc = useMemo(() => {
    if (typeOfParent !== 'suite') {
      const actor = actors.find((i) => get(i, 'uuid', '') === get(data, 'executed_by'));
      return `${get(actor, 'uinfo.first_name', '')} ${get(actor, 'uinfo.last_name', '')}`;
    }
  }, [JSON.stringify(actors)]);

  const typeOfTesting = Array.isArray(path(['typeOfTesting'], data))
    ? path(['typeOfTesting'], data)
    : [path(['typeOfTesting'], data)];

  const valueTypeOfTesting = useMemo(() => typeOfTesting.map((i) => (
    { label: capitalize(t(`wms.options_drap_down.${i}`)), value: i })), [typeOfTesting.length, i18n?.language]);

  const getActors = async () => {
    if (get(data, 'executed_by') && typeOfParent !== 'suite') {
      const actorsList = await dispatch(getUsersByUUID({
        uuid: [get(data, 'executed_by', '')],
        userType: 'other',
      }));
      setActors(actorsList);
    }
  };

  useEffect(() => {
    getActors();
  }, [get(data, 'executed_by'), typeOfParent]);

  return (
    <Col className="ml-1">
      <BlockInfoCard
        icon={mdiTextBoxOutline}
        label={`${capitalize(t('wms.labels.description', 'description'))}:`}
        description={get(data, 'description', '-')}
      />

      <BlockInfoCard
        icon={mdiFormatIndentDecrease}
        label={`${capitalize(t('wms.labels.preconditions', 'preconditions'))}:`}
        description={get(data, 'comment', '-')}
        classNameParent="mt-3"
      />

      <BlockInfoCard
        icon={mdiFormatListBulletedType}
        label={`${capitalize(t('wms.labels.type_of_testing', 'type of testing'))}:`}
        classNameParent="mt-3"
        classNameDescription="flex items-center w-full pr-2"
        description={(
          <BaseSelect
            optionFilterProp="label"
            // getPopupContainer={() => document.getElementById(id)}
            disabled
            optionLabelProp="label"
            autoClearSearchValue
            allowClear
            hideSelected
            options={defaultArrayTypeOfTesting}
            showArrow
            closeMenuOnSelect={false}
            value={valueTypeOfTesting}
            placeholder={capitalize(t('wms.placeholders.type', 'type'))}
            mode="multiple"
            classNamePrefix="react-select"
            className="react-select w-full"
            pathTranslate="wms.options_drap_down"
          />
        )}
      />

      {typeOfParent !== 'suite'
        && (
          <BlockInfoCard
            icon={mdiAccountCheck}
            label={`${capitalize(t('wms.table.headers.executed_by', 'Executed by'))}:`}
            classNameParent="mt-3"
            description={(
              <div className="flex items-start">
                {executedByFunc}
                <span className="ml-2">
                  {get(data, 'executed_on', '-')}
                </span>
              </div>
            )}
          />
        )}

      <BlockInfoCard
        icon={mdiCalendarRange}
        label={`${capitalize(t('wms.table.headers.created_by', 'Created by'))}:`}
        classNameParent="mt-3 mb-2"
        description={(
          <div className="flex items-start">
            {`${get(data, 'uinfo.first_name')} ${get(data, 'uinfo.last_name')}`}
            <span className="ml-2">
              {moment(get(data, 'created', '')).format('DD-MM-YYYY HH:mm')}
            </span>
          </div>
        )}
      />
    </Col>
  );
}

export default TestCaseInfoCard;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  Card, Col, Row,
} from 'antd';
import BaseButton from '../_ui/BaseButton/BaseButton';
import BaseCard from "../_ui/BaseCard/BaseCard";
import { UI54Button, UI54Card, UI54Colors } from "@agpl/ui54kit";

function InfoTip({ title, text, className}) {
  const { t } = useTranslation();

  const [isActive, setActive] = useState(false);

  const toggleText = () => {
    setActive((prev) => !prev);
  };

  return (
    <Row gutter={[0, 16]} className={className}>
      <Col span={24} className="flex">
        <h4 className="dt-page__title mb-0 inline-flex items-center">
          {title}
        </h4>
        {text && (
          <>
            <UI54Button
              id="toggleHelpTextButton"
              onClick={toggleText}
              className="ml-3"
              variant="help"
              shape="round"
            >
              {t('wms.userflow.dashboard.help.button', 'Help me')}
            </UI54Button>
          </>
        )}
      </Col>
      {isActive && (
        <Col span={24}>
          <UI54Card
            variant="bordered"
            color={UI54Colors.sky[500]}
          >
            {text}
          </UI54Card>
        </Col>
      )}
    </Row>
  );
}

InfoTip.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withTranslation()(InfoTip);

import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  isEmpty, isNil,
} from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { difference, get, isEqual } from 'lodash';
import TestCaseForm from './TestCaseForm';

import { BugTrackerContext, ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import {
  currentSelectedUUID,
  getMe,
  getProjectUUID,
  // getTestCase,
  getTestCycle,
  getTestSuiteParams,
  getTestSuiteUUID,
} from '../../../selectors/selectors';
import { antNotification, formatDateTimeForWms } from '../../../../MainUtils';
import { updateTestCaseFunc, updateTestSuite, updateTestCycle } from '../../../actions/QaActions';
import { sendSystemComment, updateListComment } from '../../../actions/projectFlowActions';
import { makeComment } from '../../../actions/commentsActions';
import { qaNotify } from '../utilsQa';
import moment from 'moment';

function EditTestCase({
  defaultAssignToUsersOption,
  defaultComment,
  defaultDescription,
  defaultStatusPriority,
  defaultTitle,
  defaultTypeOfTesting,
  defaultTags,
  entityUUID,
  parentUUID,
  toggleModal,
  typeOfParent,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const getState = useSelector((state) => state);
  const testSuiteUUID = useSelector(getTestSuiteUUID);
  const testCycle = useSelector(getTestCycle);
  const testSuiteParams = useSelector(getTestSuiteParams);
  const projectUUID = useSelector(getProjectUUID);
  const myUUID = useSelector(getMe);
  // const testCase = useSelector(getTestCase);
  const cycleOrArchiveUUID = useSelector((state) => currentSelectedUUID(state, typeOfParent === 'cycle'));

  const {
    sendAssignedNotifications,
  } = useContext(ProjectMainLayerContext);

  const {
    getListOfTestCasesInSuite,
    getTestCaseFunc,
    getListOfTestCasesInCycle,
    AddCommentsCase,
    partitionType,
    getListOfTestSuites,
    getListOfTestCycles,
  } = useContext(BugTrackerContext);

  const updateTestSuitePromise = ({ uuid, params }) => {
    const data = {
      uuid: uuid || testSuiteUUID,
      params: { ...params },
      partitionType,
    };

    console.log('edit');
    
    const uniqKey = `testSuiteUpd${Date.now()}`;
    qaNotify({
      param: 'info',
      key: uniqKey,
      message: 'Updating test suite...',
    });
    return dispatch(updateTestSuite(data)).then((res) => {
      qaNotify({
        param: 'success',
        key: uniqKey,
        message: 'Test suite updated',
      });
      return res;
    });
  };

  const updateTestCyclePromise = () => {
    const uniqKey = `testCycleUpd${Date.now()}`;

    const data = {
      uuid: parentUUID,
      params: {
        modified_on: formatDateTimeForWms(moment()),
        modified_by: myUUID,
      },
      partition: partitionType
    }

    dispatch(updateTestCycle(data)).then(() => {
      qaNotify({
        param: 'success',
        key: uniqKey,
        message: 'Test cycle updated',
      });
    });
  }

  const updateTestSuiteData = (caseParams) => {
    const listOfCases = getListOfTestCasesInSuite(testSuiteUUID)
    const listOfTestingTypes = new Set()
    
    listOfCases.then(({data}) => {
      try{
        data?.map(item => item?.typeOfTesting?.forEach(listOfTestingTypes.add, listOfTestingTypes))
          caseParams.typeOfTesting.forEach(listOfTestingTypes.add, listOfTestingTypes)
      
        const editSuiteParams = {
          typeOfTesting: Array.from(listOfTestingTypes),
        };

        updateTestSuitePromise({ params: editSuiteParams }).then(() => {        
          if (getListOfTestSuites) {
            getListOfTestSuites();
          } else {
            dispatch(setGlobalUpdateEntity({ entityType: 'testSuite', action: 'update' }));
          }
        });
      }catch(e){}
    })
  };

  const sendEditedTestCase = (params) => {
    const {
      title,
      description,
      comment,
      assignToUsersOption,
      typeOfTesting,
      statusPriority,
      tags,
    } = params;

    // const allParams = new FormData();

    const data = {
      uuid: entityUUID,
      partition: partitionType,
      params: {},
    };

    const checkParam = (param) => !isNil(param) && !isEmpty(param);

    if (checkParam(testSuiteUUID)) {
      data.params.suite = testSuiteUUID;
    }

    if (checkParam(cycleOrArchiveUUID)) {
      data.params.cycle = testCycle;
    }

    if (title !== defaultTitle) {
      data.params.title = title;
    }

    if (description !== defaultDescription) {
      data.params.description = description;
    }

    if (comment !== defaultComment) {
      data.params.comment = comment;
    }

    data.params.typeOfTesting = typeOfTesting;

    if (!isEqual(tags, defaultTags)) {
      data.params.tags = tags;
    }
    data.params.casePriority = statusPriority;
    data.params.assignToUser = assignToUsersOption;

    dispatch(updateTestCaseFunc({
      constants: ['REQUEST', 'SUCCESS', 'FAILURE'],
      ...data,
    })).then((response) => {
      if (!response) {
        antNotification(
          'error',
          t('notifications.text.error.base', 'Error'),
        );
      } else {
        antNotification('success', t('wms.noun.success', 'Success'));
        const differenceAssigned = difference(get(data, 'params.assignToUser'), defaultAssignToUsersOption);

        dispatch(makeComment({
          title: defaultTitle,
          description: defaultDescription,
          comment: defaultComment,
          typeOfTesting: defaultTypeOfTesting,
          casePriority: defaultStatusPriority,
          tags: defaultTags,
        }, {
          title,
          description,
          comment,
          typeOfTesting,
          tags,
          casePriority: statusPriority,
        }, {
          entity: 'testCase',
          entityUUID,
          partitionType,
        }, {
          typeOfTesting: (field) => field.join(', '),
          tags: (field) => field.join(', '),
        }));
        dispatch(updateListComment(entityUUID));

        if (differenceAssigned.length) {
          sendAssignedNotifications({
            entity_type: 'testCase',
            eventName: 'assigned_test_case',
            assignedUsers: differenceAssigned,
            entityUUID: get(response, '[0].uuid'),
            message: get(response, '[0].params.title'),
            entityUUIDsFields: {
              [typeOfParent === 'cycle' ? 'testCycleUUID' : 'testSuiteUUID']: get(response, '[0].parent'),
              testCaseUUID: get(response, '[0].uuid'),
              projectUUID,
            },
          });
        }
      }

      if (typeOfParent === 'suite') {
        getListOfTestCasesInSuite(parentUUID);
        updateTestSuiteData(params);
      } else {
        getListOfTestCasesInCycle(parentUUID);
        updateTestCyclePromise()
        getListOfTestCycles()
      }
      getTestCaseFunc(entityUUID, typeOfParent);

      if (toggleModal) {
        toggleModal();
      }
      if (comment.trim().length !== 0) {
        // AddCommentsCase(comment, entityUUID);
      }
    });
  };

  return (
    <TestCaseForm
      formType='edit'
      defaultAssignToUsersOption={defaultAssignToUsersOption}
      defaultComment={defaultComment}
      defaultDescription={defaultDescription}
      defaultStatusPriority={defaultStatusPriority}
      defaultTitle={defaultTitle}
      defaultTypeOfTesting={defaultTypeOfTesting}
      defaultTags={defaultTags}
      hideElements={['cloneBtn', 'anotherOneBtn', 'grid']}
      submitCallback={sendEditedTestCase}
      partitionType={partitionType}
    />
  );
}

export default EditTestCase;

EditTestCase.propTypes = {
  defaultAssignToUsersOption: PropTypes.array,
  defaultComment: PropTypes.string,
  defaultDescription: PropTypes.string,
  defaultStatusPriority: PropTypes.string,
  defaultTitle: PropTypes.string,
  defaultTypeOfTesting: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  defaultTags: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  entityUUID: PropTypes.string,
  parentUUID: PropTypes.string,
  toggleModal: PropTypes.func,
  typeOfParent: PropTypes.string,
};

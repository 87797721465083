import { UsersPartitionConstants } from '../../constants/Constants';
import {head} from "lodash";

const initialState = {
  favoriteFetching: true,
  userEntityUUID: '',
  privateSettings: {},
  error: false,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case UsersPartitionConstants.GET_PRIVATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userEntityUUID: payload,
      };

    case UsersPartitionConstants.GET_PRIVATE_USER_PROFILE_REQUEST:
    case UsersPartitionConstants.GET_PRIVATE_SETTINGS_REQUEST:
    case UsersPartitionConstants.CREATE_PRIVATE_SETTINGS_REQUEST:
    case UsersPartitionConstants.CREATE_OR_UPDATE_CHILD_IN_PRIVATE_SETTINGS_REQUEST:
    case UsersPartitionConstants.UPDATE_FAVORITE_SETTINGS_REQUEST:
    case UsersPartitionConstants.GET_CHILD_FROM_PRIVATE_SETTINGS_REQUEST:
      return {
        ...state,
        favoriteFetching: true,
      };

    case UsersPartitionConstants.GET_PRIVATE_USER_PROFILE_FAILURE:
    case UsersPartitionConstants.GET_PRIVATE_SETTINGS_FAILURE:
    case UsersPartitionConstants.CREATE_PRIVATE_SETTINGS_FAILURE:
    case UsersPartitionConstants.CREATE_OR_UPDATE_CHILD_IN_PRIVATE_SETTINGS_FAILURE:
    case UsersPartitionConstants.GET_CHILD_FROM_PRIVATE_SETTINGS_FAILURE:
      return {
        ...state,
        error: payload,
        favoriteFetching: false,
      };

    case UsersPartitionConstants.GET_PRIVATE_SETTINGS_SUCCESS:
      return {
        ...state,
        privateSettings: payload,
        error: false,
      };

    case 'UPDATE_PRIVATE_SETTINGS_SUCCESS':
      return {
        ...state,
        privateSettings: head(payload),
        error: false,
      };

    case UsersPartitionConstants.GET_CHILD_FROM_PRIVATE_SETTINGS_SUCCESS:
      return {
        ...state,
        privateSettings: {
          ...state.privateSettings,
          ...payload,
        },
        favoriteFetching: false,
      };

    case UsersPartitionConstants.CREATE_OR_UPDATE_CHILD_IN_PRIVATE_SETTINGS_SUCCESS:
      return {
        ...state,
        privateSettings: {
          ...state.privateSettings,
          ...payload,
        },
        favoriteFetching: false,
      };

    case UsersPartitionConstants.UPDATE_FAVORITE_SETTINGS_SUCCESS:
      const favoriteSettings = {
        ...state.privateSettings.favoriteSettings,
        params: {
          ...state.privateSettings.favoriteSettings.params,
          ...payload,
        },
      };

      return {
        ...state,
        privateSettings: {
          ...state.privateSettings,
          favoriteSettings,
        },
        favoriteFetching: false,
      };

    default:
      return state;
  }
};

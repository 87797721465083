import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  projectsIssuesScoringData: [],
  fetching: false,
  lastComputeTime: '',
};

export const projectIssueScoringSlicer = createSlice({
  name: 'projectIssueScoringSlicer',
  initialState,
  reducers: {
    setFetchingScoringData: (state, action) => {
      state.fetching = action.payload;
    },
    setLastComputeTime: (state, action) => {
      state.lastComputeTime = action.payload;
    },
    setProjectsIssuesScoringData: (state, action) => {
      // console.log('setProjectsIssuesScoringData', action.payload);
      const newComputeTime = moment().format('DD-MM-YYYY HH:mm:ss');
      // console.log('newComputeTime', newComputeTime);
      state.projectsIssuesScoringData = action.payload;
      state.lastComputeTime = newComputeTime;
    },
    setLastComputeTime: (state, action) => {
      state.lastComputeTime = action.payload;
    },
    clearIssueScoringData: (state) => {
      state.projectsIssuesScoringData = [];
      state.fetching = false;
      state.lastComputeTime = '';
    },
  },
});

const storeProjectPath = (state) => state?.projectManagementService?.project;

export const getProjectsIssuesScoringFetching = (state) => storeProjectPath(state)?.allProjectsIssuesScoring?.fetching;
export const getProjectsIssuesScoringData = (state) => storeProjectPath(state)?.allProjectsIssuesScoring?.projectsIssuesScoringData;
export const getProjectsIssuesScoringLastComputeTime = (state) => storeProjectPath(state)?.allProjectsIssuesScoring?.lastComputeTime;

export const {
  setFetchingScoringData, setProjectsIssuesScoringData, setLastComputeTime, clearIssueScoringData,
} = projectIssueScoringSlicer.actions;

export default projectIssueScoringSlicer.reducer;

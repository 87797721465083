import PropTypes, {
  bool, func, object, string,
} from 'prop-types';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Dropdown,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';

import BaseButton from '../BaseButton/BaseButton';

import { capitalize } from 'lodash';

function BaseDropdown({
  defaultCurrentItem = '',
  defaultListOfItems = '',
  dropDownCallback,
  iconPath,
  id = 'antDropDown',
  placement = 'bottomLeft',
  translationPath = '',
  trigger = ['click'],
  children,
  classNameRowDropdown = 'custom-dropdown',
  arrow = false,
  translateItemMenuCallback,
  disabled,
  hideActiveItemInList = true,
  getSpecialViewItem,
  overlayClassName,
  destroyPopupOnHide,
}) {
  const { t } = useTranslation();
  const ref = useRef();

  const [currentItem, changeCurrentItem] = useState(defaultCurrentItem);

  const setNewItem = ({ key }) => {
    changeCurrentItem(key);

    if (dropDownCallback) {
      dropDownCallback(key);
    }
  };

  const getSpecialLabel = (i) => {
    if (i === 'specialLow') {
      return getSpecialViewItem(i);
    }
    if (i?.reactNode === true) return i?.label;

    return translateItemMenuCallback
      ? translateItemMenuCallback(i)
      : capitalize(t(`${translationPath}.${i}`, i));
  };

  const newMenu = useMemo(() => ([
    ...defaultListOfItems.flatMap((item) => {
      if (item?.type === 'group') {
        return ({
          key: item?.label,
          type: item?.type,
          label: item?.label,
          className: 'text-xs p-0 m-0',
          children: item?.children.map((i) => ({
            disabled: i === currentItem,
            hidden: i === currentItem && hideActiveItemInList,
            key: `${item?.label}-${i}`,
            label: getSpecialLabel(i),
            className: i !== currentItem && `ant-${classNameRowDropdown} ${i}`,
          })),
        });
      }

      if (typeof item === 'string') {
        return ({
          disabled: item === currentItem,
          hidden: item === currentItem && hideActiveItemInList,
          key: item,
          label: translateItemMenuCallback
            ? translateItemMenuCallback(item)
            : capitalize(t(`${translationPath}.${item}`, item)),
          className: item !== currentItem && `ant-${classNameRowDropdown} ${item}`,
        });
      }

      return ({
        disabled: item.key === currentItem,
        hidden: item.key === currentItem && hideActiveItemInList,
        key: item.key,
        label: getSpecialLabel(item),
        className: item.key !== currentItem && `ant-${classNameRowDropdown} ${item.key}`,
      });
    }),
  ]), [
    currentItem,
    defaultListOfItems,
    translateItemMenuCallback,
    classNameRowDropdown,
  ]);

  // const menu = (
  //   <Menu>
  //     {defaultListOfItems.flatMap((item) => (item === currentItem ? [] : (
  //       <Menu.Item
  //         onClick={setNewItem}
  //         key={item}
  //         className={`ant-${classNameRowDropdown} ${item}`}
  //       >
  //         {translateItemMenuCallback
  //           ? translateItemMenuCallback(item)
  //           : capitalize(t(`${translationPath}.${item}`, item))}
  //       </Menu.Item>
  //     )))}
  //   </Menu>
  // );

  useEffect(() => {
    changeCurrentItem(defaultCurrentItem);
  }, [defaultCurrentItem]);

  return (
    <div id={id} ref={ref}>
      <Dropdown
        arrow={arrow}
        placement={placement}
        overlayClassName={overlayClassName}
        menu={{
          items: newMenu,
          onClick: setNewItem,
        }}
        // overlay={menu}
        trigger={trigger}
        getPopupContainer={() => ref.current}
        disabled={disabled}
        destroyPopupOnHide={destroyPopupOnHide}
      >
        {children
          || (
            <BaseButton
              onClick={(e) => e.preventDefault()}
            >
              {capitalize(t(`${translationPath}.${currentItem}`, translationPath))}
              {iconPath && <Icon path={iconPath} size={0.9} />}
            </BaseButton>
          )}
      </Dropdown>
    </div>
  );
}

BaseDropdown.propTypes = {
  defaultCurrentItem: PropTypes.string,
  defaultListOfItems: PropTypes.array,
  dropDownCallback: PropTypes.func,
  iconPath: PropTypes.element,
  id: PropTypes.string,
  placement: PropTypes.string,
  translationPath: PropTypes.string,
  trigger: PropTypes.array,
  children: object,
  classNameRowDropdown: string,
  arrow: bool,
  disabled: bool,
  translateItemMenuCallback: func,
  hideActiveItemInList: bool,
  getSpecialViewItem: func,
  overlayClassName: string,
};
export default BaseDropdown;

import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { values } from 'lodash';

const initialState = {
  fetching: false,
  actors: {},
};

const actorsW54Slice = createSlice({
  name: 'actorsW54Slice',
  initialState,
  reducers: {
    GET_ACTORS_W54_REQUEST(state) {
      state.fetching = true;
    },
    GET_ACTORS_W54_SUCCESS(state, action) {
      state.fetching = false;

      const { actors = {} } = state;
      const { payload = {} } = action;

      payload?.data?.forEach?.((actor) => {
        if (actor?.uuid) {
          actors[actor.uuid] = {
            ...actor,
            loading: false,
            time: dayjs(),
          };
        }
      });
      state.actors = actors;
    },
    GET_ACTORS_W54_FAILURE(state) {
      state.fetching = false;
    },
    SET_ACTORS_W54_FETCHING(state, action) {
      const { payload = [] } = action;
      const { actors = {} } = state;

      payload?.forEach?.((uuid) => {
        if (uuid) {
          const actor = actors[uuid] ?? {};
          actors[uuid] = {
            ...actor,
            loading: true,
          };
        }
      });
      state.actors = actors;
    },
  },
});

export const {
  GET_ACTORS_W54_REQUEST,
  GET_ACTORS_W54_SUCCESS,
  GET_ACTORS_W54_FAILURE,
  SET_ACTORS_W54_FETCHING,
} = actorsW54Slice.actions;

export default actorsW54Slice.reducer;

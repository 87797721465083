import React from 'react';
import { mdiKey } from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch } from 'react-redux';

import BaseButton from '../../components/_ui/BaseButton/BaseButton';

import { TOGGLE_MODAL_GET_AUTH_SESSION } from '../slicers/getAuthSessionModalSlicer';
import { getSessionTokenFor } from '../../api/appConfig';

function GetAuthSessionBtn({
  className,
  btnLabel,
}) {
  const authSession = getSessionTokenFor.auth();
  const dispatch = useDispatch();

  const defaultBtnLabel = `${authSession ? 'Re-get' : 'Get'} auth session`;

  const onClick = () => {
    dispatch(TOGGLE_MODAL_GET_AUTH_SESSION());
  };

  return (
    <BaseButton
      onClick={onClick}
      className={className}
      type="primary"
      size="small"
      icon={(
        <Icon
          className="mr-1"
          path={mdiKey}
          size={0.7}
        />
      )}
    >
      {btnLabel || defaultBtnLabel}
    </BaseButton>
  );
}

export default GetAuthSessionBtn;

import { isEmpty, isNil } from 'ramda';
import Cookies from 'universal-cookie';
import { sessionNameFor } from '../../api/appConfig';

export class AuthHelper {
  static setSession = (token) => {
    const cookies = new Cookies();
    const cookieName = 'Entity';
    cookies.set(cookieName, token, { path: '/' });
  };

  static setCookies = (name, value) => {
    const cookies = new Cookies();
    cookies.set(name, value, { path: '/' });
  };

  static isAuthorised = () => {
    const cookies = new Cookies();

    const entityToken = cookies.get(sessionNameFor.entity);

    return !isNil(entityToken) && !isEmpty(entityToken);

    // const entityToken = cookies.get('Entity')
    // if(typeof entityToken !== 'undefined') {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  // AUTH

}

import { ProjectTypeCommonConstants } from '../../constants/Constants';

const initialState = {
  fetching: false,
  fetchingEdit: false,
  fetchingFiles: false,
  data: [],
  error: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ProjectTypeCommonConstants.ISSUE_ENTITY_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchingEdit: false,
      };
    case ProjectTypeCommonConstants.ISSUE_ENTITY_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetchingEdit: false,
        data: payload,
      };
    case ProjectTypeCommonConstants.ISSUE_ENTITY_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchingEdit: false,
        data: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.UPDATE_FILES_FOR_ISSUE_REQUEST:
      return {
        ...state,
        fetchingFiles: true,
      };

    case ProjectTypeCommonConstants.UPDATE_FILES_FOR_ISSUE_SUCCESS:
      return {
        ...state,
        fetchingFiles: false,
      };

    case ProjectTypeCommonConstants.UPDATE_FILES_FOR_ISSUE_FAILURE:
      return {
        ...state,
        fetchingFiles: false,
      };

    case ProjectTypeCommonConstants.EDIT_ISSUE_REQUEST:
      return {
        ...state,
        fetching: false,
        fetchingEdit: true,
      };
    case ProjectTypeCommonConstants.EDIT_ISSUE_SUCCESS:
      return {
        ...state,
        fetchingEdit: false,
        data: payload,
      };
    case ProjectTypeCommonConstants.EDIT_ISSUE_FAILURE:
      return {
        ...state,
        fetchingEdit: false,
        data: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.CLEAR_ISSUE:
      return {
        fetching: false,
        data: [],
        error: '',
      };

    default: return state;
  }
};

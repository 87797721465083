import { mdiPlus, mdiPlusBoxOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { func, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from 'antd';
import { capitalize } from 'lodash';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

export default function AddActivityOrTicketsButton({
  buttonClassName,
  AddNewActivityCallback,
}) {
  const { t } = useTranslation();

  const onClickButton = () => {
    AddNewActivityCallback();
  };

  const onKeyUp = (e) => {
    e.stopPropagation();
    if (e.code === 'Enter' && e.target.id === 'addActivity') {
      AddNewActivityCallback();
    }
  };

  return (
    <>
      {/* <div */}
      {/*  className={buttonClassName} */}
      {/*  onClick={onClickButton} */}
      {/*  onKeyUp={onKeyUp} */}
      {/*  role="button" */}
      {/*  id="addActivity" */}
      {/*  tabIndex="0" */}
      {/* > */}
      {/*  <Icon size={0.8} path={mdiPlus} /> */}
      {/*  <span className="mr-1 text-xs"> */}
      {/*  {capitalize(t('wms.verb.add', 'Add'))} */}
      {/* </span> */}
      {/* </div> */}
      <BaseButton id='addPersonalTaskButton'
        type="dashed"
        className={`w-full ${buttonClassName}`}
        onClick={onClickButton}
      >
        <Flex justify="center" align="center" gap={4}>
          <Icon size={0.8} path={mdiPlusBoxOutline} />
          <span>ADD PERSONAL TASK</span>
        </Flex>
      </BaseButton>
    </>
  );
}

AddActivityOrTicketsButton.propTypes = {
  buttonClassName: string,
  AddNewActivityCallback: func,
};

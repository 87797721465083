// THIS IS GLOBAL REACT HOOK FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2024.04.19 || YYYY/MM/DD

import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

const useLocalTranslateForComponent = (dict) => {
  const { i18n } = useTranslation();

  const currentLangIsEn = i18n.language === 'en';

  const checkLangAndGetTranslate = (key, isCapitalize = true) => {
    const translate = dict[key][currentLangIsEn ? 0 : 1];
    return isCapitalize ? capitalize(translate) : translate;
  };

  return {
    checkLangAndGetTranslate,
    currentLang: i18n.language,
    currentLangIsEn,
  };
};

export default useLocalTranslateForComponent;

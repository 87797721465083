import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { mdiMenuDown } from '@mdi/js';
import {
  Col, Dropdown, Menu, Row, Space, Tooltip
} from "antd";
import Icon from '@mdi/react';

import { getLocale } from '../../locale/selectors';

import en from '../../img/en.svg';
import ru from '../../img/ru.svg';
import cn from '../../img/cn.svg';
import { LocaleConstants } from '../../locale/constants/Constants';
import { capitalize } from 'lodash';

function LocaleSwitcher({
  i18n,
  locale,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSwitchLanguage = (lang) => {
    if (lang === locale || lang === 'cn') {
      return;
    }

    localStorage.setItem('locale', lang);
    i18n.changeLanguage(lang);
    dispatch({ type: LocaleConstants.SWITCH_LANGUAGE, payload: lang });
  };

  useEffect(() => {
    const predefinedLanguage = localStorage.getItem('locale');

    if (predefinedLanguage) {
      onSwitchLanguage(predefinedLanguage);
    }
  }, []);

  const newLangMenu = useMemo(() => ([
    {
      key: 'en',
      className: 'header-dropdown-item',
      label: (
        <>
          <img src={en} height={20} width={20} alt="en" />
          <span className="pl-2">
            {capitalize(t('wms.locales.english_menu_item', 'english'))}
          </span>
        </>
      ),
    },
    {
      key: 'ru',
      className: 'header-dropdown-item',
      label: (
        <>
          <img src={ru} height={20} width={20} alt="ru" />
          <span className="pl-2">
            {capitalize(t('wms.locales.russian_menu_item', 'russian'))}
          </span>
        </>
      ),
    },
    {
      key: 'cn',
      className: 'header-dropdown-item',
      disabled: true,
      label: (
        <Tooltip
          title={t(
            'wms.locales.coming_soon',
            'Coming soon... Use English. We are working on translation',
          )}
          placement="left"
        >
          <img src={cn} height={20} width={20} alt="cn" />
          <span className="pl-2">
            {capitalize(t('wms.locales.chinese_menu_item', 'chinese'))}
          </span>
        </Tooltip>
      ),
    },
  ]), []);

  const getLanguageName = () => {
    switch (locale) {
      case 'en':
        return 'english';
      case 'ru':
        return 'russian';
      case 'cn':
        return 'chinese';
      default:
        return 'english';
    }
  };

  const getLanguageIconName = () => {
    switch (locale) {
      case 'en':
        return en;
      case 'ru':
        return ru;
      case 'cn':
        return cn;
      default:
        return en;
    }
  };

  return (
    <div className="header-dropdown-wrapper h-full">
      <Dropdown
        trigger={['click']}
        // overlay={languagesMenu}
        menu={{
          items: newLangMenu,
          onClick: ({ key }) => onSwitchLanguage(key),
        }}
        placement="bottom"
        className="h-full"
      >
        <Space style={{ cursor: 'pointer' }} align="center" size={0}>
          <img
            src={getLanguageIconName()}
            height={20}
            width={20}
            alt={`${locale}`}
          />

          <span className="hidden 2xl:inline m-0 ml-2" style={{ userSelect: 'none' }}>
            {capitalize(
              t(
                `wms.locales.${getLanguageName()}_menu_item`,
                getLanguageName(),
              ),
            )}
          </span>

          <Icon path={mdiMenuDown} size={1} />
        </Space>
      </Dropdown>
    </div>
  );
}

const mapStateToProps = (state) => ({
  locale: getLocale(state),
});

LocaleSwitcher.propTypes = {
  locale: PropTypes.string,
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(withTranslation()(LocaleSwitcher));

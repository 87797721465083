import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiGhostOffOutline } from '@mdi/js';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';

import './MasqueradingTurnOffButton.scss';

function MasqueradingTurnOffButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isMasquerading, changeIsMasquerading] = useState(false);

  const masqueradingOff = () => new Promise((resolve, reject) => {
    try {
      const cookies = new Cookies();
      const primary = cookies.get('EntityPrimary');

      if (primary) {
        cookies.remove('Entity', { path: '/' });
        cookies.remove('EntityPrimary', { path: '/' });

        cookies.set('Entity', primary, { path: '/' });
        resolve();
      } else {
        reject();
      }
    } catch (e) {
      reject();
    }
  });

  const turnOffMasquerading = () => {
    masqueradingOff()
      .then(() => {
        notification.warning(capitalize(t(
          'auth.notifications.masquerading_off',
          'you are out of masquerading mode',
        )));
        navigate('/login');
        navigate(0);
      })
      .catch((e) => {
        notification.error(capitalize(t(
          'auth.notifications.masquerading_off_failed',
          'error exiting masquerading mode',
        )));
      });
  };

  const initFunc = () => {
    const cookies = new Cookies();

    const authPrimary = cookies.get('EntityPrimary');

    changeIsMasquerading(!!authPrimary);
  };

  useEffect(() => {
    initFunc();
  }, []);

  if (!isMasquerading) {
    return null;
  }

  return (
    <div className="header-dropdown-wrapper">
      <Button
        className="button-warning-outlined masquerading-turn-off-button"
        onClick={turnOffMasquerading}
      >
        <Icon path={mdiGhostOffOutline} size={1} className="mr-0 2xl:mr-2" />
        <span className="hidden 2xl:inline">{capitalize(t('auth.buttons.exit_masquerading', 'exit from masquerading'))}</span>
      </Button>
    </div>
  );
}

export default MasqueradingTurnOffButton;

import Icon from '@mdi/react';
import {
  mdiChevronDoubleLeft,
} from '@mdi/js';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { get } from 'lodash';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';

import '../../../projectFlow/components/issuesView/css/filterForIssue.scss';

import PersonalDashboardTagsWithRequests from './PersonalDashboardTagsWithRequests';

import { getPojectListForSelect } from '../../../projectFlow/selectors/selectors';
import {
  allTag, assignedToMeTag, createMeByTag, locationTags, modifiedTag,
} from '../../../constants/commonConstants';
import {
  expiredTag, issuesDevStatuses, issuesPurgatoryStatuses,
  issuesStatusesTags, issuesTestingStatuses,
  issuesValidationsStatuses,
  trackerTags,
} from '../../../constants/issuesConstants';
import ReactSelect from "../../../components/ReactSelect/ReactSelect";

function FiltersPersonalTasks({
  toggleCanvas,
  canvasFlag,
  onSelectTagsAndGetIssues,
  hidden,
  view,
  myUuid,
  customSelectedTags,
  defaultTagsConfig,
  filterProject,
  setFilterProject,
}) {
  const { t } = useTranslation();

  const firstItemForProjectList = { label: capitalize(t('wms.tabs.all_projects')), value: 'all' };

  const projectList = [
    firstItemForProjectList,
    ...useSelector(getPojectListForSelect),
  ];

  const tags = useMemo(() => [
    {
      tags: [allTag],
      isReset: true,
    },
    {
      tags: issuesValidationsStatuses,
      isRow: true,
      rowText: 'wms.noun.validation',
    },
    {
      tags: issuesDevStatuses,
      isRow: true,
      rowText: 'wms.labels.development',
    },
    {
      tags: issuesTestingStatuses,
      isRow: true,
      rowText: 'wms.labels.testing',
    },
    {
      tags: issuesPurgatoryStatuses,
      isRow: true,
      rowText: 'wms.noun.purgatory',
    },
    {
      tags: issuesStatusesTags.filter((i) => i.keySection === 'end'),
      isRow: true,
      rowText: 'wms.labels.tickets_end',
    },
    {
      tags: trackerTags,
      isRow: true,
      rowText: 'wms.table.headers.tracker',
    },
    {
      tags: expiredTag,
      isRow: true,
      rowText: 'wms.labels.expiration_date',
    },
    {
      tags: locationTags.map((i) => {
        if (i.value === 'all') {
          return { ...i, hidden: hidden.includes('hideProjects') || view === 'userPrivate' || hidden.includes('hidePrivate') || hidden.includes('hidePublic') };
        }
        if (i.value === 'public') {
          return { ...i, hidden: hidden.includes('hideProjects') };
        }
        if (i.value === 'private') {
          return { ...i, hidden: view === 'userPrivate' || hidden.includes('hidePrivate') };
        }
        if (i.value === 'project') {
          return { ...i, hidden: hidden.includes('hidePublic') };
        }
      }),
      isRow: true,
      rowText: view !== 'userPrivate' && 'wms.buttons.location',
      isRadio: true,
      noReset: true,
    },
    {
      tags: [
        ...createMeByTag.map((i) => ({ ...i, value: myUuid, hidden: hidden.includes('createdToMeTag') })),
        ...assignedToMeTag.map((i) => ({ ...i, value: myUuid, hidden: hidden.includes('createdToMeTag') })),
      ],
      rowText: 'wms.table.headers.user',
      isRow: true,
    },
    {
      tags: modifiedTag,
      isRow: true,
      rowText: 'wms.table.headers.modified',
      isRadio: true,
      noReset: true,
    },
  ], [issuesStatusesTags.length, myUuid]);

  // const resetFilter = () => getSelectedTags([]);

  const setFilterProjectFunc = (data) => {
    // console.log('data', data);
    if (!isNil(data)) {
      setFilterProject(data);
    } else {
      setFilterProject({
        label: 'All projects',
        value: 'all',
      });
    }
  };
  // console.log('filterProject', filterProject);
  return (
    <div className="flex flex-nowrap h-full">
      <div
        onClick={toggleCanvas}
        className="header-collapse-filter-vector"
      >
        <Icon className="mb-3" path={mdiChevronDoubleLeft} rotate={canvasFlag ? 0 : 180} size={0.9} />
        <div className="header-collapse-filter__text">
          {t('wms.issues.issues_filter', { noun: t('wms.noun.filter') }).toUpperCase()}
        </div>
      </div>

      <div className="body-collapse-filter">
        <div className={`body-collapse-filter__main-wrapper ${!canvasFlag ? 'hideWidth' : ''} p-2`}>
          {!hidden.includes('hideProjects') && (
          <>
            {capitalize(t('wms.noun.project.4', { filter: t('wms.noun.filter') }, 'project filter'))}
            <div className="w-full mt-1 mb-2">
              <ReactSelect
                noOptionsMessage={() => capitalize(t('wms.select.no_options', 'no options'))}
                placeholder={capitalize(t('wms.noun.project.1', 'projects'))}
                className="react-select"
                classNamePrefix="react-select"
                onChange={setFilterProjectFunc}
                options={projectList}
                value={get(filterProject, 'value') === 'all'
                  ? { label: capitalize(t('wms.tabs.all_projects')), value: 'all' }
                  : filterProject}
                isClearable
              />
            </div>
          </>
          )}
          {capitalize(t('wms.issues.issues_filter', { noun: t('wms.noun.filter') }))}
          <PersonalDashboardTagsWithRequests
            tags={tags}
            onSelectTagsAndGetIssues={onSelectTagsAndGetIssues}
            customSelectedTags={customSelectedTags}
            customDefaultPartition={defaultTagsConfig.partitionTypes}
          />
        </div>
      </div>

    </div>

  );
}

export default FiltersPersonalTasks;

import Icon from '@mdi/react';
import { string } from 'prop-types';
import React from 'react';

export default function BlockInfoCard({
  icon,
  label,
  description,
  classNameParent,
  classNameDescription,
}) {
  return (
    <div className={`flex items-start ${classNameParent}`}>
      <div className="mr-1 flex items-center whitespace-nowrap">
        <Icon size={0.7} path={icon} />
        <span className="inline-flex ml-1">{label}</span>
      </div>

      <span className={`inline-flex ml-2 text-secondary ${classNameDescription}`}>
        {description}
      </span>
    </div>
  );
}
BlockInfoCard.propTypes = {
  icon: string,
  label: string,
  description: string,
  classNameParent: string,
  classNameDescription: string,
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getPartitionPMUUID} from '../../../../config/selectors/selectors'
import { getListAndPartialReadEntities, getListAndReadEntities } from "../../../../entity/actions/entityActions";
import dayjs from "dayjs";
import { Button, Col, Flex, Spin} from "antd";
import { ProjectTypeCommonConstants } from "../../../constants/Constants";
import { getIssue } from "../../../actions/projectFlowActions";
import axiosRequest from "../../../../api/apiAxios";
import { getOrCreateUserBoardStorage } from "../../../../myBoard/actions/userBoardStorageActions";
import { getOrCreateUserBoard } from "../../../../myBoard/actions/userBoardActions";
import UsersTable from "../../../../toolsFlow/components/userReports/UsersTable.jsx";
import { find, get } from "lodash";
import { isAdminOrRoot } from "../../../../entity/selectors/selectors";
import { getMyInfo } from "../../../selectors/selectors";
import { partitionNamesConfig } from "../../../../api/appConfig";
import UserCard from "./UserCard";
import { getActorLabel, getClassBtn, getDaysUntilSunday, mods } from "./utils";
import MyBoardForGeneralPlanboard from "./MyBoardForGeneralPlanboard"
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import useWeekPlanning from "./useWeekPlanning.js";
import WeekPlanningBoard from "./WeekPlanningBoard.js";
import { Link } from "react-router-dom";
import './GeneralPlanboard.scss'

const limit = 5
const GeneralPlanboard = () => {
  
    const today = dayjs().format("YYYY-MM-DD")
    const tomorrow = dayjs().add(1, 'day').format("YYYY-MM-DD");
    const days =  getDaysUntilSunday()

    const defaultPartition = partitionNamesConfig.partition1

    const dispatch = useDispatch()
    const partitionPM = useSelector(getPartitionPMUUID);

    const myInfo = useSelector(getMyInfo)
    const adminOrRoot = useSelector(isAdminOrRoot);

    const [mode, setMode] = useState(mods.PLANNING_MODE)
    const [day, setDay] = useState(today)

    const [renderedBoard, setRenderedBoard] = useState(null)
    const [renderedIssues, setRenderedIssues] = useState(null)

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState({actor: myInfo.uuid, label: getActorLabel(myInfo)})
  
    const [projects, setProjects] = useState([])
    const [userBoards, setUserBoards] = useState(null)
    const [fetchedBoards, setFetchedBoards] = useState([])

    const [loading, setLoading] = useState(false)
    const [projectsOffset, setProjectsOffset] = useState(null)
    const [totalCountProjects, setTotalCountProjects] = useState(0)

    const getProjects = async () => {
      const data = {
          entity_type: "project",
          depth: 0,
          parent: partitionPM,
          limit: limit,
          offset: projectsOffset,
          params: {
            usersSearch: [selectedUser.actor],
            status: ['active', 'created']
          },
          params_fields: {
            title: "title",
            usersSearch: "usersSearch",
          }
      };
      const constants = [
        "GET_ENTITY_REQUEST",
        "GET_ENTITY_SUCCESS",
        "GET_ENTITY_FAILURE"
      ]
  
      const options = {
        partition: defaultPartition,    
      }
      const projects = await dispatch(getListAndPartialReadEntities({
        data,
        constants,
        options,
      }))
      setProjects(prev => [...prev, ...projects.data])
      setTotalCountProjects(projects.total)
  }

    const getUserBoards = async () => {
        const data = {
            entity_type: "userBoard",
            actor: selectedUser.actor,
            owner: selectedUser.actor,
            depth: 0,
            parent: partitionPM,
            params_fields: {
              [`${day}`]: day,
              [`${day}__data`]: `${day}__data`,
              [`${day}__settings`]: `${day}__settings`,
               project: 'project'
            }
        };
        const constants = [
          "GET_ENTITY_REQUEST",
          "GET_ENTITY_SUCCESS",
          "GET_ENTITY_FAILURE"
        ]
    
        const options = {
          partition: defaultPartition,
            
        }
        const userBoards = await dispatch(getListAndReadEntities({
          data,
          constants,
          options,
        }))
        setUserBoards(userBoards.data)
          return userBoards.data
    }

    const createUserBoard = async (projectUUID, actor) => {
      try {
        const storage = await dispatch(getOrCreateUserBoardStorage({
            actor: actor,
            parent: projectUUID,
            partition: defaultPartition,
        }));

        const board = await dispatch(getOrCreateUserBoard({
            parent: storage.uuid,
            partition: defaultPartition,
            actor: actor,
            project: projectUUID,
        }));

        return board;

    } catch (error) {
        console.error('error:', error);
    }    
  }

  const getBoardForProject = async (projectUUID, projectTitle, projectUsers, userBoards) => {
    const userBoard = find(userBoards, el => get(el, 'params.project', []).includes(projectUUID)) 
        ?? await createUserBoard(projectUUID, selectedUser.actor);

    return {...userBoard, projectTitle: projectTitle, projectUsers: projectUsers};
};

const fetchBoards = async (userBoards) => {
  setLoading(true)
  const fetchedBoards = await Promise.all(projects.map(project => getBoardForProject(project.uuid, project.title, project.usersSearch, userBoards)));
  setFetchedBoards(fetchedBoards);
  setLoading(false)
  return fetchedBoards

};


    const getFetchBoards = async () => {
    const userBoards = await getUserBoards()
    const boards = await fetchBoards(userBoards)
      return boards
    }


    const changeUser = (user) => {
      if(selectedUser.actor !== user.actor) {
        setSelectedUser(null)
        setSelectedUser(user)
        setProjectsOffset(null)
        setDay(today)
      }
    }

    const getClearIssue = (uuid, signal) => {
      axiosRequest.abort('', ProjectTypeCommonConstants.ISSUE_ENTITY_REQUEST);
  
      const config = {
        uuid,
        partition: defaultPartition,
        signal,
        withFiles: false,
        params: {},
      };
  
      return dispatch(getIssue(config));
    };

    const updateRenderedBoard = (userBoardUUID) => {
      setRenderedBoard(prev => {
        return {
          userBoardUUID: userBoardUUID,
          timestamp: Date.now()
        }
      })
    }

    const updateRenderedIssues = (userBoardUUID) => {
      setRenderedIssues(prev => {
        return {
          userBoardUUID: userBoardUUID,
          timestamp: Date.now()
        }
      })
    }

   
    useEffect(() => {
      if (myInfo.uuid) {
        setSelectedUser ({ actor: myInfo.uuid, label: getActorLabel(myInfo) });
      }
    }, [myInfo.uuid]);
 
    useEffect(() => {
      if (selectedUser && selectedUser.actor) {
        setProjects([]);
        setTotalCountProjects(null)
        setProjectsOffset(0);
      }
    }, [selectedUser]);
    
    useEffect(() => {
      if (projectsOffset !== null && selectedUser  && selectedUser.actor) {
        getProjects();
      }
    }, [projectsOffset, selectedUser]);

    useEffect(() => {
      if(projects && projects.length > 0)
      getUserBoards()
    },[projects])

    useEffect(() => {
      if (projects) {
        fetchBoards();
      }
    }, [projects]);

    useEffect(() => {
      if(!days.includes(day)) {
        setMode(mods.WORK_MODE)
      }
    }, [day])

    const {
      todayVector,
      relatedGeneralIssues,
      dayParams,
      loadingWeekBoard,
      updateVectorRelatedGeneralIssues,
      getListRelatedGeneralIssues,
      updateGeneralPlanboardIssues
    } = useWeekPlanning({customActor: selectedUser.actor, getFetchBoards, today, day, projects})

    return (
      <>
        <h4 className="mb-3 ml-4 dt-page__title inline-flex items-center">
          General Planboard
        </h4>
         <div className="relative h-full w-full">
      <div className="wrapper-user-report"> 
     
        {adminOrRoot && 
    <Flex vertical span={6} style={{position: 'sticky', top: 0}}>
        <UserCard 
          user={myInfo} 
          changeUser={changeUser}
        />
        <Col className=" bg-white mr-2 mt-2 overflowHidden user-table-wrapper">
           <UsersTable
           selectedUser={selectedUser.actor}
           users={users}
           setSelectedFullUser={changeUser}
           setUsers={setUsers}
         />
    </Col>
  </Flex>}       
        <div className="w-full">
          <WeekPlanningBoard
          today={today}
          selectedUser={selectedUser}
          partition={defaultPartition}
          customActor={selectedUser.actor}
          getClearIssue={getClearIssue}
          getUserBoards={getUserBoards}
          //add hook props
          todayIssues={relatedGeneralIssues}
          loading={loadingWeekBoard}
          setMode={setMode}
          mode={mode}
          setDay={setDay}
          currentDay={day}
          tomorrow={tomorrow}
          //add week planning props
          getListRelatedGeneralIssues={getListRelatedGeneralIssues}
          updateVectorRelatedGeneralIssues={updateVectorRelatedGeneralIssues}
          todayVector={todayVector}
          loadingWeekBoard={loadingWeekBoard}
          dayParams={dayParams}
          updateGeneralPlanboardIssues={updateGeneralPlanboardIssues}
          updateRenderedBoard={updateRenderedBoard}
          updateRenderedIssues={updateRenderedIssues}
          />
          {mode === mods.PLANNING_MODE && totalCountProjects && totalCountProjects > 0 &&
          <Flex className="mr-2 mb-1" justify="end">
            <h6>{`Projects shown: ${projects.length} / ${totalCountProjects}`}</h6>
            </Flex>}
          {mode === mods.PLANNING_MODE &&
           <Spin className="w-full" spinning={loading} >
              {projects && userBoards && fetchedBoards && projects.map((project, index) => {
              const userBoard = fetchedBoards[index];
              return ( 
              <Flex vertical className="projectCard w-full" key={project.uuid}>
                <Flex vertical>
                  <Flex className="m-0 text-ellipsis">
                  <b>Project title:</b>
                  <Link className="board-link ml-1.5" 
                  to={`/pm/projects/project?activeProject=${project.uuid}&tab=info`}>
                    {project.title}
                    </Link>
                  </Flex>
                </Flex>
                  {userBoard && 
                  <MyBoardForGeneralPlanboard
                  projectUUID={project.uuid}
                  userBoard={userBoard}
                  customActor={selectedUser.actor}
                  getClearIssue={getClearIssue}
                  projectUsers={project.usersSearch}
                  partition={defaultPartition}
                  today={today}
                  projectTitle={project.title}
                  currentDay={day}
                  //add new week planning props
                  todayVector={todayVector}
                  relatedGeneralIssues={relatedGeneralIssues}
                  updateVectorRelatedGeneralIssues={updateVectorRelatedGeneralIssues}
                  getListRelatedGeneralIssues={getListRelatedGeneralIssues}
                  renderedBoard={renderedBoard}
                  renderedIssues={renderedIssues}
                  />}
                </Flex>)
             })}
            {totalCountProjects > 0 && totalCountProjects > projects.length &&
               <Flex className="w-full mb-5" justify="center">
               <Button
                  disabled={false}
                  className={`${getClassBtn("get")} mb-2`}
                  size="small"
                  onClick={() => setProjectsOffset(prev => prev === null ? prev : prev + limit)}
                >
              Show more
              <Icon path={mdiChevronDown} size={0.9}/>
                  </Button>
               </Flex>
            }
          </Spin> }
          </div> 
        </div>
      </div>
     </>
    )
}

export default GeneralPlanboard
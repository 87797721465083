import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { capitalize, get } from 'lodash';

import { Tooltip, Flex, Popover } from 'antd';

import Icon from '@mdi/react';
import {
  mdiAccountMultipleOutline,
  mdiChevronRight,
  mdiClose,
  mdiHelpCircleOutline,
} from '@mdi/js';

import IssueOrderButtons from '../commonComponents/IssueOrderButtons';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import ChunksComponent from '../ChunksComponent';
import CompleteIssue from '../CompleteIssue';
import TotalIssueChunksButtons from '../commonComponents/TotalIssueChunksButtons';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';

import { stopPropagation } from '../../../../54origins/utils54origins';
import { addTagsForTitle } from '../../../../entity/entityUtils';
import { getColorByPriority, getColorByStatus } from '../utils';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { useSelector } from 'react-redux';
import { getProjectParams } from '../../../selectors/selectors';
import { getUuid } from '../../../../assignUsersModal/utils';

function PlanForDayListOfIssues({
  data,
  onActionCallback,
  onChangeRowCallback,
  customProjectUsers,
  hideElements = [],
  isMainBoard = false,
  days,
  currentDay
}) {
  const projectParams = useSelector(getProjectParams);
  const projectUsers =  customProjectUsers ||  get(projectParams, 'usersSearch', [])

  const btnBlue = "border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white";
  const btnOrange = "border border-orange-500 text-orange-500 hover:bg-orange-500 hover:text-white";
  const btnRed = "border border-red-500 bg-white text-red-500 hover:bg-red-500 hover:text-white";

  const btnStyle = {
    width: 50,
    maxWidth: 80,
    maxHeight: 25,
    height: 25,
  };

  const btnRemoveStyle = {
    width: 25,
    maxWidth: 25,
    maxHeight: 25,
    height: 25,
  };

  const TimeTitle = () => {
    return <Tooltip
      color="white"
      title={(
        <Flex
          vertical
          className='text-black'
        >
          One step is equal to 0.5 hours.
          <Flex align='center'>
            <ChunksComponent
              total={1}
              chunks={[{
                count: 1,
                color: "orange",
              }]}
            />
            <span className='-mt-1.5 ml-1'> - context switching time</span>
          </Flex>
          <Flex align='center'>
            <ChunksComponent
              total={1}
              chunks={[{
                count: 1,
                color: "blue",
              }]}
            />
            <span className='-mt-1.5 ml-1'>- working time</span>
          </Flex>
        </Flex>)
      }
    >
      <b>
        Time
      </b>
      <Icon
        path={mdiHelpCircleOutline}
        className='ml-1'
        size={0.9}
      />
    </Tooltip>
  }

  const TimeRow = ({ issue = {} }) => {
    const uuid = get(issue, 'uuid', '');
    // const chunks = !isMainBoard ? 
    // (days.includes(currentDay) ? get(issue, 'data.chunk', 0)  : get(issue, 'boardData.chunk', 3)) 
    // : get(issue, 'boardData.chunk', 3);
    const chunks = get(issue, 'data.chunk', 0)
    return (
      <Flex
        vertical
      >
        <ChunksComponent
          chunks={[
            {
              count: chunks,
              color: "blue",
            },
          ]}
        />
        <Flex>
          <TotalIssueChunksButtons
            uuid={uuid}
            issue={issue}
            chunks={chunks}
            onActionCallback={(value) => onActionCallback(value, 'issue-time-update')}
          />
          <span className='ml-1 -mt-0.5 text-blue-500'>
            hours
          </span>
        </Flex>
      </Flex>
    )
  }

  const ActionsRow = ({ issue = {} }) => {
    const done = get(issue, 'done', false);
    const uuid = get(issue, 'uuid', false);

    return (
      <Flex justify='end'>
        <Flex
          // vertical
          justify='end'
        >
          {!done && <Popover
            placement='top'
            onClick={stopPropagation}
            trigger={!days.includes(currentDay) ? "click" : "hover"}
            title={(
              <CompleteIssue
                onActionCallback={onActionCallback}
                type={"QA"}
                issue={issue}
              />
            )}
          >
            <BaseButton
              disabled={done ||  !days.includes(currentDay)}
              className={cn(`mr-1 text-xs w-full `, {
                [days.includes(currentDay) ? btnOrange : ""]: true,
              })}
              size="small"
              style={btnStyle}
              onClick={stopPropagation}
            >
              QA actions
            </BaseButton>
          </Popover>}
          {done ?
            <Tooltip
              title="Reopen issue"
              color="blue"
              placement='right'
            >
              <BaseButton
                className={`mr-1 text-xs w-full ${btnBlue}`}
                size="small"
                style={ btnStyle}
                onClick={(e) => onActionCallback?.(issue, 'issue-reopen', e)}
              >
                Reopen
              </BaseButton>
            </Tooltip>
            : <Popover
              placement='top'
              onClick={stopPropagation}
              trigger={!days.includes(currentDay) ? "click" : "hover"}
              title={(
                <CompleteIssue
                  onActionCallback={onActionCallback}
                  type={"DEV"}
                  issue={issue}
                />
              )}
            >
              <BaseButton
                disabled={done ||  !days.includes(currentDay)}
                className={cn(`mr-1 text-xs w-full `, {
                  [days.includes(currentDay) ? btnBlue : '']: true,
                })}
                size="small"
                style={btnStyle}
                onClick={stopPropagation}
              >
                Dev actions
              </BaseButton>
            </Popover>
          }
        </Flex>
        <Tooltip
          title="Delete from plan"
          placement='right'
          color="red"
        >
          <BaseButton
            disabled={!days.includes(currentDay)}
            className={`w-full mr-1 text-xs ${days.includes(currentDay) ? btnRed : ''}`}
            size="small"
            style={btnRemoveStyle}
            onClick={(e) => onActionCallback?.(!isMainBoard ? uuid : issue, 'issue-remove', e)}
          >
            <>
              {!isMainBoard ? <Icon path={mdiChevronRight} size={1}/> : <Icon path={mdiClose} size={1}/> }
            </>
          </BaseButton>
        </Tooltip>
      </Flex>
    )
  }

  const TitleRow = ({ title, issue }) => {
    const [isEditAssignUsers, setEditAssignUsers] = useState(false)

    const tags = get(issue, 'tags', '');
    const status = get(issue, 'status', '');
    const statusColor = getColorByStatus(issue);
    const priority = get(issue, 'priority', '');
    const priorityColor = getColorByPriority(issue);
    const description = get(issue, 'description', '');
    const users = get(issue, 'usersSearch', []);
    const uuid = get(issue, 'uuid', '');
    const projectUsersForMainBoard = get(issue, 'projectUsers', [])
    const project = get(issue, 'project', '');
    const userBoardUUID = get(issue, 'userBoardUUID', '')
      
    return (
      <Tooltip
        overlayStyle={{
          maxWidth: '500px',
          width: description.length > 200 ? '500px' : '250px'
        }}
        title={(<Flex
          onClick={stopPropagation}
          className='overflow-scroll'
          vertical
        >
          <span
            className='text-black mb-1'
          >
            What needs to be done:
          </span>
          <p
            className='text-gray-500 ml-2'
            style={{
              maxHeight: '300px',
              overflowY: 'scroll'
            }}
          >
            {description}
          </p>
        </Flex>)}
        color='white'
      >
        <Flex
          vertical
        >
          <EntityParamTag
            param="title"
            value={addTagsForTitle(tags, title)}
            type="value"
          />
          <Flex
            className='mt-0.5'
          >
            <Tooltip
              title="Status"
              color={statusColor}
            >
              <EntityParamTag
                param="status"
                value={status}
                type="iconWithStatus"
              />
            </Tooltip>
            <Tooltip
              title="Priority"
              color={priorityColor}
            >
              <EntityParamTag
                param="priority"
                value={priority}
                type="valueWithBorder"
              />
            </Tooltip>

            <AssigningUsersRoot
              header="Assigned users"
              isOpen={isEditAssignUsers}
              setIsOpen={setEditAssignUsers}
              isCheckWindowWidthMode={false}
              isClosePopoverOnLeaveMode
              isOneColumnMode={true}
              size={'extra small'}
              data={{
                uuids: !isMainBoard ? projectUsers : projectUsersForMainBoard,
              }}
              isPopover
              onUpdateUsers={(usersData) => {
                const newUsers = usersData?.users?.map((el) => getUuid(el));
                onActionCallback({ users: newUsers, uuid, project, userBoardUUID }, 'issue-users-update')
              }}
              globalOptions={{
                uuids: !isMainBoard ? projectUsers : projectUsersForMainBoard,
                isOneColumnMode: true,
                size: 'extra small',
              }}
              usersConfig={{
                label: 'Users',
                showSections: ['string-role'],
                textSelectButton: 'Select',
                textDeleteButton: 'Deselect',
                defaultItems: users,
              }}
              popoverChild={(
                <Tooltip
                  placement="top"
                  onClick={(e) => {
                    stopPropagation(e)
                      if(days.includes(currentDay)) {
                        setEditAssignUsers(true)
                      }
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                  title={'Assigned users'}
                >
                  <Flex
                    className="text-blue-500 border border-blue-500 rounded bg-white hover:bg-blue-500 hover:text-white"
                    align="center"
                    justify='center'
                    style={{
                      cursor: 'pointer',
                      height: 20,
                      maxHeight: 20,
                      width: 50,
                      maxWidth: 50
                    }}
                  >
                    <span
                      className='text-sm'
                    >
                      {users.length}
                    </span>
                    <Icon
                      path={mdiAccountMultipleOutline}
                      role="button"
                      size={0.8}
                      className="ml-1"
                    />
                  </Flex>
                </Tooltip>)}
            />
          </Flex>
        </Flex>
      </Tooltip>)
  }

  const columns = [
   ...(!hideElements.includes('idColumn') ? [{
    dataIndex: 'id',
    width: '5%',
    key: 'id',
    render: (cell, row, index) => <IssueOrderButtons
      isFirstItem={index === 0}
      isLastItem={index + 1 === data.length}
      done={get(row, 'done', false)}
      issue={row}
      index={index}
      onActionCallback={onActionCallback}
      issues={data}
      days={days}
      currentDay={currentDay}
    />
  }] : [{}]),
    {
      dataIndex: 'title',
      width: '50%',
      key: 'title',
      title: 'Title',
      render: (cell, row) => <TitleRow title={cell} issue={row} />,
    },
    {
      dataIndex: 'time',
      key: 'time',
      width: '35%',
      title: <TimeTitle />,
      render: (cell, row) => <TimeRow issue={row} />,
    },
    ...(isMainBoard ? [ {
      title: <Flex>
      Project title
      <Tooltip
        color="white"
        title={(
          <Flex
            vertical
            className='text-black'
          >
          Name of the project in which the task is located
          </Flex>)
        }
      >
        <Icon
          path={mdiHelpCircleOutline}
          size={0.9}
          className='ml-1'
        />
      </Tooltip>
</Flex>,
      width: "30%",
      key: "projectTitle",
      render: (cell) => <h6>{cell?.projectTitle}</h6>}]
      : [{}]
    ),
    {
      width: '10%',
      dataIndex: 'actions',
      key: 'actions',
      title: "Actions",
      render: (cell, row) => <ActionsRow
        issue={row}
      />
    },
  ].filter((el) => !el.hidden);

  const onRow = (record) => ({
    onClick: () => {
      const { uuid } = record || {};
      if (onChangeRowCallback) {
        onChangeRowCallback(!isMainBoard ? uuid : record );
      }
    },
  });

  const rowClassName = (row) => `${get(row, 'done', false) ? '!bg-green-100' : ''}`

  return (
    <BaseTableWithPagination
      columns={columns}
      data={data}
      disablePagination={true}
      rowKey="uuid"
      size="small"
      headerRowClassName="universal_header_table_row color-gray"
      onRow={onRow}
      rowClassName={rowClassName}
    />
  );
}

export default PlanForDayListOfIssues;

PlanForDayListOfIssues.propTypes = {
  data: PropTypes.array,
  onActionCallback: PropTypes.func,
  onChangeRowCallback: PropTypes.func,
  customProjectUsers: PropTypes.array,
  hideElements: PropTypes.array,
  isMainBoard: PropTypes.bool,
  days: PropTypes.array,
  currentDay: PropTypes.string
};

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { mdiLoading, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import { capitalizeAndTranslateMsg } from '../../../MainUtils';

export default function AddProjectBtn({
  disabled,
  newProjectCallback,
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  //
  return (
    <BaseButton
      id="addNewProjectButton"
      className="btnPrimary-outline mr-1 mb-1"
      onClick={newProjectCallback}
      size="small"
      disabled={disabled}
    >
      <Icon
        path={loading ? mdiLoading : mdiPlus}
        size={0.9}
        className="mr-1"
        // spin={loading}
      />
      {capitalizeAndTranslateMsg('wms.verb.add', 'Add')}
      {' '}
      {t('wms.noun.project.0', 'project')}
    </BaseButton>
  );
}

AddProjectBtn.propTypes = {
  newProjectCallback: PropTypes.func,
  disabled: PropTypes.bool,
};

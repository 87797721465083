import { axiosRequestPost } from '../../api/apiAxios';
import { entityUpdate } from '../../entity/actions/entityActions';
import { ProjectTypeCommonConstants } from '../constants/Constants';

export const removeFile = (uuid, filesUuid, partitionType) => {
  const data = {
    entity_type: 'issue',
    entity_uuid: uuid,
    files: [filesUuid],
  };

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequestPost(
        'utility/entity/update/clean',
        [
          ProjectTypeCommonConstants.DELETE_FILE_REQUEST,
          ProjectTypeCommonConstants.DELETE_FILE_SUCCESS,
          ProjectTypeCommonConstants.DELETE_FILE_FAILURE,
        ],
        data,
        {
          partition: partitionType,
          onSuccess: (response) => resolve(response),
          onFailure: reject,
        },
      ),
    );
  });
};

export const updateIssueRequest = (data, partition, callback) => {
  const constants = [
    ProjectTypeCommonConstants.EDIT_ISSUE_REQUEST,
    ProjectTypeCommonConstants.EDIT_ISSUE_SUCCESS,
    ProjectTypeCommonConstants.EDIT_ISSUE_FAILURE,
  ];

  const options = {
    partition,
    onSuccess: callback,
    onFailure: () => callback(false),
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
};

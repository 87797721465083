import { ProjectTypeCommonConstants } from '../../constants/Constants';

const initialState = {
  fetchingTestCaseInCycleFromArchive: false,
  listTestCaseInCycleFromArchive: [],
  fetchingOneTestCaseInCycleFromArchive: false,
  oneTestCaseInCycleFromArchive: [],
  dataFilter: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ProjectTypeCommonConstants.UPDATE_FILTER_LIST_TEST_CASE_IN_ARCHIVE:
      return {
        ...state,
        dataFilter: payload,
      };

    case ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_FROM_ARCHIVE_REQUEST:
      return {
        ...state,
        fetchingTestCaseInCycleFromArchive: true,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_FROM_ARCHIVE_SUCCESS:
      return {
        ...state,
        fetchingTestCaseInCycleFromArchive: false,
        listTestCaseInCycleFromArchive: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_FROM_ARCHIVE_FAILURE:
      return {
        ...state,
        fetchingTestCaseInCycleFromArchive: false,
        listTestCaseInCycleFromArchive: payload,
      };

    case ProjectTypeCommonConstants.CLEAR_LIST_OF_TEST_CASES_IN_ARCHIVE_IN_CYCLE:
      return {
        ...state,
        listTestCaseInCycleFromArchive: [],
      };

    case ProjectTypeCommonConstants.GET_TEST_CASE_IN_CYCLE_FROM_ARCHIVE_REQUEST:
      return {
        ...state,
        fetchingOneTestCaseInCycleFromArchive: true,
      };
    case ProjectTypeCommonConstants.GET_TEST_CASE_IN_CYCLE_FROM_ARCHIVE_SUCCESS:
      return {
        ...state,
        fetchingOneTestCaseInCycleFromArchive: false,
        oneTestCaseInCycleFromArchive: payload,
      };
    case ProjectTypeCommonConstants.GET_TEST_CASE_IN_CYCLE_FROM_ARCHIVE_FAILURE:
      return {
        ...state,
        fetchingOneTestCaseInCycleFromArchive: false,
        oneTestCaseInCycleFromArchive: payload,
      };
    default: return state;
  }
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import _ from 'lodash';
import { stopPropagation } from '../../../54origins/utils54origins';
import './CollapseComponent.scss';

function CollapseComponent({
  children,
  handleChangeCollapse,
  handleFuncCollapse,
  headerComponent,
  hideButton,
  hideText,
  isCollapsed = false,
  showText,
  type = '',
  id,
  disabled,
}) {
  const [collapsed, setCollapsed] = useState(isCollapsed);

  const toggle = (e) => (handleFuncCollapse ? handleFuncCollapse(e)
    : setCollapsed(!collapsed));

  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed]);
  return (
    <div
      className={`collapseComponent ${type} ${handleChangeCollapse || collapsed ? 'collapsed' : ''}`}
      id={id}
    >
      <div onClick={toggle} id={id}>
        {headerComponent}
      </div>
      <Collapse
        defaultActiveKey={_.toInteger(isCollapsed)}
        activeKey={_.toInteger(handleChangeCollapse || collapsed)}
        bordered={false}
        className="collapseComponent-body"
        id={id}
      >
        <Collapse.Panel
          id={id}
          key={1}
          header={null}
          showArrow={false}
          className="collapseActive-component"
        >
          <div onClick={stopPropagation}>
            {children}
          </div>
        </Collapse.Panel>
      </Collapse>
      {!hideButton
      && (
      <div
        className="collapseComponent-button_container"
        id={id}
        onClick={toggle}
      >
        {type
        && (showText || hideText) && (
          <h4
            id={id}
            className="collapsedText m-0"
          >
            {collapsed || handleChangeCollapse ? hideText : showText}
          </h4>
        )}
        {!disabled
        && (
        <div className="collapseComponent-expander" id={id}>
          <Icon className="collapseComponent-icon" path={mdiChevronDown} size={1} id={id} />
        </div>
        )}
      </div>
      )}
    </div>
  );
}

CollapseComponent.propTypes = {
  children: PropTypes.element,
  handleChangeCollapse: PropTypes.bool,
  handleFuncCollapse: PropTypes.func,
  headerComponent: PropTypes.element,
  hideButton: PropTypes.bool,
  hideText: PropTypes.string,
  isCollapsed: PropTypes.bool,
  showText: PropTypes.string,
  type: PropTypes.string,
};

export default CollapseComponent;

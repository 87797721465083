import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import { getCurrentArchiveStateUUID } from '../../selectors/archiveStateSelectors';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { antNotification } from '../../../MainUtils';
import { getProjectUUID } from '../../selectors/selectors';
import { entityUpdate } from '../../../entity/actions/entityActions';
import { partitionNamesConfig } from '../../../api/appConfig';

function RestoreEntityType({
  // name,
  // entityType,
  // params,
  callbackAction,
  className = '',
  archiveEntityUUID,
  entityUUID,
}) {
  const dispatch = useDispatch();
  const projectUUID = useSelector((state) => getProjectUUID(state));
  const curArchiveStateUUID = useSelector((state) => getCurrentArchiveStateUUID(state));

  const updateParentForEntities = () => {
    const checkIsValid = (param) => !isNil(param) && !isEmpty(param);
    const parent = checkIsValid(entityUUID) ? entityUUID : projectUUID;
    // let oldParent = checkIsValid(archiveEntityUUID) ? archiveEntityUUID : curArchiveStateUUID;

    const data = {
      entity_uuid: archiveEntityUUID,
      parent,
    };

    const constants = [
      ProjectTypeCommonConstants.UPDATE_PARENT_FOR_ARCHIVE_ENTITY_REQUEST,
      ProjectTypeCommonConstants.UPDATE_PARENT_FOR_ARCHIVE_ENTITY_SUCCESS,
      ProjectTypeCommonConstants.UPDATE_PARENT_FOR_ARCHIVE_ENTITY_FAILURE,
    ];

    const options = {
      partition: partitionNamesConfig.partition1,
      onSuccess: (response) => {
        antNotification('success', response.message);
      },
    };

    dispatch(entityUpdate({
      data,
      constants,
      options,
    }));
  };

  const moveEntitiesToProject = async () => {
    updateParentForEntities();

    if (callbackAction) {
      callbackAction(curArchiveStateUUID);
    }
  };

  return (
    isEmpty(curArchiveStateUUID) || isNil(curArchiveStateUUID) ? null
      : (
        <BaseButton
          className={`btnWarning-outline ${className}`}
          size="small"
          onClick={moveEntitiesToProject}
        >
          Restore
        </BaseButton>
      )
  );
}

export default RestoreEntityType;

RestoreEntityType.propTypes = {
  archiveEntityUUID: PropTypes.string,
  callbackAction: PropTypes.func,
  className: PropTypes.string,
  entityUUID: PropTypes.string,
};

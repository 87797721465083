import { Select } from 'antd';
import { get, head } from 'lodash';
import {
  bool, func, string,
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getPartitionPMUUID } from '../../../../config/selectors/selectors';
import { getListAndPartialReadEntities } from '../../../../entity/actions/entityActions';
import { convertEntitiesForSelect } from '../../../../MainUtils';
import { capitalize } from 'lodash';
import { getMe, getProject } from '../../../selectors/selectors';
import { partitionNamesConfig } from '../../../../api/appConfig';

export default function SelectAssignedProject({
  needMyUserProject = false,
  customSelect,
  selectedProjectCallback,
  size = 'large',
  disabled,
  partitionType,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const projectData = head(useSelector(getProject));
  const pmPartition = useSelector(getPartitionPMUUID);
  const userUUID = useSelector(getMe);

  const [selectedProject, setSelectedProject] = useState([]);
  const [listProjects, setListProjects] = useState({});

  const getListOfProjects = async () => {
    const data = {
      entity_type: 'project',
      parent: partitionType === partitionNamesConfig.partition3 && projectData?.parent ? projectData?.parent : pmPartition,
      params: { status__not: ['archive'] },
      fields: {
        partition: 'partition',
      },
      params_fields: {
        usersSearch: 'usersSearch',
        title: 'title',
      },
    };

    const constants = [
      'GET_PROJECTS_FOR_SELECT_REQUEST',
      'GET_PROJECTS_FOR_SELECT_SUCCESS',
      'GET_PROJECTS_FOR_SELECT_FAILURE',
    ];

    if (needMyUserProject) {
      data.actor = userUUID;
    }

    return dispatch(getListAndPartialReadEntities({
      data,
      constants,
      partition: partitionType === partitionNamesConfig.partition3 && projectData?.parent ? partitionNamesConfig.partition3 : partitionNamesConfig.partition1,
    }));
  };

  const noOption = () => capitalize(t('wms.select.no_options', 'no options'));
  const placeholder = capitalize(t('wms.placeholders.projects', 'projects'));

  const handleChangeProject = (uuid) => {
    setSelectedProject(uuid);

    const getProject = get(listProjects, 'list').find((n) => n.uuid === uuid);

    selectedProjectCallback({
      assignedProject: uuid,
      selectedProjectData: getProject,
    });
  };

  const initialFunc = async () => {
    if (customSelect) {
      setSelectedProject(customSelect);
    }

    const { data } = await getListOfProjects();

    setListProjects({
      listForSelect: convertEntitiesForSelect(data),
      list: data,
    });
  };

  useEffect(() => {
    initialFunc();
  }, [pmPartition]);

  useEffect(() => {
    if (customSelect && customSelect !== selectedProject) {
      setSelectedProject(customSelect);
    }
  }, [customSelect]);
  return (
    <Select
      showSearch
      optionFilterProp="label"
      disabled={disabled}
      value={[selectedProject]}
      size={size}
      options={get(listProjects, 'listForSelect')}
      onChange={handleChangeProject}
      isClearable
      noOptionsMessage={noOption}
      placeholder={placeholder}
    />
  );
}

SelectAssignedProject.propTypes = {
  needMyUserProject: bool,
  customSelect: string,
  selectedProjectCallback: func,
  size: string,
  disabled: bool,
  partitionType: string,
};

import moment from 'moment';
import { issuesStatusesTags } from '../../../../constants/issuesConstants';

export const getMinDateExpired = (number = 1010) => moment().subtract(number, 'year').format('YYYY-MM-DD');

export const checkIsMinDateForExpired = (date) => moment().diff(date, 'year') > 1000;

export const getExpTypeAndValue = (status) => {
  let type;
  switch (status) {
    case 'acquainted':
    case 'accepted':
    case 'rejected':
    case 'in progress':
    case 'review':
    case 'reopened':
    case 'ready for deploy':
    case 'test':
    case 'testing':
    case 'not reproducible':
      type = 'expirationDateDev';
      break;
    default: return { type: 0, days: 0 };
  }
  return {
    type,
    days: issuesStatusesTags.find((item) => item?.value === status)?.exp,
  };
};

export const getExpByStatus = (status, issue) => {
  switch (status) {
    case 'acquainted':
    case 'accepted':
    case 'rejected':
    case 'in progress':
    case 'review':
    case 'ready for deploy':
      return issue?.expirationDateDev;
    case 'test':
    case 'testing':
    case 'not reproducible':
      return issue?.expirationDateDev;
    default: return null;
  }
};

export const getExpirationDate = (priority) => {
  switch (priority) {
    case 'low': return {
      lifeTime: moment().add(21, 'days').format('YYYY-MM-DD'),
    };
    case 'medium': return {
      lifeTime: moment().add(14, 'days').format('YYYY-MM-DD'),
    };
    case 'high': return {
      lifeTime: moment().add(10, 'days').format('YYYY-MM-DD'),
    };
    case 'urgent': return {
      lifeTime: moment().add(5, 'days').format('YYYY-MM-DD'),
    };
    default: return {
      lifeTime: moment().add(2, 'week').format('YYYY-MM-DD'),
    };
  }
};

import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, Modal, Row, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { cloneDeep, get } from 'lodash';

import ProjectFlowInfoTip from '../ProjectFlowInfoTip';
import NewProject from './NewProject';
import BreadCrumbsNEW from '../../../components/breadcrumb/BreadCrumbsNEW';
import ProjectInfo from './projectInfo/ProjectInfo';
import ListOfProjectsForProjectFlow from './ListOfProjectsForProjectFlow';

import axiosRequest from '../../../api/apiAxios';
import useCheckPerm from '../../../hooks/useCheckPerm';
import useURLParams from '../../../hooks/useURLParams';
import useUserPublicPartition from '../../../hooks/useUserPublicPartition';

import {
  getAreas,
  getMe,
  getProjectFetching,
  getProjectUUID,
} from '../../selectors/selectors';
import {
  getFavoriteFetching,
  getFavoriteProjects,
  getUserPublicProfileUUID,
} from '../../../userFlow/store/selectors/selectors';
import { getListOfProjects, getProjectRequest } from '../../actions/projectFlowActions';
import { getRootEntityPartition } from '../../../config/selectors/selectors';
import {
  clearListOfProjects,
  clearListOfPersonalProjects,
  clearProject,
} from '../../actions/PtcActionsForHook';
import { selectProjectsKPM } from '../../reducers/slicers/projectsKPMSlice';
import { CLEAR_ALL_PROJECT_MILESTONES } from '../../reducers/slicers/projectMilestonesSlicer';
import { CLEAR_ALL_PROJECT_GOALS } from '../../reducers/slicers/projectGoalsSlicer';
import { getUsersByUUID } from '../../../userFlow/store/action-creators/userActions';

import { getUuidsfromParamsUsers } from '../../../MainUtils';
import { ProjectTypeCommonConstants } from '../../constants/Constants';

import { setFetchingScoringData, getProjectsIssuesScoringData } from '../../reducers/slicers/projectIssueScoringSlicer';

// import { clearAllScoringData } from '../../reducers/slicers/projectAllScoringSlicer';
import { clearCurrentProjectScoreData } from '../../reducers/slicers/projectAllScoringSlicer';
import { defaultConfigProjectsRequest } from './utils/projectsFilterHelper';
import useDevScore from '../../../hooks/projectAuditHooks/useDevScore';
import usePlanScore from '../../../hooks/projectAuditHooks/usePlanScore';
import useProjectsFilter from './utils/useProjectsFilter';
import useActorsW54ModalStyle from '../../../actors/hooks/useActorsW54ModalStyle';
import { useResize } from '../../../hooks/useResize';
import { partitionNamesConfig } from '../../../api/appConfig';

const defaultNotSavedData = {
  notSaved: false,
  confirmNotSaved: false,
};

function ProjectFlowProjectPage({
  location,
  hideAddProjectBtn,
  defaultPartition = partitionNamesConfig.partition1,
  initialPathForHistory = '/pm/projects/',
  customParent,
  doNotMakeProjectRequest,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    setSearchParams,
    getURLParams,
    routeNavigateAndClearParams,
    clearSearchParams,
  } = useURLParams();

  const userUUID = useSelector(getMe);
  const parentDefault = useSelector((state) => getRootEntityPartition(state, defaultPartition));
  const parentPM = useSelector((state) => getRootEntityPartition(state, partitionNamesConfig.partition1));
  const parentPublic = useSelector((state) => getRootEntityPartition(state, partitionNamesConfig.partition3));
  const currentParent = location === 'dashboard' ? customParent : parentDefault;

  const projectUUID = useSelector(getProjectUUID);
  const projectFetching = useSelector(getProjectFetching);

  const allAreas = useSelector(getAreas);

  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const { actorMainPublicProjectUUID } = useUserPublicPartition(myPublicUUID, 'mainPublicProjectUUID');

  const favoriteFetching = useSelector(getFavoriteFetching);
  const favoritesArray = useSelector(getFavoriteProjects);
  const favoriteProjectsUUID = [...favoritesArray].reduce((favProjects, item) => {
    const { uuid, entityType } = item;
    if (entityType === 'project') return [...favProjects, uuid];
    return [...favProjects];
  }, []);
  // console.log('favoriteProjectsUUID: ', favoriteProjectsUUID);

  const { activeProject, activePartition } = getURLParams();

  // const selectedProjectUUID = !isNil(projectUUID) && !isEmpty(projectUUID);

  const isPublicPartition = defaultPartition === partitionNamesConfig.partition3;
  const isPmPartition = defaultPartition === partitionNamesConfig.partition1;
    
  const {
    computeAllProjectsDevScore,
  } = useDevScore();

  const { computeProjectsPlanScore } = usePlanScore();

  const devProjArr = useSelector(getProjectsIssuesScoringData);
  const planProjectsArr = useSelector(selectProjectsKPM);

  const partitionPerms = useCheckPerm({
    entityUUID: currentParent,
    partitionType: defaultPartition,
  });

  const {
    requestConfig,
    setRequestConfig,
    loadingIndicator,
    showGeneralProjects,
    showPersonalProjects,
    selectedFilterTags,
    selectedAreasTags,
    filterByMetricParameters,
    searchTerm,
    onSearchCallback,
    onSelectAreaTagHandler,
    onSelectFilterTagHandler,
    onResetFilterHandler,
    onShowAllFilterHandler,
  } = useProjectsFilter(defaultPartition);
  // console.log('requestConfig state:', requestConfig);

  const disableKPMRef = useRef(false);

  const [newProjectFlag, changeNewProjectFlag] = useState(false);
  const [newProjectCreatedCallback, setNewProjectCreatedCallback] = useState(false);
  const [totalCountAll, setTotalCountAll] = useState(0);

  const [finalProjects, setFinalProjects] = useState([]);
  // console.log('All Projects:', finalProjects);

  const [notSavedData, setNotSavedData] = useState(defaultNotSavedData);

  const [isAssignOpen, setIsAssignOpen, modalStyle] = useActorsW54ModalStyle();
  const [isAssignAdminOpen, setIsAssignAdminOpen, modalAdminStyle] = useActorsW54ModalStyle();

  const setIsNotSavedDataCallback = (bool) => {
    setNotSavedData({
      ...notSavedData,
      notSaved: bool,
    });
  };

  const toggleNewProject = () => changeNewProjectFlag(!newProjectFlag);

  const getProjectMembers = (uuids) => {
    dispatch(getUsersByUUID({
      uuid: uuids,
      userType: 'project',
    }));
  };

  const getProjectInfo = (uuid, changePageFlag, customPartition) => {
    if (!doNotMakeProjectRequest) {
      dispatch(getProjectRequest(
        uuid,
        (data) => {
          if (data.length && changePageFlag) {
            setNewProjectCreatedCallback(true);
          }
          const users = get(data, '[0].params.users', []);

          if (activeProject !== uuid) {
            setSearchParams({ activeProject: uuid, activePartition: customPartition });
          }
          if (!isEmpty(users)) {
            getProjectMembers(getUuidsfromParamsUsers(users));
          }
        },
        customPartition,
      ));
    }
  };

  const totalCountProjects = useMemo(() => +totalCountAll, [totalCountAll]);

  // const getDocumentsOrIdeaOrBoth = async (newConfig) => {
  //   // console.log('docs config for request:', newConfig);

  //   const config = {
  //     ...defaultConfigDoc,
  //     ...newConfig,
  //   };

  //   if (parentUUID) {
  //     const data = await dispatch(getListDocumentsOrIdea(config, defaultPartition));
  //     setTotalCountDocs(prop('total', data));

  //     return prop('data', data);
  //   }
  // };

  const checkDevScore = (project) => {
    if (project?.scoringData) {
      return project?.scoringData;
    }
    if (devProjArr?.length) {
      return devProjArr.find((dataObj) => project?.uuid === dataObj?.uuid)?.scoringData;
    }
    return { issuesScoringAVG: '-' };
  };

  const checkIsReduxScoringValues = (projectsArr) => {
    // console.log('planProjectsArr', planProjectsArr);
    const finalProjectsArr = projectsArr.slice(0).map((project) => ({
      ...project,
      kpm: planProjectsArr.length ? planProjectsArr.find((dataObj) => project?.uuid === dataObj?.uuid)?.value : null,
      scoringData: checkDevScore(project),

    }));
    // console.log('finalProjectsArr', finalProjectsArr);
    return finalProjectsArr;
  };

  const getConfigAndGetProjectList = async () => {
    // dispatch(SET_KPM_COMPUTED_TIME('reset'));

    const projectRequests = [];
    const generalProjects = { data: [], total: 0 };
    const personalProjects = { data: [], total: 0 };
    const allProjects = { data: [], total: 0 };

    const fieldsConfig = {
      fields: {
        actor: 'actor',
        owner: 'owner',
        'owner.uinfo': 'owner_uinfo',
        'entity.created': 'created',
      },
      params_fields: {
        id: 'id',
        removed: 'removed',
        title: 'title',
        status: 'status',
        areas: 'areas',
        type: 'type',
        users: 'users',
        issuesDevScore: 'issuesDevScore',
        gmPlanScore: 'gmPlanScore',
      },
    };

    const commonConfig = {
      ...requestConfig,
      ...fieldsConfig,
    };

    // console.log('common config:', commonConfig);

    if (showGeneralProjects && isPmPartition) {
      const generalConfig = {
        ...commonConfig,
        partition: 'PM',
        parent: parentPM,
      };
      // console.log('general config:', generalConfig);

      const generalProjectsRequest = async (dispatch) => dispatch(
        getListOfProjects(generalConfig),
      )
        .then((res) => {
          generalProjects.data = res?.data?.map((el) => ({ ...cloneDeep(el), partition: partitionNamesConfig.partition1 })) || [];
          generalProjects.total = res?.total;
        }).catch((err) => {
          dispatch({
            type: ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_FAILURE,
            payload: [],
          });
        });

      projectRequests.push(generalProjectsRequest);
    }

    if (showPersonalProjects) {
      const personalConfig = {
        ...commonConfig,
        constants: [
          ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_REQUEST,
          ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_SUCCESS,
          ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_FAILURE,
        ],
        depth: 0,
        partition: partitionNamesConfig.partition3,
        parent: parentPublic,
      };
      // console.log('personal config:', personalConfig);

      const personalProjectsRequest = async () => dispatch(
        getListOfProjects(personalConfig),
      )
        .then((res) => {
          personalProjects.data = res?.data?.map((el) => ({ ...cloneDeep(el), partition: partitionNamesConfig.partition3 }));
          personalProjects.total = res?.total;
        })
        .catch((err) => {
          dispatch({
            type: ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_FAILURE,
            payload: [],
          });
        });

      projectRequests.push(personalProjectsRequest);
    }

    await Promise.all(projectRequests.map(
      (request) => dispatch(request),
    ));

    allProjects.data = [
      ...generalProjects.data,
      ...personalProjects.data,
    ]
      .map((project) => ({
        ...project,
        isFavorite: favoriteProjectsUUID.includes(project.uuid),
      }));

    allProjects.data = filterByMetricParameters(allProjects.data);
    // console.log('?? allProjects.data:', allProjects.data);
    allProjects.total = allProjects.data.length;
    // const finalProjectsData = checkIsReduxScoringValues(allProjects.data);
    const finalProjectsData = allProjects.data;
    // console.log('allProjects:', allProjects.data);
    // console.log('checkIsShowArchiveProjects', checkIsShowArchiveProjects);

    setFinalProjects(finalProjectsData);
    setTotalCountAll(allProjects.total);
  };

  const initFunc = async () => {
    // console.log('init !!');
    setRequestConfig(defaultConfigProjectsRequest(userUUID));
  };

  const deleteProjectCallback = () => {
    dispatch(clearProject);
    clearSearchParams(['activeProject']);
    setRequestConfig({ ...requestConfig });
  };

  const newProjectCallback = (uuid) => {
    toggleNewProject();
    getProjectInfo(uuid, true, defaultPartition);
    getConfigAndGetProjectList();
  };

  const quickViewProjectCallback = (uuid, customPartition) => {
    setSearchParams({ activeProject: uuid, activePartition: customPartition });
    getProjectInfo(uuid, false, customPartition);
  };

  // Not used now (but better save for future)
  const changeSortCallback = ({ order, orderBy, isParamSorted }) => {
    const orderConfig = {
      order,
    };

    orderConfig[isParamSorted ? 'order_by_params' : 'order_by'] = orderBy;

    setRequestConfig({
      ...requestConfig,
      ...orderConfig,
      offset: 0,
    });
  };

  const onComputeKPM = async () => {
    if (disableKPMRef.current) return;

    try {
      disableKPMRef.current = true;
      const allProjectsCopy = Array.isArray(finalProjects) ? finalProjects.slice(0) : [];
      const updatedProjectsData = await computeProjectsPlanScore(allProjectsCopy);
      setFinalProjects(updatedProjectsData);
    // eslint-disable-next-line no-empty
    } catch (err) {
    } finally {
      disableKPMRef.current = false;
    }
  };

  const onComputeKPI = async () => {
    try {
      dispatch(setFetchingScoringData(true));
      const allProjectsCopy = Array.isArray(finalProjects) ? finalProjects.slice(0) : [];
      const resp = await computeAllProjectsDevScore(allProjectsCopy);
      // console.log('onComputeKPI resp', resp);
      setFinalProjects(resp);
    } catch (e) {
    } finally {
      dispatch(setFetchingScoringData(false));
    }

    // console.log('onComputeKPI allProjectsCopy', allProjectsCopy);
  };

  const resetProjectCallback = () => {
    dispatch(clearProject);
    clearSearchParams(['activeProject', 'activePartition']);
    if (isPmPartition) routeNavigateAndClearParams('/pm/projects');
    if (isPublicPartition) routeNavigateAndClearParams(`/public/user/${myPublicUUID}/projects/`);
  };

  useEffect(() => () => {
    axiosRequest.abort('', ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_REQUEST);
    axiosRequest.abort('', ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_REQUEST);
    dispatch(clearProject);
    dispatch(clearListOfProjects);
    dispatch(clearListOfPersonalProjects);
    dispatch(clearCurrentProjectScoreData());
    // dispatch(clearAllScoringData());
    dispatch(CLEAR_ALL_PROJECT_GOALS());
    dispatch(CLEAR_ALL_PROJECT_MILESTONES());
  }, []);

  useEffect(() => {
    if (!favoriteFetching && !requestConfig && currentParent) {
      initFunc();
    }
  }, [favoriteFetching, currentParent]);

  useEffect(() => {
    if (currentParent
      && userUUID
      && parentPublic
      // && actorMainPublicProjectUUID if undefind, all projects not loading
      && requestConfig
    ) {
      getConfigAndGetProjectList();
    }
  }, [userUUID,
    requestConfig,
    currentParent,
    parentPublic,
    actorMainPublicProjectUUID,
    JSON.stringify(allAreas),
  ]);

  useEffect(() => {
    if (activePartition && activeProject) {
      getProjectInfo(activeProject, false, activePartition);
    }
    return () => dispatch({ type: ProjectTypeCommonConstants.SET_PROJECTS_FETCHING });
  }, []);

  return (
    <div className={isPublicPartition ? 'w-full' : 'w-full'}>
      <Row className={isPublicPartition ? 'hidden' : 'mb-3'}>
        <Col>
          <ProjectFlowInfoTip />
        </Col>
      </Row>
      <Row className={isPublicPartition ? 'hidden' : ''}>
        <Col span={24} className="py-1">
          <BreadCrumbsNEW />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="flex">
          <Col span={24}>
            <ListOfProjectsForProjectFlow
              perms={partitionPerms}
              hideAddProjectBtn={hideAddProjectBtn}
              newProjectCreatedCallback={newProjectCreatedCallback}
              totalCount={totalCountProjects}
              allData={finalProjects}
              partition={defaultPartition}
              partitionUUID={currentParent}
              favoriteProjectsUUID={favoriteProjectsUUID}
              initialPathForHistory={initialPathForHistory}
              loadingIndicator={loadingIndicator}
              searchTerm={searchTerm}
              selectedAreasTags={selectedAreasTags}
              selectedFilterTags={selectedFilterTags}
              changeSortCallback={changeSortCallback}
              newProjectCallback={toggleNewProject}
              changeProjectsData={setFinalProjects}
              onComputeKPM={onComputeKPM}
              onComputeKPI={onComputeKPI}
              onResetFilter={onResetFilterHandler}
              onShowAllFilter={onShowAllFilterHandler}
              onSelectFiltersCallback={onSelectFilterTagHandler}
              onSelectTagsCallback={onSelectAreaTagHandler}
              onSearchProjectCallback={onSearchCallback}
              setNewProjectCreatedCallback={setNewProjectCreatedCallback}
              quickViewProjectCallback={quickViewProjectCallback}
              locationDashboard={location}
            />
          </Col>
          {!hideAddProjectBtn && (
            <Col className="pl-3">
              {newProjectFlag ? (
                <Modal
                  id="modal_create_project_in_project_flow"
                  style={isAssignAdminOpen ? modalAdminStyle : modalStyle}
                  open={newProjectFlag}
                  footer={null}
                  destroyOnClose
                  onCancel={toggleNewProject}
                  width={850}
                  centered
                >
                  <NewProject
                    partitionType={defaultPartition}
                    customParent={currentParent}
                    newProjectCallback={newProjectCallback}
                    cancelBtnCallback={toggleNewProject}
                    isAssignOpen={isAssignOpen}
                    isAssignAdminOpen={isAssignAdminOpen}
                    setIsAssignOpen={setIsAssignOpen}
                    setIsAssignAdminOpen={setIsAssignAdminOpen}
                  />
                </Modal>
              ) : null}
            </Col>
          )}

          {activeProject && activePartition && (
          <Modal
            open
            footer={null}
            destroyOnClose
            closable={false}
            onCancel={resetProjectCallback}
            width={820}
          >
            <Spin spinning={projectFetching}>
              <Col span={24}>
                <ProjectInfo
                  partitionType={activePartition}
                  setIsNotSavedDataCallback={setIsNotSavedDataCallback}
                  notSavedData={notSavedData}
                  setNotSavedData={setNotSavedData}
                  updateProjectCallback={getConfigAndGetProjectList}
                  view="projectFlow"
                  deleteEntityCallback={deleteProjectCallback}
                  pathGoToTask={activePartition === partitionNamesConfig.partition1 ? {
                    pathname: `${initialPathForHistory}issuesnew`,
                    search: `?activeProject=${projectUUID}`,
                  } : {
                    pathname: `/public/user/${myPublicUUID}/projects/issuesnew`,
                    search: `?activeProject=${projectUUID}`,
                  }}
                  resetProjectCallback={resetProjectCallback}
                  isInModal
                />
              </Col>
            </Spin>
          </Modal>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ProjectFlowProjectPage;

ProjectFlowProjectPage.propTypes = {
  customParent: PropTypes.string,
  defaultPartition: PropTypes.string,
  doNotMakeProjectRequest: PropTypes.bool,
  hideAddProjectBtn: PropTypes.bool,
  initialPathForHistory: PropTypes.string,
  location: PropTypes.any,
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import * as XLSXStyle from 'xlsx-js-style';
import * as FileSaver from 'file-saver';

import { array, bool, string } from 'prop-types';
import moment from 'moment';
import BaseButton from '../../components/_ui/BaseButton/BaseButton';
import { capitalize } from 'lodash';
import { styleToTimeCells, styleToTitles } from './userReports/utils';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

function ExportReport({
  titleReport,
  columns,
  dataReport,
  disabled,
}) {
  const { t } = useTranslation();

  const setCellStyle = (cell, style) => {
    if (cell) {
      cell.s = style;
    }
  };

  const saveAsExcelFile = (buffer) => {
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });

    FileSaver.saveAs(data, `${titleReport}${EXCEL_EXTENSION}`);
  };

  const exportAsExcelFile = (json) => {
    const worksheet = XLSX.utils.json_to_sheet(json);
    const wsrows = [
      { hpx: 30 },
    ];

    Object.entries(worksheet).forEach((([key]) => {
      wsrows.push({ hpx: 50 });

      if (key !== '!ref') {
        const firstCol = key.includes(1) && key.length === 2;
        const style = firstCol ? styleToTitles : styleToTimeCells;

        setCellStyle(worksheet[key], style);

        // CHECK CELL ON TIME AND SET FORMAT
        if (moment(worksheet[key].v, 'HH:mm', true).isValid()) {
          worksheet[key].v = moment.duration(worksheet[key].v).asHours() / 24;
          worksheet[key].t = 'n';
          worksheet[key].z = 'HH:mm';
        }
      }
    }));

    worksheet['!cols'] = columns?.map((i) => ({ wpx: i?.width || 0 })) || { wpx: 90 };
    worksheet['!rows'] = wsrows;

    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSXStyle.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    saveAsExcelFile(excelBuffer);
  };

  const toggleExport = async () => {
    const JSON = dataReport.map((i) => (
      Object.fromEntries(
        Object.entries(i)
          .map(([key, value]) => ([columns?.find((p) => p?.name === key)?.title, value])),
      )
    ));
    exportAsExcelFile(JSON);
  };

  return (
    <BaseButton
      id='exportReportButton'
      size="small"
      className="btnPrimary-outline"
      onClick={toggleExport}
      disabled={disabled}
    >
      {capitalize(t('wms.buttons.export_report'))}
    </BaseButton>
  );
}

ExportReport.propTypes = {
  titleReport: string,
  columns: array,
  dataReport: array,
  disabled: bool,
};

export default ExportReport;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BaseButton from "../../../components/_ui/BaseButton/BaseButton";
import { capitalize } from "lodash";
import BtnModal from "../../components/BtnModalComponent";
import NewVectorComponent from "../../components/vector/NewVectorComponent";
import useActorsW54ModalStyle from "../../../actors/hooks/useActorsW54ModalStyle";
import "./WrapperAddVector.scss";

export default function WrapperAddVector({
  vectorType,
  partitionType,
  parentUUID,
  newVectorSuccessCallback,
  zIndex,
  isMenuItem,
  titleButton,
  hideBtnAddVector,
  handleCancelCallbackFunc,
  openFromOuterComponent,
  disableConfirm,
  getNewVector,
  getVectors,
  customTitle,
  customDescription,
  disabled,
}) {
  const { t } = useTranslation();
  const [currentType, setCurrentType] = useState(vectorType);

  const [isAssignOpen, setIsAssignOpen, styleNewVectorModal] =
    useActorsW54ModalStyle();

  const getButton = isMenuItem ? (
    <span className="dropDownItem">{titleButton}</span>
  ) : (
    !hideBtnAddVector && (
      <BaseButton
        id="addVectorButton"
        size="small"
        disabled={disabled}
        className="customAntOutPrimaryBtn"
      >
        {titleButton}
      </BaseButton>
    )
  );

  return (
    <BtnModal
      width={800}
      zIndex={zIndex}
      style={styleNewVectorModal}
      btnComponent={getButton}
      handleCancelCallbackFunc={handleCancelCallbackFunc}
      openFromOuterComponent={openFromOuterComponent}
      // confirmOnClose={!disableConfirm}
      confirmOnClose={false}
      confirmDescription={capitalize(
        t(
          "wms.modals.body.close_modal_new_issue",
          "the entered data will be lost, are you sure?"
        )
      )}
    >
      <NewVectorComponent
        isAssignOpen={isAssignOpen}
        setIsAssignOpen={setIsAssignOpen}
        vectorType={vectorType}
        currentTypeCallback={setCurrentType}
        customDescription={customDescription}
        customTitle={customTitle}
        partitionType={partitionType}
        hideVectorType={vectorType === "workblock"}
        parentUUID={parentUUID}
        newVectorSuccessCallback={newVectorSuccessCallback}
        getVectors={getVectors}
        getNewVector={getNewVector}
      />
    </BtnModal>
  );
}

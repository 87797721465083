import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

import { capitalize } from 'lodash';

function AddMoreRowsComponent({
  addMoreRowsCallback,
}) {
  const { t } = useTranslation();

  const [rowsCount, changeRowsCount] = useState(5);

  const changeInput = (e) => {
    const { target: { value } } = e;

    const reg = /^-?\d*(\.\d*)?$/;

    if (reg.test(value) || value === '') {
      if (Number.isNaN(value) || value < 0) {
        changeRowsCount(5);
      } else {
        changeRowsCount(+value > 20 ? 20 : +value);
      }
    }
  };

  return (
    <div className="flex">
      <Input
        size="small"
        // type="number"
        onChange={changeInput}
        style={{
          //   minHeight: 32,
          maxWidth: 50,
        }}
        value={rowsCount}
      />
      <BaseButton
        onClick={() => addMoreRowsCallback(rowsCount)}
        size="small"
        className="btnPrimary-outline ml-1"
      >
        {`${capitalize(t('wms.buttons.add.more_rows', 'add more'
          + ' rows'))} (${rowsCount})`}
      </BaseButton>
    </div>
  );
}

export default AddMoreRowsComponent;

AddMoreRowsComponent.propTypes = {
  addMoreRowsCallback: PropTypes.func,
};

// import { getNestedFilters } from '../../../../components/_ui/helpers/filterHelper';

import {
  priorityFilterOprions,
  vectorTypeFilterOptions,
  vectorStatusFilterOptions,
} from '../../../../constants/allFilterOptions';

const priorityColumnFilter = {
  filters: priorityFilterOprions,
  filterMode: 'tree',
  filterType: 'charNoCut',
};

const typeColumnFilter = {
  filters: vectorTypeFilterOptions,
  filterMode: 'tree',
  filterType: 'badge',
};

const statusColumnFilter = {
  filters: vectorStatusFilterOptions,
  filterMode: 'tree',
  filterType: 'iconWithStatus',
};

export const defaultVectorFilter = {
  priority: undefined,
  type: undefined,
  status: ['created', 'in progress', 'active'],
};
// console.log('defaultVectorFilter:', defaultVectorFilter);

export const columnsFilterConfig = {
  priority: priorityColumnFilter,
  type: typeColumnFilter,
  status: statusColumnFilter,
};

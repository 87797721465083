import keyMirror from 'keymirror';

export const ProjectTypeCommonConstants = keyMirror({

  DELETE_ENTITY_REQUEST: null,
  DELETE_ENTITY_SUCCESS: null,
  DELETE_ENTITY_FAILURE: null,

  CLEAR_OF_DOC_COPY: null,
  CLEAR_DOCUMENT: null,
  GET_DOCUMENT_COPY_REQUEST: null,
  GET_DOCUMENT_COPY_SUCCESS: null,
  GET_DOCUMENT_COPY_FAILURE: null,

  CLEAR_PROJECT_DOCUMENTS: null,
  FETCH_PROJECT_DOCUMENTS_REQUEST: null,
  FETCH_PROJECT_DOCUMENTS_SUCCESS: null,
  FETCH_PROJECT_DOCUMENTS_FAILURE: null,

  FETCH_DOCUMENTS_AND_IDEA_REQUEST: null,
  FETCH_DOCUMENTS_AND_IDEA_SUCCESS: null,
  FETCH_DOCUMENTS_AND_IDEA_FAILURE: null,
  FETCH_AND_PUSH_DOCUMENTS_AND_IDEA_SUCCESS: null,

  NEW_PROJECT_DOCUMENT_REQUEST: null,
  NEW_PROJECT_DOCUMENT_SUCCESS: null,
  NEW_PROJECT_DOCUMENT_FAILURE: null,

  UPDATE_DOCUMENT_REQUEST: null,
  UPDATE_DOCUMENT_SUCCESS: null,
  UPDATE_DOCUMENT_FAILURE: null,

  UPDATE_DOCUMENT_COMMENTS_COUNT_REQUEST: null,
  UPDATE_DOCUMENT_COMMENTS_COUNT_SUCCESS: null,
  UPDATE_DOCUMENT_COMMENTS_COUNT_FAILURE: null,

  UPDATE_DOCUMENT_ACTUAL_STATE_REQUEST: null,
  UPDATE_DOCUMENT_ACTUAL_STATE_SUCCESS: null,
  UPDATE_DOCUMENT_ACTUAL_STATE_FAILURE: null,

  GET_DOCUMENT_REQUEST: null,
  GET_DOCUMENT_SUCCESS: null,
  GET_DOCUMENT_FAILURE: null,
  SET_DOCUMENT_CONTENT: null,

  GET_DOCUMENT_MANAGEMENT_IMAGE_REQUEST: null,
  GET_DOCUMENT_MANAGEMENT_IMAGE_SUCCESS: null,
  GET_DOCUMENT_MANAGEMENT_IMAGE_FAILURE: null,

  GET_ALL_DOCUMENT_STATES_REQUEST: null,
  GET_ALL_DOCUMENT_STATES_SUCCESS: null,
  GET_ALL_DOCUMENT_STATES_FAILURE: null,

  CHECK_DOCUMENT_ACTUAL_STATE_REQUEST: null,
  CHECK_DOCUMENT_ACTUAL_STATE_SUCCESS: null,
  CHECK_DOCUMENT_ACTUAL_STATE_FAILURE: null,

  GET_DOCUMENT_STATE_REQUEST: null,
  GET_DOCUMENT_STATE_SUCCESS: null,
  GET_DOCUMENT_STATE_FAILURE: null,

  EDIT_DOCUMENT_REQUEST: null,
  EDIT_DOCUMENT_SUCCESS: null,
  EDIT_DOCUMENT_FAILURE: null,

  UPDATE_DOCUMENT_STATE_REQUEST: null,
  UPDATE_DOCUMENT_STATE_SUCCESS: null,
  UPDATE_DOCUMENT_STATE_FAILURE: null,

  GET_DOCUMENT_STATE_LATEST_REQUEST: null,
  GET_DOCUMENT_STATE_LATEST_SUCCESS: null,
  GET_DOCUMENT_STATE_LATEST_FAILURE: null,

  UPDATE_DOCUMENT_STATE_FULLREWRITE_REQUEST: null,
  UPDATE_DOCUMENT_STATE_FULLREWRITE_SUCCESS: null,
  UPDATE_DOCUMENT_STATE_FULLREWRITE_FAILURE: null,

  GET_DOCUMENT_PATCH_REQUEST: null,
  GET_DOCUMENT_PATCH_SUCCESS: null,
  GET_DOCUMENT_PATCH_FAILURE: null,

  UPDATE_DOCUMENT_PATCH_REQUEST: null,
  UPDATE_DOCUMENT_PATCH_SUCCESS: null,
  UPDATE_DOCUMENT_PATCH_FAILURE: null,

  NEW_PROJECT_DOC_COPY_REQUEST: null,
  NEW_PROJECT_DOC_COPY_SUCCESS: null,
  NEW_PROJECT_DOC_COPY_FAILURE: null,

  CLEAR_PROJECT: null,

  CLEAR_HISTORY_OF_DOCUMENT: null,
  FETCH_HISTORY_OF_DOCUMENT_REQUEST: null,
  FETCH_HISTORY_OF_DOCUMENT_SUCCESS: null,
  FETCH_HISTORY_OF_DOCUMENT_FAILURE: null,

  SET_PROJECTS_FETCHING: null,

  FETCH_LIST_OF_PROJECT_REQUEST: null,
  FETCH_LIST_OF_PROJECT_SUCCESS: null,
  FETCH_LIST_OF_PROJECT_FAILURE: null,

  GET_LIST_OF_PERSONAL_PROJECTS_REQUEST: null,
  GET_LIST_OF_PERSONAL_PROJECTS_SUCCESS: null,
  GET_LIST_OF_PERSONAL_PROJECTS_FAILURE: null,

  GET_LIST_OF_PROJECT_UUIDS_REQUEST: null,
  GET_LIST_OF_PROJECT_UUIDS_SUCCESS: null,
  GET_LIST_OF_PROJECT_UUIDS_FAILURE: null,

  LIST_OF_PROJECT_IN_QUICK_ISSUE_MODAL_REQUEST: null,
  LIST_OF_PROJECT_IN_QUICK_ISSUE_MODAL_SUCCESS: null,
  LIST_OF_PROJECT_IN_QUICK_ISSUE_MODAL_FAILURE: null,

  CLEAR_LIST_OF_PROJECT_FROM_QUICK_ISSUE_MODAL: null,
  CLEAR_LIST_OF_PROJECTS: null,
  CLEAR_LIST_OF_PERSONAL_PROJECTS: null,

  PROJECT_REQUEST: null,
  PROJECT_SUCCESS: null,
  PROJECT_FAILURE: null,

  NEW_PROJECT_REQUEST: null,
  NEW_PROJECT_SUCCESS: null,
  NEW_PROJECT_FAILURE: null,

  EDIT_PROJECT_REQUEST: null,
  EDIT_PROJECT_SUCCESS: null,
  EDIT_PROJECT_FAILURE: null,

  UPDATE_PROJECT_SCORE: null,

  UPDATE_PROJECT_WITHOUT_LOADING_REQUEST: null,
  UPDATE_PROJECT_WITHOUT_LOADING_SUCCESS: null,
  UPDATE_PROJECT_WITHOUT_LOADING_FAILURE: null,

  IMPORT_PROJECT_REQUEST: null,
  IMPORT_PROJECT_SUCCESS: null,
  IMPORT_PROJECT_FAILURE: null,

  ONE_MAIN_PUBLIC_ACTOR_REQUEST: null,
  ONE_MAIN_PUBLIC_ACTOR_SUCCESS: null,
  ONE_MAIN_PUBLIC_ACTOR_FAILURE: null,

  NEW_FILE_REQUEST: null,
  NEW_FILE_SUCCESS: null,
  NEW_FILE_FAILURE: null,

  DELETE_FILE_REQUEST: null,
  DELETE_FILE_SUCCESS: null,
  DELETE_FILE_FAILURE: null,

  CLEAR_ISSUE: null,

  CLEAR_RECURRING_ISSUE: null,

  CLEAR_QUICK_ISSUE: null,

  LIST_OF_QUICK_ISSUES_REQUEST: null,
  LIST_OF_QUICK_ISSUES_SUCCESS: null,
  LIST_OF_QUICK_ISSUES_FAILURE: null,

  NEW_QUICK_ISSUE_REQUEST: null,
  NEW_QUICK_ISSUE_SUCCESS: null,
  NEW_QUICK_ISSUE_FAILURE: null,

  CLEAR_LIST_OF_QUICK_ISSUES: null,

  LIST_OF_ISSUES_REQUEST: null,
  LIST_OF_ISSUES_SUCCESS: null,
  LIST_OF_ISSUES_FAILURE: null,

  LIST_OF_RECURRING_ISSUES_REQUEST: null,
  LIST_OF_RECURRING_ISSUES_SUCCESS: null,
  LIST_OF_RECURRING_ISSUES_FAILURE: null,

  GET_ALL_PROJECT_ISSUES_REQUEST: null,
  GET_ALL_PROJECT_ISSUES_SUCCESS: null,
  GET_ALL_PROJECT_ISSUES_FAILURE: null,

  GET_CURRENT_PROJECT_ISSUES_SCORING: null,
  UPDATE_CURRENT_PROJECT_ISSUES_SCORING: null,

  UPDATE_ISSUES_PRIORITY_INDEXES_DATA: null,
  UPDATE_VECTORS_PRIORITY_INDEXES_DATA: null,
  UPDATE_ISSUES_OUTDATED_INDEXES_DATA: null,

  CLEAR_ISSUES_SCORING_DATA: null,
  CLEAR_VECTORS_SCORING_DATA: null,

  SET_UUIDS_FOR_MULTI_SELECT: null,

  SET_FLAG_FOR_MULTI_SELECT: null,

  GET_LIST_OF_ISSUES_REQUEST: null,
  GET_LIST_OF_ISSUES_SUCCESS: null,
  GET_LIST_OF_ISSUES_FAILURE: null,

  GET_LIST_OF_RECURRING_ISSUES_REQUEST: null,
  GET_LIST_OF_RECURRING_ISSUES_SUCCESS: null,
  GET_LIST_OF_RECURRING_ISSUES_FAILURE: null,

  UPDATE_FILES_FOR_ISSUE_REQUEST: null,
  UPDATE_FILES_FOR_ISSUE_SUCCESS: null,
  UPDATE_FILES_FOR_ISSUE_FAILURE: null,
  
  UPDATE_FILES_FOR_RECURRING_ISSUE_REQUEST: null,
  UPDATE_FILES_FOR_RECURRING_ISSUE_SUCCESS: null,
  UPDATE_FILES_FOR_RECURRING_ISSUE_FAILURE: null,

  FETCHING_LIST_OF_ISSUES: null,

  CLEAR_UNIVERSAL_STORAGE_ISSUE: null,

  ISSUE_ENTITY_REQUEST: null,
  ISSUE_ENTITY_SUCCESS: null,
  ISSUE_ENTITY_FAILURE: null,

  RECURRING_ISSUE_ENTITY_REQUEST: null,
  RECURRING_ISSUE_ENTITY_SUCCESS: null,
  RECURRING_ISSUE_ENTITY_FAILURE: null,

  NEW_ISSUE_REQUEST: null,
  NEW_ISSUE_SUCCESS: null,
  NEW_ISSUE_FAILURE: null,

  NEW_RECURRING_ISSUE_REQUEST: null,
  NEW_RECURRING_ISSUE_SUCCESS: null,
  NEW_RECURRING_ISSUE_FAILURE: null,

  EDIT_ISSUE_REQUEST: null,
  EDIT_ISSUE_SUCCESS: null,
  EDIT_ISSUE_FAILURE: null,

  EDIT_RECURRING_ISSUE_REQUEST: null,
  EDIT_RECURRING_ISSUE_SUCCESS: null,
  EDIT_RECURRING_ISSUE_FAILURE: null,

  CLEAR_LIST_OF_ISSUES: null,

  CLEAR_LIST_OF_R_ISSUES: null,

  UPDATE_DATA_ON_CASE_IN_ISSUES_REQUEST: null,
  UPDATE_DATA_ON_CASE_IN_ISSUES_SUCCESS: null,
  UPDATE_DATA_ON_CASE_IN_ISSUES_FAILURE: null,

  ISSUE_COMMENTS_REQUEST: null,
  ISSUE_COMMENTS_SUCCESS: null,
  ISSUE_COMMENTS_FAILURE: null,

  NEED_UPDATE_LIST_COMMENT: null,
  UPDATE_TOTAL_COMMENTS_STORE: null,
  UPDATE_USER_COMMENTS_STORE: null,
  UPDATE_SYSTEM_COMMENTS_STORE: null,

  GET_COMMENTS_COUNT_REQUEST: null,
  GET_COMMENTS_COUNT_SUCCESS: null,
  GET_COMMENTS_COUNT_FAILURE: null,

  UPDATE_COMMENTS_COUNT_REQUEST: null,
  UPDATE_COMMENTS_COUNT_SUCCESS: null,
  UPDATE_COMMENTS_COUNT_FAILURE: null,

  NEW_COMMENT_REQUEST: null,
  NEW_COMMENT_SUCCESS: null,
  NEW_COMMENT_FAILURE: null,

  EDIT_COMMENT_REQUEST: null,
  EDIT_COMMENT_SUCCESS: null,
  EDIT_COMMENT_FAILURE: null,

  DEL_COMMENT_REQUEST: null,
  DEL_COMMENT_SUCCESS: null,
  DEL_COMMENT_FAILURE: null,

  ISSUE_COMMENTS_REVERSE: null,
  CLEAR_ALL_COMMENTS: null,

  CREATE_TIME_LOG_REQUEST: null,
  CREATE_TIME_LOG_SUCCESS: null,
  CREATE_TIME_LOG_FAILURE: null,

  LIST_TIME_LOG_REQUEST: null,
  LIST_TIME_LOG_SUCCESS: null,
  LIST_TIME_LOG_FAILURE: null,

  CLEAR_LIST_TIME_LOG: null,

  DELETE_TIMELOG_REQUEST: null,
  DELETE_TIMELOG_SUCCESS: null,
  DELETE_TIMELOG_FAILURE: null,
  CLEAR_OF_TIMELOGS: null,

  DELETE_BOOKMARK_REQUEST: null,
  DELETE_BOOKMARK_SUCCESS: null,
  DELETE_BOOKMARK_FAILURE: null,

  CREATE_BOOKMARK_REQUEST: null,
  CREATE_BOOKMARK_SUCCESS: null,
  CREATE_BOOKMARK_FAILURE: null,

  CHECK_BOOKMARK_REQUEST: null,
  CHECK_BOOKMARK_SUCCESS: null,
  CHECK_BOOKMARK_FAILURE: null,

  PM_VERIFY_REQUEST: null,
  PM_VERIFY_SUCCESS: null,
  PM_VERIFY_FAILURE: null,

  PM_PERMS_UPD_REQUEST: null,
  PM_PERMS_UPD_SUCCESS: null,
  PM_PERMS_UPD_FAILURE: null,

  PM_QR_TOKEN_REQUEST: null,
  PM_QR_TOKEN_SUCCESS: null,
  PM_QR_TOKEN_FAILURE: null,

  PM_QR_VERIFY_REQUEST: null,
  PM_QR_VERIFY_SUCCESS: null,
  PM_QR_VERIFY_FAILURE: null,

  NEW_VECTOR_REQUEST: null,
  NEW_VECTOR_SUCCESS: null,
  NEW_VECTOR_FAILURE: null,

  CLEAR_VECTOR: null,

  EDIT_VECTOR_REQUEST: null,
  EDIT_VECTOR_SUCCESS: null,
  EDIT_VECTOR_FAILURE: null,

  GET_ALL_PROJECT_VECTORS_REQUEST: null,
  GET_ALL_PROJECT_VECTORS_SUCCESS: null,
  GET_ALL_PROJECT_VECTORS_FAILURE: null,

  GET_PROJECT_VECTORS_REQUEST: null,
  GET_PROJECT_VECTORS_SUCCESS: null,
  GET_PROJECT_VECTORS_FAILURE: null,

  SET_UUIDS_FOR_MULTI_EDIT_VECTORS: null,
  SET_FLAG_FOR_MULTI_EDIT_VECTORS: null,

  GET_PROJECT_RELEASES_REQUEST: null,
  GET_PROJECT_RELEASES_SUCCESS: null,
  GET_PROJECT_RELEASES_FAILURE: null,

  GET_TEMP_PROJECT_VECTORS_REQUEST: null,
  GET_TEMP_PROJECT_VECTORS_SUCCESS: null,
  GET_TEMP_PROJECT_VECTORS_FAILURE: null,

  GET_VECTOR_REQUEST: null,
  GET_VECTOR_SUCCESS: null,
  GET_VECTOR_FAILURE: null,

  CLEAR_LIST_OF_VECTORS: null,

  NEW_PROJECT_IDEA_REQUEST: null,
  NEW_PROJECT_IDEA_SUCCESS: null,
  NEW_PROJECT_IDEA_FAILURE: null,

  CLEAR_IDEA: null,

  GET_ALL_ENTITIES_AUDIT_REQUEST: null,
  GET_ALL_ENTITIES_AUDIT_SUCCESS: null,
  GET_ALL_ENTITIES_AUDIT_FAILURE: null,

  CLEAR_LIST_ENTITIES: null,

  GET_ALL_AFFIX_AUDIT_REQUEST: null,
  GET_ALL_AFFIX_AUDIT_SUCCESS: null,
  GET_ALL_AFFIX_AUDIT_FAILURE: null,

  CLEAR_LIST_AFFIX: null,

  LIST_OF_PROJECT_IDEAS_REQUEST: null,
  LIST_OF_PROJECT_IDEAS_SUCCESS: null,
  LIST_OF_PROJECT_IDEAS_FAILURE: null,

  CLEAR_LIST_OF_IDEAS: null,

  GET_PROJECT_IDEA_REQUEST: null,
  GET_PROJECT_IDEA_SUCCESS: null,
  GET_PROJECT_IDEA_FAILURE: null,

  EDIT_PROJECT_IDEA_REQUEST: null,
  EDIT_PROJECT_IDEA_SUCCESS: null,
  EDIT_PROJECT_IDEA_FAILURE: null,

  NEW_AREA_REQUEST: null,
  NEW_AREA_SUCCESS: null,
  NEW_AREA_FAILURE: null,

  GET_MAIN_AREA_REQUEST: null,
  GET_MAIN_AREA_SUCCESS: null,
  GET_MAIN_AREA_FAILURE: null,

  GET_AREAS_REQUEST: null,
  GET_AREAS_SUCCESS: null,
  GET_AREAS_FAILURE: null,

  UPDATE_AREA_REQUEST: null,
  UPDATE_AREA_SUCCESS: null,
  UPDATE_AREA_FAILURE: null,

  FETCH_LIST_OF_PROFILE_TICKETS_REQUEST: null,
  FETCH_LIST_OF_PROFILE_TICKETS_SUCCESS: null,
  FETCH_LIST_OF_PROFILE_TICKETS_FAILURE: null,

  LIST_OF_SHARED_TICKETS_REQUEST: null,
  LIST_OF_SHARED_TICKETS_SUCCESS: null,
  LIST_OF_SHARED_TICKETS_FAILURE: null,
  // NOTIFICATIONS

  CREATE_NOTIFICATION_REQUEST: null,
  CREATE_NOTIFICATION_SUCCESS: null,
  CREATE_NOTIFICATION_FAILURE: null,

  ADD_UNSEEN_NOTIFICATIONS: null,

  GET_ALL_VIEWED_NOTIFICATIONS_REQUEST: null,
  GET_ALL_VIEWED_NOTIFICATIONS_SUCCESS: null,
  GET_ALL_VIEWED_NOTIFICATIONS_FAILURE: null,

  RESET_ALL_VIEWED_NOTIFICATIONS: null,

  MARK_AS_VIEWED_NOTIFICATION: null,
  MARK_AS_VIEWED_ALL_NOTIFICATIONS: null,

  SET_SENDER_FILTER: null,

  GET_MENTIONED_PROJECTS_REQUEST: null,
  GET_MENTIONED_PROJECTS_SUCCESS: null,
  GET_MENTIONED_PROJECTS_FAILURE: null,

  GET_SENDERS_REQUEST: null,
  GET_SENDERS_SUCCESS: null,
  GET_SENDERS_FAILURE: null,

  // QA

  GET_OR_CREATE_QA_ENTITY_REQUEST: null,
  GET_OR_CREATE_QA_ENTITY_SUCCESS: null,
  GET_OR_CREATE_QA_ENTITY_FAILURE: null,

  UPDATE_QA_ENTITY_REQUEST: null,
  UPDATE_QA_ENTITY_SUCCESS: null,
  UPDATE_QA_ENTITY_FAILURE: null,

  NEW_TEST_SUITE_REQUEST: null,
  NEW_TEST_SUITE_SUCCESS: null,
  NEW_TEST_SUITE_FAILURE: null,

  EDIT_TEST_SUITE_REQUEST: null,
  EDIT_TEST_SUITE_SUCCESS: null,
  EDIT_TEST_SUITE_FAILURE: null,

  REMOVE_TEST_SUITE_REQUEST: null,
  REMOVE_TEST_SUITE_SUCCESS: null,
  REMOVE_TEST_SUITE_FAILURE: null,

  UPDATE_TEST_SUITE_IN_STORE: null,
  UPDATE_LIST_TEST_SUITE_IN_STORE: null,

  LIST_OF_TEST_SUITES_REQUEST: null,
  LIST_OF_TEST_SUITES_SUCCESS: null,
  LIST_OF_TEST_SUITES_FAILURE: null,

  NEW_TEST_CASE_IN_SUITE_REQUEST: null,
  NEW_TEST_CASE_IN_SUITE_SUCCESS: null,
  NEW_TEST_CASE_IN_SUITE_FAILURE: null,

  NEW_TEST_CASE_IN_CYCLE_REQUEST: null,
  NEW_TEST_CASE_IN_CYCLE_SUCCESS: null,
  NEW_TEST_CASE_IN_CYCLE_FAILURE: null,

  CLEAR_TEST_CASE: null,

  GET_TEST_CASE_REQUEST: null,
  GET_TEST_CASE_SUCCESS: null,
  GET_TEST_CASE_FAILURE: null,

  CLEAR_SINGLE_CASE: null,
  CLEAR_ONE_TEST_CASE_IN_SUITE_IN_STORE: null,
  CLEAR_ONE_TEST_CASE_IN_CYCLE_IN_STORE: null,

  UPDATE_ONE_TEST_CASE_IN_STORE: null,
  UPDATE_ONE_TEST_CASE_IN_SUITE_IN_STORE: null,
  UPDATE_ONE_TEST_CASE_IN_CYCLE_IN_STORE: null,

  EDIT_TEST_CASE_REQUEST: null,
  EDIT_TEST_CASE_SUCCESS: null,
  EDIT_TEST_CASE_FAILURE: null,

  EDIT_TEST_CASE_DATA_GRID_REQUEST: null,
  EDIT_TEST_CASE_DATA_GRID_SUCCESS: null,
  EDIT_TEST_CASE_DATA_GRID_FAILURE: null,

  EDIT_TEST_CASE_IN_SUITE_REQUEST: null,
  EDIT_TEST_CASE_IN_SUITE_SUCCESS: null,
  EDIT_TEST_CASE_IN_SUITE_FAILURE: null,

  LIST_OF_TEST_CASES_IN_SUITE_REQUEST: null,
  LIST_OF_TEST_CASES_IN_SUITE_SUCCESS: null,
  LIST_OF_TEST_CASES_IN_SUITE_FAILURE: null,

  CLEAR_LIST_OF_TEST_CASES_IN_SUITE: null,

  UPDATE_LIST_TEST_CASE_REQUEST: null,
  UPDATE_LIST_TEST_CASE_SUCCESS: null,
  UPDATE_LIST_TEST_CASE_FAILURE: null,

  UPDATE_FILTER_LIST_TEST_CASE: null,
  UPDATE_FILTER_LIST_TEST_CASE_IN_ARCHIVE: null,

  UPDATE_LIST_OF_TEST_CASES_IN_SUITE: null,
  ADD_TEST_CASES_OF_LIST_IN_SUITE: null,

  LIST_OF_TEST_CASES_IN_CYCLE_REQUEST: null,
  LIST_OF_TEST_CASES_IN_CYCLE_SUCCESS: null,
  LIST_OF_TEST_CASES_IN_CYCLE_FAILURE: null,

  CLEAR_LIST_OF_TEST_CASES_IN_CYCLE: null,

  UPDATE_LIST_OF_TEST_CASES_IN_CYCLE: null,

  LIST_OF_ALL_TEST_CASES_REQUEST: null,
  LIST_OF_ALL_TEST_CASES_SUCCESS: null,
  LIST_OF_ALL_TEST_CASES_FAILURE: null,

  CLEAR_LIST_OF_ALL_TEST_CASES: null,

  NEW_TEST_CYCLE_REQUEST: null,
  NEW_TEST_CYCLE_SUCCESS: null,
  NEW_TEST_CYCLE_FAILURE: null,

  EDIT_TEST_CYCLE_REQUEST: null,
  EDIT_TEST_CYCLE_SUCCESS: null,
  EDIT_TEST_CYCLE_FAILURE: null,

  EDIT_CHECKLIST_REQUEST: null,
  EDIT_CHECKLIST_SUCCESS: null,
  EDIT_CHECKLIST_FAILURE: null,

  LIST_OF_TEST_CYCLES_REQUEST: null,
  LIST_OF_TEST_CYCLES_SUCCESS: null,
  LIST_OF_TEST_CYCLES_FAILURE: null,

  ALL_LIST_OF_TEST_CYCLES_REQUEST: null,
  ALL_LIST_OF_TEST_CYCLES_SUCCESS: null,
  ALL_LIST_OF_TEST_CYCLES_FAILURE: null,

  CLEAR_ALL_LIST_OF_TEST_CYCLES: null,

  CLEAR_LIST_CYCLE_IN_STORE: null,

  CLEAR_TEST_CYCLE: null,

  GET_TEST_CYCLE_REQUEST: null,
  GET_TEST_CYCLE_SUCCESS: null,
  GET_TEST_CYCLE_FAILURE: null,

  UPDATE_TEST_CYCLE_IN_STORE: null,

  UPDATE_CHECKLIST_IN_STORE: null,

  LIST_OF_TEST_CYCLES_IN_ARCHIVE_REQUEST: null,
  LIST_OF_TEST_CYCLES_IN_ARCHIVE_SUCCESS: null,
  LIST_OF_TEST_CYCLES_IN_ARCHIVE_FAILURE: null,

  CLEAR_LIST_OF_TEST_CASES_IN_ARCHIVE_IN_CYCLE: null,

  CLEAR_ARCHIVED_TEST_CYCLE: null,

  GET_TEST_CYCLE_IN_ARCHIVE_REQUEST: null,
  GET_TEST_CYCLE_IN_ARCHIVE_SUCCESS: null,
  GET_TEST_CYCLE_IN_ARCHIVE_FAILURE: null,

  LIST_OF_TEST_CASES_IN_CYCLE_FROM_ARCHIVE_REQUEST: null,
  LIST_OF_TEST_CASES_IN_CYCLE_FROM_ARCHIVE_SUCCESS: null,
  LIST_OF_TEST_CASES_IN_CYCLE_FROM_ARCHIVE_FAILURE: null,

  GET_TEST_CASE_IN_CYCLE_FROM_ARCHIVE_REQUEST: null,
  GET_TEST_CASE_IN_CYCLE_FROM_ARCHIVE_SUCCESS: null,
  GET_TEST_CASE_IN_CYCLE_FROM_ARCHIVE_FAILURE: null,

  UPDATE_TEST_CYCLE_ARCHIVE_IN_STORE: null,

  CLEAR_TEST_SUITE: null,

  GET_TEST_SUITE_REQUEST: null,
  GET_TEST_SUITE_SUCCESS: null,
  GET_TEST_SUITE_FAILURE: null,

  GET_ACTORS_NAME_REQUEST: null,
  GET_ACTORS_NAME_SUCCESS: null,
  GET_ACTORS_NAME_FAILURE: null,

  FETCH_LIST_OF_PUBLIC_TICKETS_REQUEST: null,
  FETCH_LIST_OF_PUBLIC_TICKETS_SUCCESS: null,
  FETCH_LIST_OF_PUBLIC_TICKETS_FAILURE: null,
  CLEAR_LIST_OF_PUBLIC_TICKETS: null,

  FETCH_LIST_OF_PRIVATE_TICKETS_REQUEST: null,
  FETCH_LIST_OF_PRIVATE_TICKETS_SUCCESS: null,
  FETCH_LIST_OF_PRIVATE_TICKETS_FAILURE: null,
  CLEAR_LIST_OF_PRIVATE_TICKETS: null,

  CLEAR_AUDIT_DATA: null,

  GET_LIST_OF_EXPIRED_DATA_REQUEST: null,
  GET_LIST_OF_EXPIRED_DATA_SUCCESS: null,
  GET_LIST_OF_EXPIRED_DATA_FAILURE: null,

  NEW_LIST_NOTIFICATIONS_REQUEST: null,
  NEW_LIST_NOTIFICATIONS_SUCCESS: null,
  NEW_LIST_NOTIFICATIONS_FAILURE: null,

  GET_MAIN_ARCHIVE_ENTITY_REQUEST: null,
  GET_MAIN_ARCHIVE_ENTITY_SUCCESS: null,
  GET_MAIN_ARCHIVE_ENTITY_FAILURE: null,

  ALL_DATA_FOR_ARCHIVE_ENTITY_REQUEST: null,
  ALL_DATA_FOR_ARCHIVE_ENTITY_SUCCESS: null,
  ALL_DATA_FOR_ARCHIVE_ENTITY_FAILURE: null,

  UPDATE_PARENT_FOR_ARCHIVE_ENTITY_REQUEST: null,
  UPDATE_PARENT_FOR_ARCHIVE_ENTITY_SUCCESS: null,
  UPDATE_PARENT_FOR_ARCHIVE_ENTITY_FAILURE: null,

  CREATE_ARCHIVE_ENTITY_REQUEST: null,
  CREATE_ARCHIVE_ENTITY_SUCCESS: null,
  CREATE_ARCHIVE_ENTITY_FAILURE: null,

  LIST_OF_ARCHIVE_ENTITY_REQUEST: null,
  LIST_OF_ARCHIVE_ENTITY_SUCCESS: null,
  LIST_OF_ARCHIVE_ENTITY_FAILURE: null,

  GET_ARCHIVE_ENTITY_REQUEST: null,
  GET_ARCHIVE_ENTITY_SUCCESS: null,
  GET_ARCHIVE_ENTITY_FAILURE: null,
  CLEAR_ARCHIVE_ENTITY: null,

  ALL_DATA_FROM_ARCHIVE_ENTITY_REQUEST: null,
  ALL_DATA_FROM_ARCHIVE_ENTITY_SUCCESS: null,
  ALL_DATA_FROM_ARCHIVE_ENTITY_FAILURE: null,

  SAVE_UUID_FOR_COMMENT: null,

  CREATE_TEST_STEPS_FILE_REQUEST: null,
  CREATE_TEST_STEPS_FILE_SUCCESS: null,
  CREATE_TEST_STEPS_FILE_FAILURE: null,

  GET_TEST_STEPS_FILE_IN_SUITE_REQUEST: null,
  GET_TEST_STEPS_FILE_IN_SUITE_SUCCESS: null,
  GET_TEST_STEPS_FILE_IN_SUITE_FAILURE: null,

  UPDATE_TEST_STEPS_FILE_REQUEST: null,
  UPDATE_TEST_STEPS_FILE_SUCCESS: null,
  UPDATE_TEST_STEPS_FILE_FAILURE: null,

  GET_PARENT_ENTITIES_REQUEST: null,
  GET_PARENT_ENTITIES_SUCCESS: null,
  GET_PARENT_ENTITIES_FAILURE: null,

  GET_COOK_BOOK_ENTITY_REQUEST: null,
  GET_COOK_BOOK_ENTITY_SUCCESS: null,
  GET_COOK_BOOK_ENTITY_FAILURE: null,

  GET_COOK_BOOK_ENTITY_PUBLIC_REQUEST: null,
  GET_COOK_BOOK_ENTITY_PUBLIC_SUCCESS: null,
  GET_COOK_BOOK_ENTITY_PUBLIC_FAILURE: null,

  GET_COOK_BOOK_ENTITY_PUBLIC_USER_REQUEST: null,
  GET_COOK_BOOK_ENTITY_PUBLIC_USER_SUCCESS: null,
  GET_COOK_BOOK_ENTITY_PUBLIC_USER_FAILURE: null,

  CREATE_COOK_BOOK_ENTITY_PUBLIC_REQUEST: null,
  CREATE_COOK_BOOK_ENTITY_PUBLIC_SUCCESS: null,
  CREATE_COOK_BOOK_ENTITY_PUBLIC_FAILURE: null,

  GET_LIST_COOK_BOOK_REQUEST: null,
  GET_LIST_COOK_BOOK_SUCCESS: null,
  GET_LIST_COOK_BOOK_FAILURE: null,

  GET_LIST_COOK_BOOK_PUBLIC_REQUEST: null,
  GET_LIST_COOK_BOOK_PUBLIC_SUCCESS: null,
  GET_LIST_COOK_BOOK_PUBLIC_FAILURE: null,

  CREATE_TEMPLATE_BOOK_REQUEST: null,
  CREATE_TEMPLATE_BOOK_SUCCESS: null,
  CREATE_TEMPLATE_BOOK_FAILURE: null,

  CLEAR_COOK_BOOK_STORE: null,

  SET_CUSTOM_TEMPLATE_COOK_BOOK: null,

  LIST_CHILDREN_REQUEST: null,
  LIST_CHILDREN_SUCCESS: null,
  LIST_CHILDREN_FAILURE: null,
  FETCHING_CHILDREN: null,

  CLEAR_LIST_CHILDREN: null,

  NEW_CHECKLIST_REQUEST: null,
  NEW_CHECKLIST_SUCCESS: null,
  NEW_CHECKLIST_FAILURE: null,

  LIST_OF_CHECKLIST_REQUEST: null,
  LIST_OF_CHECKLIST_SUCCESS: null,
  LIST_OF_CHECKLIST_FAILURE: null,

  GET_CHECKLIST_REQUEST: null,
  GET_CHECKLIST_SUCCESS: null,
  GET_CHECKLIST_FAILURE: null,

  // QA REPORTS
  GET_ALL_REPORTS_QA_REQUEST: null,
  GET_ALL_REPORTS_QA_SUCCESS: null,
  GET_ALL_REPORTS_QA_FAILURE: null,
});

import { pathOr, prop } from 'ramda';

export const maxLengthInput = 1000;

export const preparedComments = (data) => {
  const newList = data.map((item) => ({
    ...item,
    partial: pathOr(false, ['params', 'partial'], item),
    system: pathOr(false, ['params', 'system'], item),
    username: item.uinfo && (`${prop('first_name', item.uinfo)} ${prop('last_name', item.uinfo)}`),
  }));

  return newList;
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiMenuRight } from '@mdi/js';
import { Col } from 'antd';
import CollapseComponent from '../../CollapseComponent/CollapseComponent';

function InformationCard({
  textCollapse,
  children,
  defaultFlagCollapse = false,
}) {
  const [collapse, setCollapse] = useState(false);

  const styleWrapperIcon = {
    overflowY: 'hidden',
    transition: '0.7s',
    transform: collapse && 'rotate(-90deg)',
  };

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  useEffect(() => {
    setCollapse(defaultFlagCollapse);
  }, [defaultFlagCollapse]);
  return (
    <Col span={24}>
      <CollapseComponent
        hideButton
        isCollapsed={collapse}
        headerComponent={(
          <div onClick={toggleCollapse} className="flex mt-1 mb-3">
            <div className="flex items-center cursor-pointer">
              {textCollapse}
              <div
                style={styleWrapperIcon}
              >
                <Icon
                  path={mdiMenuRight}
                  size={1.2}
                />
              </div>
            </div>
            <div style={{ flex: '1' }}>
              <hr />
            </div>
          </div>
      )}
      >
        {collapse ? children : null}
      </CollapseComponent>
    </Col>
  );
}
InformationCard.propTypes = {
  textCollapse: PropTypes.string,
  defaultFlagCollapse: PropTypes.bool,
  children: PropTypes.element,
};

export default InformationCard;

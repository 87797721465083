// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2024.03.27 //Format YYYY|MM|DD

import React, { useState } from 'react';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { mdiCheckBold, mdiContentCopy } from '@mdi/js';
import { Button } from 'antd';
import copy from 'copy-to-clipboard';
import { UI54Button, UI54Icon } from "@agpl/ui54kit";

function CopyToClipboardBtn54origins({
  btnClassName = '',
  btnType = 'link',
  copySuccessCallback,
  defaultLabel = 'Copy',
  disableDefaultLabel = false,
  iconPath = mdiContentCopy,
  iconSize = 1,
  id,
  shape,
  successLabel = 'Copied',
  text,
}) {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyReply = () => {
    copy(text);

    setCopySuccess(true);

    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);

    copySuccessCallback?.();
  };

  return (
    <UI54Button
      id={id}
      type={btnType}
      shape={shape}
      onClick={copyReply}
      className={btnClassName}
      icon={<UI54Icon path={copySuccess ? mdiCheckBold : iconPath} size={iconSize} />}
    >
      {!disableDefaultLabel && (
        <span style={{ marginLeft: '3px' }}>
          {copySuccess ? successLabel : defaultLabel}
        </span>
      )}
    </UI54Button>
  );
}

export default CopyToClipboardBtn54origins;

CopyToClipboardBtn54origins.propTypes = {
  btnClassName: PropTypes.string,
  btnType: PropTypes.string,
  copySuccessCallback: PropTypes.func,
  defaultLabel: PropTypes.string,
  disableDefaultLabel: PropTypes.bool,
  iconPath: PropTypes.any,
  iconSize: PropTypes.number,
  id: PropTypes.any,
  shape: PropTypes.string,
  successLabel: PropTypes.string,
  text: PropTypes.string,
};

import React from 'react';
import { capitalize } from 'lodash';
import { Flex, Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import DropDownParamsEntity from '../../../entity/components/dropDownParamsEntity/DropDownParamsEntity';

const estimatedTimeInfo = (
  <div>
    Easy: up to 3 hours
    {' '}
    <br />
    Normal: from 3 - 10 hours
    {' '}
    <br />
    Difficult: up to 30 hours
    <br />
    Very complicated: more than 30 hours, should be divided into several tasks
  </div>
);

function IssueEstimatedTimeDropDown({
  disabledDropDown = false,
  currentSelect = 'empty',
  dropDownCallback,
}) {
  const { t } = useTranslation();

  return (
    <Flex vertical>
      <Flex className="wrapper-title" align="center">
        <span className="mr-1">{`${capitalize(t('wms.labels.estimated_time', 'estimated time'))}:`}</span>
        <Tooltip
          title={estimatedTimeInfo}
          placement="topLeft"
          color="#3e8adb"
        >
          <BaseButton
            style={{ minWidth: '26px' }}
            size="small"
            id="estimatedTimeButton"
            shape="circle"
            type="button"
            className="view_btn_link__info"
          >
            <Icon
              path={mdiInformationOutline}
              size={0.9}
            />
          </BaseButton>
        </Tooltip>
      </Flex>
      <DropDownParamsEntity
        type="estimated_time"
        disabled={disabledDropDown}
        destroyPopupOnHide
        currentSelect={currentSelect}
        dropDownCallback={dropDownCallback}
      />
    </Flex>
  );
}

export default IssueEstimatedTimeDropDown;

import {
  Card, Row, Spin,
} from 'antd';
import Icon from '@mdi/react';
import {
  mdiSquareEditOutline,
} from '@mdi/js';
import React, {
  useContext, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  isEmpty, uniq, isEqual,
} from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { antNotification, capitalizeFirstChar } from '../../../../MainUtils';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import JSpreadsheet from '../../../../../../JSpreadsheet/JSpreadsheet';
import {
  checkListStatusKeys,
  columnsCheckList, indexesSheet,
  priorityKeys,
} from '../../multiEdit/utils';
import useURLParams from '../../../../hooks/useURLParams';
import {
  getChecklistParams,
} from '../../../selectors/selectors';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import { updateChecklist } from '../../../actions/QaActions';
import ExpandButton from '../../../../components/ExpandButton';
import { MainCollapseSectionContext } from '../../../context/ProjectFlowListOfContexts';

export default function Checklist({ partition, setChecklistExpand, checklistExpand = '' }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const checklistParams = useSelector(getChecklistParams);

  const { getURLParams } = useURLParams();
  const { activeChecklist } = getURLParams();

  const {
    data: defaultData,
  } = checklistParams;

  const {
    selectChecklist,
  } = useContext(BugTrackerContext);

  const {
    toggleHorizontal,
  } = useContext(MainCollapseSectionContext);

  const rowsIsEdited = useRef(new Map([]));

  const [styleCells, setStyleCells] = useState({});
  const [dataSheet, setDataSheet] = useState({ ...defaultData });
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false)

  const nameColumn = {
    0: 'id',
    1: 'title',
    2: 'description',
    3: 'priority',
    4: 'status',
  };

  const onBeforeChangeCallback = (instance, x, y, value) => {
    // ======== VALIDATION DATA =========
    const row = instance.getJson()[y];
    const prevDataCell = row[nameColumn[x]];

    if (nameColumn[x] === 'id') {
      return value?.slice(0, 128);
    }

    if (nameColumn[x] === 'title') {
      return value?.slice(0, 128);
    }

    if (nameColumn[x] === 'description') {
      return value?.slice(0, 128);
    }

    if (nameColumn[x] === 'priority' && !priorityKeys.includes(value)) {
      return prevDataCell;
    }

    if (nameColumn[x] === 'status' && !checkListStatusKeys.includes(value)) {
      return prevDataCell;
    }

    if (!nameColumn[x]) {
      return '';
    }

    return value;
  };

  const changeSheetCallback = (data, columnIndex, rowIndex) => {
    const newStyle = { [`${indexesSheet[columnIndex]}${+rowIndex + 1}`]: 'background-color: #ffe7ba;' };

    const currentChange = rowsIsEdited.current;

    if (currentChange.has(+rowIndex)) {
      const prevColumn = currentChange.get(+rowIndex);
      currentChange.set(+rowIndex, uniq([...prevColumn, +columnIndex]));
    } else {
      currentChange.set(+rowIndex, [+columnIndex]);
    }

    setStyleCells((prev) => ({ ...prev, ...newStyle }));
    setDataSheet(data);
  };

  const getDataForParams = (row, indexRow) => {
    const columnsNeedUpdate = rowsIsEdited.current.get(indexRow);
    const data = { ...defaultData.filter((item) => +item?.indexRow === +indexRow)?.[0] } ?? {};

    columnsNeedUpdate.forEach((idx) => {
      data.indexRow = +indexRow;
      switch (idx) {
        case 0: data.id = row?.id; break;
        case 1: data.title = row?.title; break;
        case 2: data.description = row?.description; break;
        case 3: data.priority = row?.priority; break;
        case 4: data.status = row?.status; break;
        default: break;
      }
    });

    console.log(data);
    
    return data;
  };

  const toggleSave = async () => {
    setLoading(true);
    try {
      const dataForUpdate = [];
      dataSheet.forEach((item, index) => {
        if (rowsIsEdited.current.has(+index)) {
          const newParams = getDataForParams(item, index);
          dataForUpdate.push(newParams);
        } else {
          const oldRow = defaultData.filter((el) => +el?.indexRow === +index)?.[0] || {};
          if (!isEmpty(oldRow)) dataForUpdate.push(oldRow);
        }
      });

      const data = {
        uuid: activeChecklist,
        partition,
        params: {
          data: dataForUpdate,
        },
      };

      await dispatch(updateChecklist(data));

      selectChecklist(activeChecklist);
      antNotification('success', capitalizeFirstChar(t('wms.noun.success', 'success')));
      rowsIsEdited.current = new Map([]);
      // backEditCallback();
    } catch (e) {
      antNotification('error', 'Error');
    }
    setLoading(false);
  };

  const toggleExpand = () => {
    toggleHorizontal()
    setIsExpanded(!isExpanded)
  }

  const initFunc = () => {
    rowsIsEdited.current = new Map([]);
    setStyleCells({});

    if (!isEmpty(defaultData)) {
      setDataSheet(defaultData);
    } else {
      setDataSheet([]);
    }
  };

  useLayoutEffect(() => {
    initFunc();
  }, [defaultData]);

  useLayoutEffect(() => {
    if (`${activeChecklist}`) {
      selectChecklist(activeChecklist);
    }
  }, []);


  return (
    <Spin spinning={loading}>
      <Row>
        <div className="d-flex align-items-center mb-3">
          <ExpandButton
            toggleExpandedMode={toggleExpand}
            isExpanded={isExpanded}
          />
          <BaseButton
            id="saveIssueBtn"
            size="small"
            onClick={toggleSave}
            type="primary"
            disabled={defaultData?.length === 0 ? (isEqual(dataSheet, defaultData) && defaultData.length === 0) : isEqual(dataSheet, defaultData)}
            style={{
              marginLeft: 8,
            }}
          >
            <Icon path={mdiSquareEditOutline} size={0.7} />
            <span className="pl-1">
              {capitalizeFirstChar(t('wms.verb.save', 'save'))}
            </span>
          </BaseButton>
        </div>
        <JSpreadsheet
          columns={columnsCheckList}
          className="spread-sheet-multi-edit"
          height="600px"
          defaultTextDescription={dataSheet}
          autoIncrement={false}
          changeDescriptionCallback={changeSheetCallback}
          isFullscreen={false}
          styleCells={styleCells}
          onBeforeChangeCallback={onBeforeChangeCallback}
        />
      </Row>
    </Spin>
  );
}

Checklist.propTypes = {
  partition: PropTypes.string,
  setChecklistExpand: PropTypes.func,
  checklistExpand: PropTypes.string,
};

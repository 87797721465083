import React from 'react';
import './ReactSelect.scss';
import Select from "react-select";

function ReactSelect({ ...props }) {
  return (
   <Select
     {...props}
   />
  );
}

export default ReactSelect;

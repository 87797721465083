import PropTypes, { func, string } from 'prop-types';
import React, { useContext } from 'react';
import {
  mdiDotsVertical,
  mdiPlayOutline,
  mdiSquareEditOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Dropdown, Tooltip } from 'antd';
import { isNil, propOr } from 'ramda';
import { useTranslation } from 'react-i18next/';
import { useSelector } from 'react-redux';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import EditTestCase from './EditTestCase';
import NewExecutionForm from '../execution/NewExecutionForm';
import BtnModal from '../../BtnModalComponent';
import ReRunTestCycleOrCase from '../componentsForMultipleUse/ReRunTestCycleOrCase';

import { capitalize } from 'lodash';
import {
  currentSelectedUUID,
  getTestSuiteUUID,
} from '../../../selectors/selectors';
import { MainCollapseSectionContext } from '../../../context/ProjectFlowListOfContexts';
import { constantsForDeleteSuite } from '../QaUtils';
import { stopPropagation } from '../../../../54origins/utils54origins';
import BugTrackerModalWrapper from '../BugTrackerModalWrapper';
import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';
import CopyURL from '../../../../components/CopyURL';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import { qaNotify } from '../utilsQa';
import { useDispatch } from 'react-redux';
import { updateTestSuite, updateTestCycle } from '../../../actions/QaActions';

function ActionFormatterInTestCase({
  row,
  typeOfParent,
  parentUUID,
  removeCasesCallback,
  partitionType,
  currentHorizontalCollapseState = false,
  styleForRemoveBtn,
  parentData,
  selectedKeys,
  casesCount
}) {
  const {
    getListOfTestCasesInSuite,
    getListOfTestSuites,
    getListOfTestCasesInCycle,
    getListOfTestCycles
  } = useContext(BugTrackerContext);

  const { t } = useTranslation();
  const dispatch = useDispatch()

  const testSuiteUUID = useSelector(getTestSuiteUUID);
  const cycleOrArchiveUUID = useSelector((state) => currentSelectedUUID(state, typeOfParent === 'cycle'));

  const isArchive = typeOfParent === 'cycleArchive';

  const notHiddenButton = (row.status === 'unexecuted' || row.status === 'in progress');
  const removeCallback = () => removeCasesCallback([row.uuid], [row]);

  const customTitle = parentData?.params?.title;

   //suite update
   const updateTestSuitePromise = ({ uuid, params }) => {
    const data = {
      uuid: parentUUID,
      params: { ...params },
      partitionType,
    };

    console.log('action');
    
    const uniqKey = `testSuiteUpd${Date.now()}`;
    qaNotify({
      param: 'info',
      key: uniqKey,
      message: 'Updating test suite...',
    });
    return dispatch(updateTestSuite(data)).then((res) => {
      qaNotify({
        param: 'success',
        key: uniqKey,
        message: 'Test suite updated',
      });
      return res;
    });
  };

  const updateTestSuiteData = () => {    
    getListOfTestCasesInSuite(parentUUID)

    if (getListOfTestSuites) {
      getListOfTestSuites();
    } else {
      dispatch(setGlobalUpdateEntity({ entityType: 'testSuite', action: 'update' }));
    }
  };
  //suite update

  const updateTestCycleData = () => {
    getListOfTestCasesInCycle(parentUUID)

    if (getListOfTestCycles) {
      getListOfTestCycles();
    } else {
      dispatch(setGlobalUpdateEntity({ entityType: 'testCycle', action: 'update' }));
    }
  };

  return (
    <div
      className="flex items-center justify-start"
      onClick={stopPropagation}
      style={{ width: 130 }}
    >
      {!isNil(row) && row.template === true && (
        <BtnModal
          footer={[]}
          title={capitalize(t('wms.modals.headers.new_test_case_execution', 'new test case execution'))}
          width="40%"
          btnComponent={(
            <Tooltip title={!currentHorizontalCollapseState && capitalize(t('wms.buttons.execute', 'execute'))}>
              <BaseButton
                className="ant-btn-flex customAntOutPrimaryBtn ant-btn-default ant-btn-sm"
                style={{ borderRight: 'none', borderRadius: '4px 0px 0px 4px' }}
              >
                <span>
                  <Icon path={mdiPlayOutline} size={0.8} />
                </span>

                {currentHorizontalCollapseState
                  && (
                    <span className="pl-1">
                      {capitalize(t('wms.buttons.execute', 'execute'))}
                    </span>
                  )}
              </BaseButton>
            </Tooltip>
          )}
        >
          <NewExecutionForm
            customTitle={parentData?.params?.title}
            typeOfParent={typeOfParent}
            customTestCases={[row?.uuid]}
            parentUuid={row.parent}
          />
        </BtnModal>
      )}
      {!isNil(row) && row.template === false && (
        <BugTrackerModalWrapper counterBug={row.counterBug} uuid={row.uuid} typeOfParent="testCase" />
      )}

      {(typeOfParent !== 'cycleArchive' && notHiddenButton)
        && (
          <BtnModal
            footer={[]}
            title={capitalize(t('wms.modals.headers.edit_test_case', 'edit test case'))}
            width="50%"
            btnComponent={(
              <BaseButton
                className="ant-btn customAntOutPrimaryBtn ant-btn-default ant-btn-sm"
                style={{ borderRadius: '0px' }}
              >
                <Tooltip title={capitalize(t('wms.tooltips.edit', 'edit'))}>
                  <Icon path={mdiSquareEditOutline} size={0.7} />
                </Tooltip>
              </BaseButton>
            )}
          >
            <EditTestCase
              entityUUID={row.uuid}
              parentUUID={row.parent}
              files={row.files}
              defaultTitle={propOr('', 'title', row)}
              defaultDescription={propOr('', 'description', row)}
              defaultComment={propOr('', 'comment', row)}
              defaultAssignToUsersOption={propOr([], 'assignToUser', row)}
              defaultTypeOfTesting={propOr([], 'typeOfTesting', row)}
              defaultStatusPriority={propOr('', 'casePriority', row)}
              selectedCycle={cycleOrArchiveUUID}
              selectedSuit={testSuiteUUID}
              typeOfParent={typeOfParent}
            />
          </BtnModal>
        )}

      {(typeOfParent !== 'suite' && !notHiddenButton) && (
        <ReRunTestCycleOrCase
          title={capitalize(t('wms.modals.headers.new_test_case_execution', 'new test case execution'))}
          isArchive={isArchive}
          btnIconStyle={isArchive ? { borderRadius: '0px' } : { borderLeft: 'none', borderRadius: '0px 4px 4px 0px' }}
          titleTooltip={capitalize(t('wms.tooltips.re-run_test_case', 're-run test case'))}
        >
          <NewExecutionForm
            customTitle={customTitle}
            typeOfParent={typeOfParent}
            customTestCases={[row?.uuid]}
            parentUuid={row.parent}
          />
        </ReRunTestCycleOrCase>
      )}

      {(typeOfParent === 'suite' || notHiddenButton) && (
        <HardDeleteEntityContainer
          actionForEntity={removeCallback}
          antNotificationSuccess
          checkHardDelete={false}
          className="customAntOutDangerBtn"
          customConstants={constantsForDeleteSuite}
          entityType={row.entity_type}
          type="entity"
          entityUUID={[row.uuid]}
          hideLabel
          labelMode={false}
          name="case"
          partitionType={partitionType}
          sizeBtn="small"
          styleBtn={styleForRemoveBtn}
          updateTestSuiteData={updateTestSuiteData}
          updateTestCycleData={updateTestCycleData}
          typeOfParent={typeOfParent}
        />
      )}
    </div>
  );
}

ActionFormatterInTestCase.propTypes = {
  row: PropTypes.object,
  typeOfParent: PropTypes.string,
  removeCasesCallback: func,
  partitionType: string,
};
export default ActionFormatterInTestCase;

import { get } from 'lodash';
import { propOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseButton from '../../../../../components/_ui/BaseButton/BaseButton';
import BaseTableWithPagination from '../../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../../../components/entityParamTag/EntityParamTag';
import { capitalize } from 'lodash';

export default function RelatedProjectsTable({
  defaultRelatedProjects,
  handleCancel,
  updateProjectWithCustomsParams,
  getListProjects,

}) {
  const { t } = useTranslation();

  const [listProjects, setListProjects] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pageLimit, setPageLimit] = useState(10);

  const columns = [
    {
      dataIndex: ['id'],
      title: 'ID',
      render: (cell) => cell && `P${cell}`,
    },
    {
      dataIndex: 'title',
      title: capitalize(t('wms.labels.title')),
      render: (text, record) => text && (
        <EntityParamTag
          param="title"
          value={text}
          type="value"
          tooltip={propOr('', 'uuid', record)}
        />
      ),
    },
    {
      dataIndex: 'status',
      title: capitalize(t('wms.table.headers.status', 'Status')),
      render: (text, record) => record && (
        <EntityParamTag
          param="status"
          value={text === 'archive'
            ? 'archived'
            : text}
          type="icon"
        />
      ),
    },
  ];

  const toggleSubmit = () => {
    updateProjectWithCustomsParams({ relatedProjects: selectedKeys });
    handleCancel();
  };

  const initFunc = async () => {
    setLoading(true);
    const res = await getListProjects();
    // console.log(get(res, 'data', []))
    setListProjects(get(res, 'data', []));
    setSelectedKeys(defaultRelatedProjects);
    setLoading(false);
  };

  const multipleSelectRow = {
    // clickToSelect: !disableOnClick,
    hideSelectColumn: true,
    // mode: 'checkbox',
    selectedRowKeys: selectedKeys,
    // style: { background: customBackgroundRowTable || '#e5f4fc' },
  };

  const rowClassName = (row) => {
    const data = `universal_table_row ${selectedKeys.some((i) => i === get(row, 'uuid'))
      ? 'selected'
      : ''
      }`;

    return data;
  };

  const onRow = (record) => ({
    onClick: () => {
      if (!isChanged) {
        setIsChanged(true);
      }

      setSelectedKeys((prev) => {
        if (prev.find((p) => p === record?.uuid)) {
          return prev.filter((i) => i !== record?.uuid);
        }
        return [...prev, record.uuid];
      });
    },
  });

  useEffect(() => {
    initFunc();
  }, []);
  return (
    <div className="flex flex-col">
      <div>
        <BaseTableWithPagination
          pageLimit={pageLimit}
          changePageLimit={setPageLimit}
          loading={loading}
          columns={columns}
          data={listProjects}
          onRow={onRow}
          //   paginationSize="small"
          rowClassName={rowClassName}
          //   headerRowClassName={headerRowClassName}
          rowSelection={multipleSelectRow}
        />
      </div>
      <div className="flex justify-end">
        <BaseButton
          className="customAntOutPrimaryBtn ml-1 mr-1"
          disabled={!isChanged}
          onClick={toggleSubmit}
        >
          {capitalize(t('wms.buttons.submit'))}
        </BaseButton>
      </div>
    </div>
  );
}

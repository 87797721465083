import React from "react";
import SyncStatus from "./SyncStatus";

export const HelperText = () => (
  <>
    <div>
      <strong>Save & Editing:</strong> online editing of documents by several
      users is supported, and saving is performed automatically.
    </div>
    <div>
      <strong>Sync status:</strong>
      <ul className="pl-1 sync-status-ul">
        <li>
          <SyncStatus status={"Saved"} isHelper={true} /> - a connection to the
          server has been established, and all recent changes have been saved.
        </li>
        <li>
          <SyncStatus status={"Saving"} isHelper={true} /> - new document changes
          are saved.
        </li>
        <li>
          <SyncStatus status={"Unsync"} isHelper={true} /> - there is no
          connection to the server, the changes in the document will not be
          saved.
        </li>
      </ul>
    </div>
    <div>
      <strong>Toolbar:</strong> move the mouse cursor over the button in the
      editor to get a hint.{" "}
    </div>
    <div>
      <strong>Hotkeys:</strong>
      <ul className="pl-1">
        <li>
          Ctrl + Z - cancels the last changes to the text in the editor that you
          entered.
        </li>
        <li>
          Ctrl + Y - returns the last changes to the text in the editor that you
          entered after canceling.
        </li>
        <li>Ctrl + B - make the highlighted text bold.</li>
        <li>Ctrl + B - make the highlighted text bold.</li>
        <li>Ctrl + I - make the selected text in italics.</li>
        <li>Ctrl + U - make highlighted text underlined.</li>
        <li>
          Ctrl + K - opens a modal window for entering a link, the highlighted
          text becomes a link.
        </li>
        <li>
          Ctrl + Alt + L - adds a horizontal separator.
        </li>
      </ul>
    </div>
  </>
);

import { mdiArchiveArrowDown } from '@mdi/js';
import Icon from '@mdi/react';
import {Col, Tooltip} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next/';
import PropTypes from 'prop-types';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import { capitalize } from 'lodash';
import BtnModal from '../../../components/BtnModalComponent';
import ArchiveModalInTestCycleComponent from '../../../components/qaView/arсhiveQa/ArсhiveModalInTestCycleComponent';

function ArchiveModalInTestCycle({
  currentCycles, isArchive, selectedCycleOrArchiveCycle, type,
}) {
  const { t } = useTranslation();
  return (
    <BtnModal
      footer={[]}
      className="flex justify-end "
      title={capitalize(t('wms.tooltips.add_to_archive', 'add to archive'))}
      width="30%"
      blockPropagation
      btnComponent={
        type === 'cycle' ? (
          <Tooltip
            title={capitalize(t('wms.tooltips.add_to_archive', 'add to archive'))}
          >
            <div>
              <BaseButton
                className="customAntOutYellowBtn "
                size="small"
                style={{ borderRadius: '0px 5px 5px 0px', borderLeft: 'none' }}
              >
                <Icon
                  path={mdiArchiveArrowDown}
                  size={0.7}
                  className="cursor-pointer"
                />

              </BaseButton>
            </div>
          </Tooltip>
        ) : (

          <BaseButton
            className="customAntOutYellowBtn "
            size="small"
            style={{ display: 'flex' }}
          >
            <Icon
              path={mdiArchiveArrowDown}
              size={0.7}
              className="cursor-pointer"
            />
            {capitalize(t('wms.tooltips.add_to_archive', 'add to archive'))}
          </BaseButton>
        )

      }
    >
      <ArchiveModalInTestCycleComponent
        currentCycles={currentCycles}
        isArchive={isArchive}
        selectedCycleOrArchiveCycle={selectedCycleOrArchiveCycle}
      />
    </BtnModal>
  );
}
ArchiveModalInTestCycle.propTypes = {
  // handleOk: PropTypes.func,
  currentCycles: PropTypes.array,
  selectedCycleOrArchiveCycle: PropTypes.string,
  isArchive: PropTypes.bool,
};

export default ArchiveModalInTestCycle;

import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  arrayOf,
  bool,
  number,
  object,
  string,
  oneOfType,
  oneOf,
} from 'prop-types';

import Icon from '@mdi/react';
import { mdiAccountMultiple, mdiCheckBold } from '@mdi/js';

import {
  Popover, Button,
} from 'antd';
import ActorAvatarsList from './ActorAvatarsList';
import CircleActorAvatar54origins from '../../../../54origins/components/CircleActorAvatar54origins';

import { getMe } from '../../../selectors/selectors';

import './ActorAvatarGroup.scss';

const AVATARS_MARGIN = '6px';

const popoverInnerStyles = {
  position: 'relative',
  backgroundClip: 'border-box',
};

const findMe = (userUUID, actorsArr) => {
  const indexOfMe = actorsArr.findIndex((actor) => actor?.value === userUUID);
  return [indexOfMe !== -1, indexOfMe];
};

const getSortedArray = (indexOfMe, actorsArr) => {
  const copiedActorsArr = actorsArr.slice();

  if (indexOfMe !== -1) {
    return [
      ...copiedActorsArr.splice(indexOfMe, 1),
      ...copiedActorsArr,
    ];
  }

  return actorsArr;
};

function ActorAvatarsGroup({
  actorAvatars = [],
  maxCount = 5,
  popoverPlacement = 'top',
  popoverTrigger = 'hover',
  avatarSize = 'default',
  avatarGap = 6,
  avatarStyles,
  listAvatarGap = 6,
  listAvatarSize = 'default',
  wrapperListStyles,
  tooltipPlacement = 'top',
  groupButtonSize = 'middle',
  groupButtonStyles,
  withSearch = true,
}) {
  const userUUID = useSelector(getMe);

  const [isFindMe, indexOfMe] = findMe(userUUID, actorAvatars);
  const sortedActorAvatars = useMemo(
    () => getSortedArray(indexOfMe, actorAvatars),
    [indexOfMe, JSON.stringify(actorAvatars)],
  );
  // console.log('sortedActorAvatars', sortedActorAvatars);

  const listLength = sortedActorAvatars.length;
  const isRenderAvatarsList = listLength > maxCount && maxCount !== 'infinity';

  const baseAvatarStyles = useMemo(() => ({
    ...avatarStyles,
    ...(!isRenderAvatarsList && { marginRight: `-${AVATARS_MARGIN}` }),
    border: '1px solid #ffffff',
  }), [isRenderAvatarsList, JSON.stringify(avatarStyles)]);

  const highlightedAvatarStyles = useMemo(() => ({
    ...baseAvatarStyles,
    boxShadow: '0 0 0 2px #1890ff',
  }), [JSON.stringify(baseAvatarStyles)]);

  const buttonStyles = useMemo(() => ({
    paddingInline: '8px',
    borderRadius: '30px',
    ...groupButtonStyles,
  }), [JSON.stringify(groupButtonStyles)]);

  const renderListActorAvatar = useCallback((item, index) => {
    const isUserIn = item?.value === userUUID;
    return (
      <CircleActorAvatar54origins
        key={item?.value}
        styles={isUserIn ? highlightedAvatarStyles : baseAvatarStyles}
        actorUUID={item?.value}
        name={item?.initials}
        size={listAvatarSize}
        gap={listAvatarGap}
      />
    );
  }, [listAvatarGap, listAvatarSize, isFindMe, JSON.stringify(baseAvatarStyles)]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={!isRenderAvatarsList ? { marginRight: AVATARS_MARGIN } : {}}
      className="inline-flex items-center flex-nowrap"
      onClick={(e) => e.stopPropagation()}
    >
      {isRenderAvatarsList
        ? (
          <Popover
            content={(
              <ActorAvatarsList
                actors={sortedActorAvatars}
                renderActorAvatar={renderListActorAvatar}
                wrapperListStyles={wrapperListStyles}
                withSearch={withSearch}
              />
            )}
            placement={popoverPlacement}
            trigger={popoverTrigger}
            autoAdjustOverflow
            overlayClassName="popover-no-inner-paddings"
            overlayStyle={{ pointerEvents: 'all' }}
            overlayInnerStyle={popoverInnerStyles}
          // open
          >
            <Button
              style={buttonStyles}
              className="inline-flex items-center"
              size={groupButtonSize}
            >
              <span>{listLength}</span>
              <Icon className="ml-1" path={mdiAccountMultiple} size={0.8} />
              {isFindMe && (
                <Icon className="ml-1" path={mdiCheckBold} size={0.65} color="#72b153de" />
              )}
            </Button>
          </Popover>
        )
        : (
          sortedActorAvatars.map((actor) => {
            const isUserIn = actor?.value === userUUID;
            return (
              <CircleActorAvatar54origins
                key={actor?.value}
                styles={isUserIn ? highlightedAvatarStyles : baseAvatarStyles}
                actorUUID={actor?.value}
                name={actor?.initials}
                size={avatarSize}
                gap={avatarGap}
                tooltip={(
                  <>
                    {actor?.fullName}
                    <br />
                    {actor?.role && (`( role: ${actor?.role} )`)}
                  </>
                )}
                tooltipPlacement={tooltipPlacement}
              />
            );
          })
        )}
    </div>
  );
}
ActorAvatarsGroup.propTypes = {
  actorAvatars: arrayOf(object),
  maxCount: number,
  popoverPlacement: string,
  popoverTrigger: string,
  avatarSize: oneOfType([number, oneOf(['small', 'default', 'large']), object]),
  avatarGap: number,
  avatarStyles: object,
  listAvatarGap: number,
  listAvatarSize: oneOfType([number, oneOf(['small', 'default', 'large']), object]),
  wrapperListStyles: object,
  tooltipPlacement: string,
  groupButtonSize: oneOf(['small', 'middle', 'large']),
  groupButtonStyles: object,
  withSearch: bool,
};

export default ActorAvatarsGroup;

import { UsersPartitionConstants } from '../../constants/Constants';

const initialState = {
  fetching: true,
  publicFetching: true,
  publicData: [],
  publicError: '',
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case UsersPartitionConstants.GET_PUBLIC_PERSONAL_SETTINGS_REQUEST:
      return {
        ...state,
        publicFetching: true,
      };
    case UsersPartitionConstants.GET_PUBLIC_PERSONAL_SETTINGS_SUCCESS:
      return {
        ...state,
        publicFetching: false,
        publicData: payload,
      };
    case UsersPartitionConstants.GET_PUBLIC_PERSONAL_SETTINGS_FAILURE:
      return {
        ...state,
        publicFetching: false,
        publicData: [],
        publicError: payload,
      };

    default: return state;
  }
};

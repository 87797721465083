import { Card, Col, Row } from 'antd';
import React, { useCallback, useId, useMemo } from 'react';
import { array, bool } from 'prop-types';
import Icon from '@mdi/react';

import useURLParams from '../../hooks/useURLParams';

function GalleryListWithoutActiveCard({
  otherUser,
  galleryList,
}) {
  const id = useId();
  const { setSearchParams } = useURLParams();

  const changeActiveCard = useCallback((_, link) => {
    setSearchParams({ activeCard: link });
  }, []);

  const galleryItemListForAdmin = useMemo(() => {
    if (otherUser) {
      return galleryList.filter((e) => !e.hideForAdmin);
    }
    return galleryList;
  }, [otherUser, galleryList]);

  return (
    <Row gutter={[16, 16]} className="w-full" key={id}>
      {galleryItemListForAdmin.map((el) => (
        <Col key={el.id} xxl={6} xl={8}>
          <Card.Grid
            onClick={(e) => changeActiveCard(e, el.link)}
            className="dashboardMediumCardItem"
          >
            <Icon path={el.icon} size={1} className="mr-1" />
            {el.title}
          </Card.Grid>
        </Col>
      ))}
    </Row>
  );
}

GalleryListWithoutActiveCard.propTypes = {
  otherUser: bool,
  galleryList: array,
};

export default GalleryListWithoutActiveCard;

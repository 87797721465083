import React from 'react';
import PropTypes from 'prop-types';

function SheetHeaderComponent({ col, isOver, type, template }) {
  const className = isOver ? 'cell read-only drop-target' : 'cell read-only';

  // if (type === 'newTestCase' || template) {
  //   switch (col.title) {
  //     case 'actual': return null;
  //     case 'status': return null;
  //     default: return <th className={className} style={{ width: col.width }}>{col.label}</th>;
  //   }
  // }

  return (
    <th className={className} style={{ width: col.width }}>{col.label}</th>
  );
}

SheetHeaderComponent.propTypes = {
  col: PropTypes.shape({
    label: PropTypes.string,
    title: PropTypes.string,
    width: PropTypes.string
  }),
  template: PropTypes.bool,
  isOver: PropTypes.any,
  type: PropTypes.string
};
const SheetHeader = React.memo(SheetHeaderComponent);
export default SheetHeader;

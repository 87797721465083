import moment from 'moment';

export const prepareProgressTimeBar = (startDate, endDate) => {
  if (!startDate || !endDate) return false;
  const fullInterval = moment(endDate, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days');
  const remainingInterval = moment(endDate, 'YYYY-MM-DD').diff(moment(moment(), 'YYYY-MM-DD'), 'days');
  // const fullInterval = 100;
  // const remainingInterval = -10;

  if (fullInterval < 0) return false;

  const progress = Math.round(100 - (remainingInterval / fullInterval) * 100);
  const progressBar = { progress, color: '', status: 'normal' };
  // console.log('progress', progress);

  switch (true) {
    case ((progress <= 100) && (progress >= 85)): {
      progressBar.color = '#ff0d0d';
      break;
    }
    case ((progress < 85) && (progress >= 65)): {
      progressBar.color = '#ff4e11';
      break;
    }
    case ((progress < 65) && (progress >= 45)): {
      progressBar.color = '#ff8e15';
      break;
    }
    case ((progress < 45) && (progress >= 25)): {
      progressBar.color = '#fab733';
      break;
    }
    case ((progress < 25) && (progress > 0)): {
      progressBar.color = '#69b34c';
      break;
    }
    default: {
      progressBar.color = '#d40000';
      progressBar.status = 'exception';
    }
  }

  return progressBar;
};

export const milestoneScoreInfo = (
  <span style={{ color: '#000000d9' }}>
    <span className="text-primary"><strong>Milestone score </strong></span>
    - it shows significance of the milestone to project (max value: 10 | 100 when expired).
    <br />
    Depends on the importance and the remaining time until the deadline.
  </span>
);

export const kpmInfo = (
  <>
    <span>
      <strong>Plan score</strong>
      {' '}
      - general scoring by project milestones and goals,
      which shows the status of project planning.
    </span>
    <br />
    {/* <span>
      <strong>Calculation:</strong>
      {' '}
      &sum; of the values of all the milestones of the project.
    </span> */}
    <span>
      <strong>Threshold values: </strong>
      it&apos;s recommended to pay attention if the value exceeds 10.
      A value of 100 means that the project has an expired milestone.
    </span>
  </>
);

import React, {
  useEffect, useMemo, useState,
} from 'react';
import { Col, DatePicker } from 'antd';
import { get, isEmpty, isNil } from 'lodash';
import dayjs from 'dayjs';
import { array, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import FilterByPeriod from '../FilterByPeriod.jsx';
import SwitcherOfDate from '../../../userFlow/components/WeekPlanning/SwitcherOfDate';
import SpreadSheetReport from './SpreadSheetReport.jsx';

import useURLParams from '../../../hooks/useURLParams';
import { getNameForActor } from '../../../54origins/utils54origins';

const { RangePicker } = DatePicker;

export default function ViewReportByUser({ users, selectedUser, setSelectedUser }) {
  const { t } = useTranslation();
  const {
    getURLParams, addAndRemoveSearchParams, clearSearchParams, setSearchParams,
  } = useURLParams();

  const [typePeriod, setTypePeriod] = useState('');
  const [rangePickerValue, setRangePickerValue] = useState([]);
  const [defaultDate, setDefaultDate] = useState('');

  const [currentPeriodDate, setCurrentPeriodDate] = useState({
    startDate: '',
    endDate: '',
  });

  const {
    startDate, endDate, period, activeActor,
  } = getURLParams();

  const startDateCurrentMonths = dayjs().startOf('months').format('YYYY-MM-DD');

  const onSelectTags = (tag) => {
    const name = tag?.target?.value === 'isoWeek' ? 'week' : tag?.target?.value;

    addAndRemoveSearchParams({
      period: name,
      startDate: dayjs().startOf(tag?.target?.value).format('YYYY-MM-DD'),
    }, ['endDate']);

    setTypePeriod(name === 'week' ? 'isoWeek' : name);
    if (!isEmpty(rangePickerValue)) {
      setRangePickerValue([]);
    }
  };

  const onChangeRangePicker = (dates, dateStrings) => {
    const [date1, date2] = dateStrings;

    setRangePickerValue(dates || ['', '']);

    const newDateUrl = {
      startDate: dates ? date1 : startDateCurrentMonths,
      period: 'months',
    };

    if (dates) {
      newDateUrl.endDate = date2;
      newDateUrl.period = 'rangePicker';
    } else {
      clearSearchParams(['endDate']);
      setRangePickerValue([]);
    }
    setSearchParams(newDateUrl);
  };

  const actorName = useMemo(() => {
    const actor = users.find((i) => i?.actor === selectedUser[0]);

    return getNameForActor(actor);
  }, [selectedUser]);

  const setPeriod = async () => {
    const currentPeriodValue = period === 'week' ? 'isoWeek' : period;
    setTypePeriod(currentPeriodValue);

    if (currentPeriodValue === 'rangePicker') {
      const arrDate = [dayjs(startDate), dayjs(endDate)];
      setRangePickerValue(arrDate);
    }

    setDefaultDate(startDate);

    if (activeActor && setSelectedUser) {
      setSelectedUser([activeActor]);
    }
  };

  const getPeriod = (startDay) => {
    setSearchParams({
      period: typePeriod === 'isoWeek' ? 'week' : typePeriod || period,
      startDate: startDay || startDate,
    });
  };

  useEffect(() => {
    const currentPeriodValue = period === 'week' ? 'isoWeek' : period;

    setCurrentPeriodDate({
      startDate,
      endDate: endDate || dayjs(startDate).endOf(currentPeriodValue).format('YYYY-MM-DD'),
    });
  }, [startDate, period, endDate]);

  useEffect(() => {
    if (!isNil(users) && !isEmpty(users) && startDate) {
      setPeriod();
    }
  }, [users, startDate, endDate, typePeriod]);

  useEffect(() => {
    if (!isNil(users) && !isEmpty(users) && !startDate) {
      setSearchParams({
        startDate: startDateCurrentMonths,
        period: 'months',
      });
    }
  }, [users]);

  return (
    
    <div className="relative h-full w-full flex flex-col overflowHidden">
      <div className="bg-white p-3">
        <div className="flex items-center" id="RangePicker">
          <FilterByPeriod typePeriod={typePeriod} onSelectTags={onSelectTags} />
          <RangePicker
            format="YYYY-MM-DD"
            popupClassName="z-1001"
            className="ml-3"
            onChange={onChangeRangePicker}
            value={rangePickerValue}
            getPopupContainer={() => document.getElementById('RangePicker')}
            placeholder={[
              t('wms.table.headers.start_date', 'Start date'),
              t('wms.table.headers.end_date', 'End date'),
            ]}
          />
        </div>

        <Col span={24} className="pt-3">
          <SwitcherOfDate
            classNameWrapper="flex justify-center"
            disabled={!isEmpty(rangePickerValue)}
            defaultDate={defaultDate}
            typePeriod={typePeriod}
            rangePickerValue={rangePickerValue}
            changePeriodCallback={getPeriod}
          />
        </Col>
      </div>

      <SpreadSheetReport
        currentPeriodDate={currentPeriodDate}
        selectedUser={selectedUser}
        actorName={actorName}
      />
    </div>
  );
}

ViewReportByUser.propTypes = {
  users: array,
  selectedUser: array,
  setSelectedUser: func,
};

import { Row } from 'antd';
import React from 'react';

import DashboardIdeasTableWrapper from './DashboardIdeasTableWrapper';

import { useClearComponent } from '../../../../../hooks/useClearComponent';
import { clearDocuments } from '../../../../../projectFlow/actions/PtcActionsForHook';

function DashboardIdeasCardRoot() {
  useClearComponent(clearDocuments);

  return (
    <Row className="w-full mt-3" style={{ flex: "1 0 auto" }}>
      <DashboardIdeasTableWrapper readOnlyComments />
    </Row>
  );
}

export default DashboardIdeasCardRoot;

import { UsersPartitionConstants } from '../../constants/Constants';

const initialState = {
  publicFetching: true,
  publicEntity: {},
  error: '',
  otherUserPublicEntity: {},
  otherUserFetching: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UsersPartitionConstants.GET_MY_PROFILE_PUBLIC_ENTITY_REQUEST:
      return {
        ...state,
        publicFetching: true,
      };
    case UsersPartitionConstants.GET_MY_PROFILE_PUBLIC_ENTITY_SUCCESS:
      return {
        ...state,
        publicFetching: false,
        publicEntity: payload,
      };
    case UsersPartitionConstants.GET_MY_PROFILE_PUBLIC_ENTITY_FAILURE:
      return {
        ...state,
        publicFetching: false,
        publicEntity: '',
        error: payload,
      };

    case UsersPartitionConstants.GET_OTHER_USER_PROFILE_PUBLIC_ENTITY_REQUEST:
      return {
        ...state,
        otherUserFetching: true,
      };
    case UsersPartitionConstants.GET_OTHER_USER_PROFILE_PUBLIC_ENTITY_SUCCESS:
      return {
        ...state,
        otherUserFetching: false,
        otherUserPublicEntity: payload[0],
      };
    case UsersPartitionConstants.GET_OTHER_USER_PROFILE_PUBLIC_ENTITY_FAILURE:
      return {
        ...state,
        otherUserFetching: false,
        otherUserPublicEntity: {},
        error: payload,
      };

    case UsersPartitionConstants.CLEAR_OTHER_USER_PROFILE_PUBLIC_ENTITY:
      return {
        ...state,
        otherUserFetching: true,
        otherUserPublicEntity: {},
      };

    default:
      return state;
  }
};

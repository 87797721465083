import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Card, Col, Flex, Form, Input, Radio, Row, Switch,
} from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  mdiAccountKeyOutline,
  mdiAccountSupervisorCircleOutline,
  mdiCardTextOutline,
  mdiListStatus,
  mdiTagMultipleOutline,
  mdiTextBoxOutline,
} from '@mdi/js';
import {
  head, length, omit, pathOr, prop,
} from 'ramda';
import Icon from '@mdi/react';
import { isEmpty } from 'lodash';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import InfoSection from '../../containers/info/InfoSection';
import ActorsFromPermsSelectComponent from '../../../entity/components/actorsFromPermsSelectComponent/ActorsFromPermsSelectComponent';
import CheckMainAreaContainer from '../../containers/areas/CheckMainAreaContainer';
import TagsSettingList from '../../../components/filter/TagsSettingList';
import statusOptions from './constants/ProjectStatusOptions';
// import EntityWorkTime from '../../../components/entityWorkTime/EntityWorkTime';

import { capitalize } from 'lodash';
import {
  antNotification,
  createParamsUsersList,
} from '../../../MainUtils';
import {
  getAreasForSelect,
  getIsFetchingNewProject,
  getMe,
} from '../../selectors/selectors';
import { getRootEntityPartition } from '../../../config/selectors/selectors';
import { createEntityPermissions } from '../../../entity/actions/entityPermActions';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { createNewDocumentRequest } from '../docView&ideas/actions/docsActions';
import { updateProjectRequest } from '../../actions/projectFlowActions';
import { incrementAndGetCount, entityCreate } from '../../../entity/actions/entityActions';
import { getMainProjectEntityRequest } from '../../actions/ProjectActions';
import { mainProjectEntityFetching } from '../../reducers/slicers/projectMainEntitySlicer';
import AssigningUsersRoot from '../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { checkAdminPerms } from '../../../actors/actorsUtils';
import { partitionNamesConfig } from '../../../api/appConfig';
import useFocus from "../../../54origins/hooks/useFocus54origins";

function NewProject({
  newProjectCallback,
  cancelBtnCallback,
  partitionType = partitionNamesConfig.partition1,
  customParent,
  isAssignOpen,
  setIsAssignOpen,
  isAssignAdminOpen,
  setIsAssignAdminOpen,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const disabledCreate = useRef();

  const [projectTitleRef] = useFocus();

  const areasForSelect = useSelector(getAreasForSelect);
  const fetchingNewProject = useSelector(getIsFetchingNewProject);
  const myUUID = useSelector(getMe);
  const partitionUUID = useSelector((state) => getRootEntityPartition(state, partitionType));
  const projectEntityFetching = useSelector(mainProjectEntityFetching);

  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [selectedAreas, changeSelectedAreas] = useState([]);
  const [lifeTime, setLifeTime] = useState(moment(new Date()).add(4, 'M').format('YYYY-MM-DD'));
  const [owner, setOwner] = useState([myUUID]);
  const [isNeedToCreateProjectDoc, setIsNeedToCreateProjectDoc] = useState(true);
  const [isNeedToCreateProjectInfrastructureDoc, setIsNeedToCreateProjectInfrastructureDoc] = useState(false);

  const [assignedUsersData, setAssignedUsersData] = useState([{
    uuid: myUUID,
    role: '',
  }]);
  const [assignedUsersPerms, setAssignedUsersPerms] = useState([{
    uuid: myUUID,
    perms: {
      create: true,
      read: true,
      update: true,
      delete: true,
      set: true,
      list: true,
    },
  }]);

  const [formData, setFormData] = useState({
    visibleUsers: [myUUID],
    adminsUsers: [],
  });

  const [projectName, setProjectName] = useState('');

  const parentForNewProject = customParent || partitionUUID;

  const onChangeUsers = (usersUUIDs) => {
    setFormData({
      ...formData,
      visibleUsers: usersUUIDs,
    });
  };

  const addPermissionsToProject = (entityUUID, users) => {
    // console.log('users', users)
    const data = {
      entity_uuid: entityUUID,
      data: users.map(({ uuid, perms }) => ({
        actor: uuid,
        ...perms,
      })),
    };

    dispatch(createEntityPermissions({
      data,
      partition: partitionType,
    }));
  };

  const createProjectDocAndUpdateProject = async (
    projectUUID,
    title,
    type = 'project description',
    paramsName = 'descriptionDoc',
  ) => {
    const params = {
      title,
      text: '',
      type,
      status: 'system',
      lifeTime: moment().add('1', 'year').format('YYYY-MM-DD'),
      actualState: '0',
    };

    const config = {
      parent: projectUUID,
      partition: partitionType,
      params,
    };

    const data = await dispatch(createNewDocumentRequest(config));
    if (!isEmpty(data)) {
      const docUUID = pathOr('', ['uuid'], data[0]);

      const projectConfig = {
        uuid: projectUUID,
        params: {
          [paramsName]: {
            uuid: docUUID,
            title,
          },
        },
        partition: partitionType,
      };

      return dispatch(updateProjectRequest(projectConfig)).then();
    }
  };

  const onUserChange = (data) => {
    setAssignedUsersPerms((prev) => {
      let perms = [...prev];
      if (data?.deleteUsers?.length) {
        perms = perms?.filter((el) => !data?.deleteUsers?.includes(el?.uuid));
      }
      if (data?.newPerms?.length) {
        const uuids = data?.newPerms?.map((el) => el?.uuid);
        perms = perms?.filter((el) => !uuids?.includes(el?.uuid));
      }
      perms = [...perms, ...(data?.newPerms ?? [])];
      return perms;
    });
    setAssignedUsersData(data?.users);
    onChangeUsers(data?.users?.map((el) => el?.uuid));
  };

  const getAdminUuids = assignedUsersPerms
    .filter((el) => checkAdminPerms(el.perms))
    .map((el) => el.uuid);

  const onSubmit = async (data) => {
    if (!disabledCreate.current) {
      disabledCreate.current = true;
      setIsCreatingProject(true);

      let mainProjectEntityUUID = await dispatch(getMainProjectEntityRequest({
        parent: parentForNewProject,
        partition: partitionType,
      })).catch((error) => {
        setIsCreatingProject(false);
      });

      mainProjectEntityUUID = mainProjectEntityUUID?.uuid;

      const projectID = mainProjectEntityUUID && await dispatch(incrementAndGetCount({
        entity_uuid: mainProjectEntityUUID,
        field_name: 'project',
      }, partitionType)).catch(() => { });

      const newLifeTime = lifeTime || moment(new Date()).add(4, 'M').format('YYYY-MM-DD');

      const projectData = {
        entity_type: 'project',
        owner: owner[0],
        parent: parentForNewProject,
        params: {
          audit: 0,
          areas: selectedAreas.map(({ label }) => label),
          archive: false,
          users: assignedUsersData?.filter((el) => formData?.visibleUsers?.includes(el?.uuid)),
          usersSearch: formData.visibleUsers,
          lifeTime: newLifeTime,
          ...(projectID?.increment ? { id: projectID?.increment } : {}),
          ...omit(['ownerProject'], data),
        },
      };

      const { params: { users } } = projectData;

      const constants = [
        ProjectTypeCommonConstants.NEW_PROJECT_REQUEST,
        ProjectTypeCommonConstants.NEW_PROJECT_SUCCESS,
        ProjectTypeCommonConstants.NEW_PROJECT_FAILURE,
      ];

      const options = {
        partition: partitionType,
        onSuccess: (response) => {
          antNotification('success', t('notifications.text.success.new_project_created', 'Project was created successfully'));

          const projectUUID = prop('uuid', head(response));

          if (length(assignedUsersPerms) > 0) {
            addPermissionsToProject(projectUUID, assignedUsersPerms);
          }

          if (isNeedToCreateProjectDoc) {
            const projectDescriptionDocumentName = projectName
              ? `${projectName} (project description document)`
              : 'Project description document';
            createProjectDocAndUpdateProject(projectUUID, projectDescriptionDocumentName);
          }

          if (isNeedToCreateProjectInfrastructureDoc) {
            const documentName = projectName
              ? `${projectName} (project infrastructure document)`
              : 'Project infrastructure document';
            const type = 'project infrastructure';
            const paramsName = 'infrastructureDoc';
            createProjectDocAndUpdateProject(
              projectUUID,
              documentName,
              type,
              paramsName,
            );
          }

          if (newProjectCallback) {
            newProjectCallback(projectUUID);
          }
          setIsCreatingProject(false);
        },
      };
console.log(projectData);
      dispatch(entityCreate({
        data: projectData,
        constants,
        options,
      }));
      disabledCreate.current = false;
    }
  };

  // const callbackSetLifeTime = (date) => {
  //   const { lifeTime } = date;
  //   if (dateStartAndCreatedDateValidator(
  //     moment().toDate(),
  //     lifeTime,
  //   )) {
  //     return setLifeTime(lifeTime);
  //   }
  //   antNotification('warning', t('notifications.text.warning.incorrect_date', 'incorrect_date'));
  //   return setLifeTime(moment().format('YYYY-MM-DD'));
  // };

  const tagsList = [{ lettersLimit: 25, tags: [...areasForSelect] }];

  return (
    <>
      <Row>
        <Col>
          <h6>
            {capitalize(t('wms.modals.headers.new_project', 'new project'))}
          </h6>
        </Col>
      </Row>
      <Form
        name="basic"
        onFinish={onSubmit}
      >
        <Row>
          <Col span={24}>
            <InfoSection
              iconPath={mdiCardTextOutline}
              title={`${capitalize(t('wms.labels.title', 'title'))}*:`}
              customMargin="pt-3 pb-0"
            >
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    maxLength: 128,
                  },
                ]}
              >
                <Input
                  autoFocus
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value.trim())}
                  maxLength="128"
                  showCount
                  placeholder={capitalize(t('wms.placeholders.validation.numbers_and_alphabet', 'only A-Za-z0-9_'))}
                  ref={projectTitleRef}
                />
              </Form.Item>
            </InfoSection>
          </Col>
        </Row>
        <hr className="mb-3 mt-0" />

        <Row>
          <Col span={24}>
            <div className="flex">
              <Icon
                path={mdiTextBoxOutline}
                size={0.9}
                className="text-secondary"
                style={{ minWidth: 24 }}
              />
              <div className="flex pl-2 flex-wrap">
                <span className="text-secondary">
                  {capitalize('basic project documentation')}
                  :
                </span>
              </div>
            </div>
            <div className="flex pl-2 flex-wrap">
              <span style={{ marginLeft: '24px' }} className="text-secondary mr-3">
                {`${capitalize(t('wms.projectflow.project.info.link', 'create description document'))}?`}
              </span>
              <Switch
                onChange={(e) => setIsNeedToCreateProjectDoc(e)}
                defaultChecked
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </div>
            <div className="flex pl-2 flex-wrap">
              <span style={{ marginLeft: '24px' }} className="text-secondary mr-3">
                {`${capitalize('Create project infrastructure document')}?`}
              </span>
              <Switch
                onChange={(e) => setIsNeedToCreateProjectInfrastructureDoc(e)}
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <InfoSection
              iconPath={mdiTagMultipleOutline}
              title={`${capitalize(t('wms.table.headers.tags', 'tags'))}:`}
              customMargin="pt-3 pb-0"
            >
              <CheckMainAreaContainer>
                <TagsSettingList
                  onSelectTags={changeSelectedAreas}
                  allTags={tagsList}
                  size="small"
                />
              </CheckMainAreaContainer>
            </InfoSection>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <InfoSection
              iconPath={mdiAccountSupervisorCircleOutline}
              title={(
                <Flex className="w-full">
                  <AssigningUsersRoot
                    header="Assigning members"
                    isOpen={isAssignOpen}
                    setIsOpen={setIsAssignOpen}
                    onUpdateUsers={onUserChange}
                    needCheckUserPerm={false}
                    globalOptions={{
                      uuids: formData?.visibleUsers ?? [],
                      roles: assignedUsersData ?? [],
                      perms: assignedUsersPerms,
                      isNewProjectMode: true,
                      needLoadPerms: true,
                      defaultColumn: 'all',
                    }}
                    placement="left"
                    isPopover
                    tabs={{
                      allConfig: {
                        label: 'All',
                        value: 'all',
                        textSelectButton: 'Add member',
                        showSections: ['button-add-user'],
                      },
                    }}
                    usersConfig={{
                      label: 'Project users',
                      showSections: ['perms', 'admin', 'input-role', 'button-delete-user', 'string-role'],
                      defaultItems: formData.visibleUsers ?? [],
                    }}
                    partition={partitionType}
                    popoverText="Members"
                  />
                  <WrapperAvatarsGroupForProjectWithHook
                    actors={formData?.visibleUsers || []}
                    avatarSize={26}
                    popoverTrigger="click"
                    popoverPlacement="right"
                    checkInProject={false}
                  />
                </Flex>
              )}
              customMargin="pt-3 pb-0"
            />
          </Col>
          <Col span={24}>
            <InfoSection
              iconPath={mdiAccountSupervisorCircleOutline}
              title={(
                <Flex className="w-full">
                  <AssigningUsersRoot
                    header="Assigning admins"
                    isOpen={isAssignAdminOpen}
                    setIsOpen={setIsAssignAdminOpen}
                    onUpdateUsers={onUserChange}
                    globalOptions={{
                      uuids: formData?.visibleUsers ?? [],
                      roles: assignedUsersData ?? [],
                      perms: assignedUsersPerms,
                      needLoadPerms: true,
                      isNewProjectMode: true,
                      isAdminMode: true,
                      defaultColumn: 'all',
                    }}
                    placement="left"
                    isPopover
                    tabs={{
                      allConfig: {
                        label: 'All',
                        value: 'all',
                        textSelectButton: 'Add admin',
                        showSections: ['button-add-user'],
                      },
                    }}
                    usersConfig={{
                      label: 'Project users',
                      showSections: ['perms', 'admin', 'input-role', 'button-delete-user', 'string-role'],
                      defaultItems: formData.visibleUsers ?? [],
                    }}
                    partition={partitionType}
                    popoverText="Admins"
                  />
                  <WrapperAvatarsGroupForProjectWithHook
                    actors={getAdminUuids || []}
                    avatarSize={26}
                    popoverTrigger="click"
                    checkInProject={false}
                    popoverPlacement="right"
                  />
                </Flex>
              )}
              customMargin="pt-3 pb-0"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InfoSection
              iconPath={mdiListStatus}
              title={capitalize(t('wms.labels.status', 'status'))}
            >
              <Form.Item name="status" initialValue="created">
                <div className="radioButtons">
                  <Radio.Group defaultValue="created">
                    {statusOptions(t).map((item) => (
                      <Radio
                        className={item.className}
                        key={`project_priority_${item}`}
                        value={item.value}
                      >
                        {item.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
              </Form.Item>

            </InfoSection>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="mt-3">
          <BaseButton
            onClick={cancelBtnCallback}
            className="mr-2"
          >
            {capitalize(t('wms.verb.cancel', 'cancel'))}
          </BaseButton>
          <BaseButton
            htmlType="submit"
            type="primary"
            loading={projectEntityFetching || isCreatingProject}
            disabled={fetchingNewProject || !projectName || length(projectName) === 0}
          >
            {capitalize(
              t('wms.buttons.create.project', 'create project'),
            )}
          </BaseButton>

        </Row>
      </Form>
    </>
  );
}

NewProject.propTypes = {
  newProjectCallback: PropTypes.func,
  cancelBtnCallback: PropTypes.func,
  partitionType: PropTypes.string,
  customParent: string,
  isAssignOpen: bool,
  setIsAssignOpen: func,
  isAssignAdminOpen: bool,
  setIsAssignAdminOpen: func,
};

export default NewProject;

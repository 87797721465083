import { useRef, useEffect, useCallback } from 'react';

const useFocus = () => {
  const htmlElRef = useRef(null);

  const setFocus = useCallback(() => {
    htmlElRef.current && htmlElRef.current.focus();
  }, []);

  useEffect(() => {
    setFocus();
  }, [setFocus]);

  return [htmlElRef, setFocus];
};

export default useFocus;

import { useEffect, useState } from 'react';
import PropTypes, { func } from 'prop-types';
import { useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';

import { checkEntityPerm } from '../../../entity/actions/entityPermActions';
import { partitionNamesConfig } from '../../../api/appConfig';

function CheckEntityPerm({
  children,
  entityUUID,
  partitionType = partitionNamesConfig.partition1,
  permission = ['delete'],
  isDisplaySectionCallback,
}) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();

  const [displaySection, changeDisplayFlag] = useState(false);

  const checkPerms = () => {
    const data = {
      entity_uuid: entityUUID,
      perm: permission,
      partition: partitionType,
    };

    // console.log('PERM DATA:', data);

    dispatch(checkEntityPerm(data)).then((res) => {
      // console.log('PERM CHECK', res);
      changeDisplayFlag(res);
      if (isDisplaySectionCallback) {
        isDisplaySectionCallback(res);
      }
    });
  };

  useEffect(() => {
    if (entityUUID) {
      checkPerms();
    }
  }, []);

  return displaySection && children;
}

CheckEntityPerm.propTypes = {
  children: PropTypes.element,
  entityUUID: PropTypes.string,
  partitionType: PropTypes.string,
  permission: PropTypes.array,
  isDisplaySectionCallback: func,
};

export default CheckEntityPerm;

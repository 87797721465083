// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2024.07.04 || YYYY/MM/DD
import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import {
  Tooltip, Button, Modal, Row, Col, Alert, notification,
} from 'antd';
import { capitalize, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { stopPropagation } from '../utils54origins';
import { getCookie, setCookie } from '../cookies54origins';
import { getSessionTokenFor } from '../../api/appConfig';

const TURN_ON_MASQUERADING_REQUEST = 'TURN_ON_MASQUERADING_REQUEST';
const TURN_ON_MASQUERADING_SUCCESS = 'TURN_ON_MASQUERADING_SUCCESS';
const TURN_ON_MASQUERADING_FAILURE = 'TURN_ON_MASQUERADING_FAILURE';

function MasqueradingBtn54origins({
  url,
  actorUUID,
  btnIcon,
  btnLabel = 'M',
  btnSize = 'small',
  btnType,
  className,
  masqueradingDisabled,
  hideTooltip = true,
  tooltipPlacement = 'top',
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useReducer((previous) => !previous, false);

  const reloadBrowserPage = () => {
    // history.go(0) for react-router v5
    navigate(0);
  };

  const requestTurnOnMasquerading = (uuid) => new Promise((resolve, reject) => {
    dispatch({ type: TURN_ON_MASQUERADING_REQUEST });

    axios({
      method: 'post',
      url: `${url}/masquerade/on/`,
      data: { actor_uuid: uuid },
      headers: {
        'Session-Token': getSessionTokenFor.entity(),
      },

    })
      .then(({ data }) => {
        dispatch({ type: TURN_ON_MASQUERADING_SUCCESS });
        resolve(data);
      })
      .catch(({ error }) => {
        dispatch({ type: TURN_ON_MASQUERADING_FAILURE });
        reject(error);
      });
  });

  const masqueradingOn = (masqueradeSession, primarySession) => new Promise((resolve, reject) => {
    try {
      if (!getCookie('EntityPrimary')) {
        setCookie('EntityPrimary', primarySession, { path: '/' });
      }
      setCookie('Entity', masqueradeSession, { path: '/' });

      resolve();
    } catch (e) {
      reject();
    }
  });

  const onSubmit = () => {
    requestTurnOnMasquerading(actorUUID).then((response) => {
      const masqueradeSession = get(response, 'masquerade_session');
      const primarySession = get(response, 'primary_session');

      if (masqueradeSession && primarySession) {
        masqueradingOn(masqueradeSession, primarySession).then(() => {
          notification.warning(capitalize(t(
            'auth.notifications.masquerading_on',
            'you entered masquerading mode',
          )));
          navigate('/login');
          reloadBrowserPage();
        });
      }
    })
      .catch(() => notification.error(capitalize(t(
        'auth.notifications.masquerading_failed',
        'error switching to masquerading mode',
      ))));

    setIsModalVisible();
  };

  const btn = (
    <Button
      className={`button-warning-outlined ${className}`}
      size={btnSize}
      icon={btnIcon}
      disabled={masqueradingDisabled}
      type={btnType}
      onClick={(e) => {
        stopPropagation(e);
        setIsModalVisible();
      }}
      onDoubleClick={stopPropagation}
    >
      {btnLabel &&
        <span style={{padding: '0 1px'}}>
          {btnLabel}
        </span>
      }
    </Button>
  );

  return (
    <>
      {hideTooltip ? btn : (
        <Tooltip
          placement={tooltipPlacement}
          title={capitalize(t('wms.verb.masquerading', 'masquerading'))}
        >
          {btn}
        </Tooltip>
      )}
      <Modal
        title={capitalize(t('wms.verb.masquerading', 'masquerading'))}
        footer={[
          <div className="flex">
            <Button className="ml-auto" key="back" onClick={setIsModalVisible}>
              {capitalize(t('wms.verb.cancel', 'cancel'))}
            </Button>
            <Button key="submit" type="primary" onClick={onSubmit}>
              {capitalize(t('wms.verb.confirm', 'confirm'))}
            </Button>
          </div>,
        ]}
        closeIcon={<Icon path={mdiClose} size={1.5} />}
        // onOk={onSubmit}
        onCancel={setIsModalVisible}
        open={isModalVisible}
        destroyOnClose
        maskClosable
        centered
      >
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Alert
              message={capitalize(t('wms.noun.warning', 'warning'))}
              description={capitalize(t('wms.messages.masquerading_warning', 'masquerading mode'))}
              type="warning"
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default MasqueradingBtn54origins;

MasqueradingBtn54origins.propTypes = {
  actorUUID: PropTypes.string,
  btnIcon: PropTypes.any,
  btnLabel: PropTypes.string,
  btnSize: PropTypes.string,
  btnType: PropTypes.string,
  className: PropTypes.string,
  masqueradingDisabled: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
  url: PropTypes.string.isRequired,
};

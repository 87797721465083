import React from "react";

export const JSpreadsheetHelpContent = () => {
  return (
    <>
      <strong>Formulas: </strong>
      <ul style={{ color: "black" }} className="m-0 flex flex-col">
        <li className="pb-1">
          <span>=SUM(cell1:cell2)</span> - performs summs on range of cells;
        </li>
        <li className="pb-1">
          <span>=MIN(cell1:cell2)</span> - finds the minimum value in a range of
          cells;
        </li>
        <li className="pb-1">
          <span>=MAX(cell1:cell2)</span> - finds the maximum value in a range of
          cells;
        </li>
        <li className="pb-1">
          <span>=ROUND(cell, digits of precision)</span> - rounds a number to a
          specified number of decimal places;
        </li>
        <li className="pb-1">
          <span>=AVERAGE(cell1:cell2)</span> - calculates the average of a range
          of cells;
        </li>
        <li className="pb-1">
          <span>=COUNT(cell1:cell2)</span> - counts the number of cells in a
          range that contains numbers;
        </li>
        <li className="pb-1">
          <span>
            =IF(condition, &ldquo;result1&rdquo;, &ldquo;result2&rdquo;)
          </span>{" "}
          - performs a conditional test and returns different values based on
          the result;
        </li>
        <li className="pb-1">
          <span>=CONCATENATE(cell1, &ldquo;separator&rdquo;, cell2)</span> -
          joins two or more text strings together;
        </li>
        <li>
          <span>=MEDIAN(cell1:cell2)</span> - calculates the median of a range
          of numbers.
        </li>
      </ul>
    </>
  );
};

import React, { useEffect, useReducer, useState } from 'react';
import {
  Card, Col, Row, Tag, Tooltip,
} from 'antd';
import PropTypes, { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  pathOr, prop,
} from 'ramda';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { get, omit, uniq } from 'lodash';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';

import { getUuidsfromParamsUsers } from '../../../MainUtils';
import { capitalize } from 'lodash';
// import { getLocale } from '../../locale/selectors';
import { getFilteredEntity } from '../../../entity/selectors/selectors';
import { getIssuesWithProjectName } from '../../../projectFlow/selectors/issueSelectors';
import FiltersPersonalTasks from './FiltersPersonalTasks';
import TagsSettingList from '../../../components/filter/TagsSettingList';
import DateLeft from '../../../components/DateLeft';
import useActorsW54 from '../../../actors/hooks/useActorsW54';
import WrapperAvatarsGroupForProject from '../../../projectFlow/components/commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import BaseTag from "../../../components/_ui/BaseTag/BaseTag";
import { partitionNamesConfig } from '../../../api/appConfig';

const getFilteredIssues = (state) => getIssuesWithProjectName(
  state,
  getFilteredEntity(state, 'personalDashboardIssues'),
);

function PersonalListOfTasks({
  myProjectsUuids,
  changeIssue,
  selectedIssue,
  getTagsConfigAndSendRequest,
  myUuid,
  hidden,
  customDefaultSelectedTags,
  view,
  customCreateIssueModalConfig,
  defaultTagsConfig,
  totalCount,
  userUUID,
}) {
  const { t } = useTranslation();
  // const locale = useSelector(getLocale);

  const firstItemForProjectList = { label: capitalize(t('wms.tabs.all_projects')), value: 'all' };

  // ПЕРЕПИСАТЬ
  const filteredIssues = useSelector(getFilteredIssues);

  const [canvasFlag, toggleCanvasFlag] = useReducer((previous) => !previous, false);
  const [filterProject, setFilterProject] = useState(firstItemForProjectList);
  const [finalIssues, setFinalIssues] = useState([]);
  const [configIssue, setConfigIssue] = useState(defaultTagsConfig);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, changePageLimit] = useState(25);
  const [currentSelectedTags, setCurrentSelectedTags] = useState(customDefaultSelectedTags);

  // const [dataTable, setDataTable] = useState([]);

  // Получение пользователей
  const userUUIDs = finalIssues?.flatMap((item) => getUuidsfromParamsUsers(item?.users));
  useActorsW54({ actors: uniq(userUUIDs) });

  const [paginationOptions, changePaginationOptions] = useState({
    pageLimit,
    currentPage,
    offset: pageLimit * (currentPage - 1),
  });
  const [orderRules, changeOrderRules] = useState({
    orderBy: 'created',
    order: 'desc',
  });

  const tagsSelectedIssue = [{ tags: currentSelectedTags }];

  const changeSort = (param, sortOrder) => {
    changeOrderRules({
      orderBy: param,
      order: sortOrder === 'ascend' ? 'asc' : 'desc',
    });
  };

  const setTagConfigCallback = (config) => {
    const newConfig = { ...config };
    if (configIssue?.issuesParams?.params?.search_data) {
      newConfig.issuesParams.params.search_data = configIssue.issuesParams.params.search_data;
    }
    setConfigIssue(config);
    getTagsConfigAndSendRequest(config);

    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const onSelectTagsAndGetIssues = (data) => {
    // changeSelectedTags(data);
    setCurrentSelectedTags(data);
    const paramsKeys = ['status', 'tracker'];
    // console.log('data',data)
    const params = data.reduce((previous, current) => {
      if (current?.key === 'status' && current?.value === 'lifeTime') {
        if (current?.ruleOfTag === 'included') {
          return {
            ...previous,
            lifeTime__lt: moment().format('YYYY-MM-DD'),
          };
        }

        if (current?.ruleOfTag === 'excluded') {
          return {
            ...previous,
            lifeTime__gt: moment().format('YYYY-MM-DD'),
          };
        }

        if (current?.ruleOfTag === 'off') {
          return omit(previous, ['lifeTime__lt', 'lifeTime__gt']);
        }
      }

      if (paramsKeys.indexOf(current.key) !== -1) {
        const paramKey = get(current, 'status', 'off') === 'excluded'
          ? `${current.key}__not` : current.key;

        return {
          ...previous,
          [paramKey]: previous[paramKey]
            ? [...get(previous, paramKey), current.value]
            : [current.value],
        };
      }
      return { ...previous };
    }, {});
    // console.log('params', params);
    const issuesParams = {
      params: {
        entity_type: 'issue',
        params: {
          ...params,
        },
      },
    };

    if (pageLimit) {
      issuesParams.params.limit = pageLimit;
      issuesParams.params.offset = 0;
    }

    if (data.some(({ key }) => key === 'assigned') && myUuid) {
      issuesParams.params.params.usersSearch = [myUuid];
    }

    if (data.some(({ key }) => key === 'actor') && myUuid) {
      issuesParams.params.actor = myUuid;
    }

    const dateTag = data.find(({ key }) => key === 'date');

    if (dateTag) {
      let startDate;
      let endDate;
      const dateFormat = 'YYYY-MM-DD HH:mm:ss';

      const getStartDate = (param) => moment()
        .startOf(param)
        .format(dateFormat);

      const getEndDate = (param) => moment().endOf(param).format(dateFormat);

      switch (dateTag.value) {
        case 'today':
          startDate = getStartDate('day');
          endDate = getEndDate('day');
          break;
        case 'this_week':
          startDate = getStartDate('week');
          endDate = getEndDate('week');
          break;
        case 'this_month':
          startDate = getStartDate('month');
          endDate = getEndDate('month');
          break;
        case 'last_week':
          startDate = moment()
            .subtract(1, 'weeks')
            .startOf('week')
            .format(dateFormat);
          endDate = moment()
            .subtract(1, 'weeks')
            .endOf('week')
            .format(dateFormat);
          break;
        case 'last_month':
          startDate = moment()
            .subtract(1, 'month')
            .startOf('month')
            .format(dateFormat);
          endDate = moment()
            .subtract(1, 'month')
            .endOf('month')
            .format(dateFormat);
          break;
        default:
          break;
      }

      issuesParams.params.modified__gte = startDate;
      issuesParams.params.modified__lte = endDate;
    }

    const partitionTypes = data.filter(({ key }) => key === 'partition')
      .map(({ value }) => value);

    const config = {
      partitionTypes,
      issuesParams,
    };

    setTagConfigCallback(config);
  };

  const assignedUsersFormatter = (cell, row) => {
    const users = getUuidsfromParamsUsers(row?.users);

    return (
      <WrapperAvatarsGroupForProject
        checkInProject={false}
        actors={users}
        avatarSize="small"
        avatarGap={4}
        avatarStyles={{ cursor: 'pointer' }}
        groupButtonSize="small"
      />
    );
  };

  const setSearchIssueCallback = (value) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    const newConfig = {
      ...configIssue,
      issuesParams: {
        ...configIssue.issuesParams,
        params: {
          ...configIssue.issuesParams.params,
          limit: pageLimit,
          offset: 0,
          search_data: {
            value,
            ignore_case: true,
            fields: {
              params: ['title', 'id'],
            },
          },
        },
      },
    };
    // console.log('newConfig', newConfig);
    setConfigIssue(newConfig);
    getTagsConfigAndSendRequest(newConfig);
  };

  const changePagination = (data) => {
    changePaginationOptions(data);
  };

  const stopPropagation = (e) => e.stopPropagation();

  const lifeTimeFormatter = (cell) => cell && <DateLeft minus date={cell} type="tag" customStyle="text-neutral-600" />;

  const columns = [
    {
      dataIndex: ['id'],
      title: 'Id',
      className: 'wordBreakOneLine',
      // className: headerSortingStyle,
    },
    {
      dataIndex: ['title'],
      title: (
        <Col className="flex align-item-end minWidth130" span={24}>
          <AntDesignSearchBox54origins
            onSearch={setSearchIssueCallback}
          />
        </Col>
      ),
      render: (cell) => (cell
        ? (
          <EntityParamTag
            param="title"
            value={cell}
            type="value"
          />
        )
        : (
          <span>{'<Empty title>'}</span>
        )),
    },
    {
      dataIndex: ['tracker'],
      title: capitalize(t('wms.table.headers.tracker', 'Tracker')),
      render: (cell) => cell && (
        <EntityParamTag
          param="tracker"
          value={cell}
          type="char"
        />
      ),
      sorter: (a, b, sortOrder) => changeSort('tracker', sortOrder),
    },
    {
      dataIndex: ['priority'],
      title: capitalize(t('wms.table.headers.priority', 'Priority')),
      render: (cell) => cell && (
        <EntityParamTag
          param="priority"
          value={cell}
          type="char"
        />
      ),
      sorter: (a, b, sortOrder) => changeSort('priority', sortOrder),
    },
    {
      dataIndex: ['status'],
      title: capitalize(t('wms.table.headers.status', 'Status')),
      render: (cell) => cell && (
        <EntityParamTag
          param="status"
          value={cell}
          type="iconWithStatus"
        />
      ),
      sorter: (a, b, sortOrder) => changeSort('status', sortOrder),
    },
    {
      dataIndex: ['assigned_users'],
      title: capitalize(t('wms.table.headers.assigned', 'Assigned')),
      render: (cell, row) => assignedUsersFormatter(cell, row),
    },
    {
      dataIndex: ['lifeTime'],
      title: capitalize(t('wms.adjective.expired')),
      sorter: (a, b, sortOrder) => changeSort('lifeTime', sortOrder),
      // sortDirections: defaultWmsSortDirections,
      render: (cell, row) => lifeTimeFormatter(cell, row),
    },
    {
      dataIndex: ['projectName'],
      title: capitalize(t('wms.table.headers.project', 'Project')),
      render: (cell, row) => {
        switch (row.partition) {
          case partitionNamesConfig.partition2:
            return (
              <BaseTag color="volcano">
                {t('wms.table.headers.private', 'private').toUpperCase()}
              </BaseTag>
            );
          case partitionNamesConfig.partition3:
            return (
              <BaseTag color="volcano">
                {t('wms.table.headers.public', 'public').toUpperCase()}
              </BaseTag>
            );
          case partitionNamesConfig.partition1:
            return cell ? (
              <Tooltip
                title={cell.length > 35 ? cell : ''}
                onClick={stopPropagation}
              >
                <a
                  className="hover:underline"
                  href={`/pm/projects/issuesnew?activeProject=${row.project}`}
                >
                  <span className="textTableDashboard">
                    {cell}
                  </span>
                </a>
              </Tooltip>
            ) : (
              capitalize(t('wms.userflow.dashboard.tasks.no_assigned_project', 'no assigned project'))
            );
          default:
            return (
              <Tooltip
                title={cell.length > 35 ? cell : ''}
              >
                <span className="textTableDashboard">{cell}</span>
              </Tooltip>
            );
        }
      },
      sorter: (a, b, sortOrder) => changeSort('project', sortOrder),
    },
  ];

  const onRow = (row) => ({
    onClick: () => changeIssue(row),
  });

  const rowSelection = {
    selectedRowKeys: [selectedIssue],
  };

  const hideViewCreateIssueModal = ['assignedProject', 'assignedVector'];
  const disabledViewCreateIssueModal = ['assignedUsers'];

  const defaultCreateIssueState = {
    title: prop('title', customCreateIssueModalConfig),
    usersUuidsForAssigned: [userUUID],
    assignedUsers: [userUUID],
    users: [{ uuid: userUUID }],
    usersSearch: [userUUID],
  };

  useEffect(() => {
    let data = [...filteredIssues];
    const selectedProject = prop('value', filterProject);

    if (selectedProject !== 'all') {
      data = data.filter((item) => pathOr('', ['project'], item) === selectedProject);
    }

    setFinalIssues(data);
  }, [
    filterProject,
    JSON.stringify(filteredIssues),
    // JSON.stringify(searchIssue),
  ]);

  useEffect(() => {
    if (paginationOptions) {
      const { pageLimit, offset } = paginationOptions || {};
      const { order, orderBy } = orderRules || {};

      const newConfig = {
        ...configIssue,
        issuesParams: {
          ...configIssue.issuesParams,
          params: {
            ...configIssue.issuesParams.params,
            limit: pageLimit,
            offset,
            order,
          },
        },
      };

      newConfig.issuesParams.params[orderBy === 'created' ? 'order_by' : 'order_by_params'] = orderBy;

      if (myProjectsUuids.length) {
        getTagsConfigAndSendRequest(newConfig);
      }
    }
  }, [
    JSON.stringify(myProjectsUuids),
    JSON.stringify(paginationOptions),
    JSON.stringify(orderRules),
  ]);

  return (
    <Col span={selectedIssue ? 14 : 18}>
      <BaseCard className="vector-card-issues-view w-full">
        <Row className="w-full h-full">
          <Col span={canvasFlag ? 7 : 1}>
            <FiltersPersonalTasks
              canvasFlag={canvasFlag}
              hidden={hidden}
              view={view}
              myUuid={myUuid}
              defaultTagsConfig={defaultTagsConfig}
              customSelectedTags={currentSelectedTags}
              filterProject={filterProject}
              setFilterProject={setFilterProject}
              onSelectTagsAndGetIssues={onSelectTagsAndGetIssues}
              toggleCanvas={toggleCanvasFlag}
            />
          </Col>

          <Col span={canvasFlag ? 17 : 23} className="padding-16">
            <Row className="mb-4" type="flex" gutter={[16, 16]}>
              <Col span={11} className="flex">
                <h6 className="mb-0 mr-2">
                  {capitalize(t('wms.userflow.dashboard.tasks.header', 'personal task management'))}
                </h6>
              </Col>
              <Col span={13} className="flex flex-wrap justify-end">
                <TagsSettingList
                  allTags={tagsSelectedIssue}
                  defaultSelectedTags={currentSelectedTags}
                  onSelectTags={onSelectTagsAndGetIssues}
                  size="extraSmall"
                />
              </Col>
            </Row>

            <Row>
              <Col span={24} className="collapsedTable-table">
                <BaseTableWithPagination
                  pageLimit={pageLimit}
                  changePageLimit={changePageLimit}
                  useCustomPagination
                  columns={columns}
                  data={finalIssues}
                  disablePagination={totalCount <= 25}
                  getPaginationOptions={changePagination}
                  onRow={onRow}
                  rowClassName="cursor-pointer"
                  rowKey="uuid"
                  rowSelection={rowSelection}
                  size="small"
                  total={totalCount}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </BaseCard>
    </Col>
  );
}

PersonalListOfTasks.propTypes = {
  changeIssue: PropTypes.func,
  customCreateIssueModalConfig: PropTypes.object,
  customDefaultSelectedTags: PropTypes.any,
  defaultTagsConfig: PropTypes.object,
  getTagsConfigAndSendRequest: PropTypes.func,
  hidden: PropTypes.bool,
  myUuid: PropTypes.string,
  selectedIssue: PropTypes.string,
  totalCount: PropTypes.number,
  view: PropTypes.string,
  userUUID: string,
};

export default PersonalListOfTasks;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal, Row, Tabs
} from "antd";
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { get, isEmpty } from 'lodash';

import CheckMainAreaContainer from '../../containers/areas/CheckMainAreaContainer';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import AreasManagement from './AreasManagement';
import CheckEntityPerm from '../../containers/permissions/CheckEntityPerm';
import PermissionsStartPage from '../../containers/permissions/PermissionsStartPage';

import { getMainAreaUUID } from '../../../entity/selectors/selectors';
import { getAreas } from '../../selectors/selectors';
import { antNotification } from '../../../MainUtils';
import { capitalize } from 'lodash';
import { EntityConstants } from '../../../entity/constants/Constants';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { entityDelete, getListAndPartialReadEntities } from '../../../entity/actions/entityActions';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import "./MainAreasManagementTemplate.scss"

const { TabPane } = Tabs;

function MainAreasManagementTemplate() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mainAreaEntityUuid = useSelector(getMainAreaUUID);
  const allAreas = useSelector(getAreas);

  const [pageLimit, changePageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const defaultConfig = {
    limit: 10,
    offset: 0,
  };

  const [currentConfig, setCurrentConfig] = useState(defaultConfig);

  async function getAllAreas(newConfig) {
    const config = {
      data: {
        entity_type: 'area',
        parent: mainAreaEntityUuid,
        ...newConfig,
        params_fields: {
          name: 'name',
        },
        fields: { 'actor.created': 'created' },
      },
      constants: [
        ProjectTypeCommonConstants.GET_AREAS_REQUEST,
        ProjectTypeCommonConstants.GET_AREAS_SUCCESS,
        ProjectTypeCommonConstants.GET_AREAS_FAILURE,
      ],
    };

    const data = await dispatch(getListAndPartialReadEntities(config));
    setTotalCount(get(data, 'total'));
  }

  const removeFunc = async (UUID) => {
    const data = {
      entity_uuid: UUID,
      entity_type: 'area',
    };

    const constants = [
      EntityConstants.HARD_DELETE_PERM_REQUEST,
      EntityConstants.HARD_DELETE_PERM_SUCCESS,
      EntityConstants.HARD_DELETE_PERM_FAILURE,
    ];

    const options = {
      onSuccess: (response, state, status) => {
        if (status !== 200) {
          antNotification(
            'error',
            t('notifications.text.error.base', 'Error'),
          );
        }
      },
    };

    return dispatch(entityDelete({
      data,
      constants,
      options,
    }));
  };

  const showConfirmDeleteUser = (cell) => {
    Modal.confirm({
      title: capitalize(t('wms.modals.headers.removing_area', 'removing area')),
      width: '600px',
      content: capitalize(
        t(
          'wms.delete_entity.area',
          'You\'re going to remove this area. Are you sure',
        ),
      ),
      okText: capitalize(t('wms.buttons.remove.yes', 'yes')),
      okType: 'danger',
      cancelText: capitalize(t('wms.buttons.remove.no', 'no')),
      open: true,
      centered: true,
      mask: false,
      okButtonProps: { className: 'dangerButton' },
      className: 'custom-confirm-delete',

      onOk() {
        removeFunc(cell);
        getAllAreas(currentConfig);
      },

      onCancel() { },
    });
  };

  const onPageChange = ({ pageLimit: newPageLimit, currentPage: newCurrentPage, offset }) => {
    setCurrentPage(newCurrentPage);
    setCurrentConfig({
      limit: newPageLimit,
      offset,
    });
  };

  const actionsFormatter = (cell) => (
    <div
      className="delete_icon_button"
      onClick={() => showConfirmDeleteUser(cell)}
      role="button"
      tabIndex="0"
    >
      <Icon
        path={mdiTrashCanOutline}
        size={0.8}
      />
    </div>
  );

  const remotePagination = {
    sizePerPageList: [10, 25, 50, 100],
    defaultPageLimit: 10,
    totalSize: totalCount,
    page: currentPage,
    onChange: onPageChange,
  };

  const areaColumns = [
    {
      dataIndex: ['name'],
      title: capitalize(t('wms.table.headers.area.description', 'area description')),
    },
    {
      dataIndex: ['created'],
      title: capitalize(t('wms.table.headers.created', 'created')),
    }, {
      dataIndex: ['uuid'],
      title: capitalize(t('wms.table.headers.actions', 'actions')),
      render: actionsFormatter,
    },
  ];

  useEffect(() => {
    if (mainAreaEntityUuid && !isEmpty(mainAreaEntityUuid) && !isEmpty(currentConfig)) {
      getAllAreas(currentConfig);
    }
  }, [mainAreaEntityUuid, currentConfig]);

  return (
    <div className="flex items-stretch h-full w-full mt-3">
      <CheckMainAreaContainer>
        <Tabs defaultActiveKey="1" className="tab_area">
          <TabPane tab={capitalize(t('wms.tabs.areas', 'areas'))} key="1">
            <BaseCard className="h-full collapsedTable-table">
              <div className="flex items-center mb-2">
                <h5 className="inline-flex mr-2 mb-0">{capitalize(t('wms.tabs.areas', 'areas'))}</h5>
                <CheckEntityPerm
                  entityUUID={mainAreaEntityUuid}
                  permission={['update']}
                // isDisplaySectionCallback={setViewTitle}
                >
                  <AreasManagement areas={allAreas} />
                </CheckEntityPerm>
              </div>
              <BaseTableWithPagination
                pageLimit={pageLimit}
                changePageLimit={changePageLimit}
                useCustomPagination
                columns={areaColumns}
                data={allAreas}
                total={get(remotePagination, 'totalSize')}
                pageSizeOptions={get(remotePagination, 'sizePerPageList')}
                disablePagination={get(remotePagination, 'totalSize') <= get(remotePagination, 'defaultPageLimit')}
                newCurrentPage={get(remotePagination, 'page')}
                defaultPageLimit={get(remotePagination, 'defaultPageLimit')}
                getPaginationOptions={get(remotePagination, 'onChange')}
                paginationSize="small"
                headerRowClassName="universal_header_table_row"
              />
            </BaseCard>
          </TabPane>
          <TabPane tab={capitalize(t('wms.tabs.areas_permissions', 'areas permissions'))} key="2">
            <Row className="w-full">
              <PermissionsStartPage
                entityUUID={mainAreaEntityUuid}
              />
            </Row>
          </TabPane>
        </Tabs>
      </CheckMainAreaContainer>
    </div>
  );
}

MainAreasManagementTemplate.propTypes = {

};

export default MainAreasManagementTemplate;

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import { prop, isEmpty } from 'ramda';
import { Row } from 'antd';

import CustomCardComponent from '../commonComponents/CustomCardComponent';
import ExpandModeLayout from '../commonComponents/expandModeLayout/ExpandModeLayout';
import ListOfGoals from '../goals/ListOfGoals';
import ListOfMilestones from '../milestones/ListOfMilestones';
import ProjectGoalView from '../goals/ProjectGoalView';
import ProjectGoalsScope from "../goals/ProjectGoalsScope";
import ProjectMilestoneView from '../milestones/ProjectMilestoneView';

import useURLParams from '../../../hooks/useURLParams';

import { getProjectUUID, globalUpdateEntity } from '../../selectors/selectors';
import {
  getProjectGoalRequest,
  getListOfProjectGoalsRequest,
} from '../../actions/projectGoalsActions';
import {
  getListOfProjectMilestonesRequest,
  getProjectMilestoneRequest,
} from '../../actions/projectMilestonesActions';
import {
  CLEAR_PROJECT_GOAL,
  CLEAR_ALL_PROJECT_GOALS,
  selectLastCreatedGoal,
} from '../../reducers/slicers/projectGoalsSlicer';
import {
  CLEAR_ALL_PROJECT_MILESTONES,
  CLEAR_PROJECT_MILESTONE,
  selectLastCreatedMilestone,
} from '../../reducers/slicers/projectMilestonesSlicer';
import { clearGlobalUpdateEntity } from '../../reducers/slicers/breadcrumbsSlicer';
import { DELETE_PROJECT_KPM } from '../../reducers/slicers/projectsKPMSlice';

import useTableFilter from '../../../components/_ui/hooks/useTableFilter';
import {
  columnsMilestoneFilterConfig,
  defaultMilestoneFilter,
} from '../milestones/constants/milestoneFilterOptions';
import {
  columnsGoalFilterConfig,
  defaultGoalFilter,
} from '../goals/constants/goalFilterOptions';

const hideElements = ['goToBtn'];

const defaultMilestoneOrder = {
  orderBy: 'lifeTime',
  order: 'asc',
};

const defaultMilestonePagination = {
  currentPage: 1,
  offset: 0,
  limit: 10,
};

const defaultGoalOrder = {
  orderBy: 'handleSort',
  order: 'desc',
};

const defaultGoalPagination = {
  currentPage: 1,
  offset: 0,
  limit: 10,
};

function PlanningView({
  defaultPartition,
}) {
  const dispatch = useDispatch();

  const projectUUID = useSelector(getProjectUUID);
  const lastCreatedGoal = useSelector(selectLastCreatedGoal);
  const lastCreatedMilestone = useSelector(selectLastCreatedMilestone);
  const globalUpdate = useSelector(globalUpdateEntity);

  const firstRender = useRef(true);

  const {
    addAndRemoveSearchParams,
    clearSearchParams,
    getURLParams,
  } = useURLParams();

  const {
    goal: goalUUIDFromURL,
    milestone: milestoneUUIDFromURL,
    scope: scopeURL,
  } = getURLParams();

  const {
    filterConfig: goalFilterConfig,
    changeFilterConfig: changeGoalFilterConfig,
  } = useTableFilter('goal', defaultGoalFilter);
  // console.log('defaultGoalFilter:', defaultGoalFilter);

  const defaultGoalsConfig = {
    ...defaultGoalPagination,
    ...defaultGoalOrder,
    ...goalFilterConfig,
  }

  const [goalsConfig, setGoalsConfig] = useState(defaultGoalsConfig);
  const [totalCountGoals, setTotalCountGoals] = useState(0);

  const {
    filterConfig: milestoneFilterConfig,
    changeFilterConfig: changeMilestoneFilterConfig,
  } = useTableFilter('milestone', defaultMilestoneFilter);
  // console.log('defaultMilestoneFilter:', defaultMilestoneFilter);

  const defaultMilestonesConfig = {
    ...defaultMilestonePagination,
    ...defaultMilestoneOrder,
    ...milestoneFilterConfig,
  }

  const [milestonesConfig, setMilestonesConfig] = useState(defaultMilestonesConfig);
  const [totalCountMilestones, setTotalCountMilestones] = useState(0);

  const [planningType, setPlanningType] = useState('scope');
  // console.log('planningType', planningType);

  const changePlanningHistory = (key, uuid) => {
    // const path = `${initialPathForHistory}${pageUrl}`;
    // console.log('changePlanningHistory', key, uuid);

    switch (key) {
      case 'goal':
        addAndRemoveSearchParams({ goal: uuid }, ['milestone', 'scope']);
        break;

      case 'resetGoal':
        clearSearchParams(['goal']);
        break;

      case 'milestone':
        addAndRemoveSearchParams({ milestone: uuid }, ['goal', 'scope']);
        break;

      case 'resetMilestone':
        clearSearchParams(['milestone']);
        break;

      case 'scope':
        addAndRemoveSearchParams({ scope: 'goals'}, ['goal', 'milestone']);
        break;
      default:
        break;
    }
  };

  //----------------------------------------------------------------
  // Milestones functions
  const loadProjectMilestones = async (properties) => {
    const {
      importance,
      status,
      search,
      orderBy,
      order,
      offset,
      limit,
    } = properties || {};

    const config = {
      parent: projectUUID,
      params: {},
      orderBy,
      order,
      offset,
      limit,
    };

    if (importance && importance?.length) {
      config.params.importance = importance;
    }

    if (status && status?.length) {
      config.params.status = status;
    }

    if (search) {
      config.search_data = {
        value: search,
        ignore_case: true,
        fields: {
          params: ['title'],
        },
      };
    }

    if (!projectUUID) return;
    // console.log('milestones config for request', config);

    const data = await dispatch(
      getListOfProjectMilestonesRequest(config, defaultPartition)
    );
    setTotalCountMilestones(prop('total', data));
    return prop('data', data);
  };

  const setMilestonesConfigAndGetRequest = (newConfig) => {
    // console.log('milestones new config:', newConfig);
    setMilestonesConfig(newConfig);
    loadProjectMilestones(newConfig);
  };

  const selectMilestone = useCallback((uuid) => {
    setPlanningType('milestone');
    changePlanningHistory('milestone', uuid);
  }, []);

  const resetMilestone = useCallback(() => {
    setPlanningType('milestone');
    changePlanningHistory('resetMilestone');
  }, []);

  const createProjectMilestoneCallback = (newMilestoneUUID) => {
    setPlanningType('milestone');
    loadProjectMilestones(milestonesConfig);
    selectMilestone(newMilestoneUUID);
  };

  const deleteProjectMilestoneCallback = () => {
    setPlanningType('milestone');
    dispatch(CLEAR_PROJECT_MILESTONE());
    dispatch(DELETE_PROJECT_KPM({ uuid: projectUUID }));
    changePlanningHistory('resetMilestone');
    loadProjectMilestones(milestonesConfig);
  };

  const updateProjectMilestoneCallback = () => {
    setPlanningType('milestone');
    loadProjectMilestones(milestonesConfig);
  };

  //----------------------------------------------------------------
  // Goals functions
  const loadProjectGoals = async (properties) => {
    const {
      status,
      search,
      orderBy,
      order,
      offset,
      limit,
    } = properties || {};

    const config = {
      parent: projectUUID,
      params: {},
      orderBy,
      order,
      offset,
      limit,
    };

    if (status && status?.length) {
      config.params.status = status;
    }

    if (search) {
      config.search_data = {
        value: search,
        ignore_case: true,
        fields: {
          params: ['title'],
        },
      };
    }

    if (!projectUUID) return;
    // console.log('goals config for request', config);

    const data = await dispatch(
      getListOfProjectGoalsRequest(config, defaultPartition)
    );
    setTotalCountGoals(prop('total', data));
    return prop('data', data);
  };

  const setGoalsConfigAndGetRequest = (newConfig) => {
    // console.log('goals new config:', newConfig);
    setGoalsConfig(newConfig);
    loadProjectGoals(newConfig);
  };

  const selectGoal = useCallback((uuid) => {
    if(uuid === 'goalsScope') {
      setPlanningType('scope');
      changePlanningHistory('scope', uuid);
    } else {
      setPlanningType('goal');
      changePlanningHistory('goal', uuid);
    }
  }, []);

  const resetGoal = useCallback(() => {
    setPlanningType('goal');
    changePlanningHistory('resetGoal');
  }, []);

  const createProjectGoalCallback = (newGoalUUID) => {
    setPlanningType('goal');
    loadProjectGoals(goalsConfig);
    selectGoal(newGoalUUID);
  };

  const deleteProjectGoalCallback = () => {
    setPlanningType('goal');
    dispatch(CLEAR_PROJECT_GOAL());
    changePlanningHistory('resetGoal');
    loadProjectGoals(goalsConfig);
  };

  const updateProjectGoalCallback = () => {
    setPlanningType('goal');
    loadProjectGoals(goalsConfig);
  };

  const priorityBtnCallback = () => {
    loadProjectGoals(goalsConfig);
  }

  const updateProjectGoalScopeCallback = () => {
    loadProjectGoals(goalsConfig)
  }

  // useEffect(
  //   () => () => {
  //     dispatch(CLEAR_ALL_PROJECT_GOALS());
  //     dispatch(CLEAR_ALL_PROJECT_MILESTONES());
  //   },
  //   [projectUUID],
  // );

  useEffect(() => {
    if (goalUUIDFromURL) {
      dispatch(getProjectGoalRequest({
        uuid: goalUUIDFromURL,
        partition: defaultPartition,
      }))
        .catch(() => changePlanningHistory('resetGoal'));
    }

    if (milestoneUUIDFromURL) {
      dispatch(getProjectMilestoneRequest({
        uuid: milestoneUUIDFromURL,
        partition: defaultPartition,
      }))
        .catch(() => changePlanningHistory('resetMilestone'));
    }
  }, [milestoneUUIDFromURL, goalUUIDFromURL]);

  useEffect(() => {
    if(!milestoneUUIDFromURL && !goalUUIDFromURL){
      switch (planningType) {
        case 'milestone':
          if (!isEmpty(lastCreatedMilestone)) {
            const lastMilestoneUUID = lastCreatedMilestone.uuid;
            selectMilestone(lastMilestoneUUID);
          }
          break;
        case 'goal':
          if (!isEmpty(lastCreatedGoal)) {
            const lastGoalUUID = lastCreatedGoal.uuid;
            selectGoal(lastGoalUUID);
          }
          break;
        case 'scope':
          changePlanningHistory('scope')
          break;
        default:
          changePlanningHistory('scope')
          break;
      }
    }
  }, [JSON.stringify(lastCreatedGoal), JSON.stringify(lastCreatedMilestone)]);

  useEffect(() => {
    if (!firstRender.current && globalUpdate.entityType) {
      // console.log('effect global update');
      if (globalUpdate.entityType === 'projectMilestone') {
        resetMilestone();
        loadProjectMilestones(milestonesConfig);
      }
      if (globalUpdate.entityType === 'projectGoal') {
        resetGoal();
        loadProjectGoals(goalsConfig);
      }
      dispatch(clearGlobalUpdateEntity());
    }
  }, [globalUpdate.entityType]);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  const selectedGoal = scopeURL ? 'goalsScope' : goalUUIDFromURL

  return (
    <Row className="h-full">
      <ExpandModeLayout localStorageTarget="planningView">
        <ExpandModeLayout.Table>
          <ListOfMilestones
            className="mb-3"
            partition={defaultPartition}
            totalCount={totalCountMilestones}
            selectedMilestone={milestoneUUIDFromURL}
            defaultOrderConfig={defaultMilestoneOrder}
            defaultPaginationConfig={defaultMilestonePagination}
            filterColumnsConfig={columnsMilestoneFilterConfig}
            filterConfig={milestoneFilterConfig}
            getConfigAndMakeRequest={setMilestonesConfigAndGetRequest}
            createProjectMilestoneCallback={createProjectMilestoneCallback}
            filterCallback={changeMilestoneFilterConfig}
            selectRowCallback={selectMilestone}
          />
          <ListOfGoals
            className="mb-3"
            partition={defaultPartition}
            totalCount={totalCountGoals}
            selectedGoal={selectedGoal}
            defaultOrderConfig={defaultGoalOrder}
            defaultPaginationConfig={defaultGoalPagination}
            filterColumnsConfig={columnsGoalFilterConfig}
            filterConfig={goalFilterConfig}
            getConfigAndMakeRequest={setGoalsConfigAndGetRequest}
            createProjectGoalCallback={createProjectGoalCallback}
            deleteEntityCallback={deleteProjectGoalCallback}
            filterCallback={changeGoalFilterConfig}
            priorityBtnCallback={priorityBtnCallback}
            selectRowCallback={selectGoal}
          />
        </ExpandModeLayout.Table>
        <ExpandModeLayout.Card>
          {goalUUIDFromURL && (
            <CustomCardComponent type="goal">
              <ProjectGoalView
                defaultPartition={defaultPartition}
                hideElements={hideElements}
                updateProjectGoalCallback={updateProjectGoalCallback}
              />
            </CustomCardComponent>
          )}
          {milestoneUUIDFromURL && (
            <CustomCardComponent type="milestone">
              <ProjectMilestoneView
                key={milestoneUUIDFromURL}
                defaultPartition={defaultPartition}
                hideElements={hideElements}
                createProjectMilestoneCallback={createProjectMilestoneCallback}
                deleteMilestoneCallback={deleteProjectMilestoneCallback}
                updateProjectMilestoneCallback={updateProjectMilestoneCallback}
              />
            </CustomCardComponent>
          )}
          {scopeURL && (
            <ProjectGoalsScope
              defaultPartition={defaultPartition}
              changePlanningHistory={changePlanningHistory}
              updateGoalCallback={updateProjectGoalScopeCallback}
            />
          )}
        </ExpandModeLayout.Card>
      </ExpandModeLayout>
    </Row>
  );
}

PlanningView.propTypes = {
  defaultPartition: string,
};

export default PlanningView;

import { projectAuditTypeFilterOptions,
         projectAuditStatusFilterOptions,
         projectAuditUsersFilterOptions,
         projectAuditMetricFilterOptions } from "../../../../constants/allFilterOptions";
        

const typeColumnFilter = {
    filters: projectAuditTypeFilterOptions,
    filterMode: 'tree',
    filterType: 'badge',
    }
        
const statusColumnFilter = {
    filters: projectAuditStatusFilterOptions,
    filterMode: 'tree',
    filterType: 'iconWithStatus',
    };
        
const usersColumnFilter = {
    filters: projectAuditUsersFilterOptions,
    filterMode: 'tree',
    filterType: 'badge',
    };
        
const metricColumnFilter = {
    filters: projectAuditMetricFilterOptions,
    filterMode: 'tree',
    filterType: 'badge',
    }; 

export const defaultFilterConfig = {
    partition: ['general'], 
    users: ['member'],
    status: null,
    metric: null,
    }

export const columnsFilterConfig = {
    type: typeColumnFilter,
    status: statusColumnFilter,
    users: usersColumnFilter,
    metric: metricColumnFilter,
    }
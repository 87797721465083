import PropTypes, { bool, string } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';
import RadioButtons from '../../../components/RadioButtons/RadioButtons';
import { issuesInfo, issuesStatusesTags } from '../../../constants/issuesConstants';
import { capitalize } from 'lodash';
import { stopPropagation } from '../../../54origins/utils54origins';

import './css/IssueStatusesComponent.scss';
import { partitionNamesConfig } from '../../../api/appConfig';

export default function IssueStatusesComponent({
  currentStatus,
  onChangeCallback,
  partitionType,
  disabled,
}) {
  const { t } = useTranslation();

  const onChangeFunc = (value) => {
    onChangeCallback('status', value);
  };

  const allListIssueTags = useMemo(() => (partitionType === partitionNamesConfig.partition2
    ? [
      {
        label: 'wms.noun.validation',
        className: 'radio-lightBlue with-color-border',
        classNameWrapper: 'back-lightBlue',
        colorTooltip: '#51a5ff',
        options: issuesStatusesTags.filter((i) => i.value === 'created'),
        info: issuesInfo.find((el) => el.key === 'validation'),
      },
      {
        label: 'wms.labels.development',
        className: 'radio-darkBlue with-color-border',
        classNameWrapper: 'back-darkBlue',
        colorTooltip: '#5676f3',
        options: issuesStatusesTags.filter((i) => i.value === 'in progress'),
        info: issuesInfo.find((el) => el.key === 'development'),
      },
      {
        label: 'wms.labels.other',
        className: 'radio-gray with-color-border',
        classNameWrapper: 'back-gray',
        options: issuesStatusesTags.filter((i) => i.value === 'closed' || i.value === 'backlog'),
      },
    ]
    : [
      {
        label: 'wms.noun.validation',
        className: 'radio-lightBlue with-color-border',
        classNameWrapper: 'back-lightBlue',
        colorTooltip: '#51a5ff',
        options: issuesStatusesTags.filter((i) => i.keySection === 'validation' && i.value !== 'all'),
        info: issuesInfo.find((el) => el.key === 'validation'),
      },
      {
        label: 'wms.labels.development',
        className: 'radio-darkBlue with-color-border',
        classNameWrapper: 'back-darkBlue',
        colorTooltip: '#5676f3',
        options: issuesStatusesTags.filter((i) => i.keySection === 'development' && i.value !== 'all'),
        info: issuesInfo.find((el) => el.key === 'development'),
      },
      {
        label: 'wms.labels.testing',
        className: 'radio-orange with-color-border',
        classNameWrapper: 'back-orange',
        colorTooltip: '#f89451',
        options: issuesStatusesTags.filter((i) => i.keySection === 'testing' && i.value !== 'all'),
        info: issuesInfo.find((el) => el.key === 'testing'),
      },
      // {
      //   label: 'wms.labels.deploy',
      //   className: 'radio-green',
      //   classNameWrapper: 'back-green',
      //   options: issuesStatusesTags.filter((i) => i.keySection === 'deploy' && i.value !== 'all'),
      // },
      {
        label: 'wms.noun.purgatory',
        className: 'radio-gray with-color-border',
        classNameWrapper: 'back-gray',
        colorTooltip: '#888888',
        options: issuesStatusesTags.filter((i) => i.keySection === 'purgatory' && i.value !== 'all'),
        info: issuesInfo.find((el) => el.key === 'purgatory'),
      },
      {
        label: 'wms.labels.tickets_end',
        className: 'radio-dark with-color-border',
        classNameWrapper: 'back-dark',
        colorTooltip: '#4d4d4d',
        options: issuesStatusesTags.filter((i) => i.keySection === 'end' && i.value !== 'all'),
        info: issuesInfo.find((el) => el.key === 'tickets_end'),
      },
    ]), [issuesStatusesTags.length, partitionType]);

  return (
    <div className="flex flex-col pt-1 ml-1">
      {allListIssueTags.map((item) => (
        <div
          key={`status_${item.label}`}
          style={{ position: 'relative' }}
          className={`my-2 p-1 pt-2 ${item.classNameWrapper}`}
        >
          <Row
            className="mb-1"
            style={{ position: 'absolute', top: -13 }}
          >
            {capitalize(t(item.label))}
            <div
              id={item.label.split('.').slice(2) + 'InfoButton'}
              role="button"
              tabIndex="0"
              className="description-drag-and-paste ml-1"
              style={{ color: item?.colorTooltip }}
              onClick={stopPropagation}
            >
              <Tooltip
                placement="left"
                title={item?.info?.info}
                color={item?.colorTooltip}
              >
                <Icon
                  path={mdiHelpCircleOutline}
                  size={0.8}
                />
              </Tooltip>
            </div>
          </Row>
          <Row className="mt-1">
            <RadioButtons
              setIcons
              disabled={disabled}
              className={disabled ? '' : item.className}
              options={item.options}
              value={currentStatus}
              onChange={(value) => {
                onChangeFunc(value);
              }}
            />
          </Row>
        </div>
      ))}
    </div>
  );
}
IssueStatusesComponent.propTypes = {
  onChangeCallback: PropTypes.func,
  currentStatus: string,
  partitionType: string,
  disabled: bool,
};

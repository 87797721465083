import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import './BaseButton.scss';

const activeStyle = {
  color: '#096dd9',
  backgroundColor: '#fff',
  borderColor: '#096dd9',
};

function BaseButton({
  active,
  block,
  children,
  className,
  disabled,
  ghost,
  htmlType,
  icon,
  id,
  loading,
  onBlur,
  onClick,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  onMouseUp,
  shape,
  size,
  style,
  type,
  ...props
}) {
  return (
    <Button
      block={block}
      className={className}
      disabled={disabled}
      ghost={ghost}
      htmlType={htmlType}
      icon={icon}
      loading={loading}
      onBlur={onBlur}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      shape={shape}
      size={size}
      style={active ? activeStyle : style}
      type={type}
      id={id}
      {...props}
    >
      {children}
    </Button>
  );
}

BaseButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  htmlType: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  shape: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
  // type: PropTypes.oneOf(['default', 'primary', 'success', 'info', 'warning', 'danger', 'link']),
};

BaseButton.defaultProps = {
  type: 'default',
};

export default BaseButton;

import { mdiCardPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  bool, func, string,
} from 'prop-types';
import React from 'react';

import BaseButton from '../../../../../components/_ui/BaseButton/BaseButton';

export default function CloneIssueBtn({
  disabled,
  setDefaultIssueState,
  buttonClassName = '',
  getStartData,
}) {
  const onClickOpen = async (e) => {
    e.stopPropagation();
    getStartData(setDefaultIssueState);
  };

  return (
    <BaseButton
      id="cloneIssueButton"
      size="small"
      type="primary"
        // className="description-font"
      className={buttonClassName}
      ghost
      onClick={onClickOpen}
      disabled={disabled}
    >
      <Icon path={mdiCardPlusOutline} size={0.7} className="mr-1" />
      Clone issue
    </BaseButton>
  );
}
CloneIssueBtn.propTypes = {
  disabled: bool,
  issueUUID: string,
  partitionType: string,
  buttonClassName: string,
  setDefaultIssueState: func,
  getStartData: func,
};

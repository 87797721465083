import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card, Col, Row, Space, Spin, Tooltip, Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {cloneDeep} from 'lodash';
import Icon from '@mdi/react';
import {
  mdiCheckboxMarkedCircleOutline, mdiChevronUp, mdiCircleOutline, mdiPlusCircleOutline
} from '@mdi/js';
import { defaultProjectTypesOfTesting, descriptionTypeOFTesting } from '../QaUtils';
import { getProjectParams, getProjectUUID } from '../../../selectors/selectors';
import CommonInfo from '../../commonComponents/CommonInfo';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import { updateProjectRequest } from '../../../actions/projectFlowActions';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import { updateQAEntity } from '../../../actions/QaActions';
import { qaNotify } from '../utilsQa';
import useURLParams from '../../../../hooks/useURLParams';

const {
  Paragraph
} = Typography;

export default function GeneralOverview({
  partition,
  switchViewFunc,
  changeSelectedTypeOfTesting,
  getListOfTestSuitesFunc,
}) {
  const dispatch = useDispatch();

  const projectUUID = useSelector(getProjectUUID);

  const {
    addAndRemoveSearchParams
  } = useURLParams();

  const {
    qaEntityUUID,
    qaEntityParams,
    setQAEntityParams,
  } = useContext(BugTrackerContext);

  const {
    listOfFavouriteTypes = defaultProjectTypesOfTesting,
    testingTypesInfo = {},
    totalSuitesNumber = 0
  } = qaEntityParams;

  const [selectedCards, setSelectedCards] = useState([]);
  const [unselectedCards, setUnselectedCards] = useState([]);
  const [loading, changeLoading] = useState(false);
  const [showAllCards, setShowAllCards] = useState(false);

  const onAllTestSuitesAndCyclesByType = (type) => {
    switchViewFunc('suites');
    type !== 'all' ? changeSelectedTypeOfTesting([type]) : changeSelectedTypeOfTesting([])
  };

  const onAllTestCasesByType = (type) => {
    switchViewFunc('cycles');
    // changeIsCreatorCycleByType(type);
  };

  const searchFunc = (searchData) => {
    if (searchData) {
      setSelectedCards(descriptionTypeOFTesting
        .filter((item) => item.label.toLowerCase().indexOf(searchData.toLowerCase()) !== -1));
    } else {
      setSelectedCards(descriptionTypeOFTesting);
    }
  };

  const onCheckMark = (newTypesOfTesting) => {
    dispatch(updateQAEntity({
      data: {
        params: {
          listOfFavouriteTypes: newTypesOfTesting.map((type) => type?.value),
        },
        entity_uuid: qaEntityUUID
      },
      partition: partition
    }));

    setQAEntityParams({
      ...qaEntityParams,
      listOfFavouriteTypes: newTypesOfTesting.map((type) => type?.value)
    });
  };

  const onUpdateDataClick = () => {
    const uniqKey = `overviewUpd${Date.now()}`;
    qaNotify({
      param: 'info',
      key: uniqKey,
      message: 'Updating overview...',
    });

    changeLoading(true)
    let dataForEntity = {};
    let totalSuitesNumber = 0;

    getListOfTestSuitesFunc({
      partition: partition, 
      parent: qaEntityUUID
    }).then(({data}) => {
        totalSuitesNumber = data?.length ? data.length : 0
      });

      const promises = descriptionTypeOFTesting.map(type => {
        if (type.value !== 'all') {
          let numberOfSuites = 0;
          let lastModifiedSuite = {};

          const typePromises = [
            getListOfTestSuitesFunc({
              offset: 0,
              orderBy: 'modified',
              order: 'desc',
              params: { typeOfTesting: [type.value] },
              partition: partition,
              parent: qaEntityUUID
            }).then(({ data }) => {
              numberOfSuites = data.length;
              lastModifiedSuite = data[0] || {};
            })
          ];

          return Promise.all(typePromises).then(() => {
            Object.assign(dataForEntity, {
              [type.value]: {
                suites: numberOfSuites,
                lastModifiedSuite: lastModifiedSuite
              }
            });
          });
        }
        return Promise.resolve();
      });

      Promise.all(promises).then(() => {
        dispatch(updateQAEntity({
          data: {
            params: {
              testingTypesInfo: dataForEntity,
              totalSuitesNumber: totalSuitesNumber,
              lastUpdate: new Date()
            },
            entity_uuid: qaEntityUUID
          },
          partition: partition
        }));

        setQAEntityParams({
          testingTypesInfo: dataForEntity,
          totalSuitesNumber: totalSuitesNumber,
          lastUpdate: new Date()
        });

        changeLoading(false);

        qaNotify({
          param: 'success',
          key: uniqKey,
          message: 'Overview updated',
        });
      });

  };

  const getLastUpdateDate = (dateFromEntity) => {
    let date = new Date(dateFromEntity);
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let year = date.getFullYear();
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const onLastUpdatedSuiteRedirect = (uuid) => {
    addAndRemoveSearchParams({}, ['activeTab', 'activeProject'])
    window.location.pathname = `/s/pm/ts=${uuid}=${projectUUID}`
  }

  const renderTabs = () => {
    return(
      <>
        {[...selectedCards, ...unselectedCards].map((el) => {
          // console.log(el?.isSelected, el?.value, el);
          if (!showAllCards && !el?.isSelected) return null;

          if(el?.value !== 'all'){

          return (
            <Col span={12} key={el?.value}>
              <Card
                title={(
                  <Row>
                    <span className='text-[#038fde] text-[28px] mr-6' >
                      {el?.label}
                    </span>
                    <CommonInfo
                      placement="bottomLeft"
                      info={el?.description}
                    />
                    {el?.isSelected ? (
                      <Tooltip
                        title="Удалить из избранных"
                        placement="bottomLeft"
                        color="#32cd32"
                        // color="blue"
                        onClick={() => onCheckMark(selectedCards.filter((item) => item?.value !== el?.value))}
                      >
                        <BaseButton
                          id="link"
                          shape="circle"
                          className="view_btn_link__success pb-1 ml-1"
                        >
                          <Icon
                            // path={mdiInformationVariant}
                            color="#32cd32"
                            path={mdiCheckboxMarkedCircleOutline}
                            size={1}
                          />
                        </BaseButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Добавить в избранные"
                        placement="bottomLeft"
                        color="#808080"
                        onClick={() => onCheckMark([...selectedCards, el])}
                        // color="blue"
                      >
                        <BaseButton
                          id="link"
                          shape="circle"
                          className="view_btn_link__grey pb-1 ml-1"
                        >
                          <Icon
                            // path={mdiInformationVariant}
                            // color="#32cd32"
                            color="#808080"
                            path={mdiPlusCircleOutline}
                            size={1}
                          />
                        </BaseButton>
                      </Tooltip>
                    )}
                  </Row>
              )}
                className='h-[155px] min-w-[820px] leading-1'
              >

                  <Paragraph className='mt-[-10px]'>
                    Всего
                    {' '}
                    {testingTypesInfo[el.value]?.suites || 0}
                    {' '}
                    из
                    {' '}
                    {totalSuitesNumber}
                    {' '}
                    тест сьютов
                    {' '}
                    имеют тип
                    {' '}
                    {el?.value}
                    .
                  </Paragraph>

                  {testingTypesInfo[el.value]?.lastModifiedSuite?.title &&
                    <Paragraph style={{ marginTop: -10 }}>
                      Последний
                      {' '}
                      измененный
                      {' '}
                      тест сьют:
                      {' '}
                      <button
                        className='text-[blue]'
                        onClick={() => onLastUpdatedSuiteRedirect(qaEntityParams?.testingTypesInfo[el.value]?.lastModifiedSuite?.uuid)}
                      >
                        {qaEntityParams?.testingTypesInfo[el.value]?.lastModifiedSuite?.title}
                      </button>
                      .
                    </Paragraph>
                  }

                  {loading ? <Spin className='ml-[46%]' spinning /> : null}

                <Space
                  className="absolute d-flex justify-content-end align-items-end pb-[16px]"
                >
                  <Button
                    type="primary"
                    onClick={() => onAllTestSuitesAndCyclesByType(el?.value)}
                  >
                    Открыть список тест сьютов
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => onAllTestCasesByType(el?.value)}
                  >
                    Открыть список тест циклов
                  </Button>
                </Space>
              </Card>
            </Col>
          );}
        })}
      </>
    )
  }

  useEffect(() => {
    const unCards = [];
    const cards = cloneDeep(descriptionTypeOFTesting).reduce((acc, el) => {
      const isSelected = listOfFavouriteTypes
        .some((type) => el?.value === type);
      if (isSelected) {
        el.isSelected = true;
        acc.push(el);
      } else {
        el.isSelected = false;
        unCards.push(el);
      }
      return acc;
    }, []);
    setSelectedCards(cards);
    setUnselectedCards(unCards);
  }, [projectUUID, listOfFavouriteTypes, qaEntityUUID]);

  return (
    <>
      <Button
        className='mb-4 flex-col w-fit h-fit'
        type='primary'
        onClick={onUpdateDataClick}
      >
        <span className='text-[26px] p-0'>Update overview</span>
        {qaEntityParams?.lastUpdate && `last update: ${getLastUpdateDate(qaEntityParams?.lastUpdate)}`}
      </Button>

      <Row gutter={[16, 16]} className="w-100">
        <Col span={12}>
          <Card
            title={(
              <Row>
                <span className='text-[#038fde] text-[28px] mr-6' >
                  {descriptionTypeOFTesting[0].label}
                </span>
                <CommonInfo
                  placement="bottomLeft"
                  info={descriptionTypeOFTesting[0].description}
                />
              </Row>
            )}
              className='h-[155px] min-w-[820px] leading-1'
              >

                <Paragraph style={{ marginTop: -10 }}>
                  Общее
                  {' '}
                  число
                  {' '}
                  тест сьютов:
                  {' '}
                  {qaEntityParams?.totalSuitesNumber ? qaEntityParams?.totalSuitesNumber : 0}
                  .
                </Paragraph>

                {loading ? <Spin className='ml-[46%]' spinning /> : null}

                <Space
                  className="absolute pb-[16px] d-flex justify-content-end align-items-end"
                >
              <Button
                type="primary"
                onClick={() => onAllTestSuitesAndCyclesByType('all')}
              >
                Открыть список тест сьютов
              </Button>
              <Button
                type="primary"
                onClick={() => onAllTestCasesByType('all')}
              >
                Открыть список тест циклов
              </Button>
            </Space>
          </Card>
        </Col>

        {
          renderTabs()
        }

      </Row>
      <div
        onClick={() => setShowAllCards((prev) => !prev)}
        className="wrapper_horizontal_collapse_btn general_style mt-[40px] text-[#166fd4]"
      >
        {showAllCards ? 'Спрятать невыбранные типы тестирования' : 'Показать остальные типы тестирования'}
        <Icon
          path={mdiChevronUp}
          size={1.2}
          color="#166fd4"
          rotate={!showAllCards ? 540 : 0}
        />

      </div>
    </>
  );
}
GeneralOverview.propTypes = {
  partition: PropTypes.string,
  switchViewFunc: PropTypes.func,
  changeSelectedTypeOfTesting: PropTypes.func,
};

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useRef, useState } from 'react';
import { isNil } from 'ramda';
import moment from 'moment';
import { get, omit } from 'lodash';
import {
  Col, DatePicker, Input, Row,
} from 'antd';
import { getAllTestSuites, getProjectUUID, getTestCycleUUID } from '../../../selectors/selectors';
import { BugTrackerContext, ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import {
  clearListTestCaseInCycleInStore, createTestCaseForCycle,
  getListOfTestCasesInCycleFunc, updateListOfTestCaseInCycle,
  updateTestCycle,
} from '../../../actions/QaActions';
import { incrementAndGetCount } from '../../../../entity/actions/entityActions';
import { antNotification, capitalizeFirstChar, getHeadUUID } from '../../../../MainUtils';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

export default function ChecklistForm({
  defaultTitle = '',
  disabledSbmtBtn,
  entityUUID,
  defaultDescription = '',
  defaultStartDate = '',
  defaultDueDate = '',
  typeOfForm,
  submitLabelBtn,
  defaultDate = [],
  selectTestCycleCollback,
  handleOk,
  selectTestCycleCallback,
  reRun = false,
  selectedVectorDefault = [],
  selectVectorDefault,
  importListOfTestCasesCallback,
  assignToUser = [],
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const disabledSubmit = useRef(false);

  const testCycleUUID = useSelector(getTestCycleUUID);
  const projectUUID = useSelector(getProjectUUID);

  const {
    createPromiseNewChecklist,
    getListOfChecklists,
    partitionType,
  } = useContext(BugTrackerContext);

  const defaultState = {
    title: defaultTitle,
    start_date: defaultStartDate,
    end_date: defaultDueDate,
    data: defaultDate,
  };
  const [state, changeState] = useState(defaultState);

  const {
    title,
    start_date,
    end_date,
    data,
  } = state;

  const setNewState = (param) => {
    changeState({
      ...state,
      ...param,
    });
  };

  const onFormChange = (event) => {
    const { name, value } = event.target;

    setNewState({ [name]: value });
  };

  const onDaysChange = (param, day) => {
    setNewState({ [param]: isNil(day) ? '' : day });
  };

  const submitNewCheckList = async () => {

    const { increment } = await dispatch(incrementAndGetCount({
      entity_uuid: projectUUID,
      field_name: 'checklist',
    }, partitionType));

    const params = {
      title,
      start_date: start_date ? moment(start_date).format('YYYY-MM-DD') : '',
      end_date: end_date ? moment(end_date).format('YYYY-MM-DD') : '',
      status: 'unexecuted',
      id: `CL${increment}`,
      data,
    };

    const newCheckList = await createPromiseNewChecklist(params);
    const newCheckListUUID = getHeadUUID(newCheckList);

    if (newCheckListUUID) {
      setNewState(defaultState);

      getListOfChecklists();
    }

    // if (selectTestCycleCallback) {
    //   selectTestCycleCallback(newCheckListUUID, false, true);
    // }

    return Promise.resolve(newCheckList);
  };

  const submitNewCheckListAndCloseModal = async () => {
    if (handleOk) {
      handleOk();
    }

    await submitNewCheckList();
    disabledSubmit.current = false;
  };

  const finalSubmitFunc = () => {
    if (!disabledSubmit.current) {
      disabledSubmit.current = true;
      switch (typeOfForm) {
        case 'newForm':
          return submitNewCheckListAndCloseModal();
        default:
          return '';
      }
    }
  };

  const disabledDate = (current) => current && current < moment().startOf('day');
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {capitalizeFirstChar(t('wms.labels.title', 'title'))}
        <span style={{ color: 'red' }}>*</span>
        <Input
          maxLength={128}
          showCount
          name="title"
          onChange={onFormChange}
          defaultValue={defaultTitle}
        />
      </Col>

      <Col span={8} className="d-flex flex-column">
        <span>{`${capitalizeFirstChar(t('wms.labels.date.start', 'start date'))}:`}</span>
        <DatePicker
          format="DD-MM-YYYY"
          value={start_date}
          placeholder={t('wms.placeholders.date.format')}
          disabledDate={disabledDate}
          onChange={(day) => onDaysChange('start_date', day)}
        />
      </Col>
      <Col span={8} className="d-flex flex-column">
        <span>
          {capitalizeFirstChar(t('wms.labels.date.end', 'end date'))}
          :
        </span>
        <DatePicker
          format="DD-MM-YYYY"
          value={end_date}
          placeholder={t('wms.placeholders.date.format')}
          disabledDate={disabledDate}
          onChange={(day) => onDaysChange('end_date', day)}
        />
      </Col>
      <Col span={24} className="d-flex justify-content-end">
        <BaseButton
          className="btnPrimary ml-2"
          onClick={finalSubmitFunc}
          disabled={disabledSbmtBtn || title.trim().length === 0}
        >
          {submitLabelBtn || capitalizeFirstChar(t('wms.buttons.submit', 'submit'))}
        </BaseButton>
      </Col>
    </Row>
  );
}


// import { capitalize } from 'lodash';

export const prioritySelectedOptions = {
  low: 'createdBlue',
  medium: 'priorityMedium',
  high: 'closedRed',
  urgent: 'priorityUrgent',
};

export const priorityOptionsDropdown = ['low', 'medium', 'high', 'urgent'];

export const vectorStatusSelectedOptions = {
  created: 'createdBlue',
  planning: 'createdDarkBlue',
  protected: 'protectedOrange',
  active: 'activeGreen',
  closed: 'closedRed',
  blocked: 'blockedGrey',
  paused: 'blockedGrey',
  'in_progress': 'createdDarkBlue',
  backlog: 'blockedGrey',
};

export const releaseStatusSelectedOptions = {
  planning: 'createdDarkBlue',
  developing: 'activeGreen',
  support_only: 'createdDarkBlue',
  not_supported: 'blockedGrey',
  bugfix: 'protectedOrange',
  testing: 'protectedOrange',
};

export const vectorStatusOptionsDropdown = ['created', 'active', 'in progress', 'planning', 'backlog', 'blocked', 'paused', 'protected', 'closed'];

export const vectorTypeSelectedOptions = {
  general: 'createdBlue',
  functional: 'createdBlue',
  version: 'typeVersion',
  deploy: 'typeDeploy',
  launch: 'typeLaunch',
  workblock: 'typeWorkblock'
};


export const vectorTypeOptionsDropdown = ['version', 'general', 'deploy', "workblock", "launch"];


export const releaseStatusOptionsDropdown = ['developing', 'planning', 'bugfix', 'testing', 'support_only', 'not_supported'];

// export const vectorSubTypeSprintSelectedOptions = {
//   functional: 'createdBlue',
//   previous: 'previous-sub-type',
//   current: 'current-sub-type',
//   next: 'next-sub-type',
//   next_plus: 'next-plus-sub-type',
//   next_2plus: 'next-2-plus-sub-type',

// };

// export const vectorSubTypeSprintOptionsDropdown = ['old', 'previous', 'current', 'next', 'next_plus', 'next_2plus'];

// export const vectorSubTypeVersionSelectedOptions = {
//   none: 'createdBlue',
//   previous: 'previous-sub-type',
//   current: 'current-sub-type',
//   production: 'production-sub-type',
//   next: 'next-sub-type',
//   next_plus: 'next-plus-sub-type',
//   next_2plus: 'next-2-plus-sub-type',

// };

// export const vectorSubTypeVersionOptionsDropdown = ['none', 'previous', 'production', 'next', 'next_plus', 'next_2plus'];

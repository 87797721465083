import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";

function SimpleInfoModal({ title, isOpen, handleClose, content }) {
  return (
    <Modal title={title} open={isOpen} onCancel={handleClose} footer={null}>
      {content}
    </Modal>
  );
}

SimpleInfoModal.propTypes = {
  content: PropTypes.node,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
};

export default SimpleInfoModal;
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Col, Flex, Input, Row,
} from 'antd';

import { get } from 'lodash';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

import {
  getProjectParams,
  getProjectUUID,
  // getTestSuite,
  getTestSuiteUUID,
} from '../../../selectors/selectors';
import { capitalize } from 'lodash';

import { BugTrackerContext, ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';

const { TextArea } = Input;

function EditTestSuite({
  defaultTitle,
  defaultDescription,
  assignToUser,
  defaultTypeOfTesting,
  handleOk,
  entityUUID,
  isAssignOpen,
  setIsAssignOpen,
  type = '',
}) {
  const { t } = useTranslation();

  const testSuiteUUID = useSelector(getTestSuiteUUID);
  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);
  // const oneTestSuite = useSelector(getTestSuite);

  const {
    sendAssignedNotifications,
  } = useContext(ProjectMainLayerContext);

  const {
    getListOfTestSuites,
    updateTestSuitePromise,
    partitionType,
  } = useContext(BugTrackerContext);

  const defaultState = {
    title: defaultTitle,
    description: defaultDescription,
    assignToUsersOption: assignToUser,
    typeOfTesting: defaultTypeOfTesting,
  };
  const [state, setState] = useState(defaultState);

  const {
    title,
    description,
    assignToUsersOption,
    typeOfTesting,
  } = state;

  const changeState = (params) => {
    setState({
      ...state,
      ...params,
    });
  };

  const onFormChange = (event) => {
    const { name, value } = event.target;

    changeState({
      [name]: value,
    });
  };

  const updateTestSuiteFunc = () => {
    const data = {
      uuid: type === 'case' ? testSuiteUUID : entityUUID,
      params: {
        title: title || title === '' ? title : defaultTitle,
        description,
        assignToUser: assignToUsersOption,
        typeOfTesting,
      },
    };
    // console.log('testSuiteUUID', testSuiteUUID, 'entityUUID',entityUUID)
    if (type !== 'case' && testSuiteUUID !== entityUUID) {
      data.customConstants = ['REQUEST', 'SUCCESS', 'FAILURE'];
    }

    updateTestSuitePromise(data).then((res) => {
      // console.log('res', res)
      const assignedUsers = get(data, 'params.assignToUser');

      sendAssignedNotifications({
        entity_type: 'testSuite',
        assignedUsers,
        eventName: 'assigned_test_suite',
        entityUUID: get(res, '[0].uuid'),
        message: get(res, '[0].params.title', ''),
        entityUUIDsFields: {
          testSuiteUUID: get(res, '[0].uuid'),
          projectUUID,
        },
      });
      if (getListOfTestSuites) {
        getListOfTestSuites();
      }
    });

    if (handleOk) {
      handleOk();
    }
  };

  const assignToFunc = (value) => {
    changeState({
      assignToUsersOption: value,
    });
  };

  return (
    <>
      <Row type="flex">
        <Col span={24}>
          <Col>
            {capitalize(t('wms.labels.title', 'Title'))}
            <span style={{ color: 'red' }}>*</span>
          </Col>

          <Input
            maxLength={128}
            showCount
            name="title"
            onChange={onFormChange}
            value={title}
          />
        </Col>
      </Row>

      <Row type="flex" className="mt-2">
        <Col span={24}>
          <Flex className="w-full">
            <AssigningUsersRoot
              header="Assigning users"
              isOpen={isAssignOpen}
              needUpdateProjectUsers
              setIsOpen={setIsAssignOpen}
              onUpdateUsers={(data) => assignToFunc(data?.users?.map((el) => el?.uuid))}
              globalOptions={{
                uuids: projectParams?.usersSearch ?? [],
                defaultColumn: 'project',
              }}
              placement="left"
              isPopover
              tabs={{
                allConfig: {
                  label: 'All',
                  value: 'all',
                  textSelectButton: 'Add to suite',
                  showSections: ['button-add-user'],
                },
                projectConfig: {
                  label: 'Project',
                  value: 'project',
                  textSelectButton: 'Add to suite',
                  defaultItems: projectParams?.users ?? [],
                  showSections: ['string-role', 'button-add-user'],
                },
              }}
              usersConfig={{
                label: 'Suite users',
                showSections: ['button-remove-user', 'string-role'],
                textDeleteButton: 'Delete users',
                defaultItems: assignToUsersOption ?? [],
              }}
              partition={partitionType}
              projectUuid={projectUUID}
              popoverText={(<span style={{ color: 'black' }}>Assigned users:</span>)}
            />
            <WrapperAvatarsGroupForProjectWithHook
              actors={assignToUsersOption || []}
              avatarSize={28}
              popoverTrigger="click"
              popoverPlacement="right"
            />
          </Flex>
        </Col>
        <Col span={24}>
          <span>
            {capitalize(t('wms.labels.description', 'description'))}
            :
          </span>

          <TextArea
            style={{ height: '150px' }}
            row={4}
            name="description"
            onChange={onFormChange}
            value={description}
          />
        </Col>
      </Row>

      <Row type="flex" className="mt-4">
        <Col span={24} className="flex">
          <BaseButton
            className="ml-auto"
            type="primary"
            disabled={title.length === 0}
            onClick={updateTestSuiteFunc}
          >
            {capitalize(t('wms.buttons.submit', 'submit'))}
          </BaseButton>
        </Col>
      </Row>
    </>
  );
}

EditTestSuite.propTypes = {
  assignToUser: PropTypes.any,
  defaultDescription: PropTypes.string,
  defaultTitle: PropTypes.string,
  defaultTypeOfTesting: PropTypes.array,
  entityUUID: PropTypes.string,
  handleOk: PropTypes.func,
  type: PropTypes.string,
  isAssignOpen: PropTypes.bool,
  setIsAssignOpen: PropTypes.func,
};

export default EditTestSuite;

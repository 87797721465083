import moment from "moment";
import { get } from "lodash";
import { head } from "ramda";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { antNotification } from "../../../../MainUtils";
import { getProjectUUID } from "../../../selectors/selectors";
import { partitionNamesConfig } from "../../../../api/appConfig";
import { entityCreate, entityRead, incrementAndGetCount } from "../../../../entity/actions/entityActions";
import { ProjectTypeCommonConstants } from "../../../constants/Constants";
import { getListOfRecurringIssues } from "../../../actions/projectFlowActions";
import { setGlobalUpdateEntity } from "../../../reducers/slicers/breadcrumbsSlicer";
import axiosRequest from "../../../../api/apiAxios";

function useMyRecurringIssues() {
    const defaultPartition = partitionNamesConfig.partition1

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const projectUUID = useSelector(getProjectUUID);

    const changeAbortingState = (data, abort) => {        
        abort.current = {
          ...abort?.current || {},
          ...data,
        };
    };
    
    function getNewSignalAndChangeAbortingState(paramFromState, abort) {
    
        const newController = new AbortController();
    
        const { signal } = newController;
    
        changeAbortingState({ [paramFromState]: newController }, abort);
    
        return signal;
    };

    const requestCreateRecurringIssue = async (formState, getTitle, partitionType, handleOk, createRecurringIssueCallback) => {
        const {
            title,
            description,
            status,
            tracker,
            priority,
            estimated_time,
            partition,
            assignedProject,
            assignedVector,
            files,
            audioFiles,
            lifeTime,
            tags,
            links,
            usersSearch,
            isGlobalCreateModal,
            selectedProjectData,
            selectedVectorData,
            usersUuidsForAssigned,
            testCaseData,
        } = formState || {};

        console.log(assignedVector);
        
        const params = await getParamsForSubmit();

        console.log(assignedVector);

        const constants = [
            "NEW_RECURRING_ISSUE_REQUEST",
            "NEW_RECURRING_ISSUE_SUCCESS",
            "NEW_RECURRING_ISSUE_FAILURE",
        ].map((item) => ProjectTypeCommonConstants[item]);

        const options = {
            partition: defaultPartition,
        };

        let res = await dispatch(
            entityCreate({
                data: params,
                constants,
                options,
            })
        );

        res = head(res);

        if (!res) {
            antNotification('error', t('notifications.text.error.base', 'Error'));
        }

        if (isGlobalCreateModal) {
            dispatch(
                setGlobalUpdateEntity({
                    entityType: "recurring",
                    action: "create",
                    uuid: get(res, "uuid"),
                })
            );
        }

        antNotification(
            "success",
            t(
                "notifications.text.success.new_recurring_issue_created",
                "New recurring issue has been successfully added"
            )
        );

        if (createRecurringIssueCallback) {
            createRecurringIssueCallback(res?.uuid, res);
        }

        if (handleOk) {
            handleOk();
        }

        async function getParamsForSubmit() {
            // const listFiles = [...audioFiles, ...files];
            const listFiles = [...files];

            const parentIssue = assignedVector || assignedProject;
            
            const id = await getIdIssue(parentIssue);

            const data = {
              entity_type: "recurring",
              parent: parentIssue,
              params: {
                project: assignedProject,
                vector: assignedVector,
                usersSearch,
                title: getTitle,
                description,
                id,
                tags,
                links,
                status,
                priority,
                tracker,
                estimated_time,
                lifeTime: lifeTime || moment().add("2", "weeks").format("YYYY-MM-DD"),
                partitionType: defaultPartition,
                mandatoryIssue: false
                // mandatoryIssue: mandatoryRecurringIssue
              },
            };
            
            if (testCaseData) {
              data.params.testCaseUuid = testCaseData?.case;
              data.params.testCycleUuid = testCaseData?.cycle;
            }
        
            const formData = new FormData();
        
            for (let i = 0; i < listFiles.length; i += 1) {
              formData.append("file", listFiles[i]);
            }
        
            formData.append("data", JSON.stringify(data));
        
            return formData;
        };

        async function getIdIssue(parentIssue) {
            const countIssue = await dispatch(
              incrementAndGetCount(
                {
                  entity_uuid: parentIssue,
                  field_name: "recurring",
                },
                partitionType === partitionNamesConfig.partition2
                  ? partitionType
                  : partition || partitionType
              )
            );
    
            return `R${get(countIssue, "increment")}`;
          };
    }

    const requestGetListOfRecurringIssues = (recurringIssuesConfig, setTotalCount, abort) => {
        const {
            limit,
            offset,
            order,
            orderBy,
            search,
            usersSearch = [],
            tracker,
            priority,
            estimated_time: estimatedTime,
            status
        } = recurringIssuesConfig || {};

        const data = {
            params: {},
            limit,
            offset,
            order,
            [orderBy === 'created' ? 'order_by' : 'order_by_params']: orderBy,
            parent: projectUUID,
            signal: getNewSignalAndChangeAbortingState('listOfIssuesSignal', abort)
        }

        if (tracker && tracker?.length) {
            data.params.tracker = tracker;
        }
    
        if (priority && priority?.length) {
            data.params.priority = priority;
        }

        if (estimatedTime && estimatedTime?.length) {
            data.params.estimated_time = estimatedTime;
        }

        if (status && status?.length) {
            data.params.status = status;
        }

        if (usersSearch.length !== 0) {
            data.params.usersSearch = usersSearch;
        }

        if (search) {
            data.searchData = {
                value: search,
                ignore_case: true,
                fields: {
                params: ['title'],
                },
            };
        }

        const getClearListOfRecurringIssues = (param) => {
            const config = {
            depth: 0,
            partition: defaultPartition,
            constants: [
                ProjectTypeCommonConstants.LIST_OF_RECURRING_ISSUES_REQUEST,
                ProjectTypeCommonConstants.LIST_OF_RECURRING_ISSUES_SUCCESS,
                ProjectTypeCommonConstants.LIST_OF_RECURRING_ISSUES_FAILURE,
            ],
            fields: {
                modified: 'modified',
            },
            params_fields: {
                title: 'title',
                description: 'description',
                result: 'result',
                tracker: 'tracker',
                priority: 'priority',
                status: 'status',
                assigned_users: 'assigned_users',
                estimated_time: 'estimated_time',
                lifeTime: 'lifeTime',
                users: 'users',
                usersSearch: 'usersSearch',
                tags: 'tags',
                expirationDateDev: 'expirationDateDev',
                expirationDateQa: 'expirationDateQa',
            },
            ...param,
            };    
        
            return dispatch(getListOfRecurringIssues(config));
        };

        return getClearListOfRecurringIssues({
            ...data,
            constants: [
                ProjectTypeCommonConstants.LIST_OF_RECURRING_ISSUES_REQUEST,
                ProjectTypeCommonConstants.LIST_OF_RECURRING_ISSUES_SUCCESS,
                ProjectTypeCommonConstants.LIST_OF_RECURRING_ISSUES_FAILURE,
            ],
        }).then((res) => {
            setTotalCount(res?.total || 0);
        }).catch(() => { });
    }

    const requestGetRecurringIssue = async (uuid, abort, changeMyBoardHistory) => {
        const getClearRecurringIssue = (uuid, signal) => {
            axiosRequest.abort('', ProjectTypeCommonConstants.RECURRING_ISSUE_ENTITY_REQUEST);
        
            const config = {
              uuid,
              partition: defaultPartition,
              signal,
              withFiles: false,
              params: {},
            };
            
            return dispatch(getRecurringIssue(config));
        };

        function getRecurringIssue({
            partition = defaultPartition,
            signal,
            params,
            uuid,
            constants: customConstants,
            withFiles,
        }) {
            const data = {
                entity_type: 'recurring',
                entity_uuid: uuid,
                with_files: false,
                params: { ...params },
            };
            
            const constants = customConstants || [
                ProjectTypeCommonConstants.RECURRING_ISSUE_ENTITY_REQUEST,
                {
                type: ProjectTypeCommonConstants.RECURRING_ISSUE_ENTITY_SUCCESS,
                payload: (res) => [{ ...res[0], partition }],
                },
                ProjectTypeCommonConstants.RECURRING_ISSUE_ENTITY_FAILURE,
            ];
            
            const options = {
                signal,
                partition,
            };
            
            return entityRead({
                data,
                constants,
                options,
            });
        }

        if (changeMyBoardHistory) {
            changeMyBoardHistory('issue', uuid);
        }

        await getClearRecurringIssue(uuid, getNewSignalAndChangeAbortingState('issueSignal', abort))
        .catch((error) => {
            if (error.message) {
                antNotification('error', error.message);
            }
        });
    }

    const requestCreateIssueFromRecurring = async (issue, partitionType, partition) => {
        const getIdIssue = async (issue, parentIssue) => {
            const countIssue = await dispatch(
              incrementAndGetCount(
                {
                  entity_uuid: parentIssue,
                  field_name: "issue",
                },
                partitionType === partitionNamesConfig.partition2
                  ? partitionType
                  : partition || partitionType
              )
            );
        
            let idVector = "";
            
            if (issue.vector) {
              idVector = get(issue, "selectedVectorData.id", "V");
            }
            return `${idVector}T${get(countIssue, "increment")}`;
          };
        
        const getParamsForSubmit = async (issue) => {
            const {
              title,
              description,
              status,
              tracker,
              priority,
              estimated_time,
              partition,
              project,
              vector,
              lifeTime,
              tags,
              links,
              usersSearch,
              testCaseData,
            } = issue.params;
            
            const parentIssue = vector || project;
            
            const id = await getIdIssue(issue.params, parentIssue, partition);
        
            // const listFiles = [...audioFiles, ...files];
            // const files = [...issue.files];
            const listFiles = [];
        
            const data = {
              entity_type: "issue",
              parent: parentIssue,
              params: {
                project,
                vector,
                usersSearch,
                title,
                description,
                tags,
                id,
                links,
                status,
                priority,
                tracker,
                estimated_time,
                lifeTime,
                partitionType: partition,
                mandatoryIssue: false,
              },
            };
        
            if (testCaseData) {
              data.params.testCaseUuid = testCaseData?.case;
              data.params.testCycleUuid = testCaseData?.cycle;
            }      
        
            const formData = new FormData();
        
            for (let i = 0; i < listFiles.length; i += 1) {
              formData.append("file", listFiles[i]);
            }
        
            formData.append("data", JSON.stringify(data));
            
            return formData;
        };

        const sendAssignNotifications = (ticket) => {
            const { uuid, params } = ticket;
            const { vector, project } = params;
        
            if (params?.usersSearch?.length > 0) {
                const data = {
                projectUUID: project,
                vectorUUID: vector || "",
                entity_type: "issue",
                uuid,
                partition: partitionType,
                project_uuid: project,
                project_id: defaultIssueConfig?.selectedProjectData?.params?.id,
                project_title: defaultIssueConfig?.selectedProjectData?.params?.title,
                // bug_title: defaultIssueConfig?.bugTrackedTitle,
                assigned_entity_type: "issue",
                assigned_entity_title: params?.title,
                assigned_entity_id: params?.id,
                assigned_entity_uuid: uuid,
                };
        
                if (partitionType === partitionNamesConfig.partition3) {
                data.user = myPublicUUID || "";
                }
        
                dispatch(
                sendNotification("assigned", usersSearch, title, { entity: data })
                );
            }
        };
        
        const params = await getParamsForSubmit(issue, partition);

        const constants = [
        "NEW_ISSUE_REQUEST",
        "NEW_ISSUE_SUCCESS",
        "NEW_ISSUE_FAILURE",
        ].map((item) => ProjectTypeCommonConstants[item]);

        const options = {
        partition: partitionType,
        };

        let res = await dispatch(
        entityCreate({
            data: params,
            constants,
            options,
        })
        );

        res = head(res);

        sendAssignNotifications(res);
    }

    return { requestGetListOfRecurringIssues, requestCreateRecurringIssue, requestGetRecurringIssue, requestCreateIssueFromRecurring }
}

export default useMyRecurringIssues;

//getRecurringIssues
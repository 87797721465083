import React from 'react';
import PropTypes, { func } from 'prop-types';
import { Modal } from 'antd';
import Icon from '@mdi/react';
import { mdiWindowClose } from '@mdi/js';

function BaseModal({
  afterClose,
  bodyStyle,
  centered,
  children,
  className,
  closable,
  destroyOnClose,
  footer = null,
  handleCancel,
  getContainer,
  handleOk,
  style,
  title,
  open,
  width,
  zIndex,
}) {
  return (
    <Modal
      className={className}
      styles={{ body: bodyStyle }}
      destroyOnClose={destroyOnClose}
      title={title}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      width={width}
      centered={centered}
      closable={closable}
      style={style}
      footer={footer}
      afterClose={afterClose}
      maskClosable
      zIndex={zIndex}
      getContainer={getContainer}
      closeIcon={<Icon path={mdiWindowClose} size={1} />}
    >
      {children}
    </Modal>
  );
}

BaseModal.propTypes = {
  afterClose: PropTypes.func,
  bodyStyle: PropTypes.object,
  centered: PropTypes.bool,
  destroyOnClose: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element]),
  closable: PropTypes.bool,
  footer: PropTypes.element,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  style: PropTypes.object,
  title: PropTypes.any,
  open: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zIndex: PropTypes.number,
  className: PropTypes.string,
  getContainer: func,
};

export default BaseModal;

import React, {
  useState, useLayoutEffect, useCallback, useEffect,
} from 'react';
import {
  string, bool, func, oneOf, object,
} from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DatePicker, Flex, Space } from 'antd';

import { antNotification } from '../../MainUtils';
import {
  defaultDate, dateDiff, transformDate, dateAdd,
} from '../../54origins/dateFormats54origins';

const { RangePicker } = DatePicker;

const getRangePresets = (startPoint) => ([
  {
    label: 'Week',
    value: [transformDate(startPoint), dateAdd(1, 'week', defaultDate())],
  },
  {
    label: '2 Weeks',
    value: [transformDate(startPoint), dateAdd(2, 'week', defaultDate())],
  },
  {
    label: 'Month',
    value: [transformDate(startPoint), dateAdd(1, 'month', defaultDate())],
  },
  {
    label: '2 Months',
    value: [transformDate(startPoint), dateAdd(2, 'month', defaultDate())],
  },
  {
    label: '3 Months',
    value: [transformDate(startPoint), dateAdd(3, 'month', defaultDate())],
  },
]);

const getDaysInfo = (mode, startDate, endDate) => {
  switch (mode) {
    case 'spent': {
      const daysDiff = dateDiff(startDate, 'days', endDate);
      const modalDaysDiff = Math.abs(daysDiff);
      return {
        value: daysDiff,
        string: `${modalDaysDiff} days spent`,
        className: 'text-sky-500',
      };
    }
    case 'normal':
    default: {
      const daysDiff = dateDiff(new Date(), 'days', endDate);
      const modalDaysDiff = Math.abs(daysDiff);
      const isExpired = daysDiff < 0;
      return {
        value: daysDiff,
        string: `${modalDaysDiff} ${isExpired ? 'days overdue' : 'days left'}`,
        className: isExpired ? 'text-red-500' : 'text-green-600',
      };
    }
  }
};

function DateRangePicker({
  mode = 'normal',
  variant = 'outlined',
  title,
  size = 'middle',
  placement = 'bottomLeft',
  popupStyle = {},
  popupContainerId = '',
  flexOptions = {},
  isAlwaysOpen = false,
  showDays = true,
  startDate,
  endDate,
  disabledStart = false,
  disabledEnd = false,
  minDate = defaultDate(),
  maxDate,
  onChange,
}) {
  const { t } = useTranslation();
  // console.log('!! date start:', startDate);
  // console.log('!! date end:', endDate);

  const [currentStartDate, setCurrentStartDate] = useState('');
  const [currentEndDate, setCurrentEndDate] = useState('');

  const transformedStartDate = transformDate(startDate);
  const transformedEndDate = transformDate(endDate);

  const daysInfo = getDaysInfo(mode, startDate, endDate);
  const rangePresets = getRangePresets(startDate || new Date());

  const {
    align,
    justify,
    vertical = true,
    flex,
    wrap,
  } = flexOptions;

  const getPopupContainerFunction = (containerID) => {
    if (containerID) return () => document.body.querySelector(`#${containerID}`);
    return undefined;
  };

  const onChangeHandler = useCallback((date, dateString) => {
    const [start, end] = date || [];
    const isValidDate = dateDiff(start, 'days', end) >= 0;

    if (!isValidDate) {
      antNotification(
        'warning',
        t('notifications.text.warning.incorrect_date', 'incorrect_date'),
      );
    }
    onChange?.(date, dateString);
  }, [onChange]);

  useEffect(() => {
    if (isAlwaysOpen) {
      document.getElementById('id-range-picker-start-date').focus();
      if (disabledStart) document.getElementById('id-range-picker-end-date').focus();
      if (disabledEnd) document.getElementById('id-range-picker-start-date').focus();
    }
  }, []);

  useLayoutEffect(() => {
    if (startDate && currentStartDate !== startDate) setCurrentStartDate(startDate);
  }, [startDate]);

  useLayoutEffect(() => {
    if (endDate && currentEndDate !== endDate) setCurrentEndDate(endDate);
  }, [endDate]);

  return (
    <Flex
      gap={5}
      align={align}
      justify={justify}
      vertical={vertical}
      wrap={wrap}
      flex={flex}
    >
      <Space gap="small">
        {title && <span className="wrapper-title">{`${title}:`}</span>}
        {showDays && <span className={`${daysInfo.className}`}>{daysInfo.string}</span>}
      </Space>
      <RangePicker
        id={{ start: 'id-range-picker-start-date', end: 'id-range-picker-end-date' }}
        open={isAlwaysOpen ? true : undefined}
        size={size}
        variant={variant}
        placement={placement}
        popupStyle={popupStyle}
        getPopupContainer={getPopupContainerFunction(popupContainerId)}
        // placeholder={['', 'Till Now']}
        presets={rangePresets}
        format="DD-MM-YYYY"
        allowClear={false}
        order={false}
        showNow
        status={daysInfo.value < 0 && 'error'}
        minDate={minDate}
        value={[transformedStartDate, transformedEndDate]}
        disabled={[disabledStart, disabledEnd]}
        onChange={onChangeHandler}
      />
    </Flex>
  );
}

DateRangePicker.propTypes = {
  mode: oneOf(['normal', 'spent']),
  variant: oneOf(['outlined', 'borderless', 'filled']),
  title: string,
  size: oneOf(['small', 'middle', 'large']),
  placement: oneOf(['bottomLeft', 'bottomRight', 'topLeft', 'topRight']),
  popupStyle: object,
  popupContainerId: string,
  flexOptions: object,
  isAlwaysOpen: bool,
  startDate: string,
  endDate: string,
  disabledStart: bool,
  disabledEnd: bool,
  minDate: string,
  maxDate: string,
  showDays: bool,
  onChange: func,
};

export default DateRangePicker;

import { array, bool, func, object, string } from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlansForDay from "../../myBoard/plansForDayComponents/PlansForDay";
import { stopPropagation } from "../../../../54origins/utils54origins";
import { getIssueEntityFetching } from "../../../selectors/selectors";
import {  Button, ConfigProvider, Flex, Modal, Segmented, Tooltip, } from "antd";
import IssueInfoRoot from "../../issues/issueInfo/IssueInfoRoot";
import { get} from "lodash";
import { entityRead, entityUpdate } from "../../../../entity/actions/entityActions";
import Icon from "@mdi/react";
import { mdiHelpCircleOutline } from "@mdi/js";
import { getClassBtn, getDaysUntilSunday, isFutureDate, modeOptions, mods } from "./utils";
import SwitcherOfDate from "../../../../userFlow/components/WeekPlanning/SwitcherOfDate";
import { updateUserBoard } from "../../../../myBoard/actions/userBoardActions";
import { antNotification } from "../../../../MainUtils";

const WeekPlanningBoard = ({
    today,
    // fetchedBoards,
    partition,
    customActor,
    getClearIssue,
    selectedUser,
    // add props from hook
    todayIssues,
    dayParams, 
    setMode,
    mode,
    setDay,
    // add week planning props
    getListRelatedGeneralIssues,
    updateVectorRelatedGeneralIssues,
    todayVector,
    currentDay,
    loadingWeekBoard,
    updateGeneralPlanboardIssues,
    updateRenderedBoard,
    updateRenderedIssues
}) => {

    const dispatch = useDispatch()
    const issueFetching = useSelector(getIssueEntityFetching);
    const [activeIssue, setActiveIssue] = useState(null)

    const days = getDaysUntilSunday()

    const saveIssues = () => {
      setMode(mods.WORK_MODE)
      antNotification('success', 'Success');
    }

    const onChangeIssue = async (issue) => {
      if (!issueFetching) {
        setActiveIssue(issue)

        await getClearIssue(issue.uuid)
          .catch((error) => {
            if (error.message) {
              console.log('error', error.message);
            }
          });
      }
    };

    // add consts
    const todayVectorUUID = get(todayVector, 'uuid');
    const todayVectorIssues = get(todayVector, 'params.relatedGeneralIssues') || []
  

    const requestUpdateIssue = (data) => {
      const constants = [
        'UPDATE_ISSUE_FOR_MY_BOARD_REQUEST',
        'UPDATE_ISSUE_FOR_MY_BOARD_SUCCESS',
        'UPDATE_ISSUE_FOR_MY_BOARD_FAILURE',
      ];
  
      const options = {
        partition
      };
  
      return dispatch(entityUpdate({
        data,
        constants,
        options,
      }));
    };

    const resetIssueCallback = async () => {
      if (isFutureDate(currentDay)) {
        getListRelatedGeneralIssues(currentDay)
      } 
      updateRenderedBoard(activeIssue.userBoardUUID)
      setActiveIssue(null);
      
    };

    const getBoard = async (userBoardUUID) => {
        const board = await dispatch(entityRead({
            data: {
              entity_type: 'userBoard',
              entity_uuids: [userBoardUUID],
            },
            constants: [
              'GET_USER_BOARD_REQUEST',
              'GET_USER_BOARD_SUCCESS',
              'GET_USER_BOARD_FAILURE',
            ],
            options: {
              partition,
            },
          }));
          return board[0]
     }

     const updateBoard = async (userBoardUUID, params) => {
        await dispatch(updateUserBoard({
            entity_uuid: userBoardUUID,
            entity_type: 'userBoard',
            params: {
              ...params
            }
          }))
     }

    const onDeleteIssueCallback = async (data) => {
      if (!isFutureDate(currentDay)) {
        return;
      }

      const updateRelatedGeneralIssues = (issues, vectorUUID) => {
        const newRelatedGeneralIssues = issues.filter(issue => issue.uuid !== data.uuid);
        updateVectorRelatedGeneralIssues(vectorUUID, { relatedGeneralIssues: newRelatedGeneralIssues });
      };

      const issues =  todayVectorIssues;
      const vectorUUID = todayVectorUUID ;
      const userBoardUUID = issues.find(issue => issue.uuid === data.uuid).userBoardUUID

      
      updateRelatedGeneralIssues(issues, vectorUUID);

      const userBoard = await dispatch(entityRead({
        data: {
          entity_type: 'userBoard',
          entity_uuids: [userBoardUUID],
        },
        constants: [
          'GET_USER_BOARD_REQUEST',
          'GET_USER_BOARD_SUCCESS',
          'GET_USER_BOARD_FAILURE',
        ],
        options: {
          partition,
        },
      }));
    
      const getUpdatedData = (dayKey) => {
        const prevData = get(userBoard[0], `params.${dayKey}__data`, []);
        const prevUUIDs = get(userBoard[0], `params.${dayKey}`, []);
        const newUUIDs = prevUUIDs.filter(uuid => uuid !== data.uuid);
        const newData = prevData.filter(el => newUUIDs.includes(el.uuid));
        
        return {
          newUUIDs,
          newData,
        };
      };
    
      const todayUpdate = getUpdatedData(currentDay);
    
      await dispatch(updateUserBoard({
        entity_uuid: userBoardUUID,
        entity_type: 'userBoard',
        params: {
          [`${currentDay}`]: todayUpdate.newUUIDs,
          [`${currentDay}__data`]: todayUpdate.newData,
        }
      }));
    
      resetIssueCallback();
      updateRenderedBoard(userBoardUUID)
    }

    const onActionCallback = async (value, type, e, day) => {
      stopPropagation(e);
      switch (type) {
        case 'q-view': {
          if(isFutureDate(currentDay)) {
            if (value.uuid === activeIssue?.uuid) {
              resetIssueCallback();
            } else {
              onChangeIssue(value);
            }
          }
          break;
        }
        case 'issue-time-update': {
          const uuid = get(value, 'uuid', '');
          const newTime = get(value, 'value', '');
          const userBoardUUID = get(value, 'issue.userBoardUUID')
       
          if(isFutureDate(currentDay)) {
            // requestUpdateIssue({
            //   entity_uuid: uuid,
            //   params: {
            //     boardData: {chunk: newTime}
            //   }
            // });

            const newVectorIssues = todayVectorIssues.map(el => {
              if (el.uuid === uuid) {
                return {
                  ...el,
                  data: {chunk: newTime},
                }
              }
              return el
            });

            updateVectorRelatedGeneralIssues(todayVectorUUID, {relatedGeneralIssues: newVectorIssues})

            const userBoard = await getBoard(userBoardUUID)
            const currentDayData = get(userBoard, `params.${day}__data`, []);
            const newCurrentDayData = currentDayData.map(el => {
              if (el.uuid === uuid) {
                return {
                  ...el,
                  chunk: newTime,
                }
              }
              return el
            });
           await updateBoard(userBoardUUID, {[`${day}__data`]: newCurrentDayData})
           updateRenderedBoard(userBoardUUID)
          } 
          break;
        }
        case 'day-available-time-update': {
            if(isFutureDate(currentDay)) {
              updateVectorRelatedGeneralIssues(todayVectorUUID, {workTime: value})
            } 
            
          break;
        }
        case 'issue-remove': {
          const uuid = get(value, 'uuid', '');
          const userBoardUUID = get(value, 'userBoardUUID');
      
          if (isFutureDate(currentDay)) {
              const vectorUUID = todayVectorUUID
              const vectorIssues = todayVectorIssues
      
              const newRelatedGeneralIssues = vectorIssues.filter(el => el.uuid !== uuid);
            await  updateVectorRelatedGeneralIssues(vectorUUID, { relatedGeneralIssues: newRelatedGeneralIssues });
      
              const userBoard = await getBoard(userBoardUUID);
              const currentDayUUIDs = get(userBoard, `params.${day}`, []);
              const currentDayData = get(userBoard, `params.${day}__data`, []);
      
              const newCurrentDayUUIDs = currentDayUUIDs.filter(el => el !== uuid);
              const newCurrentDayData = currentDayData.filter(el => newCurrentDayUUIDs.includes(el.uuid));
    
             await updateBoard(userBoardUUID, {
                  [`${day}__data`]: newCurrentDayData,
                  [`${day}`]: newCurrentDayUUIDs
              });
              updateRenderedBoard(userBoardUUID)
              updateRenderedIssues(userBoardUUID)
          }
          break
      }
        case 'issue-complete': {
          const uuid = get(value, ['issue', 'uuid'], '');
          const newStatus = get(value, ['newStatus'], '');
          const userBoardUUID = get(value, ["issue", "userBoardUUID"], '')
          
          if(isFutureDate(currentDay)) {
           await requestUpdateIssue({
              entity_uuid: uuid,
              params: {
                status: newStatus,
                completed: [customActor],
                done: true,
              }
            })
           await getListRelatedGeneralIssues()
           updateRenderedBoard(userBoardUUID)
          } 
          break;
        }
        case 'issue-reopen': {
          const uuid = get(value, ['uuid'], '');
          const completed = get(value, ['completed'], []);
          const userBoardUUID = get(value, ["userBoardUUID"], '')

          if(isFutureDate(currentDay)) {
            await  requestUpdateIssue({
              entity_uuid: uuid,
              params: {
                completed: completed.filter(el => el !== customActor),
                status: 'reopened',
                done: false
              },
            })
           await getListRelatedGeneralIssues()
           updateRenderedBoard(userBoardUUID)
          }
          break;
        }
        case 'issue-users-update': {
          const uuid = get(value, ['uuid'], '');
          const users = get(value, ['users'], []);
          const projectUUID = get(value, ['project'], '')
          const userBoardUUID = get(value, ["userBoardUUID"], '')
          
          if(isFutureDate(currentDay)) {
           await requestUpdateIssue({
              entity_uuid: uuid,
              parent: projectUUID,
              params: {
                users: users.map(el => ({ uuid: el })),
                usersSearch: users,
              }
            })
          await getListRelatedGeneralIssues()
          updateRenderedBoard(userBoardUUID)
          } 
          
          break;
        }
        default: return;
      }
    };

    // ENDACTIONFUNC

    const SaveAndGetButtons = () => {
      return <Flex gap="small" className="w-full mt-1.5" align="center"  justify="end">
         <ConfigProvider
            theme={{
              components: {
                Segmented: {
                  itemSelectedBg: "white",
                  trackBg: '#cde4fd',
                },
              },
            }}
            >
          <Flex align="center">
          <h6 className="text-gray-500 mt-2 mr-1">Mode:</h6>
            <Segmented
            className="planboard-segmented"
            options={modeOptions}
            onChange={(value) => setMode(value)}
            value={mode}
            disabled={!days.includes(currentDay)}
            size="middle"
            />
          </Flex>
          </ConfigProvider>
      {/* <Button
        disabled={mode === mods.WORK_MODE}
        className={`${getClassBtn(mode === mods.WORK_MODE ? "disabled" : "get")}`}
        size="small"
        onClick={() => updateGeneralPlanboardIssues()}
      >
        <Tooltip
      color="white"
      title={(
        <Flex
          vertical
          className='text-black'
        >
         Get user issues from all plans in projects
        </Flex>)
      }
    >
      <Icon
        path={mdiHelpCircleOutline}
        // className='ml-1'
        size={0.9}
      />
    </Tooltip>
    Get general planboard from shown projects
  </Button>
  <Button
        disabled={mode === mods.WORK_MODE}
        className={`${getClassBtn(mode === mods.WORK_MODE ? "disabled" : "save")}`}
        size="small"
        onClick={() => saveIssues()}
        loading={false}
        iconPosition="end"
      >
        <Flex>
        <Tooltip
      color="white"
      title={(
        <Flex
          vertical
          className='text-black'
        >
       Save the current configuration of plans. All plans will be overwritten
        </Flex>)
      }
    >
      <Icon
        path={mdiHelpCircleOutline}
        className='mr-1'
        size={0.9}
      />
    </Tooltip>
        Save
        </Flex>
  </Button> */}
      </Flex>
    }
    return (
  <Flex vertical className="pr-1 pl-1">
       <Flex justify="space-between">
       </Flex>
    <SwitcherOfDate
    typePeriod="day"
    defaultDate={currentDay}
    changePeriodCallback={setDay}
    />
     <Flex className="w-full mt-1 mb-1" justify="space-between">
     <Flex align="center" className="w-full">
        <h6 className="mt-3.5 text-gray-500">General board member:</h6>
        <h6 className="ml-1 mt-2.5 text-lg">{selectedUser.label}</h6>
        </Flex>
          <SaveAndGetButtons/>
     </Flex>
      <Flex vertical>
        <PlansForDay
        key={today}
        day={currentDay}
        dayParams={dayParams}
        issues={todayIssues}
        partition={partition}
        loading={loadingWeekBoard}
        actorUUID={customActor}
        onActionCallback={onActionCallback}
        hideElements={['addIssueBtn', "idColumn"]}
        isMainBoard={true}
        currentDay={currentDay}
        />
          {activeIssue && (
              <Modal
                open
                footer={null}
                destroyOnClose
                closable={false}
                onCancel={resetIssueCallback}
                width={820}
              >
                {activeIssue && 
                <IssueInfoRoot
                  // hiddenView={hiddenInIssueInfo}
                  // disabledView={disabledInIssueInfo}
                  partitionType={partition}
                  resetIssueCallback={resetIssueCallback}
                  deleteCallback={onDeleteIssueCallback}
                  onChangeRowCallback={(uuid) => onActionCallback(uuid, 'q-view')}
                  customProject={activeIssue.project}
                />}
              </Modal>
            )}
          </Flex>
      </Flex>
    )
     
    
}

WeekPlanningBoard.propTypes = {
 today: string,
 fetchedBoards: array,
 customActor: string,
 partition: string,
 partitionPM: string,
 getClearIssue: func,
 projects: array,
 getFetchBoards: func,
 selectedUser: object,
 todayIssues: array,
 saveIssuesInAllBoards: func,
 updateGeneralPlanboardStorage: func,
 updateGeneralPlanboardStorageTodayIssues: func,
 dayParams: object,
 saveLoading: bool,
 updateLoading: bool, 
 loading: bool, 
 setMode: func,
 mode: string,
 setDay: func,
 day: string,
 tomorrow: string,
 //add weekplanning props
 getListRelatedGeneralIssues: func,
 updateVectorRelatedGeneralIssues: func,
 todayVector: object,
 loadingWeekBoard: bool,
 currentDay: string,
 updateGeneralPlanboardIssues: func,
 updateRenderedBoard: func,
 updateRenderedIssues: func
}

export default WeekPlanningBoard
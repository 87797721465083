import { capitalize, get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Col, Dropdown, Tag, Tooltip,
} from 'antd';
import Icon from '@mdi/react';
import {
  mdiCalendarAlert, mdiDotsVertical, mdiGhostOutline, mdiTrashCanOutline, mdiPlusMinusBox,
} from '@mdi/js';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';

import AntDesignSearchBox54origins from '../../../../../54origins/components/AntDesignSearchBox54origins';
import BaseButton from '../../../../../components/_ui/BaseButton/BaseButton';
import MasqueradingBtn54origins from '../../../../../54origins/components/MasqueradingBtn54origins';
import AddOrRemoveUserBanGroup from '../../../AddOrRemoveUserBanGroup';
import CircleActorAvatar54origins
  from '../../../../../54origins/components/CircleActorAvatar54origins';

import {
  getInitialsNameOfActor,
  getNameForActor,
  stopPropagation,
} from '../../../../../54origins/utils54origins';

import { getLocale } from '../../../../../locale/selectors';
import { getAdminGroupUUID, getBanGroupUUID } from '../../../../../actors/slices/defaultGroupsSlice';
import publicUsersEntities from '../../../../../publicPartition/reducers/publicUsersEntities';
import { getPeriodStartWorkReportsData } from '../../../../../userFlow/store/selectors/periodReportsSelectors';
import useURLParams from '../../../../../hooks/useURLParams';
import { InternalModalBtn } from '../../../InternalModalBtn';
import { configUrlAuth } from '../../../../../api/appConfig';
import { entityBackendURL } from '../../../../../api/api';
import DayUserReportGroups from '../../DayUserReportGroups';
import BaseTag from "../../../../../components/_ui/BaseTag/BaseTag";

function AlertView({
  haveBothNotifications,
  haveWorkQuestions,
  dontHaveEnoughTasks,
  feelSickGoingHome,
}) {
  const templateMsg = (
    <>
      <p>{haveWorkQuestions ? 'I have work questions' : ''}</p>
      <p>{dontHaveEnoughTasks ? 'I dont have enough tasks' : ''}</p>
      <p>{feelSickGoingHome ? 'I feel sick going home' : ''}</p>
    </>
  );

  return (
    <Tooltip
      placement="left"
      title={templateMsg}
      color={haveBothNotifications ? '#ff0000' : '#f89451cb'}
    >
      <Icon color={haveBothNotifications ? '#ff0000' : '#f89451cb'} size={1} path={mdiCalendarAlert} />
    </Tooltip>
  );
}

export default function useDayUserReportUsersTableColumns({
  setTypeOfSort,
  setValueOfSort,
  setShowDeleteModal,
  setObjectOfSort,
  typeOfSort,
  valueOfSort,
  getActorsWithSearchFilterAndPagination,
}) {
  const { t } = useTranslation();
  const { getStartDateParam, getActorUUIDParam, routeNavigateTo } = useURLParams();

  const locale = useSelector(getLocale);
  const usersStartWorkReports = useSelector(getPeriodStartWorkReportsData);
  const usersEntities = useSelector(publicUsersEntities);
  const banGroupUUID = useSelector(getBanGroupUUID);
  const adminGroupUUID = useSelector(getAdminGroupUUID);

  // console.log(publicUsersStartWorkReports);
  const actor = getActorUUIDParam;
  const selectedDay = getStartDateParam;

  const [searchData, setSearchData] = useState(null);

  const searchInTitle = (
    <Col className="flex align-item-end minWidth130" span={24}>
      <AntDesignSearchBox54origins
        onSearch={setSearchData}
      />
    </Col>
  );

  const dayUserReportActions = (cell, row) => (
    <div
      style={{ minWidth: '200px' }}
      className="flex"
    >
      <Tooltip title="Personal dashboard">
        <BaseButton
          onClick={stopPropagation}
          className="btnPrimary-outline mr-2"
        >
          <a
            target="_blank"
            onClick={stopPropagation}
            href={`/admin/dashboard/public/user/${row?.uuid}/projects`}
            rel="noreferrer"
          >
            PD
          </a>
        </BaseButton>
      </Tooltip>
      <Tooltip title="User reports">
        <BaseButton
          className="btnPrimary-outline mr-2"
          onClick={(e) => {
            stopPropagation(e);
            routeNavigateTo('/tools/userReport', {
              activeActor: cell,
              actorUUID: row.uuid,
            });
          }}
        >
          UR
        </BaseButton>
      </Tooltip>
      <Tooltip title={"Go to user in auth"}>
        <div onClick={stopPropagation} className="mr-2">
          <BaseButton onClick={() => {
            window.open(`${configUrlAuth}/admin/actors/list&uuid=${cell}&view=info`, '_blank');
          }}
          >
            Auth
          </BaseButton>
        </div>
      </Tooltip>
      <Tooltip title={"Internal"}>
        <div onClick={stopPropagation} className="mr-2">
          <InternalModalBtn
            btnClassName="w-full"
            actor={row}
            onSaveCallBack={getActorsWithSearchFilterAndPagination}
          />
        </div>
      </Tooltip>
      <Tooltip title={"Groups"}>
        <div onClick={stopPropagation} className="mr-2">
          <DayUserReportGroups
            actorUUID={cell}
            onSaveCallBack={getActorsWithSearchFilterAndPagination}
            actorType={row?.actor_type}
            groups={row?.uinfo?.groups}
          />
        </div>
      </Tooltip>
      <div onClick={stopPropagation} className="mr-2">
        <MasqueradingBtn54origins
          url={entityBackendURL()}
          actorUUID={cell}
          btnIcon={<Icon path={mdiGhostOutline} size={0.6}/>}
          btnSize=""
          btnLabel=""
          hideTooltip={false}
        />
      </div>
      <Tooltip title={row?.uinfo?.groups?.includes(banGroupUUID) ? "Unban user" : "Ban user"}>
        <div onClick={stopPropagation} className="mr-2">
          <AddOrRemoveUserBanGroup
            actor={row}
            finallyUpdateActorCallback={getActorsWithSearchFilterAndPagination}
            fullLabel={false}
          />
        </div>
      </Tooltip>
      <Tooltip title={"Remove actor"}>
        <BaseButton
          danger
          onClick={(e) => {
            stopPropagation(e);
            setShowDeleteModal(row);
          }}
          icon={
            <Icon
              path={mdiTrashCanOutline}
              size={0.8}
            />
          }
        />
      </Tooltip>
    </div>
  );

  const columns = useMemo(() => ([
    {
      dataIndex: 'actor',
      key: 'onWorkPlace',
      title: 'Online',
      render: (cell, row) => {
        return (
          <div className="relative avatar-wrapper flex align-items">
            <CircleActorAvatar54origins
              actorUUID={row?.actor}
              name={getInitialsNameOfActor(row)}
              size="large"
            />
          </div>
        );
      },
    },
    {
      dataIndex: 'actor_type',
      title: searchInTitle,
      width: '15%',
      render: (cell, row) => (
        <>
          <p
            className="user-dashboard-table-user-name"
          >
            {getNameForActor(row)}
          </p>
          <div className="flex items-center">

            <BaseTag
              color="blue"
              className="fontSize10 mr-1"
            >
              {capitalize(row?.actor_type === 'classic_user' ? 'classic' : row?.actor_type)}
            </BaseTag>

            <BaseTag
              color="orange"
              className="fontSize10 mr-1"
            >
              {row?.uinfo?.internal_user ? 'Internal' : 'External'}
            </BaseTag>

            {row?.uinfo?.groups?.includes(banGroupUUID) && (
              <BaseTag
                color="orange"
                className="fontSize10 mr-1"
              >
                BANNED
              </BaseTag>
            )}
            {row?.uinfo?.groups?.includes(adminGroupUUID) && (
              <BaseTag
                color="red"
                className="fontSize10"
              >
                ADMIN
              </BaseTag>
            )}
          </div>
        </>
      ),
    },
    {
      dataIndex: 'created',
      key: 'actorCreated',
      title: capitalize(t('wms.table.headers.created', 'created')),
      sorter: (a, b, c) => {
        setTypeOfSort('created');
        setValueOfSort(c);
        setObjectOfSort({ createdS: c });
      },
      sortOrder: typeOfSort === 'created' ? valueOfSort : null,
      render: (cell) => (
        <Tooltip
          placement="left"
          title={moment(cell).locale(locale).format('DD MMM YY, LT')}
        >
          {moment(cell).locale(locale).format('DD-MM-YY')}
        </Tooltip>
      ),
    },
    {
      dataIndex: 'actor',
      title: t('wms.reports.stats.actions', 'Actions'),
      key: 'actions',
      render: dayUserReportActions,
    },
  ]), [usersStartWorkReports, usersEntities, typeOfSort, valueOfSort]);

  const currentColumns = useMemo(
    () => columns.filter((e) => (selectedDay !== moment().format('YYYY-MM-DD') ? e.key !== 'onWorkPlace' : e)),
    [columns, actor, selectedDay],
  );

  return (
    [currentColumns, { searchData, }]
  );
}

/* eslint-disable import/prefer-default-export */
import {
  mdiBellOutline,
  mdiProgressClock,
  mdiCloseThick,
  mdiCheckBold,
  mdiClockTimeThreeOutline,
} from '@mdi/js';

export const goalStatusOptions = [
  {
    value: 'created',
    label: 'wms.status.created',
    className: 'radio-lightBlue',
    icon: mdiBellOutline,
  },
  {
    value: 'in progress',
    label: 'wms.status.in progress',
    className: 'radio-darkBlue',
    icon: mdiProgressClock,
  },
  {
    value: 'completed',
    label: 'wms.status.completed',
    className: 'radio-green',
    icon: mdiCheckBold,
  },
  {
    value: 'failed',
    label: 'wms.status.failed',
    className: 'radio-red',
    icon: mdiCloseThick,
  },
  {
    value: 'backlog',
    label: 'wms.status.backlog',
    className: 'radio-gray',
    icon: mdiClockTimeThreeOutline,
  },
];

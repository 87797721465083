import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { Select } from 'antd';
import { capitalize, difference, get } from 'lodash';
import {
  array, bool, func, string,
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import BaseButton from '../../components/_ui/BaseButton/BaseButton';

import { getUsersByUUID } from '../../userFlow/store/action-creators/userActions';
import { getNameForActor } from '../../54origins/utils54origins';
import ReactSelect from "../../components/ReactSelect/ReactSelect";

export default function SelectAssignedUsers({
  showParentUsersBtn,
  getParentListCallback,
  projectUsers = [],
  isDisabled,
  actorsUuid = [],
  customSelect = [],
  typeSelect = 'multiple',
  onSelectCallback,
  size = 'large',
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [listActors, setListActors] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showParentList, setShowParentList] = useState(false);

  const getListUsers = async (actors) => {
    const data = await dispatch(getUsersByUUID({
      uuid: actors,
      userType: 'other',
    }));

    setListActors(data.map((item) => ({
      value: item?.uuid,
      label: projectUsers?.find((el) => el.uuid === item.uuid)?.role
        ? `${getNameForActor(item)} ( role: ${projectUsers?.find((el) => el.uuid === item.uuid)?.role} )`
        : `${getNameForActor(item)}`,
    })));
  };

  const onChangeSelect = (data) => {
    onSelectCallback(data);
    setSelectedUsers(data);
  };

  // const noOptionsMessage = () => capitalize(t('wms.select.no_options', 'no options'));
  const placeholder = capitalize(t('wms.placeholders.users', 'Users'));

  const toggleShowParentList = () => {
    setShowParentList((prev) => {
      getParentListCallback(prev ? 'vector' : 'project');
      return !prev;
    });
  };

  const dropdownRender = (menu) => (
    <>
      {menu}
      {showParentUsersBtn && (
        <BaseButton
          type="link"
          onClick={toggleShowParentList}
        >
          {capitalize(t(`wms.labels.${showParentList ? 'hide' : 'show'}_project_users`))}
          <Icon size={1} path={mdiChevronDown} vertical={showParentList} />
        </BaseButton>
      )}
    </>
  );

  useEffect(() => {
    if (JSON.stringify(customSelect) !== JSON.stringify(selectedUsers)) {
      setSelectedUsers(customSelect);
    }
  }, [JSON.stringify(customSelect)]);

  useEffect(() => {
    const differenceUsers = difference(customSelect, actorsUuid);
    const actors = [...differenceUsers, ...actorsUuid];
    if (actors.length) {
      getListUsers(actors);
    } else {
      setListActors([]);
    }
  }, [JSON.stringify(actorsUuid)]);

  useEffect(() => {
    if (!showParentUsersBtn) {
      getListUsers(projectUsers.map((user) => user?.uuid))
    }
  }, [showParentUsersBtn]);

  return (
    <ReactSelect
      showArrow
      allowClear
      optionFilterProp="label"
      optionLabelProp="label"
      autoClearSearchValue={false}
      dropdownRender={dropdownRender}
      mode={typeSelect}
      size={size}
      disabled={isDisabled}
      value={selectedUsers}
      options={listActors}
      onChange={onChangeSelect}
      isClearable
      classNamePrefix="react-select"
      className="react-select w-full"
      placeholder={placeholder}
    />
  );
}

SelectAssignedUsers.propTypes = {
  isDisabled: bool,
  actorsUuid: string,
  customSelect: array,
  typeSelect: string,
  onSelectCallback: func,
  size: string,
  projectUsers: array,
  showParentUsersBtn: bool,
  getParentListCallback: func,
};

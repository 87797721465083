import React from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { getProject } from '../projectFlow/selectors/selectors';
import { getRootEntityPartition } from '../config/selectors/selectors';
import { partitionNamesConfig } from '../api/appConfig';
import { Tooltip } from "antd";

function ProjectName() {
  const url = window.location.pathname;
  const selectedProjects = useSelector(getProject);
  const selectedProjectName = get(selectedProjects, '[0].params.title');
  const partitionType = selectedProjects[0]?.parent;
  const partitionPM = useSelector((state) => getRootEntityPartition(state, partitionNamesConfig.partition1));

  const projectTypeTitle = partitionType === partitionPM ? 'General project' : 'Personal project';

  return (
    selectedProjectName && url.includes('projects') ? (
      <div className="mx-4 overflow-hidden max-w-56 shrink-0">
        <p className="text-xs">{projectTypeTitle}:</p>
        <Tooltip title={selectedProjectName}>
          <p className="text-lg text-ellipsis">{selectedProjectName}</p>
        </Tooltip>
      </div>
    ) : null
  );
}

// ProjectName.propTypes = {
//   menuOpen: PropTypes.bool,
// };

export default ProjectName;

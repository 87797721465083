import React from 'react';

import cn from 'classnames';

import { Col } from 'antd';

import { useExpandModeContext } from './ExpandModeLayout';

function ExpandModeLayoutTable({ children }) {
  const { isWideMode } = useExpandModeContext();
  return (
    <Col className={cn(
      'pr-3 expandCol',
      { '_collapsed-100': isWideMode },
    )}
    >
      {children}
    </Col>
  );
}

export default ExpandModeLayoutTable;

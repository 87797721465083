import React, { memo } from 'react';
import { func, number, string } from 'prop-types';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

function NewOrderFormater({
  onMoveFuncCallback,
  allListLength,
  currentKey,
}) {
// console.log('currentKey', currentKey, 'allListLength',allListLength)
  return (
    <div className="flex justify-center">
      <BaseButton
        disabled={currentKey + 1 === allListLength}
        onClick={() => onMoveFuncCallback('down', currentKey)}
        style={{
          width: 30,
          height: 30,
          padding: 0,
          backgroundColor: '#d3eefc',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon path={mdiChevronDown} color="cornflowerblue" size={0.8} />
      </BaseButton>

      <BaseButton
        disabled={currentKey === 0}
        onClick={() => onMoveFuncCallback('up', currentKey)}
        style={{
          width: 30,
          height: 30,
          padding: 0,
          backgroundColor: 'rgba(255, 0, 0, 0.104)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >

        <Icon path={mdiChevronUp} color="red" size={0.8} />
      </BaseButton>
    </div>
  );
}

NewOrderFormater.propTypes = {

};

export default memo(NewOrderFormater);

NewOrderFormater.propTypes = {
  onMoveFuncCallback: func,
  allListLength: number,
  currentKey: string,
}

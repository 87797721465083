import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty, last } from 'lodash';
import { array, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiFullscreen, mdiFullscreenExit } from '@mdi/js';
import { Button, Spin } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import JSpreadsheet from '../../../../../JSpreadsheet/JSpreadsheet';
import { getPartitionPMUUID, getPartitionPublicUUID } from "../../../config/selectors/selectors";

import { capitalize } from 'lodash';
import ExportReport from '../ExportReport.jsx';
import { getListAndReadAffix } from "../../../affix/affixActions";
import { partitionNamesConfig } from '../../../api/appConfig.js';


const columnsStartWork = [
  {
    name: 'date',
    title: 'Date',
    width: 120,
    align: 'left',
  },
  {
    name: 'trackerTime',
    title: 'Timelog',
    width: 90,
  },
  {
    name: 'title',
    title: 'title',
    width: 90,
  },
  {
    name: 'tracker',
    title: 'tracker',
    width: 90,
  },
  {
    name: 'type',
    title: 'timelog type',
    width: 100,
  },
  {
    name: 'description',
    title: 'description',
    width: 100,
  },
];

export default function SpreadSheetReport({
  currentPeriodDate,
  selectedUser,
  actorName,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const publicParentEntity = useSelector(getPartitionPublicUUID);
  const pmParentEntity = useSelector(getPartitionPMUUID);

  const [dataSheet, setDataSheet] = useState([]);
  const [styleCells, setStyleCells] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const toggleFullscreen = (flag) => {
    setFullscreen(flag);
  };

  const setStyleCell = (obj, arr, indexRow) => {
    const newStyle = obj;

    arr.forEach((i) => {
      const index = `${i}${indexRow}`;
      newStyle[index] = 'background-color: #fff1f0;';
    });
    return newStyle;
  };

  const totalTime = useMemo(() => get(last(dataSheet), 'totalTime') || get(last(dataSheet), 'trackerTime'), [dataSheet]);

  const initFunc = async () => {
    const { startDate = '', endDate = '' } = currentPeriodDate || {};

    dispatch(getListAndReadAffix({
      partition: partitionNamesConfig.partition1,
      data: {
        entity_uuid: pmParentEntity,
        affix_type: 'timeLog',
        depth: 0,
        actor: selectedUser,
        params: {
          date__gte: startDate,
          date__lte: endDate,
        }

      },
    })).then((res) => {
      console.log('res', res?.data);
      setDataSheet(res?.data.map(item => item.params))
    })
  };

  useEffect(() => {
    if (!isEmpty(selectedUser)) {
      initFunc();
    }
  }, [selectedUser, currentPeriodDate.startDate, currentPeriodDate.endDate]);

  return (
    <div id="user-report" className={`wrapper-view-report ${fullscreen ? '_fullscreen' : ''}`}>
      <h5 className="p-0 mb-2 mr-3">{actorName}</h5>
      <div className="flex">
        <Button
          id='userReportSetFullscreenButton'
          onClick={() => toggleFullscreen(!fullscreen)}
          size="small"
          className="btnPrimary-outline ant-btn-flex mb-1 mr-1"
        >
          <Icon path={fullscreen ? mdiFullscreenExit : mdiFullscreen} size={1} />
          <span className="ml-1">
                {fullscreen ? capitalize(t('wms.buttons.full_screen_exit')) : capitalize(t('wms.buttons.full_screen'))}
              </span>
        </Button>
        <ExportReport
          titleReport={actorName}
          columns={columnsStartWork}
          dataReport={dataSheet}
          disabled={disabled}
        />
      </div>

      <div className="wrapper-view-report__view mt-0">
        {!loading ? (
          <JSpreadsheet
            columns={columnsStartWork}
            isReadOnly
            height="100%"
            defaultTextDescription={dataSheet}
            styleCells={styleCells}
            isFullscreen={fullscreen}
          />
        ) : <Spin spinning={loading} className="flex justify-center mt-3" />}
      </div>

      <div className={cn({ 'hidden': !isEmpty(selectedUser), 'wrapper-not-selected': isEmpty(selectedUser) })}>
        Select user...
      </div>
    </div>
  );
}
SpreadSheetReport.propTypes = {
  currentPeriodDate: string,
  selectedUser: array,
  actorName: string,
};

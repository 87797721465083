import React from 'react';
import {
  Tooltip, Tag, Row, Col,
} from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isNil, propOr } from 'ramda';

import './css/SystemTabs.scss';

import Icon from '@mdi/react';
import { mdiAlpha } from '@mdi/js';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import BaseTag from "../../../components/_ui/BaseTag/BaseTag";

const showTypeStyle = {
  fontSize: 10,
  // paddingLeft: 5,
  position: 'absolute',
  top: -15,
};

function SystemTabs({
  // switchViewFunc,
  viewMode,
  getPathForLink,
  onClickCallback,
  tabs = [],
  t,
}) {
  const getTranslate = (name) => {
    if (!name) {
      return '';
    }

    return capitalize(t(name.toLowerCase(), name));
  };

  const getType = (type) => {
    const parsedType = type.split('-')[1];

    return capitalize(
      t(`wms.tabs_types.${parsedType.toLowerCase()}`, parsedType),
    );
  };

  const currentVersion = (version) => {
    switch (version) {
      case 'alpha':
        return (
          <div className="alphaProd">
            <Tooltip
              placement="top"
              title={capitalize(
                t('wms.tooltips.not_availabl', 'not available in this version'),
              )}
            >
              <BaseTag color="red" className="flex items-center h-5 ">
                <Icon path={mdiAlpha} size={0.8} />
              </BaseTag>
            </Tooltip>
          </div>
        );
      default:
        return '';
    }
  };

  const hideTabs = (bool) => bool && process.env.NODE_ENV === 'production';

  return (
    <Row gutter={[5, 5]} className="tabs-switch">
      {tabs.map((item) => (
        item?.customComponent
          ? (<div key={item.name} className="tabs_with_other_data">{item?.data}</div>)
          : (
            <div key={item.name}>
              {item.showType && (
                <div style={showTypeStyle} className="text-neutral-600">{getType(item.type)}</div>
              )}

              <Link
                id={`${item.view}Link`}
                className={`
                  ${(hideTabs(!isNil(item.version) && item.version === 'alpha') || item?.isHide) ? 'hidden' : ''}
                 mr-1 tab-item
                 ${item.type && item.type}
                 ${viewMode.toLowerCase() === item.view.toLowerCase() ? 'tab-item-current' : ''}`}
                onClick={onClickCallback}
                to={getPathForLink(item.view)}
              >
                {/* <div
                  className={`
                     ${(hideTabs(!isNil(item.version) && item.version === 'alpha') || item?.isHide) ? 'hidden' : ''}
                    mr-1 tab-item
                    ${item.type && item.type}
                    ${viewMode.toLowerCase() === item.view.toLowerCase() ? 'tab-item-current' : ''}`}
                  onClick={() => switchViewFunc(item.view)}
                > */}
                {!isNil(item.version) && currentVersion(item.version)}
                {item.icon && <span className="tab-icon">{propOr('', 'icon', item)}</span>}
                <span className="tab-text">
                  {getTranslate(item.name)}
                </span>
                {/* </div> */}
              </Link>

            </div>
          )
      ))}
    </Row>
  );
}

SystemTabs.propTypes = {
  viewMode: PropTypes.string,
  // switchViewFunc: PropTypes.func,
  getPathForLink: PropTypes.func,
  t: PropTypes.func.isRequired,
  onClickCallback: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.object,
      type: PropTypes.string,
      showType: PropTypes.bool,
    }),
  ),
};

export default withTranslation()(SystemTabs);

import {
  Col, Tooltip,
} from 'antd';
import {
  get, isEmpty, isNil, omit,
} from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';

import { capitalize } from 'lodash';
import { getListOfAllAffixByTypes } from '../../../actions/auditActions';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import { getAllAffixAuditData, getAllAffixAuditLoading } from '../../../selectors/auditSelectors';
import { getProjectUUID, globalUserFilterTags } from '../../../selectors/selectors';
import RenderAudio from './RenderAudio';
import RenderImg from './RenderImg';
import RenderTimeLog from './RenderTimeLog';
import { audioFiles, imageFiles, otherFiles } from './utilsAffix';
import ViewComment from './ViewComment';

export default function ListOfAllAffix({ modified }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    defaultPartition,
  } = useContext(ProjectMainLayerContext);

  const projectUUID = useSelector(getProjectUUID);
  const loading = useSelector(getAllAffixAuditLoading);
  const affixData = useSelector(getAllAffixAuditData);
  const userFilterTags = useSelector(globalUserFilterTags);

  // console.log(affixData);

  const defaultConfig = {
    affix_type: ['comment', 'timeLog'],
    depth: 0,
    entity_uuid: projectUUID,
    limit: 25,
    offset: 0,
    fields: {
      actor: 'actor',
    },
    params: {},
  };

  const {
    modified__gte,
    modified__lte,
  } = modified;

  const [currentConfig, setCurrentConfig] = useState(null);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [totalCount, setTotalCount] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState('');

  const dateFormatter = (cell) => (
    <Tooltip title={moment(cell).format('HH:mm')}>
      <div className="whitespace-nowrap flex items-center">
        <span className="inline-flex mr-2 color-blue">{moment().diff(moment(cell), 'days')}</span>
        <span className="text-xs">
          {capitalize(t('wms.labels.days_ago'))}
        </span>
      </div>

    </Tooltip>
  );

  const renderComment = (row) => {
    const image = imageFiles(row);
    const other = otherFiles(row);
    const audio = audioFiles(row);
    return (
      <div className="w-full">
        <ViewComment item={row} projectUUID={projectUUID} partition={defaultPartition} />

        <div className="flex flex-wrap w-full">
          {(image.length + other.length) > 0 && <RenderImg imageFiles={image} otherFiles={other} />}
          {/* {otherFiles.length > 0 && renderFiles} */}
          {audio.length > 0 && <RenderAudio audioFiles={audio} />}
        </div>
      </div>
    );
  };

  const formatDescription = (row) => (get(row, 'affix_type') === 'comment'
    ? renderComment(row)
    : <RenderTimeLog item={row} />);

  const formatUser = (cell) => (
    <span className="whitespace-nowrap color-user-activity">{`${get(cell, 'first_name')} ${get(cell, 'last_name')}`}</span>
  );

  // const formatEntity = (row) => {
  //   const cell = get(row, 'affix_type') === 'timeLog'
  //     ? get(row, 'params.type', 'issue')
  //     : get(row, 'params.comment.entity', 'issue');

  //   return (
  //     <EntityParamTag
  //       value={cell}
  //       type="badge"
  //       param="entity"
  //     />
  //   );
  // };

  const columns = [
    {
      dataIndex: ['uinfo'],
      title: capitalize(t('wms.table.headers.name')),
      render: (cell, row) => cell && formatUser(cell),
    },
    {
      dataIndex: 'affix_type',
      title: capitalize(t('wms.table.headers.affix_type')),
      // sorter: (a, b, c) => setSortSettings({ sortValue: c, sortType: 'entity_type' }),
      render: (cell) => cell && (
        <EntityParamTag
          value={cell}
          type="badge"
          param="affix"
        />
      ),
    },
    // {
    //   dataIndex: 'entity_type',
    //   title: capitalize(t('wms.labels.entity_type')),
    //   // sorter: (a, b, c) => setSortSettings({ sortValue: c, sortType: 'entity_type' }),
    //   render: (cell, row) => formatEntity(row),
    // },
    {
      dataIndex: ['params'],
      className: 'activity-affix-description',
      title: capitalize(t('wms.table.headers.description')),
      render: (cell, row) => {
        console.log('row', row);
        console.log('cell', cell);
        return cell && formatDescription(row);
      },
    },
    {
      dataIndex: 'created',
      title: capitalize(t('wms.table.headers.created', 'created')),
      render: dateFormatter,
    },
  ];

  const getListOfAllAffix = async (config) => {
    if (!config.entity_uuid || !config.affix_type) return;
    const newConfig = { ...omit(config, ['modified__gte', 'modified__lte']) };

    if (modified__gte && modified__lte) {
      newConfig.modified__gte = modified__gte;
      newConfig.modified__lte = modified__lte;
    }

    const data = await dispatch(getListOfAllAffixByTypes(newConfig, defaultPartition));
    const total = get(data, 'total');
    setTotalCount(total);
  };

  const rowClassName = (row) => {
    const data = `universal_table_row ${get(row, 'uuid') === selectedEntity
      ? 'selected'
      : ''
      }`;

    return data;
  };

  const selectRow = {
    mode: 'radio',
    clickToSelect: false,
    hideSelectColumn: true,
    selected: [selectedEntity],
    style: { background: '#e5f4fc' },
  };

  const onRow = (row) => ({
    onClick: (e) => {
      setSelectedEntity(row);
    },
  });

  const onPageChange = async ({ pageLimit: pl, currentPage: cp, offset: offs }) => {
    setCurrentPage(cp);
    setPageLimit(pl);
    setOffset(offs);

    setCurrentConfig((prev) => ({
      ...prev,
      limit: pl,
      offset: offs,
    }));
  };

  const remotePagination = {
    sizePerPageList: [25, 50, 100],
    defaultPageLimit: 25,
    totalSize: totalCount,
    page: currentPage,
    onChange: onPageChange,
    // onSizePerPage: onSizePerPageTable,
  };

  useEffect(() => {
    const modifiedConfig = {
      modified__gte,
      modified__lte,
      offset: 0,
    };

    const affixConfig = isNil(currentConfig)
      ? {
        ...defaultConfig,
        ...modifiedConfig,
      }
      : {
        ...currentConfig,
        ...modifiedConfig,
      };

    // if (userFilterTags.length !== 0) affixConfig.params.usersSearch = userFilterTags;
    // else delete affixConfig?.params?.usersSearch;

    if (userFilterTags.length !== 0) affixConfig.actor = userFilterTags;
    else delete affixConfig?.actor;

    setCurrentConfig(affixConfig);

    setCurrentPage(1);
  }, [
    JSON.stringify(userFilterTags),
    modified__gte,
    modified__lte,
  ]);

  useEffect(() => {
    if (!isEmpty(currentConfig) && !isNil(currentConfig)) {
      getListOfAllAffix(currentConfig);
    }
  }, [JSON.stringify(currentConfig)]);

  useEffect(() => () => {
    dispatch({ type: ProjectTypeCommonConstants.CLEAR_LIST_AFFIX });
  }, []);

  return (
    <Col span={24} className="mt-2">
      <BaseTableWithPagination
        pageLimit={pageLimit}
        changePageLimit={setPageLimit}
        useCustomPagination
        onRow={onRow}
        columns={columns}
        data={affixData}
        rowSelection={selectRow}
        loading={loading}
        total={get(remotePagination, 'totalSize')}
        pageSizeOptions={get(remotePagination, 'sizePerPageList')}
        disablePagination={get(remotePagination, 'totalSize') <= get(remotePagination, 'defaultPageLimit')}
        newCurrentPage={get(remotePagination, 'page')}
        defaultPageLimit={get(remotePagination, 'defaultPageLimit')}
        getPaginationOptions={get(remotePagination, 'onChange')}
        rowClassName={rowClassName}
        paginationSize="small"
        headerRowClassName="universal_header_table_row"
      />
    </Col>
  );
}

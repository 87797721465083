/* eslint-disable no-param-reassign */
import { useDispatch } from 'react-redux';

import moment from 'moment';
import { getListOfProjectGoalsRequest } from '../../projectFlow/actions/projectGoalsActions';
import { getListOfMilestonesForScoreRequest } from '../../projectFlow/actions/projectMilestonesActions';

import {
  ADD_PROJECT_KPM,
  SET_KPM_COMPUTING,
  SET_KPM_COMPUTED_TIME,
  SET_PROJECTS_KPM,
} from '../../projectFlow/reducers/slicers/projectsKPMSlice';

import { computeMilestoneScore, scoreByTime } from '../../projectFlow/components/milestones/helpers/computeScore';
import { updateProjectScoring } from '../../projectFlow/actions/projectFlowActions';

const usePlanScore = () => {
  const dispatch = useDispatch();

  const updateProject = async (props) => {
    const {
      projectUuid, projectPartition,
      gScore,
      mScore,
      planScoreValue,
    } = props;

    const data = {
      uuid: projectUuid,
      params: {
        gmPlanScore: {
          updated: moment(),
          gScore,
          mScore,
          planScoreValue,
        },
      },
      partition: projectPartition,
    };
    return dispatch(updateProjectScoring(data));
  };

  const computeGoalsScore = (response) => {
    const { total: totalGoals = 0 } = response;
    // console.log('totalGoals:', totalGoals);
    const gScore = totalGoals;

    return gScore;
  };

  const computeMilestonesScore = (response) => {
    const {
      data: milestonesData = [],
      total: totalMilestones = 0,
    } = response;
    // console.log('milestonesData:', milestonesData);
    // console.log('response', response);
    let mScore = 0;
    let finalMScore = 0;
    let hasExpired = false;

    if (milestonesData?.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < milestonesData.length; i++) {
        const {
          status, importance, startDate, lifeTime,
        } = milestonesData[i];

        const score = computeMilestoneScore({
          status,
          importance,
          startDate,
          endDate: lifeTime,
        });

        if (score === scoreByTime.expired) {
          mScore = score;
          hasExpired = true;
          break;
        } else {
          mScore += score;
        }
      }

      if (hasExpired) finalMScore = mScore;
      else finalMScore = Number((mScore / totalMilestones).toFixed(1));
    }

    return finalMScore;
  };

  const computePlanScore = (mScore, gScore) => {
    if (mScore === scoreByTime.expired) return mScore;
    return Number((0.9 * mScore + 0.1 * gScore).toFixed(1));
  };

  const updateProjectsArray = (projectsArray = [], planScoresArray = []) => projectsArray
    .map((project) => ({
      ...project,
      gmPlanScore: planScoresArray.find((data) => data.uuid === project.uuid),
    }));

  const getDataForComputeScore = async (
    uuid,
    partition,
    isNeedProject = false,
    isAllScoring = false,
  ) => {
    const scoreData = {
      uuid,
      update: moment(),
    };

    const milestonesConfig = {
      parent: uuid,
      params: { status: ['in progress'] },
      offset: 0,
    };

    const goalsConfig = {
      parent: uuid,
      params: { status__not: ['completed', 'failed'] },
      offset: 0,
    };

    await Promise.all([
      dispatch(getListOfProjectGoalsRequest(goalsConfig, partition))
        .then((response) => {
          scoreData.gScore = computeGoalsScore(response, scoreData);
        }),
      dispatch(getListOfMilestonesForScoreRequest(milestonesConfig, partition))
        .then((response) => {
          scoreData.mScore = computeMilestonesScore(response, scoreData);
        }),
    ]);

    scoreData.planScoreValue = computePlanScore(scoreData.mScore, scoreData.gScore);

    if (!isAllScoring) {
      const res = await updateProject({
        gScore: scoreData.gScore,
        mScore: scoreData.mScore,
        planScoreValue: scoreData.planScoreValue,
        projectUuid: uuid,
        projectPartition: partition,
      });

      if (isNeedProject) {
        return res;
      }
    }

    return scoreData;
  };

  const computeProjectsPlanScore = async (projectsArray = [], isAllScoring = false) => {
    dispatch(SET_KPM_COMPUTING(true));

    try {
      const scoresArray = await Promise.all(
        projectsArray.map((project) => {
          const { uuid, partition } = project || {};
          return getDataForComputeScore(uuid, partition, false, isAllScoring);
        }),
      );
      // console.log('scoresArray:', scoresArray);

      const planScoresArray = scoresArray.map((scoreData) => {
        const { uuid, mScore = 0, gScore = 0 } = scoreData;

        return {
          uuid,
          mScore,
          gScore,
          planScoreValue: computePlanScore(mScore, gScore),
          updated: moment(),
        };
      });
      // console.log('planScoresArray:', planScoresArray);

      dispatch(SET_PROJECTS_KPM(planScoresArray));
      dispatch(SET_KPM_COMPUTED_TIME());

      return updateProjectsArray(projectsArray, planScoresArray);
    } catch (err) {
      console.error(`Can't get data for compute projects plan score: ${err.message}`);
    } finally {
      dispatch(SET_KPM_COMPUTING(false));
    }
  };

  const computeSingleProjectPlanScore = async (
    parent,
    partition,
    prevScoringData,
    isNeedProject,
  ) => {
    dispatch(SET_KPM_COMPUTING(true));
    try {
      const project = await getDataForComputeScore(
        parent,
        partition,
        prevScoringData,
        isNeedProject,
      ) || [{}];

      // const kpmData = {
      //   uuid,
      //   value: planScoreValue,
      //   date: new Date().toUTCString(),
      // };

      return project;
    } catch (err) {
      console.error(`Can't get data for compute projects plan score: ${err.message}`);
    } finally {
      dispatch(SET_KPM_COMPUTING(false));
    }
  };

  return {
    computeProjectsPlanScore,
    computePlanScore,
    computeGoalsScore,
    computeMilestoneScore,
    getDataForComputeScore,
    computeSingleProjectPlanScore,
  };
};

export default usePlanScore;

import PropTypes from 'prop-types';
import React from 'react';
import { Tag, Tooltip } from 'antd';
import { mdiAlpha, mdiBeta } from '@mdi/js';
import Icon from '@mdi/react';

import './CheckBuildVersionComponent.scss';
import BaseTag from "../_ui/BaseTag/BaseTag";
import { UI54Icon, UI54Tag } from "@agpl/ui54kit";

function VersionTag({ version, customStyleVersion }) {
  let iconTag = null;

  switch (version) {
    case 'alpha':
      iconTag = <UI54Icon path={mdiAlpha} size={0.7} />;
      break;
    case 'beta':
      iconTag = <UI54Icon path={mdiBeta} size={0.7} />;
      break;
    default:
      return (
        <div className={`buildVersionTag-${customStyleVersion || 'wrapper'}`}>
          <UI54Tag className="m-0" color="blue">
            <Tooltip placement="top" title={version.toUpperCase()}>
              <b>{version.charAt(0).toUpperCase()}</b>
            </Tooltip>
          </UI54Tag>
        </div>
      );
  }

  return (
    <div
      className={`buildVersionTag-${customStyleVersion || 'wrapper'}`}
    >
      <UI54Tag
        color="gold"
        className="flex items-center px-1 m-0"
      >
        <Tooltip title={version.toUpperCase()}>{iconTag}</Tooltip>
      </UI54Tag>
    </div>
  );
}

export default function CheckBuildVersion({
  children,
  onlyLabel = false,
  showLabel = true,
  version = 'prod',
  inside,
  customStyleVersion,
}) {
  const env = process.env.NODE_ENV;
  const isAlpha = version === 'alpha';

  return (
    <div className={`buildVersionTag ${inside ? 'inside' : ''}`}>
      {onlyLabel && (
        <UI54Tag className="m-0" color={isAlpha ? 'gold' : 'blue'}>
          <b>{version.toUpperCase()}</b>
        </UI54Tag>
      )}
      {!onlyLabel && (env === 'local' || env === 'development' || (env === 'production' && version !== 'alpha')) && (
        <div className="flex">
          {children}
          {showLabel && (
            <VersionTag
              version={version}
              customStyleVersion={customStyleVersion}
            />
          )}
        </div>
      )}
    </div>
  );
}

CheckBuildVersion.propTypes = {
  children: PropTypes.element,
  inside: PropTypes.bool,
  onlyLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  version: PropTypes.string,
  customStyleVersion: PropTypes.string,
};

VersionTag.propTypes = {
  customStyleVersion: PropTypes.string,
  version: PropTypes.string,
};

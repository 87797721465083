import React, {
  useState, useRef,
  useEffect,
} from 'react';

import './userList.scss';

import {
  array,
  bool,
  func, number, object, string,
} from 'prop-types';
import {
  Flex,
  Row,
  Spin,
} from 'antd';
import {
  AutoSizer, List,
} from 'react-virtualized';
import UserItemForAssignList from './UserItemForAssignList';

export default function UserListForAssignList({
  size,
  isOneColumnMode,
  selectedList = [],
  total = null,
  items,
  allChanges = {},
  config = {},
  setConfig,
  disabledScroll,
  onClickCallback,
  onUserChangeCallback,
  showLoadingAfterLastElement = false,
  itemHeight = 40,
  disabledList = [],
  itemOptions = {},
}) {
  const listRef = useRef();

  const [expandedUsers, setExpandedUsers] = useState([]);

  const handleScroll = ({ scrollTop, clientHeight, scrollHeight }) => {
    if (disabledScroll || !setConfig) return;
    const { limit = 100, offset = 0 } = config;

    if (scrollTop + clientHeight >= scrollHeight - 300) {
      const newOffset = offset + limit;
      if (newOffset < total) {
        setConfig((prev) => ({
          ...prev,
          offset: newOffset,
        }));
      }
    }
  };

  const getRowHeight = ({ index }) => {
    if (expandedUsers.includes(index)) return 80;
    return itemHeight;
  };

  const onChangeSize = (index) => {
    if (expandedUsers.includes(index)) {
      setExpandedUsers((prev) => prev.filter((el) => el !== index));
    } else {
      setExpandedUsers((prev) => [...prev, index]);
    }

    setTimeout(() => {
      listRef.current?.recomputeRowHeights();
    }, 0);
  };

  const onItemClick = (value) => {
    onClickCallback(value)
  }

  const rowRenderer = ({ index, style, isScrolling }) => {
    if (index === items?.length && showLoadingAfterLastElement) {
      return <Flex
        key={value}
        style={{ ...style }}
        className='w-full mt-3'
        justify='center'
      >
        <Spin className='mr-2' />
        <span style={{ color: '#1677ff' }}>Loading...</span>
      </Flex>
    }

    if (items?.[index] === undefined && isScrolling) {
      return (
        <div key={index} style={style}>
          Scrolling...
        </div>
      );
    }

    const {
      value, label, role, perms, isWMSAdmin, className = '',
    } = items?.[index] ?? {};

    const isSelected = selectedList?.includes(value);
    const isDisabled = disabledList?.includes(value);

    return (
      <div key={value + perms} style={{ ...style }} className={className}>
        <UserItemForAssignList
          options={{
            isOneColumnMode,
            size,
            ...itemOptions,
          }}
          isSelected={isSelected}
          disabled={isDisabled}
          changes={allChanges?.[value]}
          label={label}
          uuid={value}
          item={items?.[index]}
          role={role}
          perms={perms}
          isWMSAdmin={isWMSAdmin}
          onChangeSize={() => onChangeSize(index)}
          onClickCallback={onItemClick}
          onUserChangeCallback={onUserChangeCallback}
          {...itemOptions}
        />
      </div>
    );
  };

  return (
    <Row
      className="h-full"
    >
      <AutoSizer>
        {({ width, height }) => (
          <List
            ref={listRef}
            height={height}
            rowHeight={getRowHeight}
            width={isOneColumnMode ? 230 : width}
            rowCount={showLoadingAfterLastElement ? items?.length + 1 : items?.length || 0}
            onScroll={handleScroll}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </Row>
  );
}

UserListForAssignList.propTypes = {
  size: string,
  textSelectButton: string,
  textDeleteButton: string,
  disabledList: array,
  selectedList: array,
  isOneColumnMode: bool,
  items: array,
  total: number,
  setConfig: func,
  config: object,
  disabledScroll: bool,
  onClickCallback: func,
  itemHeight: number,
  showSections: array,
  onUserChangeCallback: func,
  itemOptions: object,
  allChanges: object,
  showLoadingAfterLastElement: bool,
};

import { typeOfTestingOptions, cycleStatusTags, cycleArchiveTags } from "../../../../constants/allFilterOptions";

const typeOfTestingColumnFilter = {
    filters: typeOfTestingOptions,
    filterMode: 'tree',
    filterType: 'badge',
}

const cycleStatusColumnFilter = {
    filters: cycleStatusTags,
    filterMode: 'tree',
    filterType: 'badge',
}

const cycleArchiveColumnFilter = {
    filters: cycleArchiveTags,
    filterMode: 'tree',
    filterType: 'badge',
}

export const suiteColumnsFilterConfig = {
    filters: typeOfTestingColumnFilter,
    filterMode: 'tree',
    filterType: 'iconWithStatus',
}

export const defaultSuitesFilter = {
    typeOfTesting: [],
    assignToUser: [],
}

export const defaultCyclesFilter = {
    archive: [false]
}

export const defaultChecklistsFilter = {
    priority: undefined,
    status: [],
}

export const defaultCasesFilter = {
    typeOfTesting: [],
    status: [],
    assignToUser: [],
}

export const columnsFilterConfig = {
    typeOfTesting: typeOfTestingColumnFilter,
}

export const cyclesFilterConfig = {
    status: cycleStatusColumnFilter,
    archive: cycleArchiveColumnFilter
}

export const cycleArchiveFilterConfig = {
    archive: cycleArchiveColumnFilter
}
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import Icon from '@mdi/react';
import { mdiBullseyeArrow } from '@mdi/js';
import moment from 'moment';

export const scoreByTime = {
  max: 5,
  expired: 100,
  getScore(startDate, endDate) {
    if (!startDate || !endDate) return 0;

    const fullInterval = moment(endDate, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days');
    const remainingInterval = moment(endDate, 'YYYY-MM-DD').diff(moment(moment(), 'YYYY-MM-DD'), 'days');

    if (fullInterval < 0) return 0;

    // eslint-disable-next-line max-len
    const timeScore = Math.round((this.max - (remainingInterval / fullInterval) * this.max) * 10) / 10;
    // console.log('timeScore:', timeScore);

    switch (true) {
      case ((timeScore <= this.max) && (timeScore >= 0)): return timeScore;
      default: return this.expired;
    }
  },
};

const scoreByStatus = {
  'in progress': 1,
  default: 0,
  getScore(status) {
    return this[status] || this.default;
  },
};

const scoreByImportance = {
  minor: 1,
  medium: 2,
  significant: 3,
  critical: 5,
  default: 0,
  getScore(importance) {
    return this[importance] || this.default;
  },
};

export const computeMilestoneScore = ({
  status,
  importance,
  startDate,
  endDate,
}) => {
  const multiplier = {
    status: 0,
    importance: 0,
    time: 0,
  };

  multiplier.status = scoreByStatus.getScore(status);
  multiplier.importance = scoreByImportance.getScore(importance);
  multiplier.time = scoreByTime.getScore(startDate, endDate);

  // console.log('score status:', multiplier.status);
  // console.log('score importance:', multiplier.importance);
  // console.log('score time:', multiplier.time);

  const isExpired = (status === 'in progress') && (multiplier.time === scoreByTime.expired);

  const score = isExpired
    ? scoreByTime.expired
    : Number(((multiplier.importance + multiplier.time) * multiplier.status).toFixed(1));

  return score;
};

//----------------------------------------------------------------
export const sortScore = (a, b, sortOrder) => {
  // console.log('sort b', b);
  // console.log('sort a', a);
  const aScore = computeMilestoneScore({
    status: a?.status,
    importance: a?.importance,
    startDate: a?.startDate,
    endDate: a?.lifeTime,
  });

  const bScore = computeMilestoneScore({
    status: b?.status,
    importance: b?.importance,
    startDate: b?.startDate,
    endDate: b?.lifeTime,
  });

  return aScore - bScore;
};

export const dataWithMilestoneScore = (data) => data.map((item) => {
  const {
    status, importance, startDate, lifeTime,
  } = item || {};

  const score = computeMilestoneScore({
    status,
    importance,
    startDate,
    endDate: lifeTime,
  });

  return {
    ...item,
    mScore: score,
  };
});

export const getMilestoneScoreClassName = (score) => {
  switch (true) {
    case (score === 0): {
      return 'score-gray';
    }
    case (score > 0 && score <= 3): {
      return 'score-green';
    }
    case (score > 3 && score <= 5): {
      return 'score-yellow';
    }
    case (score > 5 && score <= 7): {
      return 'score-orange';
    }
    case (score > 7 && score < 10): {
      return 'score-red';
    }
    case (score >= 10): {
      return 'score-darkred';
    }

    default: return 'score-gray';
  }
};

//----------------------------------------------------------------
export const renderMilestoneScore = (score, withIcon = false) => {
  // console.log('!!! score:', score);
  const scoreClassName = getMilestoneScoreClassName(score);

  return (
    <div className={`score-box ${withIcon ? 'score-box--big' : ''} ${scoreClassName}`}>
      {withIcon && (<Icon className="mr-2" path={mdiBullseyeArrow} size={0.92} color="#fefefe" />)}
      <span className="">{score}</span>
    </div>
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

function TestCaseOrderFormatter({
  index,
  length,
  updateOrderCallback,
}) {
  return (
    <div className="flex justify-start">
      <BaseButton
        disabled={+index + 1 === length}
        onClick={(e) => updateOrderCallback('down', index, e)}
        style={{
          width: 30,
          height: 30,
          padding: 0,
          backgroundColor: '#d3eefc',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon path={mdiChevronDown} color="cornflowerblue" size={0.8} />
      </BaseButton>

      <BaseButton
        disabled={+index === 0}
        onClick={(e) => updateOrderCallback('up', index, e)}
        style={{
          width: 30,
          height: 30,
          padding: 0,
          backgroundColor: 'rgba(255, 0, 0, 0.104)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >

        <Icon path={mdiChevronUp} color="red" size={0.8} />
      </BaseButton>
    </div>
  );
}

export default TestCaseOrderFormatter;

TestCaseOrderFormatter.propTypes = {
  updateOrderCallback: PropTypes.func,
  index: PropTypes.number,
  length: PropTypes.number,
};

import React, {
  useEffect, useState,
} from 'react';
import { Col } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';

import FilterByPeriod from '../FilterByPeriod.jsx';
import SwitcherOfDate from '../../../userFlow/components/WeekPlanning/SwitcherOfDate';
import SpreadSheetProjectReport from './SpreadSheetProjectReport.jsx';

import useURLParams from '../../../hooks/useURLParams';

export default function ViewReportByProject() {
  const { getURLParams, addAndRemoveSearchParams, setSearchParams } = useURLParams();

  const [typePeriod, setTypePeriod] = useState('');
  const [rangePickerValue, setRangePickerValue] = useState([]);
  const [defaultDate, setDefaultDate] = useState('');

  const [currentPeriodDate, setCurrentPeriodDate] = useState({
    startDate: '',
    endDate: '',
  });

  const {
    startDate, endDate, period,
  } = getURLParams();

  const onSelectTags = (tag) => {
    const name = tag?.target?.value === 'isoWeek' ? 'week' : tag?.target?.value;

    addAndRemoveSearchParams({
      period: name,
      startDate: moment().startOf(tag?.target?.value).format('YYYY-MM-DD'),
    }, ['endDate']);

    setTypePeriod(name === 'week' ? 'isoWeek' : name);
    if (!isEmpty(rangePickerValue)) {
      setRangePickerValue([]);
    }
  };

  const setPeriod = async () => {
    const currentPeriodValue = period === 'week' ? 'isoWeek' : period;
    setTypePeriod(currentPeriodValue);

    if (currentPeriodValue === 'rangePicker') {
      const arrDate = [moment(startDate), moment(endDate)];
      setRangePickerValue(arrDate);
    }

    setDefaultDate(startDate);
  };

  const getPeriod = (startDay) => {
    setSearchParams({
      period: typePeriod === 'isoWeek' ? 'week' : typePeriod || period,
      startDate: startDay || startDate,
    });
  };

  useEffect(() => {
    const currentPeriodValue = period === 'week' ? 'isoWeek' : period;

    setCurrentPeriodDate({
      startDate,
      endDate: endDate || moment(startDate).endOf(currentPeriodValue).format('YYYY-MM-DD'),
    });
  }, [startDate, period, endDate]);

  useEffect(() => {
    if (startDate) {
      setPeriod();
    }
  }, [startDate, endDate, typePeriod]);

  useEffect(() => {
    if (!startDate) {
      setSearchParams({
        startDate: moment().startOf('months').format('YYYY-MM-DD'),
        period: 'months',
      });
    }
  }, []);
  return (
    <div className="relative h-full w-full flex flex-col overflowHidden">
      <div className="bg-white p-3">
        <FilterByPeriod typePeriod={typePeriod} onSelectTags={onSelectTags} />

        <Col span={24} className="pt-3">
          <SwitcherOfDate
            classNameWrapper="flex justify-center"
            disabled={!isEmpty(rangePickerValue)}
            defaultDate={defaultDate}
            typePeriod={typePeriod}
            rangePickerValue={rangePickerValue}
            changePeriodCallback={getPeriod}
          />
        </Col>
      </div>

      <SpreadSheetProjectReport
        currentPeriodDate={currentPeriodDate}
      />
    </div>
  );
}

ViewReportByProject.propTypes = {

};

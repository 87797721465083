import {
  importanceFilterOptions,
  milestoneStatusFilterOptions,
} from "../../../../constants/allFilterOptions";

const importanceColumnFilter = {
  filters: importanceFilterOptions,
  filterMode: 'tree',
  filterType: 'charNoCut',
};

const statusColumnFilter = {
  filters: milestoneStatusFilterOptions,
  filterMode: 'tree',
  filterType: 'iconWithStatus',
};

export const defaultMilestoneFilter = {
  importance: undefined,
  status: ['created', 'in progress', 'draft'],
};
// console.log('defaultMilestoneFilter:', defaultMilestoneFilter);

export const columnsMilestoneFilterConfig = {
  importance: importanceColumnFilter,
  status: statusColumnFilter,
};

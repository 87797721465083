import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Col, Modal, Row,
} from 'antd';
import ListOfTestCyclesQa from './cycles/ListOfTestCyclesQa';
import { capitalizeFirstChar } from '../../../MainUtils';
import useURLParams from '../../../hooks/useURLParams';
import ListOfChecklist from './checklist/ListOfChecklist';
import Checklist from './checklist/Checklist';
import { BugTrackerContext } from '../../context/ProjectFlowListOfContexts';
import TestCycleFormForRun from './cycles/TestCycleFormForRun';
import MainChecklist from './checklist/MainChecklist';
import MainCollapseSection from '../../containers/qaView/MainCollapseSection';
import { capitalize } from 'lodash';

export default function RunQa({
  config,
  totalCountCycle,
  setNewConfigCallback,
  deleteCycle,
  createEntitiesFromJSON,
  changeSelectedTypeOfTesting,
  partition,
  totalCountCheckList,
  cycleComponent,
  archiveComponent
}) {
  const { t } = useTranslation();

  const [checklistExpand, setChecklistExpand] = useState('table');

  const {
    changeIsCreatorCycleByType,
    isCreatorCycleByType,
    changeQAHistory,
  } = useContext(BugTrackerContext);

  const { getURLParams, clearSearchParams } = useURLParams();
  const { activeChecklist } = getURLParams() || {};

  const currentView = activeChecklist ? 'checklist' : null;

  const backChecklist = () => {
    clearSearchParams(['activeChecklist']);
    setChecklistExpand('table');
  };

  const onModalClose = () => {
    changeIsCreatorCycleByType(null);
  };

  useEffect(() => {
    changeSelectedTypeOfTesting([]);
  }, []);

  useEffect(() => {
    if (activeChecklist && currentView === 'checklist') {
      setChecklistExpand('normal');
    } else {
      setChecklistExpand('table');
    }
  }, [activeChecklist]);

  return (
    <MainCollapseSection
      id="mainChecklistCollapse"
      classNameBtn="btnBlue-outline"
      typeCard="primaryCard"
      splitSection={activeChecklist}
      changeQAHistory={changeQAHistory}
      titleCollapseBtn={capitalize(t('wms.projectflow.qa.checklists', 'checklists'))}
      firstComponent={
        <ListOfChecklist
          title={capitalizeFirstChar(t('wms.noun.checklist.1'))}
          checklistExpand={checklistExpand}
          setNewConfigCallback={setNewConfigCallback}
          totalCountCheckList={totalCountCheckList}
        />
      }
      secondComponent={
        <MainChecklist
          partition={partition}
          toggleReset={backChecklist}
        />
      }    
      thirdComponent={
        <Checklist
          setChecklistExpand={setChecklistExpand}
          checklistExpand={checklistExpand}
          partition={partition}
        />
      }
    />
  );
}
RunQa.propTypes = {
  partition: PropTypes.string,
  config: PropTypes.object,
  totalCountCycle: PropTypes.number,
  totalCountCheckList: PropTypes.number,
  setNewConfigCallback: PropTypes.func,
  deleteCycle: PropTypes.func,
  createEntitiesFromJSON: PropTypes.func,
  changeSelectedTypeOfTesting: PropTypes.func,
  cycleComponent: PropTypes.element,
  archiveComponent: PropTypes.element,
};

import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { concat, get, isEmpty, isPlainObject } from 'lodash';

import {
  head, path, prop, propOr,
} from 'ramda';
import moment from 'moment';

import {
  Row, Modal,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CustomCardComponent from '../commonComponents/CustomCardComponent';
import ExpandModeLayout from '../commonComponents/expandModeLayout/ExpandModeLayout';
import ListOfDocuments from './ListOfDocuments';
import DocView from './docView/DocView';
// import DocMergeComponent from './docsPatchingMerge/DocMergeComponent';
import DocHistoryComponent from './docsHistory/DocHistoryComponent';

import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
import {
  getProjectUUID,
  globalUpdateEntity,
  getProjDocOrIdeas,
  areFetchingProjDocs,
  getDocumentContent,
  selectLastCreatedDocument,
} from '../../selectors/selectors';
import { antNotification } from '../../../MainUtils';
import { capitalize } from 'lodash';
import {
  clearDocCopy,
  clearDocument,
  clearHistoryOfDocument,
} from '../../actions/PtcActionsForHook';
import { viewNotification } from '../../../entity/selectors/selectors';
import {
  getListDocumentsOrIdea,
  updateDocument,
  // checkActualStateRequest,
  // setDocumentContent,
} from '../../actions/DocumentsActions';
import { getCurrentArchiveStateUUID } from '../../selectors/archiveStateSelectors';
import { sendSystemComment, updateListComment, updateProjectRequest } from '../../actions/projectFlowActions';
import { clearGlobalUpdateEntity } from '../../reducers/slicers/breadcrumbsSlicer';
import { entityCreate, incrementAndGetCount } from '../../../entity/actions/entityActions';

import {
  setFlagNotSaveData,
  setFlagOpenConfirm,
} from '../../../components/un-save-data-confirm/unSaveDataSlicer';

import useTableFilter from '../../../components/_ui/hooks/useTableFilter';
import { columnsFilterConfig, defaultDocumentFilter, qaDocumentFilter } from './docView/constants/documentFilterOptions';


import useURLParams from '../../../hooks/useURLParams';
import useMouseMiddleRedirect from '../../../hooks/useMouseMiddleRedirect';

import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { setDeletedDocHtml, setQuillDocReady, getQuillDocReady } from '../../reducers/slicers/quillSlicer';

const defaultDocumentOrder = {
  orderBy: 'created',
  order: 'desc',
};

const defaultDocumentPagination = {
  currentPage: 1,
  limit: 25,
  offset: 0,
};

// const initialState = {
//   notSaved: false,
//   confirmNotSaved: false,
// };


const initialDocumentState = {
  title: null,
  lifeTime: null,
  change: false,
};

// const initialCurrentDocumentData = {
//   title: null,
//   status: null,
//   lifeTime: null,
//   content: null,
// };

function DocsStartPage({
  defaultPartition,
  hideElements = [],
  isQa = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  hideElements.push('goToBtn');

  const {
    // getDocument,
    getDocumentWithOptionalData,
  } = useContext(ProjectMainLayerContext);

  const {
    clearSearchParams,
    getURLParams,
    addAndRemoveSearchParams,
  } = useURLParams();
  const { onMouseMiddleClick } = useMouseMiddleRedirect();

  const projectUUID = useSelector(getProjectUUID);
  const lastCreatedDocument = useSelector(selectLastCreatedDocument);
  const docListFetching = useSelector(areFetchingProjDocs);
  const archiveStateUUID = useSelector(getCurrentArchiveStateUUID);
  const documentContent = useSelector(getDocumentContent);
  const seeNotification = useSelector(viewNotification);
  const globalUpdate = useSelector(globalUpdateEntity);
  const projDocOrIdeas = useSelector(getProjDocOrIdeas);
  const isQuillDocReady = useSelector(getQuillDocReady);

  // Document
  const docUUID = get(projDocOrIdeas, '[0].uuid', '');
  const docParams = propOr('', 'params', head(projDocOrIdeas));
  const defaultDescription = prop('text', docParams) || '';
  const hasOptionalData = propOr(false, 'optional_data', head(projDocOrIdeas));
  const actualState = propOr('', 'actualState', docParams);
  const defaultDocTitle = prop('title', docParams);
  const defaultDocType = prop('type', docParams);
  const isProjectDescriptionDocument = defaultDocType === 'project description' || defaultDocType === 'project infrastructure';
  

  const parentUUID = archiveStateUUID || projectUUID;

  const {
    activeDoc,
    activeNote,
    activeSheet,
    activeQaDoc,
    activeProject,
  } = getURLParams();


  const {
    filterConfig: documentFilterConfig,
    changeFilterConfig: changeDocumentFilterConfig,
  } = useTableFilter('document', isQa ? qaDocumentFilter : defaultDocumentFilter);

  const defaultDocumentsConfig = {
    ...defaultDocumentPagination,
    ...defaultDocumentOrder,
    ...documentFilterConfig,
  };

  const [Spreadsheet, getSpreadsheet] = useState(null);
  const [documentState, setDocumentState] = useState(initialDocumentState);
  const [totalCountDocs, setTotalCountDocs] = useState(0);
  const [showEditor, setShowEditor] = useState(false);
  const [documentsConfig, setDocumentsConfig] = useState(defaultDocumentsConfig);
  const [isShowHistoryModal, setIsShowHistoryModal] = useState(false);
  const [docFetching, setDocFetching] = useState(false);
  const [resetAndCloseInfoCard, setResetAndCloseInfoCard] = useState(false);
  const [isEditedDescription, setIsEditedDescription] = useState(false);
  const [withComments, setWithComments] = useState(false);
  const [isOpenComments, setIsOpenComments] = useState(false);

  // const [totalSystemDocs, setTotalSystemDocs] = useState(0);

  const hasNotSavedData = prop('change', documentState) || isEditedDescription;

  const entityDocType = get(projDocOrIdeas, '[0].entity_type', '');
  // console.log('TYPE:', entityDocType);

  const changeDocumentState = (newState) => {
    setDocumentState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const getDescription = (entityType) => {
    let descriptionData;
    switch (entityType) {
      case 'projectDocument':
      case 'projectIdea': {
        descriptionData = '';
        break;
      }
      case 'projectSpreadsheet': {
        const data = Spreadsheet?.getJson();
        descriptionData = Array.isArray(data) ? data : [];
        // descriptionData.columns = Spreadsheet?.getConfig().columns;
        // descriptionData = Spreadsheet?.getData();
        break;
      }
      default: descriptionData = '';
    }

    return descriptionData;
  };

  const closeUnsaveDataConfirm = () => {
    dispatch(setFlagOpenConfirm(false));
    dispatch(setFlagNotSaveData(false));
    // setNotSavedData(initialState);
  };

  const loadSystemDocuments = async (config) => {
    const requestConfig = {
      ...config,
      offset: 0,
      params: {
        ...config?.params,
        status: ['system'],
      }
    };
    console.log('system docs:', requestConfig);

    return dispatch(getListDocumentsOrIdea(
      requestConfig,
      defaultPartition,
    ));
  };

  const loadOmitSystemDocuments = async (config, constants) => {
    const requestConfig = {
      ...config,
      params: {
        ...get(config, 'params', {}),
        status__not: concat(get(config, 'params.status__not', []), ['system']),
      },
    };
    // console.log('other docs:', requestConfig);

    return dispatch(getListDocumentsOrIdea(
      requestConfig,
      defaultPartition,
      constants,
    ));
  };

  const loadAllDocuments = async (properties) => {
    const {
      entity_type,
      status,
      search,
      orderBy,
      order,
      offset,
      limit,
    } = properties || {};

    const config = {
      parent: parentUUID,
      entity_type,
      params: {},
      orderBy,
      order,
      offset,
      limit,
    };


    if (status && status?.length) {
      config.params.status = status;
    }

    if (search) {
      config.search_data = {
        value: search,
        ignore_case: true,
        fields: {
          params: ['title'],
        },
      };
    }

    if (!parentUUID) return;
    // console.log('docs config:', config);

    let data = {
      data: [],
      total: 0,
    };

    const checkIncludes = typeof entity_type === 'string' || Array.isArray(entity_type) ? entity_type?.includes('projectDocument') : false;
    const needLoadSystemDocuments = !entity_type || checkIncludes;

    if (needLoadSystemDocuments) {
      const systemDocuments = await loadSystemDocuments(config);
      const otherDocuments = await loadOmitSystemDocuments(
        config,
        [
          ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_REQUEST,
          ProjectTypeCommonConstants.FETCH_AND_PUSH_DOCUMENTS_AND_IDEA_SUCCESS,
          ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_FAILURE,
        ],
      );

      data = {
        data: concat(systemDocuments.data, otherDocuments.data),
        total: otherDocuments.total,
      }
      setTotalCountDocs(prop('total', data));
    } else data = await loadOmitSystemDocuments(config);

    // console.log(data)
    setTotalCountDocs(prop('total', data));
    return prop('data', data);
  };

  const getDocumentData = async (uuid, type) => {
    await getDocumentWithOptionalData(uuid, type);
  };

  const clickOnNonExistingDoc = async() => {
    const message = 'This entity does not exist, it was deleted by another user. Please click the Restart button or close the window to update the data in the table.';
    Modal.warning({
      title: capitalize(t('wms.noun.warning', 'warning')),
      content: message,
      icon: <ExclamationCircleOutlined />,      
      okText: 'Reload',
      maskClosable: true,
      onOk: () => {        
        resetSelectedDocument(true);       
      },   
      onCancel: () => {
        resetSelectedDocument(true);       
      },   
    });
  }

  const getClearDocument = async (uuid, type) => {
    const checkIsDoc = type === 'projectDocument' || type === 'projectIdea';
    if (checkIsDoc) {
      dispatch(setQuillDocReady(false));
    }
    setIsOpenComments(false);
    setShowEditor(false);
    setDocFetching(true);    
    await getDocumentData(uuid, type).then(() => {
      setShowEditor(true);
      setDocFetching(false);
      if (withComments) setIsOpenComments(true);
      }).catch((e) => {       
        const checkError = e?.status === 404 && e?.message === 'There is no entity with such uuid';
        if (checkError) {
          clickOnNonExistingDoc();
        }   
      }
    );    
  };

  const changeDocsHistory = (key, uuid) => {
    // const path = `${initialPathForHistory}${pageUrl}`;

    // console.log('changeDocsHistory', key, uuid);

    switch (key) {
      case 'projectDocument':
        addAndRemoveSearchParams({ activeDoc: uuid }, ['activeNote', 'activeSheet', 'activeQaDoc']);
        break;

      case 'projectIdea':
        addAndRemoveSearchParams({ activeNote: uuid }, ['activeDoc', 'activeSheet', 'activeQaDoc']);
        break;

      case 'projectSpreadsheet':
        addAndRemoveSearchParams({ activeSheet: uuid }, ['activeDoc', 'activeNote', 'activeQaDoc']);
        break;
      case 'qaDocument':
        addAndRemoveSearchParams({ activeQaDoc: uuid }, ['activeDoc', 'activeNote', 'activeSheet']);
        break;

      case 'restore':
        // path.search = `?activeProject=${projectUUID}`;
        // routeNavigateTo(path);
        clearSearchParams(['activeDoc', 'activeSheet', 'activeNote', 'activeQaDoc']);
        break;

      default:
        break;
    }
  };

  const selectedDocument = (uuid, type) => changeDocsHistory(type, uuid);

  const resetSelectedDocument = (resetInfoCard = true) => {
    if (resetInfoCard) {
      // setNotSavedData(initialState);
      changeDocumentState(initialDocumentState);
      setIsEditedDescription(false);
      setResetAndCloseInfoCard(true);
    }

    loadAllDocuments(documentsConfig);
  };

  const deleteDocumentCallback = (uuid, doc) => {
    const { type } = prop('params', doc) || {};
    const selectedDocUuid = activeDoc || activeNote || activeSheet || activeQaDoc;

    resetSelectedDocument(selectedDocUuid === uuid);

    if (type === 'project description' || type === 'project infrastructure') {
      const paramsKey = (type === 'project description' && 'descriptionDoc') || (type === 'project infrastructure' && 'infrastructureDoc');
      const projectConfig = {
        uuid: activeProject,
        params: {
          [paramsKey]: {
            uuid: '',
            title: '',
          },
        },
        partition: defaultPartition,
      };
      return dispatch(updateProjectRequest(projectConfig))
        .then(() => {
          antNotification('success', t('wms.noun.success', 'Success'));
        });
    }
  };

  const createNewDoc = async (type, withContent, config = {}) => {
    let defaultTitle;
    let fieldName;
    switch (type) {
      case 'projectIdea': {
        defaultTitle = 'New Note';
        fieldName = 'note';
        break;
      }
      case 'projectSpreadsheet': {
        defaultTitle = 'New Spreadsheet';
        fieldName = 'spreadsheet';
        break;
      }
      case 'projectDocument':
      default: {
        defaultTitle = 'New Document';
        fieldName = 'document';
        break;
      }
    }
    // console.log('crdt config', config)
    const {
      // description: configDescription,
      title: configTitle,
      lifeTime: configLifeTime,
      status: configStatus,
      content: configContent,
      text: configText,
      // columnsWidths: configColumnsWidths,
      stateID: configActualState,
      projectUUUID: configProjectUUID,
    } = config || {};

    const { increment: docID } = await dispatch(incrementAndGetCount({
      entity_uuid: configProjectUUID || projectUUID,
      field_name: fieldName,
    }, defaultPartition)) || {};

    const newTitle = configTitle || defaultTitle;

    const data = {
      entity_type: type,
      parent: configProjectUUID || projectUUID,
      params: {
        id: docID,
        status: configStatus || 'created',
        title: newTitle,
        text: '',
        lifeTime: configLifeTime || type === 'projectIdea'
          ? moment().add('2', 'week').format('YYYY-MM-DD')
          : moment().add('1', 'year').format('YYYY-MM-DD'),
        updated: moment().locale('en').format('LLL'),
      },
    };

    if (type === 'projectSpreadsheet') {
      // data.params.columnsWidths = configColumnsWidths || Array.from({ length: 26 }, () => 100);
      data.params.text = configText || configContent || '';
      data.params.actualState = '0';
    } else {
      data.params.actualState = withContent ? configActualState : '0';
    }

    // console.log('crdt DATA:', data);

    const constants = [
      ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_REQUEST,
      ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_SUCCESS,
      ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_FAILURE,
    ];

    const options = {
      partition: defaultPartition,
    };

    return dispatch(entityCreate({
      data,
      constants,
      options,
    }));
  };

  const createNewDocumentWithContent = async ({
    type,
    data = {},
    open = false,
    docHtmlString = ''
  }) => {

    // console.log('DATA', data);
    try {
      const config = {
        ...data,
        stateID: '0'
      };

      const withContent = isPlainObject(data) && !isEmpty(data);

      const [documentData] = await createNewDoc(type, withContent, config);
      const newDocumentUUID = documentData?.uuid;

      // if (withContent && type !== 'projectSpreadsheet') {
      //   await createNewDocumentState(newDocumentUUID, config);
      // }

      antNotification('success', t('wms.noun.success', 'Success')); 

      if (open) {
        selectedDocument(newDocumentUUID, type);
        dispatch(setDeletedDocHtml(docHtmlString));
        // console.log('crdt createNewDocumentWithContent docHtmlString', docHtmlString)   
        await loadAllDocuments(documentsConfig); 
      };
      return newDocumentUUID;
    } catch (error) {
      console.error(`Failed to create new document: ${error.message}`);
      return null;
    }
  };


  const confirmFunction = async (type, config, docHtmlString) => {

    await createNewDocumentWithContent({
      type,
      data: config,
      open: true,
      docHtmlString
    });
  }


  const documentDoNotExistModal = (type, config, docHtmlString) => {
    let message;
    let okText;
    switch (type) {
      case 'projectIdea': {
        message = capitalize(t('wms.labels.the_idea_that_you_modify'));
        okText = capitalize(t('wms.labels.create_new_idea'));
        break;
      }
      case 'projectSpreadsheet': {
        message = capitalize(t('wms.labels.the_sheet_that_you_modify'));
        okText = capitalize(t('wms.labels.create_new_sheet'));
        break;
      }
      case 'projectDocument':
      default: {
        message = capitalize(t('wms.labels.the_doc_that_you_modify'));
        okText = capitalize(t('wms.labels.create_new_doc', { noun: t('wms.noun.document') }));
        break;
      }
    }

    Modal.confirm({
      title: capitalize(t('wms.noun.error', 'error')),
      content: message,
      icon: <ExclamationCircleOutlined />,
      okText,
      cancelText: capitalize(t('wms.verb.cancel', 'cancel')),

      onOk: () => {
        confirmFunction(type, config, docHtmlString)
      },
      onCancel: () => {
        // closeUnsaveDataConfirm();
        resetSelectedDocument(true);
      },
    });
  };

  const sendCustomComment = async (params, action) => {
    const comment = {
      entity: params.entity,
      action,
      param: params.title,
      value: params.value,
    };

    await dispatch(sendSystemComment(params.uuid, comment, defaultPartition));
    dispatch(updateListComment(params.uuid));
  }; 

  const updateProjectScoringData = async ({ params, currentUUID }) => {
    const projectDocumentKey = (params?.params?.type === 'project description' && 'descriptionDoc')
      || (params?.params?.type === 'project infrastructure' && 'infrastructureDoc') || null;
    if (projectDocumentKey) {
      const projectConfig = {
        uuid: projectUUID,
        params: {
          [projectDocumentKey]: {
            uuid: currentUUID,
            title: path(['params', 'title'], params),
            symbols_count: params?.params?.symbols_count || 0,
          },
        },
        partition: defaultPartition,
        constants: [
          ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_REQUEST,
          ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_SUCCESS,
          ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_FAILURE,
        ],
      };
      // console.log('projectConfig', projectConfig);
      dispatch(updateProjectRequest(projectConfig));
    }
  }

  const successfullyDocUpdate = async ({ defaultLifeTime, params, currentEntityType, currentUUID }) => {
    antNotification('success', t('notifications.text.success.saved', 'Saved'));

    if (defaultLifeTime && defaultLifeTime !== params.params.lifeTime) {
      sendCustomComment({
        entity: currentEntityType,
        uuid: currentUUID,
        title: `lifetime from ${defaultLifeTime}`,
        value: params.params.lifeTime,
      }, 'changed');
    }
    loadAllDocuments(documentsConfig); 
    setIsEditedDescription(false);
    setIsShowHistoryModal(false);
    setDocumentState(initialDocumentState);
    await getDocumentData(currentUUID, currentEntityType);
  }

  const updateProjectDocument = async (params, currentEntityType, defaultLifeTime) => {

    const { entity_uuid: currentUUID } = params;

    const config = {
      params: {
        ...params,
        params: {
          ...params.params,
        },
      },
      partition: defaultPartition,
    };

    // console.log('config', config);
    // console.log('updateProjectDocument params', params);

    setDocFetching(true);
    await dispatch(updateDocument(config)).then(async () => {
      // console.log('params?.params', params?.params);
      updateProjectScoringData({ params, currentUUID });
      await successfullyDocUpdate({ defaultLifeTime, params, currentEntityType, currentUUID })
    }).catch((e) => {
      const checkError = e?.status === 404 && e?.message === 'There is no entity with such uuid';
      if (checkError) {
        documentDoNotExistModal(currentEntityType, config?.params?.params);
      }   
      setIsEditedDescription(false);
      setDocumentState(initialDocumentState);     
    })
    setDocFetching(false);
  };

  const setCurrentConfigAndGetRequest = (newConfig) => {
    // console.log('docsConfig newConfig', newConfig);
    setDocumentsConfig(newConfig);
    loadAllDocuments(newConfig);
  };

  // const visibleElement = (elementName) => !hideElements.includes(elementName);

  const setNotSavedDataCallback = () => {
    // setNotSavedData(data);
    setIsEditedDescription(false);
  };

  const changeDescriptionCallback = () => {
    if (!isEditedDescription) {
      setIsEditedDescription(true);
      // setIsNotSavedDataCallback?.(true);
    }
  };

  const reloadDocumentWithoutSaving = async () => {
    setIsEditedDescription(false);
    await getClearDocument(docUUID, entityDocType);
  };


  const handleCreateDocWhenDelete = async ({ entityType, docHtmlString }) => {
    const oldDocumentData = {
      title: documentState.title ?? prop('title', docParams),
      status: documentState.status ?? prop('status', docParams),
      lifeTime: documentState.lifeTime ?? prop('lifeTime', docParams),
      projectUUID: parentUUID,
    };
    documentDoNotExistModal(entityType, oldDocumentData, docHtmlString);
    dispatch(clearDocument);
  }

  const onMouseMiddleClickHandler = (e, record) => {
    const { entity_type: entityType, uuid: documentUUID } = record;
    const searchParams = { activeProject: projectUUID };

    switch (entityType) {
      case 'projectIdea': {
        searchParams.activeNote = documentUUID;
        break;
      }
      case 'projectDocument': {
        searchParams.activeDoc = documentUUID;
        break;
      }
      case 'projectSpreadsheet': {
        searchParams.activeSheet = documentUUID;
        break;
      }
      default: break;
    }

    onMouseMiddleClick(e, searchParams);
  };

  useEffect(() => () => {
    dispatch(clearDocument);
    getSpreadsheet(null);
    dispatch(clearDocCopy);
    dispatch(clearHistoryOfDocument);
  }, [parentUUID]);

  useEffect(() => {
    if (!activeDoc && !activeNote && !activeSheet && !activeQaDoc && !isEmpty(lastCreatedDocument)) {
      const lastDocUUID = lastCreatedDocument.uuid;
      const lastDocType = lastCreatedDocument.entity_type;
      selectedDocument(lastDocUUID, lastDocType);
    }
  }, [lastCreatedDocument]);

  useEffect(() => {
    if (seeNotification) {
      loadAllDocuments(documentsConfig);
    }
  }, [seeNotification]);

  useEffect(() => {
    if (activeDoc || activeNote || activeSheet || activeQaDoc) {      
      changeDocumentState(initialDocumentState);
      setIsEditedDescription(false);
      // dispatch(clearDocument);
      // dispatch(clearDocCopy);
      if (activeDoc) getClearDocument(activeDoc, 'projectDocument');
      else if (activeNote) getClearDocument(activeNote, 'projectIdea');
      else if (activeSheet) getClearDocument(activeSheet, 'projectSpreadsheet');
      else if (activeQaDoc) getClearDocument(activeQaDoc, 'qaDocument');

      // setServerContent(null);
      // setIsMerged(false);
    }
  }, [activeDoc, activeNote, activeSheet, activeQaDoc]);

  useEffect(() => {
    if (globalUpdate.entityType === 'projectDocument') {
      resetSelectedDocument();
      dispatch(clearGlobalUpdateEntity());
    }
  }, [globalUpdate.entityType]);

  useEffect(() => {
    if (resetAndCloseInfoCard) {
      dispatch(clearDocument);
      dispatch(clearDocCopy);
      getSpreadsheet(null);
      changeDocsHistory('restore');
      setResetAndCloseInfoCard(false);
    }
  }, [resetAndCloseInfoCard]);

  return (
    <>
      <Row className="h-full">
        <ExpandModeLayout localStorageTarget="documents">
          <ExpandModeLayout.Table>
            <ListOfDocuments
              className="mb-3"
              partitionType={defaultPartition}
              fetching={docListFetching}
              totalCount={totalCountDocs}
              defaultOrderConfig={defaultDocumentOrder}
              defaultPaginationConfig={defaultDocumentPagination}
              filterColumnsConfig={columnsFilterConfig}
              filterConfig={documentFilterConfig}
              setWithComments={setWithComments}
              onMouseMiddleClick={onMouseMiddleClickHandler}
              resetSelectedDocument={resetSelectedDocument}
              setCurrentConfigAndGetRequest={setCurrentConfigAndGetRequest}
              selectDocumentCallback={selectedDocument}
              deleteDocumentCallback={deleteDocumentCallback}
              newDocumentCallback={createNewDocumentWithContent}
              updateDocumentsTableCallback={() => loadAllDocuments(documentsConfig)}
              filterCallback={changeDocumentFilterConfig}
              disabledOnRow={!isQuillDocReady}
              isQa={isQa}
            />
          </ExpandModeLayout.Table>
          <ExpandModeLayout.Card>
            {(activeDoc || activeNote || activeSheet || activeQaDoc) && (
              <CustomCardComponent
                type="document"
                params={{
                  isDisabled: !prop('change', documentState) && !isEditedDescription,
                  isDocumentOrNote: activeDoc || activeNote || activeQaDoc,
                }}
              >
                <DocView
                  docFetching={docFetching || !docParams}
                  defaultPartition={defaultPartition}
                  spreadsheetEditor={Spreadsheet}
                  isSidePanelOpen={isOpenComments}
                  showEditor={showEditor}
                  description={hasOptionalData ? documentContent : defaultDescription}
                  docStatus={prop('status', documentState)}
                  entityDocType={entityDocType}
                  hideElements={Array.isArray(hideElements) ? hideElements : []}
                  lifeTime={prop('lifeTime', documentState)}
                  title={prop('title', documentState)}
                  isDisabled={!hasNotSavedData}
                  changeDescriptionCallback={changeDescriptionCallback}
                  getSpreadsheet={getSpreadsheet}
                  getDescription={getDescription}
                  handleCreateDocWhenDelete={handleCreateDocWhenDelete}
                  updateProjectDocument={updateProjectDocument}
                  restoreEntityCallback={resetSelectedDocument}
                  setIsShowHistoryModal={setIsShowHistoryModal}
                  setIsSidePanelOpen={setIsOpenComments}
                  changeDocumentState={changeDocumentState}
                  setNotSavedDataCallback={setNotSavedDataCallback}
                  closeUnsaveDataConfirm={closeUnsaveDataConfirm}
                  setIsEditedDescription={setIsEditedDescription}
                  deleteDocumentCallback={deleteDocumentCallback}    
                  updateDocumentsTableCallback={() => loadAllDocuments(documentsConfig)}
                  reloadDocumentWithoutSaving={reloadDocumentWithoutSaving}
                />
              </CustomCardComponent>
            )}
          </ExpandModeLayout.Card>
        </ExpandModeLayout>
      </Row>

      {isShowHistoryModal && (
        <DocHistoryComponent
          currentActualState={actualState}
          defaultPartition={defaultPartition}
          docUUID={docUUID}
          entityType={entityDocType}
          isProjectDescriptionDocument={isProjectDescriptionDocument}
          title={prop('title', documentState) ?? defaultDocTitle}
          reloadDocument={reloadDocumentWithoutSaving}
          reloadDocumentCallback={() => loadAllDocuments(documentsConfig)}
          setIsShowHistoryModal={setIsShowHistoryModal}
        />
      )}
    </>
  );
}

DocsStartPage.propTypes = {
  hideElements: PropTypes.array,
  defaultPartition: PropTypes.string,
  pageUrl: PropTypes.string,
};

export default DocsStartPage;

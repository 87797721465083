import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { propOr, isNil, head } from 'ramda';
import { Space, Tooltip } from 'antd';
import { mdiArrowCollapseLeft, mdiArrowCollapseRight } from '@mdi/js';
import Icon from '@mdi/react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import {
  capitalize, difference, get, isEmpty,
} from 'lodash';
import moment from 'moment';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';
import ArchiveModalInTestCycle from '../../../containers/qaView/arсhiveQa/ArсhiveModalInTestCycle';
import RestoreEntityTypeBtn from '../../archiveState/RestoreEntityTypeBtn';
import QaCollapseControlBtn from '../QaCollapseControlBtn';
import CopyURL from '../../../../components/CopyURL';
import ExpandButton from '../../../../components/ExpandButton';

import { antNotification, formatDateTimeForWms } from '../../../../MainUtils';
import {
  currentSelectedUUID,
  oneTestCycleArchive,
  getTestCycle,
  getProject,
  getMe,
} from '../../../selectors/selectors';
import { BugTrackerContext, MainCollapseSectionContext, ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import HeaderCard from '../../commonComponents/HeaderCard';
import CommonInfo from '../../commonComponents/CommonInfo';
import { defaultStateBtn } from '../../issuesView/utils';
import EditButton from '../../commonComponents/EditButton';
import SpecialTextArea from '../../commonComponents/SpecialTextArea/SpecialTextArea';
import AssignedUsersRoot from '../../commonComponents/AssignedUsers/AssignedUsersRoot';
import AssignedVector from '../../commonComponents/assignedVector/AssignedVector';
import { getUsersByUUID } from '../../../../userFlow/store/action-creators/userActions';
import DateLeftEdit from '../../../../components/DateLeftEdit/DateLeftEdit';
import { updateTestCycle } from '../../../actions/QaActions';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { setGlobalUpdateEntity } from '../../../reducers/slicers/breadcrumbsSlicer';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';

function MainTestCycles({
  deleteCycle,
  isArchive,
  partition,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getClearVector, sendAssignedNotifications } = useContext(ProjectMainLayerContext);

  const { partitionType, changeQAHistory, getListOfTestCycles } = useContext(BugTrackerContext);

  const {
    toggleReset,
    toggleHorizontal,
    getCurrentHorizontalCollapseState,
  } = useContext(MainCollapseSectionContext);

  const currentHorizontalCollapseState = getCurrentHorizontalCollapseState(isArchive ? 'mainArchiveCollapse' : 'mainCycleCollapse');

  const curSelectedUUID = useSelector((state) => currentSelectedUUID(state, isArchive));
  const testCycleArchive = useSelector(oneTestCycleArchive);
  const testCycle = useSelector(getTestCycle);
  const selectedProject = useSelector(getProject);
  const userUUID = useSelector(getMe);

  const refDescription = useRef({});
  const refClonedFrom = useRef({});
  const refTitle = useRef({});
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const [stateBtnTitle, setStateBtnTitle] = useState(defaultStateBtn);
  const [stateBtnDescription, setStateBtnDescription] = useState(defaultStateBtn);
  const [stateBtnclonedFrom, setStateBtnClonedFrom] = useState(defaultStateBtn);
  const [startDate, setStartDate] = useState(defaultStateBtn);
  const [endDate, setEndDate] = useState(defaultStateBtn);
  const [attachedVector, setAttachedVector] = useState({});
  const [executedBy, setExecutedBy] = useState('');

  const [collapse, setCollapse] = useState(false);

  const [isEditAssignUsers, setEditAssignUsers] = useState(false);

  const oneTestCycle = isArchive ? testCycleArchive : testCycle;
  const testCycleParams = propOr([], 'params', head(oneTestCycle));
  const testCycleUUID = propOr('', 'uuid', oneTestCycle[0]);

  const hidden = (!isNil(testCycle[0])
    && (!isNil(testCycle[0].params.status)
      && (testCycle[0].params.status !== 'unexecuted'
        && testCycle[0].params.status !== 'in progress'
          && testCycle[0].params?.cases_count !== 0)));

  const deleteTestCycle = (uuid) => {
    const clearName = isArchive ? 'clearCycleArchive' : 'clearCycle';

    if (isArchive) {
      deleteCycle();
    } else {
      deleteCycle(uuid, true, false);
    }

    changeQAHistory({ entity: clearName, clearCases: true });
    dispatch(setGlobalUpdateEntity({
      entityType: isArchive ? 'archive' : 'cycle',
      action: 'delete',
      uuid,
    }));
  };

  const toggleCollapse = () => setCollapse((prev) => !prev);

  const onChangeData = async (newData) => {
    const data = {
      uuid: testCycleUUID,
      partition: partitionType,
      params: {
        modified_on: formatDateTimeForWms(moment()),
        modified_by: userUUID,
        archive: false,
        ...newData,
      },
    };

    dispatch(updateTestCycle(data)).then((response) => {
      if (response) {
        antNotification('success', t('wms.noun.success', 'Success'));

        const assignedUsers = get(data, 'params.assignToUser');

        if (assignedUsers) {
          const receiver = difference(
            assignedUsers,
            get(testCycleParams, 'assignToUser', []),
          );
          sendAssignedNotifications({
            entity_type: 'testCycle',
            assignedUsers: receiver,
            eventName: 'assigned_test_cycle',
            entityUUID: get(response, '[0].uuid'),
            message: get(response, '[0].params.title', ''),
            entityUUIDsFields: {
              testCycleUUID: get(response, '[0].uuid'),
              projectUUID: get(selectedProject, '[0].uuid'),
            },
          });
        }

        dispatch({
          type: ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE,
          payload: response,
        });
      } else {
        antNotification('error', t('notifications.text.error.base', 'Error'));
      }

      getListOfTestCycles();
    });
  };

  const setIsEdit = (e, closeEdit) => {
    const { id } = e.currentTarget;
    switch (id) {
      case 'description':
        if (stateBtnDescription.isEdit && !closeEdit) {
          onChangeData({ description: refDescription.current?.trim() });
        }
        refDescription.current = get(testCycleParams, 'description', '');
        setStateBtnDescription((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
        case 'clonedFrom':
        if (stateBtnclonedFrom.isEdit && !closeEdit) {
          onChangeData({ clonedFrom: refClonedFrom.current?.trim() });
        }
        refClonedFrom.current = get(testCycleParams, 'clonedFrom', '');
        setStateBtnClonedFrom((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'title':
        if (stateBtnTitle.isEdit && !closeEdit) {
          onChangeData({ title: refTitle.current?.trim() });
        }
        refTitle.current = get(testCycleParams, 'title', '');
        setStateBtnTitle((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'startDate':
        if (startDate.isEdit && !closeEdit) {
          onChangeData({ start_date: startDateRef.current });
        }
        startDateRef.current = get(testCycleParams, 'start_date', '')
          ? get(testCycleParams, 'start_date', '')
          : moment().format('YYYY-MM-DD');
        setStartDate((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'endDate':
        if (endDate.isEdit && !closeEdit) {
          onChangeData({ end_date: endDateRef.current });
        }
        endDateRef.current = get(testCycleParams, 'end_date', '')
          ? get(testCycleParams, 'end_date', '')
          : moment().format('YYYY-MM-DD');
        setEndDate((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      default: break;
    }
  };

  const onChangeTextArea = ({ id, value }) => {
    switch (id) {
      case 'title':
        refTitle.current = value;
        if (!stateBtnTitle?.isValid || !value.trim()?.length) {
          setStateBtnTitle((prev) => ({ ...prev, isValid: value.trim()?.length }));
        }
        break;
      case 'description':
        refDescription.current = value;
        if (!stateBtnDescription?.isValid) {
          setStateBtnDescription((prev) => ({ ...prev, isValid: true }));
        }
        break;
      case 'clonedFrom':
        refClonedFrom.current = value;
        if (!stateBtnclonedFrom?.isValid) {
          setStateBtnClonedFrom((prev) => ({ ...prev, isValid: true }));
        }
        break;
      default: break;
    }
  };

  const onChangeAssignedUsers = (data) => {
    const { users } = data;
    onChangeData({ assignToUser: users?.map((el) => el?.uuid) });
  };

  const onChangeStartDate = (data) => {
    const { lifeTime: time } = data;

    if (moment(time).isAfter(moment(endDateRef?.current), 'day')) {
      antNotification('warning', t('notifications.text.warning.beginning_after_end', 'beginning_after_end'));
      setStartDate((prev) => ({ ...prev, isValid: false }));
    } else {
      if (startDateRef?.current
        && endDateRef?.current
        && moment(startDateRef?.current).isSameOrBefore(moment(endDateRef?.current))) {
        setEndDate((prev) => ({ ...prev, isValid: true }));
        setStartDate((prev) => ({ ...prev, isValid: true }));
      }
      startDateRef.current = time;
      if (!startDate?.isValid) {
        setStartDate((prev) => ({ ...prev, isValid: true }));
      }
    }
  };

  const onChangeEndDate = (data) => {
    const { lifeTime: time } = data;

    if (moment(time).isBefore((startDateRef?.current ? moment(startDateRef?.current) : moment()), 'day')) {
      antNotification('warning', t('notifications.text.warning.end_before_beginning', 'end_before_beginning'));
      setEndDate((prev) => ({ ...prev, isValid: false }));
    } else {
      if (startDateRef?.current
        && endDateRef?.current
        && moment(startDateRef?.current).isSameOrBefore(moment(endDateRef?.current))) {
        setEndDate((prev) => ({ ...prev, isValid: true }));
        setStartDate((prev) => ({ ...prev, isValid: true }));
      }
      endDateRef.current = time;
      if (!endDate?.isValid) {
        setEndDate((prev) => ({ ...prev, isValid: true }));
      }
    }
  };

  const onChangeVectorCallback = (data) => {
    const { selectedVectorData, vector } = data;
    onChangeData({ vector: [vector] });
    setAttachedVector(selectedVectorData);
  };

  const createdBy = `${capitalize(get(oneTestCycle, '[0].uinfo.first_name', ''))} ${get(oneTestCycle, '[0].uinfo.last_name', '')}`;

  const subTitle = (
    <div className="flex items-center w-full gap5">
      <CommonInfo
        creator={createdBy}
        createdDate={get(head(oneTestCycle || []), 'created', '')}
        executedBy={executedBy}
        executedOn={get(testCycleParams, 'executed_on', '-')}
      />
      <CopyURL
        partition={partition}
        entityType="testCycle"
        entityUUID={testCycleUUID}
        qaEntityParent={get(selectedProject, '[0].uuid')}
      />
      <Tooltip title="Resize">
        <BaseButton
          size="small"
          type="primary"
          ghost
          onClick={toggleHorizontal}
        >
          <Icon
            size={0.9}
            path={currentHorizontalCollapseState
              ? mdiArrowCollapseRight
              : mdiArrowCollapseLeft}
          />
        </BaseButton>
      </Tooltip>
    </div>
  );

  const beforeTitle = (
    <ExpandButton
      toggleExpandedMode={toggleHorizontal}
      isExpanded={currentHorizontalCollapseState}
    />
  );

  const headerButtons = (
    <Space
      align="center"
      size={[5, 5]}
    >
      {!isArchive
        && (
          <RestoreEntityTypeBtn
            className="px-2 ml-1"
            archiveEntityUUID={curSelectedUUID}
          />
        )}

      {!isArchive && hidden ? (
        <ArchiveModalInTestCycle
          currentCycles={testCycle}
          isArchive={isArchive}
          selectedCycleOrArchiveCycle={testCycleUUID}
          type="case"
        />
      ) : (
        <HardDeleteEntityContainer
          name="cycle"
          labelMode={false}
          entityUUID={testCycleUUID}
          className="ant-btn customAntOutDangerBtn ant-btn-default ml-1 mb-1"
          actionForEntity={() => deleteTestCycle(testCycleUUID)}
          checkHardDelete={false}
          entityType="testCycle"
          type="entity"
          sizeBtn="small"
          antNotificationSuccess
          partitionType={partitionType}
        />
      )}
    </Space>
  );

  const getVector = async (uuid) => {
    const res = await getClearVector(uuid, null, ['REQUEST', 'SUCCESS', 'FAILURE']);
    setAttachedVector(head(res));
  };

  const getActors = async () => {
    if (get(testCycleParams, 'executed_by')) {
      const actor = await dispatch(getUsersByUUID({
        uuid: [get(testCycleParams, 'executed_by', '')],
        userType: 'other',
      }));
      const name = `${capitalize(get(actor, '[0].uinfo.first_name', ''))} ${get(actor, '[0].uinfo.last_name', '')}`;
      setExecutedBy(name);
    } else if (!isEmpty(executedBy)) {
      setExecutedBy('');
    }
  };

  useEffect(() => {
    getActors();
  }, [get(testCycleParams, 'executed_by'), testCycleUUID]);

  useEffect(() => {
    if (get(testCycleParams, 'vector[0]', '') && collapse) {
      getVector(get(testCycleParams, 'vector[0]', ''));
    }
  }, [get(testCycleParams, 'vector[0]', ''), collapse]);

  return (
    <div className="relative">
      <HeaderCard
        title={capitalize(t('wms.projectflow.qa.cycl'))}
        spanTitleSection={15}
        spanDescriptionSection={9}
        subTitle={subTitle}
        extra={headerButtons}
        backCallback={toggleReset}
        beforeTitle={beforeTitle}
      />
      <hr className="mt-1 mb-2" />

      <div className="pl-1">
        <div className="flex items-center">
          <div className="wrapper-title pb-0 flex items-center">
            <EditButton
              id="title"
              isEditFlag={stateBtnTitle.isEdit}
              disabled={(!stateBtnTitle.isValid && stateBtnTitle.isEdit) || isArchive}
              onClick={setIsEdit}
            />
            {`${capitalize(t('wms.labels.title', 'Title'))}:`}
          </div>
          <div className="grow wrapper-description pt-0 pl-0 ml-2">
            <SpecialTextArea
              id="title"
              placeholder="Enter title"
              maxLength={128}
              defaultValue={get(testCycleParams, 'title', '-')}
              isEdit={stateBtnTitle.isEdit}
              onChange={onChangeTextArea}
            />
          </div>
        </div>

        {collapse && (
          <>
            <hr className="my-2" />

            <div className="flex items-center w-full">
              <div className="wrapper-title pb-0 flex items-center">
                {/* <AssignedUsersRoot
                  disabled={isArchive}
                  projectUUID={get(selectedProject, '[0].uuid')}
                  partitionType={partitionType}
                  onChangeData={onChangeAssignedUsers}
                  defaultUsers={get(testCycleParams, 'assignToUser', [])}
                /> */}
                <EditButton
                  onClick={() => setEditAssignUsers(true)}
                />
                <AssigningUsersRoot
                  header="Assigning users"
                  isOpen={isEditAssignUsers}
                  setIsOpen={setEditAssignUsers}
                  onUpdateUsers={onChangeAssignedUsers}
                  needUpdateProjectUsers
                  placement="left"
                  isModal
                  globalOptions={{
                    uuids: get(selectedProject, '[0].params.usersSearch', []),
                    defaultColumn: 'project',
                  }}
                  tabs={{
                    allConfig: {
                      label: 'All',
                      value: 'all',
                      textSelectButton: 'Add to project/cycle',
                      showSections: ['button-add-user'],
                    },
                    projectConfig: {
                      label: 'Project',
                      value: 'project',
                      textSelectButton: 'Add to cycle',
                      defaultItems: get(selectedProject, '[0].params.users', []),
                      showSections: ['string-role', 'button-add-user'],
                    },
                  }}
                  usersConfig={{
                    label: 'Cycle users',
                    showSections: ['string-role', 'button-remove-user'],
                    textDeleteButton: 'Remove users',
                    defaultItems: get(testCycleParams, 'assignToUser', []),
                  }}
                  projectUuid={get(selectedProject, '[0].uuid', '')}
                  partition={partitionType}
                />
                {`${capitalize(t('wms.labels.assigned_users'))}:`}
              </div>
              <div className="grow wrapper-description ml-2">
                <WrapperAvatarsGroupForProjectWithHook
                  actors={get(testCycleParams, 'assignToUser', [])}
                  avatarSize={28}
                  popoverTrigger="click"
                  popoverPlacement="right"
                />
              </div>
            </div>

            <hr className="my-2" />

            <div className="flex items-center w-full">
              <div className="wrapper-title pb-0 flex items-center">
                <AssignedVector
                  partitionType={partitionType}
                  projectUsers={get(selectedProject, '[0].params.users', [])}
                  vectorUuid={get(testCycleParams, 'vector[0]', '')}
                  projectUuid={get(selectedProject, '[0].uuid')}
                  ticketUuid={testCycleUUID}
                  vectorData={attachedVector}
                  disabled={isArchive}
                  onChangeFieldCallback={onChangeVectorCallback}
                />
                {`${capitalize(t('wms.noun.vector', { before: t('wms.adjective.assigned') }))}:`}
              </div>
              <div className="grow wrapper-description ml-2">
                <span className="inline-flex">
                  {get(attachedVector, 'params.title', '-')}
                </span>
              </div>
            </div>

            <hr className="my-2" />

            <div className="flex  flex-col items-start w-full mb-2">
              <div className="wrapper-title pb-0 flex items-center">
                <EditButton
                  id="description"
                  isEditFlag={stateBtnDescription.isEdit}
                  disabled={
                    (!stateBtnDescription.isValid && stateBtnDescription.isEdit) || isArchive
                  }
                  onClick={setIsEdit}
                />
                {`${capitalize(t('wms.labels.description', 'description'))}:`}
              </div>
              <div className={cn('grow wrapper-description pl-0 w-full', { 'mt-1': stateBtnDescription.isEdit })}>
                <SpecialTextArea
                  id="description"
                  className={cn({ 'min-height-50': stateBtnDescription.isEdit, 'min-h-0': !stateBtnDescription.isEdit })}
                  defaultValue={get(testCycleParams, 'description', '-')}
                  isEdit={stateBtnDescription.isEdit}
                  onChange={onChangeTextArea}
                />
              </div>
            </div>
            <hr className="my-2" />
            <div className="flex flex items-center">
              <div className="wrapper-title pb-0 flex items-center">
                <EditButton
                  id="startDate"
                  isEditFlag={startDate.isEdit}
                  disabled={(!startDate.isValid && startDate.isEdit) || isArchive}
                  onClick={setIsEdit}
                />
                <span className="mr-1">
                  {`${capitalize(t('wms.labels.date.start', 'start date'))}:`}
                </span>
              </div>
              <div className={cn('grow wrapper-description pt-0 pl-0', { 'pt-1': startDate.isEdit })}>
                {startDate.isEdit ? (
                  <DateLeftEdit
                    regularFormat
                    expirationDate={startDateRef.current}
                    onChangeFieldCallback={onChangeStartDate}
                  />
                ) : (
                  <span className="ml-2">
                    {get(testCycleParams, 'start_date', '-')}
                  </span>
                )}
              </div>
            </div>
            <hr className="my-2" />
            <div className="flex flex items-center">
              <div className="wrapper-title pb-0 flex items-center">
                <EditButton
                  id="endDate"
                  isEditFlag={endDate.isEdit}
                  disabled={(!endDate.isValid && endDate.isEdit) || isArchive}
                  onClick={setIsEdit}
                />
                <span className="mr-1">
                  {`${capitalize(t('wms.labels.date.end', 'end date'))}:`}
                </span>
              </div>
              <div className={cn('grow wrapper-description pt-0 pl-0', { 'pt-1': endDate.isEdit })}>
                {endDate.isEdit ? (
                  <DateLeftEdit
                    regularFormat
                    expirationDate={endDateRef?.current}
                    onChangeFieldCallback={onChangeEndDate}
                  />
                ) : (
                  <span className="ml-2">
                    {get(testCycleParams, 'end_date', '-')}
                  </span>
                )}
              </div>
            </div>
            <hr className="my-2" />
            <div className="flex  flex-row items-start w-full mb-2">
              <div className="wrapper-title pb-0 flex items-center">
                <EditButton
                  id="clonedFrom"
                  isEditFlag={stateBtnclonedFrom.isEdit}
                  disabled={
                    (!stateBtnclonedFrom.isValid && stateBtnclonedFrom.isEdit) || isArchive
                  }
                  onClick={setIsEdit}
                />
                {`${capitalize(t('wms.labels.clonedFrom', 'cloned From'))}:`}
              </div>
              <div className={cn('grow wrapper-clonedFrom pl-0 w-full', { 'mt-1': stateBtnclonedFrom.isEdit })}>
                <SpecialTextArea
                  id="clonedFrom"
                  className={cn({ 'min-height-50': stateBtnclonedFrom.isEdit, 'min-h-0': !stateBtnclonedFrom.isEdit })}
                  defaultValue={get(testCycleParams, 'clonedFrom', '-')}
                  isEdit={stateBtnclonedFrom.isEdit}
                  onChange={onChangeTextArea}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <QaCollapseControlBtn
        toggleCollapse={toggleCollapse}
        collapse={collapse}
      />
    </div>
  );
}

MainTestCycles.propTypes = {
  deleteCycle: PropTypes.func,
  isArchive: PropTypes.bool,
  partition: PropTypes.string,
};

export default MainTestCycles;

import { head, omit } from 'lodash';
import {
  array, bool, func, object, string,
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { pathOr } from 'ramda';
import NewIssueModal from './NewIssueModal';

import { getProject, getProjectUUID, getVectorInfo } from '../../../selectors/selectors';
import { getUuidsfromParamsUsers } from '../../../../MainUtils';
import useURLParams from '../../../../hooks/useURLParams';
import useGetUserPerm from '../../../../permissions/hooks/useGetUserPerm';

export default function WrapperAddIssue({
  partitionType,
  customParentIssue,
  createIssueCallback,
  isGlobalCreateModal = false,
  hideView = [],
  disabledView = [],
  disableBtn,
  openFromOuterComponent,
  closeModalCallback,
  hideAddIssueBtn,
  defaultIssueState,
  customTitleOpenModalBtn,
  issueForProject = false,
  mandatoryIssue = false,
  activeVectorFromProps
}) {
  const { getURLParams } = useURLParams();

  const projectData = head(useSelector(getProject));
  const projectUUID = useSelector(getProjectUUID);
  const vectorData = head(useSelector(getVectorInfo));

  const { activeVector, activeProject } = getURLParams();

  const [customDisableBtn, setCustomDisableBtn] = useState(false);

  const { perm } = useGetUserPerm({
    entityUUID: projectUUID,
    partitionType,
  })

  const getListUsers = () => {
    if (activeProject && !activeVector) {
      const projectUUIDs = getUuidsfromParamsUsers(projectData?.params?.users);
      return projectUUIDs;
    }
    if (activeVector) {
      return getUuidsfromParamsUsers(vectorData?.params?.users);
    }
    return [];
  };

  const defaultIssueConfig = {
    usersUuidsForAssigned: getListUsers(),
    assignedProject:  customParentIssue || activeProject || '',
    assignedVector: issueForProject ? '' :  activeVector || '',
    selectedVectorData:  activeVectorFromProps ||  { ...omit(vectorData, ['params']), ...vectorData?.params }  || {},
    selectedProjectData: projectData || {},
    isGlobalCreateModal,
    ...defaultIssueState,
  };

  useEffect(() => {
    if (activeVector && pathOr('', ['params', 'status'], vectorData) === 'protected') {
      setCustomDisableBtn(true);
    } else {
      setCustomDisableBtn(false);
    }
  }, [vectorData, activeVector]);

  return (
    <NewIssueModal
      // hasCreatePerm={perm?.create ?? false}
      hasCreatePerm={true}
      hideView={hideView}
      disabledView={disabledView}
      disableBtn={disableBtn || customDisableBtn}
      partitionType={partitionType}
      defaultIssueConfig={defaultIssueConfig}
      customParentIssue={customParentIssue}
      openFromOuterComponent={openFromOuterComponent}
      hideAddIssueBtn={hideAddIssueBtn}
      closeModalCallback={closeModalCallback}
      createIssueCallback={createIssueCallback}
      customTitleOpenModalBtn={customTitleOpenModalBtn}
      mandatoryIssue={mandatoryIssue}
    />
  );
}

WrapperAddIssue.propTypes = {
  partitionType: string,
  createIssueCallback: func,
  customParentIssue: string,
  isGlobalCreateModal: bool,
  hideView: array,
  disabledView: array,
  openFromOuterComponent: bool,
  closeModalCallback: func,
  hideAddIssueBtn: bool,
  defaultIssueState: object,
  disableBtn: bool,
  customTitleOpenModalBtn: string,
  issueForProject: bool,
  mandatoryIssue: bool,
  activeVectorFromProps: object
};

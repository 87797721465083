import React, { useLayoutEffect, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { array, bool, string } from 'prop-types';

import {
  mdiArrowExpand,
  mdiArrowCollapse,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiUnfoldMoreHorizontal,
  mdiUnfoldLessHorizontal,
  // mdiTableEye,
  // mdiTableEyeOff,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Radio,
  Tooltip,
  Flex,
  // Button,
} from 'antd';

import { capitalize } from 'lodash';

import { useExpandModeContext, EXPAND_MODE } from './ExpandModeLayout';

import './ExpandModeLayoutActions.scss';

const ExpandModeLayoutActions = memo(({
  parentType,
  isFetching,
  // showMetaBtn = true,
  disabledOptions = [],
}) => {
  const { t } = useTranslation();

  const {
    localStorageTarget,
    isDisabled,
    expandedMode,
    setExpandedMode,
    showMetaInfo,
    // setShowMetaInfo,
    isBaseMode,
    isWideMode,
    isVerticalMode,
    isFullscreenMode,
  } = useExpandModeContext();

  // const metaInfoText = showMetaInfo
  //   ? capitalize(t('', 'hide meta info'))
  //   : capitalize(t('', 'show meta info'));

  // console.log('expandedMode', expandedMode);

  useEffect(() => {
    return () => {
      setExpandedMode(EXPAND_MODE.BASE)
    }
  }, [])

  useLayoutEffect(() => {
    if (isFullscreenMode) {
      document.body.classList.add('overflow-hidden');
      // setShowMetaInfo?.(false);
    } else {
      document.body.classList.remove('overflow-hidden');
      // setShowMetaInfo?.(true);
    }

    // if (isWideMode) document.body.classList.add('overflow-x-hidden overflow-hidden overflow-y-auto');
    // else document.body.classList.remove('overflow-x-hidden overflow-hidden overflow-y-auto');

    return () => {
      document.body.classList.remove('overflow-hidden');
      // document.body.classList.remove('overflow-x-hidden overflow-hidden overflow-y-auto');
    };
  }, [expandedMode]);

  const toggleExpandedMode = (e) => {
    const { value: newValue } = e?.target;

    const isNeedReset = newValue === expandedMode;
    const finalValue = isNeedReset ? EXPAND_MODE.BASE : newValue;

    setExpandedMode(finalValue);
    if (localStorageTarget) localStorage.setItem(`${localStorageTarget}ExpandMode`, newValue.toString());
  };

  // const toggleMetaInfo = () => {
  //   setShowMetaInfo((prev) => !prev);
  // };

  if (isDisabled) return null;
  return (
    <Flex gap="small" align="center">
      <Tooltip
        title={isFullscreenMode ? '' : capitalize(t('wms.buttons.collapse', `Expand ${parentType} area`))}
        placement="top"
      >
        <Radio.Group
          className="expand-radio-group"
          optionType="button"
          buttonStyle="solid"
          value={expandedMode || EXPAND_MODE.BASE}
          disabled={isFetching}
          onChange={toggleExpandedMode}
        >
          <Radio
            id='setFullScreenButton'
            value={EXPAND_MODE.FULLSCREEN}
            disabled={disabledOptions.includes(EXPAND_MODE.FULLSCREEN)}
            onClick={toggleExpandedMode}
          >
            <Icon className="mr-1" path={isFullscreenMode ? mdiFullscreenExit : mdiFullscreen} size={0.8} />
            <span className="expand-radio-group-text">
              {isFullscreenMode
                ? capitalize(t('wms.buttons.full_screen_exit', 'exit full screen'))
                : capitalize(t('wms.buttons.full_screen', 'full screen'))}
            </span>
          </Radio>
          <Radio
            id='setWideButton'
            value={EXPAND_MODE.WIDE}
            disabled={disabledOptions.includes(EXPAND_MODE.WIDE)}
            onClick={toggleExpandedMode}
          >
            <Icon className="mr-1" path={isWideMode ? mdiArrowCollapse : mdiArrowExpand} size={0.8} />
            <span className="expand-radio-group-text">{capitalize(t('wms.buttons.wide', 'wide'))}</span>
          </Radio>
          <Radio
            id='setVerticalButton'
            value={EXPAND_MODE.VERTICAL}
            disabled={disabledOptions.includes(EXPAND_MODE.VERTICAL)}
            onClick={toggleExpandedMode}
          >
            <Icon className="mr-1" path={isVerticalMode ? mdiUnfoldLessHorizontal : mdiUnfoldMoreHorizontal} size={0.8} />
            <span className="expand-radio-group-text">{capitalize(t('wms.buttons.v-view', 'v-view'))}</span>
          </Radio>
          <Radio
            id='setBaseButton'
            className={!isBaseMode && 'highlighted-base-mode'}
            value={EXPAND_MODE.BASE}
            disabled={disabledOptions.includes(EXPAND_MODE.BASE)}
            onClick={toggleExpandedMode}
          >
            <span className="expand-radio-group-text">{capitalize(t('wms.buttons.base', 'base'))}</span>
          </Radio>
        </Radio.Group>
      </Tooltip>
      {/* {showMetaBtn && isFullscreenMode && (
        <Button
          id='showMetaInfoButton'
          className="btnBlue"
          icon={<Icon path={showMetaInfo ? mdiTableEyeOff : mdiTableEye} size={0.8} />}
          onClick={toggleMetaInfo}
        >
          {metaInfoText}
        </Button>
      )} */}
    </Flex>
  );
});

ExpandModeLayoutActions.propTypes = {
  parentType: string,
  isFetching: bool,
  showMetaBtn: bool,
  disabledOptions: array,
};

export default ExpandModeLayoutActions;

import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { isEmpty } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Outlet } from 'react-router-dom';
import cn from 'classnames';

import "./MainLayout.scss"

import Loader from './projectFlow/components/Loader';
import SidebarMenu from './components/SidebarMenu/SidebarMenu';
import DidNotReceiveServiceDomain from './components/DidNotReceiveServiceDomain';
import ChangeResolutionPage from './components/mobile/ChangeResolutionPage';
import GetSessionTokenModal from './admin/components/GetSessionTokenModal';
import { VersionLabel } from './54origins/components/GitVersion54origins';
import ChatGptBtn from './GPT/ChatGptBtn';
import Header from "./components/Header/Header";
import BaseSidebar from "./components/_ui/BaseSidebar/BaseSidebar";

import { AuthHelper } from './auth/reducers/AuthReducer';
import {
  checkBackendUrlEntity,
  getAuthConfigFetching,
  getConfig,
  getConfigAuth,
  getConfigFetching,
} from './config/selectors/selectors';
import getConfigEntity, { getPublicInterfaces } from './api/api';
import {
  getActorPublicEntityAndGetPublicSettings,
  getPrivateSettingsChildren,
  getPrivateSettingsRequest,
  getPrivateUserProfile,
  getMyInfoAndListOfActors,
} from './userFlow/store/action-creators/userActions';
import { capitalizeAndTranslateMsg } from './MainUtils';
import usePublicUserProjects from './userFlow/hooks/usePublicUserProjects';
import SocketProvider from './socket/SocketProvider';
import { useResize } from './hooks/useResize';
import useUpdateReduxActorsW54 from './actors/hooks/useUpdateReduxActorsW54';


const { Content} = Layout;

function GetNamedProject() {
  usePublicUserProjects();
  return (<></>);
}

function MainLayout({ fixedHeader, headerHeight, fixedSidebar, sidebarWidth }) {
  // console.log('RENDER APP');
  const dispatch = useDispatch();

  const { isScreenXxl } = useResize();

  // Обновление пользователей ActorsW54 каждые 30 мин
  useUpdateReduxActorsW54();

  const authConfigFetching = useSelector(getAuthConfigFetching);
  const backendUrl = useSelector(checkBackendUrlEntity);
  const configAuth = useSelector(getConfigAuth);
  const configFetching = useSelector(getConfigFetching);
  const partitions = useSelector(getConfig);

  const [collapsed, setCollapsed] = useState(false);

  const currentYear = new Date().getFullYear();

  const toggleMenu = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const isAuthorized = AuthHelper.isAuthorised();

  const initFunc = async () => {
    // console.log('IF isAuthorized', isAuthorized);
    // console.log('IF authConfigFetching', authConfigFetching);

    if (isAuthorized && !authConfigFetching) {
      console.log('IF initFunc');
      await dispatch(getConfigEntity());
      dispatch(getMyInfoAndListOfActors());

      // Private
      const uuid = await dispatch(getPrivateUserProfile());
      const res = await dispatch(getPrivateSettingsRequest(uuid));

      dispatch(getPrivateSettingsChildren(
        get(res, 'uuid'),
        'favoriteSettings',
      ));

      // Public
      dispatch(getActorPublicEntityAndGetPublicSettings());
    }
  };

  useEffect(() => {
    if (isEmpty(configAuth)) {
      dispatch(getPublicInterfaces());
    }
  }, [configAuth]);

  useEffect(() => {
    // console.log('configAuth', configAuth);
    if (!isEmpty(configAuth)) {
      initFunc();
    }
  }, [isAuthorized, authConfigFetching]);

  useEffect(() => {
    !isScreenXxl && setCollapsed(true);
  }, [isScreenXxl]);

  if ((isAuthorized && configFetching) || (isAuthorized && isEmpty(partitions))) {
    return <Loader />;
  }

  if (!backendUrl) {
    return <DidNotReceiveServiceDomain />;
  }

  return (
    <>
      <div id="successMigration" />
      <ChangeResolutionPage />
      <GetNamedProject />
      <SocketProvider>
        <Layout className="desktop-display min-h-screen">
          <Layout>
            {isAuthorized && (
            <>
              <Header
                toggleMenu={toggleMenu}
                menuOpen={collapsed}
                fixed={fixedHeader}
                headerHeight={headerHeight}
                sidebarWidth={sidebarWidth}
              />
            </>
            )}

            <Layout
              className={cn('contentBar', fixedHeader ? 'fixed-header' : '', fixedSidebar ? 'fixed-sidebar' : '', collapsed ? 'collapsed' : '', !isAuthorized ? 'login' : '')}
              style={{
                paddingTop: fixedHeader ? `${headerHeight}px` : '',
                paddingLeft: fixedSidebar ? `${sidebarWidth}px` : ''
              }}
            >
              {isAuthorized && (
                <BaseSidebar
                  trigger={null}
                  collapsible={true}
                  collapsed={collapsed}
                  fixed={fixedSidebar}
                  sidebarWidth={sidebarWidth}
                >
                  <SidebarMenu
                    collapsed={collapsed}
                  />
                </BaseSidebar>
              )}

              <Layout>
                <Content className="p-6">
                  <Outlet />
                </Content>

                {isAuthorized && (
                  <Layout.Footer className='app-layout-footer'>
                    <Row className="w-full">
                      <Col span={6}>
                        {`${capitalizeAndTranslateMsg('wms.copyright', 'copyright')} 54Origins`}
                        <span className="px-1">&#169;</span>
                        <span>{currentYear}</span>
                      </Col>
                      <Col span={12} align="center">
                        <VersionLabel />
                      </Col>
                      <Col span={8} />
                    </Row>
                  </Layout.Footer>
                )}
              </Layout>

              <ChatGptBtn />
            </Layout>
          </Layout>
        </Layout>
      </SocketProvider>
      <GetSessionTokenModal width={800} />
    </>
  );
}

export default MainLayout;

MainLayout.propTypes = {
  fixedHeader: PropTypes.bool,
  headerHeight: PropTypes.number,
  fixedSidebar: PropTypes.bool,
  sidebarWidth: PropTypes.number,
};

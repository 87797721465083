import { ProjectTypeCommonConstants } from '../constants/Constants';

const initialState = {
  fetching: false,
  listTimeLog: [],
  total: 0,
  // timelogs: [],
  // timelogsData: [],
  error: '',
};

export default (state = initialState, action) => {
  const {
    total,
    payload,
    type,
  } = action;

  switch (type) {
    case ProjectTypeCommonConstants.LIST_TIME_LOG_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.LIST_TIME_LOG_SUCCESS:
      return {
        ...state,
        fetching: false,
        total,
        listTimeLog: payload,

      };
    case ProjectTypeCommonConstants.LIST_TIME_LOG_FAILURE:
      return {
        ...state,
        fetching: false,
        total: 0,
        error: payload,
      };

    case ProjectTypeCommonConstants.CLEAR_LIST_TIME_LOG:
      return {
        fetching: false,
        total: 0,
        listTimeLog: [],
        error: '',
      };
      // case ProjectTypeCommonConstants.LIST_OF_TIMELOG_REQUEST:
      //   return {
      //     ...state,
      //     fetching: state.timelogs.length !== 0
      //   };

      // case ProjectTypeCommonConstants.LIST_OF_TIMELOG_SUCCESS:
      //   return {
      //     ...state,
      //     fetching: false,
      //     timelogs: action.payload,
      //     timelogsData: action.payload
      //   };
      // case ProjectTypeCommonConstants.LIST_OF_TIMELOG_FAILURE:
      //   return {
      //     ...state,
      //     fetching: false,
      //     timelogs: [],
      //     timelogsData: [],
      //     error: action.data.message
      //   };

      // case ProjectTypeCommonConstants.NEW_TIMELOG_REQUEST:
      //   return {
      //     ...state,
      //     fetching: false
      //   };

      // case ProjectTypeCommonConstants.NEW_TIMELOG_SUCCESS:
      //   return {
      //     ...state,
      //     fetching: false,
      //     timelogs: state.timelogs,
      //     timelogsData: state.timelogs
      //   };
      // case ProjectTypeCommonConstants.NEW_TIMELOG_FAILURE:
      //   return {
      //     ...state,
      //     fetching: false,
      //     timelogs: [],
      //     timelogsData: [],
      //     error: action.data.message
      //   };
      // case ProjectTypeCommonConstants.DELETE_TIMELOG_REQUEST:
      //   return {
      //     ...state,
      //     fetching: false
      //   };

      // case ProjectTypeCommonConstants.DELETE_TIMELOG_SUCCESS:
      //   return {
      //     ...state,
      //     fetching: false,
      //     timelogs: state.timelogs,
      //     timelogsData: state.timelogs
      //   };
      // case ProjectTypeCommonConstants.DELETE_TIMELOG_FAILURE:
      //   return {
      //     ...state,
      //     fetching: false,
      //     timelogs: [],
      //     timelogsData: [],
      //     error: action.data.message
      //   };
      // case ProjectTypeCommonConstants.EDIT_TIMELOG_REQUEST:
      //   return {
      //     ...state,
      //     fetching: false
      //   };

      // case ProjectTypeCommonConstants.EDIT_TIMELOG_SUCCESS:
      //   return {
      //     ...state,
      //     fetching: false,
      //     timelogs: state.timelogs,
      //     timelogsData: state.timelogs
      //   };
      // case ProjectTypeCommonConstants.EDIT_TIMELOG_FAILURE:
      //   return {
      //     ...state,
      //     fetching: false,
      //     timelogs: [],
      //     timelogsData: [],
      //     error: action.payload
      //   };
      // case ProjectTypeCommonConstants.CLEAR_OF_TIMELOGS:
      //   return {
      //     ...state,
      //     fetching: false,
      //     timelogs: [],
      //     timelogsData: [],
      //     error: ''
      //   };

    default: return state;
  }
};

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import {  getProjectUUID } from "../../selectors/selectors";
import { getNewProjectGoalFetching } from '../../reducers/slicers/projectGoalsSlicer';
import { incrementAndGetCount } from '../../../entity/actions/entityActions';
import { antNotification, capitalizeAndTranslateMsg } from '../../../MainUtils';
import { useGoalsRequestHook } from "../../../entity/hooks/useGoalsRequestHook";
import { UI54Button } from "@agpl/ui54kit";

function CreateProjectGoalBtn({
  createProjectGoalCallback,
  disableBtn,
  partition,
  className,
  isLink,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {createProjectGoalRequest} = useGoalsRequestHook(partition)

  const projectUUID = useSelector(getProjectUUID);
  const newProjectGoalFetching = useSelector(getNewProjectGoalFetching);

  const [loading, setLoading] = useState(false);

  const createProjectGoal = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const { increment: goalID } = await dispatch(incrementAndGetCount({
      entity_uuid: projectUUID,
      field_name: 'goal',
    }, partition)) || {};

    const newGoalParams = {
      id: goalID,
      title: 'New Goal',
      status: 'created',
      handleSort: goalID + 1,
    };

    createProjectGoalRequest({
      parent: projectUUID,
      params: newGoalParams,
      partition,
    }).then((res) => {
        antNotification('success', t('wms.noun.success', 'Success'));
        createProjectGoalCallback?.(res[0]?.uuid);
      })
      .finally(setLoading(false));
  };

  return (
    <UI54Button
      className={`${className} ${!isLink ? 'btnPrimary-outline mb-1' : ''} ml-1`}
      disabled={disableBtn || newProjectGoalFetching}
      onClick={createProjectGoal}
      type={isLink ? 'link' : null}
      size="small"
      id="create_milestone_btn"
    >
      {capitalizeAndTranslateMsg('wms.verb.create', 'create')}
      {' '}
      {t('wms.noun.goal.0', 'goal')}
    </UI54Button>
  );
}

export default CreateProjectGoalBtn;

CreateProjectGoalBtn.propTypes = {
  className: PropTypes.string,
  createProjectGoalCallback: PropTypes.func,
  disableBtn: PropTypes.bool,
  partition: PropTypes.string,
};

import { ProjectTypeCommonConstants } from '../constants/Constants';

const initialState = {
  fetching: false,
  issues: [],
  error: '',
};
export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ProjectTypeCommonConstants.LIST_OF_QUICK_ISSUES_REQUEST:
      return {
        ...state,
        fetching: state.issues.length === 0,
      };
    case ProjectTypeCommonConstants.LIST_OF_QUICK_ISSUES_SUCCESS:
      return {
        ...state,
        fetching: false,
        issues: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_QUICK_ISSUES_FAILURE:
      return {
        ...state,
        fetching: false,
        issues: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.NEW_QUICK_ISSUE_REQUEST:
      return {
        ...state,
      };
    case ProjectTypeCommonConstants.NEW_QUICK_ISSUE_SUCCESS:
      return {
        ...state,
        fetching: false,
        issues: state.issues,
      };
    case ProjectTypeCommonConstants.NEW_QUICK_ISSUE_FAILURE:
      return {
        ...state,
        fetching: false,
        issues: [],
        error: payload,
      };
    case ProjectTypeCommonConstants.CLEAR_LIST_OF_QUICK_ISSUES:
      return {
        ...state,
        fetching: false,
        issues: [],
        error: '',
      };
    default: return state;
  }
};

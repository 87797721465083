import React from 'react';
import {
  Divider, Row, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiChevronUp } from '@mdi/js';

const { Text } = Typography;

export default function LineWithTextButton({
  onClickCallback,
  isExpanded,
  defaultMessage,
  expandedMessage,
  wrapperStyle,
}) {
  return (
    <Row
      id='showAllStatusesButton'
      className="flex items-center"
      onClick={onClickCallback}
      style={wrapperStyle}
    >
      <Divider
        plain
        style={{
          borderBlockColor: '#3e9bffec',
        }}
      >
        <Text
          style={{
            color: '#1890ff',
            cursor: 'pointer',
          }}
        >
          {isExpanded ? expandedMessage : defaultMessage}
        </Text>
        <Icon
          path={mdiChevronUp}
          size={1}
          color="#3e9bffec"
          rotate={!isExpanded ? 540 : 0}
        />
      </Divider>
    </Row>
  );
}
LineWithTextButton.propTypes = {
  isExpanded: PropTypes.bool,
  onClickCallback: PropTypes.func,
  defaultMessage: PropTypes.string,
  expandedMessage: PropTypes.string,
  wrapperStyle: PropTypes.object,
};

import React from "react";
import PropTypes from "prop-types";
import { Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

function SyncStatus({ status, isHelper }) {
  const createTagData = (status, isHelper) => {
    switch (status) {
      case "Saved": {
        return {
          icon: <CheckCircleOutlined />,
          color: "success",
        };
      }
      case "Saving": {
        return {
          icon: <SyncOutlined spin={!isHelper} />,
          color: "processing",
        };
      }
      default: {
        return {
          icon: <CloseCircleOutlined />,
          color: "error",
        };
      }
    }
  };

  const tagData = createTagData(status, isHelper);

  return (
    <Tag
      icon={tagData?.icon}
      color={tagData?.color}
      className="quill-toolbar-sync-status"
    >
      {status}
    </Tag>
  );
}

SyncStatus.propTypes = {
  status: PropTypes.string,
  isHelper: PropTypes.bool,
};

export default SyncStatus;

import Icon from '@mdi/react';
import cn from "classnames";
import PropTypes from "prop-types";

const BaseIcon = ({ path, color, size, className, ...props }) => {
  return (
    <span
      className={cn('anticon', className)}
    >
      <Icon path={path} size={size} color={color} />
    </span>
  );
};

export default BaseIcon;

BaseIcon.propTypes = {
  path: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};

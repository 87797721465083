import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import PropTypes, { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'ramda';
import { capitalize } from 'lodash';

import BaseSelect from '../../components/_ui/BaseSelect/BaseSelect';

import {
  getPublicActorsForSelectList,
  getProjectActors,
  getPublicActorsFromMainIssueEntityForSelectList,
  getTemporaryActors,
} from '../../selectors/selectors';
import { getUuidsfromParamsUsers } from '../../MainUtils';
import { transformActorsToSelect } from '../selectors/selectors';
import { getProjectParams, getProjectUUID } from '../../projectFlow/selectors/selectors';
import { getUsersByUUID } from '../../userFlow/store/action-creators/userActions';
import { entityPartialRead } from '../actions/entityActions';

function SelectActorsComponent({
  actorTypes = ['user', 'classic_user', 'phantom'],
  allowClear,
  closeMenuOnSelect = false,
  customHeight,
  customInputLabel,
  customSelectedActors = [],
  customSelectedActorsReset,
  fetching = false,
  hideLabel = false,
  hideSelected,
  id = 'projectVisibleMembers',
  isDisabled = false,
  isMulti = 'multiple',
  labelClassName = '',
  listOfActors = ['defaultUsers'],
  onDeselectUser,
  onSelectUser,
  parentUUID,
  partitionType,
  placement,
  saveActorsCallback,
  showOption = false,
  usersFetching = false,
  vectorActors,
  wrapperClassName = 'w-full',
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const dispatch = useDispatch();

  const defaultActors = useSelector(getProjectActors);
  const projectActors = useSelector(getProjectActors);
  const temporaryActors = useSelector(getTemporaryActors);
  const publicActors = useSelector(getPublicActorsForSelectList);
  const publicActorsFromMainIssue = useSelector(getPublicActorsFromMainIssueEntityForSelectList);
  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);
  const {
    users,
  } = projectParams;

  const [isShowProjectActors, setIsShowProjectActors] = useState(false);
  const [customListOfActors, setCustomListOfActors] = useState(listOfActors);
  const [selectedActors, setSelectedActors] = useState(null);
  const [projectUsers, setProjectUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProjectRequest = () => {
    const data = {
      entity_type: 'project',
      entity_uuid: projectUUID || parentUUID,
      params_fields: {
        users: 'users',
      },
    };

    const constants = [
      'GET_PROJECT_FOR_ACTORS_SELECT_NOREDUX_REQUEST',
      'GET_PROJECT_FOR_ACTORS_SELECT_NOREDUX_SUCCESS',
      'GET_PROJECT_FOR_ACTORS_SELECT_NOREDUX_FAILURE',
    ];

    const options = {
      partition: partitionType,
    };

    return dispatch(entityPartialRead({
      data,
      constants,
      options,
    }));
  };

  const createARoleString = (name, uuid, usersWithRoles) => {
    if (usersWithRoles) {
      const user = usersWithRoles.find((e) => e.uuid === uuid);
      if (user?.role) {
        return `${name} ( ${usersWithRoles.find((e) => e.uuid === uuid).role} )`;
      }
      return `${name}`;
    }

    return `${name}`;
  };

  const addRolesToOptionsList = (actors) => (actors.map((el) => ({
    ...el,
    label: createARoleString(el.label, el.value, users?.length ? users : projectUsers),
  })));

  const filterByActorType = (actors) => actors
    .filter((item) => actorTypes.indexOf(item.actor_type) !== -1);

  const getActorsByType = (name) => {
    let actors = [];
    switch (name) {
      case 'defaultUsers':
        actors = transformActorsToSelect(filterByActorType(defaultActors));
        return actors;
      case 'projectActors':
        actors = transformActorsToSelect(filterByActorType(projectActors));
        return addRolesToOptionsList(actors);
      case 'vectorActors':
        actors = transformActorsToSelect(filterByActorType(projectActors));

        if (vectorActors.length) {
          return addRolesToOptionsList(actors.filter((e) => vectorActors.includes(e.value))).length
            ? addRolesToOptionsList(actors.filter((e) => vectorActors.includes(e.value)))
            : addRolesToOptionsList(actors);
        }
        return addRolesToOptionsList(actors);
      case 'temporaryActors':

        if (vectorActors.length) {
          return transformActorsToSelect(temporaryActors)
            .filter((e) => vectorActors.includes(e.value)).length
            ? transformActorsToSelect(temporaryActors)
              .filter((e) => vectorActors.includes(e.value))
            : transformActorsToSelect(temporaryActors);
        }
        return transformActorsToSelect(temporaryActors);
      case 'publicActors':

        return publicActors;
      case 'publicActorsFromMainIssue':

        return publicActorsFromMainIssue;
      default:
        return transformActorsToSelect(filterByActorType(defaultActors));
    }
  };

  const getInitialsOptions = () => {
    if (typeof customListOfActors === 'string') {
      return getActorsByType(customListOfActors);
    }
    let finalActors = [];
    customListOfActors.forEach((item) => {
      finalActors = [...finalActors, ...getActorsByType(item)];
    });
    return uniqBy((item) => item.value, finalActors);
  };

  const changeSelectedActors = (actors, removeTarget) => {
    let finalActors = actors;

    if (finalActors === (null || undefined)) {
      finalActors = [];
    }

    if (typeof finalActors === 'string') {
      finalActors = [finalActors];
    }

    if (saveActorsCallback) {
      saveActorsCallback(finalActors);
    }
  };

  const setSelectedCurrentActors = () => {
    const currentSelectedUsers = defaultActors
      .filter((el) => customSelectedActors.includes(el.uuid));

    setSelectedActors(transformActorsToSelect(filterByActorType(currentSelectedUsers)));
  };

  const getProjectActorsRequest = async () => {
    if (projectUUID || parentUUID) {
      const res = await getProjectRequest();

      setProjectUsers(res[0]?.users || []);

      dispatch(getUsersByUUID({
        uuid: getUuidsfromParamsUsers(res[0]?.users || []),
        userType: 'project',
      }));
    }
    setLoading(false);
  };

  const options = getInitialsOptions();

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    getProjectActorsRequest();
  }, [parentUUID]);

  useEffect(() => {
    setSelectedCurrentActors();
  }, [
    customSelectedActorsReset,
    customSelectedActors,
    // JSON.stringify(customListOfActors),
    // projectUsers,
    // projectActors,
    defaultActors,
  ]);

  useEffect(() => {
    if (showOption) {
      if (isShowProjectActors) {
        setCustomListOfActors((prev) => [...prev, 'projectActors']);
      } else {
        setCustomListOfActors([...customListOfActors].filter((el) => el !== 'projectActors'));
      }
    }
  }, [isShowProjectActors, showOption]);

  const usersForGroup = useMemo(() => {
    if (showOption && projectUsers) {
      return [...projectUsers.map(({ uuid }) => ({
        value: uuid,
        label: transformActorsToSelect(filterByActorType(projectActors))
          .find((e) => e.value === uuid)?.label,
      }))].filter(({ value }) => !customSelectedActors.includes(value));
    }
  }, [projectUsers, customSelectedActors]);

  return (
    <div id={id} ref={ref} className={wrapperClassName}>
      {!hideLabel
        && (
        <label className={` m-0 p-0 ${labelClassName}`}>
          {customInputLabel !== undefined ? customInputLabel : capitalize(t('wms.labels.users', 'Users'))}
        </label>
        )}
      <div className="w-full">
        <BaseSelect
          allowClear={allowClear}
          autoClearSearchValue
          loading={loading || usersFetching || fetching}
          className="react-select w-full"
          classNamePrefix="react-select"
          closeMenuOnSelect={closeMenuOnSelect}
          disabled={isDisabled}
          getPopupContainer={() => ref?.current}
          hideSelected={hideSelected}
          isClearable
          isShowProjectActors={isShowProjectActors}
          maxMenuHeight={customHeight}
          mode={isMulti}
          onChange={changeSelectedActors}
          onDeselect={onDeselectUser}
          onSelect={onSelectUser}
          optionFilterProp="label"
          optionLabelProp="label"
          options={options}
          placeholder={capitalize(t('wms.placeholders.users', 'Users'))}
          placement={placement}
          projectUsers={usersForGroup}
          setIsShowProjectActors={setIsShowProjectActors}
          showArrow
          showOption={showOption}
          value={selectedActors || []}
        />
      </div>
    </div>
  );
}

export default SelectActorsComponent;

SelectActorsComponent.propTypes = {
  allowClear: PropTypes.bool,
  vectorActors: PropTypes.array,
  actorTypes: PropTypes.array,
  closeMenuOnSelect: PropTypes.bool,
  customInputLabel: PropTypes.string,
  customSelectedActors: PropTypes.array,
  customSelectedActorsReset: PropTypes.func,
  fetching: PropTypes.bool,
  saveActorsCallback: PropTypes.func,
  hideLabel: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  labelClassName: PropTypes.any,
  listOfActors: PropTypes.array,
  usersFetching: PropTypes.any,
  customHeight: PropTypes.number,
  parentUUID: PropTypes.string,
  wrapperClassName: string,
  placement: string,
};

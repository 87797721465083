import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { concat, head, omit } from 'lodash';

const initialState = {
  fetching: false,
  permissions: {},
  fetchingPSP: {
    isFetchingDefaultGroups: false,
    isFetchingOtherGroups: false,
    isFetchingPersonalUsers: false,
    isFetchingUsersInGroup: false,
    isFetchingSelectedActor: false,
  },
  permsPSP: {
    defaultGroups: [],
    otherGroups: [],
    personalUsers: [],
    usersInGroup: [],
    selectedActor: {},
  },
  totalPSP: {
    defaultGroupsTotal: 0,
    otherGroupsTotal: 0,
    personalUsersTotal: 0,
    usersInGroupTotal: 0,
  }
};

const permissionsSlice = createSlice({
  name: 'permissionsSlice',
  initialState,
  reducers: {
    // User perms (project)
    GET_USER_PERMS_FOR_ENTITY_REQUEST(state) {
      state.fetching = true;
    },
    GET_USER_PERMS_FOR_ENTITY_SUCCESS(state, action) {
      state.fetching = false;

      const { permissions = {} } = state;
      const { payload = {} } = action;

      payload?.forEach?.((perm) => {
        if (perm.entity_uuid) {
          permissions[perm.entity_uuid] = {
            ...omit(perm, 'entity_uuid'),
            time: dayjs(),
          };
        }
      });
      state.permissions = permissions;
    },
    GET_USER_PERMS_FOR_ENTITY_FAILURE(state) {
      state.fetching = false;
    },
    // PSP - PermissionsStartPage component
    RESET_ALL_PSP(state) {
      state.fetchingPSP = {
        isFetchingDefaultGroups: false,
        isFetchingOtherGroups: false,
        isFetchingPersonalUsers: false,
      }
      state.permsPSP = {
        defaultGroups: [],
        otherGroups: [],
        personalUsers: [],
      }
      state.totalPSP = {
        defaultGroupsTotal: 0,
        otherGroupsTotal: 0,
        personalUsersTotal: 0,
      }
    },
    /////////////////////////////////////////////////////////////////////////////////
    GET_DEFAULT_GROUPS_PERMS_FOR_PSP_REQUEST(state) {
      state.fetchingPSP.isFetchingDefaultGroups = true;
    },
    GET_DEFAULT_GROUPS_PERMS_FOR_PSP_SUCCESS(state, action) {
      state.fetchingPSP.isFetchingDefaultGroups = false;

      state.permsPSP.defaultGroups = concat(state.permsPSP.defaultGroups, action.payload.data);
      state.totalPSP.defaultGroupsTotal = action.payload.total;
    },
    CLEAR_AND_GET_DEFAULT_GROUPS_PERMS_FOR_PSP_SUCCESS(state, action) {
      state.fetchingPSP.isFetchingDefaultGroups = false;

      state.permsPSP.defaultGroups = action.payload.data;
      state.totalPSP.defaultGroupsTotal = action.payload.total;
    },
    GET_DEFAULT_GROUPS_PERMS_FOR_PSP_FAILURE(state) {
      state.fetchingPSP.isFetchingDefaultGroups = false;
    },
    /////////////////////////////////////////////////////////////////////////////////
    GET_OTHER_GROUPS_PERMS_FOR_PSP_REQUEST(state) {
      state.fetchingPSP.isFetchingOtherGroups = true;
    },
    GET_OTHER_GROUPS_PERMS_FOR_PSP_SUCCESS(state, action) {
      state.fetchingPSP.isFetchingOtherGroups = false;

      state.permsPSP.otherGroups = concat(state.permsPSP.otherGroups, action.payload.data);
      state.totalPSP.otherGroupsTotal = action.payload.total;
    },
    CLEAR_AND_GET_OTHER_GROUPS_PERMS_FOR_PSP_SUCCESS(state, action) {
      state.fetchingPSP.isFetchingOtherGroups = false;

      state.permsPSP.otherGroups = action.payload.data;
      state.totalPSP.otherGroupsTotal = action.payload.total;
    },
    GET_OTHER_GROUPS_PERMS_FOR_PSP_FAILURE(state) {
      state.fetchingPSP.isFetchingOtherGroups = false;
    },
    /////////////////////////////////////////////////////////////////////////////////
    GET_PERSONAL_USER_PERMS_FOR_PSP_REQUEST(state) {
      state.fetchingPSP.isFetchingPersonalUsers = true;
    },
    GET_PERSONAL_USER_PERMS_FOR_PSP_SUCCESS(state, action) {
      state.fetchingPSP.isFetchingPersonalUsers = false;

      state.permsPSP.personalUsers = concat(state.permsPSP.personalUsers, action.payload.data);
      state.totalPSP.personalUsersTotal = action.payload.total;
    },
    CLEAR_AND_GET_PERSONAL_USER_PERMS_FOR_PSP_SUCCESS(state, action) {
      state.fetchingPSP.isFetchingPersonalUsers = false;

      state.permsPSP.personalUsers = action.payload.data;
      state.totalPSP.personalUsersTotal = action.payload.total;
    },
    GET_PERSONAL_USER_PERMS_FOR_PSP_FAILURE(state) {
      state.fetchingPSP.isFetchingPersonalUsers = false;
    },
    /////////////////////////////////////////////////////////////////////////////////
    GET_USER_PERMS_IN_GROUP_FOR_PSP_REQUEST(state) {
      state.fetchingPSP.isFetchingUsersInGroup = true;
    },
    GET_USER_PERMS_IN_GROUP_FOR_PSP_SUCCESS(state, action) {
      state.fetchingPSP.isFetchingUsersInGroup = false;

      state.permsPSP.usersInGroup = concat(state.permsPSP.usersInGroup, action.payload.data.map(el => ({ ...el, disabled: true })));
      state.totalPSP.usersInGroupTotal = action.payload.total;
    },
    CLEAR_AND_GET_USER_PERMS_IN_GROUP_FOR_PSP_SUCCESS(state, action) {
      state.fetchingPSP.isFetchingUsersInGroup = false;

      state.permsPSP.usersInGroup = action.payload.data.map(el => ({ ...el, disabled: true }));
      state.totalPSP.usersInGroupTotal = action.payload.total;
    },
    GET_USER_PERMS_IN_GROUP_FOR_PSP_FAILURE(state) {
      state.fetchingPSP.isFetchingUsersInGroup = false;
    },
    /////////////////////////////////////////////////////////////////////////////////
    GET_SELECTED_ACTOR_PERM_FOR_PSP_REQUEST(state) {
      state.fetchingPSP.isFetchingSelectedActor = true;
    },
    GET_SELECTED_ACTOR_PERM_FOR_PSP_SUCCESS(state, action) {
      const actorPerm = head(action.payload.data);

      state.fetchingPSP.isFetchingSelectedActor = false;
      state.permsPSP.selectedActor = actorPerm;

      if (['DEFAULT', 'BAN', 'ADMIN'].includes(actorPerm?.uinfo?.group_name) && actorPerm?.actor_type === 'group') {
        state.permsPSP.defaultGroups = state.permsPSP.defaultGroups.map(el => {
          if (el?.actor_uuid === actorPerm?.actor_uuid) {
            return actorPerm;
          }
          return el;
        })
      } else if (actorPerm?.actor_type === 'group') {
        state.permsPSP.otherGroups = state.permsPSP.otherGroups.map(el => {
          if (el?.actor_uuid === actorPerm?.actor_uuid) {
            return actorPerm;
          }
          return el;
        })
      } else if (['classic_user', 'user'].includes(actorPerm?.actor_type)) {
        state.permsPSP.personalUsers = state.permsPSP.personalUsers.map(el => {
          if (el?.actor_uuid === actorPerm?.actor_uuid) {
            return actorPerm;
          }
          return el;
        })
      }
    },
    GET_SELECTED_ACTOR_PERM_FOR_PSP_FAILURE(state) {
      state.fetchingPSP.isFetchingSelectedActor = false;
    },
  },
});

export const {
  GET_USER_PERMS_FOR_ENTITY_REQUEST,
  GET_USER_PERMS_FOR_ENTITY_SUCCESS,
  GET_USER_PERMS_FOR_ENTITY_FAILURE,

  RESET_ALL_PSP,
  GET_DEFAULT_GROUPS_PERMS_FOR_PSP_REQUEST,
  GET_DEFAULT_GROUPS_PERMS_FOR_PSP_SUCCESS,
  CLEAR_AND_GET_DEFAULT_GROUPS_PERMS_FOR_PSP_SUCCESS,
  GET_DEFAULT_GROUPS_PERMS_FOR_PSP_FAILURE,
  GET_OTHER_GROUPS_PERMS_FOR_PSP_REQUEST,
  GET_OTHER_GROUPS_PERMS_FOR_PSP_SUCCESS,
  CLEAR_AND_GET_OTHER_GROUPS_PERMS_FOR_PSP_SUCCESS,
  GET_OTHER_GROUPS_PERMS_FOR_PSP_FAILURE,
  GET_PERSONAL_USER_PERMS_FOR_PSP_REQUEST,
  GET_PERSONAL_USER_PERMS_FOR_PSP_SUCCESS,
  CLEAR_AND_GET_PERSONAL_USER_PERMS_FOR_PSP_SUCCESS,
  GET_PERSONAL_USER_PERMS_FOR_PSP_FAILURE,
  GET_USER_PERMS_IN_GROUP_FOR_PSP_REQUEST,
  GET_USER_PERMS_IN_GROUP_FOR_PSP_SUCCESS,
  CLEAR_AND_GET_USER_PERMS_IN_GROUP_FOR_PSP_SUCCESS,
  GET_USER_PERMS_IN_GROUP_FOR_PSP_FAILURE,
  GET_SELECTED_ACTOR_PERM_FOR_PSP_REQUEST,
  GET_SELECTED_ACTOR_PERM_FOR_PSP_SUCCESS,
  GET_SELECTED_ACTOR_PERM_FOR_PSP_FAILURE,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;

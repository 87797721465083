import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CommentsStartPage from '../comment/CommentsStartPage/CommentsStartPage.js';

import { lastUuidForComments } from '../../selectors/selectors';

function TestCaseComments({
  parentUUID,
  setClickComment,
  partitionType,
  testCaseUUID,
  typeOfParent,
}) {
  const lastUuidForCommentsFromStore = useSelector(lastUuidForComments);

  useEffect(() => {
    if (testCaseUUID !== lastUuidForCommentsFromStore) {
      setClickComment(false);
    }
  }, [testCaseUUID, lastUuidForCommentsFromStore]);

  return (
    <CommentsStartPage
      partitionType={partitionType}
      parentUUID={parentUUID || testCaseUUID}
      type={typeOfParent}
    />
  );
}
TestCaseComments.propTypes = {
  parentUUID: PropTypes.string,
  partitionType: PropTypes.string,
  setClickComment: PropTypes.func,
  testCaseUUID: PropTypes.string,
  typeOfParent: PropTypes.string,
};

export default TestCaseComments;

import PropTypes, { func } from 'prop-types';
import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { Card, Col, Row } from 'antd';

import { mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import CollapseComponent from '../../components/CollapseComponent/CollapseComponent';

import { MainCollapseSectionContext } from '../../context/ProjectFlowListOfContexts';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";

function MainCollapseSection({
  titleCollapseBtn,
  classNameBtn,
  defaultCollapseState = true,
  defaultHorizontalMainCollapse = false,
  firstComponent,
  id = 'mainCollapse',
  secondComponent,
  splitSection = false,
  changeQAHistory,
  thirdComponent,
  typeCard,
  fourthComponent,
}) {
  const [verticalCollapseFlagSuite, changeCollapseFlag] = useState(defaultCollapseState);
  const [
    horizontalMainCollapse,
    changeHorizontalMainCollapse,
  ] = useState(defaultHorizontalMainCollapse);

  const toggleMainSectionCollapse = useCallback(() => changeCollapseFlag((prev) => !prev), []);

  const changeMainCollapseFromOuterComponent = useCallback((name, value) => {
    if (name === id) {
      changeCollapseFlag(value);
    }
  }, [id]);

  const getCurrentHorizontalCollapseState = useCallback((param) => (
    param === id ? horizontalMainCollapse : null), [id, horizontalMainCollapse]);

  const toggleHorizontal = useCallback(() => changeHorizontalMainCollapse((prev) => !prev), []);

  const toggleReset = useCallback(() => {
    changeHorizontalMainCollapse(false);

    const labelToClear = {
      mainSuiteCollapse: 'clearSuite',
      mainCycleCollapse: 'clearCycle',
      mainArchiveCollapse: 'clearCycleArchive',
    };

    changeQAHistory({ entity: labelToClear[id], clearCases: true });
  }, [id]);

  const value = useMemo(() => ({
    toggleReset,
    toggleHorizontal,
    getCurrentHorizontalCollapseState,
    changeMainCollapseFromOuterComponent,
  }), [
    getCurrentHorizontalCollapseState,
    changeMainCollapseFromOuterComponent,
    toggleReset,
    toggleHorizontal,
  ]);

  useEffect(() => {
    if (!splitSection) {
      changeHorizontalMainCollapse(false);
    }
  }, [splitSection]);

  return (
    <div className="w-full relative">
      <CollapseComponent
        hideButton
        handleChangeCollapse={verticalCollapseFlagSuite}
        handleFuncCollapse={toggleMainSectionCollapse}
      >
        <MainCollapseSectionContext.Provider
          value={value}
        >
          <Row gutter={16}>
            <Col
              span={24}
              xl={splitSection ? horizontalMainCollapse ? 6 : 12 : 24}
              xxl={splitSection ? horizontalMainCollapse ? 4 : 12 : 24}
            >
              <BaseCard className={`borderR10 ${typeCard} w-full h-full p-3`}>
                {firstComponent}
              </BaseCard>
            </Col>

            {splitSection
                  && (
                    <Col
                      span={24}
                      xl={horizontalMainCollapse ? 18 : 12}
                      xxl={horizontalMainCollapse ? 20 : 12}
                    >
                      <div className="flex flex-col grow h-full">
                        <BaseCard className={`borderR10 ${typeCard} w-full p-3 mb-3`}>
                          {secondComponent}
                        </BaseCard>

                        {fourthComponent && 
                          <div className='w-[104%] right-[2%] relative'>
                            {fourthComponent}
                          </div>
                        }

                        <BaseCard className={`borderR10 ${typeCard} w-full h-full p-3`}>
                          {thirdComponent}
                        </BaseCard>
                      </div>
                    </Col>
                  )}
          </Row>
        </MainCollapseSectionContext.Provider>
      </CollapseComponent>

      {/* <div
        onClick={toggleMainSectionCollapse}
        className={`wrapper_horizontal_collapse_btn ${typeCard}_style ${verticalCollapseFlagSuite && 'absolute'}`}
      >
        <BaseButton
          className={`${verticalCollapseFlagSuite ? classNameBtn : classNameBtn.replace('outline', 'link')} square`}
          size="small"
        >
          {!verticalCollapseFlagSuite && titleCollapseBtn}
          <Icon
            path={mdiChevronUp}
            size={1.2}
            rotate={!verticalCollapseFlagSuite ? 540 : 0}
          />
        </BaseButton>

      </div> */}
    </div>
  );
}

export default MainCollapseSection;

MainCollapseSection.propTypes = {
  defaultCollapseState: PropTypes.bool,
  defaultHorizontalMainCollapse: PropTypes.bool,
  firstComponent: PropTypes.element,
  id: PropTypes.string,
  secondComponent: PropTypes.element,
  thirdComponent: PropTypes.element,
  splitSection: PropTypes.bool,
  changeQAHistory: func,
  classNameBtn: PropTypes.string,
  typeCard: PropTypes.string,
  titleCollapseBtn: PropTypes.string,
};

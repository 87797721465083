import { Card } from 'antd';
import { get } from 'lodash';
import { propOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import { capitalize } from 'lodash';
import useURLParams from '../../../hooks/useURLParams';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";

export default function CookBookTemplateList({
  title,
  fetching,
  listData = [],
  partition,
  setSelectedCookbook,
  totalCount,
  lestConfigIssue,
  setNewConfigCallback,
}) {
  const { t } = useTranslation();

  const { getURLParams, setSearchParams } = useURLParams();

  const {
    activeCookBook,
  } = getURLParams();

  const [pageLimit, setPageLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      dataIndex: 'label',
      title: capitalize(t('wms.table.headers.title', 'Title')),
    },
    {
      dataIndex: 'entityType',
      title: capitalize(t('wms.table.headers.type', 'type')),
      render: (cell) => (
        <EntityParamTag
          value={cell}
          type="badge"
          param="entity"
        />
      ),
    },
  ];

  const selectRow = {
    mode: 'radio',
    clickToSelect: false,
    hideSelectColumn: true,
    selected: [activeCookBook],
    style: { background: '#e5f4fc' },
  };

  const rowClassName = (row) => {
    const data = `universal_table_row ${get(row, 'uuid') === activeCookBook
      ? 'selected'
      : ''
      }`;

    return data;
  };

  const onRow = (row) => ({
    onClick: () => {
      setSearchParams({
        activeCookBook: propOr('', 'uuid', row),
      });
    },
  });

  const onPageChange = ({ pageLimit: pl, currentPage: cp, offset }) => {
    const config = {
      ...lestConfigIssue,
      limit: pl,
      offset,
    };
    setNewConfigCallback(partition, config);

    setCurrentPage(cp);
    setPageLimit(pl);
  };

  const remotePagination = {
    sizePerPageList: [5, 10, 25, 50],
    defaultPageLimit: 5,
    totalSize: totalCount,
    page: currentPage,
    onChange: onPageChange,
  };

  useEffect(() => {
    if (activeCookBook) {
      const cookBook = listData.find((item) => item.uuid === activeCookBook);
      if (cookBook) {
        setSelectedCookbook(cookBook, partition);
      }
    }
  }, [activeCookBook, JSON.stringify(listData)]);

  return (
    <BaseCard className="primaryCard mb-3">
      <h5>
        {title}
      </h5>
      <div className="mt-3">
        <BaseTableWithPagination
          pageLimit={pageLimit}
          changePageLimit={setPageLimit}
          useCustomPagination
          onRow={onRow}
          columns={columns}
          data={listData}
          rowSelection={selectRow}
          loading={fetching}
          total={get(remotePagination, 'totalSize')}
          pageSizeOptions={get(remotePagination, 'sizePerPageList')}
          disablePagination={get(remotePagination, 'totalSize') <= get(remotePagination, 'defaultPageLimit')}
          newCurrentPage={get(remotePagination, 'page')}
          defaultPageLimit={get(remotePagination, 'defaultPageLimit')}
          getPaginationOptions={get(remotePagination, 'onChange')}
          rowClassName={rowClassName}
          paginationSize="small"
          headerRowClassName="universal_header_table_row"
        />
      </div>
    </BaseCard>
  );
}

import { mdiWindowClose } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Col, Flex, Input, message, Modal,
} from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import { entityCreate, incrementAndGetCount } from '../../../entity/actions/entityActions';
import { capitalize } from 'lodash';
import { createParamsUsersFromUuids } from '../../../MainUtils';
import { sendSystemComment, getCommentsAndUpdateStore } from '../../actions/projectFlowActions';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { getProjectDocumentUUID, getProjectParams, getUserUuid } from '../../selectors/selectors';
import '../style/QuickCreateIssueModal.scss';
import useURLParams from '../../../hooks/useURLParams';
import AssigningUsersRoot from '../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { partitionNamesConfig } from '../../../api/appConfig';

const { TextArea } = Input;

export default function ModalsCreateIssues({
  entityUUID,
  showModal,
  handleCancelCallback,
  customTitles = [],
  customDescriptionsArr=[],
  customDescription = '',
  commentType,
  partitionType,
  parent,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userUUID = useSelector(getUserUuid);

  const projectParams = useSelector(getProjectParams);

  const { getURLParams } = useURLParams();

  const [issues, setIssues] = useState(['new Issue']);
  const handleCancel = () => handleCancelCallback(false);

  const [isAssignOpen, setIsAssignOpen] = useState(false);
  const [isIssuesCreating, setIsIssuesCresting] = useState(false);

  const {
    goal,
    milestone,
    activeDoc,
    activeNote,
    activeSheet,
  } = getURLParams();

  const onChangeTitle = (e, id) => {
    setIssues((state) => state.map((item, i) => {
      if (i === id) {
        return { ...item, title: e.target.value };
      }
      return item;
    }));
  };

  const onChangeDescription = (e, id) => {
    setIssues((state) => state.map((item, i) => {
      if (i === id) {
        return { ...item, description: e.target.value };
      }
      return item;
    }));
  };

  const saveSelectedActors = (actors = [], id) => {
    setIssues((state) => state.map((item, i) => {
      if (i === id) {
        return {
          ...item,
          users: actors?.map((el) => ({ uuid: el?.uuid })),
          usersSearch: actors,
        };
      }
      return item;
    }));
  };

  const createIssue = (data) => {
    const constants = [
      ProjectTypeCommonConstants.NEW_ISSUE_REQUEST,
      ProjectTypeCommonConstants.NEW_ISSUE_SUCCESS,
      ProjectTypeCommonConstants.NEW_ISSUE_FAILURE,
    ];

    const options = {
      partition: partitionType,
    };

    return dispatch(entityCreate({
      data,
      constants,
      options,
    }));
  };

  const createComment = async (issuesLinkAndTitle) => {
    const comment = {
      entity: 'issue',
      action: 'created_bulk_issues',
      value: issuesLinkAndTitle,
    };
    const parentUuid = goal || milestone || activeDoc || activeNote || activeSheet || entityUUID;
    await dispatch(sendSystemComment(
      parentUuid,
      comment,
      partitionType,
    ));

    if (commentType) {
      dispatch(getCommentsAndUpdateStore({
        commentType,
        parent: parentUuid,
        partition: partitionType,
      }));
    }
  };

  const submitData = async () => {
    setIsIssuesCresting(true);

    if (isIssuesCreating) return;
    // message.loading({
    //   content: 'Action in progress..',
    //   className: 'text-right',
    //   prefixCls: 'z-10000 ant-message',
    //   duration: 0,
    // });
    const issuesLinkAndTitle = [];

    if (parent) {       
      for (const item of issues) {        

        const countIssue = await dispatch(incrementAndGetCount({
          entity_uuid: parent,
          field_name: 'issue',
        }, partitionType));

        const data = {
          entity_type: 'issue',
          parent,
          params: {
            id: `T${get(countIssue, 'increment')}`,
            project: parent,
            users: get(item, 'users', []),
            usersSearch: get(item, 'usersSearch', []),
            description: get(item, 'description', ''),
            title: get(item, 'title', 'New Issue'),
            lifeTime: moment().add(2, 'week').format('YYYY-MM-DD'),
            tracker: 'ticket',
            priority: 'low',
            status: 'created',
            estimated_time: 'easy',
          },
        };

        const newIssue = await createIssue(data);

        // console.log('newIssue', newIssue);

        issuesLinkAndTitle.push({
          link: partitionType === partitionNamesConfig.partition1
            ? `/pm/projects/issuesnew?activeProject=${parent}&activeIssue=${get(newIssue, '[0].uuid')}`
            : `/public/user/${userUUID}/projects/issuesnew?activeProject=${parent}&activeIssue=${get(newIssue, '[0].uuid')}`,
          title: get(newIssue, '[0].params.title'),
        });
      }

      createComment(issuesLinkAndTitle);
      // message.destroy();
      message.success({
        content: 'New issue has been successfully added',
        className: 'text-right',
        prefixCls: 'z-10000 ant-message',
        duration: 1.5,
      });
      handleCancel();
    }
    setIsIssuesCresting(false);
  };

  const deleteIssue = (key) => setIssues((state) => state.filter((item) => get(item, 'customKey') !== key));

  useEffect(() => {
    const data = customTitles.map((item, i) => ({
      title: item || 'New Issue',
      users: [],
      usersSearch: [],
      description: `${customDescriptionsArr?.[i] ? customDescriptionsArr?.[i] + '\n' : ''}${customDescription || ''}`,
      customKey: `T${i}`,
    }));

    setIssues(data);
  }, [customTitles]);

  return (
    <Modal
      // title="Create Issues"
      width={800}
      open={showModal}
      className="custom-ant-modal"
      footer={null}
      onCancel={handleCancel}
    // zIndex={9998}
    >
      {showModal
        && (
          <Col span={24}>
            <div className="container_issues">
              {issues.map((data, id) => {  
                const deleteItem = () => deleteIssue(get(data, 'customKey'));
                return (
                  <div key={`${id}${get(data, 'customKey')}`}>
                    <div className="wrapper_issue">
                      <div className="mt-1 flex items-start w-full">
                        <div className="title_wrapper">
                          <div className="title_wrapper__name">{`${capitalize(t('wms.labels.title'))}:`}</div>
                          <Input
                            maxLength={128}
                            showCount
                            placeholder="Title"
                            value={get(data, 'title')}
                            onChange={(e) => onChangeTitle(e, id)}
                          />
                        </div>

                        <div className="assigned_wrapper ml-1">
                          <div className="title_wrapper__name">{`${capitalize(t('wms.labels.assigned_users'))}:`}</div>
                          <div>
                            <Flex>
                              <AssigningUsersRoot
                                header="Assigning users"
                                isOpen={isAssignOpen}
                                setIsOpen={setIsAssignOpen}
                                onUpdateUsers={(dataUsers) => saveSelectedActors(
                                  dataUsers?.users?.map((el) => el?.uuid),
                                  id,
                                )}
                                globalOptions={{
                                  uuids: projectParams?.usersSearch ?? [],
                                  defaultColumn: 'project',
                                }}
                                needUpdateProjectUsers
                                placement="left"
                                isPopover
                                tabs={{
                                  allConfig: {
                                    label: 'All',
                                    value: 'all',
                                    textSelectButton: 'Add to project/issue',
                                    showSections: ['button-add-user'],
                                  },
                                  projectConfig: {
                                    label: 'Project',
                                    value: 'project',
                                    textSelectButton: 'Add to issue',
                                    defaultItems: projectParams?.users ?? [],
                                    showSections: ['string-role', 'button-add-user'],
                                  },
                                }}
                                usersConfig={{
                                  label: 'Issue users',
                                  showSections: ['string-role', 'button-remove-user'],
                                  textDeleteButton: 'Remove users',
                                  defaultItems: [...(get(data, 'usersSearch') ?? [])],
                                }}
                                popoverText="Assigned users"
                                projectUuid={parent}
                                partition={partitionType}
                              />

                              <WrapperAvatarsGroupForProjectWithHook
                                actors={get(data, 'usersSearch') || []}
                                avatarSize={28}
                                popoverTrigger="click"
                                popoverPlacement="right"
                                checkProjectRole
                              />
                            </Flex>
                          </div>
                        </div>

                      </div>

                      <div className="description_wrapper">
                        <div className="title_wrapper__name">{`${capitalize(t('wms.labels.description'))}:`}</div>
                        <TextArea
                          autoSize={{ minRows: 2 }}
                          placeholder="Description"
                          value={get(data, 'description')}
                          onChange={(e) => onChangeDescription(e, id)}
                        />
                      </div>

                      {issues.length > 1 && (
                        <div
                          className="delete_issue_button"
                          onClick={deleteItem}
                          role="button"
                          tabIndex="0"
                        >
                          <Icon
                            path={mdiWindowClose}
                            size={0.8}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <hr /> */}
            <div className="w-full flex justify-end mt-2">
              <BaseButton
                onClick={submitData}
                className="btnPrimary"
                size="small"
                loading={isIssuesCreating}
                disabled={isEmpty(issues)}
              >
                {capitalize(t('wms.buttons.submit', 'submit'))}
              </BaseButton>
            </div>
          </Col>
        )}
    </Modal>
  );
}

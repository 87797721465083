import { ProjectTypeCommonConstants } from '../constants/Constants';
import { getListAndPartialReadEntities, getOrCreateSingle } from '../../entity/actions/entityActions';

export const getAreasRequest = (parent) => {
  const config = {
    data: {
      entity_type: 'area',
      parent,
      params_fields: {
        name: 'name',
        description: 'description',
        priority: 'priority',
      },
      fields: { 'actor.created': 'created' },
    },
    constants: [
      ProjectTypeCommonConstants.GET_AREAS_REQUEST,
      ProjectTypeCommonConstants.GET_AREAS_SUCCESS,
      ProjectTypeCommonConstants.GET_AREAS_FAILURE,
    ],
  };
  return (dispatch) => dispatch(getListAndPartialReadEntities(config));
};

export const getMainEntityAreaRequest = (parent) => {
  const config = {
    data: {
      entity_type: 'mainAreaEntity',
      parent,
    },
    doNotCheckError: true,
    constants: [
      ProjectTypeCommonConstants.GET_MAIN_AREA_REQUEST,
      ProjectTypeCommonConstants.GET_MAIN_AREA_SUCCESS,
      ProjectTypeCommonConstants.GET_MAIN_AREA_FAILURE,
    ],
  };

  return getOrCreateSingle(config);
};

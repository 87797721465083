import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Spin, Tooltip } from 'antd';
import { transformActorsToSelect } from '../../selectors/selectors';
import { checkEntityPerm, getPermissionsOfEntity } from '../../actions/entityPermActions';
import BaseSelect from '../../../components/_ui/BaseSelect/BaseSelect';

import './ActorsFromPermsSelectComponent.scss';
import { capitalize } from 'lodash';
import { partitionNamesConfig } from '../../../api/appConfig';

function ActorsFromPermsSelectComponent({
  id = 'projectAdmins',
  setStartAdmins,
  permissions = ['user', 'classic_user', 'phantom'],
  customInputLabel,
  customSelectedActors = [],
  customSelectedActorsReset,
  // fetching = false,
  onChange,
  hideLabel = false,
  hideSelected = true,
  // isDisabled = false,
  labelClassName = '',
  partitionType = partitionNamesConfig.partition1,
  entityUUID = '',
  wrapperClassName = 'w-full new-project-members-field',
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [options, changeOptions] = useState([]);
  const [modifiedValue, setModifiedValue] = useState([]);
  const [displaySection, changeDisplayFlag] = useState(null);

  const [loading, setLoading] = useState(true);

  const getEntityPerms = (params) => {
    const data = {
      entity_uuid: entityUUID,
      actor_type: ['user', 'classic_user'],
      ...params,
    };

    return dispatch(
      getPermissionsOfEntity({
        partition: partitionType,
        data,
      }),
    );
  };

  const initFunc = async () => {
    const inversionOfPermissions = Object.fromEntries(
      Object.entries(permissions).map(([key, value]) => [key, !value]),
    );
    let actorsWithPerms;
    let actorsWithoutPerms;
    try {
      // Пересмотреть компонент, проверка пермов 2 раза по 300 объектов в ответе
      actorsWithPerms = await getEntityPerms({ perms: permissions });
      actorsWithoutPerms = await getEntityPerms({ perms: inversionOfPermissions });
    } catch (error) {
      console.log(error);
    }

    setStartAdmins?.(transformActorsToSelect(actorsWithPerms).map((el) => el.value));

    changeOptions([
      ...transformActorsToSelect(actorsWithPerms),
      ...transformActorsToSelect(actorsWithoutPerms),
    ]);
    setLoading(false);
  };

  const checkPerms = () => {
    const data = {
      entity_uuid: entityUUID,
      perm: ['set'],
      partition: partitionType,
    };

    dispatch(checkEntityPerm(data)).then((res) => {
      changeDisplayFlag(res);
    });
  };

  useEffect(() => {
    if (entityUUID) {
      checkPerms();
    }
  }, []);

  useEffect(() => {
    if (entityUUID && displaySection !== null && displaySection) {
      if (!loading) {
        setLoading(true);
      }
      initFunc();
    }

    if (displaySection !== null && !displaySection && loading) {
      setLoading(false);
    }
  }, [
    customSelectedActorsReset,
    displaySection,
  ]);

  useEffect(() => {
    if (displaySection !== null && displaySection) {
      setModifiedValue(customSelectedActors
        .map((e) => ({ value: e, label: options.find((el) => el.value === e)?.label })));
    }
  }, [customSelectedActors, options, displaySection]);

  return (
    <div id={id} className={wrapperClassName}>
      {!hideLabel
        && (
          <label className={` m-0 ${labelClassName}`}>
            {customInputLabel || capitalize(t('wms.labels.users', 'Users'))}
          </label>
        )}
      <Spin spinning={loading}>
        <Tooltip title={!displaySection ? 'You do not have access to view this information' : ''}>
          <div>
            <BaseSelect
              optionFilterProp="label"
              getPopupContainer={() => document.getElementById(id)}
              optionLabelProp="label"
              autoClearSearchValue
              allowClear
              hideSelected={hideSelected}
              options={options}
              showArrow
              closeMenuOnSelect={false}
              value={modifiedValue}
              placeholder={capitalize(t('wms.placeholders.admins', 'Admins'))}
              disabled={!displaySection}
              onChange={onChange}
              mode="multiple"
              classNamePrefix="react-select"
              className="react-select w-full"
            />
          </div>
        </Tooltip>
      </Spin>
    </div>
  );
}

export default ActorsFromPermsSelectComponent;

ActorsFromPermsSelectComponent.propTypes = {
  setStartAdmins: PropTypes.func,
  permissions: PropTypes.array,
  id: PropTypes.string,
  labelClassName: PropTypes.string,
  entityUUID: PropTypes.string,
  partitionType: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

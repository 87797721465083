import { ProjectTypeCommonConstants } from '../../constants/Constants';

const initialState = {
  listTestCycleFetching: false,
  listTestCycleArchive: [],
  oneTestCycleArchive: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_IN_ARCHIVE_REQUEST:
      return {
        ...state,
        listTestCycleFetching: true,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_IN_ARCHIVE_SUCCESS:
      return {
        ...state,
        listTestCycleArchive: payload,
        listTestCycleFetching: false,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_IN_ARCHIVE_FAILURE:
      return {
        ...state,
        listTestCycleArchive: [],
        listTestCycleFetching: false,
      };

    case ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_ARCHIVE_IN_STORE:
      return {
        ...state,
        oneTestCycleArchive: payload,
      };

    case ProjectTypeCommonConstants.GET_TEST_CYCLE_IN_ARCHIVE_REQUEST:
      return {
        ...state,
        fetchingOneTestCycleArchive: true,
      };
    case ProjectTypeCommonConstants.GET_TEST_CYCLE_IN_ARCHIVE_SUCCESS:
      return {
        ...state,
        fetchingOneTestCycleArchive: false,
        oneTestCycleArchive: payload,
      };
    case ProjectTypeCommonConstants.GET_TEST_CYCLE_IN_ARCHIVE_FAILURE:
      return {
        ...state,
        fetchingOneTestCycleArchive: false,
        oneTestCycleArchive: payload,
      };
    case ProjectTypeCommonConstants.CLEAR_ARCHIVED_TEST_CYCLE:
      return {
        ...state,
        fetchingOneTestCycleArchive: false,
        oneTestCycleArchive: [],
      };

    default: return state;
  }
};

import React, { useDispatch } from 'react-redux';
import moment from 'moment';
import { useState } from 'react';
import { o } from 'ramda';
import { getListOfIssues, updateProjectScoring } from '../../projectFlow/actions/projectFlowActions';
import { projectEntityScoring, notActiveStatusesArr } from '../../projectFlow/components/issuesView/utils';
import { ProjectTypeCommonConstants } from '../../projectFlow/constants/Constants';
import { setFetchingScoringData, setProjectsIssuesScoringData } from '../../projectFlow/reducers/slicers/projectIssueScoringSlicer';

export default function useDevScore() {
  const dispatch = useDispatch();

  const devTitle = 'Dev';

  const defaultDataObj = {
    sum: 0, avgVal: 0, total: 0, background: 'default',
  };

  const updateProject = async (props) => {
    const {
      projectUuid, projectPartition, avgVal: issuesScoringAVG, sum: indexesSum, total: totalCount,
    } = props;
    const data = {
      uuid: projectUuid,
      params: {
        issuesDevScore: {
          updated: moment(),
          issuesScoringAVG: Number(issuesScoringAVG),
          indexesSum,
          totalCount,
        },
      },
      partition: projectPartition,
    };
    return dispatch(updateProjectScoring(data));
  };

  const getAllProjectsIssues = async (projectUuid, projectPartition) => {
    const config = {
      partition: projectPartition,
      parent: projectUuid,
      depth: 2,
      params: {
        status__not: notActiveStatusesArr,
      },
      constants: [
        ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_REQUEST,
        ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_SUCCESS,
        ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_FAILURE,
      ],
      fields: {
        modified: 'modified',
      },
      params_fields: {
        title: 'title',
        priority: 'priority',
        status: 'status',
      },
    };
    return dispatch(getListOfIssues(config));
  };

  const computeProjectDevScore = async (
    projectUuid,
    projectPartition,
    isNeedProject,
    isAllScoring,
  ) => {
    try {
      const uuid = projectUuid;
      const issuesData = await getAllProjectsIssues(uuid, projectPartition);
      const check = Array.isArray(issuesData?.data) && !!issuesData?.total;
      const issuesScoring = check
        ? projectEntityScoring(issuesData.data, issuesData.total)
        : defaultDataObj;

      const finalData = {
        updated: moment(),
        indexesSum: issuesScoring?.sum,
        issuesScoringAVG: Number(issuesScoring?.avgVal),
        totalCount: issuesScoring?.total,
      };

      if (!isAllScoring) {
        const res = await updateProject({
          ...issuesScoring,
          projectUuid,
          projectPartition,
        });

        if (isNeedProject) {
          return res;
        }
      }

      return { uuid, ...finalData };
    } catch (e) {
      console.log('onComputeKPI error', e);
    }
  };

  const computeAllProjectsDevScore = async (projectsArr = [], isAllScoring) => {
    try {
      dispatch(setFetchingScoringData(true));
      const projectsDevScoreData = await Promise.all(projectsArr
        .map((project) => computeProjectDevScore(
          project?.uuid,
          project?.partition,
          false,
          isAllScoring,
        )));

      const finalProjectsData = projectsArr.map((item) => {
        const projectScoring = projectsDevScoreData
          .find((project) => project?.uuid === item?.uuid) || {
          issuesScoringAVG: 0,
          background: 'default',
        };
        return {
          ...item,
          issuesDevScore: projectScoring,
        };
      });
      dispatch(setProjectsIssuesScoringData(finalProjectsData));
      return finalProjectsData;
      // console.log('onComputeKPI projectsWithIssueScoringData', projectsWithIssueScoringData);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setFetchingScoringData(false));
    }
    // console.log('onComputeKPI allProjectsCopy', allProjectsCopy);
  };

  const handleDevBackground = (score, isButtonColor) => {
    if (score >= 3) {
      return !isButtonColor ? 'error' : 'scoringBtnDanger';
    }
    if (score >= 2) {
      return !isButtonColor ? 'warning' : 'scoringBtnWarning';
    }
    if (score >= 1) {
      return !isButtonColor ? 'success' : 'scoringBtnGreen';
    }
    if (score >= 0 && score < 1) {
      return !isButtonColor ? 'error' : 'scoringBtnDanger';
    }
    return !isButtonColor ? '' : 'scoringBtnDefault';
  };

  const devTooltipText = (
    <>
      <div>
        <strong>
          Dev score (issues)
        </strong>
        {' '}
        - general scoring by project issues, which showing the
        status of the development process and the priority of active issues.
      </div>
      <div>
        <strong>Threshold values: </strong>
        it&apos;s recommended to pay attention if the value less than 1 or more than 2.
      </div>

    </>

  );

  return {
    devTitle,
    devTooltipText,
    computeAllProjectsDevScore,
    computeProjectDevScore,
    handleDevBackground,
  };
}

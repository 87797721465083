import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next/';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { prop } from 'ramda';
import { capitalize } from 'lodash';
import { getListOfVectors } from '../../../actions/projectFlowActions';
import { getProjectUUID } from '../../../selectors/selectors';
import BaseSelect from '../../../../components/_ui/BaseSelect/BaseSelect';

export default function ListOfVectorsDropDown({
  partition,
  selectVectorCallback,
  selectVectorDefault = [],
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectUUID = useSelector(getProjectUUID);

  const [option, setOption] = useState([]);
  // const [listTitleVector, setListTitleVector] = useState();
  const [selectedVector, setSelectedVector] = useState();

  const initFunc = async () => {
    const vectors = await dispatch(getListOfVectors({
      parent: projectUUID,
      params_fields: {
        title: 'title',
      },
      params: { status__not: 'protected' },
      partition,
    }));
    const data = prop('data', vectors).map((item) => ({
      value: item.uuid,
      label: item.title,
    }));

    setOption(data);
  };

  useEffect(() => {
    initFunc();
    // setListTitleVector(data);
  }, []);

  useEffect(() => {
    // if (selectVectorDefault.length !== 0) {
    setSelectedVector(Array.isArray(selectVectorDefault)
      ? selectVectorDefault
      : [selectVectorDefault]);
    // }
  }, [JSON.stringify(selectVectorDefault)]);

  const handleChangeVector = (item) => {
    // console.log('Changeitem', item);
    if (isNil(item)) {
      setSelectedVector([]);
      selectVectorCallback([]);
    } else {
      setSelectedVector([item]);
      selectVectorCallback([item]);
    }
  };
  // console.log('selectedVector', selectedVector);
  return (
    <>
      <label className="m-0">
        {`${capitalize(t('wms.noun.vector', { before: t('wms.verb.assign') }, 'assign vector'))}:`}
      </label>

      <BaseSelect
        optionFilterProp="label"
        // getPopupContainer={() => document.getElementById(id)}
        optionLabelProp="label"
        autoClearSearchValue
        allowClear
        hideSelected
        options={option}
        showArrow
        closeMenuOnSelect={false}
        value={selectedVector}
        placeholder={capitalize(t('wms.placeholders.vectors', 'vectors'))}
        onChange={handleChangeVector}
        // mode="multiple"
        classNamePrefix="react-select"
        className="react-select w-full"
      />

      {/* <Select
        className
        // isDisabled={option.length === 0}
        value={selectedVector}
        options={option}
        onChange={handleChangeVector}
        isClearable
        maxMenuHeight={220}
        noOptionsMessage={() => capitalize(t('wms.select.no_options', 'no options'))}
        placeholder={capitalize(t('wms.placeholders.vectors', 'vectors'))}
      /> */}
    </>
  );
}
ListOfVectorsDropDown.propTypes = {
  selectVectorCallback: PropTypes.func,
  selectVectorDefault: PropTypes.array,
};

import React from 'react';

import InfoTip from '../../components/infoTip/InfoTip';
import useLocalTranslateForComponent from '../../54origins/hooks/useLocalTranslateForComponent';

function ProjectFlowInfoTip() {
  const { currentLangIsEn } = useLocalTranslateForComponent();

  return (
    <InfoTip
      title={currentLangIsEn ? 'Project flow' : 'Проектный поток'}
      text={
        currentLangIsEn
          ? 'Project flow describes a preset sequence of activities required to plan, produce, deliver and maintain project product, along with information, materials, and resources required by the project.'
          : 'Проектный поток описывает заранее установленную последовательность действий, необходимых для планирования, создания, доставки и обслуживания продукта проекта, а также информацию, материалы и ресурсы, необходимые для проекта.'
      }
    />
  );
}

export default ProjectFlowInfoTip;

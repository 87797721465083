import { EntityConstants } from '../../entity/constants/Constants';
import { ConfigConstants } from '../constants/Constants';

const initialState = {
  fetching: true,
  fetched: false,
  type: '',
  uuid: null,
  data: '',
  error: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ConfigConstants.FETCH_PARTITION_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case ConfigConstants.FETCH_PARTITION_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        type: payload.type,
        uuid: payload.uuid,
        data: payload,
      };

    case ConfigConstants.FETCH_PARTITION_FAILURE:
      return {
        ...state,
        fetching: false,
        uuid: null,
        error: payload,
        data: '',
      };

    case EntityConstants.CLEAR_PARTITION:
      return {
        fetching: true,
        fetched: false,
        type: '',
        uuid: null,
        data: '',
        error: '',
      };

    default: return state;
  }
};

/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { createSelector } from 'reselect';

const initialState = {
  fetching: true,
  data: [],
  currnetYear: '',
  fetchingDeliveryUser: false,
  deliveryUser: null,
  fetchingMonthlyReportsStorage: false,
  monthlyReportsStorage: null,
};

const rootEntitiesDeliverySlice = createSlice({
  name: 'rootEntityDelivery',
  initialState,
  reducers: {
    GET_DELIVERY_ROOT_ENTITIES_REQUEST: (state) => {
      state.fetching = true;
    },
    GET_DELIVERY_ROOT_ENTITIES_SUCCESS: (state, action) => {
      state.fetching = false;
      state.data = action.payload;
    },
    GET_DELIVERY_ROOT_ENTITIES_FAILURE: (state) => {
      state.fetching = false;
      state.data = [];
    },
    GET_DELIVERY_YEAR_DAY_OFF_REQUEST: (state) => {
      state.fetching = true;
    },
    GET_DELIVERY_YEAR_DAY_OFF_SUCCESS: (state, action) => {
      state.fetching = false;
      state.currnetYear = action.payload;
    },
    GET_DELIVERY_YEAR_DAY_OFF_FAILURE: (state) => {
      state.fetching = false;
      state.currnetYear = '';
    },
    GET_ROOT_DELIVERY_USER_REQUEST: (state) => {
      state.fetchingDeliveryUser = true;
    },
    GET_ROOT_DELIVERY_USER_SUCCESS: (state, action) => {
      state.fetchingDeliveryUser = false;
      state.deliveryUser = action.payload;
    },
    GET_ROOT_DELIVERY_USER_FAILURE: (state) => {
      state.fetchingDeliveryUser = false;
      state.deliveryUser = null;
    },
    GET_MONTHLY_REPORTS_STORAGE_REQUEST: (state) => {
      state.fetchingMonthlyReportsStorage = true;
    },
    GET_MONTHLY_REPORTS_STORAGE_SUCCESS: (state, action) => {
      state.fetchingMonthlyReportsStorage = false;
      state.monthlyReportsStorage = action.payload;
    },
    GET_MONTHLY_REPORTS_STORAGE_FAILURE: (state) => {
      state.fetchingMonthlyReportsStorage = false;
      state.monthlyReportsStorage = null;
    },
  },
});

export const rootEntityDeliveryAction = rootEntitiesDeliverySlice.actions;

export default rootEntitiesDeliverySlice.reducer;

export const rootEntitiesDelivery = (state) => get(state, 'Delivery.rootEntitiesDelivery.data', []);

export const getCurrnetYearDayOff = (state) => get(state, 'Delivery.rootEntitiesDelivery.currnetYear', '');

export const getDeliveryUserUUID = (state) => get(state, 'Delivery.rootEntitiesDelivery.deliveryUser.uuid', null);

export const getMonthlyStorageUUID = (state) => get(state, 'Delivery.rootEntitiesDelivery.monthlyReportsStorage.uuid', null);

export const getDayOffStorageRoot = createSelector(
  rootEntitiesDelivery,
  (list) => list.find((i) => i?.entity_type === 'dayOffStorage'),
);

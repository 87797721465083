import { useDispatch, useSelector } from 'react-redux';

import axiosRequest from '../../api/apiAxios';
import { getMe } from '../../projectFlow/selectors/selectors';
import { gptUrl, sessionNameFor } from '../../api/appConfig';
import { removeCookie } from '../../54origins/cookies54origins';
import { DELETE_CHAT_FOR_USER_FAILURE, DELETE_CHAT_FOR_USER_REQUEST, DELETE_CHAT_FOR_USER_SUCCESS } from '../slices/chatGptSlice';

const useGptRequestsHook = () => {
  const dispatch = useDispatch();

  const actorUUID = useSelector(getMe);

  const optionsForGPTRequest = (resolve, reject) => ({
    onFailure: (err) => {
      if (err?.status === 403) {
        removeCookie(sessionNameFor.proxyGPT);
      }

      reject();
    },
    onSuccess: resolve,
    tokenName: sessionNameFor.proxyGPT,
    url: gptUrl,
    doNotCheckError: true
  });

  const requestGetUsersOnGPT = (data) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.get(
        'api/users',
        [
          'GET_GPT_LIST_OF_USERS_REQUEST',
          'GET_GPT_LIST_OF_USERS_SUCCESS',
          'GET_GPT_LIST_OF_USERS_FAILURE',
        ],
        optionsForGPTRequest(resolve, reject),
      ),
    );
  });

  const requestGetUserOnGPT = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.get(
        `api/users/${actorUUID}`,
        [
          'GET_GPT_USER_REQUEST',
          'GET_GPT_USER_SUCCESS',
          'GET_GPT_USER_FAILURE',
        ],
        optionsForGPTRequest(resolve, reject),
      ),
    );
  });

  const requestCreateUserOnGPT = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'api/users',
        [
          'CREATE_USER_REQUEST',
          'CREATE_USER_SUCCESS',
          'CREATE_USER_FAILURE',
        ],
        {},
        optionsForGPTRequest(resolve, reject),
      ),
    );
  });

  const requestGetListOfChatForUserOnGPT = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.get(
        `api/users/${actorUUID}/chats`,
        [
          'GET_LIST_OF_CHAT_FOR_USER_REQUEST',
          'GET_LIST_OF_CHAT_FOR_USER_SUCCESS',
          'GET_LIST_OF_CHAT_FOR_USER_FAILURE',
        ],
        optionsForGPTRequest(resolve, reject),
      ),
    );
  });

  const requestCreateNewChatForUserOnGPT = (title) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        `api/users/${actorUUID}/chats`,
        [
          'CREATE_CHAT_FOR_USER_REQUEST',
          'CREATE_CHAT_FOR_USER_SUCCESS',
          'CREATE_CHAT_FOR_USER_FAILURE',
        ],
        {
          chat_title: title,
          user_uuid: actorUUID,
        },
        optionsForGPTRequest(resolve, reject),
      ),
    );
  });

  const requestGetChatByIdOnGPT = ({
    id,
    start,
    end,
  }) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.get(
        `api/users/${actorUUID}/chats/${id}?${start ? `start=${start}` : ''}${end ? `&end=${end}` : ''}`,
        [
          'GET_CHAT_BY_ID_REQUEST',
          'GET_CHAT_BY_ID_SUCCESS',
          'GET_CHAT_BY_ID_FAILURE',
        ],
        optionsForGPTRequest(resolve, reject),
      ),
    );
  });

  const requestDeleteChatForUserOnGPT = (userUUID, chatID) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.delete(
        `api/users/${userUUID}/chats/${chatID}`,
        [
          DELETE_CHAT_FOR_USER_REQUEST,
          DELETE_CHAT_FOR_USER_SUCCESS,
          DELETE_CHAT_FOR_USER_FAILURE,
        ],
        optionsForGPTRequest(resolve, reject),
      ),
    );
  });

  const requestSendActionForUserChatOnGPT = (userUUID, chatID, actionName, title) => {
    const newFormData = new FormData();
    newFormData.append('action', actionName);

    if (title) {
      newFormData.append('title', title);
    }

    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.patch(
          `api/users/${userUUID}/chats/${chatID}`,
          [
            'CLEAR_CHAT_CONTEXT_FOR_USER_REQUEST',
            'CLEAR_CHAT_CONTEXT_FOR_USER_SUCCESS',
            'CLEAR_CHAT_CONTEXT_FOR_USER_FAILURE',
          ],
          newFormData,
          optionsForGPTRequest(resolve, reject),
        ),
      );
    });
  };

  return ({
    requestGetUsersOnGPT,
    requestGetUserOnGPT,
    requestCreateUserOnGPT,
    requestGetListOfChatForUserOnGPT,
    requestCreateNewChatForUserOnGPT,
    requestGetChatByIdOnGPT,
    requestDeleteChatForUserOnGPT,
    requestSendActionForUserChatOnGPT,
  });
};

export default useGptRequestsHook;

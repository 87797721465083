import { pathOr } from 'ramda';
import React from 'react';

export default function RenderTimeLog({ item }) {
  return (
    <div>
      { pathOr('', ['params', 'description'], item) && (
      <div className="flex items-start">
        <div className="description_comment_text comment-text">description:</div>
        <p className="comment-text ml-1">
          {pathOr('', ['params', 'description'], item)}
        </p>
      </div>
      )}

      <div className="flex items-center">
        <span className="comment-text">time:</span>
        <p className="comment-text ml-1">
          {pathOr('', ['params', 'time'], item) || pathOr('', ['params', 'trackerTime'], item)}
        </p>
      </div>
    </div>

  );
}

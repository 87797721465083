import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  issues: [],
  fetching: true,
};

const dragTasksSlicer = createSlice({
  name: 'dragTasksSlicer',
  initialState,
  reducers: {
    setTasksFromVersion: (state, action) => {
      state.issues = action.payload;
      state.fetching = false;
    },
    clearTasksFromVersion: (state) => {
      state.issues = [];
      state.fetching = true;
    },
    setFetchingForDragTasks: (state, action) => {
      state.fetching = action.payload;
    }
  },
});

export const {
  setTasksFromVersion,
  clearTasksFromVersion,
  setFetchingForDragTasks,
} = dragTasksSlicer.actions;

export default dragTasksSlicer.reducer;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Col, Row, Table, Tag, Tooltip,
} from 'antd';
import { mdiGhostOutline } from '@mdi/js';
import Icon from '@mdi/react';

import MasqueradingBtn54origins from '../../54origins/components/MasqueradingBtn54origins';
import AntDesignSearchBox54origins from '../../54origins/components/AntDesignSearchBox54origins';

import {
  getNameForActor, actorIsRoot, actorIsRootOrAdmin,
} from '../../54origins/utils54origins';
import { getSessionTokenFor } from '../../api/appConfig';
import useURLParams from '../../hooks/useURLParams';
import { entityBackendURL } from '../../api/api';
import BaseTableWithPagination from '../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import BaseTag from "../../components/_ui/BaseTag/BaseTag";

function ListOfMasqueradingActors() {
  const { navigate } = useURLParams();

  const [actors, setActors] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState('');
  const [config, setConfig] = useState({
    limit: 10,
    offset: 0,
    not_banned: true,
    actor_type: ['user', 'classic_user'],
  });
  // const [state, setState] = useState({
  //   tableActors: [],
  //   loadingActors: false,
  //   searchValue: '',
  // });

  const { limit = 10 } = config;
  // const {
  //   tableActors,
  //   loadingActors,
  //   searchData,
  // } = state || {};

  const changePage = (page) => {
    setCurrentPage(page);
    setConfig((prev) => ({
      ...prev,
      offset: prev?.limit * (page - 1),
    }));
  };

  const resetUsers = () => {
    setActors([]);
    setCurrentPage(1);
  };

  // const changeState = (params) => {
  //   setState({
  //     ...state,
  //     ...params,
  //   });
  // };

  const changeLimit = (newSize) => {
    if (!newSize) return;
    setConfig((prev) => ({
      ...prev,
      limit: newSize,
      offset: 0,
    }));
    resetUsers();
  };

  const getAllActors = () => {
    axios({
      method: 'post',
      url: `${entityBackendURL()}/utility/actor/list`,
      headers: {
        'Content-Type': 'application/json',
        'Session-Token': getSessionTokenFor.entity(),
      },
      data: {
        actor_type: ['user', 'classic_user'],
        search_data: searchData,
        ...config,
      },
    }).then(({ data }) => {
      setActors(data?.data);
      setTotal(data?.total);
      setFetching(false);
      // changeState({
      //   tableActors: data?.data,
      //   loadingActors: false,
      // });
    })
      .catch(() => {
        navigate('/pm/projects');
      });
  };

  const getActors = (uuids) => {
    axios({
      method: 'post',
      url: `${entityBackendURL()}/utility/actor/get`,
      headers: {
        'Content-Type': 'application/json',
        'Session-Token': getSessionTokenFor.entity(),
      },
      data: {
        uuids,
        search_data: searchData,
        ...config,
      },
    }).then(({ data }) => {
      setActors(data?.data);
      setTotal(data?.total);
      setFetching(false);
      // changeState({
      //   tableActors: data?.data,
      //   loadingActors: false,
      // });
    })
      .catch(() => {
        navigate('/pm/projects');
      });
  };

  const getMasqueradingAllowed = () => {
    setFetching(true);
    // changeState({ loadingActors: true });

    axios({
      method: 'post',
      url: `${entityBackendURL()}/masquerade/get_actor_info/`,
      headers: {
        'Content-Type': 'application/json',
        'Session-Token': getSessionTokenFor.entity(),
      },
      data: {},
    }).then(({ data }) => {
      const { masquerade_uuids_list, root } = data || {};

      if (root) {
        getAllActors();
      } else {
        getActors(masquerade_uuids_list);
      }
    })
      .catch(() => {
        navigate('/pm/projects');
      });
  };

  const onSearchData = (value) => {
    setSearchData({
      value,
      ignore_case: true,
      fields: {
        uinfo: ['first_name', 'last_name'],
      },
    });
    // changeState({
    //   searchData: {
    //     value,
    //     ignore_case: true,
    //     fields: {
    //       uinfo: ['first_name', 'last_name'],
    //     },
    //   },
    // });
    resetUsers();
  };

  const formatActorName = (cell, row) => {
    const isRoot = actorIsRoot(row);
    const isRootOrAdmin = actorIsRootOrAdmin(row);

    return (isRootOrAdmin)
      ? (
        <Tooltip
          placement="left"
          title={isRoot ? 'root' : 'wms admin'}
          color={isRoot ? 'purple' : '#f50'}
        >
          <BaseTag color={isRoot ? 'purple' : '#f50'}>{getNameForActor(row)}</BaseTag>
        </Tooltip>
      ) : getNameForActor(row);
  };

  const columns = [
    {
      title: <AntDesignSearchBox54origins
        onSearch={onSearchData}
      />,
      key: 'name',
      render: formatActorName,
    },
    {
      title: 'Type',
      key: 'actor_type',
      dataIndex: 'actor_type',
      render: (cell) => <BaseTag color="blue">{cell}</BaseTag>,
    },
    {
      title: 'Action',
      dataIndex: 'uuid',
      key: 'action',
      render: (cell) => (
        <MasqueradingBtn54origins
          url={entityBackendURL()}
          actorUUID={cell}
          btnLabel="masquerade"
          btnType="primary"
          btnSize="default"
          btnIcon={<Icon path={mdiGhostOutline} size={0.8} />}
        />
      ),
    },
  ];

  useEffect(() => {
    getMasqueradingAllowed();
  }, [searchData, JSON.stringify(config)]);

  return (
    <Row style={{
      padding: '25px',
    }}
    >
      <Col span={12}>
        <h3>Masquerading</h3>
        <BaseTableWithPagination
          loading={isFetching}
          newCurrentPage={currentPage}
          pageLimit={limit}
          pageSizeOptions={[10, 25, 50, 100]}
          changePageLimit={changeLimit}
          useCustomPagination
          columns={columns}
          data={actors}
          // data={tableActors}
          size="small"
          total={total}
          changePageCallback={changePage}
        />
        {/* <table
          dataSource={tableActors}
          columns={columns}
          pagination={customPagination}
          loading={loadingActors}
        /> */}
      </Col>
    </Row>
  );
}

export default ListOfMasqueradingActors;

import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Timeline } from 'antd';
import StepNum from '../stepNum/StepNum';
import DescriptionDocument from '../DescriptionDocument';
import InfrastructureDocument from '../InfrastructureDocument';
import InfoComponent from '../infoComponent/InfoComponent';

import { getProject } from '../../../../../selectors/selectors';
import '../../../../../../components/entityWorkTime/entityWorkTime.scss';

function ProjectInfoBlock({ partitionType }) {
  const { t } = useTranslation();

  const selectedProject = useSelector(getProject);

  const parseBlockParams = (project) => {
    const defaultParams = {
      uuid: '',
      created: null,
      modified: null,
      lifeTime: null,
    };
    const params = Array.isArray(project) ? project?.[0] : null;
    if (!params) {
      return defaultParams;
    }
    const uuid = params?.uuid;
    const created = params?.created ? moment(params?.created).format('DD-MM-YYYY') : null;
    const modified = params?.modified ? moment(params?.modified).format('DD-MM-YYYY') : null;
    const lifeTime = params?.params?.lifeTime ? moment(params?.params?.lifeTime).format('DD-MM-YYYY') : null;
    const daysLeft = lifeTime ? moment(lifeTime, 'DD-MM-YYYY').diff(moment(moment(), 'DD-MM-YYYY'), 'days') : '-';
    const isOverdue = daysLeft < 0;
    return {
      uuid, created, modified, lifeTime, daysLeft: Math.abs(daysLeft), isOverdue,
    };
  };

  const blockParams = parseBlockParams(selectedProject);

  const expirationInfo = (
    <>
      Expires
      {' '}
      {blockParams?.lifeTime}
    </>
  );

  return (
    <Timeline className="wrapper-time-line ml-2">
      <Timeline.Item dot={<StepNum num={1} />}>
        <div className="flex" style={{ position: 'relative', top: '1px' }}>
          <span className="mr-1 text-secondary">
            {t('wms.labels.created', 'Creation date')}
            :
          </span>
          {blockParams?.created}
        </div>
      </Timeline.Item>
      <Timeline.Item dot={<StepNum num={2} />}>
        <div className="flex" style={{ position: 'relative', top: '1px' }}>
          <span className="mr-1 text-secondary">
            {t('wms.labels.modified', 'Last modified')}
            :
          </span>
          {blockParams?.modified}
        </div>
      </Timeline.Item>
      {/* <Timeline.Item dot={<StepNum num={3} />}>
        <div className="flex items-center" style={{ position: 'relative', top: '-2px' }}>
          <span className="mr-1 text-secondary">
            {t('wms.labels.expired', 'Expiration date')}
            :
          </span>
          {blockParams?.daysLeft}
          {' '}
          {blockParams?.isOverdue ? t('wms.labels.lifetime.days_overdue', 'days_overdue') : t('wms.labels.lifetime.days_left', 'days_left')}
          <div className="inline ml-1">
            <InfoComponent tooltipTitle={expirationInfo} />
          </div>

        </div>
      </Timeline.Item> */}

      <Timeline.Item dot={<StepNum num={3} />}>
        <DescriptionDocument
          partitionType={partitionType}
          layout="horizontal"
          isGrayTitle
        />
      </Timeline.Item>
      <Timeline.Item dot={<StepNum num={4} />}>
        <InfrastructureDocument
          partitionType={partitionType}
          layout="horizontal"
          isGrayTitle
        />
      </Timeline.Item>

    </Timeline>
  );
}

ProjectInfoBlock.propTypes = {
  partitionType: string,
};

export default ProjectInfoBlock;

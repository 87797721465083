import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  fetching: false,
};

const projectMainEntitySlicer = createSlice({
  name: 'PROJECT_MAIN_ENTITY',
  initialState,
  reducers: {
    GET_MAIN_PROJECT_ENTITY_REQUEST: (state) => {
      state.fetching = true;
    },
    GET_MAIN_PROJECT_ENTITY_SUCCESS: (state, action) => {
      state.data = action.payload;
      state.fetching = false;
    },
    GET_MAIN_PROJECT_ENTITY_FAILURE: (state) => {
      state.fetching = false;
    },
  },
});

export const mainProjectEntityFetching = (state) => state?.projectManagementService?.project?.projectMainEntity?.fetching;

export const {
  GET_MAIN_PROJECT_ENTITY_REQUEST,
  GET_MAIN_PROJECT_ENTITY_SUCCESS,
  GET_MAIN_PROJECT_ENTITY_FAILURE,
} = projectMainEntitySlicer.actions;

export default projectMainEntitySlicer.reducer;

export const vectorTypes = [
  {
    value: 'general',
    label: 'general',
    translate: 'wms.type.general',
    className: 'radio-blue',
  },
  {
    value: 'version',
    label: 'version',
    translate: 'wms.type.version',
    className: 'radio-violet',
  },
  {
    value: 'deploy',
    label: 'deploy',
    translate: 'wms.type.deploy',
    className: 'radio-green',
  },
  {
    value: 'launch',
    label: 'launch',
    translate: 'Launch',
    className: 'radio-orange',
  },
  {
    value: 'workblock',
    label: 'workblock',
    translate: 'wms.type.workblock',
    className: 'radio-red',
  },

];

export default vectorTypes;

import React from 'react';
import {
  Card, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import DayWeek from '../WeekPlanning/DayWeek';

import {
  dateFormat2,
  dateToFormat2,
  dateToFormat3,
  transformDate,
} from '../../../54origins/dateFormats54origins';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";

const styles = {
  body: {
    padding: '5px',
  },
};

function MyBoardCardDay({
  date,
  planningDayUUID,
  projectUserReportsUUID,
  loading,
  handleDuplicate,
  handleSyncDataApply,
  syncChanges,
  // automaticallyDistributeIssues,
  // updateFromOuter,
}) {
  // const dispatch = useDispatch();
  // const parentForMyBoardDayStorage = useSelector(selectPlanningVectorDays);

  // const { setSearchParams } = useURLParams();
  //
  // const { getOrCreateBoardDayEntity, updateMyBoardStorage } = useMyBoard();
  //
  // const [myBoardDayStorage, setMyBoardDayStorage] = useState(null);
  // const [myBoardDayIssues, setMyBoardDayIssues] = useState([]);

  // console.log('myBoardDayStorage', myBoardDayStorage);

  const formatDayOfWeek = dateToFormat2(date);

  // const getIssuesForCurrentDay = (issues) => {
  //   if (issues?.length !== 0) {
  //     dispatch(entityRead({
  //       constants: ['GET_ISSUES_FOR_DAY_BOARD_REQUEST',
  //         'GET_ISSUES_FOR_DAY_BOARD_SUCCESS',
  //         'GET_ISSUES_FOR_DAY_BOARD_FAILURE'],
  //       data: {
  //         entity_uuids: issues,
  //       },
  //     })).then((res) => {
  //       setMyBoardDayIssues(res);
  //     });
  //   }
  // };

  // const updateIssuesForCurrentDay = (issues) => {
  //   updateMyBoardStorage({
  //     params: {
  //       issues: issues.map((item) => ({
  //         uuid: item,
  //         hours: Math.floor(Math.random() * 6) + 1,
  //       })),
  //     },
  //     uuid: myBoardDayStorage?.uuid,
  //   }).then((res) => {
  //     getIssuesForCurrentDay(issues);
  //   });
  // };

  // const issueOnClick = (uuid) => {
  //   setSearchParams({
  //     selectedIssue: uuid,
  //   });
  // };

  const todayDate = dateFormat2();
  const currentCardDay = dateToFormat2(date);
  const isToday = todayDate === currentCardDay;

  // const initFunc = () => {
  //   const findDay = parentForMyBoardDayStorage.find((item) => item?.date === formatDayOfWeek);
  //
  //   if (findDay?.length !== 0) {
  //     const parent = findDay?.uuid;
  //     const params = {
  //       date: formatDayOfWeek,
  //     };
  //
  //     getOrCreateBoardDayEntity(parent, params).then((res) => {
  //       setMyBoardDayStorage(res);
  //       getIssuesForCurrentDay(compact(res?.params?.issues?.map((item) => item?.uuid)));
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (parentForMyBoardDayStorage.length !== 0) {
  //     initFunc();
  //   }
  // }, [parentForMyBoardDayStorage.length, updateFromOuter]);

  return (
    <BaseCard
      styles={styles}
      className={`wrapper_day ${isToday ? 'today' : 'no_today'}`}
      loading={loading}
    >
      <DayTitle currentCardDay={currentCardDay} isToday={isToday} date={date} />
      <Space
        direction="vertical"
        size="small"
        className="w-full"
      >
        <DayWeek
          dayDate={formatDayOfWeek}
          dayUUID={planningDayUUID}
          projectUserReportsUUID={projectUserReportsUUID}
          handleDuplicate={handleDuplicate}
          handleSyncData={handleSyncDataApply}
          syncChanges={syncChanges}
        />
        {/* {myBoardDayIssues.length !== 0 && ( */}
        {/*  <div className="title_activity_daily_plan"> */}
        {/*    {capitalize('Project tasks')} */}
        {/*  </div> */}
        {/* )} */}
        {/* {myBoardDayIssues.map((item) => ( */}
        {/*  // <Dropdown.Button */}
        {/*  //   className="w-full" */}
        {/*  //   overlayClassName="w-full" */}
        {/*  //   menu={{ */}
        {/*  //     items: [], */}
        {/*  //     onClick: () => {}, */}
        {/*  //   }} */}
        {/*  //   onClick={() => {}} */}
        {/*  // > */}
        {/*  //   {item?.params?.title} */}
        {/*  // </Dropdown.Button> */}
        {/*  <BaseButton */}
        {/*    key={`issue${item?.uuid}1`} */}
        {/*    className="w-full" */}
        {/*    onClick={() => issueOnClick(item?.uuid)} */}
        {/*  > */}
        {/*    <Flex gap={8} justify="start" align="center"> */}
        {/*      <Icon path={mdiTicketConfirmationOutline} size={0.7} /> */}
        {/*      /!*{myBoardDayStorage?params?.issues?.find(issue => issue.uuid === item?.uuid)[0]?.hours}*!/ */}
        {/*      {item?.params?.title} */}
        {/*    </Flex> */}
        {/*  </BaseButton> */}
        {/* ))} */}
        {/* <Flex gap={8} align="center" justify="center"> */}
        {/*  <ModalForDayBoardIssues */}
        {/*    dayStorageUUID={myBoardDayStorage?.uuid} */}
        {/*    updateIssuesForCurrentDay={updateIssuesForCurrentDay} */}
        {/*  /> */}
        {/*  <Tooltip */}
        {/*    title="Распределить автоматически тикеты на неделю" */}
        {/*    placement="bottom" */}
        {/*    color="blue" */}
        {/*  > */}
        {/*    <BaseButton */}
        {/*      onClick={automaticallyDistributeIssues} */}
        {/*      type="dashed" */}
        {/*      className="w-full activity_window__button-private-tab" */}
        {/*      icon={<Icon path={mdiCreation} size={1} />} */}
        {/*    /> */}
        {/*  </Tooltip> */}
        {/* </Flex> */}
      </Space>
    </BaseCard>
  );
}

export default MyBoardCardDay;

function DayTitle({ date, currentCardDay, isToday }) {
  const { t } = useTranslation();
  const isWeekend = transformDate(date).day() === 6 || transformDate(date).day() === 0;

  return (
    <h6
      className={`day_of_week_head ${
        isWeekend ? 'weekend' : ''
      }`}
    >
      <span
        className={`day_of_week_number ${
          isToday ? 'toDay' : ''
        }`}
      >
        {dateToFormat3(date)}
      </span>
      <span
        className={`day_of_week_name ${
          isToday ? 'toDay' : ''
        }`}
      >
        {transformDate(date)
          .locale(i18n.language)
          .format('dddd').toUpperCase()}
      </span>
    </h6>
  );
}

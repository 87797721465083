import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/personalDashboard.css';

import GridOfCards from '../../../components/GridOfCards/GridOfCards';

import { getMyProjectsUuids } from '../../hooks/useGetMyOrPublicUserProjects';
import { useClearComponent } from '../../../hooks/useClearComponent';
import { clearListOfProjects } from '../../../projectFlow/actions/PtcActionsForHook';
import { galleryItemList } from '../../tools/personalDashboard';
import { getPartitionPMUUID } from '../../../config/selectors/selectors';
import { getOtherUserPublicEntity, getUserPublicProfile } from '../../store/selectors/selectors';

function PersonalDashboardRoot({
  otherUser,
}) {
  const dispatch = useDispatch();

  const partitionPM = useSelector(getPartitionPMUUID);

  const myUserPublicEntity = useSelector(getUserPublicProfile);
  const otherUserPublicEntity = useSelector(getOtherUserPublicEntity);

  const actorUUID = otherUserPublicEntity.actor || myUserPublicEntity.actor;
  const currentUserPublicEntity = otherUser ? otherUserPublicEntity : myUserPublicEntity;

  useEffect(() => {
    if (actorUUID) {
      getMyProjectsUuids(dispatch, partitionPM, actorUUID);
    }
  }, [actorUUID]);

  useClearComponent(clearListOfProjects);

  return (
    <GridOfCards
      grid={[{ galleryList: galleryItemList }]}
      otherUser={otherUser}
      currentUserPublicEntity={currentUserPublicEntity}
    />
  );
}

PersonalDashboardRoot.propTypes = {
  otherUser: bool,
};

export default PersonalDashboardRoot;

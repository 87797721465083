import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input, Result, Skeleton,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  head, isNil, prop, propOr,
} from 'ramda';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiContentSaveOutline } from '@mdi/js';
import moment from 'moment';

import '../style/ListProjectCookbook.scss';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import BaseModal from '../../../components/_ui/BaseModal/BaseModal';

import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { axiosRequestPost } from '../../../api/apiAxios';
import {
  myUserCookBookEntityPublicUuid,
  commonCookBookEntityUuid,
  newPublicCookBookEntityUuid,
} from '../../selectors/selectors';
import { isAdminOrRoot } from '../../../entity/selectors/selectors';
import { downloadDataAsJSON } from '../../../MainUtils';
import { capitalize } from 'lodash';
import { entityCreate } from '../../../entity/actions/entityActions';
import { partitionNamesConfig } from '../../../api/appConfig';

function SaveAsCookbookModal({
  entityType,
  cookBookState,
  disabledBtn,
  getListTemplateCookBook,
  customHideLink,
  customHideBtn = false,
  customOpenModal = false,
  setCustomOpenModalCallback,
  customFetching,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const commonCookBookEntityUUID = useSelector(commonCookBookEntityUuid);
  const myUserCookBookEntityPublicUUID = useSelector(myUserCookBookEntityPublicUuid);
  const newCookBookEntityPublicUUID = useSelector(newPublicCookBookEntityUuid);

  const checkAdmin = useSelector(isAdminOrRoot);

  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState('Template');
  const [successCreate, setSuccessCreate] = useState(false);

  const handleCancelModalInsert = () => {
    setOpenModal(false);
    setTitle('Template');
    setSuccessCreate(false);
  };

  const createTemplateRequest = (customPartition) => {
    const paramsProject = {
      archive: false,
      areas: [],
      description: '',
      goal: '',
      status: 'created',
      title: 'Project template',
      usersSearch: [],
    };

    const jsObject = {
      entity_type: 'project',
      params: paramsProject,
    };

    if (!isNil(cookBookState.jsObject.issues)) {
      jsObject.issues = cookBookState.jsObject.issues;
    }

    if (!isNil(cookBookState.jsObject.vectors)) {
      jsObject.vectors = cookBookState.jsObject.vectors;
    }

    const publicUuid = myUserCookBookEntityPublicUUID || newCookBookEntityPublicUUID;

    const parentUuid = (customPartition === partitionNamesConfig.partition1)
      ? commonCookBookEntityUUID
      : publicUuid;

    const newEntityType = entityType === 'vector'
      ? entityType
      : propOr('project', 'entityType', cookBookState);

    const data = {
      entity_type: 'CookBookTemplate',
      parent: parentUuid,
      params: {
        entityType: newEntityType,
        label: title,
        value: jsObject,
      },
    };
    const constants = [
      ProjectTypeCommonConstants.CREATE_TEMPLATE_BOOK_REQUEST,
      ProjectTypeCommonConstants.CREATE_TEMPLATE_BOOK_SUCCESS,
      ProjectTypeCommonConstants.CREATE_TEMPLATE_BOOK_FAILURE,
    ];
    const options = {
      partition: customPartition,
      onSuccess: (response) => {
        setSuccessCreate(prop('uuid', head(response)));
        getListTemplateCookBook(parentUuid, customPartition);
      },
    };

    dispatch(entityCreate({
      data,
      constants,
      options,
    }));
  };

  const templateSuccessCreate = () => {
    const hideLink = !isNil(customHideLink) ? customHideLink : entityType === 'project';
    return (
      <div>
        <Result
          status="success"
          title="Template successfully created!"
        />
        {!hideLink
          && (
            <div className="flex justify-start items-start">
              <span className="text-base whitespace-nowrap flex justify-start items-start">
                {/* {`${capitalize(t('wms.labels.go_to_project', 'go to project'))}:`} */}
                Go to cookbook:
              </span>
              <div>
                <Link
                  className="ml-2"
                  to={{
                    pathname: '/pm/cookbook',
                    search: `?activeCookBook=${successCreate}`,
                  }}
                >
                  {`${window.location.origin}/pm/cookbook`}
                </Link>
              </div>

            </div>
          )}

        <div className="mt-4 flex justify-end">
          <BaseButton
            className="btnPrimary"
            onClick={handleCancelModalInsert}
          >
            {capitalize(t('wms.buttons.close', 'close'))}
          </BaseButton>
        </div>
      </div>
    );
  };

  const onExportArea = () => {
    const fileName = `${title}${moment(new Date()).format('YYYY-MM-DD_hh-mm-ss')}`;

    downloadDataAsJSON(cookBookState, fileName);
  };

  const exportCookBook = () => {
    onExportArea();
    handleCancelModalInsert();
  };
  const saveToDeviseBtn = (
    <div className="to-devise-container-bth">
      <span className="p-3">
        {capitalize(t(
          'wms.modals.body.save_to_device',
          'save to device as Json ',
        ))}

      </span>

      <div className="mt-4 flex justify-center">
        <BaseButton
          className="btnPrimary"
          onClick={exportCookBook}
        >
          {capitalize(t('wms.labels.save_as_json', 'save as Json'))}
        </BaseButton>
      </div>
    </div>
  );

  const toggleBtnOpen = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (customOpenModal === true) {
      setOpenModal(true);
      setCustomOpenModalCallback(false);
    }
  }, [customOpenModal]);


  return (
    <>
      {!customHideBtn
        && (
          <BaseButton
            className="btnPrimary-outline"
            size="small"
            onClick={toggleBtnOpen}
            disabled={disabledBtn}
          >
            <Icon
              className="mr-1"
              path={mdiContentSaveOutline}
              size={0.8}
            />

            {capitalize(t('wms.buttons.save_as', 'save as'))}
          </BaseButton>
        )}
      {openModal
        && (
          <BaseModal
            open={openModal}
            width={1100}
            blockPropagation
            centered
            footer={null}
            handleCancel={handleCancelModalInsert}
          // title="Save template cookbook to"
          >
            <Skeleton loading={customFetching}>
              {
                successCreate
                  ? templateSuccessCreate()
                  : (
                    <div>
                      <div>
                        <label>
                          {`${capitalize(t('wms.labels.template_name', 'template name'))}:`}
                          <span style={{
                            color: title.length === 0 ? 'red' : 'green',
                            fontSize: '16px',
                            marginLeft: '3px',
                          }}
                          >
                            *
                          </span>
                        </label>
                        <Input
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      {checkAdmin ? (
                        <div className="flex mt-4 w-full">
                          <div className="user-flow-container-bth">
                            <span className="p-3 flex justify-center">
                              {capitalize(t(
                                'wms.modals.body.description_creation_Public_template',
                                'You can save a template cookBook as a personal one. Only administrators and people to whom you grant rights will have access to it.',
                              ))}

                            </span>

                            <div className="mt-4 flex justify-center">
                              <BaseButton
                                className="btnPrimary"
                                onClick={() => createTemplateRequest(partitionNamesConfig.partition3)}
                                disabled={title.length === 0}
                              >
                                {capitalize(t('wms.labels.create_as_public_cookBook', 'save as my public template cookBook'))}
                              </BaseButton>
                            </div>

                          </div>

                          <div className="project-flow-container-bth">
                            <span className="p-3">
                              {capitalize(t(
                                'wms.modals.body.description_creation_common_template',
                                'save as a common template cookbook',
                              ))}

                            </span>

                            <div className="mt-4 flex justify-center">
                              <BaseButton
                                className="btnPrimary"
                                onClick={() => createTemplateRequest(partitionNamesConfig.partition1)}
                                disabled={title.length === 0}
                              >
                                {capitalize(t('wms.labels.create_as_common_cookBook', 'save as a common template cookBook'))}
                              </BaseButton>
                            </div>
                          </div>

                          {saveToDeviseBtn}

                        </div>
                      ) : (
                        <div className="flex mt-4 w-full">
                          <div className="user-flow-container-bth">
                            <span className="p-3">
                              {capitalize(t('wms.modals.body.description_creation_my_public_template', 'save as my personal template cookbook'))}
                              :
                            </span>

                            <BaseButton
                              className="btnPrimary"
                              onClick={() => createTemplateRequest(partitionNamesConfig.partition3)}
                              disabled={title.length === 0}
                            >
                              {capitalize(t('wms.labels.save', 'save'))}

                            </BaseButton>

                          </div>

                          {saveToDeviseBtn}

                        </div>
                      )}
                    </div>
                  )
              }
            </Skeleton>

          </BaseModal>
        )}
    </>
  );
}

SaveAsCookbookModal.propTypes = {
  entityType: PropTypes.string,
  cookBookState: PropTypes.object,
  disabledBtn: PropTypes.boll,
  getListTemplateCookBook: PropTypes.func,
  customHideBtn: PropTypes.boll,
  customOpenModal: PropTypes.boll,
  setCustomOpenModalCallback: PropTypes.func,
  customHideLink: PropTypes.bool,
};

export default SaveAsCookbookModal;

import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  array, string, func, bool, number, oneOfType, shape,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  capitalize, difference, isEmpty, get,
} from 'lodash';

import {
  Col, Input, Badge, Space, Tooltip, Flex, Spin,
} from 'antd';
import Icon from '@mdi/react';
import {
  mdiAccountGroup, mdiChat, mdiContentSave, mdiCloseThick, mdiCheckBold,
} from '@mdi/js';

// Don't comment this import, cause lose styles for AssignedUsersPopover component!!!
import AssignUsers from '../assigUsers/AssignUsers';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import AssignedUsersPopover from '../commonComponents/AssignedUsers/AssignedUsersPopover';
import CardSidePanel from '../commonComponents/cardSidePanel/CardSidePanel';
import CommonInfo from '../commonComponents/CommonInfo';
import CommentsStartPage from '../comment/CommentsStartPage/CommentsStartPage';
import CopyURL from '../../../components/CopyURL';
import DateRangePicker from '../../../components/dateRangePicker/DateRangePicker';
import DocsOptions from '../docView&ideas/docsOptions/DocsOptions';
import DocTypeLabel from '../docView&ideas/DocTypeLabel';
import ExpandModeLayout, { useExpandModeContext } from '../commonComponents/expandModeLayout/ExpandModeLayout';
import GoToButton from '../../../components/buttons/GoToButton';
import HardDeleteEntityContainer from '../../../entity/containers/HardDeleteEntityContainer';
import LastComments from '../commonComponents/activities/activitiesDashboard/LastComments';
import MilestoneStatus from './MilestoneStatus';
import EntityParamsPopover from '../commonComponents/EntityParamsPopover';
import UnSaveDataConfirmNew from '../../../components/un-save-data-confirm/UnSaveDataConfirmNew';
import WrapperAvatarsGroupForProjectWithHook from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';

import { useResize } from '../../../hooks/useResize';
import { sendNotification } from '../../../api/notificationsAPI';

import {
  clearAllComments,
  sendSystemComment,
  updateCommentsCountRequest,
  getCommentsAndUpdateStore,
} from '../../actions/projectFlowActions';
import { updateProjectMilestoneRequest } from '../../actions/projectMilestonesActions';
import { getProjectMilestone, getProjectMilestoneFetching } from '../../reducers/slicers/projectMilestonesSlicer';
import { DELETE_PROJECT_KPM } from '../../reducers/slicers/projectsKPMSlice';
import { getUserName } from '../../../entity/selectors/selectors';
import {
  getTotalComments, getProjectParams, getProjectUUID,
} from '../../selectors/selectors';
import { getProjectActors } from '../../../selectors/selectors';
import { getUserPublicProfileUUID } from '../../../userFlow/store/selectors/selectors';

import { computeMilestoneScore, renderMilestoneScore } from './helpers/computeScore';
import { milestoneScoreInfo } from './helpers/milestoneUtils';
import { COMMENT_TYPE } from '../comment/commentType';
import { antNotification } from '../../../MainUtils';
import { milestoneImportanceOptions } from './constants/milestoneOptions';

import { dateToFormat2 } from '../../../54origins/dateFormats54origins';
import EditButton from '../commonComponents/EditButton';
// import { getProjectRequest } from '../commonComponents/AssignedUsers/actions';
import AssigningUsersRoot from '../../../assignUsersModal/AssigningUsersRoot';
import { getUuid } from '../../../assignUsersModal/utils';
import { partitionNamesConfig } from '../../../api/appConfig';

const { TextArea } = Input;

function MilestoneDescription({
  classNames,
  defaultValue,
  disabled,
  isReset,
  rows = 5,
  autoSize,
  changeIsReset,
  onSelect,
  onChangeCallback,
}) {
  const [value, setValue] = useState(defaultValue);
  // console.log('VALUE:', value);

  const changeValueHandler = (e) => {
    // console.log('onChange');
    setValue(e.target.value);
    onChangeCallback?.(e.target.value);
  };

  useLayoutEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useLayoutEffect(() => {
    if (isReset) {
      setValue(defaultValue);
      changeIsReset?.(false);
    }
  }, [isReset]);

  return (
    <TextArea
      className={classNames}
      value={value}
      disabled={disabled}
      defaultValue={defaultValue}
      name="description"
      rows={rows}
      autoSize={autoSize}
      onChange={changeValueHandler}
      onBlur={onSelect}
    />
  );
}

MilestoneDescription.propTypes = {
  classNames: string,
  defaultValue: string,
  disabled: bool,
  isReset: bool,
  rows: number,
  autoSize: oneOfType([bool, shape({ minRows: number, maxRows: number })]),
  changeIsReset: func,
  onChangeCallback: func,
  onSelect: func,
};

const initialState = {
  title: null,
  failReason: null,
  completionResult: null,
  isEdit: false,
};

function ProjectMilestoneView({
  defaultPartition,
  hideElements = [],
  disabledElements = [],
  createProjectMilestoneCallback,
  deleteMilestoneCallback,
  updateProjectMilestoneCallback,
  // readOnlyComments = false,
}) {
  disabledElements.push('exportPDF', 'exportDocx');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const milestoneDescriptionRef = useRef(null);

  const projectMilestone = useSelector(getProjectMilestone);
  const projectMilestoneFetching = useSelector(getProjectMilestoneFetching);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const userFullName = useSelector(getUserName);
  const commentsCountStore = useSelector(getTotalComments);
  const projectParams = useSelector(getProjectParams);
  const projectActors = useSelector(getProjectActors);
  const projectUUID = useSelector(getProjectUUID);

  const { width } = useResize();
  const {
    showMetaInfo,
    isBaseMode,
    isWideMode,
    isVerticalMode,
    isFullscreenMode,
  } = useExpandModeContext();

  const [state, setState] = useState(initialState);
  const [resetDescription, setResetDescription] = useState(false);
  const [bulkTitles, setBulkTitles] = useState(['']);
  const [showTitleError, setShowTitleError] = useState(false);
  const [showFailReasonError, setShowFailReasonError] = useState(false);
  const [showCompletionResultError, setShowCompletionResultError] = useState(false);

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [commentsCountLocal, setCommentsCountLocal] = useState(0);

  const [isEditAssignUsers, setEditAssignUsers] = useState(false);

  const {
    isEdit,
    title,
    failReason,
    completionResult,
  } = state || {};

  const {
    entity_type: entityType,
    params: {
      id: defaultID,
      title: defaultTitle,
      description: defaultDescription,
      lifeTime: defaultLifeTime,
      startDate: defaultStartDate,
      completionDate: defaultCompletionDate,
      status: defaultStatus,
      importance: defaultImportance,
      responsible: defaultResponsible,
      modifiedBy,
      failReason: defaultFailReason,
      completionResult: defaultCompletionResult,
      [COMMENT_TYPE.TOTAL]: defaultCommentsCount,
    } = {},
    uinfo: {
      first_name: creatorFirstName,
      last_name: creatorLastName,
    } = {},
    modified,
    created,
    parent,
    uuid: milestoneUUID,
  } = projectMilestone?.[0] || {};

  const defaultResponsibleActors = Array.isArray(defaultResponsible) ? defaultResponsible : [];

  const isInProgress = defaultStatus === 'in progress';
  const isCompleted = defaultStatus === 'completed' || defaultStatus === 'partially completed';
  const isFailed = defaultStatus === 'failed';
  const showDatePicker = isInProgress || isCompleted || isFailed;

  const autoSizeDescription = isVerticalMode
    ? false
    : { minRows: 20, ...(isBaseMode && { maxRows: 20 }) };
  const autoSizeTextarea = { minRows: 5, ...(isFullscreenMode && { maxRows: 10 }) };

  const score = useMemo(
    () => computeMilestoneScore({
      status: defaultStatus,
      importance: defaultImportance,
      startDate: defaultStartDate,
      endDate: defaultLifeTime,
    }),
    [defaultStatus, defaultImportance, defaultStartDate, defaultLifeTime],
  );

  const creatorInfo = `${creatorFirstName} ${creatorLastName}`;

  const pathToRedirect = {
    pathname: defaultPartition === partitionNamesConfig.partition3
      ? `/public/user/${myPublicUUID}/projects/planning`
      : '/pm/projects/planning',
    search: `?activeProject=${parent}&milestone=${milestoneUUID}`,
  };

  const toggleCommentsPanel = () => setIsSidePanelOpen?.((prev) => !prev);

  const openCommentsPanel = useCallback(() => setIsSidePanelOpen?.(true), [setIsSidePanelOpen]);
  const closeCommentsPanel = useCallback(() => setIsSidePanelOpen?.(false), [setIsSidePanelOpen]);

  const updateCommentsCount = async () => {
    if (commentsCountStore !== commentsCountLocal) {
      const commentsConfig = {
        entity_type: entityType,
        entity_uuid: milestoneUUID,
        params: {
          [COMMENT_TYPE.TOTAL]: commentsCountStore,
        },
      };


      const response = await dispatch(updateCommentsCountRequest(commentsConfig, defaultPartition));
      setCommentsCountLocal(response[0]?.params[COMMENT_TYPE.TOTAL] || 0);
    }
  };

  const makeComment = async (params) => {
    if (!params || isEmpty(params)) return false;

    await Promise.all(
      Object.entries(params).reduce((result, item) => {

        const [param, value] = item;

        // console.log('item param:', param);
        // console.log('item value:', value);

        const comment = {
          entity: 'projectMilestone',
          action: 'changed',
          param,
          value,
        };

        if (param === 'responsible') {
          // if (value?.length > 0) {
          //   comment.value = value.map((userUUID) => {
          //     const currentUser = projectActors.find((user) => userUUID === user?.uuid);
          //     return `${currentUser?.uinfo?.first_name} ${currentUser?.uinfo?.last_name}`;
          //   }).join(', ');
          // } else comment.value = 'none';
          comment.value = value?.map((el) => getUuid(el));
          comment.param = 'users';
        }

        if (param === 'failReason') comment.param = 'fail reason';
        if (param === 'startDate') comment.param = 'start date';
        if (param === 'completionDate') comment.param = 'completion date';
        if (param === 'completionResult') comment.param = 'completion result';

        return [
          ...result,
          value && dispatch(sendSystemComment(milestoneUUID, comment, defaultPartition, param))
            .then(() => dispatch(getCommentsAndUpdateStore({
              commentType: COMMENT_TYPE.TOTAL,
              parent: milestoneUUID,
              partition: defaultPartition,
            }))),
        ];
      }, []),
    );
  };

  const updateMilestoneParameter = async (params, callback) => {
    if (!params || isEmpty(params)) return;

    dispatch(
      updateProjectMilestoneRequest({
        uuid: milestoneUUID,
        params: {
          ...params,
          modifiedBy: userFullName ?? '',
        },
        partition: defaultPartition,
      }),
    ).then(() => {
      antNotification('success', capitalize(t('wms.noun.success', 'Success')));
      updateProjectMilestoneCallback?.();
      makeComment(params);
      dispatch(DELETE_PROJECT_KPM({ uuid: parent }));
      callback?.();
    });
  };

  //--------------------------------------------------------
  const setDefaultState = () => {
    setState(initialState);
    milestoneDescriptionRef.current = defaultDescription;
    setShowTitleError(false);
    setShowFailReasonError(false);
    setShowCompletionResultError(false);
  };

  const changeState = (params) => {
    setState((prev) => ({
      ...prev,
      ...params,
    }));
  };

  const changeImportance = useCallback((importanceValue) => {
    updateMilestoneParameter({ importance: importanceValue });
  }, [updateMilestoneParameter]);

  const changeInputField = useCallback((e) => {
    const { value, name } = e?.target || {};

    changeState({
      [name]: value,
      isEdit: true,
    });
  }, [changeState]);

  const changeLifeTime = useCallback((date, dateString) => {
    const [start, end] = date || [];

    updateMilestoneParameter({ lifeTime: dateToFormat2(end) });
  }, [updateMilestoneParameter]);

  const changeDescriptionCallback = useCallback((value) => {
    // console.log('CHANGE DESC CALLBACK');
    milestoneDescriptionRef.current = value;

    if (!isEdit) {
      changeState({
        isEdit: true,
      });
    }
  }, [isEdit, changeState]);

  // const resetMilestoneToInitial = () => {
  //   changeState(initialState);
  //   setShowTitleError(false);
  //   setShowFailReasonError(false);
  // };

  const sendCustomComment = async (params) => {
    if (!milestoneUUID) return;

    const comment = {
      entity: 'projectMilestone',
      action: 'changed',
      param: params.title,
      value: params.value,
    };

    dispatch(sendSystemComment(milestoneUUID, comment, defaultPartition))
      .then(() => dispatch(getCommentsAndUpdateStore({
        commentType: COMMENT_TYPE.TOTAL,
        parent: milestoneUUID,
        partition: defaultPartition,
      })));
  };

  const sendResponsibleUsersNotifications = (currentResponsible) => {
    if (currentResponsible?.length) {
      const receiver = difference(currentResponsible, defaultResponsible);

      // console.log('receiver', receiver);

      if (!isEmpty(receiver)) {
        const data = {
          projectUUID: parent,
          uuid: milestoneUUID,
          entity_type: 'projectMilestone',
          partition: defaultPartition,
          project_uuid: parent,
          project_id: get(projectParams, 'id', ''),
          project_title: get(projectParams, 'title', ''),
          assigned_entity_type: 'projectMilestone',
          assigned_entity_title: defaultTitle || '',
          assigned_entity_id: defaultID ? `M${defaultID}` : '',
          assigned_entity_uuid: milestoneUUID,
        };

        if (defaultPartition === partitionNamesConfig.partition3) {
          data.user = myPublicUUID || '';
        }

        // console.log('receiver data', data);

        dispatch(
          sendNotification('assigned_milestone', receiver, title, { entity: data }),
        );
      }
    }
  };

  const checkEmptyInputs = () => {
    if (title?.trim().length === 0 || defaultTitle?.trim().length === 0) {
      setShowTitleError(true);
      return false;
    }
    setShowTitleError(false);

    if (failReason?.trim()?.length === 0 || defaultFailReason?.trim()?.length === 0) {
      setShowFailReasonError(true);
      return false;
    }
    setShowFailReasonError(false);

    if (completionResult?.trim()?.length === 0 || defaultCompletionResult?.trim()?.length === 0) {
      setShowCompletionResultError(true);
      return false;
    }
    setShowCompletionResultError(false);

    return true;
  };

  const updateProjectMilestone = () => {
    if (!checkEmptyInputs()) return;

    const params = {
      title: title || defaultTitle,
      status: defaultStatus || 'created',
      importance: defaultImportance,
      lifeTime: defaultLifeTime,
      modifiedBy: userFullName ?? '',
      responsible: defaultResponsible,
      failReason: failReason || defaultFailReason,
      completionResult: completionResult || defaultCompletionResult,
      description: milestoneDescriptionRef.current ?? '',
    };

    dispatch(
      updateProjectMilestoneRequest({
        uuid: milestoneUUID,
        params,
        partition: defaultPartition,
      }),
    ).then(() => {
      antNotification('success', capitalize(t('wms.noun.success', 'Success')));
      updateProjectMilestoneCallback?.();

      if (defaultTitle && defaultTitle !== params.title) {
        sendCustomComment({
          title: 'title',
          value: params.title,
        });
      }

      if (defaultFailReason && defaultFailReason !== params.failReason) {
        sendCustomComment({
          title: 'fail reason',
          value: params.failReason,
        });
      }

      if (defaultCompletionResult && defaultCompletionResult !== params.completionResult) {
        sendCustomComment({
          title: 'completion result',
          value: params.completionResult,
        });
      }
    })
      .finally(() => {
        changeState({ isEdit: false });
      });
  };

  const onChangeResponsible = (data) => {
    const {
      users,
    } = data || {};

    // dispatch(getProjectRequest(projectUUID, defaultPartition));

    updateMilestoneParameter(
      { responsible: users },
    );
  };

  const resetMilestoneChangesHandler = () => {
    changeState({
      title: defaultTitle,
      isEdit: false,
    });

    milestoneDescriptionRef.current = defaultDescription || '';
    setResetDescription(true);

    setShowTitleError(false);
    setShowFailReasonError(false);
  };

  const onSelectTextArea = (e) => {
    const startSelected = e.target.selectionStart;
    const endSelected = e.target.selectionEnd;

    const valueArray = e.target.value?.split('\n');

    const newArr = [];
    const titlesArray = [];

    valueArray.forEach((el, indx) => {
      newArr.push({
        elem: el,
        length: el.length || 0,
        lengthInterval: {
          start: indx === 0 ? 0 : 1 + +newArr[indx - 1].lengthInterval.end,
          end: indx === 0 ? el.length : el.length + 1 + +newArr[indx - 1].lengthInterval.end,
        },
      });
    });

    if (startSelected === endSelected) {
      const arr = newArr
        .filter((e) => (
          startSelected < e.lengthInterval.end
          || startSelected === e.lengthInterval.end)
          && (startSelected > e.lengthInterval.start || startSelected === e.lengthInterval.start))
        .map((el) => el.elem);

      setBulkTitles(arr);
    }

    for (let i = 0; i < newArr.length; i += 1) {
      const {
        elem,
        lengthInterval,
      } = newArr[i];

      const {
        start,
        end,
      } = lengthInterval;

      if (
        (startSelected > start || startSelected === start)
        && (startSelected < end || startSelected === end)) {
        titlesArray.push(elem);
        continue;
      }
      if ((end < endSelected || end === endSelected) && startSelected < end) {
        titlesArray.push(elem);
      }
    }

    setBulkTitles(titlesArray);
  };
  //----------------------------------------------------------------
  useLayoutEffect(() => {
    setIsSidePanelOpen(isFullscreenMode || isWideMode);
  }, [milestoneUUID, isFullscreenMode, isWideMode]);

  useLayoutEffect(() => {
    if (milestoneUUID) setDefaultState();
  }, [milestoneUUID]);

  useEffect(() => {
    setBulkTitles(['']);
  }, [projectMilestone]);

  useEffect(() => {
    if (milestoneUUID) {
      updateCommentsCount?.();
    }
  }, [commentsCountStore]);

  useEffect(() => {
    setCommentsCountLocal(defaultCommentsCount || 0);
  }, [defaultCommentsCount]);

  useEffect(() => () => dispatch(clearAllComments()), []);

  return (
    <Spin
      size="large"
      spinning={projectMilestoneFetching}
      wrapperClassName={cn(
        'milestone-card-spinner milestone-expand-container',
        { wide: isWideMode },
        { vertical: isVerticalMode },
        { fullscreen: isFullscreenMode },
      )}
    >
      <Col
        id="milestone_component"
        className="h-full card-with-side-panel"
      >
        <Col className="milestone-expand-content">
          <Flex gap={10} vertical flex="1 1 100%">
            <Flex gap="10px 20px" wrap="wrap">
              <Flex align="flex-start">
                <ExpandModeLayout.Actions
                  parentType="milestone"
                  isFetching={projectMilestoneFetching}
                />
              </Flex>

              <Flex gap={5} wrap="wrap" align="flex-start" flex="1 1 auto">
                <CopyURL
                  partition={defaultPartition}
                  entityType="milestone"
                  entityUUID={milestoneUUID}
                />

                {!hideElements.includes('docInfoBtn') && (
                  <CommonInfo
                    creator={creatorInfo}
                    createdDate={created}
                    lastActivity={modified || ''}
                    lastModifiedBy={modifiedBy || ''}
                  />
                )}

                {width > 1599 && (
                  <DocTypeLabel entityDocType="projectMilestone" />
                )}

                <Flex align="center">
                  <Tooltip title={milestoneScoreInfo} placement="bottom" color="#ffffff">
                    {renderMilestoneScore(score, true)}
                  </Tooltip>
                </Flex>
              </Flex>

              <Flex align="center">
                {!hideElements.includes('goToBtn') && (
                  <GoToButton
                    pathForLabelTranslate="wms.buttons.go_to_milestone"
                    pathToRedirect={pathToRedirect}
                  />
                )}
              </Flex>

              <Flex gap={5} align="center" wrap="wrap">
                <Space size={[5, 5]} wrap>
                  <BaseButton
                    id='toggleCommentsPanelButton'
                    className="btnPrimary-outline"
                    size="small"
                    disabled={projectMilestoneFetching}
                    onClick={toggleCommentsPanel}
                  >
                    <Icon path={mdiChat} size={0.8} className="mr-1" />
                    {(width > 1699 && !isFullscreenMode) && capitalize(t('wms.buttons.comments', 'comments'))}
                    <Badge count={commentsCountLocal || 0} showZero color="#ff4d4f" size="small" className="ml-1" />
                  </BaseButton>

                  {!hideElements.includes('options') && (
                    <DocsOptions
                      defaultPartition={defaultPartition}
                      commentType={COMMENT_TYPE.TOTAL}
                      entityUUID={milestoneUUID}
                      entityType={entityType}
                      title={title}
                      bulkTitles={bulkTitles}
                      disabledElements={disabledElements}
                      hideElements={hideElements}
                      isDisabled={projectMilestoneFetching}
                    />
                  )}

                  {!hideElements.includes('deleteMilestone') && (
                    <HardDeleteEntityContainer
                      sizeBtn="small"
                      name="milestone"
                      entityUUID={milestoneUUID}
                      entityType="projectMilestone"
                      type="entity"
                      actionForEntity={deleteMilestoneCallback}
                      checkHardDelete={false}
                      hideLabel
                      hideTooltip
                      partitionType={defaultPartition}
                    />
                  )}
                </Space>
              </Flex>

              <Flex gap={5} align="center" wrap="wrap">
                <Space className="customPopconfirm" size={[5, 5]} wrap>
                  <UnSaveDataConfirmNew dataIsNotSave={isEdit}>
                    <BaseButton
                      id="saveGoalBtn"
                      className="ml-auto btnGreen"
                      size="small"
                      type="primary"
                      disabled={!isEdit}
                      onClick={updateProjectMilestone}
                    >
                      <Icon className="mr-1" path={mdiContentSave} size={0.8} />
                      {capitalize('save')}
                    </BaseButton>
                  </UnSaveDataConfirmNew>

                  <BaseButton
                    id='resetMilestoneButton'
                    size="small"
                    className="customAntOutWarningBtn"
                    disabled={!isEdit}
                    onClick={resetMilestoneChangesHandler}
                  >
                    Reset
                  </BaseButton>
                </Space>
              </Flex>

              {!isFullscreenMode && <hr className="m-0 w-full" />}

              <Flex
                className={cn('wrapper-title', { 'mt-1': !isFullscreenMode })}
                gap="small"
                align="center"
                flex="1 0 auto"
              >
                {showMetaInfo && (
                  <MilestoneStatus
                    partition={defaultPartition}
                    projectUUID={projectUUID}
                    currentStatus={defaultStatus}
                    currentStartDate={defaultStartDate}
                    milestoneDescription={defaultDescription}
                    disabledElements={disabledElements}
                    statusPreviewType="hoverValueAndIconWithArrow"
                    updateMilestoneParameter={updateMilestoneParameter}
                    createProjectMilestoneCallback={createProjectMilestoneCallback}
                  />
                )}

                {showMetaInfo && (
                  <EntityParamsPopover
                    className="p-0 flex flex-col flex-shrink-0"
                    param="importance"
                    tooltip={capitalize(t('wms.labels.importance', 'importance'))}
                    currentValue={defaultImportance}
                    options={milestoneImportanceOptions}
                    previewType="charNoCut"
                    tooltipPlacement="bottomLeft"
                    withBorder
                    disableCollapse={disabledElements.includes('importance')}
                    onChangeValue={changeImportance}
                  />
                )}

                <Input
                  name="title"
                  maxLength={80}
                  status={showTitleError && 'error'}
                  disabled={projectMilestoneFetching || disabledElements.includes('title')}
                  value={title ?? defaultTitle}
                  placeholder={showTitleError
                    ? capitalize(t('wms.placeholders.title_error', "Title can't be empty!"))
                    : capitalize(t('wms.placeholders.title', 'title'))}
                  showCount
                  onChange={changeInputField}
                />
              </Flex>

              {showMetaInfo && (
                <Flex gap="large" align="start" wrap="wrap" flex="1 1 100%">
                  {showDatePicker && (
                    <DateRangePicker
                      mode={isCompleted || isFailed ? 'spent' : 'normal'}
                      title={capitalize('life time info')}
                      startDate={defaultStartDate}
                      endDate={isCompleted ? defaultCompletionDate : defaultLifeTime}
                      disabledStart
                      disabledEnd={isCompleted || isFailed}
                      onChange={changeLifeTime}
                    />
                  )}

                  <Flex gap={5} vertical={showDatePicker}>
                    <Flex gap={5} align="center">
                      <Flex
                        style={{ cursor: 'pointer' }}
                        align="center"
                        gap={4}
                        onClick={() => setEditAssignUsers((prev) => !prev)}
                      >
                        <Icon path={mdiAccountGroup} size={1} />
                        <span className="wrapper-title">
                          {capitalize(t('wms.adjective.responsible', 'responsible'))}
                          :
                        </span>
                      </Flex>

                      <EditButton
                        id='editResponsibleButton'
                        disabled={disabledElements.includes('assign_users')}
                        onClick={() => setEditAssignUsers(true)}
                      />
                      <AssigningUsersRoot
                        header="Assigning responsible"
                        isOpen={isEditAssignUsers}
                        setIsOpen={setEditAssignUsers}
                        onUpdateUsers={onChangeResponsible}
                        needUpdateProjectUsers
                        placement="left"
                        isModal
                        globalOptions={{
                          uuids: projectParams?.users?.map((el) => getUuid(el)) ?? [],
                          roles: projectParams?.users ?? [],
                          defaultColumn: 'project',
                        }}
                        tabs={{
                          allConfig: {
                            label: 'All',
                            value: 'all',
                            textSelectButton: 'Add to milestone',
                            showSections: ['button-add-user'],
                          },
                          projectConfig: {
                            label: 'Project',
                            value: 'project',
                            textSelectButton: 'Add to milestone',
                            defaultItems: projectParams?.users ?? [],
                            showSections: ['string-role', 'button-add-user'],
                          },
                        }}
                        usersConfig={{
                          label: 'Milestone users',
                          showSections: ['string-role', 'button-remove-user'],
                          textDeleteButton: 'Remove users',
                          defaultItems: defaultResponsibleActors?.map((el) => el?.uuid) ?? [],
                        }}
                        projectUuid={projectUUID}
                        partition={defaultPartition}
                      />
                    </Flex>

                    <WrapperAvatarsGroupForProjectWithHook
                      actors={defaultResponsibleActors}
                      avatarSize={28}
                      popoverTrigger="click"
                      popoverPlacement="right"
                      checkProjectRole
                    />
                  </Flex>
                </Flex>
              )}
            </Flex>

            <Flex gap={10} vertical flex="1 1 auto">
              <div className="editor-wrapper page-view">
                <div className="page-view__surface">
                  <MilestoneDescription
                    classNames="page-view__a4"
                    defaultValue={defaultDescription || ''}
                    disabled={projectMilestoneFetching || disabledElements.includes('textarea')}
                    isReset={resetDescription}
                    autoSize={autoSizeDescription}
                    changeIsReset={setResetDescription}
                    onChangeCallback={changeDescriptionCallback}
                    onSelect={onSelectTextArea}
                  />
                </div>
              </div>

              {isFailed && showMetaInfo && (
                <>
                  <hr className="m-0 w-full" />
                  <Flex className="mb-4" vertical>
                    <Flex className="wrapper-title mb-1" align="center" gap={2}>
                      <Icon path={mdiCloseThick} size={0.75} color="#f5222d" />
                      <span>
                        {capitalize(t('wms.labels.fail_reason', 'fail reason'))}
                        :
                      </span>
                    </Flex>

                    <TextArea
                      style={{ resize: 'none' }}
                      name="failReason"
                      placeholder={showFailReasonError
                        ? capitalize("fail reason can't be empty!")
                        : capitalize('please fill in the reason for failed milestone')}
                      autoSize={autoSizeTextarea}
                      maxLength={200}
                      showCount
                      status={showFailReasonError && 'error'}
                      disabled={projectMilestoneFetching || disabledElements.includes('failReason')}
                      value={failReason ?? defaultFailReason}
                      onChange={changeInputField}
                    />
                  </Flex>
                </>
              )}

              {isCompleted && showMetaInfo && (
                <>
                  <hr className="m-0 w-full" />
                  <Flex className="mb-4" vertical>
                    <Flex className="wrapper-title mb-1" align="center" gap={2}>
                      <Icon path={mdiCheckBold} size={0.75} color="#72b153de" />
                      <span>
                        {capitalize(t('wms.labels.completion_result', 'completion result'))}
                        :
                      </span>
                    </Flex>

                    <TextArea
                      style={{ resize: 'none' }}
                      name="completionResult"
                      placeholder={showCompletionResultError
                        ? capitalize("completion result can't be empty!")
                        : capitalize('please fill in the result for completed milestone')}
                      autoSize={autoSizeTextarea}
                      status={showCompletionResultError && 'error'}
                      disabled={projectMilestoneFetching || disabledElements.includes('completionResult')}
                      value={completionResult ?? defaultCompletionResult}
                      onChange={changeInputField}
                    />
                  </Flex>
                </>
              )}

              {(isBaseMode || isVerticalMode) && (
                <LastComments
                  key={milestoneUUID}
                  partition={defaultPartition}
                  parentUUID={milestoneUUID}
                  allCommentsMode
                  readOnlyComments
                  showMoreComments={openCommentsPanel}
                />
              )}
            </Flex>
          </Flex>
        </Col>

        <CardSidePanel
          isVisible={isSidePanelOpen}
          hideCloseButton={isFullscreenMode}
          closeSidePanel={closeCommentsPanel}
        >
          {isSidePanelOpen && (
            <CommentsStartPage
              commentType={COMMENT_TYPE.TOTAL}
              inPanel
              isPanelOpen={isSidePanelOpen}
              parentUUID={milestoneUUID}
              partitionType={defaultPartition}
            />
          )}
        </CardSidePanel>
      </Col>
    </Spin>
  );
}

ProjectMilestoneView.propTypes = {
  defaultPartition: string,
  hideElements: array,
  disabledElements: array,
  createProjectMilestoneCallback: func,
  deleteMilestoneCallback: func,
  updateProjectMilestoneCallback: func,
  // readOnlyComments: bool,
};

export default ProjectMilestoneView;

import { mdiChevronDown, mdiHome } from '@mdi/js';
import Icon from '@mdi/react';
import {
  get, head, isEmpty, capitalize,
} from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Dropdown, Tooltip } from 'antd'; 

import HardDeleteEntityContainer from '../../entity/containers/HardDeleteEntityContainer';
import WrapperAddIssue from '../../projectFlow/components/issues/newIssue/WrapperAddIssue';
import NewTestCaseModalHook from '../../projectFlow/components/qaView/case/NewTestCaseModalHook';
import WrapperAddVector from '../../projectFlow/containers/vector/WrapperAddVector';

import { ProjectMainLayerContext } from '../../projectFlow/context/ProjectFlowListOfContexts';
import { clearGlobalUpdateEntity, setGlobalUpdateEntity } from '../../projectFlow/reducers/slicers/breadcrumbsSlicer';
import { clearIssue, clearVector } from '../../projectFlow/actions/PtcActionsForHook';
import { clearSuite, clearTestCycle } from '../../projectFlow/actions/QaActions';
import {
  getProjDocOrIdeas,
  getProject,
  getProjectUUID,
  // getTestCaseParamsInCycle,
  getTestCycle,
  getTestSuite,
  getVectorInfo,
  issueEntity,
  getVectorsScoringData,
} from '../../projectFlow/selectors/selectors';
import { getIssuesScoringData } from '../../projectFlow/reducers/issues/IssuesSlicer';
import { getProjectGoal } from '../../projectFlow/reducers/slicers/projectGoalsSlicer';
import { getProjectMilestone } from '../../projectFlow/reducers/slicers/projectMilestonesSlicer';
import { DELETE_PROJECT_KPM } from '../../projectFlow/reducers/slicers/projectsKPMSlice';
import useURLParams from '../../hooks/useURLParams';
import { updateEntityScoring } from '../../projectFlow/components/issuesView/utils';
import { ProjectTypeCommonConstants } from '../../projectFlow/constants/Constants';
import { updateProjectScoring, getProjectScoring } from '../../projectFlow/actions/projectFlowActions';
import { partitionNamesConfig } from '../../api/appConfig';
import { getAllDocPerms } from '../../projectFlow/reducers/slicers/quillSlicer';


export default function ProjectBreadcrumbs({ isGlobalDisabled }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getURLParams,
    currentLocationPathname,
    routeNavigateTo,
    routeNavigateAndClearParams,
    setSearchParams,
    addAndRemoveSearchParams,
    clearSearchParams,
  } = useURLParams();

  const selectedProject = useSelector(getProject);
  const activeProjectUUID = useSelector(getProjectUUID);
  const selectedIssue = useSelector(issueEntity);
  const selectedVector = useSelector(getVectorInfo);
  const selectedTestSuite = useSelector(getTestSuite);
  const selectedTestCycle = useSelector(getTestCycle);
  const selectedGoal = useSelector(getProjectGoal);
  const selectedMilestone = useSelector(getProjectMilestone);
  // const selectedTestCaseSuite = useSelector(singleCaseInSuite);
  // const testCaseParamsInCycle = useSelector(getTestCaseParamsInCycle);
  const selectedProjDocOrIdeas = useSelector(getProjDocOrIdeas);
  const issuesScoringData = useSelector(getIssuesScoringData);
  const vectorsScoringData = useSelector(getVectorsScoringData);

  const {
    activeProject,
    activeVector,
    activeIssue,
    activeVersion,
    activeSuite,
    activeCycle,
    // activeCaseSuite,
    // activeCaseCycle,
    activeDoc,
    activeNote,
    activeSheet,
    goal,
    milestone,
    // activeCookBook,
  } = getURLParams();

  const {
    defaultPartition,
    initialPathForHistory,
  } = useContext(ProjectMainLayerContext);

  const isPM = defaultPartition === partitionNamesConfig.partition1;
  const projectUUID = head(selectedProject)?.uuid;

  const disabledViewCreateIssueModal = ['assignedProject'];
  const currentUserDocPerms = useSelector(getAllDocPerms);
  const isNoDeletePerm = !currentUserDocPerms?.delete;

  const createIssueCallback = (uuid) => {
    if (window.location.pathname?.includes('issuesnew')) {
      addAndRemoveSearchParams({ activeIssue: uuid }, ['activeVector']);
    } else {
      routeNavigateAndClearParams(`${initialPathForHistory}issuesnew`, { activeProject, activeVector, activeIssue: uuid });
    }

    // dispatch(clearGlobalUpdateEntity());
    dispatch(setGlobalUpdateEntity({ entityType: 'vector' }));
  };

  const newIssueModal = (
    <WrapperAddIssue
      partitionType={defaultPartition}
      createIssueCallback={createIssueCallback}
      isGlobalCreateModal
      disabledView={disabledViewCreateIssueModal}
    />
  );

  const newVectorSuccessCallback = (vector, uuid) => {
    routeNavigateAndClearParams(`${initialPathForHistory}issuesnew`, { activeProject, activeVector: uuid });
    // addAndRemoveSearchParams({ activeVector: uuid }, ['activeIssue']);

    dispatch(setGlobalUpdateEntity({ entityType: 'vector', action: 'create', uuid }));
  };

  const newVectorModal = (
    <WrapperAddVector
      vectorType="general"
      partitionType={defaultPartition}
      parentUUID={activeProjectUUID}
      newVectorSuccessCallback={newVectorSuccessCallback}
      titleButton={capitalize(t('wms.entities.vector.label', { verb: t('wms.verb.add') }, 'add vector'))}
    />
  );

  const newTestCase = (
    <NewTestCaseModalHook
      partition={defaultPartition}
    />
  );

  const updateProject = async (props) => {
    const { avgVal: issuesScoringAVG, sum: indexesSum, total: totalCount } = props;
    const data = {
      uuid: activeProject,
      params: { scoringData: { issuesScoringAVG: Number(issuesScoringAVG), indexesSum, totalCount } },
      partition: defaultPartition,
    };
    // console.log('updateProjectScoring data', data);
    dispatch(updateProjectScoring(data));
  };

  const updateIssuesScoringData = async (priorityVal, statusVal) => {
    // actionType, total, prevSum, currentPriority, oldPriority
    try {
      dispatch(getProjectScoring(
        activeProject,
        (resp) => {
          const oldScoringData = resp?.[0]?.params?.scoringData;
          // console.log('oldScoringData', oldScoringData);
          const data = {
            actionType: 'delete',
            sum: oldScoringData?.indexesSum || issuesScoringData?.sum,
            total: oldScoringData?.totalCount || issuesScoringData?.total,
            currentPriority: priorityVal,
            currentStatus: statusVal,
          };
          const newScoringData = updateEntityScoring(data);
          if (newScoringData) {
            dispatch({
              type: ProjectTypeCommonConstants.UPDATE_ISSUES_PRIORITY_INDEXES_DATA,
              payload: newScoringData,
            });
            // console.log('activeProject', activeProject);
            updateProject(newScoringData);
          }
        },
        partitionNamesConfig.partition1,
      ));
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleDeleteFromEntityScoring = (entity) => {
    switch (entity?.entity_type) {
      case 'issue': {
        const data = {
          priority: entity?.params?.priority,
          status: entity?.params?.status,
        };
        updateIssuesScoringData(data?.priority, data?.status);
        break;
      }
      case 'vector': {
        const data = {
          actionType: 'delete',
          sum: vectorsScoringData?.sum,
          total: vectorsScoringData?.total,
          currentPriority: entity?.params?.priority,
          currentStatus: entity?.params?.status,
        };
        const newScoringData = updateEntityScoring(data);
        dispatch({
          type: ProjectTypeCommonConstants.UPDATE_VECTORS_PRIORITY_INDEXES_DATA,
          payload: newScoringData,
        });
        break;
      }
      default:
        return null;
    }
  };

  const deleteEntity = (entity) => {
    const actionForEntity = () => {
      switch (get(entity, 'entity_type')) {
        case 'project': routeNavigateTo(initialPathForHistory); break;
        case 'issue':
          clearSearchParams(['activeIssue']);
          dispatch(clearIssue);
          dispatch(setGlobalUpdateEntity({ entityType: 'issue', action: 'delete' }));
          handleDeleteFromEntityScoring(entity);
          break;
        case 'vector':
          clearSearchParams(['activeVector', 'activeIssue']);
          dispatch(clearVector);
          dispatch(setGlobalUpdateEntity({ entityType: 'vector', action: 'delete' }));
          handleDeleteFromEntityScoring(entity);
          break;
        case 'projectGoal':
          clearSearchParams(['goal']);
          dispatch(setGlobalUpdateEntity({ entityType: 'projectGoal', action: 'delete' }));
          break;
        case 'projectMilestone':
          clearSearchParams(['milestone']);
          dispatch(setGlobalUpdateEntity({ entityType: 'projectMilestone', action: 'delete' }));
          dispatch(DELETE_PROJECT_KPM({ uuid: projectUUID }));
          break;
        case 'testSuite':
          clearSearchParams(['activeSuite', 'activeCaseSuite']);
          dispatch(clearSuite);
          dispatch(setGlobalUpdateEntity({ entityType: 'testSuite', action: 'delete' }));
          break;
        case 'testCycle':
          clearSearchParams(['activeCycle', 'activeCaseCycle']);
          dispatch(clearTestCycle);
          dispatch(setGlobalUpdateEntity({ entityType: 'testCycle', action: 'delete' }));
          break;
        case 'testCase':
          if (get(entity, 'parentType') === 'testSuite') {
            clearSearchParams(['activeCaseSuite']);
            dispatch(setGlobalUpdateEntity({ entityType: 'activeCaseSuite', action: 'delete' }));
          }
          break;
        case 'projectDocument':
        case 'projectIdea':
        case 'projectSpreadsheet':
          clearSearchParams(['activeNote', 'activeDoc', 'activeSheet']);
          dispatch(setGlobalUpdateEntity({ entityType: 'projectDocument', action: 'delete' }));
          break;
        default: break;
      }
    };

    return (
      <HardDeleteEntityContainer
        name="remove"
        sizeBtn="small"
        checkHardDelete
        partitionType={defaultPartition}
        noHideBtn
        entityType={get(entity, 'entity_type') || get(entity, 'affix_type')}
        type={get(entity, 'entity_type') ? 'entity' : 'affix'}
        className="customAntOutDangerBtn customAntOutBtn"
        labelMode
        entityUUID={get(entity, 'uuid')}
        actionForEntity={actionForEntity}
      />
    );
  };

  const crumbs = [
    {
      // headerTitle: isPM
      //   ? capitalize(t('wms.projectflow.header', 'Project flow'))
      //   : capitalize(t('wms.sidemenu.userflow.header.full', 'User flow')),
      label: (
        <Tooltip title={capitalize(t('wms.sidemenu.projectflow.projects', 'projects'))}>
          <Icon path={mdiHome} size={0.75} />
        </Tooltip>
      ),
      link: {
        pathname: `${currentLocationPathname.substring(0, currentLocationPathname.indexOf('projects'))}projects/`,
      },
    },
    {
      headerTitle: capitalize(t('wms.projectflow.breadcrumbs.project', 'project')),
      label: get(selectedProject, '[0].params.title'),
      hide: !activeProject,
      link: {
        pathname: `${currentLocationPathname.substring(0, currentLocationPathname.indexOf('projects'))}projects/project`,
        search: `?activeProject=${activeProjectUUID}&tab=info`,
      },
      menu: [newIssueModal, newVectorModal, deleteEntity(head(selectedProject))],
    },
    {
      headerTitle: capitalize(t('wms.projectflow.breadcrumbs.vector', 'vector')),
      label: get(selectedVector, '[0].params.title'),
      hide: (!activeVector && !activeVersion),
      link: {
        pathname: `${currentLocationPathname.substring(0, currentLocationPathname.indexOf('issuesnew'))}issuesnew`,
        search: `?activeProject=${activeProjectUUID}&${activeVector ? 'activeVector' : 'activeVersion'}=${get(selectedVector, '[0].uuid')}`,
      },
      menu: [newIssueModal, deleteEntity(head(selectedVector))],
    },
    {
      headerTitle: capitalize(t('wms.projectflow.breadcrumbs.issue', 'issue')),
      label: get(selectedIssue, '[0].params.title'),
      hide: !activeIssue,
      menu: [deleteEntity(head(selectedIssue))],
    },
    {
      headerTitle: capitalize(t('wms.noun.goal.0')),
      label: get(selectedGoal, '[0].params.title'),
      hide: !goal,
      menu: [deleteEntity(head(selectedGoal))],
    },
    {
      headerTitle: capitalize(t('wms.noun.milestone.0')),
      label: get(selectedMilestone, '[0].params.title'),
      hide: !milestone,
      menu: [deleteEntity(head(selectedMilestone))],
    },
    {
      headerTitle: capitalize(t('wms.projectflow.breadcrumbs.test_suite', 'test suite')),
      label: get(selectedTestSuite, '[0].params.title'),
      hide: !activeSuite,
      menu: [newTestCase, deleteEntity(head(selectedTestSuite))],
    },
    // {
    //   headerTitle: capitalize(t('wms.projectflow.breadcrumbs.test_case', 'test case')),
    //   label: get(selectedTestCaseSuite, '[0].params.title'),
    //   hide: !activeCaseSuite,
    //   menu: [deleteEntity({ ...head(selectedTestCaseSuite), parentType: 'testSuite' })],
    // },
    {
      headerTitle: capitalize(t('wms.projectflow.breadcrumbs.test_cycle', 'test cycle')),
      label: get(selectedTestCycle, '[0].params.title'),
      hide: !activeCycle,
      menu: [deleteEntity(head(selectedTestCycle))],
    },
    // {
    //   headerTitle: capitalize(t('wms.projectflow.breadcrumbs.test_case', 'test case')),
    //   label: get(testCaseParamsInCycle, '[0].params.title'),
    //   hide: !activeCaseCycle,
    //   menu: [deleteEntity({ ...head(testCaseParamsInCycle), parentType: 'testCycle' })],
    // },
    {
      headerTitle: capitalize(t('wms.projectflow.breadcrumbs.document', 'document')),
      label: get(selectedProjDocOrIdeas, '[0].params.title'),
      hide: !activeDoc,
      disabled: isNoDeletePerm,
      menu: (get(selectedProjDocOrIdeas, '[0]params.type') !== 'project description'
        && get(selectedProjDocOrIdeas, '[0]params.type') !== 'project infrastructure'
      )
        ? [deleteEntity(head(selectedProjDocOrIdeas))] : [],
    },
    {
      headerTitle: capitalize(t('wms.projectflow.breadcrumbs.idea', 'note')),
      label: get(selectedProjDocOrIdeas, '[0].params.title'),
      hide: !activeNote,
      disabled: isNoDeletePerm,
      menu: [deleteEntity(head(selectedProjDocOrIdeas))],
    },
    {
      headerTitle: capitalize(t('wms.projectflow.breadcrumbs.spreadsheet', 'spreadsheet')),
      label: get(selectedProjDocOrIdeas, '[0].params.title'),
      hide: !activeSheet,
      disabled: isNoDeletePerm,
      menu: [deleteEntity(head(selectedProjDocOrIdeas))],
    },
    // {
    //   headerTitle: capitalize(t('wms.projectflow.breadcrumbs.cookbook', 'cookbook')),
    //   label: get(selectedProjDocOrIdeas, '[0].params.title'),
    //   hide: !activeCookBook,
    //   menu: [deleteEntity(head(selectedProjDocOrIdeas))],
    // },
  ].filter((i) => !i?.hide);

  const getLabel = (label, link) => {
    if (link) {
      return <Link to={link}>{label}</Link>;
    }

    return <p>{label}</p>;
  };

  return (
    <div className="breadCrumbsProject">
      {crumbs.map(({
        label,
        link,
        menu,
        disabled,
        headerTitle,
      }, key) => {
        const currentLabel = headerTitle ? `${headerTitle}: ${label}` : label;
        return (
          <div
            className={cn('breadCrumbs_wrapper', { 'last-item': key === crumbs.length - 1 })}
            key={`breadCrumbsProject${key}_${label}`}
          >
            {key !== 0 && (
              <div className="breadCrumbsProject_wrapper-arrow">
                /
              </div>
            )}

            <div className="breadCrumbsProject_crumb">
              <div className="breadCrumbsProject_crumb-card">
                {getLabel(currentLabel, link)}

                {(menu && !isEmpty(menu)) && (
                  <div id={`breadCrumbsProject_${key}_${label}_dropdown`}>
                    <Dropdown
                      getPopupContainer={() => document.getElementById(`breadCrumbsProject_${key}_${label}_dropdown`)}
                      arrow
                      trigger={['hover']}
                      placement="bottom"
                      className="ml-1"
                      disabled={isGlobalDisabled}
                      menu={{
                        items: menu.map((item, id) => ({
                          className: 'ant-custom-dropdown-status-project',
                          key: `breadCrumbsProject_${key}_${label}_${id}`,
                          label: item,
                          disabled: disabled
                        })),
                      }}
                    >
                      <div className="project-caret">
                        <Icon path={mdiChevronDown} size={0.9} />
                      </div>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>

          </div>
        );
      })}
    </div>
  );
}

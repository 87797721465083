import { array, func, string } from "prop-types";
import React, { useState } from "react";
import { capitalizeAndTranslateMsg } from "../../../MainUtils";
import { Button } from "antd";
import Icon from "@mdi/react";
import { mdiPlusMinusBox } from "@mdi/js";
import AssigningUsersRoot from "../../../assignUsersModal/AssigningUsersRoot";
import useAuthActorsRequests from "../../hooks/useAuthActorsRequests";
import { useDispatch } from "react-redux";
import { synchronizeWithAuthAndGetActor } from "../../../userFlow/store/action-creators/userActions";
import { difference } from "lodash";

export default function DayUserReportGroups({
    actorUUID,
    onSaveCallBack,
    actorType,
    groups,
}) {
    const dispatch = useDispatch()

    const { requestUpdateActorOnAuth } = useAuthActorsRequests();

    const [isEditGroups, setEditGroups] = useState(false)

    const getBtnModalLabel = () => {
        switch (actorType) {
            case 'user':
            case 'classic_user':
            case 'service':
            case 'newService':
                return capitalizeAndTranslateMsg('54origins.noun.group.1', 'groups');
            case 'group':
            case 'newGroup':
                return capitalizeAndTranslateMsg('54origins.noun.user.1', 'users');
            default:
                return 'actor';
        }
    };

    const onChangeGroups = async ({
        allUuids,
    }) => {
        const addActorsList = difference(allUuids, groups);
        const removeActorsList = difference(groups, allUuids);

        const data = {
            uuid: actorUUID,
            ...(addActorsList.length ? { add_actors_list: addActorsList } : {}),
            ...(removeActorsList.length ? { remove_actors_list: removeActorsList } : {}),
        };

        if (actorUUID && (addActorsList.length || removeActorsList.length)) {
            await requestUpdateActorOnAuth(data);
            await dispatch(synchronizeWithAuthAndGetActor(actorUUID));
        }
        onSaveCallBack?.()
    };

    return (
        <>
            <Button
                onClick={() => {
                    setEditGroups(true)
                }}
                size={"default"}
                className={`w-full btnPrimary-outline actorsModalBtn`}
            >
                <Icon
                    size={0.8}
                    className="mr-1"
                    path={mdiPlusMinusBox}
                />
                <span>{getBtnModalLabel()}</span>
            </Button>
            <AssigningUsersRoot
                header="Groups"
                isOpen={isEditGroups}
                setIsOpen={setEditGroups}
                isGroupMode={true}
                onUpdateUsers={onChangeGroups}
                globalOptions={{
                    uuids: groups ?? [],
                }}
                isModal
                tabs={{
                    allConfig: {
                        label: 'All groups',
                        value: 'all',
                        textSelectButton: 'Add group',
                        showSections: ['button-add-user'],
                    },
                }}
                usersConfig={{
                    label: 'Groups',
                    showSections: ['button-remove-user'],
                    textDeleteButton: 'Delete group',
                    defaultItems: groups ?? [],
                }}
            />
        </>
    )
}

DayUserReportGroups.propTypes = {
    actorUUID: string,
    onSaveCallBack: func,
    actorType: string,
    groups: array,
}
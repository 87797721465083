import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getListOfIssues } from "../../projectFlow/actions/projectFlowActions";
import { getUserBoard, getUserBoardUUID } from "../selectors/userBoardSelector";
import { get, uniq, omit, isEmpty, isEqual } from "lodash";
import dayjs from "dayjs";
import { computeIssueChunks, computeIssuesChunks } from "../utils";
import { defaultIssueFilter } from "../../projectFlow/components/issuesView/constants/issueFilterOptions";
import useTableFilter from "../../components/_ui/hooks/useTableFilter";
import { excludeSectionFilterResult, getNestedFilters } from "../../components/_ui/helpers/filterHelper";
import { issueStatusFilterOptions } from "../../constants/allFilterOptions";
import { getDaysUntilSunday } from "../../projectFlow/components/project/generalPlanboard/utils";

const defaultIssuesConfig = {
  offset: 0,
  limit: 100,
  depth: 0,
}

// const days = [dayjs().format('YYYY-MM-DD'), dayjs().add(1, 'day').format('YYYY-MM-DD')]
const days = getDaysUntilSunday()

const useSuggestedIssues = ({
  actor,
  partition,
  projectUUID,
}) => {
  const dispatch = useDispatch();

  const userBoard = useSelector(getUserBoard);
  const userBoardUUID = useSelector(getUserBoardUUID);

  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [suggestedIssues, setSuggestedIssues] = useState([]);

  const [showClosed, setShowClosed] = useState(false);

  const [suggestedMode, changeSuggestedMode] = useState('user');

  const [config, setConfig] = useState(defaultIssuesConfig);
  const [currentPage, setCurrentPage] = useState(1);

  const [issueFilterConfig, setFilterConfig] = useState({
    title: getNestedFilters(
      ['validation:section', 'development:section', 'testing:section', 'purgatory:section'],
      issueStatusFilterOptions,
    ),
  });

  const {
    limit,
  } = config;

  const changeMode = (mode) => {
    changeSuggestedMode(mode);
    setConfig(prev => ({ ...prev, offset: 0 }));
    setCurrentPage(1)
  }

  const changeShowClosed = (closed) => {
    setShowClosed(closed);
    setConfig(prev => ({ ...prev, offset: 0 }));
    setCurrentPage(1)
  }

  const onClearSuggestedIssues = () => setSuggestedIssues([])

  const getSuggestedIssues = async (board) => {
    try {
      setIsFetching(true);
      const currentBoard = board || userBoard;
      const uuids_not = uniq(days.flatMap(day => get(currentBoard, ['params', day], [])))

      const newConfig = {
        parent: projectUUID,
        entity_uuids__not: uuids_not,
        ...config,
        params: {
          ...config.params,
          completed__not: [actor],
        },
        partition,
        constants: [
          'GET_ISSUES_SUGGESTED_PLAN_REQUEST',
          'GET_ISSUES_SUGGESTED_PLAN_SUCCESS',
          'GET_ISSUES_SUGGESTED_PLAN_FAILURE',
        ],
      }

      if (issueFilterConfig?.title?.length > 0) {
        newConfig.params.status = issueFilterConfig?.title
      }

      if (suggestedMode === 'user') {
        newConfig.params.usersSearch = [actor];
      } else if (suggestedMode === 'all') {
        delete newConfig.params.usersSearch;
      }
      if (showClosed) {
        delete newConfig.params.completed__not;
      }

      const res = await dispatch(
        getListOfIssues(newConfig),
      );

      if (res?.data) {
        setTotal(res?.total)
        const issues = res?.data?.filter(el => !uuids_not.includes(el?.uuid));

        const isssueChunks = computeIssuesChunks(issues)
        const issuesWithData = issues?.map(el => {
          const chunks = isssueChunks?.find(issue => issue?.uuid === el?.uuid)
          return {
            ...el,
            done: el?.completed?.includes(actor),
            data: {
              chunk: chunks?.chunk
            }
          }
        })
        setSuggestedIssues(issuesWithData)
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  };

  const onUpdateSuggestedIssue = (issue = {}) => {
    const uuid = get(issue, 'uuid', '');

    const isssueChunks = computeIssueChunks(issue);

    setSuggestedIssues(prev => prev.map(el => {
      if (el?.uuid === uuid) {
        return {
          ...el,
          ...issue,
          data: {
            ...get(el, 'data', {}),
            chunk: +get(isssueChunks, 'chunk', 0),
          },
        }
      }
      return el;
    }))
  }

  const prepareFilterConfig = (config) => {
    const fixedConfig = { ...config };
    if (!isEmpty(fixedConfig)) {
      const filterKeys = Object.keys(fixedConfig);
      filterKeys.forEach(
        (key) => fixedConfig[key] = excludeSectionFilterResult(fixedConfig[key]),
      );
    }
    return fixedConfig;
  };

  useEffect(() => {
    if (partition && projectUUID && userBoardUUID) {
      getSuggestedIssues();
    }
  }, [partition, projectUUID, suggestedMode, actor, userBoardUUID, config, showClosed])


  return {
    suggestedIssues,
    isFetchingSuggestedIssues: isFetching,
    onUpdateSuggestedIssue,
    onReloadSuggestedIssues: getSuggestedIssues,
    onClearSuggestedIssues,
    suggestedOptions: {
      showClosed,
      setShowClosed: changeShowClosed,
      mode: suggestedMode,
      changeMode,
    },
    paginationSettings: {
      limit,
      total,
      issueFilterConfig,
      changeIssueFilterConfig: (config) => {
        const fixedConfig = prepareFilterConfig(config);
        console.log(issueFilterConfig, fixedConfig)
        if (!isEqual(issueFilterConfig, fixedConfig)) {
          setFilterConfig(fixedConfig);
          setConfig(prev => ({ ...prev, offset: 0 }));
          setCurrentPage(1)
        };
      },
      newCurrentPage: currentPage,
      setLimit: (newLimit) => {
        setCurrentPage(1);
        setConfig(prev => ({ ...prev, offset: 0, limit: newLimit }));
      },
      setNewPage: (newPage) => {
        setCurrentPage(newPage);
        setConfig(prev => ({ ...prev, offset: (newPage - 1) * prev.limit }))
      },
      setPartOfConfig: (newPart) => {
        const valueSearch = get(newPart, 'search', '')

        if (valueSearch) {
          setConfig(prev => ({
            ...prev,
            ...omit(newPart, ['search']),
            searchData: {
              value: valueSearch,
              ignore_case: true,
              fields: { params: ['title', 'id'] },
            }
          }))
          return;
        } else {
          setConfig(prev => ({ ...omit(prev, 'searchData'), newPart }))
        }

      },
    },
  }
}

export default useSuggestedIssues;
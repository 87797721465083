import { get, head } from 'lodash';
import { createSelector } from 'reselect';

export const getMainWorkConfigData = (state) => get(state, ['ProfileService', 'workConfig', 'mainWorkConfigData'], []);
export const getMainWorkConfigDataUUID = (state) => get(head(getMainWorkConfigData(state)), 'uuid', '');
export const getMainWorkConfigIsLoading = (state) => get(state, ['ProfileService', 'workConfig', 'mainWorkConfigIsLoading'], false);
export const getMainWorkConfigError = (state) => get(state, ['ProfileService', 'workConfig', 'mainWorkConfigError'], '');

export const getWorkConfigData = (state) => get(state, ['ProfileService', 'workConfig', 'workConfigData'], []);
export const getWorkConfigIsLoading = (state) => get(state, ['ProfileService', 'workConfig', 'workConfigIsLoading'], false);
export const getWorkConfigError = (state) => get(state, ['ProfileService', 'workConfig', 'workConfigError'], '');

export const getWorkConfigListData = (state) => get(state, ['ProfileService', 'workConfig', 'workConfigListData'], []);
export const getWorkConfigListIsLoading = (state) => get(state, ['ProfileService', 'workConfig', 'workConfigListIsLoading'], false);
export const getWorkConfigListError = (state) => get(state, ['ProfileService', 'workConfig', 'workConfigListError'], '');

export const getWorkConfigListDataTemplateFirstCustomLast = createSelector(
  getWorkConfigListData,
  (data) => [...data].sort((a) => (get(a, ['params', 'type'], '') !== 'custom' ? -1 : 1)),
);

export const getWorkConfigListDataWorkScheduleFirst = createSelector(
  getWorkConfigListDataTemplateFirstCustomLast,
  (data) => [...data].sort((a) => (get(a, ['params', 'type'], '') !== 'work schedule' ? 1 : -1)),
);

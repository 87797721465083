import { get, uniq } from 'lodash';
import { priorityWeightFormatter } from '../issuesView/utils';

// eslint-disable-next-line import/prefer-default-export
export const indexesSheet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

export const priorityKeys = ['low', 'medium', 'high', 'urgent'];

export const estimatedTimeKeys = ['easy', 'normal', 'difficult', 'very_complicated'];

export const trackerKeys = ['ticket', 'feature', 'bug'];

export const defaultProjectTags = ['frontend', 'backend', 'mobile', 'qa'];

export const issueStatusKeys = [
  'created',
  'acquainted',
  'accepted',
  'rejected',
  'in progress',
  'review',
  'ready for deploy',
  'test',
  'testing',
  'not reproducible',
  'backlog',
  'blocked',
  'paused',
  'discuss',
  'closed',
  'deployed',
  'abandoned',
  'reopened',
  'not enough info',
];

export const typeKeys = [
  'general',
  'version',
  'deploy',
];

export const vectorStatusKeys = [
  'created',
  'planning',
  'protected',
  'active',
  'closed',
  'blocked',
  'paused',
  'in progress',
  'backlog',
];

const priority = [
  { id: 'low', name: 'Low' },
  { id: 'medium', name: 'Medium' },
  { id: 'high', name: 'High' },
  { id: 'urgent', name: 'Urgent' },
];

export const checkListStatusKeys = ['pass', 'failed', 'blocked', 'in progress', 'unexecuted'];

export const columnsCheckList = [
  {
    name: 'id',
    title: 'Id',
    width: 50,
  },
  {
    name: 'title',
    title: 'Title',
    width: 210,
  },
  {
    name: 'description',
    title: 'Description',
    width: 240,
  },
  {
    type: 'dropdown',
    source: [
      { id: 'low', name: 'Low' },
      { id: 'medium', name: 'Medium' },
      { id: 'high', name: 'High' },
      { id: 'urgent', name: 'Urgent' },
    ],
    name: 'priority',
    title: 'Priority',
    width: 110,
  },
  {
    type: 'dropdown',
    source: [
      { id: 'pass', name: 'Pass' },
      { id: 'failed', name: 'Failed' },
      { id: 'blocked', name: 'Blocked' },
      { id: 'in progress', name: 'In progress' },
      { id: 'unexecuted', name: 'Unexecuted' },
    ],
    name: 'status',
    title: 'Status',
    width: 110,
  },
];

export const columnsMultiEditIssue = [
  {
    name: 'id',
    title: 'tID',
    readOnly: true,
    width: 50,
  },
  {
    name: 'title',
    title: 'Title',
    width: 200,
    align: 'left',
  },
  {
    type: 'dropdown',
    name: 'tags',
    title: 'Tags',
    multiple: true,
    source: [
      { id: 'frontend', name: 'Frontend' },
      { id: 'backend', name: 'Backend' },
      { id: 'mobile', name: 'Mobile' },
      { id: 'qa', name: 'QA' },
    ],
    width: 200,

  },
  {
    name: 'description',
    title: 'Description',
    width: 200,
    align: 'left',
  },
  {
    name: 'result',
    title: 'Result',
    width: 200,
    align: 'left',
  },
  {
    type: 'dropdown',
    source: priority,
    name: 'priority',
    title: 'Priority',
    width: 110,
  },
  {
    type: 'dropdown',
    source: [
      { id: 'ticket', name: 'Ticket' },
      { id: 'feature', name: 'Feature' },
      { id: 'bug', name: 'Bug' },
    ],
    name: 'tracker',
    title: 'Tracker',
    width: 110,
  },
  {
    type: 'dropdown',
    source: [
      { id: 'created', name: 'Created' },
      { id: 'acquainted', name: 'Acquainted' },
      { id: 'accepted', name: 'Accepted' },
      { id: 'rejected', name: 'Rejected' },
      { id: 'in progress', name: 'In progress' },
      { id: 'review', name: 'Ready for review' },
      { id: 'ready for deploy', name: 'Ready for deploy' },
      { id: 'test', name: 'Ready for test' },
      { id: 'testing', name: 'Testing' },
      { id: 'not reproducible', name: 'Not reproducible' },
      { id: 'backlog', name: 'Backlog' },
      { id: 'blocked', name: 'Blocked' },
      { id: 'paused', name: 'Paused' },
      { id: 'discuss', name: 'Discuss' },
      { id: 'closed', name: 'Closed' },
      { id: 'deployed', name: 'Deployed' },
      { id: 'abandoned', name: 'Abandoned' },
      { id: 'reopened', name: 'Reopened' },
      { id: 'not enough info', name: 'Not enough info' },
    ],
    name: 'status',
    title: 'Status',
    width: 130,
  },
  {
    name: 'users',
    title: 'Users',
    width: 200,
    type: 'hidden',
    multiple: true,
  },
  {
    type: 'calendar',
    name: 'lifeTime',
    title: 'Expired',
    options: { format: 'YYYY-MM-DD' },
    width: 100,
  },
  {
    type: 'dropdown',
    source: [
      { id: 'easy', name: 'Easy' },
      { id: 'normal', name: 'Normal' },
      { id: 'difficult', name: 'Difficult' },
      { id: 'very_complicated', name: 'Very Complicated' },
    ],
    name: 'estimated_time',
    title: 'Estimated time',
    width: 140,
  },
  {
    type: 'hidden',
    name: 'uuid',
    title: 'uuid',
    width: 0,
  },
];

export const columnsMultiEditVectors = [
  {
    name: 'id',
    title: 'vID',
    readOnly: true,
    width: 50,
  },
  {
    name: 'title',
    title: 'Title',
    width: 200,
    align: 'left',
  },
  {
    name: 'description',
    title: 'Description',
    width: 200,
    align: 'left',
  },
  {
    type: 'dropdown',
    source: priority,
    name: 'priority',
    title: 'Priority',
    width: 110,
  },
  {
    type: 'dropdown',
    source: [
      { id: 'general', name: 'General' },
      { id: 'version', name: 'Version' },
      { id: 'deploy', name: 'Deploy' },
    ],
    name: 'type',
    title: 'Type',
    width: 110,
  },
  {
    type: 'dropdown',
    source: [
      { id: 'created', name: 'Created' },
      { id: 'planning', name: 'Planning' },
      { id: 'protected', name: 'Protected' },
      { id: 'active', name: 'Active' },
      { id: 'closed', name: 'Closed' },
      { id: 'blocked', name: 'Blocked' },
      { id: 'paused', name: 'Paused' },
      { id: 'in progress', name: 'In progress' },
      { id: 'backlog', name: 'Backlog' },
    ],
    name: 'status',
    title: 'Status',
    width: 110,
  },
  {
    name: 'users',
    title: 'Assigned',
    width: 200,
    multiple: true,
  },
  {
    type: 'calendar',
    name: 'lifeTime',
    title: 'Expired',
    options: { format: 'YYYY-MM-DD' },
    width: 100,
  },
  {
    type: 'hidden',
    name: 'uuid',
    title: 'uuid',
    width: 0,
  },
];

export const prepereUsersForRequest = (users) => {
  const usersUUIDS = typeof users === 'string' ? users.split(';') : users;

  return {
    usersData: usersUUIDS.map((el) => ({ uuid: el })),
    usersSearch: usersUUIDS,
  };
};

export const prepareActors = (usersSearch, allActors) => {
  const users = usersSearch;

  const actors = [];

  for (let i = 0; i < users?.length; i++) {
    if (allActors.has(users[i])) {
      const user = allActors.get(users[i]);
      actors.push({ id: user?.value, name: user?.fullName });
    }
  }
  return actors;
};

const notActiveStatusesArr = ['backlog', 'rejected', 'closed', 'deployed', 'abandoned'];

const statusWeightIndexRatio = (entityStatus) => {
  const checkIsNullableStatus = notActiveStatusesArr.some((status) => status === entityStatus);
  return checkIsNullableStatus ? 0 : 1;
};

const projectScoringBackground = (val) => {
  // console.log('projectScoringBackground', val);
  if (val >= 3) {
    return 'error';
  }
  if (val >= 2) {
    return 'warning';
  }
  if (val >= 1) {
    return 'processing';
  }
  if (val >= 0 && val < 1) {
    return 'default';
  }
};

export const entityScoringMultiUpdate = (oldDataArray, newDataArray, oldScoringData) => {
  // console.log('oldScoringData', oldScoringData);
  try {
    const { sum: oldSum, total } = oldScoringData;
    let newTotalCount = total;

    const newData = newDataArray.map((newItem) => {
      const oldItemData = oldDataArray.find((oldItem) => oldItem?.uuid === newItem?.uuid);
      const checkOldStatus = statusWeightIndexRatio(oldItemData?.status);
      const checkCurrentStatus = statusWeightIndexRatio(newItem?.status);
      newTotalCount = newTotalCount - checkOldStatus + checkCurrentStatus;
      const oldValue = priorityWeightFormatter(oldItemData?.priority, oldItemData?.status);
      const newValue = priorityWeightFormatter(newItem?.priority, newItem?.status);
      return newValue - oldValue;
    });

    const newSum = oldSum + newData.reduce((partialSum, a) => partialSum + a, 0);
    const startScoringVal = (Math.round((newSum / newTotalCount) * 100) / 100);
    const projectIssueScoringVal = startScoringVal ? startScoringVal.toFixed(2) : newTotalCount ? 0 : '-';
    const background = projectScoringBackground(Number(projectIssueScoringVal));
    // console.log('entityScoringMultiUpdate', newSum, newTotalCount);
    const finalData = {
      sum: newSum,
      avgVal: !projectIssueScoringVal ? 0 : projectIssueScoringVal,
      background,
      total: newTotalCount,
    };
    // console.log('oldScoringData', oldScoringData);
    // console.log('newScoringData', finalData);
    return finalData;
  } catch (e) {
    console.log('e', e);
  }
};

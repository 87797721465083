import { ProjectTypeCommonConstants } from '../../constants/Constants';

const initialState = {
  fetching: true,
  fetchingChecklist: false,
  data: [],
  singleChecklist: [],
  error: '',
  updating: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ProjectTypeCommonConstants.LIST_OF_CHECKLIST_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.LIST_OF_CHECKLIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_CHECKLIST_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        error: payload,
      };
    case ProjectTypeCommonConstants.GET_CHECKLIST_REQUEST:
      return {
        ...state,
        fetchingChecklist: true,
      };
    case ProjectTypeCommonConstants.GET_CHECKLIST_SUCCESS:
      return {
        ...state,
        fetchingChecklist: false,
        singleChecklist: payload,
      };
    case ProjectTypeCommonConstants.GET_CHECKLIST_FAILURE:
      return {
        ...state,
        fetchingChecklist: false,
        singleChecklist: [],
        error: payload,
      };
    case ProjectTypeCommonConstants.EDIT_CHECKLIST_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case ProjectTypeCommonConstants.EDIT_CHECKLIST_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case ProjectTypeCommonConstants.EDIT_CHECKLIST_FAILURE:
      return {
        ...state,
        updating: false,
      };

    case ProjectTypeCommonConstants.UPDATE_CHECKLIST_IN_STORE:
      return {
        ...state,
        singleChecklist: payload,
      };

    default: return state;
  }
};

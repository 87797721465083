import {
  array, bool, func, object, string,
} from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BtnModal from '../../BtnModalComponent';
import NewIssueComponents from './NewIssueComponents';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

import { capitalize } from 'lodash';
import useActorsW54ModalStyle from '../../../../actors/hooks/useActorsW54ModalStyle';
import { Tooltip } from 'antd';

export default function NewIssueModal({
  hasCreatePerm = true,
  closeModalCallback,
  openFromOuterComponent,
  blockPropagationModal,
  hideAddIssueBtn,
  hideView,
  disabledView,
  disableBtn,
  partitionType,
  defaultIssueConfig,
  customParentIssue,
  createIssueCallback,
  customTitleOpenModalBtn,
  mandatoryIssue = false
}) {
  const { t } = useTranslation();

  const [isChanged, setIsChanged] = useState(false);

  const [isAssignOpen, setIsAssignOpen, styleNewIssueModal] = useActorsW54ModalStyle();

  const disabled = disableBtn || !hasCreatePerm

  const confirmDescription = capitalize(
    t('wms.modals.body.close_modal_new_issue', 'the entered data will be lost, are you sure?'),
  );

  const getTooltipTitle = () => {
    if (!hasCreatePerm) {
      return "You don't have 'create' permission!"
    }
    return null;
  }

  const openModalBtn = !hideAddIssueBtn && (
    <Tooltip
      color={"grey"}
      title={getTooltipTitle()}
    >
      <BaseButton
        id='addIssueButton'
        size="small"
        className={!disabled && "customAntOutPrimaryBtn"}
        type="primary"
        ghost
        disabled={disabled}
      >
        {customTitleOpenModalBtn || capitalize(t('wms.issues.buttons.add_issue'))}
      </BaseButton>
    </Tooltip>
  );

  return (
    <BtnModal
      width={800}
      openFromOuterComponent={openFromOuterComponent}
      blockPropagation={blockPropagationModal}
      confirmOnClose={false}
      confirmDescription={confirmDescription}
      centered
      style={styleNewIssueModal}
      footer={null}
      btnComponent={openModalBtn}
      handleCancelCallbackFunc={closeModalCallback}
    >
      <NewIssueComponents
        isAssignOpen={isAssignOpen}
        setIsAssignOpen={setIsAssignOpen}
        hideView={hideView}
        disabledView={disabledView}
        partitionType={partitionType}
        defaultIssueConfig={defaultIssueConfig}
        customParentIssue={customParentIssue}
        createIssueCallback={createIssueCallback}
        setIsChanged={setIsChanged}
        mandatoryIssue={mandatoryIssue}
      />
    </BtnModal>
  );
}

NewIssueModal.propTypes = {
  closeModalCallback: func,
  openFromOuterComponent: bool,
  blockPropagationModal: bool,
  hideAddIssueBtn: bool,
  hideView: array,
  partitionType: string,
  defaultIssueConfig: object,
  createIssueCallback: func,
  customParentIssue: string,
  disabledView: array,
  disableBtn: bool,
  hasCreatePerm: bool,
  customTitleOpenModalBtn: string,
  mandatoryIssue: bool
};

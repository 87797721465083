import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "antd";
import { head, isEmpty, isNil, omit, path, pathOr, prop, propOr } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";

import CookBookTree from "./CookBookTree";
import ActionCookBook from "./ActionCookBook";
import CurrentProjectTemplate from "./CurrentProjectTemplate";
import CookBookTemplateList from "./CookBookTemplateList";
import CheckBuildVersion from "../../../components/checkBuildVersionComponent/CheckBuildVersionComponent";

import { getPartitionPMUUID } from "../../../config/selectors/selectors";
import { ProjectTypeCommonConstants } from "../../constants/Constants";
import {
  commonCookBookEntityUuid,
  cookBookFetchingCommon,
  cookBookFetchingPublic,
  cookBookPublicTemplate,
  cookBookTemplate,
  newPublicCookBookEntityUuid,
} from "../../selectors/selectors";
import { antNotification } from "../../../MainUtils";
import { capitalize } from 'lodash';
import { clearCookBookStore } from "../../actions/PtcActionsForHook";
import useUserPublicPartition from "../../../hooks/useUserPublicPartition";
import useProjectURL from "../../../hooks/useProjectURL";
import {
  getOtherUserPublicProfileUUID,
  getUserPublicProfileUUID,
} from "../../../userFlow/store/selectors/selectors";
import {
  incrementAndGetCount,
  entityCreate,
  getListAndPartialReadEntities,
} from "../../../entity/actions/entityActions";
import { getCookBookEntityFromPM } from "../../actions/projectFlowActions";
import useURLParams from "../../../hooks/useURLParams";
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import { partitionNamesConfig } from "../../../api/appConfig";

function CookBookStartPage({
  customParent,
  currentPartition = partitionNamesConfig.partition1,
  entityType = "project",
  otherUser = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getURLParams, clearSearchParams } = useURLParams();

  const commonCookBookEntityUUID = useSelector(commonCookBookEntityUuid);
  const newBookEntityPublicUUID = useSelector(newPublicCookBookEntityUuid);
  const pmPartitionUUID = useSelector(getPartitionPMUUID);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const cookBookTemplateList = useSelector(cookBookTemplate);
  const templateListVectors = useSelector((state) =>
    cookBookTemplate(state, "vectors")
  );
  const cookBookPublicTemplateList = useSelector(cookBookPublicTemplate);
  const PublicTemplateListVectors = useSelector((state) =>
    cookBookPublicTemplate(state, "vectors")
  );
  const [{ userUUIDFromUrl }] = useProjectURL();
  const otherUserPublicProfileUUID = useSelector(getOtherUserPublicProfileUUID);
  const fetchingCommon = useSelector(cookBookFetchingCommon);
  const fetchingPublic = useSelector(cookBookFetchingPublic);

  const { activeCookBook } = getURLParams();

  const myUserPublicProfileUUID = myPublicUUID || userUUIDFromUrl;
  const finalPublicUserUUID = otherUser
    ? otherUserPublicProfileUUID
    : myUserPublicProfileUUID;

  const { mainPublicCookBookUUID, otherUserPublicCookBookUUID } =
    useUserPublicPartition(finalPublicUserUUID, "publicCookBookUUID", false);

  const currentPublicCookBookUUID = otherUser
    ? otherUserPublicCookBookUUID
    : mainPublicCookBookUUID;

  const { actorMainPublicProjectUUID } = useUserPublicPartition(
    myPublicUUID,
    "mainPublicProjectUUID"
  );
  const commonEntityUuid = commonCookBookEntityUUID;
  const publicEntityUuid = mainPublicCookBookUUID || newBookEntityPublicUUID;

  const initialCookBookState = {
    jsObject: {},
    error: !customParent,
    text: "{}",
  };

  const initialSelectedEntity = {
    type: "project",
    id: 0,
  };
  const defaultTemplateConfig = {
    entity_type: "CookBookTemplate",
    limit: 5,
    offset: 0,
  };
  const defaultTotalCount = { pm: 0, public: 0 };
  const defaultListConfig = {
    pm: { parent: commonEntityUuid, ...defaultTemplateConfig },
    public: { parent: publicEntityUuid, ...defaultTemplateConfig },
  };

  const [cookBookState, setCookBookState] = useState(initialCookBookState);
  const [selectedEntity, setSelectedEntity] = useState(initialSelectedEntity);
  const [expand, setExpanded] = useState(true);
  const [currentSelectedRow, setCurrentSelectedRow] = useState({});
  const [totalCount, setTotalCount] = useState(defaultTotalCount);
  const [lestConfigIssue, setLestConfigIssue] = useState(defaultListConfig);

  const setTemplate = (templateType, templateData, customEntityType) => {
    switch (templateType) {
      case "project":
        const params = {
          archive: false,
          areas: [],
          description: "",
          goal: "",
          status: "created",
          title: "Project name",
          usersSearch: [],
        };
        const data = {
          error: false,
          jsObject: prop("value", templateData) || {
            entity_type: "project",
            params,
          },
        };

        if (!isNil(customEntityType)) {
          data.entityType = customEntityType;
        } else if (entityType === "vector") {
          data.entityType = "vector";
        } else if (!isNil(prop("entityType", cookBookState))) {
          data.entityType = propOr("", "entityType", cookBookState);
        }

        setCookBookState(data);
        break;
      case "vector": {
        const params = {
          users: [],
          usersSearch: [],
          dateEnd: "",
          dateStart: "",
          description: "",
          priority: "low",
          status: "active",
          // subType: 'none',
          title: "Vector name",
          type: "general",
        };

        if (
          !pathOr("", ["jsObject", "entity_type"], cookBookState) &&
          entityType === "project"
        ) {
          const newData = initialCookBookState;

          if (!isNil(customEntityType)) {
            newData.entityType = customEntityType;
          } else if (entityType === "vector") {
            newData.entityType = "vector";
          } else if (!isNil(prop("entityType", cookBookState))) {
            newData.entityType = propOr("", "entityType", cookBookState);
          }

          setCookBookState(newData);
          break;
        }

        const data = (prev) => {
          const newData = {
            error: false,
            // entityType: prop('entityType', templateData),
            jsObject: {
              ...prev.jsObject,
              vectors: [
                ...pathOr([], ["jsObject", "vectors"], prev),
                prop("value", templateData) || {
                  entity_type: "vector",
                  params,
                },
              ],
            },
          };

          if (!isNil(customEntityType)) {
            newData.entityType = customEntityType;
          } else if (entityType === "vector") {
            newData.entityType = "vector";
          } else if (!isNil(prop("entityType", cookBookState))) {
            newData.entityType = propOr("", "entityType", cookBookState);
          }

          return newData;
        };

        setCookBookState(data);
        break;
      }
      case "issue": {
        const params = {
          users: [],
          usersSearch: [],
          date_end: "",
          date_start: "",
          description: "",
          estimated_time: "",
          priority: "low",
          status: "created",
          title: "Issue name",
          tracker: "ticket",
        };

        if (selectedEntity.type === "vector") {
          setCookBookState((prev) => {
            const newData = {
              error: false,
              jsObject: {
                ...prev.jsObject,
                vectors: [...pathOr([], ["jsObject", "vectors"], prev)].map(
                  (vector, id) => {
                    if (id === selectedEntity.id) {
                      return {
                        ...vector,
                        issues: [
                          ...propOr([], "issues", vector),
                          {
                            entity_type: "issue",
                            params,
                          },
                        ],
                      };
                    }
                    return vector;
                  }
                ),
              },
            };

            if (!isNil(customEntityType)) {
              newData.entityType = customEntityType;
            } else if (entityType === "vector") {
              newData.entityType = "vector";
            } else if (!isNil(prop("entityType", cookBookState))) {
              newData.entityType = propOr("", "entityType", cookBookState);
            }

            return newData;
          });
        } else {
          setCookBookState((prev) => {
            const newData = {
              error: false,
              jsObject: {
                ...prev.jsObject,
                issues: [
                  ...pathOr([], ["jsObject", "issues"], prev),
                  {
                    entity_type: "issue",
                    params,
                  },
                ],
              },
            };

            if (!isNil(customEntityType)) {
              newData.entityType = customEntityType;
            } else if (entityType === "vector") {
              newData.entityType = "vector";
            } else if (!isNil(prop("entityType", cookBookState))) {
              newData.entityType = propOr("", "entityType", cookBookState);
            }

            return newData;
          });
        }
        break;
      }
      default:
        break;
    }
  };

  // const handleOnChange = (e) => {
  //   const { jsObject, error, plainText } = e;
  //   console.log('e',e)
  //   setCookBookState(
  //     {
  //       error: error || !jsObject || isEmpty(jsObject),
  //       jsObject: jsObject || JSON.parse(plainText) || {}
  //     }
  //   );
  // };

  const createRequest = async (entityType, params, customPartition) => {
    let typeConst;

    switch (entityType) {
      case "project":
        typeConst = "NEW_PROJECT";
        break;
      case "vector":
        typeConst = "NEW_VECTOR";
        break;
      case "issue":
        typeConst = "NEW_ISSUE";
        break;
      default:
        break;
    }

    const constants = [
      ProjectTypeCommonConstants[`${typeConst}_REQUEST`],
      ProjectTypeCommonConstants[`${typeConst}_SUCCESS`],
      ProjectTypeCommonConstants[`${typeConst}_FAILURE`],
    ];

    const options = {
      partition: customPartition,
      onSuccess: (response) => {
        if (response) {
          switch (entityType) {
            case "project":
              antNotification(
                "success",
                t(
                  "notifications.text.success.new_project_created",
                  "Project was created successfully"
                )
              );
              break;
            case "vector":
              antNotification(
                "success",
                t(
                  "notifications.text.success.new_vector_created",
                  "Vector has been successfully added"
                )
              );
              break;
            case "issue":
              antNotification(
                "success",
                t(
                  "notifications.text.success.new_issue_created",
                  "Issue has been successfully added"
                )
              );
              break;
            default:
              break;
          }
        }
      },
    };

    return dispatch(
      entityCreate({
        data: params,
        constants,
        options,
      })
    );
  };

  const createIssues = async (
    issues,
    projectUUID,
    parent = "",
    customPartition,
    idVector
  ) => {
    for (const issue of issues) {
      const countIssue = await dispatch(
        incrementAndGetCount(
          {
            entity_uuid: parent || projectUUID,
            field_name: "issue",
          },
          customPartition
        )
      );

      await createRequest(
        "issue",
        {
          ...issue,
          parent: parent || projectUUID,
          params: {
            ...issue.params,
            id: `${idVector ? `V${idVector}` : ""}T${get(
              countIssue,
              "increment"
            )}`,
            project: projectUUID,
            vector: parent,
          },
        },
        customPartition
      );
    }
  };

  const createVectors = async (uuid, customPartition) => {
    const { jsObject } = cookBookState;

    if (jsObject.vectors && jsObject.vectors.length > 0) {
      for (const vector of jsObject.vectors) {
        const countVector = await dispatch(
          incrementAndGetCount(
            {
              entity_uuid: uuid,
              field_name: "vector",
            },
            customPartition
          )
        );

        const idVector = get(countVector, "increment");

        const vectorObject = {
          ...vector,
          parent: uuid,
          params: {
            ...get(vector, "params", {}),
            id: idVector,
            issue_increment: 0,
          },
        };

        const response = await createRequest(
          "vector",
          omit(["issues"], vectorObject),
          customPartition
        );

        if (vector.issues && vector.issues.length > 0) {
          await createIssues(
            vector.issues,
            uuid,
            head(response).uuid,
            customPartition,
            idVector
          );
          // console.log('create-Issue-Vector', response);
        }
      }
    }
  };

  const toResetArea = () => {
    if (activeCookBook) {
      clearSearchParams(["activeCookBook"]);
    }

    setCookBookState(initialCookBookState);
    setSelectedEntity(initialSelectedEntity);
    setCurrentSelectedRow({});
  };

  const createEntities = async (type = partitionNamesConfig.partition1, parentUUID, callback) => {
    const { jsObject } = cookBookState;

    let response = [];

    if (!customParent && !parentUUID) {
      const projectParams = {
        ...jsObject,
        parent: type === partitionNamesConfig.partition1 ? pmPartitionUUID : actorMainPublicProjectUUID,
      };

      response = await createRequest(
        "project",
        omit(["vectors", "issues", "uuid"], projectParams),
        type
      );
    }

    if (callback && !parentUUID) {
      callback(head(response));
    }

    const parenUuid = parentUUID || propOr("", "uuid", head(response));

    await createVectors(parenUuid, type);
    // console.log('Finish---------vector');
    if (callback && parentUUID) {
      callback(true);
    }

    if (jsObject.issues && jsObject.issues.length > 0) {
      await createIssues(jsObject.issues, parenUuid, "", type);
    }
    // console.log('Finish---------issue');
  };

  const onSelectEntity = (entity) => {
    setSelectedEntity(JSON.parse(entity));
  };

  const getListTemplateCookBook = async (param, partition) => {
    const isPM = partition === partitionNamesConfig.partition1;
    // const publicCookBookUuid = currentPublicCookBookUUID || uuid;
    // const parentUuid = isPM ? uuid : publicCookBookUuid;

    const typeConstants = isPM
      ? "GET_LIST_COOK_BOOK"
      : "GET_LIST_COOK_BOOK_PUBLIC";

    const config = {
      data: {
        // entity_type: 'CookBookTemplate',
        // parent: parentUuid,
        ...param,
        params_fields: {
          label: "label",
          value: "value",
          entityType: "entityType",
        },
        fields: { entity_type: "entity_type" },
      },
      partition,
      constants: [
        ProjectTypeCommonConstants[`${typeConstants}_REQUEST`],
        ProjectTypeCommonConstants[`${typeConstants}_SUCCESS`],
        ProjectTypeCommonConstants[`${typeConstants}_FAILURE`],
      ],
    };

    if (prop("parent", param) && !isEmpty(prop("parent", param))) {
      const res = await dispatch(getListAndPartialReadEntities(config));
      // console.log('5555', isPM ? 'pm' : 'public', res)
      setTotalCount((state) => ({
        ...state,
        [isPM ? "pm" : "public"]: prop("total", res),
      }));
      return prop("data", res);
    }
  };

  const selectCookbookCallback = (template, partition) => {
    if (entityType === "project") {
      setCookBookState(initialCookBookState);
      setSelectedEntity(initialSelectedEntity);
    }
    // console.log('template',template, partition)
    setCurrentSelectedRow({
      data: prop("uuid", template),
      partition,
    });
    // console.log('template',template)
    setTemplate(
      path(["value", "entity_type"], template),
      template,
      prop("entityType", template)
    );
  };

  const removeCookBookTemplateCallback = (uuid, partition) => {
    const currentEntityUuid =
      partition === partitionNamesConfig.partition1 ? commonEntityUuid : publicEntityUuid;

    getListTemplateCookBook(
      {
        ...lestConfigIssue[partition === partitionNamesConfig.partition1 ? "pm" : "public"],
        parent: currentEntityUuid,
      },
      partition
    );

    if (activeCookBook === uuid) {
      toResetArea();
    }
  };

  const importVector = (JsonData) => {
    if (!isEmpty(prop("jsObject", cookBookState))) {
      setCookBookState({
        ...cookBookState,
        jsObject: {
          ...prop("jsObject", cookBookState),
          vectors: [
            ...pathOr([], ["jsObject", "vectors"], cookBookState),
            ...pathOr([], ["jsObject", "vectors"], JsonData),
          ],
        },
      });
    } else {
      setCookBookState(JsonData);
      // antNotification('error', 'Data insertion is not possible...');
    }
  };

  const getListTemplateCallback = (uuid, partition) => {
    // console.log('lestConfigIssue',lestConfigIssue, 'partition',partition)
    const currentUuid =
      partition === partitionNamesConfig.partition1 ? commonEntityUuid : publicEntityUuid;
    getListTemplateCookBook(
      {
        ...lestConfigIssue[partition === partitionNamesConfig.partition1 ? "pm" : "public"],
        parent: currentUuid || uuid,
      },
      partition
    );
  };

  const importProject = (JsonData) => {
    if (isEmpty(prop("jsObject", cookBookState))) {
      setCookBookState(JsonData);
    } else {
      antNotification("error", "Data insertion is not possible...");
    }
  };

  const importCookBookCallback = (JsonData) => {
    const type = prop("entityType", JsonData);

    if (type === "vector") {
      importVector(JsonData);
    } else if (type === "project") {
      importProject(JsonData);
    }
  };

  const getFromPmMainEntityCookbookAndListCookbook = async () => {
    if (pmPartitionUUID) {
      let mainCookBookEntity = await dispatch(
        getCookBookEntityFromPM(pmPartitionUUID)
      );
      mainCookBookEntity = get(mainCookBookEntity, "uuid");

      getListTemplateCookBook(
        {
          ...lestConfigIssue.pm,
          parent: mainCookBookEntity,
        },
        partitionNamesConfig.partition1
      );
    }
  };

  const setNewConfigCallback = (partition, config) => {
    //     console.log('commonEntityUuid',commonEntityUuid)
    // console.log('publicEntityUuid',publicEntityUuid)
    const newConfig = config;
    if (!prop("parent", newConfig)) {
      newConfig.parent =
        partition === partitionNamesConfig.partition1 ? commonEntityUuid : publicEntityUuid;
    }

    setLestConfigIssue({
      ...lestConfigIssue,
      [partition === partitionNamesConfig.partition1 ? "pm" : "public"]: newConfig,
    });
    // console.log('ggggggggg', newConfig, partition);
    getListTemplateCookBook(newConfig, partition);
  };



  const listPMCookBook =
    entityType !== "project" ? templateListVectors : cookBookTemplateList;

  useEffect(() => {
    getFromPmMainEntityCookbookAndListCookbook();
  }, [pmPartitionUUID]);

  useEffect(() => {
    console.log(
      "currentPublicCookBookUUID",
      currentPublicCookBookUUID
    );
    if (currentPublicCookBookUUID) {
      getListTemplateCookBook(
        {
          ...lestConfigIssue.public,
          parent: currentPublicCookBookUUID,
        },
        partitionNamesConfig.partition3
      );
    }
  }, [currentPublicCookBookUUID]);

  useEffect(
    () => () => {
      dispatch(clearCookBookStore);
      // dispatch(clearMainPublicProjectUuid);
    },
    []
  );

  const listPublicCookBook =
    entityType !== "project"
      ? PublicTemplateListVectors
      : cookBookPublicTemplateList;

  // console.log('listPublicCookBook', listPublicCookBook)
  // console.log('cookBookPublicTemplateList', cookBookPublicTemplateList)
  // console.log('entityType', entityType)
  // console.log('totalCount',totalCount)
  return (
    <Row type="flex" className="w-full">
      <Col span={24} className="pb-2">
        <CheckBuildVersion version="beta" onlyLabel />
      </Col>
      <Col span={13} className="pr-3">
        <BaseCard
          className={`
           ${entityType === "vector" ? "" : "hidden"}
           collapsedTable  ${expand ? "expanded" : ""
            }  blue with-text small mb-3 `}
        >
          {expand && (
            <CurrentProjectTemplate
              getListTemplateCookBook={getListTemplateCallback}
              currentPartition={currentPartition}
            />
          )}
          <div
            onClick={() => setExpanded(!expand)}
            className="collapsedTable-button flex"
          >
            <div className="flex items-center">
              <h6 className="m-0">
                {expand
                  ? capitalize(
                    t(
                      "wms.modals.body.hide_cookbook_from_project",
                      "Hide cookbook creation from this project"
                    )
                  )
                  : capitalize(
                    t(
                      "wms.modals.body.show_cookbook_from_project",
                      "Show cookbook creation from this project"
                    )
                  )}
              </h6>
              <Icon
                className="text-blue-500 ml-2"
                path={mdiChevronDown}
                size={1}
                rotate={expand ? 180 : 0}
              />
            </div>
          </div>
        </BaseCard>

        <CookBookTemplateList
          title={`${capitalize(
            t("wms.modals.headers.common_cookbooks", "Common cookbooks")
          )}:`}
          listData={listPMCookBook}
          partition={partitionNamesConfig.partition1}
          fetching={fetchingCommon}
          totalCount={totalCount.pm}
          lestConfigIssue={lestConfigIssue.pm}
          setNewConfigCallback={setNewConfigCallback}
          setSelectedCookbook={selectCookbookCallback}
        />

        <CookBookTemplateList
          title={`${capitalize(
            t(
              "wms.modals.headers.my_personal_public_cookbooks",
              "My personal public cookbooks"
            )
          )}:`}
          listData={cookBookPublicTemplateList}
          partition={partitionNamesConfig.partition3}
          fetching={fetchingPublic}
          totalCount={totalCount.public}
          lestConfigIssue={lestConfigIssue.public}
          setNewConfigCallback={setNewConfigCallback}
          setSelectedCookbook={selectCookbookCallback}
        />
      </Col>

      <Col span={11} className="pl-3">
        <Row>
          <Col span={24} className="mb-4">
            <BaseCard className="primaryCard w-full" style={{ minHeight: 300 }}>
              <ActionCookBook
                cookBookState={cookBookState}
                selectedEntity={selectedEntity}
                entityType={entityType}
                parentProject={customParent}
                currentPartition={currentPartition}
                currentSelectedRow={currentSelectedRow}
                getListTemplateCookBook={getListTemplateCallback}
                importCookBookCallback={importCookBookCallback}
                setTemplate={setTemplate}
                toResetArea={toResetArea}
                createEntities={createEntities}
                setCookBookState={setCookBookState}
                removeCookBookTemplateCallback={removeCookBookTemplateCallback}
              />
              <Row>
                <Col span={24}>
                  <CookBookTree
                    activeCookBook={activeCookBook}
                    jsObject={cookBookState.jsObject}
                    onSelectEntity={onSelectEntity}
                    customParent={customParent}
                    selectedEntity={selectedEntity}
                  />
                </Col>
              </Row>
            </BaseCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CookBookStartPage;

CookBookStartPage.propTypes = {
  entityType: PropTypes.string,
  customParent: PropTypes.object,
  currentPartition: PropTypes.string,
};

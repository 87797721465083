import React, { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import { capitalize } from 'lodash';

function ListOfStatuses({
  currentStatus,
  onChangeStatusCallback,
  disabled,
}) {
  const { t } = useTranslation();
  const [status, changeStatus] = useState(currentStatus);

  const changeStateAndReturnCallback = (e) => {
    const { target: { id } } = e;

    changeStatus(id);

    if (onChangeStatusCallback) {
      onChangeStatusCallback(id);
    }
  };

  useEffect(() => {
    changeStatus(currentStatus);
  }, [currentStatus]);

  return (
    <div
      onClick={changeStateAndReturnCallback}
      className="flex justify-center flex-warp"
    >
      <BaseButton
        size="small"
        className={`status_test_case pass ${status === 'pass' ? 'active' : ''}`}
        id="pass"
        disabled={disabled}
      >
        <span id="pass">
          {capitalize(t('wms.modals.body.pass'))}
        </span>
      </BaseButton>

      <BaseButton
        size="small"
        className={`status_test_case fail ${status === 'fail' ? 'active' : ''}`}
        id="fail"
        disabled={disabled}
      >
        <span id="fail">
          {capitalize(t('wms.modals.body.fail'))}
        </span>
        
      </BaseButton>

      <BaseButton
        size="small"
        className={`status_test_case block ${status === 'block' ? 'active' : ''}`}
        id="block"
        disabled={disabled}
      >
        <span id="block">
          {capitalize(t('wms.modals.body.block'))}
        </span>
      </BaseButton>

      <BaseButton
        size="small"
        className={`status_test_case unexecuted ${status === 'unexecuted' ? 'active' : ''}`}
        id="unexecuted"
        disabled={disabled}
      >
        <span id="unexecuted">
          {capitalize(t('wms.modals.body.unexecuted'))}
        </span>
      </BaseButton>

    </div>
  );
}

ListOfStatuses.propTypes = {

};

export default memo(ListOfStatuses);

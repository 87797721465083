import { get } from 'lodash';
import {
  array, bool, element, func,
} from 'prop-types';
import React, { useState } from 'react';

import './uploader.scss';
import { errorMaxSize } from './utils';

export default function UploaderWrapperInView({
  children,
  files = [],
  onChangeFileCallback,
  disabled,
}) {
  const [isFileCard, setIsFileCard] = useState(false);

  const getAndSaveFiles = (newData) => {
    const newFile = [];
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].kind === 'file') {
        const file = newData[i].getAsFile();
        newFile.push(file);
      }
    }

    const moreThanMax = files?.reduce((acc, file) => file?.size || file?.content_length || 0 + acc, 0)
    + newFile.reduce((acc, file) => file.size + acc, 0) >= 10485760;

    // const moreThanMax = newFile.some((i) => i.size >= 10485760);

    if (moreThanMax) {
      errorMaxSize();
    } else {
      onChangeFileCallback({ files: [...(files || []), ...newFile] });
    }
  };

  const uploadingClipboard = (data) => {
    if (!disabled) {
      const newData = (data.clipboardData || data.originalEvent.clipboardData).items;
      getAndSaveFiles(newData);
    }
  };

  const onDropFile = (e) => {
    if (!disabled) {
      e.preventDefault();
      e.stopPropagation();
      if (get(e, 'dataTransfer.types', []).every((i) => i === 'Files')) {
        const newData = e.dataTransfer.items;
        getAndSaveFiles(newData);
      }
      setIsFileCard(false);
    }
  };

  const setActiveFileCard = (e) => {
    if (!disabled) {
      e.preventDefault();
      e.stopPropagation();

      if (!isFileCard && get(e, 'dataTransfer.types', []).every((i) => i === 'Files')) {
        setIsFileCard(true);
      }
    }
  };

  const setDeactivateFileCard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) {
      setIsFileCard(false);
    }
  };

  const clearIsFileCard = () => { if (isFileCard && !disabled) { setIsFileCard(false); } };
  return (
    <div
      tabIndex="0"
      className="w-full h-full relative"
      role="button"
      onPaste={uploadingClipboard}
      onMouseLeave={clearIsFileCard}
      onMouseOver={clearIsFileCard}
      onDragOver={setActiveFileCard}
    >

      <div
        onDragLeave={setDeactivateFileCard}
        onDrop={onDropFile}
        className={`${isFileCard ? 'wrapper-drag-card' : 'hidden'}`}
      />

      <div className="w-full h-full">{children}</div>

    </div>
  );
}

UploaderWrapperInView.propTypes = {
  children: element,
  onChangeFileCallback: func,
  files: array,
  disabled: bool,
};

import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import InfoTip from '../../../components/infoTip/InfoTip';
import BreadCrumbsNEW from '../../../components/breadcrumb/BreadCrumbsNEW';
import CookBookStartPage from './CookBookStartPage';

const CookBookMenuSection = ({}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <Row className="mb-3">
        <Col span={24}>
          <InfoTip
            title={t('wms.projectflow.cookbook', 'Cookbook')}
            text={t(
              'wms.projectflow.cookbook.help.hint',
              'cookbook help.',
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="py-4">
          <BreadCrumbsNEW />
        </Col>
      </Row>
      <CookBookStartPage />
    </div>
  );
};

export default CookBookMenuSection;

import { useDispatch, useSelector } from 'react-redux';
import { getListAndReadEntities, getOrCreateSingle, entityCreate } from '../../entity/actions/entityActions';
import { getPartitionPMUUID } from '../../config/selectors/selectors';

const useToolsHooks = () => {
  const dispatch = useDispatch();

  const pmPartition = useSelector(getPartitionPMUUID);

  const getWhatsNewStorage = () => {
    const data = {

      entity_type: 'WhatsNewStorage',
      parent: pmPartition,
    };

    return dispatch(getOrCreateSingle({
      constants: [
        'GET_SINGLE_WHATS_NEW_REQUEST',
        'GET_SINGLE_WHATS_NEW_SUCCESS',
        'GET_SINGLE_WHATS_NEW_FAILURE',
      ],
      data,
    }));
  };

  const getAllWhatsNew = (parent, params) => {
    const data = {
      entity_type: 'whatsNewRelease',
      parent,
      params,
    };

    return dispatch(
      getListAndReadEntities({
        data,
        constants: [
          'GET_WHATS_NEW_REQUEST',
          'GET_WHATS_NEW_SUCCESS',
          'GET_WHATS_NEW_FAILURE',
        ],
      }),
    );
  };

  const getWhatsNewStorageAndChild = (paramsForChild) => getWhatsNewStorage()
    .then((mainRes) => getAllWhatsNew(mainRes?.uuid, paramsForChild).then((res) => ({
      parent: mainRes?.uuid,
      ...res,
    })));

  const createWhatsNew = (data) => {
    dispatch(entityCreate({
      data,
      constants: [
        'CREATE_WHATS_NEW_REQUEST',
        'CREATE_WHATS_NEW_SUCCESS',
        'CREATE_WHATS_NEW_FAILURE',
      ],
    }));
  };

  return ({
    getWhatsNewStorage,
    getAllWhatsNew,
    getWhatsNewStorageAndChild,
    createWhatsNew,
  });
};

export default useToolsHooks;

import { Alert, Button, Flex, InputNumber } from "antd";
import cn from "classnames";
import { func } from "prop-types";
import React, { useState } from "react";

const issueTypeOptions = ['bug', 'ticket', 'feature']
const issuePriorityOptions = ['low', 'medium', 'high', 'urgent'];
const issueStatusOptions = ['created', 'acquainted', 'accepted', 'rejected',
    'reopened', 'in progress', 'review', 'ready for deploy', 'test', 'testing',
    'not reproducible', 'backlog', 'blocked', 'paused', 'discuss',
    'not enough info', 'closed', 'abandoned', 'deployed']

export default function ComputePlanSettings({
    onCancel,
}) {
    const [activeTypes, setActiveTypes] = useState(['bug']);
    const [activePriorities, setActivePriorities] = useState(['high', 'urgent']);
    const [activeStatuses, setActiveStatuses] = useState([
        'reopened', 'acquainted', 'in progress',
        'review', 'accepted', 'rejected', 'created',
        'not reproducible', 'backlog',
        'discuss', 'test',
    ]);

    return (
        <Flex
            vertical
        >
            <span className="text-lg mb-2">Settings</span>
            <Alert
                showIcon
                className="mb-2"
                message={"Tickets with high-priority parameters will be added to the plan first of all."}
            />
            <Flex>
                <Flex
                    vertical
                    className="text-neutral-700"
                >
                    High-priority types:
                    <Flex className="mt-1">
                        {issueTypeOptions.map((el) => (
                            <Flex
                                key={el}
                                onClick={() => {
                                    if (activeTypes.includes(el)) {
                                        setActiveTypes(prev => prev.filter(i => i !== el))
                                    } else {
                                        setActiveTypes(prev => [...prev, el])
                                    }
                                }}
                                className={cn('border mr-1 text-sm p-0.5 cursor-pointer px-2 rounded max-w-max', {
                                    "border-blue-500 bg-blue-500 text-white hover:bg-white hover:text-blue-500": activeTypes.includes(el),
                                    "border-blue-500 text-blue-500": !activeTypes.includes(el),
                                })}
                            >
                                {el}
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
                <Flex
                    vertical
                    className="ml-20 text-neutral-700"
                >
                    High-priority priority:
                    <Flex className="mt-1">
                        {issuePriorityOptions.map((el) => (
                            <Flex
                                key={el}
                                onClick={() => {
                                    if (activePriorities.includes(el)) {
                                        setActivePriorities(prev => prev.filter(i => i !== el))
                                    } else {
                                        setActivePriorities(prev => [...prev, el])
                                    }
                                }}
                                className={cn('border mr-1 text-sm p-0.5 cursor-pointer px-2 rounded max-w-max', {
                                    "border-blue-500 bg-blue-500 text-white hover:bg-white hover:text-blue-500": activePriorities.includes(el),
                                    "border-blue-500 text-blue-500": !activePriorities.includes(el),
                                })}
                            >
                                {el}
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                vertical
                className="mt-3 text-neutral-700"
            >
                High-priority statuses:
                <Flex
                    className="mt-1"
                    wrap
                >
                    {issueStatusOptions.map((el) => (
                        <Flex
                            key={el}
                            onClick={() => {
                                if (activeStatuses.includes(el)) {
                                    setActiveStatuses(prev => prev.filter(i => i !== el))
                                } else {
                                    setActiveStatuses(prev => [...prev, el])
                                }
                            }}
                            className={cn('border mr-1 text-sm mb-1 p-0.5 cursor-pointer px-2 rounded max-w-max', {
                                "border-blue-500 bg-blue-500 text-white hover:bg-white hover:text-blue-500": activeStatuses.includes(el),
                                "border-blue-500 text-blue-500 hover:bg-blue-100": !activeStatuses.includes(el),
                            })}
                        >
                            {el}
                        </Flex>
                    ))}
                </Flex>
            </Flex>
            <Flex className="mt-1 text-neutral-700">
                Number of hours in a day
                <InputNumber
                    className="ml-2"
                    min={1} э
                    style={{ width: 55 }}
                    max={24}
                    defaultValue={8}
                    size="small"
                />
            </Flex>
            <Flex className="mt-1 text-neutral-700">
                Maximum overtime
                <InputNumber
                    className="ml-2"
                    min={1} э
                    style={{ width: 55 }}
                    max={24}
                    defaultValue={4}
                    size="small"
                />
            </Flex>
            <Flex justify="end" className="mt-2">
                {onCancel ? <Button
                    className={'border-red-500 text-red-500 bg-white hover:bg-red-500 hover:text-white'}
                    onClick={onCancel}
                >
                    Close
                </Button> : null}
            </Flex>
        </Flex>
    )
}

ComputePlanSettings.propTypes = {
    onCancel: func,
}
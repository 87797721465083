import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Popover, Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiAccountSupervisorCircleOutline, mdiSquareEditOutline } from '@mdi/js';

import { useTranslation } from 'react-i18next';

import { capitalize } from 'lodash';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import UsersForAssign from './UsersForAssign';
import ListOfAssignedUsers from './ListOfAssignedUsers';

import './AssignUsers.scss';

function AssignUsers({
  partitionType,
  parentUUID,
  title,
  stylesForTitleAndIcon,
  sylesForTitle,
  stylesForIcon,
  stylesForUsersList,
  selectedUsers = [],
  isSmall = false,
  multiSelectMode = false,
  disabled = false,
  getAssignedUsers,
}) {
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const toggleModalVisibility = () => setIsOpenModal((prev) => !prev);

  const saveUsersCallback = (users) => {
    if (getAssignedUsers) {
      getAssignedUsers(users);
    }
    setIsOpenModal(false);
  };

  return (
    <div className="flex items-center">
      <div className={`${stylesForTitleAndIcon || 'flex items-center smallEntityText p-0'}`}>
        <Icon
          className={`${stylesForIcon || ''}`}
          path={mdiAccountSupervisorCircleOutline}
          size={1}
        />
        <span className={`${sylesForTitle || 'ml-1'}`}>
          {title}
          :
        </span>
      </div>

      <Popover
        placement="left"
        trigger="click"
        open={isOpenModal}
        onClick={toggleModalVisibility}
        destroyTooltipOnHide
        zIndex={2}
        content={(
          <>
            <UsersForAssign
              partitionType={partitionType}
              parentUUID={parentUUID}
              multiSelectMode={multiSelectMode}
              title={capitalize(t('wms.labels.assigned_users'))}
              defaultSelectedUsers={selectedUsers}
              isSmall={isSmall}
              onSaveUsersCallback={saveUsersCallback}
            />
            <div className="users-for-assing-overlay" onClick={toggleModalVisibility} role="none" />
          </>
        )}
      >
        <Tooltip
          title={capitalize(t('wms.tooltips.edit'), 'edit')}
          placement="top"
        >
          <BaseButton
            id="link"
            shape="circle"
            className="ml-1 view_btn_link"
            disabled={disabled}
          >
            <Icon
              path={mdiSquareEditOutline}
              size={0.8}
            />
          </BaseButton>
        </Tooltip>
      </Popover>

      <ListOfAssignedUsers
        assignedUsers={selectedUsers}
        stylesForUsersList={stylesForUsersList}
      />
    </div>
  );
}

AssignUsers.propTypes = {
  partitionType: PropTypes.string,
  parentUUID: PropTypes.string,
  title: PropTypes.string,
  stylesForTitleAndIcon: PropTypes.string,
  sylesForTitle: PropTypes.string,
  stylesForIcon: PropTypes.string,
  stylesForUsersList: PropTypes.string,
  selectedUsers: PropTypes.array,
  isSmall: PropTypes.bool,
  multiSelectMode: PropTypes.bool,
  disabled: PropTypes.bool,
  getAssignedUsers: PropTypes.func,
};

export default AssignUsers;

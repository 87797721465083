import Icon from '@mdi/react';
import { Popover } from 'antd';
import { bool, element, string } from 'prop-types';
import React, { useState } from 'react';
import BaseButton from '../../components/_ui/BaseButton/BaseButton';

export default function PoroverWrapper({
  children, titleBtn, iconRight, iconLeft, customBtn, placement = 'leftTop',
}) {
  const [iseOpen, setIsOpen] = useState(false);

  const toggleBtn = () => setIsOpen((prev) => !prev);
  return (
    <Popover
      placement={placement}
      trigger="click"
      destroyTooltipOnHide
      open={iseOpen}
      onClick={toggleBtn}
      content={(
        <div className="relative">
          {React.cloneElement(children, {
            closePorover: toggleBtn,
          })}
          <div className="substrate_assigned_users" onClick={toggleBtn} role="none" />
        </div>
  )}
    >
      {customBtn || (
      <BaseButton
        id="cloneIssueBtn"
        size="small"
        type="primary"
        className="mr-1"
        ghost
      >
        {iconLeft && (
          <Icon path={iconLeft} size={0.8} className="mr-1" />
        )}

        {titleBtn}

        {iconRight && (
        <Icon path={iconRight} size={0.8} className="ml-1" />
        )}
      </BaseButton>
      )}
    </Popover>
  );
}
PoroverWrapper.propTypes = {
  children: element,
  titleBtn: string,
  iconRight: bool,
  iconLeft: bool,
  customBtn: element,
  placement: string,
};

import React, { useEffect, useState } from 'react';
import {
  Button, Col, Collapse, Flex, Modal, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { mdiCog } from '@mdi/js';
import Icon from '@mdi/react';
import useToolsHooks from '../hooks/useToolsHooks';

import ToolsFlowInfoTip from './ToolsFlowInfoTip.jsx';
import PermissionsStartPage from '../../projectFlow/containers/permissions/PermissionsStartPage';
import { isAdminOrRoot } from '../../entity/selectors/selectors';
import BaseButton from '../../components/_ui/BaseButton/BaseButton';
import useURLParams from '../../hooks/useURLParams';
import { partitionNamesConfig } from '../../api/appConfig.js';

const { Panel } = Collapse;

function WhatsNew() {
  const { getWhatsNewStorageAndChild } = useToolsHooks();

  const rootOrAdmin = useSelector(isAdminOrRoot);

  const [data, setData] = useState([]);

  const [isModalOpen, setModalOpen] = useState(false);

  const onOpenModal = () => setModalOpen(true);
  const onCloseModal = () => setModalOpen(false);

  const { getURLParams } = useURLParams();

  const { activeActorPerm } = getURLParams();

  const initFunc = () => {
    getWhatsNewStorageAndChild().then((res) => {
      setData(res?.data?.reverse());
    });
  };

  useEffect(() => {
    initFunc();
  }, []);

  return (
    <div>
      <Flex
        align="center"
        className="w-50"
      >
        <ToolsFlowInfoTip title="Whats new" />
        {rootOrAdmin
          ? (
            <Col
              className="ml-2 view_btn_link_circle"
              style={{
                cursor: 'pointer',
              }}
              onClick={onOpenModal}
            >
              <Icon path={mdiCog} size={0.75} />
            </Col>
          ) : null}
      </Flex>
      <Collapse
        className="mt-3"
        defaultActiveKey={['0']}
        // expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        {data.reverse().map((item, i) => (
          <Panel header={`Version ${item.params.version}`} key={i}>
            <p
              style={{ whiteSpace: 'pre-line' }}
            >
              {item.params.description}
            </p>
          </Panel>
        ))}
      </Collapse>
      <Modal
        open={isModalOpen}
        onCancel={onCloseModal}
        destroyOnClose
        footer={null}
        title={(<span className="h4">Whats new permissions</span>)}
        centered
        width={activeActorPerm ? 1400 : 700}
      >
        <Row className="mt-4">
          <PermissionsStartPage
            entityUUID={data?.[0]?.uuid}
            partitionType={partitionNamesConfig.partition1}
            nameSectionPermissions="Whats new user permissions"
            options={{
              isFullWidthMode: true,
            }}
          />
        </Row>
      </Modal>
    </div>
  );
}

export default WhatsNew;

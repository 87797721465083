import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getUserPublicProfileUUID } from '../../../../userFlow/store/selectors/selectors';
import { parseLinks } from '../../../../MainUtils';
import { capitalize } from 'lodash';

export default function ViewComment({ item, projectUUID = '', partition = 'PM' }) {
  const { t } = useTranslation();

  const userUUID = useSelector(getUserPublicProfileUUID);

  const isPublicPartition = partition === 'PUBLIC';
  const system = get(item, 'params.system', false);

  const localizeSystem = (comment = {}) => {
    if (typeof comment === 'string') {
      return comment;
    }

    const {
      action, param, value, entity, descriptionComment,
    } = comment;

    switch (action) {
      case 'changed': {
        const getMessage = (changedParam, changedValue) => {
          const valueIsExclusion = [
            'dateEnd',
            'datestart',
          ].includes(changedParam);

          const actionText = capitalize(t(`wms.comments.actions.${action}`, action));
          const paramText = t(`wms.comments.params.${changedParam}`, `the ${changedParam} to`);
          const valueText = valueIsExclusion ? changedValue
            : t(`wms.comments.values.${changedValue}`, changedValue);

          return (
            <>
              {`${actionText} ${paramText}: ${valueText}`}
              <br />

              {descriptionComment
                ? (
                  <>
                    <span className="inline-flex ml-2">
                      {descriptionComment}
                    </span>
                    <br />
                  </>
                )
                : ''}
            </>
          );
        };

        if (Array.isArray(param)) {
          return param.map((item, id) => getMessage(item, value[id]));
        }

        return getMessage(param, value);
      }

      case 'created': {
        const text = capitalize(t('wms.comments.issue_created', 'issue created'));

        return (
          <Link
            to={{
              pathname: isPublicPartition ? `/public/user/${userUUID}/projects/issuesnew` : '/pm/projects/issuesnew',
              search: `?activeProject=${projectUUID}&activeIssue=${value}`,
            }}
            rel="noreferrer"
            target="_blank"
          >
            {`${text}: ${param}`}
          </Link>
        );
      }

      case 'created_bulk_issues': {
        const text = capitalize(t('wms.comments.issue_created', 'issue created'));

        return (
          <div className="flex flex-col">
            <div>{`${text}:`}</div>
            <div className="flex flex-col">
              {value.map((issue, i) => (
                <div className="flex">
                  <span>{`${i + 1}. `}</span>
                  <Link
                    to={get(issue, 'link')}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {get(issue, 'title')}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        );
      }

      case 'created vector': {
        const text = capitalize(t('wms.comments.vector_created', 'vector created'));

        return (
          <Link
            to={{
              pathname: isPublicPartition ? `/public/user/${userUUID}/projects/issuesnew` : '/pm/projects/issuesnew',
              search: `?activeProject=${projectUUID}&activeVector=${value}`,
            }}
            rel="noreferrer"
            target="_blank"
          >
            {`${text}: ${param}`}
          </Link>
        );
      }

      case 'important': {
        return (
          <span>
            {`Marked this ${entity} as important`}
          </span>
        );
      }

      default:
        return '';
    }
  };

  const comment = get(item, 'params.comment', system ? {} : '');

  if (!comment) {
    return null;
  }

  return (
    <p className="comment-text">
      {system ? localizeSystem(comment) : parseLinks(comment)}
    </p>
  );
}

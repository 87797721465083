import React, { useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import { head } from 'ramda';
import { get } from 'lodash';
import {
  Row, Col, Card, Spin,
} from 'antd';
import ProjectInfo from '../projectInfo/ProjectInfo';
import ProjectRecentActivity from './ProjectRecentActivity';
import ProjectRecentTimeLogs from './ProjectRecentTimeLogs';
import ProjectMilestonesAtWork from './ProjectMilestonesAtWork';

import { ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import { getProject } from '../../../selectors/selectors';
import { clearProject } from '../../../actions/projectFlowActions';
import ProjectScoring from '../projectInfo/components/projectScoring/ProjectScoring';
import ProjectInfoBlock from '../projectInfo/components/projectGeneralInfo/ProjectInfoBlock';
import BaseCard from "../../../../components/_ui/BaseCard/BaseCard";

function ProjectGeneralInfo({
  defaultPartition,
}) {
  // const { t } = useTranslation();

  const dispatch = useDispatch();
  const project = head(useSelector(getProject));

  // const { isGlobalDisabled } = useContext(ProjectMainLayerContext);

  const projectUUID = get(project, 'uuid');

  const {
    backToProjects,
    getProjectMembers,
  } = useContext(ProjectMainLayerContext);

  //----------------------------------------------------------------
  // Project functions
  const resetProjectCallback = useCallback(() => {
    dispatch(clearProject);
    backToProjects();
  }, []);
  //----------------------------------------------------------------

  return (
    <Row>
      <Col className="tableColumn pr-2 project-general-info">
        <BaseCard className="mb-3 borderR10 primaryCard">
          <Row gutter={[32, 16]}>
            <Col span={15} className="pr-0">
              <ProjectScoring
                parent={projectUUID}
                partitionType={defaultPartition}
              />
            </Col>
            <Col span={9} style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }} className="pr-0">
              <h6 className="mb-4">Project general info:</h6>
              <ProjectInfoBlock partitionType={defaultPartition} />
            </Col>
          </Row>
        </BaseCard>

        <ProjectMilestonesAtWork
          parent={projectUUID}
          partitionType={defaultPartition}
        />
        <ProjectRecentActivity partitionType={defaultPartition} />
        <ProjectRecentTimeLogs
          parent={projectUUID}
          partitionType={defaultPartition}
        />
      </Col>

      <Col className="cardColumn pl-2 project-info">
        <BaseCard className="p-0 borderR10 dangerCard">
          <ProjectInfo
            getProjectMembers={getProjectMembers}
            deleteEntityCallback={backToProjects}
            partitionType={defaultPartition}
            resetProjectCallback={resetProjectCallback}
          />
        </BaseCard>
      </Col>
    </Row>
  );
}

ProjectGeneralInfo.propTypes = {
  defaultPartition: string,
};

export default ProjectGeneralInfo;

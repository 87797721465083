import { Col, Row } from 'antd';
import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { func } from 'prop-types';
import { capitalize, isEmpty } from 'lodash';

import CreateUserOnAuth from '../CreateUserOnAuth';
import GetAuthSessionBtn from '../GetAuthSessionBtn';
import { getBanGroupUUID } from '../../../actors/slices/defaultGroupsSlice';
import TagsSettingList from '../../../components/filter/TagsSettingList';

const defaultSelectedTags = [{
  key: 'internal',
  value: 'internal',
  label: capitalize('internal'),
  className: 'internal-tag',
  ruleOfTag: 'included',
}, {
  key: 'banned',
  value: 'banned',
  label: capitalize('banned'),
  className: 'banned-tag',
  ruleOfTag: 'excluded',
},];

function DayUserReportUserTableHeader({
  getActorsList,
  setConfig,
}) {
  const { t } = useTranslation();

  const banGroupUUID = useSelector(getBanGroupUUID);

  const [selectedTags, setSelectedTags] = useState([]);

  const allTags = useMemo(() => [
    {
      tags: [
        {
          key: 'all',
          label: capitalize(t('wms.filters.all', 'all')),
        },
      ],
      isReset: true,
    },
    {
      tags: [
        {
          key: 'banned',
          value: 'banned',
          label: 'Banned',
          className: 'banned-tag',
          ruleOfTag: 'off',
        },
      ],
      isRadio: true,
    },
    {
      tags: [
        {
          key: 'internal',
          value: 'internal',
          label: 'Internal',
          className: 'internal-tag',
          ruleOfTag: 'off',
        },
      ],
      isRadio: true,
    },
  ], [t]);

  const onSelectTags = (tags) => {
    setSelectedTags(tags);

    if (isEmpty(tags)) {
      setConfig({});
    } else if (!isEmpty(tags)) {
      const config = {};

      tags.forEach((el) => {
        if (el?.key === 'banned' && el?.ruleOfTag === 'excluded') config.groups__not = [banGroupUUID];
        if (el?.key === 'banned' && el?.ruleOfTag === 'included') config.groups = [banGroupUUID];
        if (el?.key === 'internal' && el?.ruleOfTag === 'included') config.internal_user = true;
        if (el?.key === 'internal' && el?.ruleOfTag === 'excluded') config.internal_user = false;
      });
      setConfig((prev) => ({
        ...prev,
        uinfo: config,
      }));
    }
  };

  return (
    <Row className="flex items-center justify-between pb-3">
      <Col>
        <TagsSettingList
          allTags={allTags}
          defaultSelectedTags={defaultSelectedTags}
          size="small"
          onSelectTags={onSelectTags}
        />
      </Col>
      <Col className="flex">
        <CreateUserOnAuth
          createActorCallback={getActorsList}
        />

        <GetAuthSessionBtn
          className="ml-2"
        />
      </Col>
    </Row>
  );
}

export default memo(DayUserReportUserTableHeader);

DayUserReportUserTableHeader.propTypes = {
  getActorsList: func,
  setConfig: func,
};

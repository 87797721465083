import {EntityConstants} from "../../entity/constants/Constants";

const initialState = {
  fetching: false,
  message: [],
  error: ''
};


export default (state=initialState, action) => {
  switch (action.type) {
    case EntityConstants.FETCH_ENTITY_TREE_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case EntityConstants.FETCH_ENTITY_TREE_SUCCESS:
      return {
        ...state,
        fetching: false,
        message:  action.payload
      };
    case EntityConstants.FETCH_ENTITY_TREE_FAILURE:
      return {
        ...state,
        fetching: false,
        message: [],
        error:  action.payload
      };

    default:  return state;
  }
};

import React, { useState, useCallback } from 'react';
import { bool, func } from 'prop-types';
import { capitalize } from 'lodash';

import { Flex, Modal } from 'antd';
import DateRangePicker from '../../../../components/dateRangePicker/DateRangePicker';

import useStatusModal from '../hooks/useStatusModal';
import {
  dateAdd, defaultDate, dateFormat2, dateToFormat2,
} from '../../../../54origins/dateFormats54origins';

import { baseMilestoneConfig } from '../constants/baseMilestoneConfig';

function InProgressModal({
  modalOpen,
  closeModal,
  updateMilestoneParameter,
  closeModalCallback,
}) {
  const [modalLifeTime, setModalLifeTime] = useState(() => dateToFormat2(dateAdd(1, 'month', defaultDate())));

  const {
    modalHeader,
    modalOkText,
    modalCancelText,
    modalConfirmLoading,
    modalDisableOkButton,
    changeModalState,
  } = useStatusModal(false);

  const changeModalLifeTime = useCallback((date, dateString) => {
    const [start, end] = date || [];
    setModalLifeTime(dateToFormat2(end));
  }, []);

  const onOkHandler = async () => {
    changeModalState({ confirmLoading: true });
    updateMilestoneParameter({
      ...baseMilestoneConfig,
      status: 'in progress',
      startDate: dateFormat2(),
      lifeTime: dateToFormat2(modalLifeTime),
    })
      .then(() => closeModal())
      .catch((error) => console.error(`Failed to update milestone: ${error.message}`))
      .finally(() => changeModalState({ confirmLoading: false }));
  };

  return (
    <Modal
      width="fit-content"
      className="custom-ant-modal"
      centered
      destroyOnClose
      open={modalOpen}
      confirmLoading={modalConfirmLoading}
      okText={modalOkText}
      okButtonProps={{ disabled: modalDisableOkButton }}
      cancelText={modalCancelText}
      onOk={onOkHandler}
      onCancel={closeModal}
      afterClose={() => closeModalCallback?.()}
    >
      <Flex className="w-full" vertical>
        {modalHeader}

        <Flex className="py-2" vertical>
          <DateRangePicker
            variant="borderless"
            popupContainerId="milestone-in-progress-calendar-wrapper"
            popupStyle={{ position: 'relative' }}
            flexOptions={{
              align: 'center',
              justify: 'space-between',
              vertical: false,
            }}
            isAlwaysOpen
            title={capitalize('milestone expires')}
            disabledStart
            startDate={dateFormat2()}
            endDate={modalLifeTime}
            onChange={changeModalLifeTime}
          />
          <div id="milestone-in-progress-calendar-wrapper" />
        </Flex>
      </Flex>
    </Modal>
  );
}

InProgressModal.propTypes = {
  modalOpen: bool,
  closeModal: func,
  updateMilestoneParameter: func,
  closeModalCallback: func,
};

export default InProgressModal;

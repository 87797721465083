import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Space,
  Tooltip,
  Button,
} from "antd";
import { capitalize } from "lodash";
import BaseButton from "../../../../components/_ui/BaseButton/BaseButton";
import Icon from "@mdi/react";
import {
    mdiContentSave,   
    mdiArrowULeftTop
  } from '@mdi/js';
import UnSaveDataConfirmNew from "../../../../components/un-save-data-confirm/UnSaveDataConfirmNew";


const DocSaveBlock = ({
  isUndoTooltipOpen,
  setUndoTooltipOpen,
  undoChangesDocOrIdea,
  entityDocType,
  documentFetching,
  saveOrUndoBtnDisabled,
  updateDocOrIdea
}) => {
  const { t } = useTranslation();

  const isSpreadsheet = entityDocType === 'projectSpreadsheet';
  return (

      <Space className="customPopconfirm" size={[5, 5]} wrap={false}>
        <Tooltip
          open={isUndoTooltipOpen}
          placement="right"
          overlayClassName="popupPopconfirm"
          overlayInnerStyle={{ width: "220px" }}
          destroyTooltipOnHide
          color="#fa8c16"
          title={
            <>
              <Flex className="mb-1">
                Your changes will be cancelled. Are you sure?
              </Flex>
              <Button
                className="btnBlue mr-2"
                size="small"
                onClick={() => setUndoTooltipOpen(false)}
              >
                No
              </Button>
              <Button
                className="btnDanger"
                size="small"
                onClick={() => {
                  undoChangesDocOrIdea(entityDocType);
                  setUndoTooltipOpen(false);
                }}
              >
                Yes
              </Button>
            </>
          }
        >
          {" "}
          <BaseButton
            className="btnWarning"
            size="small"
            icon={<Icon path={mdiArrowULeftTop} size={0.8} />}
            disabled={saveOrUndoBtnDisabled}
            onClick={() => setUndoTooltipOpen(true)}
          >
            {isSpreadsheet && capitalize(t("wms.buttons.undo", "undo"))}
            </BaseButton>
        </Tooltip>
        <UnSaveDataConfirmNew
          dataIsNotSave={!saveOrUndoBtnDisabled}
          id="docView"
        >
          <BaseButton
            id="savePlanningBtn"
            className="btnGreen"
            size="small"
            icon={<Icon path={mdiContentSave} size={0.8} />}
            loading={documentFetching}
            disabled={saveOrUndoBtnDisabled}
            onClick={updateDocOrIdea}
          >
            {capitalize(t("wms.buttons.save", "save"))}
          </BaseButton>
        </UnSaveDataConfirmNew>
      </Space>

  );
};

DocSaveBlock.propTypes = {
    isUndoTooltipOpen: PropTypes.bool,
    setUndoTooltipOpen: PropTypes.func,
    undoChangesDocOrIdea: PropTypes.func,
    documentFetching: PropTypes.bool,
    saveOrUndoBtnDisabled:PropTypes.bool,
    updateDocOrIdea: PropTypes.func,
    entityDocType: PropTypes.string,
  };

  export default DocSaveBlock;

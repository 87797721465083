import { isEmpty, prop } from 'ramda';

import { EntityConstants } from '../constants/Constants';
import { axiosRequestPost } from '../../api/apiAxios';
import { ProjectTypeCommonConstants } from '../../projectFlow/constants/Constants';
import { getActionCreatorFromConstantWithEmptyPayload } from '../entityUtils';
import { getMD5HashFromString } from '../../MainUtils';
import { partitionNamesConfig } from '../../api/appConfig';

export function clearTemporaryActors() {
  return (dispatch) => dispatch({
    type: EntityConstants.CLEAR_TEMPORARY_ACTORS,
  });
}

export function getListOfEntities({
  endpoint,
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      const onSuccessData = (res = []) => onSuccess(
        {
          entity_type: prop('entity_type', data),
          ...res,
        },
      );

      dispatch(
        axiosRequestPost(
          endpoint || 'entity/list',
          constants,
          data,
          {
            onSuccess: onSuccessData,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export function entityRead({
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/read',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export function entityPartialRead({
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/read/partial',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export function entityUpdate({
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/update',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export function entitySet({
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/set',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export const entityDelete = ({
  endpoint = 'entity/delete',
  data,
  constants,
  options,
}) => (dispatch) => (
  new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequestPost(
        endpoint,
        constants,
        data,
        {
          onSuccess,
          onFailure,
          // onCancel: onFailure,
          ...options,
        },
      ),
    );
  })
);

export function entityCreate({
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/create',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export function getListAndReadEntities({
  data,
  partition,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  doNotCheckError = false,
  requestOptions,
  url,
  with_files,
  signal,
}) {
  const configList = {
    endpoint: url,
    data,
    constants: [constants[0], '', ''],
    options: {
      partition,
      signal,
    },
  };

  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(getListOfEntities(configList))
        .then((listParams) => {
          if (isEmpty(listParams.entity_uuids)) {
            dispatch(getActionCreatorFromConstantWithEmptyPayload(constants[1]));

            onSuccess({ data: [], total: 0 });
          } else {
            const configRead = {
              data: {
                ...listParams,
                with_files,
              },
              constants: ['', constants[1], constants[2]],
              options: requestOptions || {
                partition,
                onSuccess: (res) => onSuccess({ data: res, total: prop('total', listParams) }),
                doNotCheckError,
                signal,
              },
            };

            dispatch(entityRead(configRead));
          }
        })
        .catch((error) => {
          onFailure(error);
        });
    })
  );
}

export function getListAndPartialReadEntities({
  data,
  partition,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  doNotCheckError = false,
  requestOptions,
  with_files,
  url,
  signal,
}) {
  const configList = {
    endpoint: url,
    data,
    constants: [constants[0], '', ''],
    options: {
      partition,
      signal,
    },
  };

  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(getListOfEntities(configList))
        .then((listParams) => {
          if (isEmpty(listParams.entity_uuids)) {
            dispatch(getActionCreatorFromConstantWithEmptyPayload(constants[1]));

            onSuccess({ data: [], total: 0 });
          } else {
            const configRead = {
              data: {
                ...listParams,
                fields: data.fields,
                params_fields: data.params_fields,
                with_files,
              },
              constants: ['', constants[1], constants[2]],
              options: requestOptions || {
                partition,
                onSuccess: (res) => onSuccess({ data: res, total: prop('total', listParams) }),
                doNotCheckError,
                signal,
              },
            };

            dispatch(entityPartialRead(configRead));
          }
        })
        .catch((error) => {
          onFailure(error);
        });
    })
  );
}

export const getRequestEntity = ({
  data,
  partition = partitionNamesConfig.partition1,
}) => {
  const config = {
    partition,
    data: { ...data },
    constants: [
      ProjectTypeCommonConstants.GET_PARENT_ENTITIES_REQUEST,
      ProjectTypeCommonConstants.GET_PARENT_ENTITIES_SUCCESS,
      ProjectTypeCommonConstants.GET_PARENT_ENTITIES_FAILURE,
    ],
  };
  return (dispatch) => dispatch(getListAndReadEntities(config));
};

export const getRequestReadEntity = ({
  data,
  partition = partitionNamesConfig.partition1,
}) => {
  const constants = [
    ProjectTypeCommonConstants.GET_PARENT_ENTITIES_REQUEST,
    ProjectTypeCommonConstants.GET_PARENT_ENTITIES_SUCCESS,
    ProjectTypeCommonConstants.GET_PARENT_ENTITIES_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
};

export const setFetchingIssueInStore = (name, bool) => (dispatch) => dispatch(
  {
    type: ProjectTypeCommonConstants.FETCHING_LIST_OF_ISSUES,
    payload: { [`${name}Fetching`]: bool },
  },
);

export const incrementAndGetCount = (params, partition = partitionNamesConfig.partition1) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(axiosRequestPost(
    'utility/entity/increment',
    ['', '', ''],
    params,
    {
      partition,
      onSuccess: resolve,
      onFailure: reject,
    },
  ));
});

export const getOrCreateSingle = ({
  constants,
  data,
  doNotCheckError,
  partition = partitionNamesConfig.partition1,
}) => {
  const { key, entity_type, parent } = data || {};

  // console.log('data', data)
  // console.log('22222', getMD5HashFromString(`${entity_type}${parent}`))

  if (!key) {
    data.key = getMD5HashFromString(`${entity_type}${parent}`);
  }

  const types = constants || [
    'GET_OR_CREATE_SINGLE_REQUEST',
    'GET_OR_CREATE_SINGLE_SUCCESS',
    'GET_OR_CREATE_SINGLE_FAILURE',
  ];
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(axiosRequestPost(
      'utility/entity/unique/check',
      types,
      data,
      {
        partition,
        doNotCheckError,
        onSuccess: resolve,
        onFailure: reject,
      },
    ));
  });
};

// OPTIONAL DATA URLS
export function entityOptionalDataRead({
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/optional_data/read',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export function entityOptionalDataStateRead({
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/optional_data/state/read',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export function entityOptionalDataStateUpdate({
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/optional_data/state/update',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export function entityOptionalDataPatchRead({
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/optional_data/patch/read',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export function entityOptionalDataPatchUpdate({
  data,
  constants,
  options,
}) {
  console.log('entityOptionalDataPatchUpdate')
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/optional_data/patch/update',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export function entityOptionalDataStateLatest({
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/optional_data/state/latest',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}

export function entityOptionalDataStateFullRewrite({
  data,
  constants,
  options,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/optional_data/update',
          constants,
          data,
          {
            onSuccess,
            onFailure,
            ...options,
          },
        ),
      );
    })
  );
}
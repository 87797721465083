import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import UsersTable from './UsersTable.jsx';

import { EntityConstants } from '../../../entity/constants/Constants';

import './user-report.scss';

import ViewReportByUser from './ViewReportByUser.jsx';


function UserReportFromSidebar() {

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  return (
    <div className="relative h-full w-full">
      <div className="wrapper-user-report">
        <Col className="transition07 h-full bg-white mr-2 overflowHidden user-table-wrapper" span={5}>
          <UsersTable
            selectedUser={selectedUser}
            users={users}
            setSelectedUser={setSelectedUser}
            setUsers={setUsers}
          />
        </Col>

        <ViewReportByUser
          users={users}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </div>
    </div>
  );
}

export default UserReportFromSidebar;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enDict from './locale/dictionaries/en/translation.json';
import ruDict from './locale/dictionaries/ru/translation.json';

import configureStore from './redux/store/configureStore';

export const { store } = configureStore();

const initialLanguage = store.getState().locales.language;
console.log('initialLanguage', initialLanguage);
i18n
  .use(initReactI18next)
  .init({
    lng: initialLanguage,
    debug: false,
    fallbackLng: initialLanguage,
    whitelist: ['en', 'ru'],
    interpolation: { skipOnVariables: false }, // SEE COMMENT FOR INTERPOLATION BELOW
    resources: {
      en: {
        translation: enDict,
      },
      ru: {
        translation: ruDict,
      },
    },
  });

export default i18n;

// COMMENT FOR INTERPOLATION
//If your interpolation variables are user provided or loaded from an
// external source, we strongly suggest to keep this option to true.
// If you know what you're doing, you can also set this to false.

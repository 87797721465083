import { get } from 'lodash';
import { createSelector } from 'reselect';

export const getPeriodStartWorkReportsData = (state) => get(state, ['ReportService', 'periodReports', 'startWorkReportsData'], []);
export const getPeriodStartWorkReportsTotal = (state) => get(state, ['ReportService', 'periodReports', 'startWorkReportsTotal'], 0);
export const getPeriodStartWorkReportsIsLoading = (state) => get(state, ['ReportService', 'periodReports', 'startWorkReportsIsLoading'], false);
export const getPeriodStartWorkReportsError = (state) => get(state, ['ReportService', 'periodReports', 'startWorkReportsError'], '');

export const getPartialPeriodStartWorkReportsRequestTrigger = (state) => get(state, ['ReportService', 'periodReports', 'partialDailyEntityRequestTrigger'], false);
export const getPartialPeriodStartWorkReportsData = (state) => get(state, ['ReportService', 'periodReports', 'partialStartWorkReportsData'], []);
export const getPartialPeriodStartWorkReportsIsLoading = (state) => get(state, ['ReportService', 'periodReports', 'partialStartWorkReportsIsLoading'], false);
export const getPartialPeriodStartWorkReportsError = (state) => get(state, ['ReportService', 'periodReports', 'partialStartWorkReportsError'], '');

export const getPartialStartWorkAndReportsDataMAP = createSelector(
  getPartialPeriodStartWorkReportsData,
  (data) => data.reduce((acc, curr) => acc.set(curr?.date, curr), new Map([])),
);

export const getPeriodDetailedRecordsData = (state) => get(state, ['ReportService', 'periodReports', 'detailedReportsData'], []);
export const getPeriodDetailedRecordsTotal = (state) => get(state, ['ReportService', 'periodReports', 'detailedReportsTotal'], 0);
export const getPeriodDetailedRecordsIsLoading = (state) => get(state, ['ReportService', 'periodReports', 'detailedReportsIsLoading'], false);
export const getPeriodDetailedRecordsError = (state) => get(state, ['ReportService', 'periodReports', 'detailedReportsError'], '');

export const getPartialPeriodDetailedReportsRequestTrigger = (state) => get(state, ['ReportService', 'periodReports', 'partialDetailedReportsRequestTrigger'], false);
export const getPartialPeriodDetailedReportsData = (state) => get(state, ['ReportService', 'periodReports', 'partialDetailedReportsData'], []);
export const getPartialPeriodDetailedReportsIsLoading = (state) => get(state, ['ReportService', 'periodReports', 'partialDetailedReportsIsLoading'], false);
export const getPartialPeriodDetailedReportsError = (state) => get(state, ['ReportService', 'periodReports', 'partialDetailedReportsError'], '');

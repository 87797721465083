import { ProjectTypeCommonConstants } from '../constants/Constants';
import { EntityConstants } from '../../entity/constants/Constants';

export const clearProject = { type: ProjectTypeCommonConstants.CLEAR_PROJECT };

export const clearListOfProjects = { type: ProjectTypeCommonConstants.CLEAR_LIST_OF_PROJECTS };

export const clearListOfPersonalProjects = {
  type: ProjectTypeCommonConstants.CLEAR_LIST_OF_PERSONAL_PROJECTS,
};

export const clearHistoryOfDocument = {
  type: ProjectTypeCommonConstants.CLEAR_HISTORY_OF_DOCUMENT,
};

export const clearListOfDocuments = { type: ProjectTypeCommonConstants.CLEAR_PROJECT_DOCUMENTS };

export const clearDocument = { type: ProjectTypeCommonConstants.CLEAR_DOCUMENT };

export const clearAllComments = { type: ProjectTypeCommonConstants.CLEAR_ALL_COMMENTS };

export const clearDocCopy = { type: ProjectTypeCommonConstants.CLEAR_OF_DOC_COPY };

export const resetArchiveState = { type: ProjectTypeCommonConstants.CLEAR_ARCHIVE_ENTITY };

export const clearAuditData = { type: ProjectTypeCommonConstants.CLEAR_AUDIT_DATA };

export const clearIssue = { type: ProjectTypeCommonConstants.CLEAR_ISSUE };

export const clearRecurringIssue = { type: ProjectTypeCommonConstants.CLEAR_RECURRING_ISSUE };

export const clearStorageIssues = {
  type: ProjectTypeCommonConstants.CLEAR_UNIVERSAL_STORAGE_ISSUE,
};

export const clearListOfIssues = { type: ProjectTypeCommonConstants.CLEAR_LIST_OF_ISSUES };

// export const clearListOfRecurringIssues = { type: ProjectTypeCommonConstants.CLEAR_LIST_OF_RECURRING_ISSUES };

export const clearPublicIssues = { type: ProjectTypeCommonConstants.CLEAR_LIST_OF_PUBLIC_TICKETS };

export const clearPrivateIssues = {
  type: ProjectTypeCommonConstants.CLEAR_LIST_OF_PRIVATE_TICKETS,
};

export const clearIssuesScoringData = {
  type: ProjectTypeCommonConstants.CLEAR_ISSUES_SCORING_DATA,
};

export const clearVector = { type: ProjectTypeCommonConstants.CLEAR_VECTOR };

export const clearListOfVectors = { type: ProjectTypeCommonConstants.CLEAR_LIST_OF_VECTORS };

export const clearVectorsScoringData = {
  type: ProjectTypeCommonConstants.CLEAR_VECTORS_SCORING_DATA,
};

export const clearListOfIdeas = { type: ProjectTypeCommonConstants.CLEAR_LIST_OF_IDEAS };

export const clearIdea = { type: ProjectTypeCommonConstants.CLEAR_IDEA };

export const clearDocuments = { type: ProjectTypeCommonConstants.CLEAR_PROJECT_DOCUMENTS };

export const setFilteredData = (id, data) => ({
  type: EntityConstants.FILTER_SET_FILTERED_ENTITY,
  payload: { [id]: data },
});

export const resetTags = (id) => {
  let resetID = [];

  if (id) {
    resetID = Array.isArray(id) ? id : [id];
  }

  return {
    type: EntityConstants.FILTER_RESET_TAGS,
    payload: resetID,
  };
};

export const addUnseenNotifications = (data) => ({
  type: ProjectTypeCommonConstants.ADD_UNSEEN_NOTIFICATIONS,
  payload: JSON.parse(data),
});

export const markAsViewedNotification = (uuid) => ({
  type: ProjectTypeCommonConstants.MARK_AS_VIEWED_NOTIFICATION,
  payload: uuid,
});

export const setNotificationSenderFilter = (user_data) => ({
  type: ProjectTypeCommonConstants.SET_SENDER_FILTER,
  payload: user_data,
});

export const markAsViewedAllNotifications = {
  type: ProjectTypeCommonConstants.MARK_AS_VIEWED_ALL_NOTIFICATIONS,
};

export const resetAllViewedNotifications = {
  type: ProjectTypeCommonConstants.RESET_ALL_VIEWED_NOTIFICATIONS,
};

export const clearCookBookStore = {
  type: ProjectTypeCommonConstants.CLEAR_COOK_BOOK_STORE,
};

export const setCustomCookBockStore = (data) => ({
  type: ProjectTypeCommonConstants.SET_CUSTOM_TEMPLATE_COOK_BOOK,
  payload: data,
});

// noinspection JSDeprecatedSymbols

import { ProjectTypeCommonConstants } from '../projectFlow/constants/Constants';
import { antNotification, getArrayForItem, getFromStore } from '../MainUtils';
import axiosRequest from './apiAxios';
import { notificationUrl } from './appConfig';

export const newNotification = () => {};

const onFailureNotification = () => {
  const locale = getFromStore(['locales', 'language']);
  antNotification('error', locale === 'ru' ? 'Уведомление не отправлено' : 'Notification has not been sent');
};

export const createNotification = (data) => (dispatch) => {
  dispatch(
    axiosRequest.post(
      'notification',
      [
        ProjectTypeCommonConstants.CREATE_NOTIFICATION_REQUEST,
        ProjectTypeCommonConstants.CREATE_NOTIFICATION_SUCCESS,
        ProjectTypeCommonConstants.CREATE_NOTIFICATION_FAILURE,
      ],
      data,
      {
        doNotCheckError: true,
        url: notificationUrl,
        tokenName: 'Notification',
        onFailure: onFailureNotification,
      },
    ),
  );
};

export const sendNotification = (event, receiver, message, params = {}) => createNotification({
  receiver: getArrayForItem(receiver),
  event_name: event,
  sender: getFromStore([
    'personalInfoService',
    'userInfo',
    'user',
    'UserInfoReducer',
    'user',
    'uuid',
  ]),
  title: event,
  message,
  params,
});

import { reverse } from 'ramda';
import { ProjectTypeCommonConstants } from '../constants/Constants';

import { COMMENT_TYPE } from '../components/comment/commentType';

const initialState = {
  fetching: false,
  comments: [],
  error: '',
  uuidForComment: '',
  needUpdateList: '',
  [COMMENT_TYPE.TOTAL]: 0,
  [COMMENT_TYPE.USER]: 0,
  [COMMENT_TYPE.SYSTEM]: 0,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ProjectTypeCommonConstants.ISSUE_COMMENTS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.ISSUE_COMMENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        comments: Array.isArray(payload) ? payload : [],
      };
    case ProjectTypeCommonConstants.ISSUE_COMMENTS_FAILURE:
      return {
        ...state,
        fetching: false,
        comments: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.NEED_UPDATE_LIST_COMMENT:
      return {
        ...state,
        needUpdateList: payload,
      };

    case ProjectTypeCommonConstants.UPDATE_TOTAL_COMMENTS_STORE:
      return {
        ...state,
        [COMMENT_TYPE.TOTAL]: payload,
      };

    case ProjectTypeCommonConstants.UPDATE_USER_COMMENTS_STORE:
      return {
        ...state,
        [COMMENT_TYPE.USER]: payload,
      };

    case ProjectTypeCommonConstants.UPDATE_SYSTEM_COMMENTS_STORE:
      return {
        ...state,
        [COMMENT_TYPE.SYSTEM]: payload,
      };

    case ProjectTypeCommonConstants.NEW_COMMENT_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.NEW_COMMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        // comments: state.comments.concat(payload)
      };
    case ProjectTypeCommonConstants.NEW_COMMENT_FAILURE:
      return {
        ...state,
        fetching: false,
        comments: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.DEL_COMMENT_REQUEST:
      return {
        ...state,
        fetching: false,
      };
    case ProjectTypeCommonConstants.DEL_COMMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        comments: state.comments,
      };
    case ProjectTypeCommonConstants.DEL_COMMENT_FAILURE:
      return {
        ...state,
        fetching: false,
        comments: state.comments,
        error: payload,
      };

    case ProjectTypeCommonConstants.EDIT_COMMENT_REQUEST:
      return {
        ...state,
        fetching: false,
      };
    case ProjectTypeCommonConstants.EDIT_COMMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        comments: state.comments,
      };
    case ProjectTypeCommonConstants.EDIT_COMMENT_FAILURE:
      return {
        ...state,
        fetching: false,
        comments: state.comments,
        error: payload,
      };
    case ProjectTypeCommonConstants.ISSUE_COMMENTS_REVERSE:
      return {
        ...state,
        comments: reverse(state.comments),
      };

    case ProjectTypeCommonConstants.CLEAR_ALL_COMMENTS:
      return {
        fetching: false,
        comments: [],
        error: '',
        [COMMENT_TYPE.TOTAL]: 0,
        [COMMENT_TYPE.USER]: 0,
        [COMMENT_TYPE.SYSTEM]: 0,
      };

    case ProjectTypeCommonConstants.SAVE_UUID_FOR_COMMENT:
      return {
        ...state,
        uuidForComment: payload,
      };

    default: return state;
  }
};

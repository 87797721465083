import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty, last } from 'lodash';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiAccountFilterOutline, mdiFullscreen, mdiFullscreenExit } from '@mdi/js';
import { Button, Spin } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import JSpreadsheet from '../../../../../JSpreadsheet/JSpreadsheet';
import { convertArrayOfHHhmmmToSum } from '../../../userFlow/tools/dailyTools/dailyTools';
import { getProjectReportForThePeriod } from '../../../userFlow/store/action-creators/periodReportsActionsCreators';
import { capitalize } from 'lodash';

import InputTimeWork from '../../../userFlow/components/StartWorkRecord/InputTimeWork';
import { getTemplateForPeriod, isDayoff } from '../userReports/utils';
import ExportReport from '../ExportReport.jsx';
import UsersTabs from '../../../components/usersTabs/UsersTabs';

import { actorFormatter, columnsProjectReport, indexesReport } from './utils';
import { entityRead } from '../../../entity/actions/entityActions';
import useURLParams from '../../../hooks/useURLParams';

export default function SpreadSheetProjectReport({ currentPeriodDate }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { getURLParams } = useURLParams();

  const [dataSheet, setDataSheet] = useState([]);
  const [styleCells, setStyleCells] = useState([]);
  const [loading, setLoading] = useState(false);

  const [fullscreen, setFullscreen] = useState(false);

  const [selectedProject, setSelectedProject] = useState(null);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isShowUsersFilterTabs, setIsShowUsersFilterTabs] = useState(false);

  const { project, partition } = getURLParams();

  const toggleFullscreen = (flag) => {
    setFullscreen(flag);
  };

  const onSelectUserTagsCallback = (data = []) => setSelectedUsers(data.map((i) => i.uuid));
  const toggleButtonUser = () => setIsShowUsersFilterTabs((prev) => !prev);

  const getReportsRequest = () => {
    const { startDate = '', endDate = '' } = currentPeriodDate || {};

    const config = {
      entity_uuid: get(selectedProject, 'uuid'),
      order: 'asc',
      order_by_params: 'date',
      params: {
        date__gte: startDate,
        date__lte: endDate,
      },
    };

    if (!isEmpty(selectedUsers)) {
      config.actor = selectedUsers;
    }

    return dispatch(getProjectReportForThePeriod(config, partition));
  };

  const setStyleCell = (obj, arr, indexRow) => {
    const newStyle = obj;

    arr.forEach((i) => {
      const index = `${i}${indexRow}`;
      newStyle[index] = 'background-color: #fff1f0;';
    });
    return newStyle;
  };

  const getProject = async () => {
    const config = {
      data: {
        entity_type: 'project',
        entity_uuid: project,
      },
      options: { partition },
    };
    const curProject = await dispatch(entityRead(config));

    setSelectedProject(get(curProject, '[0]', null));
  };

  const getProjectReport = async () => {
    setLoading(true);
    const listOfReports = await getReportsRequest();

    const totalTimeForPeriod = [];
    const newStyleCells = {};

    const templateDaysPeriod = getTemplateForPeriod(
      moment(currentPeriodDate.startDate),
      moment(currentPeriodDate.endDate).diff(moment(currentPeriodDate.startDate), 'day') + 1,
    );

    const dataTable = templateDaysPeriod.reduce((acc, cur) => {
      let lastDate = '';

      const daysFromReport = get(listOfReports, 'data', []).reduce((arr, item) => {
        // SET DATA TABLE
        if (get(item, 'date') === get(cur, 'date')) {
          if (get(item, 'trackerTime')) {
            totalTimeForPeriod.push(get(item, 'trackerTime'));
          }
          arr.push({
            date: lastDate !== get(item, 'date') ? moment(get(item, 'date')).locale('en').format('DD-MM-YY ddd') : '',
            title: get(item, 'title', ''),
            type: get(item, 'type', ''),
            description: get(item, 'description', ''),
            created: moment(get(item, 'created')).format('DD-MM-YYYY'),
            actor: actorFormatter(get(item, 'uinfo', {})),
            trackerTime: get(item, 'trackerTime', ''),
            normalTimeFormat: get(item, 'trackerTime') ? moment(get(item, 'trackerTime'), 'HH[h]mm[m]').format('HH:mm') : '',
          });
        }

        lastDate = get(item, 'date');
        return arr;
      }, []);

      if (!isEmpty(daysFromReport)) {
        if (isDayoff(get(cur, 'date'))) {
          daysFromReport.forEach((i, idx) => {
            setStyleCell(newStyleCells, indexesReport, acc.length + 1 + idx);
          });
        }
        acc.push(...daysFromReport);
      } else {
        if (isDayoff(get(cur, 'date'))) {
          setStyleCell(newStyleCells, indexesReport, acc.length + 1);
        }

        acc.push({ date: moment(get(cur, 'date')).locale('en').format(' DD-MM-YY ddd') });
      }
      return acc;
    }, []);

    if (!isEmpty(dataTable)) {
      const time = convertArrayOfHHhmmmToSum(totalTimeForPeriod) || '00h00m';
      dataTable.push({
        trackerTime: time,
        normalTimeFormat: time.replace('h', ':').slice(0, time.length - 1),
      });
    }

    setStyleCells(newStyleCells);
    setDataSheet(dataTable);
    setLoading(false);
  };

  const totalTime = useMemo(
    () => get(last(dataSheet), 'totalTime') || get(last(dataSheet), 'trackerTime'),
    [dataSheet],
  );

  useEffect(() => {
    if (project) {
      getProject();
    }
    return () => {
      setSelectedProject(null);
      setSelectedUsers([]);
    };
  }, [project]);

  useEffect(() => {
    if (selectedProject && partition) {
      getProjectReport();
    }
  }, [selectedUsers, currentPeriodDate.startDate, currentPeriodDate.endDate, selectedProject]);
  return (
    <div id="project-report" className={`wrapper-view-report ${fullscreen ? '_fullscreen' : ''}`}>
      <h6 className="p-0 mb-2 mr-3">
        {`Title: ${get(selectedProject, 'params.title', 'empty')}`}
      </h6>
      <div className="flex items-start justify-between">
        <Button
          id='setFilterByUserButton'
          className={cn('flex items-center customAntOutWarningBtn', {
            active: isShowUsersFilterTabs,
          })}
          size="small"
          onClick={toggleButtonUser}
        >
          <Icon path={mdiAccountFilterOutline} size={0.8} className="mr-1" />
          {capitalize(t('wms.buttons.user_filter', 'user filter'))}
        </Button>
        <div className="flex">
          <Button
            id='projectReportSetFullscreenButton'
            onClick={() => toggleFullscreen(!fullscreen)}
            size="small"
            className="btnPrimary-outline ant-btn-flex mb-1 mr-1"
          >
            <Icon path={fullscreen ? mdiFullscreenExit : mdiFullscreen} size={1} />
            <span className="ml-1">
              {fullscreen ? capitalize(t('wms.buttons.full_screen_exit')) : capitalize(t('wms.buttons.full_screen'))}
            </span>
          </Button>
          <ExportReport
            titleReport={get(selectedProject, 'params.title', 'empty')}
            columns={columnsProjectReport}
            dataReport={dataSheet}
          />
        </div>
      </div>
      <UsersTabs
        id={get(selectedProject, 'uuid', '')}
        projectParams={get(selectedProject, 'params')}
        isShowUsersFilterTabs={isShowUsersFilterTabs}
        onSelectUserTagsCallback={onSelectUserTagsCallback}
      />

      <div className="wrapper-view-report__view mt-0">
        {!loading ? (
          <JSpreadsheet
            columns={columnsProjectReport}
            isReadOnly
            height="100%"
            defaultTextDescription={dataSheet}
            styleCells={styleCells}
            isFullscreen={fullscreen}
          />
        ) : (
          <Spin spinning={loading} className="flex justify-center mt-3" />
        )}
      </div>

      <div className={`bg-white py-3 ${fullscreen ? 'total-time _fullscreen' : ''}`}>
        <div className="flex items-center justify-start block_time_section">
          <span className="text-secondary">Total time per day:</span>
          <div className="ml-1">
            <InputTimeWork
              disableControlls
              addTimeClass="startWorkAddBtnsDisabled"
              bordered={false}
              className="color_orange"
              small
              disableAddTimeBtnClick
              disableEdit
              defaultValueInput={totalTime}
              currentValueInput={totalTime}
            />
          </div>
        </div>
      </div>

      <div className={cn({ 'hidden': project, 'wrapper-not-selected': !project })}>
        Select project...
      </div>
    </div>
  );
}
SpreadSheetProjectReport.propTypes = {
  currentPeriodDate: string,
};

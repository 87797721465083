import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { capitalize } from 'lodash';
import { getDocumentTypeInfo } from './constants/docsEntityTypes';
import BaseTag from "../../../components/_ui/BaseTag/BaseTag";

function DocTypeLabel({
  entityDocType,
}) {
  const { t } = useTranslation();

  const label = getDocumentTypeInfo(entityDocType)?.label;
  const text = getDocumentTypeInfo(entityDocType)?.text;
  const className = getDocumentTypeInfo(entityDocType)?.className;

  return (
    <BaseTag className={`entityTagBadge flex w-fit wordBreak rounded-pill px-3 py-1 ${className}`}>
      {`${capitalize(t(label, text))}`}
    </BaseTag>
  );
}

DocTypeLabel.propTypes = {
  entityDocType: PropTypes.string.isRequired,
};

export default DocTypeLabel;

import React from 'react';
import {
  string, node, element, func,
} from 'prop-types';
import OnlyInfoVariant from './OnlyInfoVariant';
import WithRefreshvariant from './WithRefreshVariant';
import './index.scss';

export default function InfoComponent({
  tooltipPlacement = 'topLeft',
  tooltipTitle = '',
  refreshCallback,
  parent,
  partitionType,
}) {
  return refreshCallback
    ? (
      <WithRefreshvariant
        parent={parent}
        partitionType={partitionType}
        tooltipPlacement={tooltipPlacement}
        tooltipTitle={tooltipTitle}
        refreshCallback={refreshCallback}
      />
    )
    : (
      <OnlyInfoVariant
        tooltipPlacement={tooltipPlacement}
        tooltipTitle={tooltipTitle}
      />
    );
}

InfoComponent.propTypes = {
  tooltipPlacement: string || node || element,
  tooltipTitle: string,
  refreshCallback: func,
  parent: string,
  partitionType: string,
};

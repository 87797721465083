import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { mdiCancel } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Col, Modal, Row, Switch,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import BaseButton from '../../components/_ui/BaseButton/BaseButton';
import TagsGroupNames54origins from '../../54origins/components/TagsGroupNames54origins';

import { getNameForActor, stopPropagation } from '../../54origins/utils54origins';
import { antNotification } from '../../MainUtils';
import { getBanGroupUUID } from '../../actors/slices/defaultGroupsSlice';
import { entityUpdate } from '../../entity/actions/entityActions';
import useAuthSessionHook from '../hooks/useAuthSessionHook';
import useAuthActorsRequests from '../hooks/useAuthActorsRequests';
import { useUtilityActors } from '../../actors/hooks/useUtilityActors';
import { synchronizeWithAuthAndGetActor } from '../../userFlow/store/action-creators/userActions';
import { partitionNamesConfig } from '../../api/appConfig';

function AddOrRemoveUserBanGroup({
  actor,
  finallyUpdateActorCallback,
  fullLabel = true
}) {
  const dispatch = useDispatch();

  const { getActorsFromPagination } = useUtilityActors();

  const defaultState = {
    isModalOpen: false,
    restoreGroups: false,
    groupNames: [],
  };

  const [state, setState] = useState(defaultState);

  const {
    isModalOpen,
    restoreGroups,
    groupNames,
  } = state || {};

  const { requestUpdateActorOnAuth } = useAuthActorsRequests();

  const banGroupUUID = useSelector(getBanGroupUUID);

  const {
    actor_type: actorType,
    actor: actorUUID,
    uinfo: {
      groups,
    } = {},
    params: {
      prevGroups,
    },
    uuid: actorEntityUUID,
  } = actor || {};

  const userIsBanned = groups.includes(banGroupUUID);

  const changeState = (params) => setState((prev) => ({ ...prev, ...params }));

  const saveOldGroupsForActor = () => {
    dispatch(entityUpdate({
      constants: [
        'SAVE_GROUPS_FOR_ACTOR_REQUEST',
        'SAVE_GROUPS_FOR_ACTOR_SUCCESS',
        'SAVE_GROUPS_FOR_ACTOR_FAILURE',
      ],
      data: {
        params: {
          prevGroups: userIsBanned ? [] : groups,
        },
        entity_uuid: actorEntityUUID,
      },
      options: {
        partition: partitionNamesConfig.partition3,
      },
    }));
  };

  const onOk = (e) => {
    stopPropagation(e);

    const data = {
      actor_type: actorType,
      add_actors_list: [],
      remove_actors_list: [],
      uuid: actorUUID,
    };

    if (userIsBanned) {
      if (restoreGroups && prevGroups?.length > 0) {
        data.add_actors_list = prevGroups;
      }
      data.remove_actors_list = [banGroupUUID];
    } else {
      data.add_actors_list = [banGroupUUID];
      data.remove_actors_list = groups;
    }

    requestUpdateActorOnAuth(data)
      .then(() => {
        antNotification('success', 'Success');

        saveOldGroupsForActor();

        dispatch(synchronizeWithAuthAndGetActor(actorUUID));
        changeState({ isModalOpen: false });
      })
      .catch((err) => {
        checkRequestErrorAndGetAuthSession(err);
      })
      .finally(() => {
        finallyUpdateActorCallback?.();
      });
  };

  const { checkRequestErrorAndGetAuthSession } = useAuthSessionHook(onOk);

  const titleForPopConfirm = userIsBanned ? 'Remove user from ban group? Are you sure?' : 'Add user to ban group? Are you sure?';

  const showModal = () => {
    changeState({ isModalOpen: true });
  };

  const handleCancel = () => {
    changeState(defaultState);
  };

  const switchOnClick = () => {
    changeState({
      restoreGroups: !restoreGroups,
    });
  };

  const getNamesOfGroups = () => {
    if (prevGroups?.length === 0) {
      changeState({ groupNames: [] });
    } else {
      getActorsFromPagination({
        uuids: prevGroups,
      }, [
        'GET_ACTORS_FOR_BAN_ACTOR_REQUEST',
        'GET_ACTORS_FOR_BAN_ACTOR_SUCCESS',
        'GET_ACTORS_FOR_BAN_ACTOR_FAILURE',
      ]).then((res) => {
        changeState({
          groupNames: res?.data?.map((item) => getNameForActor(item)),
        });
      });
    }
  };

  useEffect(() => {
    if (restoreGroups) {
      getNamesOfGroups();
    }
  }, [restoreGroups]);

  return (
    <>
      <Modal
        title={userIsBanned ? 'Unban user' : 'Ban user'}
        open={isModalOpen}
        onOk={onOk}
        onCancel={handleCancel}
        destroyOnClose
      >
        <p>{titleForPopConfirm}</p>
        {userIsBanned && (
        <>
          <Row>
            <Col>
              <p className="mr-2">Restore groups for user?</p>
              <Switch
                onClick={switchOnClick}
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </Col>
          </Row>
          {restoreGroups && (
            <TagsGroupNames54origins
              groups={groupNames}
              className="mt-2"
            />
          )}
        </>
        )}
      </Modal>
      <BaseButton
        type="primary"
        onClick={(e) => {
          stopPropagation(e);
          showModal();
        }}
        icon={(
          <Icon
            path={mdiCancel}
            size={0.8}
            className="mr-1"
          />
        )}
        block
      >
        <span>
          {userIsBanned ? 'Unban' : 'Ban'}
          {' '}
          {fullLabel && "user"}
        </span>
      </BaseButton>
    </>
  );
}

export default AddOrRemoveUserBanGroup;

AddOrRemoveUserBanGroup.propTypes = {
  actor: PropTypes.any,
  finallyUpdateActorCallback: PropTypes.func,
  fullLabel: PropTypes.bool,
};

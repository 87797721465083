import React, { useState, useMemo, useEffect } from 'react';
import {
  arrayOf, bool, object, func,
} from 'prop-types';
import { List, Input } from 'antd';

import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';

const baseWrapperStyles = {
  position: 'relative',
  height: '350px',
  width: '265px',
  display: 'flex',
  flexDirection: 'column',
  // borderRight: '1px solid #efeeee',
};

const searchBoxStyles = {
  position: 'sticky',
  zIndex: 10,
  top: 0,
  left: 0,
  flexShrink: 0,
  padding: '10px',
  borderBottom: '1px solid #efeeee',
  backgroundColor: '#deefff',
  boxShadow: '0 1px 4px -1px rgba(0, 0, 0, 0.3)',
};

function ActorAvatarsList({
  actors = [],
  renderActorAvatar,
  wrapperListStyles,
  withSearch,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [finalActors, setFinalActors] = useState(() => actors);

  const wrapperStyles = useMemo(() => ({
    ...baseWrapperStyles,
    ...wrapperListStyles,
  }), [JSON.stringify(wrapperListStyles)]);

  const onChangeSearch = (e) => {
    const { value } = e?.target || {};
    setSearchTerm(value?.toLowerCase());
  };

  const searchUser = () => {
    if (!searchTerm) {
      setFinalActors(actors);
    } else {
      const filteredActors = actors.filter(
        (user) => user?.fullName?.toLowerCase()?.includes(searchTerm),
      );
      setFinalActors(filteredActors);
    }
  };

  useEffect(() => {
    searchUser();
  }, [searchTerm]);

  return (
    <div style={wrapperStyles}>
      {withSearch && (
        <div style={searchBoxStyles}>
          <Input
            style={{ border: 'none', borderRadius: '6px' }}
            size="middle"
            placeholder="Search user"
            variant="borderless"
            allowClear
            prefix={<Icon path={mdiMagnify} size={1} color="#bebebe" />}
            value={searchTerm}
            onChange={onChangeSearch}
          />
        </div>
      )}
      <List
        style={{ paddingInline: '12px', overflowY: 'auto' }}
        dataSource={finalActors}
        renderItem={(item, index) => (
          <List.Item key={item?.value}>
            <List.Item.Meta
              avatar={renderActorAvatar(item, index)}
              title={<div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{item?.fullName}</div>}
              description={item?.role && (<span style={{ fontSize: 'small' }}>{`( role: ${item?.role} )`}</span>)}
              // description="( role: placeholder )"
            />
          </List.Item>
        )}
      />
    </div>
  );
}

ActorAvatarsList.propTypes = {
  actors: arrayOf(object),
  renderActorAvatar: func,
  wrapperListStyles: object,
  withSearch: bool,
};

export default ActorAvatarsList;

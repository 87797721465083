import PropTypes, { object, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { capitalize, isEmpty } from 'lodash';

import './UsersTabs.css';

import TagsSettingList from '../filter/TagsSettingList';
import CollapseWrapper from '../../projectFlow/containers/CollapseWrapper';

import { filterUuidsAndGetParamsUsers, getUuidsfromParamsUsers } from '../../MainUtils';
import { getMe } from '../../projectFlow/selectors/selectors';
import { entityBackendURL } from '../../api/api';
import { getNameForActor } from '../../54origins/utils54origins';
import { getSessionTokenFor } from '../../api/appConfig';

function UsersTabs({
  id,
  projectParams,
  onSelectUserTagsCallback,
  isShowUsersFilterTabs,
}) {
  const { t } = useTranslation();

  const getMeUUID = useSelector(getMe);

  const [userFilterTags, setUserFilterTags] = useState([]);
  const [adminMembers, setAdminMembers] = useState([]);

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Session-Token': getSessionTokenFor.entity(),
    },
  };

  const onSelectUserTags = (data) => {
    setUserFilterTags(data);
    onSelectUserTagsCallback(data);
  };

  useEffect(() => {
    function getUsersByUUID() {
      const uuids = getUuidsfromParamsUsers(projectParams?.users);
      if (!isEmpty(uuids)) {
        const data = { uuids };

        axios.post(
          `${entityBackendURL()}/utility/actor/get`,
          data,
          axiosConfig,
          { withCredentials: true },
        )
          .then(({ data }) => {
            setAdminMembers(data.data);
          });
      } else {
        setAdminMembers([]);
      }
    }
    return getUsersByUUID();
  }, [projectParams?.users]);

  useEffect(() => {
    if (id) {
      setUserFilterTags([]);
    }
  }, [id]);

  const usersTabs = useMemo(() => (
    adminMembers
      .sort((a) => (a?.uuid === getMeUUID ? -1 : 1))
      .map((elem) => ({
        tags: [
          {
            key: 'userTab',
            value: elem.uuid,
            label: getNameForActor(elem),
            uuid: elem.uuid,
            className: getMeUUID === elem.uuid && 'switch-is-me',
          // titleHeader: getMeUUID === elem.uuid && 'Me:',
          },
        ],
      }))
  ), [adminMembers]);

  if (!isShowUsersFilterTabs) return <></>;

  return (
    <div className="mb-3">
      <CollapseWrapper
        className="UserTabsCollaple"
        style={{ width: '100%' }}
        disabled
      >
        <div className="mb-1">
          {`${capitalize(t('wms.labels.user_filter', 'user_filter'))}:`}
        </div>

        {adminMembers.length > 0 ? (
          <TagsSettingList
            size="small"
            type="userTab"
            onSelectTags={onSelectUserTags}
            defaultSelectedTags={userFilterTags}
            allTags={usersTabs}
            id="dashboardFilter"
          />
        )
          : <div className="mt-2">{`${capitalize(t('wms.labels.list_users_is_empty'))}...`}</div>}
      </CollapseWrapper>

    </div>
  );
}

UsersTabs.propTypes = {
  onSelectUserTagsCallback: PropTypes.func,
  isShowUsersFilterTabs: PropTypes.bool,
  id: string,
  projectParams: object,
};

export default UsersTabs;

// import Quill from "quill";
// const Keyboard = Quill.import('modules/keyboard');
// const BlockEmbed = Quill.import('blots/block/embed');

// export class DividerBlot extends BlockEmbed {
//   static blotName = 'divider';
//   static tagName = 'hr';
// }


// export function handleDivider() {
//     const quill = this.quill;
//     const range = quill.getSelection(true);
//     quill.insertText(range.index, '\n', Quill.sources.USER);
//     quill.insertEmbed(range.index + 1, 'divider', true, Quill.sources.USER);
//     quill.setSelection(range.index + 2, Quill.sources.SILENT);
// }

// Quill.register(DividerBlot);

// export function setupDeleteHandler(quill) {
//   // добавляем обработчик для клавиши Delete
  
//   quill.keyboard.addBinding({
//     key: 'Delete'
//   }, {
//     empty: true 
//   }, (range) => {
//     // Проверяем, что курсор перед blot разделителя
//     if (range.index < quill.getLength() - 1) {
//       const [blot] = quill.getLeaf(range.index + 1);
//       if (blot instanceof DividerBlot) {
//         quill.deleteText(range.index, 1, Quill.sources.USER);
//         return false;
//       }
//     }
//     return true;
//   });
// }


import Quill from "quill";
const Keyboard = Quill.import('modules/keyboard');
const BlockEmbed = Quill.import('blots/block/embed');

export class DividerBlot extends BlockEmbed {
  static blotName = 'divider';
  static tagName = 'hr';
}


export function handleDivider() {
  const quill = this.quill;
  const range = quill.getSelection();
  
  // Check if there is a selection
  if (!range) {
      console.warn("No selection available to insert divider.");
      return;
  }

  const position = range.index;
  quill.insertText(position, '\n', Quill.sources.USER);
  quill.insertEmbed(position + 1, 'divider', true, Quill.sources.USER);
  quill.setSelection(position + 2, Quill.sources.SILENT);
}

Quill.register(DividerBlot);

export function setupDeleteHandler(quill) {
  // добавляем обработчик для клавиши Delete
  
  quill.keyboard.addBinding({
    key: 'Delete'
  }, {
    empty: true 
  }, (range) => {
    // Проверяем, что курсор перед blot разделителя
    if (range.index < quill.getLength() - 1) {
      const [blot] = quill.getLeaf(range.index + 1);
      if (blot instanceof DividerBlot) {
        quill.deleteText(range.index, 1, Quill.sources.USER);
        return false;
      }
    }
    return true;
  });
}
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Col, Modal, Radio, Row, Switch,
} from 'antd';
import { isNil, propOr } from 'ramda';
import Icon from '@mdi/react';
import {
  mdiAccountCancelOutline,
  mdiAccountCheckOutline,
  mdiAccountEyeOutline, mdiAccountQuestion,
  mdiAccountSearchOutline,
  mdiAlertOutline,
  mdiArchiveSettingsOutline,
  mdiBellOutline,
  mdiBlockHelper,
  mdiBugOutline,
  mdiCheck,
  mdiClockCheckOutline,
  mdiClockTimeThreeOutline,
  mdiCloseThick,
  mdiEyeOutline,
  mdiLayersRemove,
  mdiLayersSearchOutline,
  mdiMagnify,
  mdiPause,
  mdiProgressClock,
  mdiTabRemove,
  mdiWrenchOutline,
} from '@mdi/js';
import EntityParamTag from '../../../../../components/entityParamTag/EntityParamTag';
import { capitalize } from 'lodash';
import { issuesStatusesTags } from '../../../../../constants/issuesConstants';

const defaultIssuesStatusesTags = [
  {
    key: 'status',
    value: 'created',
    label: 'wms.status.created',
    className: 'switch-validation-section',
    classNameWrapper: 'radio-lightBlue with-color-border',
    icon: mdiBellOutline,
    keySection: 'validation',
  },
  {
    key: 'status',
    value: 'in progress',
    label: 'wms.status.in progress',
    className: 'switch-development-section',
    classNameWrapper: 'radio-darkBlue with-color-border',
    icon: mdiProgressClock,
    keySection: 'development',
  },
  {
    key: 'status',
    value: 'test',
    label: 'wms.status.ready for test',
    className: 'switch-testing-section',
    classNameWrapper: 'radio-orange with-color-border',
    icon: mdiMagnify,
    keySection: 'testing',
  },
  {
    key: 'status',
    value: 'backlog',
    label: 'wms.status.backlog',
    className: 'switch-purgatory-section',
    classNameWrapper: 'radio-gray with-color-border',
    icon: mdiClockTimeThreeOutline,
    keySection: 'purgatory',
  },
  {
    key: 'status',
    value: 'discuss',
    label: 'for discuss',
    className: 'switch-purgatory-section',
    classNameWrapper: 'radio-gray with-color-border',
    icon: mdiAccountQuestion,
    keySection: 'purgatory',
  },
  {
    key: 'status',
    value: 'closed',
    label: 'wms.status.closed',
    className: 'switch-end-section',
    classNameWrapper: 'radio-dark with-color-border',
    icon: mdiCloseThick,
    keySection: 'end',
  },
];

const getWrapperClassName = (keySection) => {
  switch (keySection) {
    case 'validation':
      return 'radio-lightBlue with-color-border';
    case 'development':
      return 'radio-darkBlue with-color-border';
    case 'testing':
      return 'radio-orange with-color-border';
    case 'purgatory':
      return 'radio-gray with-color-border';
    case 'end':
      return 'radio-dark with-color-border';
    default: return 'radio-dark with-color-border';
  }
};

export default function NewIssueStatus({
  value,
  onChangeFieldCallback,
}) {
  const { t } = useTranslation();

  const [statusArr, setStatusArr] = useState(defaultIssuesStatusesTags);

  useEffect(() => {
    if (statusArr?.every((el) => el.value !== value)) {
      setStatusArr(() => {
        const selectedArr = [...defaultIssuesStatusesTags];
        selectedArr.shift();
        const selectedItem = issuesStatusesTags.find((item) => item.value === value);
        selectedItem.classNameWrapper = getWrapperClassName(selectedItem?.keySection);
        selectedArr.unshift(selectedItem);
        return selectedArr;
      });
    }
  }, [value, JSON.stringify(statusArr)]);

  return (
    <div className="radioButtons ml-1">
      <Radio.Group
        size="small"
        style={{}}
        // defaultValue={defaultValue}
        // disabled={}
        onChange={(e) => onChangeFieldCallback({ status: e.target.value })}
        value={value}
      >
        {statusArr?.map((item) => (
          <Radio
            id={item.value[0] + item.value.split(' ').map(el => el[0].toUpperCase() + el.slice(1)).join('').slice(1) + "Button"}
            key={item.label}
            value={item.value}
            className={item.classNameWrapper || item.className || ''}
          >
            <Icon
              className="mr-1"
              path={item.icon}
              size={0.8}
            />
            {capitalize(t(item.label))}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
}

NewIssueStatus.propTypes = {
  value: PropTypes.string,
  onChangeFieldCallback: PropTypes.func,
};

import React, { useEffect, useState } from 'react';
import {
  Col,
  Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  isEmpty, isNil, pathOr, prop, take,
} from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { get } from 'lodash';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';

import { clearListOfIssues } from '../../actions/PtcActionsForHook';
import { listOfIssuesFromStore } from '../../reducers/issues/IssuesSlicer';
import { capitalize } from 'lodash';
import { getUuidsfromParamsUsers } from '../../../MainUtils';
import { getProjectParams } from '../../selectors/selectors';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import WrapperAvatarsGroupForProject from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';
import useActorsW54 from '../../../actors/hooks/useActorsW54';

function ListOfIssuesTable({
  changeIssueCallback,
  clearIssuesAfterUnmount = false,
  customColumns,
  customSelectedIssue,
  issuesFromOuterComponent,
  disableOnClick,
  multipleIssueSelect = false,
  hideSearchTitle,
  hiddenColumn = [],
  fetchingIssue,
  remoteSearch,
  setSearchIssueCallback,
  customSearchValue,
  headerRowClassName,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const issuesFromStore = useSelector(listOfIssuesFromStore);
  const projectParams = useSelector(getProjectParams);

  const [selectedIssue, changeSelectedIssue] = useState(null);
  const [selectedMultipleIssues, changeSelectedMultipleIssue] = useState([]);
  const [uuidSelectedIssue, setUuidSelectedIssue] = useState([]);
  const [searchIssue, setSearchIssue] = useState('');
  const [dataTable, setDataTable] = useState([]);

  const [pageLimit, setPageLimit] = useState(10);

  // Получение issue пользователей
  const userUUIDs = dataTable?.flatMap((item) => item?.users);
  useActorsW54({ actors: userUUIDs });

  const assignedUsersFormatter = (cell, row) => {
    const users = getUuidsfromParamsUsers(row?.users);

    if (isNil(users) || isEmpty(users)) {
      return null;
    }

    return (
      <WrapperAvatarsGroupForProject
        actors={users}
        avatarSize="small"
        avatarGap={2}
        avatarStyles={{ cursor: 'pointer' }}
        groupButtonSize="small"
        checkProjectRole
      />
    );
  };

  // const indication = () => <p>{t('wms.no_records', 'Sorry, issues are not found')}</p>;

  const titleFormatter = (cell, row) => (isNil(cell)
    ? <span />
    : (
      cell
        ? (
          <Tooltip
            placement="left"
            title={row.title}
          >
            <span className="text-neutral-600">
              {cell.length > 150 ? `${take(150, cell)}...` : cell}
            </span>
          </Tooltip>
        )
        : (
          <span>{'<Empty title>'}</span>
        )
    ));

  const onRow = (row) => ({
    onClick: () => {
      const {
        uuid,
        title,
        tracker,
        priority,
        status,
        project,
      } = row;
      let finalData;
      let multipleArray = [];

      if (!disableOnClick) {
        if (multipleIssueSelect) {
          const examination = selectedMultipleIssues.some((item) => (typeof (item) === 'object'
            ? prop('uuid', item) === uuid
            : item === uuid));

          const projectUUID = project || '';
          if (!examination) {
            multipleArray = [
              ...selectedMultipleIssues,
              {
                projectUUID,
                title,
                uuid,
                tracker,
                priority,
                status,
              },
            ];
          } else {
            multipleArray = selectedMultipleIssues.filter((i) => (typeof (i) === 'object'
              ? prop('uuid', i) !== uuid
              : i !== uuid));
          }

          changeSelectedMultipleIssue(multipleArray);

          const selected = multipleArray.map((item) => (typeof (item) === 'object'
            ? prop('uuid', item)
            : item));
          setUuidSelectedIssue(selected);
        } else {
          changeSelectedIssue(uuid);
        }

        finalData = multipleIssueSelect ? multipleArray : uuid;

        if (changeIssueCallback) {
          changeIssueCallback(finalData);
        }
      }
    },
  });

  // const onSearchData = (value) => {
  //   setSearchIssue(value);
  //   if (remoteSearch) {
  //     setSearchIssueCallback(value);
  //   }
  // };
  const onSearchData = (value) => {
    setSearchIssue(value.trim());
    if (remoteSearch) {
      setSearchIssueCallback(value.trim());
    }
  };
  const searchInTitle = (
    <Col className="flex align-item-end minWidth130" span={24}>
      {hideSearchTitle
        ? t('wms.table.headers.title', 'Title')
        : (
          <AntDesignSearchBox54origins
            onSearch={onSearchData}
          // valueSearch={searchIssue}
          />
        )}
    </Col>
  );

  const formatterTag = (typeParam, item, type) => (
    <EntityParamTag
      param={typeParam}
      value={item}
      type={type}
    />
  );

  const columns = [
    // {
    //   dataIndex: 'uuid',
    //   title: 'uuid',
    //   hidden: true,
    // },
    {
      dataIndex: ['id'],
      title: 'Id',
      render: (cell) => cell && cell.toString(),
    },
    {
      dataIndex: ['title'],
      title: searchInTitle,
      render: titleFormatter,
    },
    {
      dataIndex: ['tracker'],
      title: capitalize(t('wms.table.headers.tracker', 'Tracker')),
      sort: true,
      hidden: hiddenColumn.includes('trackerColumn'),
      render: (cell) => cell && formatterTag('tracker', cell, 'char'),
    },
    {
      dataIndex: ['priority'],
      title: capitalize(t('wms.table.headers.priority', 'Priority')),
      sort: true,
      hidden: hiddenColumn.includes('priorityColumn'),
      render: (cell) => cell && formatterTag('priority', cell, 'char'),
    },
    {
      dataIndex: ['status'],
      title: capitalize(t('wms.table.headers.status', 'Status')),
      sort: true,
      hidden: hiddenColumn.includes('statusColumn'),
      render: (cell) => cell && formatterTag('status', cell, 'iconWithStatus'),
    },
    {
      dataIndex: ['assigned_users'],
      title: capitalize(t('wms.table.headers.assigned', 'Assigned')),
      sort: true,
      hidden: hiddenColumn.includes('assignedColumn'),
      render: (cell, row) => assignedUsersFormatter(cell, row),
    },
  ];

  const selectRow = {
    clickToSelect: false,
    hideSelectColumn: true,
    mode: 'radio',
    selectedRowKeys: [selectedIssue],
    // style: { background: customBackgroundRowTable || '#e5f4fc' },
  };

  const multipleSelectRow = {
    clickToSelect: !disableOnClick,
    hideSelectColumn: true,
    mode: 'checkbox',
    selectedRowKeys: uuidSelectedIssue,
    // style: { background: customBackgroundRowTable || '#e5f4fc' },
  };

  const finalData = issuesFromOuterComponent || issuesFromStore;
  const finalColumns = customColumns || columns;
  // const finalIndication = noDataIndication ? <p>{noDataIndication}</p> : indication;
  const selectedRowKeys = multipleIssueSelect ? multipleSelectRow : selectRow;
  const selectedRows = multipleIssueSelect ? uuidSelectedIssue : [selectedIssue];

  const rowClassName = (row) => {
    const data = `universal_table_row ${selectedRows.some((i) => i === get(row, 'uuid'))
      ? 'selected'
      : ''
      }`;

    return data;
  };

  useEffect(() => {
    if (multipleIssueSelect) {
      changeSelectedMultipleIssue(customSelectedIssue);
      const selected = customSelectedIssue.map((item) => (typeof (item) === 'object'
        ? prop('uuid', item)
        : item));
      setUuidSelectedIssue(selected);
    } else {
      changeSelectedIssue(customSelectedIssue);
    }
  }, [customSelectedIssue]);

  useEffect(() => () => clearIssuesAfterUnmount && dispatch(clearListOfIssues), []);

  useEffect(() => {
    let data = finalData || [];
    // console.log('data',data)
    if (searchIssue && !remoteSearch) {
      data = data.filter((item) => {
        const title = pathOr('', ['title'], item).toString().toLowerCase();
        return title.includes(searchIssue.toLowerCase());
      });
    }

    if (customSearchValue && remoteSearch) {
      data = data.filter((item) => {
        const title = pathOr('', ['title'], item).toString().toLowerCase();
        return title.includes(customSearchValue.toLowerCase());
      });
    }
    setDataTable(data);
  }, [JSON.stringify(finalData), searchIssue, customSearchValue]);

  return (
    <BaseTableWithPagination
      pageLimit={pageLimit}
      changePageLimit={setPageLimit}
      loading={fetchingIssue}
      columns={finalColumns}
      data={dataTable}
      onRow={onRow}
      paginationSize="small"
      rowClassName={rowClassName}
      headerRowClassName={headerRowClassName}
      rowSelection={selectedRowKeys}
    />
  );
}

ListOfIssuesTable.propTypes = {
  changeIssueCallback: PropTypes.func,
  clearIssuesAfterUnmount: PropTypes.bool,
  issuesFromOuterComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ListOfIssuesTable;

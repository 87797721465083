export const devScoreTooltipText = (type) => (
  <>
    <div>
      <strong>
        Dev score (
        {type}
        )
      </strong>
      {' '}
      - general scoring by project
      {' '}
      {type}
      , which showing the status of the development process and the priority of active
      {' '}
      {' '}
      {type}
      .
    </div>
    {/* <div>
      <strong>Calculation:</strong>
      {' '}
      &sum; of the priority indexes of all active
      {' '}
      {type}
      {' '}
      \ the number of active
      {' '}
      {type}
      .
    </div> */}
    <div>
      <strong>Threshold values: </strong>
      it&apos;s recommended to pay attention if the value less than 1 or more than 2.
    </div>
  </>
);

export const outdatedScoreTooltipText = (type) => (
  <>
    <div>
      <strong>
        Outdated dev score
      </strong>
      {' '}
      -   an indicator reflecting the effectiveness of monitoring
      {' '}
      {type}
      in the project.
    </div>
    {/* <div>
      <strong>Calculation:</strong>
      {' '}
      &sum; of the Outdated indexes of all active
      {' '}
      {type}
      .
    </div> */}
    <div>
      <strong>Threshold values: </strong>
      it&apos;s recommended to pay attention if the value is 0 or more than 50.
    </div>
  </>
);

export const parsePlanScoreColor = (score, isButtonColor) => {
  if (score > 10) {
    return !isButtonColor ? 'error' : 'scoringBtnDanger';
  }
  if (score > 5) {
    return !isButtonColor ? 'warning' : 'scoringBtnWarning';
  }
  if (score > 0) {
    return !isButtonColor ? 'success' : 'scoringBtnGreen';
  }
  if (score === 0) {
    return !isButtonColor ? 'warning' : 'scoringBtnWarning';
  }
  return !isButtonColor ? 'default' : 'scoringBtnDefault';
};

export const parseScoringColor = (score, isButtonColor) => {
  if (score >= 3) {
    return !isButtonColor ? 'error' : 'scoringBtnDanger';
  }
  if (score >= 2) {
    return !isButtonColor ? 'warning' : 'scoringBtnWarning';
  }
  if (score >= 1) {
    return !isButtonColor ? 'success' : 'scoringBtnGreen';
  }
  if (score >= 0 && score < 1) {
    return !isButtonColor ? 'error' : 'scoringBtnDanger';
  }
  return !isButtonColor ? 'default' : 'scoringBtnDefault';
};

export const parseOutdatedColor = (score, isButtonColor) => {
  if (score >= 100) {
    return !isButtonColor ? 'error' : 'scoringBtnDanger';
  }
  if (score >= 50) {
    return !isButtonColor ? 'warning' : 'scoringBtnWarning';
  }
  if (score > 0) {
    return !isButtonColor ? 'success' : 'scoringBtnGreen';
  }
  if (score === 0) {
    return !isButtonColor ? 'warning' : 'scoringBtnWarning';
  }
  return !isButtonColor ? 'default' : 'scoringBtnDefault';
};

// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.06.28 || YYYY/MM/DD

import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Col, Row, Tag } from 'antd';

import { actorIsAdmin, actorIsRoot, getNameForActor } from '../utils54origins';
import BaseTag from "../../components/_ui/BaseTag/BaseTag";

function TagsGroupNames54origins({
  groups,
  className,
}) {
  const getNameForGroup = (group) => (typeof group === 'object' && group !== null ? getNameForActor(group) : group);

  return (
    <Row gutter={[8, 8]} className={className}>
      {groups?.map((group, i) => (
        <Col key={group + i}>
          <BaseTag
            color={getNameForGroup(group).toLowerCase() === 'admin' ? 'red' : 'blue'}
          >
            {getNameForGroup(group)}
          </BaseTag>
        </Col>
      ))}
    </Row>
  );
}

export default memo(TagsGroupNames54origins);

TagsGroupNames54origins.propTypes = {
  className: PropTypes.string,
  groups: PropTypes.array,
};

export function ActorListOfTags({ actor }) {
  // const adminGroupUUID = useSelector(getDefaultAdminGroupUUID);

  const {
    actor_type: actorType,
    uinfo: {
      isRobot = false,
      group_name = '',
      groups = [],
    } = {},
  } = actor || {};

  return (
    <Row gutter={[8, 8]}>
      {actorIsRoot(actor) && (<Col><BaseTag color="purple">ROOT</BaseTag></Col>)}
      {(actorIsAdmin(actor) && <Col><BaseTag color="red">ADMIN</BaseTag></Col>)}
      {isRobot && <Col><BaseTag color="volcano">ROBOT</BaseTag></Col>}
    </Row>
  );
}

import React from "react";

export const relatedIssuesInfo = (
    <>
      <span>
        <strong>Linked Issues</strong>
        {' '}
        - issues that are linked to the workblock but may originate from different sources.
      </span>
    </>
  );

export const connectedIssuesInfo = (
    <>
      <span>
        <strong>Workblock Issues</strong>
        {' '}
        - issues that are directly associated with the workblock or task at hand.
        Their purpose is to provide a focused view of issues that are directly relevant to the workblock,
        helping in immediate tracking and resolution.
      </span>
    </>
  );

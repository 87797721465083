import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import {
  head, isEmpty, isNil, propOr,
} from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import TestCaseWithCommentsAndButtons from './TestCaseWithCommentsAndButtons';

import {
  BugTrackerContext,
} from '../../../context/ProjectFlowListOfContexts';

import {
  updateTestCycle,
  updateTestCaseFunc,
} from '../../../actions/QaActions';
import {
  getMe,
  getTestCycle,
  getTestCycleUUID,
  getTestSuiteUUID,
  oneTestCycleArchive,
} from '../../../selectors/selectors';

import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import moment from 'moment';
import { formatDateTimeForWms } from '../../../../MainUtils';

function TestCase({
  typeOfParent,
  testCaseUUID,
  selectedCycle,
  isArchive,
  entityUUID,
  getProgress,
}) {
  const dispatch = useDispatch();
  const {
    getTestCaseFunc,
    partitionType,
    getListOfTestCycles,
    getListOfTestCasesInCycle,
    getListOfTestCasesInSuite,
  } = useContext(BugTrackerContext);

  const testCycleUUID = useSelector(getTestCycleUUID);
  const testSuiteUUID = useSelector(getTestSuiteUUID);
  const testCycleArchive = useSelector(oneTestCycleArchive);
  const testCycle = useSelector(getTestCycle);
  const userUUID = useSelector(getMe);

  const [testCase, changeTestCase] = useState(null);
  const [testCaseFetching, changeTestCaseFetching] = useState(true);
  const [controller, changeController] = useState();

  const oneTestCycle = isArchive ? testCycleArchive : testCycle;

  const testCaseParamsFromState = propOr('', 'params', testCase);

  const updateTestCycles = async (newParams) => {
    const data = {
      uuid: testCycleUUID,
      partition: partitionType,
      params: newParams,
      customConstants: ['REQUEST', ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE, 'FAILURE'],
    };

    dispatch(updateTestCycle(data));
    // getListOfTestCycles();
  };

  const updateStatusOrProgressInCycle = ({ params }) => {    
    const { params: { status } } = oneTestCycle[0];
    const newParams = {};

    if (status === 'unexecuted') {
      newParams.status = 'in progress';
    }

    if (params?.status && params?.status !== testCase?.params?.status) {
      newParams.progress = getProgress(params?.status, testCase?.params?.status);
    }

    if (!isEmpty(newParams)) {
      updateTestCycles(newParams);
    }
  };

  const updateTestCaseGrid = async (params) => {
    // console.log("updateTestCaseGrid");
    
    // updateTestCycles({
    //   modified_on: formatDateTimeForWms(moment()),
    //   modified_by: userUUID,
    // });
    // console.log('updateTestCaseGrid')
    const { uuid, params: localParams } = testCase;

    const newController = new AbortController();
    const { signal } = newController;

    const data = {
      params: {
        ...params,
      },
    };

    const checkParam = (param) => !isNil(param) && !isEmpty(param);

    if (checkParam(testSuiteUUID)) {
      data.params.suite = testSuiteUUID;
    }

    if (checkParam(selectedCycle)) {
      data.params.cycle = testCycleUUID;
    }

    if (controller) {
      controller.abort();
    }

    changeController(newController);

    const newLocalTestCase = {
      ...testCase,
      params: {
        ...localParams,
        ...params,
      },
    };

    await dispatch(updateTestCaseFunc({
      uuid,
      params: data.params,
      partition: partitionType,
      signal,
    }));

    if (typeOfParent === 'suite') {
      getListOfTestCasesInSuite(testSuiteUUID);
    }
    if (typeOfParent === 'cycle') {
      updateStatusOrProgressInCycle({
        params: data.params,
        uuid,
      });
      getListOfTestCasesInCycle(testCycleUUID);
    }

    changeTestCase(newLocalTestCase);
  };

  const getOneTestCase = () => {
    getTestCaseFunc(testCaseUUID, typeOfParent)
      .then((response) => {
        if (response) {
          changeTestCase(head(response));
          changeTestCaseFetching(false);
        }
      });
  };

  useEffect(() => {
    getOneTestCase();
  }, []);

  return (
    <Spin spinning={testCaseFetching}>
      <TestCaseWithCommentsAndButtons
        typeOfParent={typeOfParent}
        testCaseParams={testCaseParamsFromState}
        testCaseUUID={testCaseUUID}
        testCaseData={testCase}
        entityUUID={entityUUID}
        updateTestCaseGrid={updateTestCaseGrid}
        updateTestCycles={updateTestCycles}
      />
    </Spin>
  );
}

TestCase.propTypes = {
  entityUUID: PropTypes.string,
  isArchive: PropTypes.bool,
  selectedCycle: PropTypes.string,
  testCaseUUID: PropTypes.string,
  typeOfParent: PropTypes.string,
  getProgress: PropTypes.func,
};

export default TestCase;

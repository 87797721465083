import {
  Col, Row, Input,
} from 'antd';
import { capitalize, get } from 'lodash';
import {
  array, func, object, string, arrayOf,
} from 'prop-types';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { defaultStateBtn } from '../../issuesView/utils';

import DropDownParamsEntity from '../../../../entity/components/dropDownParamsEntity/DropDownParamsEntity';
import SpecialTextArea from '../../commonComponents/SpecialTextArea/SpecialTextArea';
import DateLeftEdit from '../../../../components/DateLeftEdit/DateLeftEdit';
import DateLeft from '../../../../components/DateLeft';
import EditButton from '../../commonComponents/EditButton';
import UnSaveDataConfirmNew from '../../../../components/un-save-data-confirm/UnSaveDataConfirmNew';
import WrapperActivitiesDashboard from '../../commonComponents/activities/activitiesDashboard/WrapperActivitiesDashboard';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import { getUuid } from '../../../../assignUsersModal/utils';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';

export default function VectorInfoView({
  uuid,
  activitiesTabs,
  defaultCommentsCount,
  defaultVectorData,
  onChangeData,
  getFromParams,
  disabledView,
  vectorData,
  partitionType,
  dataProject,
  onChangeAssignedUsers,
  clickDashboardButtonHandler,
}) {
  const { t } = useTranslation();

  const refDescription = useRef({});
  const refTitle = useRef({});
  const refResult = useRef({});

  const [versionNumber, setVersionNumber] = useState('');
  const [currentExpirationDate, setCurrentExpitarionDate] = useState(null);

  const [stateBtnNumber, setStateBtnNumber] = useState(defaultStateBtn);
  const [stateBtnTitle, setStateBtnTitle] = useState(defaultStateBtn);
  const [stateBtnDescription, setStateBtnDescription] = useState(defaultStateBtn);
  const [isEditLifeTime, setIsEditLifeTime] = useState(false);
  const [stateBtnResult, setStateBtnResult] = useState(defaultStateBtn);

  const [isEditAssignUsers, setEditAssignUsers] = useState(false);

  const onChangeLifeTime = (data) => {
    const { lifeTime } = data;

    setCurrentExpitarionDate(lifeTime);
  };

  const setIsEdit = (e, closeEdit) => {
    const { id } = e.currentTarget;
    switch (id) {
      case 'description':
        if (stateBtnDescription.isEdit && !closeEdit) {
          onChangeData('description', refDescription.current?.trim());
        }
        refDescription.current = getFromParams('description');
        setStateBtnDescription((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'title':
        if (stateBtnTitle.isEdit && !closeEdit) {
          onChangeData('title', refTitle.current?.trim());
        }
        refTitle.current = getFromParams('title');
        setStateBtnTitle((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'lifeTime':
        if (isEditLifeTime && !closeEdit) {
          onChangeData('lifeTime', currentExpirationDate);
        }
        setCurrentExpitarionDate(getFromParams('lifeTime'));
        setIsEditLifeTime((prev) => !prev);
        break;
      case 'versionNumber':
        if (stateBtnNumber.isEdit && !closeEdit) {
          onChangeData('versionNumber', versionNumber);
        }
        setVersionNumber(getFromParams('versionNumber'));
        setStateBtnNumber((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'result':
        if (stateBtnResult.isEdit && !closeEdit) {
          onChangeData('result', refResult.current?.trim());
        }
        refResult.current = getFromParams('result', '');
        setStateBtnResult((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      default: break;
    }
  };

  const onChangeTextArea = ({ id, value }) => {
    switch (id) {
      case 'title':
        refTitle.current = value;
        if (!stateBtnTitle?.isValid || !value.trim()?.length) {
          setStateBtnTitle((prev) => ({ ...prev, isValid: value.trim()?.length }));
        }
        break;
      case 'description':
        refDescription.current = value;
        if (!stateBtnDescription?.isValid) {
          setStateBtnDescription((prev) => ({ ...prev, isValid: true }));
        }
        break;
      case 'result':
        refResult.current = value;
        if (!stateBtnResult?.isValid) {
          setStateBtnResult((prev) => ({ ...prev, isValid: value.length }));
        }
        break;
      default: break;
    }
  };

  const onChangeNumber = (e) => {
    const value = e.target.value.replace(/[a-zа-яё]/gi, '')?.trim();
    setVersionNumber(value);
    if (!stateBtnNumber?.isValid || !value.trim()?.length) {
      setStateBtnNumber((prev) => ({ ...prev, isValid: value.trim()?.length }));
    }
  };

  const {
    disabledFull,
  } = useMemo(() => ({
    disabledFull: disabledView.includes('fullEdit'),
  }), [disabledView]);

  useEffect(() => {
    setVersionNumber(getFromParams('versionNumber'));
  }, [getFromParams('versionNumber')]);

  useEffect(() => {
    if (vectorData?.uuid) {
      if (stateBtnDescription.isEdit) {
        setStateBtnDescription(defaultStateBtn);
      }
      if (stateBtnTitle.isEdit) {
        setStateBtnTitle(defaultStateBtn);
      }
      if (stateBtnNumber.isEdit) {
        setStateBtnNumber(defaultStateBtn);
      }
      if (stateBtnResult.isEdit) {
        setStateBtnResult(defaultStateBtn);
      }
    }
  }, [vectorData?.uuid]);

  return (
    <>
      {getFromParams('type') === 'version' && (
        <>
          <div className="flex items-center py-1 px-1">
            <div className="wrapper-title pb-0 flex items-center">
              <UnSaveDataConfirmNew dataIsNotSave={stateBtnNumber.isEdit && stateBtnNumber.isValid}>
                <EditButton
                  id="versionNumber"
                  isEditFlag={stateBtnNumber.isEdit}
                  disabled={(!stateBtnNumber.isValid && stateBtnNumber.isEdit) || disabledFull}
                  onClick={setIsEdit}
                />
              </UnSaveDataConfirmNew>
              {`${capitalize(t('wms.labels.version_number'))}:`}
            </div>
            <div className="grow wrapper-description pt-0 pl-0 ml-2">
              <Input
                maxLength={25}
                autoSize
                type="text"
                placeholder="Enter number"
                value={versionNumber}
                onChange={onChangeNumber}
                bordered={stateBtnNumber.isEdit}
                disabled={!stateBtnNumber.isEdit}
              />
            </div>
          </div>

          <hr className="my-2" />
        </>
      )}

      <Row justify="space-between" gutter={10} className="px-1 pt-1 pb-2">
        <Col className="flex items-stretch">
          <div className="flex flex-col items-start pt-1">
            <div className="wrapper-title pb-0">
              {`${capitalize(t('wms.status.title', 'status'))}:`}
            </div>
            <div className="grow wrapper-description pt-0 pl-0">
              <DropDownParamsEntity
                type="vector_status"
                disabled={disabledFull}
                currentSelect={getFromParams('status')}
                dropDownCallback={(value) => onChangeData('status', value?.vector_status)}
              />
            </div>
          </div>
        </Col>
        <Col className="flex items-stretch">
          <div className="flex flex-col items-start pt-1">
            <div className="wrapper-title pb-0">
              {`${capitalize(t('wms.priority.title', 'priority'))}:`}
            </div>
            <div className="grow wrapper-description pt-0 pl-0">
              <DropDownParamsEntity
                type="priority"
                disabled={disabledFull}
                currentSelect={getFromParams('priority')}
                dropDownCallback={(value) => onChangeData('priority', value?.priority)}
              />
            </div>
          </div>
        </Col>
        <Col className="flex items-stretch">
          <div className="flex flex-col items-start pt-1">
            <div className="wrapper-title pb-0">
              {`${capitalize(t('wms.vectors.type.title', 'type'))}:`}
            </div>
            <div className="grow wrapper-description pt-0 pl-0">
              <DropDownParamsEntity
                type="vector_type"
                disabled={disabledFull}
                currentSelect={getFromParams('type')}
                dropDownCallback={(value) => onChangeData('type', value?.vector_type)}
              />
            </div>
          </div>
        </Col>
        <Col className="flex items-stretch">
          <div className="flex flex-col items-start">
            <div className="wrapper-title pb-0 flex items-center">
              <span className="mr-1">{`${capitalize(t('wms.labels.expiration_date', 'expiration date'))}:`}</span>
              <UnSaveDataConfirmNew dataIsNotSave={isEditLifeTime}>
                <EditButton
                  id="lifeTime"
                  isEditFlag={isEditLifeTime}
                  onClick={setIsEdit}
                  disabled={disabledFull}
                />
              </UnSaveDataConfirmNew>
            </div>
            <div className={cn('grow wrapper-description pt-0 pl-0', { 'pt-1': isEditLifeTime })}>
              {isEditLifeTime ? (
                <DateLeftEdit
                  expirationDate={currentExpirationDate}
                  onChangeFieldCallback={onChangeLifeTime}
                />
              ) : (
                <DateLeft
                  date={get(vectorData, 'params.lifeTime', '')}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>

      <hr className="my-2" />

      <div className="flex items-center py-1 px-1">
        <div className="wrapper-title pb-0 flex items-center">
          <UnSaveDataConfirmNew
            id="vectorTitle"
            dataIsNotSave={stateBtnTitle.isValid && stateBtnTitle.isEdit}
          >
            <EditButton
              id="title"
              isEditFlag={stateBtnTitle.isEdit}
              disabled={(!stateBtnTitle.isValid && stateBtnTitle.isEdit) || disabledFull}
              onClick={setIsEdit}
            />
          </UnSaveDataConfirmNew>
          {`${capitalize(t('wms.labels.title', 'Title'))}:`}
        </div>
        <div className="grow wrapper-description title-description pt-0 pl-0 ml-2">
          <SpecialTextArea
            id="title"
            placeholder="Enter title"
            maxLength={128}
            defaultValue={getFromParams('title')}
            isEdit={stateBtnTitle.isEdit}
            onChange={onChangeTextArea}
          />
        </div>
      </div>

      <hr className="my-2" />

      <div className="flex items-center w-full px-1">
        <div className="wrapper-title pb-0 flex items-center">
          <EditButton
            id='editAssignedUsersButton'
            disabled={disabledFull}
            onClick={() => setEditAssignUsers(true)}
          />
          <AssigningUsersRoot
            header="Assigning users"
            isOpen={isEditAssignUsers}
            setIsOpen={setEditAssignUsers}
            onUpdateUsers={onChangeAssignedUsers}
            needUpdateProjectUsers
            placement="left"
            isModal
            globalOptions={{
              uuids: get(dataProject, 'projectData.users', [])?.map((el) => getUuid(el)),
              roles: get(dataProject, 'projectData.users', []),
              defaultColumn: 'project',
            }}
            tabs={{
              allConfig: {
                label: 'All',
                value: 'all',
                textSelectButton: 'Add to project/vector',
                showSections: ['button-add-user'],
              },
              projectConfig: {
                label: 'Project',
                value: 'project',
                textSelectButton: 'Add to vector',
                defaultItems: get(dataProject, 'projectData.users', []),
                showSections: ['string-role', 'button-add-user'],
              },
            }}
            usersConfig={{
              label: 'Vector users',
              showSections: ['string-role', 'button-remove-user'],
              textDeleteButton: 'Remove users',
              defaultItems: get(defaultVectorData, 'visibleUsers', []),
            }}
            projectUuid={get(dataProject, 'projectData.uuid', '')}
            partition={partitionType}
          />
          <Row
            onClick={() => !disabledFull && setEditAssignUsers((prev) => !prev)}
            style={{ cursor: 'pointer' }}
          >
            {`${capitalize(t('wms.labels.assigned_users'))}:`}
          </Row>
        </div>
        <div className="grow wrapper-description ml-2">
          <WrapperAvatarsGroupForProjectWithHook
            actors={get(defaultVectorData, 'visibleUsers', []) || []}
            avatarSize={28}
            popoverTrigger="click"
            popoverPlacement="right"
            checkProjectRole
          />
        </div>
      </div>

      <hr className="my-2" />

      <div className="flex  flex-col items-start w-full px-1 mb-2">
        <div className="wrapper-title pb-0 flex items-center">
          <UnSaveDataConfirmNew
            dataIsNotSave={stateBtnDescription.isEdit && stateBtnDescription.isValid}
          >
            <EditButton
              id="description"
              isEditFlag={stateBtnDescription.isEdit}
              disabled={(!stateBtnDescription.isValid && stateBtnDescription.isEdit) || disabledFull}
              onClick={setIsEdit}
            />
          </UnSaveDataConfirmNew>
          {`${capitalize(t('wms.labels.need_done', 'what needs to be done'))}:`}
        </div>
        <div className={cn('grow wrapper-description pl-0 w-full', { 'mt-1': stateBtnDescription.isEdit })}>
          <SpecialTextArea
            id="description"
            className={cn({ 'min-height-50': stateBtnDescription.isEdit, 'min-h-0': !stateBtnDescription.isEdit })}
            defaultValue={getFromParams('description')}
            isEdit={stateBtnDescription.isEdit}
            onChange={onChangeTextArea}
          />
        </div>
      </div>

      <hr className="my-2" />

      <div className="flex  flex-col items-start w-full px-1 mb-2">
        <div className="wrapper-title pb-0 flex items-center">
          <UnSaveDataConfirmNew dataIsNotSave={stateBtnResult.isValid && stateBtnResult.isEdit}>
            <EditButton
              id="result"
              isEditFlag={stateBtnResult.isEdit}
              disabled={(!stateBtnResult.isValid && stateBtnResult.isEdit) || disabledFull}
              onClick={setIsEdit}
            />
          </UnSaveDataConfirmNew>
          {`${capitalize('Result')}:`}
        </div>
        <div className={cn('grow wrapper-description pl-0 w-full', { 'mt-1': stateBtnResult.isEdit })}>
          <SpecialTextArea
            id="result"
            className={cn({ 'min-height-50': stateBtnResult.isEdit, 'min-h-0 mb-1': !stateBtnResult.isEdit })}
            defaultValue={getFromParams('result', '')}
            isEdit={stateBtnResult.isEdit}
            onChange={onChangeTextArea}
          />
        </div>
      </div>

      <hr className="my-2" />

      <Row className="px-1 mb-2">
        <Col span={24}>
          <WrapperActivitiesDashboard
            defaultCommentsCount={defaultCommentsCount}
            entityType="vector"
            parentUUID={uuid}
            partition={partitionType}
            tabs={activitiesTabs}
            clickDashboardButton={clickDashboardButtonHandler}
          />
        </Col>
      </Row>

    </>
  );
}

VectorInfoView.propTypes = {
  uuid: string,
  activitiesTabs: arrayOf(object),
  defaultCommentsCount: object,
  defaultVectorData: object,
  onChangeData: func,
  getFromParams: func,
  disabledView: array,
  vectorData: object,
  partitionType: string,
  dataProject: object,
  onChangeAssignedUsers: func,
  clickDashboardButtonHandler: func,
};

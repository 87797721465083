import React, { useEffect, useRef, useState } from "react";
import { io } from 'socket.io-client';
import { useDispatch } from 'react-redux';

import { addUnseenNotifications } from '../projectFlow/actions/PtcActionsForHook';
import { UsersPartitionConstants } from '../userFlow/constants/Constants';
import { getSessionTokenFor, gptUrl, notificationUrl } from '../api/appConfig';

export const SocketContext = React.createContext({ socket: null });

function SocketProvider({ children }) {
  const dispatch = useDispatch();

  // we use a ref to store the socket as it won't be updated frequently
  const notifySocket = useRef(io(`${notificationUrl}/notification`, {
    path: '/socket',
    withCredentials: true,
  }));

  const gptSocket = useRef(io(gptUrl, {
    withCredentials: true,
  }));

  const currentNotifySocket = notifySocket.current;
  const currentGptSocket = gptSocket.current;

  // const [gptConnected] = useState()

  // const gptSocketIsConnected = currentGptSocket?.connected;

  // When the Provider mounts, initialize it 👆
  // and register a few listeners 👇

  const subscribeEvent = (eventName, handler, socket) => {
    // console.log('subscribeEvent **************', eventName);
    socket?.on(eventName, handler);
  };
  const sendEmit = (name, data, socket) => {
    // console.log('SOCKET EMIT ****');
    // console.log('EMIT NAME', name);
    // console.log('EMIT DATA', data);
    // console.log('SOCKET SOCKET', socket);
    // console.log('SOCKET ****');

    socket?.emit(name, data);
  };

  const sendNotifyEmit = (name, data) => sendEmit(name, data, currentNotifySocket);

  const sendGptEmit = (name, data) => {
    sendEmit(name, data, currentGptSocket);
  };

  const subscribeNotify = (eventName, handler) => subscribeEvent(eventName, handler, currentNotifySocket);

  const subscribeGpt = (eventName, handler) => {
    subscribeEvent(eventName, handler, currentGptSocket);
  };

  const subscribeOnNotificationService = () => {
    subscribeNotify('connect', () => {
      console.log('SocketIO: Notification Connected and authenticated');
    });

    subscribeNotify('disconnect', () => {
      console.log('SocketIO: disconnect');
    });

    subscribeNotify('error', (msg) => {
      console.error('SocketIO: Error', msg);
    });

    subscribeNotify('notification_authorization', (res) => {
      console.log('SUCCESS notification_authorization');
      sendNotifyEmit('notification_authorization', `${getSessionTokenFor.notification()}`);
    });

    subscribeNotify('get_new_notification', (data) => {
      // console.log('data data', data);
      const { message, event_name } = JSON.parse(data);

      if (Notification.permission === 'granted' && message) {
        new Notification(`WMS54 ${event_name}`, { body: message });
      }

      dispatch(addUnseenNotifications(data));
    });

    subscribeNotify('update_vector_activities', (data) => {
      // data - is object already, don't parse!
      // console.log("update_vector_activities", data?.params);
      dispatch({
        type: UsersPartitionConstants.SYNC_DATA,
        payload: data?.params,
      });
      //  dispatch(setSyncData(data?.params));
    });
  };

  const subscribeOnGptService = () => {
    // console.log('currentGptSocket', currentGptSocket);

    subscribeGpt('connect', () => {
      console.log('SocketIO: GPT Connected and authenticated');
    });

    subscribeGpt('disconnect', () => {
      console.log('SocketIO: GPT disconnect');
    });

    subscribeGpt('error', (msg) => {
      console.error('SocketIO: GPT Error', msg);
    });
  };

  useEffect(() => {
    subscribeOnNotificationService();

    // Remove all the listeners and
    // close the socket when it unmounts
    return () => {
      if (currentNotifySocket) {
        currentNotifySocket.removeAllListeners();
        currentNotifySocket.close();
      }
    };
  }, []);

  // console.log('EFFECT currentGptSocket', currentGptSocket);

  useEffect(() => {
    // console.log('EFFECT currentGptSocket', currentGptSocket);
    // console.log('EFFECT currentNotifySocket', currentNotifySocket);
  }, []);

  return (
    <SocketContext.Provider value={{
      notifySocket: currentNotifySocket,
      gptSocket: currentGptSocket,
      sendNotifyEmit,
      sendGptEmit,
      subscribeGpt,
      subscribeOnGptService,
    }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export default SocketProvider;

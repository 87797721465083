/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  startWorkReportsData: [],
  startWorkReportsTotal: 0,
  startWorkReportsIsLoading: false,
  startWorkReportsError: '',

  partialDailyEntityRequestTrigger: false,
  partialStartWorkReportsData: [],
  partialStartWorkReportsIsLoading: false,
  partialStartWorkReportsError: '',

  detailedReportsData: [],
  detailedReportsTotal: 0,
  detailedReportsIsLoading: false,
  detailedReportsError: '',

  partialDetailedReportsRequestTrigger: false,
  partialDetailedReportsData: [],
  partialDetailedReportsIsLoading: false,
  partialDetailedReportsError: '',
};

const periodReportsSlice = createSlice({
  name: 'periodReports',
  initialState,
  reducers: {
    GET_PERIOD_START_WORK_RECORDS_REQUEST: (state) => {
      state.startWorkReportsIsLoading = true;
    },
    GET_PERIOD_START_WORK_RECORDS_SUCCESS: (state, action) => {
      state.startWorkReportsTotal = action.total || 0;
      state.startWorkReportsIsLoading = false;
      state.startWorkReportsData = action.payload;
      state.startWorkReportsError = '';
    },
    GET_PERIOD_START_WORK_RECORDS_FAILURE: (state, action) => {
      state.startWorkReportsIsLoading = false;
      state.startWorkReportsData = [];
      state.startWorkReportsError = action.payload;
    },

    GET_PARTIAL_DAILY_ENTITIES_REQUEST_TRIGGER: (state, action) => {
      state.partialDailyEntityRequestTrigger = action.payload;
    },
    GET_PARTIAL_PERIOD_START_WORK_REPORTS_REQUEST: (state) => {
      state.partialStartWorkReportsIsLoading = true;
    },
    GET_PARTIAL_PERIOD_START_WORK_REPORTS_SUCCESS: (state, action) => {
      state.partialStartWorkReportsIsLoading = false;
      state.partialStartWorkReportsData = action.payload;
      state.partialStartWorkReportsError = '';
    },
    GET_PARTIAL_PERIOD_START_WORK_REPORTS_FAILURE: (state, action) => {
      state.partialStartWorkReportsIsLoading = false;
      state.partialStartWorkReportsData = [];
      state.partialStartWorkReportsError = action.payload;
    },

    GET_PERIOD_DETAILED_RECORDS_REQUEST: (state) => {
      state.detailedReportsIsLoading = true;
    },
    GET_PERIOD_DETAILED_RECORDS_SUCCESS: (state, action) => {
      state.detailedReportsTotal = action.total || 0;
      state.detailedReportsIsLoading = false;
      state.detailedReportsData = action.payload;
      state.detailedReportsError = '';
    },
    GET_PERIOD_DETAILED_RECORDS_FAILURE: (state, action) => {
      state.detailedReportsIsLoading = false;
      state.detailedReportsData = [];
      state.detailedReportsError = action.payload;
    },
    CLEAR_PERIOD_START_WORK_REPORTS: (state) => {
      state.startWorkReportsIsLoading = false;
      state.startWorkReportsData = [];
      state.startWorkReportsError = '';
    },
    CLEAR_PARTIAL_PERIOD_START_WORK_REPORTS: (state) => {
      state.partialStartWorkReportsIsLoading = false;
      state.partialStartWorkReportsData = [];
      state.partialStartWorkReportsError = '';
    },
  },
});

export const periodReportsActions = periodReportsSlice.actions;

export default periodReportsSlice.reducer;

export const {
  GET_PERIOD_START_WORK_RECORDS_REQUEST,
  GET_PERIOD_START_WORK_RECORDS_SUCCESS,
  GET_PERIOD_START_WORK_RECORDS_FAILURE,
  GET_PARTIAL_DAILY_ENTITIES_REQUEST_TRIGGER,
  GET_PARTIAL_PERIOD_START_WORK_REPORTS_REQUEST,
  GET_PARTIAL_PERIOD_START_WORK_REPORTS_SUCCESS,
  GET_PARTIAL_PERIOD_START_WORK_REPORTS_FAILURE,
  GET_PERIOD_DETAILED_RECORDS_REQUEST,
  GET_PERIOD_DETAILED_RECORDS_SUCCESS,
  GET_PERIOD_DETAILED_RECORDS_FAILURE,
  CLEAR_PERIOD_START_WORK_REPORTS,
  CLEAR_PARTIAL_PERIOD_START_WORK_REPORTS,
} = periodReportsSlice.actions;

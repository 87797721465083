import React from "react";
import PropTypes from "prop-types";
import { Flex, Table } from "antd";
import { capitalize, get } from "lodash";
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import EditIssueForIssuesTable from "../issuesView/EditIssueForIssuesTable";
import { useTranslation } from "react-i18next";

export default function ConnectedIssuesTable({
    issuesData,
    selectIssueCallback,
    connectedIssuesPage,
    setConnectedIssuesPage,
    totalCountConnectedIssues,
    loading,
  }) {
    const {t} = useTranslation()
    const getBackgroundColor = (type) => {
            switch (type) {
              case 'ticket':
                return 'ticket-background';
              case 'bug':
                return 'bug-background';
              case 'feature':
                return 'feature-background';
              case 'low':
                return 'low-background';
              case 'medium':
                return 'medium-background';
              case 'high':
                return 'high-background';
              case 'urgent':
                return 'urgent-background';
              case 'easy':
                return 'easy-background';
              case 'normal':
                return 'normal-background';
              case 'difficult':
                return 'difficult-background';
              case 'very_complicated':
                return 'very-complicated-background';
              default:
                return '';
            }
          };

        const issuesColumns = [
            {
              dataIndex: 'id',
              title: 'ID',
              key: 'id',
              render: (cell, row) => {

                return ( get(row, ['params', 'id']) || cell);
              },
            },
            {
              dataIndex: 'title',
              key: 'title',
              title: 'Title',
              render: (cell, row) => (
                <EntityParamTag
                  value={get(row, ['params', 'title']) || cell}
                  type="value"
                  param="title"
                  maxLength={128}
                />
              ),
            },
            {
              dataIndex: 'tracker',
              key: 'tracker',
              title: "Info",
              render: (cell, row) =>  (
                <EditIssueForIssuesTable
                  issue={row}
                  type="tracker"
                >
                  <Flex>
                  <Flex
                    align="center"
                    justify="center"
                    className={`${getBackgroundColor(cell)}`}
                    style={{
                      height: '20px',
                      borderRadius: '4px',
                    }}
                  >
                    <EntityParamTag param="tracker" value={get(row, ['tracker'])} type="char" />
                  </Flex>
                    <Flex
                    align="center"
                    justify="center"
                    className={`${getBackgroundColor(cell)} ml-2 mr-2`}
                    style={{
                      height: '20px',
                      borderRadius: '4px',
                    }}
                  >
                    <EntityParamTag param="priority" value={get(row, ['priority'])} type="char" />
                  </Flex>
                  <Flex
                    align="center"
                    justify="center"
                    className={`${getBackgroundColor(cell)}`}
                    style={{
                      height: '20px',
                      borderRadius: '4px',
                    }}
                  >
                    <EntityParamTag param="estimated_time" value={get(row, ['estimated_time'])} type="char" />
                  </Flex>
                  </Flex>
                </EditIssueForIssuesTable>
              ),
            },
            {
              dataIndex: 'status',
              key: 'status',
              title: capitalize(t('wms.table.headers.status', 'status')),
              render: (cell, row) => (
                <EntityParamTag
                  value={get(row, ['params', 'status']) || cell}
                  param="status"
                  type={'iconWithStatus'}
                />
              ),
            },
           ]

    return <div>
          <Table
           columns={issuesColumns}
           dataSource={issuesData}
           loading={loading}
           pagination={issuesData.length > 5 ? {
            current: connectedIssuesPage,
            pageSize: 5,
            total: issuesData.length ,
            onChange: (page) => {
              setConnectedIssuesPage(page);
            },
            style: {margin: '5px 0'},
          } : false}
           size='small'
           className="mb-3"
           onRow={(record) => ({
             onClick: () => selectIssueCallback(record.uuid),
             style: {cursor: 'pointer'},
           })}
           />
    </div>
}

ConnectedIssuesTable.PropTypes = {
    issuesData: PropTypes.array,
    tableColums: PropTypes.array,
    setConnectedIssuesPage: PropTypes.func,
    loading: PropTypes.bool,
    totalCountConnectedIssues: PropTypes.number,
    selectIssueCallback: PropTypes.func
}

import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import useGetUserPerm from "../hooks/useGetUserPerm";
import { any, array, bool, string } from "prop-types";
import { getProjectCreatorPerms } from "../../projectFlow/selectors/selectors";
import BaseButton from "../../components/_ui/BaseButton/BaseButton";
import { UI54Button } from "@agpl/ui54kit";

export default function BaseButtonWithCheckPerms({
    tooltipTitle = 'You don\'t have perms!',
    tooltipColor = 'grey',
    entityUUID = '',
    partitionType = '',
    needCheckCreatorPerms = false,
    permsList = [],
    children,
    disabled = false,
    ...props
}) {
    const projectCreatorPerms = useSelector(getProjectCreatorPerms);

    const [hasPerm, setHasPerm] = useState(false);

    const { perm } = useGetUserPerm({
        entityUUID,
        partitionType,
    });

    useEffect(() => {
        if (permsList.length && perm) {
            const newHasPerms = permsList.every(p => perm?.[p])
            setHasPerm(newHasPerms)
        }
    }, [
        JSON.stringify(perm),
        JSON.stringify(projectCreatorPerms),
        needCheckCreatorPerms,
        JSON.stringify(permsList),
    ])

    return <Tooltip
        title={!hasPerm && tooltipTitle}
        color={tooltipColor}
    >
        <UI54Button
            disabled={disabled || !hasPerm}
            {...props}
        >
            {children}
        </UI54Button>
    </Tooltip>
}

BaseButtonWithCheckPerms.propTypes = {
    tooltipTitle: string,
    tooltipColor: string,
    entityUUID: string,
    partitionType: string,
    needCheckCreatorPerms: bool,
    permsList: array,
    children: any,
    disabled: bool,
}


import { capitalize } from 'lodash';

const statusOptions = (t) => ([
  {
    value: 'created',
    label: capitalize(t('wms.status.created', 'created')),
    className: 'radio-blue',
    mainButtonStyle: 'createdBlue drop-down-button-status',
    iconActiveColor: '#3e9bffec',
  },
  {
    value: 'active',
    label: capitalize(t('wms.status.active', 'active')),
    className: 'radio-green',
    mainButtonStyle: 'activeGreen drop-down-button-status',
    iconActiveColor: '#52c41a',
  },
  {
    value: 'closed',
    label: capitalize(t('wms.status.closed', 'closed')),
    className: 'radio-red',
    mainButtonStyle: 'closedRed drop-down-button-status',
    iconActiveColor: '#f5222d',
  },
  {
    value: 'paused',
    label: capitalize(t('wms.status.paused', 'paused')),
    className: 'radio-orange',
    mainButtonStyle: 'pausedOrange drop-down-button-status',
    iconActiveColor: '#3e9bffec',
  },
  {
    value: 'archive',
    label: capitalize(t('wms.status.archive', 'archive')),
    className: 'radio-gold ',
    mainButtonStyle: 'archiveGold drop-down-button-status',
    iconActiveColor: '#faad14',
  },
]);

export default statusOptions;

export const projectStatusSelectedOptions = {
  created: 'createdBlue',
  active: 'activeGreen',
  closed: 'closedRed',
  paused: 'pausedOrange',
  archive: 'archiveGold',
};

export const projectStatusesOption = ['created', 'active', 'closed', 'paused', 'archive'];

export const projectDefaultTags = ['frontend', 'backend', 'mobile', 'qa'];

export const projectDefaultQaTags = ['automation'];

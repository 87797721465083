import React, { useState } from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

const initialModalState = {
  confirmLoading: false,
  disableOkButton: true,
};

function useStatusModal(disableOkButton = true) {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(() => ({ ...initialModalState, disableOkButton }));

  const {
    confirmLoading: modalConfirmLoading,
    disableOkButton: modalDisableOkButton,
  } = modalState || {};

  const modalOkText = capitalize(t('wms.verb.save', 'save'));
  const modalCancelText = capitalize(t('wms.verb.cancel', 'cancel'));

  const modalHeader = (
    <div className="text-neutral-600 text-base">
      {t('wms.labels.change_of_status', 'Do you want to change your status, are you sure?')}
      <hr className="my-2" />
    </div>
  );

  const changeModalState = (params) => setModalState((prev) => ({ ...prev, ...params }));

  return {
    modalHeader,
    modalOkText,
    modalCancelText,
    modalConfirmLoading,
    modalDisableOkButton,
    changeModalState,
  };
}

useStatusModal.propTypes = {
  disableOkButton: bool,
};

export default useStatusModal;

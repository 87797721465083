import { getOrCreateSingle } from "../../entity/actions/entityActions";
import {
    GET_OR_CREATE_USER_BOARD_STORAGE_REQUEST,
    GET_OR_CREATE_USER_BOARD_STORAGE_SUCCESS,
    GET_OR_CREATE_USER_BOARD_STORAGE_FAILURE,
} from "../slices/userBoardStorageSlice";

export const getOrCreateUserBoardStorage = ({ parent, partition, ...options }) => (dispatch) => dispatch(getOrCreateSingle({
    data: {
        entity_type: 'userBoardStorage',
        parent: parent,
        ...options,
    },
    partition: partition,
    constants: [
        GET_OR_CREATE_USER_BOARD_STORAGE_REQUEST,
        GET_OR_CREATE_USER_BOARD_STORAGE_SUCCESS,
        GET_OR_CREATE_USER_BOARD_STORAGE_FAILURE,
    ],
}));

import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";

import { Col, Flex, List, Tag } from "antd";
import { UI54Button, UI54Icon } from "@agpl/ui54kit";
import ListContainer from "./components/ListContainer";
import ListHeaderWithSearch from './components/ListHeaderWithSearch';

import { mdiAccountPlus } from '@mdi/js';

import { getProjectParams } from "../../../../../selectors/selectors";
import { getActorsW54 } from '../../../../../../actors/selectors/actorsW54Selector';

import { actorIsRootOrAdmin } from '../../../../../../54origins/utils54origins';
import { fullName, sortByAdmin, sortByLabel } from './utils';


function ProjectUsersList({
  selectedActors = [],
  setSelectedActors,
}) {
  const actorsW54 = useSelector(getActorsW54);
  console.log('actorsW54:', actorsW54);

  const projectParams = useSelector(getProjectParams);
  console.log('projectParams:', projectParams);
  const projectActors = get(projectParams, 'usersSearch', []);

  // const [projectActorsList, setProjectActorsList] = useState();

  const [searchMode, setSearchMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const getRoleFromProject = (uuid) => {
    const { users = [] } = projectParams;
    return users.find((user) => user.uuid === uuid)?.role || '';
  };

  const actorsFinalList = useMemo(() => {
    console.log('useMemo');
    let actorsData = [];
    projectActors.forEach((uuid) => {
      const actorW54 = actorsW54[uuid];
      if (!isEmpty(actorW54)) {
        actorsData.push({
          uuid: actorW54?.uuid,
          actorType: actorW54?.actor_type,
          isAdmin: actorIsRootOrAdmin(actorW54),
          label: fullName(actorW54),
          role: getRoleFromProject(actorW54?.uuid),
        });
      }
    });

    console.log('actorsData:', actorsData);

    if (actorsData.length > 1) {
      actorsData.sort(sortByLabel);
      actorsData.sort(sortByAdmin);
    }
    console.log('sorted actorsData:', actorsData);

    if (searchValue) {
      actorsData = actorsData
        .filter((el) => el?.label.toLowerCase().includes(searchValue));
    }

    return actorsData;
  }, [JSON.stringify(projectActors), searchValue]);

  const disableAddActor = (uuid) => {
    const isDisable = selectedActors.indexOf(uuid) !== -1;
    return isDisable;
  };

  const handleAddActor = (uuid) => {
    if (uuid) setSelectedActors?.(prev => [...prev, uuid]);
  }

  return (
    <ListContainer>
      <ListHeaderWithSearch
        title="Project users"
        size="px-3 py-2"
        usersCount={actorsFinalList?.length}
        searchMode={searchMode}
        setSearchMode={setSearchMode}
        setSearchValue={setSearchValue}
        searchFunction={setSearchValue}
      />
      <List
        style={{ overflowY: 'auto' }}
        dataSource={actorsFinalList}
        rowKey="uuid"
        size="small"
        split={true}
        // bordered
        renderItem={(actor, index) => (
          <List.Item
            key={actor?.uuid}
            actions={[
              <UI54Button
                key='addUser'
                style={{ width: '50px' }}
                type="primary"
                size='middle'
                variant="outlined"
                disabled={disableAddActor(actor?.uuid)}
                icon={<UI54Icon path={mdiAccountPlus} size={1} />}
                onClick={() => handleAddActor(actor?.uuid)}
              />,
            ]}
          // extra={item?.isAdmin ? "admin" : ""}
          >
            <List.Item.Meta
              title={actor?.label}
              description={
                <Flex gap={4}>
                  {actor?.isAdmin && (
                    <Tag bordered={true} color="blue">
                      Admin
                    </Tag>
                  )}
                  {/* <span>{item?.role}</span> */}
                  {actor?.role && <Tag bordered={true}>{actor?.role}</Tag>}
                </Flex>
              }
            />
          </List.Item>
        )}
      />
    </ListContainer>
  );
}

export default ProjectUsersList;

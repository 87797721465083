import React from 'react';

export const ProjectMainLayerContext = React.createContext();

export const BugTrackerContext = React.createContext();

export const MainCollapseSectionContext = React.createContext();

export const PermissionsContext = React.createContext();

export const ReportsQaContext = React.createContext();

export const FiltersContext = React.createContext();

import React, {
  useEffect,
  useState,
} from 'react';
import { string } from 'prop-types';
import { Row } from 'antd';
import UniGeneralButtons from '../uniGeneralButtons/UniGeneralButtons';
import { useSelector } from 'react-redux';
import { isAdminOrRoot } from '../../../entity/selectors/selectors';
import useGetUserPerm from '../../../permissions/hooks/useGetUserPerm';
import useURLParams from '../../../hooks/useURLParams';

import MyBoardNew from './MyBoardNew'
import AdminBoard from './AdminBoard';
import ComputePlanSettings from './ComputePlanSettings';
import BaseCard from '../../../components/_ui/BaseCard/BaseCard';
import SwitcherOfDate from '../../../userFlow/components/WeekPlanning/SwitcherOfDate';
import dayjs from 'dayjs';
import { getMyInfo, getProject } from '../../selectors/selectors';
import useWeekPlanning from '../project/generalPlanboard/useWeekPlanning';
import { get } from 'lodash';

function MyBoardRoot({
  partition,
}) {
  const {
    getURLParams,
  } = useURLParams();

  const today = dayjs().format("YYYY-MM-DD")

  const { activeProject } = getURLParams();
  const selectedProject = useSelector(getProject);

  const projectTitle = get(selectedProject[0], ['params', 'title'], '')
  const projectUsers = get(selectedProject[0], ['params', 'usersSearch'], [])
  const projectUUID = get(selectedProject[0], ['uuid'], '')

  const adminOrRoot = useSelector(isAdminOrRoot);

  const myInfo = useSelector(getMyInfo)

  const [selectedUser, setSelectedUser] = useState(null)

  const [tab, setTab] = useState('board');
  const [day, setDay] = useState(today)

  const { perm } = useGetUserPerm({
    entityUUID: activeProject,
    partitionType: partition,
  });

  const {
    todayVector,
    relatedGeneralIssues,
    updateVectorRelatedGeneralIssues,
    getNexDayVector
  } = useWeekPlanning({customActor: selectedUser, today, day})

  const filteredRelatedIssues = relatedGeneralIssues.filter(el => el?.params?.project === projectUUID)

  const checkTab = (type) => type === tab;

  const tabs = [
    { name: 'board', title: 'My plans' },
    {
      name: 'admin_board', title: 'Admin view board', hidden: !(adminOrRoot || (
        perm.set
        && perm.read
        && perm.list
        && perm.create
        && perm.delete
        && perm.update
      ))
    },
    // { name: "settings", title: "Settings" }
  ].filter(el => !el?.hidden);

  useEffect(() => {
    setSelectedUser(myInfo.uuid)
  }, [myInfo])

  useEffect(() => {
    if(tab === "board") {
      setSelectedUser(myInfo.uuid)
    }
  }, [tab])

  return (
    <>
    <div className='mb-3'>
    <SwitcherOfDate
    typePeriod="day"
    defaultDate={day}
    changePeriodCallback={setDay}
    />
    </div>
      {adminOrRoot || perm.set ? <Row span={24} className="mb-3">
        <UniGeneralButtons
          viewMode={tab}
          switchViewFunc={setTab}
          tabs={tabs}
        />
      </Row> : null}

      {checkTab('board') && (
        <MyBoardNew
        partition={partition}
        currentDay={day}
        projectTitle={projectTitle}
        projectUsers={projectUsers}
        todayVector={todayVector}
        customActor={selectedUser}
        updateVectorRelatedGeneralIssues={updateVectorRelatedGeneralIssues}
        relatedGeneralIssues={filteredRelatedIssues}
        getNexDayVector={getNexDayVector}
        />
      )}

      {checkTab('admin_board') && (adminOrRoot || perm.set) && (
        <AdminBoard
        partition={partition}
        currentDay={day}
        projectTitle={projectTitle}
        projectUsers={projectUsers}
        todayVector={todayVector}
        customActor={selectedUser}
        setSelectedUser={setSelectedUser}
        updateVectorRelatedGeneralIssues={updateVectorRelatedGeneralIssues}
        relatedGeneralIssues={filteredRelatedIssues}
        getNexDayVector={getNexDayVector}
        />
      )}

      {/* {checkTab('settings') && (
        <BaseCard
          className={"mb-2 px-4 py-2 myboard-plan-card-issues-view"}
        >
          <ComputePlanSettings />
        </BaseCard>
      )} */}
    </>
  );
}

MyBoardRoot.propTypes = {
  partition: string,
};

export default MyBoardRoot;

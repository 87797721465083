import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  globalUpdateEntity: {
    entityType: '',
    action: '',
  },
};

export const breadcrumbsSlicer = createSlice({
  name: 'breadcrumbsSlicer',
  initialState,
  reducers: {
    setGlobalUpdateEntity: (state, action) => {
      state.globalUpdateEntity = action.payload;
    },
    clearGlobalUpdateEntity: (state) => {
      state.globalUpdateEntity = initialState.globalUpdateEntity;
    },
  },
});

export const { setGlobalUpdateEntity, clearGlobalUpdateEntity } = breadcrumbsSlicer.actions;

export default breadcrumbsSlicer.reducer;

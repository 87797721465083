import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import {
  bool, element, func, string,
} from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from 'antd';
import BaseDropdown from '../../../components/_ui/BaseDropdown/BaseDropdown';

import './DropDownParamsEntity.scss';

// import {
//   docsEntityTypesDropdown,
//   docsEntityTypesOptions,
// } from '../../projectFlow/components/docView&ideas/constants/docsEntityTypes';
// import {
//   docsStatusesDropdown,
//   docsStatusesOptions,
// } from '../../projectFlow/components/docView&ideas/constants/docsStatuses';
import {
  estimatedTime,
  priorityIssueOptionsDropdown,
  priorityOptionsDropdown,
  prioritySelectedOptions,
  trackerOptionsDropdown,
  trackerSelectedOptions,
} from '../../../projectFlow/components/issues/constants/optionsIssue';
import {
  projectStatusesOption,
  projectStatusSelectedOptions,
} from '../../../projectFlow/components/project/constants/ProjectStatusOptions';
import {
  releaseStatusOptionsDropdown,
  releaseStatusSelectedOptions,
  vectorStatusOptionsDropdown,
  vectorStatusSelectedOptions,
  // vectorSubTypeSprintOptionsDropdown, vectorSubTypeSprintSelectedOptions,
  // vectorSubTypeVersionOptionsDropdown, vectorSubTypeVersionSelectedOptions,
  vectorTypeOptionsDropdown,
  vectorTypeSelectedOptions,
} from '../../../projectFlow/components/vector/constants/optionsVector';
import {
  importanceOptionsDropdown,
  importanceSelectedOptions,
} from '../../../projectFlow/components/milestones/constants/optionsMilestone';
import { capitalize } from 'lodash';

const selectOptions = {
  // docs_type: docsEntityTypesOptions,
  // docs_status: docsStatusesOptions,
  tracker: trackerSelectedOptions,
  priority: prioritySelectedOptions,
  priorityIssue: prioritySelectedOptions,
  project_status: projectStatusSelectedOptions,
  vector_status: vectorStatusSelectedOptions,
  release_status: releaseStatusSelectedOptions,
  vector_type: vectorTypeSelectedOptions,
  milestone_importance: importanceSelectedOptions,
  estimated_time: {
    easy: 'estimated-blue',
    normal: 'estimated-orange',
    difficult: 'estimated-red',
    very_complicated: 'estimated-purple',
  },
  goal_status: {
    created: 'createdBlue',
    completed: 'activeGreen',
    failed: 'priorityRed',
    in_progress: 'createdBlue',
    backlog: 'blockedGrey',
  },
  adjustments: {
    overtime: 'createdBlue',
    i_feel_sick_going_home: 'protectedOrange',
    personal_needs: 'createdDarkBlue',
  },

  // sub_type_sprint: vectorSubTypeSprintSelectedOptions,
  // sub_type_version: vectorSubTypeVersionSelectedOptions,
};

const pathTranslate = {
  tracker: 'wms.tracker',
  priority: 'wms.priority',
  priorityIssue: 'wms.priority',
  project_status: 'wms.status',
  vector_status: 'wms.status',
  goal_status: 'wms.status',
  release_status: 'wms.status',
  vector_type: 'wms.vectors.type',
  // sub_type_sprint: 'wms.vectors.subtype',
  // sub_type_version: 'wms.vectors.subtype',
  docs_type: 'wms.labels',
  estimated_time: 'wms.adjective',
  milestone_importance: 'wms.importance',
};

const classNameRow = {
  docs_type: 'custom-dropdown-docs',
  docs_status: 'custom-dropdown-docs',
  tracker: 'custom-dropdown-tracker',
  priority: 'custom-dropdown-priority',
  priorityIssue: 'custom-dropdown-priority',
  project_status: 'custom-dropdown-status-project',
  vector_status: 'custom-dropdown-status',
  goal_status: 'custom-dropdown-status',
  release_status: 'custom-dropdown-status',
  vector_type: 'custom-dropdown-type',
  adjustments: 'custom-dropdown-adjustments',
  estimated_time: 'custom-dropdown-estimated',
  milestone_importance: 'custom-dropdown-importance',
  // sub_type_sprint: 'custom-dropdown-subtype',
  // sub_type_version: 'custom-dropdown-subtype',
};

export default function DropDownParamsEntity({
  currentSelect,
  disabled,
  type,
  placement,
  hideActiveItemInList,
  dropDownCallback,
  customComponentSelected,
  getSpecialViewItem,
  overlayClassName,
  destroyPopupOnHide,
  hideIcon,
}) {
  const { t } = useTranslation();

  const options = useMemo(() => ({
    // docs_type: docsEntityTypesDropdown,
    // docs_status: docsStatusesDropdown,
    tracker: trackerOptionsDropdown,
    priority: priorityOptionsDropdown,
    priorityIssue: priorityIssueOptionsDropdown,
    project_status: projectStatusesOption,
    vector_status: vectorStatusOptionsDropdown,
    vector_type: vectorTypeOptionsDropdown,
    release_status: releaseStatusOptionsDropdown,
    milestone_importance: importanceOptionsDropdown,
    goal_status: ['created', 'in progress', 'failed', 'completed', 'backlog'],
    adjustments: ['overtime', 'i feel sick going home', 'personal needs'],
    estimated_time: estimatedTime(t),
  }), [t]);

  const onChangeDropDown = (value) => {
    dropDownCallback({ [type]: value });
  };
  // console.log('currentSelect',currentSelect)
  return (
    <Flex>
      <BaseDropdown
        trigger={['hover']}
        arrow
        placement={placement || "bottom"}
        hideActiveItemInList={hideActiveItemInList}
        disabled={disabled}
        defaultCurrentItem={currentSelect}
        translationPath={pathTranslate[type]}
        classNameRowDropdown={classNameRow[type]}
        defaultListOfItems={options[type]}
        dropDownCallback={onChangeDropDown}
        getSpecialViewItem={getSpecialViewItem}
        overlayClassName={overlayClassName}
        destroyPopupOnHide={destroyPopupOnHide}
      >
        {customComponentSelected || (
          <div 
            id={classNameRow[type][0] + classNameRow[type].split('-').map(el => el[0].toUpperCase() + el.slice(1)).join('').slice(1) + "Button"}
            className={`${selectOptions[type][currentSelect?.toString()?.replaceAll(" ", "_")]} ${disabled && "cursor-not-allowed"} view-wrap-statuses-btn p-1`}>
            <span>
              {capitalize(t(`${pathTranslate[type]}.${currentSelect}`, `${currentSelect}`))}
            </span>
            {!hideIcon ? <Icon path={mdiChevronDown} size={0.7} className={`ml-2 ${disabled && "hidden"}`} /> : null}
          </div>
        )}
      </BaseDropdown>
    </Flex>
  );
}

DropDownParamsEntity.propTypes = {
  currentSelect: string,
  disabled: bool,
  type: string,
  dropDownCallback: func,
  hideActiveItemInList: bool,
  customComponentSelected: element,
  getSpecialViewItem: func,
  overlayClassName: string,
  destroyPopupOnHide: bool,
  hideIcon: bool,
  placement: string,
};

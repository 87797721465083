import {
    array, bool, func, object, string,
  } from 'prop-types';
  import React, { useState } from 'react';
  import { useTranslation } from 'react-i18next';
  
  import BtnModal from '../../BtnModalComponent';
  import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
  import NewRecurringIssueComponents from './NewRecurringIssueComponents';
  
  import { capitalize } from 'lodash';
  import useActorsW54ModalStyle from '../../../../actors/hooks/useActorsW54ModalStyle';
  import { Tooltip } from 'antd';
  
  export default function NewRecurringIssueModal({
    hasCreatePerm = true,
    closeModalCallback,
    openFromOuterComponent,
    blockPropagationModal,
    hideAddRecurringIssueBtn,
    hideView,
    disabledView,
    disableBtn,
    partitionType,
    defaultRecurringIssueConfig,
    customParentIssue,
    createRecurringIssueCallback,
    customTitleOpenModalBtn,
    mandatoryRecurringIssue = false
  }) {
    const { t } = useTranslation();
  
    const [isChanged, setIsChanged] = useState(false);
  
    const [isAssignOpen, setIsAssignOpen, styleNewIssueModal] = useActorsW54ModalStyle();
  
    const disabled = disableBtn || !hasCreatePerm
  
    const confirmDescription = capitalize(
      t('wms.modals.body.close_modal_new_issue', 'the entered data will be lost, are you sure?'),
    );
  
    const getTooltipTitle = () => {
      if (!hasCreatePerm) {
        return "You don't have 'create' permission!"
      }
      return null;
    }
  
    const openModalBtn = !hideAddRecurringIssueBtn && (
      <Tooltip
        color={"grey"}
        title={getTooltipTitle()}
      >
        <BaseButton
          id='addRecurringIssueButton'
          size="small"
          className={!disabled && "customAntOutPrimaryBtn"}
          type="primary"
          ghost
          disabled={disabled}
        >
          {customTitleOpenModalBtn || capitalize(t('wms.issues.buttons.add_recurring_issue'))}
        </BaseButton>
      </Tooltip>
    );
  
    return (
      <BtnModal
        width={800}
        openFromOuterComponent={openFromOuterComponent}
        blockPropagation={blockPropagationModal}
        confirmOnClose={false}
        confirmDescription={confirmDescription}
        centered
        style={styleNewIssueModal}
        footer={null}
        btnComponent={openModalBtn}
        handleCancelCallbackFunc={closeModalCallback}
      >
        <NewRecurringIssueComponents
          isAssignOpen={isAssignOpen}
          setIsAssignOpen={setIsAssignOpen}
          hideView={hideView}
          disabledView={disabledView}
          partitionType={partitionType}
          defaultRecurringIssueConfig={defaultRecurringIssueConfig}
          customParentIssue={customParentIssue}
          createRecurringIssueCallback={createRecurringIssueCallback}
          setIsChanged={setIsChanged}
          mandatoryRecurringIssue={mandatoryRecurringIssue}
        />
      </BtnModal>
    );
  }
  
  NewRecurringIssueModal.propTypes = {
    closeModalCallback: func,
    openFromOuterComponent: bool,
    blockPropagationModal: bool,
    hideAddRecurringIssueBtn: bool,
    hideView: array,
    partitionType: string,
    defaultRecurringIssueConfig: object,
    createRecurringIssueCallback: func,
    customParentIssue: string,
    disabledView: array,
    disableBtn: bool,
    hasCreatePerm: bool,
    customTitleOpenModalBtn: string,
    mandatoryRecurringIssue: bool
  };
  
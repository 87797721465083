import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkEntityPerm } from '../entity/actions/entityPermActions';

export default function useCheckPerm({ entityUUID, partitionType }) {
  const dispatch = useDispatch();

  const [allowed, setAllowed] = useState(false);

  const checkPerm = () => {
    const data = {
      entity_uuid: entityUUID,
      partition: partitionType,
      returnPermsList: true,
    };

    dispatch(checkEntityPerm(data))
      .then((res) => {
        setAllowed({ ...res, isAllAllow: Object.values(res).every((item) => item === true) });
      })
      .catch(() => {
        setAllowed(false);
      });
  };

  useEffect(() => {
    if (entityUUID) {
      checkPerm();
    }
  }, [entityUUID, partitionType]);

  return allowed;
}

import React from 'react';

import { Col, Row } from 'antd';

import InfoTip from '../../components/infoTip/InfoTip';
import GridOfCards from '../../components/GridOfCards/GridOfCards';

import { capitalizeAndTranslateMsg } from '../../MainUtils';
import { galleryItemList } from '../utils/dashboardItems';
import { mdiCalendarToday } from "@mdi/js";
import UserDashboardForAdmin from "./UserDashboardForAdmin";

function AdminDashboardRoot() {
  return (
    <div className="w-full">
      <Row className="mb-2">
        <Col>
          <InfoTip
            title={capitalizeAndTranslateMsg('wms.admin.dashboard.header', 'Admin dashboard')}
            text={capitalizeAndTranslateMsg(
              'wms.userflow.dashboard.help.hint',
              'User flow is the path taken by a prototypical user on a '
              + 'website or app to complete a task. The user flow takes them from their '
              + 'entry point through a set of steps towards a successful outcome and final '
              + 'action, such as purchasing a product.',
            )}
          />
        </Col>
      </Row>
      <GridOfCards
        grid={[
          {
            labelForRow: 'User management',
            galleryList: [
              {
                id: 4,
                link: 'dashboard',
                title: 'User dashboard',
                icon: mdiCalendarToday,
                component: () => <UserDashboardForAdmin />,
              },
            ],
          },
          {
            labelForRow: 'Special',
            galleryList: galleryItemList,
          },
        ]}
      />
    </div>
  );
}

export default AdminDashboardRoot;

import React, { memo } from "react";
import { Col, Layout, Result, Row } from "antd";
import { mdiCellphoneOff } from "@mdi/js";
import Icon from "@mdi/react";
import "./ChangeResolutionPage.scss"

import useLocalTranslateForComponent from "../../54origins/hooks/useLocalTranslateForComponent";
import BaseCard from "../_ui/BaseCard/BaseCard";

function ChangeResolutionPage() {
  const translateForChangeResolution = {
    desktopUsage: ['Please use a desktop version to access.', 'Пожалуйста, используйте десктопную версию для доступа.'],
    notAvailable: ['The mobile version is not available yet.', 'Мобильная версия пока недоступна.'],
    thanks: ['Thanks.', 'Спасибо.'],
    desktopScale:
      [
        'Reduce the scale of the page in your browser or use a monitor with a resolution higher than Full HD.',
        'Уменьшите масштаб страницы в браузере или используйте монитор с разрешением выше Full HD.',
      ],
  };

  const { checkLangAndGetTranslate } = useLocalTranslateForComponent(translateForChangeResolution);

  return (
    <Layout className="change-resolution-block block d-xl-none">
      <Row className="h-full flex items-stretch">
        <Col span={24} className="flex justify-center items-center">
          <BaseCard className="card">
            <Result
              icon={<Icon path={mdiCellphoneOff} size={3} color="#f5222d" />}
              title={checkLangAndGetTranslate('desktopUsage')}
              subTitle={(
                <>
                  {checkLangAndGetTranslate('desktopScale')}
                  <br />
                  {checkLangAndGetTranslate('thanks')}
                  <br />
                  {checkLangAndGetTranslate('notAvailable')}
                </>
              )}
            />
          </BaseCard>
        </Col>
      </Row>

    </Layout>
  );
}

export default memo(ChangeResolutionPage);

import { head } from 'lodash';
import { bool, func, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearDocument, clearHistoryOfDocument } from '../../../../../projectFlow/actions/projectFlowActions';

import DocViewWrapper from '../../../../../projectFlow/containers/docView&ideas/DocViewWrapper';

import { getProjDoc } from '../../../../../projectFlow/selectors/selectors';
import CustomCardComponent from '../../../../../projectFlow/components/commonComponents/CustomCardComponent';
import { partitionNamesConfig } from '../../../../../api/appConfig';
import { setQuillDocReady } from '../../../../../projectFlow/reducers/slicers/quillSlicer';
import ProjectMilestoneView from '../../../../../projectFlow/components/milestones/ProjectMilestoneView';
import useURLParams from '../../../../../hooks/useURLParams';
import { getStateID } from '../../../../../projectFlow/components/docView&ideas/helpers/statesAndPatches/idHelpers';
import { getDocumentStateRequest, getOneDocument, setDocumentContent } from '../../../../../projectFlow/actions/DocumentsActions';
import {
 prop, propOr,
} from 'ramda';
import { useQuill } from '../../../../../projectFlow/components/docView&ideas/QuillEditor/useQuill/useQuill';
import { clearDocCopy } from '../../../../../projectFlow/actions/PtcActionsForHook';

function DashboardDocOrIdeaView({
  showEditor,
  setShowEditor,
  activeDoc, 
  docType,
  defaultPartition
}) {
  const dispatch = useDispatch();

  const doc = useSelector(getProjDoc);

  const docHead = head(doc);

  // useEffect(() => () => {
  //   dispatch(clearDocument());
  // }, []);

  // add requset
  const { getURLParams} = useURLParams();
  // const { activeDoc } = getURLParams();
  const {parseOldDocumentStructure, parseCrdtDocumentStructure} = useQuill();

  const getDocument = (uuid, entityType) => dispatch(getOneDocument(uuid, entityType, defaultPartition));

  const getDocumentState = (uuid, stateID) => {
    const optionalDataConfig = {
      uuid,
      stateID,
      partition: defaultPartition,
    };

    if (stateID && stateID !== '0') {
      return dispatch(getDocumentStateRequest(optionalDataConfig));
    }

    return null;
  };

  const getDocumentWithOptionalData = async (uuid, type) => {
    try {
      const document = await getDocument(uuid, type);
      

      const hasOptionalData = propOr(false, 'optional_data', head(document));
    
      if (hasOptionalData) {
        const documentParams = propOr('', 'params', head(document));

        const currentActualState = prop('actualState', documentParams);
        const stateID = getStateID(`${currentActualState}`);

        const state = await getDocumentState(uuid, stateID);
 
        const checkIsOldStructure = !Array.isArray(state);
        if (checkIsOldStructure) {
          const { text, patches: statePatches } = state || {};
      
          const startHtmlString = parseOldDocumentStructure(text, statePatches, type);
         
          dispatch(setDocumentContent({startHtmlString, startStateVector: ''}));
        } else {
          const startStateVector = parseCrdtDocumentStructure(state);
       
          dispatch(setDocumentContent({startHtmlString: '', startStateVector}));
        }
      } else {
        const paramsText = document?.params?.text;
        const checkParamsText = paramsText?.length;
        if (checkParamsText) {
          const startHtmlString = parseOldDocumentStructure(paramsText, [], type);
          dispatch(setDocumentContent({startHtmlString, startStateVector: ''}));
        }
      }
      // dispatch(setDocumentContent(null));
      // return [null, null];
    } catch(e) {
      console.log('getDocumentWithOptionalData error', e)
    } finally {
      dispatch(setQuillDocReady(true));
    }
  };


  const getDocumentData = async (uuid, type) => {
    await getDocumentWithOptionalData(uuid, type);
  };

  const getClearDocument = async (uuid, type) => {
    const checkIsDoc = type === 'projectDocument' || type === 'projectIdea';
    if (checkIsDoc) {
      dispatch(setQuillDocReady(false));
    }
    setShowEditor(false);
    await getDocumentData(uuid, type).then(() => {
      setShowEditor(true);
      }).catch((e) => {       
        const checkError = e?.status === 404 && e?.message === 'There is no entity with such uuid';
        if (checkError) {
          console.log("check");
          
        }   
      }
    );    
  };

  useEffect(() => () => {
    dispatch(clearDocument);
    dispatch(clearDocCopy);
    dispatch(clearHistoryOfDocument);
  }, []);
  
  useEffect(() => {
      getClearDocument(activeDoc, docType)
  }, [activeDoc, docType])

  return (
    <CustomCardComponent
      id="document"
      type="document"
      params={{
        closeBtns: true,
        isDisabled: true,
      }}
    >
      <DocViewWrapper
        currentSelected={docHead}
        defaultPartition={partitionNamesConfig.partition1}
        showEditor={showEditor}
        setShowEditor={setShowEditor}
      />
    </CustomCardComponent>
  );
}

DashboardDocOrIdeaView.propTypes = {
  showEditor: bool,
  setShowEditor: func,
  activeDoc: string, 
  docType: string,
  defaultPartition: string
};

export default DashboardDocOrIdeaView;

import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { getUsersByUUID } from '../../../userFlow/store/action-creators/userActions';

// import { getUuidsfromParamsUsers } from '../../../MainUtils';
import { capitalize } from 'lodash';

import UserAvatar from '../../../components/UserAvatar/UserAvatar';
// import BaseButton from '../../../components/_ui/BaseButton';

function ListOfAssignedUsers({
  assignedUsers,
  stylesForUsersList,
}) {
  const dispatch = useDispatch();

  const [usersInfo, setUserInfo] = useState([]);

  // console.log('usersInfo', usersInfo);
  // console.log('assignedUsers', assignedUsers);

  const getProjectActorsRequest = async (users) => dispatch(getUsersByUUID({
    uuid: users || [],
    userType: 'project',
  }));

  const getUsersInfo = async () => {
    const usersInfo = await getProjectActorsRequest(assignedUsers);
    setUserInfo(usersInfo || []);
  };

  const getFullNameOrInitials = (user, action) => {
    const firstName = user?.uinfo?.first_name;
    const lastName = user?.uinfo?.last_name;
    const fullName = `${firstName} ${lastName}`;
    const initials = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();

    if (action === 'fullname') {
      return fullName;
    }
    if (action === 'initials') {
      return initials;
    }
  };

  const usersAvatars = useMemo(() => usersInfo.map((user, i) => (
    !isNil(user)
    && (
      <Tooltip key={i} title={user ? getFullNameOrInitials(user, 'fullname') : ''}>
        <div
          className="user-avatar small user-avatar_circle user-avatar_extra_sm user-avatar_default"
        >
          <UserAvatar
            customInitials={getFullNameOrInitials(user, 'initials')}
            className="avatar-my small"
            userUUID={user.uuid}
            size={22}
          />
        </div>
      </Tooltip>
    )
  )), [usersInfo]);

  useEffect(() => {
    if (assignedUsers.length > 0) {
      getUsersInfo();
    } else {
      setUserInfo([]);
    }
  }, [assignedUsers]);

  return (
    usersInfo.length > 0 && (
      <div className={`${stylesForUsersList || 'list-assigned-users inline-flex ml-2'}`}>
        {usersAvatars.length > 0 && usersAvatars}
      </div>
    )
  );
}

ListOfAssignedUsers.propTypes = {
  assignedUsers: PropTypes.array,
  stylesForUsersList: PropTypes.string,
};

export default ListOfAssignedUsers;

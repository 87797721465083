import { convertHHhmmmToMinutes } from '../../userFlow/tools/dailyTools/dailyTools';

const usersWithWeekendWork = (userList = [], reports = {}) => (userList.map((e) => {
  const { weekendHoursWorked = '' } = reports[e.actor];

  return { ...e, weekendHoursWorked: convertHHhmmmToMinutes(weekendHoursWorked) };
}));

const usersWithStartWork = (userList = [], reports = {}) => (userList.map((e) => {
  const { startWork = '' } = reports[e.actor] || {};
  return { ...e, startWork };
}));

const usersWithFinishWork = (userList = [], reports = {}) => (userList.map((e) => {
  const { finishWork = '' } = reports[e.actor] || {};

  return { ...e, finishWork };
}));

const sortUsersByShowingReports = (users, sortValue) => {
  const usersWithShowReportsTab = [];
  const usersWithoutShowReportsTab = [];

  users.forEach((e) => {
    if (e.options?.showReports ?? true) {
      usersWithShowReportsTab.push(e);
    } else {
      usersWithoutShowReportsTab.push(e);
    }
  });

  if (sortValue === 'ascend') {
    return usersWithShowReportsTab.concat(usersWithoutShowReportsTab);
  }
  return usersWithoutShowReportsTab.concat(usersWithShowReportsTab);
};

export {
  usersWithFinishWork,
  usersWithStartWork,
  sortUsersByShowingReports,
  usersWithWeekendWork,
};

import axios from 'axios';
import { isEmpty, prop } from 'ramda';

import { get } from 'lodash';
import {
  entityBackendURL,
  getPartitionUUID,
} from './api';
import { store } from '../AppComponent';
import { AuthConstants } from '../auth/Constants';
import {
  antNotification,
  getFromStore,
  prepareAndSaveMetaData,
} from '../MainUtils';
import { logoutAndClearAllCookies, parseErrorFromRequest54origins } from '../54origins/utils54origins';
import { getCookie } from '../54origins/cookies54origins';
import { getSessionTokenFor, sessionNameFor } from './appConfig';

const handleError = (error) => {
  const { status, message } = parseErrorFromRequest54origins(error);

  const locale = getFromStore(['locales', 'language']);

  console.log('message', message);
  console.log('status', status);

  switch (status) {
    case 400:
      antNotification('error', message || (locale === 'ru' ? 'Ошибка данных' : 'Data error'));
      break;
    case 401:
      antNotification(
        'error',
        message || (locale === 'ru' ? 'Пользователь не авторизован' : 'Unauthorized'),
      );
      logoutAndClearAllCookies(() => {
        setTimeout(() => {
          window.location.href = '/login';
        }, 500);
      });
      break;

    case 403:
      antNotification(
        'error',
        message || (locale === 'ru' ? 'Доступ запрещен' : 'Forbidden'),
      );
      break;

    case 430:
      // console.log('ERROR 430 STORE DISPATCH')
      store.dispatch({
        type: AuthConstants.ERROR_430,
        payload: message,
      });
      logoutAndClearAllCookies(() => {
        setTimeout(() => {
          window.location.href = '/login';
        }, 500);
      });
      break;

    default:
      break;
  }
};

export const requestOnBrowserClose = async (url, config) => fetch(`${entityBackendURL()}/${url}`, {
  method: 'POST',
  body: JSON.stringify(prop('data', config)),
  headers: {
    'Content-Type': 'application/json',
    'Session-Token': getSessionTokenFor.entity(),
    'Partition-Uuid': getPartitionUUID(prop('partition', config)),
    'Http-Accept-Language': localStorage.getItem('locale'),
  },
  keepalive: true,
});

class AxiosAPI {
  allSources = new Map([]);

  getSourceToken = (requestType) => {
    const source = this.allSources.has(requestType)
      ? this.allSources.get(requestType)
      : axios.CancelToken.source();

    this.allSources.set(requestType, axios.CancelToken.source());

    return source.token;
  };

  removeSource = (requestType) => {
    this.allSources.delete(requestType);
  };

  request = (endpoint, types = [], data, options = {}, method) => {
    const {
      doNotCheckError,
      header = {},
      onCancel,
      onFailure,
      onSuccess,
      partition,
      signal,
      tokenName,
      url,
    } = options;
    const [REQUEST, SUCCESS, FAILURE, CANCELED] = types;
    const headers = !isEmpty(header)
      ? header
      : {
        'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
        'Session-Token': getCookie(tokenName || sessionNameFor.entity),
        'Partition-Uuid': getPartitionUUID(partition),
        'Http-Accept-Language': localStorage.getItem('locale'),
      };

    const config = {
      url: `${url || entityBackendURL()}/${endpoint}`,
      method,
      data,
      headers,
      signal,
    };

    return (dispatch, getState) => {
      if (REQUEST) {
        dispatch({ type: REQUEST.type || REQUEST });
      }

      const state = getState();

      axios.request(config)
        .then((response) => {
          const { data: resData, status } = response || {};
          if (SUCCESS) {
            dispatch({
              type: SUCCESS.type || SUCCESS,
              payload: SUCCESS.payload
                ? SUCCESS.payload(resData, state, status)
                : resData,
              total: config.data?.total || null,
            });
          }

          if (onSuccess) {
            onSuccess(resData, state, status);
          }
        })
        .catch((error) => {
          const checkFailure = () => {
            if (onFailure) {
              onFailure(parseErrorFromRequest54origins(error));
            }

            if (FAILURE) {
              dispatch({
                type: FAILURE.type || FAILURE,
                payload: FAILURE.payload
                  ? FAILURE.payload(parseErrorFromRequest54origins(error), state)
                  : parseErrorFromRequest54origins(error),
              });
            }
          };

          if (!doNotCheckError) {
            handleError(error);
          }

          if (axios.isCancel) {
            // console.log('error', error);
            if (onCancel) {
              onCancel(parseErrorFromRequest54origins(error));
            }

            if (CANCELED) {
              dispatch({
                type: CANCELED.type || CANCELED,
              });
            }

            if (!onCancel && !CANCELED) {
              checkFailure();
            }
          } else {
            checkFailure();
          }
        });
    };
  };

  get = (endpoint, types, options) => this.request(endpoint, types, null, options, 'get');

  post = (endpoint, types, data, options) => this.request(endpoint, types, data, options, 'post');

  put = (endpoint, types, data, options) => this.request(endpoint, types, data, options, 'put');

  patch = (endpoint, types, data, options) => this.request(endpoint, types, data, options, 'patch');

  delete = (endpoint, types, options) => this.request(endpoint, types, null, options, 'delete');

  abort = (abortMessage = 'abort', abortType) => {
    const isString = typeof abortType === 'string';
    // console.log('allSources', this.allSources);

    // console.log('abortType abortType', abortType);
    const onAbort = (requestType) => {
      const source = this.allSources.has(requestType)
        ? this.allSources.get(requestType)
        : '';

      if (source) {
        source.cancel(`abort-${requestType}`);
      }
    };

    if (abortType && isString) {
      if (this.allSources.has(abortType)) {
        onAbort(abortType);
      }
    } else if (abortType && !isString) {
      abortType.forEach((key) => onAbort(key));
    } else {
      Object.keys(this.allSources)
        .forEach((key) => {
          onAbort(key);
        });
    }
  };
}

axios.interceptors.request.use((config) => {
  try {
    const { url, data } = config;
    const partition = get(config, 'headers.Partition-Uuid', '');
    prepareAndSaveMetaData(data, url, 'request', partition);
  } finally {
    return config;
  }
});

axios.interceptors.response.use((config) => {
  try {
    const { data, request } = config;
    const partition = get(config, 'config.headers.Partition-Uuid', '');
    prepareAndSaveMetaData(data, request.responseURL, 'response', partition);
  } finally {
    return config;
  }
});

const axiosRequest = new AxiosAPI();

export default axiosRequest;

export const axiosRequestPost = axiosRequest.post;
export const axiosRequestGet = axiosRequest.get;
export const axiosRequestPut = axiosRequest.put;
export const axiosRequestAbort = axiosRequest.abort;

import { isEmpty, prop } from 'ramda';
import { axiosRequestPost } from '../api/apiAxios';
import { partitionNamesConfig } from '../api/appConfig';

export function getListOfAffix({
  data,
  partition = partitionNamesConfig.partition1,
  constants,
  doNotCheckError = false,
  aborting = false,
  cancelToken,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      const onSuccessData = (res = []) => onSuccess(
        {
          affix_type: prop('affix_type', data),
          affix_uuids: prop('affix_uuids', res),
          total: prop('total', res),
        },
      );

      dispatch(
        axiosRequestPost(
          'entity/affix/list',
          constants,
          data,
          {
            partition,
            onSuccess: onSuccessData,
            onFailure,
            doNotCheckError,
            aborting,
            cancelToken,
          },
        ),
      );
    })
  );
}

export function getListAndReadAffix({
  data,
  partition,
  constants = ['REQUEST_AFFIX', 'SUCCESS_AFFIX', 'FAILURE_AFFIX'],
  doNotCheckError = false,
  aborting = false,
  cancelToken,
  requestOptions,
}) {
  const configList = {
    data,
    partition,
    constants: [constants[0], '', ''],
  };

  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(getListOfAffix(configList))
        .then((listParams) => {
          if (isEmpty(listParams.affix_uuids)) {
            if (typeof constants[1] === 'function') {
              dispatch({ type: constants[1]?.type, payload: [] });
            } else {
              dispatch({ type: constants[1], payload: [] });
            }
            onSuccess({ data: [], total: 0 });
            return;
          }
          // console.log('listParams', listParams);
          const onSuccessRead = (res) => onSuccess({ data: res, total: prop('total', listParams) });

          const newParams = { ...listParams };

          if ('with_files' in data) {
            newParams.with_files = data.with_files;
          }

          dispatch(
            axiosRequestPost(
              'entity/affix/read',
              ['', constants[1], constants[2]],
              newParams,
              requestOptions || {
                partition,
                onSuccess: onSuccessRead,
                onFailure,
                doNotCheckError,
                aborting,
                cancelToken,
              },
            ),
          );
        })
        .catch((error) => {
          onFailure(error);
        });
    })
  );
}

export function getListAndPartialReadAffix({
  data,
  partition,
  constants = ['REQUEST_AFFIX', 'SUCCESS_AFFIX', 'FAILURE_AFFIX'],
  doNotCheckError = false,
  aborting = false,
  cancelToken,
  requestOptions,
}) {
  const configList = {
    data,
    partition,
    constants: [constants[0], '', ''],
  };

  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(getListOfAffix(configList))
        .then((listParams) => {
          if (isEmpty(listParams.affix_uuids)) {
            if (typeof constants[1] === 'function') {
              dispatch({ type: constants[1]?.type, payload: [] });
            } else {
              dispatch({ type: constants[1], payload: [] });
            }
            onSuccess({ data: [], total: 0 });
            return;
          }
          // console.log('listParams',listParams)
          const onSuccessRead = (res) => onSuccess({ data: res, total: prop('total', listParams) });

          const newParams = {
            ...listParams,
            fields: data.fields,
            params_fields: data.params_fields,
          };

          if ('with_files' in data) {
            newParams.with_files = data.with_files;
          }

          dispatch(
            axiosRequestPost(
              'entity/affix/read/partial',
              ['', constants[1], constants[2]],
              newParams,
              requestOptions || {
                partition,
                onSuccess: onSuccessRead,
                onFailure,
                doNotCheckError,
                aborting,
                cancelToken,
              },
            ),
          );
        })
        .catch((error) => {
          onFailure(error);
        });
    })
  );
}

export function getAffixRead({
  data,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  partition = partitionNamesConfig.partition1,
  signal,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/affix/read',
          constants,
          data,
          {
            partition,
            onSuccess,
            onFailure,
            signal,
          },
        ),
      );
    })
  );
}

export function getAffixPartialRead({
  data,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  partition = partitionNamesConfig.partition1,
  signal,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/affix/read/partial',
          constants,
          data,
          {
            partition,
            onSuccess,
            onFailure,
            signal,
          },
        ),
      );
    })
  );
}

export function affixDelete({
  data,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  partition = partitionNamesConfig.partition1,
  signal,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/affix/delete',
          constants,
          data,
          {
            partition,
            onSuccess,
            onFailure,
            signal,
          },
        ),
      );
    })
  );
}

export function affixCreate({
  data,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  partition = partitionNamesConfig.partition1,
  signal,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/affix/create',
          constants,
          data,
          {
            partition,
            onSuccess,
            onFailure,
            signal,
          },
        ),
      );
    })
  );
}

export function affixUpdate({
  data,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  partition =  partitionNamesConfig.partition1,
  signal,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequestPost(
          'entity/affix/update',
          constants,
          data,
          {
            partition,
            onSuccess,
            onFailure,
            signal,
          },
        ),
      );
    })
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const initialState = {
  activeFilter: null,
};

const dashboardGeneralFilerSlice = createSlice({
  name: 'DASHBOARD_GENERAL_FILTER',
  initialState,
  reducers: {
    setActiveFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    clearActiveFilter: (state) => {
      state.activeFilter = null;
    },
  },
});

export const getDashboardGeneralActiveFilter = (state) => get(state, [''], null);

export const useDashboardGeneralFilterActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(dashboardGeneralFilerSlice.actions, dispatch);
};

export default dashboardGeneralFilerSlice.reducer;

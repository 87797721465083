import { getOrCreateSingle } from '../../entity/actions/entityActions';
import {
  GET_MAIN_PROJECT_ENTITY_FAILURE,
  GET_MAIN_PROJECT_ENTITY_REQUEST,
  GET_MAIN_PROJECT_ENTITY_SUCCESS,
} from '../reducers/slicers/projectMainEntitySlicer';

export function getMainProjectEntityRequest({
  parent,
  partition,
}) {
  const data = {
    entity_type: 'mainProjectEntity',
    parent,
    params: {},
  };

  const constants = [
    GET_MAIN_PROJECT_ENTITY_REQUEST,
    GET_MAIN_PROJECT_ENTITY_SUCCESS,
    GET_MAIN_PROJECT_ENTITY_FAILURE,
  ];

  return getOrCreateSingle({
    data,
    constants,
    partition,
  });
}

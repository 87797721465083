import PublicPartitionConstants from '../../publicPartition/constants/publicPartitionConstants';

const initialState = {
  data: [],
  isLoading: false,
  isError: '',
};

const userDaylogForDayReportReducer = (state = initialState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case PublicPartitionConstants.GET_USER_DAY_LOG_FOR_DAY_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PublicPartitionConstants.GET_USER_DAY_LOG_FOR_DAY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        isError: '',
      };
    case PublicPartitionConstants.GET_USER_DAY_LOG_FOR_DAY_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: [],
        isError: payload,
      };

    case PublicPartitionConstants.CLEAR_USER_DAY_LOG_FOR_DAY_REPORT:
      return {
        ...state,
        data: [],
        isError: '',
        isLoading: false,
      };

    default: return state;
  }
};

export default userDaylogForDayReportReducer;

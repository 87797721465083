import { createSlice } from '@reduxjs/toolkit';

import { head } from 'lodash';

const initialState = {
  fetching: false,
  fetchingUpdate: false,
  userBoard: {},
};

const userBoardSlice = createSlice({
  name: 'userBoardSlice',
  initialState,
  reducers: {
    CLEAR_USER_BOARD(state) {
      state.fetching = true;
      state.userBoard = {};
    },
    GET_OR_CREATE_USER_BOARD_REQUEST(state) {
      state.fetching = true;
    },
    GET_OR_CREATE_USER_BOARD_SUCCESS(state, action) {
      state.fetching = false;
      state.userBoard = action.payload;
    },
    GET_OR_CREATE_USER_BOARD_FAILURE(state) {
      state.fetching = false;
    },
    UPDATE_USER_BOARD_REQUEST(state) {
      state.fetchingUpdate = true;
    },
    UPDATE_USER_BOARD_SUCCESS(state, action) {
      state.fetchingUpdate = false;
      state.userBoard = head(action.payload);
    },
    UPDATE_USER_BOARD_FAILURE(state) {
      state.fetchingUpdate = false;
    },
  },
});

export const {
  CLEAR_USER_BOARD,
  GET_OR_CREATE_USER_BOARD_REQUEST,
  GET_OR_CREATE_USER_BOARD_SUCCESS,
  GET_OR_CREATE_USER_BOARD_FAILURE,
  UPDATE_USER_BOARD_REQUEST,
  UPDATE_USER_BOARD_SUCCESS,
  UPDATE_USER_BOARD_FAILURE,
} = userBoardSlice.actions;

export default userBoardSlice.reducer;

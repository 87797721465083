import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allTimeLogs: [],
  timelogUniqProjects: [],
  projectUUIDAndBillableHoursCollection: {},
};

const projectReportPerUser = createSlice({
  name: 'projectReportPerUser',
  initialState,
  reducers: {
    setAllTimelogsAction: (state, action) => {
      state.allTimeLogs = action.payload;
    },
    setProjectReportCollectionAction: (state, action) => {
      state.projectUUIDAndBillableHoursCollection = action.payload;
    },
    setTimelogUniqProjectsAction: (state, action) => {
      state.timelogUniqProjects = action.payload;
    },
    clearAllTimelogsAction: (state) => {
      state.allTimeLogs = [];
    },
    clearProjectReportCollectionAction: (state) => {
      state.projectUUIDAndBillableHoursCollection = {};
    },
    clearTimelogUniqProjectsAction: (state) => {
      state.timelogUniqProjectsUUIDs = [];
      state.timelogUniqProjects = [];
      state.projectUUIDAndBillableHoursCollection = {};
      state.allTimeLogs = [];
    },
    clearProjectReportPerUserAction: (state) => {
      state.allTimeLogs = [];
      state.projectUUIDAndBillableHoursCollection = {};
    },
  },
});

export const {
  setAllTimelogsAction,
  setProjectReportCollectionAction,
  setTimelogUniqProjectsAction,
  clearAllTimelogsAction,
  clearProjectReportCollectionAction,
  clearTimelogUniqProjectsAction,
  clearProjectReportPerUserAction,
} = projectReportPerUser.actions;

export default projectReportPerUser.reducer;

import { get } from 'lodash';
import {head, path, propOr} from 'ramda';

export const getMainArchiveState = state => path(['projectManagementService', 'project', 'mainArchiveState', 'data'], state);

export const getCurrentArchiveState = state => path(['projectManagementService', 'project', 'archiveState', 'data'], state);

export const getChildFromCurrentArchiveState = state => path(['projectManagementService', 'project', 'archiveState', 'entitiesFromArchiveState'], state);

export const getListOfArchiveState = state => path(['projectManagementService', 'project', 'listOfArchiveState', 'data'], state);

export const getMainArchiveStateFetching = state => path(['projectManagementService', 'project', 'mainArchiveState', 'fetching'], state);

export const getMainArchiveUUID = state => get(getMainArchiveState(state), 'uuid', '');

export const getCurrentArchiveStateUUID = state => propOr('', 'uuid', head(getCurrentArchiveState(state)));

import PropTypes from 'prop-types';
import React from 'react';
import { capitalize } from 'lodash';
import { Flex, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import AssignedVector from '../commonComponents/assignedVector/AssignedVector';

function ListOfVectorsDropDownForIssueInfo({
  partitionType,
  projectUsers,
  vectorUuid,
  projectUuid,
  ticketUuid,
  vectorTitle = 'empty',
  disabled,
  onChangeFieldCallback,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Flex
        className="wrapper-title"
        align="center"
      >
        <span className="mr-2">
          {`${capitalize(t('wms.noun.vector', 'vector').trim())}:`}
        </span>
        <AssignedVector
          partitionType={partitionType}
          projectUsers={projectUsers}
          vectorUuid={vectorUuid}
          projectUuid={projectUuid}
          ticketUuid={ticketUuid}
          disabled={disabled}
          onChangeFieldCallback={onChangeFieldCallback}
        />
      </Flex>
      <Tooltip
        title={vectorTitle}
        placement="bottom"
      >
        <div className="text-secondary">
          {vectorTitle?.length > 20 ? `${vectorTitle.slice(0, 20)}...` : vectorTitle}
        </div>
      </Tooltip>
    </>
  );
}

export default ListOfVectorsDropDownForIssueInfo;

ListOfVectorsDropDownForIssueInfo.propTypes = {
  disabled: PropTypes.bool,
  onChangeFieldCallback: PropTypes.func,
  partitionType: PropTypes.string,
  projectUsers: PropTypes.any,
  projectUuid: PropTypes.string,
  ticketUuid: PropTypes.string,
  vectorTitle: PropTypes.string,
  vectorUuid: PropTypes.string,
};

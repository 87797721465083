import React, { useState, useCallback } from 'react';

function useActivitiesDashboard() {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState('');

  const closeSidePanel = useCallback(() => setIsSidePanelOpen(false), []);

  const toggleSidePanelVisibility = useCallback(() => setIsSidePanelOpen((prev) => !prev), []);

  const clickActivitiesHandler = useCallback(() => {
    if (!isSidePanelOpen) {
      setSelectedActivity('');
      setIsSidePanelOpen(true);
    } else {
      setIsSidePanelOpen(false);
    }
  }, [isSidePanelOpen]);

  const clickDashboardButtonHandler = useCallback((activityView) => {
    if (!isSidePanelOpen) {
      setSelectedActivity(activityView);
      setIsSidePanelOpen(true);
    } else if (selectedActivity === activityView) {
      setIsSidePanelOpen(false);
    } else {
      setSelectedActivity(activityView);
    }
  }, [isSidePanelOpen, selectedActivity]);

  return {
    isSidePanelOpen,
    setIsSidePanelOpen,
    selectedActivity,
    setSelectedActivity,
    clickActivitiesHandler,
    clickDashboardButtonHandler,
    closeSidePanel,
    toggleSidePanelVisibility,
  };
}

export default useActivitiesDashboard;

import { mdiStar, mdiStarOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Alert, Badge, Tooltip } from 'antd';
import {
  get, isNil,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import { getListAndPartialReadEntities } from '../../../../entity/actions/entityActions';
import { getFavoriteUUID } from '../../../../userFlow/store/selectors/selectors';
import { capitalize } from 'lodash';

export default function UpdateListFavoriteSuiteModal({
  dataFavorite,
  handleCancel,
  partition,
  projectUUID,
  updateFavorite,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const favoriteSettingsUUID = useSelector(getFavoriteUUID);

  const [listTestSuite, setListTestSuite] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pageLimit, setPageLimit] = useState(10);

  const getListTestSuite = (entities) => {
    const config = {
      data: {
        entity_type: 'testSuite',
        params: {},
        entities,
        parent: projectUUID,
        params_fields: {
          id: 'id',
          title: 'title',
          cases_count: 'cases_count',
          favorite: 'favorite',
        },
      },
      partition,
      constants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    };

    return dispatch(getListAndPartialReadEntities(config));
  };

  const columns = [
    {
      dataIndex: ['id'],
      title: capitalize(t('wms.table.headers.key', 'key')),
      render: (cell, row) => (
        <Tooltip
          title=""
          className="whitespace-nowrap text-xs"
        >
          {!isNil(cell) ? cell : 'key'}
        </Tooltip>
      ),
    },
    {
      dataIndex: ['title'],
      key: 'title',
      title: capitalize(t('wms.label.title', 'title')),
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
          maxLength={80}
        />
      ),
    },
    {
      dataIndex: ['cases_count'],
      key: 'cases_count',
      sorter: true,
      title: capitalize(t('wms.table.headers.cases', 'Cases')),
      render: (cell) => (
        <Badge
          count={cell}
          showZero
          style={{ backgroundColor: '#fa8c16' }}
        />
      ),
    },
    {
      dataIndex: 'favorite',
      title: capitalize(t('wms.table.headers.favorites', 'Favorites')),
      render: (cell, row) => (
        <BaseButton
          className="add-to-favorite-btn active"
          size="small"
        >
          <Icon path={selectedKeys.includes(row.uuid) ? mdiStar : mdiStarOutline} size={1} />
        </BaseButton>
      ),
    },
  ];

  const toggleSubmit = () => {
    const favoriteParams = {
      entity_type: 'favoriteSettings',
      entity_uuid: favoriteSettingsUUID,
      params: {
        ...get(dataFavorite, '[0]'),
        // testSuites: []
        testSuites: get(dataFavorite, '[0].testSuites', []).map((item) => {
          if (item?.uuid === projectUUID) {
            return ({
              ...item,
              suites: selectedKeys,
            });
          }
        }),
      },
    };
    updateFavorite(favoriteParams);
    handleCancel();
  };

  const initFunc = async () => {
    setLoading(true);

    const listFav = get(dataFavorite, '[0].testSuites', []).filter((item) => item?.uuid === projectUUID);

    const currentList = get(listFav, '[0].suites', []);

    const res = await getListTestSuite(currentList || []);
    // console.log(get(res, 'data', []))
    const list = get(res, 'data', []);

    if (get(res, 'data') && get(res, 'data', []).length !== currentList.length) {
      currentList.forEach((i) => {
        if (get(res, 'data', []).every((p) => p.uuid !== i)) {
          list.push({ uuid: i, params: { title: 'Test suite has been removed', status: 'created' } });
        }
      });
    }

    setListTestSuite(list);
    setSelectedKeys(currentList);
    setLoading(false);
  };

  const multipleSelectRow = {
    // clickToSelect: !disableOnClick,
    hideSelectColumn: true,
    // mode: 'checkbox',
    selectedRowKeys: selectedKeys,
    // style: { background: customBackgroundRowTable || '#e5f4fc' },
  };

  const rowClassName = (row) => {
    const data = `universal_table_row ${selectedKeys.some((i) => i === get(row, 'uuid'))
      ? 'selected'
      : ''
      }`;

    return data;
  };

  const onRow = (record) => ({
    onClick: () => {
      if (!isChanged) {
        setIsChanged(true);
      }

      setSelectedKeys((prev) => {
        if (prev.find((p) => p === record?.uuid)) {
          return prev.filter((i) => i !== record?.uuid);
        }
        return [...prev, record.uuid];
      });
    },
  });

  useEffect(() => {
    initFunc();
  }, []);
  return (
    <div className="flex flex-col">
      <Alert
        showIcon
        message="Favorite test suite limit exceeded, delete previously added"
        type="error"
        description="Max limit 15 favorites"
        className="mb-2"
      />
      <div>
        <BaseTableWithPagination
          pageLimit={pageLimit}
          changePageLimit={setPageLimit}
          loading={loading}
          columns={columns}
          data={listTestSuite}
          onRow={onRow}
          rowClassName={rowClassName}
          rowSelection={multipleSelectRow}
        />
      </div>
      <div className="flex justify-end">
        <BaseButton
          className="customAntOutPrimaryBtn ml-1 mr-1"
          disabled={!isChanged}
          onClick={toggleSubmit}
        >
          {capitalize(t('wms.buttons.submit'))}
        </BaseButton>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Tag, Tooltip } from 'antd';

import {
  dateDiff, dateFormat2, dateToFormat1,
} from '../54origins/dateFormats54origins';
import BaseTag from "./_ui/BaseTag/BaseTag";

export default function DateLeft({
  type,
  date,
  minus = false,
  customStyle = 'ml-1',
}) {
  const { t } = useTranslation();

  const [currentDate, setCurrentDate] = useState('');

  const current = dateDiff(dateFormat2(), 'days', currentDate);

  const dayLeft = () => {
    if (type === 'tag') {
      return (
        <Tooltip title={t('wms.labels.lifetime.days_left', 'days_left')}>
          <BaseTag className="tag_day_left">{current}</BaseTag>
        </Tooltip>
      );
    }
    return `${current} ${t('wms.labels.lifetime.days_left', 'days_left')}`;
  };

  const dayOverdue = () => {
    const label = minus
      ? current
      : current?.toString()?.replace('-', '');
    if (type === 'tag') {
      return (
        <Tooltip title={t('wms.labels.lifetime.days_overdue', 'days_overdue')}>
          <BaseTag className="tag_day_overdue">{label || 0}</BaseTag>
        </Tooltip>
      );
    }
    return `${label} ${t('wms.labels.lifetime.days_overdue', 'days_overdue')}`;
  };

  useEffect(() => {
    if (date) setCurrentDate(date);
  }, [date]);

  return (
    <div className="flex">
      <Tooltip placement="right" title={dateToFormat1(currentDate)}>
        <div className={customStyle}>
          {current >= 0 ? dayLeft() : dayOverdue()}
        </div>
      </Tooltip>
    </div>
  );
}
DateLeft.propTypes = {
  date: string,
  customStyle: string,
  type: string,
  minus: bool,
};

import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getListOfIssues, updateProjectScoring } from '../../projectFlow/actions/projectFlowActions';
import projectEntityOutdatedScoring, { notActiveStatusesArrOutdated } from '../../projectFlow/components/issuesView/utilsForOutdatedScoring';
import { ProjectTypeCommonConstants } from '../../projectFlow/constants/Constants';
import { setOutdatedProjectsArr } from '../../projectFlow/reducers/slicers/projectAllScoringSlicer';

export default function useOutdatedScore() {
  const dispatch = useDispatch();

  const outdatedTitle = 'Outdated';

  const updateProject = async (props) => {
    const {
      projectUuid, projectPartition, outdatedScore,
    } = props;
    const data = {
      uuid: projectUuid,
      params: {
        outdatedDevScore: {
          updated: moment(),
          outdatedScore,
        },
      },
      partition: projectPartition,
    };
    return dispatch(updateProjectScoring(data));
  };

  const getAllProjectsIssues = async (projectUuid, projectPartition) => {
    const config = {
      partition: projectPartition,
      parent: projectUuid,
      depth: 2,
      params: {
        status__not: notActiveStatusesArrOutdated,
      },
      constants: [
        ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_REQUEST,
        ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_SUCCESS,
        ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_FAILURE,
      ],
      fields: {
        modified: 'modified',
      },
      params_fields: {
        title: 'title',
        priority: 'priority',
        status: 'status',
      },
    };
    return dispatch(getListOfIssues(config));
  };

  const computeProjectOutdatedScore = async (
    projectUuid,
    projectPartition,
    isNeedProject,
    isAllScoring,
  ) => getAllProjectsIssues(projectUuid, projectPartition)
    .then(async (resp) => {
      const { data, total } = resp;
      // console.log('getAllProjectsIssues data', data);
      // console.log('handleDevScoreIssues', data);
      const outdatedScore = projectEntityOutdatedScoring(data);
      const finalScore = total ? outdatedScore : 0;
      // console.log('getAllProjectsIssues outdatedScore', outdatedScore);

      if (!isAllScoring) {
        const res = await updateProject({
          outdatedScore: finalScore,
          projectUuid,
          projectPartition,
        });
        if (isNeedProject) {
          return res;
        }
      }

      return {
        uuid: projectUuid,
        outdatedScore: finalScore,
        updated: moment(),
      };
    });

  const computeAllProjectsOutdatedScore = async (projectsArr, isAllScoring) => {
    try {
      const projectsOutdatedData = await Promise.all(projectsArr.map(async (project) => {
        const { uuid, partition } = project;
        const data = await computeProjectOutdatedScore(
          uuid,
          partition,
          false,
          isAllScoring,
        );
        return data;
      }));
      // console.log('projectsOutdatedData ', projectsOutdatedData);
      dispatch(setOutdatedProjectsArr(projectsOutdatedData));
      const finalProjectsData = projectsArr.map((item) => {
        const projectScoring = projectsOutdatedData.find((project) => project?.uuid === item?.uuid);
        // console.log('onComputeOutdated projectScoring', finalResp);

        return {
          ...item,
          outdatedDevScore: projectScoring,
        };
      });
      return finalProjectsData;
    } catch (e) {
    } finally {
      // dispatch(setFetchingScoringData(false));
    }
    // console.log('onComputeKPI allProjectsCopy', allProjectsCopy);
  };

  const handleOutdatedBackground = (score, isButtonColor) => {
    if (score >= 100) {
      return !isButtonColor ? 'error' : 'scoringBtnDanger';
    }
    if (score >= 50) {
      return !isButtonColor ? 'warning' : 'scoringBtnWarning';
    }
    if (score > 0) {
      return !isButtonColor ? 'success' : 'scoringBtnGreen';
    }
    if (score === 0) {
      return !isButtonColor ? 'warning' : 'scoringBtnWarning';
    }
    return !isButtonColor ? 'default' : 'scoringBtnDefault';
  };

  const outdatedTooltipText = (
    <div>
      <div>
        <strong>Outdated dev score</strong>
        {' '}
        - an index that shows how long the status of the active issue has not been updated.
      </div>
      <div>
        <strong>Threshold values: </strong>

        it&apos;s recommended to pay attention when the value is 0 or greater than 50.

      </div>

      {/* <strong>Calculation:</strong>
          days_from_change * issue_status * issue_priority
          <br /> */}
      {/* <strong>Threshold values:</strong>
      <ul>
        {' '}
        <li>it is equal to 0 - the issue is relevant or in an inactive status,</li>
        <li>from 0 to 4 - work is underway on the issue,</li>
        <li>more than 4 - the status of the task has not been updated for a long time.</li>
      </ul> */}

    </div>
  );

  const outdatedIssueTooltipText = (
    <div>
      <div>
        <strong>Outdated dev score</strong>
        {' '}
        - an index that shows how long the status of the active issue has not been updated.
      </div>
      <div>
        <strong>Threshold values: </strong>

        <ul style={{ margin: '0 0', padding: '0 16px' }}>

          <li>it is equal to 0 - the issue is relevant or in an inactive status,</li>
          <li>from 0 to 4 - work is underway on the issue,</li>
          <li>more than 4 - the status of the task has not been updated for a long time.</li>
        </ul>

      </div>

    </div>
  );

  return {
    outdatedTitle,
    outdatedTooltipText,
    outdatedIssueTooltipText,
    computeAllProjectsOutdatedScore,
    computeProjectOutdatedScore,
    handleOutdatedBackground,
  };
}

import cn from "classnames";
import Sider from "antd/es/layout/Sider";
import React from "react";
import PropTypes from "prop-types";
import './BaseSidebar.scss';

function BaseSidebar({trigger, fixed, collapsible, collapsed, sidebarWidth, className, children, ...props}) {
  return (
    <Sider
      trigger={trigger}
      fixed={fixed}
      collapsible={collapsible}
      collapsed={collapsed}
      width={sidebarWidth}
      className={cn('base-sidebar', fixed ? 'fixed' : '')}
      {...props}
    >
      {children}
    </Sider>
  );
}

export default BaseSidebar;

BaseSidebar.propTypes = {
  trigger: PropTypes.bool,
  fixed: PropTypes.bool,
  collapsible: PropTypes.bool,
  collapsed: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  sidebarWidth: PropTypes.number,
};

import React, {
  useContext, useEffect, useState,
} from 'react';
import PropTypes, { bool } from 'prop-types';
import { Badge } from 'antd';
import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';

export default function RemoveSelectedTestCycleWrapper({
  updateAfterRemove,
  selectRows,
  isArchive,
  disabled,
}) {
  const { partitionType } = useContext(BugTrackerContext);

  const [entityUUID, setEntityUUID] = useState([]);

  const counter = isArchive ? selectRows : entityUUID;

  useEffect(
    () => {
      if (!isArchive) {
        setEntityUUID(selectRows);
      }
    },
    [selectRows.length],
  );

  return counter.length !== 0
      && (
        <Badge
          count={counter.length ? counter.length : 0}
          showZero
          style={{ backgroundColor: 'red' }}
        >
          <HardDeleteEntityContainer
            name={isArchive ? 'selected' : 'cycles'}
            labelMode={false}
            entityUUID={isArchive ? selectRows : entityUUID}
            className="ant-btn customAntOutDangerBtn ant-btn-default"
            actionForEntity={updateAfterRemove}
            checkHardDelete={false}
            entityType="testCycle"
            type="entity"
            sizeBtn="small"
            antNotificationSuccess
            partitionType={partitionType}
            disabled={disabled}
          />
        </Badge>
      );
}
RemoveSelectedTestCycleWrapper.propTypes = {
  updateAfterRemove: PropTypes.func,
  selectRows: PropTypes.array,
  isArchive: PropTypes.bool,
  disabled: bool,
};

import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { List } from 'antd';
import { capitalize, get } from "lodash";
import { useTranslation } from "react-i18next";
import BaseButton from "../../../../components/_ui/BaseButton/BaseButton";
import { useDispatch } from "react-redux";
import { getListAndPartialReadEntities, incrementAndGetCount } from "../../../../entity/actions/entityActions";
import cn from 'classnames';
import { array, func, string } from "prop-types";
import AntDesignSearchBox54origins from "../../../../54origins/components/AntDesignSearchBox54origins";

const AttachIssueModal = ({
    defaultPartition, 
    projectUUID, 
    vectorUUID,
    vectorIssues,
    updateIssue,
    vectorID,
    vectorStatus
}) => {

const dispatch = useDispatch()
const {t} = useTranslation()

const [isOpen, setIsOpen] = useState(false);
const [issuesData, setIssuesData] = useState([])
const [loading, setLoading] = useState(false)
const [valueSearch, setValueSearch] = useState('')

const [selectedIssue, setSelectedIssue] = useState(null)

const toggleBtn = () => {
    setIsOpen((prev) => !prev)
    setSelectedIssue(null)
    setValueSearch('')
};  

const getIssues = async () => {
    setLoading(true)
    const data = {
        entity_type: "issue",
        parent: projectUUID,
        depth: 0,
        params: {
        },
        params_fields: {
            id: "id",
            title: 'title',
        },
        search_data: {
            value: valueSearch,
            ignore_case: true,
            fields: {
                params: ['title'],
            },
            }
    };
    const constants = [
        "GET_ENTITY_REQUEST",
        "GET_ENTITY_SUCCESS",
        "GET_ENTITY_FAILURE"
    ]
      
        const issues = await dispatch(getListAndPartialReadEntities({
        data,
        constants,
        partition: defaultPartition,

        }))
        setIssuesData(issues.data)
        setLoading(false)
}

const getIdIssue = async () => {
    const countIssue = await dispatch(
      incrementAndGetCount(
        {
          entity_uuid: vectorUUID,
          field_name: 'issue',
        },
        defaultPartition,
      ),
    );

    if (vectorUUID) {
        return `${vectorID}T${get(countIssue, 'increment')}`;
    }
  };

const changeVectorForIssue = async (selectedIssue) => {
    if(selectedIssue) {
        const newId = await getIdIssue()
        updateIssue(selectedIssue.uuid, {vector: vectorUUID, id: newId} )
    }
    setSelectedIssue(null)
    setValueSearch('')
    setIsOpen(false)
}

useEffect(() => {
    getIssues()
}, [valueSearch])

return (
    <Popover
        placement="left"
        trigger="click"
        destroyTooltipOnHide
        open={isOpen}
        onClick={toggleBtn}
        content={(
        <div className="relative">
        <div
            className="wrapper_select_release"
            onClick={(e) => e.stopPropagation()}
            >
            <div className="flex items-center justify-between p-2 wrapper_header_assigned_user_btn">
                <BaseButton
                id="saveBtn"
                className="mr-1"
                type="primary"
                disabled={!selectedIssue}
                onClick={() => changeVectorForIssue(selectedIssue)}
                >
                {capitalize(t('wms.verb.save', 'save'))}
                </BaseButton>
                <div className="">
                <AntDesignSearchBox54origins onSearch={setValueSearch} styles={{width: 315}}/>
                </div>
            </div>

                <div className="wrapper_list_assigned_user_btn height-350">
                    <List
                    className="p-2"
                    dataSource={issuesData}
                    loading={loading}
                    renderItem={(item, key) => (
                        <List.Item
                        key={(item?.uuid || item.label) + key}
                        className={cn(
                            'item_from_list_items',
                            {
                            selected: item.uuid === selectedIssue?.uuid,
                            disabled: vectorIssues.filter(el => el?.uuid?.includes(item?.uuid)).length > 0
                            },
                        )}
                        onClick={() => vectorIssues.filter(el => el?.uuid?.includes(item?.uuid)).length > 0 ? setSelectedIssue(null) : setSelectedIssue(item) 
                        }
                        >
                        <List.Item.Meta
                            title={(
                            <div className="flex items-center">
                                <span className="inline-flex pl-1 pr-3 text-xs whitespace-nowrap">
                                {`${item?.id || 0}`}
                                </span>
                                <span className="inline-flex px-1">{item?.title}</span>
                            </div>
                            )}
                        />
                        </List.Item>
                        )}
                        />
                    </div>
                    </div>
            <div className="substrate_assigned_users" onClick={toggleBtn} role="none" />
        </div>
        )}
    >
    <BaseButton
    id='addIssueButton'
    size="small"
    className={!vectorStatus === "protected" ? "customAntOutPrimaryBtn ml-2" : 'ml-2'}
    type="primary"
    ghost
    disabled={vectorStatus === "protected"}
    >
    {capitalize(t('Attach issue'))}
    </BaseButton>
    </Popover>

    );
}

export default AttachIssueModal;

AttachIssueModal.propTypes = {
    defaultPartition: string,
    vectorIssues: array,
    projectUUID: string,
    updateIssue: func,
    vectorUUID: string,
    vectorID: string,
    vectorStatus: string
}
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  isNil, propOr,
} from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Button, Col, Result, Row,
} from 'antd';
import { get, capitalize } from 'lodash';

import AuthLoader from '../../../auth/components/AuthLoader';
import BreadCrumbsNEW from '../../../components/breadcrumb/BreadCrumbsNEW';
import UniGeneralButtons from '../../../projectFlow/components/uniGeneralButtons/UniGeneralButtons';
import PersonalDashboardRoot from '../../../userFlow/components/PersonalDashboard/PersonalDashboardRoot';
import PersonalProjectsCardRoot from '../../../userFlow/components/PersonalProjects/PersonalProjectsCardRoot';
// import DetailedRecordRoot from '../../../userFlow/components/DetailedRecords/DetailedRecordRoot';

import {
  getOtherUserPublicEntity,
  getOtherUserPublicFetching,
  getOtherUserPublicProfileActor,
} from '../../../userFlow/store/selectors/selectors';
import { cookBookTemplate, getProjectParams } from '../../../projectFlow/selectors/selectors';
import { UsersPartitionConstants } from '../../../userFlow/constants/Constants';
import { getActorPublicEntity } from '../../../userFlow/store/action-creators/userActions';
import useURLParams from '../../../hooks/useURLParams';
import { getNameForActor } from '../../../54origins/utils54origins';
import useActorsW54 from '../../../actors/hooks/useActorsW54';
import { appLabel } from "../../../api/appConfig";

function MemberDashboard() {
  const userUUIDFromUrl = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { routeNavigateTo, currentLocationPathname } = useURLParams();

  const userUUID = get(userUUIDFromUrl, 'uuid');
  const cookBookTemplateList = useSelector(cookBookTemplate);
  const getProjectParam = useSelector(getProjectParams);
  const actorUUID = useSelector(getOtherUserPublicProfileActor);
  const otherUserProfile = useSelector(getOtherUserPublicEntity);
  const otherUserPublicFetching = useSelector(getOtherUserPublicFetching);

  const { users: usersW54 } = useActorsW54({ actors: [actorUUID] });

  const {
    showBaseReport = false,
    showDetailedReport = false,
  } = get(otherUserProfile, ['params'], {});

  const [viewMode, switchViewFunc] = useState('projects');
  const [userExists, setUserExists] = useState(true);

  const tabsList = useMemo(() => [
    { name: 'dashboard', title: 'personal dashboard' },
    { name: 'projects', title: 'Personal projects' },
  ], [showDetailedReport, showBaseReport]);

  const initPath = `/admin/dashboard/public/user/${userUUID}`;

  const templatesData = [
    ...cookBookTemplateList,
  ];

  const nameFromPath = (path) => {
    switch (path[path.length - 1]) {
      case 'template3project':
        return 'Project with vector & issue';
      case 'template2project':
        return 'Project with vector';
      case 'template1project':
        return 'SEO';
      case 'template1vector':
        return 'Vector';
      case 'template2vector':
        return 'Vector with issue';
      default:
        return '';
    }
  };

  const currentCookBook = useCallback((path) => {
    let data;
    if (path[path.length - 2] === 'cookbook') {
      const selectedCookBook = templatesData.find(
        (item) => item.label === nameFromPath(
          path,
        ) || item.uuid === path[path.length - 1],
      );
      data = (!isNil(selectedCookBook) && !isNil(selectedCookBook.label))
        ? selectedCookBook.label : selectedCookBook;
    }
    // console.log('templatesData',selectedCookBook)
    return data
      ? {
        label: data,
        link: `admin/dashboard/public/user/${userUUID}/cookbook`,
      }
      : {};
  }, [currentLocationPathname]);

  const getMembersBreadCrumbsConfig = () => {
    const user = usersW54.find((item) => item.uuid === actorUUID);

    const crumbs = [
      {
        label: capitalize(
          t('wms.sidemenu.admin.dashboard'),

        ),
      },
      {
        label: capitalize(
          t('wms.sidemenu.tools.users', 'Users'),
        ),
        link: 'admin/dashboard',
      },
      {
        label: getNameForActor(user),
        title: capitalize(
          t('wms.projectflow.breadcrumbs.user', 'user'),
        ),
      },
    ];

    const cookBookBredCrumbs = () => {
      const path = currentLocationPathname.split('/');

      return path[path.length - 2] === 'cookbook'
        ? [
          {
            label: capitalize(
              t(`wms.projectflow.breadcrumbs.${viewMode}`, `${viewMode}`),
            ),
            link: `admin/dashboard/public/user/${userUUID}/cookbook`,
          },
          currentCookBook(path),
        ]
        : [
          {
            label: capitalize(
              t(`wms.projectflow.breadcrumbs.${viewMode}`, `${viewMode}`),
            ),
            link: `admin/dashboard/public/user/${userUUID}/cookbook`,
          },
        ];
    };

    const getProjectBreadCrumb = () => {
      const path = currentLocationPathname.split('/');

      const currentSelectedProject = () => {
        const pathProject = [
          { label: propOr('', 'title', getProjectParam) },
        ];

        return [
          ...pathProject,
        ];
      };

      return (path[6] === 'publicprojects' && !isNil(path[7]))
        ? [
          {
            label: capitalize(
              t('wms.labels.personal_projects'),
            ),
            link: `admin/dashboard/public/user/${userUUID}/publicprojects`,
          },
          ...currentSelectedProject(),
        ]
        : [
          {
            label:
              capitalize(
                t('wms.labels.personal_projects'),
              ),
            link: `admin/dashboard/public/user/${userUUID}/publicprojects`,
          },
        ];
    };

    const currentTab = () => {
      switch (viewMode) {
        case 'cookbook':
          return cookBookBredCrumbs();
        case 'daily plan & report':
          return [
            {
              label: capitalize(
                t('wms.labels.plan_and_report'),
              ),
            },
          ];
        case 'publicprojects':
          return getProjectBreadCrumb();
        default:
          return [
            {
              label: capitalize(
                t(`wms.labels.${viewMode}`, `${viewMode}`),
              ),
            },
          ];
      }
    };

    return {
      admin: {
        crumbs: [
          ...crumbs,
          ...currentTab(),
        ],

      },
    };
  };

  const switchView = (tab) => {
    switch (tab) {
      case 'projects':
        return routeNavigateTo(`${initPath}/projects`);
      case 'dashboard':
        return routeNavigateTo(`${initPath}/dashboard`);
      default:
        return routeNavigateTo(`${initPath}/projects`);
    }
  };

  const actorPublicEntity = async () => {
    const res = await dispatch(getActorPublicEntity(userUUID));
    const uuid = get(res, '[0].uuid');
    if (!uuid) {
      setUserExists(false);
    }
  };

  useEffect(() => {
    if (userUUID) {
      actorPublicEntity();
    }
    return () => {
      dispatch(
        { type: UsersPartitionConstants.CLEAR_OTHER_USER_PROFILE_PUBLIC_ENTITY },
      );
    };
  }, [userUUID]);

  useEffect(() => {
    const path = currentLocationPathname.split('/');

    switchView(path[6])
  }, [currentLocationPathname]);

  if (otherUserPublicFetching) {
    return <AuthLoader />;
  }

  if (!userExists) {
    return (
      <div className="w-full">
        <Row>
          <Col span={24}>
            <Result
              status="warning"
              title={`User has not logged into ${appLabel} yet .`}
              extra={(
                <div className="w-full flex justify-center">
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => routeNavigateTo('/admin/dashboard')}
                  >
                    return to members
                  </Button>
                </div>
              )}
            />
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className="w-full">
      <Row>
        <Col span={24} className="py-2">
          <BreadCrumbsNEW
            breadCrumbsConfig={getMembersBreadCrumbsConfig()}
          />
        </Col>
      </Row>
      <UniGeneralButtons
        viewMode={viewMode}
        switchViewFunc={switchView}
        tabs={tabsList}
      />

      {viewMode === 'dashboard' && <PersonalDashboardRoot otherUser />}

      {viewMode === 'projects' && <PersonalProjectsCardRoot otherUser />}
      {/* {viewMode === 'projects' && <DashboardPersonalProjectsCardRoot location="dashboard" otherUser />} */}
    </div>

  );
}

export default MemberDashboard;

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toUpper } from 'lodash';
import { getUserPublicProfileUUID } from '../store/selectors/selectors';
import { getMainPublicProject } from '../store/action-creators/userActions';
import { getMD5HashFromString } from '../../MainUtils';
import { getOrCreateSingle } from '../../entity/actions/entityActions';
import { getNameForActor } from '../../54origins/utils54origins';
import { getUserUuid } from '../../projectFlow/selectors/selectors';
import { partitionNamesConfig } from '../../api/appConfig';

function usePublicUserProjects() {
  const dispatch = useDispatch();

  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);
  const myUUID = useSelector(getUserUuid);

  const getOrCreatePersonalNamedProject = () => {
    dispatch(getMainPublicProject(myPublicEntityUUID)).then((res) => {
      const data = {
        entity_type: 'project',
        params: {
          status: 'systemic',
          title: toUpper(getNameForActor(res?.owner_uinfo)),
          users: [
            {
              role: '',
              uuid: myUUID,
            },
          ],
          usersSearch: [myUUID],
        },
        key: getMD5HashFromString(`personalproject${res.uuid}`),
        parent: res.uuid,
      };
      dispatch(getOrCreateSingle({
        data,
        partition: partitionNamesConfig.partition3,
      }));
    });
  };

  useEffect(() => {
    if (myPublicEntityUUID) {
      getOrCreatePersonalNamedProject();
    }
  }, [myPublicEntityUUID]);
}

export default usePublicUserProjects;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fetchingDeleteChatForUser: false,
};

const ChatGPTSlice = createSlice({
  name: 'ChatGPTSlice',
  initialState,
  reducers: {
    DELETE_CHAT_FOR_USER_REQUEST(state) {
      state.fetchingDeleteChatForUser = true;
    },
    DELETE_CHAT_FOR_USER_SUCCESS(state, action) {
      state.fetchingDeleteChatForUser = false;
    },
    DELETE_CHAT_FOR_USER_FAILURE(state) {
      state.fetchingDeleteChatForUser = false;
    },
  }
});

export const {
  DELETE_CHAT_FOR_USER_REQUEST,
  DELETE_CHAT_FOR_USER_SUCCESS,
  DELETE_CHAT_FOR_USER_FAILURE,
} = ChatGPTSlice.actions;

export default ChatGPTSlice.reducer;

import React from 'react';
import Icon from "@mdi/react";
import { mdiArrowDown, mdiArrowUp } from "@mdi/js";
import { useSelector } from "react-redux";

import BaseButton from "../../../components/_ui/BaseButton/BaseButton";

import { getProjectGoals, getTotalGoals } from "../../reducers/slicers/projectGoalsSlicer";
import { useGoalsRequestHook } from "../../../entity/hooks/useGoalsRequestHook";
import { stopPropagation } from "../../../54origins/utils54origins";


const GoalsPriorityBtns = ({
  size,
  goalUUID,
  defaultPartition,
  onClickCallback,
  currentPage,
}) => {
  const { updateProjectGoalRequest } = useGoalsRequestHook(defaultPartition)

  const projectGoals = useSelector(getProjectGoals);

  const updateGoal = async (event, value) => {
    stopPropagation(event)

    const findIndex = projectGoals.map(item => item?.uuid).indexOf(goalUUID);
    const currentGoal = projectGoals[findIndex]

    console.log("cuurentGoal", currentGoal);


    let currentGoalData = {
      uuid: goalUUID,
      params: {
        handleSort: currentGoal?.handleSort
      }
    }

    if(value === 'up') {
      const prevGoal = projectGoals[findIndex - 1]
      const prevData = {
        uuid: prevGoal.uuid,
        params: {
          handleSort: currentGoal?.handleSort - 1
        }
      }

      currentGoalData = {
        ...currentGoalData,
        params: {
          handleSort: prevGoal?.handleSort
        }
      };

      await updateProjectGoalRequest(prevData).then(() => {
        onClickCallback?.()
      })
     await updateProjectGoalRequest(currentGoalData)

      // console.log('UP currentGoal', currentGoal);
      // console.log('UP currentGoalData', currentGoalData);
      // console.log('UP prevData', prevData);

    } else {
      const nextGoal = projectGoals[findIndex + 1]
      const nextData = {
        uuid: nextGoal.uuid,
        params: {
          handleSort: currentGoal?.handleSort + 1
        }
      }

      currentGoalData = {
        ...currentGoalData,
        params: {
          handleSort: nextGoal?.handleSort
        }
      };

      await  updateProjectGoalRequest(nextData).then(() => {
        onClickCallback?.()
      })
      await updateProjectGoalRequest(currentGoalData)

    }
  }

  const checkIfDisabled = (key) => {
    if(key === 'up') {
      return projectGoals[0]?.uuid === goalUUID
    } else {
      return projectGoals[projectGoals.length -1 ]?.uuid === goalUUID
    }
  }

  return(
    <>
      <BaseButton
        id='upPriorityButton'
        disabled={checkIfDisabled('up')}
        size={size}
        onClick={(e) => updateGoal(e,'up')}
      >
        <Icon path={mdiArrowUp} size={0.6} color={'blue'}/>
      </BaseButton>
      <BaseButton
        id="downPriorityButton"
        disabled={checkIfDisabled('down')}
        onClick={(e) => updateGoal(e,'down')}
        size={size}
      >
        <Icon path={mdiArrowDown} size={0.6} color={'blue'}/>
      </BaseButton>
    </>
  )
}

export default GoalsPriorityBtns;

import { get } from 'lodash';

export const firstName = (actor) => get(actor, 'uinfo.first_name', 'name');

export const lastName = (actor) => get(actor, 'uinfo.last_name', 'last_name');

export const fullName = (actor, type = 'user') => {
  switch (type) {
    case 'user': return `${firstName(actor)} ${lastName(actor)}`
    case 'group': return get(actor, 'uinfo.group_name', 'group_name')
    default: return `${firstName(actor)} ${lastName(actor)}`
  }
};

export function debounce(func, delay) {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

export const sortByLabel = (a, b) => {
  if (a?.label.toLowerCase() < b?.label.toLowerCase()) {
    return -1;
  }
  if (a?.label.toLowerCase() > b?.label.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const sortByAdmin = (a, b) => {
  return b?.isAdmin - a?.isAdmin;
}

export const USER_TABS = {
  all: 'All',
  project: 'Project',
  vector: 'Vector',
};

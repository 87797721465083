import PropTypes from 'prop-types';
import React, { memo } from 'react';
import ReactDataSheet from 'react-datasheet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SheetRendererHook from '../sheet/SheetRendererHook';

import { capitalize } from 'lodash';
import { getLocale } from '../../../../locale/selectors';

function DataGridComponentHook({
  grid = [],
  type,
  typeOfParent,
  onCellsChangedCallback,
}) {
  const { t } = useTranslation();
  const locale = useSelector(getLocale);

  const step = {
    label: capitalize(t('wms.projectflow.qa.case.new.table.headers.step', 'step')),
    title: 'step',
    width: '5%',
  };

  const description = {
    label: capitalize(t('wms.projectflow.qa.case.new.table.headers.description', 'description')),
    title: 'description',
    width: '20%',
  };

  const testData = {
    label: capitalize(t('wms.projectflow.qa.case.new.table.headers.test_data', 'test data')),
    title: 'test_data',
    width: '20%',
  };

  const expected = {
    label: capitalize(t('wms.projectflow.qa.case.new.table.headers.expected_result', 'expected result')),
    title: 'expected',
    width: '25%',
  };

  const actions = {
    label: capitalize(t('wms.projectflow.qa.case.new.table.headers.actions', 'actions')),
    title: 'actions',
    width: '16%',
  };

  const status = {
    label: capitalize(t('wms.projectflow.qa.case.new.table.headers.status', 'status')),
    title: 'status',
    width: locale === 'en' ? '28%' : '50%',
  };

  const order = {
    label: capitalize(t('wms.table.headers.order', 'Order')),
    title: 'order',
    width: '8%',
  };

  const cycleTestCaseColumns = [order, step, description, testData, expected, status, actions];
  const suiteTestCaseColumns = [order, step, description, testData, expected, actions];

  const columns = {
    suite: suiteTestCaseColumns,
    cycle: cycleTestCaseColumns,
    cycleArchive: cycleTestCaseColumns,
  };

  const valueRenderer = (cell) =>
    // Set actual props
    (cell.hasOwnProperty('valueViewer') ? grid : cell.value);

  const customStyleTable = {
    suite: 'steps-border-suit',
    cycle: 'steps-border-cycle',
    cycleArchive: 'steps-border-cycle-archive',
  };

  return (
    <ReactDataSheet
      data={grid}
      overflow="wrap" 
      // valueRenderer={({ value }) => value}
      valueRenderer={valueRenderer}
      sheetRenderer={(props) => (
        <SheetRendererHook
          {...props}
          columns={columns[typeOfParent]}
          customStyleTable={customStyleTable[typeOfParent]}
          template
          type={type}
        />
      )}
      // rowRenderer={({ row, ...rest }) => <SheetRow rowIndex={row} {...rest} />}
      onCellsChanged={onCellsChangedCallback}
    />
  );
}

DataGridComponentHook.propTypes = {
  grid: PropTypes.array,
  onCellsChangedCallback: PropTypes.func,
  type: PropTypes.string,
  typeOfParent: PropTypes.string,
};

export default memo(DataGridComponentHook);

import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import {
  head, isEmpty, isNil, omit, prop, propOr,
} from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { capitalize } from 'lodash';
import { getRequestEntity } from '../../../entity/actions/entityActions';
import { getProject } from '../../selectors/selectors';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import SaveAsCookbookModal from './SaveAsCookbookModal';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';

function CurrentProjectTemplate({
  customProject,
  getListTemplateCookBook,
  currentPartition,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const project = head(useSelector(getProject));

  const [fetching, setFetching] = useState(false);
  const [fetchingInSaveModal, setFetchingInSaveModal] = useState(false);
  const [listDataProject, setListDataProject] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);

  const currentProject = customProject || project;

  const withoutProps = (item, extraOptions = []) => omit(['uuid', 'parent', 'created', 'modified', 'file',
    'files', 'owner', 'uinfo', 'creator_perms',
    'actor', 'owner_uinfo', 'perms_inherited', ...extraOptions], item);

  const extraOptionsParams = ['assigned_users', 'vector', 'project', 'usersSearch'];

  const getPromiseRequestEntity = async (uuid, type) => {
    const data = {
      entity_type: type,
      params: {},
      parent: uuid,
      depth: 0,
    };

    return dispatch(getRequestEntity({ data, partition: currentPartition }));
  };

  const getDataAndSetTemplate = async (row) => {
    setOpenModal(true);
    setFetchingInSaveModal(true);

    let newData = {};
    let vectors = [];
    const res = await getPromiseRequestEntity(row.uuid, 'issue');
    const issues = prop('data', res);
    if (row.entity_type === 'project') {
      vectors = listDataProject.reduce((sum, current) => {
        if (current.entity_type === 'vector') {
          return ([
            ...sum,
            {
              ...withoutProps(current),
              issues: [
                ...issues.filter((issue) => issue.parent === current.uuid)
                  .map((item) => ({
                    ...withoutProps(item),
                    params: withoutProps(item.params, extraOptionsParams),
                  })),
              ],
            }]);
        }
        return sum;
      }, []);

      const issueProject = issues.filter((issue) => issue.parent === currentProject.uuid)
        .map((item) => ({
          ...withoutProps(item),
          params: withoutProps(item.params, extraOptionsParams),
        }));

      const data = {
        ...withoutProps(currentProject),
        params: withoutProps(currentProject.params, extraOptionsParams),
        vectors,
        issues: issueProject,
      };
      newData = { entityType: 'project', jsObject: data, error: false };
    }

    if (row.entity_type === 'vector') {
      vectors = [row].map((item) => ({
        ...withoutProps(item),
        issues: [
          ...issues.filter((issue) => issue.parent === item.uuid)
            .map((i) => ({
              ...withoutProps(i),
              params: withoutProps(i.params, extraOptionsParams),
            })),
        ],
      }));

      newData = {
        entityType: 'vector',
        error: false,
        jsObject: {
          entity_type: 'project',
          params: {
            archive: false,
            areas: [],
            description: '',
            goal: '',
            status: 'open',
            title: 'Project name',
            usersSearch: [],
          },
          vectors,
          issues: [],
        },
      };
    }

    setCurrentTemplate(newData);
    setFetchingInSaveModal(false);
  };

  const columns = [
    {
      dataIndex: ['params', 'title'],
      title: t('wms.table.headers.title', 'Title'),
      sort: true,
    },
    {
      dataIndex: 'entity_type',
      title: capitalize(t('wms.table.headers.type', 'type')),
      sort: true,
      render: (cell) => (
        <EntityParamTag
          value={cell}
          type="badge"
          param="entity"
        />
      ),
    }, {
      dataIndex: 'uuid',
      title: capitalize(t('wms.table.headers.action', 'action')),
      sort: true,
      render: (cell, row) => (
        <BaseButton
          className="btnPrimary-outline mr-2 mb-2"
          size="small"
          onClick={() => getDataAndSetTemplate(row)}
        >
          {capitalize(t('wms.buttons.cookbook_create', 'create cookbook'))}
        </BaseButton>
      ),
    }];

  const getEntities = async (data) => {
    setFetching(true);
    const parent = propOr('', 'uuid', data);
    const res = await getPromiseRequestEntity(parent, 'vector');
    const vectors = prop('data', res);
    const listData = [data, ...vectors];

    setListDataProject(listData);
    setFetching(false);
  };

  const setCustomOpenModalCallback = (boll) => setOpenModal(boll);

  const rowClassName = (row) => 'universal_table_row';

  useEffect(() => {
    if (!isNil(currentProject)) {
      getEntities(currentProject);
    }
  }, [customProject]);

  return (
    <div className="p-4">
      <h5 className="mb-2">{`${capitalize(t('wms.modals.headers.create_cookbooks_from_current_project'))}:`}</h5>

      <BaseTableWithPagination
        loading={fetching}
        headerRowClassName="universal_header_table_row"
        paginationSize="small"
        rowClassName={rowClassName}
        data={listDataProject}
        columns={columns}
        pageLimit={pageLimit}
        changePageLimit={setPageLimit}
        sizePerPageList={[10, 25, 50, 100]}
      />

      {!isEmpty(currentTemplate)
        && (
          <SaveAsCookbookModal
            customHideLink={false}
            entityType={prop('entityType', currentTemplate)}
            cookBookState={currentTemplate}
            disabledBtn={false}
            customFetching={fetchingInSaveModal}
            customOpenModal={openModal}
            customHideBtn
            getListTemplateCookBook={getListTemplateCookBook}
            setCustomOpenModalCallback={setCustomOpenModalCallback}
          />
        )}
    </div>
  );
}

CurrentProjectTemplate.propTypes = {
  customProject: PropTypes.object,
  getListTemplateCookBook: PropTypes.func,
  currentPartition: PropTypes.string,
};

export default CurrentProjectTemplate;

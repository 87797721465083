import {
  every, isNil, keys, uniq,
  values,
} from 'lodash';
import {
  prop,
} from 'ramda';
import { getUuid } from '../assignUsersModal/utils';

export const getInitialsFromActors = (actors = []) => {
  const arr = new Map([]);
  actors?.forEach?.((item) => {
    const firstName = prop('first_name', item.uinfo);
    const lastName = prop('last_name', item.uinfo);

    const getFirstNameInitials = () => (firstName ? firstName.charAt(0).toUpperCase() : '');
    const getLastNameInitials = () => (lastName ? lastName.charAt(0).toUpperCase() : '');

    arr.set(item.uuid, {
      initials: `${getFirstNameInitials()}${getLastNameInitials()}`,
      fullName: `${firstName} ${lastName}`,
      label: prop('name', item.uinfo) ? `${item.actor_type} ${item.uinfo.name}`
        : prop('service_name', item.uinfo) ? `${item.actor_type} ${item.uinfo.service_name}`
          : prop('first_name', item.uinfo) ? `${item.actor_type} ${item.uinfo.first_name}${item.uinfo.last_name}` : `${item.actor_type} ` + 'name',
      value: item.uuid,
    });
  });

  return arr;
};

export const getUuids = (items) => uniq(items?.map?.((item) => (typeof item === 'string' ? item : getUuid(item)))
  ?.filter?.((uuid) => !isNil(uuid)) ?? []);

export const checkAdminPerms = (perms = {}) => values(perms).every((el) => el);

export const permKeys = ['create', 'read', 'update', 'delete', 'list', 'set'];

import { capitalize, get } from 'lodash';

export const permsAdmin = {
  create: true,
  read: true,
  update: true,
  delete: true,
  list: true,
  set: true,
};

export const permsNormalUser = {
  create: true,
  read: true,
  update: true,
  delete: false,
  list: true,
  set: false,
};

export const filterByActorType = (actors) => actors.filter((item) => (
  ['user', 'classic_user', 'phantom'].indexOf(item.actor_type) !== -1));

export const getUuid = (item) => get(item, 'uuid') || get(item, 'actor_uuid') || get(item, 'value');
export const firstName = (actor) => get(actor, 'uinfo.first_name', 'name');
export const lastName = (actor) => get(actor, 'uinfo.last_name', 'last_name');
export const fullName = (actor, type = 'user') => {
  switch (type) {
    case 'user': return `${firstName(actor)} ${lastName(actor)}`
    case 'group': return get(actor, 'uinfo.group_name', 'group_name')
    default: return `${firstName(actor)} ${lastName(actor)}`
  }
};
export const getInitial = (string) => string?.charAt(0).toUpperCase();

export const blockUsersStyles = {
  height: 550,
  borderRadius: '10px 10px 0 0',
  border: '1px solid #c0e5f8',
};

export const largePopoverStyle = {
  width: 765,
}

export const middlePopoverStyle = {
  height: 550,
  width: 665,
}

export const smallPopoverStyle = {
  height: 470,
  width: 565,
}

export const extraSmallPopoverStyle = {
  height: 450,
  width: 260,
}

export const defaultPopoverStyle = {
  width: 765,
}
import moment from 'moment';

export const indexesStartWorkRecord = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
export const indexesDetailedRecord = ['A', 'B', 'C', 'D', 'E', 'F'];


export const columnsDetailedRecord = [
  {
    name: 'date',
    title: 'Date',
    width: 120,
    align: 'left',
  },
  {
    name: 'title',
    title: 'Title',
    align: 'left',
    width: 300,
  },
  {
    name: 'typeForReport',
    title: 'Type',
    width: 100,
  },
  {
    name: 'description',
    title: 'Description',
    align: 'left',
    width: 350,
  },
  {
    name: 'trackerTime',
    title: 'Time/00h00m',
    width: 100,
  },
  {
    name: 'normalTimeFormat',
    title: 'Time/HH:mm',
    width: 100,
  },
];

export const styleToTitles = {
  alignment: {
    wrapText: true,
    vertical: 'center',
    horizontal: 'center',
  },
  font: {
    name: 'TimesNewRoman',
    sz: 16,
    bold: true,
  },
  fill: {
    fgColor: { rgb: 'f0fae7' },
  },
  border: {
    top: {
      style: 'thin',
      color: { rgb: '000000' },
    },
    bottom: {
      style: 'thin',
      color: { rgb: '000000' },
    },
    right: {
      style: 'thin',
      color: { rgb: '000000' },
    },
    left: {
      style: 'thin',
      color: { rgb: '000000' },
    },
  },
};

export const styleToTimeCells = {
  alignment: {
    wrapText: true,
    vertical: 'center',
    horizontal: 'center',
  },
  font: {
    name: 'TimesNewRoman',
    sz: 12,
  },
  fill: {
    fgColor: { rgb: 'ffefd0' },
  },
  border: {
    top: {
      style: 'thin',
      color: { rgb: '000000' },
    },
    bottom: {
      style: 'thin',
      color: { rgb: '000000' },
    },
    right: {
      style: 'thin',
      color: { rgb: '000000' },
    },
    left: {
      style: 'thin',
      color: { rgb: '000000' },
    },
  },
};

export const getTemplateForPeriod = (startDate, days) => {
  const arr = [];
  for (let i = 0; i < days; i += 1) {
    arr.push({
      date: moment(startDate, moment.defaultFormat)
        .add(i, 'days')
        .format('YYYY-MM-DD'),
    });
  }
  return arr;
};

export const isDayoff = (date) => moment(date).locale('en').format('dddd') === 'Sunday'
|| moment(date).locale('en').format('dddd') === 'Saturday';

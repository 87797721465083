import { goalStatusFilterOptions } from "../../../../constants/allFilterOptions";

const statusColumnFilter = {
  filters: goalStatusFilterOptions,
  filterMode: 'tree',
  filterType: 'iconWithStatus',
};

export const defaultGoalFilter = {
  status: ['created', 'in progress', 'backlog'],
};
// console.log('defaultGoalFilter:', defaultGoalFilter);

export const columnsGoalFilterConfig = {
  status: statusColumnFilter,
};

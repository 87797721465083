import React from 'react';

import { Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

import { antNotification } from '../../../../MainUtils';
import { capitalize } from 'lodash';

function ImportFromJSON({
  importCallback,
  className = '',
  title,
}) {
  const { t } = useTranslation();

  const importProps = {
    beforeUpload: (file) => {
      if (file.type !== 'application/json') {
        antNotification('error', `${file.name} is not a json file`);
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = JSON.parse(e.target.result);

        if (importCallback) {
          importCallback(data);
        }
      };
      reader.readAsText(file);

      return false;
    },
    accept: '.json',
    showUploadList: false,
  };

  return (
    <Upload {...importProps}>
      <BaseButton
        className={`customAntOutPrimaryBtn ${className}`}
        size="small"
      >
        {title || capitalize(t('wms.verb.import'))}
      </BaseButton>
    </Upload>
  );
}

export default ImportFromJSON;

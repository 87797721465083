import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BaseModal from '../../components/_ui/BaseModal/BaseModal';
import Login54Origins from '../../54origins/components/Login54Origins';

import {
  getModalAuthSessionFlag,
  CHANGE_MODAL_GET_AUTH_SESSION,
} from '../slicers/getAuthSessionModalSlicer';
import { configUrlAuth } from '../../api/appConfig';

function GetSessionTokenModal({ width }) {
  const dispatch = useDispatch();

  const isOpen = useSelector(getModalAuthSessionFlag);
  // const isOpenGPT = useSelector(getModalGPTSessionFlag);

  const handleCancel = useCallback(() => {
    dispatch(CHANGE_MODAL_GET_AUTH_SESSION(false));
    // dispatch(CHANGE_MODAL_GET_GPT_SESSION(false));
  }, []);

  return (
    <BaseModal
      open={isOpen}
      handleCancel={handleCancel}
      width={width}
      destroyOnClose
    >
      <Login54Origins
        url={configUrlAuth}
        doNotMakeRedirect
        serviceName="Auth54"
        hiddenElements={['signUpSection']}
        afterSaveSession={handleCancel}
        reloadJsAfterLoadHTML
      />
    </BaseModal>
  );
}

export default memo(GetSessionTokenModal);

import React, {
  createContext,
  useCallback,
  useContext, useEffect,
  // useEffect,
  useMemo,
  useState
} from "react";
import { element, bool, func } from 'prop-types';
import { mdiTools } from '@mdi/js';
import Icon from '@mdi/react';
import { Switch, Tooltip, Flex } from 'antd';
import { antNotification } from '../MainUtils';

const SHOW_DEBUG_MODE = JSON.parse(process.env.DEBUG_MODE);
// console.log('DEBUG_MODE:', process.env.DEBUG_MODE);

const defaultValue = {
  debug: false,
  changeDebug: () => {},
  // integration: undefined,
  // isLoadingIntegration: false,
};

const AppModeContext = createContext(defaultValue);

export function useAppMode() {
  return useContext(AppModeContext);
}

export function AppModeSwitcher({ value, onChange }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (SHOW_DEBUG_MODE) {
    return (
      <Tooltip title={ windowWidth < 1536 ? 'Debug mode' : ''}>
        <Flex className="header-dropdown-wrapper">
          <Icon className="mr-2" path={mdiTools} size={1} />

          <span className="hidden 2xl:inline mr-2 debug-mode-label">Debug mode</span>

          <Switch
            size="default"
            checked={value}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={onChange}
          />
        </Flex>
      </Tooltip>
    );
  }

  return null;
}

AppModeSwitcher.propTypes = {
  value: bool,
  onChange: func,
};

function AppModeProvider({ children }) {
  // const [firstRender, setFirstRender] = useState(true);
  const [debug, setDebug] = useState(
    () => (SHOW_DEBUG_MODE
      ? (Boolean(JSON.parse(localStorage.getItem('debugMode') || 'false')))
      : false),
  );

  const changeDebug = useCallback(
    (value) => {
      if (SHOW_DEBUG_MODE) {
        localStorage.setItem('debugMode', String(value));
        setDebug(value);
        antNotification(
          'warning',
          'App mode was changed!',
          value ? 'Some features might appear' : 'Some features might disappear',
        );
      }
    },
    [SHOW_DEBUG_MODE],
  );

  const memoizedContext = useMemo(() => ({
    debug,
    changeDebug,
  }), [debug, changeDebug]);

  // useEffect(() => {
  //   if (isSuccess && !firstRender && integration) notification.warning({ message: 'Integration mode was changed!' });
  //   if (integration) setFirstRender(false);
  // }, [integration]);

  return <AppModeContext.Provider value={memoizedContext}>{children}</AppModeContext.Provider>;
}

AppModeProvider.propTypes = {
  children: element,
};

export default AppModeProvider;

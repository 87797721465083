import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiPlus, mdiMinus } from '@mdi/js';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List } from 'antd';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import UserAvatar from '../../../components/UserAvatar/UserAvatar';

import { getUuidsfromParamsUsers } from '../../../MainUtils';
import { entityPartialRead } from '../../../entity/actions/entityActions';
import { getProjectUUID } from '../../selectors/selectors';
import { getUsersByUUID } from '../../../userFlow/store/action-creators/userActions';
import { getInitialsNameOfActor, getNameForActor } from '../../../54origins/utils54origins';

function UsersForAssign({
  partitionType,
  parentUUID,
  multiSelectMode,
  title,
  isSmall,
  defaultSelectedUsers = [],
  onSaveUsersCallback,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectUUID = useSelector(getProjectUUID);
  // console.log('projectUUID', projectUUID);

  const [projectUsers, setProjectUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(defaultSelectedUsers);
  const [loading, setLoading] = useState(false);
  const [isActiveConfirm, setIsActiveConfirm] = useState(false);

  const checkIsAssigned = (uuid) => selectedUsers.includes(uuid);

  async function getProjectRequest() {
    const data = {
      entity_type: 'project',
      entity_uuid: projectUUID || parentUUID,
      params_fields: {
        users: 'users',
      },
    };

    const constants = [
      'GET_PROJECT_FOR_ACTORS_SELECT_NOREDUX_REQUEST',
      'GET_PROJECT_FOR_ACTORS_SELECT_NOREDUX_SUCCESS',
      'GET_PROJECT_FOR_ACTORS_SELECT_NOREDUX_FAILURE',
    ];

    const options = {
      partition: partitionType,
    };

    return dispatch(entityPartialRead({
      data,
      constants,
      options,
    }));
  }

  const getProjectActorsRequest = async () => {
    if (projectUUID || parentUUID) {
      const res = await getProjectRequest();

      // setProjectUsers(res[0]?.users || []);

      return dispatch(getUsersByUUID({
        uuid: getUuidsfromParamsUsers(res[0]?.users || []),
        userType: 'project',
      }));
    }
  };

  const getUsersList = async () => {
    setLoading(true);
    setProjectUsers(await getProjectActorsRequest());
    // console.log('Users', projectUsers);
    setLoading(false);
  };

  const selectUser = (e) => {
    const {
      id: uuid,
      getAttribute,
    } = e?.currentTarget || {};

    if (getAttribute('data-action') === '_add') {
      if (multiSelectMode === true) {
        setSelectedUsers((prev) => [...prev, uuid]);
      } else {
        setSelectedUsers([uuid]);
      }
    } else {
      setSelectedUsers((prev) => prev.filter((id) => id !== uuid));
    }

    setIsActiveConfirm(true);
  };

  const onSaveSelectedUsers = () => {
    if (onSaveUsersCallback) {
      onSaveUsersCallback(selectedUsers);
    }
  };

  useEffect(() => {
    getUsersList();
  }, [parentUUID]);

  return (
    <div className={`users-for-assign-content ${isSmall ? '_small' : ''}`}>
      <div className="flex justify-between items-center p-1 pb-2">
        <span className="users-for-assign-title">
          {title}
          :
        </span>
        <BaseButton
          id="saveBtn"
          className="ml-1"
          disabled={!isActiveConfirm}
          type="primary"
          size={isSmall ? 'small' : 'middle'}
          onClick={onSaveSelectedUsers}
        >
          {capitalize(t('wms.verb.confirm', 'confirm'))}
        </BaseButton>
      </div>
      <div className="users-for-assign-list-wrapper">
        <List
          dataSource={projectUsers}
          loading={loading}
          renderItem={(user) => {
            const isAssigned = checkIsAssigned(user?.uuid);
            const actions = isAssigned ? '_delete' : '_add';
            const userName = getNameForActor(user);
            const initials = getInitialsNameOfActor(user);

            return (
              <List.Item className={`users-for-assign-list-item ${isAssigned ? '_assigned' : ''}`} key={user?.uuid}>
                <List.Item.Meta
                  avatar={(
                    <UserAvatar
                      customInitials={initials}
                      className="avatar-my"
                      userUUID={user?.uuid}
                      size={isSmall ? 26 : 35}
                    />
                  )}
                  title={<span className="break-all">{userName}</span>}
                />
                <button
                  id={user.uuid}
                  className={`users-for-assign-button ml-2 ${actions}`}
                  data-action={isAssigned ? '_delete' : '_add'}
                  type="button"
                  tabIndex={0}
                  onClick={selectUser}
                >
                  <Icon path={isAssigned ? mdiMinus : mdiPlus} size={isSmall ? 0.8 : 1} />
                </button>
              </List.Item>
            );
          }}
        />
      </div>
    </div>
  );
}

UsersForAssign.propTypes = {
  partitionType: PropTypes.string,
  parentUUID: PropTypes.string,
  multiSelectMode: PropTypes.bool,
  title: PropTypes.string,
  isSmall: PropTypes.bool,
  defaultSelectedUsers: PropTypes.array,
  onSaveUsersCallback: PropTypes.func,
};

export default UsersForAssign;

import React from 'react';
import PropTypes, { string } from 'prop-types';
import { Tooltip } from 'antd';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';

function EditDataCookBookTree({
  params,
  type,
}) {
  return (
    <div className="w-full">
      <Tooltip title={params?.title}>
        <div className="title-data">
          <span className="title">
            {params?.title}
          </span>
          <EntityParamTag
            value={type}
            type="badge"
            param="entity"
          />
        </div>
      </Tooltip>
    </div>
  );
}

EditDataCookBookTree.propTypes = {
  params: PropTypes.object,
  type: string,
};

export default EditDataCookBookTree;

import React, { useMemo } from 'react';
import {
  arrayOf,
  number,
  object,
  string,
  oneOf,
  oneOfType,
  bool,
} from 'prop-types';
import { useSelector } from 'react-redux';

import ActorAvatarsGroup from './ActorAvatarsGroup';

import { getMe, getProjectParams } from "../../../selectors/selectors";
import useActorsW54 from '../../../../actors/hooks/useActorsW54';
import { getInitialsFromActors } from '../../../../actors/actorsUtils';
import { UI54AvatarGroup } from "@agpl/ui54kit";

const findMe = (userUUID, actorsArr) => {
  const indexOfMe = actorsArr.findIndex((actor) => actor?.uuid === userUUID);
  return [indexOfMe !== -1, indexOfMe];
};

function WrapperAvatarsGroupForProjectWithHook({
  wrapperClassName = '',
  actors = [],
  checkInProject = true,
  checkProjectRole = false,
}) {
  const userUUID = useSelector(getMe);
  const projectParams = useSelector(getProjectParams);

  const { users: usersW54 = [] } = useActorsW54({ actors });
  const [isFindMe, indexOfMe] = findMe(userUUID, usersW54);

  const getRoleFromProps = (uuid) => actors.find((user) => user.uuid === uuid)?.role || '';

  const getRoleFromProject = (uuid) => {
    const { users = [] } = projectParams;
    return users.find((user) => user.uuid === uuid)?.role || '';
  };

  const getRole = (uuid) => {
    let role = '';

    if (checkProjectRole) role = getRoleFromProject(uuid);
    else role = getRoleFromProps(uuid);

    return role;
  };

  const currentActors = useMemo(() => {
    const existedActors = [];
    const allActors = getInitialsFromActors(usersW54);
    actors.forEach((actor) => {
      let actorUUID = '';
      if (typeof actor === 'object') {
        actorUUID = actor?.uuid;
      } else if (typeof actor === 'string') {
        actorUUID = actor;
      }

      if (checkInProject && !projectParams?.usersSearch?.includes(actorUUID)) return;

      const isUserIn = actorUUID === userUUID;

      if (allActors.has(actorUUID)) {
        existedActors.push({
          name: allActors.get(actorUUID).fullName,
          additionalInfo: getRole(actorUUID),
          useInitials: true,
          tooltipText: allActors.get(actorUUID).fullName,
          uuid: allActors.get(actorUUID).value,
          highlight: isUserIn,
        });
      }
    });

    return existedActors;
  }, [JSON.stringify(usersW54),
  JSON.stringify(actors),
  JSON.stringify(projectParams?.usersSearch)
  ]);
  // console.log('currentActors', currentActors);

  return (
    <div className={`flex items-center ${wrapperClassName}`}>
      <UI54AvatarGroup
        type="switch"
        items={currentActors}
        maxCount={30}
        size="small"
        transparent={0.85}
        additionalInfoPrefix="role:"
        collapsedButtonSize="small"
        collapsedButtonCheckIcon={isFindMe}
        virtualized={false}
      />
    </div>
  );
}

WrapperAvatarsGroupForProjectWithHook.propTypes = {
  wrapperClassName: string,
  actors: oneOfType([arrayOf(object), arrayOf(string)]),
  maxCount: number,
  popoverPlacement: string,
  popoverTrigger: string,
  avatarSize: oneOfType([number, oneOf(['small', 'default', 'large']), object]),
  avatarGap: number,
  avatarStyles: object,
  listAvatarGap: number,
  listAvatarSize: oneOfType([number, oneOf(['small', 'default', 'large']), object]),
  wrapperListStyles: object,
  tooltipPlacement: string,
  checkInProject: bool,
  groupButtonSize: oneOf(['small', 'middle', 'large']),
  groupButtonStyles: object,
  withSearch: bool,
  checkProjectRole: bool,
};

export default WrapperAvatarsGroupForProjectWithHook;

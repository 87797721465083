import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import Icon from '@mdi/react';
import { mdiAlertOutline, mdiCheckboxMarkedCircleOutline } from '@mdi/js';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import { getTodayDaily } from '../userFlow/store/selectors/dailySectionSelectors';
import { dateFormat1, dateFormat22, dateFormat23, dateFormat2 } from "../54origins/dateFormats54origins";
import { getListAndReadEntities } from "../entity/actions/entityActions";
import { getPartitionDeliveryUUID } from "../config/selectors/selectors";
import BaseButton from "./_ui/BaseButton/BaseButton";
import { getMe } from "../projectFlow/selectors/selectors";
import { gptReportsUrl, partitionNamesConfig } from "../api/appConfig";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

function ReportInfoMessage() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const todayDaily = useSelector(getTodayDaily);
  const reports54Partition = useSelector(getPartitionDeliveryUUID);
  const getMeUUID = useSelector(getMe);

  const [localDailyReport, changeLocalDailyReport] = useState(false);

  const initFunc = () => {
    dispatch(
      getListAndReadEntities({
        data: {
          entity_type: 'monthlyReport',
          parent: reports54Partition,
          depth: 0,
          actor: getMeUUID,
          params: {
            date: dateFormat23()
          }
        },
        partition: partitionNamesConfig.partition5
      })
    ).then((res) => {
      changeLocalDailyReport(!!get(res.data, '[0].params.days', []).find((item) => get(item, 'rangeDates[0]') === dateFormat2()))
    })
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // console.log('Пользователь ушел из вкладки');
      } else {
        initFunc()
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <Tooltip title={localDailyReport
      ? `${t('wms.labels.daily_report_submitted', 'Daily report submitted')}`
      : `${t('wms.labels.daily_report_no_send', 'Your daily report not sent, go to REPORTS54')}`}>
      <Link
        to={gptReportsUrl}
        className={`hidden xl:flex header-dropdown-wrapper ${localDailyReport ? 'text-green-500' : 'text-red-500'} max-w-52 gap-1.5`}
        target={'_blank'}
      >
        <Icon
          size={1}
          path={localDailyReport ? mdiCheckboxMarkedCircleOutline : mdiAlertOutline}
          style={{
            flex: "1 0 auto",
          }}
        />

        <p className="text-ellipsis">
        {localDailyReport
          ? `${t('wms.labels.daily_report_submitted', 'Daily report submitted')}`
          : `${t('wms.labels.daily_report_no_send', 'Your daily report not sent, go to REPORTS54')}`}
        </p>
      </Link>
    </Tooltip>
  );

  // return <BaseButton onClick={initFunc}>start</BaseButton>
}

export default ReportInfoMessage;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fetching: false,
  defaultGroups: [],
};

const getDefaultGroupsSlicer = createSlice({
  name: 'ModalGetAuth54',
  initialState,
  reducers: {
    GET_DEFAULT_GROUPS_REQUEST(state) {
      state.fetching = true;
    },
    GET_DEFAULT_GROUPS_SUCCESS(state, action) {
      state.fetching = false;
      state.defaultGroups = action.payload;
    },
    GET_DEFAULT_GROUPS_FAILURE(state) {
      state.fetching = false;
    },
  },
});

export const {
  GET_DEFAULT_GROUPS_REQUEST,
  GET_DEFAULT_GROUPS_SUCCESS,
  GET_DEFAULT_GROUPS_FAILURE,
} = getDefaultGroupsSlicer.actions;

export default getDefaultGroupsSlicer.reducer;

const getGroupUUID = (state, name) => state.Auth.defaultGroupsSlice.defaultGroups?.filter((item) => item?.uinfo?.group_name === name)[0]?.uuid;
const getGroup = (state, name) => state.Auth.defaultGroupsSlice.defaultGroups?.filter((item) => item?.uinfo?.group_name === name)[0];

export const getBanGroupUUID = (state) => getGroupUUID(state, 'BAN');

export const getAdminGroupUUID = (state) => getGroupUUID(state, 'ADMIN');

export const getDefaultGroupUUID = (state) => getGroupUUID(state, 'DEFAULT');
export const getDefaultGroup = (state) => getGroup(state, 'DEFAULT');

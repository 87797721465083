import React, {
  useEffect, useState, useCallback,
} from 'react';
import { string, number, object, func, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get, isEmpty, isEqual, uniq } from 'lodash';

import {
  Flex,
  Tooltip,
} from 'antd';
import Icon from '@mdi/react';
import { mdiTimerOutline, mdiAccountGroup, mdiBullseyeArrow } from '@mdi/js';

import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import CreateProjectMilestoneBtn from '../docView&ideas/CreateProjectMilestoneBtn';
import CustomAvatar from "../../../components/CustomAvatar/CustomAvatar";
import DateLeft from '../../../components/DateLeft';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import MilestoneProgressBar from './MilestoneProgressBar';
import WrapperAvatarsGroupForProject from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';

import { useResize } from '../../../hooks/useResize';
import useActorsW54 from '../../../actors/hooks/useActorsW54';
import useMouseMiddleRedirect from '../../../hooks/useMouseMiddleRedirect';

import { getProjectUUID } from '../../selectors/selectors';
import { getProjectMilestones, getProjectMilestonesFetching, getTotalMilestones } from '../../reducers/slicers/projectMilestonesSlicer';

import { renderTableID } from '../../../MainUtils';
import { capitalize } from 'lodash';
import { getNameForActor } from '../../../54origins/utils54origins';
import { computeMilestoneScore, renderMilestoneScore } from './helpers/computeScore';

import './Milestone.scss';
import { importanceWeight } from '../issuesView/utils';

function ListOfMilestones({
  className,
  partition,
  totalCount,
  selectedMilestone,
  defaultOrderConfig,
  defaultPaginationConfig,
  filterColumnsConfig,
  filterConfig,
  sizePerPageList = [10, 25, 50, 100],
  getConfigAndMakeRequest,
  createProjectMilestoneCallback,
  filterCallback,
  selectRowCallback,
}) {
  const { t } = useTranslation();

  const { width } = useResize();
  const { onMouseMiddleClick } = useMouseMiddleRedirect();

  const projectUUID = useSelector(getProjectUUID);
  const projectMilestones = useSelector(getProjectMilestones);
  const projectMilestonesFetching = useSelector(getProjectMilestonesFetching);
  const totalMilestones = useSelector(getTotalMilestones);

  const importanceWeight = (importance) => {
    switch (importance) {
      case "minor":
      return 1;
      case "medium":
      return 2;
      case "significant":
      return 3;
      case "critical":
      return 4;
      default:
      return 0;
    }
  }

  // Получение milestone пользователей
  const userUUIDs = projectMilestones.flatMap((item) => item?.responsible);
  useActorsW54({ actors: uniq(userUUIDs) });

  const [config, setConfig] = useState({
    ...defaultOrderConfig,
    ...defaultPaginationConfig,
    ...filterConfig,
    search: '',
  });
  const [isConfigChanged, setIsConfigChanged] = useState(true);

  const changeConfig = (newConfig) => {
    const updatedConfig = {
      ...config,
      ...newConfig,
    }

    if (!isEqual(config, updatedConfig)) {
      setConfig(updatedConfig);
      setIsConfigChanged(true);
    }
  }

  const {
    currentPage,
    limit,
  } = config || {};

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    style: { background: '#e5f4fc' },
    selected: [selectedMilestone],
  };

  const currentSortCallback = (dataSort) => {
    // console.log('dataSort', dataSort);
    // const orderBy = get(dataSort, 'orderBy');
    const order = get(dataSort, 'order');

    if (!order) changeConfig(defaultOrderConfig);
    else changeConfig(dataSort);
  };

  const changePageLimit = (size) => {
    changeConfig({ currentPage: 1, limit: size, offset: 0 });
  };

  const onSearchData = (value) => {
    changeConfig({
      currentPage: 1,
      offset: 0,
      limit,
      search: value,
    });
  };

  const userFormatter = (cell, row) => {
    const fullName = getNameForActor(row);
    return (
      <div className="flex flex-wrap items-center">
        <CustomAvatar
          fullName={fullName}
        />
      </div>
    );
  };

  const setSelectDoc = (row) => {
    const { uuid } = row || {};
    selectRowCallback(uuid);
  };

  const lifeTimeFormatter = (cell) => cell && <DateLeft minus date={cell} type="tag" customStyle="text-neutral-600" />;

  const renderScore = useCallback((cell, row) => {
    const {
      status, importance, startDate, lifeTime,
    } = row;

    const score = computeMilestoneScore({
      status,
      importance,
      startDate,
      endDate: lifeTime,
    });

    return renderMilestoneScore(score);
  }, [computeMilestoneScore]);

  const rowClassName = (row) => `universal_table_row ${get(row, 'uuid') === selectedMilestone ? 'selected' : ''}`;

  const onRow = (record) => ({
    onClick: () => setSelectDoc(record),
    onMouseDown: (e) => {
      const searchParams = { activeProject: projectUUID, milestone: record?.uuid };
      onMouseMiddleClick(e, searchParams);
    },
  });

  const columns = [
    {
      dataIndex: 'id',
      title: 'ID',
      key: 'id',
      sorter: true,
      render: (cell) => cell && renderTableID(cell, 'M'),
    },
    {
      dataIndex: 'title',
      sorter: true,
      key: 'title',
      title: <AntDesignSearchBox54origins onSearch={onSearchData} />,
      render: (cell, row) => {
        const { status, startDate, lifeTime } = row;

        return (
          <div className="inline-flex flex-col">
            {cell && (
              <EntityParamTag
                param="title"
                value={cell}
                type="value"
                maxLength={width > 1500 ? 0 : 45}
              />
            )}
            {status === 'in progress' && (
              <MilestoneProgressBar
                size={width > 1599 ? 'default' : 8}
                startDate={startDate}
                endDate={lifeTime}
              />
            )}
          </div>
        );
      },
    },
    {
      dataIndex: 'score',
      key: 'score',
      title: (
        <Tooltip title={capitalize(t('wms.labels.score', 'score'))}>
          <Icon path={mdiBullseyeArrow} size={0.9} />
        </Tooltip>
      ),
      render: renderScore,
    },
    {
      dataIndex: 'importance',
      key: 'importance',
      title: capitalize(t('wms.table.headers.importance', 'importance')),
      sorter: (row1, row2) => importanceWeight(row1?.importance) - importanceWeight(row2?.importance),
      ...(filterColumnsConfig && { ...filterColumnsConfig?.importance }),
      ...(filterConfig?.importance && { defaultFilteredValue: filterConfig?.importance }),
      render: (cell) => cell && (
        <EntityParamTag
          value={cell}
          param="importance"
          type={width < 1500 ? 'char' : 'charNoCut'}
        />
      ),
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: capitalize(t('wms.table.headers.status', 'status')),
      // sorter: (a, b, c) => setSortSettings({ sortValue: c, sortType: 'entity_type' }),
      sorter: true,
      ...(filterColumnsConfig && { ...filterColumnsConfig?.status }),
      ...(filterConfig?.status && { defaultFilteredValue: filterConfig?.status }),
      render: (cell) => cell && (
        <EntityParamTag
          value={cell}
          param="status"
          type={width < 1500 ? 'icon' : 'iconWithStatus'}
        />
      ),
    },
    {
      dataIndex: 'uinfo',
      // key: 'uinfo',
      title: capitalize(t('wms.table.headers.owner', 'owner')),
      key: 'owner',
      render: userFormatter,
    },
    {
      dataIndex: 'responsible',
      key: 'responsible',
      title: (
        <Tooltip title={capitalize(t('wms.adjective.responsible', 'responsible'))}>
          <Icon path={mdiAccountGroup} size={1} />
        </Tooltip>
      ),
      // sorter: true,
      render: (cell) => cell?.length > 0 && (
        <WrapperAvatarsGroupForProject
          maxCount={width > 1499 ? 5 : 3}
          actors={cell}
          avatarSize="small"
          avatarGap={4}
          avatarStyles={{ cursor: 'pointer' }}
          groupButtonSize="small"
          checkProjectRole
        />
      ),
    },
    {
      dataIndex: 'lifeTime',
      key: 'lifeTime',
      sorter: true,
      title: (
        <Tooltip title={capitalize(t('wms.adjective.days_left', 'days left'))}>
          <Icon path={mdiTimerOutline} size={0.9} />
        </Tooltip>
      ),
      render: (cell, row) => {
        const { status } = row;
        if (status !== 'in progress') return;


        return lifeTimeFormatter(cell, row);
      },
    },
  ].filter((el) => !el.hidden);

  useEffect(() => {
    // console.log('effect 1 milestone');
    if (!isEmpty(filterConfig)) {
      changeConfig({
        ...filterConfig,
        currentPage: 1,
        offset: 0,
        limit,
      });
    }
  }, [filterConfig]);

  useEffect(() => {
    if (isConfigChanged) {
      // console.log('effect 2 milestone');
      getConfigAndMakeRequest?.(config);
      setIsConfigChanged(false);
    }
  }, [isConfigChanged]);

  return (
    <BaseCard className={`primaryCard ${className}`}>
      <Flex justify="space-between" align="center">
        <Flex align="center">
          <h6 className="mb-1 mr-2 ml-1">
            Sprint milestone
          </h6>
          <CreateProjectMilestoneBtn
            partition={partition}
            createProjectMilestoneCallback={createProjectMilestoneCallback}
          />
        </Flex>
      </Flex>
      <div className="collapsedTable-table">
        <BaseTableWithPagination
          headerRowClassName="universal_header_table_row"
          rowClassName={rowClassName}
          rowSelection={selectRow}
          columns={columns}
          data={projectMilestones}
          total={totalMilestones || totalCount}
          loading={projectMilestonesFetching}
          useCustomPagination
          paginationSize="small"
          newCurrentPage={currentPage}
          pageSizeOptions={sizePerPageList}
          pageLimit={limit}
          onRow={onRow}
          changePageLimit={changePageLimit}
          getPaginationOptions={changeConfig}
          currentSortCallback={currentSortCallback}
          filterCallback={filterCallback}
        />
      </div>
    </BaseCard>
  );
}

ListOfMilestones.propTypes = {
  className: string,
  partition: string,
  defaultOrderConfig: object,
  defaultPaginationConfig: object,
  selectedMilestone: string,
  totalCount: number,
  filterColumnsConfig: object,
  filterConfig: object,
  sizePerPageList: arrayOf(number),
  getConfigAndMakeRequest: func,
  createProjectMilestoneCallback: func,
  filterCallback: func,
  selectRowCallback: func,
};

export default ListOfMilestones;

import {
  string, oneOf, bool,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { capitalize, isEmpty } from 'lodash';

import { getUserName } from '../../../../entity/selectors/selectors';
import { updateDocumentPartiallyRequest } from '../../../actions/DocumentsActions';
import { sendSystemComment } from '../../../actions/projectFlowActions';

import { antNotification, camelCaseToFlatString } from '../../../../MainUtils';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { partitionNamesConfig } from '../../../../api/appConfig';

const defaultConstants = [
  ProjectTypeCommonConstants.UPDATE_DOCUMENT_REQUEST,
  ProjectTypeCommonConstants.UPDATE_DOCUMENT_SUCCESS,
  ProjectTypeCommonConstants.UPDATE_DOCUMENT_FAILURE,
];

const noReduxConstants = [
  'UPDATE_DOCUMENT_NO_REDUX_REQUEST',
  'UPDATE_DOCUMENT_NO_REDUX_SUCCESS',
  'UPDATE_DOCUMENT_NO_REDUX_FAILURE',
];

function useUpdateDocumentParameter({
  entityUUID,
  entityType,
  commentType,
  partition,
  noReduxRequest = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userFullName = useSelector(getUserName);

  const makeComment = async (params) => {
    if (!params || isEmpty(params)) return false;

    try {
      await Promise.all(
        Object.entries(params).reduce((result, item) => {
          // eslint-disable-next-line no-shadow
          const [title, value] = item;
          const param = camelCaseToFlatString(title);

          const comment = {
            entity: entityType,
            action: 'changed',
            param,
            value,
          };

          return [
            ...result,
            dispatch(sendSystemComment(entityUUID, comment, partition, param)),
          ];
        }, []),
      );
    } catch (err) {
      console.error(`Failed to send system comments for ${entityType}: ${err.message}`);
    }
  };

  const updateDocumentComments = async (newCommentsCount) => {
    const config = {
      entity_uuid: entityUUID,
      entity_type: entityType,
      params: { [commentType]: newCommentsCount },
    };

    try {
      await dispatch(
        updateDocumentPartiallyRequest(
          config,
          partition,
          noReduxRequest ? noReduxConstants : defaultConstants,
        ),
      );
    } catch (err) {
      console.error(`Failed to update comments count for ${entityType}: ${err.message}`);
    }
  };

  const updateDocumentParameter = async (params, callback) => {
    if (!params || isEmpty(params)) return;

    const config = {
      entity_uuid: entityUUID,
      entity_type: entityType,
      params: {
        ...params,
        modifiedBy: userFullName ?? '',
      },
    };

    try {
      const response = await dispatch(
        updateDocumentPartiallyRequest(
          config,
          partition,
          noReduxRequest ? noReduxConstants : defaultConstants,
        ),
      );

      await makeComment(params);

      const initialComments = response[0]?.params?.[commentType];
      if (initialComments) {
        const newComments = initialComments + Object.keys(params).length;
        await updateDocumentComments(newComments);
      }

      antNotification?.('success', capitalize(t('wms.noun.success', 'Success')));
    } catch (err) {
      console.error(`Failed to update document parameters: ${err.message}`);
    } finally {
      callback?.();
    }
  };

  return { updateDocumentParameter };
}

useUpdateDocumentParameter.propTypes = {
  entityUUID: string,
  entityType: string,
  commentType: string,
  partition: oneOf([partitionNamesConfig.partition1, partitionNamesConfig.partition3]),
  noReduxRequest: bool,
};

export default useUpdateDocumentParameter;

import React from 'react';
import { ProjectTypeCommonConstants } from '../constants/Constants';

// export const ContextApp = React.createContext();

export const initialState = {
  fetching: false,
  mainFetching: true,
  vectors: [],
  tempVectors: [],
  error: '',
  releases: [],
  releaseFetching: true,
  uuidsFromMultiEdit: [],
  flagFromMultiEdit: false,
  allProjectVectors: [],
  scoringData: {
    total: 0,
    avgVal: '-',
    sum: 0,
    background: '',
  },
};

const vectorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ProjectTypeCommonConstants.GET_PROJECT_RELEASES_REQUEST:
      return {
        ...state,
        releaseFetching: true,
      };
    case ProjectTypeCommonConstants.GET_PROJECT_RELEASES_SUCCESS:
      return {
        ...state,
        releaseFetching: false,
        releases: payload,
      };
    case ProjectTypeCommonConstants.GET_PROJECT_RELEASES_FAILURE:
      return {
        ...state,
        releaseFetching: false,
        releases: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.SET_UUIDS_FOR_MULTI_EDIT_VECTORS:
      return {
        ...state,
        uuidsFromMultiEdit: payload,
      };
    case ProjectTypeCommonConstants.SET_FLAG_FOR_MULTI_EDIT_VECTORS:
      return {
        ...state,
        flagFromMultiEdit: payload,
      };
    case ProjectTypeCommonConstants.GET_PROJECT_VECTORS_REQUEST:
      return {
        ...state,
        fetching: state.vectors.length === 0,
        mainFetching: true,
      };
    case ProjectTypeCommonConstants.GET_TEMP_PROJECT_VECTORS_REQUEST:
      return {
        ...state,
        fetching: state.tempVectors.length === 0,
        mainFetching: false,
      };
    case ProjectTypeCommonConstants.GET_PROJECT_VECTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        mainFetching: false,
        vectors: payload,
      };
    case ProjectTypeCommonConstants.GET_TEMP_PROJECT_VECTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        mainFetching: false,
        tempVectors: payload,
      };
    case ProjectTypeCommonConstants.GET_PROJECT_VECTORS_FAILURE:
      return {
        ...state,
        fetching: false,
        mainFetching: false,
        vectors: [],
        error: payload,
      };
    case ProjectTypeCommonConstants.GET_TEMP_PROJECT_VECTORS_FAILURE:
      return {
        ...state,
        fetching: false,
        mainFetching: false,
        tempVectors: [],
      };

    case ProjectTypeCommonConstants.NEW_VECTOR_REQUEST:
      return {
        ...state,
        mainFetching: false,
        fetching: true,
      };
    case ProjectTypeCommonConstants.NEW_VECTOR_SUCCESS:
      return {
        ...state,
        fetching: false,
        mainFetching: false,
        vectors: state.vectors,
      };
    case ProjectTypeCommonConstants.NEW_VECTOR_FAILURE:
      return {
        ...state,
        fetching: false,
        mainFetching: true,
        vectors: [],
        error: payload,
      };
    case ProjectTypeCommonConstants.CLEAR_LIST_OF_VECTORS:
      return {
        ...state,
        fetching: false,
        mainFetching: true,
        vectors: [],
        error: '',
      };

    case ProjectTypeCommonConstants.GET_ALL_PROJECT_VECTORS_REQUEST:
      return {
        ...state,
        // fetching: true,
      };

    case ProjectTypeCommonConstants.GET_ALL_PROJECT_VECTORS_SUCCESS:
      return {
        ...state,
        // fetching: false,
        allProjectVectors: payload,
      };

    case ProjectTypeCommonConstants.GET_ALL_PROJECT_VECTORS_FAILURE:
      return {
        ...state,
        // fetching: false,
        allProjectVectors: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.UPDATE_VECTORS_PRIORITY_INDEXES_DATA:
      // console.log('UPDATE_VECTORS_PRIORITY_INDEXES_DATA', payload);
      const {
        total, avgVal, sum, background,
      } = payload;
      return {
        ...state,
        allProjectVectors: [],
        scoringData: {
          total,
          avgVal,
          sum,
          background,
        },
      };
    case ProjectTypeCommonConstants.CLEAR_VECTORS_SCORING_DATA:
      // console.log('CLEAR_VECTORS_SCORING_DATA');
      return {
        ...state,
        allProjectVectors: [],
        scoringData: {
          total: 0,
          avgVal: '-',
          sum: 0,
          background: '',
        },
      };

    default: return state;
  }
};

export default vectorReducer;

/* eslint-disable import/prefer-default-export */
import {
  mdiBellOutline,
  mdiProgressClock,
  mdiProgressCheck,
  mdiCloseThick,
  mdiCheckBold,
  mdiPencil,
} from '@mdi/js';

export const milestoneStatusOptions = [
  {
    value: 'created',
    label: 'wms.status.created',
    className: 'radio-lightBlue',
    icon: mdiBellOutline,
  },
  {
    value: 'in progress',
    label: 'wms.status.in progress',
    className: 'radio-darkBlue',
    icon: mdiProgressClock,
  },
  {
    value: 'partially completed',
    label: 'wms.status.partially_completed',
    className: 'radio-orange',
    icon: mdiProgressCheck,
  },
  {
    value: 'completed',
    label: 'wms.status.completed',
    className: 'radio-green',
    icon: mdiCheckBold,
  },
  {
    value: 'failed',
    label: 'wms.status.failed',
    className: 'radio-red',
    icon: mdiCloseThick,
  },
  {
    value: 'draft',
    label: 'wms.status.draft',
    className: 'radio-gray',
    icon: mdiPencil,
  },
];

export const milestoneImportanceOptions = [
  {
    value: 'minor',
    label: 'wms.importance.minor',
    className: 'radio-lightBlue',
  },
  {
    value: 'medium',
    label: 'wms.importance.medium',
    className: 'radio-orange',
  },
  {
    value: 'significant',
    label: 'wms.importance.significant',
    className: 'radio-red',
  },
  {
    value: 'critical',
    label: 'wms.importance.critical',
    className: 'radio-violet',
  },
];

import React, {
  useState, useContext, useMemo, createContext,
} from 'react';
import { string, bool } from 'prop-types';

import ExpandModeLayoutCard from './ExpandModeLayoutCard';
import ExpandModeLayoutTable from './ExpandModeLayoutTable';
import ExpandModeLayoutActions from './ExpandModeLayoutActions';

import './ExpandModeLayout.scss';

export const EXPAND_MODE = {
  BASE: 'BASE',
  WIDE: 'WIDE',
  VERTICAL: 'VERTICAL',
  FULLSCREEN: 'FULLSCREEN',
};

const ExpandModeContext = createContext(undefined);

export function useExpandModeContext() {
  const context = useContext(ExpandModeContext);
  if (!context) throw new Error('ExpandModeLayout.* component must be rendered as child of ExpandModeLayout component');
  return context;
}

const initialExpandedMode = (target, isDisabled) => {
  if (isDisabled) return EXPAND_MODE.BASE;
  if (!target) {
    console.error('No local storage target for ExpandModeLayout!');
    return EXPAND_MODE.BASE;
  }

  const localStorageValue = localStorage.getItem(`${target}ExpandMode`);
  return localStorageValue || EXPAND_MODE.BASE;
};

function ExpandModeLayout({
  localStorageTarget,
  isDisabled = false,
  children,
}) {
  const [expandedMode, setExpandedMode] = useState(
    initialExpandedMode(localStorageTarget, isDisabled),
  );
  const [showMetaInfo, setShowMetaInfo] = useState(true);

  const memoizedContext = useMemo(() => ({
    localStorageTarget,
    isDisabled,
    expandedMode,
    setExpandedMode,
    showMetaInfo,
    setShowMetaInfo,
    isBaseMode: expandedMode === EXPAND_MODE.BASE,
    isWideMode: expandedMode === EXPAND_MODE.WIDE,
    isVerticalMode: expandedMode === EXPAND_MODE.VERTICAL,
    isFullscreenMode: expandedMode === EXPAND_MODE.FULLSCREEN,
  }), [
    localStorageTarget,
    expandedMode,
    // setExpandedMode,
    showMetaInfo,
    // setShowMetaInfo,
  ]);

  return (
    <ExpandModeContext.Provider value={memoizedContext}>
      {children}
    </ExpandModeContext.Provider>
  );
}

ExpandModeLayout.propTypes = {
  localStorageTarget: string,
  isDisabled: bool,
  // parentType: string,
};

ExpandModeLayout.Card = ExpandModeLayoutCard;
ExpandModeLayout.Table = ExpandModeLayoutTable;
ExpandModeLayout.Actions = ExpandModeLayoutActions;

export default ExpandModeLayout;

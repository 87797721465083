import React from 'react';
import { useSelector } from 'react-redux';
import { bool } from 'prop-types';

import WrapperPublicProject from '../WrapperPublicProject';

import { getUserPublicProfileUUID, getOtherUserPublicProfileActor } from '../../store/selectors/selectors';

function PersonalProjectsCardRoot({
  otherUser,
  hideAddProjectBtn,
  doNotMakeProjectRequest,
}) {
  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);
  const otherUserPublicProfileActor = useSelector(getOtherUserPublicProfileActor);

  return (
    <div>
      <WrapperPublicProject
        doNotMakeProjectRequest={doNotMakeProjectRequest}
        hideAddProjectBtn={hideAddProjectBtn}
        otherUser={otherUser}
        initialPathForHistory={otherUser
          ? `/admin/dashboard/public/user/${otherUserPublicProfileActor}/projects/`
          : `/public/user/${myPublicEntityUUID}/projects/`}
      />
    </div>
  );
}

PersonalProjectsCardRoot.propTypes = {
  otherUser: bool,
};

export default PersonalProjectsCardRoot;

import React, {
  useLayoutEffect, useRef, useState,
} from 'react';
import Icon from '@mdi/react';
import {
  mdiArrowLeftDropCircleOutline, mdiFullscreen, mdiFullscreenExit, mdiRestart, mdiSquareEditOutline,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { Button, Pagination, Spin } from 'antd';
import {
  get, isEmpty, isNil, uniq,
} from 'lodash';
import cn from 'classnames';

import './multiEdit.scss';

import { useDispatch, useSelector } from 'react-redux';
import {
  array, func, number, string,
} from 'prop-types';
import moment from 'moment';
import {
  columnsMultiEditIssue, defaultProjectTags, estimatedTimeKeys,
  indexesSheet,
  issueStatusKeys,
  prepareActors,
  prepereUsersForRequest,
  priorityKeys,
  trackerKeys,
  entityScoringMultiUpdate,
} from './utils';
import { antNotification, getUuidsfromParamsUsers } from '../../../MainUtils';
import { capitalize } from 'lodash';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import JSpreadsheet from '../../../../../JSpreadsheet/JSpreadsheet';
import { entityUpdate } from '../../../entity/actions/entityActions';
import { getProjectParams, getProjectUUID } from '../../selectors/selectors';
import { getMinDateExpired } from '../issues/issueInfo/utils';
import { getIssuesScoringData } from '../../reducers/issues/IssuesSlicer';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { getProjectScoring, updateProjectScoring } from '../../actions/projectFlowActions';
import FullscreenButton from '../../../components/fullscreenButton/FullscreenButton';
import useActorsW54 from '../../../actors/hooks/useActorsW54';
import { fullName } from '../../../assignUsersModal/utils';

export default function MultiEditIssueView({
  currentPage,
  totalCount,
  defaultData,
  pageLimit,
  partitionType,
  backIssueCallback,
  getIssuesRequestSetting,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeProjectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);
  const issuesScoringData = useSelector(getIssuesScoringData);
  const { tags } = projectParams;

  const rowsIsEdited = useRef(new Map([]));

  const [isFullscreen, setFullscreen] = useState(false);

  const [dataSheet, setDataSheet] = useState([]);
  const [columns, setColumns] = useState([]);
  const [styleCells, setStyleCells] = useState({});

  const [loading, setLoading] = useState(false);

  const { users: allActors } = useActorsW54({ actors: projectParams?.usersSearch });

  const tagsKeys = [...defaultProjectTags, ...tags ?? []];

  const onChangePage = (page) => {
    const offset = pageLimit * (page - 1);
    getIssuesRequestSetting?.({
      pageLimit,
      currentPage: page,
      offset,
    });
  };

  const prepareData = () => {
    setDataSheet(defaultData.map(({
      id, title, description, result, priority, tracker, status, usersSearch, lifeTime, uuid, estimated_time, tags,
    }) => ({
      id,
      title,
      description,
      result,
      priority,
      tracker,
      status,
      users: usersSearch,
      lifeTime,
      uuid,
      estimated_time,
      tags: tags || [],
    })));
  };

  const nameColumn = {
    1: 'title',
    2: 'tags',
    3: 'description',
    4: 'result',
    5: 'priority',
    6: 'tracker',
    7: 'status',
    8: 'users',
    9: 'lifeTime',
    10: 'estimated_time',
  };

  const getTime = (status) => {
    switch (status) {
      case 'closed':
      case 'deployed':
      case 'abandoned':
        return getMinDateExpired();
      case 'backlog':
        return getMinDateExpired(1005);
      default: return moment().add(2, 'week').format('YYYY-MM-DD');
    }
  };

  const onBeforeChangeCallback = (instance, x, y, value) => {
    // ======== VALIDATION DATA =========
    const row = instance.getJson()[y];
    const prevDataCell = row[nameColumn[x]];

    if (!row?.uuid) {
      return '';
    }

    if (nameColumn[x] === 'title') {
      return value?.slice(0, 128);
    }

    if (nameColumn[x] === 'tags') {
      const newTagsArr = value?.split(';');
      if (!newTagsArr?.every((tag) => tagsKeys.includes(tag))) return prevDataCell;
      return newTagsArr;
    }

    if (nameColumn[x] === 'priority' && !priorityKeys.includes(value)) {
      return prevDataCell;
    }

    if (nameColumn[x] === 'tracker' && !trackerKeys.includes(value)) {
      return prevDataCell;
    }

    if (nameColumn[x] === 'status' && !issueStatusKeys.includes(value)) {
      return prevDataCell;
    }

    if (nameColumn[x] === 'estimated_time' && !estimatedTimeKeys.includes(value)) {
      return prevDataCell;
    }

    if (nameColumn[x] === 'status' && issueStatusKeys.includes(value)) {
      // UPDATE LIFE TIME WHEN CHANGE STATUS
      instance.setValueFromCoords('8', y, getTime(value), true);
    }

    if (nameColumn[x] === 'users') {
      const usersUUIDS = typeof value === 'string' ? value.split(';') : value;
      return get(projectParams, 'usersSearch', []).some((i) => usersUUIDS.includes(i)) ? value : prevDataCell;
    }

    if (nameColumn[x] === 'lifeTime' && !moment(value, 'YYYY-MM-DD').isValid()) {
      return prevDataCell;
    }

    if (!nameColumn[x]) {
      return '';
    }

    return value;
  };

  const changeSheetCallback = (data, columnIndex, rowIndex) => {
    const newStyle = { [`${indexesSheet[columnIndex]}${+rowIndex + 1}`]: 'background-color: #ffe7ba;' };

    const currentChange = rowsIsEdited.current;

    if (currentChange.has(+rowIndex)) {
      const prevColumn = currentChange.get(+rowIndex);
      currentChange.set(+rowIndex, uniq([...prevColumn, +columnIndex]));
    } else {
      currentChange.set(+rowIndex, [+columnIndex]);
    }

    setStyleCells((prev) => ({ ...prev, ...newStyle }));
    setDataSheet(data);
  };

  const updateRequest = (data) => {
    const constants = [
      'MULTI_EDIT_ISSUE_UPDATE_REQUEST',
      'MULTI_EDIT_ISSUE_UPDATE_SUCCESS',
      'MULTI_EDIT_ISSUE_UPDATE_FAILURE',
    ];

    const options = {
      partition: partitionType,
    };

    return dispatch(entityUpdate({
      data,
      constants,
      options,
    }));
  };

  const getDataForParams = (row, indexRow) => {
    const columsNeedUpdate = rowsIsEdited.current.get(indexRow);
    const data = {};

    columsNeedUpdate.forEach((idx) => {
      switch (idx) {
        case 1: data.title = row?.title; break;
        case 2: data.tags = row?.tags || []; break;
        case 3: data.description = row?.description; break;
        case 4: data.result = row?.result; break;
        case 5: data.priority = row?.priority; break;
        case 6: data.tracker = row?.tracker; break;
        case 7: data.status = row?.status; break;
        case 8: {
          const { usersData, usersSearch } = prepereUsersForRequest(row?.users);
          data.users = usersData;
          data.usersSearch = usersSearch;
        }
          break;
        case 9: data.lifeTime = row?.lifeTime; break;
        case 10: data.estimated_time = row?.estimated_time; break;
        default: break;
      }
    });

    return data;
  };

  const updateProject = async (props) => {
    const { avgVal: issuesScoringAVG, sum: indexesSum, total: totalCount } = props;
    const data = {
      uuid: activeProjectUUID,
      params: { scoringData: { issuesScoringAVG: Number(issuesScoringAVG), indexesSum, totalCount } },
      partition: partitionType,
    };
    // console.log('updateProjectScoring data', data);
    dispatch(updateProjectScoring(data))
      .then((resp) => {
        console.log('updateProjectScoring resp', resp);
      })
      .catch(() => antNotification('error', 'Access denied!'));
  };

  const updateIssuesScoring = (newData, defaultData) => {
    try {
      const oldEntities = defaultData.filter((defEntity) => newData?.find((newEntity) => newEntity?.uuid === defEntity?.uuid));
      // console.log('oldEntities', oldEntities);
      // console.log('projectParams', projectParams);
      // console.log('defaultData', defaultData);

      dispatch(getProjectScoring(
        activeProjectUUID,
        (resp) => {
          const oldScoringData = resp?.[0]?.params?.scoringData;
          // console.log('oldScoringData', oldScoringData);
          const data = {
            actionType: 'update',
            sum: oldScoringData?.indexesSum || issuesScoringData?.sum,
            total: oldScoringData?.totalCount || issuesScoringData?.total,
          };
          const newScoringData = entityScoringMultiUpdate(oldEntities, newData, data);
          if (newScoringData) {
            dispatch({
              type: ProjectTypeCommonConstants.UPDATE_ISSUES_PRIORITY_INDEXES_DATA,
              payload: newScoringData,
            });
            // console.log('activeProject', activeProject);
            updateProject(newScoringData);
          }
        },
        partitionType,
      ));
    } catch (e) {
      console.log('e', e);
    }
  };

  const toggleSave = async () => {
    setLoading(true);
    try {
      const issuesForUpdate = [];
      dataSheet.forEach((item, index) => {
        if (rowsIsEdited.current.has(+index)) {
          const newParams = getDataForParams(item, index);
          const preparedBody = {
            entity_type: 'issue',
            entity_uuid: item?.uuid,
            params: newParams,
          };
          if (item?.uuid && !isEmpty(newParams)) {
            issuesForUpdate.push(updateRequest(preparedBody));
          }
        }
      });
      const results = await Promise.allSettled(issuesForUpdate);

      const isReject = results.find((result) => result.status === 'rejected');
      if (isReject) {
        antNotification('error', isReject.value);
      }
      // console.log('toggleSave defaultData', defaultData);
      // console.log('toggleSave issuesForUpdate', issuesForUpdate);
      // console.log('toggleSave results', results);
      const newEntitiesData = results.map((item) => item?.value[0]).map((item) => ({ uuid: item?.uuid, ...item?.params }));
      updateIssuesScoring(newEntitiesData, defaultData);
      rowsIsEdited.current = new Map([]);
      backIssueCallback();
    } catch (e) {
      antNotification('error', e);
    }
    setLoading(false);
  };

  const initFunc = () => {
    rowsIsEdited.current = new Map([]);
    setStyleCells({});

    if (!isEmpty(defaultData)) {
      prepareData();
    } else {
      setDataSheet([]);
    }
  };

  useLayoutEffect(() => {
    if (!isEmpty(allActors) && !isEmpty(projectParams)) {
      const actors = projectParams?.usersSearch?.map((uuid) => {
        const actor = allActors?.find((el) => el?.uuid === uuid);
        if (actor) {
          return {
            id: actor?.uuid,
            name: fullName(actor),
          };
        }
        return null;
      }).filter((el) => !isNil(el));

      setColumns(
        columnsMultiEditIssue.map((i) => {
          if (i?.name === 'users') {
            return {
              ...i,
              type: 'dropdown',
              source: actors,
            };
          }
          if (i?.name === 'tags' && tags?.length > 0) {
            return {
              ...i,
              source: [
                ...i?.source,
                ...tags?.map((tag) => ({ id: tag, name: capitalize(tag) })),
              ],
            };
          }
          return i;
        }),
      );
    }
  }, [columnsMultiEditIssue, allActors, projectParams?.usersSearch]);

  useLayoutEffect(() => {
    initFunc();
  }, [defaultData]);

  return (
    <div className="w-full  h-full relative">
      <hr />
      <Spin spinning={loading} className="w-full h-full">
        <div className={cn({ 'multi-edit-full-screen': isFullscreen })}>
          <div className={cn('flex items-center justify-between mb-2', { 'py-3': isFullscreen })}>
            <div className="flex items-center flex-wrap ">
              <BaseButton
                size="small"
                className="btnWarning-outline mr-1"
                onClick={backIssueCallback}
                type="link"
              >
                <div className="flex items-center justify-center ">
                  <Icon
                    className="mr-1"
                    path={mdiArrowLeftDropCircleOutline}
                    size={0.8}
                  />
                  {capitalize(t('wms.buttons.back', 'back'))}
                </div>
              </BaseButton>
              <span className="m-0 title-info-card mx-1">
                Multi edit issues:
              </span>
            </div>
            <div className="flex items-center">
              <FullscreenButton
                isFullscreen={isFullscreen}
                setFullscreen={setFullscreen}
              />

              <BaseButton
                size="small"
                className="btnWarning-outline mr-1 ml-1"
                onClick={initFunc}
                disabled={isEmpty(styleCells)}
                type="link"
              >
                <div className="flex items-center justify-center ">
                  <Icon
                    className="mr-1"
                    path={mdiRestart}
                    size={0.8}
                  />
                  {capitalize(t('wms.buttons.reset', 'reset'))}
                </div>
              </BaseButton>

              <BaseButton
                id="saveIssueBtn"
                size="small"
                onClick={toggleSave}
                type="primary"
                disabled={isEmpty(styleCells)}
              >
                <Icon path={mdiSquareEditOutline} size={0.7} />
                <span className="pl-1">
                  {capitalize(t('wms.verb.save', 'save'))}
                </span>
              </BaseButton>

            </div>

          </div>
          <div className="wrapper-sheet-multi-edit mb-2">
            {!isEmpty(columns) && (
              <JSpreadsheet
                disabledContextMenu
                columns={columns}
                className="spread-sheet-multi-edit"
                height="600px"
                defaultTextDescription={dataSheet}
                autoIncrement={false}
                changeDescriptionCallback={changeSheetCallback}
                isFullscreen={isFullscreen}
                styleCells={styleCells}
                onBeforeChangeCallback={onBeforeChangeCallback}
              />
            )}
          </div>

          <Pagination
            size="small"
            className="mt-1"
            pageSize={pageLimit}
            current={currentPage}
            total={totalCount}
            onChange={onChangePage}
          />
        </div>
      </Spin>
    </div>
  );
}
MultiEditIssueView.propTypes = {
  currentPage: number,
  totalCount: number,
  defaultData: array,
  pageLimit: number,
  partitionType: string,
  backIssueCallback: func,
  getIssuesRequestSetting: func,
};

import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  head,
  prop,
  isEmpty,
} from 'ramda';
import {
  Card, Col, Row,
} from 'antd';
import IStickyBox from 'react-sticky-box';
import { get } from 'lodash';
import cn from 'classnames';

import './css/issuesView.scss';

// import CardWithFilter from './CardWithFilter';
import CustomCardComponent from '../commonComponents/CustomCardComponent';
import IssueInfoRoot from '../issues/issueInfo/IssueInfoRoot';
import ListOfIssuesForIssueView from './ListOfIssuesForIssueView';
import ListOfVectorsForIssueView from './ListOfVectorsForIssueView';
import WrapperVectorInfoCardWithComments from '../vector/vectorInfo/WrapperVectorInfoCardWithComments';

import {
  globalUpdateEntity,
  getProject,
  getProjectUUID,
  getVectorUUID,
  globalUserFilterTags,
  getIssueEntityFetching,
  flagMultiEditVector,
  selectLastCreatedIssue,
  getActiveVectors,
} from '../../selectors/selectors';
import { getCurrentArchiveStateUUID } from '../../selectors/archiveStateSelectors';
import {
  clearIssue,
  clearRecurringIssue,
  clearVector,
} from '../../actions/PtcActionsForHook';
import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
import { antNotification } from '../../../MainUtils';
import { clearGlobalUpdateEntity } from '../../reducers/slicers/breadcrumbsSlicer';
import { flagMultiEditIssue } from '../../reducers/issues/IssuesSlicer';
import { myPerms } from '../../reducers/slicers/myProjectPermsSlicer';
import useURLParams from '../../../hooks/useURLParams';
import { globalDataNotSaved } from '../../../components/un-save-data-confirm/unSaveDataSlicer';
import useActivitiesDashboard from '../commonComponents/activities/useActivitiesDashboard';
import useTableFilter from '../../../components/_ui/hooks/useTableFilter';
import {
  defaultOrderRules,
  defaultIssuesPagination,
  defaultVectorsPagination,
  defaultNotSavedData,
} from './utils';
import { defaultIssueFilter } from './constants/issueFilterOptions';
import { defaultVectorFilter } from './constants/vectorFilterOptions';

import { ProjectTypeCommonConstants } from '../../constants/Constants';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import RecurringIssueInfoRoot from '../recurringIssue/recurringIssueInfo/RecurringIsueInfoRoot';
import useMyRecurringIssues from '../recurringIssue/hooks/useMyRecurringIssues';

const gutter = [16, 16];

function IssuesView({
  defaultPartition,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const abort = useRef();

  const { isGlobalDisabled } = useContext(ProjectMainLayerContext);
  const {
    // initialPathForHistory,
    getClearIssue,
    getClearListOfIssues,
    getClearVector,
    getClearVectors,
    getClearListOfCombinedIssues
  } = useContext(ProjectMainLayerContext);

  const { requestGetRecurringIssue } = useMyRecurringIssues(); 

  const {
    getURLParams,
    clearSearchParams,
    addAndRemoveSearchParams,
  } = useURLParams();

  const archiveStateUUID = useSelector(getCurrentArchiveStateUUID);

  const projectData = head(useSelector(getProject));
  const projectUUID = useSelector(getProjectUUID);
  const myProjectPerms = useSelector(myPerms);

  const globalUpdate = useSelector(globalUpdateEntity);
  const issueFetching = useSelector(getIssueEntityFetching);

  const lastCreatedIssue = useSelector(selectLastCreatedIssue);

  const multiEditIssue = useSelector(flagMultiEditIssue);
  const multiEditVector = useSelector(flagMultiEditVector);

  const isGlobalDataNotSaved = useSelector(globalDataNotSaved);

  const [notSavedData, setNotSavedData] = useState(defaultNotSavedData);

  const [hasRecurring, setHasRecurring] = useState(true);
  const [isRecurring, setIsRecurring] = useState(false);

  const {
    filterConfig: issueFilterConfig,
    changeFilterConfig: changeIssueFilterConfig,
  } = useTableFilter('issue', defaultIssueFilter);
  // console.log('defaultIssueFilter:', defaultIssueFilter);
  const {
    filterConfig: vectorFilterConfig,
    changeFilterConfig: changeVectorFilterConfig,
  } = useTableFilter('vector', defaultVectorFilter);
  // console.log('defaultVectorFilter:', defaultVectorFilter);

  const [vectorConfig, setVectorConfig] = useState({
    ...defaultOrderRules,
    ...defaultVectorsPagination,
    ...vectorFilterConfig,
  });
  // console.log('vectorConfig:', vectorConfig);
  const [issuesConfig, setIssuesConfig] = useState({
    ...defaultOrderRules,
    ...defaultIssuesPagination,
    ...issueFilterConfig,
  });
  // console.log('issuesConfig:', issuesConfig);

  const [customResetFilter, setCustomResetFilter] = useState(false);
  const [totalCountVector, setTotalCountVector] = useState(0);
  const [totalCountIssues, setTotalCountIssues] = useState(0);

  const [configIsChanged, setConfigIsChanged] = useState(null);

  const { isSidePanelOpen } = useActivitiesDashboard();

  const {
    entityType: globalEntityType,
    // uuid: globalUUID,
  } = globalUpdate || {};

  const {
    vectorSignal,
    listOfVectorsSignal,
    issueSignal,
    listOfIssuesSignal,
  } = abort?.current || {};

  const { currentPage: currentIssuesPage } = issuesConfig;
  const { currentPage: currentVectorsPage } = vectorConfig;

  const { activeIssue, activeVector, activeProject, activeRecurring } = getURLParams() || {};

  const isMultiEdit = multiEditIssue || multiEditVector;
  const isAdmin = useMemo(() => (Object.values(myProjectPerms)
    .every((i) => i === true)), [myProjectPerms]);

  const setIsNotSavedDataCallback = (bool) => {
    setNotSavedData({
      ...notSavedData,
      notSaved: bool,
    });
  };

  const finalProjectUUID = archiveStateUUID || activeProject || projectUUID;

  const changeAbortingState = (data) => {
    abort.current = {
      ...abort?.current || {},
      ...data,
    };
  };

  const getNewSignalAndChangeAbortingState = (paramFromState) => {
    const newController = new AbortController();

    const { signal } = newController;

    changeAbortingState({ [paramFromState]: newController });

    return signal;
  };

  const checkIfDataNotSaved = (func) => {
    if ((!notSavedData.notSaved || notSavedData.confirmNotSaved) && !isGlobalDataNotSaved) {
      func();
    }
  };

  const changeIssuesViewHistoryAndStore = (key, uuid) => {
    switch (key) {
      case 'issue':
        addAndRemoveSearchParams({ activeIssue: uuid }, ['activeRecurring']);
        checkIfDataNotSaved(() => setNotSavedData(defaultNotSavedData));
        break;
      case 'recurring':
        addAndRemoveSearchParams({ activeRecurring: uuid }, ['activeIssue']);
        checkIfDataNotSaved(() => setNotSavedData(defaultNotSavedData));
        break;
      case 'vector':
        addAndRemoveSearchParams({ activeVector: uuid }, ['activeIssue', 'activeRecurring']);
        checkIfDataNotSaved(() => setNotSavedData(defaultNotSavedData));
        break;
      case 'clearIssue':
        clearSearchParams(['activeIssue']);

        checkIfDataNotSaved(() => {
          setNotSavedData(defaultNotSavedData);
          dispatch(clearIssue);
          if (issueSignal) {
            issueSignal.abort();
          }
        });
        break;
      case 'clearRecurring':
        clearSearchParams(['activeRecurring']);

        checkIfDataNotSaved(() => {
          setNotSavedData(defaultNotSavedData);
          dispatch(clearRecurringIssue);
          if (issueSignal) {
            issueSignal.abort();
          }
        });
        break;
      case 'clearVector':
        clearSearchParams(['activeVector']);

        checkIfDataNotSaved(() => {
          setNotSavedData(defaultNotSavedData);
          dispatch(clearVector);
          if (vectorSignal) {
            vectorSignal.abort();
          }
        });
        break;
      case 'clearVectorAndIssue':
        clearSearchParams(['activeVector', 'activeIssue', 'activeRecurring']);

        checkIfDataNotSaved(() => {
          setNotSavedData(defaultNotSavedData);
          dispatch(clearVector);
          if (vectorSignal) {
            vectorSignal.abort();
          }
        });
        break;
      default:
        break;
    }
  };

  const restoreVectorActionCallback = async () => {
    const data = await getClearVectors({ ...vectorConfig, parent: archiveStateUUID });
    setTotalCountVector(prop('total', data));

    dispatch(clearIssue);
    clearSearchParams(['activeIssue', 'activeVector', 'activeRecurring']);
    setCustomResetFilter(!customResetFilter);
  };

  // not used
  // const getStatuses = (tags) => {
  //   const data = tags.reduce((prev, item) => {
  //     if (item.key === 'status') {
  //       // Excluded
  //       if ('ruleOfTag' in item && item.ruleOfTag === 'excluded' && item.value === 'lifeTime') {
  //         return { ...prev, lifeTime__gt: moment().format('YYYY-MM-DD') };
  //       }

  //       if ('ruleOfTag' in item && item.ruleOfTag === 'excluded') {
  //         return { ...prev, statusesExcluded: [...prev.statusesExcluded, item.value] };
  //       }

  //       // Included
  //       if (item.value === 'lifeTime') {
  //         return { ...prev, lifeTime__lt: moment().format('YYYY-MM-DD') };
  //       }
  //       return { ...prev, statusesIncluded: [...prev.statusesIncluded, item.value] };
  //     }
  //     return prev;
  //   }, {
  //     statusesExcluded: [],
  //     statusesIncluded: [],
  //     lifeTime__lt: '',
  //     lifeTime__gt: '',
  //   });
  //   return data;
  // };

  const getVectors = async () => {
    // console.log('getVectors');
    const {
      limit,
      offset,
      order,
      orderBy,
      search,
      usersSearch = [],
      type,
      priority,
      status,
    } = vectorConfig;

    if (listOfVectorsSignal) {
      listOfVectorsSignal.abort();
    }

    // const {
    //   statusesExcluded,
    //   statusesIncluded,
    //   lifeTime__lt,
    //   lifeTime__gt,
    // } = getStatuses(vectorsTags);

    const data = {
      parent: finalProjectUUID,
      limit,
      offset,
      order,
      params: {},
      signal: getNewSignalAndChangeAbortingState('listOfVectorsSignal'),
      [orderBy === 'created' ? 'order_by' : 'order_by_params']: orderBy,
    };

    if (!archiveStateUUID) {
      data.entity_type__not = ['archiveEntity'];
    }

    // if (lifeTime__lt) {
    //   data.params.lifeTime__lt = lifeTime__lt;
    // }

    // if (lifeTime__gt) {
    //   data.params.lifeTime__gt = lifeTime__gt;
    // }

    if (type && type?.length) {
      data.params.type = type;
    }

    if (priority && priority?.length) {
      data.params.priority = priority;
    }

    if (status && status?.length) {
      data.params.status = status;
    }

    if (usersSearch.length !== 0) {
      data.params.usersSearch = usersSearch;
    }

    if (search) {
      data.searchData = {
        value: search,
        ignore_case: true,
        fields: {
          params: ['title', 'id'],
        },
      };
    }

    // console.log('DATA VECTOR:', data);
    return getClearVectors(data).then((vectors) => {
      // if (!vectors?.data?.length) {
      //   setVectorConfig(defaultConfigVectors);
      //   getClearVectors({ defaultConfigVectors, parent: projectUUID }).then((startVectors) => {
      //     setTotalCountVector(prop('total', startVectors));
      //   });
      // } else {
      setTotalCountVector(prop('total', vectors));
      // }
    }).catch((err) => {
      console.log(err);
    });
  };

  const getIssues = async (uuid) => {
    const {
      limit,
      offset,
      order,
      orderBy,
      search,
      usersSearch = [],
      tracker,
      priority,
      estimated_time: estimatedTime,
      status
    } = issuesConfig || {};

    // console.log('!! issuesConfig:', issuesConfig);

    if (listOfIssuesSignal) {
      listOfIssuesSignal?.abort();
    }

    // const {
    //   statusesExcluded,
    //   statusesIncluded,
    //   lifeTime__lt,
    //   lifeTime__gt,
    // } = getStatuses(issuesTags);

    const data = {
      params: {},
      depth: 2,
      limit,
      offset,
      order,
      [orderBy === 'created' ? 'order_by' : 'order_by_params']: orderBy,
      parent: uuid || activeVector || finalProjectUUID,
      signal: getNewSignalAndChangeAbortingState('listOfIssuesSignal'),
    };

    if (!archiveStateUUID) {
      data.entity_type__not = ['archiveEntity'];
    }

    // if (lifeTime__lt) {
    //   data.params.lifeTime__lt = lifeTime__lt;
    // }

    // if (lifeTime__gt) {
    //   data.params.lifeTime__gt = lifeTime__gt;
    // }

    if (tracker && tracker?.length) {
      data.params.tracker = tracker;
    }

    if (priority && priority?.length) {
      data.params.priority = priority;
    }

    if (estimatedTime && estimatedTime?.length) {
      data.params.estimated_time = estimatedTime;
    }

    if (status && status?.length) {
      data.params.status = status;
    }

    if (usersSearch.length !== 0) {
      data.params.usersSearch = usersSearch;
    }

    if (search) {
      data.searchData = {
        value: search,
        ignore_case: true,
        fields: {
          params: ['title', 'id'],
        },
      };
    }

    // console.log('DATA ISSUE:', data);

    // return getClearListOfIssues({
    //   ...data,
    //   constants: [
    //     ProjectTypeCommonConstants.LIST_OF_ISSUES_REQUEST,
    //     ProjectTypeCommonConstants.LIST_OF_ISSUES_SUCCESS,
    //     ProjectTypeCommonConstants.LIST_OF_ISSUES_FAILURE,
    //   ],
    // }).then((res) => {
    //   setTotalCountIssues(res?.total || 0);
    // }).catch(() => { });

    return getClearListOfCombinedIssues({
      ...data,
      constants: [
        ProjectTypeCommonConstants.LIST_OF_ISSUES_REQUEST,
        ProjectTypeCommonConstants.LIST_OF_ISSUES_SUCCESS,
        ProjectTypeCommonConstants.LIST_OF_ISSUES_FAILURE,
      ],
    }).then((res) => {
      console.log(res);
      
      setTotalCountIssues(res?.total || 0);
    }).catch(() => { });
  };

  const onChangeIssue = (uuid) => {
    if (!issueFetching) {
      if (issueSignal) {
        issueSignal.abort();
      }
      
      changeIssuesViewHistoryAndStore('issue', uuid);

      checkIfDataNotSaved(async () => {
        await getClearIssue(uuid, getNewSignalAndChangeAbortingState('issueSignal'))
          .catch((error) => {
            if (error.message) {
              antNotification('error', error.message);
            }
            if (error?.message !== 'canceled') {
              changeIssuesViewHistoryAndStore('clearIssue');
            }
          });
      });
    }
  };

  const onChangeRecurringIssue = (uuid) => {
    if (!issueFetching) {
      // if (issueSignal) {
      //   issueSignal.abort();
      // }
      
      changeIssuesViewHistoryAndStore('recurring', uuid);

      checkIfDataNotSaved(async () => {
        await requestGetRecurringIssue(uuid, abort).catch((error) => {
          if (error.message) {
            antNotification('error', error.message);
          }
          if (error?.message !== 'canceled') {
            changeIssuesViewHistoryAndStore('clearRecurring');
          }
        });
      });
    }
  };

  const onChangeVector = (uuid, needChangeHistory = true) => {
    if (vectorSignal) {
      vectorSignal?.abort();
    }

    if (needChangeHistory) {
      changeIssuesViewHistoryAndStore('vector', uuid);
    }

    checkIfDataNotSaved(async () => {
      if (uuid) {
        getClearVector(uuid, getNewSignalAndChangeAbortingState('vectorSignal'))
          .catch(async (error) => {
            if (error.message) {
              antNotification('error', error.message);
            }
            if (error?.message !== 'canceled') {
              changeIssuesViewHistoryAndStore('clearVectorAndIssue');
              getIssues(finalProjectUUID);
            }
          });
        getIssues(uuid);
      }
    });
  };

  const newVectorSuccessCallback = async (data) => {
    const { uuid } = data || {};

    onChangeVector(uuid);
    getVectors();
  };

  const newIssueSuccessCallback = (uuid, res) => {
    if (res.entity_type == 'recurring') {
      onChangeRecurringIssue(uuid);
    } else {
      onChangeIssue(uuid);
    }

    getIssues();
  };

  const resetVectorCallback = async () => {
    changeIssuesViewHistoryAndStore('clearVectorAndIssue');

    getIssues(finalProjectUUID);
  };

  const deleteVectorCallback = async () => {
    await getVectors();
    resetVectorCallback();
  };

  const resetIssueCallback = () => {
    if(isRecurring) {
      changeIssuesViewHistoryAndStore('clearRecurring')
    } else {
      changeIssuesViewHistoryAndStore('clearIssue')
    }
  };

  const onSelectRowVectorCallback = (uuid) => {
    if (uuid === activeVector) {
      if (vectorSignal) {
        vectorSignal?.abort();
      }
      if (listOfIssuesSignal) {
        listOfIssuesSignal?.abort();
      }
      resetVectorCallback();
    } else {
      onChangeVector(uuid);
    }
  };

  const onSelectRowIssueCallback = (uuid, type = "issue") => {
    if (uuid === activeIssue || uuid === activeRecurring) {
      resetIssueCallback();
    } else {
      if (type === 'recurring') {
        onChangeRecurringIssue(uuid);
      } else {
        onChangeIssue(uuid);
      }
    }
  };

  const compareTwoConfigsAndSaveTypeOfChanges = (currentConfig, newConfig, typeOfChanges) => {
    const inputDataKeys = Object.keys(newConfig);

    // console.log('CHANGED current:', currentConfig);
    // console.log('CHANGED new:', newConfig);

    for (let i = 0; i < inputDataKeys.length; i++) {
      const currentKey = inputDataKeys[i];

      if (newConfig[currentKey] !== currentConfig[currentKey]) {
        // console.log('CHANGED:', currentKey);
        setConfigIsChanged(typeOfChanges);
        break;
      }
    }
  };

  const saveVectorsConfig = (data) => {
    setVectorConfig((prevState) => ({
      ...prevState,
      ...data,
    }));

    compareTwoConfigsAndSaveTypeOfChanges(vectorConfig, data, 'vector');
  };

  const saveIssuesConfig = (data) => {
    setIssuesConfig(
      (prevState) => ({
        ...prevState,
        ...data,
      }),
    );

    compareTwoConfigsAndSaveTypeOfChanges(issuesConfig, data, 'issue');
  };

  const initFunc = async () => {
    await getVectors();
    await getIssues(activeVector || finalProjectUUID);
    if (activeVector) onChangeVector(activeVector);
    if (activeIssue) onChangeIssue(activeIssue);
    if (activeRecurring) onChangeRecurringIssue(activeRecurring);

    // const vectorUuid = globalEntityType === 'vector' ? globalUUID : activeVector;
    // const issueUuid = globalEntityType === 'issue' ? globalUUID : activeIssue;

    // // TODO: fix routing from breadcrunmbs after remove
    // if (vectorUuid) {
    //   await onChangeVector(vectorUuid);
    // } else {
    //   await getIssues(finalProjectUUID);
    // }
    //
    // if (issueUuid) {
    //   await onChangeIssue(issueUuid);
    // }
  };

  const globalEntityUpdateFunc = async () => {
    switch (globalEntityType) {
      case 'vector':
        changeIssuesViewHistoryAndStore('clearVectorAndIssue');
        await getVectors();
        await getIssues(finalProjectUUID);
        break;
      case 'issue':
        changeIssuesViewHistoryAndStore('clearIssue');
        await getIssues(finalProjectUUID);
        break;
      default: break;
    }
    dispatch(clearGlobalUpdateEntity());
  };

  const updateIssueCallback = () => getIssues();

  const deleteIssueCallback = (selectedIssue) => {
    if (get(selectedIssue, 'entity_type') === 'recurring') {
      changeIssuesViewHistoryAndStore('clearRecurring');
    } else {
      changeIssuesViewHistoryAndStore('clearIssue');
    }
    getIssues();
  };

  const logicOfUnmount = () => {
    dispatch({
      type: ProjectTypeCommonConstants.SET_FLAG_FOR_MULTI_EDIT_VECTORS,
      payload: false,
    });

    dispatch({
      type: ProjectTypeCommonConstants.SET_FLAG_FOR_MULTI_SELECT,
      payload: false,
    });
    dispatch({ type: ProjectTypeCommonConstants.CLEAR_LIST_OF_ISSUES });
  };

  const hiddenInIssueInfo = useMemo(() => {
    if (isGlobalDisabled) {
      return ['linkToProject', 'goButton', 'edit', 'deleteIssueBtn', 'cloneBtn', 'editBtn', 'bookmarkBtn', 'subscribeBtn'];
    }
    return ['linkToProject', 'goButton'];
  }, [isGlobalDisabled]);

  const disabledInIssueInfo = useMemo(() => (isGlobalDisabled ? ['fullEdit'] : []), [isGlobalDisabled]);

  const hiddenInVectorInfo = useMemo(() => {
    const arr = ['goToBtn'];
    if (isGlobalDisabled) {
      arr.push('fullEdit');
    }
    if (+totalCountIssues === 0) {
      arr.push('dragTask');
    }
    return arr;
  }, [totalCountIssues, isGlobalDisabled]);

  const disabledInVectorInfo = useMemo(() => (isGlobalDisabled ? ['fullEdit'] : []), [isGlobalDisabled]);

  const hideInListVectors = useMemo(() => ['versionNumber', "hideVectors"], []);

  useEffect(() => {
    saveIssuesConfig({ currentPage: 1, offset: 0 });
  }, [issueFilterConfig]);

  useEffect(() => {
    saveVectorsConfig({ currentPage: 1, offset: 0 });
  }, [vectorFilterConfig]);

  useEffect(() => {
    if (configIsChanged === 'issue') getIssues();
    if (configIsChanged === 'vector') getVectors();
    setConfigIsChanged(null);
  }, [configIsChanged]);

  useEffect(() => {
    if (globalEntityType) {
      globalEntityUpdateFunc();
    }
  }, [globalEntityType]);

  useEffect(() => {
    if (!activeVector && !activeIssue && !activeRecurring) {
      if (!isEmpty(lastCreatedIssue)) {
        const lastCreatedIssueUUID = lastCreatedIssue.uuid;
        onChangeIssue(lastCreatedIssueUUID);
      }
    }
  }, [JSON.stringify(lastCreatedIssue)]);

  // TODO: rewrite logic if need it
  // useEffect(() => {
  //   if (archiveStateUUID || seeNotification || activeIssue) {
  //     initFunc();
  //   }
  // }, [archiveStateUUID, seeNotification, activeIssue]);

  useEffect(() => {
    initFunc();

    return () => logicOfUnmount();
  }, []);

  return (
    <Row gutter={gutter} wrap={false}>
      <Col span={isMultiEdit ? 14 : 14}>
        {projectData && projectData.length !== 0 && (
          <Row gutter={gutter}>
            <Col span={24}>
              <BaseCard className="w-100 padding-16 vector-card-issues-view">
                <ListOfVectorsForIssueView
                  typeOfVector="vector"
                  disabledMultiEdit={!isAdmin}
                  isGlobalDisabled={isGlobalDisabled}
                  hideElements={hideInListVectors}
                  newCurrentPage={currentVectorsPage}
                  countOfData={totalCountVector}
                  partitionType={defaultPartition}
                  filterConfig={vectorFilterConfig}
                  getVectorsRequest={getVectors}
                  getVector={onChangeVector}
                  getVectorsRequestSetting={saveVectorsConfig}
                  filterCallback={changeVectorFilterConfig}
                  onChangeRowCallback={onSelectRowVectorCallback}
                  newVectorSuccessCallback={newVectorSuccessCallback}
                />
              </BaseCard>
            </Col>
            <Col span={24}>
              <BaseCard className="w-100 padding-16 issue-card-issues-view">
                <ListOfIssuesForIssueView
                  disabledMultiEdit={!isAdmin}
                  isGlobalDisabled={isGlobalDisabled}
                  newCurrentPage={currentIssuesPage}
                  countOfData={totalCountIssues}
                  partitionType={defaultPartition}
                  getIssue={onChangeIssue}
                  getIssues={getIssues}
                  getIssuesRequestSetting={saveIssuesConfig}
                  filterConfig={issueFilterConfig}
                  filterCallback={changeIssueFilterConfig}
                  onChangeRowCallback={onSelectRowIssueCallback}
                  newIssueSuccessCallback={newIssueSuccessCallback}
                  hasRecurring={hasRecurring}
                />
              </BaseCard>
            </Col>
          </Row>
        )}
      </Col>
      <Col span={10}
      // className={cn({ 'hidden': isMultiEdit })}
      >
        {activeVector && !activeIssue && (
          <CustomCardComponent
            type="vector"
            style={{ minHeight: isSidePanelOpen ? '72vh' : 'unset' }}
          >
            <WrapperVectorInfoCardWithComments
              setIsNotSavedDataCallback={setIsNotSavedDataCallback}
              notSavedData={notSavedData}
              setNotSavedData={setNotSavedData}
              defaultTemplateState={getVectors}
              viewMode="vector"
              hiddenView={hiddenInVectorInfo}
              disabledView={disabledInVectorInfo}
              restoreActionCallback={restoreVectorActionCallback}
              deleteVectorCallback={deleteVectorCallback}
              resetVectorCallback={resetVectorCallback}
              dragIssueCallback={getIssues}
              partitionType={defaultPartition}
              parentType="vector"
            />
          </CustomCardComponent>
        )}

        {activeIssue && (
          <IStickyBox offsetTop={80} offsetBottom={80}>
            <CustomCardComponent type="issue">
              <IssueInfoRoot
                onChangeRowCallback={onSelectRowIssueCallback}
                hiddenView={hiddenInIssueInfo}
                disabledView={disabledInIssueInfo}
                partitionType={defaultPartition}
                resetIssueCallback={resetIssueCallback}
                deleteCallback={deleteIssueCallback}
                updateCallback={updateIssueCallback}
                setIsRecurring={setIsRecurring}
              />
            </CustomCardComponent>
          </IStickyBox>
        )}
        {activeRecurring && (
          <IStickyBox offsetTop={80} offsetBottom={80}>
            <CustomCardComponent type="issue">
              <RecurringIssueInfoRoot
                onChangeRowCallback={onSelectRowIssueCallback}
                hiddenView={hiddenInIssueInfo}
                disabledView={disabledInIssueInfo}
                partitionType={defaultPartition}
                resetIssueCallback={resetIssueCallback}
                deleteCallback={deleteIssueCallback}
                setIsRecurring={setIsRecurring}
                updateCallback={updateIssueCallback}
              />
            </CustomCardComponent>
          </IStickyBox>
        )}
      </Col>
    </Row>
  );
}

IssuesView.propTypes = {
  defaultPartition: string,
};

export default IssuesView;

import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { Tooltip } from 'antd';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

function DataGridActionButton({
  iconPath,
  onClick,
  className = 'btnWarning-outline',
  borderRadius = '0 0 0 0',
  hideBorder,
  toolTipText,
  disabled = false,
}) {
  const getButtonStyle = () => {
    switch (hideBorder) {
      case 'left':
        return {
          borderRadius,
          borderLeft: 'none',
        };
      case 'right':
        return {
          borderRadius,
          borderRight: 'none',
        };
      default:
        return {
          borderRadius,
        };
    }
  };

  const iconButton = (
    <BaseButton
      onClick={onClick}
      size="small"
      className={className}
      style={getButtonStyle()}
      disabled={disabled}
    >
      <Icon path={iconPath} size={0.7} />
    </BaseButton>
  );

  return (
    <>
      {toolTipText ? (
        <Tooltip
          placement="top"
          title={toolTipText}
        >
          {iconButton}
        </Tooltip>
      ) : (
        iconButton
      )}
    </>

  );
}

export default DataGridActionButton;

DataGridActionButton.propTypes = {
  borderRadius: PropTypes.string,
  className: PropTypes.string,
  iconPath: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  toolTipText: PropTypes.string,
  hideBorder: PropTypes.oneOf(['left', 'right']),
};

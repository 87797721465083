import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { func, string } from 'prop-types';
import { capitalize } from 'lodash';

function FilterByPeriod({
  typePeriod,
  onSelectTags,
}) {
  const { t } = useTranslation();
  const labelName = (name) => (name === 'months' ? 'month'
    : name === 'isoWeek' ? 'week' : name);

  const tags = [
    'day',
    'isoWeek',
    'months',
  ];
  return (
    <Radio.Group
      onChange={onSelectTags}
      value={typePeriod}
    >
      {tags.map((i) => (
        <Radio.Button id={`set${i}PeriodButton`} key={i} value={i}>{capitalize(t(`wms.status.${labelName(i)}`, labelName(i)))}</Radio.Button>
      ))}

    </Radio.Group>
  );
}

FilterByPeriod.propTypes = {
  typePeriod: string,
  onSelectTags: func,
};
export default FilterByPeriod;

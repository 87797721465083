import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import {
  Col, Modal, Row, Tooltip,
} from 'antd';
import PropTypes, { bool } from 'prop-types';
import cn from 'classnames';
import "./WindowCreateComment.scss"

import Icon from '@mdi/react';
import {
  mdiMicrophoneOutline,
  mdiPaperclip,
  mdiRecord,
  mdiSend,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';

import { get, isEmpty } from 'lodash';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import CommentReply from './CommentReply';

import { capitalize } from 'lodash';
import UploaderWrapperInView from '../../../components/uploader/UploaderWrapperInView';
import {
  errorMaxSize,
} from '../../../components/uploader/utils';
import ViewFiles from '../../../components/uploader/ViewFiles';
import BaseButtonWithCheckPerms from '../../../permissions/components/BaseButtonWithCheckPerms';
import { UI54Button, UI54Icon } from "@agpl/ui54kit";

let timerRecord;

function WindowCreateComment({
  parentUUID,
  projectUUID,
  partitionType,
  getAllFiles,
  getCommentsFetching,
  handleChangeNewComment,
  maxLengthInput,
  mentionsUsers,
  newComment,
  onResetReply,
  replyStatus,
  sendNewComment,
  type,
  disabledEdit = false,
  isFullscreen = false,
  inPanel,
}) {
  const { t } = useTranslation();

  const [filesList, setFiles] = useState([]);

  const fileRef = useRef(null);
  const audioChunks = useRef([]);
  const mediaRecorder = useRef(null);
  const [startRecord, setStartRecord] = useState(false);
  const [currentListBase64, setCurrentListBase64] = useState([]);

  const [customFiles, setCustomFiles] = useState([]);
  const [customAudio, setCustomAudio] = useState([]);

  const addCommentDisabled = () => {
    const haveContent = newComment.text.replace(/\s/g, '').length !== 0
      || newComment.files.length !== 0
      || newComment.audio.length !== 0;

    return !haveContent || getCommentsFetching;
  };

  const sendComment = () => {
    sendNewComment();
    setCustomFiles([]);
    setCustomAudio([]);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && e.ctrlKey && !addCommentDisabled()) {
      e.preventDefault();
      sendComment();
    }
  };

  const saveAudioFileCallback = (files) => {
    getAllFiles({ audio: files });
    setCustomAudio(files);
  };

  const saveFileCallback = (files) => {
    getAllFiles({ files });
    setCustomFiles(files);
  };

  const onChangeFromDragAndDrop = (data) => saveFileCallback(data.files);

  const uploadingClipboard = (data) => {
    const newData = (data.clipboardData || data.originalEvent.clipboardData).items;
    const newList = [];

    for (let i = 0; i < newData.length; i += 1) {
      if (newData[i].kind === 'file') {
        const file = newData[i].getAsFile();
        // console.log('file', file);
        newList.push(file);
      }
    }

    if (newList.length) {
      getAllFiles({ files: [...customFiles, ...newList] });
      setCustomFiles([...customFiles, ...newList]);
    }
  };

  const removeFile = (i) => {
    const newList = [...filesList];
    newList.splice(i, 1);
    setFiles(newList);
    saveFileCallback(newList);
  };

  const onRecordMouseDown = () => {
    timerRecord = setTimeout(() => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        setStartRecord(true);

        const options = {
          audioBitsPerSecond: 32000,
          bitsPerSecond: 32000,
        };

        mediaRecorder.current = new MediaRecorder(stream, options);
        mediaRecorder.current.start();

        mediaRecorder.current.ondataavailable = async (e) => {
          audioChunks.current.push(e.data);

          if (mediaRecorder.current.state === 'inactive') {
            const newAudio = new File(
              audioChunks.current,
              `audio-${currentListBase64.length + 1}.${mediaRecorder.current.mimeType.match(/\/([\w\d]+);?/)?.[1] || 'webm'}`,
              { type: mediaRecorder.current.mimeType || ' audio/webm;codecs=opus' },
            );

            const newAudioList = [];

            if (currentListBase64.length) {
              newAudioList.push(...currentListBase64);
            }
            newAudioList.push(newAudio);
            saveAudioFileCallback(newAudioList);
            setCurrentListBase64(newAudioList);
            audioChunks.current = [];
          }
        };
      });
    }, 100);
  };

  const deleteFile = async (id) => {
    const newBase64List = [...currentListBase64];

    newBase64List.splice(id, 1);

    setCurrentListBase64(newBase64List);
    saveAudioFileCallback(newBase64List);
  };

  const showConfirmDelete = (id, type) => Modal.confirm({
    title: capitalize(t('wms.modals.headers.removing_process', 'removing process')),
    width: '600px',
    content: capitalize(
      t('wms.modals.body.removing_process', "you're going to remove this file. Are you sure?"),
    ),
    okText: capitalize(t('wms.buttons.removing_process.yes', 'yes, I am')),
    okType: 'danger',
    cancelText: capitalize(t('wms.buttons.removing_process.no', "no, I'm not")),
    open: false,
    centered: true,
    maskClosable: true,
    okButtonProps: { className: 'dangerButton' },

    onOk() {
      if (type === 'audio') {
        deleteFile(id);
      } else {
        removeFile(id);
      }
    },
  });

  const onRecordMouseUp = () => {
    mediaRecorder.current?.stop();
    mediaRecorder.current?.stream?.getTracks()?.forEach((track) => track?.stop());
    clearTimeout(timerRecord);
    setStartRecord(false);
  };

  const titleTooltip = startRecord
    ? t('wms.tooltips.audio.stop', 'Release to stop recording')
    : t('wms.tooltips.audio.start', 'Click and hold to record');

  const customUpdateList = async () => {
    if (customAudio && customAudio?.length !== currentListBase64?.length) {
      const newList = [];

      customAudio.forEach((item) => {
        const dataAudio = get(item, 'type')
          ? URL.createObjectURL(item)
          : `data:${item.content_type};base64, ${item.file}`;
        newList.push(dataAudio);
      });
      setCurrentListBase64(newList);
    }
  };

  const handleChange = (e) => {
    const files = [...e.target.files];
    const sizeFiles = filesList.reduce((acc, file) => file.size + acc, 0) + files.reduce((acc, file) => file.size + acc, 0);
    fileRef.current.value = '';
    if (sizeFiles >= 10485760) {
      errorMaxSize();
    } else {
      setFiles((prev) => {
        saveFileCallback([...prev, ...files]);
        return [...prev, ...files];
      });
    }
  };

  const toggleUploadFile = () => fileRef.current.click();

  const resetAllFiles = () => {
    setFiles([]);
    setCurrentListBase64([]);
    setCustomFiles([]);
    setCustomAudio([]);
  };

  useEffect(() => {
    customUpdateList();
  }, [JSON.stringify(customAudio)]);

  useEffect(() => {
    if (JSON.stringify(customFiles) !== JSON.stringify(filesList)) {
      setFiles(customFiles);
    }
  }, [customFiles]);

  useEffect(() => {
    resetAllFiles();
  }, [parentUUID]);

  const hasFiles = !isEmpty(currentListBase64) || !isEmpty(filesList);

  return (
    <div className="mb-1">
      {type !== 'cycleArchive' && (
        <>
          <Row className="pb-1" align="bottom" gutter={5}>
            <Col span={inPanel ? 12 : 4} className={cn('flex flex-col justify-start', { 'mt-2 order-2': inPanel })}>
              <div className="w-full flex items-center">
                <input ref={fileRef} type="file" hidden multiple onChange={handleChange} />

                <UI54Button
                  type="outline"
                  primary
                  className="w-full px-1 flex justify-center items-center"
                  onClick={toggleUploadFile}
                  disabled={disabledEdit}
                >
                  <UI54Icon path={mdiPaperclip} size={1} rotate={30} />
                </UI54Button>

                <Tooltip
                  title={titleTooltip}
                  color={"grey"}
                >
                  <UI54Button
                    type="outline"
                    primary
                    className="ml-1 w-full px-1 flex justify-center items-center"
                    disabled={disabledEdit}
                    onMouseDown={onRecordMouseDown}
                    onMouseUp={onRecordMouseUp}
                  >
                    <UI54Icon
                      className={`${startRecord && 'blink'}`}
                      path={startRecord ? mdiRecord : mdiMicrophoneOutline}
                      size={1}
                    />
                  </UI54Button>
                </Tooltip>
              </div>
            </Col>
            <Col span={inPanel ? 24 : 18} className={cn('flex flex-col grow bg-white', { 'order-1': inPanel })}>
              {replyStatus?.actorUUID && (
                <CommentReply
                  actorName={replyStatus?.actorName}
                  actorUUID={replyStatus?.actorUUID}
                  text={replyStatus?.text}
                  onResetReply={onResetReply}
                />
              )}
              <UploaderWrapperInView
                files={customFiles}
                // disabled={disabledView.includes('dropFile')}
                onChangeFileCallback={onChangeFromDragAndDrop}
              >
                <MentionsInput
                  onPaste={uploadingClipboard}
                  onKeyPress={handleKeyPress}
                  maxLength={maxLengthInput}
                  className={`mentions ${isFullscreen && 'mentions--fullscreen'}`}
                  onChange={handleChangeNewComment}
                  value={newComment.value}
                  placeholder={`${capitalize(t('wms.placeholders.comment_with_file'))}...`}
                >
                  <Mention
                    type="user"
                    trigger="@"
                    displayTransform={(_id, display) => `@${display}`}
                    appendSpaceOnAdd
                    data={mentionsUsers}
                    allowSuggestionsAboveCursor
                  />
                </MentionsInput>
              </UploaderWrapperInView>
            </Col>
            <Col span={inPanel ? 12 : 2} className={cn('flex', { 'mt-2 order-3': inPanel, 'items-end': !inPanel })}>
              <BaseButtonWithCheckPerms
                tooltipTitle={"You don't have 'create' permission!"}
                tooltipColor={'grey'}
                entityUUID={parentUUID}
                partitionType={partitionType}
                permsList={["create"]}
                size="middle"
                onClick={sendComment}
                type="primary"
                className="px-1 flex justify-center items-center w-full"
                ghost
                // size={sizeBtn}
                disabled={addCommentDisabled()}
              >
                {inPanel && (<span className="mr-2">{capitalize(t('wms.buttons.send', 'send'))}</span>)}
                <UI54Icon path={mdiSend} size={1} />
              </BaseButtonWithCheckPerms>
            </Col>
          </Row>
          {hasFiles && (
            <div className="flex mt-2">
              <ViewFiles
                files={filesList}
                audio={currentListBase64}
                deleteFile={(i) => showConfirmDelete(i, 'file')}
                deleteAudio={(i) => showConfirmDelete(i, 'audio')}
              />
            </div>
          )}
        </>
      )
      }
    </div >
  );
}

WindowCreateComment.propTypes = {
  parentUUID: PropTypes.string.isRequired,
  getAllFiles: PropTypes.func.isRequired,
  getCommentsFetching: PropTypes.bool,
  handleChangeNewComment: PropTypes.func.isRequired,
  isFullscreen: PropTypes.bool,
  inPanel: PropTypes.bool,
  maxLengthInput: PropTypes.number.isRequired,
  mentionsUsers: PropTypes.array,
  newComment: PropTypes.object,
  onResetReply: PropTypes.func.isRequired,
  replyStatus: PropTypes.shape({
    actorName: PropTypes.string,
    actorUUID: PropTypes.string,
    text: PropTypes.string,
  }),
  sendNewComment: PropTypes.func.isRequired,
  type: PropTypes.string,
  partitionType: bool,
  disabledEdit: bool,
  projectUUID: PropTypes.string,
};

export default WindowCreateComment;

/* eslint-disable import/prefer-default-export */

import { isEmpty } from 'lodash';
import { prop } from 'ramda';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPartitionPublicUUID } from '../../../../config/selectors/selectors';
import { getPublicUsersEntitiesData } from '../../../selectors/selectors';
import { getDailyForThePeriod } from '../../../../userFlow/store/action-creators/periodReportsActionsCreators';
import useURLParams from '../../../../hooks/useURLParams';

export const useFetchUsersReportsForPeriod = () => {
  const dispatch = useDispatch();
  const { getStartDateParam } = useURLParams();

  const startDate = getStartDateParam;

  const publicParentEntity = useSelector(getPartitionPublicUUID);
  const publicUsersEntities = useSelector(getPublicUsersEntitiesData);

  const getAllReports = async (start, end) => {
    const usersArrUuid = publicUsersEntities.map((item) => prop('actor', item));
    if (!isEmpty(usersArrUuid)) {
      const params = {
        actor: usersArrUuid,
        parent: publicParentEntity,
        params: {
          date__gte: start,
          date__lte: end,
        },
      };

      return dispatch(getDailyForThePeriod(params));
    }

    return null;
  };

  useEffect(() => {
    if (startDate) {
      getAllReports(startDate, startDate);
    }
  }, [publicUsersEntities, startDate]);
};

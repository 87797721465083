import {
  head, path, pathOr, propOr,
} from 'ramda';
import _ from 'lodash';
import { partitionNamesConfig } from '../../api/appConfig';

export const getConfig = (state) => pathOr([], ['config', 'configReducer', 'data'], state);
export const getConfigAuth = (state) => path(['config', 'configReducer', 'authConfig'], state);
export const fetchingConfigAuth = (state) => path(['config', 'configReducer', 'fetchingAuthConfig'], state);

export const getAuthConfigFetching = (state) => path(['config', 'configReducer', 'authConfigFetching'], state);

export const checkBackendUrlEntity = (state) => {
  const arr = head(propOr([], 'public_interface', getConfigAuth(state)));
  return pathOr(null, ['params', 'backend_domain'], arr);
};

export const getConfigFetching = (state) => path(['config', 'configReducer', 'fetching'], state);

export const getPartitionUUIDForHeaderByName = (state, name) => {
  const configStore = getConfig(state);
  if (_.isArray(configStore)) {
    const partitionItemHeader = configStore.filter(
      (item) => path(['params', 'name'], item) === name,
    );
    return propOr('', 'header_uuid', head(partitionItemHeader));
  }

  return '';
};

export const getRootEntityPartition = (state, name) => {
  const configStore = getConfig(state);
  if (_.isArray(configStore)) {
    const findPartition = configStore.find((item) => pathOr('', ['params', 'name'], item) === name);
    return propOr('', 'uuid', findPartition);
  }
  return '';
};

export const getPartitionPMUUID = (state) => getRootEntityPartition(state, partitionNamesConfig.partition1);

export const getPartitionPublicUUID = (state) => getRootEntityPartition(state, partitionNamesConfig.partition3);

export const getPartitionUsersUUID = (state) => getRootEntityPartition(state, partitionNamesConfig.partition2);

export const getPartitionDeliveryUUID = (state) => getRootEntityPartition(state, partitionNamesConfig.partition5);

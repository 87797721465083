import {
  get, includes, isEmpty, without,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { func } from 'prop-types';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import BaseModal from '../../../components/_ui/BaseModal/BaseModal';
import UserInfoModalForAdminDashboard from './UserInfoModalForAdminDashboard';
import DeletingUser from '../deletingUser/DeletingUser';

import './style/DayUserReportUsersTableMedia.scss';

import {
  getPublicUsersEntitiesData,
  getPublicUsersEntitiesFetching,
  getPublicUsersEntitiesTotal,
} from '../../selectors/selectors';
import { useFetchUsersReportsForPeriod } from './hooks/useFetchUsersReportsForPeriod';
import { useFilteredUser } from './hooks/useSortUsers';
import useDayUserReportUsersTableColumns from './hooks/dayUserReportUsersTableHooks/useDayUserReportUsersTableColumns';
import {
  getPeriodStartWorkReportsData,
  getPeriodStartWorkReportsIsLoading,
} from '../../../userFlow/store/selectors/periodReportsSelectors';
import { nameSort } from '../../../MainUtils';
import useURLParams from '../../../hooks/useURLParams';

function DayUserReportUsersTable({ getActorsList }) {
  useFetchUsersReportsForPeriod();

  const {
    getActorUUIDParam, setSearchParams, clearSearchParams, getURLParams, addAndRemoveSearchParams,
  } = useURLParams();

  const searchParams = getURLParams();

  const publicUsersEntitiesData = useSelector(getPublicUsersEntitiesData);
  const publicUsersEntitiesTotal = useSelector(getPublicUsersEntitiesTotal);
  const publicUsersStartWorkReports = useSelector(getPeriodStartWorkReportsData);
  const publicUsersReportsFetching = useSelector(getPeriodStartWorkReportsIsLoading);
  const publicUsersEntitiesFetching = useSelector(getPublicUsersEntitiesFetching);

  const actor = getActorUUIDParam;
  const isLoading = publicUsersEntitiesFetching || publicUsersReportsFetching;

  const [paginationOptions, setPaginationOptions] = useState({
    currentPage: 1,
    offset: 0,
    pageLimit: 100,
  });

  const [totalCount, setTotalCount] = useState(0);
  const [typeOfSort, setTypeOfSort] = useState('');
  const [valueOfSort, setValueOfSort] = useState('');
  const [objectOfSort, setObjectOfSort] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const sorterArr = ['onWorkPlaceS', 'startWorkS', 'finishWorkS', 'lunchBreakS', 'dailyPlanningTextS', 'showReportsS', 'kindOfDayS', 'alertS', 'createdS'];

  const setCurrentPage = (page) => {
    setPaginationOptions((prev) => ({ ...prev, currentPage: page }));
  };

  const onSizePerPageTable = (data) => {
    setPaginationOptions((prev) => ({
      ...prev,
      currentPage: 1,
      offset: 0,
      pageLimit: data,
    }));
  };

  const getActorsWithSearchFilterAndPagination = () => {
    const params = {};
    const config = {
      // limit: pageLimit,
      // offset,
    };

    // if (searchData) {
    //   config.search_data = {
    //     value: searchData,
    //     fields: { uinfo: ['first_name', 'last_name'] },
    //   };
    // }

    if (typeOfSort === 'created') {
      config.order_by = 'created';
      config.order = nameSort[valueOfSort];
    }

    getActorsList(params, config);
  };

  const [columns, { searchData }] = useDayUserReportUsersTableColumns({
    setTypeOfSort,
    setValueOfSort,
    setObjectOfSort,
    setShowDeleteModal,
    typeOfSort,
    valueOfSort,
    getActorsWithSearchFilterAndPagination,
  });

  const getPaginationOptions = (data) => {
    setPaginationOptions(data);
  };

  const onRow = (record) => ({
    onClick: () => {
      if (get(record, 'actor') === actor) {
        return clearSearchParams(['actor', 'actorUUID']);
      }
      return setSearchParams({
        actor: get(record, 'actor'),
        actorUUID: get(record, 'uuid'),
      });
    },
  });

  const rowClassName = (row) => {
    const data = `universal_table_row ${get(row, 'actor') === actor
      ? 'selected'
      : ''
      }`;

    return data;
  };

  const deleteUserCallback = () => {
    setShowDeleteModal(null);
    getActorsWithSearchFilterAndPagination();
  };

  const filteredUsers = useFilteredUser({
    users: publicUsersEntitiesData,
    typeOfSort,
    valueOfSort,
    reportListTime: publicUsersStartWorkReports,
    searchData,
    paginationOptions,
    setCurrentPage,
    setPaginationOptions,
    setTotalCountUsers: setTotalCount,
  });

  useEffect(() => {
    if (publicUsersEntitiesTotal) {
      setTotalCount(publicUsersEntitiesTotal);
    }
  }, [publicUsersEntitiesTotal]);

  useEffect(() => {
    if (typeOfSort === 'created') {
      getActorsWithSearchFilterAndPagination();
    }
  }, [typeOfSort, valueOfSort]);

  useEffect(() => {
    if (!isEmpty(objectOfSort)) {
      addAndRemoveSearchParams(
        objectOfSort,
        without(sorterArr, ...Object.keys(objectOfSort)),
      );
      setObjectOfSort({});
    }
  }, [JSON.stringify(objectOfSort)]);

  useEffect(() => {
    const sorting = Object
      .entries(searchParams)
      .find(([key, value]) => includes(sorterArr, key));
    if (sorting) {
      const key = sorting[0].slice(0, sorting[0].length - 1);
      setTypeOfSort(key);
      setValueOfSort(sorting[1]);
    }
  }, []);

  return (
    <>
      <div span={24} className="collapsedTable-table user-dashboard-table-wrapper">
        <BaseTableWithPagination
          changePageLimit={onSizePerPageTable}
          columns={columns}
          data={filteredUsers}
          defaultPageLimit={100}
          getPaginationOptions={getPaginationOptions}
          headerRowClassName="universal_header_table_row color-gray"
          loading={isLoading}
          newCurrentPage={get(paginationOptions, 'currentPage')}
          onRow={onRow}
          pageLimit={get(paginationOptions, 'pageLimit')}
          pageSizeOptions={[25, 50, 100]}
          paginationSize="small"
          rowClassName={rowClassName}
          total={totalCount}
          useCustomPagination
        />
      </div>
      {showDeleteModal && (
        <BaseModal
          title="Removing user"
          open={Boolean(showDeleteModal)}
          handleCancel={deleteUserCallback}
          width="1200px"
          destroyOnClose
          centered
          footer={null}
        >
          <DeletingUser
            user={showDeleteModal}
            deleteUserCallback={deleteUserCallback}
          />
        </BaseModal>
      )}
      <UserInfoModalForAdminDashboard />
    </>

  );
}

export default DayUserReportUsersTable;

DayUserReportUsersTable.propTypes = {
  getActorsList: func,
};

import {
    Col, Row, Input,
    Table,
    Flex,
    Modal,
    Collapse,
    ConfigProvider,
    Dropdown,
    Tooltip,
  } from 'antd';
  import { capitalize, get, isEqual } from 'lodash';
  import {
    array, func, object, string, arrayOf,
    number,
    bool,
  } from 'prop-types';
  import React, {
    useContext,
    useEffect, useMemo, useRef, useState,
  } from 'react';
  import { useTranslation } from 'react-i18next';
  import cn from 'classnames';
  import Icon from '@mdi/react';
  import { defaultStateBtn } from '../issuesView/utils';
  import { CaretRightOutlined } from '@ant-design/icons';
  import DropDownParamsEntity from '../../../entity/components/dropDownParamsEntity/DropDownParamsEntity';
  import SpecialTextArea from '../commonComponents/SpecialTextArea/SpecialTextArea';
  import DateLeftEdit from "../../../components/DateLeftEdit/DateLeftEdit";
  import DateLeft from '../../../components/DateLeft';
  import EditButton from '../commonComponents/EditButton';
  import UnSaveDataConfirmNew from '../../../components/un-save-data-confirm/UnSaveDataConfirmNew';
  import WrapperActivitiesDashboard from '../commonComponents/activities/activitiesDashboard/WrapperActivitiesDashboard';
  import AssigningUsersRoot from '../../../assignUsersModal/AssigningUsersRoot';
  import { getUuid } from '../../../assignUsersModal/utils';
  import WrapperAvatarsGroupForProjectWithHook from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
  //add new imports
  import { useDispatch, useSelector } from 'react-redux';
  import {getProjectUUID} from '../../selectors/selectors';
  import { ProjectTypeCommonConstants } from '../../constants/Constants';
  import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
  import { entityCreate, entityPartialRead, entityUpdate, incrementAndGetCount} from '../../../entity/actions/entityActions';
  import { antNotification, renderTableID } from '../../../MainUtils';
  import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
  import EditIssueForIssuesTable from '../issuesView/EditIssueForIssuesTable';
  import { mdiDotsVertical, mdiLinkOff, mdiTrashCanOutline } from '@mdi/js';
  import NewDocsTable from './NewDocsTable';
  import NewIssuesTable from './NewIssuesTable';
  import WrapperAddIssue from '../issues/newIssue/WrapperAddIssue';
  import moment from 'moment';
  import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
  import { getListOfIssues } from '../../actions/projectFlowActions';
  import ConnectedIssuesTable from './ConnectedIssuesTable';
import InfoComponent from '../project/projectInfo/components/infoComponent/InfoComponent';
import { connectedIssuesInfo, relatedIssuesInfo } from './helpers/workblockUtils';
import DragTasksWrapper from '../vector/dragTask/DragTasksWrapper';
import { UI54Modal } from '@agpl/ui54kit';
import useURLParams from '../../../hooks/useURLParams';

  const issueStatuses = {
    "Opened": [
      {key: 'created', title: 'Created'},
      {key: 'reopened', title: 'Re-opened'},
      {key: 'acquainted', title: 'Acquainted'},
      {key: 'accepted', title: 'Accepted'},
      {key: 'rejected', title: 'Rejected'}
    ],
    "On Development": [
      {key: 'in progress', title: 'In progress'},
      {key: 'review', title: 'Ready for review'},
      {key: 'ready for deploy', title: 'Ready for deploy'}
      
    ],
    "Testing": [
      {key: 'test', title: 'Ready for test'},
      {key: 'testing', title: 'Testing'},
      {key: 'not reproducible', title: 'Not reproducible'}
    ],
    "Purgatory": [
      {key: 'backlog', title: 'Backlog'},
      {key: 'blocked', title: 'Blocked'},
      {key: 'paused', title: 'Paused'},
      {key: 'discuss', title: 'Discuss'},
      {key: 'not enough info', title: 'Not enough info'}
    ],
    "Closed": [
      {key: 'closed', title: 'closed'},
      {key: 'deployed', title: 'deployed'},
      {key: 'abandoned', title: 'abandoned'},
    ]
  }

  export default function WorkBlockInfoViewForVectorsTable({
    activitiesTabs,
    defaultCommentsCount,
    defaultVectorData,
    onChangeData,
    getFromParams,
    disabledView,
    partitionType,
    dataProject,
    onChangeAssignedUsers,
    clickDashboardButtonHandler,
    // add props
    dragIssueCallback,
    isGlobalDisabled,
    newCurrentPage,
    countOfData,
    getIssue,
    getIssues,
    getIssuesRequestSetting,
    filterConfig,
    filterCallback,
    newIssueSuccessCallback,
    onChangeRowCallback,
    connectedIssueTransfered,
    //
    uuid,
    vectorData,
       //add document modal props
    activeDocumentModal,
    setActiveDocumentModal,
    activeDocument,
    setActiveDocument,
    activeDocumentType,
    setActiveDocumentType,
    // add issue modal props
    activeIssue,
    setActiveIssue,
    activeIssueModal,
    saveIssue,
    setActiveIssueModal,
    saveDocument,
  }) {

    const { t } = useTranslation();
    const projectUUID = useSelector(getProjectUUID)

    const { routeNavigateTo } = useURLParams()
    const dispatch = useDispatch();
    const refDescription = useRef({});
    const refTitle = useRef({});
    const refResult = useRef({});

    const [versionNumber, setVersionNumber] = useState('');
    const [currentExpirationDate, setCurrentExpitarionDate] = useState(null);

    const [stateBtnNumber, setStateBtnNumber] = useState(defaultStateBtn);
    const [stateBtnTitle, setStateBtnTitle] = useState(defaultStateBtn);
    const [stateBtnDescription, setStateBtnDescription] = useState(defaultStateBtn);
    const [isEditLifeTime, setIsEditLifeTime] = useState(false);
    const [stateBtnResult, setStateBtnResult] = useState(defaultStateBtn);

    const [isEditAssignUsers, setEditAssignUsers] = useState(false);

    //add issues state
    const [addIssueModal, setAddIssueModal] = useState(false)
    const [selectedIssueForDelete, setSelectedIssueForDelete] = useState(null)
    const [listIssuesRelated, setListIssuesRelated] = useState([])
    const [issuesData, setIssuesData] = useState([])
    const [multiListIssues, setMultiListIssues] = useState(listIssuesRelated)
    const [issuesPage, setIssuesPage] = useState(1)
    const [loadingIssues, setLoadingIssues] = useState(false)
    const [issueModalClose, setIssueModalClose] = useState(false)

    //add docs props
    const [listDocumentsRelated, setListDocumentsRelated] = useState([])
    const [documentsData, setDocumentsData] = useState([])
    const [addDocumentModal, setAddDocumentModal] = useState(false)
    const [multiListDocuments, setMultiListDocuments] = useState(listDocumentsRelated)
    const [selectedDocumentForDelete, setSelectedDocumentForDelete] = useState(null)
    const [documentsPage, setDocumentsPage] = useState(1)


    const [loadingDocuments, setLoadingDocuments] = useState(false)
    //add createDocFn

    //add connected issues
    const [connectedIssuesData, setConnectedIssuesData] = useState([])
    const [listIssuesConnected, setListIssuesConnected] = useState([])
    const [connectedIssuesLoading, setConnectedIssuesLoading] = useState(false)
    const [connectedIssuesPage, setConnectedIssuesPage] = useState(1)
    const [totalCountConnectedIssues ,setTotalCountConnectedIssues] = useState(0)


    // const getPage = (relatedList, page) => {
    //   if(relatedList.length < 5) {
    //     return relatedList
    //   } else {
    //     const startPage = page === 1 ? 0 : 5 * (page - 1)
    //     const endPage = 5 * page
    //     const finalPage = relatedList.slice(startPage, endPage)
    //     return finalPage.length > 0 ? finalPage : relatedList
    //   }
    // }

    useEffect(() => {
      console.log(connectedIssuesData);
    }, [connectedIssuesData])

    const documentItems = [{
    key: "1",
    label: (
        <Flex justify='center' align='center' gap={4}>
        <span className=' text-[#cc161f]'>Unlink Document</span>
        <Icon path={mdiLinkOff} size={0.7} color={'#cc161f'} />
      </Flex>
      )
    },
  ]

  const issueItems = [{
    key: "1",
    label: (
        <Flex justify='center' align='center' gap={4}>
        <span className=' text-[#cc161f]'>Unlink Issue</span>
        <Icon path={mdiLinkOff} size={0.7} color={'#cc161f'} />
      </Flex>
      )
    },
  ]

  const getTooltipIssues = (issuesList, type) => {
    
    const unique = issuesList.filter((obj, index, self) =>
      index === self.findIndex((t) => t.status === obj.status)
  );

    return !type ? null : <div className='p-1 flex flex-col gap-y-1'>
      {unique.map(issue  =>  
        <span>{
          capitalize(issueStatuses[type].filter(item => item.key === issue.status)[0]?.title)}: {issuesList.filter(filterIssue => filterIssue.status === issue.status).length}</span>
        )
      }
    </div>
  }

    const createDocumentTitle = (title) => {
      const baseTitle = `${title} (related to `;
      const fullTitle = `${baseTitle}${vectorData?.params?.title})`;
      let defaultTitle;

      if (fullTitle.length > 100) {
          const remainingLength = 100 - baseTitle.length - 4;
          const trimmedTitle = vectorData?.params?.title.slice(0, remainingLength);
          defaultTitle = `${baseTitle}${trimmedTitle})...`;
      } else {
          defaultTitle = fullTitle;
      }
      return defaultTitle
    }

    const getClearListOfIssues = async (param) => {
      setConnectedIssuesLoading(true)
      const config = {
        depth: 0,
        partition: partitionType,
        // limit: 5,
        // offset: connectedIssuesPage === 1 ? 0 : 5 * (connectedIssuesPage - 1),
        constants: [
          ProjectTypeCommonConstants.LIST_OF_ISSUES_REQUEST,
          ProjectTypeCommonConstants.LIST_OF_ISSUES_SUCCESS,
          ProjectTypeCommonConstants.LIST_OF_ISSUES_FAILURE,
        ],
        fields: {
          modified: 'modified',
        },
        params_fields: {
          id: 'id',
          title: 'title',
          description: 'description',
          result: 'result',
          tracker: 'tracker',
          priority: 'priority',
          status: 'status',
          assigned_users: 'assigned_users',
          estimated_time: 'estimated_time',
          lifeTime: 'lifeTime',
          users: 'users',
          usersSearch: 'usersSearch',
          tags: 'tags',
          expirationDateDev: 'expirationDateDev',
          expirationDateQa: 'expirationDateQa',
        },
        parent: uuid,
        ...param,
      };

      const res = await  dispatch(getListOfIssues(config));
      setConnectedIssuesData(res.data)
      setTotalCountConnectedIssues(res.total)
      setConnectedIssuesLoading(false)
    };

    const createConnectedIssueCallback = (uuid) => {
      newIssueSuccessCallback(uuid)
      getClearListOfIssues()
    }

    useEffect(() => {
      if(uuid) {
        getClearListOfIssues()
      }
    }, [uuid, issueModalClose])

const createNewDoc = async (type, withContent, config = {}) => {
  setActiveDocumentModal(false)
  setActiveIssueModal(false)
  let defaultTitle;
  let fieldName;
  switch (type) {
    case 'projectIdea': {
      defaultTitle = createDocumentTitle("New Note")
      fieldName = 'note';
      break;
    }
    case 'projectSpreadsheet': {
      defaultTitle = createDocumentTitle("New Spreadsheet")
      fieldName = 'spreadsheet';
      break;
    }
    case 'projectDocument':
    default: {
      defaultTitle = createDocumentTitle("New Document")
      fieldName = 'document';
      break;
    }
  }

  // const {
  //   // description: configDescription,
  //   title: docTitle,
  //   status: docStatus,
  //   content: configContent,
  //   stateID: configActualState,
  //   projectUUID: projectUUID,
  // } = config || {};

  const { increment: docID } = await dispatch(incrementAndGetCount({
    entity_uuid: projectUUID,
    field_name: fieldName,
  }, partitionType)) || {};

  const newTitle = defaultTitle;

  const data = {
    entity_type: type,
    parent: projectUUID,
    params: {
      id: docID,
      status: 'created',
      title: newTitle,
      text: "",
      lifeTime: type === 'projectIdea'
        ? moment().add('2', 'week').format('YYYY-MM-DD')
        : moment().add('1', 'year').format('YYYY-MM-DD'),
      updated: moment().locale('en').format('LLL'),
    },
  };

 
  const constants = [
    ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_REQUEST,
    ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_SUCCESS,
    ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_FAILURE,
  ];

  const options = {
    partition: partitionType,
  };

  if (type === 'projectSpreadsheet') {
    data.params.text = '';
    data.params.actualState = '0';
  } else {
    data.params.actualState = '0';
  }


 const response = await dispatch(entityCreate({
    data,
    constants,
    options,
  }));
  addNewDocumentInVector(response[0].uuid)
  setActiveDocument(response[0].uuid)
  setActiveDocumentModal(true)
  antNotification('success', t('wms.noun.success', 'Success'));
};

    const changeDocumentList = (list) => {
        if(JSON.stringify(list) !== JSON.stringify(listDocumentsRelated)){
          dispatch(updateDocumentsVectorList(list))
        }
      setAddDocumentModal(false)
    }

    const changeIssuesList = (list) => {
        if(JSON.stringify(list)!== JSON.stringify(listIssuesRelated)){
          dispatch(updateIssuesVectorList(list))
        }
      setAddIssueModal(false)
    }

    const updateDocumentsVectorList = (list) => {
      const data = {
        entity_type: 'vector',
        entity_uuid: uuid,
        params: {
          docsRelated: list,
        }
      }
      const constants = [
        ProjectTypeCommonConstants.EDIT_VECTOR_REQUEST,
        ProjectTypeCommonConstants.EDIT_VECTOR_SUCCESS,
        ProjectTypeCommonConstants.EDIT_VECTOR_FAILURE
      ]
      const options = {
        partition: partitionType
      }
      setSelectedIssueForDelete(null)
      setSelectedDocumentForDelete(null)

    return entityUpdate({
      data,
      constants,
      options
    })
  }

  const updateIssuesVectorList = (list) => {
    const data = {
      entity_type: 'vector',
      entity_uuid: uuid,
      params: {
        issuesRelated: list,
      }
    }
    const constants = [
      ProjectTypeCommonConstants.EDIT_VECTOR_REQUEST,
      ProjectTypeCommonConstants.EDIT_VECTOR_SUCCESS,
      ProjectTypeCommonConstants.EDIT_VECTOR_FAILURE
    ]
    const options = {
      partition: partitionType
    }
    setSelectedIssueForDelete(null)
    setSelectedDocumentForDelete(null)

  return entityUpdate({
    data,
    constants,
    options
  })
}

// add new Issue
  const addNewIssueInVector = (uuid) => {
    newIssueSuccessCallback(uuid)
    dispatch(updateIssuesVectorList([...listIssuesRelated, uuid]))
  }

// add new Doc
  const addNewDocumentInVector = (uuid) => {
    dispatch(updateDocumentsVectorList([...listDocumentsRelated, uuid]))
  }

    const getBackgroundColor = (type) => {
      switch (type) {
        case 'ticket':
          return 'ticket-background';
        case 'bug':
          return 'bug-background';
        case 'feature':
          return 'feature-background';
        case 'low':
          return 'low-background';
        case 'medium':
          return 'medium-background';
        case 'high':
          return 'high-background';
        case 'urgent':
          return 'urgent-background';
        case 'easy':
          return 'easy-background';
        case 'normal':
          return 'normal-background';
        case 'difficult':
          return 'difficult-background';
        case 'very_complicated':
          return 'very-complicated-background';
        default:
          return '';
      }
    };




    const updateVector = () => {
        let newIssuesRelated = vectorData?.params?.issuesRelated || []
        let newDocumentsRelated = vectorData?.params?.docsRelated || []

        if(selectedDocumentForDelete) {
          newDocumentsRelated = newDocumentsRelated.filter(el => el !== selectedDocumentForDelete)
           setListDocumentsRelated(newDocumentsRelated)
         }
        if(selectedIssueForDelete) {
         newIssuesRelated = newIssuesRelated.filter(el => el !== selectedIssueForDelete)
          setListIssuesRelated(newIssuesRelated)
        }

        const data = {
          entity_type: 'vector',
          entity_uuid: uuid,
          params: {
            docsRelated: newDocumentsRelated,
            issuesRelated: newIssuesRelated
          }
        }

        const constants = [
          ProjectTypeCommonConstants.EDIT_VECTOR_REQUEST,
          ProjectTypeCommonConstants.EDIT_VECTOR_SUCCESS,
          ProjectTypeCommonConstants.EDIT_VECTOR_FAILURE
        ]

        const options = {
          partition: partitionType
        }

        setSelectedIssueForDelete(null)
        setSelectedDocumentForDelete(null)

      return entityUpdate({
        data,
        constants,
        options
      })

    }

    const updateVectorRequest = async () => {
      if(selectedIssueForDelete || selectedDocumentForDelete) {
     dispatch(updateVector())
      }
    }

   useEffect(() => {
    updateVectorRequest()
   }, [selectedIssueForDelete, selectedDocumentForDelete])

   useEffect(() => {
    if(vectorData?.params?.issuesRelated && !isEqual(vectorData?.params?.issuesRelated, listIssuesRelated)) {
      setListIssuesRelated(vectorData?.params?.issuesRelated )
    } else if(!vectorData?.params?.issuesRelated){
      setListIssuesRelated([])
    }


    if(vectorData?.params?.docsRelated && !isEqual(vectorData?.params?.docsRelated, listDocumentsRelated)) {
      setListDocumentsRelated(vectorData?.params?.docsRelated)
    } else if(!vectorData?.params?.docsRelated) {
      setListDocumentsRelated([])
    }

  }, [vectorData, uuid])


  const getListOfDocuments = async () => {
    setLoadingDocuments(true)
    const data = {
        entity_type: ['projectDocument', 'projectIdea', 'projectSpreadsheet'],
        entity_uuids: listDocumentsRelated,
        params_fields: {
          title: "title",
          id: "id",
          lifeTime: "lifeTime",
          status: "status",
        },
        fields: {
          entity_type: "entity_type",
        }
    };

    const constants = [
      "GET_ENTITY_REQUEST",
      "GET_ENTITY_SUCCESS",
      "GET_ENTITY_FAILURE"
    ]

    const options = {
      partition: partitionType
    }
    try {
      const docsData = await dispatch(entityPartialRead({
        data,
        constants,
        options
      }))
    setDocumentsData(docsData)
    } catch(e) {
      setDocumentsData(null)
      setListDocumentsRelated([])
      dispatch(updateDocumentsVectorList([]))
    }
  setLoadingDocuments(false)
  }

  const getListOfAttachedIssues = async () => {
    setLoadingIssues(true)
    const data = {
        entity_type: "issue",
        // entity_uuids: getPage(listIssuesRelated, issuesPage),
        entity_uuids: listIssuesRelated,
        params_fields: {
          title: "title",
          id: "id",
          status: "status",
          tracker: "tracker",
          priority: "priority",
          estimated_time: "estimated_time",
          partitionType: "partitionType",
          vector: "vector",
        }
    };

    const constants = [
      "GET_ENTITY_REQUEST",
      "GET_ENTITY_SUCCESS",
      "GET_ENTITY_FAILURE"
    ]

    const options = {
      partition: partitionType,

    }
    try {
      const issuesData = await dispatch(entityPartialRead({
        data,
        constants,
        options,
      }))
      setIssuesData(issuesData)
    } catch(e) {
      setListIssuesRelated([])
      setIssuesData(null)
      dispatch(updateIssuesVectorList([]))
    }
    setLoadingIssues(false)
  }

 useEffect(() => {
  if(!activeIssueModal) {
    setIssueModalClose(prev => !prev)
  }
 }, [activeIssueModal])

  useEffect(() => {
    if(listDocumentsRelated && listDocumentsRelated.length > 0) {
      getListOfDocuments()
    }
    if(listDocumentsRelated.length === 0) {
      setDocumentsData(null)
    }
  }, [listDocumentsRelated, saveDocument])

  useEffect(() => {
    if(listIssuesRelated && listIssuesRelated.length > 0) {
      getListOfAttachedIssues()
    }
    if(listIssuesRelated.length === 0) {
      setIssuesData(null)
    }
  }, [listIssuesRelated, saveIssue])

  useEffect(() => {
    
    if(connectedIssuesData && connectedIssuesData.length > 0) {
      getClearListOfIssues()
    }
    if(connectedIssuesData.length === 0) {
      setConnectedIssuesData(null)
    }
  }, [saveIssue, connectedIssueTransfered])


    const selectDocumentCallback = async (uuid, type) => {
      setActiveIssueModal(false)
    //  await getDocumentWithOptionalData(uuid, type)
      setActiveDocument(uuid)
      setActiveDocumentType(type)
      setActiveDocumentModal(true)
    }

   const selectIssueCallback = (uuid) => {
    setActiveIssue(uuid)
    setActiveDocumentModal(false)
    setActiveIssueModal(true)
   }

   const docsColums = [
    {
      dataIndex: 'id',
      title: 'ID',
      key: 'id',
      render: (cell, row) => {
        let letter;
        const { entity_type } = row;

        switch (entity_type) {
          case 'projectIdea': {
            letter = 'N';
            break;
          }
          case 'projectDocument': {
            letter = 'D';
            break;
          }
          case 'projectSpreadsheet': {
            letter = 'S';
            break;
          }
        }
        return ( renderTableID(get(row, ['id']) || 0, letter));
      },
    },
    {
      dataIndex: 'title',
      key: 'title',
      title: 'Title',
      render: (cell, row) => (
        <EntityParamTag
          value={get(row, [ 'title']) || cell}
          type="value"
          param="title"
          maxLength={128}
        />
      ),
    },
    {
      dataIndex: 'entity_type',
      key: 'entity_type',
      title: capitalize(t('wms.table.headers.type', 'type')),
      render: (cell, row) => cell && (
        <EntityParamTag
          param='type'
          value={get(row, ['entity_type']) || cell}
          type='badge'
        />
      ),
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: capitalize(t('wms.table.headers.status', 'status')),
      render: (cell, row) => (
        <EntityParamTag
          value={get(row, [ 'status']) || cell}
          param="status"
          type={'iconWithStatus'}
        />
      ),
    },
    {
      key: 'action',
      render: (_, record) => (
        <Flex justify='end'>
          <Dropdown
          placement='topCenter'
          menu={{
            items: documentItems,
            onClick: ({key, domEvent}) => {
              domEvent.stopPropagation()
              switch(key) {
                case '1':
                  setSelectedDocumentForDelete(record.uuid)
                  break;
              }
            }
          }}
          >
          <BaseButton
            onClick={e => e.stopPropagation()}
            id='dotsVerticalButton'
            className="btnPrimary-outline"
            size="small"
          >
            <Icon path={mdiDotsVertical} size={1} />
          </BaseButton>
        </Dropdown>
        </Flex>
      ),
    },
   ]

   const issuesColums = [
    {
      dataIndex: 'id',
      title: 'ID',
      key: 'id',
      render: (cell, row) => {

        return ( get(row, ['params', 'id']) || cell);
      },
    },
    {
      dataIndex: 'title',
      key: 'title',
      title: 'Title',
      render: (cell, row) => (
        <EntityParamTag
          value={get(row, ['params', 'title']) || cell}
          type="value"
          param="title"
          maxLength={128}
        />
      ),
    },
    {
      dataIndex: 'tracker',
      key: 'tracker',
      title: "Info",
      render: (cell, row) =>  (
        <EditIssueForIssuesTable
          issue={row}
          type="tracker"
        >
          <Flex>
          <Flex
            align="center"
            justify="center"
            className={`${getBackgroundColor(cell)}`}
            style={{
              height: '20px',
              borderRadius: '4px',
            }}
          >
            <EntityParamTag param="tracker" value={get(row, ['tracker'])} type="char" />
          </Flex>
            <Flex
            align="center"
            justify="center"
            className={`${getBackgroundColor(cell)} ml-2 mr-2`}
            style={{
              height: '20px',
              borderRadius: '4px',
            }}
          >
            <EntityParamTag param="priority" value={get(row, ['priority'])} type="char" />
          </Flex>
          <Flex
            align="center"
            justify="center"
            className={`${getBackgroundColor(cell)}`}
            style={{
              height: '20px',
              borderRadius: '4px',
            }}
          >
            <EntityParamTag param="estimated_time" value={get(row, ['estimated_time'])} type="char" />
          </Flex>
          </Flex>
        </EditIssueForIssuesTable>
      ),
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: capitalize(t('wms.table.headers.status', 'status')),
      render: (cell, row) => (
        <EntityParamTag
          value={get(row, ['params', 'status']) || cell}
          param="status"
          type={'iconWithStatus'}
        />
      ),
    },
    {
      key: 'action',
      render: (_, record) => (
        <Flex justify='end'>
        <Dropdown
          placement='topCenter'
          menu={{
            items: issueItems,
            onClick: ({key, domEvent}) => {
              domEvent.stopPropagation()
              switch(key) {
                case '1':
                  setSelectedIssueForDelete(record.uuid)
                  break;
              }
            }
          }}
          >
          <BaseButton
            onClick={e => e.stopPropagation()}
            id='dotsVerticalButton'
            className="btnPrimary-outline"
            size="small"
          >
            <Icon path={mdiDotsVertical} size={1} />
          </BaseButton>
        </Dropdown>
        </Flex>
      ),
    },
   ]

    // finish add docs props


    const onChangeLifeTime = (data) => {
      const { lifeTime } = data;

      setCurrentExpitarionDate(lifeTime);
    };

    const setIsEdit = (e, closeEdit) => {
      const { id } = e.currentTarget;
      switch (id) {
        case 'description':
          if (stateBtnDescription.isEdit && !closeEdit) {
            onChangeData('description', refDescription.current?.trim());
          }
          refDescription.current = getFromParams('description');
          setStateBtnDescription((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
          break;
        case 'title':
          if (stateBtnTitle.isEdit && !closeEdit) {
            onChangeData('title', refTitle.current?.trim());
          }
          refTitle.current = getFromParams('title');
          setStateBtnTitle((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
          break;
        case 'lifeTime':
          if (isEditLifeTime && !closeEdit) {
            onChangeData('lifeTime', currentExpirationDate);
          }
          setCurrentExpitarionDate(getFromParams('lifeTime'));
          setIsEditLifeTime((prev) => !prev);
          break;
        case 'versionNumber':
          if (stateBtnNumber.isEdit && !closeEdit) {
            onChangeData('versionNumber', versionNumber);
          }
          setVersionNumber(getFromParams('versionNumber'));
          setStateBtnNumber((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
          break;
        case 'result':
          if (stateBtnResult.isEdit && !closeEdit) {
            onChangeData('result', refResult.current?.trim());
          }
          refResult.current = getFromParams('result', '');
          setStateBtnResult((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
          break;
        default: break;
      }
    };

    const onChangeTextArea = ({ id, value }) => {
      switch (id) {
        case 'title':
          refTitle.current = value;
          if (!stateBtnTitle?.isValid || !value.trim()?.length) {
            setStateBtnTitle((prev) => ({ ...prev, isValid: value.trim()?.length }));
          }
          break;
        case 'description':
          refDescription.current = value;
          if (!stateBtnDescription?.isValid) {
            setStateBtnDescription((prev) => ({ ...prev, isValid: true }));
          }
          break;
        case 'result':
          refResult.current = value;
          if (!stateBtnResult?.isValid) {
            setStateBtnResult((prev) => ({ ...prev, isValid: value.length }));
          }
          break;
        default: break;
      }
    };

    useEffect(() => {
      console.log(connectedIssuesData);
      
    }, [connectedIssuesData])

    const onChangeNumber = (e) => {
      const value = e.target.value.replace(/[a-zа-яё]/gi, '')?.trim();
      setVersionNumber(value);
      if (!stateBtnNumber?.isValid || !value.trim()?.length) {
        setStateBtnNumber((prev) => ({ ...prev, isValid: value.trim()?.length }));
      }
    };

    const {
      disabledFull,
    } = useMemo(() => ({
      disabledFull: disabledView.includes('fullEdit'),
    }), [disabledView]);

    useEffect(() => {
      setVersionNumber(getFromParams('versionNumber'));
    }, [getFromParams('versionNumber')]);

    useEffect(() => {
      if (vectorData?.uuid) {
        if (stateBtnDescription.isEdit) {
          setStateBtnDescription(defaultStateBtn);
        }
        if (stateBtnTitle.isEdit) {
          setStateBtnTitle(defaultStateBtn);
        }
        if (stateBtnNumber.isEdit) {
          setStateBtnNumber(defaultStateBtn);
        }
        if (stateBtnResult.isEdit) {
          setStateBtnResult(defaultStateBtn);
        }
      }
    }, [vectorData?.uuid]);

    const hideViewCreateIssueModal = ['assignedProject', 'assignedVector'];
    return (
      <>
        {getFromParams('type') === 'version' && (
          <>
            <div className="flex items-center py-1 px-1">
              <div className="wrapper-title pb-0 flex items-center">
                <UnSaveDataConfirmNew dataIsNotSave={stateBtnNumber.isEdit && stateBtnNumber.isValid}>
                  <EditButton
                    id="versionNumber"
                    isEditFlag={stateBtnNumber.isEdit}
                    disabled={(!stateBtnNumber.isValid && stateBtnNumber.isEdit) || disabledFull}
                    onClick={setIsEdit}
                  />
                </UnSaveDataConfirmNew>
                {`${capitalize(t('wms.labels.version_number'))}:`}
              </div>
              <div className="grow wrapper-description pt-0 pl-0 ml-2">
                <Input
                  maxLength={25}
                  autoSize
                  type="text"
                  placeholder="Enter number"
                  value={versionNumber}
                  onChange={onChangeNumber}
                  bordered={stateBtnNumber.isEdit}
                  disabled={!stateBtnNumber.isEdit}
                />
              </div>
            </div>

            <hr className="my-2" />
          </>
        )}

        <Row gutter={10} justify={'start'} className="px-1 pt-1 pb-2 gap-10">
          <Col className="flex items-stretch">
            <div className="flex flex-col items-start pt-1">
              <div className="wrapper-title pb-0">
                {`${capitalize(t('wms.status.title', 'status'))}:`}
              </div>
              <div className="grow wrapper-description pt-0 pl-0">
                <DropDownParamsEntity
                  type="vector_status"
                  disabled={disabledFull}
                  currentSelect={getFromParams('status')}
                  dropDownCallback={(value) => onChangeData('status', value?.vector_status)}
                />
              </div>
            </div>
          </Col>
          <Col className="flex items-stretch">
            <div className="flex flex-col items-start pt-1">
              <div className="wrapper-title pb-0">
                {`${capitalize(t('wms.priority.title', 'priority'))}:`}
              </div>
              <div className="grow wrapper-description pt-0 pl-0">
                <DropDownParamsEntity
                  type="priority"
                  disabled={disabledFull}
                  currentSelect={getFromParams('priority')}
                  dropDownCallback={(value) => onChangeData('priority', value?.priority)}
                />
              </div>
            </div>
          </Col>
          {/* <Col className="flex items-stretch">
            <div className="flex flex-col items-start pt-1">
              <div className="wrapper-title pb-0">
                {`${capitalize(t('wms.vectors.type.title', 'type'))}:`}
              </div>
              <div className="grow wrapper-description pt-0 pl-0">
                <DropDownParamsEntity
                  type="vector_type"
                  disabled={disabledFull}
                  currentSelect={getFromParams('type')}
                  dropDownCallback={(value) => onChangeData('type', value?.vector_type)}
                />
              </div>
            </div>
          </Col> */}
          <Col className="flex items-stretch">
            <div className="flex flex-col items-start">
              <div className="wrapper-title pb-0 flex items-center">
                <span className="mr-1">{`${capitalize(t('wms.labels.expiration_date', 'expiration date'))}:`}</span>
                <UnSaveDataConfirmNew dataIsNotSave={isEditLifeTime}>
                  <EditButton
                    id="lifeTime"
                    isEditFlag={isEditLifeTime}
                    onClick={setIsEdit}
                    disabled={disabledFull}
                  />
                </UnSaveDataConfirmNew>
              </div>
              <div className={cn('grow wrapper-description pt-0 pl-0', { 'pt-1': isEditLifeTime })}>
                {isEditLifeTime ? (
                  <DateLeftEdit
                    expirationDate={currentExpirationDate}
                    onChangeFieldCallback={onChangeLifeTime}
                  />
                ) : (
                  <DateLeft
                    date={get(vectorData, 'params.lifeTime', '')}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>

        <hr className="my-2" />

        <div className="flex items-center py-1 px-1">
          <div className="wrapper-title pb-0 flex items-center">
            <UnSaveDataConfirmNew
              id="vectorTitle"
              dataIsNotSave={stateBtnTitle.isValid && stateBtnTitle.isEdit}
            >
              <EditButton
                id="title"
                isEditFlag={stateBtnTitle.isEdit}
                disabled={(!stateBtnTitle.isValid && stateBtnTitle.isEdit) || disabledFull}
                onClick={setIsEdit}
              />
            </UnSaveDataConfirmNew>
            {`${capitalize(t('wms.labels.title', 'Title'))}:`}
          </div>
          <div className="grow wrapper-description title-description pt-0 pl-0 ml-2">
            <SpecialTextArea
              id="title"
              placeholder="Enter title"
              maxLength={128}
              defaultValue={getFromParams('title')}
              isEdit={stateBtnTitle.isEdit}
              onChange={onChangeTextArea}
            />
          </div>
        </div>

        <hr className="my-2" />

        <div className="flex items-center w-full px-1">
          <div className="wrapper-title pb-0 flex items-center">
            <EditButton
              id='editAssignedUsersButton'
              disabled={disabledFull}
              onClick={() => setEditAssignUsers(true)}
            />
            <AssigningUsersRoot
              header="Assigning users"
              isOpen={isEditAssignUsers}
              setIsOpen={setEditAssignUsers}
              onUpdateUsers={onChangeAssignedUsers}
              needUpdateProjectUsers
              placement="left"
              isModal
              globalOptions={{
                uuids: get(dataProject, 'projectData.users', [])?.map((el) => getUuid(el)),
                roles: get(dataProject, 'projectData.users', []),
                defaultColumn: 'project',
              }}
              tabs={{
                allConfig: {
                  label: 'All',
                  value: 'all',
                  textSelectButton: 'Add to project/vector',
                  showSections: ['button-add-user'],
                },
                projectConfig: {
                  label: 'Project',
                  value: 'project',
                  textSelectButton: 'Add to vector',
                  defaultItems: get(dataProject, 'projectData.users', []),
                  showSections: ['string-role', 'button-add-user'],
                },
              }}
              usersConfig={{
                label: 'Vector users',
                showSections: ['string-role', 'button-remove-user'],
                textDeleteButton: 'Remove users',
                defaultItems: get(defaultVectorData, 'visibleUsers', []),
              }}
              projectUuid={get(dataProject, 'projectData.uuid', '')}
              partition={partitionType}
            />
            <Row
              onClick={() => !disabledFull && setEditAssignUsers((prev) => !prev)}
              style={{ cursor: 'pointer' }}
            >
              {`${capitalize(t('wms.labels.assigned_users'))}:`}
            </Row>
          </div>
          <div className="grow wrapper-description ml-2">
            <WrapperAvatarsGroupForProjectWithHook
              actors={get(defaultVectorData, 'visibleUsers', []) || []}
              avatarSize={28}
              popoverTrigger="click"
              popoverPlacement="right"
              checkProjectRole
            />
          </div>
        </div>

        <hr className="my-2" />

        <div className="flex  flex-col items-start w-full px-1 mb-2">
          <div className="wrapper-title pb-0 flex items-center">
            <UnSaveDataConfirmNew
              dataIsNotSave={stateBtnDescription.isEdit && stateBtnDescription.isValid}
            >
              <EditButton
                id="description"
                isEditFlag={stateBtnDescription.isEdit}
                disabled={(!stateBtnDescription.isValid && stateBtnDescription.isEdit) || disabledFull}
                onClick={setIsEdit}
              />
            </UnSaveDataConfirmNew>
            {`${capitalize(t('wms.labels.need_done', 'what needs to be done'))}:`}
          </div>
          <div className={cn('grow wrapper-description pl-0 w-full', { 'mt-1': stateBtnDescription.isEdit })}>
            <SpecialTextArea
              id="description"
              className={cn({ 'min-height-50': stateBtnDescription.isEdit, 'min-h-0': !stateBtnDescription.isEdit })}
              defaultValue={getFromParams('description')}
              isEdit={stateBtnDescription.isEdit}
              onChange={onChangeTextArea}
            />
          </div>
        </div>

        <hr className="my-2" />

        <div className="flex  flex-col items-start w-full px-1 mb-2">
          <div className="wrapper-title pb-0 flex items-center">
            <UnSaveDataConfirmNew dataIsNotSave={stateBtnResult.isValid && stateBtnResult.isEdit}>
              <EditButton
                id="result"
                isEditFlag={stateBtnResult.isEdit}
                disabled={(!stateBtnResult.isValid && stateBtnResult.isEdit) || disabledFull}
                onClick={setIsEdit}
              />
            </UnSaveDataConfirmNew>
            {`${capitalize('Result')}:`}
          </div>
          <div className={cn('grow wrapper-description pl-0 w-full', { 'mt-1': stateBtnResult.isEdit })}>
            <SpecialTextArea
              id="result"
              className={cn({ 'min-height-50': stateBtnResult.isEdit, 'min-h-0 mb-1': !stateBtnResult.isEdit })}
              defaultValue={getFromParams('result', '')}
              isEdit={stateBtnResult.isEdit}
              onChange={onChangeTextArea}
            />
          </div>
        </div>
        {/* add listofiisues */}
        <hr className="my-2" />
         {addIssueModal &&
                <Modal
                title="Add Issue"
                centered
                open={addIssueModal}
                onOk={() => changeIssuesList(multiListIssues)}
                onCancel={() => setAddIssueModal(false)}
                width={1000}
              >
              <NewIssuesTable
              isGlobalDisabled={isGlobalDisabled}
              newCurrentPage={newCurrentPage}
              countOfData={countOfData}
              partitionType={partitionType}
              getIssue={getIssue}
              getIssues={getIssues}
              getIssuesRequestSetting={getIssuesRequestSetting}
              filterConfig={filterConfig}
              filterCallback={filterCallback}
              onChangeRowCallback={() => {}}
              newIssueSuccessCallback={newIssueSuccessCallback}
              listIssuesRelated={listIssuesRelated}
              multiListIssues={multiListIssues}
              setMultiListIssues={setMultiListIssues}
              projectUUID={projectUUID}
              />
            </Modal>
          }
          <Row className="px-1 mt-4 mb-2">
          <Col span={24}>
          <Collapse ghost>
            <Collapse.Panel header={"Activities Dashboard"}>
            <WrapperActivitiesDashboard
                defaultCommentsCount={defaultCommentsCount}
                entityType="vector"
                parentUUID={uuid}
                partition={partitionType}
                tabs={activitiesTabs}
                clickDashboardButton={clickDashboardButtonHandler}
              />
            </Collapse.Panel>
          </Collapse>
            
          </Col>
        </Row>
          <Flex className='gap-2 my-2 items-center ' onClick={(e) => e.stopPropagation()}>
                  <h6 className="mt-2">Workblock Issues</h6>
                    <InfoComponent
                      tooltipPlacement='topLeft'
                      tooltipTitle={connectedIssuesInfo}
                    />
                    <WrapperAddIssue
                    hideView={['assignedProject', 'assignedVector']}
                    partitionType={partitionType}
                    // createIssueCallback={newIssueSuccessCallback}
                    createIssueCallback={createConnectedIssueCallback}
                    issueForProject={false}
                    customTitleOpenModalBtn={"Create Workblock Issue"}
                    // hideView={hideViewCreateIssueModal}
                    disableBtn={isGlobalDisabled}
                    defaultIssueState={{
                      partition: partitionType,
                    }}
                  />
                  {connectedIssuesData && (
                    <BaseButton
                      id='addIssueButton'
                      size="small"
                      className={`customAntOutPrimaryBtn ${(!connectedIssuesData || connectedIssuesData.length <= 0) && 'hidden'}`}
                      type="primary"
                      ghost
                      onClick={() => routeNavigateTo('/pm/projects/issuesnew', {activeProject: projectUUID, activeVector: vectorData.uuid})}
                    >To Workblock Issues</BaseButton>
                  )}
                  {/* WORKBLOCK ISSUES TRANSFER */}
                  {/* {(connectedIssuesData && connectedIssuesData.length > 0)
                    && (
                        <DragTasksWrapper
                          partition={partitionType}
                          parent={uuid}
                          updateCallback={dragIssueCallback}
                        />
                  )} */}
                </Flex>
                { connectedIssuesData && 
                <Flex className=' py-2 flex-col gap-2 mb-2 border-b-[#e9e9e9] border-b-[1px]'>
                    <span className='w-fit text-[#888888] py-1 px-3 rounded-md border-[#888888] border-[1px]'>Total tickets: {connectedIssuesData.length}</span>
                    <Flex gap={'1rem'}>
                    <Tooltip
                      title={getTooltipIssues(connectedIssuesData.filter(item => issueStatuses['Opened'].map(item => item.key).includes(item.status)), "Opened")}
                      className={connectedIssuesData.filter(item => issueStatuses['Opened'].map(item => item.key).includes(item.status)).length === 0 && "hidden"}
                      >
                        <span className='w-fit text-[#51a5ff] py-1 px-3 bg-[#e2f6ff] rounded-md border-[#51a5ff] border-[1px]'>
                          Opened: {connectedIssuesData.filter(
                          item => issueStatuses['Opened'].map(item => item.key).includes(item.status)).length}
                        </span>
                    </Tooltip>
                    <Tooltip
                      title={getTooltipIssues(connectedIssuesData.filter(item => issueStatuses['On Development'].map(item => item.key).includes(item.status)), "On Development")}
                      className={connectedIssuesData.filter(item => issueStatuses['On Development'].map(item => item.key).includes(item.status)).length === 0 && "hidden"}
                      >       
                    <span className='w-fit text-[#5676f3] py-1 px-3 bg-[#dde7fa] rounded-md border-[#5676f3] border-[1px]'>
                      On Development: {connectedIssuesData.filter(
                      item => issueStatuses['On Development'].map(item => item.key).includes(item.status)).length}
                    </span>
                    </Tooltip>   
                    <Tooltip 
                      title={getTooltipIssues(connectedIssuesData.filter(item => issueStatuses['Testing'].map(item => item.key).includes(item.status)), "Testing")}
                      className={connectedIssuesData.filter(item => issueStatuses['Testing'].map(item => item.key).includes(item.status)).length === 0 && 'hidden'}
                      >  
                    <span className='w-fit text-[#f9a972] py-1 px-3 bg-[#fefaf5] rounded-md border-[#f9a972] border-[1px]'>
                    Testing: {connectedIssuesData.filter(
                      item => issueStatuses['Testing'].map(item => item.key).includes(item.status)).length}
                    </span>
                    </Tooltip>
                    <Tooltip
                     title={getTooltipIssues(connectedIssuesData.filter(item => issueStatuses['Purgatory'].map(item => item.key).includes(item.status)), "Purgatory")}
                     className={connectedIssuesData.filter(item => issueStatuses['Purgatory'].map(item => item.key).includes(item.status)).length === 0 && "hidden"}
                    >
                    <span className='w-fit text-[#888888] py-1 px-3 bg-[#f9f9f9] rounded-md border-[#888888] border-[1px]'>
                    Purgatory: {connectedIssuesData.filter(
                      item => issueStatuses['Purgatory'].map(item => item.key).includes(item.status)).length}
                    </span>
                    </Tooltip>
                    <Tooltip
                      title=
                       {getTooltipIssues(connectedIssuesData.filter(item => issueStatuses['Closed'].map(item => item.key).includes(item.status)), "Closed")}
                       className={connectedIssuesData.filter(item => issueStatuses['Closed'].map(item => item.key).includes(item.status)).length === 0 && "hidden"}
                      >
                    <span className='w-fit text-[#4d4d4d] py-1 px-3 bg-[#e9e9e9] rounded-md border-[#4d4d4d] border-[1px]'>
                      Closed: {connectedIssuesData.filter(
                      item => issueStatuses['Closed'].map(item => item.key).includes(item.status)).length}
                    </span>
                    </Tooltip>
                    </Flex>
                </Flex>}
                 { (connectedIssuesData && connectedIssuesData.length > 0) &&
                  <ConnectedIssuesTable
                  key={uuid}
                  issuesData={connectedIssuesData}
                  tableColums={issuesColums}
                  selectIssueCallback={selectIssueCallback}
                  connectedIssuesPage={connectedIssuesPage}
                  setConnectedIssuesPage={setConnectedIssuesPage}
                  totalCountConnectedIssues={totalCountConnectedIssues}
                  loading={connectedIssuesLoading}
                  />} 
      
          {addDocumentModal &&
          <Modal
            title="Attach Document"
            centered
            open={addDocumentModal}
            onOk={() => changeDocumentList(multiListDocuments)}
            onCancel={() => setAddDocumentModal(false)}
            width={1000}
              >
              <NewDocsTable
              listDocumentsRelated={listDocumentsRelated}
              multiListDocuments={multiListDocuments}
              setMultiListDocuments={setMultiListDocuments}
              partitionType={partitionType}
              />
          </Modal>
          }
          <Flex className='my-2'>
          <h6 className="mt-1 ">Linked Documents</h6>
          <BaseButton
            className="btnPrimary-outline mb-2 ml-2"
            onClick={() => setAddDocumentModal(true)}
            size="small"
            disabled={vectorData && vectorData?.params?.status === "protected"}
          >
              Link Document
              </BaseButton>
               <BaseButton
                  className="btnPrimary-outline mb-2 ml-2"
                  onClick={() => {
                    createNewDoc("projectDocument")
                    setActiveDocumentType("projectDocument")
                  }}
                  size="small"
                  disabled={vectorData && vectorData?.params?.status === "protected"}
             >
              Create Linked Document
              </BaseButton>
              <BaseButton
                  className="btnPrimary-outline mb-2 ml-2"
                  onClick={() => {
                    createNewDoc("projectIdea")
                    setActiveDocumentType("projectIdea")
                  }}
                  size="small"
                  disabled={vectorData && vectorData?.params?.status === "protected"}
             >
              Create Linked Note
              </BaseButton>
              <BaseButton
                  className="btnPrimary-outline mb-2 ml-2"
                  onClick={() => {
                    createNewDoc("projectSpreadsheet")
                    setActiveDocumentType("projectSpreadsheet")
                  }}
                  size="small"
                  disabled={vectorData && vectorData?.params?.status === "protected"}
             >
              Create Linked Spreadsheet
              </BaseButton>

          </Flex>
            {documentsData &&
             <Table
              columns={docsColums}
              dataSource={documentsData}
              loading={loadingDocuments}
              pagination={documentsData.length > 5 ? {
                current: documentsPage,
                pageSize: 5,
                total: documentsData.length,
                onChange: (page) => {
                  setDocumentsPage(page);
                },
                style: { margin: '5px 0'},
              } : false}
              size='small'
              onRow={(record) => ({
                onClick: () => {
                  selectDocumentCallback(record.uuid, record.entity_type)
                },
                style: {cursor: 'pointer'},
              })}
              />}
          <Flex className='gap-2 my-2 items-center'>
              <h6 className="mt-2">Linked Issues</h6>
              <InfoComponent
                tooltipPlacement='topLeft'
                tooltipTitle={relatedIssuesInfo}
              />
            <BaseButton
              className="btnPrimary-outline "
              onClick={() => setAddIssueModal(true)}
              size="small"
              disabled={vectorData && vectorData?.params?.status === "protected"}
            >
              Link Issue
            </BaseButton>
            <WrapperAddIssue
              partitionType={partitionType}
              // createIssueCallback={newIssueSuccessCallback}
              createIssueCallback={addNewIssueInVector}
              issueForProject={true}
              customTitleOpenModalBtn={"Create Linked Issue"}
              hideView={hideViewCreateIssueModal}
              disableBtn={isGlobalDisabled}
              defaultIssueState={{
                partition: partitionType,
              }}
            />  
          </Flex>
      {issuesData &&
           <Table
           columns={issuesColums}
           dataSource={issuesData}
           loading={loadingIssues}
           pagination={issuesData.length > 5 ? {
            current: issuesPage,
            pageSize: 5,
            total: issuesData.length,
            onChange: (page) => {
              setIssuesPage(page);
            },
            style: { margin: '5px 0'},
          }: false}
           size='small'
           onRow={(record) => ({
             onClick: () => selectIssueCallback(record.uuid),
             style: {cursor: 'pointer'},
           })}
           />
      }
              
        
      </>
    );
  }

  WorkBlockInfoViewForVectorsTable.propTypes = {
    activitiesTabs: arrayOf(object),
    defaultCommentsCount: object,
    defaultVectorData: object,
    onChangeData: func,
    getFromParams: func,
    disabledView: array,
    partitionType: string,
    dataProject: object,
    onChangeAssignedUsers: func,
    clickDashboardButtonHandler: func,
    //add props
    countOfData: number,
    getIssuesRequestSetting: func,
    newIssueSuccessCallback: func,
    onChangeRowCallback: func,
    filterConfig: object,
    filterCallback: func,
    getIssues: func,
    isGlobalDisabled: bool,
    newCurrentPage: number,
    getIssue: func,
    disabledMultiEdit: bool,
    // add vector
    vectorData: object,
    vectorFetching: bool,
    uuid: string,
    //add modals props
    activeDocumentModal: bool,
    setActiveDocumentModal: func,
    activeDocument: string,
    setActiveDocument: func,
    activeDocumentType: string,
    setActiveDocumentType: func,
    activeIssue: string,
    setActiveIssue: func,
    activeIssueModal: bool,
    setActiveIssueModal: func,
    saveDocument: bool,
  };

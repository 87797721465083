import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { Button } from "antd";

export const ErrorBlock = ({ callback }) => {
  const handleReload = () => {
    callback();
  };

  return (
    <div className="quill-disable-block-wrapper ">
      <div className="content">
        <div>
          Something went wrong! You have been disconnected from the server with
          the document. Try to reload and connect again?
        </div>
        <div style={{ marginTop: "16px", textAlign: "center" }}>
          <Button
            onClick={handleReload}
            className="btnPrimary-outline help-btn"
          >
            Reload the document
          </Button>
        </div>
      </div>
    </div>
  );
};

ErrorBlock.propTypes = {
  callback: PropTypes.func,
};

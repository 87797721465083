/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// import { createSelector } from 'reselect';

import moment from 'moment';

const initialState = {
  projectsKPM: [],
  lastComputedTime: '',
  computing: false,
};

const projectsKPMSlice = createSlice({
  name: 'PROJECTS_KPM',
  initialState,
  reducers: {
    ADD_PROJECT_KPM: (state, action) => {
      const { uuid, value, date } = action.payload || {};
      const formatValue = typeof value === 'number' ? Number((value).toFixed(1)) : value;
      const newKPM = { uuid, value: formatValue, date };
      const foundIndex = state.projectsKPM.findIndex((item) => item.uuid === uuid);

      if (foundIndex !== -1) {
        state.projectsKPM[foundIndex] = newKPM;
      } else state.projectsKPM.push(newKPM);
    },

    DELETE_PROJECT_KPM: (state, action) => {
      const { uuid } = action.payload || {};
      const foundIndex = state.projectsKPM.findIndex((item) => item.uuid === uuid);
      if (foundIndex !== -1) state.projectsKPM.splice(foundIndex, 1);
    },

    SET_PROJECTS_KPM: (state, action) => {
      state.projectsKPM = Array.isArray(action.payload) ? action.payload : [];
    },

    SET_KPM_COMPUTED_TIME: (state, action) => {
      if (action.payload === 'reset') state.lastComputedTime = '';
      else state.lastComputedTime = moment(new Date()).format('DD-MM-YYYY HH:mm:ss');
    },

    SET_KPM_COMPUTING: (state, action) => {
      state.computing = action.payload;
    },

    CLEAR_PROJECTS_KPM: (state) => {
      state.projectsKPM = [];
      state.lastComputedTime = '';
      state.computing = false;
    },
  },
});

const storePath = (state) => state?.ProjectsKPM;
export const selectProjectsKPM = (state) => storePath(state)?.projectsKPM;
export const selectProjectsKPMComputing = (state) => storePath(state)?.computing;
export const selectProjectsKPMComputedTime = (state) => storePath(state)?.lastComputedTime;

export const {
  ADD_PROJECT_KPM,
  DELETE_PROJECT_KPM,
  SET_PROJECTS_KPM,
  SET_KPM_COMPUTED_TIME,
  SET_KPM_COMPUTING,
  CLEAR_PROJECTS_KPM,
} = projectsKPMSlice.actions;

export default projectsKPMSlice.reducer;

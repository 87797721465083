import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getModalAuthSessionFlag,
  getPrevModalAuthSessionFlag,
  TOGGLE_MODAL_GET_AUTH_SESSION,
} from '../slicers/getAuthSessionModalSlicer';
import { antNotification } from '../../MainUtils';
import { getSessionTokenFor, sessionNameFor } from '../../api/appConfig';
import { removeCookie } from '../../54origins/cookies54origins';

const useAuthSessionHook = (funcForRestart) => {
  const dispatch = useDispatch();

  const modalAuthSessionFlag = useSelector(getModalAuthSessionFlag);
  const prevModalAuthSessionFlag = useSelector(getPrevModalAuthSessionFlag);

  const [funcIsClicked, setFuncIsClicked] = useState(false);

  const checkAuthSessionAndRunFuncForRestart = (funcIsClick) => {
    setFuncIsClicked(!!funcIsClick);

    if (getSessionTokenFor.auth()) {
      setFuncIsClicked(false);
      funcForRestart();
    } else {
      dispatch(TOGGLE_MODAL_GET_AUTH_SESSION());
    }
  };

  const checkRequestError = (err, callback) => {
    const { status, message } = err || {};

    antNotification('error', `${status} ${message}`);

    if (status === 403 || status === 401) {
      removeCookie(sessionNameFor.auth);

      callback?.();
    }
  };

  function checkRequestErrorAndGetAuthSession(err, callback) {
    checkRequestError(err, () => {
      checkAuthSessionAndRunFuncForRestart(true);
      callback?.();
    });
  }

  useEffect(() => {
    // console.log('useEffect modalAuthSessionFlag', modalAuthSessionFlag);
    // console.log('prevModalAuthSessionFlag', prevModalAuthSessionFlag);
    // console.log('!modalAuthSessionFlag', !modalAuthSessionFlag);
    // console.log('funcIsClicked', funcIsClicked);

    if (prevModalAuthSessionFlag && !modalAuthSessionFlag && getSessionTokenFor.auth() && funcIsClicked) {
      checkAuthSessionAndRunFuncForRestart();
    }
  }, [modalAuthSessionFlag]);

  return {
    checkAuthSessionAndRunFuncForRestart,
    checkRequestError,
    checkRequestErrorAndGetAuthSession,
  };
};

export default useAuthSessionHook;

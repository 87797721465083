/* eslint-disable no-confusing-arrow */
import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { oneOf } from 'prop-types';

import {
  getMe,
  isListOfProjectFetching,
  isListOfPersonalProjectsFetching,
} from '../../../selectors/selectors';

import {
  defaultSelectedFilterTags,
  showAllSelectedFilterTags,
} from './projectsFilterHelper';
import { partitionNamesConfig } from '../../../../api/appConfig';

const setDefaultSelectedFilterTags = (partition) => defaultSelectedFilterTags(partition);
// const setInitialProjectType = (initial, def) => typeof initial === 'boolean' ? initial : def;

function useProjectsFilter(partition) {
  const userUUID = useSelector(getMe);
  const generalProjectsFetching = useSelector(isListOfProjectFetching);
  const personalProjectsFetching = useSelector(isListOfPersonalProjectsFetching);

  const defaultShowGeneral = useMemo(
    () => defaultSelectedFilterTags(partition)
      .findIndex((i) => i?.key === 'partition' && (i?.value === 'general' || i?.value === 'all-partition')) !== -1,
    [partition],
  );
  const defaultShowPersonal = useMemo(
    () => defaultSelectedFilterTags(partition)
      .findIndex((i) => i?.key === 'partition' && (i?.value === 'personal' || i?.value === 'all-partition')) !== -1,
    [partition],
  );

  const [loadingIndicator, setLoadingIndicator] = useState(true);
  const [showGeneralProjects, setShowGeneralProjects] = useState(() => defaultShowGeneral);
  const [showPersonalProjects, setShowPersonalProjects] = useState(() => defaultShowPersonal);

  const [requestConfig, setRequestConfig] = useState(null);
  // console.log('requestConfig:', requestConfig);
  const [selectedAreasTags, setSelectedAreasTags] = useState([]);
  const [selectedFilterTags, setSelectedFilterTags] = useState(
    setDefaultSelectedFilterTags(partition),
  );
  const [metricParameters, setMetricParameters] = useState([]);
  // console.log('metricParameters:', metricParameters);
  const [searchTerm, setSearchTerm] = useState('');

  const updateConfigFromTags = (tags, resetConfig = false) => {
    const updatedConfig = {
      ...(!resetConfig && { ...requestConfig }),
      params: {
        ...(!resetConfig && { ...requestConfig.params }),
      },
    };

    const parameters = tags.reduce((prevTags, tag) => {
      const newTags = { ...prevTags };

      switch (tag.key) {
        case 'user': {
          if (tag.value === 'member') newTags.member = true;
          if (tag.value === 'creator') newTags.creator = true;
          if (tag.value === 'owner') newTags.owner = true;
          break;
        }

        case 'status': {
          const isIncluded = tag?.ruleOfTag === 'included'
          || (tag?.ruleOfTag !== 'included' && tag?.ruleOfTag !== 'excluded');

          if (isIncluded) newTags.status = [...newTags.status, tag.value];
          else newTags.status__not = [...newTags.status__not, tag.value];
          break;
        }

        case 'partition': {
          if (tag.value === 'general') newTags.type = 'general';
          if (tag.value === 'personal') newTags.type = 'personal';
          if (tag.value === 'all-partition') newTags.type = 'all';
          break;
        }

        case 'metric': {
          newTags.metrics = [...newTags.metrics, tag.value];
          break;
        }

        default: break;
      }

      return newTags;
    }, {
      member: false,
      creator: false,
      owner: false,
      status: [],
      status__not: [],
      type: '',
      metrics: [],
    });
    // console.log('!!! parameters:', parameters);

    if (parameters.member) updatedConfig.params.usersSearch = [userUUID];
    else delete updatedConfig?.params?.usersSearch;

    if (parameters.creator) updatedConfig.actor = userUUID;
    else delete updatedConfig?.actor;

    if (parameters.owner) updatedConfig.owner = userUUID;
    else delete updatedConfig?.owner;

    if (parameters.status.length > 0) updatedConfig.params.status = parameters.status;
    else delete updatedConfig?.params?.status;

    if (parameters.status__not.length > 0) {
      updatedConfig.params.status__not = parameters.status__not;
    } else delete updatedConfig?.params?.status__not;

    switch (parameters.type) {
      case 'general': {
        setShowGeneralProjects(true);
        setShowPersonalProjects(false);
        break;
      }
      case 'personal': {
        setShowPersonalProjects(true);
        setShowGeneralProjects(false);
        break;
      }
      case 'all': {
        setShowGeneralProjects(true);
        setShowPersonalProjects(true);
        break;
      }
      default: break;
    }

    if (parameters.metrics.length > 0) setMetricParameters(parameters.metrics);
    else setMetricParameters([]);

    return updatedConfig;
  };

  const filterByMetricParameters = (projects = []) => {
    let filteredProjects = [...projects];

    metricParameters.forEach((metric) => {
      switch (metric) {
        case 'no-planning': {
          filteredProjects = filteredProjects.filter((project) => {
            const { gmPlanScore: { planScoreValue: score } = {} } = project || {};
            return score === 0;
          });
          break;
        }

        case 'expired-milestone': {
          filteredProjects = filteredProjects.filter((project) => {
            const { gmPlanScore: { planScoreValue: score } = {} } = project || {};
            return score === 100;
          });
          break;
        }

        case 'dev-score': {
          filteredProjects = filteredProjects.filter((project) => {
            const { issuesDevScore: { issuesScoringAVG: score } = {} } = project || {};
            return score < 1 || score >= 2;
          });
          break;
        }

        case 'outdated-score': {
          filteredProjects = filteredProjects.filter((project) => {
            const { outdatedDevScore: { outdatedScore: score } = {} } = project || {};
            return score === 0 || score >= 50;
          });
          break;
        }

        case 'low-tests-level': {
          filteredProjects = filteredProjects.filter((project) => {
            const { qaStructureScoring: { qaStructureScore: score } = {} } = project || {};
            return score < 5;
          });
          break;
        }

        case 'low-tests-efficiency': {
          filteredProjects = filteredProjects.filter((project) => {
            const { qaActivityScoring: { qaActivityScore: score } = {} } = project || {};
            return score < 10;
          });
          break;
        }

        case 'low-archive-level': {
          filteredProjects = filteredProjects.filter((project) => {
            const { archiveScoring: { archiveScore: score } = {} } = project || {};
            return score < 10;
          });
          break;
        }

        default: break;
      }
    });

    return filteredProjects;
  };

  const changeSelectedTagsAndConfig = (tags, resetConfig = false) => {
    const updatedConfig = updateConfigFromTags(tags, resetConfig);
    const newConfig = {
      ...updatedConfig,
      offset: 0,
    };
    // console.log('newConfig filters:', newConfig);

    if (resetConfig) setSelectedAreasTags?.([]);
    setSelectedFilterTags(tags);
    setRequestConfig(newConfig);
  };

  const onSelectFilterTagHandler = (tags) => {
    changeSelectedTagsAndConfig(tags);
  };

  const onResetFilterHandler = () => {
    changeSelectedTagsAndConfig(defaultSelectedFilterTags(partition), true);
    setSearchTerm?.('');
  };

  const onShowAllFilterHandler = () => {
    changeSelectedTagsAndConfig(showAllSelectedFilterTags(partition), true);
    setSearchTerm?.('');
  };

  //--------------------------------------------
  // Area tags

  const updateConfigFromAreaTags = (tags) => {
    const updatedConfig = {
      params: {},
      ...requestConfig,
      offset: 0,
    };

    const areasTags = tags.reduce((prevTags, tag) => {
      const newTags = { ...prevTags };

      switch (tag.key) {
        case 'areas': {
          newTags.areas = [...newTags.areas, tag.value];
          break;
        }
        default: break;
      }

      return newTags;
    }, { areas: [] });
    // console.log('filteredTags', filteredTags);

    if (areasTags.areas.length > 0) {
      updatedConfig.params.areas = areasTags.areas;
    } else delete updatedConfig?.params?.areas;

    return updatedConfig;
  };

  const onSelectAreaTagHandler = (tags) => {
    const updatedConfig = updateConfigFromAreaTags(tags);
    setSelectedAreasTags(tags);
    setRequestConfig(updatedConfig);
  };

  //--------------------------------------------
  // Search project by title

  const updateConfigOnSearch = (value) => {
    const updatedConfig = {
      ...requestConfig,
      search_data: {
        value,
        ignore_case: true,
        fields: { params: ['title'] },
      },
      offset: 0,
    };

    if (!value) delete updatedConfig?.search_data;

    return updatedConfig;
  };

  const onSearchCallback = (value) => {
    // const { value } = e.target;

    if (value) setSearchTerm(value);
    else setSearchTerm('');

    const updatedConfig = updateConfigOnSearch(value);
    setRequestConfig(updatedConfig);
  };

  const getLoadingIndicator = () => {
    if (showGeneralProjects && !showPersonalProjects) return generalProjectsFetching;
    if (showPersonalProjects && !showGeneralProjects) return personalProjectsFetching;
    return generalProjectsFetching || personalProjectsFetching;
  };

  useEffect(() => {
    setLoadingIndicator(getLoadingIndicator());
  }, [
    showGeneralProjects,
    showPersonalProjects,
    generalProjectsFetching,
    personalProjectsFetching,
  ]);

  return {
    requestConfig,
    setRequestConfig,
    loadingIndicator,
    showGeneralProjects,
    showPersonalProjects,
    selectedFilterTags,
    selectedAreasTags,
    updateConfigFromTags,
    filterByMetricParameters,
    searchTerm,
    onSearchCallback,
    onSelectAreaTagHandler,
    onSelectFilterTagHandler,
    onResetFilterHandler,
    onShowAllFilterHandler,
  };
}

useProjectsFilter.propTypes = {
  partition: oneOf([partitionNamesConfig.partition1, partitionNamesConfig.partition3]),
};

export default useProjectsFilter;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { capitalize, isEmpty, isNil } from 'lodash';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import TagsSettingList from '../../../../components/filter/TagsSettingList';

const defaultEntityTypes = [
  'issue',
  'vector',
  'projectGoal',
  'projectDocument',
  'projectIdea',
  'projectSpreadsheet',
  'testCycle',
  'testSuite',
];

function ActivityEntityTypeFilter({
  changeFilterHandler,
  setCurrentConfig,
  currentConfig,
}) {
  const { t } = useTranslation();

  const [currentFilter, setCurrentFilter] = useState(null);
  const [entityTypeConfig, setEntityTypeConfig] = useState(null);

  const handleSetSelectedFilters = (tags) => {
    setCurrentFilter(tags);
  };

  const createConfigFromSelectedTabs = (tabs = []) => {
    if (isEmpty(tabs)) {
      return setEntityTypeConfig({});
    }

    const entityTypes = tabs.map((e) => e.value);

    return setEntityTypeConfig({
      entity_type: entityTypes,
    });
  };

  useEffect(() => {
    if (currentFilter) {
      createConfigFromSelectedTabs(currentFilter);
    }
  }, [currentFilter]);

  const defaultTypeFilters = [
    {
      tags: [
        {
          value: 'all',
          label: capitalize(t('wms.filters.all', 'all')),
        },
      ],
      isReset: true,
    },
    {
      tags: [
        {
          key: 'entity_type',
          value: 'projectGoal',
          label: capitalize(t('wms.noun.goal.0', 'goal').trim()),
        },
        {
          key: 'entity_type',
          value: 'vector',
          label: capitalize(t('wms.filters.entity_vector', 'vector')),
        },
        {
          key: 'entity_type',
          value: 'issue',
          label: capitalize(t('wms.filters.entity_ticket', 'ticket')),
        },
        {
          key: 'entity_type',
          value: 'projectIdea',
          label: capitalize(t('wms.filters.entity_note', 'note')),
        },
        {
          key: 'entity_type',
          value: 'projectDocument',
          label: capitalize(t('wms.filters.entity_document', 'document')),
        },
        {
          key: 'entity_type',
          value: 'projectSpreadsheet',
          label: capitalize(t('wms.filters.entity_spreadsheet', 'spreadsheet')),
        },
        {
          key: 'entity_type',
          value: 'testCycle',
          label: capitalize(t('wms.filters.entity_testCycle', 'test cycle')),
        },
        {
          key: 'entity_type',
          value: 'testSuite',
          label: capitalize(t('wms.filters.entity_testCycle', 'test suite')),
        },
      ],
    },
  ];

  useEffect(() => {
    if (!isNil(entityTypeConfig) && !isEmpty(currentConfig)) {
      const config = {
        ...currentConfig,
        offset: 0,
      };

      if (isEmpty(entityTypeConfig)) {
        config.entity_type = defaultEntityTypes;
      } else {
        config.entity_type = entityTypeConfig.entity_type;
      }

      changeFilterHandler?.();
      setCurrentConfig(config);
    }
  }, [entityTypeConfig]);

  return (
    <Row>
      <Col span={24}>
        <div className="text-secondary">
          {`${capitalize(t('wms.labels.entity_filter', 'Entity filter'))}:`}
        </div>
      </Col>
      <Col span={24}>
        <TagsSettingList
          size="small"
          type="userTab"
          onSelectTags={handleSetSelectedFilters}
          // defaultSelectedTags={projectFilterTags}
          allTags={defaultTypeFilters}
          id="dashboardFilter"
        />
      </Col>
    </Row>
  );
}

ActivityEntityTypeFilter.propTypes = {
  changeFilterHandler: PropTypes.func,
  setCurrentConfig: PropTypes.func,
  currentConfig: PropTypes.object,
};

export default ActivityEntityTypeFilter;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Col, Flex, Modal, Switch,
} from 'antd';
import {
  mdiAccountSupervisorCircleOutline,
  mdiBellOutline,
  mdiChevronDown,
  mdiDebugStepOver,
} from '@mdi/js';
import { isNil, propOr } from 'ramda';
import Icon from '@mdi/react';

import '../../css/statusesTicket.scss';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { pick } from 'lodash';
import InfoSection from '../../../../containers/info/InfoSection';
import RadioButtons from '../../../../../components/RadioButtons/RadioButtons';
import IssueStatusesComponent from '../../IssueStatusesComponent';
import EntityParamTag from '../../../../../components/entityParamTag/EntityParamTag';

import { createParamsUsersFromUuids, getUuidsfromParamsUsers } from '../../../../../MainUtils';
import { capitalize } from 'lodash';
import { getMinDateExpired } from '../../issueInfo/utils';
import { getProjectParams, getProjectUUID } from '../../../../selectors/selectors';
import AssigningUsersRoot from '../../../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { partitionNamesConfig } from '../../../../../api/appConfig';

export default function IssueStatusCollapse({
  vectorActors,
  getClearVector,
  ticket,
  partitionType,
  hideCollapse = false,
  disabledBtnCollapse = false,
  customSelectedActorsReset,
  saveDataFromModalCallback,
  customStatus,
  hiddenModalAssigned = false,
  customSelectedActors,
  children,
  onChangeField,
}) {
  const { t } = useTranslation();

  // const vectorUUID = useSelector(getVectorUUID);

  const [collapseStatusFlag, setCollapseStatusFlag] = useState(false);
  const [statusValue, setStatusValue] = useState();
  const [selectedUsersOption, setSelectedUsersOption] = useState([]);
  const [notNotifyValue, setNotNotifyValue] = useState(false);
  const [modalFlag, setModalFlag] = useState('');
  const [vectorActors1, setVectorActors] = useState([]);

  const [isAssignOpen, setIsAssignOpen] = useState(false);

  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);

  const toggleCollapseStatus = () => {
    if (!disabledBtnCollapse) {
      setCollapseStatusFlag(!collapseStatusFlag);
    }
  };

  const translateStatus = () => {
    switch (ticket?.status?.toLowerCase()) {
      // case 'fixing':
      case 'created':
      case 'developing':
      case 'reopened':
      case 'testing':
      case 'bugs':
      case 'not enough info':
      case 'not reproducible':
      case 'review':
      case 'ready for deploy':
      case 'deployed':
      case 'abandoned':
      case 'backlog':
      case 'self testing':
      case 'in progress':
      case 'paused':
      case 'blocked':
      case 'closed': return capitalize(t(`wms.status.${ticket?.status}`, `${ticket?.status}`));
      case 'test failed': return capitalize(t('wms.status.reopened', 'reopened'));
      case 'test': return capitalize(t('wms.status.ready for test', 'ready for test'));
      default: return ticket?.status;
    }
  };

  const statusOptionsInPrivate = [
    {
      value: 'created',
      label: capitalize(t('wms.status.created', 'created')),
      className: 'radio-blue ',
    },
    {
      value: 'in progress',
      label: capitalize(t('wms.status.in progress', 'in progress')),
      className: 'radio-orange',
    },
    {
      value: 'backlog',
      label: capitalize(t('wms.status.backlog', 'backlog')),
      className: 'radio-gray',
    },
    {
      value: 'closed',
      label: capitalize(t('wms.status.closed', 'closed')),
      className: 'radio-red',
    },
  ];

  const changeDropDownCallback = (status, value) => {
    setStatusValue(value);
    onChangeField({
      date_start: '',
      date_end: '',
      lifeTime: moment().add(2, 'week').format('YYYY-MM-DD'),
      status: value,
    });
  };

  const changeStatus = (status, value) => {
    switch (value) {
      case 'review':
      case 'test':
      case 'bugs':
      case 'acquainted':
      case 'not enough info':
      case 'reopened':
      case 'accepted':
      case 'ready for deploy': return hiddenModalAssigned
        ? changeDropDownCallback(status, value)
        : setModalFlag(value);
      case 'closed':
      case 'deployed':
      case 'abandoned':
        setStatusValue(value);
        return onChangeField({
          date_start: moment().format('YYYY-MM-DD'),
          date_end: moment().format('YYYY-MM-DD'),
          lifeTime: getMinDateExpired(),
          status: value,
        });
      case 'backlog':
        setStatusValue(value);
        return onChangeField({
          date_start: moment().format('YYYY-MM-DD'),
          date_end: moment().format('YYYY-MM-DD'),
          lifeTime: getMinDateExpired(1005),
          status: value,
        });
      default: return changeDropDownCallback(status, value);
    }
  };

  const saveActorsCallback = (value) => {
    setSelectedUsersOption(createParamsUsersFromUuids(value, params));
  };

  const handleCancelModal = () => {
    setNotNotifyValue(false);
    // setSelectedUsersOption([]);
    setModalFlag('');
  };

  const saveDataFromModal = () => {
    const paramsEdit = {
      entity_type: 'issue',
      entity_uuid: ticket?.uuid,
      params: {
        status: modalFlag,
      },
    };
    if (selectedUsersOption.length !== 0) {
      paramsEdit.params.usersSearch = getUuidsfromParamsUsers(selectedUsersOption);
      paramsEdit.params.users = selectedUsersOption;
    }
    const notifyValue = !notNotifyValue ? selectedUsersOption : [];

    saveDataFromModalCallback(paramsEdit, notifyValue);
    setNotNotifyValue(false);
    changeDropDownCallback('status', modalFlag);
    // setSelectedUsersOption([]);
    setModalFlag('');
  };

  const [hover, setHover] = useState(false);

  const toggleHover = () => setHover(!hover);

  const viewModal = () => (
    <Modal
      open={modalFlag.length !== 0}
      className="custom-ant-modal"
      centered
      cancelText={capitalize(t('wms.verb.cancel', 'cancel'))}
      okButtonProps={{ disabled: selectedUsersOption.length === 0 }}
      onOk={saveDataFromModal}
      // footer={null}
      onCancel={handleCancelModal}
    >
      <Col span={24}>
        <div className="text-neutral-600 text-base">
          {t('wms.labels.change_of_status', 'Do you want to change your status, are you sure?')}
          <hr />
        </div>
        <div>
          <InfoSection
            titleComponent={(
              <div id='asdadas' className="flex">
                <span className="text-secondary">
                  {`${capitalize(t('wms.labels.assigned_users', 'assigned users'))}:`}
                </span>
                <span className="text-red-500 ml-1">*</span>
              </div>
            )}
            iconPath={mdiAccountSupervisorCircleOutline}
            customMargin="py-1"
          >

            <Flex className="w-full">
              <AssigningUsersRoot
                header="Assigning users"
                needUpdateProjectUsers
                isOpen={isAssignOpen}
                setIsOpen={setIsAssignOpen}
                onUpdateUsers={(data) => setSelectedUsersOption(data?.users?.map((el) => pick(el, 'uuid')))}
                globalOptions={{
                  uuids: projectParams?.usersSearch ?? [],
                  defaultColumn: 'project',
                }}
                placement="left"
                isPopover
                tabs={{
                  allConfig: {
                    label: 'All',
                    value: 'all',
                    textSelectButton: 'Add to issue',
                    showSections: ['button-add-user'],
                  },
                  projectConfig: {
                    label: 'Project',
                    value: 'project',
                    textSelectButton: 'Add to issue',
                    defaultItems: projectParams?.users ?? [],
                    showSections: ['string-role', 'button-add-user'],
                  },
                }}
                usersConfig={{
                  label: 'Issue users',
                  showSections: ['button-remove-user', 'string-role'],
                  textDeleteButton: 'Delete users',
                  defaultItems: getUuidsfromParamsUsers(selectedUsersOption) ?? [],
                }}
                wrapperProps={{ autoAdjustOverflow: true }}
                partition={partitionType}
                projectUuid={projectUUID}
                popoverText={(<span style={{ color: 'black' }}>Assigned users:</span>)}
              />
              <WrapperAvatarsGroupForProjectWithHook
                actors={getUuidsfromParamsUsers(selectedUsersOption) || []}
                avatarSize={28}
                popoverTrigger="click"
                popoverPlacement="right"
              />
            </Flex>
          </InfoSection>
        </div>

        <InfoSection
          title={`${capitalize(
            t('wms.labels.send_notification', 'send notification'),
          )}:`}
          iconPath={mdiBellOutline}
        >
          <Switch
            onChange={() => setNotNotifyValue(!notNotifyValue)}
            checked={!notNotifyValue}
            key="switch-primary"
            className="switch-primary ml-3"
            checkedChildren="ON"
            unCheckedChildren="OFF"
            size="small"
          />
        </InfoSection>
      </Col>
    </Modal>
  );

  useEffect(() => {
    if (ticket?.vector) {
      getClearVector(ticket?.vector)
        .then((data) => {
          const { users } = data[0].params;
          setVectorActors(getUuidsfromParamsUsers(users));
        });
    }
  }, []);

  useEffect(() => {
    if (ticket) {
      setStatusValue(propOr('', 'status', ticket));
      setSelectedUsersOption(ticket?.users);
    }
  }, [ticket]);

  useEffect(() => {
    if (!isNil(customStatus) && customStatus.length !== 0) {
      setStatusValue(customStatus);
    }
  }, [customStatus]);

  useEffect(() => {
    if (!isNil(customSelectedActors)) {
      setSelectedUsersOption(customSelectedActors);
    }
  }, [JSON.stringify(customSelectedActors)]);
  // console.log('ticket.status',ticket.status)

  if (partitionType === partitionNamesConfig.partition2) {
    return (
      <div className="pl-2 w-full">
        <RadioButtons
          options={statusOptionsInPrivate}
          value={statusValue}
          onChange={(value) => changeDropDownCallback('status', value)}
        />
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full justify-around">
        <Col className={`${hideCollapse ? 'hidden' : 'flex flex-col items-start justify-center'}`}>
          <div className="flex items-center text-secondary">
            <Icon
              path={mdiDebugStepOver}
              size={0.9}
            />
            <span className="ml-2">
              {`${capitalize(t('wms.labels.status', 'status'))}`}
            </span>
          </div>

          <div
            className={`${disabledBtnCollapse ? 'cursor-not-allowed' : 'cursor-pointer'} statuses-wrapper-issue ${ticket.status}`}
            onClick={toggleCollapseStatus}
            onMouseOver={toggleHover}
            onMouseOut={toggleHover}
          >
            <EntityParamTag
              param="status"
              value={ticket?.status}
              type="iconAndHideWrapper"
            />

            {translateStatus()}

            <Icon path={mdiChevronDown} size={0.7} className="ml-2" />
          </div>
        </Col>

        {children}
      </div>

      <div className={`${(!collapseStatusFlag && !hideCollapse) && 'hidden'} mt-1 w-full`}>
        <IssueStatusesComponent
          hideTitle
          currentStatus={statusValue}
          onChangeCallback={changeStatus}
        />
      </div>
      {modalFlag.length !== 0 && viewModal()}

    </>
  );
}

IssueStatusCollapse.propTypes = {
  getClearVector: PropTypes.func,
  vectorActors: PropTypes.array,
  ticket: PropTypes.object,
  partitionType: PropTypes.string,
  hideCollapse: PropTypes.bool,
  saveDataFromModalCallback: PropTypes.func,
  customSelectedActorsReset: PropTypes.bool,
  customStatus: PropTypes.string,
  hiddenModalAssigned: PropTypes.bool,
  customSelectedActors: PropTypes.array,
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fetching: false,
    userBoards: [],
};

const userBoardsSlice = createSlice({
    name: 'userBoardsSlice',
    initialState,
    reducers: {
        GET_USER_BOARDS_REQUEST(state) {
            state.fetching = true;
        },
        GET_USER_BOARDS_SUCCESS(state, action) {
            state.fetching = false;
            state.userBoards = action.payload;
        },
        GET_USER_BOARDS_FAILURE(state) {
            state.fetching = false;
        },
        SET_CHANGED_USER_BOARD(state, action) {
            const changedUserBoard = action.payload;
            const newUserBoards = state.userBoards?.filter((el) => el?.uuid !== changedUserBoard?.uuid);
            newUserBoards.push(changedUserBoard)
            state.userBoards = newUserBoards;
        }
    },
});

export const {
    GET_USER_BOARDS_REQUEST,
    GET_USER_BOARDS_SUCCESS,
    GET_USER_BOARDS_FAILURE,
    SET_CHANGED_USER_BOARD,
} = userBoardsSlice.actions;

export default userBoardsSlice.reducer;

import {
    mdiCheck,
    mdiClockCheckOutline,
    mdiCloseThick,
    mdiEyeOutline,
    mdiMagnify,
  } from '@mdi/js';

export const priorityValues = [
    {
        value: "7",
        color: "magenta",
    }, 
    {
        value: "6",
        color: "red",
    },  
    {
        value: "5",
        color: "volcano",
    },
    {
        value: "4",
        color: "orange",
    },
    {
        value: "3",
        color: "gold",
    },
    {
        value: "2",
        color: "green",
    },
    {
        value: "1",
        color: "lime",
    },
]

export const numberSorter = (row1, row2, paramName) => {
    const pri1 = row1[paramName];
    const pri2 = row2[paramName];

    if (!pri1) {
      return -1; 
    }
    if (!pri2) {
      return 1; 
    }
    return pri1 - pri2;
  }

  export  const changeSortVectors = (a, b, paramName) => {
    // console.log('a, b, paramName', a, b, paramName);
    switch (paramName) {
      case 'type':  return a?.[paramName].toLowerCase().localeCompare(b?.[paramName].toLowerCase());
      case 'status': return a?.[paramName].toLowerCase().localeCompare(b?.[paramName].toLowerCase());
      default:
    }
    return 0;
  };

 export  const changeSortIssues = (a, b, paramName) => {
    // console.log('a, b, paramName', a, b, paramName);
    switch (paramName) {
      case 'id':
      case 'title':
      case 'tracker':
      case 'status': return a?.[paramName].toLowerCase().localeCompare(b?.[paramName].toLowerCase());
      default:
    }
    return 0;
  };

  export const issuesCompletedStatuses = [
    {
        key: 'status',
        value: 'ready for deploy',
        // after: '14',
        // exp: 14,
        className: 'switch-development-section',
        label: 'wms.status.ready for deploy',
        icon: mdiClockCheckOutline,
        keySection: 'development',
      },
      {
        key: 'status',
        value: 'review',
        // after: '14',
        // exp: 14,
        label: 'wms.status.ready for review',
        className: 'switch-development-section',
        icon: mdiEyeOutline,
        keySection: 'development',
      },
      {
        key: 'status',
        value: 'test',
        // after: '14',
        // exp: 14,
        label: 'wms.status.ready for test',
        className: 'switch-testing-section',
        icon: mdiMagnify,
        keySection: 'testing',
      },
      {
        key: 'status',
        value: 'closed',
        // after: '0',
        // exp: 0,
        label: 'wms.status.closed',
        className: 'switch-end-section',
        icon: mdiCloseThick,
        keySection: 'end',
      },
      {
        key: 'status',
        value: 'deployed',
        // after: '0',
        // exp: 0,
        className: 'switch-end-section',
        label: 'wms.status.deployed_closed',
        icon: mdiCheck,
        keySection: 'end',
      },
  ]
import moment from 'moment';
// ---------------  get start Outdated scoring -----------------------------

// export const issueStatusKeys = [
//   'created',
//   'acquainted',
//   'accepted',
//   'rejected',
//   'in progress',
//   'review',
//   'ready for deploy',
//   'test',
//   'testing',
//   'not reproducible',
//   'backlog',
//   'blocked',
//   'paused',
//   'discuss',
//   'closed',
//   'deployed',
//   'abandoned',
//   'reopened',
//   'not enough info',
// ];

export const notActiveStatusesArrOutdated = ['backlog', 'created', 'rejected', 'closed', 'deployed', 'abandoned', 'paused', 'discuss', 'blocked', 'not enough info'];

export const tableCellOutdatedTagColor = (val) => {
  // console.log('tableCellOutdatedTagColor', score > 4);
  if (val > 4) {
    return 'error';
  }
  if (val > 0) {
    return 'warning';
  }
  if (val === 0) {
    return 'success';
  }
  return 'default';
};

const priorityWeight = (priority) => {
  switch (priority) {
    case 'low':
      return 1;
    case 'medium':
      return 2;
    case 'high':
      return 3;
    case 'urgent':
      return 4;
    default:
      return 0;
  }
};

const statusWeightIndexRatio = (entityStatus) => {
  const checkIsNullableStatus = notActiveStatusesArrOutdated.some((status) => status === entityStatus);
  return checkIsNullableStatus ? 0 : 1;
};

export const entityOutdatedScore = (priority, status, modified) => {
  const ratio = statusWeightIndexRatio(status);
  const weight = priorityWeight(priority);
  const daysFromChange = moment().diff(modified, 'day');
  // console.log('daysFromChange', daysFromChange);
  // console.log('entityOutdatedScore', ratio, weight, daysFromChange);
  const finalVal = weight * ratio * daysFromChange;
  // console.log('finalVal', finalVal);
  return finalVal;
};

export default function projectEntityOutdatedScoring(data) {
  const prioritySum = data.reduce((accumulator, item) => accumulator + entityOutdatedScore(item?.priority, item?.status, item?.modified), 0);
  // const background = projectScoringBackground(Number(projectIssueScoringVal));
  return prioritySum;
}

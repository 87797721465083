import { ProjectTypeCommonConstants } from '../constants/Constants';

const initialState = {
  mainFetching: true,
  listOfExpiredData: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ProjectTypeCommonConstants.GET_LIST_OF_EXPIRED_DATA_REQUEST:
      return {
        ...state,
        mainFetching: true,
      };
    case ProjectTypeCommonConstants.GET_LIST_OF_EXPIRED_DATA_SUCCESS:
      return {
        ...state,
        mainFetching: false,
        listOfExpiredData: payload,
      };
    case ProjectTypeCommonConstants.GET_LIST_OF_EXPIRED_DATA_FAILURE:
      return {
        ...state,
        mainFetching: false,
      };
    case ProjectTypeCommonConstants.CLEAR_AUDIT_DATA:
      return initialState;

    default: return state;
  }
};

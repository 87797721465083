import React from 'react';
import { Tooltip } from 'antd';
import Icon from '@mdi/react';
import {
  mdiInformationOutline,
} from '@mdi/js';
import { string, node, element } from 'prop-types';
import BaseButton from '../../../../../../components/_ui/BaseButton/BaseButton';
import { UI54Button, UI54Icon } from "@agpl/ui54kit";

export default function OnlyInfoVariant({
  tooltipPlacement = 'topLeft',
  tooltipTitle = '',
}) {
  return (
    <Tooltip
      title={(
        <div style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
          {tooltipTitle}
        </div>
      )}
      placement={tooltipPlacement}
      color="#ffffff"
    >
      <UI54Button
        id="viewLinkButton"
        shape="circle"
        className="view_btn_link__info"
      >
        <UI54Icon
          path={mdiInformationOutline}
          size={1}
        />
      </UI54Button>

      {/* <div
        style={{ backgroundColor: '#f4f9ffd0', borderRadius: '50%' }}
        className="ml-1 p-1 flex items-center justify-center cursor-pointer"
      >
        <Icon
          className="cursor-pointer"
          path={mdiInformationOutline}
          size={1}
          color="#51a4fc77"
        />
      </div> */}
    </Tooltip>
  );
}

OnlyInfoVariant.propTypes = {
  tooltipPlacement: string || node || element,
  tooltipTitle: string,
};

import {
  mdiBellOutline,
  mdiBlockHelper,
  mdiClockCheckOutline,
  mdiCloseThick,
  mdiDebugStepOver,
  mdiPause,
} from '@mdi/js';

export const vectorTypeTags = [
  {
    key: 'type',
    value: 'general',
    label: 'wms.type.general',
  },
  {
    key: 'type',
    value: 'version',
    label: 'wms.type.version',
  },
  {
    key: 'type',
    value: 'deploy',
    label: 'wms.type.deploy',
  },
];

export const vectorStatusesTags = [
  {
    key: 'status',
    value: 'created',
    label: 'wms.status.created',
    icon: mdiBellOutline,
  },
  {
    key: 'status',
    value: 'active',
    label: 'wms.status.active',
    icon: mdiDebugStepOver,
  },
  {
    key: 'status',
    value: 'in progress',
    label: 'wms.status.in progress',
    // icon: mdiBellOutline,
  },
  {
    key: 'status',
    value: 'planning',
    label: 'wms.status.planning',
    // icon: mdiBellOutline,
  },
  {
    key: 'status',
    value: 'backlog',
    label: 'wms.status.backlog',
    // icon: mdiBellOutline,
  },
  {
    key: 'status',
    value: 'protected',
    label: 'wms.status.protected',
    // icon: mdiPause,
  },
  {
    key: 'status',
    value: 'paused',
    label: 'wms.status.paused',
    icon: mdiPause,
  },
  {
    key: 'status',
    value: 'blocked',
    label: 'wms.status.blocked',
    icon: mdiBlockHelper,
  },
  {
    key: 'status',
    value: 'closed',
    label: 'wms.status.closed',
    icon: mdiCloseThick,
  },
];

import React, { useLayoutEffect } from 'react';
import {
  func,
  bool,
  object,
  string,
  arrayOf,
} from 'prop-types';
import cn from 'classnames';

import { Spin } from 'antd';

import UniGeneralButtons from '../../../uniGeneralButtons/UniGeneralButtons';
import CommentsStartPage from '../../../comment/CommentsStartPage/CommentsStartPage';
import TimeLogStartPage from '../../../TimeLogStartPage';


import { ACTIVITIES_TABS } from '../activitiesTabs';
import { COMMENT_TYPE } from '../../../comment/commentType';

function ActivitiesWrapper({
  fetching,
  inPanel = false,
  isPanelOpen = false,
  partitionType,
  parentUUID,
  selectedActivity,
  targetData,
  tabs,
  setSelectedActivity,
}) {
  const { entity_type: parentType } = targetData || {};

  useLayoutEffect(() => {
    if (parentUUID && isPanelOpen) {
      const isTabExist = tabs.find((tab) => tab.name === selectedActivity);
      if (isTabExist) setSelectedActivity(selectedActivity);
      else {
        // console.log('tab', tabs[0]?.name);
        setSelectedActivity(tabs[0]?.name);
      }
    }
  }, [isPanelOpen]);

  return (
    <div className={cn('w-full', { 'h-full flex flex-col': inPanel })}>
      <UniGeneralButtons
        inPanel
        viewMode={selectedActivity}
        switchViewFunc={setSelectedActivity}
        tabs={tabs}
        classNameTab="header-buttons-section-comment wrapper-title"
      />

      <div className={cn('w-full pt-2', { 'h-full overflow-hidden': inPanel })}>
        <Spin spinning={!!fetching} size="middle" wrapperClassName="activity-timelog-spinner">
          {selectedActivity === ACTIVITIES_TABS.TOTAL_COMMENTS && (
            <CommentsStartPage
              commentType={COMMENT_TYPE.TOTAL}
              inPanel={inPanel}
              isPanelOpen={isPanelOpen}
              partitionType={partitionType}
              parentUUID={parentUUID}
              sizeBtn="small"
            />
          )}
          {selectedActivity === ACTIVITIES_TABS.COMMENTS && (
            <CommentsStartPage
              commentType={COMMENT_TYPE.USER}
              inPanel={inPanel}
              isPanelOpen={isPanelOpen}
              partitionType={partitionType}
              parentUUID={parentUUID}
              sizeBtn="small"
            />
          )}

          {selectedActivity === ACTIVITIES_TABS.TIME_LOG && (
            <TimeLogStartPage
              inPanel={inPanel}
              isPanelOpen={isPanelOpen}
              targetData={targetData}
              parentType={parentType}
              partitionType={partitionType}
              parentUUID={parentUUID}
            />
          )}

          {selectedActivity === ACTIVITIES_TABS.HISTORY_OF_CHANGES && (
            <CommentsStartPage
              commentType={COMMENT_TYPE.SYSTEM}
              inPanel={inPanel}
              isPanelOpen={isPanelOpen}
              partitionType={partitionType}
              parentUUID={parentUUID}
              readOnlyComments
              sizeBtn="small"
            />
          )}
        </Spin>
      </div>
    </div>
  );
}

ActivitiesWrapper.propTypes = {
  fetching: bool,
  inPanel: bool,
  isPanelOpen: bool,
  partitionType: string,
  parentUUID: string,
  selectedActivity: string,
  targetData: object,
  tabs: arrayOf(object),
  setSelectedActivity: func,
};

export default ActivitiesWrapper;

import { Button } from 'antd';
import {
  bool, func, object, string,
} from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import BtnModal from '../../BtnModalComponent';
import ImportCaseComponent from './ImportCaseComponent';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

export default function ImportTestCaseModal({
  typeOfParent,
  disabledBtnImport,
  hideBtnComponent,
  openFromOuterComponent,
  parentData,
  handleCancelCallbackFunc,
  importTestCaseCallback,
  isTableEmpty = false,
}) {
  const { t } = useTranslation();
  return (
    <BtnModal
      footer={[]}
      width="50%"
      openFromOuterComponent={openFromOuterComponent}
      title={capitalize(t('wms.modals.headers.import_test_cases', 'test cases import'))}
      handleCancelCallbackFunc={handleCancelCallbackFunc}
      confirmOnClose
      confirmDescription={capitalize(
        t('wms.modals.body.close_modal_new_issue', 'the entered data will be lost, are you sure?'),
      )}
      btnComponent={
      isTableEmpty ? 
      <BaseButton
        className="btnPrimary-outline w-[200px] h-[40px] text-[20px] font-bold ml-1" 
      >
        {capitalize(t('wms.buttons.import.test_cases', 'import tests'))}
      </BaseButton>
      :
      !hideBtnComponent ?
      <Button
      title={disabledBtnImport && capitalize(t('wms.tooltips.no_test_cases', 'no test cases found'))}
      className={`${disabledBtnImport ? 'customAntOutGrinBtn_disabled' : 'customAntOutPrimaryBtn'} px-2 ant-btn ant-btn-default ant-btn-sm`}
      disabled={disabledBtnImport}
    >
      {capitalize(t('wms.buttons.import.test_cases', 'import tests'))}
    </Button>
    : null
      }
    >
      <ImportCaseComponent
        typeOfParent={typeOfParent}
        parentData={parentData}
        importTestCaseCallback={importTestCaseCallback}
      />
    </BtnModal>
  );
}

ImportTestCaseModal.propTypes = {
  typeOfParent: string,
  openFromOuterComponent: bool,
  hideBtnComponent: bool,
  disabledBtnImport: bool,
  handleCancelCallbackFunc: func,
  parentData: object,
  importTestCaseCallback: func,
};

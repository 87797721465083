import keyMirror from 'keymirror';

export const ConfigConstants = keyMirror({
  GET_CONFIG_REQUEST: null,
  GET_CONFIG_SUCCESS: null,
  GET_CONFIG_FAILURE: null,

  FETCH_PARTITION_REQUEST: null,
  FETCH_PARTITION_SUCCESS: null,
  FETCH_PARTITION_FAILURE: null,

  GET_AUTH_CONFIG_REQUEST: null,
  GET_AUTH_CONFIG_SUCCESS: null,
  GET_AUTH_CONFIG_FAILURE: null,
});

export default ConfigConstants;

import {
  Button, Col, Flex, Layout, Modal, Result, Spin, Tooltip,
} from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronDoubleRight } from '@mdi/js';
import {
  capitalize, get, sampleSize, sum,
} from 'lodash';

import '../../styles/PlanningComponent.css';

// import DayWeek from './DayWeek';
import SwitcherOfDate from './SwitcherOfDate';

import { antNotification } from '../../../MainUtils';
import { useGetActivitiesDays } from './hooks/useGetActivitiesDays';
import {
  // getUserPublicProfile,
  getUserPublicProfileUUID,
} from '../../store/selectors/selectors';
import { getSyncData, needDublicateActivity } from '../../store/selectors/dailySectionSelectors';
// import { getDataFromStartWorkModal } from '../../store/selectors/startAndFinishWorkModalSelectors';
import '../../styles/PrivatePlanning.scss';

import { UsersPartitionConstants } from '../../constants/Constants';
import { SocketContext } from '../../../socket/SocketProvider';
import { getSessionTokenFor } from '../../../api/appConfig';
import MyBoardCardDay from '../myBoard/MyBoardCardDay';
import { fetchingPlanningVectorDays } from '../../store/reducers/slices/planingSlice';
// import { startOfWeek } from '../../../54origins/dateFormats54origins';
// import IssueInfoRoot from '../../../projectFlow/components/issues/issueInfo/IssueInfoRoot';
import useURLParams from '../../../hooks/useURLParams';
import IssueInfoWithRequest from '../../../projectFlow/components/issues/IssueInfoWithRequest';
import dayjs from 'dayjs';
// import { useMyBoard } from '../../hooks/useMyBoard';

let intervalGetDay;

export default function WeekPlanningStartPage() {
  const { sendNotifyEmit } = useContext(SocketContext);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // const { getURLParams, addAndRemoveSearchParams } = useURLParams();
  // const { selectedIssue } = getURLParams();

  // const { getListOfBoardDayEntities, updateMyBoardStorage, getIssuesForMyBoard } = useMyBoard();

  const fetchingVectorsDays = useSelector(fetchingPlanningVectorDays);
  const needDublicate = useSelector(needDublicateActivity);
  // const userPublicUUID = useSelector(getUserPublicProfileUUID);
  const syncData = useSelector(getSyncData);

  const defaultDate = moment().startOf('isoWeek').format('YYYY-MM-DD');

  let planningOutTimer;

  const [startWeekDay, setStartWeekDay] = useState(defaultDate);
  const [needUpdateFromTimer, setNeedUpdate] = useState(false);

  const [duplicateData, setDuplicateData] = useState([]);
  const [syncDataApply, setSyncDataApply] = useState([]);
  // const [autoIssuesLoading, setAutoIssuesLoading] = useState(false);

  const endWeekDay = moment(startWeekDay).endOf('isoWeek').format('YYYY-MM-DD');

  const { daysActivity, initLoading, projectUserReportsUUID } = useGetActivitiesDays({
    startDate: startWeekDay,
    endDate: endWeekDay,
    createDayIfNull: true,
  });

  // console.log('RENDER daysActivity', daysActivity);

  const syncChanges = (req_data, isDuplicate) => {
    // console.log('syncChanges syncChanges');

    const token = getSessionTokenFor.notification();
    // console.log("req_data", req_data);
    const data = JSON.stringify({ token, params: req_data });
    sendNotifyEmit('update_vector_activities', data);
    if (isDuplicate) {
      setDuplicateData([]);
      dispatch({ type: UsersPartitionConstants.CLEAR_DUPLICATE_ACTIVITY });
    }
  };

  const handleDuplicate = (dayData) => {
    // console.log('dayData', dayData)
    setDuplicateData((prev) => [...prev, dayData]);
  };

  const handleSyncDataApply = (dayData) => {
    setSyncDataApply((prev) => [...prev, dayData]);
  };

  const refreshData = () => setNeedUpdate(false);

  const changePeriodCallback = (day) => {
    const mondayOfWeek = dayjs(day).startOf('week').format('YYYY-MM-DD');

    clearTimeout(intervalGetDay);
    intervalGetDay = setTimeout(() => setStartWeekDay(mondayOfWeek), 300);
  };

  // const closeIssueModal = () => {
  //   addAndRemoveSearchParams([], ['selectedIssue']);
  // };

  // const automaticallyDistributeIssues = async () => {
  //   setAutoIssuesLoading(true)
  //
  //
  //   let issuesForWeek = await getIssuesForMyBoard({
  //     pageLimit: 40,
  //     offset: 0,
  //   });
  //
  //   issuesForWeek = issuesForWeek.data?.map((item) => ({
  //     uuid: item.uuid,
  //     hours: Math.floor(Math.random() * 6) + 1,
  //   }));
  //
  //   getListOfBoardDayEntities(userPublicUUID, startWeekDay, endWeekDay).then((res) => {
  //     for (let i = 0; i < res.data.length; i++) {
  //       updateMyBoardStorage({
  //         params: {
  //           issues: sampleSize(issuesForWeek, 4),
  //         },
  //         uuid: res?.data[i]?.uuid,
  //       });
  //     }
  //
  //     setAutoIssuesLoading(false)
  //   });
  //
  // };

  useEffect(() => {
    // console.log('duplicateData', duplicateData);
    // console.log('needDublicate', needDublicate);
    if (
      !!needDublicate?.length
      && !!duplicateData?.length
      && needDublicate?.length === duplicateData?.length
    ) {
      // console.log("duplicateData", duplicateData);
      const req_data = { action_type: 'duplicate', data: duplicateData };
      syncChanges(req_data, true);
    }
  }, [needDublicate, duplicateData]);

  useEffect(() => {
    if (
      !!syncDataApply?.length
      && !!syncData?.length
      && syncDataApply?.length === syncData?.length
    ) {
      // console.log("Synchronization has been completed");
      setSyncDataApply([]);
      dispatch({ type: UsersPartitionConstants.CLEAR_SYNC_DATA });
      antNotification('info', 'Synchronization has been completed');
    }
  }, [JSON.stringify(syncDataApply), JSON.stringify(syncData)]);

  useEffect(() => {
    if (!needUpdateFromTimer) {
      planningOutTimer = setTimeout(() => {
        setNeedUpdate(true);
      }, 1200000);
    } else {
      clearTimeout(planningOutTimer);
    }
    return () => clearTimeout(planningOutTimer);
  }, [needUpdateFromTimer]);

  return (
    <div>
      {needUpdateFromTimer ? (
        <Result
          title={capitalize(t('wms.labels.please_refresh_page'))}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          extra={(
            <Button type="primary" key="console" onClick={refreshData}>
              {capitalize(t('wms.verb.refresh'))}
            </Button>
          )}
        />
      ) : (
        <>
          <SwitcherOfDate
            classNameWrapper="flex justify-center"
            defaultDate={startWeekDay}
            typePeriod="isoWeek"
            typeView="picker"
            changePeriodCallback={changePeriodCallback}
          />
          <Spin spinning={initLoading || fetchingVectorsDays}>
            <div className="mt-3 week-wrapper">
              {daysActivity.map((item) => (
                <MyBoardCardDay
                  date={item?.date}
                  planningDayUUID={item?.uuid}
                  // loading={}
                  projectUserReportsUUID={projectUserReportsUUID}
                  handleDuplicate={handleDuplicate}
                  handleSyncDataApply={handleSyncDataApply}
                  syncChanges={syncChanges}
                // automaticallyDistributeIssues={automaticallyDistributeIssues}
                />
              ))}
            </div>
          </Spin>
          {/*<Modal*/}
          {/*  visible={selectedIssue}*/}
          {/*  onCancel={closeIssueModal}*/}
          {/*  onOk={closeIssueModal}*/}
          {/*  destroyOnClose*/}
          {/*>*/}
          {/*  <IssueInfoWithRequest*/}
          {/*    issueUUID={selectedIssue}*/}
          {/*    hiddenView={[*/}
          {/*      'resetIssueBtn',*/}
          {/*      'linkToProject',*/}
          {/*      'goButton',*/}
          {/*      'deleteIssueBtn',*/}
          {/*      'editBtn',*/}
          {/*      'edit',*/}
          {/*    ]}*/}
          {/*    disabledView={['typeIssue', 'statusCollapse', 'priorityIssue', 'assignedVector', 'uploadFiles']}*/}
          {/*  />*/}
          {/*</Modal>*/}
        </>
      )}

    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import './BaseTag.scss';

function BaseTag({ children, ...props }) {
  return (
    <Tag
      {...props}
    >
      {children}
    </Tag>
  );
}

export default BaseTag;

BaseTag.propTypes = {
  children: PropTypes.node,
};

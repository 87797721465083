import React, { useEffect, useMemo, useState } from 'react';
import {
  arrayOf,
  number,
  object,
  string,
  oneOf,
  oneOfType,
  bool,
} from 'prop-types';
import { useSelector } from 'react-redux';

import { isArray, isNil } from 'lodash';
import ActorAvatarsGroup from './ActorAvatarsGroup';

import { getMe, getProjectParams } from "../../../selectors/selectors";
import { getInitialsFromActors, getUuids } from '../../../../actors/actorsUtils';
import { getActorsW54 } from '../../../../actors/selectors/actorsW54Selector';
import { UI54AvatarGroup } from "@agpl/ui54kit";

const findMe = (userUUID, actorsArr) => {
  const indexOfMe = actorsArr.findIndex((actor) => actor?.uuid === userUUID);
  return [indexOfMe !== -1, indexOfMe];
};

function WrapperAvatarsGroupForProject({
  wrapperClassName = '',
  actors = [],
  maxCount,
  popoverPlacement,
  popoverTrigger,
  avatarSize,
  avatarGap,
  avatarStyles,
  listAvatarGap,
  listAvatarSize,
  wrapperListStyles,
  tooltipPlacement,
  groupButtonSize,
  groupButtonStyles,
  withSearch = true,
  checkInProject = true,
  checkProjectRole = false,
}) {
  const userUUID = useSelector(getMe);
  const [users, setUsers] = useState([])
  const actorsW54Object = useSelector(getActorsW54);
  const projectParams = useSelector(getProjectParams);
  const [isFindMe, indexOfMe] = findMe(userUUID, users);

  const getRoleFromProps = (uuid) => actors.find((user) => user.uuid === uuid)?.role || '';

  const getRoleFromProject = (uuid) => {
    const { users = [] } = projectParams;
    return users.find((user) => user.uuid === uuid)?.role || '';
  };

  const getRole = (uuid) => {
    let role = '';

    if (checkProjectRole) role = getRoleFromProject(uuid);
    else role = getRoleFromProps(uuid);

    return role;
  };



  const addUsers = () => {
    if (!isArray(actors)) return [];
    const uuids = getUuids(actors);
    const usersW54 = uuids
      .map((uuid) => actorsW54Object?.[uuid])
      .filter?.((el) => !isNil(el));
    const allActors = getInitialsFromActors(usersW54);

    const existedActors = [];

    actors?.forEach?.((actor) => {
      let actorUUID = '';
      if (typeof actor === 'object') {
        actorUUID = actor?.uuid;
      } else if (typeof actor === 'string') {
        actorUUID = actor;
      }

      // if (checkInProject && !projectParams?.usersSearch?.includes(actorUUID)) return;

      // console.log('1111111111 ', allActors.get(actorUUID))
      const isUserIn = actorUUID === userUUID;

      if (allActors.has(actorUUID)) {
        existedActors.push({
          name: allActors.get(actorUUID).fullName,
          additionalInfo: getRole(actorUUID),
          useInitials: true,
          tooltipText: allActors.get(actorUUID).fullName,
          uuid: allActors.get(actorUUID).value,
          highlight: isUserIn,
        });
      }
    });
    setUsers((prev) => {
      return existedActors;
    })
  }

  useEffect( () => {    
    addUsers()
  }, [actors, actorsW54Object, projectParams])


  const currentActors = useMemo(() => {
    if (!isArray(actors)) return [];
    const uuids = getUuids(actors);
    const usersW54 = uuids
      .map((uuid) => actorsW54Object?.[uuid])
      .filter?.((el) => !isNil(el));
    const allActors = getInitialsFromActors(usersW54 ?? []);

    const existedActors = [];

    actors?.forEach?.((actor) => {

      let actorUUID = '';
      if (typeof actor === 'object') {
        actorUUID = actor?.uuid;
      } else if (typeof actor === 'string') {
        actorUUID = actor;
      }

      if (checkInProject && !projectParams?.usersSearch?.includes(actorUUID)) return;

      // console.log('1111111111 ', allActors.get(actorUUID))

      if (allActors.has(actorUUID)) {
        existedActors.push({
          ...allActors.get(actorUUID),
          role: getRole(actorUUID),
        });
      }
    });
    return existedActors;
  }, [JSON.stringify(actorsW54Object), JSON.stringify(actors)]);
  // console.log('currentActors', currentActors);

  // console.log(users)

  return (
    <div className={`flex items-center ${wrapperClassName}`}>
      <UI54AvatarGroup
        type="switch"
        items={users}
        maxCount={5}
        size="small"
        transparent={0.85}
        additionalInfoPrefix="role:"
        collapsedButtonSize="small"
        collapsedButtonCheckIcon={isFindMe}
        virtualized={false}
      />
      {/*<ActorAvatarsGroup*/}
      {/*  actorAvatars={users}*/}
      {/*  maxCount={maxCount}*/}
      {/*  popoverPlacement={popoverPlacement}*/}
      {/*  popoverTrigger={popoverTrigger}*/}
      {/*  avatarGap={avatarGap}*/}
      {/*  avatarSize={avatarSize}*/}
      {/*  avatarStyles={avatarStyles}*/}
      {/*  listAvatarGap={listAvatarGap}*/}
      {/*  listAvatarSize={listAvatarSize}*/}
      {/*  wrapperListStyles={wrapperListStyles}*/}
      {/*  tooltipPlacement={tooltipPlacement}*/}
      {/*  groupButtonSize={groupButtonSize}*/}
      {/*  groupButtonStyles={groupButtonStyles}*/}
      {/*  withSearch={withSearch}*/}
      {/*/>*/}
    </div>
  );
}

WrapperAvatarsGroupForProject.propTypes = {
  wrapperClassName: string,
  actors: oneOfType([arrayOf(object), arrayOf(string)]),
  maxCount: number,
  popoverPlacement: string,
  popoverTrigger: string,
  avatarSize: oneOfType([number, oneOf(['small', 'default', 'large']), object]),
  avatarGap: number,
  avatarStyles: object,
  listAvatarGap: number,
  listAvatarSize: oneOfType([number, oneOf(['small', 'default', 'large']), object]),
  wrapperListStyles: object,
  tooltipPlacement: string,
  groupButtonSize: oneOf(['small', 'middle', 'large']),
  groupButtonStyles: object,
  withSearch: bool,
  checkInProject: bool,
  checkProjectRole: bool,
};

export default WrapperAvatarsGroupForProject;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { mdiAlertCircleOutline, mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Alert, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AllUserData from './AllUserData';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import { removeActorFromWMS } from '../../../userFlow/store/action-creators/userActions';
import { antNotification } from '../../../MainUtils';
import { getNameForActor } from '../../../54origins/utils54origins';
import axiosRequest from '../../../api/apiAxios';
import { configUrlAuth, sessionNameFor } from '../../../api/appConfig';
import useAuthSessionHook from '../../hooks/useAuthSessionHook';
import { capitalize } from 'lodash';

export default function DeletingUser({
  user,
  deleteUserCallback,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showData, setShowData] = useState(false);
  const [confirm, setConfirm] = useState(null);
  const [removeFromAuthFetching, setRemoveFromAuthFetching] = useState(false);

  const actorUUID = user?.actor;

  const toggleShowData = () => setShowData((prev) => !prev);

  const requestRemoveActorOnAuth = () => new Promise((resolve) => {
    dispatch(
      axiosRequest.post(
        'delete/actor',
        [
          'REMOVE_ACTOR_FROM_AUTH_REQUEST',
          'REMOVE_ACTOR_FROM_AUTH_SUCCESS',
          'REMOVE_ACTOR_FROM_AUTH_FAILURE',
        ],
        {
          uuid: actorUUID,
        },
        {
          url: configUrlAuth,
          tokenName: sessionNameFor.auth,
          onSuccess: resolve,
          onFailure: checkRequestErrorAndGetAuthSession,
          doNotCheckError: true,
        },
      ),
    );
  });

  const removeActorOnAuthAndEntity = (showNotification) => {
    setRemoveFromAuthFetching(true);
    return requestRemoveActorOnAuth()
      .then(() => {
        showNotification && antNotification('success', 'Success');
      })
  };

  const removeActorFunc = async () => {
    if (confirm === 'authDelete') {
      await removeActorOnAuthAndEntity(true);
      setRemoveFromAuthFetching(false);
      setConfirm(null);
      deleteUserCallback?.();
    } else if(confirm === 'softDelete') {
      dispatch(removeActorFromWMS(actorUUID, confirm === 'hardDelete')).then(() => {
        antNotification('success', t('wms.noun.success', 'Success'));
        setConfirm(null)
        deleteUserCallback?.();
      });
    } else {
      await removeActorOnAuthAndEntity(false)
      await dispatch(removeActorFromWMS(actorUUID, confirm === 'hardDelete')).then(() => {
        antNotification('success', t('wms.noun.success', 'Success'));
        deleteUserCallback?.();
      })
      setRemoveFromAuthFetching(false);
      setConfirm(null);
    }
  };

  const { checkRequestErrorAndGetAuthSession } = useAuthSessionHook(removeActorFunc);

  const toggleDeleteUserWithData = () => {
    setConfirm('hardDelete');
  };

  const toggleDeleteOnlyUser = () => {
    setConfirm('softDelete');
  };

  const toggleDeleteOnlyUserFromAuth = () => {
    setConfirm('authDelete');
  };

  const toggleDeleteWithTransferData = () => { };

  const toggleCancel = () => {
    setConfirm(null);
  };

  return (
    <div>
      {confirm ? (
        <>
          <Alert
            message="Warning"
            description="Are you sure?"
            type="error"
            showIcon
            icon={<Icon path={mdiAlertCircleOutline} size={1.3} />}
          />
          <div className="w-full flex justify-end px-2 my-4 w-full">
            <BaseButton
              className="btnPrimary-outline mr-1 mb-1"
              onClick={toggleCancel}
            >
              {capitalize(t('wms.buttons.removing_process.no', 'no'))}
            </BaseButton>

            <BaseButton
              className="btnPrimary mr-1 mb-1"
              onClick={removeActorFunc}
              loading={removeFromAuthFetching}
            >
              {capitalize(t('wms.buttons.removing_process.yes', 'yes'))}
            </BaseButton>
          </div>
        </>
      ) : (
        <>
          <Alert
            message="Warning"
            description={`You want to delete ${getNameForActor(user)}. how do you want to do it?`}
            type="warning"
            showIcon
          />
          {/* <div className="px-2 my-4 w-full" /> */}
          <div className="w-full flex justify-between px-2 my-4 w-full">
            <BaseButton
              className="btnPrimary-outline mr-1 mb-1"
              onClick={toggleShowData}
            >
              <Icon path={mdiChevronDown} size={1} rotate={showData ? 180 : 0} />
              Show user data
            </BaseButton>

            <div className="flex flex-wrap items-center justify-end">
              <BaseButton
                className="btnPrimary-outline mr-1 mb-1"
                onClick={toggleDeleteOnlyUser}
              >
                Delete only user
              </BaseButton>

              <BaseButton
                className="btnPrimary-outline mr-1 mb-1"
                onClick={toggleDeleteOnlyUserFromAuth}
                loading={removeFromAuthFetching}
              >
                Delete only user from auth
              </BaseButton>

              <BaseButton
                className="btnPrimary-outline mr-1 mb-1"
                onClick={toggleDeleteUserWithData}
              >
                Complete deletion of a user with data
              </BaseButton>

              <Tooltip title="Functionality in development">
                <BaseButton
                  className="btnPrimary-outline mr-1 mb-1"
                  onClick={toggleDeleteWithTransferData}
                  disabled
                >
                  deleting a user with data transfer
                </BaseButton>
              </Tooltip>
            </div>
          </div>

          {showData && <AllUserData user={user} />}
        </>
      )}
    </div>
  );
}

DeletingUser.propTypes = {
  deleteUserCallback: PropTypes.func,
  user: PropTypes.object,
};

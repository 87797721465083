import { antNotificationExtended } from "../../../MainUtils";

export const qaNotify = ({
  param,
  key,
  message,
}) => {
  switch (param) {
    case 'info':
      antNotificationExtended({
        duration: 0,
        key,
        message,
        type: 'info',
      });
      break;
    case 'success':
      antNotificationExtended({
        duration: 3.5,
        key,
        message,
        type: 'success',
      });
      break;
    case 'error':
      antNotificationExtended({
        duration: 3.5,
        key,
        message,
        type: 'error',
      });
      break;
    default:
      break;
  }
};

import React, {
  useRef,
} from 'react';

import './userList.scss';

import {
  array,
  bool,
  func, number, object, string,
} from 'prop-types';
import {
  Row,
} from 'antd';
import {
  AutoSizer, List,
} from 'react-virtualized';
import UserItem from './UserItem';

export default function UserList({
  total = null,
  items,
  config = {},
  setConfig,
  disabledScroll,
  onClickCallback,
  selectedUUIDs = [],
}) {
  const listRef = useRef();

  const handleScroll = ({ scrollTop, clientHeight, scrollHeight }) => {
    const { limit = 100, offset = 0 } = config;

    if (scrollTop + clientHeight >= scrollHeight - 400
      && !disabledScroll
      && setConfig) {
      const newOffset = offset + limit;
      if (newOffset < total) {
        setConfig((prev) => ({
          ...prev,
          offset: newOffset,
        }));
      }
    }
  };

  const rowRenderer = ({ index, style, isScrolling }) => {
    if (items[index] === undefined && isScrolling) {
      return (
        <div key={index} style={style}>
          Scrolling...
        </div>
      );
    }

    const {
      value, label,
    } = items[index];

    return (
      <div key={value} style={{ ...style }}>
        <UserItem
          onClickCallback={onClickCallback}
          label={label}
          uuid={value}
          isSelected={selectedUUIDs?.includes(value)}
        />
      </div>
    );
  };

  return (
    <Row
      className="user-list-container"
    >
      <AutoSizer
        className="w-full h-full"
      >
        {({ width, height }) => (
          <List
            ref={listRef}
            height={height}
            rowHeight={50}
            width={width}
            rowCount={items?.length || 0}
            onScroll={handleScroll}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </Row>
  );
}

UserList.propTypes = {
  selectedUUIDs: array,
  items: array,
  total: number,
  setConfig: func,
  config: object,
  disabledScroll: bool,
  onClickCallback: func,
};

import { isNil } from 'lodash';
import { pathOr } from 'ramda';
import { useMemo } from 'react';

import {
  arrSortUsersByShowingReports,
  arrSortUsersByAlerts,
  arrSortUsersByDayType,
  arrSortUsersByFinishWork,
  arrSortUsersByHavingPlan,
  arrSortUsersByOnWorkPlace,
  arrSortUsersByStartWork,
} from '../../../../toolsFlow/utils/customUserSortFunctionsByArrReports';

import {
  sortUsersByShowingReports,
  usersWithFinishWork,
  usersWithStartWork,
  usersWithWeekendWork,
} from '../../../../toolsFlow/utils/customUserSortFunctionsByObjReports';

const sortHandler = (usersToSort, defaultUsers, sortValue, target) => {
  if (sortValue === 'ascend') {
    return (usersToSort.sort((a, b) => {
      if (isNil(b[target])) {
        return -1;
      }
      return b[target] - a[target];
    })
      .concat(defaultUsers));
  }
  return (defaultUsers
    .concat(usersToSort.sort((a, b) => {
      if (isNil(a[target])) {
        return -1;
      }
      return a[target] - b[target];
    })));
};

export const useSortUsersByObjReport = (typeOfSort, valueOfSort, reportListTime, users) => {
  const usersFromSort = useMemo(() => {
    const usersWithoutReports = [];
    const usersWithReports = [];

    const userFilterHandler = (e) => (reportListTime[e.actor]
      ? usersWithReports.push(e)
      : usersWithoutReports.push(e));

    users.forEach(userFilterHandler);

    switch (typeOfSort) {
      case 'startWork': {
        const usersWithStartWorkArr = usersWithStartWork(usersWithReports, reportListTime);
        const sortedList = sortHandler(usersWithStartWorkArr, usersWithoutReports, valueOfSort, 'startWork');

        return sortedList;
      }
      case 'finishWork': {
        const usersWithFinishWorkArr = usersWithFinishWork(usersWithReports, reportListTime);
        const sortedList1 = sortHandler(usersWithFinishWorkArr, usersWithoutReports, valueOfSort, 'finishWork');

        return sortedList1;
      }
      case 'weekendHoursWorked': {
        const usersWithWeekendWorkArr = usersWithWeekendWork(usersWithReports, reportListTime);
        const sortedUsersByWeekendWork = sortHandler(usersWithWeekendWorkArr, usersWithoutReports, valueOfSort, 'weekendHoursWorked');

        return sortedUsersByWeekendWork;
      }
      case 'reports': {
        const sortedUsers = sortUsersByShowingReports(users, valueOfSort);
        return sortedUsers;
      }
      default:
        return users;
    }
  }, [typeOfSort, valueOfSort, users, reportListTime]);

  return usersFromSort;
};

export const useSortUsersByArrReports = (typeOfSort, valueOfSort, reports, users) => {
  const usersFromSort = useMemo(() => {
    const usersWithoutReports = [];
    const usersWithReports = [];

    const userFilterHandler = (e) => (reports.find((el) => el.actor === e.actor)
      ? usersWithReports.push(e)
      : usersWithoutReports.push(e));

    users.forEach(userFilterHandler);

    switch (typeOfSort) {
      case 'startWork': {
        const usersWithStartWorkArr = arrSortUsersByStartWork(usersWithReports, reports);
        const sortedList = sortHandler(usersWithStartWorkArr, usersWithoutReports, valueOfSort, 'startWork');

        return sortedList;
      }
      case 'finishWork': {
        const usersWithFinishWorkArr = arrSortUsersByFinishWork(usersWithReports, reports);
        const sortedList = sortHandler(usersWithFinishWorkArr, usersWithoutReports, valueOfSort, 'finishWork');

        return sortedList;
      }
      case 'showReports': {
        const allUsers = arrSortUsersByShowingReports(users);
        const sortedList = sortHandler(allUsers, [], valueOfSort, 'showReports');

        return sortedList;
      }
      case 'onWorkPlace': {
        const usersOnWorkPlace = arrSortUsersByOnWorkPlace(usersWithReports, reports);
        const sortedList = sortHandler(usersOnWorkPlace, usersWithoutReports, valueOfSort, 'onWorkPlace');

        return sortedList;
      }
      case 'dailyPlanningText': {
        const usersWithPlanForToday = arrSortUsersByHavingPlan(usersWithReports, reports);
        const sortedList = sortHandler(usersWithPlanForToday, usersWithoutReports, valueOfSort, 'dailyPlanningText');

        return sortedList;
      }
      case 'kindOfDay': {
        const usersWithPlanForToday = arrSortUsersByDayType(usersWithReports, reports);
        const sortedList = sortHandler(usersWithPlanForToday, usersWithoutReports, valueOfSort, 'kindOfDay');

        return sortedList;
      }

      case 'alert': {
        const usersWithPlanForToday = arrSortUsersByAlerts(usersWithReports, reports);
        const sortedList = sortHandler(usersWithPlanForToday, usersWithoutReports, valueOfSort, 'alert');

        return sortedList;
      }
      default:
        return users;
    }
  }, [typeOfSort, valueOfSort, users, reports]);

  return usersFromSort;
};

export const useSearchUsers = ({
  typeOfSort,
  valueOfSort,
  reportListTime,
  users,
  searchData,
  paginationOptions,
  setCurrentPage,
  setPaginationOptions,
}) => {
  const usersAfterSort = Array.isArray(reportListTime)
    ? useSortUsersByArrReports(typeOfSort, valueOfSort, reportListTime, users)
    : useSortUsersByObjReport(typeOfSort, valueOfSort, reportListTime, users);

  const usersAfterSearch = useMemo(() => {
    if (searchData) {
      const currentUsers = usersAfterSort.filter((e) => {
        const firstName = pathOr('', ['uinfo', 'first_name'], e)?.toLowerCase();
        const lastName = pathOr('', ['uinfo', 'last_name'], e)?.toLowerCase();
        const fullName = `${firstName} ${lastName}`;
        if ((firstName || lastName) && fullName.indexOf(searchData?.toLowerCase()) !== -1) {
          return true;
        }
        return false;
      });

      setCurrentPage(1);
      setPaginationOptions({
        ...paginationOptions,
        currentPage: 1,
        offset: 0,
      });
      return currentUsers;
    }
    setCurrentPage(1);
    setPaginationOptions({
      ...paginationOptions,
      currentPage: 1,
      offset: 0,
    });
    return usersAfterSort;
  }, [usersAfterSort, searchData]);

  return usersAfterSearch;
};

export const useFilteredUser = ({
  typeOfSort,
  valueOfSort,
  reportListTime,
  users,
  searchData,
  paginationOptions,
  setCurrentPage,
  setPaginationOptions,
  setTotalCountUsers,
  disablePagination,
}) => {
  const usersAfterSearch = useSearchUsers({
    typeOfSort,
    valueOfSort,
    reportListTime,
    users,
    searchData,
    paginationOptions,
    setCurrentPage,
    setPaginationOptions,
  });

  const usersFromPagination = useMemo(() => {
    const { offset, pageLimit } = paginationOptions;

    setTotalCountUsers?.(usersAfterSearch.length);

    if (disablePagination) {
      return usersAfterSearch;
    }

    return [...usersAfterSearch].splice(offset, pageLimit);
  }, [paginationOptions, usersAfterSearch]);

  return usersFromPagination;
};

import { useDispatch } from 'react-redux';
// import moment from 'moment';

import moment from 'moment';
import { getListOfTestCyclesFunc } from '../../projectFlow/actions/QaActions';
import { getListAndPartialReadEntities } from '../../entity/actions/entityActions';
import { ProjectTypeCommonConstants } from '../../projectFlow/constants/Constants';

import { setQaStructureCurrentProject, setQaStructureProjectsArr } from '../../projectFlow/reducers/slicers/projectAllScoringSlicer';
import { updateProjectScoring } from '../../projectFlow/actions/projectFlowActions';

export default function useQaStructureScoring() {
  const dispatch = useDispatch();

  const scoreTitle = 'Qa (str)';

  const updateProject = async (props) => {
    const {
      projectUuid, projectPartition, qaStructureScore,
    } = props;
    const data = {
      uuid: projectUuid,
      params: {
        qaStructureScoring: {
          updated: moment(),
          qaStructureScore,
        },
      },
      partition: projectPartition,
    };
    return dispatch(updateProjectScoring(data));
  };

  async function getAllSuites(parent, partition) {
    const data = {
      entity_type: 'testSuite',
      parent,
      partition,
      params_fields: {
        id: 'id',
        title: 'title',
        description: 'description',
        assignToUser: 'assignToUser',
        cases_count: 'cases_count',
      },
    };

    const currentConstants = [
      ProjectTypeCommonConstants.LIST_OF_TEST_SUITES_REQUEST,
      ProjectTypeCommonConstants.LIST_OF_TEST_SUITES_SUCCESS,
      ProjectTypeCommonConstants.LIST_OF_TEST_SUITES_FAILURE,
    ];

    return dispatch(getListAndPartialReadEntities(
      {
        data,
        parent,
        partition,
        constants: currentConstants,
      },
    ));
  }

  const computeSuiteScore = (suite) => {
    const testCasesCount = suite?.cases_count;
    // console.log('computeSuiteScore', suite);
    if (testCasesCount >= 10) {
      return 1;
    }
    return 0;
  };

  const computeQaStructureScore = async (
    projectUuid,
    partitionType,
    isNeedProject,
    isAllScoring,
  ) => {
    try {
      const resp = await getAllSuites(projectUuid, partitionType);
      const data = resp?.data;
      // console.log('computeQaStructureScore data', data);
      const qaStructureScore = data
        .reduce((accumulator, item) => accumulator + computeSuiteScore(item), 0);
      const finalData = {
        uuid: projectUuid,
        qaStructureScore,
        updated: moment(),
      };

      if (!isAllScoring) {
        const res = await updateProject({
          qaStructureScore,
          projectUuid,
          projectPartition: partitionType,
        });

        if (isNeedProject) {
          return res;
        }
      }

      return finalData;
      // console.log('computeQaStructureScore', finalData);
      dispatch(setQaStructureCurrentProject(finalData));
    } catch (e) {
      console.log('error handleOnProjectQaData', e);
    }
  };

  const computeAllProjectsQaStructureScore = async (projectArray, isAllScoring) => {
    try {
      const projectsQaStructureData = await Promise.all(projectArray.map(async (project) => {
        const { uuid, partition } = project;
        const qaScoringData = await computeQaStructureScore(uuid, partition, false, isAllScoring);
        return qaScoringData;
      }));
      // console.log('projectsQaStructureData', projectsQaStructureData);
      dispatch(setQaStructureProjectsArr(projectsQaStructureData));
      const finalProjectsData = projectArray.map((item) => {
        const qaStructureScore = projectsQaStructureData.find((project) => project?.uuid === item?.uuid);
        // console.log('finalProjectsData qaStructureScore', qaStructureScore);

        return {
          ...item,
          qaStructureScoring: qaStructureScore,
        };
      });
      return finalProjectsData;
    } catch (e) {

    }
  };

  const handleQaStructureBackground = (score, isButtonColor) => {
    if (score === 0) {
      return !isButtonColor ? 'error' : 'scoringBtnDanger';
    }
    if (score >= 5) {
      return !isButtonColor ? 'success' : 'scoringBtnGreen';
    }
    if (score > 0) {
      return !isButtonColor ? 'warning' : 'scoringBtnWarning';
    }
    return !isButtonColor ? '' : 'scoringBtnDefault';
  };

  const qaStructureTooltipText = (
    <>
      <div>
        <strong>
          QA structure score
        </strong>
        {' '}
        - an index reflecting the approximate level of availability of tests in the project.
      </div>
      <div>
        <strong>Threshold values: </strong>
        it&apos;s recommended to pay attention if the value less than 5.
      </div>
    </>
  );

  return {
    qaStructureTitle: scoreTitle,
    computeAllProjectsQaStructureScore,
    computeQaStructureScore,
    handleQaStructureBackground,
    qaStructureTooltipText,
  };
}

// ---------------- old logic ----------------------

// const resp = await Promise.all([getAllSuites, getAllCycles].map((async (request) => await request(projectUuid, partitionType))));
// const suitesData = Array.isArray(resp[0]?.data) ? resp[0]?.data : [];
// const cyclesData = Array.isArray(resp[1]?.data) ? resp[1]?.data : [];
// console.log(`handleOnProjectQaData ${title} suitesData`, suitesData);
// console.log(`handleOnProjectQaData ${title} cyclesData`, cyclesData);
// const testCasesIndex = handleTestCasesIndex(suitesData, cyclesData);
// const testCyclesIndex = handleTestCyclesIndex(cyclesData);
// const qaStructureIndex = Number((Math.round((testCasesIndex + testCyclesIndex) * 1000) / 1000).toFixed(2));
// console.log(`handleOnProjectQaData ${title} testCasesIndex`, testCasesIndex);
// console.log(`handleOnProjectQaData ${title} testCyclesIndex`, testCyclesIndex);

// const getAllCycles = async (parent, partition) => {
//   const data = await dispatch(getListOfTestCyclesFunc(
//     { parent, partition },
//   ));
//   return data;
// };

// const handleTestCasesIndex = (suitesData, cyclesData) => {
//   const totalCases = suitesData.reduce((accumulator, item) => accumulator + item?.cases_count, 0);
//   const casesInCycles = cyclesData.reduce((accumulator, item) => accumulator + item?.cases_count, 0);
//   const checkTotalCases = typeof totalCases === 'number' ? totalCases : 0;
//   if (!checkTotalCases) {
//     return 0;
//   }
//   const casesIndex = casesInCycles / totalCases;
//   return casesIndex;
// };

// const handleTestCyclesIndex = (cyclesData) => {
//   const cyclesDataLength = cyclesData?.length || 0;
//   if (!cyclesDataLength) {
//     return 0;
//   }
//   const activeCaseStatuses = ['block', 'fail', 'pass'];
//   const notActiveCycles = cyclesData.filter((item) => {
//     const cycleProgressDataObj = item?.progress;
//     const checkIsActive = activeCaseStatuses.some((key) => Boolean(cycleProgressDataObj[key]));
//     return !checkIsActive;
//   })?.length;
//   const cyclesIndex = notActiveCycles / cyclesDataLength;
//   return cyclesIndex;
// };

import { get, isEmpty } from 'lodash';
import moment from 'moment';

export const timeDifference = (start, finish) => {
  const dur = moment.duration(finish.diff(start));
  const hours = Math.floor(dur.asHours());
  const minutes = dur.asMinutes() - hours * 60;

  return {
    hours,
    minutes,
  };
};

export const getHoursWorked = (startWork, finishWork) => {
  const currStartWork = moment(startWork, 'YYYY-MM-DD HH:mm');
  const currFinishWork = moment(finishWork, 'YYYY-MM-DD HH:mm');

  if (startWork && finishWork) {
    const { hours, minutes } = timeDifference(currStartWork, currFinishWork);

    if (hours < 0 || minutes < 0) {
      const newStart = currStartWork;
      const newFinish = currFinishWork.add(1, 'd');

      const { hours: newHours, minutes: newMinutes } = timeDifference(newStart, newFinish);

      return {
        currentTimeDiff: `${newHours < 10 ? `0${newHours}` : newHours}h${newMinutes < 10 ? `0${newMinutes}` : newMinutes}m`,
        startTime: newStart,
        finishTime: newFinish,
      };
    }
    return {
      currentTimeDiff: `${hours < 10 ? `0${hours}` : hours}h${minutes < 10 ? `0${minutes}` : minutes}m`,
      startTime: currStartWork,
      finishTime: currFinishWork,
    };
  }
  return {
    currentTimeDiff: '00h00m',
    startTime: moment().format('YYYY-MM-DD HH:mm'),
    finishTime: moment().format('YYYY-MM-DD HH:mm'),
  };
};

export const parseTimeFromMinutesToHHhmmm = (time, moreThan24h) => {
  if (time === 0) {
    return '00h00m';
  }
  if (!time) return null;

  if (moreThan24h) {
    const hours = Math.floor(time / 60);
    const minutes = time - Math.floor(time / 60) * 60;

    const fulltime = `${hours < 10 ? `0${hours}` : hours}h${minutes < 10 ? `0${minutes}` : minutes}m`;

    return fulltime;
  }
  if (time / 60 > 24) {
    const hours = Math.floor(time / 60) - 24;
    const minutes = time - Math.floor(time / 60) * 60;

    const fulltime = `${hours < 10 ? `0${hours}` : hours}h${minutes < 10 ? `0${minutes}` : minutes}m`;

    return fulltime;
  }

  const hours = Math.floor(time / 60);
  const minutes = time - Math.floor(time / 60) * 60;

  const fulltime = `${hours < 10 ? `0${hours}` : hours}h${minutes < 10 ? `0${minutes}` : minutes}m`;

  return fulltime;
};

export const convertTimeFromMomentToHHhmmm = (time) => {
  if (time === 0) return '00h00m';
  if (!time) return null;
  const isTimeWithDate = moment(time, 'YYYY-MM-DD HH:mm').format('HH[h]mm[m]');
  if (isTimeWithDate === 'Invalid date') {
    return moment(time, 'HH[h]mm[m]').format('HH[h]mm[m]');
  }
  return isTimeWithDate;
};

export const convertTimeFromMomentToInput = (h, m, time) => {
  if (h || m) {
    if (time) {
      return moment(time).add(h, 'h').add(m, 'minute').format('HH:mm')
        .split(':')
        .map((e, index) => (index === 0 ? `${e}h` : `${e}m`))
        .join('');
    }
    return moment().add(h, 'h').add(m, 'minute').format('HH:mm')
      .split(':')
      .map((e, index) => (index === 0 ? `${e}h` : `${e}m`))
      .join('');
  }
  if (time) {
    return moment(time).format('HH:mm')
      .split(':')
      .map((e, index) => (index === 0 ? `${e}h` : `${e}m`))
      .join('');
  }
  return moment().format('HH:mm')
    .split(':')
    .map((e, index) => (index === 0 ? `${e}h` : `${e}m`))
    .join('');
};

export const convertTimeFromHHhmmm = (time) => {
  const hours = parseInt(time.slice(0, time.indexOf('h')), 10);
  const minutes = parseInt(time.slice(time.indexOf('h') + 1), 10);

  const startDay = moment().startOf('day').add(hours, 'h').add(minutes, 'minute')
    .format('YYYY-MM-DD HH:mm');
  return {
    fullTime: startDay,
    hours,
    minutes,
  };
};

export const timeDifferenceHhhmmm = (time1, time2) => {
  const { hours: h1, minutes: m1 } = convertTimeFromHHhmmm(time1);
  const { hours: h2, minutes: m2 } = convertTimeFromHHhmmm(time2);

  const t1 = h1 * 60 + m1;
  const t2 = h2 * 60 + m2;

  const diffTime = t1 - t2;

  const CH = Math.floor(diffTime / 60);
  const CM = diffTime - (CH * 60);

  return `${CH < 10 ? `0${CH}` : CH}h${CM < 10 ? `0${CM}` : CM}m`;
};

export const getLocaleDateTime = (currentDate, time) => {
  const newDate = new Date(currentDate);

  const H = Math.floor(time / 60);
  const M = time - H * 60;

  newDate.setHours(H);
  newDate.setMinutes(M);

  return newDate.toUTCString();
};

export const validStartWork = (start) => {
  if (start || start === 0) return true;
  return false;
};

export const getDateDifference = (start, end) => {
  const startFullTime = convertTimeFromHHhmmm(start).fullTime;
  const endFullTime = convertTimeFromHHhmmm(end).fullTime;

  let convertedStart;
  let convertedEnd;

  if (new Date(endFullTime) < new Date(startFullTime)) {
    convertedStart = moment(startFullTime);
    convertedEnd = moment(endFullTime).add(1, 'day');
  } else {
    convertedStart = moment(startFullTime);
    convertedEnd = moment(endFullTime);
  }

  const duration = moment.duration(convertedEnd.diff(convertedStart));

  const h = duration.hours();
  const m = duration.minutes();

  return { h, m };
};

export const convertStartAndFinishFromHHhmmmToMinutes = (start, finish) => {
  const hoursStart = get(convertTimeFromHHhmmm(start), 'hours');
  const minStart = get(convertTimeFromHHhmmm(start), 'minutes');

  const hoursFinish = get(convertTimeFromHHhmmm(finish), 'hours');
  const minFinish = get(convertTimeFromHHhmmm(finish), 'minutes');

  const startInMinutes = +hoursStart * 60 + +minStart;
  const finishInMinutes = +hoursFinish * 60 + +minFinish;

  if (finishInMinutes < startInMinutes) {
    return {
      finishWork: 24 * 60 + finishInMinutes,
      startWork: startInMinutes,
    };
  }

  return {
    finishWork: finishInMinutes,
    startWork: startInMinutes,
  };
};

export const transformStartAndFinishWorkToMinutes = (start, finish) => {
  if (Number.isFinite(start) && Number.isFinite(finish)) {
    return {
      start,
      finish,
    };
  }
  if (!start || !finish) {
    return {
      start: null,
      finish: null,
    };
  }

  const startInHHhmmm = convertTimeFromMomentToHHhmmm(start);
  const finishInHHhmmm = convertTimeFromMomentToHHhmmm(finish);

  const {
    startWork: sw,
    finishWork: fw,
  } = convertStartAndFinishFromHHhmmmToMinutes(startInHHhmmm, finishInHHhmmm);

  return {
    start: sw,
    finish: fw,
  };
};

export const convertArrayOfHHhmmmToSum = (arr) => {
  if (isEmpty(arr)) {
    return '00h00m';
  }
  const arrInMinutes = arr.map((time) => {
    const e = time || '00h00m';
    const h = Number.parseInt(e.slice(0, e.indexOf('h')), 10);
    const m = Number.parseInt(e.slice(e.indexOf('h') + 1), 10);

    const fullMin = h * 60 + m;
    return fullMin;
  });

  const sum = arrInMinutes.reduce((a, b) => a + b, 0);

  const newH = Math.floor(sum / 60);
  const newMin = sum - Math.floor(sum / 60) * 60;
  return `${newH < 10 ? `0${newH}` : newH}h${newMin < 10 ? `0${newMin}` : newMin}m`;
};

export const convertHHhmmmToHours = (time) => {
  if (!time) return null;

  const { hours, minutes } = convertTimeFromHHhmmm(time);

  return (+hours + +minutes / 60).toFixed(2);
};

export const convertHHhmmmToMinutes = (time) => {
  if (!time) return null;

  const { hours, minutes } = convertTimeFromHHhmmm(time);

  return hours * 60 + minutes;
};

export const calculateHoursWorkedForDay = (report) => {
  if (!report) return '00h00m';

  const {
    startWork,
    finishWork,
    lunchBreak,
  } = report || {};

  if (startWork && finishWork && lunchBreak) {
    const hoursWorkedInMinutes = finishWork - startWork - convertHHhmmmToMinutes(lunchBreak);

    return parseTimeFromMinutesToHHhmmm(hoursWorkedInMinutes);
  }
  return '00h00m';
};

export const compareHHhMMmTime = (value1, value2) => {
  const { hours: h1, minutes: m1 } = convertTimeFromHHhmmm(value1);
  const { hours: h2, minutes: m2 } = convertTimeFromHHhmmm(value2);

  const totalMin1 = h1 * 60 + m1;
  const totalMin2 = h2 * 60 + m2;

  if (totalMin1 === totalMin2) {
    return '=';
  }
  return totalMin1 > totalMin2 ? '>' : '<';
};

import React, { useState } from "react";

import cn from "classnames";
import { array, bool, func, number, string } from "prop-types";

import { Flex } from "antd";
import Icon from "@mdi/react";
import { mdiClose, mdiPlus } from "@mdi/js";

export default function ChunksComponent({
    total = 0,
    size = 'small',
    isEditMode = false,
    chunks = [],
    onActionCallback,
}) {
    const [hovered, setHovered] = useState(null);

    const btnRemoveClass = 'hover:bg-white hover:border-red-500 hover:text-red-500';
    const btnGray = 'text-gray-500 border-gray-500 hover:bg-gray-500 hover:text-white';
    const btnBlue = 'text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white';
    const btnOrange = 'text-orange-500 border-orange-500 hover:bg-orange-500 hover:text-white';

    const chunkStyles = {
        small: {
            width: 12, height: 7
        },
        middle: {
            width: 18, height: 12
        },
        large: {
            width: 24, height: 17
        },
    }

    const onDeleteChunk = (e, chunk) => {
        if (isEditMode) {
            onActionCallback({
                chunk,
                changes: -1
            }, 'chunk-add-delete', e);
        }
    }

    const onAddChunk = (e, chunk) => {
        if (isEditMode) {
            onActionCallback({
                chunk,
                changes: 1
            }, 'chunk-add-delete', e)
        }
    }

    return (<Flex
        wrap
        align="center"
    >
        {chunks.flatMap((el, index) => {
            const result = [];
            if (el.count < 0) return result;
            for (let i = 0; i < el.count; i++) {
                const isHovered = hovered === i + index;
                result.push(<Flex
                    align="center"
                    justify="center"
                    onMouseEnter={() => setHovered(i + index)}
                    onMouseLeave={() => setHovered(null)}
                    className={cn(`mr-1 mb-1 border border-${el.color}-500 bg-${el.color}-500 cursor-pointer`, {
                        [btnRemoveClass]: isEditMode,
                        'bg-orange-500': el.color === 'orange',
                    })}
                    onClick={(e) => onDeleteChunk(e, el)}
                    style={chunkStyles[size]}
                >
                    {isEditMode && isHovered ? <Icon path={mdiClose} size={0.7} /> : ' '}
                </Flex>);
            }
            if (isEditMode) {
                result.push(<Flex
                    align="center"
                    justify="center"
                    className={cn(`mr-1 mb-1 border cursor-pointer`, {
                        [btnBlue]: el.color === "blue",
                        [btnOrange]: el.color === "orange",
                        [btnGray]: el.color === "gray",
                    })}
                    style={chunkStyles[size]}
                    onClick={(e) => onAddChunk(e, el)}
                >
                    <Icon path={mdiPlus} size={0.75} />
                </Flex>)
            }
            return result;
        })}
        <span className="text-xs -mt-1 text-blue-500">
            {total ? '(' + (total / 2).toFixed(1) + ' hours)' : null}
        </span>
    </Flex>)
}
ChunksComponent.propTypes = {
    total: number,
    chunks: array,
    size: string,
    isEditMode: bool,
    onActionCallback: func
}
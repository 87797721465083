import { useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import moment from 'moment';
import { getListDocumentsOrIdea } from '../../projectFlow/actions/DocumentsActions';
import { getListOfProjectGoalsRequest } from '../../projectFlow/actions/projectGoalsActions';
import { getListOfProjectMilestonesRequest } from '../../projectFlow/actions/projectMilestonesActions';
import { setArchiveProjectsArr, setArchiveCurrentProject } from '../../projectFlow/reducers/slicers/projectAllScoringSlicer';
import { updateProjectScoring } from '../../projectFlow/actions/projectFlowActions';

export default function useArchiveScore() {
  const dispatch = useDispatch();

  const archiveScoreTitle = 'Archive';

  const updateProject = async (props) => {
    const {
      projectUuid,
      projectPartition,
      archiveScore,
    } = props;
    const data = {
      uuid: projectUuid,
      params: {
        archiveScoring: {
          updated: moment(),
          archiveScore,
        },
      },
      partition: projectPartition,
    };
    return dispatch(updateProjectScoring(data));
  };

  const getArchiveProjectDocs = async (uuid, partition) => {
    const config = {
      parent: uuid,
      entity_type: ['projectDocument', 'projectIdea', 'projectSpreadsheet'],
      depth: 1,
      params: {},
    };
    const resp = await dispatch(getListDocumentsOrIdea(config, partition));
    return { docsCount: resp?.total };
  };

  const getArchiveProjectGoals = async (uuid, partition) => {
    const config = {
      parent: uuid,
      depth: 0,
      params: {},
    };
    const resp = await dispatch(getListOfProjectGoalsRequest(config, partition));
    return { goalsCount: resp?.total };
  };

  const getArchiveProjectMilestones = async (uuid, partition) => {
    const config = {
      parent: uuid,
      depth: 0,
      params: {},
    };
    const resp = await dispatch(getListOfProjectMilestonesRequest(config, partition));
    return { milestonesCount: resp?.total };
  };

  const checkIsNumber = (val) => {
    const isNotValidNumber = isNil(val) || typeof val !== 'number' || !Number.isFinite(val);
    return isNotValidNumber ? 0 : val;
  };

  const computeArchiveIndex = (props) => {
    const {
      descrDocSymbols,
      docsCount,
      goalsCount,
      infraDocSymbols,
      milestonesCount,
      modified,
      // partition,
      // title,
      // uuid,
    } = props;

    const K_date = 1.5 / 1.5 ** moment().diff(modified, 'years');
    const K_description = checkIsNumber(descrDocSymbols / 300);
    const K_infrastructure = checkIsNumber(infraDocSymbols / 300);
    const K_docs = checkIsNumber(docsCount / 10);
    const K_miles = checkIsNumber(milestonesCount / 10);
    const K_goals = checkIsNumber(goalsCount / 10);
    // console.log('descrDocSymbols', descrDocSymbols);
    // console.log(K_date, K_description, K_infrastructure, K_docs, K_miles, K_goals);
    const archiveIndex = K_date * (K_description + K_infrastructure + K_docs + K_miles + K_goals);
    const finalIndex = Number((Math.round(checkIsNumber(archiveIndex) * 1000) / 1000).toFixed(2));
    return finalIndex;
  };

  const computeProjectArchiveScore = async (
    project,
    isNeedProject,
    isAllScoring,
  ) => {
    try {
      const { uuid, partition } = project;
      const requestsArr = [
        getArchiveProjectDocs,
        getArchiveProjectGoals,
        getArchiveProjectMilestones];
      const allResp = await Promise.all(requestsArr.map(async (request) => await request(uuid, partition)));
      const dataForCompute = {
        ...project,
        ...allResp[0],
        ...allResp[1],
        ...allResp[2],
      };

      const archiveScore = computeArchiveIndex(dataForCompute);
      const finalData = {
        uuid,
        archiveScore,
        updated: moment(),
      };

      if (!isAllScoring) {
        const res = await updateProject({
          archiveScore,
          projectUuid: uuid,
          projectPartition: partition,
        });

        if (isNeedProject) {
          return res;
        }
      }

      return finalData;
      // console.log('finalData', finalData);
      dispatch(setArchiveCurrentProject(finalData));
    } catch (e) {
      console.log('archiveScore error', e);
    }

  // console.log('archiveIndex',archiveIndex);
  };

  const computeAllProjectsArchiveScore = async (projectArray, isAllScoring) => {
    // console.log('computeAllProjectsArchiveScore projectArray', projectArray);
    // const archiveProjects = projectArray?.filter((project) => project?.status === 'archive');
    const pureDataArr = projectArray.map((project) => {
      const {
        uuid, partition, modified, title, infrastructureDoc, descriptionDoc,
      } = project;
      const newData = {
        uuid,
        partition,
        modified,
        title,
        infraDocSymbols: infrastructureDoc?.symbols_count,
        descrDocSymbols: descriptionDoc?.symbols_count,
      };
      return newData;
    });

    const archiveProjectsData = await Promise
      .all(pureDataArr.map(async (project) => await computeProjectArchiveScore(project, false, isAllScoring)));
    dispatch(setArchiveProjectsArr(archiveProjectsData));
    // console.log('computeAllProjectsArchiveScore archiveProjectsData', archiveProjectsData);
    const finalProjectsData = projectArray.map((item) => {
      const projectArchiveData = archiveProjectsData?.find((archiveData) => archiveData?.uuid === item?.uuid);
      // console.log('projectArchiveData', projectArchiveData);
      return {
        ...item,
        archiveScoring: projectArchiveData,
      };
    });
    // console.log('finalData',finalData );
    return finalProjectsData;
  };

  const handleArchiveBackground = (score, isButtonColor) => {
    // console.log('handleArchiveBackground score', score);
    if (score === '-') {
      return !isButtonColor ? 'default' : 'scoringBtnDefault';
    }
    if (score >= 20) {
      return !isButtonColor ? 'success' : 'scoringBtnGreen';
    }
    if (score >= 10) {
      return !isButtonColor ? 'warning' : 'scoringBtnWarning';
    }
    return !isButtonColor ? 'error' : 'scoringBtnDanger';
  };

  const archiveTooltipText = (
    <>
      <div>
        <strong>
          Archive score
        </strong>
        {' '}
        -   an indicator reflecting the value of the archive project at the moment.
      </div>
      <div>
        <strong>Threshold values: </strong>
        it&apos;s recommended to pay attention when the value is less than 10.
      </div>
    </>
  );

  return {
    archiveScoreTitle,
    archiveTooltipText,
    computeAllProjectsArchiveScore,
    computeProjectArchiveScore,
    handleArchiveBackground,
  };
}

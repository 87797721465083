import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiCalendarClock, mdiAllInclusive } from '@mdi/js';

import { useTranslation } from 'react-i18next';

import DateLeftEdit from '../DateLeftEdit/DateLeftEdit';
import DateLeft from '../DateLeft';
import { capitalize } from 'lodash';

function ExpirationTime({
  expirationDate,
  isEdit,
  unlimited = false,
  onChangeFieldCallback,
}) {
  const { t } = useTranslation();
  const [currentExpirationDate, setCurrentExpirationDate] = useState(null);

  const title = capitalize(t('wms.labels.expiration_date', 'expiration date'));

  const renderContent = () => {
    if (unlimited) return (<span className="ml-1 text-danger">(because important)</span>);

    if (isEdit) {
      return (
        <DateLeftEdit
          expirationDate={currentExpirationDate}
          onChangeFieldCallback={onChangeFieldCallback}
        />
      );
    }

    return (
      <DateLeft
        date={currentExpirationDate}
      />
    );
  };

  useEffect(() => {
    if (expirationDate) {
      setCurrentExpirationDate(expirationDate);
    }
  }, [expirationDate]);

  return (
    <div className="flex items-center">
      <div className="flex items-center">
        <Tooltip title={title}>
          <Icon path={unlimited ? mdiAllInclusive : mdiCalendarClock} size={1} className="text-danger" />
        </Tooltip>
        {renderContent()}
      </div>
    </div>
  );
}

ExpirationTime.propTypes = {
  expirationDate: PropTypes.string,
  isEdit: PropTypes.bool,
  unlimited: PropTypes.bool,
  onChangeFieldCallback: PropTypes.func,
};

export default ExpirationTime;

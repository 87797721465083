import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, string } from 'prop-types';

import CustomCardComponent from '../../../../../projectFlow/components/commonComponents/CustomCardComponent';
import ExpandModeLayout from '../../../../../projectFlow/components/commonComponents/expandModeLayout/ExpandModeLayout';
import ListOfGoals from '../../../../../projectFlow/components/goals/ListOfGoals';
import ProjectGoalView from '../../../../../projectFlow/components/goals/ProjectGoalView';

import { clearProjectGoal, getProjectGoalRequest } from '../../../../../projectFlow/actions/projectGoalsActions';
import { useGetProjectsGoals } from '../../../../../userFlow/hooks/personalDashboardHooks/useGetProjectsGoals';
import useURLParams from '../../../../../hooks/useURLParams';
import useMouseMiddleRedirect from '../../../../../hooks/useMouseMiddleRedirect';
import { partitionNamesConfig } from '../../../../../api/appConfig';
import {  getListAndReadEntities } from '../../../../../entity/actions/entityActions';
import { getPartitionPMUUID } from '../../../../../config/selectors/selectors';
import { getOtherUserPublicEntity, getUserPublicProfile } from '../../../../../userFlow/store/selectors/selectors';

const hideElements = ['createGoal', 'deleteGoal'];
const disabledElements = ['textarea', 'title', 'status', 'responsible', 'options'];

function DashboardGoalsCardWrapper({
  defaultPartition = partitionNamesConfig.partition1,
  readOnlyComments = false,
}) {
  const dispatch = useDispatch();
  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams();
  const { goal } = getURLParams();
  const { onMouseMiddleClick } = useMouseMiddleRedirect();

//add getGoals logic
  const [goals, setGoals] = useState(null)
  const [loadingGoals, setLoadingGoals] = useState(false)
  const [searchDashboardData, setSearchDashboardData] = useState('')
  const partitionPM = useSelector(getPartitionPMUUID);
  const myUserPublicEntity = useSelector(getUserPublicProfile);
  const otherUserPublicEntity = useSelector(getOtherUserPublicEntity);

  const actorUUID = otherUserPublicEntity.actor || myUserPublicEntity.actor;

  const getGoals = async () => {
    setLoadingGoals(true)
    const data = {
        entity_type: "projectGoal",
        owner: actorUUID,
        depth: 0,
        parent: partitionPM,
        search_data: {
          value: searchDashboardData,
          ignore_case: true,
          fields: {
            params: ['title'],
          },
        }

    };
    const constants = [
      "GET_ENTITY_REQUEST",
      "GET_ENTITY_SUCCESS",
      "GET_ENTITY_FAILURE"
    ]

    const options = {
      partition: defaultPartition,
        
    }
      const userGoals = await dispatch(getListAndReadEntities({
        data,
        constants,
        options,
      }))
      setGoals(userGoals.data)
      setLoadingGoals(false)
      
}

useEffect(() => {
  getGoals()
}, [searchDashboardData])


  const [paginationWidthSortAndSearchGoals, setPaginationWidthSortAndSearchGoals] = useState({});

  const clearGoal = () => clearSearchParams(['goal']);

  const selectGoal = (uuid) => {
    if (uuid === goal) {
      clearSearchParams(['goal']);
    } else {
      setSearchParams({ goal: uuid });
    }
  };

  const onMouseMiddleClickHandler = (e, record) => {
    const { uuid: goalUUID } = record;
    const searchParams = { activeCard: 'goals', goal: goalUUID };
    onMouseMiddleClick(e, searchParams);
  };

  useEffect(() => {
    if (goal) {
      dispatch(getProjectGoalRequest({
        uuid: goal,
        partition: defaultPartition,
      }));
    } else {
      dispatch(clearProjectGoal);
    }
  }, [goal]);

  useGetProjectsGoals(paginationWidthSortAndSearchGoals);

  return (
    <Col span={24} className="flex">
      <ExpandModeLayout localStorageTarget="planningView">
        <ExpandModeLayout.Table>
          <ListOfGoals
            className="mb-3"
            partition={defaultPartition}
            hideElements={hideElements}
            onMouseMiddleClick={onMouseMiddleClickHandler}
            getPaginationAndSearch={setPaginationWidthSortAndSearchGoals}
            selectedGoal={goal}
            selectRowCallback={selectGoal}
            dashboardGoals={goals}
            isDashboardTable={true}
            loadingDashboardGoals={loadingGoals}
            setSearchDashboardData={setSearchDashboardData}
          />
        </ExpandModeLayout.Table>

        <ExpandModeLayout.Card>
          {goal && (
            <CustomCardComponent type="goal">
              <ProjectGoalView
                disabledElements={disabledElements}
                backBtnCallback={clearGoal}
                defaultPartition={defaultPartition}
                readOnlyComments={readOnlyComments}
              />
            </CustomCardComponent>
          )}
        </ExpandModeLayout.Card>
      </ExpandModeLayout>
    </Col>
  );
}

DashboardGoalsCardWrapper.propTypes = {
  defaultPartition: string,
  readOnlyComments: bool,
};

export default DashboardGoalsCardWrapper;

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  isEmpty, isNil,
} from 'ramda';

import { getMainAreaUUID } from '../../../entity/selectors/selectors';
import { getPartitionPMUUID } from '../../../config/selectors/selectors';
import { axiosRequestPost } from '../../../api/apiAxios';
import { getMainEntityArea } from '../../actions/projectFlowActions';

class CheckMainAreaContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideSection: !this.props.showSection,
    };
  }

  componentDidMount() {
    const { partitionPM, areaPartitionUuid } = this.props;

    if (isEmpty(areaPartitionUuid) || isNil(areaPartitionUuid)) {
      if (partitionPM.length !== 0) {
        this.checkMainAreaEntity(partitionPM);
      }
    } else {
      this.changeHideSection(false);
    }
  }

  componentDidUpdate(prevProps) {
    const { areaPartitionUuid } = this.props;
    const { areaPartitionUuid: prevAreaPartitionUuid } = prevProps;

    if (areaPartitionUuid !== prevAreaPartitionUuid
            && (!isEmpty(areaPartitionUuid) && !isNil(areaPartitionUuid))) {
      this.changeHideSection(false);
    }
  }

  changeHideSection = (value) => {
    this.setState({
      hideSection: value,
    });
  };

  async checkMainAreaEntity(parent) {
    const { getMainEntityArea } = this.props;

    try {
      await getMainEntityArea({ parent });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { hideSection } = this.state;
    const { children } = this.props;

    return !hideSection && children;
  }
}

function mapStateToProps(state) {
  return {
    areaPartitionUuid: getMainAreaUUID(state),
    partitionPM: getPartitionPMUUID(state),
  };
}

CheckMainAreaContainer.propTypes = {
  areaPartitionUuid: PropTypes.string,
  children: PropTypes.element,

  partitionPM: PropTypes.string,
  showSection: PropTypes.bool,
};

export default connect(mapStateToProps, {
  axiosRequestPost,
  getMainEntityArea,
})(CheckMainAreaContainer);

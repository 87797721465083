import { EntityConstants } from '../constants/Constants';

const initialState = {
  filteredData: {},
  resetTags: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EntityConstants.FILTER_SET_FILTERED_ENTITY:

      return {
        ...state,
        filteredData: {
          ...state.filteredData,
          ...action.payload,
        },
      };

    case EntityConstants.FILTER_RESET_TAGS:
      const resetIds = [...action.payload];
      let resetTags = [...state.resetTags];

      resetIds.forEach((item) => {
        if (resetTags.includes(item)) {
          resetTags = resetTags.filter((tag) => tag !== item);
        } else {
          resetTags = [...resetTags, item];
        }
      });

      return {
        ...state,
        resetTags,
      };

    default:
      return state;
  }
};

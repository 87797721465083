import React, { useEffect, useMemo, useRef, useState } from "react";

import moment from "moment";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { array, bool, func, object, string } from "prop-types";

import useURLParams from "../../../../hooks/useURLParams";
import NewIssueForm from "../../issues/newIssue/NewIssueForm";
import { partitionNamesConfig } from "../../../../api/appConfig";
import useMyRecurringIssues from "../hooks/useMyRecurringIssues";
import { getIssuesScoringData } from "../../../reducers/issues/IssuesSlicer";
import { getFirstStringOfText } from "../../../../MainUtils";
import { getUserPublicProfileUUID } from "../../../../userFlow/store/selectors/selectors";
import { entityCreate, incrementAndGetCount } from "../../../../entity/actions/entityActions";
import { getProjectScoring, updateProjectScoring } from "../../../actions/projectFlowActions";
import { defaultIssuesPagination, defaultOrderRules } from "../../issuesView/utils";
import useTableFilter from "../../../../components/_ui/hooks/useTableFilter";
import { defaultIssueFilter } from "../../issuesView/constants/issueFilterOptions";

export default function NewRecurringIssueComponents({
  isAssignOpen,
  setIsAssignOpen,
  defaultRecurringIssueConfig,
  partitionType,
  hideView,
  disabledView,
  customParentIssue,
  createRecurringIssueCallback,
  handleOk,
  setIsChanged = () => {},
  mandatoryRecurringIssue = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const issuesScoringData = useSelector(getIssuesScoringData);

  const { getURLParams } = useURLParams();
  const { activeProject } = getURLParams() || {};

  const isRecurring = true;

  const initialFormState = {
    title: "",
    description: "",
    status: "created",
    tracker: "ticket",
    priority: "low",
    partition: null,
    lifeTime: moment().add(2, "week").format("YYYY-MM-DD"),
    estimated_time: "easy",
    assignedProject: "",
    usersSearch: [],
    assignedVector: "",
    files: [],
    audioFiles: [],
    tags: [],
    links: [],
    selectedVectorData: {},
    selectedProjectData: {},
    usersUuidsForAssigned: [],
    testCaseData: "",
    isGlobalCreateModal: false,
  };  

  const [formState, setFormState] = useState(initialFormState);
  const [loading, setLoading] = useState(true);

  const {
    title,
    description,
    status,
    tracker,
    priority,
    estimated_time,
    partition,
    assignedProject,
    assignedVector,
    files,
    audioFiles,
    lifeTime,
    tags,
    links,
    usersSearch,
    isGlobalCreateModal,
    selectedProjectData,
    selectedVectorData,
    usersUuidsForAssigned,
    testCaseData,
  } = formState || {};

  const [totalCountRecurringIssues, setTotalCountRecurringIssues] = useState(0);
  const { requestCreateRecurringIssue, requestGetListOfRecurringIssues } = useMyRecurringIssues();

  const {
    filterConfig: recurringIssueFilterConfig,
  } = useTableFilter('recurring', defaultIssueFilter);

  const [recurringIssuesConfig, setRecurringIssuesConfig] = useState({
    ...defaultOrderRules,
    ...defaultIssuesPagination,
    ...recurringIssueFilterConfig,
  });

  const abort = useRef();

  useEffect(() => {
    if (
      title === "" &&
      description === "" &&
      status === "created" &&
      tracker === "ticket" &&
      priority === "low" &&
      estimated_time === "easy" &&
      isEmpty(usersSearch) &&
      assignedVector === "" &&
      isEmpty(files) &&
      isEmpty(audioFiles) &&
      isEmpty(tags) &&
      isEmpty(links) &&
      testCaseData === "" 
    ) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  }, [JSON.stringify(formState)]);

  const onChangeField = (data) => {
    setFormState((prev) => ({ ...prev, ...data }));
  };

  const getTitle = useMemo(
    () => getFirstStringOfText(description.trim(), 128),
    [description]
  );

  const handleFormSubmit = async () => {
    setLoading(true);
    await requestCreateRecurringIssue(formState, getTitle, partitionType, handleOk, createRecurringIssueCallback);
    requestGetListOfRecurringIssues(recurringIssuesConfig, setTotalCountRecurringIssues, abort)
  };

  const selectedProjectCallback = (value) => {
    onChangeField({
      ...value,
      assignedVector: "",
      selectedVectorData: {},
      usersSearch: [],
      partition:
        get(value, ["selectedProjectData", "partition"]) === "public"
          ? partitionNamesConfig.partition3
          : partitionNamesConfig.partition1,
      usersUuidsForAssigned: value?.selectedProjectData?.usersSearch,
    });
  };

  const selectedVectorCallback = (value) => {
    if (
      get(value, ["assignedVector"]) === "" &&
      isEmpty(get(value, ["assignedVector"]))
    ) {
      onChangeField({
        ...value,
        partition: null,
        usersUuidsForAssigned: [],
      });
    } else {
      onChangeField({
        ...value,
        partition:
          get(value, ["selectedVectorData", "partition"]) === "public"
            ? partitionNamesConfig.partition3
            : partitionNamesConfig.partition1,
        usersUuidsForAssigned: get(value, "assignedVector")
          ? value?.selectedVectorData?.usersSearch
          : selectedProjectData?.params?.usersSearch,
      });
    }
  };

  const selectedUsersCallback = (value) =>
    onChangeField({
      usersSearch: value,
    });

  // const onChangeLifeTime = (date) => onChangeField({ lifeTime: date });
  const onChangeFiles = (filesData) => onChangeField(filesData);

  const getInputValueAndChangeField = (e) => {
    const {
      target: { value, id },
    } = e || {};

    onChangeField({ [id]: value });
  };

  const onChangeEstimatedTime = (e) => {
    const { estimated_time } = e;

    onChangeField({ estimated_time });
  };

  const setInitData = () => {
    if (defaultRecurringIssueConfig) {
      setFormState((prev) => ({
        ...prev,
        ...defaultRecurringIssueConfig,
      }));
    }
  };

  useEffect(() => {
    setInitData();
    setLoading(false);
  }, []);

  // console.log('formState', formState);

  return (
    <NewIssueForm
      bugTrackedTitle={defaultRecurringIssueConfig?.testCaseData?.step?.description}
      testSteps={defaultRecurringIssueConfig?.testCaseData?.allSteps}
      isAssignOpen={isAssignOpen}
      setIsAssignOpen={setIsAssignOpen}
      hideView={hideView}
      disabledView={disabledView}
      partitionType={partitionType}
      title={title}
      getTitle={getTitle}
      description={description}
      status={status}
      tracker={tracker}
      priority={priority}
      links={links}
      projectUsers={get(selectedProjectData, "params.users", [])}
      estimated_time={estimated_time}
      usersSearch={usersSearch}
      assignedProject={assignedProject}
      assignedVector={assignedVector}
      usersUuidsForAssigned={usersUuidsForAssigned}
      lifeTime={lifeTime}
      selectedVectorData={selectedVectorData}
      files={files}
      audioFiles={audioFiles}
      loading={loading}
      setLoading={setLoading}
      onChangeField={onChangeField}
      handleFormSubmit={handleFormSubmit}
      onChangeFiles={onChangeFiles}
      onChangeEstimatedTime={onChangeEstimatedTime}
      getInputValueAndChangeField={getInputValueAndChangeField}
      selectedUsersCallback={selectedUsersCallback}
      selectedVectorCallback={selectedVectorCallback}
      selectedProjectCallback={selectedProjectCallback}
      isRecurring={isRecurring}
    />
  );
}

NewRecurringIssueComponents.propTypes = {
  isAssignOpen: bool,
  setIsAssignOpen: func,
  defaultRecurringIssueConfig: object,
  partitionType: string,
  hideView: array,
  createRecurringIssueCallback: func,
  handleOk: func,
  customParentIssue: string,
  disabledView: array,
  setIsChanged: func,
  mandatoryRecurringIssue: bool,
};

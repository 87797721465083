import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TagsForEntity from '../../../entity/components/TagsForEntity';

import { updateProjectRequest } from '../../actions/projectFlowActions';
import { getProjectParams, getProjectUUID } from '../../selectors/selectors';
import { ProjectTypeCommonConstants } from '../../constants/Constants';

function ProjectTagsSettings({
  partitionType,
}) {
  const dispatch = useDispatch();

  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);

  const {
    tags = [],
  } = projectParams || {};

  const [localProjectTags, setLocalProjectTags] = useState(tags);

  const updateProject = (newTagsForProject) => {
    dispatch(updateProjectRequest({
      params: {
        tags: newTagsForProject,
      },
      uuid: projectUUID,
      partition: partitionType,
      constants: [
        ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_REQUEST,
        ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_SUCCESS,
        ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_FAILURE,
      ],
    }))
      .then(async (res) => {
        setLocalProjectTags(res[0]?.params?.tags);
      });
  };

  return (
    <TagsForEntity
      entityType="project"
      defaultTags={localProjectTags}
      addOrRemoveTagCallBack={updateProject}
    />
  );
}

export default ProjectTagsSettings;

ProjectTagsSettings.propTypes = {
  partitionType: PropTypes.string,
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Col, Row } from 'antd';

import GalleryListWithoutActiveCard from './GalleryListWithoutActiveCard';
import DashboardActiveCardWrapper from './DashboardCards/DashboardActiveCardWrapper';

import useURLParams from '../../hooks/useURLParams';

function GridOfCards({
  grid = [],
  otherUser,
  currentUserPublicEntity,
}) {
  const { getURLParams } = useURLParams();
  const { activeCard } = getURLParams();

  const activeCardItem = useMemo(
    () => grid.flatMap((item) => item.galleryList).find((el) => el?.link === activeCard),
    [activeCard, grid],
  );

  const activeCardComponent = useMemo(
    // передача пропсов в компонент как простая передача параметра (объект) в функцию component
    () => activeCardItem?.component({ otherUser, currentUserPublicEntity }) || null,
    [activeCard, otherUser, currentUserPublicEntity],
  );

  const memoisedGrid = useMemo(() => grid.map((item, key) => {
    const { className, labelForRow, galleryList } = item || {};

    return (
      labelForRow ? (
        <div key={key + labelForRow}>
          <h4 className="mt-3 pt-3">{labelForRow}</h4>
          <GalleryListWithoutActiveCard
            galleryList={galleryList}
            otherUser={otherUser}
          />
        </div>
      ) : (
        <GalleryListWithoutActiveCard
          galleryList={galleryList}
          otherUser={otherUser}
        />
      )
    );
  }), [grid]);

  return (
    <>
      {activeCard ? (
        <Col
          span={24}
          className="flex flex-wrap h-full"
        >
          <DashboardActiveCardWrapper title={activeCardItem?.title}>
            {activeCardComponent}
          </DashboardActiveCardWrapper>
        </Col>
      ) : memoisedGrid}
    </>
  );
}

export default GridOfCards;

GridOfCards.propTypes = {
  currentUserPublicEntity: PropTypes.any,
  grid: PropTypes.array,
  otherUser: PropTypes.any,
};

import React from 'react';
import { Alert } from 'antd';

function GptAlert() {
  return (
    <Alert
      className="h-full"
      message="Выберите чат или создайте новый..."
      description={(
        <>
          <p>
            Несколько чатов в системе могут быть полезными по нескольким причинам:
          </p>
          <p>
            1. Разные контексты: Несколько чатов могут использоваться для различных контекстов общения.
          </p>
          <p>
            2. Разделение ролей: Каждый чат может быть настроен так, чтобы обслуживать определенные роли или задачи. Например, один чат может быть направлен на техническую поддержку, другой - на консультирование и т. д.
          </p>
          <p>
            3. Несколько чатов могут быть выгодны для организации и управления различными потоками общения, предоставляя более гибкую и персонализированную обработку запросов и информацию для пользователей.
          </p>
        </>
      )}
      type="info"
      showIcon
    />
  );
}

export default GptAlert;

import React, { useEffect, useState } from 'react';
import {
  Card, Col, Row, TimePicker,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import BaseButton from '../../components/_ui/BaseButton/BaseButton';

import { getPartitionPMUUID } from '../../config/selectors/selectors';
import { getOrCreateSingle, entityUpdate } from '../../entity/actions/entityActions';
import { antNotification } from '../../MainUtils';
import BaseCard from "../../components/_ui/BaseCard/BaseCard";
import { partitionNamesConfig } from '../../api/appConfig';

function RobotsFreeTime() {
  const dispatch = useDispatch();

  const [settingsFetching, setSettingsFetching] = useState(false);
  const [settingsEntity, setSettingsEntity] = useState(null);

  const format = 'HH:mm';

  const {
    uuid,
    params: {
      startTimeRobots,
      endTimeRobots,
    } = {},
  } = settingsEntity || {};

  const pmPartition = useSelector(getPartitionPMUUID);

  const updateRobotsEntity = (data) => (
    dispatch(entityUpdate({
      data,
      constants: [
        'UPDATE_ENTITY_SETTINGS_REQUEST',
        'UPDATE_ENTITY_SETTINGS_SUCCESS',
        'UPDATE_ENTITY_SETTINGS_FAILURE',
      ],
      partition: partitionNamesConfig.partition1,
    })).then((res) => {
      setSettingsEntity(res);
      antNotification('success', 'Success');
    }).catch(() => {})
      .finally(() => {
        setSettingsFetching(false);
      })
  );

  const onChange = (time, name) => {
    const data = {
      entity_type: 'entityServiceSettings',
      entity_uuids: [uuid],
      params: {},
    };

    data.params[name === 'start' ? 'startTimeRobots' : 'endTimeRobots'] = moment(time).format();

    updateRobotsEntity(data);
  };

  const resetRobotsTime = () => {
    setSettingsFetching(true);

    const data = {
      entity_type: 'entityServiceSettings',
      entity_uuids: [uuid],
      params: {
        startTimeRobots: null,
        endTimeRobots: null,
      },
    };
    updateRobotsEntity(data);
  };

  const getEntitySettings = () => {
    setSettingsFetching(true);

    dispatch(
      getOrCreateSingle({
        data: {
          entity_type: 'entityServiceSettings',
          parent: pmPartition,
          params: {
            startTimeRobots: moment('3:30', 'HH:mm').format(),
            endTimeRobots: moment('4:30', 'HH:mm').format(),
          },
        },
        constants: [
          'ENTITY_SETTINGS_REQUEST',
          'ENTITY_SETTINGS_SUCCESS',
          'ENTITY_SETTINGS_FAILURE',
        ],
        partition: partitionNamesConfig.partition1,
      }),
    ).then((res) => {
      setSettingsEntity(res);
      setSettingsFetching(false);
    }).catch(() => {
      setSettingsFetching(false);
    });
  };

  useEffect(() => {
    getEntitySettings();
  }, []);

  return (
    <BaseCard
      title={(
        <Row>
          <Col span={12}>
            <span>Robots free time</span>
          </Col>
          <Col span={12} className="flex">
            <BaseButton
              size="small"
              className="btnWarning-outline ml-auto"
              onClick={resetRobotsTime}
            >
              Reset
            </BaseButton>
          </Col>
        </Row>
    )}
      className="w-full"
    >
      <Row>
        <Col span={4}>
          <span>Start time:</span>
        </Col>
        <Col>
          {settingsFetching ? 'Loading...'
            : (
              <TimePicker
                defaultValue={startTimeRobots && moment(startTimeRobots)}
                format={format}
                use12Hours={false}
                onChange={(time) => onChange(time, 'start')}
              />
            )}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={4}>
          <span>End time:</span>
        </Col>
        <Col>
          {settingsFetching ? 'Loading...'
            : (
              <TimePicker
                defaultValue={endTimeRobots && moment(endTimeRobots)}
                format={format}
                use12Hours={false}
                onChange={(time) => onChange(time, 'end')}
              />
            )}
        </Col>
      </Row>
    </BaseCard>
  );
}

export default RobotsFreeTime;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiChevronDoubleDown, mdiChevronDoubleUp } from '@mdi/js';

import { Col, Row } from 'antd';
import TagsSettingList from '../../../components/filter/TagsSettingList';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import { capitalize } from 'lodash';
import { getAreas, getMe } from '../../selectors/selectors';
import { resetTags } from '../../actions/PtcActionsForHook';
import ProjectAreaAndManagement from './ProjectAreaAndManagement';

export default function ProjectFilterTags({
  className,
  partition,
  partitionUUID,
  selectedTags = [],
  onSelectTags,
  newProjectCreatedCallback,
  setNewProjectCreatedCallback,
  showMoreCallback,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const myUUID = useSelector(getMe);
  const allAreas = useSelector(getAreas);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const tagsArr = [
    {
      isRow: true,
      // rowText: capitalize(t('wms.table.headers.tags', 'tags')),
      isReset: true,
      tags: [{
        key: 'all',
        label: capitalize(t('wms.filters.reset', 'reset')),
      }],
    },
    {
      lettersLimit: 25,
      tags: [
        ...allAreas.map(({ name, uuid }) => (
          {
            key: 'areas',
            // TO DO: search by uuid of tag
            // value: uuid,
            value: name,
            label: name,
          }
        )),
      ],
    },
  ].filter((el) => !el.hide);

  const defaultSelectedTags = [].concat(selectedTags);

  const btnOnClick = () => {
    setIsCollapsed(!isCollapsed);
    showMoreCallback?.();
  };

  useEffect(() => {
    if (newProjectCreatedCallback) {
      dispatch(resetTags('listOfProjectsFilter'));
      setNewProjectCreatedCallback(false);
    }
  }, [newProjectCreatedCallback]);

  return (
    <Col span={24} className={`${className} p-3`}>
      {/* <div className={`flex ${className} pl-3 pb-2`}> */}
      <Row>
        <Col><h5>Tags</h5></Col>
        <Col className="ml-3">
          <ProjectAreaAndManagement
            partitionUUID={partitionUUID}
            partition={partition}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <TagsSettingList
            size="small"
            id="project_tags"
            allTags={tagsArr}
            defaultSelectedTags={defaultSelectedTags}
            // customClassName="flex justify-end"
            onSelectTags={onSelectTags}
          />
        </Col>
        {/* <BaseButton */}
        {/*  size="small" */}
        {/*  onClick={btnOnClick} */}
        {/*  type="primary" */}
        {/*  className="mt-1" */}
        {/* > */}
        {/*  /!* Show more *!/ */}
        {/*  <Icon */}
        {/*    path={isCollapsed ? mdiChevronDoubleUp : mdiChevronDoubleDown} */}
        {/*    size={0.7} */}
        {/*  /> */}
        {/* </BaseButton> */}
      </Row>
      {/* </div> */}
    </Col>
  );
}
ProjectFilterTags.propTypes = {
  className: PropTypes.string,
  partition: PropTypes.string,
  partitionUUID: PropTypes.string,
  selectedTags: PropTypes.array,
  onSelectTags: PropTypes.func,
  newProjectCreatedCallback: PropTypes.func,
  setNewProjectCreatedCallback: PropTypes.func,
  showMoreCallback: PropTypes.func,
};

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useLayoutEffect, useRef } from 'react';
import {
  any, bool, func, number, string,
} from 'prop-types';
import cn from 'classnames';
import './SpecialTextArea.scss';

export default function SpecialTextArea({
  id,
  defaultValue = '',
  maxLength,
  isEdit,
  onChange,
  placeholder = '',
  className,
}) {
  const ref = useRef(null);
  const pattern = /(http:|https:)+[^\s]+[\w]/gi;

  const onChangeData = (e) => {
    e.preventDefault();
    onChange?.({ id, value: e.currentTarget.innerText });
  };

  const onKeyDown = (event) => {
    if (maxLength) {
      const text = event.currentTarget.textContent;
      /* Any Shortcut except Ctrl + V */
      const isValidShortcut = (event.ctrlKey && event.keyCode !== 86);

      /* Backspace - Delete - Arrow Keys - Ctrl - Shift */
      const isValidKeyCode = [8, 16, 17, 37, 38, 39, 40, 46].includes(event.keyCode);

      if (text.length >= +maxLength && !isValidKeyCode && !isValidShortcut) {
        event.preventDefault();
      }
    }
  };

  const onPaste = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // get text representation of clipboard
    const text = (e.originalEvent || e).clipboardData.getData('text/plain');

    // insert text manually
    document.execCommand('insertHTML', false, text);
  };

  useLayoutEffect(() => {
    if (isEdit) {
      ref.current.innerHTML = defaultValue;
    } else {
      ref.current.innerHTML = String(defaultValue)?.replaceAll(pattern, '<a href="$&" target="_blank">$&</a>');
    }
  }, [defaultValue, isEdit]);

  return (
    <p
      ref={ref}
      tabIndex={0}
      data-border={isEdit}
      contentEditable={isEdit}
      className={cn('mb-0 custom-textarea', className)}
      data-disabled={!isEdit}
      data-placeholder={placeholder}
      onInput={onChangeData}
      onKeyDown={onKeyDown}
      onPaste={onPaste}
    />
  );
}

SpecialTextArea.propTypes = {
  className: string,
  id: any,
  defaultValue: string,
  isEdit: bool,
  onChange: func,
  placeholder: string,
  maxLength: number,
};

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const defaultCurrentProjectScoreData = {
  uuid: '',
  score: '-',
  lastComputeTime: '',
};

const defaultAllProjectsScoreData = {
  projectsArr: [],
  lastComputeTime: '',
};

const initialState = {
  fetching: false,
  lastComputeTime: '',
  qaStructureCurrentProject: defaultCurrentProjectScoreData,
  qaActivityCurrentProject: defaultCurrentProjectScoreData,
  archiveCurrentProject: defaultCurrentProjectScoreData,

  outdatedProjectsArr: defaultAllProjectsScoreData,
  qaStructureProjectsArr: defaultAllProjectsScoreData,
  qaActivityProjectsArr: defaultAllProjectsScoreData,
  archiveProjectsArr: defaultAllProjectsScoreData,
};

export const projectAllScoringSlicer = createSlice({
  name: 'projectAllScoringSlicer',
  initialState,
  reducers: {
    setQaStructureCurrentProject: (state, action) => {
      const { uuid, qaStructureScore } = action.payload;
      const lastComputeTime = moment().format('DD-MM-YYYY HH:mm:ss');
      state.qaStructureCurrentProject = { uuid, score: qaStructureScore, lastComputeTime };
    },
    setQaActivityCurrentProject: (state, action) => {
      const { uuid, qaActivityScore } = action.payload;
      const lastComputeTime = moment().format('DD-MM-YYYY HH:mm:ss');
      state.qaActivityCurrentProject = { uuid, score: qaActivityScore, lastComputeTime };
    },
    setArchiveCurrentProject: (state, action) => {
      const { uuid, archiveScore } = action.payload;
      const lastComputeTime = moment().format('DD-MM-YYYY HH:mm:ss');
      state.archiveCurrentProject = { uuid, score: archiveScore, lastComputeTime };
    },
    setQaStructureProjectsArr: (state, action) => {
      const projectsArr = action.payload;
      const lastComputeTime = moment().format('DD-MM-YYYY HH:mm:ss');
      state.qaStructureProjectsArr = { projectsArr, lastComputeTime };
    },
    setQaActivityProjectsArr: (state, action) => {
      const projectsArr = action.payload;
      const lastComputeTime = moment().format('DD-MM-YYYY HH:mm:ss');
      state.qaActivityProjectsArr = { projectsArr, lastComputeTime };
    },
    setOutdatedProjectsArr: (state, action) => {
      const projectsArr = action.payload;
      const lastComputeTime = moment().format('DD-MM-YYYY HH:mm:ss');
      state.outdatedProjectsArr = { projectsArr, lastComputeTime };
    },
    setArchiveProjectsArr: (state, action) => {
      const projectsArr = action.payload;
      const lastComputeTime = moment().format('DD-MM-YYYY HH:mm:ss');
      state.archiveProjectsArr = { projectsArr, lastComputeTime };
    },
    clearAllScoringData: (state) => {
      // state.projectsIssuesScoringData = [];
      state.fetching = false;
      state.qaStructureCurrentProject = defaultCurrentProjectScoreData;
      state.qaActivityCurrentProject = defaultCurrentProjectScoreData;
      state.qaStructureProjectsArr = defaultAllProjectsScoreData;
      state.qaActivityProjectsArr = defaultAllProjectsScoreData;
      state.outdatedProjectsArr = defaultAllProjectsScoreData;
      state.archiveProjectsArr = defaultAllProjectsScoreData;
    },
    clearCurrentProjectScoreData: (state) => {
      state.fetching = false;
      state.qaStructureCurrentProject = defaultCurrentProjectScoreData;
      state.qaActivityCurrentProject = defaultCurrentProjectScoreData;
      state.archiveCurrentProject = defaultCurrentProjectScoreData;
    },
  },
});

const storeProjectPath = (state) => state?.projectManagementService?.project;

// export const getProjectsIssuesScoringFetching = (state) => storeProjectPath(state)?.projectAllScoringSlicer?.fetching;
export const getCurrentProjectQaStructureScore = (state) => storeProjectPath(state)?.projectAllScoringSlicer?.qaStructureCurrentProject;
export const getCurrentProjectQaActivityScore = (state) => storeProjectPath(state)?.projectAllScoringSlicer?.qaActivityCurrentProject;
export const getCurrentProjectArchiveScore = (state) => storeProjectPath(state)?.projectAllScoringSlicer?.archiveCurrentProject;
export const getQaStructureProjectsArr = (state) => storeProjectPath(state)?.projectAllScoringSlicer?.qaStructureProjectsArr;
export const getQaActivityProjectsArr = (state) => storeProjectPath(state)?.projectAllScoringSlicer?.qaActivityProjectsArr;
export const getOutdatedProjectsArr = (state) => storeProjectPath(state)?.projectAllScoringSlicer?.outdatedProjectsArr;
export const getArchiveProjectsArr = (state) => storeProjectPath(state)?.projectAllScoringSlicer?.archiveProjectsArr;
// export const getProjectsIssuesScoringLastComputeTime = (state) => storeProjectPath(state)?.projectAllScoringSlicer?.lastComputeTime;

export const {
  setQaStructureCurrentProject,
  setQaActivityCurrentProject,
  setArchiveCurrentProject,
  setQaStructureProjectsArr,
  setQaActivityProjectsArr,
  setOutdatedProjectsArr,
  setArchiveProjectsArr,
  clearAllScoringData,
  clearCurrentProjectScoreData,
} = projectAllScoringSlicer.actions;

export default projectAllScoringSlicer.reducer;

import React, { useState, useMemo } from "react";

import { Col, Flex, Segmented } from "antd";
import { UI54Button, UI54Modal, UI54Icon } from "@agpl/ui54kit";
import ProjectUsersList from "./ProjectUsersList";

import { mdiAccountPlus, mdiContentSave } from '@mdi/js';

import { USER_TABS } from "./utils";

function AddUsersPanel({
  tabs = [],
  selectedActors,
  inProject,
  setSelectedActors,
}) {
  const [currentTab, setCurrentTab] = useState(USER_TABS.project);

  // const availableTabs = useMemo(() => {
  //   const options = [];
  //   tabs.forEach((el) => {
  //     if (USER_TABS[el]) options.push(USER_TABS[el]);
  //   });
  //   return options;
  // }, [JSON.stringify(tabs)]);

  return (
    <>
      <Segmented
        options={tabs}
        block
        size='middle'
        // defaultValue={USER_TABS.project} // TO DO
        value={currentTab}
        onChange={setCurrentTab}
      />
      {currentTab === USER_TABS.all && (
        <div>{USER_TABS.all}</div>
      )}
      {currentTab === USER_TABS.project && (
        <ProjectUsersList
          selectedActors={selectedActors}
          setSelectedActors={setSelectedActors}
        />
      )}
      {currentTab === USER_TABS.vector && (
        <div>{USER_TABS.vector}</div>
      )}
    </>
  );
}

export default AddUsersPanel;

import Quill from "quill";
import { handleQuillFiles } from "../utils";

const BlockEmbed = Quill.import("blots/block/embed");

export class ImageBlot extends BlockEmbed {
  static blotName = "image";
  static tagName = "img";

  static create(value) {
    const node = super.create();
    // console.log("crdt ImageBlot create", value);
    const finalVal =
      (typeof value === "string" && { alt: "image", url: value }) ||
      (typeof value === "object" && value);
    node.setAttribute("alt", finalVal.alt);
    node.setAttribute("src", finalVal.url);
    if (value.width) {
      node.setAttribute("width", value.width);
    }
    if (value.height) {
      node.setAttribute("height", value.height);
    }
    return node;
  }

  static value(node) {
    // console.log("crdt ImageBlot value", node);
    return {
      alt: node.getAttribute("alt"),
      url: node.getAttribute("src"),
      width: node.getAttribute("width"),
      height: node.getAttribute("height"),
    };
  }
}

// export function handleQuillImage() {
//   const quill = this.quill;
//   const input = document.createElement("input");
//   input.setAttribute("type", "file");
//   input.setAttribute("accept", "image/*");

//   input.onchange = async () => {
//     const rawFile = input.files[0];
//     // console.log("crdt rawFile", rawFile);
//     const fileArr = await handleQuillFiles([rawFile]);
//     const file = fileArr?.length ? fileArr[0] : null;
//     // console.log("crdt file", file);
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         const range = quill.getSelection(true);
//         // console.log("crdt range", range);
//         quill.insertText(range.index, "\n", Quill.sources.USER);
//         quill.insertEmbed(
//           range.index + 1,
//           "image",
//           {
//             alt: file.name,
//             url: e.target.result,
//           },
//           Quill.sources.USER
//         );
//         quill.insertText(range.index + 2, "\n", Quill.sources.USER);
//         quill.setSelection(range.index + 3, Quill.sources.SILENT);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   input.click();
// }

export function handleQuillImage() {
  const quill = this.quill;
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");

  input.onchange = async () => {
    const rawFile = input.files[0];
    console.log('Selected file:', rawFile);

    const fileArr = await handleQuillFiles([rawFile]);
    const file = fileArr?.length ? fileArr[0] : null;

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let range = quill.getSelection(true);

        if (!range) {
          range = { index: quill.getLength(), length: 0 };
        }

        // Вставляем новую строку перед изображением
        quill.insertText(range.index, "\n", Quill.sources.USER);
        quill.insertEmbed(
          range.index + 1,
          "image",
          {
            alt: file.name,
            url: e.target.result,
          },
          Quill.sources.USER
        );
        // Вставляем новую строку после изображения
        quill.insertText(range.index + 2, "\n", Quill.sources.USER);
        quill.setSelection(range.index + 3, Quill.sources.SILENT);
      };
      console.log('Reading file as data URL...');
      reader.readAsDataURL(file);
    } else {
      console.error('No valid file found.');
    }
  };

  input.click(); // Открываем окно выбора файла
}



Quill.register(ImageBlot);

import { useState } from 'react';
import { useResize } from '../../hooks/useResize';

export default function useActorsW54ModalStyle() {
  let modalTimeout;

  const [isOpen, setIsOpen] = useState(false);
  const [style, setStyle] = useState({
    transition: 'margin-left 0.5s',
  });

  const { isScreenXxl } = useResize();

  const onChangeOpen = (value) => {
    if (!value) {
      setStyle((prev) => ({ ...prev, marginLeft: '0px' }));
      setIsOpen(false);
    } else {
      if (modalTimeout) {
        clearTimeout(modalTimeout);
      }
      if (isScreenXxl) {
        setStyle((prev) => ({ ...prev, marginLeft: '700px' }));
        modalTimeout = setTimeout(() => { setIsOpen(true); }, 600);
      } else {
        setIsOpen(true);
      }
    }
  };

  return [
    isOpen,
    onChangeOpen,
    style,
  ];
}

import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  bool, func, object, string,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown } from 'antd';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';

import { capitalize } from 'lodash';
import { getStatusTranslateForBtn, getButtonStylesForDropdown } from '../QaUtils';
import { stopPropagation } from '../../../../54origins/utils54origins';
import { defaultArrayStatus } from '../QaUtils';

function TestStatusDropdown({
  currentStatus,
  isArchive,
  type,
  row,
  additionalBlocking,
  onChangeStatusCallback,
  disableStatusButton = false,
}) {
  const { t } = useTranslation();
  const ref = useRef();

  const [localStatus, changeLocalStatus] = useState(currentStatus || '');

  const statusStyle = useMemo(() => getButtonStylesForDropdown(localStatus), [localStatus]);

  const onChangeStatus = (status) => {
    onChangeStatusCallback(status, row);
    changeLocalStatus(status);
  };

  if (!currentStatus) {
    return <span>-</span>;
  }

  const getDisableDropdown = type === 'case'
    ? localStatus !== 'unexecuted' && localStatus !== 'in progress'
    : localStatus === 'unexecuted';

  useEffect(() => {
    if (currentStatus) changeLocalStatus(currentStatus.toString());
  }, [currentStatus]);

  return (
    <div
      id="statusDropDown"
      ref={ref}
      onClick={stopPropagation}
    >
      {((type === 'case' && !isArchive) || (type === 'cycle') || (type === 'checklist')) && !disableStatusButton ? (
        <Dropdown
          getPopupContainer={() => ref?.current}
          menu={{
            items: defaultArrayStatus
              .filter(({value}) => value.toLowerCase() !== localStatus)
              .map(({value}) => ({
                key: value,
                // disabled: value.toLowerCase() === 'unexecuted' && type === 'cycle',
                label: capitalize(t(getStatusTranslateForBtn(value))),
              })),
            onClick: ({ key }) => onChangeStatus(key.toLowerCase()),
          }}
          // disabled={getDisableDropdown || additionalBlocking}
          trigger={['click']}
          overlayStyle={{ flex: '1 1 auto' }}
        >
          <Button size="small" style={statusStyle} onClick={stopPropagation}>
            <div className="flex justify-center items-center">
              <span>
                {capitalize(t(getStatusTranslateForBtn(localStatus)))}
              </span>
              {(!additionalBlocking)
                && <Icon path={mdiChevronDown} size={0.7} />}
            </div>
          </Button>
        </Dropdown>
      ) : (
        <Button
          size="small"
          style={statusStyle}
          onClick={stopPropagation}
        >
          <div className="flex justify-center items-center">
            <span>
              {capitalize(t(getStatusTranslateForBtn(localStatus)))}
            </span>
          </div>
        </Button>
      )}
    </div>
  );
}

TestStatusDropdown.propTypes = {
  onChangeStatusCallback: func,
  currentStatus: string,
  type: string,
  row: object,
  additionalBlocking: bool,
  disableStatusButton: bool,
};

export default TestStatusDropdown;

import React, { useMemo } from 'react';
import PropTypes, { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

import './BaseSelect.scss';
import { capitalize } from 'lodash';

const { Option } = Select;

function BaseSelect({
  disabled,
  showOption,
  isShowProjectActors,
  setIsShowProjectActors,
  projectUsers = [],
  hideSelected,
  options = [],
  onSelect,
  onDeselect,
  mode = 'default',
  placeholder,
  getPopupContainer,
  value = [],
  onChange,
  style,
  size,
  showArrow,
  allowClear,
  closeMenuOnSelect,
  notFoundContent,
  classNamePrefix,
  optionFilterProp,
  optionLabelProp,
  className,
  autoClearSearchValue,
  isClearable,
  maxMenuHeight,
  placement,
  pathTranslate,
  loading,
}) {
  const { t } = useTranslation();
  const filteredOptions = useMemo(() => {
    if (!hideSelected) {
      return options?.map((item) => (
        <Option
          key={item.value}
          value={item.value}
          label={item.label}
        >
          {pathTranslate ? capitalize(t(`${pathTranslate}.${item.label}`)) : item.label}
        </Option>
      ));
    }
    return options?.filter((item) => !value?.map((el) => el.value)
      ?.includes(item.value))?.map((item) => (
        <Option
          key={item.value}
          value={item.value}
          label={item.label}
        >
          {pathTranslate ? capitalize(t(`${pathTranslate}.${item.label}`)) : item.label}
        </Option>
      ));
  }, [hideSelected, options, value]);

  const filteredOptionsWithOptionsGroup = useMemo(() => {
    if (showOption) {
      return (
        <>
          {filteredOptions}
          {projectUsers.length > 0 && (
            <Option
              className="OptionBtn"
              disabled
            >
              <span onClick={() => setIsShowProjectActors((prev) => !prev)}>
                {isShowProjectActors
                  ? (
                    <>
                      {capitalize(t('wms.labels.hide_project_users'))}
                      <Icon size={1} path={mdiChevronUp} />
                    </>
                  )
                  : (
                    <>
                      {capitalize(t('wms.labels.show_project_users'))}
                      <Icon size={1} path={mdiChevronDown} />
                    </>
                  )}
              </span>
            </Option>
          )}
        </>
      );
    }
  }, [filteredOptions, projectUsers]);

  return (
    <Select
      loading={loading}
      showSearch
      placement={placement}
      maxMenuHeight={maxMenuHeight}
      isClearable={isClearable}
      autoClearSearchValue={autoClearSearchValue}
      optionLabelProp={optionLabelProp}
      optionFilterProp={optionFilterProp}
      disabled={disabled || loading}
      notFoundContent={notFoundContent}
      mode={mode}
      showArrow={showArrow}
      allowClear={allowClear}
      placeholder={placeholder}
      getPopupContainer={getPopupContainer}
      value={value}
      closeMenuOnSelect={closeMenuOnSelect}
      onChange={onChange}
      style={style}
      size={size}
      onSelect={onSelect}
      onDeselect={onDeselect}
      className={className}
      classNamePrefix={classNamePrefix}
    >
      {showOption
        ? filteredOptionsWithOptionsGroup
        : filteredOptions}
    </Select>
  );
}

BaseSelect.propTypes = {
  mode: PropTypes.oneOf(['multiple', 'default', 'tags']),
  optionFilterProp: PropTypes.string,
  optionLabelProp: PropTypes.string,
  showArrow: PropTypes.string,
  onChange: PropTypes.func,
  notFoundContent: PropTypes.func,
  placeholder: PropTypes.string,
  getPopupContainer: PropTypes.func,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  hideSelected: PropTypes.bool,
  autoClearSearchValue: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  style: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  placement: string,
};

BaseSelect.defaultProps = {
  // options: [],
  mode: 'default',
};

export default BaseSelect;

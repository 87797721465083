import {
    bool, func, number, string, object,
    array,
  } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spin } from "antd";
import ListOfIssuesAntDesignTable from '../issuesView/ListOfIssuesAntDesignTable';
import { listOfIssuesFetching, listOfIssuesFromStore } from '../../reducers/issues/IssuesSlicer';
import useURLParams from '../../../hooks/useURLParams';
import { columnsFilterConfig,  } from '../issuesView/constants/issueFilterOptions';
import cn from 'classnames';
import { includes } from 'lodash';


  function NewIssuesTable({
    newCurrentPage,
    countOfData,
    filterConfig,
    getIssues,
    getIssuesRequestSetting,
    filterCallback,
    projectUUID,
    multiListIssues,
    setMultiListIssues,
    listIssuesRelated,
    partitionType
  }) {

    const issues = useSelector(listOfIssuesFromStore);
    // const filteredIssues = issues.filter((issue) =>  !issue.id.includes("V"))
    // console.log("filteredIssues", filteredIssues);

    const issuesFetching = useSelector(listOfIssuesFetching);


    const { getURLParams } = useURLParams();
    const { activeIssue } = getURLParams() || {};

    const [limit, setLimit] = useState(10);


  const propsRowClassName = (record) =>
    cn(
      'item_from_list_items',
         {
           selected:  multiListIssues.includes(record.uuid),
         },
      )

    const handleChangeData = (selectedOption) => {
      if (multiListIssues.includes(selectedOption)) {
        setMultiListIssues(prev => prev.filter(el => el !== selectedOption))
      } else {
        setMultiListIssues(prev => [...prev, selectedOption])
      }
    };

    useEffect(() => {
      setMultiListIssues(listIssuesRelated)
    }, [])

    useEffect(() => {
      const timer = setTimeout(() => {
        getIssues(projectUUID)
      }, 200);

      return () => clearTimeout(timer);
    }, [])
    return (
          <Spin spinning={issuesFetching}>
              <ListOfIssuesAntDesignTable
                data={issues}
                limit={limit}
                totalCount={countOfData}
                selectedIssues={activeIssue}
                newCurrentPage={newCurrentPage}
                setLimit={setLimit}
                getIssuesRequestSetting={getIssuesRequestSetting}
                filterColumnsConfig={columnsFilterConfig}
                filterConfig={filterConfig}
                filterCallback={filterCallback}
                onChangeRowCallback={handleChangeData}
                propsRowClassName={propsRowClassName}
                rowHoverable={false}
              />
          </Spin>
    );
  }

  export default NewIssuesTable;

  NewIssuesTable.propTypes = {
    countOfData: number,
    getIssuesRequestSetting: func,
    newIssueSuccessCallback: func,
    onChangeRowCallback: func,
    filterConfig: object,
    filterCallback: func,
    partitionType: string,
    getIssues: func,
    isGlobalDisabled: bool,
    newCurrentPage: number,
    getIssue: func,
    disabledMultiEdit: bool,
    hiddenElements: array,
    multiListIssues: array,
    setMultiListIssues: func,
    listIssuesRelated: array
  };

import {ProjectTypeCommonConstants} from "../constants/Constants";

const initialState = {
  fetching: false,
  listOfDocs: [],
  error: '',
  fetchingDocCopy: false,
  singleDocCopy: [],
  errorDocCopy: []
};


export default (state=initialState, action) => {
  const {type, payload } = action;
  switch (type) {
    case ProjectTypeCommonConstants.CLEAR_HISTORY_OF_DOCUMENT:
      return initialState;
    case ProjectTypeCommonConstants.FETCH_HISTORY_OF_DOCUMENT_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case ProjectTypeCommonConstants.FETCH_HISTORY_OF_DOCUMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        listOfDocs:payload
      };
    case ProjectTypeCommonConstants.FETCH_HISTORY_OF_DOCUMENT_FAILURE:
      return {
        ...state,
        fetching: false,
        listOfDocs: [],
        error: payload
      };

    case ProjectTypeCommonConstants.GET_DOCUMENT_COPY_REQUEST:
      return {
        ...state,
        fetchingDocCopy: true
      };
    case ProjectTypeCommonConstants.GET_DOCUMENT_COPY_SUCCESS:
      return {
        ...state,
        singleDocCopy: payload
      };
    case ProjectTypeCommonConstants.GET_DOCUMENT_COPY_FAILURE:
      return {
        ...state,
        fetchingDocCopy: false,
        singleDocCopy: [],
        errorDocCopy: payload
      };

    case ProjectTypeCommonConstants.CLEAR_OF_DOC_COPY:
      return {
        ...state,
        fetchingDocCopy: false,
        singleDocCopy: [],
        errorDocCopy: []
      };

    //
    // case ProjectTypeCommonConstants.GET_DOCUMENT_REQUEST:
    //   return {
    //     ...state,
    //     fetchingDoc: true,
    //   };
    // case ProjectTypeCommonConstants.GET_DOCUMENT_SUCCESS:
    //   return {
    //     ...state,
    //     singleDoc: payload
    //   };
    // case ProjectTypeCommonConstants.GET_DOCUMENT_FAILURE:
    //   return {
    //     ...state,
    //     fetchingDoc: false,
    //     singleDoc: [],
    //     errorDoc: payload
    //   };

    default:  return state;
  }
};

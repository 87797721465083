import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { keys } from 'lodash';
import dayjs from 'dayjs';
import { getActorsW54 } from '../selectors/actorsW54Selector';
import useActorsW54Api from '../actions/useActorsW54Api';

let interval;

const CHUNK_SIZE = 100;

export default function useUpdateReduxActorsW54() {
  const dispatch = useDispatch();

  const actorsW54 = useSelector(getActorsW54);

  const { getActorsW54ByUUID } = useActorsW54Api();

  const getUsersW54 = async (usersUUIDs = []) => {
    if (!usersUUIDs?.length) return;

    const chunks = [];
    for (let i = 0; i < usersUUIDs.length; i += CHUNK_SIZE) {
      chunks.push(usersUUIDs.slice(i, i + CHUNK_SIZE));
    }

    for (const chunk of chunks) {
      await getActorsW54ByUUID({
        uuids: chunk,
        not_banned: true,
      });
    }
  };

  const checkActors = () => {
    if (!actorsW54) return;
    const needUpdateActors = keys(actorsW54)?.filter((uuid) => {
      if (dayjs().diff(actorsW54?.[uuid]?.time, 'minute') >= 30 && !actorsW54?.[uuid]?.time?.loading) {
        return true;
      }
      return false;
    });
    getUsersW54(needUpdateActors);
  };

  useEffect(() => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(checkActors, 30 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
}

import {
  Col, Flex, Input, Modal, Switch,
} from 'antd';
import { capitalize, get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectAssignedUsers from '../../../../../entity/components/SelectAssignedUsers';
import { filterUuidsAndGetParamsUsers } from '../../../../../MainUtils';
import InfoBlock from '../../../../containers/info/InfoBlock';
import InfoSection from '../../../../containers/info/InfoSection';
import DateLeftEdit from '../../../../../components/DateLeftEdit/DateLeftEdit';
import { getExpTypeAndValue } from '../utils';
import WrapperAvatarsGroupForProjectWithHook from '../../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { getUuid } from '../../../../../assignUsersModal/utils';
import AssigningUsersRoot from '../../../../../assignUsersModal/AssigningUsersRoot';
import EditButton from '../../../commonComponents/EditButton';
import useActorsW54ModalStyle from '../../../../../actors/hooks/useActorsW54ModalStyle';

const { TextArea } = Input;

export default function AssignedUsersFromStatusModal({
  projectUuid,
  partitionType,
  creatorIssue,
  statusForAssigned,
  vectorData,
  setModalAssigned,
  usersUuidsForAssigned,
  descriptionComment = [],
  usersSearch,
  users,
  projectUsers,
  getListUsersFromAssignedUsersCallback,
  isAssignedVector,
  onChangeFieldCallback,
}) {
  const { t } = useTranslation();

  const defaultExpirationData = moment().add(2, 'week').format('YYYY-MM-DD');

  const [needSendNotification, setNeedSendNotification] = useState(true);
  const [customComment, setCustomComment] = useState('');
  const [expirationData, setExpirationData] = useState(defaultExpirationData);
  const [selectedUsersOption, setSelectedUsersOption] = useState({
    users: !isEmpty(users) ? users : [{ uuid: creatorIssue }],
    usersSearch: !isEmpty(usersSearch) ? usersSearch : [creatorIssue],
    projectUsers,
  });

  const [isEditAssignUsers, setEditAssignUsers, modalStyle] = useActorsW54ModalStyle();

  const onChangeValue = (e) => setCustomComment(e.target.value);

  const handleCancelModal = () => {
    setNeedSendNotification(false);
    setModalAssigned('');
  };

  const withAssigned = (status) => {
    switch (status) {
      case 'review':
      case 'test':
      case 'test failed':
      case 'not reproducible':
      case 'rejected':
      case 'ready for deploy':
      case 'accepted': return true;
      default: return false;
    }
  };

  const saveDataFromModal = () => {
    const data = {
      status: statusForAssigned,
      lifeTime: moment(expirationData).format('YYYY-MM-DD'),
    };

    const { type, days } = getExpTypeAndValue(statusForAssigned);
    if (type) {
      data[type] = moment().add(days, 'days').format('YYYY-MM-DD');
    }
    if (withAssigned(statusForAssigned)) {
      data.needSendNotification = needSendNotification;

      if (selectedUsersOption.users.length !== 0) {
        data.usersSearch = get(selectedUsersOption, 'usersSearch', []);
        data.users = get(selectedUsersOption, 'users', []);
      }
    }

    if (customComment) {
      data.descriptionComment = [...descriptionComment, { name: 'status', description: customComment }];
    }

    onChangeFieldCallback(data);

    setModalAssigned(false);
  };

  const selectedUsersCallback = (value) => setSelectedUsersOption({
    users: value?.users?.map((el) => ({ uuid: el?.uuid })),
    usersSearch: value?.users?.map((el) => el?.uuid),
    projectUsers: value?.allUsers,
  });

  const onChangeTimeCallback = ({ lifeTime }) => {
    setExpirationData(lifeTime);
  };

  return (
    <Modal
      open={statusForAssigned}
      style={modalStyle}
      className="custom-ant-modal"
      centered
      cancelText={capitalize(t('wms.verb.cancel', 'cancel'))}
      okText={capitalize(t('wms.verb.save', 'save'))}
      okButtonProps={{ disabled: selectedUsersOption?.users?.length === 0 }}
      onOk={saveDataFromModal}
      // footer={null}
      onCancel={handleCancelModal}
    >
      <Col span={24}>
        <div className="text-neutral-600 text-base">
          {t('wms.labels.change_of_status', 'Do you want to change your status, are you sure?')}
          <hr className="my-2" />
        </div>

        {/* <InfoBlock
          hideIcon
          // className="p-0 ml-1 my-5"
          title={`${capitalize(t('wms.labels.task_expires'))}:`}
          titleAndIconClassName="whitespace-nowrap items-center"
          childrenClassName="items-center justify-start"
        > */}
        {/* <div className="flex mb-3 text-secondary items-center">
          <span className="mr-2">
            {capitalize(t('wms.labels.task_expires'))}
            :
          </span>
          <DateLeftEdit
            expirationDate={expirationData}
            onChangeFieldCallback={onChangeTimeCallback}
          />
        </div> */}
        {/* </InfoBlock> */}

        {withAssigned(statusForAssigned) && (
          <>
            <Flex className="w-full" align="center">
              <AssigningUsersRoot
                header="Assigning users"
                isOpen={isEditAssignUsers}
                setIsOpen={setEditAssignUsers}
                onUpdateUsers={selectedUsersCallback}
                globalOptions={{
                  uuids: selectedUsersOption?.projectUsers?.map((el) => getUuid(el)) ?? [],
                  defaultColumn: 'project',
                }}
                needUpdateProjectUsers
                placement="left"
                isPopover
                tabs={{
                  allConfig: {
                    label: 'All',
                    value: 'all',
                    textSelectButton: 'Add to project/issue',
                    showSections: ['button-add-user'],
                  },
                  projectConfig: {
                    label: 'Project',
                    value: 'project',
                    textSelectButton: 'Add to issue',
                    defaultItems: selectedUsersOption?.projectUsers ?? [],
                    showSections: ['string-role', 'button-add-user'],
                  },
                  vectorConfig: {
                    label: 'Vector',
                    value: 'vector',
                    textSelectButton: 'Add to issue',
                    defaultItems: vectorData?.params?.usersSearch ?? [],
                    showSections: ['string-role', 'button-add-user'],
                  },
                }}
                usersConfig={{
                  label: 'Issue users',
                  showSections: ['string-role', 'button-remove-user'],
                  textDeleteButton: 'Remove users',
                  defaultItems: get(selectedUsersOption, 'usersSearch', []),
                }}
                popoverText={(<span className="text-secondary">Assigned users:</span>)}
                projectUuid={projectUuid}
                partition={partitionType}
              />
              <WrapperAvatarsGroupForProjectWithHook
                actors={get(selectedUsersOption, 'usersSearch', [])}
                avatarSize={28}
                popoverTrigger="click"
                popoverPlacement="right"
                checkProjectRole
              />
            </Flex>

            <InfoSection
              hideIcon
              title={`${capitalize(
                t('wms.labels.send_notification', 'send notification'),
              )}:`}
            >
              <Switch
                onChange={() => setNeedSendNotification(!needSendNotification)}
                checked={needSendNotification}
                key="switch-primary"
                className="switch-primary ml-3"
                checkedChildren="ON"
                unCheckedChildren="OFF"
                size="small"
              />
            </InfoSection>
          </>
        )}

        {(
          statusForAssigned === 'rejected'
          || statusForAssigned === 'accepted'
          || statusForAssigned === 'acquainted') && (
            <>
              <h7 className="text-secondary">{`${capitalize(t('wms.labels.you_can_add_a_comment'))}:`}</h7>
              <TextArea
                rows={4}
                value={customComment}
                onChange={onChangeValue}
                placeholder={capitalize(t('wms.labels.enter_description'))}
              />
            </>
          )}
      </Col>
    </Modal>
  );
}

import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getRootEntityPartition } from '../../../../../config/selectors/selectors';
import { getListAndPartialReadEntities } from '../../../../../entity/actions/entityActions';
import { capitalize } from 'lodash';
import BtnModal from '../../../BtnModalComponent';
import RelatedProjectsTable from './RelatedProjectsTable';
import EditButton from '../../../commonComponents/EditButton';
import { partitionNamesConfig } from '../../../../../api/appConfig';

export default function RelatedProjects({
  isEdit,
  relatedProjects = [],
  partitionType,
  currentProject,
  customParent,
  updateProjectWithCustomsParams,
  disabled,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const partitionUuid = useSelector((state) => getRootEntityPartition(state, partitionType));

  const splitPath = location.pathname.split('/');
  const userUUIDFromUrl = splitPath[splitPath.indexOf('user') + 1];

  const [listRelated, setListRelated] = useState([]);

  const getListProjects = (entities) => {
    const config = {
      data: {
        entities__not: [currentProject],
        entity_type: 'project',
        params: {},
        parent: customParent || partitionUuid,
        params_fields: {
          id: 'id',
          title: 'title',
          status: 'status',
        },
      },
      partition: partitionType,
      constants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    };

    if (entities) {
      config.data.entities = relatedProjects;
    }

    return dispatch(getListAndPartialReadEntities(config));
  };

  const getRelatedList = async () => {
    if (!isEmpty(relatedProjects)) {
      const res = await getListProjects(relatedProjects);
      setListRelated(get(res, 'data', []));
    } else if (!isEmpty(listRelated)) { setListRelated([]); }
  };

  useEffect(() => {
    getRelatedList();
  }, [relatedProjects.length]);
  return (
    <div className="flex  flex-col items-start w-full px-1">
      <div className="wrapper-title pb-0 flex items-center">
        <BtnModal
          width="50%"
          title={capitalize(t('wms.labels.related_projects'))}
          btnComponent={(<EditButton id='setRelatedProjectsButton' />)}
        >
          <RelatedProjectsTable
            defaultRelatedProjects={relatedProjects}
            updateProjectWithCustomsParams={updateProjectWithCustomsParams}
            getListProjects={getListProjects}
          />
        </BtnModal>
        {`${capitalize(t('wms.labels.related_projects'))}:`}
      </div>
      <div className="grow wrapper-description flex flex-col w-full px-1 mt-1">
        {listRelated.map((item, index) => (
          <div key={get(item, 'uuid')} className="flex items-center">
            <span className="text-secondary">
              {index + 1}
              .
            </span>
            <a
              href={partitionType === partitionNamesConfig.partition1
                ? `/pm/projects/issuesnew?activeProject=${get(item, 'uuid')}`
                : `/public/user/${userUUIDFromUrl}/projects/issuesnew?activeProject=${get(item, 'uuid')}`}
              target="_blank"
              className="ml-1 textEllepsis"
              rel="noreferrer"
            >
              {get(item, 'title')}
            </a>
          </div>
        ))}
      </div>
    </div>

  );
}

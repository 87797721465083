import { getListAndPartialReadAffix } from '../../../affix/affixActions';
import { partitionNamesConfig } from '../../../api/appConfig';
import { getListAndPartialReadEntities } from '../../../entity/actions/entityActions';
import {
  GET_PARTIAL_PERIOD_START_WORK_REPORTS_FAILURE,
  GET_PARTIAL_PERIOD_START_WORK_REPORTS_REQUEST,
  GET_PARTIAL_PERIOD_START_WORK_REPORTS_SUCCESS,
  GET_PERIOD_DETAILED_RECORDS_FAILURE,
  GET_PERIOD_DETAILED_RECORDS_REQUEST,
  GET_PERIOD_DETAILED_RECORDS_SUCCESS,
  GET_PERIOD_START_WORK_RECORDS_FAILURE,
  GET_PERIOD_START_WORK_RECORDS_REQUEST,
  GET_PERIOD_START_WORK_RECORDS_SUCCESS,
} from '../reducers/slices/periodReportsSlice';

export const getDailyForThePeriod = (param, constants) => {
  const config = {
    data: {
      entity_type: 'daily',
      depth: 0,
      params_fields: {
        date: 'date',
        startWork: 'startWork',
        finishWork: 'finishWork',
        lunchBreak: 'lunchBreak',
        localeFinishWork: 'localeFinishWork',
        localeStartWork: 'localeStartWork',
        dailyPlanningText: 'dailyPlanningText',
        dontHaveEnoughTasks: 'dontHaveEnoughTasks',
        haveWorkQuestions: 'haveWorkQuestions',
        kindOfDay: 'kindOfDay',
        hoursWorked: 'hoursWorked',
        weekendHoursWorked: 'weekendHoursWorked',
        feelSickGoingHome: 'feelSickGoingHome',
      },
      fields: { actor: 'actor', entity_type: 'entity_type', owner: 'owner' },
      ...param,
    },
    partition: partitionNamesConfig.partition3,
    constants: constants || [
      GET_PERIOD_START_WORK_RECORDS_REQUEST,
      GET_PERIOD_START_WORK_RECORDS_SUCCESS,
      GET_PERIOD_START_WORK_RECORDS_FAILURE,
    ],
  };
  return getListAndPartialReadEntities(config);
};

export const getDetailedRecordForThePeriod = (conf, constants) => {
  const config = {
    data: {
      affix_type: 'timeLog',
      depth: 0,
      params_fields: {
        date: 'date',
        title: 'title',
        description: 'description',
        trackerTime: 'trackerTime',
        type: 'type',
        typeForReport: 'typeForReport',
        uuid: 'parentUuid',
        // projectUUID: 'projectUUID',
        // entityUUID: 'entityUUID',
        // entityPartition: 'entityPartition',
        // affixLinkUUID: 'affixLinkUUID',
        // priority: 'priority',
        // status: 'status',
        // tracker: 'tracker',
      },
      ...conf,
    },
    partition: partitionNamesConfig.partition3,
    constants: constants || [
      GET_PERIOD_DETAILED_RECORDS_REQUEST,
      GET_PERIOD_DETAILED_RECORDS_SUCCESS,
      GET_PERIOD_DETAILED_RECORDS_FAILURE,
    ],
  };
  return getListAndPartialReadAffix(config);
};

export const getProjectReportForThePeriod = (conf, partition) => {
  const config = {
    data: {
      affix_type: 'timeLog',
      depth: 0,
      ...conf,
      params_fields: {
        date: 'date',
        title: 'title',
        description: 'description',
        trackerTime: 'trackerTime',
        type: 'type',
        uuid: 'parentUuid',
      },
      fields: {
        'affix.created': 'created',
        'actor.uinfo': 'uinfo',
      },
    },
    partition,
  };
  return getListAndPartialReadAffix(config);
};

export const getPartialStartWorkPeriodReportsActionCreator = (data) => {
  const constants = [
    GET_PARTIAL_PERIOD_START_WORK_REPORTS_REQUEST,
    GET_PARTIAL_PERIOD_START_WORK_REPORTS_SUCCESS,
    GET_PARTIAL_PERIOD_START_WORK_REPORTS_FAILURE,
  ];

  const config = {
    data,
    partition: partitionNamesConfig.partition3,
    constants,
  };
  return getListAndPartialReadEntities(config);
};

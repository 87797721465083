import { Descriptions, Spin } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import TagsGroupNames54origins from '../../../54origins/components/TagsGroupNames54origins';

import { getUsersByUUID } from '../../../userFlow/store/action-creators/userActions';
import { getNameForActor } from '../../../54origins/utils54origins';
import { dateWithTooltipTime } from '../../../MainUtils';
import useAuthActorsRequests from '../../hooks/useAuthActorsRequests';
import useAuthSessionHook from '../../hooks/useAuthSessionHook';
import useURLParams from '../../../hooks/useURLParams';
import { getSessionTokenFor } from '../../../api/appConfig';

function UserInfoForAdminDashboard() {
  const { getActorUUIDParam } = useURLParams();
  const actor = getActorUUIDParam;

  const dispatch = useDispatch();

  const authToken = getSessionTokenFor.auth();

  const { checkRequestError } = useAuthSessionHook();
  const { requestGetListOfActorsOnAuth } = useAuthActorsRequests();

  const [user, setUser] = useState({});
  const [groups, setGroups] = useState([]);
  const [fetching, setFetching] = useState(false);

  const {
    actor_type: actorType,
    created,
    uinfo: {
      birthday,
      phone_number,
      internal_user,
      email,
      login,
    } = {},
    uuid,
  } = user || {};

  const getActorInfoFromEntity = async () => {
    const actorData = await dispatch(getUsersByUUID({ uuid: actor }));
    const actorGroups = await dispatch(getUsersByUUID({ uuid: actorData[0]?.uinfo?.groups }));

    setUser(actorData[0]);
    setGroups(actorGroups);
  };

  const getActorInfoFromAuth = async () => {
    let actorData = await requestGetListOfActorsOnAuth(
      { uuid: actor },
      [
        'GET_ACTOR_FROM_AUTH_REQUEST',
        'GET_ACTOR_FROM_AUTH_SUCCESS',
        'GET_ACTOR_FROM_AUTH_FAILURE',
      ],
    ).catch((err) => {
      checkRequestError(err, getActorInfoFromEntity);
    });

    actorData = actorData?.actors[0];

    const actorGroups = await dispatch(getUsersByUUID({ uuid: actorData?.uinfo?.groups }, []));

    setUser(actorData);
    setGroups(actorGroups);
  };

  const initFunc = () => {
    setFetching(true);

    if (actor && !authToken) {
      getActorInfoFromEntity().finally(() => {
        setFetching(false);
      });
    }

    if (actor && authToken) {
      getActorInfoFromAuth().finally(() => {
        setFetching(false);
      });
    }
  };

  useEffect(() => {
    initFunc();
  }, [actor]);

  return (
    <Spin tip="Loading..." spinning={fetching}>
      <Descriptions title="User Info" bordered>
        <Descriptions.Item span={24} label="Actor type">{actorType}</Descriptions.Item>
        <Descriptions.Item span={24} label="Name">{getNameForActor(user)}</Descriptions.Item>
        <Descriptions.Item span={24} label="Login">{login}</Descriptions.Item>
        <Descriptions.Item span={24} label="Email">{email}</Descriptions.Item>
        <Descriptions.Item span={24} label="UUID">{uuid}</Descriptions.Item>
        <Descriptions.Item span={24} label="Created">
          {dateWithTooltipTime(created)}
        </Descriptions.Item>
        {authToken && (
        <>
          <Descriptions.Item span={24} label="Birthday">{birthday}</Descriptions.Item>
          <Descriptions.Item span={24} label="Phone">{phone_number}</Descriptions.Item>
        </>
        )}
        <Descriptions.Item span={24} label="Groups">
          <TagsGroupNames54origins groups={groups} />
        </Descriptions.Item>
      </Descriptions>
    </Spin>
  );
}

export default memo(UserInfoForAdminDashboard);

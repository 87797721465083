import { useDispatch } from 'react-redux';

import moment from 'moment';
import { getListOfVectors, updateProjectScoring } from '../../projectFlow/actions/projectFlowActions';
import { projectEntityScoring, notActiveStatusesArr } from '../../projectFlow/components/issuesView/utils';
import { ProjectTypeCommonConstants } from '../../projectFlow/constants/Constants';

export default function useDevVectorsScore() {
  const dispatch = useDispatch();

  const devTitle = 'Dev';

  const defaultDataObj = {
    sum: 0, avgVal: 0, total: 0, background: 'default',
  };

  const updateProject = async (props) => {
    const {
      projectUuid, projectPartition, vectorsScoringAVG, indexesSum, totalCount,
    } = props;
    const data = {
      uuid: projectUuid,
      params: {
        vectorsDevScore: {
          updated: moment(),
          vectorsScoringAVG: Number(vectorsScoringAVG),
          indexesSum,
          totalCount,
        },
      },
      partition: projectPartition,
    };
    return dispatch(updateProjectScoring(data));
  };

  const getAllProjectsVectors = async (projectUuid, projectPartition) => {
    // console.log('defaultPartition', defaultPartition);
    const config = {
      partition: projectPartition,
      parent: projectUuid,
      depth: 2,
      params: {
        status__not: notActiveStatusesArr,

      },
      constants: [
        ProjectTypeCommonConstants.GET_ALL_PROJECT_VECTORS_REQUEST,
        ProjectTypeCommonConstants.GET_ALL_PROJECT_VECTORS_SUCCESS,
        ProjectTypeCommonConstants.GET_ALL_PROJECT_VECTORS_FAILURE,
      ],
      params_fields: {
        title: 'title',
        priority: 'priority',
        status: 'status',
      },
    };
    return dispatch(getListOfVectors(config));
  };

  const computeProjectVectorsDevScore = async (
    projectUuid,
    projectPartition,
    isNeedProject,
    isAllScoring,
  ) => {
    try {
      const uuid = projectUuid;
      const vectorsData = await getAllProjectsVectors(uuid, projectPartition);
      const check = Array.isArray(vectorsData?.data) && !!vectorsData?.total;
      const vectorsScoring = check ? projectEntityScoring(vectorsData.data, vectorsData.total) : defaultDataObj;
      // console.log('vectorsScoring', vectorsScoring);
      const finalData = {
        update: moment(),
        indexesSum: vectorsScoring?.sum,
        vectorsScoringAVG: Number(vectorsScoring?.avgVal),
        totalCount: vectorsScoring?.total,
      };

      if (!isAllScoring) {
        const res = await updateProject({
          ...finalData,
          projectUuid,
          projectPartition,
        });

        if (isNeedProject) {
          return res;
        }
      }

      return { uuid, ...finalData };
      // return {
      //   uuid,
      //   scoringData: {
      //     issuesScoringAVG: 0,
      //     background: 'default',
      //   },
      // };
    } catch (e) {
      console.log('onComputeKPI error', e);
    }
  };

  // const computeAllProjectsVectorsDevScore = async (projectsArr) => {
  //   try {
  //     dispatch(setFetchingScoringData(true));
  //     const projectsDevScoreData = await Promise.all(projectsArr.map(async (project) => await computeProjectVectorsDevScore(project?.uuid, project?.partition)));

  //     const finalProjectsData = projectsArr.map((item) => {
  //       const projectScoring = projectsDevScoreData.find((project) => project?.uuid === item?.uuid)?.scoringData || {
  //         vectorsScoringAVG: 0,
  //         background: 'default',
  //       };
  //       return {
  //         ...item, scoringData: projectScoring,
  //       };
  //     });
  //     dispatch(setProjectsIssuesScoringData(finalProjectsData));
  //     return finalProjectsData;
  //     // console.log('onComputeKPI projectsWithIssueScoringData', projectsWithIssueScoringData);
  //   } catch (e) {
  //   } finally {
  //     dispatch(setFetchingScoringData(false));
  //   }
  //   // console.log('onComputeKPI allProjectsCopy', allProjectsCopy);
  // };

  const handleDevBackground = (score, isButtonColor) => {
    if (score >= 3) {
      return !isButtonColor ? 'error' : 'scoringBtnDanger';
    }
    if (score >= 2) {
      return !isButtonColor ? 'warning' : 'scoringBtnWarning';
    }
    if (score >= 1) {
      return !isButtonColor ? 'success' : 'scoringBtnGreen';
    }
    if (score >= 0 && score < 1) {
      return !isButtonColor ? 'error' : 'scoringBtnDanger';
    }
    return !isButtonColor ? '' : 'scoringBtnDefault';
  };

  const devTooltipText = (
    <>
      <div>
        <strong>
          Dev score (vectors)
        </strong>
        {' '}
        - general scoring by project vectors, which showing the status of the development process and the priority of active vectors.
      </div>
      <div>
        <strong>Threshold values: </strong>
        it&apos;s recommended to pay attention if the value less than 1 or more than 2.
      </div>

    </>

  );

  return {
    devTitle, devTooltipText, computeProjectVectorsDevScore, handleDevBackground,
  };
}

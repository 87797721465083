import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const initialState = {
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
};

const dashboardTimelineSlice = createSlice({
  name: 'dashboardTimeline',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
  },
});

export const useDashboardTimelineActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(dashboardTimelineSlice.actions, dispatch);
};

export default dashboardTimelineSlice.reducer;

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import { head, pathOr } from 'ramda';
import { get, isEmpty, isNil } from 'lodash';

import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';

import {
  Row,
  Col,
  Card,
  Button,
  Tooltip,
  Modal, Flex,
} from 'antd';

import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import ExpandModeLayout from '../../commonComponents/expandModeLayout/ExpandModeLayout';
import GoToButton from '../../../../components/buttons/GoToButton';
import IssueInfoRoot from '../../issues/issueInfo/IssueInfoRoot';
import ProjectGoalView from '../../goals/ProjectGoalView';
import DocViewWrapper from '../../../containers/docView&ideas/DocViewWrapper';
import WrapperVectorInfoCardWithComments from '../../vector/vectorInfo/WrapperVectorInfoCardWithComments';

import useURLParams from '../../../../hooks/useURLParams';
import useEntityListSort from '../../../../hooks/useEntityListSort';

import { getLocale } from '../../../../locale/selectors';
import { getProjDocOrIdeas, getProjectUUID } from '../../../selectors/selectors';
import { getUserPublicProfileUUID } from '../../../../userFlow/store/selectors/selectors';
import {
  getAllAffixAuditData,
  getAllAffixAuditLoading,
  getAllEntitiesAuditData,
  getAllEntitiesAuditLoading,
} from '../../../selectors/auditSelectors';
import { getCurrentArchiveStateUUID } from '../../../selectors/archiveStateSelectors';

import { getListOfAllEntitiesByTypes, getListOfAllAffixByTypes } from '../../../actions/auditActions';
import { clearIdea, clearVector, clearIssue } from '../../../actions/PtcActionsForHook';
import { ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import { getProjectGoalRequest } from '../../../actions/projectGoalsActions';

import { capitalizeAndTranslateMsg, dateWithTooltipTime } from '../../../../MainUtils';

import { capitalize } from 'lodash';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { PROJECT_TABS } from './projectTabs';
import { getProjectMilestoneRequest } from '../../../actions/projectMilestonesActions';
import ProjectMilestoneView from '../../milestones/ProjectMilestoneView';
import BaseCard from "../../../../components/_ui/BaseCard/BaseCard";
import { partitionNamesConfig } from '../../../../api/appConfig';

const entitiesLimit = 5;

const parentTypeComment = {
  activeNote: 'idea',
  activeSheet: 'spreadsheet',
  activeVector: 'vector',
  activeDoc: 'document',
  activeIssue: 'issue',
  project: 'project',
  goal: 'goal',
  milestone: 'projectMilestone',
};

function ProjectRecentActivity({
  partitionType,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const locale = useSelector(getLocale);
  // const dateFormat = locale === 'en' ? 'MM-DD-YY' : 'DD-MM-YY';
  const dateFormat = 'DD-MM-YY';

  const {
    routeNavigateAndClearParams,
    routeNavigateTo,
    addAndRemoveSearchParams,
    getURLParams,
    clearSearchParams,
  } = useURLParams();

  const {
    activeVector,
    activeIssue,
    activeDoc,
    activeNote,
    activeSheet,
    goal,
    milestone,
  } = getURLParams();

  const {
    getClearVector,
    switchViewFunc,
    getClearIssue,
    getDocument,
    initialPathForHistory,
  } = useContext(ProjectMainLayerContext);

  const projectUUID = useSelector(getProjectUUID);
  const archiveStateUUID = useSelector(getCurrentArchiveStateUUID);
  const parentUUID = archiveStateUUID || projectUUID;

  const myPublicUUID = useSelector(getUserPublicProfileUUID);

  const allEntitiesData = useSelector(getAllEntitiesAuditData);
  const allEntitiesLoading = useSelector(getAllEntitiesAuditLoading);
  const projDocOrIdeas = useSelector(getProjDocOrIdeas);

  const initialEntitiesConfig = useMemo(() => ({
    entity_type: [
      'issue',
      'vector',
      'projectGoal',
      'projectDocument',
      'projectIdea',
      'projectSpreadsheet',
      'projectMilestone',
      'testSuite',
      'testCycle',
    ],
    parent: parentUUID,
    depth: 0,
    params: {},
    offset: 0,
    limit: entitiesLimit,
    order_by: 'created',
    order: 'desc',
  }), [parentUUID, entitiesLimit]);

  const [currentEntitiesConfig, setCurrentEntitiesConfig] = useState(initialEntitiesConfig);
  const [sortSettings, setSortSettings] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [projectState, setProjectState] = useState({});
  const { currentView, selectedEntity } = projectState;

  const pathToRedirect = {
    pathname: partitionType === partitionNamesConfig.partition3
      ? `/public/user/${myPublicUUID}/projects/project`
      : '/pm/projects/project',
    search: `?activeProject=${parentUUID}&tab=${PROJECT_TABS.AUDIT}`,
  };

  const changeGeneralState = (params) => setProjectState((prev) => ({
    ...prev,
    ...params,
  }));

  const deleteEntityCallback = async (type) => {
    changeGeneralState({
      currentView: 'project',
    });
    routeNavigateTo(`${initialPathForHistory}audit`, {
      activeAuditSectionTab: `views.${type}`,
    });
    dispatch(clearVector);
    dispatch(clearIdea);
  };

  const resetEntity = (type) => {
    dispatch(clearVector);
    dispatch(clearIdea);
  };

  const resetFunc = () => {
    setProjectState({
      currentView: '',
      selectedEntity: '',
    });
    clearSearchParams([
      'activeIssue', 'activeVector', 'goal', 'activeNote', 'activeDoc', 'activeSheet', 'milestone',
    ]);
    resetEntity('activity');
  };

  const entityString = {
    project: 'project',
    issue: 'activeIssue',
    vector: 'activeVector',
    projectGoal: 'goal',
    projectMilestone: 'milestone',
    projectIdea: 'activeNote',
    projectDocument: 'activeDoc',
    projectSpreadsheet: 'activeSheet',
  };

  const selectEntity = (row) => {
    const { entity_type, uuid } = row;

    addAndRemoveSearchParams(
      { [entityString[entity_type]]: uuid },
      [
        'activeIssue',
        'activeVector',
        'activeDoc',
        'activeNote',
        'activeSheet',
        'goal',
        'milestone',
      ],
    );
  };

  const quickViewFunc = (e, row) => {
    e.stopPropagation();
    const { tagName } = e.target;

    const {
      entity_type,
    } = row;

    if (entity_type !== 'testCycle' && entity_type !== 'testSuite') {
      selectEntity(row);
    }

    // setCurrentView(row?.entity_type);
  };

  const columns = [
    // {
    //   width: 50,
    // },
    {
      dataIndex: 'uuid',
      title: '',
      className: 'w-fullpx',
      render: (cell, row) => {
        if (row?.entity_type === 'testCycle' || row?.entity_type === 'testSuite') return '';
        return (
          <Button
          id='quikViewButton'
            className="btnPrimary-outline"
            size="small"
            // disabled={get(row, 'removed')}
            onClick={(e) => quickViewFunc(e, row)}
          >
            <Tooltip
              placement="left"
              title={capitalizeAndTranslateMsg('wms.buttons.quick_view', 'Q-view')}
            >
              {capitalizeAndTranslateMsg('wms.buttons.quick_v', 'Q-view')}
            </Tooltip>
            <Icon path={mdiChevronRight} size={0.75} className="ml-1" />
          </Button>
        );
      },
    },
    {
      dataIndex: 'title',
      title: capitalize(t('wms.table.headers.title', 'title')),
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
          maxLength={30}
        />
      ),
    },
    {
      dataIndex: 'entity_type',
      title: capitalize(t('wms.table.headers.type', 'type')),
      sorter: (a, b, c) => setSortSettings({ sortValue: c, sortType: 'entity_type' }),
      render: (cell) => cell && (
        <EntityParamTag
          value={cell}
          type="badge"
          param="entity"
        />
      ),
    },
    {
      dataIndex: 'status',
      title: capitalize(t('wms.table.headers.status', 'status')),
      render: (cell) => cell && (
        <EntityParamTag
          param="status"
          value={cell}
          type="icon"
        />
      ),
    },
    {
      dataIndex: 'created',
      title: capitalize(t('wms.table.headers.created', 'created')),
      sorter: (a, b, c) => setSortSettings({ sortValue: c, sortType: 'created' }),
      render: (cell) => dateWithTooltipTime(cell, dateFormat),
    },
  ].filter((el) => !el.hidden);

  const rowClassName = (row) => {
    const data = `universal_table_row ${get(row, 'uuid') === selectedEntity
      ? 'selected'
      : ''
      }`;

    return data;
  };

  const selectRow = {
    mode: 'radio',
    clickToSelect: false,
    hideSelectColumn: true,
    selected: [selectedEntity],
    style: { background: '#e5f4fc' },
  };

  const getURLForEntity = (uuid, entityType) => {
    const searchParams = {
      activeProject: parentUUID,
    };
    const initialPathForHistory = partitionType === partitionNamesConfig.partition1 ? '/pm/projects/' : `/public/user/${myPublicUUID}/projects/`;
    switch (entityType) {
      case 'issue':
        return { pathname: `${initialPathForHistory}issuesnew`, searchParams: { ...searchParams, activeIssue: uuid } };
      case 'vector':
        return { pathname: `${initialPathForHistory}issuesnew`, searchParams: { ...searchParams, activeVector: uuid } };
      case 'projectDocument':
        return { pathname: `${initialPathForHistory}docs`, searchParams: { ...searchParams, activeDoc: uuid } };
      case 'projectIdea':
        return { pathname: `${initialPathForHistory}docs`, searchParams: { ...searchParams, activeNote: uuid } };
      case 'projectSpreadsheet':
        return { pathname: `${initialPathForHistory}docs`, searchParams: { ...searchParams, activeSheet: uuid } };
      case 'projectGoal':
        return { pathname: `${initialPathForHistory}planning`, searchParams: { ...searchParams, goal: uuid } };
      case 'projectMilestone':
        return { pathname: `${initialPathForHistory}planning`, searchParams: { ...searchParams, milestone: uuid } };
      case 'testCycle':
        return { pathname: `${initialPathForHistory}qa`, searchParams: { ...searchParams, activeTab: "configure", activeCycle: uuid } };
      case 'testSuite':
        return { pathname: `${initialPathForHistory}qa`, searchParams: { ...searchParams, activeTab: "configure", activeSuite: uuid } };
      default:
        return { pathname: '', searchParams: null };
    }
  };

  const onRow = (record) => ({
    onClick: () => {
      const {
        pathname, searchParams,
      } = getURLForEntity(record?.uuid, record?.entity_type);
      if (pathname && searchParams) {
        routeNavigateAndClearParams(pathname, searchParams);
      }
    },
    onMouseDown: (e) => {
      if (e.button === 1) {
        const {
          pathname, searchParams,
        } = getURLForEntity(record?.uuid, record?.entity_type);
        if (pathname && searchParams) {
          window.open(`${pathname}?${Object.entries(searchParams).map(([key, value]) => `${key}=${value}`).join('&')}`);
        }
      }
    },
  });

  const getListOfAllEntities = async (config = {}) => {
    if (!config.parent || !config.entity_type) return;
    const data = await dispatch(getListOfAllEntitiesByTypes(config, partitionType));
    // console.log('DATA ENTITIES:', data);

    // const total = get(data, 'total');
    // setTotalCount(total);
  };

  const getListOfAllAffix = async (config) => {
    if (!config.entity_uuid || !config.affix_type) return;

    const data = await dispatch(getListOfAllAffixByTypes(config, partitionType));
    // console.log('DATA AFFIXES:', data);

    // const total = get(data, 'total');
    // setTotalCount(total);
  };

  const updateListOfIssues = async () => {
    await getListOfAllEntities();
  };

  const defaultProjectState = async () => {
    resetEntity('activity');
    await getListOfAllEntities();
  };

  const defaultVectorState = async () => {
    getClearVector(activeVector);
    await getListOfAllEntities();
  };

  const initFunc = async () => {
    if (!activeDoc && !activeNote && !activeSheet) setIsExpanded(false);
    if (activeDoc || activeNote || activeSheet) setShowEditor(false);

    let view = '';
    const uuid = activeVector
      || activeIssue
      || activeDoc
      || activeNote
      || activeSheet
      || goal
      || milestone;

    if (activeVector) {
      getClearVector(activeVector);
      view = 'activeVector';
    }

    if (activeIssue) {
      getClearIssue(activeIssue);
      view = 'activeIssue';
    }

    if (goal) {
      dispatch(getProjectGoalRequest({
        uuid: goal,
        partition: partitionType,
      }));
      view = 'goal';
    }

    if (activeNote) {
      getDocument(activeNote, 'projectIdea');
      view = 'activeNote';
    }
    if (activeDoc) {
      getDocument(activeDoc, 'projectDocument');
      view = 'activeDoc';
    }
    if (activeSheet) {
      getDocument(activeSheet, 'projectSpreadsheet');
      view = 'activeSheet';
    }

    if (milestone) {
      dispatch(getProjectMilestoneRequest({
        uuid: milestone,
        partition: partitionType,
      }));
      view = 'milestone';
    }

    changeGeneralState({
      currentView: view || '',
      selectedEntity: uuid || '',
    });
  };

  useEffect(() => {
    initFunc();
    return () => {
      dispatch(clearVector);
      dispatch(clearIdea);
      dispatch(clearIssue);
    };
  }, [
    archiveStateUUID,
    activeVector,
    activeIssue,
    activeDoc,
    activeNote,
    activeSheet,
    goal,
    milestone,
  ]);

  useEffect(() => {
    if (!isEmpty(currentEntitiesConfig) && !isNil(currentEntitiesConfig)) {
      getListOfAllEntities(currentEntitiesConfig);
    }
  }, [JSON.stringify(currentEntitiesConfig)]);

  useEffect(() => () => {
    dispatch({ type: ProjectTypeCommonConstants.CLEAR_LIST_AFFIX });
    dispatch({ type: ProjectTypeCommonConstants.CLEAR_LIST_ENTITIES });
  }, []);

  const sortedEntities = useEntityListSort(allEntitiesData, sortSettings);

  return (
    <>
      <BaseCard
        title={(
          <Flex
            gap="small"
            align="center"
            justify="space-between"
          >
            <h6 className="m-0">Project log:</h6>
            <GoToButton
              id="toAllActivitiesButton"
              pathForLabelTranslate="wms.buttons.go_to_all_activities"
              pathToRedirect={pathToRedirect}
            />
          </Flex>
        )}
        className="mb-3 borderR10 primaryCard"
      >
        <div className="collapsedTable-table">
          <BaseTableWithPagination
            pageLimit={entitiesLimit}
            // changePageLimit={(data) => changeState({ pageLimit: data })}
            // currentSortCallback={currentSortCallback}
            useCustomPagination
            columns={columns}
            data={sortedEntities}
            onRow={onRow}
            rowSelection={selectRow}
            loading={allEntitiesLoading}
            // loading={allEntitiesLoading || allAffixesLoading}
            total={entitiesLimit}
            pageSizeOptions={entitiesLimit}
            disablePagination
            // newCurrentPage={currentPage}
            defaultPageLimit={entitiesLimit}
            // getPaginationOptions={changeState}
            rowClassName={rowClassName}
            paginationSize="small"
            headerRowClassName="universal_header_table_row"
          />
        </div>
      </BaseCard>
      <Modal
        open={currentView}
        closable={false}
        destroyOnClose
        footer={null}
        onCancel={resetFunc}
        width={isExpanded ? 1200 : 685}
      >
        {currentView === 'activeVector' && (
          <WrapperVectorInfoCardWithComments
            defaultTemplateState={defaultVectorState}
            viewMode="vector"
            resetVectorCallback={resetFunc}
            disabledView={['priorityIssue', 'responsible', 'assigned_users', 'edit']}
            hiddenView={['close', 'goToBtn', 'edit', 'delete']}
            restoreActionCallback={defaultProjectState}
            deleteVectorCallback={() => deleteEntityCallback('activity')}
            partitionType={partitionType}
            parentType={parentTypeComment[currentView]}
          />
        )}

        {currentView === 'activeIssue' && (
          <IssueInfoRoot
            showCard={false}
            actorsTypeForSelect={['defaultUsers', 'projectActors']}
            // actorsForAssignedUsers={projectUsers}
            hiddenView={[
              'resetIssueBtn',
              'linkToProject',
              'goButton',
              'deleteIssueBtn',
              'editBtn',
              'edit',
            ]}
            disabledView={['typeIssue', 'statusCollapse', 'priorityIssue', 'assignedVector', 'uploadFiles']}
            deleteCallback={() => deleteEntityCallback('activity')}
            switchViewFunc={switchViewFunc}
            updateCallback={updateListOfIssues}
            restoreEntityCallback={defaultProjectState}
            partitionType={partitionType}
            resetIssueCallback={resetFunc}
          />
        )}

        {(currentView === 'activeNote' || currentView === 'activeDoc' || currentView === 'activeSheet') && (
          <ExpandModeLayout isDisabled>
            <DocViewWrapper
              showCard={false}
              defaultPartition={partitionType}
              currentSelected={projDocOrIdeas[0]}
              isExpanded={isExpanded}
              showEditor={showEditor}
              setIsExpanded={setIsExpanded}
              setShowEditor={setShowEditor}
            />
          </ExpandModeLayout>
        )}

        {currentView === 'goal' && (
          <ExpandModeLayout isDisabled>
            <ProjectGoalView
              showCard={false}
              backBtnCallback={resetFunc}
              defaultPartition={partitionType}
              hideElements={['options']}
              disabledElements={['title', 'scale', 'status', 'life_time', 'assign_users', 'textarea']}
            />
          </ExpandModeLayout>
        )}

        {currentView === 'milestone' && (
          <ExpandModeLayout isDisabled>
            <ProjectMilestoneView
              showCard={false}
              backBtnCallback={resetFunc}
              defaultPartition={partitionType}
              hideElements={['options']}
              disabledElements={['title', 'scale', 'status', 'life_time', 'assign_users', 'textarea']}
            />
          </ExpandModeLayout>
        )}
      </Modal>
    </>
  );
}

ProjectRecentActivity.propTypes = {
  partitionType: string,
};

export default ProjectRecentActivity;

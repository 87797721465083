import React, {
  useState, useCallback, useMemo, useLayoutEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bool, string, func } from 'prop-types';

import { mdiArrowRightBold } from '@mdi/js';
import Icon from '@mdi/react';
import { Flex, Modal, Input } from 'antd';

import { incrementAndGetCount } from '../../../../entity/actions/entityActions';
import { createProjectMilestoneRequest } from '../../../actions/projectMilestonesActions';

import useStatusModal from '../hooks/useStatusModal';
import { dateFormat2 } from '../../../../54origins/dateFormats54origins';
import { antNotification } from '../../../../MainUtils';

import { baseMilestoneConfig } from '../constants/baseMilestoneConfig';

const { TextArea } = Input;

const initialErrorState = {
  title: false,
  completionResult: false,
  newDescription: false,
};

function PartiallyCompletedModal({
  partition,
  projectUUID,
  modalOpen,
  defaultDescription,
  currentStartDate,
  closeModal,
  updateMilestoneParameter,
  closeModalCallback,
  createProjectMilestoneCallback,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialUpdatedState = useMemo(() => ({
    title: 'New milestone',
    completionResult: defaultDescription,
    newDescription: '',
  }), [defaultDescription]);

  const [updatedState, setUpdatedState] = useState(() => initialUpdatedState);
  const [inputError, setInputError] = useState(() => initialErrorState);

  const {
    title: modalTitle,
    completionResult: modalCompletionResult,
    newDescription: modalNewDescription,
  } = updatedState || {};

  const {
    title: titleError,
    completionResult: completionResultError,
    newDescription: newDescriptionError,
  } = inputError || {};

  const {
    modalHeader,
    modalCancelText,
    modalConfirmLoading,
    modalDisableOkButton,
    changeModalState,
  } = useStatusModal();

  const changeInputError = (params) => {
    setInputError((prev) => ({ ...prev, ...params }));
  };

  const changeUpdatedState = (params) => {
    setUpdatedState((prev) => ({ ...prev, ...params }));
  };

  const checkIsValidInput = (input) => input?.trim()?.length > 0;

  const changeModalInputField = useCallback((e) => {
    const { name, value } = e?.target || {};
    const isValid = value?.trim()?.length > 0;

    changeUpdatedState({ [name]: value });
    changeInputError({ [name]: !isValid });
  }, []);

  const createNewMilestone = async ({ title, description }) => {
    const { increment: milestoneID } = await dispatch(incrementAndGetCount({
      entity_uuid: projectUUID,
      field_name: 'milestone',
    }, partition)) || {};

    const newMilestoneParams = {
      id: milestoneID,
      title,
      status: 'created',
      description,
    };

    return dispatch(createProjectMilestoneRequest({
      parent: projectUUID,
      params: newMilestoneParams,
      partition,
    }));
  };

  const onOkHandler = async () => {
    changeModalState({ confirmLoading: true });

    Promise.all([
      createNewMilestone({
        title: modalTitle,
        description: modalNewDescription,
      })
        .catch((error) => console.error(`Failed to create new milestone: ${error.message}`)),
      updateMilestoneParameter({
        ...baseMilestoneConfig,
        status: 'partially completed',
        completionResult: modalCompletionResult,
        completionDate: dateFormat2(),
        ...(currentStartDate
          ? { startDate: currentStartDate }
          : { startDate: dateFormat2() }),
      })
        .catch((error) => console.error(`Failed to update milestone: ${error.message}`)),
    ])
      .then((results) => {
        antNotification('success', t('wms.noun.success', 'Success'));
        createProjectMilestoneCallback?.(results[0][0]?.uuid);
        closeModal();
      })
      .finally(() => changeModalState({ confirmLoading: false }));
  };

  useLayoutEffect(() => {
    const isValidTitle = checkIsValidInput(modalTitle);
    const isValidCompletionResult = checkIsValidInput(modalCompletionResult);
    const isValidNewDescription = checkIsValidInput(modalNewDescription);

    const isValidAll = isValidTitle
    && isValidCompletionResult
    && isValidNewDescription;

    changeModalState({ disableOkButton: !isValidAll });
  }, [modalTitle, modalCompletionResult, modalNewDescription]);

  return (
    <Modal
      width="75vw"
      className="custom-ant-modal"
      centered
      destroyOnClose
      open={modalOpen}
      confirmLoading={modalConfirmLoading}
      okText="Complete milestone and create new one"
      okButtonProps={{ disabled: modalDisableOkButton }}
      cancelText={modalCancelText}
      onOk={onOkHandler}
      onCancel={closeModal}
      afterClose={() => closeModalCallback?.()}
    >
      <Flex style={{ minHeight: 'max(500px, 72vh)' }} className="w-full" vertical>
        {modalHeader}

        <Flex flex="1 1 100%">
          <Flex vertical flex="1 1 100%">
            <span className="block mb-1 text-secondary">
              Write down result of current milestone:
            </span>
            <TextArea
              name="completionResult"
              style={{ resize: 'none' }}
              className="h-full"
              autoSize={{ minRows: 10 }}
              placeholder="Please, fill in the result for current completed milestone"
              status={completionResultError && 'error'}
              value={modalCompletionResult}
              onChange={changeModalInputField}
            />
            {completionResultError && <span className="text-danger">Completion result can not be empty!</span>}
          </Flex>
          <div style={{ alignSelf: 'center' }}>
            <Icon path={mdiArrowRightBold} size={1.4} color="#1677ff" />
          </div>
          <Flex vertical flex="1 1 100%">
            <span className="block mb-1 text-secondary">
              Write down information of new milestone:
            </span>
            <Input
              name="title"
              maxLength={80}
              status={titleError && 'error'}
              placeholder="Please, fill in the title for new milestone"
              showCount
              value={modalTitle}
              onChange={changeModalInputField}
            />
            {titleError && <span className="text-danger">Title can not be empty!</span>}
            <TextArea
              name="newDescription"
              style={{ resize: 'none' }}
              className="h-full mt-2"
              autoSize={{ minRows: 8 }}
              placeholder="Please, fill in the description for new milestone"
              status={newDescriptionError && 'error'}
              value={modalNewDescription}
              onChange={changeModalInputField}
            />
            {newDescriptionError && <span className="text-danger">Description of new milestone can not be empty!</span>}
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}

PartiallyCompletedModal.propTypes = {
  partition: string,
  projectUUID: string,
  modalOpen: bool,
  defaultDescription: string,
  currentStartDate: string,
  closeModal: func,
  updateMilestoneParameter: func,
  closeModalCallback: func,
  createProjectMilestoneCallback: func,
};

export default PartiallyCompletedModal;

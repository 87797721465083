export function getStateID(actualState) {
  if (actualState && actualState.length) {
    if (actualState.includes('.')) {
      return actualState.split('.', 2)[0];
    }

    return actualState;
  }

  return false;
}

export function getStateNumber(actualState) {
  if (actualState && actualState.length) {
    if (actualState.includes('.')) {
      return Number(actualState.split('.', 2)[0]);
    }

    return Number(actualState);
  }

  return false;
}

export function getPatchID(actualState) {
  if (actualState && actualState.length && actualState.includes('.')) {
    return actualState;
  }

  return false;
}

export function getPatchNumber(actualState) {
  if (actualState && actualState.length && actualState.includes('.')) {
    return Number(actualState.split('.', 2)[1]);
  }

  return false;
}

export function createNewStateID(prevID) {
  if (prevID && prevID.length && prevID !== '0') {
    const newStateID = `${+prevID[0] + 1}`;

    console.log('newStateID', newStateID);

    return newStateID;
  }
  return '1';
}

export function createNewPatchID(prevID) {
  if (prevID && prevID.length) {
    const newPatchID = prevID.includes('.')
      ? `${prevID.split('.', 2)[0]}.${+prevID.split('.', 2)[1] + 1}`
      : `${prevID}.1`;

    console.log('newPatchID', newPatchID);

    return newPatchID;
  }

  return '1.1';
}

import { Divider, Flex } from "antd"
import { object } from "prop-types"
import React from "react"
import EntityParamTag from "../../../components/entityParamTag/EntityParamTag"

const devCompleteItems = [{
    name: 'Start development',
    dividerText: 'Progress',
    complete: false,
    status: 'in progress',
    className: 'hover:bg-sky-200 border-sky-600 text-sky-600',
    color: 'sky-600',
}, {
    name: 'Reopen the task',
    complete: false,
    status: 'reopened',
    className: 'border-sky-500 hover:bg-sky-200',
    color: 'sky-500',
}, {
    name: 'Submit for review',
    dividerText: 'Completion',
    status: 'review',
    complete: true,
    className: 'hover:bg-blue-100',
    color: 'blue-500 ',
}, {
    name: 'Submit for testing',
    complete: true,
    status: 'test',
    className: 'hover:bg-orange-100',
    color: 'orange-500 ',
}, {
    name: "Bug didn't reproduce",
    complete: true,
    status: 'not reproducible',
    className: 'hover:bg-orange-100',
    color: 'orange-500 ',
}, {
    name: 'Close ticket',
    complete: true,
    status: 'closed',
    className: 'hover:bg-gray-200',
    color: 'gray-500',
}]

const qaCompleteItems = [{
    name: 'Start testing',
    dividerText: 'Progress',
    status: 'testing',
    complete: false,
    className: 'hover:bg-orange-200',
    color: 'orange-500',
}, {
    name: 'Reopen the task',
    complete: true,
    dividerText: 'Completion',
    status: 'reopened',
    className: 'border-sky-500 hover:bg-sky-200 text-sky-500',
    color: 'sky-500',
}, {
    name: 'Submit for testing',
    complete: true,
    status: 'test',
    className: 'hover:bg-orange-200',
    color: 'orange-500',
}, {
    name: 'Close ticket',
    status: 'closed',
    complete: true,
    className: 'hover:bg-gray-200',
    color: 'gray-500',
}]

const otherItems = [{
    name: 'Close ticket',
    status: 'closed',
    className: 'border-gray-500 bg-gray-100 hover:bg-gray-300',
    color: 'gray-500',
}]

export default function CompleteIssue({
    issue = {},
    type = '',
    onActionCallback,
}) {
    const completeItemStyle = {
        width: 250,
    }

    return <Flex
        vertical
        className="pt-2"
        onClick={(e) => e?.stopPropagation()}
    >
        {type === 'DEV' && devCompleteItems.map((item = {}, index) => {
            const {
                name = '',
                status = '',
                color = '',
                complete = false,
                dividerText = '',
                className = ''
            } = item;
            return (
                <>
                    {dividerText && <Divider
                        className="-mt-1 mb-0.5 border-blue-500 text-blue-500"
                        orientation="left"
                    >{dividerText}</Divider>}
                    <Flex
                        key={index}
                        className={`px-3 pt-1 pb-2 ${index !== devCompleteItems.length - 1 ? 'mb-2' : ''} cursor-pointer border rounded border-${color} text-${color} ${className}`}
                        vertical
                        onClick={(e) => onActionCallback({
                            newStatus: status,
                            issue: issue,
                            complete: complete,
                        }, 'issue-complete', e)}
                        style={completeItemStyle}
                    >
                        <span
                            className="text-base font-bold"
                        >
                            {name}
                        </span>
                        <Flex
                            align="center"
                            className="mt-1"
                        >
                            <span
                                className="mr-1 -mt-0.5"
                            >
                                New status:
                            </span>
                            <EntityParamTag
                                param="status"
                                value={status}
                                type="iconWithStatus"
                            />
                        </Flex>
                    </Flex>
                </>)
        })}
        {type === 'QA' && qaCompleteItems.map((item = {}, index) => {
            const {
                name = '',
                status = '',
                color = '',
                complete = false,
                dividerText = '',
                className = ''
            } = item;
            return (
                <>
                    {dividerText && <Divider
                        className="-mt-1 mb-0.5 border-blue-500 text-blue-500" orientation="left">{dividerText}</Divider>}
                    <Flex
                        key={index}
                        className={`px-3 pt-1 pb-2 ${index !== qaCompleteItems.length - 1 ? 'mb-2' : ''} cursor-pointer border rounded border-${color} text-${color} ${className}`}
                        vertical
                        onClick={(e) => onActionCallback({
                            newStatus: status, issue: issue,
                            complete: complete,
                        }, 'issue-complete', e)}
                        style={completeItemStyle}
                    >
                        <span
                            className="text-base font-bold"
                        >
                            {name}
                        </span>
                        <Flex
                            align="center"
                            className="mt-1"
                        >
                            <span
                                className="mr-1 -mt-0.5"
                            >
                                New status:
                            </span>
                            <EntityParamTag
                                param="status"
                                value={status}
                                type="iconWithStatus"
                            />
                        </Flex>
                    </Flex>
                </>)
        })}
    </Flex>
}

CompleteIssue.propTypes = {
    issue: object,
}
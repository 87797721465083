import { Row } from 'antd';
import React from 'react';

import DashboardGoalsCardWrapper from './DashboardGoalsCardTableWrapper';

import { clearProjectsGoals } from '../../../../../projectFlow/actions/projectGoalsActions';
import { useClearComponent } from '../../../../../hooks/useClearComponent';

function DashboardGoalsCardRoot() {
  useClearComponent(clearProjectsGoals);

  return (
    <Row className="mt-3 w-full">
      <DashboardGoalsCardWrapper readOnlyComments />
    </Row>
  );
}

export default DashboardGoalsCardRoot;

import PropTypes, { bool, string } from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';

import { clearListTestCaseInSuiteInStore, clearSuite } from '../../../actions/QaActions';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import useURLParams from '../../../../hooks/useURLParams';

function RemoveTestSuite({
  actionForEntityCallback,
  entityUUID,
  hideLabel,
  styleBtn,
  className,
  disabled,
}) {
  const dispatch = useDispatch();

  const { getURLParams, clearSearchParams } = useURLParams();

  const { activeSuite } = getURLParams();

  const { getListOfTestSuites, partitionType } = useContext(BugTrackerContext);

  const actionForEntity = () => {
    if (activeSuite === entityUUID
      || (Array.isArray(entityUUID) && entityUUID.indexOf(activeSuite))) {
      dispatch(clearSuite);
      clearSearchParams(['activeSuite', 'activeCaseSuite']);
      dispatch(clearListTestCaseInSuiteInStore);
    }

    getListOfTestSuites();

    if (actionForEntityCallback) {
      actionForEntityCallback();
    }
  };

  const constants = [
    ProjectTypeCommonConstants.REMOVE_TEST_SUITE_REQUEST,
    ProjectTypeCommonConstants.REMOVE_TEST_SUITE_SUCCESS,
    ProjectTypeCommonConstants.REMOVE_TEST_SUITE_FAILURE,
  ];

  return (
    <HardDeleteEntityContainer
      disabled={disabled}
      className={className}
      name="suite"
      labelMode={false}
      entityUUID={entityUUID}
      actionForEntity={actionForEntity}
      checkHardDelete={false}
      entityType="testSuite"
      type="entity"
      sizeBtn="small"
      styleBtn={styleBtn}
      antNotificationSuccess
      hideLabel={hideLabel}
      customConstants={constants}
      partitionType={partitionType}
    />
  );
}

export default RemoveTestSuite;

RemoveTestSuite.propTypes = {
  actionForEntityCallback: PropTypes.func,
  entityUUID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  hideLabel: PropTypes.bool,
  styleBtn: PropTypes.object,
  className: string,
  disabled: bool,
};

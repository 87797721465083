import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { createComment } from './projectFlowActions';

export const makeComment = (defaultFields, fields, options = {}, special = null) => {
  const {
    entityUUID,
    partitionType,
  } = options;

  const changedFields = {};

  Object.keys(fields).forEach((key) => {
    if (!isEqual(defaultFields[key], fields[key])) {
      changedFields[key] = fields[key];
    }
  });

  if (special) {
    Object.keys(special).forEach((key) => {
      if (changedFields[key]) {
        changedFields[key] = special[key](fields[key]);
      }
    });
  }
  const camelToFlat = (str) => str.replace(/[A-Z]/g, ' $&').toLowerCase();

  const data = {
    parent: entityUUID,
    partition: partitionType,
    params: {
      comment: `Changed ${Object.keys(changedFields).map((key) => `${camelToFlat(key) !== 'comment' ? camelToFlat(key) : 'preconditions'} to: ${changedFields[key]}`).join(', ')}.`,
      entity: entityUUID,
    },
  };

  return (dispatch) => dispatch(createComment(data));
};

// export const createActionChangedComment = async (changedFields = [], data = {}, options = {}) => {
//   const dispatch = useDispatch();

//   const usersKey = ['users, 'usersSearch'];

//   if (changedFields.length) {
//     await Promise.all(
//       changedFields.reduce((acc, item) => {
//         const systemType = item.toLowerCase();

//         const comment = {
//           entity: options.entity,
//           action: 'changed',
//           param: systemType,
//           value: data[item],
//         };

//         if (item === 'selectedVectorData') {
//           comment.value = form[item].title;
//           comment.param = 'vector';
//         }

//         if (item === 'usersSearch') {
//           comment.value = form[item];
//           comment.param = 'users';
//         }

//         if (!isEmpty(descriptionComment) && descriptionComment.some((p) => p?.name === item)) {
//           const data = descriptionComment.find((p) => p?.name === item);
//           comment.descriptionComment = get(data, 'description', '');
//         }

//         return [
//           ...acc,
//           dispatch(sendSystemComment(uuid, comment, partitionType, systemType))
//             .then(() => dispatch(getCommentsAndUpdateStore({
//               commentType: COMMENT_TYPE.SYSTEM,
//               parent: uuid,
//               partition: partitionType,
//             }))),
//         ];
//         return acc;
//       }, []),
//     );
//   }
// };

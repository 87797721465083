import PropTypes, { func, string } from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { mdiStar, mdiStarOutline } from '@mdi/js';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { getFavoriteSuites, getFavoriteUUID } from '../../../userFlow/store/selectors/selectors';
import { getProjectUUID } from '../../selectors/selectors';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import { capitalize } from 'lodash';

function AddSuiteToFavorite({
  suiteUuid,
  showTitle,
  addToFavorite,
}) {
  const { t } = useTranslation();

  const projectUUID = useSelector(getProjectUUID);
  const favoriteSettingsUUID = useSelector(getFavoriteUUID);
  const favoriteSuites = useSelector((state) => getFavoriteSuites(state, projectUUID));

  const isFavorite = get(favoriteSuites, '[0].suites', []).includes(suiteUuid);

  return (
    <Tooltip
      title={isFavorite ? 'Remove from favorite' : 'Add to favorite'}
      placement="left"
    >
      <BaseButton
        className={`btnFavorite ${isFavorite ? 'active' : ''}`}
        onClick={(e) => addToFavorite(e, isFavorite, suiteUuid)}
        disabled={!favoriteSettingsUUID}
      >
        <Icon path={isFavorite ? mdiStar : mdiStarOutline} size={1} />
        {showTitle && (
          <span className="ml-1" style={{ whiteSpace: 'break-spaces', textAlign: 'left' }}>
            {capitalize(
              t(
                `wms.buttons.${isFavorite ? 'remove' : 'add'}_favorite`,
                `${isFavorite ? 'Remove from' : 'Add to'} favorite`,
              ),
            )}
          </span>
        )}
      </BaseButton>
    </Tooltip>
  );
}

export default AddSuiteToFavorite;

AddSuiteToFavorite.propTypes = {
  showTitle: PropTypes.bool,
  addToFavorite: func,
  suiteUuid: string,
};

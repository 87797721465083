import PropTypes, { array } from 'prop-types';
import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, DatePicker, Flex, Input, Row, Select, Tabs,
  Tooltip, Typography
} from 'antd';
import { isNil } from 'ramda';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, isEqual, omit } from 'lodash';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import ListOfVectorsDropDown from '../componentsForMultipleUse/ListOfVectorsDropDown';

import { antNotification, formatDateTimeForWms, getHeadUUID } from '../../../../MainUtils';
import { capitalize } from 'lodash';
import {
  getAllTestSuites, getMe, getProjectParams, getProjectUUID, getTestCycleUUID,
} from '../../../selectors/selectors';
import {
  clearListTestCaseInCycleInStore,
  updateListOfTestCaseInCycle,
  createTestCaseForCycle,
  updateTestCycle,
  getListOfTestCasesInCycleFunc,
} from '../../../actions/QaActions';
import { BugTrackerContext, ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { incrementAndGetCount } from '../../../../entity/actions/entityActions';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { capitalizeFirstChar } from '../../../../MainUtils';
import BaseSelect from '../../../../components/_ui/BaseSelect/BaseSelect';
import { defaultArrayTypeOfTesting } from '../QaUtils';
import ImportCaseComponent from '../case/ImportCaseComponent';
import Icon from '@mdi/react';
import { mdiCalendarClock } from '@mdi/js';
import moment from 'moment';
import useSessionStorage from '../../../../hooks/useSessionStorage';

const { TextArea } = Input;

function TestCycleForm({
  defaultTitle = '',
  disabledSbmtBtn,
  entityUUID,
  defaultDescription = '',
  defaultStartDate = new Date(),
  defaultDueDate = new Date(),
  defaultAdHocDueDate = new Date(),
  datesDifference = 3,
  typeOfForm,
  submitLabelBtn,
  selectTestCycleCollback,
  handleOk,
  selectTestCycleCallback,
  reRun = false,
  selectedVectorDefault = [],
  selectVectorDefault,
  importListOfTestCasesCallback,
  assignToUser = [],
}) {
  const {Paragraph} = Typography;
  const { t } = useTranslation();
  const {TabPane} = Tabs;
  const dispatch = useDispatch();

  const disabledSumbit = useRef(false);

  const getCustomTestSuites = useSelector(getAllTestSuites);
  const testCycleUUID = useSelector(getTestCycleUUID);
  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);

  const [storageData, setStorageData] = useSessionStorage('newCycleState', {});

  const {
    sendAssignedNotifications,
  } = useContext(ProjectMainLayerContext);

  const {
    createPromiseNewTestCycle,
    getListOfTestCycles,
    partitionType,
  } = useContext(BugTrackerContext);

  const userUUID = useSelector(getMe);

  const defaultState = {
    'new cycle form': {
      title: defaultTitle,
      description: defaultDescription,
      start_date: defaultStartDate,
      end_date: defaultDueDate?.setDate ? defaultDueDate?.setDate(new Date(defaultDueDate).getDate() + datesDifference) : defaultDueDate,
      difference: datesDifference,
      selectVector: selectedVectorDefault,
      casesCount: 0,
      assignToUsersOption: assignToUser,
    },
    'existing cycle form': {
      selectedCycle: {},
      selectedSuites: [],
      suitesOptions: [],
      selectedCases: []
    },
    'ad-hoc cycle form':{
      title: 'Ad-hoc cycle',
      description: defaultDescription,
      start_date: defaultStartDate,
      end_date: defaultAdHocDueDate?.setDate ? defaultAdHocDueDate?.setDate(new Date(defaultAdHocDueDate).getDate() + datesDifference) : defaultAdHocDueDate,
      difference: datesDifference,
      selectVector: selectedVectorDefault,
      casesCount: 0,
      assignToUsersOption: assignToUser,
    }
  };

  const [state, changeState] = useState(defaultState);
  const [cycleType, setCycleType] = useState('new cycle form');
  const [testCycleOptions, setTestCycleOptions] = useState([]);
  const [isAssignOpen, setIsAssignOpen] = useState(false);
  const [cycles, setCycles] = useState([]);

  const {
    title = state[cycleType]?.title,
    description = state[cycleType]?.description,
    start_date = state[cycleType]?.start_date,
    end_date = state[cycleType]?.end_date,
    assignToUsersOption = state[cycleType]?.assignToUsersOption,
    casesCount = state[cycleType]?.casesCount,
    selectVector = state[cycleType]?.selectVector,
    difference = state[cycleType]?.difference,
    selectedCycle = state[cycleType]?.selectedCycle,
  } = state;

  const isClearDisabled = {
    'new cycle form': 
      isEmpty(assignToUsersOption) &&
      description === '' &&
      title === '' &&
      isEmpty(selectVector) &&
      isEqual(new Date(start_date).getDate(), new Date(defaultState[cycleType]?.start_date).getDate()) &&
      isEqual(new Date(end_date).getDate(), new Date(defaultState[cycleType]?.end_date).getDate()),
    'existing cycle form': 
      isEmpty(selectedCycle) &&
      isEmpty(state[cycleType]?.selectedSuites) && 
      isEmpty(state[cycleType]?.selectedCases) &&
      isEmpty(state[cycleType]?.suitesOptions),
    'ad-hoc cycle form':
      isEmpty(assignToUsersOption) &&
      description === '' &&
      isEmpty(selectVector) &&
      isEqual(new Date(start_date).getDate(), new Date(defaultState[cycleType]?.start_date).getDate()) &&
      isEqual(new Date(end_date).getDate(), new Date(defaultState[cycleType]?.end_date).getDate()),
  }

  const setNewState = (param) => {
    const newState = {
      ...state,
      ...param,
    };
    changeState(newState);

    if(typeOfForm === 'newForm'){
      setStorageData(newState);
    }
  };

  const onSelectTestCycle = async (e) => {    
    setNewState({[cycleType]: {...state[cycleType], selectedCycle: e}});
  };

  const onFormChange = (event) => {
    const { name, value } = event.target;

    setNewState({[cycleType]: {...state[cycleType], [name]: value} });
  };

  const onDaysChange = (param, day) => {
    const newState = { [param]: isNil(day) ? '' : day };
  
    if (param === 'start_date' && !isNil(day)) {
      const startDate = new Date(day);
      startDate.setDate(startDate.getDate() + difference + 1);
      newState.end_date = startDate.toISOString().split('T')[0];
    }
    if (param === 'end_date' && !isNil(day)) {
      const startDate = new Date(start_date);
      const endDate = new Date(day);
      const diff = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
      newState.difference = diff;
    }
  
    setNewState({[cycleType]: {...state[cycleType], ...newState}});
  };

  const submitNewTestCycle = async (customCountCase) => {
    dispatch(clearListTestCaseInCycleInStore());

    const { increment } = await dispatch(incrementAndGetCount({
      entity_uuid: projectUUID,
      field_name: 'suite',
    }, partitionType));

    const params = {
      title,
      description,
      start_date: start_date ? dayjs(start_date).format('YYYY-MM-DD') : '',
      end_date: end_date ? dayjs(end_date).format('YYYY-MM-DD') : '',
      status: 'unexecuted',
      executed_by: null,
      executed_on: null,
      cases_count: customCountCase || casesCount,
      order: [],
      helperInStatus: false,
      archive: false,
      assignToUser: assignToUsersOption,
      id: `C${increment}`,
      vector: selectVector,
      progress: {
        pass: 0,
        block: 0,
        fail: 0,
        unexecuted: customCountCase || 0,
        progress: 0,
        quantityCase: 0,
      },
      clonedFrom: defaultTitle,
    };

    const newTestCycle = await createPromiseNewTestCycle(params);
    const newTestCycleUUID = getHeadUUID(newTestCycle);

    const assignedUsers = get(params, 'assignToUser');

    sendAssignedNotifications({
      entity_type: 'testCycle',
      assignedUsers,
      eventName: 'assigned_test_cycle',
      entityUUID: get(newTestCycle, '[0].uuid'),
      message: get(newTestCycle, '[0].params.title', ''),
      entityUUIDsFields: {
        testCycleUUID: get(newTestCycle, '[0].uuid'),
        projectUUID,
      },
    });

    if (newTestCycleUUID) {
      getListOfTestCycles();
    }

    if (selectTestCycleCallback) {
      selectTestCycleCallback(newTestCycleUUID, false, true);
    }

    return Promise.resolve(newTestCycle);
  };

  const submitNewTestCycleAndCloseModal = async () => {
    if (handleOk) {
      handleOk();
    }

    await submitNewTestCycle();
    disabledSumbit.current = false;
  };

  const submitAndImport = async () => {
    const submitNewCycle = await submitNewTestCycle();

    if (importListOfTestCasesCallback) {
      importListOfTestCasesCallback(get(submitNewCycle, '[0]'));
    }

    if (handleOk) {
      handleOk();
    }
  };

  const clearSelectedForm = () => {
    setNewState({[cycleType]: defaultState[cycleType]});
  };

  const updateTestCycleFunc = async () => {
    const currentUud = entityUUID || testCycleUUID;
    const data = {
      uuid: currentUud,
      partition: partitionType,
      params: {
        archive: false,
        modified_by: userUUID,
        modified_on: formatDateTimeForWms(moment()),
      },
    };

    data.params.title = !isNil(title) ? title : '';
    data.params.description = !isNil(description) ? description : '';
    data.params.start_date = dayjs(start_date).format('YYYY-MM-DD');
    data.params.end_date = dayjs(end_date).format('YYYY-MM-DD');
    data.params.assignToUser = assignToUsersOption;
    data.params.vector = selectVector;

    dispatch(updateTestCycle(data)).then((response) => {
      if (response) {
        antNotification('success', t('wms.noun.success', 'Success'));
        const assignedUsers = get(data, 'params.assignToUser');

        sendAssignedNotifications({
          entity_type: 'testCycle',
          assignedUsers,
          eventName: 'assigned_test_cycle',
          entityUUID: get(response, '[0].uuid'),
          message: get(response, '[0].params.title', ''),
          entityUUIDsFields: {
            testCycleUUID: get(response, '[0].uuid'),
            projectUUID,
          },
        });

        dispatch({
          type: ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE,
          payload: response,
        });
      } else {
        antNotification('error', t('notifications.text.error.base', 'Error'));
      }

      getListOfTestCycles();

      if (handleOk) {
        handleOk();
      }
      disabledSumbit.current = false;
    });
  };

  const createNewCycleAndCase = async () => {
    const res = await dispatch(getListOfTestCasesInCycleFunc({
      entity_type: 'testCase',
      parent: entityUUID,
      params: {},
      partition: partitionType,
      customConstants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    }));

    const submitNewCycle = await submitNewTestCycle(get(res, 'data', []).length);

    const newCycleUUID = getHeadUUID(submitNewCycle);

    const list = await Promise.all(
      get(res, 'data', []).map(async (item) => {
        const { increment } = await dispatch(incrementAndGetCount({
          entity_uuid: newCycleUUID,
          field_name: 'case',
        }, partitionType));

        return ({
          ...item.params,
          status: 'unexecuted',
          steps: item.params.steps.map(
            (i) => ({ ...i, status: 'unexecuted' }),
          ),
          closedCase: false,
          counterBug: 0,
          id: `${get(submitNewCycle, '[0].params.id', 0)}C${increment}`,
          order: increment,
        });
      }),
    );

    const data = {
      parent: newCycleUUID,
      partition: partitionType,
      params: list,
    };

    const response = await dispatch(createTestCaseForCycle(data));

    if (selectTestCycleCollback) {
      selectTestCycleCollback(newCycleUUID, true);
    }
    dispatch(updateListOfTestCaseInCycle(response.map((item) => ({ ...omit(item, ['params']), ...item.params }))));

    if (handleOk) {
      handleOk();
    }
    disabledSumbit.current = false;
  };

  const selectVectorCollback = (item) => {
    setNewState({[cycleType]:{
      ...state[cycleType],
      selectVector: item,
    }});
  };

  const assignToFunc = (value) => {
    setNewState({[cycleType]:{
      ...state[cycleType],
      assignToUsersOption: value,
    }});
  };

  const finalSubmitFunc = () => {
    if (!disabledSumbit.current) {
      disabledSumbit.current = true;
      switch (typeOfForm) {
        case 'newForm':
          return submitNewTestCycleAndCloseModal();
        case 'editForm':
          return updateTestCycleFunc();
        case 'reRun':
          return createNewCycleAndCase();
        default:
          return '';
      }
    }
  };

  const disabledDate = (current) => current && current < dayjs().startOf('day');
  const disabledEndDate = (current) => current && current < start_date;

  useEffect(() => {
    const savedState = storageData;
    if (!isEmpty(savedState) && typeOfForm === 'newForm') {
      changeState(savedState);
    }
  }, []);

  useEffect(() => {
    getListOfTestCycles().then((data) => {
      setCycles(data);
      let arrayForOptions = [];
      data.map(item => {
        arrayForOptions.push({label: item.title, value: item.uuid});
      });
      setTestCycleOptions(arrayForOptions);
    });
  }, []);

  return (
    <Row gutter={[30, 16]}>
      {typeOfForm === 'newForm' &&
        <Tabs type="card" activeKey={cycleType} onChange={setCycleType} className='w-full'>

        <TabPane
          tab={capitalize(t('wms.tabs.new_cycle', 'new'))}
          key="new cycle form"
        >
          <Col span={24}>
            {capitalize(t('wms.labels.title', 'title'))}
            <span style={{ color: 'red' }}>*</span>
            <Input
              maxLength={128}
              showCount
              name="title"
              onChange={onFormChange}
              value={title}
            />
          </Col>
        </TabPane>

        <TabPane
          tab={capitalize(t('wms.tabs.existing', 'existing'))}
          key="existing cycle form"
        >
        </TabPane>

        <TabPane
          tab={capitalize(t('wms.tabs.ad-hoc_cycle', 'ad-hoc cycle'))}
          key="ad-hoc cycle form"
        >
          <Col span={24}>
            {capitalize(t('wms.labels.title', 'title'))}
            <Input
              maxLength={128}
              showCount
              name="title"
              disabled
              value={'Ad-hoc cycle'}
            />
          </Col>
        </TabPane>

      </Tabs>
      }

      {typeOfForm !== 'newForm' &&
        <Col span={24}>
          {capitalize(t('wms.labels.title', 'title'))}
          <span style={{ color: 'red' }}>*</span>
          <Input
            maxLength={128}
            showCount
            name="title"
            onChange={onFormChange}
            value={title}
          />
        </Col>
      }

      {cycleType === 'existing cycle form' ?
        <Col span={24}>
          {capitalize(t('wms.labels.test_cycles', 'Test cycle'))}
          <span style={{ color: 'red' }}>*</span>
          <Select
            className="w-full mt-1 mb-1"
            noOptionsMessage={() => capitalize(t('wms.select.no_options', 'no options'))}
            placeholder={capitalize(t('wms.placeholders.select_cycle', 'select cycles'))}
            onChange={onSelectTestCycle}
            options={testCycleOptions}
            value={selectedCycle}
          />

          <ImportCaseComponent
            typeOfParent='cycle'
            parentData={cycles.filter(item => item.uuid === selectedCycle)[0]}
            toggleModal={handleOk}
            cycleState={state}
            setCycleState={setNewState}
            isClearDisabled={isClearDisabled}
          />
        </Col>

        :

      <>
      <Col span={24}>
        {capitalize(t('wms.labels.description', 'description'))}
        :
        <TextArea
          row={10}
          name="description"
          value={description}
          onChange={onFormChange}
        />
      </Col>

      <Col span={24}>
        <Row className='w-full'>
          <Col span={24}>
            <ListOfVectorsDropDown
              partition={partitionType}
              selectVectorCallback={selectVectorCollback}
              selectVectorDefault={selectVector}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Flex className="w-full">
          <AssigningUsersRoot
            header="Assigning users"
            needUpdateProjectUsers
            isOpen={isAssignOpen}
            setIsOpen={setIsAssignOpen}
            onUpdateUsers={(data) => assignToFunc(data?.users?.map((el) => el?.uuid))}
            globalOptions={{
              uuids: projectParams?.usersSearch ?? [],
              defaultColumn: 'project',
            }}
            placement="left"
            isPopover
            tabs={{
              allConfig: {
                label: 'All',
                value: 'all',
                textSelectButton: 'Add to cycle',
                showSections: ['button-add-user'],
              },
              projectConfig: {
                label: 'Project',
                value: 'project',
                textSelectButton: 'Add to cycle',
                defaultItems: projectParams?.users ?? [],
                showSections: ['string-role', 'button-add-user'],
              },
            }}
            usersConfig={{
              label: 'Cycle users',
              showSections: ['button-remove-user', 'string-role'],
              textDeleteButton: 'Delete users',
              defaultItems: assignToUsersOption ?? [],
            }}
            wrapperProps={{ autoAdjustOverflow: true }}
            partition={partitionType}
            projectUuid={projectUUID}
            popoverText={(<span style={{ color: 'black' }}>Assigned users:</span>)}
          />
          <WrapperAvatarsGroupForProjectWithHook
            actors={assignToUsersOption || []}
            avatarSize={28}
            popoverTrigger="click"
            popoverPlacement="right"
          />
        </Flex>
      </Col>

      <Col span={8} className="flex flex-col">
        <span>{`${capitalize(t('wms.labels.date.start', 'start date'))}:`}</span>
        <DatePicker
          format="YYYY-MM-DD"
          value={dayjs(start_date).toString() === 'Invalid Date' ? '' : dayjs(start_date)}
          placeholder={t('wms.placeholders.date.format')}
          disabledDate={disabledDate}
          onChange={(day) => {onDaysChange('start_date', day)}}
        />
      </Col>
      <Flex className='mt-4 flex flex-row items-center'>
        <Tooltip title={title}>
          <Icon path={mdiCalendarClock} size={1} className="text-danger" />
        </Tooltip>
          <Paragraph className='m-0 ml-1'>
            {difference}
            {' '}
            days
          </Paragraph>
      </Flex>
      <Col span={8} className="flex flex-col">
        <span>
          {capitalize(t('wms.labels.date.end', 'end date'))}
          :
        </span>
        <DatePicker
          format="YYYY-MM-DD"
          value={dayjs(end_date).toString() === 'Invalid Date' ? '' : dayjs(end_date)}
          placeholder={t('wms.placeholders.date.format')}
          disabledDate={disabledEndDate}
          onChange={(day) => onDaysChange('end_date', day)}
        />
      </Col>
      <Col span={24} className="flex justify-between wrap">
          <BaseButton
            className="btnDanger"
            onClick={clearSelectedForm}
            disabled={isClearDisabled[cycleType]}
          >
            {capitalize(t('wms.buttons.Clear_form', 'Clear form'))}
          </BaseButton>
          <Row>
            {(typeOfForm === 'newForm' && !reRun) && (
              <BaseButton
                className="btnPrimary"
                onClick={submitAndImport}
                disabled={disabledSbmtBtn || getCustomTestSuites.length === 0 || title?.length === 0 || isEmpty(state[cycleType])}
              >
                {capitalize(t('wms.buttons.Submit_and_import', 'Submit and import test cases ?'))}
              </BaseButton>
            )}
            <BaseButton
              className="btnPrimary ml-2"
              onClick={() => {
                finalSubmitFunc()
                clearSelectedForm()
              }}
              disabled={disabledSbmtBtn || title?.trim()?.length === 0 || cycleType === 'existing cycle form' || isEmpty(state[cycleType])}
            >
              {submitLabelBtn || capitalize(t('wms.buttons.submit', 'submit'))}
            </BaseButton>
          </Row>
      </Col>
      </>
    }
    </Row>
  );
}

TestCycleForm.propTypes = {
  defaultDescription: PropTypes.string,
  defaultDueDate: PropTypes.string,
  defaultStartDate: PropTypes.string,
  defaultTitle: PropTypes.string,
  defaultTypeOfTesting: PropTypes.array,
  disabledSbmtBtn: PropTypes.any,
  entityUUID: PropTypes.string,
  handleOk: PropTypes.func,
  importListOfTestCasesCallback: PropTypes.func,
  reRun: PropTypes.bool,
  selectTestCycleCallback: PropTypes.func,
  selectTestCycleCollback: PropTypes.func,
  selectVectorDefault: PropTypes.func,
  selectedVectorDefault: PropTypes.array,
  submitLabelBtn: PropTypes.any,
  typeOfForm: PropTypes.string,
  assignToUser: array,
};

export default TestCycleForm;

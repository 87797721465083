import { UsersPartitionConstants } from '../../constants/Constants';

const initialState = {
  data: [],
  mainPublicProjectUUID: '',
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case UsersPartitionConstants.MAIN_PUBLIC_PROJECT:
      return {
        ...state,
        mainPublicProjectUUID: payload,
      };

    case UsersPartitionConstants.GET_MAIN_PUBLIC_PROJECT_SUCCESS:
      // console.log('crdt GET_MAIN_PUBLIC_PROJECT_SUCCESS', {
      //   ...state,
      //   mainPublicProjectUUID: payload,
      // })
      return {
        ...state,
        mainPublicProjectUUID: payload,
      };

    case UsersPartitionConstants.CLEAR_MAIN_PUBLIC_PROJECT:
      return {
        ...state,
        mainPublicProjectUUID: '',
      };

    default:
      return state;
  }
};

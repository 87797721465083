export function createPythonByteString(uint8Array) {
  let byteString = "";
  for (let i = 0; i < uint8Array.length; i++) {
    const byte = uint8Array[i];
    // Добавляем экранирование для специальных символов и представление в формате \xHH.
    if (byte >= 32 && byte <= 126 && byte !== 92 && byte !== 39) {
      // Printable characters except backslash and single quote
      byteString += String.fromCharCode(byte);
    } else {
      byteString += "\\x" + byte.toString(16).padStart(2, "0");
    }
  }
  // byteString += "'";
  return byteString;
}

import { ProjectTypeCommonConstants } from '../constants/Constants';

const initialState = {
  fetching: true,
  areas: [],
  error: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ProjectTypeCommonConstants.GET_AREAS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.GET_AREAS_SUCCESS:
      return {
        ...state,
        fetching: false,
        areas: payload,
      };
    case ProjectTypeCommonConstants.GET_AREAS_FAILURE:
      return {
        ...state,
        fetching: false,
        areas: [],
        error: payload,
      };

    default:
      return state;
  }
};

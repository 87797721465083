import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tags: [],
  isChanged: false,
};

const userFilterSlicer = createSlice({
  name: 'userFilterSlicer',
  initialState,
  reducers: {
    setGlobalUserFilterTags: (state, action) => {
      // console.log('action', action);
      state.tags = action.payload;
      state.isChanged = true;
    },
    clearGlobalUserFilterTags: (state) => {
      state.tags = [];
    },
  },
});

export const {
  setGlobalUserFilterTags,
  clearGlobalUserFilterTags,
} = userFilterSlicer.actions;

export default userFilterSlicer.reducer;

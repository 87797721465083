import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, string, func } from 'prop-types';
import { capitalize } from 'lodash';

import { Flex, Modal, Input } from 'antd';

import useStatusModal from '../hooks/useStatusModal';
import { dateFormat2 } from '../../../../54origins/dateFormats54origins';

import { baseMilestoneConfig } from '../constants/baseMilestoneConfig';

const { TextArea } = Input;

function FailedModal({
  modalOpen,
  currentStartDate,
  closeModal,
  updateMilestoneParameter,
  closeModalCallback,
}) {
  const { t } = useTranslation();

  const [modalFailReason, setModalFailReason] = useState('');
  const [inputError, setInputError] = useState(false);

  const {
    modalHeader,
    modalOkText,
    modalCancelText,
    modalConfirmLoading,
    modalDisableOkButton,
    changeModalState,
  } = useStatusModal();

  const changeModalInputField = useCallback((e) => {
    const { value } = e?.target || {};
    const isValid = value?.trim()?.length > 0;

    setModalFailReason(value);
    setInputError(!isValid);
    changeModalState({ disableOkButton: !isValid });
  }, [changeModalState]);

  const onOkHandler = async () => {
    changeModalState({ confirmLoading: true });
    updateMilestoneParameter({
      ...baseMilestoneConfig,
      status: 'failed',
      failReason: modalFailReason,
      lifeTime: dateFormat2(),
      ...(currentStartDate
        ? { startDate: currentStartDate }
        : { startDate: dateFormat2() }),
    })
      .then(() => closeModal())
      .catch((error) => console.error(`Failed to update milestone: ${error.message}`))
      .finally(() => changeModalState({ confirmLoading: false }));
  };

  return (
    <Modal
      width="fit-content"
      className="custom-ant-modal"
      centered
      destroyOnClose
      open={modalOpen}
      confirmLoading={modalConfirmLoading}
      okText={modalOkText}
      okButtonProps={{ disabled: modalDisableOkButton }}
      cancelText={modalCancelText}
      onOk={onOkHandler}
      onCancel={closeModal}
      afterClose={() => closeModalCallback?.()}
    >
      <Flex className="w-full" vertical>
        {modalHeader}

        <Flex className="pt-2 mb-3" vertical>
          <span className="block mb-1 text-secondary">
            {capitalize(t('wms.labels.fail_reason', 'fail reason'))}
            :
          </span>
          <TextArea
            name="failReason"
            style={{ resize: 'none', minWidth: '40vw' }}
            placeholder="Please, fill in the reason for failed milestone"
            status={inputError && 'error'}
            autoSize={{ minRows: 5 }}
            maxLength={200}
            showCount
            value={modalFailReason}
            onChange={changeModalInputField}
          />
          {inputError && <span className="mt-1 text-danger">Milestone fail reason can not be empty!</span>}
        </Flex>
      </Flex>
    </Modal>
  );
}

FailedModal.propTypes = {
  modalOpen: bool,
  currentStartDate: string,
  closeModal: func,
  updateMilestoneParameter: func,
  closeModalCallback: func,
};

export default FailedModal;

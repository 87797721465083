import React, {
  useEffect, useContext, useState, useRef,
} from 'react';
import {
  Col, Empty, Row, Spin, Tooltip,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiReloadAlert } from '@mdi/js';
import { marked } from 'marked';
import hljs from 'highlight.js';
import PropTypes from 'prop-types';

import 'highlight.js/styles/github.css';

import BaseButton from '../components/_ui/BaseButton/BaseButton';
import CircleActorAvatar54origins from '../54origins/components/CircleActorAvatar54origins';
import CopyToClipboardBtn54origins from '../54origins/components/CopyToClipboardBtn54origins';

import { getUser } from '../entity/selectors/selectors';
import {
  getInitialsNameOfActor,
  getNameForActor,
} from '../54origins/utils54origins';
import { dateGetStartOfFromNow, dateToFormat13 } from '../54origins/dateFormats54origins';
import { SocketContext } from '../socket/SocketProvider';
import useGptRequestsHook from './hooks/useGptRequestsHook';
import { GPTContext } from './GptContext';

function RenderGptMessage(props) {
  const {
    clearContextAndReloadChat,
    chatMessages,
    loadingMessages,
  } = props;

  const pageRef = useRef(null);

  const myUser = useSelector(getUser);

  const transformDate = (param) => dateToFormat13(param);

  useEffect(() => {
    pageRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages.length, loadingMessages]);

  return (
    <>
      {chatMessages.map((item, messageNumber) => {
        const {
          input_text: inputText,
          output_text: outputText,
          sent_at_iso: sentAtIso,
          received_at: receivedAt,
          system: msgIsSystem,
          error: msgIsSystemError,
          received_at_iso: receivedAtIso,
        } = item;

        return (
          <Row
            className="chat-gpt-message"
            key={`key${receivedAtIso + messageNumber}`}
            ref={(messageNumber === 10) ? pageRef : null}
          >
            {inputText && (
              <Col
                span={24}
              // align="start"
              >
                <Comment
                  author={<a>{getNameForActor(myUser)}</a>}
                  avatar={(
                    <CircleActorAvatar54origins
                      name={getInitialsNameOfActor(myUser)}
                      actorUUID={myUser?.uuid}
                    />
                  )}
                  content={(
                    <p>
                      {inputText}
                    </p>
                  )}
                  datetime={(
                    <Tooltip title={transformDate(sentAtIso)}>
                      <span>{transformDate(sentAtIso)}</span>
                    </Tooltip>
                  )}
                />
              </Col>
            )}
            {receivedAt && outputText && (
              <Col
                span={24}
              // align="bottom"
              >
                <Comment
                  // author={<a>GPT</a>}
                  avatar={<CircleActorAvatar54origins name="GPT" />}
                  content={(
                    <>
                      <p>
                        {outputText ? <div dangerouslySetInnerHTML={{ __html: marked(outputText) }} /> : 'empty message'}
                      </p>
                      {msgIsSystemError && (
                        <p className="text-center">
                          <BaseButton
                            type="primary"
                            onClick={clearContextAndReloadChat}
                            icon={<Icon path={mdiReloadAlert} size={0.9} />}
                          >
                            <span className="ml-1">Clear context end reload chat</span>
                          </BaseButton>
                        </p>
                      )}
                      {outputText && !msgIsSystem && (
                        <p className="text-right">
                          <CopyToClipboardBtn54origins text={outputText} iconSize={0.8} />
                        </p>
                      )}
                    </>
                  )}
                  datetime={(
                    <Tooltip title={transformDate(receivedAtIso)}>
                      <span>{transformDate(receivedAtIso)}</span>
                    </Tooltip>
                  )}
                />
              </Col>
            )}
          </Row>
        );
      })}
    </>
  );
}

function ChatMessages(props) {
  const {
    selectedChatID,
    setStopAutoScroll,
    waitGptResponse,
    clearContextAndReloadChat,
    gptReplyCallback,
    stopAutoScroll,
    focusOnMessageInput,
    setLoadingMessages,
    loadingMessages,
  } = props;

  // console.log('RENDER stopAutoScroll', stopAutoScroll);

  const messagesWindowRef = useRef(null);

  const { subscribeGpt, sendGptEmit } = useContext(SocketContext);

  const {
    addNewMessage,
    changeChatMessages,
    chatMessages,
  } = useContext(GPTContext);

  const { requestGetChatByIdOnGPT } = useGptRequestsHook();

  const defaultGptPagination = {
    start: 0,
    end: 10,
    total: 0,
  };

  const [gptReply, setGptReply] = useState(null);
  const [gptStreamReply, setGptStreamReply] = useState('');
  const [gptPagination, setGptPagination] = useState(defaultGptPagination);

  const { start, end, total } = gptPagination;

  const scrollToDownFunc = () => {
    // console.log('scrollToDownFunc scrollToDownFunc', messagesWindowRef?.current?.scrollHeight);

    if (!stopAutoScroll) {
      messagesWindowRef?.current?.scrollIntoView(0, messagesWindowRef?.current?.scrollHeight);
    }
  };

  const addNewMessageAndChangePagination = (newMessage) => {
    addNewMessage(newMessage);
    setGptPagination((prev) => ({
      ...prev,
      end: end + 1,
    }));
    scrollToDownFunc();
  };

  const getMessagesForChat = (id, end, loading = true) => {
    const checkAndSetLoading = (param) => {
      if (loading) {
        setLoadingMessages(param);
      }
    };

    checkAndSetLoading(true);

    const data = {
      id,
      ...gptPagination,
      ...(end ? { end } : {}),
    };

    requestGetChatByIdOnGPT(data)
      .then((res) => {
        const { total_messages, messages } = res || {};

        changeChatMessages(messages);
        setGptPagination((prev) => ({
          ...prev,
          total: total_messages,
        }));
        checkAndSetLoading(false);
        focusOnMessageInput?.();
      })
      .catch(() => {
        checkAndSetLoading(false);
        // setWaitGptResponse(false);
      });
  };

  const showLoadMoreBtn = () => {
    setStopAutoScroll?.(true);

    const newEnd = end + defaultGptPagination.end;

    setGptPagination((prev) => ({
      ...prev,
      end: newEnd,
    }));

    getMessagesForChat(selectedChatID, newEnd, false);
  };

  const showLoadMoreMsg = total > end;

  const checkSocketError = (res, callback) => {
    // console.log('res res res', res)
    if (res?.error) {
      addNewMessageAndChangePagination({
        output_text: res?.error,
        received_at: dateGetStartOfFromNow('minute'),
        system: true,
        error: true,
      });
    } else {
      callback?.();
    }
  };

  const initFunc = () => {
    subscribeGpt('chatgpt_popup_completion_as_stream', (res) => {
      checkSocketError(res, () => {
        setGptStreamReply((prev) => prev + res?.completion);
      });
    });

    subscribeGpt('chatgpt_popup_completion', (res) => {
      // console.log('res res res', res);
      checkSocketError(res, () => {
        setGptReply(res);
        setGptStreamReply('');
      });
      gptReplyCallback(res);
    });
  };

  useEffect(() => {
    hljs?.highlightAll();
  }, [chatMessages.length]);

  useEffect(() => {
    initFunc();
  }, []);

  useEffect(() => {
    if (gptStreamReply.length !== 0) {
      scrollToDownFunc();
    }

    if (gptReply) {
      addNewMessageAndChangePagination(gptReply);
      setGptReply(null);
    }
  }, [gptStreamReply, gptReply]);

  useEffect(() => {
    if (selectedChatID) {
      getMessagesForChat(selectedChatID, defaultGptPagination.end);
    }
  }, [selectedChatID]);

  useEffect(() => {
    if (!loadingMessages) {
      scrollToDownFunc();
    }
  }, [selectedChatID, loadingMessages]);

  return (
    <>
      {loadingMessages ? <Spin />
        : (
          <div
            className={`w-full h-full --side-overflow chat-gpt-messages-container ${chatMessages.length === 0 ? 'chat-gpt-messages-container--center' : ''}`}
            ref={messagesWindowRef}
          >
            {showLoadMoreMsg && (
              <BaseButton
                type="link"
                onClick={showLoadMoreBtn}
              >
                Show previous messages
              </BaseButton>
            )}

            {chatMessages.length === 0 ? <Empty /> : (
              <>
                <RenderGptMessage
                  clearContextAndReloadChat={clearContextAndReloadChat}
                  chatMessages={chatMessages}
                  loadingMessages={loadingMessages}
                />
                {gptStreamReply?.length !== 0 && (
                  <Comment
                    avatar={<CircleActorAvatar54origins name="GPT" />}
                    content={(
                      <p>
                        {gptStreamReply}
                      </p>
                    )}
                  />
                )}
                <Row>
                  <Col span={24} align="middle">

                    {waitGptResponse && (
                      <>
                        Waiting for response...
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
        )}
    </>
  );
}

ChatMessages.propTypes = {
  clearContextAndReloadChat: PropTypes.any,
  waitGptResponse: PropTypes.any,
};

export default ChatMessages;

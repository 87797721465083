import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next/';
import { Modal } from 'antd';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

import { capitalize } from 'lodash';
import { colorStatus } from '../QaUtils';

export default function EndExecutionTestCaseBtn({
  callbackUpdateStateAndRequest,
  finishStatusCase,
}) {
  const { t } = useTranslation();

  const [finishExecutedModal, setFinishExecutedModal] = useState(false);

  const toggleModal = () => setFinishExecutedModal(!finishExecutedModal);

  const submitFunc = () => {
    callbackUpdateStateAndRequest();
    toggleModal();
  };

  return (
    <>
      <BaseButton
        className="btnDanger-outline"
        size="small"
        onClick={toggleModal}
      >
        <Icon
          path={mdiCheck}
          size={0.7}
          className="mr-1"
        />
        {capitalize(t('wms.buttons.finish_execution', 'finish execution'))}
      </BaseButton>

      <Modal
        centered
        open={finishExecutedModal}
        footer={false}
        onOk={submitFunc}
        onCancel={toggleModal}
      >
        <div>
          {finishExecutedModal
            && (
              <div>
                <h5>
                  {finishStatusCase === 'fail'
                    ? capitalize(t('wms.modals.body.finish_test_case', 'finish test case execution?'))
                    : capitalize(t('wms.modals.body.block_test_case', 'block test case execution?'))}
                </h5>
                <hr />
                <h6>
                  {capitalize(t('wms.modals.body.You_are_going', 'You are going to'))}

                  <span
                    style={{
                      margin: '0px 1px',
                      fontWeight: 'bold',
                      fontSize: '18px',
                      color: colorStatus[finishStatusCase],
                    }}
                  >
                    "
                    {capitalize(t(`wms.modals.body.${finishStatusCase}`, finishStatusCase))}
                    "
                  </span>

                  {t('wms.modals.body.this_test_ase_Are_you_sure', 'this test case. Are you sure?')}
                </h6>
              </div>
            )}

          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '15px',
          }}
          >
            <BaseButton
              className="ant-btn customAntOutGrinBtn ant-btn-default"
              onClick={toggleModal}
              style={{ padding: '6px 24px' }}
            >
              {capitalize(t('wms.buttons.remove.no', 'no'))}
            </BaseButton>

            <BaseButton
              className="ant-btn customAntOutDangerBtn ant-btn-default"
              onClick={submitFunc}
              style={{ padding: '6px 24px' }}
            >
              {capitalize(t('wms.buttons.remove.yes', 'yes'))}
            </BaseButton>
          </div>
        </div>
      </Modal>

    </>
  );
}
EndExecutionTestCaseBtn.propTypes = {

};

import keyMirror from 'keymirror';

export const EntityConstants = keyMirror({
  GET_CONFIG_REQUEST: null,
  GET_CONFIG_SUCCESS: null,
  GET_CONFIG_FAILURE: null,

  CLEAR_CONFIG: null,
  CLEAR_PARTITION: null,

  GET_AUTH_CONFIG_REQUEST: null,
  GET_AUTH_CONFIG_SUCCESS: null,
  GET_AUTH_CONFIG_FAILURE: null,

  FETCH_PARTITION_REQUEST: null,
  FETCH_PARTITION_SUCCESS: null,
  FETCH_PARTITION_FAILURE: null,

  FETCH_ENTITY_REQUEST: null,
  FETCH_ENTITY_SUCCESS: null,
  FETCH_ENTITY_FAILURE: null,

  // ACTOR FLOW
  FETCH_ACTORS_REQUEST: null,
  FETCH_ACTORS_SUCCESS: null,
  FETCH_ACTORS_FAILURE: null,

  FETCH_ACTORS_AND_BANED_USERS_SUCCESS: null,
  FETCH_PROJECT_ACTORS_SUCCESS: null,
  FETCH_TEMPORARY_ACTORS_SUCCESS: null,
  FETCH_PUBLIC_ACTORS_SUCCESS: null,

  FETCH_DEFAULT_ACTORS_SUCCESS: null,
  CLEAR_USERS_WITH_BANED_USERS: null,

  FETCH_ENTITY_PERMS_LIST_REQUEST: null,
  FETCH_ENTITY_PERMS_LIST_SUCCESS: null,
  FETCH_ENTITY_PERMS_LIST_FAILURE: null,

  FETCH_ENTITY_TREE_REQUEST: null,
  FETCH_ENTITY_TREE_SUCCESS: null,
  FETCH_ENTITY_TREE_FAILURE: null,

  FETCH_ACTOR_REQUEST: null,
  FETCH_ACTOR_SUCCESS: null,
  FETCH_ACTOR_FAILURE: null,

  FETCH_USER_INFO_REQUEST: null,
  FETCH_USER_INFO_SUCCESS: null,
  FETCH_USER_INFO_FAILURE: null,

  FETCH_ALL_PERMS_REQUEST: null,
  FETCH_ALL_PERMS_SUCCESS: null,
  FETCH_ALL_PERMS_FAILURE: null,

  FETCH_ENTITY_PERMS_ALL_ACTORS_REQUEST: null,
  FETCH_ENTITY_PERMS_ALL_ACTORS_SUCCESS: null,
  FETCH_ENTITY_PERMS_ALL_ACTORS_FAILURE: null,

  CREATE_ENTITY_PERM_REQUEST: null,
  CREATE_ENTITY_PERM_SUCCESS: null,
  CREATE_ENTITY_PERM_FAILURE: null,

  GET_HARD_DELETE_PERM_REQUEST: null,
  GET_HARD_DELETE_PERM_SUCCESS: null,
  GET_HARD_DELETE_PERM_FAILURE: null,

  HARD_DELETE_PERM_REQUEST: null,
  HARD_DELETE_PERM_SUCCESS: null,
  HARD_DELETE_PERM_FAILURE: null,

  CHECK_ENTITY_PERM_REQUEST: null,
  CHECK_ENTITY_PERM_SUCCESS: null,
  CHECK_ENTITY_PERM_FAILURE: null,

  GET_ENTITY_PERMS_REQUEST: null,
  GET_ENTITY_PERMS_SUCCESS: null,
  GET_ENTITY_PERMS_FAILURE: null,

  GET_ENTITY_PERM_REQUEST: null,
  GET_ENTITY_PERM_SUCCESS: null,
  GET_ENTITY_PERM_FAILURE: null,

  CREATE_ENTITY_PERMS_REQUEST: null,
  CREATE_ENTITY_PERMS_SUCCESS: null,
  CREATE_ENTITY_PERMS_FAILURE: null,
  CLEAR_ENTITY_PERMS: null,

  DELETE_ENTITY_PERMS_REQUEST: null,
  DELETE_ENTITY_PERMS_SUCCESS: null,
  DELETE_ENTITY_PERMS_FAILURE: null,

  GET_USERS_ENTITIES_REQUEST: null,
  GET_USERS_ENTITIES_SUCCESS: null,
  GET_USERS_ENTITIES_FAILURE: null,

  CLEAR_TEMPORARY_ACTORS: null,

  FILTER_SET_FILTERED_ENTITY: null,
  FILTER_RESET_TAGS: null,

  CLEAR_ACTORS_FETCHING: null,
  SET_FETCHING_FOR_ACTORS_LIST: null,

  VIEW_NOTIFICATION_TRUE: null,
  VIEW_NOTIFICATION_FALSE: null,

});

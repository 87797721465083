import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, Fragment, useMemo } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { isEmpty } from "ramda";
import { capitalize, flatMap, range } from "lodash";

import { Col, Row, Spin, Input, List, Button, Result, Divider } from "antd";

import BaseModal from "../../../../components/_ui/BaseModal/BaseModal";
import DocTypeLabel from "../DocTypeLabel";
import HistoryListItem from "./HistoryListItem";

import { getAllDocumentStatesRequest } from "../../../actions/DocumentsActions";
import { useQuill } from "../QuillEditor/useQuill/useQuill";


import "./DocHistoryComponent.scss";
import { capitalizeAndTranslateMsg } from "../../../../MainUtils";
import QuillEditorForHistory from "../QuillEditor/QuillEditorForHistory";


const RESTORE_VERSION = {
  SUCCESS: "RESTORE_VERSION_SUCCESS",
  FAIL: "RESTORE_VERSION_FAIL",
  NONE: "RESTORE_VERSION_NONE",
};

function DocHistoryComponent({
  currentActualState,
  defaultPartition,
  docUUID,
  entityType,
  // isProjectDescriptionDocument,
  title,
  reloadDocument,
  reloadDocumentCallback,
  setIsShowHistoryModal,
}) {
  const dispatch = useDispatch();

  const titleRef = useRef(null);

  const [selectedState, setSelectedState] = useState(currentActualState || null);

  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [statesData, setStatesData] = useState([]);
  const [statesList, setStatesList] = useState([]);

  const [showEditor, setShowEditor] = useState(false);
  const [currentDocumentContent, setCurrentDocumentContent] = useState(null);


  // console.log('currentDocumentContent', currentDocumentContent);

  const [isRestoreVersion, setIsRestoreVersion] = useState(false);
  const [restoreResult, setRestoreResult] = useState(RESTORE_VERSION.NONE);
  const [showRestoreResult, setShowRestoreResult] = useState(false);

  const [showTitleError, setShowTitleError] = useState(false);

  const { parseCrdtDocumentStructure } = useQuill();

  function getDocumentTypeText() {
    const typeMapping = {
      projectIdea: "note",
      projectSpreadsheet: "sheet",
      projectDocument: "document",
    };

    return typeMapping[entityType] || "document";
  }

  const documentTypeText = getDocumentTypeText();

  const loadingDocumentStates = async () => {
    setIsLoadingStates(true);

    const config = {
      uuid: docUUID,
      partition: defaultPartition,
    };

    const { states } = await dispatch(getAllDocumentStatesRequest(config));
    if (Array.isArray(states)) {
  // console.log("crdt STATES:", states);
      const finalStates = states.map((item, idx, arr) => {
        const { text, from_state_id } = item || {};
        const finalText = from_state_id ? arr?.[from_state_id]?.text : text;
        return { ...item, text: finalText, baseIdx: idx };
      });
      // console.log('crdt finalStates', finalStates)
      setStatesData(finalStates);
    }

    setIsLoadingStates(false);
  };

  const prepareListData = async (data) => {
    try {
      if (!Array.isArray(data)) {
        setStatesList([]);
        return;
      }

      const parsedData = data
        .map((item, idx) => {
          const { timestamp, text, from_state_id } = item || {};
          const finalText = from_state_id != null && from_state_id < data.length
            ? data[from_state_id]?.text
            : text;
          const finalTimestamp = timestamp ? new Date(timestamp * 1000) : null;

          return {
            version_id: `${idx + 1}`,
            timestamp: finalTimestamp,
            text: finalText || 'No text available',
          };
        })
        .reverse();

      setStatesList(parsedData);
    } catch (error) {
      // console.error("Error preparing list data:", error);
      setStatesList([]);
    }
  };

  const selectVersionHandler = (selectedVersion) => {
    // console.log('crdt selectedVersion', selectedVersion)
    // console.log('crdt statesData', statesData)
    setSelectedState(selectedVersion);
    setShowEditor(false);
  };

  function excludeRanges(ranges, array) {
    // Создаем массив всех индексов, которые должны быть исключены
    const excludedIndexes = flatMap(ranges, ([start, end]) => range(start, end + 1));

    // Фильтруем массив, исключая элементы по индексу
    return array.filter((_, idx) => !excludedIndexes.includes(idx));
  }

  const buildAndShowDocumentContent = async () => {


    // setShowEditor(false);

    if (!statesData?.length || !selectedState) {
      setCurrentDocumentContent(null);
      console.log('SET DOC 111');
      // setShowEditor(true);
      return;
    }

    try {
      const indexDB = Number(selectedState) - 1;

      if (indexDB < 0 || indexDB >= statesData.length) {
        // console.warn(`Invalid selectedState index: ${indexDB}`);
        setCurrentDocumentContent(null);
        console.log('SET DOC 222');
        // setShowEditor(true);
        return;
      }

      const currentStateData = statesData[indexDB];
      // console.log('currentStateData ***********', currentStateData);
      const { excluded_versions = [] } = currentStateData || {};
      // console.log('excluded_versions ***********', excluded_versions);
      const currentDeltasArr = statesData.slice(0, Number(selectedState));
      // console.log('currentDeltasArr ***********', currentDeltasArr);
      const deltasForMerge = excludeRanges(excluded_versions, currentDeltasArr);
      // console.log('deltasForMerge ***********', deltasForMerge);
      // console.log('crdt deltasForMerge', deltasForMerge);

      // Генерируем структуру документа
      const stateVector = parseCrdtDocumentStructure(deltasForMerge);
      // console.log('crdt stateVector', stateVector);
      // console.log('stateVector ***********', stateVector);
      setCurrentDocumentContent(stateVector);
      console.log('SET DOC 333');

    } catch (error) {
      console.error("Error building and showing document content:", error);
      setCurrentDocumentContent(null);
      console.log('SET DOC 444');
    } finally {
      // Отображаем редактор после всех вычислений
      // setShowEditor(true);
    }
  };

  const returnToDocument = () => {
    setIsShowHistoryModal(false);
  };

  const returnToDocumentWithReload = async () => {
    setIsShowHistoryModal(false);
    await reloadDocument();
    await reloadDocumentCallback?.();
  };

  const returnToHistory = () => {
    setRestoreResult(RESTORE_VERSION.NONE);
    setShowRestoreResult(false);
  };

  const initialFunction = async () => {
    await prepareListData(statesData);
    // buildAndShowDocumentContent();
  };

  useEffect(() => {
    loadingDocumentStates();
  }, []);

  useEffect(() => {
    if (!isEmpty(statesData)) {
      initialFunction();
    }
  }, [statesData]);

  useEffect(() => {
    if (!isLoadingStates && selectedState && statesData) {
      // console.log('EFFECT IF');
      buildAndShowDocumentContent();
    }
  }, [selectedState, isLoadingStates, statesData]);


  useEffect(() => {
    console.log('EFFECT 3333333333333333', currentDocumentContent);
    setShowEditor(true)
  }, [currentDocumentContent]);

  // console.log('showEditor', showEditor);

  const renderedItems = useMemo(() => {
    return statesList.map((item, idx) => {
      const prevItem = statesList[idx - 1];
      const prevDate = prevItem ? dayjs(prevItem.timestamp).format('DD/MM/YYYY') : '';
      const currentDate = dayjs(item.timestamp).format('DD/MM/YYYY');
      const checkIsNewDate = prevDate !== currentDate;

      return {
        item,
        idx,
        prevDate,
        currentDate,
        checkIsNewDate,
      };
    });
  }, [statesList]);

  return (
    <BaseModal
      title={capitalize("Document history")}
      width={showRestoreResult ? 600 : "80%"}
      centered
      open
      handleCancel={() => setIsShowHistoryModal(false)}
    >
      {!showRestoreResult && (
        <Spin spinning={isLoadingStates || isRestoreVersion} size="large">
          <Row
            id="history-doc-wrapper"
            style={{ columnGap: "24px" }}
            className="flex-nowrap"
          >
            <Col
              style={{ rowGap: "16px", width: 'calc(75% - 24px)' }}
              className="flex flex-col p-0"
            >
              <Row gutter={[12, 12]}>
                <Col className="flex items-start justify-start flex-shrink-0">
                  <DocTypeLabel entityDocType={entityType} />
                </Col>
                <Col className="flex-auto">
                  <Input
                    ref={titleRef}
                    maxLength={100}
                    showCount
                    disabled={true}
                    name="title"
                    status={showTitleError && "error"}
                    placeholder={
                      showTitleError ? capitalizeAndTranslateMsg(
                        "wms.placeholders.title_error",
                        "Title can't be empty!"
                      ) : capitalizeAndTranslateMsg("wms.placeholders.title", "title")
                    }
                    defaultValue={title}
                  />
                </Col>
              </Row>

              <Row className="flex-auto overflow-hidden">
                <Spin spinning={!showEditor} wrapperClassName="editor-spin">
                  <Col id="history-editor-wrapper">
                    {showEditor ? (
                      <>
                        <QuillEditorForHistory defaultDocContetnt={currentDocumentContent}/>
                      </>
                    ) : null}
                  </Col>
                </Spin>
              </Row>

              <Row>
                <Col>
                  <div>{`Version: ${selectedState}`}</div>
                </Col>
              </Row>
            </Col>
            <Col
              style={{ width: '25%' }}
              className="flex flex-shrink-0"
            >

              <List
                className="w-full grow versions-list"
                header={<h6 className="text-center">Version history:</h6>}
                dataSource={renderedItems}
                bordered
                renderItem={({ item, idx, checkIsNewDate, currentDate }) => (
                  <React.Fragment key={`versions_list_${idx}_${item.timestamp}`}>
                    {checkIsNewDate && (
                      <Divider
                        style={{
                          margin: '4px 0',
                          padding: '0 8px',
                          color: 'rgba(0, 0, 0, 0.45)',
                          borderColor: 'rgba(0, 0, 0, 0.45)',
                        }}
                      >
                        {currentDate}
                      </Divider>
                    )}
                    <HistoryListItem
                      item={item}
                      selectedState={selectedState}
                      currentActualState={currentActualState}
                      selectVersionHandler={selectVersionHandler}
                    />
                  </React.Fragment>
                )}
              />
            </Col>
          </Row>
        </Spin>
      )}

      {showRestoreResult && (
        <>
          {restoreResult === RESTORE_VERSION.SUCCESS && (
            <Result
              status="success"
              title="Success!"
              subTitle={`${capitalize(
                documentTypeText
              )} was successfully restored to version ${selectedState}`}
              extra={
                <Button
                  className="btnBlue"
                  onClick={returnToDocumentWithReload}
                >
                  Return to
                  {` ${documentTypeText}`}
                </Button>
              }
            />
          )}
          {restoreResult === RESTORE_VERSION.FAIL && (
            <Result
              status="error"
              title="Error!"
              subTitle={`Something went wrong while restoring the ${documentTypeText}`}
              extra={[
                <Button onClick={returnToHistory} key={'return_to_history'}>Return to history</Button>,
                <Button className="btnBlue" onClick={returnToDocument} key={`return_to_${documentTypeText}`}>
                  Return to
                  {` ${documentTypeText}`}
                </Button>,
              ]}
            />
          )}
        </>
      )}
    </BaseModal>
  );
}

DocHistoryComponent.propTypes = {
  currentActualState: PropTypes.string,
  defaultPartition: PropTypes.string,
  docUUID: PropTypes.string,
  entityType: PropTypes.string,
  isProjectDescriptionDocument: PropTypes.bool,
  title: PropTypes.string,
  reloadDocument: PropTypes.func,
  reloadDocumentCallback: PropTypes.func,
  setIsShowHistoryModal: PropTypes.func,
};

export default DocHistoryComponent;

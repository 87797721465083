import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getListOfTestCyclesFunc } from '../../projectFlow/actions/QaActions';
import { setQaActivityCurrentProject, setQaActivityProjectsArr } from '../../projectFlow/reducers/slicers/projectAllScoringSlicer';
import { updateProjectScoring } from '../../projectFlow/actions/projectFlowActions';

export default function useQaActivityScoring() {
  const dispatch = useDispatch();

  const scoreTitle = 'Qa (act)';

  const updateProject = async (props) => {
    const {
      projectUuid, projectPartition,
      qaActivityScore,
    } = props;
    const data = {
      uuid: projectUuid,
      params: {
        qaActivityScoring: {
          updated: moment(),
          qaActivityScore,
        },
      },
      partition: projectPartition,
    };
    return dispatch(updateProjectScoring(data));
  };

  const getAllCycles = async (parent, partition) => {
    const data = await dispatch(getListOfTestCyclesFunc(
      { parent, partition, params: { archive: false } },
    ));
    return data;
  };

  const computeCycleScore = (cycle) => {
    // const finishStatuses = ['fail', 'pass'];
    // const inProgressStatuses = ['progress', 'block'];
    const casesCount = cycle?.cases_count;
    const progressData = cycle?.progress;
    const isFinished = (progressData?.fail + progressData?.pass === casesCount) && casesCount;
    if (isFinished) {
      // console.log(`proj ${title}, cycle ${cycle?.title} >>>>>>>>>>>>>`, 5);
      return 5;
    }
    const isProgress = progressData?.block || progressData?.progress;
    if (isProgress) {
      // console.log(`proj ${title}, cycle ${cycle?.title} >>>>>>>>>>>>>`, 3);
      return 3;
    }
    // console.log(`proj ${title}, cycle ${cycle?.title} >>>>>>>>>>>>>`, -1);
    return -1;
  };

  const computeQaActivityScore = async (
    projectUuid,
    partitionType,
    isNeedProject,
    isAllScoring,
  ) => {
    try {
      const resp = await getAllCycles(projectUuid, partitionType);
      const data = resp?.data;

      const qaActivityScore = data.reduce((accumulator, item) => accumulator + computeCycleScore(item), 0);
      const finalData = { uuid: projectUuid, qaActivityScore, updated: moment() };
      // console.log('computeQaActivityScore finalData', finalData);
      if (!isAllScoring) {
        const res = await updateProject({
          qaActivityScore,
          projectUuid,
          projectPartition: partitionType,
        });

        if (isNeedProject) {
          return res;
        }
      }
      return finalData;
      dispatch(setQaActivityCurrentProject(finalData));

      // console.log('computeQaStructureScore', finalData);
    } catch (e) {
      console.log('error computeQaActivityScore', e);
    }
  };

  const computeAllProjectsQaActivityScore = async (projectArray, isAllScoring) => {
    const projectsQaActivityData = await Promise.all(projectArray.map(async (project) => {
      const { uuid, partition } = project;
      const qaScoringData = await computeQaActivityScore(uuid, partition, false, isAllScoring);
      return qaScoringData;
    }));
    // console.log('projectsQaActivityData', projectsQaActivityData);
    dispatch(setQaActivityProjectsArr(projectsQaActivityData));
    const finalProjectsData = projectArray.map((item) => {
      const qaActivityScore = projectsQaActivityData.find((project) => project?.uuid === item?.uuid);
      // console.log('finalProjectsData qaStructureScore', qaStructureScore);
      return {
        ...item,
        qaActivityScoring: qaActivityScore,
      };
    });
    return finalProjectsData;
  };

  const handleQaActivityBackground = (score, isButtonColor) => {
    if (score <= 0) {
      return !isButtonColor ? 'error' : 'scoringBtnDanger';
    }
    if (score < 10) {
      return !isButtonColor ? 'warning' : 'scoringBtnWarning';
    }
    if (score >= 10) {
      return !isButtonColor ? 'success' : 'scoringBtnGreen';
    }
    return !isButtonColor ? '' : 'scoringBtnDefault';
  };

  const qaActivityTooltipText = (
    <>
      <div>
        <strong>
          QA activity score
        </strong>
        {' '}
        - an index reflecting the level of efficiency of using test cases and test cycles in the project.
      </div>
      <div>
        <strong>Threshold values: </strong>
        it&apos;s recommended to pay attention when the value is less than 10.
      </div>
    </>
  );

  return {
    qaActivityTitle: scoreTitle, qaActivityTooltipText, computeAllProjectsQaActivityScore, computeQaActivityScore, handleQaActivityBackground,
  };
}

// ------------------ old logic --------------------
// const getAllBugs = async (parent, partition) => {
//   const config = {
//     partition,
//     parent,
//     depth: 2,
//     created__gte: moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm'),
//     params: {
//       tracker: 'bug',
//     },
//     constants: [
//       ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_REQUEST,
//       ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_SUCCESS,
//       ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_FAILURE,
//     ],
//     params_fields: {
//       title: 'title',
//       priority: 'priority',
//       status: 'status',
//       firstActivityTimestamp: 'firstActivityTimestamp',
//     },
//   };
//   return dispatch(getListOfIssues(config));
// };
// const getAllBugs = async (parent, partition) => {
//   const config = {
//     partition,
//     parent,
//     depth: 2,
//     created__gte: moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm'),
//     params: {
//       tracker: 'bug',
//     },
//     constants: [
//       ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_REQUEST,
//       ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_SUCCESS,
//       ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_FAILURE,
//     ],
//     params_fields: {
//       title: 'title',
//       priority: 'priority',
//       status: 'status',
//       firstActivityTimestamp: 'firstActivityTimestamp',
//     },
//   };
//   return dispatch(getListOfIssues(config));
// };

// const computeQaActivityScore = async (projectUuid, partitionType, title) => {
//   try {
//     const resp = await getAllBugs(projectUuid, partitionType);
//     const qaActivityScore = resp?.total;
//     return { uuid: projectUuid, qaActivityScore };
//   } catch (e) {
//     console.log('error handleOnProjectQaData', e);
//   }
// };

// const computeAllProjectsQaActivityScore = async (projectArray) => {
//   const projectsQaStructureData = await Promise.all(projectArray.map(async (project) => {
//     const { uuid, partition, title } = project;
//     const qaScoringData = await computeQaActivityScore(uuid, partition, title);
//     return qaScoringData;
//   }));

//   const finalProjectsData = projectArray.map((defProject) => {
//     const qaActivityScore = projectsQaStructureData.find((project) => project?.uuid === defProject?.uuid)?.qaActivityScore;
//     // console.log('finalProjectsData qaStructureScore', qaStructureScore);
//     return { ...defProject, qaActivityScore };
//   });
//   return finalProjectsData;
// };

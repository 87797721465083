import {
  mdiAccount,
  mdiAccountGroup,
  mdiChevronRight,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Card, Col, Row, Tooltip, Button, Tag, Badge, Space, Flex
} from 'antd';
import { get, uniq, capitalize } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  isNil, isEmpty, prop, propOr,
} from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IStickyBox from 'react-sticky-box';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import BaseTable from '../../../components/_ui/BaseTable/BaseTable';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import InfoIcon from './projectsAudit/infoIcon/InfoIcon';
import CheckMainAreaContainer from '../../containers/areas/CheckMainAreaContainer';
import HashtagsList from '../commonComponents/HashtagsList';
import WrapperAvatarsGroupForProjectWithHook from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import AddProjectBtn from './AddProjectBtn';
import ProjectFilter from './ProjectFilter';
import ProjectFilterTags from './ProjectFilterTags';

// import colums config
import { columnsFilterConfig } from './utils/projectFlowFilterConfig';
import { defaultFilterConfig } from './utils/projectFlowFilterConfig';

import WrapperAddToFavorite from './WrapperAddToFavorite';

import './css/ListOfProjectsForProjectFlowMedia.scss';

import { globalDataNotSaved } from '../../../components/un-save-data-confirm/unSaveDataSlicer';
import useURLParams from '../../../hooks/useURLParams';
import { getMyInfoAndListOfActors } from '../../../userFlow/store/action-creators/userActions';
import { getUserPublicProfileUUID } from '../../../userFlow/store/selectors/selectors';
import { getAreas } from '../../selectors/selectors';
import {
  getProjectsIssuesScoringFetching,
  getProjectsIssuesScoringLastComputeTime,
} from '../../reducers/slicers/projectIssueScoringSlicer';
import {
  selectProjectsKPMComputing,
  selectProjectsKPMComputedTime,
} from '../../reducers/slicers/projectsKPMSlice';

import { useResize } from '../../../hooks/useResize';
import useDevScore from '../../../hooks/projectAuditHooks/useDevScore';

import {
  capitalizeAndTranslateMsg,
  filterUsersAndGetUuidsWithRole,
} from '../../../MainUtils';
import { PROJECT_TABS } from './projectView/projectTabs';
import { kpmInfo } from '../milestones/helpers/milestoneUtils';
import { projectScoringBackground } from '../issuesView/utils';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { sortNumValues } from './projectsAudit/utils';
import { parsePlanScoreColor } from './projectInfo/utils/scoringUtils';
import WrapperAvatarsGroupForProject from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';
import useActorsW54 from '../../../actors/hooks/useActorsW54';
import { useLocation } from 'react-router-dom';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import BaseTag from "../../../components/_ui/BaseTag/BaseTag";
import CustomAvatar from "../../../components/CustomAvatar/CustomAvatar";
import { partitionNamesConfig } from '../../../api/appConfig';

const defaultPaginationOptions = {
  currentPage: 1,
  pageLimit: 100,
  defaultPageLimit: 100,
  pageLimitList: [50, 100],
};

function ListOfProjectsForProjectFlow({
  totalCount,
  perms,
  partitionUUID,
  allData,
  partition,
  hideAddProjectBtn,
  hideAreaAndFilter = false,
  initialPathForHistory = '/pm/projects/',
  loadingIndicator,
  searchTerm,
  selectedAreasTags,
  selectedFilterTags,
  changeProjectsData,
  onComputeKPM,
  onComputeKPI,
  onSearchProjectCallback,
  onSelectTagsCallback,
  onSelectFiltersCallback,
  onShowAllFilter,
  onResetFilter,
  changeSortCallback,
  newProjectCallback,
  newProjectCreatedCallback,
  quickViewProjectCallback,
  setNewProjectCreatedCallback,
  selectProjectCallback,
  locationDashboard
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    routeNavigateTo, navigate, currentLocationPathname, getURLParams,
  } = useURLParams();

  const { activeProject } = getURLParams();

  const location = useLocation();

  const GlobalDataNotSaved = useSelector(globalDataNotSaved);
  const allAreas = useSelector(getAreas);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const computationKPIloading = useSelector(getProjectsIssuesScoringFetching);
  const lastIssuesScoringComputeTime = useSelector(getProjectsIssuesScoringLastComputeTime);
  // const projectsIssuesScoringData = useSelector(getProjectsIssuesScoringData);

  const kpmComputing = useSelector(selectProjectsKPMComputing);
  const kpmComputedTime = useSelector(selectProjectsKPMComputedTime);

  const isPmPartition = partition === partitionNamesConfig.partition1;

  const { width } = useResize();
  const {
    devTooltipText,
    handleDevBackground,
  } = useDevScore();

  const [showAreas, setShowAreas] = useState(false);
  const [customResetTagFromCollapse, setCustomResetTagFromCollapse] = useState(null);
  // const [currentSort, setCurrentSort] = useState('');

  const [paginationOptions, setPaginationOptions] = useState(() => defaultPaginationOptions);

  ///// add fliters
  const [defaultFilters, setDefaultFilters] = useState(defaultFilterConfig)
  const [selectedFilters, setSelectedFilters] = useState(null)

  const addFilters = (filters) => {
    const userFilters = []
    const statusFilters = []
    let partitionFilters = []
    let metricFilters = []
       if (filters.users) {
          filters.users.forEach((el) =>
            userFilters.push({key: 'user', value: el, label: `wms.filters.project.${el}`, keySection: 'user-filter'}))
       }
       if(filters.partition) {
        filters.partition.forEach((el) =>
          partitionFilters.push({key: 'partition', value: el, label: `wms.filters.project.${el}`, keySection: 'partition-filter'}))
       }
       if((filters.partition && filters.partition.length > 1 || !filters.partition) && locationDashboard !== 'dashboard' ) {
        partitionFilters = [{key: 'partition', value: 'all-partition', label: `wms.filters.project.all-partition`, keySection: 'partition-filter'}]
       }
       if(filters.status) {
        filters.status.forEach((el) =>
          statusFilters.push({key: 'status', value: el, label: `wms.filters.project.${el}`, keySection: 'status-filter'}))
        }
       if(filters.metric) {
        filters.metric.forEach((el) =>
          metricFilters.push({key: 'metric', value: el, label: `wms.filters.project.${el}`, keySection: 'metric-filter'}))
     }
       setSelectedFilters([
        ...userFilters,
        ...partitionFilters,
        ...statusFilters,
        ...metricFilters]);
       setDefaultFilters(filters)
  }

  const showAllFiltersWrapper = () => {
    setDefaultFilters({partition: null, status: null, metric: null, users: null})
     onShowAllFilter()
   }

   const resetToDefaultsWrapper = () => {
     setDefaultFilters(defaultFilterConfig)
     onResetFilter()
   }

  const {
    currentPage,
    pageLimit,
    pageLimitList,
    defaultPageLimit,
  } = paginationOptions || {};

  const dataTable = useMemo(
    () => allData
      ?.slice((currentPage - 1) * pageLimit, currentPage * pageLimit),
    [currentPage, pageLimit, JSON.stringify(allData)],
  );

  // Получение проектных пользователей
  const userUUIDs = dataTable?.flatMap((item) => item?.users);
  useActorsW54({ actors: uniq(userUUIDs) });

  const changePagination = useCallback((page) => {
    setPaginationOptions((prev) => ({
      ...prev,
      currentPage: page,
    }));
  }, []);

  const onShowSizeChange = (current, size) => {
    setPaginationOptions((prev) => ({
      ...prev,
      currentPage: 1,
      pageLimit: size,
    }));
  };

  const resetPagination = useCallback(() => {
    setPaginationOptions((prev) => ({
      ...prev,
      currentPage: 1,
    }));
  }, []);

  const onSelectFilters = (tags) => {

    onSelectFiltersCallback?.(tags);
    resetPagination?.();
  };

  const onSelectTags = (tags) => {
    onSelectTagsCallback?.(tags);
    resetPagination?.();
  };

  // const onSearchProject = (value) => {
  //   if (value) {
  //     setValueSearch(value);
  //   } else {
  //     setValueSearch('');
  //   }

  //   if (onSearchProjectCallback) {
  //     onSearchProjectCallback(value);
  //   }
  // };

  const parseDevScoringData = (projectScoringData) => {
    const finalData = {
      total: projectScoringData?.totalCount || 0,
      avgVal: projectScoringData?.issuesScoringAVG || 0,
      sum: projectScoringData?.indexesSum || 0,
      background: projectScoringBackground(projectScoringData?.issuesScoringAVG),
    };
    return finalData;
  };

  const onRow = (record) => ({
    onClick: () => {
      selectProjectCallback?.();

      const navigateTo = {};
      const isWeeklyPlanning = record?.type?.toUpperCase() === 'ACTIVITIES_USER' && record?.status === 'systemic';

      if (isWeeklyPlanning) {
        navigateTo.tabname = 'issuesnew';
        navigateTo.searchParams = { activeProject: record?.uuid };
      } else {
        // navigateTo.tabname = 'project';
        navigateTo.tabname = 'default';
        navigateTo.searchParams = {
          activeProject: record?.uuid,
          tab: PROJECT_TABS.INFO,
        };
      }
      // console.log('onRow project', record);
      if (record?.scoringData) {
        dispatch({
          type: ProjectTypeCommonConstants.UPDATE_ISSUES_PRIORITY_INDEXES_DATA,
          payload: parseDevScoringData(record?.scoringData),
        });
      }
      if (record?.partition === partitionNamesConfig.partition1) {
        routeNavigateTo(
          `${initialPathForHistory}${navigateTo.tabname}`,
          { ...navigateTo.searchParams },
          { state: { from: location } },
        );
      } else if (record?.partition === partitionNamesConfig.partition3) {
        const searchParams = {
          activeProject: record?.uuid,
          tab: PROJECT_TABS.INFO,
        };
        routeNavigateTo(
          `/public/user/${myPublicUUID}/projects/default`,
          searchParams,
          { state: { from: location } }
        );
      }
    },
    onMouseDown: (e) => {
      if (e.button === 1) {
        const navigateTo = {};
        const isWeeklyPlanning = record?.type?.toUpperCase() === 'ACTIVITIES_USER' && record?.status === 'systemic';

        if (isWeeklyPlanning) {
          navigateTo.tabname = 'issuesnew';
          navigateTo.searchParams = { activeProject: record?.uuid };
        } else {
          navigateTo.tabname = 'project';
          navigateTo.searchParams = {
            activeProject: record?.uuid,
            tab: PROJECT_TABS.INFO,
          };
        }
        if (record?.partition === partitionNamesConfig.partition1) {
          window.open(`${initialPathForHistory}${navigateTo.tabname}?${Object.entries(navigateTo.searchParams).map(([key, value]) => `${key}=${value}`).join('&')}`);
        } else if (record?.partition === partitionNamesConfig.partition3) {
          const searchParams = {
            activeProject: record?.uuid,
            tab: PROJECT_TABS.INFO,
          };
          window.open(`/public/user/${myPublicUUID}/projects/project?${Object.entries(searchParams).map(([key, value]) => `${key}=${value}`).join('&')}`);
        }
      }
    },
  });

  const onHeaderRow = (columns, index) => ({
    onClick: () => {
      console.log('columns', columns);
    },
  });

  const quickViewFunc = (e, cell, customPartition) => {
    e.stopPropagation();

    if (GlobalDataNotSaved) {
      navigate('/data-not-saved', {
        state: {
          path: currentLocationPathname,
          search: {
            activeProject: cell,
          },
        },
      });
    } else {
      quickViewProjectCallback?.(cell || '', customPartition);
    }
  };

  // Server sorting
  // const changeSort = (name, sortOrder) => {
  //   const typeSort = sortOrder === 'ascend' ? 'asc' : 'desc';
  //   const isParamSorted = name !== 'owner_uinfo';
  //   const orderName = name === 'owner_uinfo' ? 'owner' : name;

  //   setCurrentSort({
  //     orderBy: orderName,
  //     order: typeSort,
  //     isParamSorted,
  //   });
  // };

  const sortKPI = (scoringData1, scoringData2) => {
    const val1 = scoringData1?.issuesScoringAVG;
    const val2 = scoringData2?.issuesScoringAVG;
    const val1Num = !isNil(val1) && val1 ? val1 : 0;
    const val2Num = !isNil(val2) && val2 ? val2 : 0;
    return val1Num > val2Num ? 1 : -1;
  };

  // Local sorting
  const changeSort = (a, b, paramName, sortOrder) => {

    switch (paramName) {
      case 'id':
      case 'kpi':
        return sortNumValues(
          a?.issuesDevScore?.issuesScoringAVG,
          b?.issuesDevScore?.issuesScoringAVG,
        );
      case 'kpm':
        return sortNumValues(
          a?.gmPlanScore?.planScoreValue,
          b?.gmPlanScore?.planScoreValue,
        );
      // case 'kpm': return sortNumValues(a?.[paramName], b?.[paramName]);
      // case 'kpi':
      //   return sortKPI(a?.scoringData, b?.scoringData);
      case 'title':
      case 'status':
      case 'partition': return a?.[paramName].toLowerCase().localeCompare(b?.[paramName].toLowerCase());
      case 'created': return moment(a?.[paramName]).unix() - moment(b?.[paramName]).unix();
      case 'isFavorite': return a?.[paramName] > b?.[paramName] ? -1 : 1;
      default:
    }
    return 0;
  };

  const searchInTitle = (
    <AntDesignSearchBox54origins
      valueSearch={searchTerm}
      onSearch={onSearchProjectCallback}
      isFocus={isPmPartition}
    />
  );

  const userFormatter = (cell) => {
    const fullName = `${prop('first_name', cell)} ${prop('last_name', cell)}`;
    return (
      <div className="flex flex-wrap items-center">
        <CustomAvatar
          fullName={fullName}
        />
      </div>
    );
  };

  const onChangeFavoriteCallback = (action, projectUUID) => {
    let updatedData;
    switch (action) {
      case 'add': {
        updatedData = allData
          .map((project) => (project?.uuid === projectUUID
            ? { ...project, isFavorite: true }
            : project
          ));
        break;
      }
      case 'delete': {
        updatedData = allData
          .map((project) => (project?.uuid === projectUUID
            ? { ...project, isFavorite: false }
            : project
          ));
        break;
      }
      default: return;
    }

    // console.log('!!! updatedData:', updatedData);
    changeProjectsData(updatedData);
  };

  const columns = [
    {
      dataIndex: 'uuid',
      hide: hideAddProjectBtn,
      title: '',
      render: (cell, row) => (
        <BaseButton
          id={'qViewButton' + cell}
          className="btnPrimary-outline"
          size="small"
          disabled={get(row, 'removed')}
          onClick={(e) => quickViewFunc(e, cell, row?.partition)}
        >
          <Tooltip
            placement="left"
            title={capitalizeAndTranslateMsg('wms.buttons.quick_view', 'Q-view')}
          >
            {capitalizeAndTranslateMsg('wms.buttons.quick_v', 'Q-view')}
          </Tooltip>
          <Icon path={mdiChevronRight} size={0.75} className="ml-1" />
        </BaseButton>
      ),
    },
    {
      dataIndex: 'id',
      title: 'ID',
      className: 'text-nowrap',
      sorter: (a, b, sortOrder) => changeSort(a, b, 'id', sortOrder),
      render: (cell) => cell && (`P${cell}`),
    },
    {
      dataIndex: 'title',
      title: searchInTitle,
      sorter: (a, b, sortOrder) => changeSort(a, b, 'title', sortOrder),
      render: (cell, row) => {
        const projectTags = propOr([], 'areas', row);
        const tooltip = propOr('', 'uuid', row);
        const filteredTags = projectTags?.filter((tag) => allAreas?.some((el) => el?.name === tag));
        return (
          <>
            {cell && (
              <EntityParamTag
                param="title"
                value={cell}
                type="value"
                tooltip={tooltip}
                maxLength={100}
              />
            )}
            <HashtagsList
              tags={filteredTags}
              maxLength={60}
            />
          </>
        );
      },
    },
    {
      dataIndex: 'kpm',
      title: <InfoIcon title="Plan" tooltipText={kpmInfo} />,
      // ...(kpmComputedTime && { sorter: (a, b, sortOrder) => changeSort(a, b, 'kpm', sortOrder) }),
      sorter: (a, b, sortOrder) => changeSort(a, b, 'kpm', sortOrder),
      render: (cell, row) => {
        const defaultVal = row?.gmPlanScore?.planScoreValue;
        const finalKpm = defaultVal >= 0 && !isNil(defaultVal) ? defaultVal : '-';
        const btnBackgroundClassname = parsePlanScoreColor(finalKpm);
        return (
          <BaseTag
            className="ml-1 text-sm"
            style={{ fontWeight: '500' }}
            color={btnBackgroundClassname}
          >
            {finalKpm}
          </BaseTag>
        );
        // return renderMilestoneScore(kpm);
      },
    },
    {
      dataIndex: 'kpi',
      title: <InfoIcon title="Dev" tooltipText={devTooltipText} />,
      sorter: (a, b, sortOrder) => changeSort(a, b, 'kpi', sortOrder),
      // ...(lastIssuesScoringComputeTime && { sorter: (a, b, sortOrder) => changeSort(a, b, 'kpi', sortOrder) }),
      render: (cell, row) => {
        const defaultVal = row?.issuesDevScore?.issuesScoringAVG;
        // console.log('row?.scoringData', row?.scoringData);
        const finalDefaultVal = typeof defaultVal === 'number' || defaultVal === null ? (defaultVal && Number(defaultVal).toFixed(2)) || 0 : '-';
        return (
          <BaseTag
            className="ml-1 text-sm"
            style={{ fontWeight: '500' }}
            color={handleDevBackground(finalDefaultVal)}
          >
            {finalDefaultVal}
          </BaseTag>
        );
      },
    },
    // {
    //   dataIndex: 'outdatedScoring',
    //   title: 'Outdated score',
    // },
    {
      dataIndex: 'status',
      title: capitalizeAndTranslateMsg('wms.table.headers.status', 'Status'),
      sorter: true,
      filteredValue: defaultFilters.status,
      defaultFilteredValue: defaultFilters.status,
      ...columnsFilterConfig.status,
      onFilter: (value, record) => value === record.status,
      render: (cell) =>
        cell
        && (
          <EntityParamTag
            param="status"
            value={cell === 'archive'
              ? 'archived'
              : cell}
            type="iconWithStatus"
          />
        )
    },
    // {
    //   dataIndex: 'owner_uinfo',
    //   title: capitalizeAndTranslateMsg('wms.table.headers.owner', 'owner'),
    //   render: userFormatter,
    // },
    {
      dataIndex: 'users',
      title: capitalizeAndTranslateMsg('wms.table.headers.members', 'members'),
      key: 'users',
      sorter: true,
      filteredValue: defaultFilters.users,
      defaultFilteredValue: defaultFilters.users,
      ...columnsFilterConfig.users,
        onFilter: (record) => record.actor === record.owner,
      render: (cell, row) => {
        // console.log('cell', cell);
        const actorsWithRoles = cell;
        return actorsWithRoles?.length > 0 && (
          <WrapperAvatarsGroupForProject
            actors={actorsWithRoles}
            avatarSize="small"
            avatarGap={4}
            avatarStyles={{ cursor: 'pointer' }}
            groupButtonSize="small"
          />
        );
      },
    },
    {
      dataIndex: 'metric',
      title: capitalizeAndTranslateMsg('wms.table.headers.metric', 'metric'),
      key: 'metric',
      sorter: true,
      ...columnsFilterConfig.metric,
      filteredValue: defaultFilters.metric,
      defaultFilteredValue: defaultFilters.metric,
        onFilter: (record) => record,
        render: (cell) => cell
        && (
          <EntityParamTag
            param="metric"
            value={cell}
            type="icon"
          />
        )
    },
    {
      dataIndex: 'partition',
      key: 'partition',
      // width: 24,
      title: capitalizeAndTranslateMsg('general', 'type'),
      // ...(isPmPartition && { sorter: (a, b, sortOrder) => changeSort(a, b, 'partition', sortOrder) }),
      ...(locationDashboard !== 'dashboard' && {sorter: true,
      filteredValue: locationDashboard !== 'dashboard' ?  defaultFilters.partition : ['personal'],
      defaultFilteredValue: locationDashboard !== 'dashboard' ?  defaultFilters.partition : ['personal'],
      ...columnsFilterConfig.type,
      onFilter: (value, record) =>  value === 'personal' ? record.partition === partitionNamesConfig.partition3 : record.partition === partitionNamesConfig.partition1}),
      render: (cell) => {
        // eslint-disable-next-line no-shadow
        const isPmPartition = cell === partitionNamesConfig.partition1;
        const title = isPmPartition ? 'General project' : 'Personal project';
        const icon = isPmPartition ? mdiAccountGroup : mdiAccount;
        const iconColor = isPmPartition ? '#72b153' : '#60a1eb';
        return (
          <Tooltip title={title}>
            <Icon path={icon} size={1} color={iconColor} />
          </Tooltip>
        );
      },
    },
    {
      dataIndex: 'created',
      // width: 120,
      className: 'text-nowrap',
      title: capitalizeAndTranslateMsg('wms.table.headers.created', 'Created'),
      render: (cell) => (
        moment(cell).format('DD-MM-YYYY')
      ),
      hide: width < 1500,
      sorter: (a, b, sortOrder) => changeSort(a, b, 'created', sortOrder),
    },
    {
      dataIndex: 'uuid',
      // width: 20,
      title: capitalizeAndTranslateMsg('wms.table.headers.favorites', 'Favorites'),
      defaultSortOrder: 'ascend',
      sorter: (a, b, sortOrder) => changeSort(a, b, 'isFavorite', sortOrder),
      render: (cell, row) => {
        const { partition: projectPartition } = row;
        return (
          <WrapperAddToFavorite
            entityUUID={cell}
            entityType="project"
            entityPartition={projectPartition}
            partitionType={partition}
            onChangeFavoriteCallback={onChangeFavoriteCallback}
          />
        );
      },
    },
  ].filter((column) => !column.hide);

  const rowClassName = (row) => `cursor-pointer universal_table_row ${prop('uuid', row) === activeProject ? 'selected' : ''}`;

  const {
    create: createPerm,
    set: setPerm,
  } = perms || {};

  useEffect(() => {
    const noGetBanedUsers = false;
    dispatch(getMyInfoAndListOfActors(noGetBanedUsers));
  }, []);

  useEffect(() => {
    if (!isNil(customResetTagFromCollapse)) {
      setCustomResetTagFromCollapse('');
    }
  }, [customResetTagFromCollapse]);

  // useEffect(() => {
  //   if (currentSort) {
  //     changeSortCallback(currentSort);
  //   }
  // }, [JSON.stringify(currentSort)]);

  return (
    <Flex vertical className="projects-block" gutter={[12, 12]}>
       <Col className="projects-filters" span={24}>
        <IStickyBox className="projects-filters-inner" offsetTop={80} offsetBottom={80}>
          {/* <BaseCard className="projects-filters-item collapsedTable blue with-text small">
            <Col span={24} className="flex items-start">
              <CheckMainAreaContainer showSection>
                {!hideAreaAndFilter && (
                  <ProjectFilter
                    className=""
                    location="projects"
                    partition={partition}
                    loading={loadingIndicator}
                    searchTerm={searchTerm}
                    selectedTags={selectedFilterTags}
                    onSearch={onSearchProjectCallback}
                    onSelectTags={onSelectFilters}
                    onResetFilter={onResetFilter}
                    onShowAllFilter={onShowAllFilter}
                    setNewProjectCreatedCallback={setNewProjectCreatedCallback}
                    newProjectCreatedCallback={newProjectCreatedCallback}
                  // showMoreCallback={() => setShowAreas(!showAreas)}
                  // customResetTagFromCollapse={customResetTagFromCollapse}
                  />
                )}
              </CheckMainAreaContainer>
            </Col>
          </BaseCard> */}

          <BaseCard className="projects-filters-item collapsedTable blue with-text small">
            <Col span={24} className="flex items-start">
              <CheckMainAreaContainer showSection>
                {!hideAreaAndFilter && (
                  <ProjectFilterTags
                    className=""
                    partition={partition}
                    partitionUUID={partitionUUID}
                    selectedTags={selectedAreasTags}
                    onSelectTags={onSelectTags}
                    newProjectCreatedCallback={newProjectCreatedCallback}
                    setNewProjectCreatedCallback={setNewProjectCreatedCallback}
                    showMoreCallback={() => setShowAreas(!showAreas)}
                  // customResetTagFromCollapse={customResetTagFromCollapse}
                  />
                )}
              </CheckMainAreaContainer>
            </Col>
          </BaseCard>
        </IStickyBox>
      </Col>
      <Col className="projects-list mt-5" span={24}>
        <BaseCard className="collapsedTable blue with-text small pt-3">
          <Row className="mb-2 mx-2 px-1">
            <Col className="flex items-center flex-shrink-0">
              <h5 className="mb-2 mr-3">Projects</h5>
              {/* <Tooltip
                title={`Count of projects: ${+totalCount || +allData?.length}`}
                color="#038fde"
              >
                <Badge
                  style={{
                    backgroundColor: '#038fde',
                    marginTop: -4,
                    marginLeft: -8,
                  }}
                  className="mr-2"
                  showZero
                  count={totalCount || allData?.length}
                />
              </Tooltip> */}

              {!hideAddProjectBtn && (
                <AddProjectBtn
                  disabled={!createPerm}
                  partition={partition}
                  parentUUID={partitionUUID}
                  newProjectCallback={newProjectCallback}
                />
              )}

              <Space size={[8, 8]} className='ml-1 mb-1'>
            <Button
              id='flowResetFiltersButton'
              className="btnWarning-outline"
              size="small"
              onClick={resetToDefaultsWrapper}
            >
              {capitalize(t('wms.filters.project.reset_to_defaults', 'reset to defaults'))}
            </Button>
            <Button
              id='flowShowAllFiltersButton'
              className="btnWarning-outline"
              size="small"
              onClick={showAllFiltersWrapper}
            >
              {capitalize(t('wms.filters.project.show_all', 'show all'))}
            </Button>
          </Space>
            </Col>
            <Col className="ml-auto flex flex-wrap">
              <BaseButton
                id='computePlanScoreButton'
                className="btnPrimary-outline mr-2 mb-1"
                size="small"
                loading={kpmComputing}
                disabled={kpmComputing
                  || computationKPIloading
                  || loadingIndicator}
                onClick={onComputeKPM}
              >
                Compute Plan score
                {/* {' '} */}
                {/* {kpmComputedTime && `(${kpmComputedTime})`} */}
              </BaseButton>
              <BaseButton
                id='computeDevScoreButton'
                className="btnPrimary-outline mr-1 mb-1"
                size="small"
                loading={computationKPIloading}
                disabled={computationKPIloading
                  || kpmComputing
                  || loadingIndicator}
                onClick={onComputeKPI}
              >
                Compute Dev score
                {/* {' '}
                {lastIssuesScoringComputeTime ? `(${lastIssuesScoringComputeTime})` : ''} */}
              </BaseButton>
            </Col>

          </Row>

          <Row>
            <Col
              span={24}
              className="collapsedTable-table px-3 pb-3"
              id="ProjectList"
            >
              <BaseTable
                useCustomPagination
                columns={columns}
                data={dataTable}
                loading={loadingIndicator}
                total={totalCount}
                onShowSizeChange={onShowSizeChange}
                currentPage={currentPage}
                pageSizeOptions={pageLimitList}
                paginationPageSize={pageLimit}
                onChangePagination={changePagination}
                rowClassName={rowClassName}
                headerRowClassName="universal_header_table_row"
                onRow={onRow}
                onSelectFilters={onSelectFilters}
                setDefaultFilters={setDefaultFilters}
                addFilters={addFilters}
                selectedFilters={selectedFilters}
              />
            </Col>
          </Row>
        </BaseCard>
      </Col>
    </Flex>
  );
}

ListOfProjectsForProjectFlow.propTypes = {
  allData: PropTypes.any,
  changeSortCallback: PropTypes.func,
  hideAreaAndFilter: PropTypes.bool,
  initialPathForHistory: PropTypes.string,
  loadingIndicator: PropTypes.bool,
  searchTerm: PropTypes.string,
  selectedAreasTags: PropTypes.array,
  selectedFilterTags: PropTypes.array,
  changeProjectsData: PropTypes.func,
  newProjectCallback: PropTypes.func,
  newProjectCreatedCallback: PropTypes.bool,
  onComputeKPM: PropTypes.func,
  onComputeKPI: PropTypes.func,
  onShowAllFilter: PropTypes.func,
  onResetFilter: PropTypes.func,
  onSearchProjectCallback: PropTypes.func,
  onSelectTagsCallback: PropTypes.func,
  onSelectFiltersCallback: PropTypes.func,
  partition: PropTypes.string,
  partitionUUID: PropTypes.any,
  quickViewProjectCallback: PropTypes.func.isRequired,
  selectProjectCallback: PropTypes.func,
  setNewProjectCreatedCallback: PropTypes.any,
  totalCount: PropTypes.any,
  hideAddProjectBtn: PropTypes.bool,
  locationDashboard: PropTypes.string
};

export default ListOfProjectsForProjectFlow;

// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2024.08.15 //Format YYYY|MM|DD

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import i18n from 'i18next';
import { capitalize } from 'lodash';

import { stopPropagation } from '../utils54origins';

const { Search } = Input;

let timerSearch;

function AntDesignSearchBox54origins({
  id,
  htmlFor,
  styles = {},
  maxWidth = 350,
  size,
  variant,
  placeholder,
  disabled = false,
  loading = false,
  isFocus = false,
  classNameLabel = "",
  valueSearch,
  onSearch,
}) {
  const refInput = useRef();
  const [value, setValue] = useState('');

  const defaultPlaceholder = capitalize(i18n?.language === 'en' ? 'type here to search' : 'введите для поиска');

  const handleChange = (e) => {
    setValue(e.target.value);
    clearTimeout(timerSearch);
    timerSearch = setTimeout(() => {
      onSearch(e.target.value.trim());
    }, 700);
  };

  const styleInput = { maxWidth };

  // const styleIcon = { position: 'absolute', left: 8, top: 3 };
  const styleLabel = { position: 'relative', marginBottom: '0px' };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      clearTimeout(timerSearch);
      onSearch(e.target.value);
    }
  };

  useEffect(() => {
    if (valueSearch !== value) {
      setValue(valueSearch);
    }
  }, [valueSearch]);

  useEffect(() => {
    if ((valueSearch && !value) || isFocus) {
      refInput?.current?.focus();
    }
  }, []);

  return (
    <label
      className={classNameLabel}
      htmlFor={htmlFor}
      style={styleLabel}
      onClick={stopPropagation}
    >
      <Search
        ref={refInput}
        id={id}
        allowClear
        style={{ ...styleInput, ...styles }}
        type="text"
        size={size}
        variant={variant}
        disabled={disabled}
        loading={loading}
        placeholder={placeholder || defaultPlaceholder}
        value={value}
        onKeyDown={onKeyDown}
        onClick={stopPropagation}
        onChange={handleChange}
      />
    </label>
  );
}

AntDesignSearchBox54origins.propTypes = {
  id: PropTypes.string,
  htmlFor: PropTypes.string,
  styles: PropTypes.object,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isFocus: PropTypes.bool,
  classNameLabel: PropTypes.string,
  placeholder: PropTypes.string,
  valueSearch: PropTypes.string,
  onSearch: PropTypes.func,
};

export default AntDesignSearchBox54origins;

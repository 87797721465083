import { Card, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { path } from 'ramda';
import CollapseComponent from '../components/CollapseComponent/CollapseComponent';
import BaseCard from "../../components/_ui/BaseCard/BaseCard";

function CollapseWrapper({
  children,
  className,
  classNameHeaderWrapper,
  customCollapse,
  defaultCollapseFlag = true,
  headerChildrenWithHideOnCollapse,
  headerChildrenWithNotHideOnCollapse,
  bottomChildrenWithNotHideOnCollapse,
  hoverable,
  id,
  style,
  toggleCollapseCallback,
  disabled,
}) {
  const [collapseFlag, toggleCollapse] = useState(defaultCollapseFlag);

  const handleToggleCollapse = (e) => {
    if (!disabled) {
      toggleCollapse(!collapseFlag);

      if (toggleCollapseCallback) {
        toggleCollapseCallback(e, !collapseFlag);
      }
    }
  };

  const handleToggleCollapseInHeader = (e) => {
    e.stopPropagation();

    // console.log('e.target.offsetParent.id', e.target.offsetParent.id);

    if (path(['offsetParent', 'id'], e.target) === id) {
      handleToggleCollapse(e);
    }
  };

  useEffect(() => {
    if (!isNil(customCollapse) && customCollapse !== collapseFlag) {
      toggleCollapse(customCollapse);
    }
  }, [customCollapse]);

  return (
    <BaseCard
      hoverable={hoverable}
      id={id}
      style={style}
      className={className}
      onClick={handleToggleCollapseInHeader}
    >
      <Row
        id={id}
        className={classNameHeaderWrapper}
        onClick={handleToggleCollapseInHeader}
      >
        {headerChildrenWithNotHideOnCollapse}
        {collapseFlag && headerChildrenWithHideOnCollapse}
      </Row>
      <CollapseComponent
        handleChangeCollapse={collapseFlag}
        id={id}
        disabled={disabled}
        handleFuncCollapse={handleToggleCollapse}
      >
        {children}
      </CollapseComponent>

      {bottomChildrenWithNotHideOnCollapse
      && (
      <Row
        id={id}
        className={classNameHeaderWrapper}
        onClick={handleToggleCollapseInHeader}
      >
        {bottomChildrenWithNotHideOnCollapse}
      </Row>
      )}
    </BaseCard>
  );
}

export default CollapseWrapper;

CollapseWrapper.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  toggleCollapseCallback: PropTypes.func,
  headerChildrenWithNotHideOnCollapse: PropTypes.any,
  headerChildrenWithHideOnCollapse: PropTypes.any,
  customCollapse: PropTypes.bool,
  classNameHeaderWrapper: PropTypes.string,
};

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActorsW54 } from '../selectors/actorsW54Selector';
import useActorsW54Api from '../actions/useActorsW54Api';
import { getUuids } from '../actorsUtils';

const useActorsW54 = ({ actors = [] }) => {
  const actorsW54 = useSelector(getActorsW54);

  const [users, setUsers] = useState([]);

  const { getActorsW54ByUUID } = useActorsW54Api();

  const getUsersW54WithRedux = (usersUUIDs = []) => {
    if (!usersUUIDs?.length) return;
    getActorsW54ByUUID({
      uuids: usersUUIDs,
      not_banned: true,
    });
  };

  const initFunc = () => {
    const uuids = getUuids(actors);
    if (!uuids?.length) return;
    const usersW54 = [];
    const needUploadUUIDs = [];

    uuids?.forEach((uuid) => {
      const user = actorsW54?.[uuid];
      if (!user) needUploadUUIDs?.push(uuid);
      if (user && !user?.loading) usersW54?.push(user);
    });

    setUsers(usersW54);
    getUsersW54WithRedux(needUploadUUIDs);
  };

  useEffect(() => {
    initFunc();
  }, [
    JSON.stringify(actorsW54),
    JSON.stringify(actors),
  ]);

  return {
    users,
  };
};

export default useActorsW54;

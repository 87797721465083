import { Button, Col, Row } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next/';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { antNotification } from '../../../../MainUtils';
import { capitalize } from 'lodash';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import { listTestCycleArchive } from '../../../selectors/selectors';
import {
  updateListOfTestCase,
  updateTestCycle,
} from '../../../actions/QaActions';

function ArchiveModalInTestCycleComponent({
  handleOk,
  currentCycles,
  selectedCycleOrArchiveCycle,
  isArchive,
}) {
  const { t } = useTranslation();
  const {
    partitionType,
    getListOfTestCasesInCycle,
    getListOfTestCycles,
    getListTestCycleInArchive,
    changeQAHistory,
  } = useContext(BugTrackerContext);

  const listTestCycleInArchive = useSelector(listTestCycleArchive);
  const dispatch = useDispatch();

  const setTableOffsetCycleOrArchiveCycle = (isArchive = false) => {
    changeQAHistory({ entity: isArchive ? 'clearCycleArchive' : 'clearCycle', clearCases: true });
  };

  async function updateListTestCase(newCustomId, arrData) {
    const listTestCase = await getListOfTestCasesInCycle(arrData.uuid);

    if (!isEmpty(listTestCase)) {
      dispatch(
        updateListOfTestCase({
          partition: partitionType,
          entity_uuids: listTestCase.map((item) => item.uuid),
          params: {
            customIdParent: newCustomId,
          },
        }),
      );
    }
  }

  function updateStore(newCustomId) {
    for (const arrData of currentCycles) {
      if (arrData.uuid === selectedCycleOrArchiveCycle) {
        setTableOffsetCycleOrArchiveCycle(isArchive);
      }

      updateListTestCase(newCustomId, arrData);
    }
  }

  const updateTestCycles = async () => {
    const uuidArray = currentCycles.map((m) => m.uuid);
    const newCustomId = `C${listTestCycleInArchive.length + 1}`;

    const data = {
      uuid: [...uuidArray],
      partition: partitionType,
      params: {
        archive: true,
        customId: newCustomId,
      },
    };

    dispatch(updateTestCycle(data)).then((response) => {
      getListOfTestCycles();
      getListTestCycleInArchive();
      updateStore(newCustomId, uuidArray);

      handleOk();

      if (!response) {
        antNotification('error', t('notifications.text.error.base', 'Error'));
      }
      if (response) {
        antNotification('success', t('wms.noun.success', 'Success'));
      }
    });
  };

  return (
    <Col>
      <h5>{`${capitalize(t('wms.modals.body.add_to_archive_cycle', 'Add current test cycle to archive'))}?`}</h5>
      <hr />
      <Row>
        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button
            className="customAntOutDangerBtn "
            onClick={() => handleOk()}
          >
            <span className="ml-2 mr-2">
              {capitalize(t('wms.buttons.remove.no', 'no'))}
            </span>
          </Button>

          <Button
            onClick={() => updateTestCycles()}
            className="customAntOutGrinBtn"
          >
            <span className="ml-2 mr-2">
              {capitalize(t('wms.buttons.submit', 'submit'))}
            </span>
          </Button>
        </Col>
      </Row>
    </Col>
  );
}

export default ArchiveModalInTestCycleComponent;
ArchiveModalInTestCycleComponent.propTypes = {
  handleOk: PropTypes.func,
  currentCycles: PropTypes.array,
  selectedCycleOrArchiveCycle: PropTypes.string,
  isArchive: PropTypes.bool,
};

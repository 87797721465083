import { ProjectTypeCommonConstants } from '../constants/Constants';

const initialState = {
  isLoading: false,
  isLoadingAffix: false,
  data: [],
  dataAffix: [],
  error: '',
};

export default function allEntitiesAuditReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ProjectTypeCommonConstants.GET_ALL_ENTITIES_AUDIT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ProjectTypeCommonConstants.GET_ALL_ENTITIES_AUDIT_SUCCESS: {
      return {
        ...state,
        error: '',
        data: payload,
        isLoading: false,
      };
    }
    case ProjectTypeCommonConstants.GET_ALL_ENTITIES_AUDIT_FAILURE: {
      return {
        ...state,
        error: payload,
        data: [],
        isLoading: false,
      };
    }
    case ProjectTypeCommonConstants.CLEAR_LIST_ENTITIES: {
      return {
        ...state,
        data: [],
        isLoading: false,
      };
    }

    case ProjectTypeCommonConstants.GET_ALL_AFFIX_AUDIT_REQUEST: {
      return {
        ...state,
        isLoadingAffix: true,
      };
    }
    case ProjectTypeCommonConstants.GET_ALL_AFFIX_AUDIT_SUCCESS: {
      return {
        ...state,
        error: '',
        dataAffix: payload,
        isLoadingAffix: false,
      };
    }
    case ProjectTypeCommonConstants.GET_ALL_AFFIX_AUDIT_FAILURE: {
      return {
        ...state,
        error: payload,
        dataAffix: [],
        isLoadingAffix: false,
      };
    }
    case ProjectTypeCommonConstants.CLEAR_LIST_AFFIX: {
      return {
        ...state,
        dataAffix: [],
        isLoadingAffix: false,
      };
    }
    default: return { ...state };
  }
}

import React, { useEffect, useState } from "react";
import { func, number, object, string } from "prop-types";

import Icon from "@mdi/react";
import { mdiMinus, mdiPlus } from "@mdi/js";

import { Flex, Input } from "antd";

import { stopPropagation } from "../../../../54origins/utils54origins";

let timerSearch;

export default function TotalIssueChunksButtons({
  uuid,
  issue,
  chunks,
  onActionCallback,
}) {
  const [newTime, setNewTime] = useState(chunks);

  const btnPlusMinusClass = 'px-0.5 text-base text-blue-500 hover:text-white bg-white hover:bg-blue-500 rounded-r-sm border border-blue-500 cursor-pointer';
  const btnPlusMinusStyle = { height: 16 };

  const inputClass = '!text-xs text-blue-500 text-center rounded-none border-l-0 border-r-0 border-blue-500 bg-white ';

  const onTimeChange = (value) => {
    if (value === '') {
      setNewTime('')
      return;
    }

    const parsedValue = parseInt(value, 10);

    if (parsedValue >= 0 && parsedValue <= 24) {
      setNewTime(parsedValue);

      clearTimeout(timerSearch);

      timerSearch = setTimeout(() => {
        if (parsedValue !== chunks) {
          onActionCallback({ value: parsedValue, uuid, issue });
        }
      }, 1000);
    }
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      clearTimeout(timerSearch);
      onActionCallback({ value: newTime, uuid, issue });
    }
  };

  const onMinusOrPlusClick = (value) => {
    const parsedValue = parseInt(value, 10);
    if (parsedValue && parsedValue >= 0 && parsedValue <= 24) {
      onActionCallback({ value: parsedValue, uuid, issue });
    }
  }

  useEffect(() => {
    if (chunks && chunks !== newTime) {
      setNewTime(chunks);
    }
  }, [chunks]);

  return (
    <Flex
      onClick={stopPropagation}
    >
      <Flex
        align='center'
        justify="center"
        style={btnPlusMinusStyle}
        className={btnPlusMinusClass}
        onClick={() => onMinusOrPlusClick(+newTime - 1)}
      >
        <Icon path={mdiMinus} size={0.6} />
      </Flex>
      <Input
        value={newTime / 2}
        onKeyDown={onKeyDown}
        min={0}
        max={24}
        disabled
        style={{ width: 37, height: 16 }}
        className={inputClass}
        onChange={(e) => onTimeChange(e.target.value)}
        size='small'
      />
      <Flex
        align='center'
        justify="center"
        style={btnPlusMinusStyle}
        className={btnPlusMinusClass}
        onClick={() => onMinusOrPlusClick(+newTime + 1)}
      >
        <Icon path={mdiPlus} size={0.6} />
      </Flex>
    </Flex>)
}

TotalIssueChunksButtons.propTypes = {
  uuid: string,
  chunks: number,
  issue: object,
  onActionCallback: func,
}
export default function customContextMenu(el, x, y, e) {
  const items = [];

  if (y == null) {
    // Insert a new column
    if (el.options.allowInsertColumn == true) {
      items.push({
        title: el.options.text.insertANewColumnBefore,
        onclick() {
          el.insertColumn(1, parseInt(x), 1);
        },
      });
    }

    if (el.options.allowInsertColumn == true) {
      items.push({
        title: el.options.text.insertANewColumnAfter,
        onclick() {
          el.insertColumn(1, parseInt(x), 0);
        },
      });
    }

    // Delete a column
    if (el.options.allowDeleteColumn == true) {
      items.push({
        title: el.options.text.deleteSelectedColumns,
        onclick() {
          el.deleteColumn(el.getSelectedColumns().length ? undefined : parseInt(x));
        },
      });
    }

    // Rename column
    if (el.options.allowRenameColumn == true) {
      items.push({
        title: el.options.text.renameThisColumn,
        onclick() {
          el.setHeader(x);
        },
      });
    }

    // Sorting
    if (el.options.columnSorting == true) {
      // Line
      items.push({ type: 'line' });

      items.push({
        title: el.options.text.orderAscending,
        onclick() {
          el.orderBy(x, 0);
        },
      });
      items.push({
        title: el.options.text.orderDescending,
        onclick() {
          el.orderBy(x, 1);
        },
      });
    }
  } else {
    // Insert new row
    if (el.options.allowInsertRow == true) {
      items.push({
        title: el.options.text.insertANewRowBefore,
        onclick() {
          el.insertRow(1, parseInt(y), 1);
        },
      });

      items.push({
        title: el.options.text.insertANewRowAfter,
        onclick() {
          el.insertRow(1, parseInt(y));
        },
      });
    }

    if (el.options.allowDeleteRow == true) {
      items.push({
        title: el.options.text.deleteSelectedRows,
        onclick() {
          el.deleteRow(el.getSelectedRows().length ? undefined : parseInt(y));
        },
      });
    }

    if (x) {
      if (el.options.allowComments == true) {
        items.push({ type: 'line' });

        const title = el.records[y][x].getAttribute('title') || '';

        items.push({
          title: title ? el.options.text.editComments : el.options.text.addComments,
          onclick() {
            const comment = prompt(el.options.text.comments, title);
            if (comment) {
              el.setComments([x, y], comment);
            }
          },
        });

        if (title) {
          items.push({
            title: el.options.text.clearComments,
            onclick() {
              el.setComments([x, y], '');
            },
          });
        }
      }
    }
  }

  // Line
  items.push({ type: 'line' });

  // Copy
  items.push({
    title: el.options.text.copy,
    shortcut: 'Ctrl + C',
    onclick() {
      el.copy(true);
    },
  });

  // Paste
  if (navigator && navigator.clipboard) {
    items.push({
      title: el.options.text.paste,
      shortcut: 'Ctrl + V',
      onclick() {
        if (el.selectedCell) {
          if (navigator.userAgent.match(/firefox|fxios/i)) {
            console.log('FIREFOX!');
            if (el.data) {
              el.paste(el.selectedCell[0], el.selectedCell[1], el.data);
            }
          } else {
            navigator.clipboard.readText().then((text) => {
              if (text) {
                el.paste(el.selectedCell[0], el.selectedCell[1], text);
              }
            });
          }
        }
      },
    });
  }

  // Save
  if (el.options.allowExport) {
    items.push({
      title: el.options.text.saveAs,
      shortcut: 'Ctrl + S',
      onclick() {
        el.download();
      },
    });
  }

  // About
  if (el.options.about) {
    items.push({
      title: el.options.text.about,
      onclick() {
        if (el.options.about === true) {
          alert(Version().print());
        } else {
          alert(el.options.about);
        }
      },
    });
  }

  return items;
}

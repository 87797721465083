/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'antd';
import { isNil, prop } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { omit, isEmpty } from 'lodash';
import cn from 'classnames';

import PersonalListOfTasks from './PersonalListOfTasks';

import {
  getListOfProjectsEntity,
  getMe,
  getProjectsEntityUuids,
  isGettingUserFetching,
} from '../../../projectFlow/selectors/selectors';
import {
  getMainIssuePrivateEntityUUID,
  getOtherUserPublicProfileUUID,
  getUserPublicProfileUUID,
} from '../../store/selectors/selectors';
import {
  getIssue,
  getListOfCustomIssues,
  getListOfProjectRequest,
  getListOfVectors,
} from '../../../projectFlow/actions/projectFlowActions';
import {
  clearIssue,
  clearListOfIssues,
  clearPrivateIssues,
  clearPublicIssues,
  clearStorageIssues,
} from '../../../projectFlow/actions/PtcActionsForHook';
import axiosRequest from '../../../api/apiAxios';
import { getUsersByUUID } from '../../store/action-creators/userActions';
import { ProjectTypeCommonConstants } from '../../../projectFlow/constants/Constants';
import { getPartitionPMUUID } from '../../../config/selectors/selectors';
import { getUuidsfromParamsUsers } from '../../../MainUtils';
import IssueInfoRoot from '../../../projectFlow/components/issues/issueInfo/IssueInfoRoot';
import CustomCardComponent from '../../../projectFlow/components/commonComponents/CustomCardComponent';
import { partitionNamesConfig } from '../../../api/appConfig';

function PersonalTaskManagementComponent({
  view,
  customActorUuid,
  customDefaultTagsConfig,
  customDefaultSelectedTags,
  hidden,
  customCreateIssueModalConfig,
}) {
  const dispatch = useDispatch();

  const myFetching = useSelector(isGettingUserFetching);
  const myUuid = useSelector(getMe);
  const myProjects = useSelector(getListOfProjectsEntity);
  const myProjectsUuids = useSelector(getProjectsEntityUuids);
  const mainIssuePrivateEntity = useSelector(getMainIssuePrivateEntityUUID);
  const pmPartition = useSelector(getPartitionPMUUID);
  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);
  const otherUserPublicEntityUUID = useSelector(getOtherUserPublicProfileUUID);

  const [totalCountIssue, setTotalCountIssue] = useState(0);

  const actorUUID = customActorUuid || myUuid;
  const publicEntityUUID = customActorUuid
    ? otherUserPublicEntityUUID
    : myPublicEntityUUID;

  const initialValues = {
    selectedIssue: '',
    partitionType: '',
  };
  const {
    hiddenInIssueInfo = ['resetIssueBtn'],
    hideInPersonalTask = [],
  } = hidden || [];

  const userUUID = actorUUID;

  const defaultTagsConfig = {
    partitionTypes: ['project'],
    with_files: false,
    issuesParams: {
      params: {
        entity_type: 'issue',
        limit: 25,
        offset: 0,
        params: {
          usersSearch: [userUUID],
          status: ['created'],
        },
      },
    },
  };

  const finalDefaultTagsConfig = customDefaultTagsConfig || defaultTagsConfig;

  const [stateValues, changeStateValues] = useState(initialValues);
  const [currentTagsConfig, saveTagsConfig] = useState(finalDefaultTagsConfig);

  const getProjectIssues = async (data) => {
    const config = {
      data: {
        ...data.params,
        depth: 0,
        entities: myProjectsUuids,
        entity_type__not: ['archiveEntity'],
        parent: pmPartition,
      },
      with_files: false,
      customPartition: partitionNamesConfig.partition1,
    };
    const res = await dispatch(getListOfCustomIssues(config));
    setTotalCountIssue(prop('total', res));
  };

  const getPrivateIssues = async (data) => {
    if (mainIssuePrivateEntity) {
      const config = {
        data: {
          ...data.params,
          params: {
            ...omit(data.params.params, ['usersSearch']),
          },
          depth: 1,
          parent: mainIssuePrivateEntity,
        },
        with_files: false,
        customPartition: partitionNamesConfig.partition2,
        customConstants: [
          ProjectTypeCommonConstants.FETCH_LIST_OF_PRIVATE_TICKETS_REQUEST,
          ProjectTypeCommonConstants.FETCH_LIST_OF_PRIVATE_TICKETS_SUCCESS,
          ProjectTypeCommonConstants.FETCH_LIST_OF_PRIVATE_TICKETS_FAILURE,
          ProjectTypeCommonConstants.CLEAR_LIST_OF_PRIVATE_TICKETS,
        ],
      };

      const res = await dispatch(getListOfCustomIssues(config));
      setTotalCountIssue(prop('total', res));
    }
  };

  const getPublicIssues = async (data) => {
    const config = {
      data: {
        ...data.params,
        depth: 0,
        params: {
          ...omit(data.params.params, ['usersSearch']),
        },
        parent: publicEntityUUID,
      },
      with_files: false,
      customConstants: [
        ProjectTypeCommonConstants.FETCH_LIST_OF_PUBLIC_TICKETS_REQUEST,
        ProjectTypeCommonConstants.FETCH_LIST_OF_PUBLIC_TICKETS_SUCCESS,
        ProjectTypeCommonConstants.FETCH_LIST_OF_PUBLIC_TICKETS_FAILURE,
      ],
      customPartition: partitionNamesConfig.partition3,
    };

    const res = await dispatch(getListOfCustomIssues(config));
    setTotalCountIssue(prop('total', res));
  };

  // const getProjectMembers = (uuid) => {
  //   dispatch(getUsersByUUID({ uuid, userType: 'project' }));
  // };

  const changePersonalIssue = (row) => {
    // console.log('params', row);
    const { uuid, partition, project } = row || {};

    if (!isEmpty(myProjectsUuids)) {
      const currentProjects = myProjects.find((item) => item.uuid === project);
      if (!isNil(currentProjects) && currentProjects.length !== 0) {
        dispatch(getUsersByUUID({ uuid: getUuidsfromParamsUsers(currentProjects.params.users), userType: 'project' }));
      }
    }

    if (!hiddenInIssueInfo.includes('assignedVector') && project) {
      dispatch(getListOfVectors({ parent: project, partition }));
    }

    changeStateValues({
      selectedIssue: uuid,
      partitionType: partition,
    });

    const config = {
      uuid,
      partition,
      aborting: true,
    };

    // axiosRequest.abort('', ProjectTypeCommonConstants.ISSUE_ENTITY_REQUEST);
    return dispatch(getIssue(config));
  };

  const savePersonalPriority = (count, uuid) => {

  };

  const getTagsConfigAndSendRequest = (config) => {
    const { partitionTypes, issuesParams } = config;

    saveTagsConfig(config);

    if (partitionTypes.length !== 0) {
      partitionTypes.forEach((el) => {
        switch (el) {
          case 'project':
            getProjectIssues(issuesParams);
            break;
          case 'public':
            getPublicIssues(issuesParams);
            break;
          case 'private':
            if (!hideInPersonalTask.includes('hidePrivate')) {
              getPrivateIssues(issuesParams);
            }
            break;
          default:
            break;
        }
      });
      return;
    }

    if (hideInPersonalTask && !hideInPersonalTask.includes('hideProjects')) {
      getProjectIssues(issuesParams);
    }

    if (hideInPersonalTask && !hideInPersonalTask.includes('hidePublic')) {
      getPublicIssues(issuesParams);
    }

    if (hideInPersonalTask && !hideInPersonalTask.includes('hidePrivate')) {
      getPrivateIssues(issuesParams);
    }
  };

  const getIssuesAndClearSelected = () => {
    changeStateValues((prev) => ({
      ...prev,
      selectedIssue: '',
    }));

    getTagsConfigAndSendRequest(currentTagsConfig);
  };

  const newIssueSuccessCallback = () => {
    const partition = currentTagsConfig.partitionTypes;
    if (isEmpty(partition)
      || partition.includes('public')
      || partition.includes('private')
    ) {
      getTagsConfigAndSendRequest(currentTagsConfig);
    }
  };

  const updateCallbackFromIssueInfo = () => getTagsConfigAndSendRequest(currentTagsConfig);

  const getListProjects = () => {
    const params = {
      usersSearch: [actorUUID],
      // adminsMembers__or: [actorUUID],
      archive: false,
    };
    return dispatch(getListOfProjectRequest(pmPartition, params));
  };

  useEffect(() => {
    if (!hideInPersonalTask.includes('hideProjects') && actorUUID) {
      getListProjects();
    }
  }, [actorUUID]);

  useEffect(() => () => {
    axiosRequest.abort('', ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_REQUEST);
    // dispatch(clearListOfProjects);
    dispatch(clearIssue);
    dispatch(clearStorageIssues);
    dispatch(clearListOfIssues);
    dispatch(clearPublicIssues);
    dispatch(clearPrivateIssues);
  }, []);

  const { selectedIssue, partitionType } = stateValues;

  return (
    <Row gutter={[14]} wrap={false} className="w-full pt-3">
      {!myFetching && (
        <PersonalListOfTasks
          myProjectsUuids={myProjectsUuids}
          changeIssue={changePersonalIssue}
          customCreateIssueModalConfig={customCreateIssueModalConfig}
          customDefaultSelectedTags={customDefaultSelectedTags}
          defaultTagsConfig={finalDefaultTagsConfig}
          getTagsConfigAndSendRequest={getTagsConfigAndSendRequest}
          hidden={hideInPersonalTask}
          myUuid={actorUUID}
          newIssueSuccessCallback={newIssueSuccessCallback}
          savePersonalPriority={savePersonalPriority}
          selectedIssue={selectedIssue}
          totalCount={totalCountIssue}
          view={view}
          userUUID={userUUID}
        />
      )}

      <Col className="w-full" span={10}>
        {selectedIssue && (
        <CustomCardComponent type="issue">
          <IssueInfoRoot
            partitionType={partitionType}
            hiddenView={hiddenInIssueInfo}
            deleteCallback={getIssuesAndClearSelected}
            updateCallback={updateCallbackFromIssueInfo}
          />
        </CustomCardComponent>
        )}
      </Col>
    </Row>
  );
}

PersonalTaskManagementComponent.propTypes = {
  customActorUuid: PropTypes.string,
  customCreateIssueModalConfig: PropTypes.object,
  customDefaultSelectedTags: PropTypes.object,
  customDefaultTagsConfig: PropTypes.object,
  hidden: PropTypes.any,
  view: PropTypes.any,
};

export default PersonalTaskManagementComponent;

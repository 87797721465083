import { prop, propOr } from 'ramda';
import { EntityConstants } from '../constants/Constants';

const initialState = {
  fetching: true,
  data: [],
  dataInMapByUuid: new Map([]),
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EntityConstants.GET_ENTITY_PERMS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case EntityConstants.GET_ENTITY_PERMS_SUCCESS:
      const byUuid = new Map([]);
      propOr([], 'payload.data', action).forEach((item) => {
        byUuid.set(prop('actor_uuid', item), item);
      });
      return {
        ...state,
        data: action.payload.data,
        dataInMapByUuid: byUuid,
        fetching: false,
        error: false,
      };
    case EntityConstants.GET_ENTITY_PERMS_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        dataInMapByUuid: new Map([]),
        error: true,
      };

    case EntityConstants.CLEAR_ENTITY_PERMS:
      return {
        fetching: true,
        data: [],
        dataInMapByUuid: new Map([]),
        error: false,
      };

    default: return state;
  }
};

import React from 'react';
import {
  Col, Row,
} from 'antd';

import SessionsManagementMain from './SessionsManagement';
import RobotsFreeTime from './RobotsFreeTime';


function GeneralManagement() {
  return (
    <Row className="mt-3">
      <Col span={24}>
        <Row className="mt-3">
          <Col span={6}>
            <RobotsFreeTime />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col span={6}>
            <SessionsManagementMain.Card />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default GeneralManagement;

import {
  mdiAccount,
  mdiAccountGroup,
  mdiChevronRight,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Card, Col, Row, Tooltip, Button, Tag, Divider, Space, Flex
} from 'antd';
import { get, capitalize, uniq } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  isNil, isEmpty, prop, propOr,
} from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IStickyBox from 'react-sticky-box';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import BaseTable from '../../../../components/_ui/BaseTable/BaseTable';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import InfoIcon from './infoIcon/InfoIcon';
import CheckMainAreaContainer from '../../../containers/areas/CheckMainAreaContainer';
import HashtagsList from '../../commonComponents/HashtagsList';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import ProjectFilter from '../ProjectFilter';
import ProjectFilterTags from '../ProjectFilterTags';

// TO DO: new styles
import '../css/ListOfProjectsForProjectFlowMedia.scss';

import { capitalizeAndTranslateMsg, filterUsersAndGetUuidsWithRole } from '../../../../MainUtils';
import { globalDataNotSaved } from '../../../../components/un-save-data-confirm/unSaveDataSlicer';
import useURLParams from '../../../../hooks/useURLParams';
import { getMyInfoAndListOfActors } from '../../../../userFlow/store/action-creators/userActions';
import { getUserPublicProfileUUID } from '../../../../userFlow/store/selectors/selectors';

import { getProjectsIssuesScoringLastComputeTime } from '../../../reducers/slicers/projectIssueScoringSlicer';
import {
  selectProjectsKPMComputedTime,
} from '../../../reducers/slicers/projectsKPMSlice';

///// import colums config
import { columnsFilterConfig } from '../utils/projectAuditFilterConfig';
import { defaultFilterConfig } from '../utils/projectAuditFilterConfig';
import WrapperAvatarsGroupForProject from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';
import useActorsW54 from '../../../../actors/hooks/useActorsW54';

import useDevScore from '../../../../hooks/projectAuditHooks/useDevScore';
import useOutdatedScore from '../../../../hooks/projectAuditHooks/useOutdatedScore';
import useQaStructureScoring from '../../../../hooks/projectAuditHooks/useQaStructureScoring';
import useQaActivityScoring from '../../../../hooks/projectAuditHooks/useQaActivityScoring';
import useArchiveScore from '../../../../hooks/projectAuditHooks/useArchiveScore';
import ComputeButton from './computeButton/ComputeButton';
import {
  getQaStructureProjectsArr,
  getQaActivityProjectsArr,
  getOutdatedProjectsArr,
  getArchiveProjectsArr,
} from '../../../reducers/slicers/projectAllScoringSlicer';
import { sortNumValues } from './utils';
import { parsePlanScoreColor } from '../projectInfo/utils/scoringUtils';
import { kpmInfo } from '../../milestones/helpers/milestoneUtils';
import { PROJECT_TABS } from '../projectView/projectTabs';
import { useLocation } from 'react-router-dom';
import BaseCard from "../../../../components/_ui/BaseCard/BaseCard";
import BaseTag from "../../../../components/_ui/BaseTag/BaseTag";
import CustomAvatar from "../../../../components/CustomAvatar/CustomAvatar";
import { partitionNamesConfig } from '../../../../api/appConfig';
import { UI54Button, UI54Card, UI54Colors, UI54Icon, UI54Table } from "@agpl/ui54kit";

const defaultPaginationOptions = {
  currentPage: 1,
  pageLimit: 100,
  defaultPageLimit: 100,
  pageLimitList: [50, 100],
};

function ListOfProjectsForAudit({
  totalCount,
  partitionUUID,
  perms,
  allData,
  partition,
  optionalSelectedTags = [],
  selectedFilterTags,
  hideAreaAndFilter = false,
  initialPathForHistory = '/pm/projects/',
  loadingIndicator,
  searchTerm,
  onComputeKPM,
  onComputeKPI,
  onComputeOutdated,
  onComputeArchive,
  onComputeQaStructure,
  onComputeQaActivity,
  onComputeAllScore,
  onSearchProjectCallback,
  onSelectTagsCallback,
  onShowAllFilter,
  onResetFilter,
  onSelectFiltersCallback,
  changeSortCallback,
  newProjectCreatedCallback,
  quickViewProjectCallback,
  selectProjectCallback,
  setNewProjectCreatedCallback,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    routeNavigateTo, navigate, currentLocationPathname, getURLParams,
  } = useURLParams();

  const { activeProject } = getURLParams();

  const location = useLocation();

  const GlobalDataNotSaved = useSelector(globalDataNotSaved);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  // const projectsIssuesScoringData = useSelector(getProjectsIssuesScoringData);

  const kpmComputedTime = useSelector(selectProjectsKPMComputedTime);

  const [showAreas, setShowAreas] = useState(false);
  const [customResetTagFromCollapse, setCustomResetTagFromCollapse] = useState(null);
  // const [currentSort, setCurrentSort] = useState('');

  const [paginationOptions, setPaginationOptions] = useState(() => defaultPaginationOptions);

  const [someScoreFetching, setSomeScoreFetching] = useState(false);

  const lastIssuesScoringComputeTime = useSelector(getProjectsIssuesScoringLastComputeTime);
  const qaStructureProjData = useSelector(getQaStructureProjectsArr);
  const qaActivityProjData = useSelector(getQaActivityProjectsArr);
  const outdatedProjData = useSelector(getOutdatedProjectsArr);
  const archiveProjData = useSelector(getArchiveProjectsArr);

  ///// add filters
  const [defaultFilters, setDefaultFilters] = useState(defaultFilterConfig)
  const [selectedFilters, setSelectedFilters] = useState(null)

  const addFilters = (filters) => {
    const userFilters = []
    const statusFilters = []
    let partitionFilters = []
    let metricFilters = []
       if (filters.users) {
          filters.users.forEach((el) =>
            userFilters.push({key: 'user', value: el, label: `wms.filters.project.${el}`, keySection: 'user-filter'}))
       }
       if(filters.partition) {
        filters.partition.forEach((el) =>
          partitionFilters.push({key: 'partition', value: el, label: `wms.filters.project.${el}`, keySection: 'partition-filter'}))
       }
       if(filters.status) {
        filters.status.forEach((el) =>
          statusFilters.push({key: 'status', value: el, label: `wms.filters.project.${el}`, keySection: 'status-filter'}))
        }
      if(filters.partition && filters.partition.length > 1 || !filters.partition ) {
        partitionFilters = [{key: 'partition', value: 'all-partition', label: `wms.filters.project.all-partition`, keySection: 'partition-filter'}]
       }
       if(filters.metric) {
        filters.metric.forEach((el) =>
          metricFilters.push({key: 'metric', value: el, label: `wms.filters.project.${el}`, keySection: 'metric-filter'}))
     }

       setSelectedFilters([
        ...userFilters,
        ...partitionFilters,
        ...statusFilters,
        ...metricFilters]);
       setDefaultFilters(filters)
  }

  const showAllFiltersWrapper = () => {
   setDefaultFilters({partition: null, status: null, metric: null, users: null})
    onShowAllFilter()
  }

  const resetToDefaultsWrapper = () => {
    setDefaultFilters(defaultFilterConfig)
    onResetFilter()
  }

  // const { width } = useResize();
  const {
    devTitle,
    devTooltipText,
    handleDevBackground,
  } = useDevScore();

  const {
    outdatedTitle,
    outdatedTooltipText,
    handleOutdatedBackground,
  } = useOutdatedScore();

  const {
    qaStructureTitle,
    qaStructureTooltipText,
    handleQaStructureBackground,
  } = useQaStructureScoring();

  const {
    qaActivityTitle,
    qaActivityTooltipText,
    handleQaActivityBackground,
  } = useQaActivityScoring();

  const {
    archiveScoreTitle,
    archiveTooltipText,
    handleArchiveBackground,
  } = useArchiveScore();

  const {
    currentPage,
    pageLimit,
    pageLimitList,
    defaultPageLimit,
  } = paginationOptions || {};

  const dataTable = useMemo(
    () => allData
      ?.slice((currentPage - 1) * pageLimit, currentPage * pageLimit),
    [currentPage,
      pageLimit,
      JSON.stringify(allData),
    ],
  );

  const isPmPartition = partition === partitionNamesConfig.partition1;

  const onShowSizeChange = (current, size) => {
    setPaginationOptions((prev) => ({
      ...prev,
      currentPage: 1,
      pageLimit: size,
    }));
  };

  const changePagination = useCallback((page) => {
    setPaginationOptions((prev) => ({
      ...prev,
      currentPage: page,
    }));
  }, []);

  const resetPagination = useCallback(() => {
    setPaginationOptions((prev) => ({
      ...prev,
      currentPage: 1,
    }));
  }, []);

  const paginationTotal = useCallback((total) => `Total projects: ${total}`, []);

  const onSelectFilters = (tags) => {
    onSelectFiltersCallback?.(tags);
    resetPagination?.();
  };

  const onSelectTags = (tags) => {
    onSelectTagsCallback?.(tags);
    resetPagination?.();
  };

  const onRow = (record, tabName) => ({
    onClick: () => {
      selectProjectCallback?.();

      const navigateTo = {};
      const isWeeklyPlanning = record?.type?.toUpperCase() === 'ACTIVITIES_USER' && record?.status === 'systemic';

      if (isWeeklyPlanning) {
        navigateTo.tabname = 'issuesnew';
        navigateTo.searchParams = { activeProject: record?.uuid };
      } else {
        // navigateTo.tabname = tabName || 'project';
        navigateTo.tabname = 'default';
        navigateTo.searchParams = {
          activeProject: record?.uuid,
          tab: PROJECT_TABS.INFO,
        };
      }
      if (record?.partition === partitionNamesConfig.partition1) {
        routeNavigateTo(
          `${initialPathForHistory}${navigateTo.tabname}`,
          { ...navigateTo.searchParams },
          { state: { from: location } },
        );
      } else if (record?.partition === partitionNamesConfig.partition3) {
        const searchParams = {
          activeProject: record?.uuid,
          tab: PROJECT_TABS.INFO,
        };
        routeNavigateTo(
          `/public/user/${myPublicUUID}/projects/default`,
          searchParams,
          { state: { from: location } },
        );
      }
    },
    onMouseDown: (e) => {
      if (e.button === 1) {
        const navigateTo = {};
        const isWeeklyPlanning = record?.type?.toUpperCase() === 'ACTIVITIES_USER' && record?.status === 'systemic';

        if (isWeeklyPlanning) {
          navigateTo.tabname = 'issuesnew';
          navigateTo.searchParams = { activeProject: record?.uuid };
        } else {
          navigateTo.tabname = 'project';
          navigateTo.searchParams = {
            activeProject: record?.uuid,
            tab: PROJECT_TABS.INFO,
          };
        }
        if (record?.partition ===  partitionNamesConfig.partition1) {
          window.open(`${initialPathForHistory}${navigateTo.tabname}?${Object.entries(navigateTo.searchParams).map(([key, value]) => `${key}=${value}`).join('&')}`);
        } else if (record?.partition ===  partitionNamesConfig.partition3) {
          const searchParams = {
            activeProject: record?.uuid,
            tab: PROJECT_TABS.INFO,
          };
          window.open(`/public/user/${myPublicUUID}/projects/project?${Object.entries(searchParams).map(([key, value]) => `${key}=${value}`).join('&')}`);
        }
      }
    },
  });

  const quickViewFunc = (e, cell, customPartition) => {
    e.stopPropagation();

    if (GlobalDataNotSaved) {
      navigate('/data-not-saved', {
        state: {
          path: currentLocationPathname,
          search: {
            activeProject: cell,
          },
        },
      });
    } else {
      quickViewProjectCallback?.(cell || '', customPartition);
    }
  };

  const handleRedirectFromScore = (e, row, tab) => {
    e.stopPropagation();
    onRow(row, tab)?.onClick();
  };

    const userUUIDs = dataTable?.flatMap((item) => item?.users);
  useActorsW54({ actors: uniq(userUUIDs) });

  // Server sorting
  // const changeSort = (name, sortOrder) => {
  //   const typeSort = sortOrder === 'ascend' ? 'asc' : 'desc';
  //   const isParamSorted = name !== 'owner_uinfo';
  //   const orderName = name === 'owner_uinfo' ? 'owner' : name;

  //   setCurrentSort({
  //     orderBy: orderName,
  //     order: typeSort,
  //     isParamSorted,
  //   });
  // };

  const sortKPI = (scoringData1, scoringData2) => {
    const val1 = scoringData1?.issuesScoringAVG;
    const val2 = scoringData2?.issuesScoringAVG;
    const val1Num = !isNil(val1) && val1 ? val1 : 0;
    const val2Num = !isNil(val2) && val2 ? val2 : 0;
    return val1Num > val2Num ? 1 : -1;
  };

  // Local sorting
  const changeSort = (a, b, paramName, sortOrder) => {
    // console.log('a, b, paramName', a, b, paramName);
    switch (paramName) {
      case 'issuesDevScore':
        return sortNumValues(
          a?.issuesDevScore?.issuesScoringAVG,
          b?.issuesDevScore?.issuesScoringAVG,
        );
      case 'gmPlanScore':
        return sortNumValues(
          a?.gmPlanScore?.planScoreValue,
          b?.gmPlanScore?.planScoreValue,
        );
      case 'outdatedDevScore':
        return sortNumValues(
          a?.outdatedDevScore?.outdatedScore,
          b?.outdatedDevScore?.outdatedScore,
        );
      case 'qaStructureScoring':
        return sortNumValues(
          a?.qaStructureScoring?.qaStructureScore,
          b?.qaStructureScoring?.qaStructureScore,
        );
      case 'qaActivityScoring':
        return sortNumValues(
          a?.qaActivityScoring?.qaActivityScore,
          b?.qaActivityScoring?.qaActivityScore,
        );
      case 'archiveScoring':
        return sortNumValues(
          a?.archiveScoring?.archiveScore,
          b?.archiveScoring?.archiveScore,
        );
      case 'id': return sortNumValues(a?.[paramName], b?.[paramName]);
      // case 'outdatedScore':
      // case 'qaStructureScore':
      // case 'qaActivityScore':
      // case 'archiveScore':
      // case 'kpm': return sortNumValues(a?.[paramName], b?.[paramName]);
      // case 'kpi':
      //   return sortKPI(a?.scoringData, b?.scoringData);
      case 'title':
      case 'status':
      case 'partition': return a?.[paramName]?.toLowerCase()?.localeCompare(b?.[paramName]?.toLowerCase());
      case 'created': return moment(a?.[paramName]).unix() - moment(b?.[paramName]).unix();
      default:
    }
    return 0;
  };

  const searchInTitle = (
    <AntDesignSearchBox54origins
      valueSearch={searchTerm}
      isFocus={isPmPartition}
      onSearch={onSearchProjectCallback}
    />
  );

  const userFormatter = (cell) => {
    const fullName = `${prop('first_name', cell)} ${prop('last_name', cell)}`;
    return (
      <div className="flex flex-wrap items-center">
        <CustomAvatar
          fullName={fullName}
        />
      </div>
    );
  };

  const columns = [
        {
          dataIndex: 'uuid',
          title: '',
          render: (cell, row) => (
            <UI54Button
              id={"qView" + cell}
              size="small"
              disabled={get(row, 'removed')}
              onClick={(e) => quickViewFunc(e, cell, row?.partition)}
              variant="outlined"
            >
              <Tooltip
                placement="left"
                title={capitalizeAndTranslateMsg('wms.buttons.quick_view', 'Q-view')}
              >
                {capitalizeAndTranslateMsg('wms.buttons.quick_v', 'Q-view')}
              </Tooltip>
              <Icon path={mdiChevronRight} size={0.75} className="ml-1" />
            </UI54Button>
          ),
        },
        {
          dataIndex: 'id',
          title: 'ID',
          className: 'text-nowrap',
          sorter: (a, b, sortOrder) => changeSort(a, b, 'id', sortOrder),
          render: (cell) => cell && (`P${cell}`),
        },
        {
          dataIndex: 'title',
          title: searchInTitle,
          sorter: (a, b, sortOrder) => changeSort(a, b, 'title', sortOrder),
          render: (cell, row) => {
            const projectTags = propOr([], 'areas', row);
            const tooltip = propOr('', 'uuid', row);

            return (
              <>
                {cell && (
                  <EntityParamTag
                    param="title"
                    value={cell}
                    type="value"
                    tooltip={tooltip}
                    maxLength={100}
                  />
                )}
                <HashtagsList
                  tags={projectTags}
                  maxLength={60}
                />
              </>
            );
          },
        },
        {
          dataIndex: ['gmPlanScore', 'planScoreValue'],
          title: (
            <InfoIcon title="Plan" tooltipText={kpmInfo} />
          ),
          sorter: (a, b, sortOrder) => changeSort(a, b, 'gmPlanScore', sortOrder),
          // ...(kpmComputedTime && { sorter: (a, b, sortOrder) => changeSort(a, b, 'kpm', sortOrder) }),
          render: (cell, row) => {
            const finalKpm = cell >= 0 && !isNil(cell) ? cell : '-';
            const btnBackgroundClassname = parsePlanScoreColor(finalKpm, true);
            return (
              <UI54Button
                id='redirectPlanScoreButton'
                className={`w-full text-base scoringBtn ${btnBackgroundClassname}`}
                size="small"
                onClick={(e) => handleRedirectFromScore(e, row, 'planning')}
              >
                {finalKpm}
              </UI54Button>
            );
          },
        },
        {
          dataIndex: ['issuesDevScore', 'issuesScoringAVG'],
          title: (
            <InfoIcon title={devTitle} tooltipText={devTooltipText} />
          ),
          sorter: (a, b, sortOrder) => changeSort(a, b, 'issuesDevScore', sortOrder),
          // ...(lastIssuesScoringComputeTime && { sorter: (a, b, sortOrder) => changeSort(a, b, 'kpi', sortOrder) }),
          render: (cell, row) => {
            // console.log('row?.scoringData', row?.scoringData);
            const finalDefaultVal = typeof cell === 'number' || cell === null ? (cell && Number(cell).toFixed(2)) || 0 : '-';
            const btnBackgroundClassname = handleDevBackground(cell, true);
            return (
              <UI54Button
                id='redirectDevScoreButton'
                className={`w-full text-base scoringBtn ${btnBackgroundClassname}`}
                size="small"
                onClick={(e) => handleRedirectFromScore(e, row, 'issuesnew')}
              >
                {finalDefaultVal}
              </UI54Button>
            );
          },
        },
        {
          dataIndex: ['outdatedDevScore', 'outdatedScore'],
          title: <InfoIcon title={outdatedTitle} tooltipText={outdatedTooltipText} />,
          ...(lastIssuesScoringComputeTime && { sorter: (a, b, sortOrder) => changeSort(a, b, 'outdatedScore', sortOrder) }),
          render: (cell, row) => {
            const finalVal = (typeof cell === 'number' ? cell : '-');
            // console.log('outdatedScore finalVal', finalVal);
            const btnBackgroundClassname = handleOutdatedBackground(finalVal, true);
            return (
              <UI54Button
                id='redirectOutdatedDevScoreButton'
                className={`w-full text-base scoringBtn ${btnBackgroundClassname}`}
                size="small"
                onClick={(e) => handleRedirectFromScore(e, row, 'myboard')}
              >

                {finalVal}
              </UI54Button>
            );
          },
          sorter: (a, b, sortOrder) => changeSort(a, b, 'outdatedDevScore', sortOrder),
        },
        {
          dataIndex: ['qaStructureScoring', 'qaStructureScore'],
          title: <InfoIcon title={qaStructureTitle} tooltipText={qaStructureTooltipText} />,
          render: (cell, row) => {
            const finalVal = (typeof cell === 'number' ? cell : '-');
            // console.log('qaStructureScore finalVal', finalVal);
            const btnBackgroundClassname = handleQaStructureBackground(finalVal, true);
            return (
              <UI54Button
                id="redirectQaStructureScoreButton"
                className={`w-full text-base scoringBtn ${btnBackgroundClassname}`}
                size="small"
                onClick={(e) => handleRedirectFromScore(e, row, 'qa')}
              >

                {finalVal}
              </UI54Button>
            // <BaseTag
            //   className="ml-1 text-sm"
            //   style={{ fontWeight: '500' }}
            //   color={handleQaStructureBackground(finalVal)}
            // >
            //   {finalVal}
            // </BaseTag>
            );
          },
          sorter: (a, b, sortOrder) => changeSort(a, b, 'qaStructureScoring', sortOrder),
        },

        {
          dataIndex: ['qaActivityScoring', 'qaActivityScore'],
          title: <InfoIcon title={qaActivityTitle} tooltipText={qaActivityTooltipText} />,
          render: (cell, row) => {
            const finalVal = (typeof cell === 'number' ? cell : '-');
            // console.log('qaActivityScore finalVal', finalVal);
            const btnBackgroundClassname = handleQaActivityBackground(finalVal, true);
            return (
              <UI54Button
                id='redirectQaActivityScoreButton'
                className={`w-full text-base scoringBtn ${btnBackgroundClassname}`}
                size="small"
                onClick={(e) => handleRedirectFromScore(e, row, 'qa')}
              >

                {finalVal}
              </UI54Button>
            // <BaseTag
            //   className="ml-1 text-sm"
            //   style={{ fontWeight: '500' }}
            //   color={handleQaActivityBackground(finalVal)}
            // >
            //   {finalVal}
            // </BaseTag>
            );
          },
          sorter: (a, b, sortOrder) => changeSort(a, b, 'qaActivityScoring', sortOrder),
        },
        {
          dataIndex: ['archiveScoring', 'archiveScore'],
          title: <InfoIcon title={archiveScoreTitle} tooltipText={archiveTooltipText} />,
          render: (cell, row) => {
            const finalVal = typeof cell === 'number' ? cell : '-';
            // console.log('archiveScore', cell);
            const btnBackgroundClassname = handleArchiveBackground((finalVal), true);
            return (
              <UI54Button
                id='redirectArchiveScoreButton'
                className={`w-full text-base scoringBtn ${btnBackgroundClassname}`}
                size="small"
                onClick={(e) => handleRedirectFromScore(e, row, 'docs')}
              >

                {finalVal}
              </UI54Button>
            // <BaseTag
            //   className="ml-1 text-sm"
            //   style={{ fontWeight: '500' }}
            //   color={handleArchiveBackground(finalVal)}
            // >
            //   {finalVal}
            // </BaseTag>
            );
          },
          sorter: (a, b, sortOrder) => changeSort(a, b, 'archiveScoring', sortOrder),
        },
        {
          dataIndex: 'status',
          title: capitalizeAndTranslateMsg('wms.table.headers.status', 'Status'),
          // sorter: (a, b, sortOrder) => changeSort(a, b, 'status', sortOrder),
          sorter: true,
          filteredValue: defaultFilters.status,
          defaultFilteredValue: defaultFilters.status,
          ...columnsFilterConfig.status,
          onFilter: (value, record) => value === record.status,
          render: (cell) => cell
            && (
              <EntityParamTag
                param="status"
                value={cell === 'archive'
                  ? 'archived'
                  : cell}
                type="iconWithStatus"
              />
            )
          ,
        },
        {
          dataIndex: 'users',
          title: capitalizeAndTranslateMsg('wms.table.headers.members', 'members'),
          key: 'users',
          sorter: true,
          filteredValue: defaultFilters.users,
          defaultFilteredValue: defaultFilters.users,
          ...columnsFilterConfig.users,
          onFilter: (record) => record.actor === record.owner,
          render: (cell, row) => {
            const actorsWithRoles = cell;
            return actorsWithRoles?.length > 0 && (
              <WrapperAvatarsGroupForProject
                actors={actorsWithRoles}
                avatarSize="small"
                avatarGap={4}
                avatarStyles={{ cursor: 'pointer' }}
                groupButtonSize="small"
              />
            );
          },
        },
        // {
        //   dataIndex: 'owner_uinfo',
        //   title: capitalizeAndTranslateMsg('wms.table.headers.owner', 'owner'),
        //   render: userFormatter,
        // },
        // {
        //   dataIndex: 'users',
        //   title: capitalizeAndTranslateMsg('wms.table.headers.members', 'members'),
        //   render: (cell) => {
        //     const visibleActorsWithRoles = getUuidsfromParamsUsers(cell);

        //     return visibleActorsWithRoles?.length > 0 && (
        //       <WrapperAvaWrapperAvatarsGroupForProjectWithHooktarsGroup
        //         actors={visibleActorsWithRoles}
        //         avatarSize="small"
        //         avatarGap={4}
        //         avatarStyles={{ cursor: 'pointer' }}
        //         groupButtonSize="small"
        //       />
        //     );
        //   },
        // },
        {
          dataIndex: 'partition',
          key: 'partition',
          title: capitalizeAndTranslateMsg('general', 'type'),
          // ...(isPmPartition && { sorter: (a, b, sortOrder) => changeSort(a, b, 'partition', sortOrder) }),
          sorter: true,
          filteredValue: defaultFilters.partition,
          defaultFilteredValue: defaultFilters.partition,
          ...columnsFilterConfig.type,
          onFilter: (value, record) =>  value === 'personal' ? record.partition ===  partitionNamesConfig.partition3 : record.partition ===  partitionNamesConfig.partition1,
          render: (cell) => {
            // eslint-disable-next-line no-shadow
            const isPmPartition = cell === 'PM';
            const title = isPmPartition ? 'General project' : 'Personal project';
            const icon = isPmPartition ? mdiAccountGroup : mdiAccount;
            const iconColor = isPmPartition ? '#72b153' : '#60a1eb';
            return (
              <Tooltip title={title}>
                <UI54Icon path={icon} size={1} color={iconColor} />
              </Tooltip>
            );
          },
        },
        {
          dataIndex: 'metric',
          title: capitalizeAndTranslateMsg('wms.table.headers.metric', 'metric'),
          key: 'metric',
          sorter: true,
          filteredValue: defaultFilters.metric,
          defaultFilteredValue: defaultFilters.metric,
          ...columnsFilterConfig.metric,
            onFilter: (record) => record,
            render: (cell) => cell
            && (
              <EntityParamTag
                param="metric"
                value={cell}
                type="icon"
              />
            )
        },
        // {
        //   dataIndex: 'created',
        //   className: 'text-nowrap',
        //   title: capitalizeAndTranslateMsg('wms.table.headers.created', 'Created'),
        //   render: (cell) => (
        //     moment(cell).format('DD-MM-YYYY')
        //   ),
        //   hide: width < 1500,
        //   sorter: (a, b, sortOrder) => changeSort(a, b, 'created', sortOrder),
        // },
  ].map((columnGroup) => (Array.isArray(columnGroup?.children)
    ? { ...columnGroup, children: columnGroup?.children?.filter((column) => !column.hide) }
    : columnGroup));

  const rowClassName = (row) => `cursor-pointer universal_table_row ${prop('uuid', row) === activeProject ? 'selected' : ''}`;

  const {
    create: createPerm,
    set: setPerm,
  } = perms || {};

  useEffect(() => {
    const noGetBanedUsers = false;
    dispatch(getMyInfoAndListOfActors(noGetBanedUsers));
  }, []);

  useEffect(() => {
    if (!isNil(customResetTagFromCollapse)) {
      setCustomResetTagFromCollapse('');
    }
  }, [customResetTagFromCollapse]);

  const handlePlanScore = async () => {
    await onComputeKPM();
  };

  const handleDevScore = async () => {
    await onComputeKPI();
  };

  const handleOutdatedScore = async () => {
    await onComputeOutdated();
  };

  const handleArchiveScore = async () => {
    await onComputeArchive();
  };

  const handleQaStructureScore = async () => {
    await onComputeQaStructure();
  };

  const handleQaActivityScore = async () => {
    await onComputeQaActivity();
  };

  const handleAllScore = async () => {
    await onComputeAllScore();
  };

  const buttonsDisabled = loadingIndicator || someScoreFetching;

  return (
    <Flex vertical className="projects-block" gutter={[12, 12]}>
      <Col className="projects-filters" span={24}>
        <IStickyBox className="projects-filters-inner" offsetTop={80} offsetBottom={80}>
          {/* <BaseCard className="projects-filters-item collapsedTable blue with-text small">
            <Col span={24} className="flex items-start">
              <CheckMainAreaContainer showSection>
                {!hideAreaAndFilter && (
                  <ProjectFilter
                    className=""
                    location="audit"
                    partition={partition}
                    loading={loadingIndicator}
                    searchTerm={searchTerm}
                    selectedTags={selectedFilterTags}
                    onSearch={onSearchProjectCallback}
                    onSelectTags={onSelectFilters}
                    onResetFilter={onResetFilter}
                    onShowAllFilter={onShowAllFilter}
                    setNewProjectCreatedCallback={setNewProjectCreatedCallback}
                    newProjectCreatedCallback={newProjectCreatedCallback}
                  // showMoreCallback={() => setShowAreas(!showAreas)}
                  // customResetTagFromCollapse={customResetTagFromCollapse}
                  />
                )}
              </CheckMainAreaContainer>
            </Col>
          </BaseCard> */}

          <UI54Card noBodyPadding shadow={false}>
            <Col span={24} className="flex items-start">
              <CheckMainAreaContainer showSection>
                {!hideAreaAndFilter && (
                  <ProjectFilterTags
                    className=""
                    partition={partition}
                    partitionUUID={partitionUUID}
                    optionalSelectedTags={optionalSelectedTags}
                    onSelectTags={onSelectTags}
                    newProjectCreatedCallback={newProjectCreatedCallback}
                    setNewProjectCreatedCallback={setNewProjectCreatedCallback}
                    showMoreCallback={() => setShowAreas(!showAreas)}
                  // customResetTagFromCollapse={customResetTagFromCollapse}
                  />
                )}
              </CheckMainAreaContainer>
            </Col>
          </UI54Card>
        </IStickyBox>
      </Col>

      <Col className="projects-list mt-5" >
        <UI54Card noBodyPadding shadow={false}>
          <Col span={24} className="p-3">
            <Row className="mb-2">
              <Col className="flex items-center flex-shrink-0">
                <h5>Projects</h5>
              </Col>
            </Row>

            <Flex className='mb-3'>
              <Space size={[4, 4]}>
                <UI54Button
                  id='auditResetFiltersButton'
                  style={{ height: '48px'}}
                  onClick={resetToDefaultsWrapper}
                  variant="outlined"
                  color={UI54Colors.orange[500]}
                >
                  {capitalize(t('wms.filters.project.reset_to_defaults', 'reset to defaults'))}
                </UI54Button>

                <UI54Button
                  id='auditShowAllFiltersButton'
                  style={{ height: '48px'}}
                  onClick={showAllFiltersWrapper}
                  variant="outlined"
                  color={UI54Colors.orange[500]}
                >
                  {capitalize(t('wms.filters.project.show_all', 'show all'))}
                </UI54Button>
              </Space>

              <Space size={[6, 6]} className="ml-auto">
                <ComputeButton
                  title="Plan score"
                  callback={handlePlanScore}
                  // timestamp={kpmComputedTime}
                  disabled={buttonsDisabled}
                  setSomeScoreFetching={setSomeScoreFetching}
                />
                <ComputeButton
                  title="Dev score"
                  callback={handleDevScore}
                  // timestamp={lastIssuesScoringComputeTime}
                  disabled={buttonsDisabled}
                  setSomeScoreFetching={setSomeScoreFetching}
                />
                <ComputeButton
                  title="Outdated dev score"
                  callback={handleOutdatedScore}
                  // timestamp={outdatedProjData?.lastComputeTime}
                  disabled={buttonsDisabled}
                  setSomeScoreFetching={setSomeScoreFetching}
                />
                <ComputeButton
                  title="Qa structure"
                  callback={handleQaStructureScore}
                  // timestamp={qaStructureProjData?.lastComputeTime}
                  disabled={buttonsDisabled}
                  setSomeScoreFetching={setSomeScoreFetching}
                />
                <ComputeButton
                  title="Qa activity"
                  callback={handleQaActivityScore}
                  // timestamp={qaActivityProjData?.lastComputeTime}
                  disabled={buttonsDisabled}
                  setSomeScoreFetching={setSomeScoreFetching}
                />
                <ComputeButton
                  title="Archive score"
                  callback={handleArchiveScore}
                  // timestamp={archiveProjData?.lastComputeTime}
                  disabled={buttonsDisabled}
                  setSomeScoreFetching={setSomeScoreFetching}
                />
                <div className="flex items-center" style={{ height: '48px' }}>
                  <Divider type="vertical" style={{ height: '24px', background: 'rgba(0,0,0,.3)', margin: 0 }} />
                </div>
                <ComputeButton
                  title="All score"
                  callback={handleAllScore}
                  timestamp=""
                  disabled={buttonsDisabled}
                  setSomeScoreFetching={setSomeScoreFetching}
                />
              </Space>
            </Flex>

            <Row>
              <Col
                span={24}
                className="collapsedTable-table"
                id="ProjectList"
              >
                <UI54Table
                  columns={columns}
                  dataSource={dataTable}
                  headerRowClassName="universal_header_table_row"
                  pagination={{
                    total: totalCount,
                    current: currentPage,
                    pageSize: pageLimit,
                    pageSizeOptions: pageLimitList,
                    onShowSizeChange: onShowSizeChange,
                    onChange: changePagination,
                  }}
                  loading={loadingIndicator}
                  rowClassName={rowClassName}
                  onRow={onRow}
                  //add filter props
                  onSelectFilters={onSelectFilters}
                  setDefaultFilters={setDefaultFilters}
                  addFilters={addFilters}
                  selectedFilters={selectedFilters}
                />
              </Col>
            </Row>
          </Col>
        </UI54Card>
      </Col>
    </Flex>
  );
}

ListOfProjectsForAudit.propTypes = {
  allData: PropTypes.any,
  totalCount: PropTypes.any,
  hideAreaAndFilter: PropTypes.bool,
  optionalSelectedTags: PropTypes.array,
  initialPathForHistory: PropTypes.string,
  loadingIndicator: PropTypes.bool,
  searchTerm: PropTypes.string,
  selectedFilterTags: PropTypes.array,
  onComputeKPM: PropTypes.func,
  onComputeKPI: PropTypes.func,
  onComputeOutdated: PropTypes.func,
  onComputeArchive: PropTypes.func,
  onComputeQaStructure: PropTypes.func,
  onComputeQaActivity: PropTypes.func,
  onComputeAllScore: PropTypes.func,
  onSearchProjectCallback: PropTypes.func,
  onShowAllFilter: PropTypes.func,
  onResetFilter: PropTypes.func,
  onSelectTagsCallback: PropTypes.func,
  onSelectFiltersCallback: PropTypes.func,
  partition: PropTypes.string,
  partitionUUID: PropTypes.any,
  changeSortCallback: PropTypes.func,
  newProjectCreatedCallback: PropTypes.any,
  quickViewProjectCallback: PropTypes.func.isRequired,
  selectProjectCallback: PropTypes.func,
  setNewProjectCreatedCallback: PropTypes.any,
};

export default ListOfProjectsForAudit;

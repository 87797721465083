import { ProjectTypeCommonConstants } from '../../constants/Constants';

const initialState = {
  fetching: true,
  fetchingSuite: false,
  fetchingNewSuite: false,
  fetchingRemoveSuite: false,
  data: [],
  singleSuite: [],
  error: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ProjectTypeCommonConstants.LIST_OF_TEST_SUITES_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_SUITES_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_SUITES_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.NEW_TEST_SUITE_REQUEST:
      return {
        ...state,
        fetching: false,
        fetchingNewSuite: true,
      };
    case ProjectTypeCommonConstants.NEW_TEST_SUITE_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetchingNewSuite: false,
        singleSuite: payload,
      };
    case ProjectTypeCommonConstants.NEW_TEST_SUITE_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchingNewSuite: false,
        data: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.GET_TEST_SUITE_REQUEST:
      return {
        ...state,
        fetchingSuite: false,
      };
    case ProjectTypeCommonConstants.GET_TEST_SUITE_SUCCESS:
      return {
        ...state,
        fetchingSuite: false,
        singleSuite: payload,
      };

    case ProjectTypeCommonConstants.GET_TEST_SUITE_FAILURE:
      return {
        ...state,
        fetchingSuite: false,
        singleSuite: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.EDIT_TEST_SUITE_REQUEST:
      return {
        ...state,
      };
    case ProjectTypeCommonConstants.EDIT_TEST_SUITE_SUCCESS:
      return {
        ...state,
        singleSuite: payload,
      };

    case ProjectTypeCommonConstants.EDIT_TEST_SUITE_FAILURE:
      return {
        ...state,
      };

    case ProjectTypeCommonConstants.UPDATE_TEST_SUITE_IN_STORE: 
    // console.log('payload', payload,'singleSuite', state.singleSuite)
      return {
        ...state,
        fetchingSuite: false,
        singleSuite: payload,
      };

    case ProjectTypeCommonConstants.UPDATE_LIST_TEST_SUITE_IN_STORE:
      return {
        ...state,
        fetching: false,
        data: payload,
      };

    case ProjectTypeCommonConstants.REMOVE_TEST_SUITE_REQUEST:
      return {
        ...state,
        fetchingRemoveSuite: true,
      };

    case ProjectTypeCommonConstants.REMOVE_TEST_SUITE_SUCCESS:
      return {
        ...state,
        fetchingRemoveSuite: false,
      };

    case ProjectTypeCommonConstants.REMOVE_TEST_SUITE_FAILURE:
      return {
        ...state,
        fetchingRemoveSuite: false,
      };

    case ProjectTypeCommonConstants.CLEAR_TEST_SUITE:
      return {
        ...state,
        singleSuite: [],
      };

    default: return state;
  }
};

import { Col } from 'antd';
import moment from 'moment';
import { pathOr, prop, propOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import {
  array, func, string,
} from 'prop-types';
import { get } from 'lodash';

import CommentTemplate from './CommentTemplate';
import UserAvatar from '../../../components/UserAvatar/UserAvatar';

import { getLocale } from '../../../locale/selectors';
import { timeToCalendar } from '../../../MainUtils';
import { getUserUuid } from '../../selectors/selectors';
import useActorsW54 from '../../../actors/hooks/useActorsW54';

export default function CommentList({
  listData,
  partitionType,
  afterRemoveComment,
  onReply,
  entityType,
  readOnlyComments,
}) {
  const userUUID = useSelector(getUserUuid);
  const locale = useSelector(getLocale);

  // Получение пользователей
  const userUUIDs = listData?.flatMap((item) => {
    if (get(item, 'params.comment.param', '') === 'users') {
      return get(item, 'params.comment.value', []);
    }
    return [];
  });
  useActorsW54({ actors: userUUIDs });

  const dateSeparator = (dateText) => (
    <div className="comment-date">
      <p>{dateText}</p>
    </div>
  );

  const checkCombineComments = (comment, nextComment) => {
    const commentActorUUID = propOr('', 'actor_uuid', comment);
    const nextCommentActorUUID = propOr('', 'actor_uuid', nextComment);
    const checkTime = (current, prev) => moment(current).locale(locale).format('MMM. D, LT')
      === moment(prev).locale(locale).format('MMM. D, LT');

    return (
      nextComment
      && nextCommentActorUUID === commentActorUUID
      && checkTime(comment.created, nextComment.created)
    );
  };

  const renderAvatar = (comment, combined) => {
    const itsMe = propOr('', 'actor_uuid', comment) === userUUID;

    return combined ? (
      <div style={{ width: 48, height: 40 }} />
    ) : (
      <Col className={cn('p-0', { 'pl-2': itsMe, 'pr-2': !itsMe })}>
        <UserAvatar
          userFirstName={pathOr('', ['uinfo', 'first_name'], comment)}
          userLastName={pathOr('', ['uinfo', 'last_name'], comment)}
          className={itsMe ? 'avatar-my' : 'avatar-user'}
          userUUID={propOr('', 'actor_uuid', comment)}
        />
      </Col>
    );
  };

  return (
    // <Skeleton loading={fetching}>
    <>
      {listData[0] && (
        dateSeparator(timeToCalendar(listData[0].created))
      )}

      {listData.map((item, index) => {
        const combined = checkCombineComments(
          item,
          listData.length !== index - 1 && listData[index + 1],
        );
        const itsMe = propOr('', 'actor_uuid', item) === userUUID;

        const showSeparator = () => {
          if (index !== 0
            && moment(prop('created', item)).format('D')
            !== moment(prop('created', listData[index - 1])).format('D')) {
            return dateSeparator(timeToCalendar(item.created));
          }
          return '';
        };

        return (
          <Col
            span={24}
            key={`comment_${prop('uuid', item)}`}
          >
            {showSeparator()}
            <div
              className={`flex items-end ${combined ? 'pb-1' : 'pb-3'
                }`}
            >
              {!itsMe && renderAvatar(item, combined)}
              <Col className={`p-0 comment_background ${itsMe ? 'ml-auto comment-my' : 'flex comment-user'}`}>
                <CommentTemplate
                  key={`comment_template_${prop('uuid', item)}`}
                  item={item}
                  itsMe={itsMe}
                  entityType={entityType}
                  partitionType={partitionType}
                  afterRemoveComment={afterRemoveComment}
                  onReply={onReply}
                  readOnlyComments={readOnlyComments}
                />
              </Col>
              {itsMe && renderAvatar(item, combined)}
            </div>
          </Col>
        );
      })}
    </>
    // </Skeleton>
  );
}

CommentList.propTypes = {
  listData: array,
  partitionType: string,
  afterRemoveComment: func,
  onReply: func,
  entityType: string,
};

import React from 'react';
import {
  mdiAccountCancelOutline,
  mdiAccountCheckOutline,
  mdiAccountEyeOutline, mdiAccountQuestion,
  mdiAccountSearchOutline,
  mdiAlertOutline,
  mdiArchiveSettingsOutline,
  mdiBellOutline,
  mdiBlockHelper,
  mdiBugOutline,
  mdiCached,
  mdiCheck,
  mdiClockCheckOutline,
  mdiClockTimeThreeOutline,
  mdiCloseThick,
  mdiEyeOutline,
  mdiInformationOffOutline,
  mdiLayersRemove,
  mdiLayersSearchOutline,
  mdiMagnify,
  mdiPause,
  mdiProgressClock,
  mdiTabRemove,
  mdiWrenchOutline,
} from '@mdi/js';

export const issuesStatusesTags = [
  {
    key: 'status',
    value: 'all',
    label: 'wms.filters.all',
    className: 'switch-validation-section',
    parentSubSection: 'validation-section',
    subSectionTags: ['created', 'reopened', 'acquainted', 'accepted', 'rejected'],
    keySection: 'validation',
  },
  {
    key: 'status',
    value: 'created',
    // after: '0',
    // exp: 0,
    label: 'wms.status.created',
    className: 'switch-validation-section',
    icon: mdiBellOutline,
    keySection: 'validation',
  },
  {
    key: 'status',
    value: 'reopened',
    // after: '14',
    // exp: 14,
    label: 'wms.status.reopened',
    className: 'switch-validation-section',
    icon: mdiCached,
    keySection: 'validation',
  },
  {
    key: 'status',
    value: 'acquainted',
    // after: '0',
    // exp: 0,
    label: 'wms.status.acquainted',
    className: 'switch-validation-section',
    icon: mdiAccountEyeOutline,
    keySection: 'validation',
  },
  {
    key: 'status',
    value: 'accepted',
    // after: '30',
    // exp: 30,
    label: 'wms.status.accepted',
    className: 'switch-validation-section',
    icon: mdiAccountCheckOutline,
    keySection: 'validation',
  },
  {
    key: 'status',
    value: 'rejected',
    // after: '30',
    // exp: 30,
    label: 'wms.status.rejected',
    className: 'switch-validation-section',
    icon: mdiAccountCancelOutline,
    keySection: 'validation',
  },
  {
    key: 'status',
    value: 'all',
    label: 'wms.filters.all',
    className: 'switch-development-section',
    parentSubSection: 'develop-section',
    subSectionTags: ['in progress', 'review', 'ready for deploy'],
    keySection: 'development',
  },
  {
    key: 'status',
    value: 'in progress',
    // after: '14',
    // exp: 14,
    label: 'wms.status.in progress',
    className: 'switch-development-section',
    icon: mdiProgressClock,
    keySection: 'development',
  },
  {
    key: 'status',
    value: 'review',
    // after: '14',
    // exp: 14,
    label: 'wms.status.ready for review',
    className: 'switch-development-section',
    icon: mdiEyeOutline,
    keySection: 'development',
  },
  {
    key: 'status',
    value: 'ready for deploy',
    // after: '14',
    // exp: 14,
    className: 'switch-development-section',
    label: 'wms.status.ready for deploy',
    icon: mdiClockCheckOutline,
    keySection: 'development',
  },
  // {
  //   key: 'status',
  //   value: 'bugs',
  //   label: 'wms.status.bugs',
  //   className: 'switch-development-section',
  //   icon: mdiBugOutline,
  //   keySection: 'development',
  // },
  // {
  //   key: 'status',
  //   value: 'fixing',
  //   label: 'wms.status.fixing',
  //   className: 'switch-development-section',
  //   icon: mdiWrenchOutline,
  //   keySection: 'development',
  // },
  {
    key: 'status',
    value: 'all',
    label: 'wms.filters.all',
    className: 'switch-testing-section',
    parentSubSection: 'test-section',
    subSectionTags: ['test', 'testing', 'not reproducible', 'test failed'],
    keySection: 'testing',
  },
  {
    key: 'status',
    value: 'test',
    // after: '14',
    // exp: 14,
    label: 'wms.status.ready for test',
    className: 'switch-testing-section',
    icon: mdiMagnify,
    keySection: 'testing',
  },
  {
    key: 'status',
    value: 'testing',
    // after: '14',
    // exp: 14,
    label: 'wms.status.testing',
    className: 'switch-testing-section',
    icon: mdiLayersSearchOutline,
    keySection: 'testing',
  },
  // {
  //   key: 'status',
  //   value: 'test failed',
  //   label: 'wms.status.test failed',
  //   className: 'switch-testing-section',
  //   icon: mdiLayersRemove,
  //   keySection: 'testing',
  // },
  {
    key: 'status',
    value: 'not reproducible',
    // after: '14',
    // exp: 14,
    label: 'wms.status.not reproducible',
    className: 'switch-testing-section',
    icon: mdiAlertOutline,
    keySection: 'testing',
  },
  // {
  //   key: 'status',
  //   value: 'self testing',
  //   label: 'wms.status.self testing',
  //   className: 'switch-testing-section',
  //   icon: mdiAccountSearchOutline,
  //   keySection: 'testing',
  // },
  {
    key: 'status',
    value: 'all',
    label: 'wms.filters.all',
    className: 'switch-purgatory-section',
    parentSubSection: 'test-other',
    subSectionTags: ['backlog', 'paused', 'blocked'],
    keySection: 'purgatory',
  },
  {
    key: 'status',
    value: 'backlog',
    // after: '0',
    // exp: 0,
    label: 'wms.status.backlog',
    className: 'switch-purgatory-section',
    icon: mdiClockTimeThreeOutline,
    keySection: 'purgatory',
  },
  {
    key: 'status',
    value: 'blocked',
    // after: '0',
    // exp: 0,
    label: 'wms.status.blocked',
    className: 'switch-purgatory-section',
    icon: mdiBlockHelper,
    keySection: 'purgatory',
  },
  {
    key: 'status',
    value: 'paused',
    // after: '0',
    // exp: 0,
    label: 'wms.status.paused',
    className: 'switch-purgatory-section',
    icon: mdiPause,
    keySection: 'purgatory',
  },
  {
    key: 'status',
    value: 'discuss',
    // after: '0',
    // exp: 0,
    label: 'for discuss',
    className: 'switch-purgatory-section',
    icon: mdiAccountQuestion,
    keySection: 'purgatory',
  },
  {
    key: 'status',
    value: 'not enough info',
    // after: '0',
    // exp: 0,
    label: 'wms.status.not enough info',
    className: 'switch-purgatory-section',
    icon: mdiInformationOffOutline,
    keySection: 'purgatory',
  },
  {
    key: 'status',
    value: 'closed',
    // after: '0',
    // exp: 0,
    label: 'wms.status.closed',
    className: 'switch-end-section',
    icon: mdiCloseThick,
    keySection: 'end',
  },
  {
    key: 'status',
    value: 'deployed',
    // after: '0',
    // exp: 0,
    className: 'switch-end-section',
    label: 'wms.status.deployed_closed',
    icon: mdiCheck,
    keySection: 'end',
  },
  {
    key: 'status',
    value: 'abandoned',
    // after: '0',
    // exp: 0,
    className: 'switch-end-section',
    label: 'wms.status.abandoned',
    icon: mdiArchiveSettingsOutline,
    keySection: 'end',
  },
];

export const issuesValidationsStatuses = issuesStatusesTags.filter((i) => i.keySection === 'validation');

export const issuesDevStatuses = issuesStatusesTags.filter((i) => i.keySection === 'development');

export const issuesTestingStatuses = issuesStatusesTags.filter((i) => i.keySection === 'testing');

export const issuesPurgatoryStatuses = issuesStatusesTags.filter((i) => i.keySection === 'purgatory');

export const expiredTag = [
  {
    key: 'status',
    value: 'lifeTime',
    className: 'switch-end-section',
    label: 'wms.adjective.expired',
    ruleOfTag: 'off',
  },
];

export const trackerTags = [
  {
    key: 'tracker',
    value: 'ticket',
    label: 'wms.tracker.ticket',
  },
  {
    key: 'tracker',
    value: 'bug',
    label: 'wms.tracker.bug',
  },
  {
    key: 'tracker',
    value: 'feature',
    label: 'wms.tracker.feature',
  },
];

export const issuesInfo = [
  {
    key: 'validation',
    info: (
      <div className="flex flex-col">
        Created: issue была создана.
        <br />
        Acquainted: назначенные на issue разработчики ознакомились с ним.
        <br />
        Accepted: issue была принята и готова к выполнению.
        <br />
        Rejected: issue не была принята в работу, либо была отклонена после выполнения.
        <br />
        Reopened: тестирование issue не прошло успешно и он был переоткрыт.
      </div>
    ),
  },
  {
    key: 'development',
    info: (
      <div className="flex flex-col">
        In progress: issue находится в реализации.
        <br />
        Ready for review: issue готова к проверке и ожидает рассмотрения.
        <br />
        Ready for deploy: issue готова к деплою, то есть к развертыванию на рабочем окружении.
      </div>
    ),
  },
  {
    key: 'testing',
    info: (
      <div className="flex flex-col">
        Ready for test: issue готова к тестированию.
        <br />
        Testing: issue находится на тестировании у тестировщика.
        <br />
        Not reproducible: issue не может быть воспроизведен.
        Это может произойти, если issue был временным или случайным,
        или если он был исправлен в последующих версиях программного обеспечения.
      </div>
    ),
  },
  {
    key: 'purgatory',
    info: (
      <div className="flex flex-col">
        Backlog: issue, которые имеют связь с проектом и не имеют крайнего срока выполнения.
        <br />
        Blocked: issue не может быть выполнена,
        потому что она зависит от другой задачи, которая еще не выполнена.
        <br />
        Paused: issue приостановлена и не может быть выполнена в данный момент.
        <br />
        For discuss: issue находится в процессе обсуждения и не готова к выполнению.
      </div>
    ),
  },
  {
    key: 'tickets_end',
    info: (
      <div className="flex flex-col">
        Closed: issue была проверена, issue выполнена с требуемым качеством, работы по задаче завершены.
        <br />
        Deployed&Closed: issue закрыта и готова к деплою.
        <br />
        Abandoned: issue была заброшена и не будет выполнена.
      </div>
    ),
  },
];

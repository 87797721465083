import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, Form, Result, Row,
} from 'antd';
import { capitalize, omit } from 'lodash';
import { mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';

import BaseButton from '../../components/_ui/BaseButton/BaseButton';
import BaseModal from '../../components/_ui/BaseModal/BaseModal';
import UserInfoFormItems from './infoForms/UserInfoFormItems';
import ActorFormWrapper from '../../components/ActorFormWrapper';

import { synchronizeWithAuthAndGetActor } from '../../userFlow/store/action-creators/userActions';
import {
  antNotification,
  defaultSuccessNotify,
} from '../../MainUtils';
import { entityCreate } from '../../entity/actions/entityActions';
import { getPartitionPMUUID, getPartitionPublicUUID } from '../../config/selectors/selectors';
import useAuthSessionHook from '../hooks/useAuthSessionHook';
import useAuthActorsRequests from '../hooks/useAuthActorsRequests';
import { createEntityPermissions } from '../../entity/actions/entityPermActions';
import { appLabel, partitionNamesConfig, sessionNameFor } from '../../api/appConfig';
import { dateToFormat1 } from '../../54origins/dateFormats54origins';
import { removeCookie } from '../../54origins/cookies54origins';

function CreateUserOnAuth({
  createActorCallback,
  className,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [actorForm] = Form.useForm();

  const { setFieldsValue, getFieldsValue, getFieldValue } = actorForm;

  const publicPartitionUUID = useSelector(getPartitionPublicUUID);
  const pmPartitionUUID = useSelector(getPartitionPMUUID);

  const [isModalVisible, setIsModalVisible] = useReducer((previous) => !previous, false);

  const { checkAuthSessionAndRunFuncForRestart } = useAuthSessionHook(setIsModalVisible);
  const { requestCreateActorOnAuth, requestUpdateActorOnAuth } = useAuthActorsRequests();

  const [isFetching, setIsFetching] = useState(false);
  const [isModalVisibleCopy, setIsModalVisibleCopy] = useState(false);
  const [isChecked, setIsChecked] = useState({
    showBaseReport: false,
    showDetailedReport: false,
    showLunchBreak: false,
  });

  const {
    showBaseReport,
    showDetailedReport,
    showLunchBreak,
  } = isChecked;

  const onChangeIsChecked = (checked, type) => {
    setIsChecked((prev) => ({ ...prev, [type]: checked }));
  };

  const checkRequestError = (err) => {
    const { status, message } = err || {};

    antNotification('error', status);

    if (message) {
      antNotification('error', message);
    }

    if (status === 403 || status === 401) {
      removeCookie(sessionNameFor.auth);
      setIsModalVisible();
      checkAuthSessionAndRunFuncForRestart(true);
    }

    setIsFetching(false);
  };

  const addPermissionsToPMandPublicPartition = (actorUUID) => {
    const data = {
      data: [{
        actor: actorUUID,
        list: true,
      }],
    };

    const pmData = {
      entity_uuid: pmPartitionUUID,
      ...data,
    };

    const publicData = {
      entity_uuid: publicPartitionUUID,
      ...data,
    };

    dispatch(createEntityPermissions({
      data: pmData,
      partition: partitionNamesConfig.partition1,
    }));

    dispatch(createEntityPermissions({
      data: publicData,
      partition: partitionNamesConfig.partition3,
    }));
  };

  const createActorOnAuthAndEntityService = async (data) => {
    const newActor = await requestCreateActorOnAuth(data).catch((err) => {
      console.log('err', err);
      checkRequestError(err);
    });

    const newActorUUID = newActor?.actor?.uuid;

    if (newActorUUID) {
      await dispatch(synchronizeWithAuthAndGetActor(newActorUUID));
    }

    return newActor;
  };

  const addGroupsAndSynchronizeActor = async ({
    groupUuids,
    actorUUID,
    addActorsList,
    removedActorList,
  }) => {
    const newActorsNotEmpty = addActorsList?.length > 0;
    const removedActorsNotEmpty = removedActorList?.length > 0;

    const data = {
      // actor_type: 'actorType',
      uuid: actorUUID,
      add_actors_list: groupUuids,
      // ...(addActorsList && newActorsNotEmpty ? { add_actors_list: addActorsList } : {}),
      // ...(removedActorList && removedActorsNotEmpty ? { remove_actors_list: removedActorList } : {}),
    };

    if (groupUuids || newActorsNotEmpty || removedActorsNotEmpty) {
      await requestUpdateActorOnAuth(data);
      await dispatch(synchronizeWithAuthAndGetActor(actorUUID));
    } else {
      await dispatch(synchronizeWithAuthAndGetActor(actorUUID));
    }
  };

  const copyUserInfo = () => {
    const inputs = getFieldsValue(true);

    const {
      first_name, last_name, password, login, email, phone_number,
    } = inputs || {};

    const text = `${first_name ? `First name: ${first_name}` : ''}
${last_name ? `Last name: ${last_name}` : ''}
${login ? `Login: ${login}` : ''}
${password ? `Password: ${password}` : ''}
${email ? `Email: ${email}` : ''}
${phone_number ? `Phone: ${phone_number}` : ''}

For login to wms, go to ${window.location.origin}/login and enter your login and password`;

    navigator.clipboard.writeText(text);
  };

  const createUserRequest = async () => {
    copyUserInfo();
    setIsFetching(true);

    let changedInputs = getFieldsValue(null, (meta) => meta.touched);
    changedInputs = Object?.fromEntries(Object?.entries(changedInputs).filter(([key, value]) => value?.length !== 0));

    const removedActorList = getFieldValue('removeActorsList');
    const addActorsList = getFieldValue('addActorsList');
    const internalValue = getFieldValue(['internal']);
    const groupUuids = getFieldValue('groupUuids');

    try {
      await actorForm.validateFields();

      const data = {
        uinfo: {
          ...omit(changedInputs, ['password_confirmation']),
          ...(changedInputs.birthday ? { birthday: dateToFormat1(changedInputs.birthday) } : {}),
          internal_user: internalValue,
        },
      };

      const newActor = await createActorOnAuthAndEntityService(data);

      const { actor } = newActor || {};

      if (actor) {
        const newActorUUID = actor?.uuid;

        const publicUserEntityData = {
          data: {
            entity_type: 'user',
            owner: newActorUUID,
            actor: newActorUUID,
            parent: publicPartitionUUID,
            params: {
              showBaseReport,
              showDetailedReport,
              showLunchBreak,
            },
          },
          constants: [
            'CREATE_PUBLIC_USER_ENTITY_REQUEST',
            'CREATE_PUBLIC_USER_ENTITY_SUCCESS',
            'CREATE_PUBLIC_USER_ENTITY_FAILURE',
          ],
          options: {
            partition: partitionNamesConfig.partition3,
          },
        };

        addGroupsAndSynchronizeActor({
          groupUuids,
          actorUUID: newActorUUID,
          addActorsList,
          removedActorList,
        });

        addPermissionsToPMandPublicPartition(newActorUUID);

        await dispatch(entityCreate(publicUserEntityData))
          .then(() => {
            defaultSuccessNotify();
            createActorCallback?.();
          })
          .finally(() => {
            setIsFetching(false);
          });

        setIsModalVisible();
        setIsModalVisibleCopy(true);
      }
    } catch (error) {
      console.log('ERROR', error);
      setIsFetching(false);
      antNotification('error', 'invalid data entered');
    }
  };

  const onCancel = () => {
    setIsModalVisible();
    setIsFetching(false);
  };

  const tMsg = (path, defaultValue) => capitalize(t(`auth.validation.messages.${path}`, defaultValue || path));

  const validateMessages = {
    required: tMsg('required', 'is required'),
    string: {
      len: tMsg('len', 'must be exactly ${len} characters'),
      min: tMsg('min', 'must be at least ${min} characters'),
      max: tMsg('max', 'cannot be longer than ${max} characters'),
    },
    types: {
      email: tMsg('email', 'is not a valid email'),
      number: tMsg('number', 'must be a number type'),
      url: tMsg('url', 'is not a valid URL'),
    },
  };

  // const getSelectedActorsForNewActors = ({ addActorsList, removeActorsList }) => {
  const getSelectedActorsForNewActors = (uuids) => {
    // console.log('getSelectedActorsForNewActors');
    setFieldsValue({
      groupUuids: uuids
      // addActorsList,
      // removeActorsList,
    });
  };

  const getSwitchValueCallback = (value) => {
    setFieldsValue({
      internal: value,
    });
  };

  const rules = {
    user: {
      first_name: [{ max: 50 }, { required: true }],
      last_name: [{ max: 50 }, { required: true }],
      password: [{ min: 4 }, { max: 50 }, { required: true }],
      password_confirmation: [
        {
          required: true,
          message: 'Please confirm your password!',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
          },
        }),
      ],
    },
  };

  const formProps = {
    creating: true,
    actorType: 'classic_user',
    actorForm,
    validateMessages,
  };

  useEffect(() => {
    if (isModalVisible) {
      setFieldsValue({
        internal: true,
      });
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (!isModalVisibleCopy) {
      actorForm.resetFields();
    }
  }, [isModalVisibleCopy]);

  return (
    <>
      <BaseButton
        type="primary"
        onClick={checkAuthSessionAndRunFuncForRestart}
        size="small"
        className={className}
      >
        Create user via
        {' '}
        {appLabel}
      </BaseButton>
      <BaseModal
        title="Create user via w54"
        open={isModalVisible}
        handleCancel={onCancel}
        handleOk={setIsModalVisible}
        width="600px"
        destroyOnClose
        centered
        footer={null}
      >
        <ActorFormWrapper
          actorForm={actorForm}
        >
          <UserInfoFormItems
            {...formProps}
            getSelectedActorsForNewActors={getSelectedActorsForNewActors}
            getSwitchValueCallback={getSwitchValueCallback}
            onChangeIsChecked={onChangeIsChecked}
            rules={rules.user}
            showBaseReport={showBaseReport}
            showDetailedReport={showDetailedReport}
            showLunchBreak={showLunchBreak}
          />
          <hr className="my-3" />
          <Row>
            <Col className="flex">
              <Form.Item shouldUpdate>
                {() => (
                  <BaseButton
                    loading={isFetching}
                    type="primary"
                    onClick={createUserRequest}
                    disabled={isFetching || !actorForm.isFieldsTouched([
                      'first_name',
                      'last_name',
                      'login',
                      'email',
                      'phone_number',
                      'password',
                      'password_confirmation',
                    ]) || !!actorForm.getFieldsError().filter(({ errors }) => errors.length).length}
                  >
                    {isFetching ? 'Please wait, user creation in progress' : 'Create and copy user'}
                  </BaseButton>
                )}
              </Form.Item>
              <BaseButton
                className="button-secondary-outlined ml-2"
                onClick={onCancel}
              >
                Cancel
              </BaseButton>
            </Col>
          </Row>
        </ActorFormWrapper>
      </BaseModal>
      <BaseModal
        open={isModalVisibleCopy}
        handleCancel={() => setIsModalVisibleCopy(false)}
        width="35%"
        destroyOnClose
        centered
        footer={null}
      >
        <Result
          status="success"
          title="User created successfully."
          extra={[
            <BaseButton
              className="btnPrimary-outline ml-2"
              onClick={() => {
                copyUserInfo();
                setIsModalVisibleCopy(false);
              }}
              icon={<Icon className="mr-1" path={mdiContentCopy} size={0.8} />}
            >
              Copy user info with instruction
            </BaseButton>,
          ]}
        />
      </BaseModal>
    </>
  );
}

export default CreateUserOnAuth;

CreateUserOnAuth.propTypes = {
  createActorCallback: PropTypes.func,
  className: PropTypes.string,
};

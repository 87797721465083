import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import {
  array,
  bool,
  number,
  oneOfType,
  func,
  string,
  shape,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  capitalize, difference, isEmpty, xor,
} from 'lodash';
import cn from 'classnames';

import { mdiAccountGroup, mdiChat, mdiContentSave } from '@mdi/js';
import {
  Col,
  Input,
  Flex,
  Badge,
  Space,
  Spin,
} from 'antd';
import Icon from '@mdi/react';
import moment from 'moment';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import AssignedUsersPopover from '../commonComponents/AssignedUsers/AssignedUsersPopover';
import CardSidePanel from '../commonComponents/cardSidePanel/CardSidePanel';
import CommonInfo from '../commonComponents/CommonInfo';
import CommentsStartPage from '../comment/CommentsStartPage/CommentsStartPage';
import CopyURL from '../../../components/CopyURL';
import DocTypeLabel from '../docView&ideas/DocTypeLabel';
import DocsOptions from '../docView&ideas/docsOptions/DocsOptions';
import ExpandModeLayout, { useExpandModeContext } from '../commonComponents/expandModeLayout/ExpandModeLayout';
import GoToButton from '../../../components/buttons/GoToButton';
import EntityParamsPopover from '../commonComponents/EntityParamsPopover';
import UnSaveDataConfirmNew from '../../../components/un-save-data-confirm/UnSaveDataConfirmNew';
import WrapperAvatarsGroupForProjectWithHook from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import EditButton from '../commonComponents/EditButton';
import AssigningUsersRoot from '../../../assignUsersModal/AssigningUsersRoot';

import { useResize } from '../../../hooks/useResize';
import { getUserName } from '../../../entity/selectors/selectors';
import { getTotalComments, getProjectParams, getProjectUUID } from '../../selectors/selectors';
import { getUserPublicProfileUUID } from '../../../userFlow/store/selectors/selectors';
import {
  getProjectGoal,
  getProjectGoalFetching,
} from '../../reducers/slicers/projectGoalsSlicer';
import {
  clearAllComments,
  sendSystemComment,
  updateCommentsCountRequest,
  updateListComment,
} from '../../actions/projectFlowActions';
import { sendNotification } from '../../../api/notificationsAPI';
import { antNotification, capitalizeAndTranslateMsg } from "../../../MainUtils";
import { goalStatusOptions } from './constants/goalStatuses';
import { COMMENT_TYPE } from '../comment/commentType';
// import { getProjectRequest } from '../commonComponents/AssignedUsers/actions';
import { getUuid } from '../../../assignUsersModal/utils';
import { useGoalsRequestHook } from "../../../entity/hooks/useGoalsRequestHook";
import { partitionNamesConfig } from '../../../api/appConfig';

const { TextArea } = Input;

function GoalDescription({
  classNames,
  defaultValue,
  disabled,
  isReset,
  autoSize,
  changeIsReset,
  onSelect,
  onChangeCallback,
}) {

  const [value, setValue] = useState(defaultValue);
  // console.log('VALUE:', value);

  const changeValueHandler = (e) => {
    // console.log('onChange');
    setValue(e.target.value);
    onChangeCallback?.(e.target.value);
  };

  useLayoutEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useLayoutEffect(() => {
    if (isReset) {
      setValue(defaultValue);
      changeIsReset?.(false);
    }
  }, [isReset]);

  return (
    <TextArea
      className={classNames}
      value={value}
      disabled={disabled}
      defaultValue={defaultValue}
      name="description"
      autoSize={autoSize}
      onChange={changeValueHandler}
      onBlur={onSelect}
    />
  );
}

GoalDescription.propTypes = {
  classNames: string,
  defaultValue: string,
  disabled: bool,
  isReset: bool,
  autoSize: oneOfType([bool, shape({ minRows: number, maxRows: number })]),
  changeIsReset: func,
  onChangeCallback: func,
  onSelect: func,
};

const initialState = {
  title: null,
  isEdit: false,
};

function ProjectGoalView({
  defaultPartition,
  hideElements = [],
  disabledElements = [],
  updateProjectGoalCallback,
  // readOnlyComments = false,
}) {
  disabledElements.push('exportPDF', 'exportDocx');

  const dispatch = useDispatch();

  const goalDescriptionRef = useRef(null);

  const { updateProjectGoalRequest } = useGoalsRequestHook(defaultPartition)

  const projectGoal = useSelector(getProjectGoal);
  const projectGoalFetching = useSelector(getProjectGoalFetching);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const userFullName = useSelector(getUserName);
  const commentsCountStore = useSelector(getTotalComments);
  const projectParams = useSelector(getProjectParams);
  const projectUUID = useSelector(getProjectUUID);


  console.log('projectGoal', projectGoal);

  const {
    users: projectUsers = [],
    id,
    title: projectTitle,
  } = projectParams || {};

  const { width } = useResize();
  const {
    showMetaInfo,
    isBaseMode,
    isWideMode,
    isVerticalMode,
    isFullscreenMode,
  } = useExpandModeContext();

  const [state, setState] = useState(initialState);
  const [resetDescription, setResetDescription] = useState(false);
  const [bulkTitles, setBulkTitles] = useState(['']);
  const [showTitleError, setShowTitleError] = useState(false);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [commentsCountLocal, setCommentsCountLocal] = useState(0);
  const [isEditAssignUsers, setEditAssignUsers] = useState(false);

  const {
    isEdit,
    title,
  } = state || {};

  const {
    entity_type: entityType,
    params: {
      title: defaultTitle,
      description: defaultDescription,
      status: defaultStatus,
      responsible: defaultResponsible,
      modifiedBy,
      [COMMENT_TYPE.TOTAL]: defaultCommentsCount,
    } = {},
    uinfo: {
      first_name: creatorFirstName,
      last_name: creatorLastName,
    } = {},
    modified,
    created,
    parent,
    uuid: goalUUID,
  } = projectGoal?.[0] || {};

  const defaultResponsibleActors = Array.isArray(defaultResponsible) ? defaultResponsible : [];
  const creatorInfo = `${creatorFirstName} ${creatorLastName}`;
  const autosizeTextarea = isVerticalMode
    ? false
    : { minRows: 20, ...(isBaseMode && { maxRows: 20 }) };

  const pathToRedirect = {
    pathname: defaultPartition === partitionNamesConfig.partition3
      ? `/public/user/${myPublicUUID}/projects/planning`
      : '/pm/projects/planning',
    search: `?activeProject=${parent}&goal=${goalUUID}`,
  };

  const toggleCommentsPanel = () => setIsSidePanelOpen?.((prev) => !prev);

  const closeCommentsPanel = useCallback(() => setIsSidePanelOpen?.(false), []);

  const updateCommentsCount = async () => {
    if (commentsCountStore !== commentsCountLocal) {
      const commentsConfig = {
        entity_type: entityType,
        entity_uuid: goalUUID,
        params: {
          [COMMENT_TYPE.TOTAL]: commentsCountStore,
        },
      };

      // eslint-disable-next-line max-len
      const response = await dispatch(updateCommentsCountRequest(commentsConfig, defaultPartition));
      setCommentsCountLocal(response[0]?.params[COMMENT_TYPE.TOTAL] || 0);
    }
  };

  const loadDefaultState = () => {
    setState({
      title: defaultTitle,
      isEdit: false,
    });

    goalDescriptionRef.current = defaultDescription;
    setShowTitleError(false);
  };

  const changeState = (params) => {
    setState((prev) => ({
      ...prev,
      ...params,
    }));
  };

  const changeStatus = (status) => {
    const data = {
      uuid: goalUUID,
      params: {
        status
      }
    }

    updateProjectGoalRequest(data).then(() => {
      antNotification('success', capitalizeAndTranslateMsg('wms.noun.success', 'Success'));
      updateProjectGoalCallback?.();

      if (defaultStatus && defaultStatus !== status) {
        sendCustomComment({
          title: `status from ${defaultStatus}`,
          value: status,
        });
      }
    })
  };

  const changeInputField = (e) => {
    const { value, name } = e?.target || {};

    changeState({
      [name]: value,
      isEdit: true,
    });
  };

  const changeDescriptionCallback = useCallback((value) => {
    // console.log('CHANGE DESC CALLBACK');
    goalDescriptionRef.current = value;

    if (!isEdit) {
      changeState({
        isEdit: true,
      });
    }
  }, [isEdit]);

  const resetGoalToInitial = () => {
    changeState(initialState);
    setShowTitleError(false);
  };


  const sendCustomComment = async (params) => {
      if(params.value) {
        const comment = {
          entity: 'projectGoal',
          action: 'changed',
          param: params.title,
          value: params.value,
        };
    
        if (goalUUID) {
          await dispatch(sendSystemComment(goalUUID, comment, defaultPartition));
          dispatch(updateListComment(goalUUID));
        }
      }
  };
  
  const sendResponsibleUsersNotifications = (currentResponsible) => {
    if (currentResponsible?.length) {
      const receiver = difference(currentResponsible, defaultResponsible);

      if (!isEmpty(receiver)) {
        const data = {
          projectUUID: parent,
          uuid: goalUUID,
          entity_type: entityType,
          partition: defaultPartition,
          project_uuid: parent,
          project_id: id,
          project_title: projectTitle,
          assigned_entity_type: entityType || 'project',
          assigned_entity_title: projectGoal?.[0]?.params?.title || '',
          assigned_entity_id: projectGoal?.[0]?.params?.id || '',
          assigned_entity_uuid: goalUUID,
        };

        if (defaultPartition === partitionNamesConfig.partition3) {
          data.user = myPublicUUID || '';
        }

        dispatch(
          sendNotification('assigned_goal', receiver, title, {
            entity: data,
          }),
        );
      }
    }
  };

  const updateProjectGoal = () => {
    if (title?.trim().length === 0 || defaultTitle?.trim().length === 0) {
      setShowTitleError(true);
      return;
    }

    setShowTitleError(false);

    const params = {
      title: title || defaultTitle,
      modifiedBy: userFullName ?? '',
      responsible: defaultResponsible,
      description: goalDescriptionRef.current ?? '',
    };

    updateProjectGoalRequest({
      uuid: goalUUID,
      params
    }).then(() => {
      antNotification('success', capitalizeAndTranslateMsg('wms.noun.success', 'Success'));
      updateProjectGoalCallback?.();

      if (defaultStatus && defaultStatus !== params.status) {
        sendCustomComment({
          title: `status from ${defaultStatus}`,
          value: params.status,
        });
      }
    })
      .finally(() => {
        changeState({ isEdit: false });
      });
  };

  const onChangeResponsible = (data) => {
    updateProjectGoalRequest({
      uuid: goalUUID,
      params: { responsible: data?.users },
    }).then(() => {
      antNotification('success', capitalizeAndTranslateMsg('wms.noun.success', 'Success'));
      updateProjectGoalCallback?.();
    });
  };

  const resetGoalChangesHandler = () => {
    changeState({
      title: defaultTitle,
      isEdit: false,
    });

    goalDescriptionRef.current = defaultDescription || '';
    setResetDescription(true);

    setShowTitleError(false);
  };

  const onSelectTextArea = (e) => {
    const startSelected = e.target.selectionStart;
    const endSelected = e.target.selectionEnd;

    const valueArray = e.target.value?.split('\n');

    const newArr = [];
    const titlesArray = [];

    valueArray.forEach((el, indx) => {
      newArr.push({
        elem: el,
        length: el.length || 0,
        lengthInterval: {
          start: indx === 0 ? 0 : 1 + +newArr[indx - 1].lengthInterval.end,
          end: indx === 0 ? el.length : el.length + 1 + +newArr[indx - 1].lengthInterval.end,
        },
      });
    });

    if (startSelected === endSelected) {
      const arr = newArr
        .filter((e) => (
          startSelected < e.lengthInterval.end
          || startSelected === e.lengthInterval.end)
          && (startSelected > e.lengthInterval.start || startSelected === e.lengthInterval.start))
        .map((el) => el.elem);

      setBulkTitles(arr);
    }

    for (let i = 0; i < newArr.length; i += 1) {
      const {
        elem,
        lengthInterval,
      } = newArr[i];

      const {
        start,
        end,
      } = lengthInterval;

      if (
        (startSelected > start || startSelected === start)
        && (startSelected < end || startSelected === end)) {
        titlesArray.push(elem);
        continue;
      }
      if ((end < endSelected || end === endSelected) && startSelected < end) {
        titlesArray.push(elem);
      }
    }

    setBulkTitles(titlesArray);
  };

  //----------------------------------------------------------------
  useLayoutEffect(() => {
    setIsSidePanelOpen(isFullscreenMode || isWideMode);
  }, [goalUUID, isFullscreenMode, isWideMode]);

  useLayoutEffect(() => {
    if (goalUUID) {
      loadDefaultState();
    }

    setBulkTitles(['']);
  }, [goalUUID]);

  useEffect(() => {
    if (goalUUID) {
      updateCommentsCount?.();
    }
  }, [commentsCountStore]);

  useEffect(() => {
    setCommentsCountLocal(defaultCommentsCount || 0);
  }, [defaultCommentsCount]);

  useEffect(() => () => dispatch(clearAllComments()), []);

  return (
    <Spin
      size="large"
      spinning={projectGoalFetching}
      wrapperClassName={cn(
        'goal-card-spinner goal-expand-container',
        { wide: isWideMode },
        { vertical: isVerticalMode },
        { fullscreen: isFullscreenMode },
      )}
    >
      <Col
        id="goal_component"
        className="h-full card-with-side-panel"
      >
        <Col className="goal-expand-content">
          <Flex gap={10} vertical flex="1 1 100%">
            <Flex gap="10px 20px" wrap="wrap">
              <Flex align="flex-start">
                <ExpandModeLayout.Actions
                  parentType="goal"
                  isFetching={projectGoalFetching}
                />
              </Flex>

              <Flex gap={5} wrap="wrap" align="flex-start" flex="1 1 auto">
                <CopyURL
                  partition={defaultPartition}
                  entityType="goal"
                  entityUUID={goalUUID}
                />

                {!hideElements.includes('docInfoBtn') && (
                  <CommonInfo
                    creator={creatorInfo}
                    createdDate={created}
                    lastActivity={modified || ''}
                    lastModifiedBy={modifiedBy || ''}
                  />
                )}

                {width > 1499 && (
                  <DocTypeLabel entityDocType="projectGoal" />
                )}
              </Flex>

              <Flex align="center">
                {!hideElements.includes('goToBtn') && (
                  <GoToButton
                    pathForLabelTranslate="wms.buttons.go_to_goal"
                    pathToRedirect={pathToRedirect}
                  />
                )}
              </Flex>

              <Flex gap={5} align="center" wrap="wrap">
                <Space size={[5, 5]} wrap>
                  <BaseButton
                    id='toggleCommentsPanelButton'
                    className="btnPrimary-outline"
                    size="small"
                    disabled={projectGoalFetching}
                    onClick={toggleCommentsPanel}
                  >
                    <Icon path={mdiChat} size={0.8} className="mr-1" />
                    {(width > 1599 && !isFullscreenMode) && capitalizeAndTranslateMsg('wms.buttons.comments', 'comments')}
                    <Badge count={commentsCountLocal || 0} showZero color="#ff4d4f" size="small" className="ml-1" />
                  </BaseButton>

                  {!hideElements.includes('options') && (
                    <DocsOptions
                      commentType={COMMENT_TYPE.TOTAL}
                      defaultPartition={defaultPartition}
                      entityUUID={goalUUID}
                      entityType={entityType}
                      title={title}
                      bulkTitles={bulkTitles}
                      disabledElements={disabledElements}
                      hideElements={hideElements}
                      isDisabled={projectGoalFetching}
                    />
                  )}
                </Space>
              </Flex>

              <Flex gap={5} align="center" wrap="wrap">
                <Space className="customPopconfirm" size={[5, 5]} wrap>
                  <UnSaveDataConfirmNew dataIsNotSave={isEdit}>
                    <BaseButton
                      id="saveGoalBtn"
                      className="ml-auto btnGreen"
                      size="small"
                      type="primary"
                      disabled={!isEdit}
                      onClick={updateProjectGoal}
                    >
                      <Icon className="mr-1" path={mdiContentSave} size={0.8} />
                      {capitalize('save')}
                    </BaseButton>
                  </UnSaveDataConfirmNew>

                  <BaseButton
                    id='resetGoalButton'
                    className="customAntOutWarningBtn"
                    size="small"
                    disabled={!isEdit}
                    onClick={resetGoalChangesHandler}
                  >
                    Reset
                  </BaseButton>
                </Space>
              </Flex>

              {!isFullscreenMode && <hr className="m-0 w-full" />}

              <Flex
                className={cn('wrapper-title', { 'mt-1': !isFullscreenMode })}
                gap="small"
                align="center"
                flex="1 0 auto"
              >
                <Input
                  name="title"
                  maxLength={80}
                  status={showTitleError && 'error'}
                  disabled={projectGoalFetching || disabledElements.includes('title')}
                  showCount
                  placeholder={showTitleError
                    ? capitalizeAndTranslateMsg('wms.placeholders.title_error', "Title can't be empty!")
                    : capitalizeAndTranslateMsg('wms.placeholders.title', 'title')}
                  value={title}
                  onChange={changeInputField}
                />
              </Flex>

              {showMetaInfo && (
                <Flex gap="large" align="start" wrap="wrap" flex="1 1 100%">
                  <EntityParamsPopover
                    className="flex items-center flex-shrink-0"
                    param="status"
                    title={capitalizeAndTranslateMsg('wms.labels.status', 'status')}
                    currentValue={defaultStatus}
                    options={goalStatusOptions}
                    previewType="hoverValueAndIconWithArrow"
                    classNameForTitle="smallEntityText p-0 pr-2"
                    withIcons
                    withBorder
                    disableCollapse={disabledElements.includes('status')}
                    onChangeValue={changeStatus}
                  />

                  <Flex gap={5}>
                    <Flex gap={5} align="center">
                      <Flex
                        style={{ cursor: 'pointer' }}
                        align="center"
                        gap={4}
                        onClick={() => setEditAssignUsers((prev) => !prev)}
                      >
                        <Icon path={mdiAccountGroup} size={1} />
                        <span className="wrapper-title">
                          {capitalizeAndTranslateMsg('wms.adjective.responsible', 'responsible')}
                          :
                        </span>
                      </Flex>
                      <EditButton
                        id='editResponsibleButton'
                        disabled={disabledElements.includes('assign_users')}
                        onClick={() => setEditAssignUsers(true)}
                      />
                      <AssigningUsersRoot
                        header="Assigning responsible"
                        isOpen={isEditAssignUsers}
                        setIsOpen={setEditAssignUsers}
                        onUpdateUsers={onChangeResponsible}
                        needUpdateProjectUsers
                        placement="left"
                        globalOptions={{
                          uuids: projectUsers.map((el) => getUuid(el)) ?? [],
                          roles: projectUsers,
                          defaultColumn: 'project',
                        }}
                        isModal
                        tabs={{
                          allConfig: {
                            label: 'All',
                            value: 'all',
                            textSelectButton: 'Add to goal',
                            showSections: ['button-add-user'],
                          },
                          projectConfig: {
                            label: 'Project',
                            value: 'project',
                            textSelectButton: 'Add to goal',
                            defaultItems: projectUsers,
                            showSections: ['string-role', 'button-add-user'],
                          },
                        }}
                        usersConfig={{
                          label: 'Goal users',
                          showSections: ['string-role', 'button-remove-user'],
                          textDeleteButton: 'Remove users',
                          defaultItems: defaultResponsibleActors?.map((el) => el?.uuid) ?? [],
                        }}
                        projectUuid={projectUUID}
                        partition={defaultPartition}
                      />
                    </Flex>

                    <WrapperAvatarsGroupForProjectWithHook
                      actors={defaultResponsibleActors}
                      avatarSize={28}
                      popoverTrigger="click"
                      popoverPlacement="right"
                      checkProjectRole
                    />
                  </Flex>
                </Flex>
              )}
            </Flex>

            <Flex gap={10} vertical flex="1 1 auto">
              <div className="editor-wrapper page-view">
                <div className="page-view__surface">
                  <GoalDescription
                    classNames="page-view__a4"
                    defaultValue={defaultDescription || ''}
                    disabled={projectGoalFetching || disabledElements.includes('textarea')}
                    isReset={resetDescription}
                    autoSize={autosizeTextarea}
                    changeIsReset={setResetDescription}
                    onChangeCallback={changeDescriptionCallback}
                    onSelect={onSelectTextArea}
                  />
                </div>
              </div>
            </Flex>
          </Flex>
        </Col>

        <CardSidePanel
          isVisible={isSidePanelOpen}
          hideCloseButton={isFullscreenMode}
          closeSidePanel={closeCommentsPanel}
        >
          {isSidePanelOpen && (
            <CommentsStartPage
              commentType={COMMENT_TYPE.TOTAL}
              inPanel
              isPanelOpen={isSidePanelOpen}
              parentUUID={goalUUID}
              partitionType={defaultPartition}
            />
          )}
        </CardSidePanel>
      </Col>
    </Spin>
  );
}

ProjectGoalView.propTypes = {
  defaultPartition: string,
  hideElements: array,
  disabledElements: array,
  updateProjectGoalCallback: func,
};

export default ProjectGoalView;

import { EntityConstants } from '../../entity/constants/Constants';

const initialState = {
  authConfigFetching: false,
  fetching: true,
  fetched: false,
  data: [],
  error: '',
  errorAuth: '',
  authConfig: [],
  fetchingAuthConfig: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EntityConstants.GET_CONFIG_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case EntityConstants.GET_CONFIG_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload,
      };
    case EntityConstants.GET_CONFIG_FAILURE:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: [],
        error: action.payload,
      };

    case EntityConstants.GET_AUTH_CONFIG_REQUEST:
      return {
        ...state,
        authConfigFetching: true,
      };
    case EntityConstants.GET_AUTH_CONFIG_SUCCESS:
      return {
        ...state,
        fetchingAuthConfig: false,
        // fetched: true,
        authConfigFetching: false,
        authConfig: action.payload,
      };
    case EntityConstants.GET_AUTH_CONFIG_FAILURE:
      return {
        ...state,
        fetchingAuthConfig: false,
        // fetched: true,
        authConfigFetching: false,
        authConfig: [],
        errorAuth: action.payload,
      };

    case EntityConstants.CLEAR_CONFIG:
      return {
        fetching: true,
        authConfigFetching: true,
        fetched: false,
        data: [],
        error: '',
        errorAuth: '',
        authConfig: [],
        fetchingAuthConfig: true,
      };

    default: return state;
  }
};

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { capitalize } from 'lodash';

import {
  Col, Row, Button, Space,
} from 'antd';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import TagsSettingList from '../../../components/filter/TagsSettingList';

import { resetTags } from '../../actions/PtcActionsForHook';

import { projectsFilter } from './utils/projectsFilterHelper';
import { partitionNamesConfig } from '../../../api/appConfig';

const searchBoxStyles = {
  padding: '5px 8px 5px 6px',
  border: '1px solid #f3f3f3',
  borderRadius: '6px',
  backgroundColor: '#f5f7fa',
  // backgroundColor: '#f0f2f5',
  boxShadow: 'inset 1px 1px 4px -1px rgba(0, 0, 0, 0.3)',
};

export default function ProjectFilter({
  className,
  location,
  partition,
  loading,
  searchTerm,
  selectedTags = [],
  onSearch,
  onSelectTags,
  onResetFilter,
  onShowAllFilter,
  setNewProjectCreatedCallback,
  newProjectCreatedCallback,
  // showMoreCallback,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hidePartitionType = partition === partitionNamesConfig.partition3 ? ['type'] : [];

  // console.log('!! selectedFilterTags:', selectedFilterTags);

  useEffect(() => {
    if (newProjectCreatedCallback) {
      dispatch(resetTags('listOfProjectsFilter'));
      setNewProjectCreatedCallback(false);
    }
  }, [newProjectCreatedCallback]);

  return (
    <Col span={24} className={`${className} p-3`}>
      <Row>
        <Col span={24}>
          <h5>Filters</h5>
        </Col>
      </Row>

      <Row>
        <Col span={24} className="mb-2">
          <Space size={[8, 8]}>
            <Button
              className="btnWarning-outline"
              size="small"
              onClick={onResetFilter}
            >
              {capitalize(t('wms.filters.project.reset_to_defaults', 'reset to defaults'))}
            </Button>
            <Button
              className="btnWarning-outline"
              size="small"
              onClick={onShowAllFilter}
            >
              {capitalize(t('wms.filters.project.show_all', 'show all'))}
            </Button>
          </Space>
        </Col>
      </Row>

      <Row>
        <Col
          span={24}
          className="ml-1 mt-2 mb-1 pb-0 smallEntityText"
        >
          {`${capitalize(t('wms.labels.title', 'title'))}:`}
        </Col>
        <Col className="">
          <AntDesignSearchBox54origins
            styles={searchBoxStyles}
            size="small"
            variant="borderless"
            disabled={loading}
            loading={loading}
            isFocus
            placeholder="Search project by title..."
            valueSearch={searchTerm}
            onSearch={onSearch}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <TagsSettingList
            id="project_filters"
            size="small"
            allTags={projectsFilter(location, hidePartitionType)}
            defaultSelectedTags={selectedTags}
            onSelectTags={onSelectTags}
          />
        </Col>
      </Row>
    </Col>
  );
}

ProjectFilter.propTypes = {
  className: PropTypes.string,
  location: PropTypes.string,
  partition: PropTypes.string,
  loading: PropTypes.bool,
  searchTerm: PropTypes.string,
  selectedTags: PropTypes.array,
  onSearch: PropTypes.func,
  onSelectTags: PropTypes.func,
  onResetFilter: PropTypes.func,
  onShowAllFilter: PropTypes.func,
  newProjectCreatedCallback: PropTypes.bool,
  setNewProjectCreatedCallback: PropTypes.func,
  // showMoreCallback: PropTypes.func,
};

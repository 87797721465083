import keyMirror from 'keymirror';

const PublicPartitionConstants = keyMirror({
  GET_PUBLIC_USERS_ENTITIES_REQUEST: null,
  GET_PUBLIC_USERS_ENTITIES_SUCCESS: null,
  GET_PUBLIC_USERS_ENTITIES_FAILURE: null,

  UPDATE_PUBLIC_USER_ENTITY_REQUEST: null,
  UPDATE_PUBLIC_USER_ENTITY_SUCCESS: null,
  UPDATE_PUBLIC_USER_ENTITY_FAILURE: null,

  CLEAR_PUBLIC_USERS_ENTITIES: null,

  GET_PUBLIC_USERS_REPORTS_REQUEST: null,
  GET_PUBLIC_USERS_REPORTS_SUCCESS: null,
  GET_PUBLIC_USERS_REPORTS_FAILURE: null,


  GET_USER_DAY_LOG_FOR_DAY_REPORT_REQUEST: null,
  GET_USER_DAY_LOG_FOR_DAY_REPORT_SUCCESS: null,
  GET_USER_DAY_LOG_FOR_DAY_REPORT_FAILURE: null,

  CLEAR_USER_DAY_LOG_FOR_DAY_REPORT: null,
});

export default PublicPartitionConstants;

import { Spin } from 'antd';
import {
  get, head, isEmpty, isEqual,
} from 'lodash';
import {
  array, bool, func, string,
} from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import '../../issues/issueInfo/issueInfo.scss';

import { partitionNamesConfig } from '../../../../api/appConfig';

import { getVector } from '../../../actions/projectFlowActions';
import IssueInfoCard from '../../issues/issueInfo/IssueInfoCard';
import { entityPartialRead } from '../../../../entity/actions/entityActions';
import { getProjectUUID, getRecurringIssue, getRecurringIssueEntityFetching } from '../../../selectors/selectors';
import useURLParams from '../../../../hooks/useURLParams';
import RecurringIssueInfoCard from './RecurringIssueInfoCard';

export default function RecurringIssueInfoRoot({
  partitionType = partitionNamesConfig.partition1,
  hiddenView = [],
  disabledView = [],
  resetIssueCallback,
  afterDeleteABookmark,
  restoreEntityCallback,
  deleteCallback,
  updateCallback,
  onChangeRowCallback,
  customProject,
  isRecurring = true,
  setIsRecurring,
}) {
  const dispatch = useDispatch();

  const defaultIssue = useSelector(getRecurringIssue);

  const issueFetching = useSelector(getRecurringIssueEntityFetching);
  const activeProjectUUID = useSelector(getProjectUUID);

  const { currentLocationPathname, getURLParams } = useURLParams();

  const { activeRecurring } = getURLParams();

  const { uuid, params, actor } = defaultIssue;

  const [defaultData, setDefaultData] = useState({});
  const [fetching, setFetching] = useState(true);

  const loading = !uuid || fetching || isEmpty(defaultData);

  const getClearVector = async (uuidVector) => {
    const request = await dispatch(getVector({
      uuid: uuidVector,
      partition: partitionType,
      constants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    }));
    return head(request);
  };

  const getClearProject = async (uuidProject) => {
    const data = {
      entity_type: 'project',
      entity_uuid: uuidProject,
      params_fields: {
        usersSearch: 'usersSearch',
        users: 'users',
      },
    };

    const constants = [
      'GET_PROJECT_FOR_ISSUE_CARD_NO_REDUX_REQUEST',
      'GET_PROJECT_FOR_ISSUE_CARD_NO_REDUX_SUCCESS',
      'GET_PROJECT_FOR_ISSUE_CARD_NO_REDUX_FAILURE',
    ];

    const options = {
      partition: partitionType,
    };

    return dispatch(entityPartialRead({
      data,
      constants,
      options,
    }));
  };

  const setInitData = useCallback(async () => {
    const data = {
      users: get(params, 'users', []),
      usersSearch: get(params, 'usersSearch', []),
      firstActivityTimestamp: get(params, 'firstActivityTimestamp', ''),
      lastActivityTimestamp: get(params, 'lastActivityTimestamp', ''),
      description: get(params, 'description', ''),
      estimated_time: get(params, 'estimated_time', ''),
      priority: get(params, 'priority', ''),
      progress: get(params, 'progress', ''),
      tracker: get(params, 'tracker', ''),
      status: get(params, 'status', ''),
      title: get(params, 'title', ''),
      tags: get(params, 'tags', []),
      lifeTime: get(params, 'lifeTime', ''),
      softLinks: get(params, 'softLinks', []),
      vector: get(params, 'vector', ''),
      project: get(params, 'project', ''),
      rate: get(params, 'rate', ''),
      result: get(params, 'result', ''),
      links: get(params, 'links', []),
      completed: get(params, 'completed', []),
      expirationDateDev: get(params, 'expirationDateDev', ''),
      expirationDateQa: get(params, 'expirationDateQa', ''),
      changedFields: [],
      selectedVectorData: {},
      projectData: {},
      needSendNotification: true,
    };

    if (get(params, 'project', '')) {
      const res = await getClearProject(get(params, 'project', ''));

      data.projectData = head(res);
      data.usersUuidsForAssigned = get(head(res), 'usersSearch', []);
    }

    if (get(params, 'vector', '')) {
      const res = await getClearVector(get(params, 'vector', ''));
      data.selectedVectorData = res;
      data.usersUuidsForAssigned = get(res, 'params.usersSearch', []);
    }

    if (get(params, 'testCaseUuid', '') && get(params, 'testCycleUuid', '')) {
      const testCycleUuid = get(params, 'testCycleUuid', '');
      const testCaseUuid = get(params, 'testCaseUuid', '');
      const linkToTestCase = `${currentLocationPathname.substring(0, currentLocationPathname.indexOf('issuesnew'))}qa?activeProject=${activeProjectUUID}&activeCycle=${testCycleUuid}&activeCaseCycle=${testCaseUuid}`;
      const link = { link: linkToTestCase, label: 'Создано на основе test case' };
      if (!data.links.some((el) => isEqual(el, link))) {
        data.links.unshift(link);
      }
    }

    setFetching(false);
    setDefaultData(data);
  }, [JSON.stringify(defaultIssue)]);

  useEffect(() => {
    if (uuid) {
      setInitData();
    }
  }, [uuid, JSON.stringify(params)]);

  useEffect(() => {
    setFetching(issueFetching);
  }, [issueFetching]);

  useEffect(() => {
    if (setIsRecurring) {
      setIsRecurring(true)
    }
  }, [])  

  // useEffect(() => {
  //   if (!uuid && !fetching && isEmpty(defaultData) && activeRecurring) {
  //     onChangeRowCallback(activeRecurring);
  //   }
  // }, [JSON.stringify(defaultData), fetching, uuid, activeRecurring]);

  return (
    <Spin spinning={loading} size="middle">
      <div
        className="overflow-hidden"
        id="issue_info_root_component"
      >
        <RecurringIssueInfoCard
          creatorIssue={actor}
          defaultData={defaultData}
          disabledView={disabledView}
          hiddenView={hiddenView}
          partitionType={partitionType}
          resetIssueCallback={resetIssueCallback}
          afterDeleteABookmark={afterDeleteABookmark}
          restoreEntityCallback={restoreEntityCallback}
          deleteCallback={deleteCallback}
          updateCallback={updateCallback}
          customProject={customProject}
          isRecurring={isRecurring}
        />
      </div>
    </Spin>
  );
}

RecurringIssueInfoRoot.propTypes = {
  partitionType: string,
  hiddenView: array,
  disabledView: array,
  resetIssueCallback: func,
  afterDeleteABookmark: func,
  restoreEntityCallback: func,
  deleteCallback: func,
  updateCallback: func,
  onChangeRowCallback: func,
  customProject: string,
  isRecurring: bool,
  setIsRecurring: func,
};

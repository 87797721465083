import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  mdiEyeOutline,
} from '@mdi/js';
import { PlusOutlined } from '@ant-design/icons';
import Icon from '@mdi/react';
import {
  Image, Modal, Row, Upload,
} from 'antd';
import { capitalize, get } from 'lodash';
import { array, func, string } from 'prop-types';
import { saveAs } from 'file-saver';

import { useTranslation } from 'react-i18next';
import ViewVideo from '../video/ViewVideo';
import { defaultImg, errorMaxSize } from './utils';

export default function AntUploaderFile({
  onChangeFileCallback,
  customFiles,
  direction = 'col',
}) {
  const { t } = useTranslation();

  const [fileList, setFiles] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);

  let hasModalErrorSize = false;

  const previewUploadIcon = (file) => {
    if ((get(file, 'content_type') && file.content_type.indexOf('image') !== -1)
      || (get(file, 'type') && file.type.indexOf('image') !== -1)
      || (get(file, 'content_type') && file.content_type.indexOf('video') !== -1)
      || (get(file, 'type') && file.type.indexOf('video') !== -1)) {
      return (
        <Icon
          path={mdiEyeOutline}
          color="white"
          size={0.75}
          style={{
            marginTop: -3,
          }}
        />
      );
    }
  };

  const onRemoveFunc = (file) => {
    // console.log('onRemove');
    const newList = [...fileList.filter((el) => el?.uid !== file?.uid)];
    setFiles(newList);
    onChangeFileCallback({ files: newList });
  };

  const showConfirmDelete = (file) => Modal.confirm({
    title: capitalize(
      t('wms.modals.headers.removing_process', 'removing process'),
    ),
    width: '600px',
    content: capitalize(t(
      'wms.modals.body.removing_file',
      'you\'re going to remove this file. Are you sure?',
    )),
    okText: capitalize(
      t('wms.buttons.removing_process.yes', 'yes, I am'),
    ),
    okType: 'danger',
    cancelText: capitalize(
      t('wms.buttons.removing_process.no', 'no, I\'m not'),
    ),
    open: false,
    centered: true,
    maskClosable: true,
    okButtonProps: { className: 'dangerButton' },
    onOk() {
      onRemoveFunc(file);
    },
  });

  const propsUpload = useMemo(() => ({
    beforeUpload: (file, files) => {
      const sizeFiles = fileList.reduce((acc, el) => el.size + acc, 0)
        + files.reduce((acc, el) => el.size + acc, 0);
      if (sizeFiles >= 10485760) {
        if (!hasModalErrorSize) {
          hasModalErrorSize = true;
          Modal.error({
            id: 'modal_error_files_size',
            title: 'Error',
            content: 'The file/files exceeds the maximum allowed size. The maximum size is 15MB',
            afterClose: () => { hasModalErrorSize = false; },
          });
        }
      } else {
        files.forEach((file) => {
          file.url = URL.createObjectURL(file);
          file.status = 'done';
        });
        setFiles([...fileList, ...files]);
        onChangeFileCallback({ files: [...fileList, ...files] });
      }
      return false;
    },
    onPreview: (file) => {
      setPreviewFile(file);
    },
    onDownload: (file) => {
      const src = get(file, 'type')
        ? URL.createObjectURL(file)
        : `data:${file.content_type};base64, ${file.file}`;
      saveAs(`data:${file?.content_type || file?.type};base64, ${src}`, file.name);
    },
    onRemove: (file) => {
      showConfirmDelete(file);
    },
    fileList,
    multiple: true,
    listType: 'picture-card',
    showUploadList: {
      previewIcon: previewUploadIcon,
    },
  }), [fileList]);

  useEffect(() => {
    if (JSON.stringify(customFiles) !== JSON.stringify(fileList)) {
      setFiles(customFiles);
    }
  }, [JSON.stringify(customFiles)]);

  return (
    <>

      <div className={`${direction === 'row' && 'flex'} w-full`}>
        <Row className="mt-2">
          <Upload
            {...propsUpload}
            id='uploadFileButton'
          >
            <div>
              <PlusOutlined style={{ color: '#1890ff' }} />
              <div
                style={{
                  marginTop: 8,
                  color: '#1890ff',
                }}
              >
                Upload

              </div>
            </div>
          </Upload>
        </Row>
      </div>
      {((get(previewFile, 'content_type') && previewFile.content_type.indexOf('video') !== -1)
        || (get(previewFile, 'type') && previewFile.type.indexOf('video') !== -1))
        && (
          <Modal
            open={previewFile}
            centered
            footer={null}
            onCancel={() => setPreviewFile(null)}
            destroyOnClose
            width={750}
          >
            <ViewVideo
              file={previewFile}
            />
          </Modal>
        )}

      {((get(previewFile, 'content_type') && previewFile.content_type.indexOf('image') !== -1)
        || (get(previewFile, 'type') && previewFile.type.indexOf('image') !== -1))
        && (
          <Image
            width={1}
            src={get(previewFile, 'type')
              ? URL.createObjectURL(previewFile)
              : `data:${previewFile.content_type};base64, ${previewFile.file}`}
            fallback={defaultImg}
            preview={{
              visible: true,
              onVisibleChange: (visible) => {
                if (!visible) {
                  setPreviewFile(null);
                }
              },
            }}
          />
        )}
    </>
  );
}

AntUploaderFile.propTypes = {
  onChangeFileCallback: func,
  hideView: array,
  customFiles: array,
  sizeBtn: string,
  disabled: array,
  direction: string,
  customButtonName: string,
};

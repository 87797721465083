import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge, Button, Col, Dropdown, Row, Space, Tooltip,
} from 'antd';
import { isNil, propOr } from 'ramda';
import {
  mdiAccountMultipleOutline,
  mdiCarBrakeAlert,
  mdiDotsVertical,
  mdiIdentifier,
  mdiSquareEditOutline,
  mdiTableOfContents,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';

import { get, isEmpty } from 'lodash';
import moment from 'moment';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';
import TestCycleForm from './TestCycleForm';
import BtnModal from '../../BtnModalComponent';
import TestStatusDropdown from '../componentsForMultipleUse/TestStatusDropdown';
import NewTestCycleWithImportTestCases from './NewTestCycleWithImportTestCases';
import ReRunTestCycleOrCase from '../componentsForMultipleUse/ReRunTestCycleOrCase';
import CustomProgress from '../componentsForMultipleUse/CustomProgress';
import RemoveSelectedTestCycleWrapper from './RemoveSelectedTestCycleWrapper';
import ArchiveModalInTestCycle from '../../../containers/qaView/arсhiveQa/ArсhiveModalInTestCycle';
import WrapperEntityFilter from '../componentsForMultipleUse/WrapperEntityFilter';
import ExportQaAsJSON from '../exportAsJSON/ExportQaAsJSON';
import ImportFromJSON from '../exportAsJSON/ImportFromJSON';

import { antNotification, capitalizeFirstChar, formatDateTimeForWms } from '../../../../MainUtils';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import {
  currentSelectedUUID,
  getMe,
  getProject,
  getTestCycles,
  globalUpdateEntity,
  listTestCycleArchive,
} from '../../../selectors/selectors';
import {
  updateTestCycle,
} from '../../../actions/QaActions';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import { stopPropagation } from '../../../../54origins/utils54origins';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import BugTrackerModalWrapper from '../BugTrackerModalWrapper';
import WrapperListActorsForProject from '../../../../entity/components/ListOfCircleActors/WrapperListActorsForProject';
import { clearGlobalUpdateEntity } from '../../../reducers/slicers/breadcrumbsSlicer';
import CardWithFilter from '../../issuesView/CardWithFilter';

const pageSizeOptions = [5, 25, 50, 100];

function ListOfTestCyclesQa({
  title,
  deleteCycle,
  isArchive,
  createEntitiesFromJSON,
  config,
  totalCount,
  setNewConfigCallback,
  selectTestCycle,
  id,
  isTitleButton = true,
  disableStatusButton = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getListOfTestCycles,
    getListTestCycleInArchive,
    // selectTestCycle,
    partitionType,
    changeQAHistory,
  } = useContext(BugTrackerContext);

  const globalUpdate = useSelector(globalUpdateEntity);
  const listOfTestCycleArchive = useSelector(listTestCycleArchive);
  const listTestCycles = useSelector(getTestCycles);
  const curSelectedUUID = useSelector((state) => currentSelectedUUID(state, isArchive));
  const selectedProject = useSelector(getProject);
  const myUserUuid = useSelector(getMe);

  const [selectedCycle, changeSelectedCycle] = useState([]);
  const [selectedRows, changeSelectedRow] = useState([]);

  const [sizePerPage, setSizePerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [cyclesTags, setCyclesTags] = useState(null);

  const defaultListTestCycle = isArchive ? listOfTestCycleArchive : listTestCycles;
  const defaultLabelCheckbox = [
    {
      rowText: 'wms.table.headers.status',
      isRow: true,
      tags: [
        { key: 'status', value: 'unexecuted', label: capitalizeFirstChar(t(`wms.filters.${'unexecuted'}`, 'unexecuted')) },
        { key: 'status', value: 'in progress', label: capitalizeFirstChar(t(`wms.filters.${'in progress'}`, 'in progress')) },
        { key: 'status', value: 'fail', label: capitalizeFirstChar(t(`wms.filters.${'failed'}`, 'failed')) },
        { key: 'status', value: 'pass', label: capitalizeFirstChar(t(`wms.filters.${'passed'}`, 'passed')) },
        { key: 'status', value: 'block', label: capitalizeFirstChar(t(`wms.filters.${'blocked'}`, 'blocked')) },
      ],
    }];
  const updateAfterRemove = () => {
    const clearName = isArchive ? 'clearCycleArchive' : 'clearCycle';
    for (const item of selectedCycle) {
      if (isArchive) {
        if (curSelectedUUID === item) {
          deleteCycle();
          changeQAHistory({ entity: clearName, clearCases: true });
        }

        getListTestCycleInArchive();
      } else if (curSelectedUUID === item) {
        deleteCycle(item, false);
        changeQAHistory({ entity: clearName, clearCases: true });
      }

      getListOfTestCycles();
    }

    changeSelectedCycle([]);
  };

  const deleteTestCycle = (uuid) => {
    const clearName = isArchive ? 'clearCycleArchive' : 'clearCycle';
    if (isArchive) {
      deleteCycle();
    } else {
      deleteCycle(uuid);
    }

    changeSelectedCycle([]);

    if (curSelectedUUID === uuid) {
      changeQAHistory({ entity: clearName, clearCases: true });
    }
  };

  const selectTestCycleFunc = (uuid, reRun = false, newCycle = false) => {
    changeSelectedRow(uuid);

    if (isArchive && !reRun) {
      selectTestCycle(uuid);
    } else if (reRun) {
      selectTestCycle(uuid);
    } else if (newCycle) {
      selectTestCycle(uuid);
    } else {
      selectTestCycle(uuid);
    }
  };

  const reRunTestCycle = (uuid, reRun) => {
    selectTestCycleFunc(uuid, reRun);

    changeSelectedCycle([]);
  };

  const colorStyle = isArchive ? 'rgb(226, 200, 8)' : '#1890ff';

  const setSearchIssue = (value) => {
    const data = {
      ...config,
      offset: 0,
      search_data: {
        value,
        ignore_case: true,
        fields: {
          params: ['title', 'customId'],
        },
      },
    };
    setNewConfigCallback(id, data);
    setCurrentPage(1);
  };

  const searchInTitle = (
    <Col className="d-flex align-item-end minWidth130">
      <AntDesignSearchBox54origins
        onSearch={setSearchIssue}
        placeholder="Search"
      />
    </Col>
  );

  const onChangeStatusCallback = (status, row) => {
    const newHelperInStatus = (
      (row.progress.unexecuted === 0
        && row.progress.progress === 0)
      && row.progress.quantityCase !== 0
    );

    const data = {
      entity_type: 'testCycle',
      uuid: row?.uuid,
      partition: partitionType,
      params: {
        status,
        helperInStatus: status !== 'in progress' ? false : newHelperInStatus,
        executed_by: status !== 'in progress' ? myUserUuid : '',
        executed_on: status !== 'in progress' ? formatDateTimeForWms(moment()) : '',
      },
      customConstants: ['REQUEST', selectedRows === row?.uuid ? ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE : '', 'FAILURE'],
    };

    dispatch(updateTestCycle(data))
      .then(() => {
        antNotification('success', t('notifications.text.success.change_status_test_cycle', 'Test cycles status has been changed'));
        getListOfTestCycles();
      });
  };

  const columns = [
    {
      dataIndex: ['id'],
      title: (<Icon path={mdiIdentifier} size={1} />),
      render: (cell, row) => (
        <Tooltip
          className="text-no-wrap fontSize12"
        >
          {!isNil(cell) ? cell : 'key'}
        </Tooltip>
      ),
    },
    {
      dataIndex: ['title'],
      key: 'title',
      title: searchInTitle,
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
        />
      ),
    },
    {
      dataIndex: ['progress'],
      title: capitalizeFirstChar(t('wms.table.headers.progress', 'Progress')),
      render: (cell) => cell && <CustomProgress cell={cell} />,
    },
    {
      dataIndex: ['status'],
      key: 'status',
      sorter: true,
      title: capitalizeFirstChar(t('wms.table.headers.status', 'Status')),
      render: (cell, row) => (
        <div
          style={row.helperInStatus
            ? { border: '2px solid red', padding: '2px', borderRadius: '5px' }
            : {}}
          className="d-flex align-items-center"
        >
          <TestStatusDropdown
            currentStatus={cell}
            row={row}
            disableStatusButton={disableStatusButton}
            type={isArchive ? 'archive' : 'cycle'}
            onChangeStatusCallback={onChangeStatusCallback}
          />

          {row.helperInStatus
            && (
              <Tooltip
                title={row.helperInStatus && t(
                  'wms.tooltips.update_cycle_status',
                  'Some of your test cases have been deleted. Please update the test cycle status',
                )}
              >
                <Icon
                  color="red"
                  path={mdiCarBrakeAlert}
                  size={0.8}
                  className="ml-1"
                />
              </Tooltip>
            )}
        </div>

      ),
    },
    // {
    //   dataIndex: ['uuid'],
    //   title: (
    //     <Tooltip
    //       placement="top"
    //       title={capitalizeFirstChar(t('wms.table.headers.assigned', 'Assigned'))}
    //     >
    //       <Icon path={mdiAccountMultipleOutline} size={1} />
    //     </Tooltip>
    //   ),
    //   render: (cell, row) => ((!isNil(
    //     row.assignToUser,
    //   ) && row.assignToUser.length !== 0)
    //     ? (
    //       <WrapperListActorsForProject
    //         classNames="d-flex flex-nowrap"
    //         countOfActors={2}
    //         projectUsers={get(selectedProject, '[0].params.users', [])}
    //         actors={get(row, 'assignToUser', [])}
    //       />
    //     )
    //     : ''),
    // },
    {
      dataIndex: ['cases_count'],
      key: 'cases_count',
      sorter: true,
      title: (
        <Tooltip
          placement="top"
          title={capitalizeFirstChar(t('wms.table.headers.cases', 'Cases'))}
        >
          <Icon path={mdiTableOfContents} size={1} />
        </Tooltip>
      ),
      render: (cell) => (
        <Badge
          count={cell}
          showZero
          style={{ backgroundColor: colorStyle }}
        />
      ),
    },
    {
      dataIndex: ['params'],
      title: capitalizeFirstChar(t('wms.table.headers.actions', 'actions')),
      render: (cell, row) => {
        const disabled = (!isNil(row.status) && (row.status !== 'unexecuted' && row.status !== 'in progress'));

        const deleteCycleCallback = () => {
          deleteTestCycle(row.uuid);
        };

        return (
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <ReRunTestCycleOrCase
                      title={capitalizeFirstChar(t('wms.modals.headers.new_test_cycle', 'new test cycle'))}
                      isArchive={isArchive}
                      disabled={row?.status === 'unexecuted' || +row?.cases_count === 0}
                      titleTooltip={
                        !isArchive
                          ? capitalizeFirstChar(t('wms.tooltips.re-run_test_cycle', 're-run test cycles'))
                          : capitalizeFirstChar(t('wms.tooltips.re-run_test_cycle_archive', 're-run test cycle from archive '))
                      }
                    >
                      <TestCycleForm
                        defaultTitle={propOr('', 'title', row)}
                        defaultDescription={propOr('', 'description', row)}
                        assignToUser={propOr([], 'assignToUser', row)}
                        selectVectorDefault={propOr([], 'vector', row)}
                        entityUUID={row.uuid}
                        selectedVectorDefault={reRunTestCycle}
                        typeOfForm="reRun"
                      />
                    </ReRunTestCycleOrCase>
                  ),
                  key: '0',
                },
                {
                  label: (
                    <BtnModal
                      footer={[]}
                      title={capitalizeFirstChar(
                        t('wms.modals.headers.edit_test_cycle', 'edit test cycles'),
                      )}
                      width="50%"
                      btnComponent={(
                        <BaseButton
                          className={`ant-btn ${(!isArchive && !disabled) && 'customAntOutPrimaryBtn'} ant-btn-default ant-btn-sm`}
                          disabled={isArchive || disabled}
                          style={{ borderRadius: '4px' }}
                        >
                          <Tooltip
                            title={capitalizeFirstChar(
                              t('wms.tooltips.edit', 'edit'),
                            )}
                          >
                            <Icon path={mdiSquareEditOutline} size={0.7} />
                          </Tooltip>
                        </BaseButton>
                      )}
                    >
                      <TestCycleForm
                        typeOfForm="editForm"
                        defaultTitle={propOr('', 'title', row)}
                        defaultDescription={propOr('', 'description', row)}
                        defaultStartDate={propOr('', 'start_date', row)}
                        defaultDueDate={propOr('', 'end_date', row)}
                        assignToUser={propOr([], 'assignToUser', row)}
                        selectedVectorDefault={propOr([], 'vector', row)}
                        defaultTypeOfTesting={propOr([], 'typeOfTesting', row)}
                        entityUUID={row.uuid}
                        selectTestCycle={selectTestCycleFunc}
                      />
                    </BtnModal>
                  ),
                  key: '1',
                },
                {
                  label: (
                    <BugTrackerModalWrapper counterBug={row?.counterBug} uuid={row.uuid} />
                  ),
                  key: '2',
                },
                {
                  label: (
                    (!isArchive && disabled) ? (
                      <ArchiveModalInTestCycle
                        currentCycles={[row]}
                        selectedCycleOrArchiveCycle={curSelectedUUID}
                        type="cycle"
                      />
                    ) : (
                      <HardDeleteEntityContainer
                        name="cycle"
                        labelMode={false}
                        entityUUID={row.uuid}
                        className="ant-btn customAntOutDangerBtn ant-btn-default"
                        styleBtn={{
                          borderRadius: '4px',
                        }}
                        actionForEntity={deleteCycleCallback}
                        checkHardDelete={false}
                        entityType={row.entity_type}
                        type="entity"
                        sizeBtn="small"
                        hideLabel
                        antNotificationSuccess
                        partitionType={partitionType}
                      />
                    )
                  ),
                  key: '3',
                },
              ],
            }}
            onClick={stopPropagation}
          >
            <Button
              size="small"
              type="primary"
              ghost
            >
              <Icon path={mdiDotsVertical} size={0.8} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const changeLimit = (data) => {
    setSizePerPage(data);
    setCurrentPage(1);

    const param = {
      ...config,
      limit: data,
      offset: 0,
    };
    setNewConfigCallback(id, param);
  };

  const changePaginationOptions = ({
    pageLimit,
    currentPage,
    offset,
  }) => {
    const data = {
      ...config,
      offset,
      limit: pageLimit,
    };
    setNewConfigCallback(id, data);
    setCurrentPage(currentPage);
  };

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys) => {
      changeSelectedCycle(selectedRowKeys);
    },
    selectedRowKeys: selectedCycle,
  };

  const currentSortCallback = (dataSort) => {
    let data;
    if (!get(dataSort, 'order')) {
      data = {
        ...config,
        orderBy: 'created',
        order: 'desc',
      };
    } else {
      data = {
        ...config,
        ...dataSort,
      };
    }
    setNewConfigCallback(id, data);
  };

  const onRow = (record) => ({
    onClick: () => {
      const { uuid } = record || {};

      selectTestCycleFunc(uuid);
    },
  });

  const rowClassName = (row) => `not-hide-checkbox
  ${!isArchive ? 'select_test_cycle_row' : 'select_test_cycle_archive_row'} ${selectedRows === get(row, 'uuid')
  ? 'selected'
  : ''} `;

  const onSelectTagsCallback = (tags) => {
    const tagsValues = tags.map((el) => el.value);
    setCurrentPage(1);
    const param = {
      ...config,
      limit: sizePerPage,
      offset: 0,
      params: { archive: isArchive },
    };

    if (!isEmpty(tagsValues)) {
      param.params.status = tagsValues;
    }

    setNewConfigCallback(id, param);
    setCyclesTags(tags);
  };

  useEffect(() => {
    if (curSelectedUUID !== selectedCycle) {
      changeSelectedRow(curSelectedUUID);
    }
  }, [curSelectedUUID]);

  useEffect(() => {
    if (
      globalUpdate?.entityType === id
      && globalUpdate?.action === 'delete'
      && selectedCycle?.length
      && selectedCycle?.includes(globalUpdate?.uuid)) {
      changeSelectedCycle((prev) => [...prev.filter((i) => i !== globalUpdate?.uuid)]);
      dispatch(clearGlobalUpdateEntity());
    }
  }, [globalUpdate.entityType]);

  return (
    <Row>
      <CardWithFilter
        id="cycle"
        title={t('wms.entities.vector.vectors_filter', { noun: t('wms.noun.filter') }).toUpperCase()}
        mainClassName="vector-card-issues-view"
        buttonCollapseClassName="header-collapse-filter-vector"
        customSelectedTags={cyclesTags}
        getSelectedTagsCallback={onSelectTagsCallback}
        allTags={defaultLabelCheckbox}
      >
        <Col span={24}>
          <Row>
            <Col span={10} className="d-flex align-items-center flex-wrap">
              <h6 className="m-0 mr-2 text-no-wrap">
                {title}
                <Badge
                  count={defaultListTestCycle.length}
                  showZero
                  style={{ backgroundColor: colorStyle }}
                  className="ml-1"
                />
              </h6>
              {(!isArchive && isTitleButton) && (
              <NewTestCycleWithImportTestCases
                hideBtnComponent
                selectTestCycleCallback={selectTestCycleFunc}
              />
              )}
              {isTitleButton ? (
                <Dropdown
                  className="ml-2"
                  menu={{
                    items: [
                      {
                        label: (
                          <div onClick={stopPropagation}>
                            <ImportFromJSON
                              importCallback={createEntitiesFromJSON}
                            />
                          </div>
                        ),
                        key: '0',
                      },
                      {
                        label: (
                          <div onClick={stopPropagation}>
                            <Badge count={selectedCycle.length}>
                              <ExportQaAsJSON
                                typeOfEntity="testCycle"
                                customUuids={selectedCycle}
                                entityRequestParams={isArchive ? { archive: true } : {}}
                              />
                            </Badge>
                          </div>
                        ),
                        key: '1',
                      },
                      {
                        label: (
                          <div onClick={stopPropagation}>
                            <RemoveSelectedTestCycleWrapper
                              updateAfterRemove={updateAfterRemove}
                              isArchive={isArchive}
                              selectRows={selectedCycle}
                              disabled={!selectedCycle.length}
                            />
                          </div>
                        ),
                        key: '2',
                      },
                    ],
                  }}
                  onClick={stopPropagation}
                >
                  <Badge count={selectedCycle.length}>
                    <Button
                      size="small"
                      type="primary"
                      ghost
                    >
                      <Icon path={mdiDotsVertical} size={0.8} />
                    </Button>
                  </Badge>
                </Dropdown>
              ) : null}
            </Col>
          </Row>
        </Col>

        <Col span={28} className="wrapper_main_card_table mt-2 mb-3">
          <BaseTableWithPagination
          // loading={loading}
            newCurrentPage={currentPage}
            pageLimit={sizePerPage}
            changePageLimit={changeLimit}
            useCustomPagination
            columns={columns}
            data={defaultListTestCycle}
            rowKey="uuid"
            rowSelection={rowSelection}
            size="small"
            pageSizeOptions={pageSizeOptions}
            total={totalCount}
            getPaginationOptions={changePaginationOptions}
            onRow={onRow}
            rowClassName={rowClassName}
            currentSortCallback={currentSortCallback}
            headerRowClassName={`not-hide-checkbox ${isArchive ? 'header-table-test-cycle-archive' : 'header-table-test-cycle'}`}
          />
        </Col>
      </CardWithFilter>
    </Row>
  );
}

ListOfTestCyclesQa.propTypes = {};

export default ListOfTestCyclesQa;

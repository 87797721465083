import React from 'react';
import { useTranslation } from 'react-i18next/';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import TagsSettingList from '../../../../components/filter/TagsSettingList';
import { getProjectParams } from '../../../selectors/selectors';
import { projectDefaultQaTags } from '../../project/constants/ProjectStatusOptions';
import { useSelector } from 'react-redux';

function WrapperEntityFilter({ isArchive, type, onSelectTagsCallback }) {
  const { t } = useTranslation();
  const projectParams = useSelector(getProjectParams);

  const {
    caseTags = [],
  } = projectParams || {};

  const defaultLabelCheckboxIsArchive = [
    { tags: [{ value: 'all', label: capitalize(t('wms.filters.all', 'all')) }], isReset: true },
    {
      tags: [
        { value: 'fail', label: capitalize(t(`wms.filters.${'failed'}`, 'failed')) },
        { value: 'pass', label: capitalize(t(`wms.filters.${'passed'}`, 'passed')) },
        { value: 'block', label: capitalize(t(`wms.filters.${'blocked'}`, 'blocked')) },
      ],
    },
    {
      tags: [...caseTags.map((tag) => ({
        key: 'tags',
        value: tag.toLowerCase(),
        label: tag,
      })), ...projectDefaultQaTags.map((tag) => ({
        key: 'tags',
        value: tag.toLowerCase(),
        label: tag,
      }))],
    },
  ];

  const defaultLabelCheckbox = [
    { tags: [{ value: 'all', label: capitalize(t('wms.filters.all', 'all')) }], isReset: true },
    {
      tags: [
        { value: 'unexecuted', label: capitalize(t(`wms.filters.${'unexecuted'}`, 'unexecuted')) },
        { value: 'in progress', label: capitalize(t(`wms.filters.${'in progress'}`, 'in progress')) },
        { value: 'fail', label: capitalize(t(`wms.filters.${'failed'}`, 'failed')) },
        { value: 'pass', label: capitalize(t(`wms.filters.${'passed'}`, 'passed')) },
        { value: 'block', label: capitalize(t(`wms.filters.${'blocked'}`, 'blocked')) },
      ],
    },
  ];

  const onSelectTags = (data = []) => onSelectTagsCallback(data);

  let allTags;
  let id;

  if (type === 'case') {
    allTags = defaultLabelCheckbox;
    allTags.push({
      tags: [...caseTags.map((tag) => ({
        key: 'tags',
        value: tag,
        label: tag,
      })), ...projectDefaultQaTags.map((tag) => ({
        key: 'tags',
        value: tag,
        label: tag,
      }))],
    });
    id = isArchive ? 'ListArchiveCaseInCycle' : 'ListCaseInCycle';
  } else {
    allTags = isArchive ? defaultLabelCheckboxIsArchive : defaultLabelCheckbox;
    id = isArchive ? 'ListCyclesArchive' : 'ListCycles';
  }

  const defaultTag = [];

  return (
    <TagsSettingList
      onSelectTags={onSelectTags}
      allTags={allTags}
      defaultSelectedTags={defaultTag}
      customClassName="flex items-center justify-end"
      size="small"
      id={id}
    />
  );
}
WrapperEntityFilter.propTypes = {
  isArchive: PropTypes.bool,
  onSelectTagsCallback: PropTypes.func,
  type: PropTypes.string,
};

export default WrapperEntityFilter;

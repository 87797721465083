import Quill from "quill";
import QuillTableUI from 'quill-table-ui'


export function handleTable() {
    const quill = this.quill
    const table = quill.getModule('table')
    table.insertTable(4, 4, 0);
}

Quill.register({'modules/tableUI': QuillTableUI}, true)


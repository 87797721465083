import React, { useCallback, useContext, useEffect, useState, useTransition } from "react";
import DocView from "../docView&ideas/docView/DocView";
import {
    head, prop, propOr, path
  } from 'ramda';
import {  capitalize, concat, get, isEmpty, isPlainObject, } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { getDocumentContent, getProjDocOrIdeas } from "../../selectors/selectors";
import PropTypes from "prop-types";
import { setFlagNotSaveData, setFlagOpenConfirm } from "../../../components/un-save-data-confirm/unSaveDataSlicer";
import { checkActualStateRequest,  getListDocumentsOrIdea, updateDocument } from "../../actions/DocumentsActions";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { antNotification } from "../../../MainUtils";
import { entityCreate, incrementAndGetCount } from "../../../entity/actions/entityActions";
import moment from "moment";
import { ProjectTypeCommonConstants } from "../../constants/Constants";
import useTableFilter from "../../../components/_ui/hooks/useTableFilter";
import { defaultDocumentFilter } from "../docView&ideas/docView/constants/documentFilterOptions";
import { ProjectMainLayerContext } from "../../context/ProjectFlowListOfContexts";
import useURLParams from "../../../hooks/useURLParams";
import { clearDocument } from "../../actions/PtcActionsForHook";
import { getPatchingAction, getPatchingRequest } from "../docView&ideas/helpers/statesAndPatches/getPatchingRequest";
import { getUserName } from "../../../entity/selectors/selectors";
import { sendSystemComment, updateListComment, updateProjectRequest } from "../../actions/projectFlowActions";
import ExpandModeLayout, { EXPAND_MODE } from "../commonComponents/expandModeLayout/ExpandModeLayout";
import DocHistoryComponent from "../docView&ideas/docsHistory/DocHistoryComponent";
import { setQuillDocReady } from "../../reducers/slicers/quillSlicer";

function ActiveDocumentModal ({
    defaultPartition,
    projectUUID,
    activeDoc,
    activeNote,
    activeSheet,
    activeDocumentModal,
    setActiveDocumentModal,
    activeDocument,
    activeDocumentType,
    setSaveDocument
}) {

    const initialDocumentState = {
        title: null,
        lifeTime: null,
        change: false,
      };

      const initialState = {
        notSaved: false,
        confirmNotSaved: false,
      };

      const defaultDocumentOrder = {
        orderBy: 'created',
        order: 'desc',
      };
      
      const defaultDocumentPagination = {
        currentPage: 1,
        limit: 25,
        offset: 0,
      };

      const defaultDocumentsConfig = {
        ...defaultDocumentPagination,
        ...defaultDocumentOrder,
        ...documentFilterConfig,
      };

      const initialCurrentDocumentData = {
        title: null,
        status: null,
        lifeTime: null,
        content: null,
      };

      const {
        filterConfig: documentFilterConfig,
        changeFilterConfig: changeDocumentFilterConfig,
      } = useTableFilter('document', defaultDocumentFilter);

    const { t } = useTransition();
    const dispatch = useDispatch()

    const {
        createNewDocumentState,
        getDocument,
        getDocumentWithOptionalData,
        initialPathForHistory,
      } = useContext(ProjectMainLayerContext);

      const {
        routeNavigateTo,
        clearSearchParams,
        getURLParams,
        addAndRemoveSearchParams,
      } = useURLParams();
    
    const [docFetching, setDocFetching] = useState(false);
    const documentContent = useSelector(getDocumentContent);
    const projDocOrIdeas = useSelector(getProjDocOrIdeas);
    const userFullname = useSelector(getUserName);
    const [documentState, setDocumentState] = useState(initialDocumentState);
    const [isShowHistoryModal, setIsShowHistoryModal] = useState(false);
    const [documentsConfig, setDocumentsConfig] = useState(defaultDocumentsConfig);


    const [docServerActualState, setDocServerActualState] = useState('');
    const [currentDocumentData, setCurrentDocumentData] = useState(null);

    const [totalCountDocs, setTotalCountDocs] = useState(0);
    const [notSavedData, setNotSavedData] = useState(initialState);
    const [resetAndCloseInfoCard, setResetAndCloseInfoCard] = useState(false);
    const [showMergeDoc, setShowMergeDoc] = useState(false);
    const [isMerged, setIsMerged] = useState(false);
    const [serverContent, setServerContent] = useState(null);


    const [isEditedDescription, setIsEditedDescription] = useState(false);
    const docParams = propOr('', 'params', head(projDocOrIdeas));
    const hasOptionalData = propOr(false, 'optional_data', head(projDocOrIdeas));
    const defaultDescription = prop('text', docParams);
    const entityDocType = get(projDocOrIdeas, '[0].entity_type', '');
    const hasNotSavedData = prop('change', documentState) || isEditedDescription;
    const actualState = propOr('', 'actualState', docParams);

    const [CKEditor, getCKEditor] = useState(null);
    const [Spreadsheet, getSpreadsheet] = useState(null);
    const [isOpenComments, setIsOpenComments] = useState(false);
    const [showEditor, setShowEditor] = useState(true);    

    const getClearDocument = async (uuid, type) => {
      const checkIsDoc = type === 'projectDocument' || type === 'projectIdea';
      if (checkIsDoc) {
        dispatch(setQuillDocReady(false));
      }
      setIsOpenComments(false);
      setShowEditor(false);
      setDocFetching(true);    
      await getDocumentData(uuid, type).then(() => {
        setShowEditor(true);
        setDocFetching(false);
        }).catch((e) => {       
          const checkError = e?.status === 404 && e?.message === 'There is no entity with such uuid';
          if (checkError) {
            console.log("check");
            
          }   
        }
      );    
    };
    
    useEffect(() => {
        if(activeDocument) {
        getClearDocument(activeDocument, activeDocumentType)
        }
    }, [activeDocument])


    const loadSystemDocuments = async (config) => {
        const requestConfig = {
          ...config,
          offset: 0,
          params: {
            ...config?.params,
            status: ['system'],
          }
        };
    
        return dispatch(getListDocumentsOrIdea(
          requestConfig,
          defaultPartition,
        ));
      };
    
      const loadOmitSystemDocuments = async (config, constants) => {
        const requestConfig = {
          ...config,
          params: {
            ...get(config, 'params', {}),
            status__not: concat(get(config, 'params.status__not', []), ['system']),
          },
        };
    
        return dispatch(getListDocumentsOrIdea(
          requestConfig,
          defaultPartition,
          constants,
        ));
      };

    const loadAllDocuments = async (properties) => {
        const {
          entity_type,
          status,
          search,
          orderBy,
          order,
          offset,
          limit,
        } = properties || {};
    
        const config = {
          parent: projectUUID,
          entity_type,
          params: {},
          orderBy,
          order,
          offset,
          limit,
        };
    
    
        if (status && status?.length) {
          config.params.status = status;
        }
    
        if (search) {
          config.search_data = {
            value: search,
            ignore_case: true,
            fields: {
              params: ['title'],
            },
    
          };
        }
    
        if (!projectUUID) return;
        // console.log('docs config:', config);
    
        let data = {
          data: [],
          total: 0,
        };
    
        const needLoadSystemDocuments = !entity_type || entity_type?.includes('projectDocument');
    
        if (needLoadSystemDocuments) {
          const systemDocuments = await loadSystemDocuments(config);
          const otherDocuments = await loadOmitSystemDocuments(
            config,
            [
              ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_REQUEST,
              ProjectTypeCommonConstants.FETCH_AND_PUSH_DOCUMENTS_AND_IDEA_SUCCESS,
              ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_FAILURE,
            ],
          );
    
          data = {
            data: concat(systemDocuments.data, otherDocuments.data),
            total: systemDocuments.total + otherDocuments.total,
          }
          setTotalCountDocs(prop('total', data));
        } else data = await loadOmitSystemDocuments(config);
    
        // console.log(data)
        setTotalCountDocs(prop('total', data));
        return prop('data', data);
      };

    const getDescription = (entityType) => {
        let descriptionData;
        switch (entityType) {
          case 'projectDocument':
          case 'projectIdea': {
            descriptionData = CKEditor?.getData();
            break;
          }
          case 'projectSpreadsheet': {
            descriptionData = Spreadsheet?.getJson();
            // descriptionData.columns = Spreadsheet?.getConfig().columns;
            // descriptionData = Spreadsheet?.getData();
            break;
          }
          default: descriptionData = null;
        }
        return descriptionData;
      };

      const setNotSavedDataCallback = (data) => {
        setNotSavedData(data);
        setIsEditedDescription(false);
      };

      const setIsNotSavedDataCallback = useCallback((bool) => {
        setNotSavedData((prev) => ({
          ...prev,
          notSaved: bool,
        }));
      }, []);

      const closeUnsaveDataConfirm = () => {
        dispatch(setFlagOpenConfirm(false));
        dispatch(setFlagNotSaveData(false));
        setNotSavedData(initialState);
      };

      const changeDocumentState = (newState) => {
        const { change } = newState;
    
        setDocumentState((prevState) => ({
          ...prevState,
          ...newState,
        }));
    
        setIsNotSavedDataCallback?.(change);
      };

      const changeDocsHistory = (key, uuid) => {
        // const path = `${initialPathForHistory}${pageUrl}`;
    
        // console.log('changeDocsHistory', key, uuid);
    
        switch (key) {
          case 'projectDocument':
            addAndRemoveSearchParams({ activeDoc: uuid }, ['activeNote', 'activeSheet']);
            break;
    
          case 'projectIdea':
            addAndRemoveSearchParams({ activeNote: uuid }, ['activeDoc', 'activeSheet']);
            break;
    
          case 'projectSpreadsheet':
            addAndRemoveSearchParams({ activeSheet: uuid }, ['activeDoc', 'activeNote']);
            break;
    
          case 'restore':
            // path.search = `?activeProject=${projectUUID}`;
            // routeNavigateTo(path);
            clearSearchParams(['activeDoc', 'activeSheet', 'activeNote']);
            break;
    
          default:
            break;
        }
      };
      const selectedDocument = (uuid, type) => changeDocsHistory(type, uuid);

      const resetSelectedDocument = (resetInfoCard = true) => {
        if (resetInfoCard) {
          // setNotSavedData(initialState);
          changeDocumentState(initialDocumentState);
          setIsEditedDescription(false);
          setResetAndCloseInfoCard(true);
        }
    
        loadAllDocuments(documentsConfig);
      };
    

      const createNewDoc = async (type, withContent, config = {}) => {
        let defaultTitle;
        let fieldName;
        switch (type) {
          case 'projectIdea': {
            defaultTitle = 'New Note';
            fieldName = 'note';
            break;
          }
          case 'projectSpreadsheet': {
            defaultTitle = 'New Spreadsheet';
            fieldName = 'spreadsheet';
            break;
          }
          case 'projectDocument':
          default: {
            defaultTitle = 'New Document';
            fieldName = 'document';
            break;
          }
        }
    
        const {
          // description: configDescription,
          title: configTitle,
          lifeTime: configLifeTime,
          status: configStatus,
          content: configContent,
          stateID: configActualState,
          projectUUUID: configProjectUUID,
        } = config || {};
    
        const { increment: docID } = await dispatch(incrementAndGetCount({
          entity_uuid: configProjectUUID || projectUUID,
          field_name: fieldName,
        }, defaultPartition)) || {};
    
        const newTitle = configTitle || defaultTitle;
    
        const data = {
          entity_type: type,
          parent: configProjectUUID || projectUUID,
          params: {
            id: docID,
            status: configStatus || 'created',
            title: newTitle,
            text: '',
            lifeTime: configLifeTime || type === 'projectIdea'
              ? moment().add('2', 'week').format('YYYY-MM-DD')
              : moment().add('1', 'year').format('YYYY-MM-DD'),
            updated: moment().locale('en').format('LLL'),
          },
        };
    
        if (type === 'projectSpreadsheet') {
          data.params.text = configContent || '';
          data.params.actualState = '0';
        } else {
          data.params.actualState = withContent ? configActualState : '0';
        }
    
        // console.log('DATA:', data);
    
        const constants = [
          ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_REQUEST,
          ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_SUCCESS,
          ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_FAILURE,
        ];
    
        const options = {
          partition: defaultPartition,
        };
    
        return dispatch(entityCreate({
          data,
          constants,
          options,
        }));
      };

      const getDocumentData = async (uuid, type) => {
        await getDocumentWithOptionalData(uuid, type);
      };

      const documentDoNotExistModal = (type, config) => {
        let message;
        let okText;
        switch (type) {
          case 'projectIdea': {
            message = capitalize(t('wms.labels.the_idea_that_you_modify'));
            okText = capitalize(t('wms.labels.create_new_idea'));
            break;
          }
          case 'projectSpreadsheet': {
            message = capitalize(t('wms.labels.the_sheet_that_you_modify'));
            okText = capitalize(t('wms.labels.create_new_sheet'));
            break;
          }
          case 'projectDocument':
          default: {
            message = capitalize(t('wms.labels.the_doc_that_you_modify'));
            okText = capitalize(t('wms.labels.create_new_doc', { noun: t('wms.noun.document') }));
            break;
          }
        }
    
        Modal.confirm({
          title: capitalize(t('wms.noun.error', 'error')),
          content: message,
          icon: <ExclamationCircleOutlined />,
          okText,
          cancelText: capitalize(t('wms.verb.cancel', 'cancel')),
          onOk: async () => {
            await createNewDocumentWithContent({
              type,
              data: config,
              open: true,
            });
            resetSelectedDocument(true);
          },
          onCancel: () => resetSelectedDocument(true),
        });
      };

      const deleteDocumentCallback = (uuid, doc) => {
        const { type } = prop('params', doc) || {};
        const selectedDocUuid = activeDoc || activeNote || activeSheet;
       
      
        resetSelectedDocument(selectedDocUuid === uuid);
    
        if (type === 'project description' || type === 'project infrastructure') {
          const paramsKey = (type === 'project description' && 'descriptionDoc') || (type === 'project infrastructure' && 'infrastructureDoc');
          const projectConfig = {
            uuid: projectUUID,
            params: {
              [paramsKey]: {
                uuid: '',
                title: '',
              },
            },
            partition: defaultPartition,
          };
          return dispatch(updateProjectRequest(projectConfig))
            .then(() => {
              antNotification('success', t('wms.noun.success', 'Success'));
             
            });
        }
      setActiveDocumentModal(false)
      };

      const changeDescriptionCallback = () => {
        if (!isEditedDescription) {
          setIsEditedDescription(true);
          setIsNotSavedDataCallback?.(true);
        }
      };
    
      
      const createNewDocumentWithContent = async ({
        type,
        data = {},
        open = false,
      }) => {
        // console.log('DATA', data);
        try {
          const config = {
            ...data,
            stateID: '1',
          };
    
          const withContent = isPlainObject(data) && !isEmpty(data);
    
          const [documentData] = await createNewDoc(type, withContent, config);
          const newDocumentUUID = documentData?.uuid;
    
          if (withContent && type !== 'projectSpreadsheet') {
            await createNewDocumentState(newDocumentUUID, config);
          }
    
          antNotification('success', t('wms.noun.success', 'Success'));
          loadAllDocuments(documentsConfig);
    
          if (open) selectedDocument(newDocumentUUID, type);
    
          return newDocumentUUID;
        } catch (error) {
          console.error(`Failed to create new document: ${error.message}`);
          return null;
        }
      };

      const checkDocumentForMerge = async (entityUUID, entityType) => {
        const config = {
          uuid: entityUUID,
          partition: defaultPartition,
        };
    
        const response = await dispatch(checkActualStateRequest(config))
          .catch(() => {
            const oldDocumentData = {
              title: documentState.title ?? prop('title', docParams),
              status: documentState.status ?? prop('status', docParams),
              lifeTime: documentState.lifeTime ?? prop('lifeTime', docParams),
              content: getDescription(entityType),
              projectUUID: projectUUID,
            };
            documentDoNotExistModal(entityType, oldDocumentData);
            dispatch(clearDocument);
            loadAllDocuments(documentsConfig);
          });
    
        if (!response) return false;
        if (entityType === 'projectSpreadsheet') return true;
    
        const [{ actualState: serverActualState }] = response;
    
        // console.log('Server actual state:', serverActualState);
        // console.log('Current actual state:', actualState);
    
        setDocServerActualState(serverActualState);
    
        if (serverActualState && serverActualState !== actualState) {
          setCurrentDocumentData({
            title: documentState.title ?? prop('title', docParams),
            status: documentState.status ?? prop('status', docParams),
            lifeTime: documentState.lifeTime ?? prop('lifeTime', docParams),
            content: getDescription(entityType),
          });
          setShowMergeDoc(true);
          return false;
        }
    
        setCurrentDocumentData(initialCurrentDocumentData);
        return true;
      };

      const makePatchAndGetRequest = (entityUUID, defaultContent, changedContent) => {
        const baseContent = isMerged ? serverContent : defaultContent;
    
        const { action, patch } = getPatchingAction(baseContent, changedContent, actualState);
    
        const data = {
          docUUID: entityUUID,
          actualState,
          newContent: changedContent,
          patch,
          createdBy: userFullname,
          partition: defaultPartition,
        };
    
        // console.log('data:', data);
    
        const { request, updatedActualState } = getPatchingRequest(action, data);
        return [request, updatedActualState];
      };

  const sendCustomComment = async (params, action) => {
    const comment = {
      entity: params.entity,
      action,
      param: params.title,
      value: params.value,
    };

    await dispatch(sendSystemComment(params.uuid, comment, defaultPartition));
    dispatch(updateListComment(params.uuid));
  };


  const updateProjectDocument = async (params, currentEntityType, defaultLifeTime) => {
    const { entity_uuid: currentUUID } = params;

    const config = {
      params: {
        ...params,
        params: {
          ...params.params,
        },
      },
      partition: defaultPartition,
    };
    console.log("config", params);
    
    // console.log('config', config);
    // console.log('updateProjectDocument params', params);

    setDocFetching(true);
    await dispatch(updateDocument(config)).then(() => {
      // console.log('params?.params', params?.params);
      const projectDocumentKey = (params?.params?.type === 'project description' && 'descriptionDoc')
        || (params?.params?.type === 'project infrastructure' && 'infrastructureDoc') || null;
      if (projectDocumentKey) {
        const projectConfig = {
          uuid: projectUUID,
          params: {
            [projectDocumentKey]: {
              uuid: currentUUID,
              title: path(['params', 'title'], params),
              symbols_count: params?.params?.symbols_count || 0,
            },
          },
          partition: defaultPartition,
          constants: [
            ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_REQUEST,
            ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_SUCCESS,
            ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_FAILURE,
          ],
        };
        // console.log('projectConfig', projectConfig);
        dispatch(updateProjectRequest(projectConfig));
      }
    });
    setDocFetching(false);
    // antNotification('success', t('notifications.text.success.saved', 'Saved'));

    if (defaultLifeTime && defaultLifeTime !== params.params.lifeTime) {
      sendCustomComment({
        entity: currentEntityType,
        uuid: currentUUID,
        title: `lifetime from ${defaultLifeTime}`,
        value: params.params.lifeTime,
      }, 'changed');
    }

    // setCurrentSelectedTime(new Date());
    // setServerDocData({});
    loadAllDocuments(documentsConfig);
    setIsEditedDescription(false);
    setIsShowHistoryModal(false);

    setDocumentState(initialDocumentState);
    setServerContent(null);
    setIsMerged(false);

    setNotSavedData(initialState);

    await getDocumentData(currentUUID, currentEntityType);
    // }
  };
    return (
        <div style={{minHeight: "70vh"}}>
         <ExpandModeLayout localStorageTarget="documents">
                <DocView
                  docFetching={docFetching }
                  defaultPartition={defaultPartition}
                  ckeditor={CKEditor}
                  spreadsheetEditor={Spreadsheet}
                  isSidePanelOpen={isOpenComments}
                  showEditor={showEditor}
                  description={hasOptionalData ? documentContent : defaultDescription}
                  docStatus={prop('status', documentState)}
                  entityDocType={entityDocType}
                  hideElements={[]}
                  lifeTime={prop('lifeTime', documentState)}
                  title={prop('title', documentState)}
                  isDisabled={!hasNotSavedData}
                  getCKEditor={getCKEditor}
                  getSpreadsheet={getSpreadsheet}
                  getDescription={getDescription}
                  // setIsNotSavedDataCallback={setIsNotSavedDataCallback}
                  setIsShowHistoryModal={setIsShowHistoryModal}
                  setIsSidePanelOpen={setIsOpenComments}
                  changeDocumentState={changeDocumentState}
                  setNotSavedDataCallback={setNotSavedDataCallback}
                  closeUnsaveDataConfirm={closeUnsaveDataConfirm}
                  setIsEditedDescription={setIsEditedDescription}
                  checkDocumentForMerge={checkDocumentForMerge}
                  makePatchAndGetRequest={makePatchAndGetRequest}
                  updateProjectDocument={updateProjectDocument}
                  deleteDocumentCallback={deleteDocumentCallback}
                  changeDescriptionCallback={changeDescriptionCallback}
                  restoreEntityCallback={resetSelectedDocument}
                  updateDocumentsTableCallback={() => loadAllDocuments(documentsConfig)}
                  documentUUID={activeDocument}
                  hideExpandLayout={[EXPAND_MODE.VERTICAL, EXPAND_MODE.WIDE]}
                  // add save doc fn
                  setSaveDocument={setSaveDocument}
                />
                  {isShowHistoryModal && (
               <DocHistoryComponent
                currentActualState={actualState}
                defaultPartition={defaultPartition}
                docUUID={activeDocument}
                entityType={entityDocType}
                isProjectDescriptionDocument={false}
                title={prop('title', documentState)}
                reloadDocument={() => {}}
                reloadDocumentCallback={() => loadAllDocuments(documentsConfig)}
                setIsShowHistoryModal={setIsShowHistoryModal}
                />
                   )}
            </ExpandModeLayout>
          </div>
  )
}

export default ActiveDocumentModal

ActiveDocumentModal.propTypes = {
    defaultPartition: PropTypes.string,
    projectUUID: PropTypes.string,
    activeDoc: PropTypes.string,
    activeNote: PropTypes.string,
    activeSheet: PropTypes.string,
    activeDocumentModal: PropTypes.bool,
    setActiveDocumentModal: PropTypes.func,
    activeDocument: PropTypes.string,
    activeDocumentType: PropTypes.string,
    setSaveDocument: PropTypes.func
}
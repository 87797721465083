import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import { getChildFromCurrentArchiveState } from '../../selectors/archiveStateSelectors';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { getProjectUUID } from '../../selectors/selectors';
import { antNotification } from '../../../MainUtils';
import { capitalize } from 'lodash';
import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
import { entityUpdate } from '../../../entity/actions/entityActions';

function RestoreFromCurrentArchiveState() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    defaultPartition
  } = useContext(ProjectMainLayerContext);

  const childsFromCurrentArchiveState = useSelector(getChildFromCurrentArchiveState);
  const projectUUID = useSelector(getProjectUUID);

  const restoreEntities = async () => {
    const childsUUID = childsFromCurrentArchiveState.map((item) => item.uuid);

    const data = {
      entity_uuids: childsUUID,
      parent: projectUUID,
    };

    const constants = [
      ProjectTypeCommonConstants.ALL_DATA_FOR_ARCHIVE_ENTITY_REQUEST,
      ProjectTypeCommonConstants.ALL_DATA_FOR_ARCHIVE_ENTITY_SUCCESS,
      ProjectTypeCommonConstants.ALL_DATA_FOR_ARCHIVE_ENTITY_FAILURE,
    ];

    const options = {
      partition: defaultPartition,
      onSuccess: () => {
        antNotification('success', t('wms.noun.success', 'Success'));
        dispatch(
          { type: ProjectTypeCommonConstants.CLEAR_ARCHIVE_ENTITY },
        );
      },
    };

    dispatch(entityUpdate({
      data,
      constants,
      options,
    }));
  };

  return (
    <div>
      <BaseButton
        className="customAntOutPrimaryBtn"
        onClick={restoreEntities}
      >
        {capitalize(t(
          'wms.modals.body.archive_restore',
          'restore all entities from archive state',
        ))}
      </BaseButton>
    </div>
  );
}

export default RestoreFromCurrentArchiveState;

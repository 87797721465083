import { Card, Col } from 'antd';
import { head } from 'lodash';
import {
  array, bool, func, object, string,
} from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import WrapperCommentsAndTimeLogs from '../../WrapperCommentsAndTimeLogs';
import VectorInfo from './VectorInfo';
import CardSidePanel from '../../commonComponents/cardSidePanel/CardSidePanel';
import ActivitiesWrapper from '../../commonComponents/activities/activitiesWrapper/ActivitiesWrapper';

import useActivitiesDashboard from '../../commonComponents/activities/useActivitiesDashboard';
import { ACTIVITIES_TABS } from '../../commonComponents/activities/activitiesTabs';

import { getVectorFetching, getVectorInfo, getVectorUUID } from '../../../selectors/selectors';
import CustomCardComponent from '../../commonComponents/CustomCardComponent';

const activitiesTabs = [
  { name: ACTIVITIES_TABS.COMMENTS },
  { name: ACTIVITIES_TABS.TIME_LOG },
  { name: ACTIVITIES_TABS.HISTORY_OF_CHANGES },
];

export default function WrapperVectorInfoCardWithComments({
  afterDeleteABookmark,
  customVectorData,
  customVectorUuid,
  defaultTemplateState,
  deleteVectorCallback,
  dragIssueCallback,
  disabledView,
  hiddenView,
  notSavedData,
  partitionType,
  resetVectorCallback,
  restoreActionCallback,
  setIsModalVisible,
  setIsNotSavedDataCallback,
  setNotSavedData,
  updateVectorModal,
  viewMode = 'vector',
}) {
  const vectorUUID = useSelector(getVectorUUID);
  const vectorFetching = useSelector(getVectorFetching);
  const vectorData = head(useSelector(getVectorInfo));
  const targetData = customVectorData || vectorData;
  const finalVectorUUID = customVectorUuid || vectorUUID;

  const {
    isSidePanelOpen,
    selectedActivity,
    setSelectedActivity,
    closeSidePanel,
    clickActivitiesHandler,
    clickDashboardButtonHandler,
  } = useActivitiesDashboard();

  return (
    <Col
      className="overflow-hidden"
      id="vector_info_component_and_comments"
    >
      <VectorInfo
        activitiesTabs={activitiesTabs}
        afterDeleteABookmark={afterDeleteABookmark}
        setIsModalVisible={setIsModalVisible}
        updateVectorModal={updateVectorModal}
        setIsNotSavedDataCallback={setIsNotSavedDataCallback}
        notSavedData={notSavedData}
        setNotSavedData={setNotSavedData}
        defaultTemplateState={defaultTemplateState}
        disabledView={disabledView}
        viewMode={viewMode}
        hiddenView={hiddenView}
        restoreActionCallback={restoreActionCallback}
        deleteVectorCallback={deleteVectorCallback}
        resetVectorCallback={resetVectorCallback}
        dragIssueCallback={dragIssueCallback}
        partitionType={partitionType}
        clickActivitiesHandler={clickActivitiesHandler}
        clickDashboardButtonHandler={clickDashboardButtonHandler}
      />

      <CardSidePanel
        isVisible={isSidePanelOpen}
        closeSidePanel={closeSidePanel}
      >
        <ActivitiesWrapper
          inPanel
          isPanelOpen={isSidePanelOpen}
          parentUUID={vectorUUID}
          partitionType={partitionType}
          selectedActivity={selectedActivity}
          setSelectedActivity={setSelectedActivity}
          tabs={activitiesTabs}
          targetData={targetData}
        />
      </CardSidePanel>
    </Col>
  );
}
WrapperVectorInfoCardWithComments.propTypes = {
  setIsNotSavedDataCallback: func,
  notSavedData: object,
  setNotSavedData: func,
  defaultTemplateState: object,
  viewMode: string,
  disabledView: array,
  hiddenView: array,
  restoreActionCallback: func,
  deleteVectorCallback: func,
  resetVectorCallback: func,
  dragIssueCallback: func,
  partitionType: string,
  parentType: string,
  fetchingComments: bool,
  updateVectorModal: func,
  setIsModalVisible: func,
  customVectorData: object,
  customVectorUuid: string,
  afterDeleteABookmark: func,
};

// --------------------- Tags --------------------------
export const allTag = {
  key: 'all',
  value: 'all',
  label: 'wms.buttons.reset',
};

export const modifiedTag = [
  {
    key: 'date',
    value: 'today',
    label: 'wms.filters.today',
  },
  {
    key: 'date',
    value: 'this_week',
    label: 'wms.filters.this_week',
  },
  {
    key: 'date',
    value: 'last_week',
    label: 'wms.filters.last_week',
  },
  {
    key: 'date',
    value: 'this_month',
    label: 'wms.filters.this_month',
  },
  {
    key: 'date',
    value: 'last_month',
    label: 'wms.filters.last_month',
  },
];

export const locationTags = [
  {
    key: 'partition',
    value: 'all',
    label: 'wms.filters.all',
    parentSubSection: 'develop-section',
    subSectionTags: ['public', 'private', 'project'],
    keySection: 'partition',
    hidden: true,
  },
  {
    key: 'partition',
    value: 'public',
    label: 'wms.filters.public',
    hidden: false,
  },
  {
    key: 'partition',
    value: 'private',
    label: 'wms.filters.private',
    hidden: false,
  },
  {
    key: 'partition',
    value: 'project',
    label: 'wms.noun.project.0',
    hidden: false,
  },
];

export const createMeByTag = [
  {
    key: 'actor',
    value: '',
    label: 'wms.filters.created_by_me',
    hidden: false,
  },
];

export const assignedToMeTag = [
  {
    key: 'assigned',
    value: '',
    label: 'wms.filters.assigned_to_me',
    hidden: false,
  },
];

// --------------------- Other --------------------------
export const colorsForWebIcon = [
  '#f5222dbe',
  '#fa531cb6',
  '#fa8c16c2',
  '#faad14be',
  '#fadb14b7',
  '#a0d911b6',
  '#53c41ab2',
  '#722ed1bb',
  '#eb2f96c4',
  '#13c2c2c4',
  '#2f55ebbe',
];

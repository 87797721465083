
import React, { useState } from "react";
import { Flex, Popover, Tag } from "antd";
import { find } from "lodash";
import { func, string } from "prop-types";
import { priorityValues } from "./utils";
import './LaunchPad.scss';



const PriorityPicker = ({launchPriority, changelaunchPriority, rowUUID}) => {

const [open, setOpen] = useState(false)

const hide = () => {
    setOpen(false);
};

const handleOpenChange = (newOpen) => {
setOpen(newOpen);
};

const changePriority = (value) => {
if( launchPriority !== value) {
    changelaunchPriority(rowUUID, {launchPriority: value})
    hide()
}
}
const popoverTag = launchPriority ? find(priorityValues, { value: launchPriority }) : { value: '-', color: 'lightGrey' };

const content = (
    <Flex vertical gap="4px 0">
        {priorityValues.map(({color, value}) => 
            <Tag 
                className="mandatory-tag"
                onClick={() => changePriority(value)} 
                key={value} 
                color={color}>
                <span className="mandatory-tag-value">{value}</span>
            </Tag>
        )}
    </Flex>
)

    return (
        <Popover 
        placement="left" 
        content={content} 
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        >
        <Tag 
            className="mandatory-tag" 
            color={popoverTag.color}>
                <span className="mandatory-tag-value">{popoverTag.value}</span>
        </Tag>
        </Popover>
    )
}

PriorityPicker.propTypes = {
    launchPriority: string,
    changelaunchPriority: func,
    rowUUID: string
}

export default PriorityPicker;
import React, { memo, useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';

import { propOr } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useClearComponent } from '../../hooks/useClearComponent';
import PublicPartitionConstants from '../../publicPartition/constants/publicPartitionConstants';
import { clearDayUserReportFilter } from '../../toolsFlow/reducers/slices/dayUserReportFilterSlice';
import { clearDayUserReportUserInfoParams } from '../../toolsFlow/reducers/slices/dayUserReportUserInfoParamsSlice';

import './day-user-report.scss';

import { GET_DEFAULT_GROUPS_SUCCESS, getBanGroupUUID } from '../../actors/slices/defaultGroupsSlice';
import DayUserReportUserTableHeader from './DayUserReport/DayUserReportUserTableHeader';
import DayUserReportUsersTable from './DayUserReport/DayUserReportUsersTable';
import { CLEAR_PERIOD_START_WORK_REPORTS } from '../../userFlow/store/reducers/slices/periodReportsSlice';
import { getPartitionPublicUUID } from '../../config/selectors/selectors';
// import { useUtilityActors } from '../../actors/hooks/useUtilityActors';
import { getListAndReadEntities } from '../../entity/actions/entityActions';
import { axiosRequestPost } from '../../api/apiAxios';
import useActorsW54Pagination from '../../actors/hooks/useActorsW54Pagination';
import BaseCard from "../../components/_ui/BaseCard/BaseCard";
import { partitionNamesConfig } from '../../api/appConfig';

const clearPublicUsersEntities = { type: PublicPartitionConstants.CLEAR_PUBLIC_USERS_ENTITIES };
const clearPublicUsersReports = CLEAR_PERIOD_START_WORK_REPORTS;
const clearUserDayLogForDayReport = {
  type: PublicPartitionConstants.CLEAR_USER_DAY_LOG_FOR_DAY_REPORT,
};

function UserDashboardForAdmin() {
  const dispatch = useDispatch();

  // const { getActorsFromPagination } = useUtilityActors();

  const publicParentEntity = useSelector(getPartitionPublicUUID);
  const banGroupUUID = useSelector(getBanGroupUUID);

  const [config, setConfig] = useState({
    limit: 100,
    offset: 0,
    uinfo: {
      internal_user: true,
      groups__not: [banGroupUUID],
    },
  });

  function getGroupsForAdminDashboard(data) {
    const finalData = {
      not_banned: true,
      ...data,
    };

    const defaultConstants = [
      'GET_DEFAULT_GROUPS_FOR_ADMIN_DASHBOARD_REQUEST',
      'GET_DEFAULT_GROUPS_FOR_ADMIN_DASHBOARD_SUCCESS',
      'GET_DEFAULT_GROUPS_FOR_ADMIN_DASHBOARD_FAILURE',
    ];

    return new Promise((resolve) => {
      dispatch(axiosRequestPost(
        'utility/actor/list',
        [
          defaultConstants[0],
          {
            type: defaultConstants[1],
            payload: (res) => {
              resolve(res);
              return propOr(res, 'data', res);
            },
          },
          defaultConstants[2],
        ],
        finalData,
      ));
    });
  }

  const getActorsListAndEntities = async (params = {}, customConfig = {}) => {
    // getActorsFromPagination({
    //   actor_type: ['user', 'classic_user'],
    // });

    const publicConfig = {
      actor_type: ['user', 'classic_user'],
      entity_type: 'user',
      parent: publicParentEntity,
      ...config,
      params,
      ...customConfig,
    };

    return dispatch(getListAndReadEntities({
      data: publicConfig,
      partition: partitionNamesConfig.partition3,
      constants: [
        PublicPartitionConstants.GET_PUBLIC_USERS_ENTITIES_REQUEST,
        PublicPartitionConstants.GET_PUBLIC_USERS_ENTITIES_SUCCESS,
        PublicPartitionConstants.GET_PUBLIC_USERS_ENTITIES_FAILURE,
      ],
    }));
  };

  useClearComponent(
    clearPublicUsersEntities,
    clearDayUserReportFilter,
    clearPublicUsersReports,
    clearDayUserReportUserInfoParams,
    clearUserDayLogForDayReport,
  );

  useEffect(() => {
    const defaultGroups = ['ADMIN', 'BAN', 'DEFAULT'];

    getGroupsForAdminDashboard({
      actor_type: 'group',
      uinfo: {
        group_name: defaultGroups,
      },
    }).then((res) => {
      dispatch(GET_DEFAULT_GROUPS_SUCCESS(res?.data?.filter((item) => ['BAN', 'ADMIN', 'DEFAULT'].includes(item?.uinfo?.group_name))));
    });
  }, []);

  useEffect(() => {
    if (publicParentEntity) {
      getActorsListAndEntities();
    }
  }, [publicParentEntity, config]);

  return (
    <Row className="mt-3">
      <Col span={24}>
        <BaseCard className="primaryCard overflowHidden p-3">
          <DayUserReportUserTableHeader
            getActorsList={getActorsListAndEntities}
            setConfig={setConfig}
          />
          <DayUserReportUsersTable
            getActorsList={getActorsListAndEntities}
            config={config}
            setConfig={setConfig}
          />
        </BaseCard>
      </Col>
    </Row>
  );
}

export default memo(UserDashboardForAdmin);

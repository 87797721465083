import PropTypes, { array } from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'ramda';

import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

import { downloadDataAsJSON, formatDateTimeForWms } from '../../../../MainUtils';
import { capitalize } from 'lodash';
import { getProjectUUID } from '../../../selectors/selectors';
import { getRequestEntity, getRequestReadEntity } from '../../../../entity/actions/entityActions';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';

function ExportQaAsJSON({
  typeOfEntity,
  className = '',
  entityRequestParams = {},
  customUuids = [],
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectUUID = useSelector(getProjectUUID);

  const { partitionType } = useContext(BugTrackerContext);

  const getPromiseRequestEntity = async ({
    parent,
    type,
    params,
    entities,
  }) => {
    const data = {
      entity_type: type,
      params: { ...params },
      parent,
      entities,
      depth: 0,
    };
    if (!isEmpty(customUuids) && !entities) {
      data.entity_uuids = customUuids;
      const res = await dispatch(getRequestReadEntity({ data, partition: partitionType }));
      return { data: res };
    }
    return dispatch(getRequestEntity({ data, partition: partitionType }));
  };

  const downloadQAEntities = async () => {
    let listOfTestEntities = await getPromiseRequestEntity({
      parent: projectUUID,
      type: typeOfEntity,
      params: entityRequestParams,
    });

    listOfTestEntities = get(listOfTestEntities, 'data', []);

    let listOftestCases = await getPromiseRequestEntity(
      {
        parent: projectUUID,
        type: 'testCase',
        entities: listOfTestEntities.map(({ uuid }) => uuid),
      },
    );
    listOftestCases = get(listOftestCases, 'data', []);

    const withoutProps = (item) => omit(['uuid', 'parent', 'created', 'modified', 'files'], item);

    const jsonObject = listOfTestEntities.map((suite) => ({
      ...withoutProps(suite),
      listOfTestCases: [
        ...listOftestCases.filter((testCase) => testCase.parent === suite.uuid).map(withoutProps),
      ],
    }));

    downloadDataAsJSON(
      jsonObject,
      `${typeOfEntity}-[${listOfTestEntities.map((i, key) => `${get(i, 'params.id', key)}`)}]-${formatDateTimeForWms()}`,
    );
  };
  // console.log('customUuids',customUuids)
  return (
    <BaseButton
      onClick={downloadQAEntities}
      className={`${!isEmpty(customUuids) && 'customAntOutPrimaryBtn'} ${className}`}
      disabled={isEmpty(customUuids)}
      size="small"
    >
      {capitalize(t('wms.buttons.export_as_json'))}
    </BaseButton>
  );
}

export default ExportQaAsJSON;

ExportQaAsJSON.propTypes = {
  className: PropTypes.string,
  entityRequestParams: PropTypes.object,
  typeOfEntity: PropTypes.string,
  customUuids: array,
};

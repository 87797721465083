import { omit } from 'lodash';
import PublicPartitionConstants from '../constants/publicPartitionConstants';

const initialState = {
  fetching: true,
  error: '',
  data: [],
  total: 0,
};

export default function publicUsersEntities(state = initialState, action) {
  const { type, payload, total } = action || {};
  switch (type) {
    case PublicPartitionConstants.UPDATE_PUBLIC_USER_ENTITY_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case PublicPartitionConstants.UPDATE_PUBLIC_USER_ENTITY_SUCCESS:
      return {
        ...state,
        fetching: false,
        // data: [...state.data.map((el) => (el.uuid === payload[0].uuid ? { ...omit(payload[0], ['params']), ...payload[0]?.params } : el))],
      };
    case PublicPartitionConstants.UPDATE_PUBLIC_USER_ENTITY_FAILURE:
      return {
        ...state,
        error: payload,
        fetching: false,
      };
    case PublicPartitionConstants.GET_PUBLIC_USERS_ENTITIES_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case PublicPartitionConstants.GET_PUBLIC_USERS_ENTITIES_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload,
        total,
      };
    case PublicPartitionConstants.GET_PUBLIC_USERS_ENTITIES_FAILURE:
      return {
        ...state,
        error: payload,
        fetching: false,
        total: 0,
      };

    case PublicPartitionConstants.CLEAR_PUBLIC_USERS_ENTITIES:
      return {
        ...state,
        data: [],
        error: '',
        total: 0,
      };
    default: return state;
  }
}

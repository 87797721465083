import { mdiStar, mdiStarOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Alert } from 'antd';
import { get, head } from 'lodash';
import { propOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import { getListAndPartialReadEntities } from '../../../entity/actions/entityActions';
import { capitalize } from 'lodash';
import { partitionNamesConfig } from '../../../api/appConfig';

export default function UpdateListFavoriteModal({
  dataFavorite,
  handleCancel,
  partition,
  partitionUUID,
  updateFavorite,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [listProjects, setListProjects] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pageLimit, setPageLimit] = useState(10);

  const getListProjects = (entities) => {
    const config = {
      data: {
        entity_type: 'project',
        params: {},
        entities,
        parent: partitionUUID,
        params_fields: {
          id: 'id',
          title: 'title',
          status: 'status',
        },
      },
      partition,
      constants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    };

    return dispatch(getListAndPartialReadEntities(config));
  };

  const columns = [
    {
      dataIndex: ['id'],
      title: 'ID',
      render: (cell) => cell && `P${cell}`,
    },
    {
      dataIndex: 'title',
      title: capitalize(t('wms.labels.title')),
      render: (text, record) => record && (
        <EntityParamTag
          param="title"
          value={text}
          type="value"
          tooltip={propOr('', 'uuid', record)}
        />
      ),
    },
    {
      dataIndex: 'status',
      title: capitalize(t('wms.table.headers.status', 'Status')),
      render: (text, record) => record && (
        <EntityParamTag
          param="status"
          value={text === 'archive'
            ? 'archived'
            : text}
          type="icon"
        />
      ),
    },
    {
      dataIndex: 'uuid',
      title: capitalize(t('wms.table.headers.favorites', 'Favorites')),
      render: (cell) => (
        <BaseButton
          className="add-to-favorite-btn active"
          size="small"
        >
          <Icon path={selectedKeys.includes(cell) ? mdiStar : mdiStarOutline} size={1} />
        </BaseButton>
      ),
    },
  ];

  const toggleSubmit = () => {
    // updateProjectWithCustomsParams({ relatedProjects: selectedKeys });
    const favoriteParams = {
      ...get(dataFavorite, '[0].params'),
      [partition === partitionNamesConfig.partition1 ? 'projects' : 'publicProjects']: selectedKeys,
    };
    updateFavorite(favoriteParams);
    handleCancel();
  };

  const initFunc = async () => {
    setLoading(true);
    const { projects, publicProjects = [] } = head(dataFavorite);

    const currentList = partition === partitionNamesConfig.partition1 ? projects : publicProjects;

    const res = await getListProjects(currentList || []);
    // console.log(get(res, 'data', []))
    const list = get(res, 'data', []);

    if (get(res, 'data') && get(res, 'data', []).length !== currentList.length) {
      currentList.forEach((i) => {
        if (get(res, 'data', []).every((p) => p.uuid !== i)) {
          list.push({ uuid: i, title: 'Project has been removed', status: 'created' });
        }
      });
    }

    setListProjects(list);
    setSelectedKeys(currentList);
    setLoading(false);
  };

  const multipleSelectRow = {
    // clickToSelect: !disableOnClick,
    hideSelectColumn: true,
    // mode: 'checkbox',
    selectedRowKeys: selectedKeys,
    // style: { background: customBackgroundRowTable || '#e5f4fc' },
  };

  const rowClassName = (row) => {
    const data = `universal_table_row ${selectedKeys.some((i) => i === get(row, 'uuid'))
      ? 'selected'
      : ''
      }`;

    return data;
  };

  const onRow = (record) => ({
    onClick: () => {
      if (!isChanged) {
        setIsChanged(true);
      }

      setSelectedKeys((prev) => {
        if (prev.find((p) => p === record?.uuid)) {
          return prev.filter((i) => i !== record?.uuid);
        }
        return [...prev, record.uuid];
      });
    },
  });

  useEffect(() => {
    initFunc();
  }, []);
  return (
    <div className="flex flex-col">
      <Alert
        showIcon
        message="Favorite projects limit exceeded, delete previously added"
        type="error"
        description="Max limit 15 favorites"
        className="mb-2"
      />
      <div>
        <BaseTableWithPagination
          pageLimit={pageLimit}
          changePageLimit={setPageLimit}
          loading={loading}
          columns={columns}
          data={listProjects}
          onRow={onRow}
          //   paginationSize="small"
          rowClassName={rowClassName}
          //   headerRowClassName={headerRowClassName}
          rowSelection={multipleSelectRow}
        />
      </div>
      <div className="flex justify-end">
        <BaseButton
          className="customAntOutPrimaryBtn ml-1 mr-1"
          disabled={!isChanged}
          onClick={toggleSubmit}
        >
          {capitalize(t('wms.buttons.submit'))}
        </BaseButton>
      </div>
    </div>
  );
}

import {EntityConstants} from "../constants/Constants";

const initialState = {
  data: false
};


export default (state=initialState, action) => {
  switch (action.type) {
    case EntityConstants.VIEW_NOTIFICATION_TRUE:
      return {
        data: true
      };
    case EntityConstants.VIEW_NOTIFICATION_FALSE:
      return {
        data: false
      };

    default:  return state;
  }
};

import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge, Button, Col, Dropdown, Row, Space, Tooltip, Flex,
  Spin, Empty
} from 'antd';
import { isNil, propOr } from 'ramda';
import {
  mdiAccountMultipleOutline,
  mdiCarBrakeAlert,
  mdiDotsVertical,
  mdiIdentifier,
  mdiSquareEditOutline,
  mdiTableOfContents,
  mdiChevronDown,
  mdiAccountEditOutline,
  mdiAccountCheckOutline,
  mdiEmoticonConfusedOutline,
  mdiTimerSand,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';

import { get, isEmpty, uniq } from 'lodash';
import moment from 'moment';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';
import TestCycleForm from './TestCycleForm';
import BtnModal from '../../BtnModalComponent';
import TestStatusDropdown from '../componentsForMultipleUse/TestStatusDropdown';
import NewTestCycleWithImportTestCases from './NewTestCycleWithImportTestCases';
import ReRunTestCycleOrCase from '../componentsForMultipleUse/ReRunTestCycleOrCase';
import CustomProgress from '../componentsForMultipleUse/CustomProgress';
import RemoveSelectedTestCycleWrapper from './RemoveSelectedTestCycleWrapper';
import ArchiveModalInTestCycle from '../../../containers/qaView/arсhiveQa/ArсhiveModalInTestCycle';
import ExportQaAsJSON from '../exportAsJSON/ExportQaAsJSON';
import ImportFromJSON from '../exportAsJSON/ImportFromJSON';

import { antNotification, formatDateTimeForWms } from '../../../../MainUtils';
import { capitalize } from 'lodash';
import {
  BugTrackerContext,
  MainCollapseSectionContext,
} from '../../../context/ProjectFlowListOfContexts';
import {
  currentSelectedUUID,
  getMe,
  getProject,
  getTestCycles,
  globalUpdateEntity,
  listTestCycleArchive,
  getProjectParams,
} from '../../../selectors/selectors';
import {
  clearListTestCycleInStore,
  clearTestCycle,
  getListOfTestCyclesFunc,
  updateTestCycle,
} from '../../../actions/QaActions';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import { stopPropagation } from '../../../../54origins/utils54origins';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import BugTrackerModalWrapper from '../BugTrackerModalWrapper';
import { clearGlobalUpdateEntity } from '../../../reducers/slicers/breadcrumbsSlicer';
import useActorsW54 from '../../../../actors/hooks/useActorsW54';
import WrapperAvatarsGroupForProject from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';

import { cyclesFilterConfig, cycleArchiveFilterConfig, defaultCyclesFilter} from '../constants/qaFilterOptions';
import { cycleArchiveTags, cycleStatusTags } from '../../../../constants/allFilterOptions';
import useTableFilter from '../../../../components/_ui/hooks/useTableFilter';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { getUuid } from '../../../../assignUsersModal/utils';
import useURLParams from '../../../../hooks/useURLParams';
import NewExecutionForm from '../execution/NewExecutionForm';
import BaseTag from '../../../../components/_ui/BaseTag/BaseTag';
import DateLeft from '../../../../components/DateLeft';
import EmptyListComponent from '../EmptyListComponent';

const pageSizeOptions = [5, 25, 50, 100];

function ListOfTestCycles({
  title,
  deleteCycle,
  isArchive,
  createEntitiesFromJSON,
  config,
  totalCount,
  setNewConfigCallback,
  selectTestCycle,
  id,
}) {

  const {getURLParams, addAndRemoveSearchParams} = useURLParams();

  const {
    filterConfig: testCycleFilterConfig,
    changeFilterConfig: changeCycleFilterConfig,
  } = useTableFilter('testCycle', {archive: ['false']});

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    changeMainCollapseFromOuterComponent,
    getCurrentHorizontalCollapseState,
  } = useContext(MainCollapseSectionContext);
  const {
    getListOfTestCycles,
    getListTestCycleInArchive,
    // selectTestCycle,
    partitionType,
    changeQAHistory,
  } = useContext(BugTrackerContext);

  const globalUpdate = useSelector(globalUpdateEntity);
  
  // const listOfTestCycleArchive = useSelector(listTestCycleArchive);
  const listTestCycles = useSelector(getTestCycles);

  const curSelectedUUID = useSelector((state) => currentSelectedUUID(state, isArchive));
  const myUserUuid = useSelector(getMe);
  const projectParams = useSelector(getProjectParams);

  const [selectedCycle, changeSelectedCycle] = useState([]);
  const [selectedRows, changeSelectedRow] = useState([]);

  const [sizePerPage, setSizePerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const [isEditAssignUsers, setEditAssignUsers] = useState(false);
  const [isEditModifiers, setEditModifiers] = useState(false);
  const [isEditExecutors, setEditExecutors] = useState(false);
  const [usersFilter, setUsersFilter] = useState([]);
  const [modifiersFilter, setModifiersFilter] = useState([]);
  const [executorsFilter, setExecutorsFilter] = useState([]);
  const [orderRules, changeOrderRules] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [defaultArchiveFilter, setDefaultArchiveFilter] = useState('false')

  const sectionId = isArchive ? 'mainArchiveCollapse' : 'mainCycleCollapse';

  // Получение case пользователей
  const userUUIDs = listTestCycles?.flatMap?.((item) => item?.assignToUser) ?? [];
  useActorsW54({ actors: uniq(userUUIDs) });

  const currentHorizontalCollapseState = getCurrentHorizontalCollapseState(sectionId);

  const updateAfterRemove = () => {
    const clearName = isArchive ? 'clearCycleArchive' : 'clearCycle';
    for (const item of selectedCycle) {
      if (isArchive) {
        if (curSelectedUUID === item) {
          deleteCycle();
          changeQAHistory({ entity: clearName, clearCases: true });
        }

        getListTestCycleInArchive();
      } else if (curSelectedUUID === item) {
        deleteCycle(item, false);
        changeQAHistory({ entity: clearName, clearCases: true });
      }

      getListOfTestCycles();
    }

    changeSelectedCycle([]);
  };

  const deleteTestCycle = (uuid) => {
    const clearName = isArchive ? 'clearCycleArchive' : 'clearCycle';
    if (isArchive) {
      deleteCycle();
    } else {
      deleteCycle(uuid);
    }

    changeSelectedCycle([]);

    if (curSelectedUUID === uuid) {
      changeQAHistory({ entity: clearName, clearCases: true });
    }
  };

  const selectTestCycleFunc = (uuid, reRun = false, newCycle = false) => {
    changeSelectedRow(uuid);

    if (isArchive && !reRun) {
      selectTestCycle(uuid);
    } else if (reRun) {
      selectTestCycle(uuid);
    } else if (newCycle) {
      selectTestCycle(uuid);
    } else {
      selectTestCycle(uuid);
    }
  };

  const reRunTestCycle = (uuid, reRun) => {
    selectTestCycleFunc(uuid, reRun);

    if (isArchive) {
      changeMainCollapseFromOuterComponent('mainArchiveCollapse', false);
    }

    changeSelectedCycle([]);
  };

  const colorStyle = isArchive ? 'rgb(226, 200, 8)' : '#1890ff';

  const setSearchIssue = (value) => {
    setSearchValue(value);
  };

  const searchInTitle = (
    <Col className="flex align-item-end minWidth130">
      <AntDesignSearchBox54origins
        onSearch={setSearchIssue}
        placeholder="Search"
      />
    </Col>
  );

  const onChangeStatusCallback = (status, row) => {
    const newHelperInStatus = (
      (row.progress.unexecuted === 0
        && row.progress.progress === 0)
      && row.progress.quantityCase !== 0
    );

    const data = {
      entity_type: 'testCycle',
      uuid: row?.uuid,
      partition: partitionType,
      params: {
        status,
        helperInStatus: status !== 'in progress' ? false : newHelperInStatus,
        executed_by: myUserUuid,
        executed_on: formatDateTimeForWms(moment()),
      },
      customConstants: ['REQUEST', selectedRows === row?.uuid ? ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE : '', 'FAILURE'],
    };

    dispatch(updateTestCycle(data))
      .then(() => {
        antNotification('success', t('notifications.text.success.change_status_test_cycle', 'Test cycles status has been changed'));
        getListOfTestCycles();
      });
  };

  const columns = [
    {
      dataIndex: ['id'],
      title: (<Icon path={mdiIdentifier} size={1} />),
      render: (cell, row) => (
        <Tooltip
          title={currentHorizontalCollapseState ? row.title : ''}
          className="whitespace-nowrap text-xs"
        >
          {!isNil(cell) ? cell : 'key'}
        </Tooltip>
      ),
    },
    {
      dataIndex: ['title'],
      key: 'title',
      className: currentHorizontalCollapseState ? 'hidden' : '',
      title: searchInTitle,
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
        />
      ),
    },
    {
      dataIndex: ['progress'],
      title: capitalize(t('wms.table.headers.progress', 'Progress')),
      render: (cell) => cell && <CustomProgress cell={cell} />,
    },
    {
      dataIndex: ['modified_on'],
      key: 'modified_on',
      className: currentHorizontalCollapseState ? 'hidden' : '',
      sorter: true,
      title: capitalize(t('wms.table.headers.modifiedOn', 'Last modified')),
      render: (cell) => <div>{cell}</div>,
    },
    {
      dataIndex: ['modified_by'],
      title: (
        <AssigningUsersRoot
          header="User filter"
          isOpen={isEditModifiers}
          setIsOpen={setEditModifiers}
          isCheckWindowWidthMode={false}
          isClosePopoverOnLeaveMode
          isOneColumnMode={true}
          size={'extra small'}
          data={{
            uuids: projectParams?.usersSearch,
          }}
          isPopover
          onUpdateUsers={(usersData) => {
            const newUsers = usersData?.users?.map((el) => getUuid(el));
            setModifiersFilter(newUsers);
          }}
          globalOptions={{
            uuids: projectParams?.usersSearch,
            isOneColumnMode: true,
            size: 'extra small',
          }}
          usersConfig={{
            label: 'Users',
            showSections: ['string-role'],
            textSelectButton: 'Select',
            textDeleteButton: 'Deselect',
            defaultItems: modifiersFilter,
          }}
          popoverChild={(
            <Flex className="ml-1 min-width-60px" align="center">
              <Tooltip
                placement="top"
                onClick={() => setEditModifiers(true)}
                className='flex flex-row cursor-pointer'
                title={capitalize(t('wms.table.headers.modifiers', 'Last modified by'))}
              >
                <Icon
                  path={mdiAccountEditOutline}
                  role="button"
                  className="text-secondary"
                  size={1}
                />
                <Icon
                  path={mdiChevronDown}
                  role="button"
                  className="text-secondary ml-1"
                  size={1}
                />
              </Tooltip>
              <WrapperAvatarsGroupForProjectWithHook
                actors={modifiersFilter || []}
                maxCount={3}
                avatarSize={22}
                popoverTrigger="click"
                popoverPlacement="right"
                checkProjectRole
              />
            </Flex>
          )}
        />
      ),
      render: (cell, row) => ((!isNil(
            row.modified_by,
          ) && row.modified_by.length !== 0)
            ? (
              <WrapperAvatarsGroupForProject
                actors={[row?.modified_by]}
                avatarSize="small"
                avatarGap={4}
                avatarStyles={{ cursor: 'pointer' }}
                groupButtonSize="small"
                checkProjectRole
              />
            )
            : ''),
    },
    {
      dataIndex: ['executed_on'],
      key: 'executed_on',
      className: currentHorizontalCollapseState ? 'hidden' : '',
      sorter: true,
      title: capitalize(t('wms.table.headers.executedOn', 'Last executed')),
      render: (cell) => <div>{cell}</div>,
    },
    {
      dataIndex: ['executed_by'],
      title: (
        <AssigningUsersRoot
          header="User filter"
          isOpen={isEditExecutors}
          setIsOpen={setEditExecutors}
          isCheckWindowWidthMode={false}
          isClosePopoverOnLeaveMode
          isOneColumnMode={true}
          size={'extra small'}
          data={{
            uuids: projectParams?.usersSearch,
          }}
          isPopover
          onUpdateUsers={(usersData) => {
            const newUsers = usersData?.users?.map((el) => getUuid(el));
            setExecutorsFilter(newUsers);
          }}
          globalOptions={{
            uuids: projectParams?.usersSearch,
            isOneColumnMode: true,
            size: 'extra small',
          }}
          usersConfig={{
            label: 'Users',
            showSections: ['string-role'],
            textSelectButton: 'Select',
            textDeleteButton: 'Deselect',
            defaultItems: executorsFilter,
          }}
          popoverChild={(
            <Flex className="ml-1 min-width-60px" align="center">
              <Tooltip
                placement="top"
                onClick={() => setEditExecutors(true)}
                className='flex flex-row cursor-pointer'
                title={capitalize(t('wms.table.headers.modifiers', 'Last executed by'))}
              >
                <Icon
                  path={mdiAccountCheckOutline}
                  role="button"
                  className="text-secondary"
                  size={1}
                />
                <Icon
                  path={mdiChevronDown}
                  role="button"
                  className="text-secondary ml-1"
                  size={1}
                />
              </Tooltip>
              <WrapperAvatarsGroupForProjectWithHook
                actors={executorsFilter || []}
                maxCount={3}
                avatarSize={22}
                popoverTrigger="click"
                popoverPlacement="right"
                checkProjectRole
              />
            </Flex>
          )}
        />
      ),
      render: (cell, row) => ((!isNil(
            row.executed_by,
          ) && row.executed_by.length !== 0)
            ? (
              <WrapperAvatarsGroupForProject
                actors={[row?.executed_by]}
                avatarSize="small"
                avatarGap={4}
                avatarStyles={{ cursor: 'pointer' }}
                groupButtonSize="small"
                checkProjectRole
              />
            )
            : ''),
    },
    {
      dataIndex: ['status'],
      key: 'status',
      sorter: true,
      title: capitalize(t('wms.table.headers.status', 'Status')),
      filters: cycleStatusTags,
      ...(cyclesFilterConfig && { ...cyclesFilterConfig?.status }),
      ...(testCycleFilterConfig?.status && { defaultFilteredValue: testCycleFilterConfig?.status }),
      // onFilter: (value, record) => {return record.status?.includes(value.toLowerCase());},
      render: (cell, row) => (
        <div
          style={row.helperInStatus
            ? { border: '2px solid red', padding: '2px', borderRadius: '5px' }
            : {}}
          className="flex items-center"
        >
          <TestStatusDropdown
            currentStatus={cell}
            row={row}
            type={isArchive ? 'archive' : 'cycle'}
            onChangeStatusCallback={onChangeStatusCallback}
          />

          {row.helperInStatus
            && (
              <Tooltip
                title={row.helperInStatus && t(
                  'wms.tooltips.update_cycle_status',
                  'Some of your test cases have been deleted. Please update the test cycle status',
                )}
              >
                <Icon
                  color="red"
                  path={mdiCarBrakeAlert}
                  size={0.8}
                  className="ml-1"
                />
              </Tooltip>
            )}
        </div>

      ),
    },
    {
      dataIndex: ['assignToUsers'],
      title: (
        <AssigningUsersRoot
          header="User filter"
          isOpen={isEditAssignUsers}
          setIsOpen={setEditAssignUsers}
          isCheckWindowWidthMode={false}
          isClosePopoverOnLeaveMode
          isOneColumnMode={true}
          size={'extra small'}
          data={{
            uuids: projectParams?.usersSearch,
          }}
          isPopover
          onUpdateUsers={(usersData) => {
            const newUsers = usersData?.users?.map((el) => getUuid(el));
            setUsersFilter(newUsers);
          }}
          globalOptions={{
            uuids: projectParams?.usersSearch,
            isOneColumnMode: true,
            size: 'extra small',
          }}
          usersConfig={{
            label: 'Users',
            showSections: ['string-role'],
            textSelectButton: 'Select',
            textDeleteButton: 'Deselect',
            defaultItems: usersFilter,
          }}
          popoverChild={(
            <Flex className="ml-1 min-width-60px" align="center">
              <Tooltip
                placement="top"
                onClick={() => setEditAssignUsers(true)}
                style={{
                  cursor: 'pointer',
                }}
                title={capitalize(t('wms.table.headers.assigned', 'Assigned'))}
              >
                <Icon
                  path={mdiAccountMultipleOutline}
                  role="button"
                  size={1}
                  className="text-secondary"
                />
                <Icon
                  path={mdiChevronDown}
                  role="button"
                  className="text-secondary ml-1"
                  size={1}
                />
              </Tooltip>
              <WrapperAvatarsGroupForProjectWithHook
                actors={usersFilter || []}
                maxCount={3}
                avatarSize={22}
                popoverTrigger="click"
                popoverPlacement="right"
                checkProjectRole
              />
            </Flex>
          )}
        />
      ),
      render: (cell, row) => ((!isNil(
            row.assignToUser,
          ) && row.assignToUser.length !== 0)
            ? (
              <WrapperAvatarsGroupForProject
                actors={get(row, 'assignToUser', [])}
                avatarSize="small"
                avatarGap={4}
                avatarStyles={{ cursor: 'pointer' }}
                groupButtonSize="small"
                checkProjectRole
              />
            )
            : ''),
    },
    {
      dataIndex: ['archive'],
      key: 'archive',
      sorter: true,
      title: capitalize(t('wms.table.headers.type', 'Type')),
      filters: cycleArchiveTags,
      ...(cyclesFilterConfig && { ...cyclesFilterConfig?.archive }),
      ...(testCycleFilterConfig?.archive && { defaultFilteredValue: testCycleFilterConfig?.archive }),
      // onFilter: (value, record) => {return record.status?.includes(value.toLowerCase());},
      render: (cell, row) => (
        <BaseTag
            color={row.archive ? 'yellow' : 'blue'}
        >
          {row.archive ? 'Archived' : 'Active'}
        </BaseTag>
      ),
    },
    {
      dataIndex: ['end_date'],
      key: 'end_date',
      sorter: true,
      title: (
        <Tooltip title={capitalize(t('wms.adjective.expired', 'expired'))}>
          <Icon path={mdiTimerSand} size={1} />
        </Tooltip>
      ),
      render: (cell, row) => (
        <DateLeft minus date={cell} type="tag" customStyle="text-neutral-600" />
      ),
    },
    {
      dataIndex: ['cases_count'],
      key: 'cases_count',
      sorter: true,
      title: (
        <Tooltip
          placement="top"
          title={capitalize(t('wms.table.headers.cases', 'Cases'))}
        >
          <Icon path={mdiTableOfContents} size={1} />
        </Tooltip>
      ),
      className: currentHorizontalCollapseState ? 'hidden' : '',
      render: (cell) => (
        <Badge
          count={cell}
          showZero
          style={{ backgroundColor: colorStyle }}
        />
      ),
    },
    {
      dataIndex: ['params'],
      title: capitalize(t('wms.table.headers.actions', 'actions')),
      className: currentHorizontalCollapseState ? 'hidden' : '',
      render: (cell, row) => {
        const isArchive = row.archive
        const disabled = (!isNil(row.status) && (row.status !== 'unexecuted' && row.status !== 'in progress' && row?.cases_count !== 0));

        const deleteCycleCallback = () => {
          deleteTestCycle(row.uuid);
        };

        return (
          <div className="flex" onClick={stopPropagation}>
            <ReRunTestCycleOrCase
              title={capitalize(t('wms.modals.headers.new_test_cycle', 'new test cycle'))}
              isArchive={isArchive}
              disabled={row?.status === 'unexecuted' || +row?.cases_count === 0}
              btnIconStyle={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', borderRight: 'none' }}
              titleTooltip={
                !isArchive
                  ? capitalize(t('wms.tooltips.re-run_test_cycle', 're-run test cycles'))
                  : capitalize(t('wms.tooltips.re-run_test_cycle_archive', 're-run test cycle from archive '))
              }
            >
              <TestCycleForm
                defaultTitle={propOr('', 'title', row)}
                defaultDescription={propOr('', 'description', row)}
                assignToUser={propOr([], 'assignToUser', row)}
                selectedVectorDefault={propOr([], 'vector', row)}
                entityUUID={row.uuid}
                selectVectorDefault={reRunTestCycle}
                typeOfForm="reRun"
              />
            </ReRunTestCycleOrCase>

            <BtnModal
              footer={[]}
              title={capitalize(
                t('wms.modals.headers.edit_test_cycle', 'edit test cycles'),
              )}
              width="50%"
              btnComponent={(
                <BaseButton
                  className={`ant-btn ${(!isArchive && !disabled) && 'customAntOutPrimaryBtn border_right_none'} ant-btn-default ant-btn-sm`}
                  disabled={isArchive || disabled}
                >
                  <Tooltip
                    title={capitalize(
                      t('wms.tooltips.edit', 'edit'),
                    )}
                  >
                    <Icon path={mdiSquareEditOutline} size={0.7} />
                  </Tooltip>
                </BaseButton>
              )}
            >
              <TestCycleForm
                typeOfForm="editForm"
                defaultTitle={propOr('', 'title', row)}
                defaultDescription={propOr('', 'description', row)}
                defaultStartDate={propOr('', 'start_date', row)}
                defaultDueDate={propOr('', 'end_date', row)}
                assignToUser={propOr([], 'assignToUser', row)}
                selectedVectorDefault={propOr([], 'vector', row)}
                defaultTypeOfTesting={propOr([], 'typeOfTesting', row)}
                entityUUID={row.uuid}
                selectTestCycle={selectTestCycleFunc}
              />
            </BtnModal>

            <BugTrackerModalWrapper counterBug={row?.counterBug} uuid={row.uuid} />

            {
              (!isArchive && disabled) ? (
                <ArchiveModalInTestCycle
                  currentCycles={[row]}
                  selectedCycleOrArchiveCycle={curSelectedUUID}
                  type="cycle"
                />
              ) : (
                <HardDeleteEntityContainer
                  name="cycle"
                  labelMode={false}
                  entityUUID={row.uuid}
                  className="ant-btn customAntOutDangerBtn ant-btn-default"
                  styleBtn={{
                    borderRadius: '0px 4px 4px 0px',
                    borderLeft: 'none',
                  }}
                  actionForEntity={deleteCycleCallback}
                  checkHardDelete={false}
                  entityType={row.entity_type}
                  type="entity"
                  sizeBtn="small"
                  hideLabel
                  antNotificationSuccess
                  partitionType={partitionType}
                />
              )
            }
          </div>
        );
      },
    },
  ];

  const changeLimit = (data) => {
    setSizePerPage(data);
    setCurrentPage(1);

    const param = {
      ...config,
      limit: data,
      offset: 0,
    };
    setNewConfigCallback(id, param);
  };

  const changePaginationOptions = ({
    pageLimit,
    currentPage,
    offset,
  }) => {
    const data = {
      ...config,
      offset,
      limit: pageLimit,
    };
    setNewConfigCallback(id, data);
    setCurrentPage(currentPage);
  };

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys) => {
      changeSelectedCycle(selectedRowKeys);
    },
    selectedRowKeys: selectedCycle,
  };

  const currentSortCallback = (dataSort) => {
    if (!get(dataSort, 'order')) {
      changeOrderRules({
        orderBy: 'created',
        order: 'desc',
      });
    } else {
      changeOrderRules(dataSort);
    }
  };

  const onRow = (record) => ({
    onClick: () => {
      const { uuid } = record || {};

      selectTestCycleFunc(uuid);
    },
  });

  const rowClassName = (row) => `not-hide-checkbox
  ${!isArchive ? 'select_test_cycle_row' : 'select_test_cycle_archive_row'} ${selectedRows === get(row, 'uuid')
      ? 'selected'
      : ''} `;

  const onSelectTagsCallback = (tags) => {
    setCurrentPage(1);
    const param = {
      ...config,
      limit: sizePerPage,
      offset: 0,
      params: { archive: isArchive },
    };

    if (!isEmpty(tags)) {
      param.params.status = tags.map((tag) => tag.value);
    }

    setNewConfigCallback(id, param);
  };

  useEffect(() => {   
    const {tcy_archive} = getURLParams();

    const archiveTypes = {
      'true': true,
      'false': false
    };
    
    let params = {...testCycleFilterConfig}; 

    if(usersFilter.length){
      params.assignToUser = usersFilter;
    };
    if(executorsFilter.length){
      params.executed_by = executorsFilter;
    };
    if(modifiersFilter.length){
      params.modified_by = modifiersFilter;
    };
    if(!tcy_archive && defaultArchiveFilter){
      params.archive = archiveTypes[defaultArchiveFilter];
      setDefaultArchiveFilter(undefined);
    } 
    else 
    if(tcy_archive){
      params.archive = archiveTypes[tcy_archive];
    } 
    else delete params.archive;

    let param = {
      ...config,
      ...orderRules,
      limit: sizePerPage,
      offset: 0,
      params: params,
      search_data: {
        value: searchValue,
        fields: {
          params: ['title', 'customId'],
        }
      },
      partition: partitionType
    };

    setNewConfigCallback(id, param);
    
  }, [testCycleFilterConfig, orderRules, usersFilter, executorsFilter, modifiersFilter, searchValue]);

  useEffect(() => {
    if (curSelectedUUID !== selectedCycle) {
      changeSelectedRow(curSelectedUUID);
    }
  }, [curSelectedUUID]);

  useEffect(() => {
    if (
      globalUpdate?.entityType === id
      && globalUpdate?.action === 'delete'
      && selectedCycle?.length
      && selectedCycle?.includes(globalUpdate?.uuid)) {
      changeSelectedCycle((prev) => [...prev.filter((i) => i !== globalUpdate?.uuid)]);
      dispatch(clearGlobalUpdateEntity());
    }
  }, [globalUpdate.entityType]);

  useEffect(() => {
    return () => {
      dispatch(clearListTestCycleInStore())
    }
  }, [])

  return (
    <Row>
      <Col span={24}>
        <Row>
          
            <Col span={8} className="flex items-center flex-wrap">
              <h6 className="m-0 whitespace-nowrap">
                {title}
                <Badge
                  count={listTestCycles.length}
                  showZero
                  style={{ backgroundColor: colorStyle }}
                  className="ml-1"
                />
              </h6>
              <Space
                size={[5, 5]}
                // wrap
                className="justify-end ml-1"
              >
                {!isArchive && (
                  <NewTestCycleWithImportTestCases
                    hideBtnComponent
                    selectTestCycleCallback={selectTestCycleFunc}
                  />
                //   <BtnModal
                //     title={capitalize(t('wms.modals.headers.new_test_case_execution', 'new test case execution'))}
                //     width="40%"
                //     btnComponent={(
                //       <BaseButton
                //         className="ant-btn-flex customAntOutPrimaryBtn ant-btn-default ant-btn-sm"
                //         style={{borderRadius: '4px' }}
                //       >
                //         Add cycle
                //       </BaseButton>
                //   )}
                // >
                //   <NewExecutionForm
                //     typeOfParent={isArchive ? 'cycleArchive' : 'cycle'}
                //     // parentUuid={parentData?.uuid}
                //     // customTestCases={[]}
                //   />
                // </BtnModal>
                )}
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: (
                          <div onClick={stopPropagation}>
                            <ImportFromJSON
                              importCallback={createEntitiesFromJSON}
                            />
                          </div>
                        ),
                        key: '0',
                      },
                      {
                        label: (
                          <div onClick={stopPropagation}>
                            <Badge count={selectedCycle.length}>
                              <ExportQaAsJSON
                                typeOfEntity="testCycle"
                                customUuids={selectedCycle}
                                entityRequestParams={isArchive ? { archive: true } : {}}
                              />
                            </Badge>
                          </div>
                        ),
                        key: '1',
                      },
                      {
                        label: (
                          <div onClick={stopPropagation}>
                            <RemoveSelectedTestCycleWrapper
                              updateAfterRemove={updateAfterRemove}
                              isArchive={isArchive}
                              selectRows={selectedCycle}
                              disabled={!selectedCycle.length}
                            />
                          </div>
                        ),
                        key: '2',
                      },
                    ],
                  }}
                  onClick={stopPropagation}
                >
                  <Badge count={selectedCycle.length}>
                    <Button
                      size="small"
                      type="primary"
                      ghost
                      className='mt-1'
                    >
                      <Icon path={mdiDotsVertical} size={0.8} />
                    </Button>
                  </Badge>
                </Dropdown>
              </Space>
            </Col>
          
          {/* <Col span={currentHorizontalCollapseState ? 24 : 16} className="flex justify-end ">
            <WrapperEntityFilter
              onSelectTagsCallback={onSelectTagsCallback}
              isArchive={isArchive}
            />
          </Col> */}
        </Row>
      </Col>

      <Col span={24} className="wrapper_main_card_table mt-2 mb-3">
        <BaseTableWithPagination
          // loading={loading}
          newCurrentPage={currentPage}
          pageLimit={sizePerPage}
          changePageLimit={changeLimit}
          useCustomPagination
          columns={columns}
          data={listTestCycles}
          rowKey="uuid"
          rowSelection={rowSelection}
          size="small"
          pageSizeOptions={pageSizeOptions}
          total={totalCount}
          hideListSizeOption={currentHorizontalCollapseState}
          getPaginationOptions={changePaginationOptions}
          onRow={onRow}
          rowClassName={rowClassName}
          currentSortCallback={currentSortCallback}
          headerRowClassName={`not-hide-checkbox ${isArchive ? 'header-table-test-cycle-archive' : 'header-table-test-cycle'}`}
          filterCallback={changeCycleFilterConfig}
        />
      </Col>
    </Row>
  );
}

ListOfTestCycles.propTypes = {};

export default ListOfTestCycles;

import { ProjectTypeCommonConstants } from '../../constants/Constants';

const initialState = {
  fetching: true,
  fetchingCycle: false,
  data: [],
  allList: [],
  allListFetching: false,
  singleCycle: [],
  error: '',
  updating: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.ALL_LIST_OF_TEST_CYCLES_REQUEST:
      return {
        ...state,
        allListFetching: true,
      };
    case ProjectTypeCommonConstants.ALL_LIST_OF_TEST_CYCLES_SUCCESS:
    case ProjectTypeCommonConstants.CLEAR_ALL_LIST_OF_TEST_CYCLES:
      return {
        ...state,
        allListFetching: false,
        allList: payload,
      };
    case ProjectTypeCommonConstants.ALL_LIST_OF_TEST_CYCLES_FAILURE:
      return {
        ...state,
        allListFetching: false,
        allList: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.NEW_TEST_CYCLE_REQUEST:
      return {
        ...state,
        fetching: false,
      };
    case ProjectTypeCommonConstants.NEW_TEST_CYCLE_SUCCESS:
      return {
        ...state,
        fetching: false,
        singleCycle: payload,
      };
    case ProjectTypeCommonConstants.NEW_TEST_CYCLE_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.CLEAR_LIST_CYCLE_IN_STORE:
      return {
        ...state,
        data: [],
      };
    case ProjectTypeCommonConstants.EDIT_TEST_CYCLE_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case ProjectTypeCommonConstants.EDIT_TEST_CYCLE_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case ProjectTypeCommonConstants.EDIT_TEST_CYCLE_FAILURE:
      return {
        ...state,
        updating: false,
      };
    case ProjectTypeCommonConstants.GET_TEST_CYCLE_REQUEST:
      return {
        ...state,
        fetchingCycle: true,
      };
    case ProjectTypeCommonConstants.GET_TEST_CYCLE_SUCCESS:
    // console.log('payload',payload)
      return {
        ...state,
        fetchingCycle: false,
        singleCycle: payload,
      };
    case ProjectTypeCommonConstants.GET_TEST_CYCLE_FAILURE:
      return {
        ...state,
        fetchingCycle: false,
        singleCycle: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE:
      return {
        ...state,
        singleCycle: payload,
      };

    case ProjectTypeCommonConstants.CLEAR_TEST_CYCLE:
      // console.log('state singleCycle singleCycle', state.singleCycle)
      return {
        ...state,
        singleCycle: [],
      };

    default: return state;
  }
};

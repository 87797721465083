import { Row } from 'antd';
import React from 'react';

import DashboardDocsTableWrapper from './DashboardDocsTableWrapper';

import { useClearComponent } from '../../../../../hooks/useClearComponent';
import { clearDocument } from '../../../../../projectFlow/actions/PtcActionsForHook';

function DashboardDocsCard() {
  useClearComponent(clearDocument);

  return (
    <Row className="w-full mt-3" style={{ flex: "1 0 auto" }}>
      <DashboardDocsTableWrapper readOnlyComments />
    </Row>
  );
}

export default DashboardDocsCard;

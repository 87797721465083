import { head } from 'lodash';
import { getCookie } from '../54origins/cookies54origins';

export const appLabel = 'W54';
const appLabelForDomain = 'w54';

export const apiConfigNewDev = {
  auth: 'https://auth.09releasetest.54origins.com',
  entity: ' https://entity.09releasetest.54origins.com',
  [appLabelForDomain]: 'https://w54.09releasetest.54origins.com',
  yjsWebsocket: 'wss://entity.09releasetest.54origins.com/ws',
  notification: 'https://notification.09releasetest.54origins.com',
  'gpt-proxy': 'https://gpt-proxy.09releasetest.54origins.com',
  reports54: 'https://reports54.09releasetest.54origins.com',
};

const apiConfigProd = {
  auth: 'https://auth.p3.54origins.com',
  entity: 'https://entity.p3.54origins.com',
  notification: 'https://notification.p3.54origins.com',
  reports54: 'https://reports54.p3.54origins.com',
  [appLabelForDomain]: 'https://w54.p3.54origins.com',
  'gpt-proxy': 'https://gpt-proxy.p3.54origins.com',
};

const apiConfig = apiConfigNewDev;

const appLocation = window.location;
const replaceDomainByService = (service) => (appLocation.host.includes(appLabelForDomain)
  ? appLocation.origin.replace(appLabelForDomain, service) : apiConfig[service]);



export const configUrlEntity = replaceDomainByService('entity');
export const configUrlWMS = replaceDomainByService(appLabelForDomain);
export const configUrlAuth = replaceDomainByService('auth');
export const notificationUrl = replaceDomainByService('notification');
export const gptUrl = replaceDomainByService('gpt-proxy');
export const gptReportsUrl = replaceDomainByService('hrm54');

export const sessionNameFor = {
  auth: 'Auth',
  entity: 'Entity',
  notification: 'Notification',
  proxyGPT: 'Gpt_proxy',
};

export const getSessionTokenFor = {
  auth: () => getCookie(sessionNameFor.auth),
  entity: () => getCookie(sessionNameFor.entity),
  notification: () => getCookie(sessionNameFor.notification),
  proxyGPT: () => getCookie(sessionNameFor.proxyGPT),
};


export const partitionNamesConfig = {
  partition1: "PM",
  partition2: "USERS",
  partition3: "PUBLIC",
  partition4: "SALES",
  partition5: "DELIVERY"
}

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { string, func } from 'prop-types';
import { Row, Col } from 'antd';
import { capitalize } from 'lodash';

import './ProjectView.scss';

import ActivityView from '../../activityView/ActivityView';
import CheckEntityPerm from '../../../containers/permissions/CheckEntityPerm';
import MainArchiveStateTemplate from '../../archiveState/MainArchiveStateTemplate';
import PermissionsStartPage from '../../../containers/permissions/PermissionsStartPage';
import ProjectGeneralInfo from './ProjectGeneralInfo';
import ProjectSettings from '../../settingsView/ProjectSettings';
import UniGeneralButtons from '../../uniGeneralButtons/UniGeneralButtons';

import { getMainArchiveStateFetching } from '../../../selectors/archiveStateSelectors';
import { getProjectUUID } from '../../../selectors/selectors';
import useURLParams from '../../../../hooks/useURLParams';
import { PROJECT_TABS } from './projectTabs';

const tabs = [
  { name: PROJECT_TABS.INFO, title: 'general info' },
  { name: PROJECT_TABS.SETTINGS, title: 'settings' },
  { name: PROJECT_TABS.PERMISSIONS, title: 'permissions' },
  { name: PROJECT_TABS.AUDIT, title: 'project log' },
  { name: PROJECT_TABS.ARCHIVE, title: 'archive state' },
];

// const path = 'pm/projects/project';

function ProjectView({
  defaultPartition,
}) {
  const { t } = useTranslation();

  const mainArchiveStateFetching = useSelector(getMainArchiveStateFetching);
  const projectUUID = useSelector(getProjectUUID);

  const {
    // clearSearchParams,
    // routeNavigateAndClearParams,
    addAndRemoveSearchParams,
    getURLParams,
  } = useURLParams();

  const {
    activeProject,
    tab,
    goal,
  } = getURLParams();

  const switchView = (selectedTab) => {
    if (selectedTab !== tab) {
      addAndRemoveSearchParams({ tab: selectedTab }, [
        'tab',
        'activeIssue',
        'activeVector',
        'activeDoc',
        'activeNote',
        'activeSheet',
        'goal',
        'milestone',
        'activeActorPerm',
        'typeActor',
        'activeCard'
      ]);
    }
  };

  const routeProjectView = () => {
    // console.log('routeProjectView');

    switch (tab) {
      case PROJECT_TABS.INFO:
      case PROJECT_TABS.AUDIT:
      case PROJECT_TABS.SETTINGS:
      case PROJECT_TABS.ARCHIVE:
      case PROJECT_TABS.PERMISSIONS:
      case PROJECT_TABS.SPLIT:
        switchView(tab);
        break;

      default:
        switchView(PROJECT_TABS.INFO);
        break;
    }
  };

  useEffect(() => {
    if (activeProject) {
      routeProjectView();
    }
  }, [activeProject]);

  return (
    <>
      <Row span={24} className="mb-3">
        <UniGeneralButtons
          viewMode={tab}
          switchViewFunc={(selectedTab) => switchView(selectedTab)}
          tabs={tabs}
        />
      </Row>
      <Row style={{ flex: '1 0 auto' }}>
        {tab === PROJECT_TABS.INFO && (
          <Col span={24}>
            <ProjectGeneralInfo
              defaultPartition={defaultPartition}
            />
          </Col>
        )}

        {tab === PROJECT_TABS.AUDIT && (
          <Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
            <ActivityView />
          </Col>
        )}

        {tab === PROJECT_TABS.SETTINGS && (
          <Col span={24} className="">
            <ProjectSettings />
          </Col>
        )}

        {tab === PROJECT_TABS.ARCHIVE && (
          <Col span={24} className="">
            {!mainArchiveStateFetching && <MainArchiveStateTemplate entityUUID={projectUUID} />}
          </Col>
        )}

        {tab === PROJECT_TABS.PERMISSIONS && (
          <Col span={24} className="">
            <CheckEntityPerm
              partitionType={defaultPartition}
              entityUUID={projectUUID}
              permission={['set']}
            >
              <PermissionsStartPage
                partitionType={defaultPartition}
                entityUUID={projectUUID}
                nameSectionPermissions={capitalize(t('wms.permissions.headers.actions_permissions', 'actions permissions'))}
              />
            </CheckEntityPerm>
          </Col>
        )}
      </Row>
    </>
  );
}

ProjectView.propTypes = {
  defaultPartition: string,
};

export default ProjectView;

import { mdiMinus, mdiPlus } from '@mdi/js';
import PropTypes, { bool, string } from 'prop-types';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Row, Tooltip } from 'antd';
import {
  dateAdd, dateDiff,
  dateSubtract,
  dateToFormat1,
  dateToFormat2,
  defaultDate,
  transformDate,
} from '../../54origins/dateFormats54origins';

import './DateLeftEdit.scss';

const issueStatusesForInfinity = ['acquainted'];

function DateLeftEdit({
  id = 'calendar',
  status = '',
  regularFormat,
  expirationDate,
  disabled,
  onChangeFieldCallback,
}) {
  const { t } = useTranslation();

  const [currentDate, setCurrentDate] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);

  const addTimeByButton = () => {
    const date = dateToFormat2(dateAdd(1, 'days', dateToFormat2(currentDate)));
    setCurrentDate(date);
    onChangeFieldCallback({ lifeTime: date });
  };

  const onChangeCalendar = (date) => {
    // console.log('onChangeCalendar date', date);
    // console.log('crdt onChangeCalendar');
    const currDate = dateToFormat2(date);
    setCurrentDate(currDate);
    onChangeFieldCallback({ lifeTime: currDate });
  };

  const subtractTimeByButton = () => {
    const currDate = dateToFormat2(dateSubtract(1, 'days', dateToFormat2(currentDate)));
    setCurrentDate(currDate);
    onChangeFieldCallback({ lifeTime: currDate });
  };

  const current = dateDiff(defaultDate(), 'days', currentDate);

  const dayLeft = () => `${current} ${t('wms.labels.lifetime.days_left', 'days_left')}`;

  const dayOverdue = () => {
    const label = current?.toString()?.replace('-', '');
    return `${label} ${t('wms.labels.lifetime.days_overdue', 'days_overdue')}`;
  };

  const getLabel = () => {
    if (disabled || issueStatusesForInfinity?.includes(status)) {
      return (
        <Row className="flex items-center">
          <Row
            className="mr-1"
            style={{
              fontSize: '25px',
            }}
          >
            {'\u221E'}
          </Row>
          {`${t('wms.labels.lifetime.days_left', 'days_left')}`}
        </Row>
      );
    }
    if (regularFormat) {
      return dateToFormat1(currentDate);
    }
    return current >= 0
      ? dayLeft()
      : dayOverdue();
  };

  const handleChangeShowCalendar = (bool) => {
    setShowCalendar(bool);
  };


  const handleInputClick = () => {
    setShowCalendar((prev) => !prev)
  }

  useEffect(() => {
    if (expirationDate && expirationDate !== currentDate) {
      setCurrentDate(expirationDate);
    }
  }, [expirationDate]);



  return (
    <div className="flex"  >
      <div className="button-time-wrapper flex ml-1">
        <button
          id='subtractTimeButton'
          className="button-time-wrapper__button-left"
          type="button"
          onClick={subtractTimeByButton}
          style={{
            cursor: disabled && 'default',
          }}
          disabled={disabled}
        >

          <Icon path={mdiMinus} size={0.6} />
        </button>

        <Tooltip title={currentDate}>
          <button
            id='openCalendarButton'
            className={`button-time-wrapper__title_time-work ${disabled ? 'disabled-date-edit' : ''} `}
            type="button"
            style={{
              cursor: disabled && 'default', border: 'none',
            }}
          >
            {getLabel()}
            <div
              id={id}
              aria-hidden
            >
              <input
                className="hiddenInputForCalendar"
                onClick={handleInputClick}
              />
              <DatePicker
                onOpenChange={handleChangeShowCalendar}
                getPopupContainer={() => document.getElementById(id)}
                value={transformDate(currentDate)}
                className="hideDatePicker"
                open={showCalendar}
                onChange={onChangeCalendar}
                disabled={disabled}
                // onBlur={inputOnBlur}
              />
            </div>
          </button>
        </Tooltip>

        <button
          id='addTimeButton'
          onClick={addTimeByButton}
          type="button"
          className="button-time-wrapper__button-right"
          style={{
            cursor: disabled && 'default',
          }}
          disabled={disabled}
        >

          <Icon path={mdiPlus} size={0.6} />
        </button>
      </div>
    </div>
  );
}

DateLeftEdit.propTypes = {
  id: PropTypes.string,
  expirationDate: PropTypes.string,
  onChangeFieldCallback: PropTypes.func,
  regularFormat: string,
  status: string,
  disabled: bool,
};

export default DateLeftEdit;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Badge, Card, Dropdown, Flex, Popover, Space, Statistic, Tag } from "antd";
import cx from 'classnames';

import BaseButton from "../../../components/_ui/BaseButton/BaseButton";
import EntityParamsPopoverNew from "../commonComponents/EntityParamsPopoverNew";
import GoalsPriorityBtns from "./GoalsPriorityBtns";
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";

import { useGoalsRequestHook } from "../../../entity/hooks/useGoalsRequestHook";
import { getProjectUUID } from "../../selectors/selectors";
import { goalStatusOptions } from "./constants/goalStatuses";
import { defaultSuccessNotify } from "../../../MainUtils";
import { EXPAND_MODE, useExpandModeContext } from "../commonComponents/expandModeLayout/ExpandModeLayout";
import { groupBy } from "lodash";




const ProjectGoalsScope = ({
     defaultPartition,
     changePlanningHistory,
     updateGoalCallback
}) => {
  const { setExpandedMode } = useExpandModeContext();
  const { getProjectGoalsScopeRequest, updateProjectGoalRequest } = useGoalsRequestHook(defaultPartition)

  const projectUUID = useSelector(getProjectUUID);

  const [goalsScope, setGoalsScope] = useState([])
  const [selectedUUID, setSelectedUUID] = useState('')

  const initFunc = async () => {
    const desiredOrder = ['in progress', 'created', 'backlog', 'failed', 'completed'];

    let goalsRequestData = await getProjectGoalsScopeRequest({
      parent: projectUUID
    })

    // goalsRequestData = goalsRequestData?.data?.filter((item) => {
    //   const { params: { description } } = item || {}
    //   return description && description?.length !== 0
    // })

    // goalsRequestData.sort((a, b) => {
    //   return desiredOrder.indexOf(a?.params?.status) - desiredOrder.indexOf(b?.params?.status);
    // });

    setGoalsScope(goalsRequestData?.data)
  }

  const changeStatus = (status, uuid) => {
    const params = {
      status: status
    }

    updateProjectGoalRequest({uuid, params}).then(() => {
      defaultSuccessNotify()
      initFunc()
      updateGoalCallback?.()
    })
  }

  const priorityBtnCallback = () => {
    initFunc()
    updateGoalCallback?.()
  }

  useEffect(() => {
    initFunc()
    setExpandedMode(EXPAND_MODE.BASE)
  }, [])

  const lengthGoalsByStatus = () => groupBy(goalsScope,item => item?.params?.status)
  const getLengthByStatus = status => lengthGoalsByStatus()?.[status]?.length || 0;

  return(
    <BaseCard title={
      <>
      <Space size={'small'}>
        <span>Project goals scope:</span>
        <Tag color={'blue'}>Created: {getLengthByStatus('created')}</Tag>
        <Tag color={'geekblue'}>In progress: {getLengthByStatus('in progress')}</Tag>
        <Tag color={'green'}>Completed: {getLengthByStatus('completed')}</Tag>
        <Tag color={'red'}>Failed: {getLengthByStatus('failed')}</Tag>
        <Tag>Backlog: {getLengthByStatus('backlog')}</Tag>
      </Space>
      </>
    }>
      {goalsScope.map((item, index) => {
        const {
          params: {
            title,
            description,
            status,
          },
          uuid
        } = item || {}

        return(
          <React.Fragment key={uuid}>
            <Popover
              open={uuid === selectedUUID}
              placement="left"
              // title={title.length > 30 ? title.slice(0, 30) + "..." : title}
              content={(
              <Flex gap={4}>
                <BaseButton
                  // size={'small'}
                  type={'primary'}
                  onClick={() => changePlanningHistory('goal', uuid)}
                >
                  Go to goal
                </BaseButton>
                <EntityParamsPopoverNew
                  param="status"
                  currentValue={status}
                  options={goalStatusOptions}
                  previewType="hoverValueAndIconWithArrow"
                  withIcons
                  withBorder
                  onChangeValue={(status) => {
                    changeStatus(status, uuid)
                    setSelectedUUID(false)
                  }}
                />
                <GoalsPriorityBtns
                  goalUUID={uuid}
                  defaultPartition={defaultPartition}
                  onClickCallback={priorityBtnCallback}
                />
              </Flex>
              )}
              trigger="click"
            >
              <div onClick={() => {setSelectedUUID(uuid)}} 
              className={cx('cursor-pointer p-2', {
                'border-sky-100 bg-sky-50 hover:bg-sky-100': status === 'created',
                'border-indigo-100 bg-indigo-50 hover:bg-indigo-100': status === 'in progress',
                'border-neutral-100 bg-neutral-50 hover:bg-neutral-100': status === 'backlog',
                'border-red-100 bg-red-50 hover:bg-red-100': status === 'failed',
                'border-green-100 bg-green-50 hover:bg-green-100': status === 'completed',
              })}>
                <h6 className={'font-medium'}>{title}</h6>
                <hr/>
                <p
                  className={'whitespace-pre-line break-all'}
                >
                  {description}
                </p>
              </div>
            </Popover>
          </React.Fragment>
        )
      })}
    </BaseCard>
  )
}


export default ProjectGoalsScope;

import { Col, Row, Space } from 'antd';
import {
  isEmpty, isNil, pathOr, prop,
} from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { mdiRestart } from '@mdi/js';
import Icon from '@mdi/react';
import { capitalize } from 'lodash';

import CreateProjectOrVectorWrapper from './CreateProjectOrVectorWrapper';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import HardDeleteEntityContainer from '../../../entity/containers/HardDeleteEntityContainer';
import SaveAsCookbookModal from './SaveAsCookbookModal';
import ImportFromJSON from '../qaView/exportAsJSON/ImportFromJSON';

function ActionCookBook({
  cookBookState,
  // selectedEntity,
  // setTemplate,
  toResetArea,
  createEntities,
  entityType,
  parentProject,
  setCookBookState,
  currentPartition,
  getListTemplateCookBook,
  removeCookBookTemplateCallback,
  currentSelectedRow,
  importCookBookCallback,
}) {
  const { t } = useTranslation();

  const disabledBtnProject = (!cookBookState.jsObject || isEmpty(
    cookBookState.jsObject,
  ));

  const disabledBtnVectors = (
    isNil(cookBookState.jsObject.issues)
    && isNil(cookBookState.jsObject.vectors))
    || (pathOr([], ['jsObject', 'vectors'], cookBookState).length === 0
      && pathOr([], ['jsObject', 'issues'], cookBookState).length === 0
    );

  const disabledBtn = entityType === 'project' ? disabledBtnProject : disabledBtnVectors;

  return (cookBookState.jsObject && !isEmpty(cookBookState.jsObject)) ? (
    <Row>
      <Col span={12}>
        <Space
          size={[5, 5]}
          wrap
          className="flex items-center"
        >
          <BaseButton
            className="btnWarning-outline"
            size="small"
            onClick={toResetArea}
            disabled={disabledBtn}
          >
            <Icon
              className="mr-1"
              path={mdiRestart}
              size={0.8}
            />
            {capitalize(
              t('wms.buttons.reset', 'reset'),
            )}
          </BaseButton>

          <ImportFromJSON
            importCallback={importCookBookCallback}
          />

          <SaveAsCookbookModal
            entityType={entityType}
            cookBookState={cookBookState}
            disabledBtn={disabledBtn}
            getListTemplateCookBook={getListTemplateCookBook}
          />

          <CreateProjectOrVectorWrapper
            disabledBtn={disabledBtn}
            cookBookState={cookBookState}
            setCookBookState={setCookBookState}
            createEntities={createEntities}
            toResetArea={toResetArea}
            entityType={entityType}
            parentProject={parentProject}
            defaultPartition={currentPartition}
          />
        </Space>
      </Col>
      <Col span={12} className="flex justify-end">
        <HardDeleteEntityContainer
          partitionType={prop('partition', currentSelectedRow)}
          className="mr-1 btnDanger-outline"
          sizeBtn="small"
          disabled={isNil(prop('data', currentSelectedRow))}
          name="cookbook"
          entityType={entityType}
          type="entity"
          entityUUID={prop('data', currentSelectedRow)}
          actionForEntity={() => {
            removeCookBookTemplateCallback(
              prop('data', currentSelectedRow),
              prop('partition', currentSelectedRow),
            );
          }}
          checkHardDelete={false}
        />
      </Col>
    </Row>
  ) : (
    <Col span={24} className="flex justify-end">
      <div>
        {' '}
        <ImportFromJSON
          importCallback={importCookBookCallback}
        />
      </div>
    </Col>
  );
}

ActionCookBook.propTypes = {
  cookBookState: PropTypes.object,
  toResetArea: PropTypes.func,
  createEntities: PropTypes.func,
  entityType: PropTypes.string,
  parentProject: PropTypes.object,
  currentPartition: PropTypes.string,
  getListTemplateCookBook: PropTypes.func,
  setCookBookState: PropTypes.func,
  removeCookBookTemplateCallback: PropTypes.func,
  currentSelectedRow: PropTypes.object,
  importCookBookCallback: PropTypes.func,
};

export default ActionCookBook;

const parseListItems = (list) => {
  const items = list.querySelectorAll(":scope > li");
  let result = [];

  items.forEach((item) => {
    // console.log('crdt li', item);
    const childNodes = item.children;
    // console.log("crdt childNodes", childNodes);
    let mainText = "";
    if (childNodes?.length) {
      // Проходимся по каждому дочернему элементу
      Array.from(childNodes).forEach((child) => {
        // Если элемент не является <li>, выполняем нужные действия
        if (child.tagName.toLowerCase() !== "li") {
          mainText += child.textContent.trim() + " ";
        }
      });
    } else {
      mainText += item?.textContent.trim();
    }

    const nestedLists = item.querySelectorAll(":scope > ul, :scope > ol");

    let nestedTexts = [];
    nestedLists.forEach((nestedList) => {
      nestedList.querySelectorAll("li").forEach((nestedLi, idx) => {
        nestedTexts.push(`${idx + 1}. ${nestedLi.textContent.trim()}`);
      });
    });

    if (nestedTexts.length > 0) {
      result.push({ title: mainText, content: nestedTexts });
    } else {
      result.push({ title: mainText });
    }
  });

  return result;
};

export function parseHTMLString(htmlString) {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const ul = doc.querySelectorAll("ul:not(ul ul):not(ol ul)");
    const ol = doc.querySelectorAll("ol:not(ol ol):not(ul ol)");
    const p = doc.querySelectorAll("p:not(ul p):not(ol p):not(li p)");
    // console.log('crdt ul',  ul)
    // console.log('crdt ol',  ol)
    // console.log('crdt p',  p)
    // console.log('crdt htmlString',  htmlString)
    const check = ul?.length || ol?.length || p?.length;
    let result = [];
    if (!check) {
      const oneMainText = doc.body.textContent;
      result.push({ title: oneMainText, content:  [oneMainText]});
    } else {
      p.forEach((item) => item.textContent ? result.push({ title: item.textContent }): null);
      ul.forEach((item) => result.push(parseListItems(item)));
      ol.forEach((item) => result.push(parseListItems(item)));
    }
    return result.flat();
  } catch (e) {
    console.log(e);
    return [];
  }
}

import { mdiInformation } from "@mdi/js"
import Icon from "@mdi/react"
import { Flex } from "antd"
import { number, object, string } from "prop-types";
import React from "react"

export default function AssignUsersInfoSearch({
    valueSearch = '',
    selectedTab,
    allConfig,
    projectConfig,
    vectorConfig,
    allTotal,
    projectTotal,
    vectorTotal,
}) {

    const showAll = selectedTab !== 'all' && !!allConfig && !!allTotal;
    const showProject = selectedTab !== 'project' && !!projectConfig && !!projectTotal;
    const showVector = selectedTab !== 'vector' && !!vectorConfig && !!vectorTotal;

    if (!valueSearch) return null;

    return <Flex
        className='my-1'
    >
        {showAll
            || showProject
            || showVector
            ? <Icon
                path={mdiInformation}
                color={"#1677ff"}
                size={1}
            /> : null
        }
        <Flex vertical>
            {showAll &&
                <span className='ml-2 text-blue'>{'In tab All found ' + allTotal + ' users'}</span>
            }
            {showProject &&
                <span className='ml-2 text-blue'>
                    {'In tab Project found ' + projectTotal + ' users'}
                </span>
            }

            {showVector &&
                <span className='ml-2 text-blue'>
                    {'In tab Vector found ' + vectorTotal + ' users'}
                </span>
            }
        </Flex>
    </Flex>
}

AssignUsersInfoSearch.propTypes = {
    valueSearch: string,
    selectedTab: string,
    allConfig: object,
    projectConfig: object,
    vectorConfig: object,
    allTotal: number,
    projectTotal: number,
    vectorTotal: number,
}
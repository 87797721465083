import React, { useRef, useEffect } from "react";

import { Flex, Input } from "antd";
import { UI54Icon } from "@agpl/ui54kit";
import BaseButton from "../../../../../../../components/_ui/BaseButton/BaseButton";

import { mdiMagnify, mdiClose } from '@mdi/js';

import { debounce } from '../utils';

function ListHeaderWithSearch({
  title,
  size = 'p-3',
  usersCount,
  searchMode,
  searchDelay = 500,
  setSearchMode,
  setSearchValue,
  searchFunction,
}) {
  const searchInputRef = useRef(null);

  const toggleSearchMode = () => {
    setSearchMode((prev) => !prev);
  };

  const debouncedSearch = debounce(searchFunction, searchDelay);
  const searchUser = (e) => {
    const value = e.target.value;
    debouncedSearch(value?.toLowerCase());
  };

  useEffect(() => {
    if (searchMode) searchInputRef.current.focus({ cursor: 'start' });
    else setSearchValue('');
  }, [searchMode]);

  return (
    <Flex
      className={`sticky inset-0 z-10 ${size} bg-blue-100 border-b rounded-b border-gray-300 shadow-md`}
      gap={20}
      align='center'
      justify='space-between'
    >
      {!searchMode && (
        <span className={`text-base font-bold text-sky-700 shrink-0 grow`}>
          {`${title}${usersCount ? `: ${usersCount}` : ''}`}
        </span>
      )}
      <Flex className={`${searchMode ? 'w-full' : ''}`} gap={2} align='center'>
        {searchMode && (
          <Input
            ref={searchInputRef}
            width={'100%'}
            variant='borderless'
            size='small'
            placeholder='Search user'
            allowClear
            disaled={false}
            onChange={searchUser}
          />
        )}

        <BaseButton
          style={{ width: '45px' }}
          className='shrink-0'
          // type={searchMode ? "text" : "default"}
          type='text'
          variant='outlined'
          icon={
            <UI54Icon
              className='text-sky-700'
              path={searchMode ? mdiClose : mdiMagnify}
              size={1}
            />}
          onClick={toggleSearchMode}
        />
      </Flex>
    </Flex>
  );
}

export default ListHeaderWithSearch;

import React from "react";

// --------------- redux -------------
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../../../entity/selectors/selectors";
import { clearCurrentQuillInstance } from "../../../../reducers/slicers/quillSlicer";
import { setDocumentContent } from "../../../../actions/DocumentsActions";
// import { getDeletingDocUuid, getDocHistoryArr, setDeletingDocUuid } from "../../../../reducers/slicers/quillSlicer";
import {
  getAllDocumentStatesRequest,
  documentStateFullRewriteRequest,
  updateDocumentPartiallyRequest,
} from "../../../../actions/DocumentsActions";
import { ProjectTypeCommonConstants } from "../../../../constants/Constants";

// -------------- utils ----------------
import {
  parseOldDocumentStructure,
  isValidHTML,
} from "./utils/parseOldDocumentStructure";
import { createPythonByteString } from "./utils/createPythonByteString";
import { parseCrdtDocumentStructure } from "./utils/parseCrdtDocumentStructure";
import { clearRepeatedDeltas } from "./utils/clearRepeatedDeltas";
import { parseContent } from "./utils/parseCrdtDocumentStructure";
import { saveAs } from "file-saver";
import { pdfExporter } from "quill-to-pdf";

export const useQuill = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const createNameWrapper = (clientID, id) => {
    const check = typeof id === "string" || typeof id === "number";
    if (check) {
      if (clientID === id) {
        return (
          <>
            <strong>{id}</strong>{" "}
          </>
        );
      }
      return id;
    }
    return "";
  };

  const handleExportPdf = async (delta) => {
    // console.log('crdt pdf delta', delta)
    const pdfAsBlob = await pdfExporter.generatePdf(delta); // converts to PDF
    saveAs(pdfAsBlob, "pdf-export.pdf"); // downloads from the browser
  };

  const handleExportDocx = (htmlString, filename = "") => {
    const preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    const postHtml = "</body></html>";
    const html = preHtml + htmlString + postHtml;
    const blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });
    // Specify link url
    const url =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
    // Specify file name
    filename = filename ? filename + ".docx" : "document.docx";
    // Create download link element
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;
      // Setting the file name
      downloadLink.download = filename;
      //triggering the function
      downloadLink.click();
    }
    document.body.removeChild(downloadLink);
  };

  const updateActualState = async ({
    actualState,
    docUUID,
    partition,
    newTextLength,
  }) => {
    // console.log('crdt actualState', actualState)
    // console.log('crdt newTextLength', newTextLength)
    const constants = [
      ProjectTypeCommonConstants.UPDATE_DOCUMENT_ACTUAL_STATE_REQUEST,
      ProjectTypeCommonConstants.UPDATE_DOCUMENT_ACTUAL_STATE_SUCCESS,
      ProjectTypeCommonConstants.UPDATE_DOCUMENT_ACTUAL_STATE_FAILURE,
    ];
    const config = {
      entity_uuid: docUUID,
      params: {},
    };

    if (actualState) {
      config.params.actualState = actualState;
    }

    if (newTextLength) {
      config.params.status = "system";
      config.params.symbols_count = newTextLength;
    }

    // console.log('crdt updateActualState', config);

    if (newTextLength || actualState) {
      return dispatch(
        updateDocumentPartiallyRequest(config, partition, constants)
      );
    }
  };

  const mergeDeltas = (filteredStates) => {
    const stateVector = parseCrdtDocumentStructure(filteredStates);
    const result = createPythonByteString(stateVector);
    return result;
  };

  const checkDeltasMaxSize = (filteredStates) => {
    const size =
      filteredStates.reduce(
        (acc, delta) =>
          acc + new TextEncoder().encode(JSON.stringify(delta)).length,
        0
      ) / 1024;
    const maxSizeForMerge = 15360; // 15Mb - получим ошибку при попытке перезаписи в БД
    const checkMaxSize = size <= maxSizeForMerge;
    return checkMaxSize;
  };

  const checkDocForMergeDeltas = (filteredStates) => {
    try {
      const size =
        filteredStates.reduce(
          (acc, delta) =>
            acc + new TextEncoder().encode(JSON.stringify(delta)).length,
          0
        ) / 1024;
      const minSizeForMerge = 5120; // 5Mb

      const minDeltasCount = 10; // минимальное количество дельт для мержа, если док более 5мб
      const currentDeltasCount = filteredStates?.length || 0;
      const checkMinSize = size >= minSizeForMerge;
      const checkMinDeltasCount = currentDeltasCount >= minDeltasCount;
      if (checkMinSize && checkMinDeltasCount) {
        // условия для мержа списка дельт в 1 выполнены
        const pythonByteString = mergeDeltas(filteredStates); // мержим
        const newDeltaArr = [
          { text: pythonByteString, timestamp: Date.now() / 1000 },
        ];
        const checkMergedSize = checkDeltasMaxSize(newDeltaArr); // проверяем размер дельты
        // console.log('crdt byteString')
        return checkMergedSize ? newDeltaArr : null; // если превышает - не перезаписываем
      }
      // если условия для мержа не выполнены
      const checkFilteredStatesSize = checkDeltasMaxSize(filteredStates); // проверяем размер дельт
      // console.log('crdt filteredStates', checkFilteredStatesSize ? filteredStates : null);
      return checkFilteredStatesSize ? filteredStates : null; // если превышает - не перезаписываем
    } catch (e) {
      console.log("crdt error size", e);
      return null;
    }
  };

  const checkAndClearDocumentStates = async (props) => {
    // запуск проверки на повторяющиеся дельты
    const { docUUID, defaultPartition, newTextLength } = props;
    const config = {
      uuid: docUUID,
      partition: defaultPartition,
    };

    const { states } = await dispatch(getAllDocumentStatesRequest(config));
    //  console.log("crdt STATES:", states);
    // console.log('crdt newTextLength', newTextLength)
    if (Array.isArray(states)) {
      //  const finalStates = states.map((item, idx, arr) => {
      //    const { text, from_state_id} = item || {};
      //    const finalText = from_state_id ? arr?.[from_state_id]?.text : text;
      //    return { ...item,  text: finalText, baseIdx: idx };
      //  });

      // console.log('crdt finalStates', finalStates?.length);
      const rawFilteredStates = clearRepeatedDeltas(states, true);
      // console.log('crdt states?.length', states?.length);
      // console.log('crdt rawFilteredStates?.length', rawFilteredStates?.length);
      if (states?.length !== rawFilteredStates?.length) {
        const filteredStates = checkDocForMergeDeltas(rawFilteredStates); // проверяем док на необходимость мержа дельт
        if (filteredStates) {
          const data = {
            uuid: docUUID,
            partition: defaultPartition,
            states: filteredStates,
          };
          const resp = await dispatch(documentStateFullRewriteRequest(data));
          // console.log("crdt resp", resp);
          if (resp?.status === "Success") {
            const stateConfig = {
              actualState: `${filteredStates?.length}`,
              docUUID,
              partition: defaultPartition,
              newTextLength,
            };
            await updateActualState(stateConfig);
            // console.log("crdt respActualState", respActualState);
          }
        }
      } else {
        await updateActualState({
          docUUID,
          partition: defaultPartition,
          newTextLength,
        });
      }
    }
  };

  const checkUsersInRoom = async (props) => {
    // const { userDataArr, defaultActualState } = props;
    const { userDataArr } = props;
    const usersUniqueUuids = new Set();
    if (Array.isArray(userDataArr)) {
      userDataArr.forEach((arr) => usersUniqueUuids.add(arr[1]?.user?.uuid));
    }
    const uuidsArr = Array.from(usersUniqueUuids);
    if (uuidsArr?.length === 1 && user?.uuid === uuidsArr?.[0]) {
      // проверяем, последний ли пользователь выходит из дока
      await checkAndClearDocumentStates(props);
    }
  };

  const clearAllValues = async (props) => {
    const { editorRef, providerRef, documentRef } = props;
    if (editorRef.current?.container) {
      // console.log("crdt clearAllValues");
      providerRef.current?.destroy();
      documentRef.current?.destroy();
      providerRef.current = null;
      documentRef.current = null;
      dispatch(clearCurrentQuillInstance());
      dispatch(
        setDocumentContent({ startHtmlString: "", startStateVector: "" })
      );
    }
  };

  const handleUnmountEditor = async ({
    docUUID,
    defaultActualState,
    defaultPartition,
    withoutConnection,
    newTextLength,
    userDataArr,
    currentData,
  }) => {
    // console.log('crdt handleUnmountEditor docUUID', docUUID);
    // console.log('crdt handleUnmountEditor docHistoryArr', docHistoryArr);
    // console.log('crdt handleUnmountEditor deletingDocUuid', deletingDocUuid);
    // dispatch(setDeletingDocUuid(null));
    try {
      if (!withoutConnection) {
        // если было активное соединение
        await checkUsersInRoom({
          docUUID,
          defaultActualState,
          defaultPartition,
          userDataArr,
          withoutConnection,
          newTextLength,
        });
      }
    } catch (e) {
      // console.log("quill unmount error", e);
      // console.log("crdt currentData", currentData);
      const checkError = e?.status === 404 && e?.message === 'There is no entity with such uuid';
      if (checkError) {
        return currentData;
      }
    }
  };

  return {
    createNameWrapper,
    handleExportDocx,
    handleExportPdf,
    parseOldDocumentStructure,
    parseCrdtDocumentStructure,
    parseCrdtByteString: parseContent,
    isValidHTML,
    clearRepeatedDeltas,
    handleUnmountEditor,
    clearAllValues,
    updateActualState,
  };
};

import { mdiChevronDoubleLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { Card, Col, Row } from "antd";
import { array, bool, func, node, object, string } from "prop-types";
import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import BaseButton from "../../../components/_ui/BaseButton/BaseButton";
import TagsSettingList from "../../../components/filter/TagsSettingList";
import { capitalize } from "lodash";
import { FiltersContext } from "../../context/ProjectFlowListOfContexts";
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";

export default function CardWithFilter({
  id,
  title,
  saveStateToGlobalStore,
  mainClassName,
  hideReset,
  buttonCollapseClassName,
  customSelectedTags,
  children,
  isOpenDefault = false,
  getSelectedTagsCallback,
  allTags,
  customFilterStyle,
  toggleHorizontal,
}) {
  const { t } = useTranslation();
  const [canvasFlag, toggleCanvasFlag] = useReducer(
    previous => !previous,
    isOpenDefault
  );

  const handleClick = () => {
    toggleCanvasFlag();
    saveStateToGlobalStore?.();
    if (toggleHorizontal) {
      toggleHorizontal();
    }
  };

  const onSelectTags = tags => {
    if (getSelectedTagsCallback) {
      getSelectedTagsCallback(tags, id);
    }
  };

  const resetFilter = () => onSelectTags([]);

  return (
    <BaseCard className={`${mainClassName} w-full`}>
      <Row className="flex flex-nowrap">
        <Col span={canvasFlag ? 7 : 0.7}>
          <div className="flex flex-nowrap h-full w-full">
            <div
              onClick={handleClick}
              className={buttonCollapseClassName}
              tabIndex="0"
              role="button"
            >
              <Icon
                className="mb-2"
                path={mdiChevronDoubleLeft}
                rotate={canvasFlag ? 0 : 180}
                size={0.8}
              />
              <div className="header-collapse-filter__text">{title}</div>
            </div>

            <div
              className={`body-collapse-filter ${
                !canvasFlag ? "hideWidth" : ""
              }`}
            >
              <div
                className={`body-collapse-filter__main-wrapper ${
                  !canvasFlag ? "hideWidth" : ""
                } p-2`}
              >
                {/* <div className="flex justify-end w-full"> */}
                {/* <span>Vectors filter</span> */}
                {!hideReset && (
                  <BaseButton
                    size="small"
                    className="btnWarning-outline rounded-2xl mr-1"
                    onClick={resetFilter}
                    type="link"
                  >
                    {capitalize(t("wms.filters.show_all", "show all"))}
                  </BaseButton>
                )}
                {/* </div> */}

                <TagsSettingList
                  allTags={allTags}
                  defaultSelectedTags={customSelectedTags}
                  size="extraSmall"
                  onSelectTags={onSelectTags}
                  id={`${id}_filters`}
                />
              </div>
            </div>
          </div>
        </Col>

        <Col span={canvasFlag ? 17 : 23} className="padding-16">
          <FiltersContext.Provider value={{ isFiltersOpen: canvasFlag }}>
            {children}
          </FiltersContext.Provider>
        </Col>
      </Row>
    </BaseCard>
  );
}

CardWithFilter.propTypes = {
  id: string,
  title: string,
  mainClassName: string,
  customSelectedTags: array,
  children: node,
  getSelectedTagsCallback: func,
  allTags: array,
  isOpenDefault: bool,
  saveStateToGlobalStore: func,
  toggleHorizontal: func,
  customFilterStyle: object,
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiBookmarkOffOutline, mdiBookmarkPlusOutline } from '@mdi/js';
import { useDispatch } from 'react-redux';
import { capitalize, get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import BaseButton from '../../components/_ui/BaseButton/BaseButton';

import axiosRequest from '../../api/apiAxios';
import { ProjectTypeCommonConstants } from '../constants/Constants';
import { UI54Button, UI54Icon } from "@agpl/ui54kit";


function AddToBookmark({
  afterDeleteABookmark,
  type = 'circle',
  entityUUID,
  partition,
  placement,
  showTitle,
  className = '',
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [flagImportantForUser, changeFlagImportantForUser] = useState(false);
  const [fetching, setFetching] = useState(true);

  const deleteBookMark = () => {
    const params = {
      entity_uuid: entityUUID,
    };

    dispatch(
      axiosRequest.post(
        'bookmark/delete',
        [
          ProjectTypeCommonConstants.DELETE_BOOKMARK_REQUEST,
          ProjectTypeCommonConstants.DELETE_BOOKMARK_SUCCESS,
          ProjectTypeCommonConstants.DELETE_BOOKMARK_FAILURE,
        ],
        params,
        {
          partition,
          onSuccess: () => {
            changeFlagImportantForUser(false);
            afterDeleteABookmark?.();
          },
          // onFailure: reject,
        },
      ),
    );
  };

  const createBookMark = () => {
    const params = {
      entity_uuid: entityUUID,
    };

    dispatch(
      axiosRequest.post(
        'bookmark/create',
        [
          ProjectTypeCommonConstants.CREATE_BOOKMARK_REQUEST,
          ProjectTypeCommonConstants.CREATE_BOOKMARK_SUCCESS,
          ProjectTypeCommonConstants.CREATE_BOOKMARK_FAILURE,
        ],
        params,
        {
          partition,
          onSuccess: () => {
            changeFlagImportantForUser(true);
          },
          // onFailure: reject,
        },
      ),
    );
  };

  const checkBookmark = () => {
    const params = {
      entity_uuid: entityUUID,
    };

    dispatch(
      axiosRequest.post(
        'bookmark/list',
        [
          ProjectTypeCommonConstants.CHECK_BOOKMARK_REQUEST,
          ProjectTypeCommonConstants.CHECK_BOOKMARK_SUCCESS,
          ProjectTypeCommonConstants.CHECK_BOOKMARK_FAILURE,
        ],
        params,
        {
          partition,
          onSuccess: (res) => {
            const checkBool = !isEmpty(get(res, 'entity_data', get(res, 'entity_uuids')));
            setFetching(false);
            changeFlagImportantForUser(checkBool);
          },
          // onFailure: reject,
        },
      ),
    );
  };

  const onClick = () => {
    if (!fetching && !flagImportantForUser) {
      createBookMark();
    } else if (!fetching && flagImportantForUser) {
      deleteBookMark();
    }
  };

  useEffect((prev) => {
    if (!fetching) {
      setFetching(true);
    }

    if (entityUUID) {
      checkBookmark();
    }
  }, [entityUUID]);

  return (
    <Tooltip
      title={capitalize(
        t(
          `wms.buttons.${flagImportantForUser ? 'remove' : 'add'}_bookmark`,
          `${flagImportantForUser ? 'Remove from' : 'Add to'} bookmark`,
        ),
      )}
      placement={placement || 'top'}

    >

      <UI54Button
        id='addToBookmarkButton'
        size={type === 'outline' && 'small'}
        shape={type === 'circle' ? 'circle' : 'default'}
        className={type === 'outline' ? `btn-outline-red ${className}` : `view_btn_link__red ${className}`}
        onClick={onClick}
      >
        <UI54Icon
          path={flagImportantForUser ? mdiBookmarkOffOutline : mdiBookmarkPlusOutline}
          size={0.8}
        />
        {type === 'outline' && (flagImportantForUser ? 'Unbookmark' : 'Bookmark')}
      </UI54Button>
    </Tooltip>
  );
}

export default AddToBookmark;

AddToBookmark.propTypes = {
  afterDeleteABookmark: PropTypes.func,
  className: PropTypes.string,
  entityUUID: PropTypes.string,
  partition: PropTypes.string,
  placement: PropTypes.string,
  showTitle: PropTypes.bool,
  type: PropTypes.string,
};

import { isEmpty } from 'lodash';
import {
  path, prop, pathOr,
} from 'ramda';
import { createSelector } from 'reselect';
import { getNameForActor } from '../54origins/utils54origins';

// export const getAllActors = (state) => path(['EntityService', 'actorList', 'message'], state);

// export const getDefaultActors = (state) => pathOr([], ['EntityService', 'actorList', 'defaultUsers'], state);

export const getFetchingDefaultActors = (state) => pathOr([], ['EntityService', 'actorList', 'fetchingDefaultUsers'], state);

export const getFetchingListOfActors = (state) => pathOr([], ['EntityService', 'actorList', 'listOfActorsFetching'], state);

export const getActorsForAssign = (state) => pathOr([], ['EntityService', 'actorList', 'actorsForAssign'], state);

export const getTotalActorsForAssign = (state) => pathOr([], ['EntityService', 'actorList', 'totalActorsForAssign'], state);

export const getFetchingActorsForAssign = (state) => pathOr([], ['EntityService', 'actorList', 'fetchingActorsForAssign'], state);

export const getDefaultActorsAndBanedUsers = (state) => path(['EntityService', 'actorList', 'defaultUsersAndBanedUsers'], state);

export const getProjectActors = (state) => path(['EntityService', 'actorList', 'projectUsers'], state);

export const getTemporaryActors = (state) => path(['EntityService', 'actorList', 'temporaryActors'], state);

export const getPublicActors = (state) => path(['EntityService', 'actorList', 'publicUsers'], state);

export const getPublicActorsFromMainIssueEntity = (state) => path(['EntityService', 'actorList', 'publicUsersFromMainIssues'], state);

// export const defaultUsersMAP = createSelector(
//   getDefaultActors,
//   (items) => new Map(items.map((item) => [prop('uuid', item), item])),
// );

// export const getActorsByUuid = (state, uuids) => {
//   const allList = defaultUsersMAP(state);
//   const newList = [];

//   if (!isEmpty(uuids)) {
//     uuids.forEach((item) => {
//       if (allList.has(item)) {
//         const user = allList.get(item);
//         return newList.push({
//           value: prop('uuid', user),
//           label: `${pathOr('name', ['uinfo', 'first_name'], user)} ${pathOr('name', ['uinfo', 'last_name'], user)}`,
//           entityType: prop('actor_type', user),
//         });
//       }
//     });
//   }
//   return newList;
// };

export const getProjectActorsForSelectList = createSelector(getProjectActors, (actors) => actors.map((item) => ({
  value: prop('uuid', item),
  label: getNameForActor(item),
  entityType: prop('actor_type', item),
})));

export const getPublicActorsForSelectList = (state) => getPublicActors(state).map((item) => ({
  publicUserEntityUUID: item.publicUserEntityUUID,
  value: prop('uuid', item),
  label: getNameForActor(item),
}));

// export const getAllUsersFromActors = createSelector(
//   getDefaultActors,
//   (list) => list.filter(
//     ({ actor_type }) => actor_type === 'user' || actor_type === 'classic_user',
//   ),
// );

export const getPublicActorsFromMainIssueEntityForSelectList = (state) => getPublicActorsFromMainIssueEntity(state).map((item) => ({
  publicUserEntityUUID: prop('publicUserEntityUUID', item),
  value: prop('uuid', item),
  label: getNameForActor(item),
}));

// export const getActorsWithInitials = createSelector(
//   getAllActors,
//   (allActors) => {
//     const arr = new Map([]);
//     allActors.forEach((item) => {
//       const firstName = prop('first_name', item.uinfo);
//       const lastName = prop('last_name', item.uinfo);

//       const getFirstNameInitials = () => (firstName ? firstName.charAt(0).toUpperCase() : '');
//       const getLastNameInitials = () => (lastName ? lastName.charAt(0).toUpperCase() : '');

//       arr.set(item.uuid, {
//         initials: `${getFirstNameInitials()}${getLastNameInitials()}`,
//         fullName: `${firstName} ${lastName}`,
//         label: prop('name', item.uinfo) ? `${item.actor_type} ${item.uinfo.name}`
//           : prop('service_name', item.uinfo) ? `${item.actor_type} ${item.uinfo.service_name}`
//             : prop('first_name', item.uinfo) ? `${item.actor_type} ${item.uinfo.first_name}${item.uinfo.last_name}` : `${item.actor_type} ` + 'name',
//         value: item.uuid,
//       });
//     });

//     return arr;
//   },
// );

import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Badge, Row, Col, Checkbox, Spin,
} from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import SelectActorsComponent from '../../../../entity/components/SelectActorsComponent';
import WrapperSelectComponent from './WrapperSelectComponent';
import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';

import { capitalizeFirstChar } from '../../../../MainUtils';
import {
  allCasesFetching,
} from '../../../selectors/selectors';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import WrapperEntityFilter from "../componentsForMultipleUse/WrapperEntityFilter";
import {isEmpty} from "lodash";
import {defaultArrayTypeOfTesting} from "../QaUtils";
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import { getProjectParams, getProjectUUID } from '../../../selectors/selectors';

function ListOfAllTestCasesForRun({
  saveDataCallback,
  checkedCheckbox,
  getSelectedCases,
  getTitle,
  listAllTestCases,
  handleChangeSuites,
  onChangeCheckbox,
  selectedUUID = [],
  changeSelectedUUID,
  loading,
  assignToUsersOption,
  changeAssignToUsersOption,
  setConfig,
  config,
  buttonTitle = '',
  valueSuites = [],
}) {
  const { t } = useTranslation();

  const {
    partitionType,
  } = useContext(BugTrackerContext);

  const allCasesFetch = useSelector(allCasesFetching);
  const projectParams = useSelector(getProjectParams);
  const projectUUID = useSelector(getProjectUUID);

  const [pageLimit, setPageLimit] = useState(5);
  const [isAssignOpen, setIsAssignOpen] = useState(false)

  const onSelectTagsCallback = (tags) => {
    // console.log('tags', tags);
    const param = {
      ...config,
      limit: pageLimit,
      offset: 0,
      params: {},
    };

    if (!isEmpty(tags)) {
      tags.forEach((tag) => {
        if (param.params[tag.key] === undefined) {
          param.params[tag.key] = [];
        }
        param.params[tag.key].push(tag.value);
      });
    }
    setConfig(param);
  };

  const submitFunc = () => {
    saveDataCallback();
  };

  const assignToFunc = (value) => {
    changeAssignToUsersOption(value);
  };

  const stepsFormatter = (cell) => (
    <Badge
      count={cell ? cell.length : 0}
      showZero
      style={{ backgroundColor: '#038fde' }}
    />
  );

  const keyFormatter = (cell, row) => <span>{`${row?.id || 0}`}</span>;

  const columns = [
    {
      dataIndex: 'uuid',
      title: 'ID',
      render: keyFormatter,
    },
    {
      dataIndex: ['title'],
      title: capitalizeFirstChar(t('wms.table.headers.title', 'Title')),
    },
    {
      dataIndex: 'description',
      title: capitalizeFirstChar(t('wms.table.headers.description', 'Description')),
    },
    {
      dataIndex: 'created',
      title: capitalizeFirstChar(t('wms.table.headers.created', 'Created')),
      render: (cell) => (
        <div className="d-flex justify-content-start">
          {moment(cell).format('DD/MM/YYYY HH:mm')}
        </div>
      ),
    },
    {
      dataIndex: 'uinfo',
      title: capitalizeFirstChar(t('wms.table.headers.created_by', 'Created by')),
      render: (cell) => (
        <div className="d-flex justify-content-start">
          {`${cell.first_name} ${cell.last_name}`}
        </div>
      ),
    },
    {
      dataIndex: ['steps'],
      title: capitalizeFirstChar(t('wms.table.headers.steps', 'Steps')),
      render: stepsFormatter,
    },
  ];

  const onBlurSelectCallback = () => {};

  const selectRow = {
    mode: 'checkbox',
    selectedRowKeys: selectedUUID,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      changeSelectedUUID(selectedRowKeys);
      getSelectedCases(selectedRows);
    },
  };
  const pageSizeOptions = useMemo(() => ([5, 25, 50, 100]), []);

  // console.log('listAllTestCases',listAllTestCases)
  return (
    <>
      <Row type="flex">
        <Col span={24}>
          <Col>
            {capitalizeFirstChar(t('wms.projectflow.qa.suite.list.header', 'test suite list'))}
            <span style={{ color: 'red' }}>*</span>
          </Col>
          <WrapperSelectComponent
            handleChangeCallback={handleChangeSuites}
            onBlurSelectCallback={onBlurSelectCallback}
            valueSuites={valueSuites}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 12, marginLeft: -4 }}>
        <Col>
          <div style={{ marginLeft: 4 }}>
            {capitalizeFirstChar(`${t('wms.adjective.custom', 'custom')} ${t('wms.noun.tags', 'tags')}`)}
          </div>
          <WrapperEntityFilter
            isArchive={false}
            type="case"
            onSelectTagsCallback={onSelectTagsCallback}
          />
        </Col>
      </Row>
      <Spin spinning={allCasesFetch}>
        <Row type="flex" className="mt-3">
          <Col span={24}>
            <span>
              {capitalizeFirstChar(t('wms.projectflow.qa.case.list.header'), 'test case list')}
              :
            </span>
            <BaseTableWithPagination
              loading={loading}
              pageLimit={pageLimit}
              changePageLimit={setPageLimit}
              columns={columns}
              data={listAllTestCases}
              pageSizeOptions={pageSizeOptions}
              paginationSize="small"
              rowClassName="universal_table_row not-hide-checkbox"
              headerRowClassName="universal_header_table_row not-hide-checkbox"
              rowSelection={selectRow}
            />
          </Col>
        </Row>
        <div style={{ width: '50%', marginTop: '10px', marginBottom: '20px' }}>
          <SelectActorsComponent
            allowClear
            customSelectedActors={assignToUsersOption}
            // hideSelected
            partitionType={partitionType}
            listOfActors={['projectActors']}
            customInputLabel={capitalizeFirstChar(t('wms.labels.assign_to', 'assign to'))}
            saveActorsCallback={assignToFunc}
          />
          
        </div>
      </Spin>
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Col className="mr-5 d-flex align-items-center">
          <div className="mr-2">
            {capitalizeFirstChar(t('wms.modals.body.hide_added_test_cases', 'don\'t display already added test cases'))}
          </div>

          <Checkbox
            onChange={(e) => onChangeCheckbox(e.target.checked)}
            checked={checkedCheckbox}
          />
        </Col>
        <BaseButton
          type="primary"
          onClick={submitFunc}
          disabled={selectedUUID.length === 0 || getTitle.trim().length === 0}
        >
          {buttonTitle || capitalizeFirstChar(t('wms.buttons.import.test_cases', 'import test cases'))}
        </BaseButton>
      </Row>
    </>
  );
}

ListOfAllTestCasesForRun.propTypes = {
  onChangeCheckbox: PropTypes.func,
  getSelectedCases: PropTypes.func,
  saveDataCallback: PropTypes.func,
  checkedCheckbox: PropTypes.bool,
  listAllTestCases: PropTypes.array,
  handleChangeSuites: PropTypes.func,
  selectedUUID: PropTypes.string,
  changeSelectedUUID: PropTypes.func,
  loading: PropTypes.bool,
  assignToUsersOption: PropTypes.array,
  changeAssignToUsersOption: PropTypes.func,
  setConfig: PropTypes.func,
  config: PropTypes.object,
  buttonTitle: PropTypes.string,
  valueSuites: PropTypes.array,
};

export default ListOfAllTestCasesForRun;

import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";

export default function CustomCardComponent({
  type,
  children,
  customStyle,
  style,
  params,
  id,
}) {
  const getCardClassName = () => {
    switch (type) {
      case 'milestone':
      case 'goal':
        return 'primaryCard';
      case 'issue':
        return 'borderR10 overflow-hidden primaryCard';
      case 'vector':
        return 'borderR10 overflow-hidden dangerCard';
      case 'document':
        const {
          cardStyle, isDisabled, closeBtns, isDocumentOrNote,
        } = params;
        return `${cardStyle || (isDisabled || closeBtns ? 'primaryCard' : 'mergeCard colorHaveChanges')} ${isDocumentOrNote ? 'document-card' : ''}`;
      default: return '';
    }
  };

  return (
    <BaseCard
      id={id}
      className={`${getCardClassName()} ${customStyle}`}
      style={style}
    >
      {children}
    </BaseCard>
  );
}

CustomCardComponent.propTypes = {
  type: PropTypes.string,
  customStyle: PropTypes.string,
  children: PropTypes.element,
  style: PropTypes.object,
  params: PropTypes.object,
  id: PropTypes.string,
};

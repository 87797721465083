/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const initialState = {
  detailedReportParams: null,
  startWorkReports: null,
  datePeriod: null,
};

const dayUserReportUserInfoParamsSlice = createSlice({
  name: 'dayUserReportUserInfoParamsSlice',
  initialState,
  reducers: {
    setDayUserReportUserInfoParams: (state, action) => {
      state.detailedReportParams = action.payload;
    },
    setReportDatePeriod: (state, action) => {
      state.datePeriod = action.payload;
    },
    setStartWorkReports: (state, action) => {
      state.startWorkReports = action.payload;
    },
    clearDayUserReportUserInfoParams: (state) => {
      state.detailedReportParams = {};
    },
    clearReportDatePeriod: (state) => {
      state.datePeriod = null;
    },
  },
});

export const useUserReportsInfoActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(dayUserReportUserInfoParamsSlice.actions, dispatch);
};

export const {
  setDayUserReportUserInfoParams,
  setReportDatePeriod,
  setStartWorkReports,
  clearDayUserReportUserInfoParams,
  clearReportDatePeriod,
} = dayUserReportUserInfoParamsSlice.actions;

export default dayUserReportUserInfoParamsSlice.reducer;

import React from "react";
import { IssuePriorityRadioButtons, IssueTypeRadioButtons } from "../issues/IssueRadioButtons";
import { Flex, Row, Tooltip } from "antd";
import { stopPropagation } from "../../../54origins/utils54origins";
import DropDownParamsEntity from "../../../entity/components/dropDownParamsEntity/DropDownParamsEntity";
import IssueStatusesComponent from "../issues/IssueStatusesComponent";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";

export default function EditIssueForIssuesTable({
    onChangeFieldCallback,
    issue = {},
    type = '',
    placement = 'left',
    children,
    isEditMode,
}) {
    const { t } = useTranslation();

    if (!isEditMode) return <>{children}</>

    if (type === 'estimated_time') {
        return <>
            <DropDownParamsEntity
                type="estimated_time"
                destroyPopupOnHide
                overlayClassName={"border border-blue-200 rounded-lg"}
                placement={placement || 'left'}
                currentSelect={issue?.estimated_time}
                dropDownCallback={(data) => onChangeFieldCallback({
                    value: data?.estimated_time ?? 'empty',
                    oldValue: issue?.estimated_time ?? 'empty',
                    type: 'estimated_time',
                    uuid: issue?.uuid
                })}
                customComponentSelected={children}
            />
        </>
    }

    return (
        <Tooltip
            color="white"
            overlayClassName="border border-blue-200 rounded-lg"
            placement={placement || 'left'}
            title={<>
                {type === 'tracker' && (
                    <>
                        <span className="wrapper-title text-blue-500 pb-0">
                            {`Quick ${t('wms.tracker.type', 'type')} editing:`}
                        </span>
                        <IssueTypeRadioButtons
                            defaultValue={issue?.tracker ?? 'empty'}
                            onChange={(value) => onChangeFieldCallback({
                                value,
                                oldValue: issue?.tracker ?? 'empty',
                                type: 'tracker',
                                uuid: issue?.uuid
                            })}
                        />
                    </>
                )}
                {type === 'priority' && (
                    <>
                        <span className="wrapper-title text-blue-500 pb-0">
                            {`Quick ${t('wms.priority.title', 'priority')} editing:`}
                        </span>
                        <Flex className="w-max">
                            <IssuePriorityRadioButtons
                                defaultValue={issue?.priority ?? 'empty'}
                                onChange={(value) => onChangeFieldCallback({
                                    value,
                                    oldValue: issue?.priority ?? 'empty',
                                    type: 'priority',
                                    uuid: issue?.uuid
                                })}
                            />
                        </Flex>
                    </>
                )}
            </>}
        >
            {children}
        </Tooltip>)
}



EditIssueForIssuesTable.propType = {

}
import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Icon from '@mdi/react';
import { mdiArrowULeftTop, mdiPencil } from '@mdi/js';

import {
  any,
  bool,
  string,
  func,
} from 'prop-types';
import { Tooltip } from 'antd';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import { capitalize } from 'lodash';
import { UI54Button, UI54Icon } from "@agpl/ui54kit";

export default function EditButton({
  id,
  className,
  isEditFlag,
  isHidden = false,
  onClick,
  disabled,
  hideCancel,
}) {
  const { t } = useTranslation();
  const toggleBtn = (e) => onClick?.(e);
  const closeEdit = (e) => {
    const close = true;
    onClick?.(e, close);
  };

  return (
    !isHidden && (
      <div className="flex align-items center mr-2">
        <Tooltip
          title={capitalize(t('wms.verb.cancel'))}
          color="#E28000"
        >
          <UI54Button
            size="small"
            id={id}
            shape="default"
            className={cn('mr-1 view_btn_link__orange border', { 'hidden': !isEditFlag || hideCancel })}
            onClick={closeEdit}
          >
            <UI54Icon path={mdiArrowULeftTop} size={0.8} />
          </UI54Button>
        </Tooltip>

        {isEditFlag ? (
          <UI54Button
            size="small"
            id={id}
            shape="default"
            // style={{
            //   border: disabled ? '' : '#72b153de solid 1px',
            //   color: disabled ? '' : '#72b153de'
            // }}
            className={cn(`${className}`, { view_btn_link: !isEditFlag, btnGreen: isEditFlag })}
            disabled={disabled}
            onClick={toggleBtn}
          >
            {capitalize(t('wms.verb.save', 'save'))}
          </UI54Button>
        ) : (
          <UI54Button
            size="small"
            id={id}
            shape="default"
            className={cn(`${className} p-0`, {
              // view_btn_link: !isEditFlag,
              save_btn: isEditFlag,
              view_btn_link_circle: true,
            })}
            disabled={disabled}
            onClick={toggleBtn}
            style={{
              borderRadius: '50%',
            }}
          >
            <UI54Icon path={mdiPencil} size={0.7} />
          </UI54Button>
        )}
      </div>
    )
  );
}
EditButton.propTypes = {
  className: string,
  isEditFlag: bool,
  disabled: bool,
  hideCancel: bool,
  id: any,
  onClick: func,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import SimpleinfoModal from "./blocks/SimpleInfoModal";

function ToolbarHelper({ content }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const modalProps = {
    title: "Info",
    isOpen: open,
    handleClose: handleCancel,
    content,
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        className="btnPrimary-outline help-btn"
        icon={<QuestionCircleOutlined />}
      >
        Info
      </Button>
      <SimpleinfoModal {...modalProps} />
    </>
  );
}

ToolbarHelper.propTypes = {
  content: PropTypes.node,
};

export default ToolbarHelper;

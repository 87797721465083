import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty, isNil, prop, propOr,
} from 'ramda';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import { get } from 'lodash';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import { antNotification } from '../../../MainUtils';
import { capitalize } from 'lodash';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import {
  getCurrentArchiveStateUUID,
  getListOfArchiveState,
} from '../../selectors/archiveStateSelectors';
import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
import { entityRead, getListAndPartialReadEntities } from '../../../entity/actions/entityActions';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';

function ListOfArchiveState({
  setCurrentConfig,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    defaultPartition,
  } = useContext(ProjectMainLayerContext);

  const listOfArchives = useSelector(getListOfArchiveState);
  const curArchiveStateUUID = useSelector(getCurrentArchiveStateUUID);

  const [selectedStateArchive, changeSelectedStateArchive] = useState(curArchiveStateUUID);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);

  const resetArchiveState = () => dispatch({ type: ProjectTypeCommonConstants.CLEAR_ARCHIVE_ENTITY });

  const getStateArchiveEntity = (uuid) => {
    const data = {
      entity_type: 'archiveEntity',
      entity_uuid: uuid,
    };

    const constants = [
      ProjectTypeCommonConstants.GET_ARCHIVE_ENTITY_REQUEST,
      ProjectTypeCommonConstants.GET_ARCHIVE_ENTITY_SUCCESS,
      ProjectTypeCommonConstants.GET_ARCHIVE_ENTITY_FAILURE,
    ];

    const options = {
      partition: defaultPartition,
    };

    return dispatch(entityRead({
      data,
      constants,
      options,
    }));
  };

  const getAllChildrenFromArchiveState = async (uuid) => {
    const config = {
      data: {
        entityType: [
          'projectIdea',
          // 'sprint',
          'issue',
          'vector',
          'projectDocument',
        ],
        parent: uuid,
        fields: { entity_type: 'entity_type' },
      },
      partition: defaultPartition,
      constants: [
        ProjectTypeCommonConstants.ALL_DATA_FROM_ARCHIVE_ENTITY_REQUEST,
        ProjectTypeCommonConstants.ALL_DATA_FROM_ARCHIVE_ENTITY_SUCCESS,
        ProjectTypeCommonConstants.ALL_DATA_FROM_ARCHIVE_ENTITY_FAILURE,
      ],
    };
    const res = await dispatch(getListAndPartialReadEntities(config));
    const response = prop('data', res);
    if (isEmpty(response)) {
      antNotification('warning', 'Archive state is empty');
    }
    return response;
  };

  const selectArchiveState = (uuid) => {
    if (uuid === selectedStateArchive) {
      changeSelectedStateArchive('');
      resetArchiveState();
    } else {
      changeSelectedStateArchive(uuid);
      getStateArchiveEntity(uuid);
      getAllChildrenFromArchiveState(uuid);
    }
  };

  useEffect(() => {
    if (isEmpty(curArchiveStateUUID) || isNil(curArchiveStateUUID)) {
      changeSelectedStateArchive('');
    }
  }, [curArchiveStateUUID]);

  const columns = [
    {
      dataIndex: ['name'],
      className: "minWidth130",
      title: capitalize(t('wms.table.headers.name', 'name')),
      render: (cell, row) => (
        <span>
          {isNil(cell) ? propOr('', 'created', row) : cell}
        </span>
      ),
    },
    {
      dataIndex: ['uuid'],
      title: capitalize(t('wms.table.headers.action', 'action')),
      render: (cell) => (
        <BaseButton
          onClick={() => selectArchiveState(cell)}
          size="small"
          className={selectedStateArchive === cell
            ? 'customAntOutWarningBtn' : 'customAntPrimaryBtn'}
        >
          {capitalize(t('wms.buttons.archive_state', {
            verb: t(`wms.verb.${selectedStateArchive === cell
              ? 'deactivate'
              : 'activate'}`),
          }))}
        </BaseButton>
      ),
    },
  ];

  const onPageChange = async ({ pageLimit: pl, currentPage: cp, offset }) => {
    setCurrentConfig({
      limit: pl,
      offset,
    });
    setCurrentPage(cp);
    setPageLimit(pl);
  };

  const onRow = (row) => ({
    onClick: () => {
      selectArchiveState(row.uuid);
    },
  });

  const remotePagination = {
    sizePerPageList: [25, 50, 100],
    defaultPageLimit: 25,
    totalSize: listOfArchives.length,
    page: currentPage,
    onChange: onPageChange,
    // onSizePerPage: onSizePerPageTable,
  };

  const rowClassName = (row) => {
    const data = `universal_table_row ${get(row, 'uuid') === selectedStateArchive
      ? 'selected'
      : ''
      }`;

    return data;
  };

  const selectRow = {
    mode: 'radio',
    clickToSelect: false,
    hideSelectColumn: true,
    selected: [selectedStateArchive],
    style: { background: '#e5f4fc' },
  };

  return (
    <Row className="mt-3">
      <h5>
        {capitalize(t(
          'wms.projectflow.settings.archive_state_list',
          'list of archive states',
        ))}
      </h5>
      <Col span={24} className="collapsedTable-table">
        <BaseTableWithPagination
          pageLimit={pageLimit}
          changePageLimit={setPageLimit}
          useCustomPagination
          onRow={onRow}
          columns={columns}
          data={listOfArchives}
          rowSelection={selectRow}
          total={get(remotePagination, 'totalSize')}
          pageSizeOptions={get(remotePagination, 'sizePerPageList')}
          disablePagination={get(remotePagination, 'totalSize') <= get(remotePagination, 'defaultPageLimit')}
          newCurrentPage={get(remotePagination, 'page')}
          defaultPageLimit={get(remotePagination, 'defaultPageLimit')}
          getPaginationOptions={get(remotePagination, 'onChange')}
          rowClassName={rowClassName}
          paginationSize="small"
          headerRowClassName="universal_header_table_row"
        />
      </Col>
    </Row>
  );
}

export default ListOfArchiveState;

import { Popover } from 'antd';
import {
  array,
  bool, func, string,
} from 'prop-types';
import React, { useState } from 'react';
import { get } from 'lodash';
import AssignedVectorView from './AssignedVectorView';
import EditButton from '../EditButton';

export default function AssignedVector({
  disabled,
  partitionType,
  vectorUuid,
  projectUuid,
  ticketUuid,
  onChangeFieldCallback,
  projectUsers,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleBtn = () => setIsOpen((prev) => !prev);

  const selectedVectorCallback = (data) => {
    toggleBtn();
    const { assignedVector, selectedVectorData } = data;
    onChangeFieldCallback({
      vector: assignedVector,
      selectedVectorData,
      usersUuidsForAssigned: assignedVector
        ? get(selectedVectorData, 'params.usersSearch', [])
        : projectUsers.map(({ uuid }) => uuid),
    });
  };

  return (
    <Popover
      placement="left"
      trigger="click"
      destroyTooltipOnHide
      open={isOpen}
      onClick={toggleBtn}
      content={(
        <div className="relative">
          <AssignedVectorView
            partitionType={partitionType}
            customSelectVector={vectorUuid}
            selectedProject={projectUuid}
            parentUuid={ticketUuid}
            onChangeFieldCallback={selectedVectorCallback}
          />
          <div className="substrate_assigned_users" onClick={toggleBtn} role="none" />
        </div>
        )}
    >
      <EditButton id='editVectorButton' disabled={disabled} />
    </Popover>

  );
}

AssignedVector.propTypes = {
  partitionType: string,
  disabled: bool,
  vectorUuid: string,
  projectUuid: string,
  ticketUuid: string,
  onChangeFieldCallback: func,
  projectUsers: array,
};

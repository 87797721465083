import {
  prop, find, equals, pathOr,
} from 'ramda';
import { getListOfProjectsEntity } from './selectors';

export const getIssuesWithProjectName = (state, issues) => {
  const projects = getListOfProjectsEntity(state);

  const searchProjectNameByUuid = (uuid) => pathOr('', ['params', 'title'], find((project) => equals(prop('uuid', project), uuid), projects));

  const newArr = [...issues].map((item) => ({
    ...item,
    ...item,
    projectName: searchProjectNameByUuid(item?.project),
  }));

  return newArr;
};

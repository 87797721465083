import {
  get, head,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'antd';
import { func, string } from 'prop-types';
import cn from 'classnames';
import { getProject } from '../../../selectors/selectors';
import { getListAndPartialReadEntities } from '../../../../entity/actions/entityActions';
import { capitalize } from 'lodash';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import { stopPropagation } from '../../../../54origins/utils54origins';

import '../AssignedUsers/assignedUserBtn.scss';
import { partitionNamesConfig } from '../../../../api/appConfig';

export default function AssignedVectorView({
  onChangeFieldCallback,
  customSelectVector,
  selectedProject,
  parentUuid = '',
  partitionType = partitionNamesConfig.partition1,
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const projectData = head(useSelector(getProject));

  const [selectedVectorOption, setSelectedVectorOption] = useState('');
  const [listVectors, setListVectors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChangeData = (selectedOption) => {
    if (selectedOption?.value?.uuid === selectedVectorOption && !selectedOption?.disabled) {
      setSelectedVectorOption('');
    } else if (!selectedOption?.disabled) {
      setSelectedVectorOption(selectedOption?.value?.uuid);
    }
  };

  const toggleSave = () => {
    const getCurrentVectors = selectedVectorOption ? get(listVectors, 'list', []).find((i) => i.uuid === selectedVectorOption) : {};

    onChangeFieldCallback({
      assignedVector: selectedVectorOption,
      selectedVectorData: getCurrentVectors || {},
    });
  };

  const getProjectVectorsRequest = async (projectUuid) => {
    const data = {
      entity_type: 'vector',
      parent: projectUuid,
      params: {},
      fields: {
        partition: 'partition',
      },
      params_fields: {
        id: 'id',
        status: 'status',
        title: 'title',
        users: 'users',
        usersSearch: 'usersSearch',
      },
    };

    const constants = [
      'GET_VECTORS_FOR_SELECTED_PROJECT_REQUEST',
      'GET_VECTORS_FOR_SELECTED_PROJECT_SUCCESS',
      'GET_VECTORS_FOR_SELECTED_PROJECT_FAILURE',
    ];

    return dispatch(getListAndPartialReadEntities({
      data,
      constants,
      partition: partitionType === partitionNamesConfig.partition3 && projectData?.parent ? 
      partitionNamesConfig.partition3 
      : partitionNamesConfig.partition1,
    }));
  };

  const getTemporaryVectors = async (projectUuid) => {
    const { data } = await getProjectVectorsRequest(projectUuid);

    setListVectors({
      listSelect: data.map((item) => ({
        value: item,
        disabled: item?.status === 'protected',
        label: item?.title,
      })),
      list: data,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (selectedProject) {
      setLoading(true);
      getTemporaryVectors(selectedProject);
    }
  }, [selectedProject, parentUuid]);

  useEffect(() => {
    if (customSelectVector) {
      setSelectedVectorOption(customSelectVector);
    }
  }, [customSelectVector]);

  return (
    <div
      className="wrapper_select_release"
      onClick={stopPropagation}
    >
      <div className="flex items-center justify-between p-2 wrapper_header_assigned_user_btn">
        <BaseButton
          id="saveBtn"
          className="mr-1"
          type="primary"
          disabled={selectedVectorOption === customSelectVector}
          onClick={toggleSave}
        >
          {capitalize(t('wms.verb.save', 'save'))}
        </BaseButton>
      </div>

      <div className="wrapper_list_assigned_user_btn height-350">
        <List
          className="p-2"
          dataSource={listVectors?.listSelect}
          loading={loading}
          renderItem={(item, key) => (
            <List.Item
              key={(item?.value?.uuid || item.label) + key}
              className={cn(
                'item_from_list_items',
                {
                  selected: selectedVectorOption === item?.value?.uuid,
                  disabled: item?.disabled,
                },
              )}
              onClick={() => handleChangeData(item)}
            >
              <List.Item.Meta
                title={(
                  <div className="flex items-center">
                    <span className="inline-flex pl-1 pr-3 text-xs whitespace-nowrap">
                      {`${item?.value?.id || 0}`}
                    </span>
                    <span className="inline-flex px-1">{item.label}</span>
                  </div>
                )}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}

AssignedVectorView.propTypes = {
  onChangeFieldCallback: func,
  customSelectVector: string,
  selectedProject: string,
  parentUuid: string,
  partitionType: string,
};

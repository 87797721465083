import { head, omit } from 'lodash';
import { axiosRequestPost } from '../../api/apiAxios';
import {
  GET_USER_PERMS_FOR_ENTITY_FAILURE,
  GET_USER_PERMS_FOR_ENTITY_REQUEST,
  GET_USER_PERMS_FOR_ENTITY_SUCCESS
} from '../slices/permissionsSlice';

export const getEntityPermForUser = ({
  entity_uuid,
  partition,
  perm = [],
  returnPermsList,
}) => {
  const data = {
    entity_uuid,
    perm,
  };

  return (dispatch) => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequestPost(
          'utility/eperm/check',
          [
            GET_USER_PERMS_FOR_ENTITY_REQUEST,
            GET_USER_PERMS_FOR_ENTITY_SUCCESS,
            GET_USER_PERMS_FOR_ENTITY_FAILURE,
          ],
          data,
          {
            partition,
            onSuccess: (response) => {
              const perms = omit(head(response), ['entity_uuid']);
              resolve(returnPermsList ? perms : perm.every((item) => perms[item] === true));
            },
            onFailure: reject,
          },
        ),
      );
    })
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalize } from "lodash";
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import './Activity.scss';

import GoToButton from '../../../components/buttons/GoToButton';
import ListOfAllEntities from './ListOfAllEntities';
import UniGeneralButtons from '../uniGeneralButtons/UniGeneralButtons';
import ListOfAllAffix from './listAffix/ListOfAllAffix';
import ActivityModifiedFilter from './activityFilter/ActivityModifiedFilter';

import {
  getProjectUUID,
} from '../../selectors/selectors';
import { getCurrentArchiveStateUUID } from '../../selectors/archiveStateSelectors';
import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import { modifiedTag } from '../../../constants/commonConstants';
import { partitionNamesConfig } from '../../../api/appConfig';

const nameTabs = [
  { name: 'entity', title: 'entity' },
  { name: 'affix', title: 'affix' },
];

function ActivityView() {
  const { t } = useTranslation();

  const {
    getProjectMembers,
    defaultPartition,
  } = useContext(ProjectMainLayerContext);

  const projectUUID = useSelector(getProjectUUID);
  const archiveStateUUID = useSelector(getCurrentArchiveStateUUID);

  const [viewMode, changeViewMode] = useState(nameTabs[0].name);
  const [modified, setModified] = useState(null);

  useEffect(() => {
    getProjectMembers();
  }, [archiveStateUUID]);

  return (
    <>
      <Row>
        <Col span={24}>
          <BaseCard>
            <div className="flex w-full items-center">
              <h5 className="mr-3 mt-2" >{capitalize(t('wms.projectflow.project.headers.project_log', 'project log'))}</h5>
              {/* <ActivityModifiedFilter
                setModified={setModified}
              /> */}

              {defaultPartition !== partitionNamesConfig.partition3 && (
                <GoToButton
                  id="toPtojectReportsButton"
                  pathToRedirect={({
                    pathname: '/tools/projectReports',
                    search: `?activeProject=${projectUUID}&project=${projectUUID}&partition=PM`,
                  })}
                  pathForLabelTranslate="wms.buttons.go_to_project_reports"
                />
              )}
            </div>
          </BaseCard>
        </Col>
      </Row>
      <Row style={{ flex: "1 0 auto" }}>
        <Col span={24} className="mt-2" style={{ display: "flex", flexDirection: "column" }}>
          <UniGeneralButtons
            viewMode={viewMode}
            switchViewFunc={changeViewMode}
            tabs={nameTabs}
          />

          {viewMode === 'entity' && (
            <ListOfAllEntities
              modified={modified}
              setModified={setModified}
            />
          )}

          {viewMode === 'affix' && (
            <BaseCard className="primaryCard mt-2">
              <ListOfAllAffix
                modified={modified}
              />
            </BaseCard>
          )}
        </Col>
      </Row>
    </>
  );
}

export default ActivityView;

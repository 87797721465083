import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getListOfUserBoards } from '../actions/userBoardActions';
import { getUserBoards } from '../selectors/userBoardsSelector';
import { SET_CHANGED_USER_BOARD } from '../slices/userBoardsSlice';

const useUserBoards = ({ parent, partition }) => {
  const dispatch = useDispatch();

  const listOfBoards = useSelector(getUserBoards);

  const onSetUserBoards = (board) => {
    if (board) {
      dispatch({
        type: SET_CHANGED_USER_BOARD?.type,
        payload: board
      });
    }
  }

  useEffect(() => {
    if (parent && partition) {
      dispatch(getListOfUserBoards({
        data: {
          depth: 0,
          entity_type: 'userBoard',
          parent: parent,
        },
        partition: partition,
      }));
    }
  }, [parent, partition])

  return {
    userBoards: listOfBoards,
    onSetUserBoards
  };
};

export default useUserBoards;

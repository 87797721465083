import { head, omit, prop } from 'ramda';
import { EntityConstants } from '../constants/Constants';
import axiosRequest, { axiosRequestPost } from '../../api/apiAxios';
import { ProjectTypeCommonConstants } from '../../projectFlow/constants/Constants';
import { partitionNamesConfig } from '../../api/appConfig';

export function getPermissionsOfEntity({
  data,
  partition,
  constants,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequestPost(
        'eperm/get',
        constants || [
          EntityConstants.GET_ENTITY_PERMS_REQUEST,
          EntityConstants.GET_ENTITY_PERMS_SUCCESS,
          EntityConstants.GET_ENTITY_PERMS_FAILURE,
        ],
        data,
        {
          partition,
          onSuccess,
          onFailure,
        },
      ),
    );
  });
}

export function getActorPermissionsTree({
  data,
  partition,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequestPost(
        'utility/eperm/dissolve',
        ['GET_TREE_PERM'],
        data,
        {
          partition,
          onSuccess,
          onFailure,
        },
      ),
    );
  });
}

export const getEntityPermission = (config, callback) => (dispatch) => dispatch(
  axiosRequest.post(
    'eperm/get',
    prop('constants', config) || [
      EntityConstants.GET_ENTITY_PERMS_REQUEST,
      EntityConstants.GET_ENTITY_PERMS_SUCCESS,
      EntityConstants.GET_ENTITY_PERMS_FAILURE,
    ],
    prop('params', config),
    {
      partition: prop('partition', config) || partitionNamesConfig.partition1,
      onSuccess: (response) => {
        if (callback) {
          callback(response);
        }
      },
      onFailure: '',
    },
  ),
);

export function createEntityPermissions({
  data,
  partition,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post(
        'eperm/create',
        [
          EntityConstants.CREATE_ENTITY_PERM_REQUEST,
          EntityConstants.CREATE_ENTITY_PERM_SUCCESS,
          EntityConstants.CREATE_ENTITY_PERM_FAILURE,
        ],
        data,
        {
          partition,
          onSuccess,
          onFailure,
        },
      ),
    );
  });
}

export function deleteEntityPerm({
  data,
  partition,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post(
        'eperm/delete',
        [
          EntityConstants.DELETE_ENTITY_PERMS_REQUEST,
          EntityConstants.DELETE_ENTITY_PERMS_SUCCESS,
          EntityConstants.DELETE_ENTITY_PERMS_FAILURE,
        ],
        data,
        {
          partition,
          onSuccess,
          onFailure,
        },
      ),
    );
  });
}

export const checkEntityPerm = ({
  entity_uuid,
  partition,
  perm = [],
  returnPermsList,
}) => {
  const data = {
    entity_uuid,
    perm,
  };

  return (dispatch) => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequestPost(
          'utility/eperm/check',
          [
            EntityConstants.CHECK_ENTITY_PERM_REQUEST,
            EntityConstants.CHECK_ENTITY_PERM_SUCCESS,
            EntityConstants.CHECK_ENTITY_PERM_FAILURE,
          ],
          data,
          {
            partition,
            onSuccess: (response) => {
              const perms = omit(['entity_uuid'], head(response));

              resolve(returnPermsList ? perms : perm.every((item) => perms[item] === true));
            },
            onFailure: reject,
          },
        ),
      );
    })
  );
};

export const setFetchingIssueInStore = (name, bool) => (dispatch) => dispatch(
  {
    type: ProjectTypeCommonConstants.FETCHING_LIST_OF_ISSUES,
    payload: { [`${name}Fetching`]: bool },
  },
);

import { ProjectTypeCommonConstants } from '../../constants/Constants';

const initialState = {
  fetching: false,
  issues: [],
  error: '',
  uuidsFromMultiSelect: [],
  flagForMultiSelect: false,
  allProjectIssues: [],
  scoringData: {
    total: 0,
    avgVal: '-',
    sum: 0,
    background: '',
  },
  outdatedScoring: '-',
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ProjectTypeCommonConstants.LIST_OF_ISSUES_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.LIST_OF_ISSUES_SUCCESS:
      return {
        ...state,
        fetching: false,
        issues: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_ISSUES_FAILURE:
      return {
        ...state,
        fetching: false,
        issues: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.SET_UUIDS_FOR_MULTI_SELECT:
      return {
        ...state,
        uuidsFromMultiSelect: payload,
      };
    case ProjectTypeCommonConstants.SET_FLAG_FOR_MULTI_SELECT:
      return {
        ...state,
        flagForMultiSelect: payload,
      };

    case ProjectTypeCommonConstants.NEW_ISSUE_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.NEW_ISSUE_SUCCESS:
      return {
        ...state,
        fetching: false,
        issues: state.issues,
      };
    case ProjectTypeCommonConstants.NEW_ISSUE_FAILURE:
      return {
        ...state,
        fetching: false,
        issues: [],
        error: payload,
      };
    case ProjectTypeCommonConstants.CLEAR_LIST_OF_ISSUES:
      return {
        ...state,
        fetching: false,
        issues: [],
        error: '',
      };

    case ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_REQUEST:
      return {
        ...state,
        // fetching: true,
      };
    case ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_SUCCESS:
      return {
        ...state,
        // fetching: false,
        allProjectIssues: payload,
      };
    case ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_FAILURE:
      return {
        ...state,
        // fetching: false,
        allProjectIssues: [],
        error: payload,
      };
    case ProjectTypeCommonConstants.UPDATE_ISSUES_PRIORITY_INDEXES_DATA:
      const {
        total, avgVal, sum, background,
      } = payload;
      // console.log('UPDATE_ISSUES_PRIORITY_INDEXES_DATA', sum, total, avgVal);
      const finalAvgVal = avgVal !== '-' ? Number(avgVal) : avgVal;
      return {
        ...state,
        allProjectIssues: [],
        scoringData: {
          total,
          avgVal: finalAvgVal,
          sum,
          background,
        },
      };
    case ProjectTypeCommonConstants.CLEAR_ISSUES_SCORING_DATA:
      return {
        ...state,
        allProjectIssues: [],
        outdatedScoring: '-',
        scoringData: {
          total: 0,
          avgVal: '-',
          sum: 0,
          background: '',
        },
      };

    case ProjectTypeCommonConstants.UPDATE_ISSUES_OUTDATED_INDEXES_DATA: {
      // console.log('GET_CURRENT_PROJECT_ISSUES_SCORING payload', payload);
      return { ...state, outdatedScoring: payload };
    }

    // case ProjectTypeCommonConstants.GET_CURRENT_PROJECT_ISSUES_SCORING: {
    //   // console.log('GET_CURRENT_PROJECT_ISSUES_SCORING payload', payload);
    //   return { ...state };
    // }
    // case ProjectTypeCommonConstants.UPDATE_CURRENT_PROJECT_ISSUES_SCORING: {
    //   // console.log('UPDATE_CURRENT_PROJECT_ISSUES_SCORING payload', payload);
    //   return { ...state };
    // }

    default:
      return state;
  }
};

export const listOfIssuesFromStore = (state) => state?.projectManagementService?.project?.issues?.issues || [];

export const listOfIssuesFetching = (state) => state?.projectManagementService?.project?.issues?.fetching || false;

export const uuidsSelectedIssuesFromStore = (state) => state?.projectManagementService?.project?.issues?.uuidsFromMultiSelect || [];

export const flagMultiEditIssue = (state) => state?.projectManagementService?.project?.issues?.flagForMultiSelect || false;

export const getIssuesScoringData = (state) => state?.projectManagementService?.project?.issues?.scoringData || null;

export const getIssuesOutdatedData = (state) => state?.projectManagementService?.project?.issues?.outdatedScoring;

// export const issuesScoringData = (state) =>   state?.projectManagementService?.project?.issues?.

import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from '../../projectFlow/selectors/selectors';
import { getUserProjectLogsStorage } from '../store';

import { createProjectLogRequest } from '../store/action-creators/reportAndPlanningAction';
import { getUserPublicProfileUUID } from '../store/selectors/selectors';
import { getUserProjectLogsStorageUUID } from '../store/selectors/mainUserProjectLogsSelectors';

export const useParseLocalStorageAndSaveData = () => {
  const dispatch = useDispatch();

  const mainUserProjectLogsUUID = useSelector(getUserProjectLogsStorageUUID);
  const myUUID = useSelector(getMe);

  const createProjectLog = async (parent, actor, params) => {
    const config = {
      actor,
      entity_uuid: parent,
      params: {
        ...params,
      },
    };

    return dispatch(createProjectLogRequest(config));
  };

  const parseLocalStorageAndSaveData = async () => {
    const created = localStorage.getItem('created');
    const updated = localStorage.getItem('updated');
    const removed = localStorage.getItem('removed');

    const propjectLogData = {};

    if (created) {
      propjectLogData.created = JSON.parse(created);
    }
    if (updated) {
      propjectLogData.updated = JSON.parse(updated);
    }
    if (removed) {
      propjectLogData.removed = JSON.parse(removed);
    }

    if (!isEmpty(propjectLogData)) {
      localStorage.removeItem('created');
      localStorage.removeItem('updated');
      localStorage.removeItem('removed');

      return createProjectLog(mainUserProjectLogsUUID, myUUID, propjectLogData);
    }
    return Promise.resolve('');
  };

  return { parseLocalStorageAndSaveData };
};

export default function useSendMetaToLocalStorage() {
  const dispatch = useDispatch();

  const { parseLocalStorageAndSaveData } = useParseLocalStorageAndSaveData();

  const userProjectLogsStorageUUID = useSelector(getUserProjectLogsStorageUUID);
  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);

  useEffect(() => {
    let timer;

    if (userProjectLogsStorageUUID) {
      const timerFromStorage = Number(localStorage.getItem('timer'));

      if (timerFromStorage) {
        if (Date.now() - 3600000 > timerFromStorage) {
          parseLocalStorageAndSaveData();

          localStorage.setItem('timer', `${Date.now()}`);
        }
      } else {
        localStorage.setItem('timer', `${Date.now()}`);
      }

      timer = setInterval(() => {
        parseLocalStorageAndSaveData();
        localStorage.setItem('timer', `${Date.now()}`);
      }, 3600000);
    }
    return () => clearInterval(timer);
  }, [userProjectLogsStorageUUID]);

  useEffect(() => {
    if (myPublicEntityUUID) {
      dispatch(getUserProjectLogsStorage(myPublicEntityUUID));
    }
  }, [myPublicEntityUUID]);

  return { parseLocalStorageAndSaveData };
};

import {ProjectTypeCommonConstants} from "../../constants/Constants";

const initialState = {
  fetching: false,
  data: [],
  error: ''
};


export default (state=initialState, action) => {
  const {type, payload } = action;

  switch (type) {
    case ProjectTypeCommonConstants.GET_MAIN_AREA_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case ProjectTypeCommonConstants.GET_MAIN_AREA_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload
      };
    case ProjectTypeCommonConstants.GET_MAIN_AREA_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        error: payload
      };

    default:  return state;
  }
};

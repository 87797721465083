import { LocaleConstants } from '../locale/constants/Constants';

const initialState = {
  language: 'en'
};

export default (state= initialState, action) => {
  switch (action.type) {
    case LocaleConstants.SWITCH_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };

    default: return state;
  }
};

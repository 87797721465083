import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Timeline, Tooltip } from "antd";
import Icon from "@mdi/react";
import {
  mdiHelpCircleOutline,
  mdiNumeric1CircleOutline,
  mdiNumeric2CircleOutline,
  mdiNumeric3CircleOutline,
  mdiNumeric4CircleOutline,
  mdiNumeric5CircleOutline,
} from "@mdi/js";
import { useTranslation } from "react-i18next";
import moment from "moment";

import "./entityWorkTime.scss";

import DateLeft from "../DateLeft";
import DateLeftEdit from "../DateLeftEdit/DateLeftEdit";

import "./entityWorkTime.scss";
import { capitalize } from "lodash";

const arrTime = [
  "firstActivityTimestamp",
  "lastActivityTimestamp",
  // "expirationDate",
];

const icon = {
  0: mdiNumeric2CircleOutline,
  1: mdiNumeric3CircleOutline,
  2: mdiNumeric4CircleOutline,
};

function EntityWorkTime({
  onChangeFieldCallback,
  isEdit,
  createdDate,
  firstActivityTimestamp,
  lastActivityTimestamp,
  expirationDate,
}) {
  const { t } = useTranslation();

  const [currentExpirationDate, setCurrentExpitarionDate] = useState(null);

  const title = {
    firstActivityTimestamp: capitalize(
      t("wms.labels.firstActivityTimestamp", "first activity timestamp")
    ),
    lastActivityTimestamp: capitalize(
      t("wms.labels.lastActivityTimestamp", "last activity timestamp")
    ),
    expirationDate: capitalize(
      t("wms.labels.expiration_date", "expiration date")
    ),
  };

  const timeByName = {
    firstActivityTimestamp,
    lastActivityTimestamp,
    expirationDate: currentExpirationDate,
  };

  const defaultCreateDate = createdDate
    ? moment(createdDate).format("DD-MM-YYYY")
    : "-";

  const getStartOrEndDate = (i) => {
    const label =
      i === "firstActivityTimestamp"
        ? capitalize(
          t("wms.labels.set_with_the_first_entitys_update_by_the_user")
        )
        : capitalize(
          t("wms.labels.set_with_the_last_entitys_update_by_the_user")
        );

    return (
      <div
        id={i === "firstActivityTimestamp" ? "firstActivityTimestampButton" : "lastActivityTimestampButton"}
        className={`${!timeByName[i] && "ml-1 flex items-center text-blue-500"
          }`}
      >
        <Tooltip title={timeByName[i] || label}>
          {timeByName[i] ? (
            moment(timeByName[i]).format("DD-MM-YYYY")
          ) : (
            <Icon path={mdiHelpCircleOutline} size={1} />
          )}
        </Tooltip>
      </div>
    );
  };

  const timeLine = (name, date, tooltip, tooltipTitle) => (
    <div className="flex items-center">
      <span className="text-secondary">{name}</span>
      {tooltip ? (
        <Tooltip title={tooltipTitle}>
          <div className="ml-2">{date}</div>
        </Tooltip>
      ) : (
        <div className="ml-2">{date}</div>
      )}
    </div>
  );

  const getViewWhenDisabled = (i) => {
    switch (i) {
      case "expirationDate":
        return timeLine(
          `${title[i]}:`,
          isEdit ? (
            <DateLeftEdit
              expirationDate={timeByName[i]}
              onChangeFieldCallback={onChangeFieldCallback}
            />
          ) : (
            <DateLeft date={timeByName[i]} />
          )
        );
      default:
        return timeLine(`${title[i]}:`, getStartOrEndDate(i));
    }
  };

  useEffect(() => {
    if (expirationDate) {
      setCurrentExpitarionDate(expirationDate);
    }
  }, [expirationDate]);

  return (
    <Timeline className="wrapper-time-line">
      <Timeline.Item dot={<Icon path={mdiNumeric1CircleOutline} size={1} />}>
        {timeLine(
          `${capitalize(t("wms.labels.work.created", "created"))}:`,
          defaultCreateDate,
          true,
          createdDate ? moment(createdDate).format("HH:mm") : ""
        )}
      </Timeline.Item>

      {arrTime.map((i, index) => (
        <Timeline.Item key={i} dot={<Icon path={icon[index]} size={1} />}>
          {getViewWhenDisabled(i)}
        </Timeline.Item>
      ))}
    </Timeline>
  );
}

EntityWorkTime.propTypes = {
  onChangeFieldCallback: PropTypes.func,
  isEdit: PropTypes.bool,
  createdDate: PropTypes.string,
  firstActivityTimestamp: PropTypes.string,
  lastActivityTimestamp: PropTypes.string,
  expirationDate: PropTypes.string,
};

export default EntityWorkTime;

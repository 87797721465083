import { getNestedFilters } from '../../../../components/_ui/helpers/filterHelper';

import {
  trackerFilterOptions,
  priorityFilterOprions,
  estimatedTimeFilterOptions,
  issueStatusFilterOptions,
} from '../../../../constants/allFilterOptions';

const trackerColumnFilter = {
  filters: trackerFilterOptions,
  filterMode: 'tree',
  filterType: 'badge',
};

const priorityColumnFilter = {
  filters: priorityFilterOprions,
  filterMode: 'tree',
  filterType: 'charNoCut',
};

const estimatedTimeColumnFilter = {
  filters: estimatedTimeFilterOptions,
  filterMode: 'tree',
  filterType: 'charNoCut',
};

const statusColumnFilter = {
  filters: issueStatusFilterOptions,
  filterMode: 'tree',
  filterSearch: true,
  filterType: 'iconWithStatus',
};

export const defaultIssueFilter = {
  tracker: undefined,
  priority: undefined,
  estimated_time: undefined,
  status: getNestedFilters(
    ['validation:section', 'development:section', 'testing:section', 'purgatory:section'],
    issueStatusFilterOptions,
  ),
};
// console.log('defaultIssueFilter:', defaultIssueFilter);

export const columnsFilterConfig = {
  tracker: trackerColumnFilter,
  priority: priorityColumnFilter,
  estimatedTime: estimatedTimeColumnFilter,
  status: statusColumnFilter,
};

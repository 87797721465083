import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import UserInfoForAdminDashboard from './UserInfoForAdminDashboard';
import BaseModal from '../../../components/_ui/BaseModal/BaseModal';
import GetAuthSessionBtn from '../GetAuthSessionBtn';

import { getModalAuthSessionFlag } from '../../slicers/getAuthSessionModalSlicer';
import useURLParams from '../../../hooks/useURLParams';
import { getSessionTokenFor } from '../../../api/appConfig';

function UserInfoModalForAdminDashboard() {
  const { clearSearchParams, getActorUUIDParam } = useURLParams();
  const actor = getActorUUIDParam;
  const isOpenAutGetSessionModal = useSelector(getModalAuthSessionFlag);

  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => { clearSearchParams(['actor', 'actorUUID']); };
  const authToken = getSessionTokenFor.auth();

  useEffect(() => {
    if (isOpenAutGetSessionModal) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
    }
  }, [isOpenAutGetSessionModal]);

  return (
    <BaseModal
      // title="Users:"
      open={actor && openModal}
      handleCancel={closeModal}
      handleOk={closeModal}
      destroyOnClose
      centered
      footer={null}
    >
      {actor && <UserInfoForAdminDashboard />}
      {!authToken && (
        <GetAuthSessionBtn
          className="mt-2"
          btnLabel="Get auth token and show additional info"
        />
      )}
    </BaseModal>
  );
}

export default memo(UserInfoModalForAdminDashboard);

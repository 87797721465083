import { Avatar } from 'antd';
import './index.scss';

export default function StepNum({ num }) {
  return (
    <Avatar size={28} className="stepNum" style={{ userSelect: 'none' }}>
      {num}
    </Avatar>
  );
}

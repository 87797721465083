import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filter: null,
};

const dayUserReportFilterSlice = createSlice({
  name: 'dayUserReportFilterSlice',
  initialState,
  reducers: {
    setDayUserReportFilter: (state, action) => {
      state.filter = action.payload;
    },
    clearDayUserReportFilter: (state) => {
      state.filter = null;
    },
    setSearchToDayUserReportFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        search_data: action.payload,
      };
    },
    clearSearchInDayUserReportFilter: (state) => {
      delete state.filter.search_data;
    },
  },
});

export const {
  setDayUserReportFilter,
  clearDayUserReportFilter,
  setSearchToDayUserReportFilter,
  clearSearchInDayUserReportFilter,
} = dayUserReportFilterSlice.actions;

export default dayUserReportFilterSlice.reducer;

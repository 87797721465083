import keyMirror from 'keymirror';

export const QaConstants = keyMirror({
  REMOVE_TEST_CASE_FROM_CYCLE_REQUEST: null,
  REMOVE_TEST_CASE_FROM_CYCLE_SUCCESS: null,
  REMOVE_TEST_CASE_FROM_CYCLE_FAILURE: null,

  REMOVE_TEST_CASE_FROM_SUITE_REQUEST: null,
  REMOVE_TEST_CASE_FROM_SUITE_SUCCESS: null,
  REMOVE_TEST_CASE_FROM_SUITE_FAILURE: null,
});

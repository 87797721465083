import { mdiFileMusicOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Popover, Tooltip } from 'antd';
import { prop, propOr } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { bytesToSize } from '../../../../components/uploader/utils';

export default function RenderAudio({ audioFiles }) {
  const { t } = useTranslation();

  const viewAudio = (src, filename, i, contentTypeData) => (
    <div className="audio-box-in-popup">
      <div>
        <div className="audio-box__label">{filename || 'audio'}</div>
        <audio
          className="audio-box__source-wrap"
          controls
          key={i}
        >
          <source src={src} type={contentTypeData} />
          <source src={src} type="audio/mpeg" />
          {t(
            'wms.audio.no_support',
            'Your browser does not support the audio element',
          )}
          .
        </audio>
      </div>
    </div>
  );

  return audioFiles.map((file, id) => {
    const contentTypeData = prop('', 'content_type', file);
    const key = propOr(id, 'uuid', file);
    const src = `data:${contentTypeData};base64, ${prop('file', file)}`;

    const {
      content_length,
      size = '',
      filename,
      upload: {
        total = '',
      } = {},
    } = file || {};

    const fileSize = bytesToSize(content_length || total || size);
    return (
      <div key={key} className="flex flex-col items-center relative m-1">
        <Popover
          content={() => viewAudio(src, filename, id, contentTypeData)}
          trigger="click"
          placement="top"
        >
          <Icon
            path={mdiFileMusicOutline}
            size={2}
            className="audio-icon"
          />
        </Popover>
        <Tooltip placement="bottom" title={`${filename || 'audio'} (${fileSize})`}>
          <span className="text-upload-file">
            {`${filename || 'audio'}(${fileSize})`}
          </span>

        </Tooltip>

      </div>
    );
  });
}

import {
  entityCreate,
  entityUpdate,
  entityRead,
  getListAndPartialReadEntities,
  getListOfEntities,
} from '../../entity/actions/entityActions';
import {
  CREATE_PROJECT_GOAL_FAILURE,
  CREATE_PROJECT_GOAL_SUCCESS,
  CREATE_PROJECT_GOAL_FETCHING,
  LIST_OF_PROJECT_GOALS_FAILURE,
  LIST_OF_PROJECT_GOALS_FETCHING,
  LIST_OF_PROJECT_GOALS_SUCCESS,
  PROJECT_GOAL_FETCHING,
  PROJECT_GOAL_SUCCESS,
  PROJECT_GOAL_FAILURE,
  CLEAR_ALL_PROJECT_GOALS,
  CLEAR_PROJECT_GOAL,
} from '../reducers/slicers/projectGoalsSlicer';
import { getArrayForItem } from '../../MainUtils';
import { partitionNamesConfig } from '../../api/appConfig';

const defaultPartition = partitionNamesConfig.partition1;

export const getListOfProjectGoalsRequest = (param, partition = defaultPartition) => {
  const { orderBy } = param || {};

  const data = {
    ...param,
    entity_type: 'projectGoal',
    depth: 0,
    fields: {
      'actor.uinfo': 'uinfo',
      modified: 'modified',
      'actor.actor_type': 'actor_type',
    },
    params_fields: {
      id: 'id',
      title: 'title',
      status: 'status',
      responsible: 'responsible',
      handleSort: 'handleSort',
    },
  };

  if (orderBy) {
    data[(orderBy === 'modified' || orderBy === 'created') ? 'order_by' : 'order_by_params'] = orderBy;
    delete data.orderBy;
  }

  // console.log('data', data);

  const constants = [
    LIST_OF_PROJECT_GOALS_FETCHING,
    LIST_OF_PROJECT_GOALS_SUCCESS,
    LIST_OF_PROJECT_GOALS_FAILURE,
  ];

  return getListAndPartialReadEntities({
    data,
    partition,
    constants,
  });
};

export const clearProjectsGoals = { type: CLEAR_ALL_PROJECT_GOALS.type };

export const clearProjectGoal = { type: CLEAR_PROJECT_GOAL.type };

export function getProjectGoalRequest({
  uuid,
  partition = defaultPartition,
}) {
  const data = {
    entity_type: 'projectGoal',
    entity_uuids: getArrayForItem(uuid),
  };

  const constants = [
    PROJECT_GOAL_FETCHING,
    PROJECT_GOAL_SUCCESS,
    PROJECT_GOAL_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

import React, { useEffect, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import TagsSettingList from '../../../../components/filter/TagsSettingList';
import { capitalize } from 'lodash';
import { modifiedTag } from '../../../../constants/commonConstants';

function ActivityModifiedFilter({
  setModified,
}) {
  const { t } = useTranslation();

  const [currentFilter, setCurrentFilter] = useState([modifiedTag[1]]);
  const [modifiedConfig, setModifiedConfig] = useState(null);

  const handleSetSelectedFilters = (tags) => {
    setCurrentFilter(tags);
  };

  const createConfigFromSelectedTabs = (tabs = []) => {
    if (isEmpty(tabs)) {
      return setModifiedConfig({});
    }
    let startDate;
    let endDate;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    const activeTab = tabs[0];

    const getStartDate = (param) => moment()
      .startOf(param)
      .format(dateFormat);

    const getEndDate = (param) => moment().endOf(param).format(dateFormat);

    switch (activeTab.value) {
      case 'today':
        startDate = getStartDate('day');
        endDate = getEndDate('day');
        break;
      case 'this_week':
        startDate = getStartDate('week');
        endDate = getEndDate('week');
        break;
      case 'this_month':
        startDate = getStartDate('month');
        endDate = getEndDate('month');
        break;
      case 'last_week':
        startDate = moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .format(dateFormat);
        endDate = moment()
          .subtract(1, 'weeks')
          .endOf('week')
          .format(dateFormat);
        break;
      case 'last_month':
        startDate = moment()
          .subtract(1, 'month')
          .startOf('month')
          .format(dateFormat);
        endDate = moment()
          .subtract(1, 'month')
          .endOf('month')
          .format(dateFormat);
        break;
      default:
        break;
    }

    const config = {
      modified__gte: startDate,
      modified__lte: endDate,
    };

    return setModifiedConfig(config);
  };

  const switchInFilterByModified = [
    {
      tags: modifiedTag,
      isRow: true,
      isRadio: true,
      noReset: true,
    },
  ];

  useEffect(() => {
    if (!isNil(modifiedConfig)) {
      if (isEmpty(modifiedConfig)) {
        setModified({
          modified__gte: '',
          modified__lte: '',
        });
      } else {
        setModified(modifiedConfig);
      }
    }
  }, [modifiedConfig]);

  useEffect(() => {
    createConfigFromSelectedTabs(currentFilter);
  }, [currentFilter]);

  return (
    <div className="relative">
      <div className="text-secondary text-xs label-modified-filter">
        {`${capitalize(t('wms.labels.modified_filter', 'Modified filter'))}:`}
      </div>
      <TagsSettingList
        size="small"
        type="userTab"
        onSelectTags={handleSetSelectedFilters}
        allTags={switchInFilterByModified}
        id="dashboardFilter"
        defaultSelectedTags={currentFilter}
      />
    </div>
  );
}

ActivityModifiedFilter.propTypes = {
};

export default ActivityModifiedFilter;

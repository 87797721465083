export const indexesReport = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

export const actorFormatter = (uinfo) => {
  const {
    first_name: fn = '<Empty first_name>',
    last_name: ln = '<Empty last_name>',
  } = uinfo;

  return `${fn} ${ln}`;
};

export const columnsProjectReport = [
  {
    name: 'date',
    title: 'Date',
    width: 120,
    align: 'left',
  },
  {
    name: 'title',
    title: 'Title',
    align: 'left',
    width: 230,
  },
  {
    name: 'type',
    title: 'Type',
    width: 90,
  },
  {
    name: 'description',
    title: 'Description time log',
    align: 'left',
    width: 270,
  },
  {
    name: 'created',
    title: 'Created',
    width: 100,
  },
  {
    name: 'actor',
    title: 'Actor',
    width: 140,
  },
  {
    name: 'trackerTime',
    title: 'Time/00h00m',
    width: 100,
  },
  {
    name: 'normalTimeFormat',
    title: 'Time/HH:mm',
    width: 100,
  },
];

import React, { useEffect, useId } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { capitalize, get } from 'lodash';

import { mdiAlertCircle } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Row,
  Col,
  Popover,
  Popconfirm,
  Button,
} from 'antd';
import ReactRouterPrompt from 'react-router-prompt';

import {
  globalDataNotSaved,
  globalPopConfirmID,
  setFlagNotSaveData,
  setPopConfirmID,
} from './unSaveDataSlicer';


import './UnSaveDataConfirm.css';
import { UI54Button, UI54Colors, UI54Icon } from "@agpl/ui54kit";

function UnSaveDataConfirmNew({
  dataIsNotSave = false,
  disabledConfirmButton = false,
  children, // must contain an id for the confirmation box to be displayed
  placement = 'leftBottom',
  unMountCallBack,
}) {
  const { t } = useTranslation();
  const idComponent = useId();
  const dispatch = useDispatch();
  const {
    isOpenConfirm,
    isNotSaveData,
  } = useSelector((state) => get(state, 'projectManagementService.project.unSaveData', {}));

  const GlobalDataNotSaved = useSelector(globalDataNotSaved);
  const GlobalPopConfirmID = useSelector(globalPopConfirmID);

  // const [nextPath, setNextPath] = useState('');

  useEffect(() => {
    if (isOpenConfirm) {
      document.querySelector('body').scrollTo(0, 0);
    }
  }, [isOpenConfirm]);

  useEffect(() => () => {
    unMountCallBack?.();
  }, []);

  useEffect(() => {
    if (dataIsNotSave) {
      dispatch(setFlagNotSaveData(true));
      dispatch(setPopConfirmID(idComponent));
    } else {
      dispatch(setFlagNotSaveData(false));
      dispatch(setPopConfirmID(null));
    }
  }, [dataIsNotSave]);

  return (
    <>
      {children}
      {GlobalDataNotSaved && GlobalPopConfirmID === idComponent ? (
        <ReactRouterPrompt
          when={dataIsNotSave && GlobalPopConfirmID === idComponent}
        >
          {({ isActive, onConfirm, onCancel }) => (
            // <Popconfirm
            //   // ID TAKES FROM CHILD, SO U NEED TO PUSH PROP 'ID' ON CHILD
            //   cancelButtonProps={{ className: 'btnDanger' }}
            //   cancelText={capitalize(t('wms.buttons.removing_process.leave_no_save', 'leave (no save)'))}
            //   className="popupPopconfirm"
            //   color="#ffdd66"
            //   destroyTooltipOnHide
            //   getPopupContainer={() => document.getElementById(children.props.id)}
            //   okButtonProps={{ className: 'btnGreen', disabled: disabledConfirmButton }}
            //   okText={capitalize(t('wms.buttons.removing_process.save_and_leave', 'save and leave'))}
            //   okType="success"
            //   onCancel={onCancel}
            //   onConfirm={onConfirm}
            //   open={isActive}
            //   overlayStyle={{
            //     display: 'table',
            //     zIndex: 1000,
            //   }}
            //   placement={placement}
            //   title={capitalize(t('wms.modals.body.data_not_saved_text', 'your changes will be lost. Are you sure?'))}
            // />
            <Popover
              // overlayStyle={{ transform: 'translateY(-0px)' }}
              overlayInnerStyle={{ minWidth: '380px' }}
              overlayClassName="popupPopconfirm"
              placement={placement}
              trigger="click"
              open={isActive}
              color="#ffdd66"
              // getPopupContainer={() => document.getElementById(children.props.id)}
              // onClick={toggleModalVisibility}
              destroyTooltipOnHide
              zIndex={10000}
              title={(
                <div className="pb-2 flex items-center">
                  <UI54Icon className="mr-2" path={mdiAlertCircle} size={1.5} color="#cc161f" />
                  <h6 className="m-0">{capitalize(t('wms.modals.body.data_not_saved_text', 'your changes will be lost. Are you sure?'))}</h6>
                </div>
              )}
              content={(
                <Row gutter={[8, 8]} className="pt-1 justify-end flex-nowrap">
                  {/* <Col className="p-0">
                    <Button
                      className="btnWarning mr-2"
                      size="small"
                      onClick={onCancel}
                    >
                      {capitalize(t('wms.verb.cancel', 'cancel'))}
                    </Button>
                  </Col> */}
                  <Col className="p-0">
                    <UI54Button
                      className="mr-2"
                      size="small"
                      onClick={onCancel}
                      type="primary"
                      color={UI54Colors.orange[500]}
                    >
                      {capitalize(t('wms.verb.cancel', 'cancel'))}
                    </UI54Button>
                    <UI54Button
                      size="small"
                      onClick={onConfirm}
                      type="primary"
                      color={UI54Colors.red[500]}
                    >
                      {capitalize(t('wms.buttons.removing_process.leave_no_save', 'leave (no save)'))}
                    </UI54Button>
                    {/* <Button
                      className="btnGreen ml-2"
                      size="small"
                      disabled={disabledConfirmButton}
                      onClick={() => {}}
                    >
                      {capitalize(t('wms.buttons.removing_process.save_and_leave', 'save and leave'))}
                    </Button> */}
                  </Col>
                </Row>
              )}
            />
          )}
        </ReactRouterPrompt>
      ) : null}
    </>
  );
}

UnSaveDataConfirmNew.propTypes = {
  children: PropTypes.element,
  dataIsNotSave: PropTypes.bool,
  disabledConfirmButton: PropTypes.bool,
  id: PropTypes.string,
  placement: PropTypes.string,
  unMountCallBack: PropTypes.func,
};

export default UnSaveDataConfirmNew;

import { projectTypeFilterOptions, projectModifiedFilterOptions } from "../../../../constants/allFilterOptions";

const typeColumnFilter = {
    filters: projectTypeFilterOptions,
    filterMode: 'tree',
    filterType: 'badge',
}

export const modifiedColumnFilter = {
    filters: projectModifiedFilterOptions,
    filterMode: 'tree',
    filterType: 'badge',
}

export const columnsFilterConfig = {
    entity_type: typeColumnFilter,

}

export const defaultProjectFilter = {
    entity_type: ['vector', 'issue'],
}
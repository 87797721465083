import Icon from '@mdi/react';
import { mdiArrowCollapse, mdiArrowExpand } from '@mdi/js';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstChar } from '../MainUtils';
import BaseButton from './_ui/BaseButton/BaseButton';

function ExpandButton({ isExpanded, toggleExpandedMode, disabled = false }) {
  const { t } = useTranslation();

  return (
    <BaseButton
      className={`${isExpanded ? 'btnDanger' : 'btnDanger-outline'}`}
      size="small"
      disabled={disabled}
      onClick={toggleExpandedMode}
    >
      <Icon path={isExpanded ? mdiArrowCollapse : mdiArrowExpand} size={0.8} className="mr-1" />
      {isExpanded
        ? capitalizeFirstChar(t('wms.buttons.collapse', 'collapse'))
        : capitalizeFirstChar(t('wms.buttons.expand', 'expand'))}
    </BaseButton>
  );
}
ExpandButton.propTypes = {
  isExpanded: PropTypes.bool,
  disabled: PropTypes.bool,
  toggleExpandedMode: PropTypes.func,
};
export default ExpandButton;

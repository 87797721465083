import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Flex, Tag } from 'antd';
import { capitalize, get } from 'lodash';
import { useSelector } from 'react-redux';
import { projectDefaultTags } from '../project/constants/ProjectStatusOptions';
import { getProject } from '../../selectors/selectors';
import BaseTag from "../../../components/_ui/BaseTag/BaseTag";

export const ListOfSelectedIssueTags = ({ selectedTags = [] }) => selectedTags?.map((label) => (
  <BaseTag
    key={`tag${label}`}
    color="#108ee9"
  >
    {capitalize(label)}
  </BaseTag>
));

function ListOfProjectTagsForIssue({
  selectedTags = [],
  onClickCallback,
}) {
  const project = useSelector(getProject);
  const currentTagsProject = get(project, '[0].params.tags', []);

  const [showMoreTags, setShowMoreTags] = useState(false);
  const [tags, setTags] = useState([]);

  const onClickTag = (item) => {
    const newTagsArr = [...tags].map((tag) => (
      tag.label === item.label ? ({ ...tag, selected: !tag?.selected }) : tag
    ));

    setTags(newTagsArr);

    onClickCallback?.([...newTagsArr].filter((tag) => tag.selected).map((fTag) => fTag?.label));
  };

  const tagTemplate = (item) => {
    const { selected, label } = item || {};
    return (
      <BaseTag
        id={label + 'TagButton'}
        key={`tag${label}`}
        color={selected ? '#108ee9' : 'blue'}
        className="cursor-pointer"
        onClick={() => onClickTag(item)}
      >
        {capitalize(label)}
      </BaseTag>
    );
  };

  const checkTagIsSelected = (item) => ({
    label: item,
    selected: selectedTags?.includes(item),
  });

  useEffect(() => {
    setTags([
      ...projectDefaultTags.map(checkTagIsSelected),
      ...currentTagsProject.map(checkTagIsSelected)]);
  }, [JSON.stringify(currentTagsProject), JSON.stringify(selectedTags)]);

  return (
    <Flex wrap="wrap" gap={2}>
      {tags.slice(0, showMoreTags ? tags.length : 7).map(tagTemplate)}
      {tags.length > 7 ? (
        <BaseTag
          onClick={() => setShowMoreTags(!showMoreTags)}
          color="orange"
          className="cursor-pointer"
        >
          {showMoreTags ? 'Hide tags' : 'All tags'}
        </BaseTag>
      ) : null}
    </Flex>
  );
}

export default ListOfProjectTagsForIssue;

ListOfProjectTagsForIssue.propTypes = {
  onClickCallback: PropTypes.func,
  selectedTags: PropTypes.array,
};

/* eslint-disable import/prefer-default-export */
import { toUpper } from 'lodash';

export const getActionCreatorFromConstantWithEmptyPayload = (constant) => {
  const action = typeof constant === 'object'
    ? constant
    : {
      type: typeof constant === 'function'
        ? constant?.type
        : constant,
      payload: [],
    };

  return action;
};

export const addTagsForTitle = (tags = [], title, tagsPosition = 'start') => {
  const tagsContent = tags?.length !== 0
    ? `[${tags.map((item) => toUpper(item)).join(', ')}]`
    : '';

  switch (tagsPosition) {
    case 'end': return `${title} ${tagsContent}`;
    case 'start':
    default: return `${tagsContent} ${title}`;
  }
};

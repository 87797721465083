import React, { useState } from 'react';
import PropTypes, { bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next/';
import Icon from '@mdi/react';
import { mdiContentDuplicate } from '@mdi/js';

import ReRunTestCycleOrCase from '../../components/qaView/componentsForMultipleUse/ReRunTestCycleOrCase';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import NewExecutionForm from '../../components/qaView/execution/NewExecutionForm';

import { capitalize } from 'lodash';

export default function ReRunSelectedInCycleWrapper({
  selectRows,
  isArchive,
  disabled,
  parentData,
}) {
  const { t } = useTranslation();
  const [customData, setCustomData] = useState([]);

  const callbackSetCustomOption = () => {
    const currentOption = [];

    selectRows?.forEach((el) => {
      if (!isArchive && el.status !== 'unexecuted' && el.status !== 'in progress') {
        currentOption.push(el);
      } else if (isArchive) {
        currentOption.push(el);
      }
    });

    const uuids = currentOption.map((item) => item.uuid);

    setCustomData(uuids);
  };
  return (
    <ReRunTestCycleOrCase
      title={capitalize(t('wms.modals.headers.new_test_cases_executing', 'new test cases executing'))}
      disabled={disabled}
      btnComponent={(
        <BaseButton
          className={`${disabled ? 'customAntOutGrinBtn_disabled' : 'customAntOutGrinBtn'}`}
          size="small"
          disabled={disabled}
          onClick={callbackSetCustomOption}
        >

          <Icon
            path={mdiContentDuplicate}
            size={0.7}
            className="cursor-pointer"
          />
          {capitalize(t('wms.buttons.re-run_selected', 're-run selected'))}

        </BaseButton>
      )}
    >
      <NewExecutionForm
        typeOfParent={isArchive ? 'cycleArchive' : 'cycle'}
        parentUuid={parentData?.uuid}
        customTestCases={customData}
      />
    </ReRunTestCycleOrCase>
  );
}
ReRunSelectedInCycleWrapper.propTypes = {
  selectRows: PropTypes.array,
  isArchive: PropTypes.bool,
  disabled: bool,
  parentData: object,
};

import { ProjectTypeCommonConstants } from '../../constants/Constants';


const initialState = {
  singleTestCaseFromSuite: [],
  singleTestCaseFromCycle: []
};

export default (state=initialState, action) => {
  const {type, payload}=action;

  switch(type) {
    case ProjectTypeCommonConstants.UPDATE_ONE_TEST_CASE_IN_SUITE_IN_STORE:
      return{
        ...state,
        singleTestCaseFromSuite: payload
      };

    case ProjectTypeCommonConstants.CLEAR_ONE_TEST_CASE_IN_SUITE_IN_STORE:
      return{
        ...state,
        singleTestCaseFromSuite: []
      };


    case ProjectTypeCommonConstants.UPDATE_ONE_TEST_CASE_IN_CYCLE_IN_STORE:
      return{
        ...state,
        singleTestCaseFromCycle: payload
      };

    case ProjectTypeCommonConstants.CLEAR_ONE_TEST_CASE_IN_CYCLE_IN_STORE:
      return{
        ...state,
        singleTestCaseFromCycle: []
      };

    default: return state;
  }
};

import { mdiDownload, mdiFileOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Col, Image, Tooltip } from 'antd';
import { get, isNil } from 'lodash';
import React from 'react';
import WrapperModalForViewVideo from '../../../../components/video/WrapperModalForViewVideo';
import { bytesToSize } from '../../../../components/uploader/utils';

export default function RenderImg({ imageFiles, otherFiles }) {
  return (
    <Image.PreviewGroup>
      {!isNil([...imageFiles, ...otherFiles])
            && [...imageFiles, ...otherFiles].map((item, i) => {
              const fileSize = bytesToSize(get(item, 'content_length', 0)
                || get(item, 'upload.total', '')
                || get(item, 'size', ''));

              if (item.content_type.indexOf('video') !== -1) {
                return (
                  <Col className="relative flex flex-col items-center m-1" key={`${item?.uid}${i}`}>
                    <WrapperModalForViewVideo
                      file={item}
                    />
                    <Tooltip placement="bottom" title={`${get(item, 'filename', '') || get(item, 'name', 'file')} (${fileSize})`}>
                      <div className="flex flex-wrap">
                        <p className="m-0 text-upload-file">{`(${fileSize})`}</p>
                      </div>
                    </Tooltip>
                  </Col>
                );
              }

              return (
                <Col className="relative flex flex-col items-center m-1" key={`${item?.uid}${i}`}>
                  {(get(item, 'content_type') && item.content_type.indexOf('image') !== -1)
                     || (get(item, 'type') && item.type.indexOf('image') !== -1)
                    ? (
                      <div className="wrapper-file-icon">
                        <Image
                          width={37}
                          height={37}
                          src={get(item, 'type')
                            ? URL.createObjectURL(item)
                            : `data:${item?.content_type};base64, ${item?.file}`}
                          // fallback={defaultImg}
                        />
                      </div>
                    ) : (
                      <div
                        className="flex items-center fileAttached"
                        onClick={(e) => saveFile(e, `data:${item.content_type};base64, ${item.file}`, item.filename)}
                      >
                        <Icon
                          className="fileIcon"
                          color="#bfbfbf"
                          path={mdiFileOutline}
                          size={2}
                        />
                        <Icon
                          className="fileLoad"
                          color="#bfbfbf"
                          path={mdiDownload}
                          size={1}
                        />
                      </div>
                    )}
                  <Tooltip placement="bottom" title={`${get(item, 'filename', '') || get(item, 'name', 'file')} (${fileSize})`}>
                    <div className="flex flex-wrap">
                      <p className="m-0 text-upload-file">{`(${fileSize})`}</p>
                    </div>
                  </Tooltip>
                </Col>
              );
            })}
    </Image.PreviewGroup>
  );
}

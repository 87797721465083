import { isEmpty, prop } from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { allListEntityPerm } from '../../../entity/selectors/selectors';
import { PermissionsContext } from '../../context/ProjectFlowListOfContexts';
import ListPermissions from './ListPermissions';
import SaveAndResetBtn from './SaveAndResetBtn';
import { capitalize } from 'lodash';
import useURLParams from '../../../hooks/useURLParams';
import { actorIsRoot } from "../../../54origins/utils54origins";

function ExpandRowPermList({
  item,
  removeEntityPermCallback,
  notSavedPermissions,
}) {
  const { t } = useTranslation();
  const {
    entityUUID,
    notSavedDataCallback,
    getActorTreePermissions,
    updateSwitchDataCallback,
    resetFromMainContainer,
    saveTreePermissions,
    defaultPerms,
    viewingAlert,
  } = useContext(PermissionsContext);
  const { getURLParams } = useURLParams();

  const { expandedActorPerm } = getURLParams();

  const allListPerms = useSelector(allListEntityPerm);

  const [treePerms, setTreePerms] = useState([]);
  const [newPerms, setNewPerms] = useState([]);
  const [localReset, setLocalReset] = useState(false);

  const savePermissions = async () => {
    await saveTreePermissions(newPerms);
    notSavedDataCallback('expandCard', false);
    setNewPerms([]);
  };

  const resetData = () => {
    notSavedDataCallback('expandCard', false);
    setLocalReset(true);
    setNewPerms([]);
  };

  const changeSwitch = (currentItem, newPermsData) => {
    const perms = updateSwitchDataCallback(currentItem, [...newPerms], newPermsData);

    setNewPerms(perms);
    notSavedDataCallback('expandCard', !isEmpty(perms));
  };

  const getSelectedActorTreePerms = async () => {
    const res = await getActorTreePermissions(expandedActorPerm);
    // console.log('resresresresresres-------------------222222222', res, 'entityUUID', entityUUID);

    if (isEmpty(res) || !res.some((i) => i.actor_uuid === expandedActorPerm
      && i.entity_uuid === entityUUID)
    ) {
      res.push({
        ...item,
        ...defaultPerms,
        perm_uuid: '',
        entity_type: 'current entity',
        entity_uuid: entityUUID,
        disabled: false,
      });
    }
    const newData = res.map((i) => ({ ...i, customId: `${i.actor_uuid}${i.entity_uuid}` }));
    setTreePerms(newData);
  };

  useEffect(() => {
    if (expandedActorPerm && !isEmpty(allListPerms)) {
      getSelectedActorTreePerms();
    }
  }, [expandedActorPerm, JSON.stringify(allListPerms)]);

  useEffect(() => {
    if (localReset) {
      setLocalReset(false);
    }

    if (resetFromMainContainer && notSavedPermissions.expandCard) {
      resetData();
    }
  }, [localReset, resetFromMainContainer]);

  if (actorIsRoot(item)) {
    return viewingAlert(
      'success',
      capitalize(t('wms.labels.this_is_root')),
      capitalize(t('wms.labels.Root_has_full_access_to_all_entities')),
    );
  }

  if (prop('is_admin', item)) {
    return viewingAlert(
      'success',
      capitalize(t('wms.labels.this_is_administrator')),
      capitalize(t('wms.labels.administrator_has_full_access_to_all_entities')),
    );
  }

  if (prop('userInBan', item)) {
    return viewingAlert(
      'error',
      capitalize(t('wms.labels.this_user_is_in_the_ban_group')),
      capitalize(t('wms.labels.users_do_not_have_access_to_anything')),
    );
  }

  return (
    <div className="wrapper_expand_row">
      <Row>
        <Col span={8}>
          <SaveAndResetBtn
            className="mb-1"
            disabledButtons={isEmpty(newPerms)}
            saveCallback={savePermissions}
            resetCallback={resetData}
          />
        </Col>

        <Col span={8} className="flex justify-center items-center">
          <h6 className="text-center gray-text">
            {prop('actor_type', item) === 'group'
              ? capitalize(t('wms.labels.all_group_permissions'))
              : capitalize(t('wms.labels.all_user_permissions'))}
          </h6>
        </Col>
      </Row>

      <ListPermissions
        dataTable={treePerms}
        resetSwitchBool={localReset}
        cardName="expandCard"
        customId="customId"
        removePermCallback={removeEntityPermCallback}
        changeSwitchCallback={changeSwitch}
      />
    </div>
  );
}

ExpandRowPermList.propTypes = {
  item: PropTypes.object,
  removeEntityPermCallback: PropTypes.func,
  notSavedPermissions: PropTypes.object,
};

export default ExpandRowPermList;

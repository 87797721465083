import { get } from 'lodash';
import { object } from 'prop-types';
import React from 'react';

import './video.scss';

// import defaultIcon from '../../img/video-play-button-transparent-19.png'

export default function ViewVideo({
  file,
}) {
//   console.log('file', file);
  return (
    <div className="flex items-center w-full">
      <div className="video_wrapper">
        <video
          width="700"
          height="600"
          controls="controls"
        //   poster={defaultIcon}
        >
          <source
            src={get(file, 'type')
              ? URL.createObjectURL(file)
              : `data:${file?.content_type};base64, ${file?.file}`}
          />
          Тег video не поддерживается bawdy браузером.
        </video>
      </div>
    </div>
  );
}

ViewVideo.propTypes = {
  file: object,
};

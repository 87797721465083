import Icon from "@mdi/react";
import { mdiFullscreen, mdiFullscreenExit } from "@mdi/js";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { capitalize } from "lodash";

function FullscreenButton({
  isFullscreen,
  setFullscreen,
  size = "small",
  isFetching = false,
}) {
  const { t } = useTranslation();

  const toggleFullscreen = () => setFullscreen((prev) => !prev);

  return (
    <Button
      className="btnPrimary-outline ant-btn-flex fullscreen-btn"
      size={size}
      onClick={toggleFullscreen}
      disabled={isFetching}
    >
      <Icon path={isFullscreen ? mdiFullscreenExit : mdiFullscreen} size={1} />
      <span className="ml-1">
        {isFullscreen
          ? capitalize(t('wms.buttons.full_screen_exit', 'exit full screen'))
          : capitalize(t('wms.buttons.full_screen', 'full screen'))}
      </span>
    </Button>
  )
}

FullscreenButton.propTypes = {
  isFullscreen: PropTypes.bool,
  setFullscreen: PropTypes.func,
  size: PropTypes.string,
  isFetching: PropTypes.bool,
}
export default FullscreenButton;

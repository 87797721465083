/* eslint-disable import/prefer-default-export */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useClearComponent = (...actionCreators) => {
  const dispatch = useDispatch();

  useEffect(() => () => {
    actionCreators.forEach((actionCreator) => {
      if (typeof actionCreator === 'function' && actionCreator.type) {
        dispatch({ type: actionCreator.type });
      } else if (typeof actionCreator === 'function') {
        actionCreator?.();
      } else {
        dispatch(actionCreator);
      }
    });
  }, []);
};

import React, { useState, useEffect } from 'react';
import {
  func,
  number,
  string,
  arrayOf,
  object,
  objectOf,
} from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { capitalize, isEmpty } from 'lodash';
import {
  mdiChatOutline,
  mdiChatProcessingOutline,
  mdiTimerOutline,
  mdiHistory,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button, Badge, Flex,
} from 'antd';

import {
  getTotalComments,
  getUserComments,
  getSystemComments,
  getTimelogsTotal,
} from '../../../../selectors/selectors';
import {
  updateCommentsCountRequest,
  clearAllComments,
} from '../../../../actions/projectFlowActions';
import { ProjectTypeCommonConstants } from '../../../../constants/Constants';
import { ACTIVITIES_TABS } from '../activitiesTabs';
import { COMMENT_TYPE } from '../../../comment/commentType';
import { UI54Button, UI54Icon } from "@agpl/ui54kit";

const INITIAL_COMMENTS = {
  [COMMENT_TYPE.TOTAL]: 0,
  [COMMENT_TYPE.USER]: 0,
  [COMMENT_TYPE.TIME_LOG]: 0,
  [COMMENT_TYPE.SYSTEM]: 0,
};

function ActivitiesDashboard({
  defaultCommentsCount = {},
  entityType = '',
  parentUUID,
  partition,
  tabs,
  clickDashboardButton,
}) {
  const dispatch = useDispatch();

  // console.log('commentsDEFAULT:', defaultCommentsCount);

  const commentsSelectors = {
    [COMMENT_TYPE.TOTAL]: useSelector(getTotalComments),
    [COMMENT_TYPE.USER]: useSelector(getUserComments),
    [COMMENT_TYPE.SYSTEM]: useSelector(getSystemComments),
    [COMMENT_TYPE.TIME_LOG]: useSelector(getTimelogsTotal),
  };
  // console.log('commentsSELECTORS:', commentsSelectors);

  const [commentsCount, setCommentsCount] = useState(INITIAL_COMMENTS);
  // console.log('commentsSTATE:', commentsCount);

  const updateCommentsCount = async (commentType) => {
    // console.log('COMMENT_TYPE:', commentType);
    if (commentsSelectors[commentType] !== commentsCount[commentType]) {
      // console.log('COMMENT_DIFF !!!');
      const config = {
        entity_uuid: parentUUID,
        params: {},
      };

      config.params[commentType] = commentsSelectors[commentType];
      // console.log('config', config);

      const response = await dispatch(updateCommentsCountRequest(config, partition));
      const updatedCount = response?.[0].params[commentType];

      setCommentsCount((prev) => ({
        ...prev,
        [commentType]: updatedCount,
      }));
    }
  };

  const getTabCommentsCount = (tabName) => {
    switch (tabName) {
      case ACTIVITIES_TABS.COMMENTS: {
        return commentsCount[COMMENT_TYPE.USER];
      }
      case ACTIVITIES_TABS.TIME_LOG: {
        return commentsCount[COMMENT_TYPE.TIME_LOG];
      }
      case ACTIVITIES_TABS.HISTORY_OF_CHANGES: {
        return commentsCount[COMMENT_TYPE.SYSTEM];
      }
      // case ACTIVITIES_TABS.ESTIMATED_TIME: {
      //   return mdiClockTimeEightOutline;
      // }
      // case ACTIVITIES_TABS.RELEASE_HISTORY: {
      //   return mdiListBoxOutline;
      // }
      case ACTIVITIES_TABS.TOTAL_COMMENTS: {
        return commentsCount[COMMENT_TYPE.TOTAL];
      }
      default: return 0;
    }
  };

  const getButtonIcon = (tabName) => {
    switch (tabName) {
      case ACTIVITIES_TABS.COMMENTS: {
        return mdiChatProcessingOutline;
      }
      case ACTIVITIES_TABS.TIME_LOG: {
        return mdiTimerOutline;
      }
      case ACTIVITIES_TABS.HISTORY_OF_CHANGES: {
        return mdiHistory;
      }
      // case ACTIVITIES_TABS.ESTIMATED_TIME: {
      //   return mdiClockTimeEightOutline;
      // }
      // case ACTIVITIES_TABS.RELEASE_HISTORY: {
      //   return mdiListBoxOutline;
      // }
      case ACTIVITIES_TABS.TOTAL_COMMENTS:
      default: {
        return mdiChatOutline;
      }
    }
  };

  useEffect(() => {
    if (parentUUID) {
      updateCommentsCount(COMMENT_TYPE.TOTAL);
    }
  }, [commentsSelectors[COMMENT_TYPE.TOTAL]]);

  useEffect(() => {
    if (parentUUID) {
      updateCommentsCount(COMMENT_TYPE.USER);
    }
  }, [commentsSelectors[COMMENT_TYPE.USER]]);

  useEffect(() => {
    if (parentUUID) {
      updateCommentsCount(COMMENT_TYPE.TIME_LOG);
    }
  }, [commentsSelectors[COMMENT_TYPE.TIME_LOG]]);

  useEffect(() => {
    if (parentUUID) {
      updateCommentsCount(COMMENT_TYPE.SYSTEM);
    }
  }, [commentsSelectors[COMMENT_TYPE.SYSTEM]]);

  // useEffect(() => {
  //   if (parentUUID && needUpdateSystemCommentsCount === parentUUID) {
  //     updateSystemCommentsStore();
  //     dispatch(setNeedUpdateSystemComments(''));
  //   }
  // }, [needUpdateSystemCommentsCount]);

  useEffect(() => {
    if (parentUUID && defaultCommentsCount && !isEmpty(defaultCommentsCount)) {
      // console.log('SET STATE FROM DEFAULT');
      const {
        [COMMENT_TYPE.TOTAL]: total,
        [COMMENT_TYPE.USER]: user,
        [COMMENT_TYPE.TIME_LOG]: timelog,
        [COMMENT_TYPE.SYSTEM]: system,
      } = defaultCommentsCount || {};

      setCommentsCount({
        [COMMENT_TYPE.TOTAL]: total || 0,
        [COMMENT_TYPE.USER]: user || 0,
        [COMMENT_TYPE.TIME_LOG]: timelog || 0,
        [COMMENT_TYPE.SYSTEM]: system || 0,
      });
    } else setCommentsCount(INITIAL_COMMENTS);
  }, [JSON.stringify(defaultCommentsCount)]);

  useEffect(() => () => {
    dispatch(clearAllComments());
    dispatch({ type: ProjectTypeCommonConstants.CLEAR_LIST_TIME_LOG });
  }, []);

  return (
    <Flex
      className="w-full mt-2 activities-container"
      justify="center"
      align="center"
      wrap="wrap"
    >
      {tabs.map((tab) => {
        const icon = getButtonIcon(tab?.name);

        return (
          <UI54Button
            id={tab.name[0].toLowerCase() + tab.name.split(" ").map(el => el[0] + el.toLowerCase().slice(1)).join('').slice(1) + "Button"}
            key={tab?.name}
            className="activity-btn"
            type="dashed"
            onClick={() => clickDashboardButton?.(tab?.name)}
          >
            <UI54Icon path={icon} size={0.9} className="mr-2" />
            {capitalize(tab?.name.toLowerCase())}
            <Badge count={getTabCommentsCount(tab?.name)} showZero color="#fc676a" className="ml-2" />
          </UI54Button>
        );
      })}
    </Flex>
  );
}

ActivitiesDashboard.propTypes = {
  defaultCommentsCount: objectOf(number),
  entityType: string,
  parentUUID: string,
  partition: string,
  tabs: arrayOf(object).isRequired,
  clickDashboardButton: func,
};

export default ActivitiesDashboard;

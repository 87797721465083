import React from 'react';
import i18next from 'i18next';
import { capitalize } from 'lodash';

export const prioritySelectedOptions = {
  low: 'createdBlue',
  medium: 'priorityMedium',
  high: 'priorityRed',
  urgent: 'priorityUrgent',
};

export const trackerSelectedOptions = {
  ticket: 'trackerTicket',
  bug: 'trackerBug',
  feature: 'trackerFeature',
};

export const trackerOptionsDropdown = ['ticket', 'bug', 'feature'];
export const issueTrackerOptions = [
  {
    label: 'ticket',
    value: 'ticket',
    translate: 'wms.type.version',
    className: 'radio-darkBlue with-border',
  },
  {
    label: 'bug',
    value: 'bug',
    className: 'radio-red with-border',
  },
  {
    label: 'feature',
    value: 'feature',
    className: 'radio-blue with-border',
  },
];

export const priorityOptionsDropdown = ['low', 'medium', 'high', 'urgent'];

export const issuePriorityOptions = [
  {
    label: 'low',
    value: 'low',
    className: 'radio-blue with-border',
  },
  {
    label: 'medium',
    value: 'medium',
    className: 'radio-orange with-color-border',
  },
  {
    label: 'high',
    value: 'high',
    className: 'radio-lightRed with-border',
  },
  {
    label: 'urgent',
    value: 'urgent',
    className: 'radio-red with-border',
  },
];

export const priorityIssueOptionsDropdown = [
  { type: 'group', label: 'Common', children: ['low', 'medium', 'high', 'urgent'] },
  { type: 'group', label: 'Special', children: ['specialLow'] },
];

const viewItem = (type, period) => (
  <div className="flex flex-col items-start">
    <div>{capitalize(i18next.t(`wms.adjective.${type}`))}</div>
    <div className="fontSize11 text-secondary">{period}</div>
  </div>
);

export const estimatedTime = () => [
  { label: viewItem('easy', 'up to 3 hours'), key: 'easy', reactNode: true },
  { label: viewItem('normal', 'from 3 - 10 hours'), key: 'normal', reactNode: true },
  { label: viewItem('difficult', 'up to 30 hours'), key: 'difficult', reactNode: true },
  { label: viewItem('very_complicated', 'more than 30 hours'), key: 'very_complicated', reactNode: true }];

import React, { useEffect } from 'react';
import { issuePriorityOptions, issueTrackerOptions } from './constants/optionsIssue';
import RadioButtons from '../../../components/RadioButtons/RadioButtons';

export function IssueTypeRadioButtons({
  defaultValue,
  onChange,
  disabled,
}) {
  return (
    <RadioButtons
      options={issueTrackerOptions}
      onChange={onChange}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
}

export function IssuePriorityRadioButtons({
  defaultValue,
  onChange,
  disabled,
}) {
  return (
    <RadioButtons
      disabled={disabled}
      options={issuePriorityOptions}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
}

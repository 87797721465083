import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fetching: false,
  storage: {},
};

const userBoardStorageSlice = createSlice({
  name: 'userBoardStorage',
  initialState,
  reducers: {
    GET_OR_CREATE_USER_BOARD_STORAGE_REQUEST(state) {
      state.fetching = true;
    },
    GET_OR_CREATE_USER_BOARD_STORAGE_SUCCESS(state, action) {
      state.fetching = false;
      state.storage = action.payload;
    },
    GET_OR_CREATE_USER_BOARD_STORAGE_FAILURE(state) {
      state.fetching = false;
    },
  },
});

export const {
  GET_OR_CREATE_USER_BOARD_STORAGE_REQUEST,
  GET_OR_CREATE_USER_BOARD_STORAGE_SUCCESS,
  GET_OR_CREATE_USER_BOARD_STORAGE_FAILURE,
} = userBoardStorageSlice.actions;

export default userBoardStorageSlice.reducer;

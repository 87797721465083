import * as Y from "yjs";
import { clearRepeatedDeltas } from "./clearRepeatedDeltas";

function convertToUint8Array(input) {
  const pattern = /\\x([0-9A-Fa-f]{2})|\\([abfnrtv\\'"])/g;
  const specialCharMappings = {
    a: 0x07,
    b: 0x08,
    f: 0x0c,
    n: 0x0a,
    r: 0x0d,
    t: 0x09,
    v: 0x0b,
    "\\": 0x5c,
    "'": 0x27,
    '"': 0x22,
  };

  let bytes = [];
  let lastIndex = 0;

  input.replace(pattern, (match, hexSequence, specialChar, offset) => {
    if (lastIndex < offset) {
      // Работаем с обычными словами и разбираем base64
      const subStr = input.substring(lastIndex, offset);
      if (/^data:image\/[a-z]+;base64,/.test(subStr)) {
        // Декодируем base64 часть
        const base64Data = subStr.split(",")[1]; // Получаем часть после запятой
        const imageBytes = atob(base64Data);
        for (let i = 0; i < imageBytes.length; i++) {
          bytes.push(imageBytes.charCodeAt(i));
        }
      } else {
        // Преобразуем простой текст в байтовый поток
        for (let char of subStr) {
          bytes.push(char.charCodeAt(0));
        }
      }
    }

    if (hexSequence) {
      // Добавляем \\xHH последовательности
      bytes.push(parseInt(hexSequence, 16));
    } else if (specialChar) {
      // Преобразуем специальные символы
      bytes.push(specialCharMappings[specialChar]);
    }

    lastIndex = offset + match.length;
  });

  // Если остались не обработанные символы, добавляем их в массив
  if (lastIndex < input.length) {
    for (let char of input.substring(lastIndex)) {
      bytes.push(char.charCodeAt(0));
    }
  }
  return new Uint8Array(bytes);
}

export const parseContent = (dirtyString = "") => {
  // console.log('crdt parseContent start')
  const uint = convertToUint8Array(dirtyString);
  // console.log('crdt parseContent end')
  return uint;
};

export const parseCrdtDocumentStructure = (dataArr) => {
  try {
    const defYdoc = new Y.Doc();
    const defYDocText = defYdoc.getText("quill");
    // console.log('crdt dataArr', dataArr?.length);
    const filteredArr = clearRepeatedDeltas(dataArr);
    // console.log('crdt filteredAr', filteredArr?.length);
  
    const byteStringArr = filteredArr.map((item) => parseContent(item?.text));
    // console.log('crdt parseCrdtDocumentStructure byteStringArr', byteStringArr)
    byteStringArr.forEach((utf8, idx) => {
      try {
        Y.applyUpdate(defYdoc, utf8);
      } catch (e) {
        console.log(`crdt error applyUpdate ${idx}`, e);
      }
    });
    // console.log('crdt yDocText toDelta', defYDocText.toDelta());
    const stateVector1 = Y.encodeStateAsUpdate(defYdoc);
    // Y.applyUpdate(docRef.current, stateVector1);
    // const yDocText = docRef.current?.getText('quill');
    // console.log('yDocText json', yDocText.toJSON());
    // console.log('crdt stateVector1', stateVector1)
    return stateVector1;
  } catch(e) {
    console.log('parseCrdtDocumentStructure error', e)
    return ''
  }
};

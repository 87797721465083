import React from 'react';

function Loader() {
  return (
    <div className="loader h-full w-full">
      <p className="loading-message">
        <i>
          {`${localStorage.getItem('locale') === 'en' ? 'Loading' : 'Загрузка'}...`}
        </i>
      </p>
      <div className="spinner">
        <div className="dot1" />
        <div className="dot2" />
      </div>
    </div>
  );
}

export default Loader;

import React from 'react';
import {
  Result, Card, Row, Col,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { resetWMSSessions } from '../../userFlow/store/action-creators/userActions';

import ButtonWithPopConfirmAntDesign54origins
  from '../../54origins/components/ButtonWithPopConfirmAntDesign54origins';
import { capitalize } from 'lodash';
import BaseCard from "../../components/_ui/BaseCard/BaseCard";

function SessionsManagement() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onConfirm = () => {
    dispatch(resetWMSSessions()).then(() => {
      navigate(0);
    });
  };

  return (
    <Result
      title={capitalize(t('wms.labels.After_resetting_sessions_all_users_on_wms_will_be_logged_out'))}
      extra={(
        <ButtonWithPopConfirmAntDesign54origins
          popConfirmPlacement="bottom"
          popConfirmTitle="Are you sure?"
          className="mx-auto"
          type="primary"
          label={capitalize(t('wms.labels.reset_all_wms_sessions'))}
          onClick={onConfirm}
        />
      )}
    />
  );
}

function SessionsManagementCard({ hideTitle }) {
  return (
    <BaseCard title={!hideTitle && 'Session management'}>
      <SessionsManagement />
    </BaseCard>
  );
}

function SessionsManagementMain(props) {
  return (
    <Row className="w-full mt-3">
      <Col span={24}>
        <SessionsManagementCard {...props} />
      </Col>
    </Row>
  );
}

SessionsManagementMain.Default = SessionsManagement;
SessionsManagementMain.Card = SessionsManagementCard;

export default SessionsManagementMain;

/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useMemo, useCallback } from 'react';
import {
  bool,
  string,
  number,
  arrayOf,
  object,
  func,
} from 'prop-types';
import cn from 'classnames';
import { Col, Popover } from 'antd';

import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import RadioButtons from '../../../components/RadioButtons/RadioButtons';
import BaseButton from "../../../components/_ui/BaseButton/BaseButton";

const defaultBorderStyles = {
  borderRadius: '6px',
};

const overlayStyles = {
  position: 'fixed',
  zIndex: -1,
  inset: 0,
  cursor: 'pointer',
  backgroundColor: 'transparent',
};

const disabledList = [
  'system',
];

function EntityParamsPopover({
  className,
  param,
  title,
  tooltip,
  tooltipPlacement = 'bottom',
  currentValue = 'none',
  options,
  previewType,
  classNameForTitle,
  classNameForButtonsBox = 'p-1',
  popoverStyles,
  popoverZIndex,
  withIcons = false,
  withBorder = false,
  disableCollapse = false,
  onChangeValue,
}) {
  const [collapseFlag, setCollapseFlag] = useState(false);

  const collapseDisabled = useMemo(
    () => disableCollapse || disabledList.includes(currentValue),
    [currentValue, disableCollapse],
  );

  const toggleCollapseFlag = useCallback(() => {
    if (!collapseDisabled) {
      setCollapseFlag((prev) => !prev);
    }
  }, [collapseDisabled]);

  const onChangeHandler = useCallback((value) => {
    onChangeValue?.(value);
    toggleCollapseFlag();
  }, [onChangeValue, toggleCollapseFlag]);

  return (
    <Col className={className}>
      <div className="flex items-center">
        {title && (
        <span className={classNameForTitle}>
          {title}
          :
        </span>
        )}
      </div>

      <Popover
        content={(
          <>
            <div
              style={defaultBorderStyles}
              className={cn(`${classNameForButtonsBox}`, { border: withBorder })}
            >
              <RadioButtons
                value={currentValue}
                options={options}
                disabled={false}
                setIcons={withIcons}
                onChange={onChangeHandler}
              />
            </div>
            <div style={overlayStyles} onClick={toggleCollapseFlag} role="none" />
          </>
          )}
        open={collapseFlag}
        placement={tooltipPlacement}
        overlayStyle={popoverStyles}
        zIndex={popoverZIndex}
      >
        <button
          id={param + 'Button'}
          style={{
            padding: '0px', border: 'none', outline: 'none', background: 'none',
          }}
          className={cn({ 'cursor-not-allowed': collapseDisabled, 'cursor-pointer': !collapseDisabled })}
          type="button"
          onClick={toggleCollapseFlag}
        >
          <EntityParamTag
            tooltip={tooltip}
            param={param}
            type={previewType}
            value={currentValue}
            isOpen={collapseFlag}
            canHover={!collapseDisabled}
          />
        </button>
      </Popover>
    </Col>
  );
}

EntityParamsPopover.propTypes = {
  className: string,
  param: string,
  title: string,
  tooltip: string,
  tooltipPlacement: string,
  currentValue: string.isRequired,
  options: arrayOf(object).isRequired,
  previewType: string,
  classNameForTitle: string,
  classNameForButtonsBox: string,
  popoverStyles: object,
  popoverZIndex: number,
  withIcons: bool,
  withBorder: bool,
  disableCollapse: bool,
  onChangeValue: func,
};

export default EntityParamsPopover;

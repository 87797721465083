import PropTypes, { bool } from 'prop-types';
import React, { useEffect } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import CreateProjectGoalBtn from '../../../docView&ideas/CreateProjectGoalBtn';
import BaseButton from '../../../../../components/_ui/BaseButton/BaseButton';

import { getProjectGoals } from '../../../../reducers/slicers/projectGoalsSlicer';
import { getUserPublicProfileUUID } from '../../../../../userFlow/store/selectors/selectors';
import { getProjectUUID } from '../../../../selectors/selectors';
import { getListOfProjectGoalsRequest } from '../../../../actions/projectGoalsActions';
import { capitalize } from 'lodash';
import useURLParams from '../../../../../hooks/useURLParams';
import { partitionNamesConfig } from '../../../../../api/appConfig';
import { UI54Button } from "@agpl/ui54kit";

function ProjectInfoGoals({
  partitionType,
  disabled,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { routeNavigateAndClearParams } = useURLParams();

  const projectGoals = useSelector(getProjectGoals);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const projectUUID = useSelector(getProjectUUID);

  const goToProjectGoal = (goalUUID) => {
    routeNavigateAndClearParams(
      `${partitionType === partitionNamesConfig.partition1 ? '/pm/' : `/public/user/${myPublicUUID}/`}projects/planning`,
      {
        activeProject: projectUUID,
        ...(goalUUID ? { goal: goalUUID } : {}),
      },
    );
  };

  const getListOfProjectGoals = () => {
    dispatch(getListOfProjectGoalsRequest({
      parent: projectUUID,
      params: {
        status: ['created', 'in progress'],
      },
      params_fields: {
        id: 'id',
        title: 'title',
        status: 'status',
      },
    }, partitionType));
  };

  const getGoalTemplate = (status) => (
    <Col span={12}>
      <Row className=" head-links-goals">
        {status === 'created' ? 'Created' : 'In progress'}

        {status === 'created' && (
          <CreateProjectGoalBtn
            id="creacteProjectGoalButton"
            isLink
            partition={partitionType}
            disableBtn={disabled}
            createProjectGoalCallback={goToProjectGoal}
          />
        )}
      </Row>
      <div className="grow wrapper-description flex flex-col w-full overflowHidden">
        {projectGoals.flatMap(({
          status: itemStatus,
          title,
          id,
          uuid,
        } = {}) => (itemStatus === status ? (
          <div key={uuid} className="flex items-center">
            <Tooltip
              placement="left"
              title={title}
            >
              <a
                href={`${partitionType === partitionNamesConfig.partition1 ? '/pm/' : `/public/user/${myPublicUUID}/`}projects/planning?activeProject=${projectUUID}&goal=${uuid}`}
                target="_blank"
                className="textEllepsis ml-1"
                rel="noreferrer"
              >
                {`${id ? `G${id} ` : ''} ${title}`}
              </a>
            </Tooltip>
          </div>
        ) : [])).slice(0, 3)}
      </div>
    </Col>
  );

  useEffect(() => {
    if (projectUUID) {
      getListOfProjectGoals();
    }
  }, [projectUUID]);

  return (
    <div className="flex  flex-col items-start w-full">
      <div className="wrapper-title pb-0 flex items-center">
        <UI54Button
        id="goToProjectGoals"
          className="pl-1"
          size="small"
          type="link"
          disabled={disabled}
          onClick={() => goToProjectGoal()}
        >
          {capitalize(t('wms.projectflow.projectgoals', 'project goals'))}
          :
        </UI54Button>
      </div>
      <div className="grow wrapper-description w-full px-1">
        <Row gutter={14}>
          {getGoalTemplate('in progress')}
          {getGoalTemplate('created')}
        </Row>
      </div>
    </div>
  );
}

export default ProjectInfoGoals;

ProjectInfoGoals.propTypes = {
  partitionType: PropTypes.string,
  disabled: bool,
};

import { ProjectTypeCommonConstants } from '../constants/Constants';

const initialState = {
  fetching: true,
  data: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ProjectTypeCommonConstants.LIST_CHILDREN_REQUEST:
      return {
        ...state,
        fetching: true,
        data: [],
      };
    case ProjectTypeCommonConstants.LIST_CHILDREN_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload,
      };
    case ProjectTypeCommonConstants.LIST_CHILDREN_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
      };

    case ProjectTypeCommonConstants.FETCHING_CHILDREN:
      return {
        ...state,
        fetching: payload,
      };
    case ProjectTypeCommonConstants.CLEAR_LIST_CHILDREN:
      return initialState;

    default: return state;
  }
};

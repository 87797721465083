import { string } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  capitalize,
  get, isEmpty, last, omit,
} from 'lodash';
import Icon from '@mdi/react';
import { mdiDrag } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

import AddActivityOrTicketsButton from './AddActivityOrTicketsButton';
import ViewActivity from './ViewActivity';

import { antNotification } from '../../../MainUtils';
import {
  entityCreate,
  entityDelete,
  entityUpdate,
  getListAndPartialReadEntities,
} from '../../../entity/actions/entityActions';
import { UsersPartitionConstants } from '../../constants/Constants';
import {
  draggableElementActivity,
  needDublicateActivity,
  getSyncData,
} from '../../store/selectors/dailySectionSelectors';
import { requestOnBrowserClose } from '../../../api/apiAxios';
import { partitionNamesConfig } from '../../../api/appConfig';

const weekPlanningActions = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  duplicate: 'duplicate',
};

export default function DayWeek({
  dayDate,
  dayUUID,
  projectUserReportsUUID,
  handleDuplicate,
  handleSyncData,
  syncChanges,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const draggableActivity = useSelector(draggableElementActivity);
  const needDublicate = useSelector(needDublicateActivity);
  const syncData = useSelector(getSyncData);

  const [draggable, setDraggable] = useState(false);
  const [activeDragHover, setActiveDragHover] = useState(null);

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  const [UUIDForFocus, setUUIDForFocus] = useState('');

  const toggleDragButton = () => setDraggable(true);
  const unToggleDragButton = () => setDraggable(false);

  const updateCurrentDayActivities = async (parent_date) => {
    if (parent_date === dayDate) {
      await getActivities().then(() => {
        handleSyncData(dayDate);
        antNotification('info', 'Synchronization has been completed');
        dispatch({ type: UsersPartitionConstants.CLEAR_SYNC_DATA });
      });
    }
  };

  const handleWeekPlanningSync = async () => {
    const { parent_date, action_type, data } = syncData;

    switch (action_type) {
      case weekPlanningActions.create:
      case weekPlanningActions.update:
      case weekPlanningActions.delete: {
        updateCurrentDayActivities(parent_date);
        break;
      }
      case weekPlanningActions.duplicate: {
        const checkIsNeedToUpdate = data.some(
          (activity) => activity?.date === dayDate,
        );
        if (checkIsNeedToUpdate) {
          await getActivities().then(() => handleSyncData(dayDate));
        }
        break;
      }
    }
  };

  useEffect(() => {
    if (syncData) {
      handleWeekPlanningSync();
    }
  }, [syncData]);

  const updateRequest = (unmount, config) => (unmount
    ? requestOnBrowserClose('entity/update', {
      ...omit(config, 'options'),
      partition: config.options.partition,
    })
    : dispatch(entityUpdate(config)));

  const updateActivity = async (uuid, params, updateLocalState, unmount) => {
    const config = {
      data: {
        entity_type: 'issue',
        entity_uuid: uuid,
        params,
      },
      constants: [
        'UPDATE_ACTIVITY_REQUEST',
        'UPDATE_ACTIVITY_SUCCESS',
        'UPDATE_ACTIVITY_FAILURE',
      ],
      options: { partition: partitionNamesConfig.partition3 },
    };

    if (updateLocalState) {
      const res = await updateRequest(unmount, config);
      setActivities((prev) => prev.map((i) => (i.uuid === get(res, '[0].uuid')
        ? { ...get(res, '[0].params', {}), uuid: get(res, '[0].uuid') }
        : i)));
      return res;
    }

    return updateRequest(unmount, config);
  };

  const getActivities = async () => {
    // console.log('getActivities', dayUUID);

    setLoading(true);
    const config = {
      data: {
        entity_type: 'issue',
        parent: dayUUID,
        order: 'asc',
        order_by_params: 'weight',
        params_fields: {
          date: 'date',
          title: 'title',
          completed: 'completed',
          weight: 'weight',
        },
      },
      partition: partitionNamesConfig.partition3,
    };

    try {
      const res = await dispatch(getListAndPartialReadEntities(config));
      setActivities(get(res, 'data', []));

      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const deleteActivityCallback = async (uuid) => {
    const config = {
      data: {
        entity_type: 'issue',
        entity_uuid: uuid,
      },
      constants: [
        'DELETE_ACTIVITY_REQUEST',
        'DELETE_ACTIVITY_SUCCESS',
        'DELETE_ACTIVITY_FAILURE',
      ],
      options: { partition: partitionNamesConfig.partition3 },
    };
    await dispatch(entityDelete(config));
    getActivities();
  };

  const createActivity = async (params, isDuplicate) => {
    const config = {
      data: {
        entity_type: 'issue',
        parent: dayUUID,
        params: {
          ...params,
          typeForReport: 'activity',
          status: 'created',
          tracker: 'ticket',
          priority: 'low',
          project: projectUserReportsUUID,
        },
      },
      options: { partition: partitionNamesConfig.partition3 },
    };
    const resp = await dispatch(entityCreate(config)).then((resp) => {
      if (!isDuplicate) {
        const activity = resp?.[0];
        const params = activity?.params;
        const req_data = {
          action_type: 'create',
          parent_date: params?.date,
          data: [
            {
              uuid: activity?.uuid,
              completed: params?.completed,
              date: params?.date,
              title: params?.title,
              weight: params?.weight,
            },
          ],
        };
        syncChanges(req_data);
      }
      return resp;
    });
    return resp;
  };

  const createActivityCallbak = useCallback(async () => {
    const params = {
      date: dayDate,
      title: '',
      completed: false,
      weight: +get(last(activities), 'weight', 0) + 1000,
    };
    const res = await createActivity(params);
    setActivities((prev) => [
      ...prev,
      { ...get(res, '[0].params', {}), uuid: get(res, '[0].uuid') },
    ]);
    setUUIDForFocus(`textArea-${get(res, '[0].uuid')}`);
  }, [dayDate, get(last(activities), 'weight', 0), dayUUID]);

  const stopPropagationAndPreventDefault = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onDragOver = (e) => {
    e.preventDefault();
    const { id } = e.currentTarget;

    if (id !== activeDragHover) {
      setActiveDragHover(id);
    }
  };

  const onDragOverActivityInIsEmpty = useCallback((e) => {
    if (activities?.length === 0) {
      e.preventDefault();
    }
  }, []);

  const onDragOverActivity = (e) => {
    if (activities?.length !== 0) {
      onDragOver(e);
    }
  };

  const deleteActivity = async () => {
    await dispatch(
      entityDelete({
        data: { entity_uuid: draggableActivity?.uuid },
        constants: [
          'DELETE_ENTITY_REQUEST',
          'DELETE_ENTITY_SUCCESS',
          'DELETE_ENTITY_FAILURE',
        ],
        options: { partition: partitionNamesConfig.partition3 },
      }),
    );
    dispatch({
      type: UsersPartitionConstants.SET_DRAG_AND_DROP_ELEMENT,
      payload: { deleteUUID: draggableActivity?.uuid },
    });
  };

  const onDropActivityInEmpty = async (e) => {
    if (activities?.length === 0) {
      stopPropagationAndPreventDefault(e);

      const newParams = {
        ...omit(draggableActivity, ['uuid', 'weight', 'date']),
        date: dayDate,
        weight: 1000,
      };
      const res = await createActivity(newParams);
      setActivities((prev) => [
        ...prev,
        { ...get(res, '[0].params', {}), uuid: get(res, '[0].uuid') },
      ]);
      // console.log("onDropActivityInEmpty", dayDate);
      deleteActivity();
    }
  };

  const getArrNeedUpdateWeight = (uuid, newWeight) => {
    const arr = [];
    let nextElements = false;
    let weight = newWeight;
    activities.forEach((el) => {
      if (
        nextElements
        && +el.weight <= +weight
        && el.uuid !== draggableActivity?.uuid
      ) {
        weight += 1;
        arr.push(updateActivity(el?.uuid, { weight }));
      }
      if (el.uuid === uuid) {
        nextElements = true;
      }
    });
    return arr;
  };

  const createActivityFromTextArea = async (prevActivityUuid) => {
    const getWeight = activities.find((i) => i.uuid === prevActivityUuid).weight || 0;
    const newWeight = +getWeight + 1;
    const params = {
      date: dayDate,
      title: '',
      completed: false,
      weight: newWeight,
    };
    const res = await createActivity(params);

    await Promise.all([...getArrNeedUpdateWeight(prevActivityUuid, newWeight)]);
    getActivities();
    setUUIDForFocus(`textArea-${get(res, '[0].uuid')}`);
  };

  const onDropActivityNotificationSync = async (resp, withDelete) => {
    const resp_data = resp?.flat();
    const data_for_notification = resp_data?.flat().map((item) => ({
      uuid: item?.uuid,
      date: item?.params?.date,
      title: item?.params?.title,
      completed: item?.params?.completed,
      weight: item?.params?.weight,
    }));
    const req_data = {
      action_type: 'update',
      parent_date: data_for_notification[0]?.date,
      data: data_for_notification,
    };
    syncChanges(req_data);
    // console.log("update activity", req_data);

    if (withDelete) {
      const req_data = {
        action_type: 'delete',
        parent_date: draggableActivity?.date,
        data: [
          { uuid: draggableActivity?.uuid, date: draggableActivity?.date },
        ],
      };
      syncChanges(req_data);
      // console.log("delete activity", req_data);
    }
  };

  const onDropActivity = async (e) => {
    stopPropagationAndPreventDefault(e);
    const { id, dataset } = e.currentTarget;
    const newWeight = +dataset.weight + 1;
    const newParams = {
      ...omit(draggableActivity, ['uuid', 'weight', 'date']),
      date: dayDate,
      weight: newWeight,
    };
    if (dataset.date === draggableActivity?.date) {
      const resp = await Promise.all([
        updateActivity(draggableActivity?.uuid, newParams),
        ...getArrNeedUpdateWeight(id, newWeight),
      ]);
      onDropActivityNotificationSync(resp);
    } else {
      const resp = await Promise.all([
        createActivity(newParams),
        ...getArrNeedUpdateWeight(id, newWeight),
      ]);
      deleteActivity();
      onDropActivityNotificationSync(resp, true);
    }
    setActiveDragHover(null);
    getActivities();
  };

  const onDragStart = (e) => {
    const { id } = e.currentTarget;
    const currentActivity = activities.find((i) => i?.uuid === id);
    const currValue = document.getElementById(`textArea-${id}`).value;

    dispatch({
      type: UsersPartitionConstants.SET_DRAG_AND_DROP_ELEMENT,
      payload: { ...currentActivity, title: currValue },
    });
  };

  const onDragLeaveActivity = (e) => {
    e.preventDefault();
    setActiveDragHover(null);
  };

  const onDragEnd = () => {
    setActiveDragHover(null);
    setDraggable(false);
  };

  const createActivityFromDublicate = useCallback(async () => {
    const needCreate = needDublicate.find((i) => i.date === dayDate);
    if (needCreate) {
      const params = {
        date: dayDate,
        title: needCreate?.title || '',
        completed: false,
        weight: +get(last(activities), 'weight', 0) + 1000,
      };
      const res = await createActivity(params, true);
      const newActivity = {
        ...get(res, '[0].params', {}),
        uuid: get(res, '[0].uuid'),
      };
      setActivities((prev) => [...prev, newActivity]);
      // console.log('newActivity', newActivity);
      const {
        uuid, completed, weight, title, date,
      } = newActivity;
      const newActivityData = {
        uuid, completed, weight, title, date,
      };
      handleDuplicate(newActivityData);
    }
  }, [dayDate, get(last(activities), 'weight', 0), dayUUID, needDublicate]);

  useEffect(() => {
    // CREATE ACTIVITY FROM DUBLICATE
    // console.log("needDublicate", needDublicate);
    if (!isEmpty(needDublicate)) {
      createActivityFromDublicate();
    }
  }, [needDublicate]);

  useEffect(() => {
    // UPDATE LIST AFTER REMOVE
    if (
      get(draggableActivity, 'deleteUUID')
      && activities.some((i) => i.uuid === get(draggableActivity, 'deleteUUID'))
    ) {
      getActivities();
      dispatch({ type: UsersPartitionConstants.CLEAR_DRAG_AND_DROP_ELEMENT });
    }
  }, [get(draggableActivity, 'deleteUUID')]);

  useEffect(() => {
    // SET FOCUS TO INPUT
    if (UUIDForFocus && !loading) {
      document.getElementById(UUIDForFocus)?.focus();
      setUUIDForFocus('');
    }
  }, [UUIDForFocus, loading]);

  useEffect(() => {
    // GET LIST ACTIVITIES
    if (dayUUID) {
      getActivities();
    }
  }, [dayUUID]);
  //   console.log('activities', activities);
  return (
    <>
      <div className="title_activity_daily_plan">
        {`${capitalize(t('wms.activity.header1', 'Personal tasks'))}:`}
      </div>
      <Spin
        spinning={loading}
        size="small"
      >
        <div
          className="activity_daily_plan_wrapper text-center"
          onDrop={onDropActivityInEmpty}
          onDragOver={onDragOverActivityInIsEmpty}
          onDragLeave={onDragLeaveActivity}
        >
          {!isEmpty(activities) ? (
            activities.map((item) => (
              <div
                draggable={draggable}
                id={item?.uuid}
                data-weight={item?.weight}
                data-date={item?.date}
                key={`${item?.uuid}-${item?.title})`}
                onDragStart={onDragStart}
                onDrop={onDropActivity}
                onDragOver={onDragOverActivity}
                onDragEnd={onDragEnd}
                onDragLeave={onDragLeaveActivity}
                className={`${
                  activeDragHover === item?.uuid
                    ? 'active-drag-hover'
                    : 'not-active-drag-hover'
                } flex items-center`}
              >
                <Icon
                  id='dragAndDropButton'
                  path={mdiDrag}
                  size={1}
                  className="drag-button-activity"
                  onMouseDown={toggleDragButton}
                  onMouseUp={unToggleDragButton}
                />
                <ViewActivity
                  item={item}
                  projectUserReportsUUID={projectUserReportsUUID}
                  updateActivityCallback={updateActivity}
                  createActivityFromTextArea={createActivityFromTextArea}
                  deleteActivityCallback={deleteActivityCallback}
                  syncChanges={syncChanges}
                />
              </div>
            ))
          ) : <div className="empty-data-tickets">empty day...</div>}
        </div>
        <AddActivityOrTicketsButton
          buttonClassName="activity_window__button-private-tab"
          AddNewActivityCallback={createActivityCallbak}
        />
      </Spin>
    </>
  );
}

DayWeek.propTypes = {
  dayDate: string,
  dayUUID: string,
  projectUserReportsUUID: string,
};

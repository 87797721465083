/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes, { bool, number } from 'prop-types';
import jspreadsheet from 'jspreadsheet-ce';

import { isNil } from 'lodash';

import { Button } from 'antd';


import customContextMenu from './customContextMenu';
import {
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { JSpreadsheetHelpContent } from './JSpreadsheetHelpContent';
import SimpleInfoModal from '../src/app/projectFlow/components/docView&ideas/QuillEditor/ToolbarHelper/blocks/SimpleInfoModal';

import 'jspreadsheet-ce/dist/jspreadsheet.css';
import './JSpreadsheet.css';

function JSpreadsheet({
  columns,
  columnsWidths,
  documentName,
  changeDescriptionCallback,
  defaultTextDescription,
  getSpreadsheet,
  getDescriptionForMerge,
  isFullscreen = false,
  isReadOnly = false,
  autoIncrement = true,
  height = '515px',
  styleCells,
  showFormulas = false,
  minDimensions,
  className,
  disabledContextMenu,
  onBeforeChangeCallback,
  setDescriptionData
}) {
  const { t } = useTranslation();

  const jRef = useRef(null);
  const firstSetupColumnsWidth = useRef(true);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const modalProps = {
    title: "Info",
    isOpen: open,
    handleClose: handleCancel,
    content: <JSpreadsheetHelpContent />,
  };

  const readOnlyOptions = {
    rowDrag: false,
    allowDeleteRow: false,
    allowInsertRow: false,

    columnDrag: false,
    allowRenameColumn: false,
    allowDeleteColumn: false,
    allowInsertColumn: false,

    allowExport: true,

    contextMenu: () => { },

    editable: false,
  };

  function onChangeData(editor, cell, x, y, newValue, oldValue) {
    const jsonData = editor?.jspreadsheet.getJson();
    changeDescriptionCallback?.(jsonData, x, y, newValue, oldValue);
    getDescriptionForMerge?.(jsonData);
      if(setDescriptionData) {
        setDescriptionData(jsonData)
      }
  }

  // For fix '\x00' problem
  function onBeforeChange(instance, cell, x, y, value) {
    if (value === '\x00') {
      // console.log('catched');
      return '';
    }
    if (onBeforeChangeCallback) {
      return onBeforeChangeCallback(jRef.current.jspreadsheet, x, y, value);
    }
  }

  function onResizeColumn() {
    if (!firstSetupColumnsWidth.current) {
      changeDescriptionCallback?.();
    }
  }

  // function onLoad() {
  // console.log('LOAD');
  // setColumnProperties();
  // }

  // function onSaveData() {
  //   console.log('save');
  // }

  // function setReadOnlyMode(instance, cell, col, row, val, label, cellName) {
  // console.log('Update table event', { cell: cell, col: col, row: row, val: val, label: label });
  // cell.classList.add('readonly');
  // }

  // const getData = () => {
  //   console.log('ALL DATA', jRef?.current.jspreadsheet.getData());
  //   console.log('ALL DATA JSON', jRef?.current.jspreadsheet.getJson());
  //   console.log('ALL COLUMNS', jRef?.current.jspreadsheet.getConfig().columns);
  // };

  // const getConfig = () => {
  //   console.log('Config', jRef?.current.jspreadsheet.getConfig());
  //   console.log('Meta', jRef?.current.jspreadsheet.getMeta());
  //   console.log('jexcel', jRef?.current.jexcel); // !!!
  //   console.log('jspreadsheet', jRef?.current.jspreadsheet);
  //   // jRef?.current.jspreadsheet.setWidth(2, 200);
  // };

  const style = useMemo(() => ({ maxHeight: height }), [height]);

  const options = () => {
    const config = {
      csvFileName: 'New Spreadsheet',
      minDimensions: minDimensions || [26, 100],
      data: defaultTextDescription || [],
      about: 'WMS Spreadsheet',
      defaultColWidth: 100,

      rowResize: true,
      columnDrag: true,
      allowRenameColumn: false,

      tableOverflow: true,

      lazyLoading: true,
      loadingSpin: true,

      tabs: true,
      autoIncrement,

      onchange: onChangeData,
      onbeforechange: onBeforeChange,
      onresizecolumn: onResizeColumn,
      contextMenu: disabledContextMenu ? () => { } : customContextMenu,
    };

    if (columns) {
      config.columns = columns;
    }
    return config;
  };  

  const stopExportShortCut = (event) => {
    if (event.keyCode === 83 && (navigator.userAgent.indexOf('Mac') !== -1 ? event.metaKey : event.ctrlKey)) {
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  useLayoutEffect(() => {
    if (isNil(jRef.current.jspreadsheet)) {
      if (isReadOnly) jspreadsheet(jRef.current, { ...options(), ...readOnlyOptions });
      else jspreadsheet(jRef.current, options());

      getSpreadsheet?.(jRef.current.jspreadsheet);
    } else {
      jRef.current.jspreadsheet.setData(defaultTextDescription || []);
    }
  }, [defaultTextDescription, JSON.stringify(columns)]);

  useLayoutEffect(() => {
    if (styleCells) {
      jRef.current.jspreadsheet.setStyle(styleCells);
    }
  }, [styleCells]);

  useLayoutEffect(() => {
    firstSetupColumnsWidth.current = true;
    if (columnsWidths && columnsWidths?.length) {
      columnsWidths.forEach((colWidth, index) => {
        jRef.current.jspreadsheet.setWidth(index, colWidth);
      });

      firstSetupColumnsWidth.current = false;
    }
  }, [JSON.stringify(columnsWidths)]);

  useEffect(() => {
    if (!isNil(jRef.current)) {
      jRef.current.jspreadsheet.fullscreen(isFullscreen);
      if (isFullscreen) document.body.classList.add('_locked');
      else document.body.classList.remove('_locked');
    }
  }, [isFullscreen]);

  return (
    <>
      <div style={{ marginBottom: showFormulas ? '8px' : 0, display: showFormulas ? 'inline-block' : 'none' }}>    
        <Button onClick={handleOpen} className={`btnPrimary-outline help-btn`} type='dashed' icon={<QuestionCircleOutlined />} size="small">Info</Button>
      </div>
      <SimpleInfoModal {...modalProps} />
      <div
        ref={jRef}
        style={style}
        className={className}
        onKeyDown={stopExportShortCut}
      />
    </>
  );
}

JSpreadsheet.propTypes = {
  columns: PropTypes.object,
  columnsWidths: PropTypes.arrayOf(number),
  defaultTextDescription: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  documentName: PropTypes.string,
  getSpreadsheet: PropTypes.func,
  isFullscreen: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  height: PropTypes.string,
  styleCells: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  disabledContextMenu: bool,
  changeDescriptionCallback: PropTypes.func,
};

export default JSpreadsheet;

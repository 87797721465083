import { mdiDotsHorizontal } from '@mdi/js';
import Icon from '@mdi/react';
import { Input, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { func, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { antNotification } from '../../../MainUtils';
import PoroverWrapper from '../../../projectFlow/components/PoroverWrapper';
import ActionsActivity from './ActionsActivity';
import BtnModal from '../../../projectFlow/components/BtnModalComponent';
import TimeLogStartPage from '../../../projectFlow/components/TimeLogStartPage';
import { partitionNamesConfig } from '../../../api/appConfig';

const { TextArea } = Input;

export default function ViewActivity({
  item,
  projectUserReportsUUID,
  updateActivityCallback,
  createActivityFromTextArea,
  deleteActivityCallback,
  syncChanges,
}) {
  const { t } = useTranslation('');
  const [messageApi, contextHolder] = message.useMessage();

  const textareaRef = useRef();
  const isEdited = useRef(null);

  const [title, setTitle] = useState('');
  const [viewTimeLogModal, setViewTimeLogModal] = useState(false);

  const {
    completed, title: defaultTitle, uuid, date,
  } = item;

  const onChangeTextarea = (e) => {
    const newValue = e.target.value;
    setTitle(newValue);
    isEdited.current = true;
  };

  const saveChange = () => {
    if (isEdited.current) {
      updateActivityCallback(uuid, { title }, false, true)
        .then(() => {
          messageApi.open({
            type: 'success',
            content: t('wms.noun.success', 'Success'),
          });
        })
        .catch(() => antNotification('error', t('wms.noun.error', 'Error')));
      isEdited.current = false;
      const req_data = {
        action_type: 'update',
        parent_date: date,
        data: [
          {
            uuid,
            completed,
            date,
            title,
          },
        ],
      };
      syncChanges(req_data);
      // console.log("update activity", req_data);
    }
  };

  const onKeyDownTextarea = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      const { value } = e.target;
      setTitle(value.concat('\n'));
      isEdited.current = true;
      return;
    }

    if (e.key === 'Enter' && !e.ctrlKey) {
      e.preventDefault();
      saveChange();
      createActivityFromTextArea(uuid);
      isEdited.current = false;
    }
  };

  const toggleOpenModalTimeLogCallback = (bool) => setViewTimeLogModal((prev) => bool || !prev);

  const targetData = {
    params: {
      typeForReport: 'activity',
      title,
      project: projectUserReportsUUID,
      status: 'created',
      tracker: 'ticket',
      priority: 'low',
    },
    uuid,
  };

  useEffect(() => {
    if (uuid) {
      setTitle(defaultTitle);
    }
  }, [uuid, defaultTitle]);
  return (
    <div className="w-full">
      {contextHolder}
      <div className="relative w-full">
        <PoroverWrapper
          placement="bottom"
          className="wrapper_textarea_daily_panning__button_action"
          id='dailyPanningButton'
          customBtn={(
            <div className="wrapper_textarea_daily_panning__button_action">
              <Icon path={mdiDotsHorizontal} size={0.8} />
            </div>
          )}
        >
          <ActionsActivity
            isComplete={completed}
            title={title}
            uuid={uuid}
            date={date}
            updateActivityCallback={updateActivityCallback}
            deleteActivityCallback={deleteActivityCallback}
            toggleOpenModalTimeLogCallback={toggleOpenModalTimeLogCallback}
            syncChanges={syncChanges}
          />
        </PoroverWrapper>

        <div className="flex items-center positionRelative w-full">
          <TextArea
            ref={textareaRef}
            id={`textArea-${uuid}`}
            value={title}
            disabled={completed}
            onChange={onChangeTextarea}
            onKeyDown={onKeyDownTextarea}
            onBlur={saveChange}
            className={cn('w-full textarea_daily_panning', {
              complete: completed,
            })}
            autoSize={{ minRows: 1, maxRows: 10000 }}
          />
        </div>
      </div>
      <BtnModal
        width={800}
        handleCancelCallbackFunc={toggleOpenModalTimeLogCallback}
        openFromOuterComponent={viewTimeLogModal}
      >
        <TimeLogStartPage
          targetData={targetData}
          currentDay={date}
          parentType="issue"
          partitionType={partitionNamesConfig.partition3}
          parentUUID={uuid}
        />
      </BtnModal>
    </div>
  );
}

ViewActivity.propTypes = {
  item: object,
  updateActivityCallback: func,
  createActivityFromTextArea: func,
  deleteActivityCallback: func,
  projectUserReportsUUID: string,
};

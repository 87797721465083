import { partitionNamesConfig } from "../../../../api/appConfig";

// Filter tags
export const userTags = [
  {
    key: 'user',
    value: 'member',
    label: 'wms.filters.project.member',
    keySection: 'user-filter',
  },
  {
    key: 'user',
    value: 'creator',
    label: 'wms.filters.project.creator',
    keySection: 'user-filter',
  },
  {
    key: 'user',
    value: 'owner',
    label: 'wms.filters.project.owner',
    keySection: 'user-filter',
  },
];

export const statusTags = [
  {
    key: 'status',
    value: 'archive',
    ruleOfTag: 'off',
    label: 'wms.filters.project.archive',
    className: 'switch-testing-section',
    // icon: mdiArchive,
    keySection: 'status-filter',
  },
];

export const partitionTags = [
  {
    key: 'partition',
    value: 'all-partition',
    label: 'wms.filters.project.all',
    keySection: 'partition-filter',
  },
  {
    key: 'partition',
    value: 'general',
    label: 'wms.filters.project.general',
    keySection: 'partition-filter',
  },
  {
    key: 'partition',
    value: 'personal',
    label: 'wms.filters.project.personal',
    keySection: 'partition-filter',
  },
];

export const metricTags = (hideMetric = []) => {
  const allMetricTags = ['no-planning', 'expired-milestone', 'dev-score', 'outdated-score', 'low-tests-level', 'low-tests-efficiency', 'low-archive-level'];

  const subSectionTags = allMetricTags.filter((tag) => !hideMetric.includes(tag));

  return [
    {
      key: 'metric',
      value: 'all-metric',
      label: 'wms.filters.project.all_reset',
      parentSubSection: 'metric-section',
      subSectionTags,
      keySection: 'metric-filter',
    },
    {
      key: 'metric',
      value: 'no-planning',
      label: 'wms.filters.project.no_planning',
      keySection: 'metric-filter',
      hide: hideMetric.includes('no-planning'),
    },
    {
      key: 'metric',
      value: 'expired-milestone',
      label: 'wms.filters.project.expired_milestone',
      keySection: 'metric-filter',
      hide: hideMetric.includes('expired-milestone'),
    },
    {
      key: 'metric',
      value: 'dev-score',
      label: 'wms.filters.project.extreme_dev_score',
      keySection: 'metric-filter',
      hide: hideMetric.includes('dev-score'),
    },
    {
      key: 'metric',
      value: 'outdated-score',
      label: 'wms.filters.project.extreme_outdated_score',
      keySection: 'metric-filter',
      hide: hideMetric.includes('outdated-score'),
    },
    {
      key: 'metric',
      value: 'low-tests-level',
      label: 'wms.filters.project.low_tests_level',
      keySection: 'metric-filter',
      hide: hideMetric.includes('low-tests-level'),
    },
    {
      key: 'metric',
      value: 'low-tests-efficiency',
      label: 'wms.filters.project.low_tests_efficiency',
      keySection: 'metric-filter',
      hide: hideMetric.includes('low-tests-efficiency'),
    },
    {
      key: 'metric',
      value: 'low-archive-level',
      label: 'wms.filters.project.low_archive_level',
      keySection: 'metric-filter',
      hide: hideMetric.includes('low-archive-level'),
    },
  ].filter((i) => !i.hide);
};

export const projectsFilter = (location, hideTags = []) => {
  const hideMetricTags = location === 'projects'
    ? ['outdated-score', 'low-tests-level', 'low-tests-efficiency', 'low-archive-level']
    : [];

  return [
    {
      isRow: true,
      rowText: 'wms.labels.filter.user',
      hide: hideTags.includes('user'),
      tags: userTags,
    },
    {
      isRow: true,
      rowText: 'wms.labels.filter.status',
      hide: hideTags.includes('status'),
      tags: statusTags,
    },
    {
      isRow: true,
      isRadio: true,
      rowText: 'wms.labels.filter.type',
      hide: hideTags.includes('type'),
      tags: partitionTags,
    },
    {
      isRow: true,
      rowText: 'wms.labels.filter.metric',
      hide: hideTags.includes('metric'),
      tags: metricTags(hideMetricTags),
    },
  ].filter((i) => !i.hide);
};

// ---------------------------------------------------
// Filter presets
export const defaultSelectedFilterTags = (partition = partitionNamesConfig.partition1) => ([
  ...userTags.filter((i) => i.value === 'member'),
  ...statusTags
    .filter((i) => i.value === 'archive')
    .map((i) => ({ ...i, ruleOfTag: 'excluded' })),
  ...partitionTags.filter((i) => (partition ===  partitionNamesConfig.partition1 && i.value === 'general')
  || (partition === partitionNamesConfig.partition3 && i.value === 'personal')),
]);

export const showAllSelectedFilterTags = (partition =  partitionNamesConfig.partition1) => ([
  ...partitionTags.filter((i) => (partition ===  partitionNamesConfig.partition1 && i.value === 'general')
  || (partition ===  partitionNamesConfig.partition3 && i.value === 'personal')),
]);

// Projects request default config
export const defaultConfigProjectsRequest = (userUUID) => ({
  params: {
    status__not: ['archive'],
    usersSearch: [userUUID],
  },
  offset: 0,
});
// ---------------------------------------------------

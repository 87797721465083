import React, { useMemo } from 'react';
import {
  string,
  number,
  bool,
  arrayOf,
} from 'prop-types';
import { take } from 'ramda';

import { Tooltip } from 'antd';

const makeHashtagsString = (tags = []) => tags.map((item) => `#${item.toLowerCase()}`).join(', ');
const makeTooltip = (tags = []) => tags.map((item) => (
  <div key={item}>
    <span className="text-blue-500">#</span>
    <span style={{ color: '#000000d9' }}>{item.toLowerCase()}</span>
  </div>
));

function HashtagsList({
  className = 'text-blue-500',
  isInline = false,
  tooltip,
  tooltipPlacement = 'right',
  tags = [],
  maxLength = 50,
}) {
  const hashtagsString = useMemo(() => makeHashtagsString(tags), [JSON.stringify(tags)]);
  const defaultTooltip = useMemo(() => makeTooltip(tags), [JSON.stringify(tags)]);
  const displayClassName = isInline ? 'inline' : 'block';

  return (
    <Tooltip
      title={tooltip || defaultTooltip}
      placement={tooltipPlacement}
      color="#ffffff"
      trigger={tooltip || (maxLength && hashtagsString.length > maxLength) ? 'hover' : ''}
    >
      <span className={`wordBreak ${className} ${displayClassName}`}>
        {maxLength && hashtagsString.length > maxLength ? `${take(maxLength, hashtagsString)}...` : hashtagsString}
      </span>
    </Tooltip>
  );
}

HashtagsList.propTypes = {
  className: string,
  isInline: bool,
  tags: arrayOf(string),
  tooltip: string,
  tooltipPlacement: string,
  maxLength: number,
};

export default HashtagsList;

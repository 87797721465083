import {
  mdiChevronDown,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Tooltip,
} from 'antd';
import {
  array, bool, func, object, string,
} from 'prop-types';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import IssueStatusesComponent from '../../IssueStatusesComponent';
import AssignedUsersFromStatusModal from './AssignedUsersFromStatusModal';
import EntityParamTag from '../../../../../components/entityParamTag/EntityParamTag';

import { capitalize } from 'lodash';
import { getIssue } from '../../../../selectors/selectors';
import DateLeft from '../../../../../components/DateLeft';
import { getMinDateExpired, checkIsMinDateForExpired, getExpTypeAndValue } from '../utils';

function IssueStatuses({
  vectorData,
  projectUuid,
  lifeTime,
  disabledView,
  currentStatus = 'empty',
  onChangeFieldCallback,
  partitionType,
  hiddenModalAssigned,
  usersUuidsForAssigned,
  usersSearch,
  users,
  projectUsers,
  getListUsersFromAssignedUsersCallback,
  isAssignedVector,
  descriptionComment,
  creatorIssue,
  expirationDateDev,
  expirationDateQa,
}) {
  const { t } = useTranslation();

  const issue = useSelector(getIssue);

  const [collapseStatusFlag, setCollapseStatusFlag] = useState(false);
  const [statusForAssigned, setModalAssigned] = useState('');

  const changeStatus = (status, value) => {
    setCollapseStatusFlag(false);
    const { type, days } = getExpTypeAndValue(value);
    const dataForUpdate = {
      [status]: value,
      [type]: moment().add(days, 'days').format('YYYY-MM-DD'),

    };

    return hiddenModalAssigned
      ? onChangeFieldCallback(type ? dataForUpdate : { [status]: value })
      : setModalAssigned(value);
    // const { type, days } = getExpTypeAndValue(value);
    // switch (value) {
    // case 'closed':
    // case 'deployed':
    // case 'abandoned':
    //   return onChangeFieldCallback({
    //     // date_end: moment().format('YYYY-MM-DD'),
    //     status: value,
    //     // lifeTime: getMinDateExpired(),
    //   });
    // case 'backlog':
    //   return onChangeFieldCallback({
    //     date_end: moment().format('YYYY-MM-DD'),
    //     status: value,
    //     lifeTime: getMinDateExpired(1005),
    //   });
    //   default:
    //     return hiddenModalAssigned
    //       ? onChangeFieldCallback({ [status]: value })
    //       : setModalAssigned(value);
    // }
  };

  const isClosed = get(issue, 'params.status') === 'closed'
    || get(issue, 'params.status') === 'deployed'
    || get(issue, 'params.status') === 'abandoned';

  const { disabledCollapse, disabledFull } = useMemo(() => ({
    disabledCollapse: disabledView.includes('statusCollapse'),
    disabledFull: disabledView.includes('fullEdit'),
  }), [disabledView]);

  const toggleCollapseStatus = () => {
    if (!disabledCollapse && !isClosed) {
      setCollapseStatusFlag((prev) => !prev);
    }
  };

  const nameForTranslate = currentStatus === 'deployed' ? 'deployed_closed' : currentStatus;

  const { type: needExp } = getExpTypeAndValue(currentStatus);

  useEffect(() => {
    if (get(issue, 'uuid') && isClosed && collapseStatusFlag) {
      setCollapseStatusFlag(false);
    }
  }, [get(issue, 'uuid')]);

  return (
    <>
      <div className="flex items-center">
        <div className="wrapper-title">
          {`${capitalize(t('wms.labels.status', 'status'))}:`}
        </div>
        <div className="grow wrapper-description ml-1 flex items-center">
          <Tooltip
            title={
              isClosed
                ? 'The task was closed. You cannot change status. Please clone this issue'
                : ''
            }
          >
            <div
              id='changeStatusButton'
              className={`${disabledCollapse || isClosed || disabledFull
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
              }
              statuses-wrapper-issue ${currentStatus}`}
              onClick={toggleCollapseStatus}
              tabIndex="0"
              role="button"
            >

              <EntityParamTag
                param="status"
                value={currentStatus}
                type="valueAndIconWithArrow"
                fontSizeClass="text-sm"
                // isOpen={collapseStatusFlag}
              />

              {/* {capitalize(t(`wms.status.${nameForTranslate}`, `${nameForTranslate}`))} */}
              {/* <Icon
                path={mdiChevronDown}
                size={0.7}
                className={`ml-2 ${
                  (disabledCollapse || isClosed || disabledFull) && 'hidden'
                }`}
              /> */}
            </div>
          </Tooltip>

          {/* {!collapseStatusFlag && needExp
        && (
        <ExpirationSwitch
          isExpanded={collapseStatusFlag}
          onChangeFieldCallback={onChangeFieldCallback}
          expirationDateDev={expirationDateDev}
          expirationDateQa={expirationDateQa}
          status={currentStatus}
        />
        )} */}
          {/* { !checkIsMinDateForExpired(lifeTime) && (
          <div className="ml-3 flex flex-shrink-0 items-center">
            <ExpirationTime
              expirationDate={lifeTime}
              onChangeFieldCallback={onChangeFieldCallback}
              isEdit
            />
          </div>
          // <DateLeft
          //   date={lifeTime}
          //   customStyle="mx-2 text-xs"
          // />
          )} */}

        </div>
        {/* {collapseStatusFlag
        && (
        <ExpirationSwitch
          isExpanded={collapseStatusFlag}
          onChangeFieldCallback={onChangeFieldCallback}
          expirationDateDev={expirationDateDev}
          expirationDateQa={expirationDateQa}
          status={currentStatus}
        />
        )} */}
      </div>

      <div className={`${!collapseStatusFlag && 'hidden'} mt-1 w-full`}>
        <IssueStatusesComponent
          disabled={isClosed || disabledFull}
          onChangeCallback={changeStatus}
          partitionType={partitionType}
          currentStatus={currentStatus}
        />

        {statusForAssigned && (
          <AssignedUsersFromStatusModal
            projectUuid={projectUuid}
            vectorData={vectorData}
            creatorIssue={creatorIssue}
            statusForAssigned={statusForAssigned}
            setModalAssigned={setModalAssigned}
            usersSearch={usersSearch}
            users={users}
            partitionType={partitionType}
            descriptionComment={descriptionComment}
            projectUsers={projectUsers}
            isAssignedVector={isAssignedVector}
            usersUuidsForAssigned={usersUuidsForAssigned}
            onChangeFieldCallback={onChangeFieldCallback}
            getListUsersFromAssignedUsersCallback={getListUsersFromAssignedUsersCallback}
          />
        )}
      </div>
    </>
  );
}

export default IssueStatuses;

IssueStatuses.propTypes = {
  lifeTime: string,
  disabledView: array,
  currentStatus: string,
  onChangeFieldCallback: func,
  partitionType: string,
  hiddenModalAssigned: bool,
  usersUuidsForAssigned: string,
  usersSearch: array,
  users: array,
  projectUsers: array,
  getListUsersFromAssignedUsersCallback: func,
  isAssignedVector: bool,
  descriptionComment: array,
  creatorIssue: string,
  expirationDateDev: string,
  expirationDateQa: string,
  vectorData: object,
};

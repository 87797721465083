// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2024.06.03 || YYYY/MM/DD

import React, { useEffect, useState, useMemo } from 'react';
import {
  Avatar, Flex, Modal, Button, Input, Space
} from "antd";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { capitalize } from 'lodash';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import { AppstoreAddOutlined } from '@ant-design/icons';

import './PublicInterface.scss';

import InterfaceCard54Origins from './InterfaceCard54Origins';

import { getCookie } from '../../cookies54origins';

function InterfacesListModal54Origins({
  url,
  sessionTokenName,
  className,
}) {
  const { i18n: { language } = {} } = useTranslation();

  const [interfaces, setInterfaces] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const filteredServices = useMemo(() => {
    if (!searchTerm) return interfaces;
    return interfaces
      .filter((service) => service?.service_name?.toLowerCase().includes(searchTerm));
  }, [interfaces.length, searchTerm]);
  // console.log('filteredServices', filteredServices);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
    setSearchTerm?.('');
  };

  const requestGetOpenInterfaces = () => {
    axios({
      method: 'get',
      url: `${url}/get_public_interfaces/`,
      headers: {
        'Content-Type': 'application/json',
        'Session-Token': getCookie(sessionTokenName),
      },
    }).then(({ data }) => {
      setInterfaces(data?.services);
    });
  };

  const onChangeSearch = (e) => {
    const { value } = e?.target || {};
    setSearchTerm(value?.trim()?.toLowerCase());
  };

  useEffect(() => {
    requestGetOpenInterfaces();
  }, []);

  return (
    <div
      id="interfaces_list"
      className={`header-dropdown-wrapper ${className}`}
    >
      <Space
        style={{ cursor: 'pointer' }}
        gap={5}
        align="center"
        justify="center"
        onClick={toggleModal}
      >
        <Avatar
          icon={<AppstoreAddOutlined style={{ color: '#1677ff' }} />}
          shape="square"
          style={{
            background: '#e6f4ff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
        <span style={{ fontSize: 15 }}>
          {capitalize(language === 'en' ? 'services' : 'сервисы')}
        </span>
      </Space>

      <Modal
        wrapClassName="interfaces_modal"
        style={{ top: 20 }}
        width="80%"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button
            key="back"
            className="btnPrimary"
            onClick={toggleModal}
          >
            Return
          </Button>,
        ]}
      >
        <Flex gap={20} align="center">
          <h5 className="interfaces__title">{capitalize('interfaces/services available in this biom:')}</h5>
          <Input
            className="interfaces__search"
            style={{ border: 'none', borderRadius: '6px' }}
            size="large"
            placeholder="Search service"
            variant="borderless"
            allowClear
            prefix={<Icon path={mdiMagnify} size={1} color="#bebebe" />}
            value={searchTerm}
            onChange={onChangeSearch}
          />
        </Flex>
        <div className="interfaces__box">
          {filteredServices.map((service) => {
            const {
              service_name: name,
              service_description: description,
              service_domain: domain,
              service_icon: icon,
              icon_color: color,
            } = service;

            return (
              <InterfaceCard54Origins
                key={`service_${name}`}
                title={name}
                description={description}
                link={domain}
                icon={icon}
                iconColor={color}
              />
            );
          })}
        </div>
      </Modal>
    </div>
  );
}

export default InterfacesListModal54Origins;

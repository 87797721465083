import { Col, Row, Spin } from 'antd';
import { get, isEmpty } from 'lodash';
import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import ListOfIssuesTable from '../../issues/ListOfIssuesTable';
import ListOfVectorsAntDesignTable from '../ListOfVectorsAntDesignTable';

import './DragTasks.scss';

import { getListAndPartialReadEntities, incrementAndGetCount } from '../../../../entity/actions/entityActions';
import { antNotification } from '../../../../MainUtils';
import { capitalize } from 'lodash';
import { clearTasksFromVersion, setFetchingForDragTasks, setTasksFromVersion } from '../../../reducers/slicers/dragTasksSlicer';
import { updateIssueRequest } from '../../../actions/issues-actions';
import useURLParams from '../../../../hooks/useURLParams';

export default function DragTasks({
  partition,
  parent,
  updateCallback,
  setIsShowModal,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { getURLParams } = useURLParams();

  const {
    fetching,
    issues,
  } = useSelector((state) => get(state, 'projectManagementService.project.dragTasks', {}));

  const { activeProject } = getURLParams();

  // const hiddenInVector = ['subType'];

  const defaultVectorsPagination = {
    currentPage: 1,
    limit: 5,
    offset: 0,
  };

  const defaultConfigVectors = {
    order: 'desc',
    orderBy: 'created',
    params: { status__not: ['protected'] },
    ...defaultVectorsPagination,
  };

  const initialConfig = {
    entity_type__not: ['archiveEntity'],
    entity_type: 'issue',
    parent,
    limit: 2000,
    offset: 0,
    params: { status__not: ['closed'] },
  };

  const [config, setConfig] = useState(initialConfig);
  const [selectedIssues, setSelectedIssues] = useState([]);

  const [selectedVector, setSelectedVector] = useState({});
  const [listVectors, setListVectors] = useState([]);
  const [vectorConfig, setVectorConfig] = useState(defaultConfigVectors);
  const [totalCountVectors, setTotalCountVector] = useState(0);

  const {
    currentPage: currentVectorsPage,
    limit,
  } = vectorConfig;

  // console.log('vectorConfig',vectorConfig)

  const getListAndReadOfListIssues = async () => {
    dispatch(setFetchingForDragTasks(true));

    return new Promise((resolve, reject) => {
      // console.log('config', config);
      dispatch(getListAndPartialReadEntities({
        data: {
          ...config,
          params_fields: {
            id: 'id',
            title: 'title',
            tracker: 'tracker',
            priority: 'priority',
            status: 'status',
            users: 'users',
            project: 'project',
          },
        },
        partition,
      }))
        .then((res) => {
          dispatch(setTasksFromVersion(get(res, 'data')));
          resolve(get(res, 'data'));
        })
        .catch((mistake) => {
          //   console.log('mistake', mistake);
          dispatch(setFetchingForDragTasks(false));
          reject(mistake);
        });
    });
  };

  const saveSelectedIssuesCallback = (data) => setSelectedIssues(data);

  const toggleAllIssue = () => {
    setSelectedIssues(issues);
  };

  const onChangeRowCallback = (uuid, data) => setSelectedVector(data);

  const updateRequest = (data) => new Promise((resolve, reject) => {
    dispatch(updateIssueRequest(
      data,
      partition,
      (res) => {
        if (!res) {
          antNotification('error', t('notifications.text.error.base', 'Error'));
          reject(res);
        } else {
          antNotification('success', t('wms.noun.success', 'Success'));
          resolve(res);
        }
      },
    ));
  });

  const getIdIssue = async () => {
    const countIssue = await dispatch(incrementAndGetCount({
      entity_uuid: get(selectedVector, 'uuid', ''),
      field_name: 'issue',
    }, partition));

    return `${get(selectedVector, 'id')}T${get(countIssue, 'increment')}`;
  };

  const updateIssue = async (uuid) => {
    const newParams = {
      entity_type: 'issue',
      entity_uuid: uuid,
      params: {},
    };

    newParams.params.id = await getIdIssue();
    newParams.parent = get(selectedVector, 'uuid', '');

    return updateRequest(newParams);
  };

  const getTemporaryVectors = async () => {
    const {
      limit, offset, order, orderBy, search, params,
    } = vectorConfig;

    const configRequest = {
      data: {
        entity_type: 'vector',
        parent: activeProject,
        params,
        limit,
        depth: 1,
        offset,
        order,
        [orderBy === 'created' ? 'order_by' : 'order_by_params']: orderBy,
        params_fields: {
          id: 'id',
          title: 'title',
          versionNumber: 'versionNumber',
          type: 'type',
          priority: 'priority',
          status: 'status',
          users: 'users',
          lifeTime: 'lifeTime',
        },
      },
      partition,
    };

    if (search) {
      configRequest.data.search_data = {
        value: search,
        ignore_case: true,
        fields: {
          params: ['title', 'id'],
        },
      };
    }

    const data = await dispatch(getListAndPartialReadEntities(configRequest));

    setListVectors(get(data, 'data'));
    setTotalCountVector(get(data, 'total'));
  };

  const getVectorsRequestSetting = (data) => {
    setVectorConfig((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const resetSelected = () => setSelectedIssues([]);

  const toggleSubmit = async () => {
    dispatch(setFetchingForDragTasks(true));
    await Promise.all(selectedIssues.map((i) => updateIssue(i.uuid)));

    if (updateCallback) {
      updateCallback();
    }

    setIsShowModal(false);
  };

  useEffect(() => {
    getListAndReadOfListIssues();
    return () => {
      dispatch(clearTasksFromVersion());
    };
  }, []);

  useEffect(() => {
    getTemporaryVectors();
  }, [vectorConfig]);

  // console.log('selectedIssues', selectedIssues, 'selectedVector', selectedVector);
  return (
    <Col span={24}>
      <Spin spinning={fetching}>
        <Row className="mt-1 mb-1 label-header-issues-table p-1">
          <Col span={18} className="flex items-center">
            <h6>{`${capitalize(t('wms.labels.select_the_tasks_you_want_to_move'))}:`}</h6>
          </Col>
          <Col span={5} className="flex items-center justify-end">
            <BaseButton
              // size="small"
              className="btnWarning-outline mr-1"
              onClick={resetSelected}
              disabled={isEmpty(selectedIssues)}
            >
              {capitalize(t('wms.buttons.reset_selected', { verb: t('wms.verb.reset') }))}

            </BaseButton>

            <BaseButton
              onClick={toggleAllIssue}
              className="btnPrimary-outline mr-1"
            >
              {capitalize(t('wms.buttons.select_all', { noun: t('wms.noun.all') }))}
            </BaseButton>
          </Col>
        </Row>

        <Row className="w-full">
          <Col span={24}>
            <ListOfIssuesTable
              customHeaderTableClasses="headerStyleTableDailyIssue"
              customSelectedIssue={selectedIssues}
              issuesFromOuterComponent={issues}
              multipleIssueSelect
              defaultPageLimit={10}
              hideSearchTitle
              remoteSearch
              customIdDropdownSizePerPageListInTable="dragIssue"
              changeIssueCallback={saveSelectedIssuesCallback}
            />
          </Col>
        </Row>

        <Col span={24} className="mt-3">
          <div className="label-header-vectors-table p-2 mb-1">
            <h6>
              {`${capitalize(t('wms.labels.select_the_vector_where_you_want_to_move_tasks'))}:`}
            </h6>
          </div>

          <ListOfVectorsAntDesignTable
            data={listVectors}
            newCurrentPage={currentVectorsPage}
            totalCount={totalCountVectors}
            onChangeRowCallback={onChangeRowCallback}
            selectedVector={get(selectedVector, 'uuid')}
            limit={limit}
            pageSizeOptions={[5, 10, 25, 50]}
            getVectorsRequestSetting={getVectorsRequestSetting}
          />
        </Col>

        <Row className="mt-2">
          <Col span={24} className="flex justify-end">
            <BaseButton
              disabled={isEmpty(selectedIssues) || isEmpty(selectedVector)}
              onClick={toggleSubmit}
              type="primary"
            >
              {capitalize(t('wms.buttons.submit'))}
            </BaseButton>
          </Col>
        </Row>
      </Spin>
    </Col>
  );
}

DragTasks.propTypes = {
  partition: string,
  parent: string,
  updateCallback: func,
  setIsShowModal: func,
};

import { bool, element, string } from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';

function InfoBlock({
  tabs,
  children,
  title,
  iconPath,
  classNameTitle = '',
  hideInlineStyle = false,
  id = null,
  hideIcon = false,
  hideTitle = false,
  hideIconAndTitle = false,
  className = '',
  childrenClassName = 'items-start justify-start',
  titleAndIconClassName = 'items-center',
}) {
  return (
    <div className={`infoBlock flex ${className}`}>
      {!hideIconAndTitle && (
      <div className={`flex ${titleAndIconClassName} flex-nowrap iconBlock`} style={!hideInlineStyle ? { width: 'max-content' } : {}}>
        {!hideIcon && (
        <Icon
          path={iconPath}
          size={0.9}
          className="text-secondary"
          style={{ minWidth: 24 }}
        />
        )}
        {!hideTitle && (
        <span
          id={id}
          className={`text-secondary inline-flex flex-nowrap mr-2 ${classNameTitle}`}
        >
          {title}
        </span>
        )}
        <span>
          {tabs}
        </span>
      </div>
      )}
      <div className={`grow flex flex-wrap ${childrenClassName}`}>
        {children}
      </div>
    </div>
  );
}

InfoBlock.propTypes = {
  hideIcon: bool,
  hideTitle: bool,
  hideIconAndTitle: bool,
  children: element,
  iconPath: string,
  title: string,
  id: string,
  className: string,
  childrenClassName: string,
  titleAndIconClassName: string,
};

export default InfoBlock;

import { head, omit } from 'lodash';
import {
  array, bool, func, object, string,
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { pathOr } from 'ramda';

import { getProject, getProjectUUID, getVectorInfo } from '../../../selectors/selectors';
import { getUuidsfromParamsUsers } from '../../../../MainUtils';
import useURLParams from '../../../../hooks/useURLParams';
import NewIssueModal from '../../issues/newIssue/NewIssueModal';
import NewRecurringIssueModal from './NewRecurringIssueModal';

export default function WrapperAddRecurringIssue({
  partitionType,
  isGlobalCreateModal = false,
  hideView = [],
  disabledView = [],
  disableBtn,
  openFromOuterComponent,
  closeModalCallback,
  hideAddRecurringIssueBtn,
  defaultRecurringIssueState,
  customTitleOpenModalBtn,
  recurringIssueForProject = false,
  mandatoryRecurringIssue = false,
  activeVectorFromProps,
  createRecurringIssueCallback
}) {
    
  const { getURLParams } = useURLParams();

  const projectData = head(useSelector(getProject));
  const vectorData = head(useSelector(getVectorInfo));

  const { activeVector, activeProject } = getURLParams();

  const [customDisableBtn, setCustomDisableBtn] = useState(false);

  const getListUsers = () => {
    if (activeProject && !activeVector) {
      const projectUUIDs = getUuidsfromParamsUsers(projectData?.params?.users);
      return projectUUIDs;
    }
    if (activeVector) {
      return getUuidsfromParamsUsers(vectorData?.params?.users);
    }
    return [];
  };

  const defaultRecurringIssueConfig = {
    usersUuidsForAssigned: getListUsers(),
    assignedProject: activeProject || '',
    assignedVector: recurringIssueForProject ? '' :  activeVector || '',
    selectedVectorData:  activeVectorFromProps ||  { ...omit(vectorData, ['params']), ...vectorData?.params }  || {},
    selectedProjectData: projectData || {},
    isGlobalCreateModal,
    ...defaultRecurringIssueState,
  };

  useEffect(() => {
    if (activeVector && pathOr('', ['params', 'status'], vectorData) === 'protected') {
      setCustomDisableBtn(true);
    } else {
      setCustomDisableBtn(false);
    }
  }, [vectorData, activeVector]);

  return (
    <NewRecurringIssueModal
      hasCreatePerm={true}
      hideView={hideView}
      disabledView={disabledView}
      disableBtn={disableBtn || customDisableBtn}
      partitionType={partitionType}
      defaultRecurringIssueConfig={defaultRecurringIssueConfig}
      openFromOuterComponent={openFromOuterComponent}
      hideAddRecurringIssueBtn={hideAddRecurringIssueBtn}
      closeModalCallback={closeModalCallback}
      customTitleOpenModalBtn={customTitleOpenModalBtn}
      mandatoryRecurringIssue={mandatoryRecurringIssue}
      createRecurringIssueCallback={createRecurringIssueCallback}
    />
  );
}

WrapperAddRecurringIssue.propTypes = {
  partitionType: string,
  isGlobalCreateModal: bool,
  hideView: array,
  disabledView: array,
  openFromOuterComponent: bool,
  closeModalCallback: func,
  hideAddRecurringIssueBtn: bool,
  defaultRecurringIssueState: object,
  disableBtn: bool,
  customTitleOpenModalBtn: string,
  recurringIssueForProject: bool,
  mandatoryRecurringIssue: bool,
  activeVectorFromProps: object,
  createRecurringIssueCallback: func
};

export const clearRepeatedDeltas = (dataArr, onUnmount) => {
  const dataSet = new Set();
  const uniqueEntries = [];
  let lastEntry = "";
  dataArr.forEach((item, idx) => {
    const check = dataSet.has(item?.text);

    if (check) {
      if (idx === dataArr?.length - 1 && onUnmount) {
        lastEntry = item;
      }
      // console.log('crdt repeated idx', idx);
    } else {
      dataSet.add(item?.text);
      uniqueEntries.push(item);
    }
  });
  const checkIsLastEntryWithBug =
    lastEntry?.text === uniqueEntries[uniqueEntries?.length - 1]?.text;
  if (checkIsLastEntryWithBug && onUnmount) {
    uniqueEntries.pop();
  }
  // const finalArray = Array.from(dataSet).map((str, idx) => ({text: str, }));
  // console.log('crdt lastEntry?.text', lastEntry?.text)
  // console.log('crdt uniqueEntries[uniqueEntries?.length - 1]?.text', uniqueEntries[uniqueEntries?.length - 1]?.text)
  return uniqueEntries;
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  mdiFlag,
  mdiFileDocumentCheck,
  mdiLightbulb,
  mdiBugCheck,
  mdiFileFind,
  mdiBell,
  mdiCodeJson,
  mdiTextBoxMultipleOutline,
  mdiShieldKeyOutline,
} from '@mdi/js';

import { Row } from 'antd';
import { isEmpty } from 'lodash';
import CookbooksCardRoot from '../../../components/GridOfCards/DashboardCards/DashboardCardsComponents/CookbooksCard/CookbooksCardRoot';
import DashboardDocsCardRoot from '../../../components/GridOfCards/DashboardCards/DashboardCardsComponents/DocsCard/DashboardDocsCardRoot';
import DashboardGoalsCardRoot from '../../../components/GridOfCards/DashboardCards/DashboardCardsComponents/GoalsCard/DashboardGoalsCardRoot';
import DashboardIdeasCardRoot from '../../../components/GridOfCards/DashboardCards/DashboardCardsComponents/IdeasCard/DashboardIdeasCardRoot';
// import NotificationCardRoot_DEPRECATED from '../../../components/GridOfCards/DashboardCards/DashboardCardsComponents/NotificationCard/NotificationCardRoot_DEPRECATED';
import DashboardPermsCardRoot from '../../../components/GridOfCards/DashboardCards/DashboardCardsComponents/DashboardPermsCard/DashboardPermsCardRoot';
import ViewReportByUser from '../../../toolsFlow/components/userReports/ViewReportByUser.jsx';
import DashboardIssuesTableWrapper from '../../../components/GridOfCards/DashboardCards/DashboardCardsComponents/IssuesCard/DashboardIssuesTableWrapper';

export const galleryItemList = [
  {
    id: 1,
    link: 'goals',
    title: 'Goals',
    icon: mdiFlag,
    component: (props) => <DashboardGoalsCardRoot {...props} />,
  },
  {
    id: 2,
    link: 'documents',
    title: 'My documents',
    icon: mdiFileDocumentCheck,
    component: (props) => <DashboardDocsCardRoot {...props} />,
  },
  {
    id: 3,
    link: 'notes',
    title: 'My notes',
    icon: mdiLightbulb,
    component: (props) => <DashboardIdeasCardRoot {...props} />,
  },
  {
    id: 4,
    link: 'issues',
    title: 'My issues',
    icon: mdiBugCheck,
    component: ({ otherUser }) => <DashboardIssuesTableWrapper otherUser={otherUser} />,
  },
  // {
  //   id: 7,
  //   link: 'notifications',
  //   title: 'Notifications',
  //   icon: mdiBell,
  //   hideForAdmin: true,
  //   component: (props) => <NotificationCardRoot_DEPRECATED {...props} />,
  // },
  {
    id: 8,
    link: 'cookbooks',
    title: 'Cookbooks',
    icon: mdiCodeJson,
    component: (props) => <CookbooksCardRoot {...props} />,
  },
  {
    id: 9,
    link: 'reports',
    title: 'My reports',
    icon: mdiTextBoxMultipleOutline,
    component: ({ currentUserPublicEntity, ...props }) => (
      <Row className="mt-3">
        <ViewReportByUser
          users={!isEmpty(currentUserPublicEntity) ? [currentUserPublicEntity] : []}
          selectedUser={!isEmpty(currentUserPublicEntity) ? [currentUserPublicEntity?.actor] : []}
          {...props}
        />
      </Row>
    ),
  },
  {
    id: 10,
    link: 'perms',
    title: 'Dashboard permissions',
    icon: mdiShieldKeyOutline,
    component: (props) => <DashboardPermsCardRoot {...props} />,
  },
];

import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'antd';
import Icon from '@mdi/react';

const InfoSection = ({
  children,
  iconColor,
  iconPath,
  title,
  titleComponent = null,
  customMargin = 'py-3',
  hoverStyleMode = false,
  hideIcon
}) => {
  const header = () => {
    if (titleComponent) {
      return (
        <div className="flex items-start">
          <span className="text-secondary">{title}</span>
          {titleComponent}
        </div>
      );
    }
    return <span className="text-secondary">{title}</span>;
  };

  return (
    <Col span={24} className={`flex items-start pl-0 ${customMargin} ${hoverStyleMode && 'text-secondary-withHover'}`}>
      {!hideIcon && (
      <Icon
        path={iconPath}
        color={iconColor}
        size={0.9}
        className="text-secondary mr-2"
        style={{ minWidth: 24 }}
      />
      )}
      <div className="w-full ">
        {header()}
        {children}
      </div>
    </Col>
  );
};

InfoSection.propTypes = {
  children: PropTypes.element,
  iconColor: PropTypes.string,
  iconPath: PropTypes.string,
  title: PropTypes.string,
  titleComponent: PropTypes.element,
  customMargin: PropTypes.string,
};

export default InfoSection;

import React from 'react';

import cn from 'classnames';

import { Col } from 'antd';

import { useExpandModeContext } from './ExpandModeLayout';

function ExpandModeLayoutCard({ children }) {
  const { isWideMode } = useExpandModeContext();

  return (
    <Col className={cn(
      'pl-3 expandCol',
      { '_expanded-100': isWideMode },
    )}
    >
      {children}
    </Col>
  );
}

export default ExpandModeLayoutCard;

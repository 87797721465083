import React from "react";
import { Flex } from 'antd';

function ListContainer({
  children,
}) {

  return (
    <Flex
      vertical
      className='relative border rounded border-neutral-200 overflow-hidden'
      flex="1 1 100%"
    >
      {children}
    </Flex>
  );
}

export default ListContainer;

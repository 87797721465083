import { EntityConstants } from '../entity/constants/Constants';
import { ProjectTypeCommonConstants } from '../projectFlow/constants/Constants';

const initialState = {
  fetching: false,
  listOfActorsFetching: true,
  message: [],
  actorsFromGroups: [],
  defaultUsers: [],
  fetchingDefaultUsers: true,
  defaultUsersAndBanedUsers: [],
  publicUsers: [],
  projectUsers: [],
  error: '',
  ActorsByName: [],
  temporaryActors: [],
  fetchingActorsName: true,
  actorsForAssign: [],
  totalActorsForAssign: null,
  fetchingActorsForAssign: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EntityConstants.FETCH_ACTORS_REQUEST:
      return {
        ...state,
        fetching: true,
        listOfActorsFetching: true,
        fetchingDefaultUsers: true,
      };
    case EntityConstants.FETCH_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        listOfActorsFetching: false,
        message: action.payload,
        defaultUsers: action.payload,
        fetchingDefaultUsers: false,
      };
    case EntityConstants.FETCH_ACTORS_AND_BANED_USERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        listOfActorsFetching: false,
        message: action.payload,
        defaultUsersAndBanedUsers: action.payload,
      };
    case EntityConstants.FETCH_ACTORS_FAILURE:
      return {
        ...state,
        fetching: false,
        listOfActorsFetching: false,
        fetchingDefaultUsers: false,
        message: [],
        actorsFromGroups: [],
        defaultUsers: [],
        publicUsers: [],
        projectUsers: [],
        error: action.payload,
      };

    case EntityConstants.FETCH_PROJECT_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        listOfActorsFetching: false,
        projectUsers: action.payload,
        actorsFromGroups: action.payload,
      };

    case EntityConstants.FETCH_TEMPORARY_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        temporaryActors: action.payload,

      };

    case EntityConstants.CLEAR_TEMPORARY_ACTORS:
      return {
        ...state,
        temporaryActors: [],
      };

    case EntityConstants.FETCH_PUBLIC_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        publicUsers: action.payload,
      };

    case ProjectTypeCommonConstants.GET_ACTORS_NAME_REQUEST:
      return {
        ...state,
        fetchingActorsName: true,
      };

    case ProjectTypeCommonConstants.GET_ACTORS_NAME_SUCCESS:
      return {
        ...state,
        fetchingActorsName: false,
        ActorsByName: action.payload,
      };

    case ProjectTypeCommonConstants.GET_ACTORS_NAME_FAILURE:
      return {
        ...state,
        fetchingActorsName: false,
      };

    case EntityConstants.CLEAR_ACTORS_FETCHING:
      return {
        ...state,
        listOfActorsFetching: true,
        fetchingActorsName: true,
      };

    case EntityConstants.CLEAR_USERS_WITH_BANED_USERS:
      return {
        ...state,
        defaultUsersAndBanedUsers: [],
      };

    case EntityConstants.SET_FETCHING_FOR_ACTORS_LIST:
      return {
        ...state,
        listOfActorsFetching: action.payload,
        fetchingActorsName: action.payload,
      };

    default: return state;
  }
};

import Icon from '@mdi/react';
import { mdiAutorenew } from '@mdi/js';
import React, { useEffect } from 'react';
import { binary_to_base58 } from 'base58-js';

import BaseButton from '../../components/_ui/BaseButton/BaseButton';

function GeneratePassBtn({
  onClickCallBack,
}) {
  const generatePass = () => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%&*ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const passwordLength = 10;
    let password = '';
    for (let i = 0; i <= passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }

    const convertToUint8Array = new TextEncoder().encode(password);
    return binary_to_base58(convertToUint8Array);
  };

  const generatePassAndCallBack = () => {
    onClickCallBack?.(generatePass());
  };

  useEffect(() => {
    generatePassAndCallBack();
  }, []);

  return (
    <BaseButton
      size="small"
      className="btnPrimary-outline"
      onClick={generatePassAndCallBack}
      icon={<Icon className="mr-1" path={mdiAutorenew} size={0.8} />}
    >
      generate pass
    </BaseButton>
  );
}

export default GeneratePassBtn;

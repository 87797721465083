import React from 'react';
import { Tooltip } from "antd";
import PropTypes from 'prop-types';
import './CustomAvatar.scss';

function CustomAvatar({ fullName, children, ...props }) {
  return (
    <Tooltip title={fullName}>
      <div
        className="user-avatar user-avatar_circle user-avatar_sm user-avatar_default"
      >
        <div className="user-avatar__initials">
          {fullName.split(' ').map((e) => e[0]?.toLocaleUpperCase()).join('')}
        </div>
      </div>
    </Tooltip>
  );
}

export default CustomAvatar;

CustomAvatar.propTypes = {
  fullName: PropTypes.string,
  children: PropTypes.node,
};

import {EntityConstants} from "../constants/Constants";

const initialState = {
  fetching: false,
  data: [],
  error: ''
};


export default (state=initialState, action) => {
  switch (action.type) {
    case EntityConstants.FETCH_ENTITY_PERMS_ALL_ACTORS_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case EntityConstants.FETCH_ENTITY_PERMS_ALL_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data:  action.payload
      };
    case EntityConstants.FETCH_ENTITY_PERMS_ALL_ACTORS_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        error:  action.payload
      };

    default: return state;
  }
};

import { useDispatch, useSelector } from 'react-redux';

import { getPartitionPMUUID } from '../../config/selectors/selectors';
import { getListOfProjectUuids } from '../../projectFlow/actions/projectFlowActions';
import { getMe } from '../../projectFlow/selectors/selectors';

export const getMyProjectsUuids = async (dispatch, PMUUID, actorUUID) => {
  const params = {
    usersSearch: [actorUUID],
    archive: false,
  };

  return dispatch(getListOfProjectUuids(PMUUID, params));
};

export const useGetMyProjects = () => {
  const dispatch = useDispatch();

  const partitionPM = useSelector(getPartitionPMUUID);
  const myUUID = useSelector(getMe);

  const getMyProjectWrapper = () => getMyProjectsUuids(dispatch, partitionPM, myUUID);

  return ([getMyProjectWrapper]);
};

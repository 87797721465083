import { Col, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbsNEW from '../../../components/breadcrumb/BreadCrumbsNEW';
import { getPartitionPMUUID, getPartitionPublicUUID } from '../../../config/selectors/selectors';
import { getMainAreaUUID } from '../../../entity/selectors/selectors';
import { capitalize } from 'lodash';
import { getCookBookEntityFromPM, getMainEntityArea } from '../../actions/projectFlowActions';
import { commonCookBookEntityUuid } from '../../selectors/selectors';
import PermissionsStartPage from './PermissionsStartPage';
import useURLParams from '../../../hooks/useURLParams';
import { partitionNamesConfig } from '../../../api/appConfig';

const { TabPane } = Tabs;

function PermissionsWrapperInRoute() {
  const { t } = useTranslation();
  const { getURLParams, setSearchParams } = useURLParams();
  const { typeEntity } = getURLParams();
  const dispatch = useDispatch();

  const partitionUuid = useSelector(getPartitionPMUUID);
  const publicPartitionUUID = useSelector(getPartitionPublicUUID);
  const mainAreaEntityUUID = useSelector(getMainAreaUUID);
  const commonCookBookEntityUUID = useSelector(commonCookBookEntityUuid);

  const [currentTab, setCurrentTab] = useState('pmpartition');

  const setTab = (tab) => {
    setSearchParams({ typeEntity: tab });
  };

  const checkEntityUuid = async () => {
    switch (currentTab) {
      case 'areas':
        if (!mainAreaEntityUUID) {
          try {
            await dispatch(getMainEntityArea(partitionUuid));
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 'cookbook':
        if (!commonCookBookEntityUUID) {
          await dispatch(getCookBookEntityFromPM(partitionUuid));
        }
        break;
      default: break;
    }
  };

  useEffect(() => {
    checkEntityUuid();
    setTab(currentTab);
  }, [currentTab]);

  return (
    <Row className="w-full mt-3">
      <Col span={24}>
        {/* <Row>
          <Col span={24} className="py-3">
            <BreadCrumbsNEW />
          </Col>
        </Row> */}
        <Row>
          <Col span={24}>
            <Tabs
              onChange={setCurrentTab}
              activeKey={typeEntity}
              type="card"
              className="pl-1"
            >
              <TabPane
                tab={"Project flow partition permissions"}
                key="pmpartition"
              >
                {currentTab === 'pmpartition'
                  && (
                    <PermissionsStartPage
                      entityUUID={partitionUuid}
                      nameSectionPermissions={"Project flow partition permissions"}
                    />
                  )}
              </TabPane>
              <TabPane
                tab={"User flow partition permissions"}
                key="publicpartition"
              >
                {currentTab === 'publicpartition'
                  && (
                    <PermissionsStartPage
                      entityUUID={publicPartitionUUID}
                      partitionType={partitionNamesConfig.partition3}
                      nameSectionPermissions={"User flow partition permissions"}
                    />
                  )}
              </TabPane>
              <TabPane
                tab={capitalize(t('wms.tabs.cookbook_permissions', 'cookbook permissions'))}
                key="cookbook"
              >
                {(currentTab === 'cookbook' && commonCookBookEntityUUID)
                  && (
                    <PermissionsStartPage
                      entityUUID={commonCookBookEntityUUID}
                      nameSectionPermissions={capitalize(
                        t('wms.tabs.cookbook_permissions', 'cookbook permissions'),
                      )}
                    />
                  )}
              </TabPane>

              <TabPane
                tab={capitalize(t('wms.tabs.areas_permissions', 'areas permissions'))}
                key="areas"
              >
                {(currentTab === 'areas' && mainAreaEntityUUID)
                  && (
                    <PermissionsStartPage
                      entityUUID={mainAreaEntityUUID}
                      nameSectionPermissions={capitalize(
                        t('wms.tabs.areas_permissions', 'areas permissions'),
                      )}
                    />
                  )}
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
export default PermissionsWrapperInRoute;

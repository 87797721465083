import React from 'react';
import "./AuthLoader.scss"

function AuthLoader() {
  return (
    <div className="spinnerAuth">
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
      <div className="rect5" />
    </div>
  );
}

export default AuthLoader;

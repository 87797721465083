import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import { getProjectUUID } from '../../selectors/selectors';
import { incrementAndGetCount } from '../../../entity/actions/entityActions';
import { antNotification, capitalizeAndTranslateMsg } from '../../../MainUtils';
import { createProjectMilestoneRequest } from '../../actions/projectMilestonesActions';
import { DELETE_PROJECT_KPM } from '../../reducers/slicers/projectsKPMSlice';
import { getNewProjectMilestoneFetching } from '../../reducers/slicers/projectMilestonesSlicer';

// import { sendCreationComment } from '../../actions/projectFlowActions';

function CreateProjectMilestoneBtn({
  createProjectMilestoneCallback,
  disableBtn,
  partition,
  className,
  isLink,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectUUID = useSelector(getProjectUUID);
  const newProjectMilestoneFetching = useSelector(getNewProjectMilestoneFetching);

  const [loading, setLoading] = useState(false);

  const createProjectMilestone = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const { increment: milestoneID } = await dispatch(incrementAndGetCount({
      entity_uuid: projectUUID,
      field_name: 'milestone',
    }, partition)) || {};

    const newMilestoneParams = {
      id: milestoneID,
      title: 'New Milestone',
      status: 'created',
      lifeTime: '',
    };

    dispatch(createProjectMilestoneRequest({
      parent: projectUUID,
      params: newMilestoneParams,
      partition,
    }))
      .then((res) => {
        antNotification('success', t('wms.noun.success', 'Success'));
        createProjectMilestoneCallback?.(res[0]?.uuid);

        dispatch(DELETE_PROJECT_KPM({ uuid: projectUUID }));
        // dispatch(sendCreationComment(
        //   res[0]?.uuid,
        //   'projectGoal',
        //   res[0]?.params.lifeTime,
        //   partition,
        // ));
      })
      .finally(setLoading(false));
  };

  return (
    <BaseButton
      className={`${className} ${!isLink ? 'btnPrimary-outline mb-1' : ''} ml-1`}
      disabled={disableBtn || newProjectMilestoneFetching}
      onClick={createProjectMilestone}
      type={isLink ? 'link' : null}
      size="small"
      id="create_milestone_btn"
    >
      {capitalizeAndTranslateMsg('wms.verb.create', 'create')}
      {' '}
      milestone
    </BaseButton>
  );
}

export default CreateProjectMilestoneBtn;

CreateProjectMilestoneBtn.propTypes = {
  className: PropTypes.string,
  createProjectMilestoneCallback: PropTypes.func,
  disableBtn: PropTypes.bool,
  partition: PropTypes.string,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import BtnModal from '../../BtnModalComponent';
import TestCycleForm from './TestCycleForm';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

import { capitalize } from 'lodash';
import ImportTestCaseModal from '../case/ImportTestCaseModal';
import { Tabs } from 'antd';

function NewTestCycleWithImport({
  hideBtnComponent = false,
  helper = false,
  isCyclesListEmpty=false,
  selectTestCycleCallback,
}) {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const [openFromOuterComponent, changeImportTestCaseModal] = useState(false);
  const [testCycle, setTestCycle] = useState(null);

  const importListOfTestCasesCallback = (data) => {
    changeImportTestCaseModal(true);
    setTestCycle(data);
  };

  const handleCancelCallbackFunc = () => {
    changeImportTestCaseModal(false);
    setTestCycle(null);
  };

  return (
    <>
      <ImportTestCaseModal
        typeOfParent="cycle"
        hideBtnComponent={hideBtnComponent}
        openFromOuterComponent={openFromOuterComponent}
        parentData={testCycle}
        UUIDFromOuterComponent={testCycle?.uuid}
        importTestCaseCallback={handleCancelCallbackFunc}
        changeImportTestCaseModal={changeImportTestCaseModal}
        handleCancelCallbackFunc={handleCancelCallbackFunc}
      />

      <BtnModal
        footer={[]}
        title={capitalize(t('wms.modals.headers.new_test_cycle', 'new test cycle'))}
        width="40%"
        // confirmOnClose
        // confirmDescription={capitalize(
        //   t('wms.modals.body.close_modal_new_issue', 'the entered data will be lost, are you sure?'),
        // )}
        btnComponent={(
          <div>
            {(!helper && !isCyclesListEmpty) && (
              <BaseButton
                className="btnPrimary-outline"
                size="small"
              >
                {capitalize(t('wms.buttons.new.test_cycle', 'add cycle'))}
              </BaseButton>
            )}

            {(helper && !isCyclesListEmpty) && (
              <div style={{ color: '#1890ff', cursor: 'pointer' }}>
                {capitalize(t('wms.buttons.new.test_cycle', 'add cycle'))}
              </div>
            )}

            {isCyclesListEmpty && 
            <BaseButton
              className="btnPrimary-outline w-[200px] h-[40px] text-[20px] font-bold" 
            >
            {capitalize(t('wms.buttons.new.test_cycle', 'add cycle'))}
          </BaseButton>
            }

          </div>
        )}
      >
        <TestCycleForm
          typeOfForm="newForm"
          importListOfTestCasesCallback={importListOfTestCasesCallback}
          selectTestCycleCallback={selectTestCycleCallback}
        />
      </BtnModal>
    </>
  );
}
const NewTestCycleWithImportTestCases = React.memo(NewTestCycleWithImport);

export default NewTestCycleWithImportTestCases;

NewTestCycleWithImport.propTypes = {
  hideBtnComponent: PropTypes.bool,
  helper: PropTypes.bool,
  selectTestCycleCallback: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import SheetHeaderHook from './SheetHeaderHook';

function SheetRendererComponent({
  className,
  children,
  columns,
  onColumnDrop,
  t,
  template,
  type,
  customStyleTable,
}) {
  return (
    <table className={`${customStyleTable} ${className}`}>
      <thead className="sheet-table-t-head">
        <tr className="sheet-table-tr">
          {/* <th className="cell read-only row-handle" /> */}

          {columns.map((col, index) => (
            <SheetHeaderHook
              key={col.label}
              col={col}
              columnIndex={index}
              onColumnDrop={onColumnDrop}
              t={t}
              template={template}
              type={type}
            />
          ))}
        </tr>
      </thead>

      <tbody>{children}</tbody>
    </table>
  );
}

SheetRendererComponent.propTypes = {
  children: PropTypes.any,
  template: PropTypes.bool,
  t: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.any,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    }),
  ),
  onColumnDrop: PropTypes.any,
  customStyleTable: PropTypes.string,
};
const SheetRenderer = React.memo(SheetRendererComponent);
export default SheetRenderer;

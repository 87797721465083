import { head } from 'lodash';
import {
  object,
  string,
  bool,
  func,
} from 'prop-types';
import { prop, propOr } from 'ramda';
import React, { useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { entityRead } from '../../../entity/actions/entityActions';
import { getDocumentStateRequest, setDocumentContent } from '../../../projectFlow/actions/DocumentsActions';
import buildContent from '../../../projectFlow/components/docView&ideas/helpers/statesAndPatches/buildContent';
import { getStateID } from '../../../projectFlow/components/docView&ideas/helpers/statesAndPatches/idHelpers';
import { ProjectTypeCommonConstants } from '../../../projectFlow/constants/Constants';

import { getDocumentContent } from '../../../projectFlow/selectors/selectors';

import DocView from '../../../projectFlow/components/docView&ideas/docView/DocView';
import CustomCardComponent from '../../../projectFlow/components/commonComponents/CustomCardComponent';

export default function DocWraperForBookmark({
  currentSelected,
  defaultPartition,
  showEditor,
  setShowEditor,
}) {
  const dispatch = useDispatch();
  const documentContent = useSelector(getDocumentContent);

  const [doc, setDoc] = useState({});
  const [openComments, setOpenComments] = useState(false);

  const docParams = prop('params', doc);
  const hasOptionalData = propOr(false, 'optional_data', doc);
  const docLoading = !showEditor;

  const getOneDocument = (uuid, currentEntityType) => {
    const data = {
      entity_type: currentEntityType,
      entity_uuid: uuid,
    };

    const constants = [
      ProjectTypeCommonConstants.GET_DOCUMENT_REQUEST,
      ProjectTypeCommonConstants.GET_DOCUMENT_SUCCESS,
      ProjectTypeCommonConstants.GET_DOCUMENT_FAILURE,
    ];

    const options = {
      partition: defaultPartition,
    };

    return entityRead({
      data,
      constants,
      options,
    });
  };

  const getDocumentState = async (uuid, stateID) => {
    const stateConfig = {
      uuid,
      stateID,
      partition: defaultPartition,
    };

    // console.log('stateID for read:', stateID);

    if (stateID && stateID !== '0') {
      return dispatch(getDocumentStateRequest(stateConfig));
    }

    return null;
  };

  const initFunc = async () => {
     
    const document = await dispatch(getOneDocument(currentSelected?.uuid, currentSelected?.entity_type));

    const isExistOptionalData = propOr(false, 'optional_data', head(document));

    if (isExistOptionalData) {
      // console.log('HAS OPTIONAL DATA');
      const documentParams = propOr('', 'params', head(document));
      const currentActualState = prop('actualState', documentParams);
      const stateID = getStateID(currentActualState);
      const type = prop('entity_type', head(document));

      // console.log('ACTUAL STATE:', currentActualState);

      const state = await getDocumentState(currentSelected?.uuid, stateID);

      const { text, patches } = state;
      const [buildedContent] = buildContent(text, patches, type);
      // console.log('Builded Content:', buildedContent);
      dispatch(setDocumentContent({startHtmlString: text,patches}));
      // dispatch(setDocumentContent(buildedContent));
    } else dispatch(setDocumentContent(null));

    setDoc(head(document));
  };

  useLayoutEffect(() => {
    if (currentSelected?.uuid) {
      initFunc().then(() => setShowEditor(true));
    }
    return () => dispatch({ type: ProjectTypeCommonConstants.CLEAR_PROJECT_DOCUMENTS });
  }, [currentSelected?.uuid]);

  return (
    <CustomCardComponent
      id="document"
      type="document"
      params={{
        closeBtns: true,
        isDisabled: true,
      }}
    >
      <DocView
        actualState={prop('actualState', docParams)}
        closeBtns
        CKEditorReadOnly
        defaultPartition={defaultPartition}
        docFetching={docLoading}
        description={hasOptionalData ? documentContent : prop('text', docParams)}
        disableData
        disableInputs
        disableEntityChange
        entityDocType={prop('entity_type', doc)}
        hideElements={['options']}
        isDisabled
        isSidePanelOpen={openComments}
        lifeTime={prop('lifeTime', doc)}
        readOnlySpreadsheet
        showEditor={showEditor}
        title={prop('title', doc)}
        setIsSidePanelOpen={setOpenComments}
      />
    </CustomCardComponent>
  );
}

DocWraperForBookmark.propTypes = {
  currentSelected: object,
  defaultPartition: string,
  showEditor: bool,
  setShowEditor: func,
};

/* eslint-disable import/prefer-default-export */
import {
  useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import useUserPublicPartition from '../../../../hooks/useUserPublicPartition';
import { getUserPublicProfileUUID } from '../../../store/selectors/selectors';
import {
  getListAndPartialReadEntities,
  entityCreate,
} from '../../../../entity/actions/entityActions';
import {
  GET_PLANNING_VECTOR_DAYS_FAILURE,
  GET_PLANNING_VECTOR_DAYS_REQUEST,
  GET_PLANNING_VECTOR_DAYS_SUCCESS,
} from '../../../store/reducers/slices/planingSlice';
import { partitionNamesConfig } from '../../../../api/appConfig';

export const useGetActivitiesDays = ({
  startDate,
  endDate,
  actorUuid,
  createDayIfNull = false,
}) => {
  const dispatch = useDispatch();
  // const [{ userUUIDFromUrl }] = useProjectURL();

  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const finalPublicUserUUID = actorUuid || myPublicUUID;

  const { projectUserReportsUUID } = useUserPublicPartition(finalPublicUserUUID, 'projectUserReportsUUID');

  const [daysActivity, setDaysActivity] = useState([]);
  const [initLoading, setInitLoading] = useState(true);

  // console.log('********  daysActivity *********', daysActivity);

  const createDayActivity = (day) => {
    const config = {
      data: {
        entity_type: 'vector',
        parent: projectUserReportsUUID,
        params: {
          title: `Day(${moment(day).format('DD-MM-YYYY')})`,
          date: moment(day).format('YYYY-MM-DD'),
          status: 'created',
        },
      },
      constants: ['CREATE_DAY_REQUEST', 'CREATE_DAY_SUCCESS', 'CREATE_DAY_FAILURE'],
      options: { partition: 'PUBLIC' },
    };
    return dispatch(entityCreate(config));
  };

  const defaultTemplateWeek = (days) => {
    const week = [];
    for (let i = 0; i < days; i += 1) {
      week.push({
        date: moment(startDate, moment.defaultFormat)
          .add(i, 'days')
          .format('YYYY-MM-DD'),
        uuid: '',
      });
    }
    return week;
  };
  const getDaysActivity = () => {
    const config = {
      data: {
        entity_type: 'vector',
        parent: projectUserReportsUUID,
        params: {
          date__gte: moment(startDate).format('YYYY-MM-DD'),
          date__lte: moment(endDate).format('YYYY-MM-DD'),
        },
        params_fields: { date: 'date' },
      },
      constants: [
        GET_PLANNING_VECTOR_DAYS_REQUEST,
        GET_PLANNING_VECTOR_DAYS_SUCCESS,
        GET_PLANNING_VECTOR_DAYS_FAILURE,
      ],
      partition: partitionNamesConfig.partition3,
    };
    return dispatch(getListAndPartialReadEntities(config));
  };

  const initFunc = async () => {
    // console.log('initFunc initFunc');
    setInitLoading(true);
    try {
      const diffDay = moment(endDate).diff(moment(startDate), 'day') + 1;
      const wholeWeek = defaultTemplateWeek(diffDay);

      const { data = [] } = await getDaysActivity();

      let days = [];

      for (let i = 0; i < wholeWeek.length; i += 1) {
        const isDayExists = data.find((p) => p.date === wholeWeek[i]?.date);
        let day = wholeWeek[i];

        if (isDayExists) {
          day = isDayExists;
        }

        if (!isDayExists && createDayIfNull) {
          day = createDayActivity(day.date);
        }

        days.push(day);
      }

      if (createDayIfNull) {
        days = await Promise.all(days);
        days = days.flat();
        days = days.map((p) => (p.params ? { uuid: p.uuid, date: p.params.date } : p));
      }
      setDaysActivity(days);
      setInitLoading(false);
    } catch {
      setInitLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate && projectUserReportsUUID) {
      initFunc();
    }
  }, [startDate, endDate, projectUserReportsUUID]);

  const data = useMemo(() => (
    { initLoading, daysActivity, projectUserReportsUUID }
  ), [initLoading, JSON.stringify(daysActivity)]);

  return data;
};

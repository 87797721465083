import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Col, Input, Row, Tooltip,
} from 'antd';
import Icon from '@mdi/react';
import {
  mdiArrowULeftTop, mdiBroom, mdiCached, mdiCheckBold, mdiTrashCanOutline,
} from '@mdi/js';
import { useSelector } from 'react-redux';

import useGptRequestsHook from './hooks/useGptRequestsHook';
import ButtonWithPopConfirmAntDesign54origins
  from '../54origins/components/ButtonWithPopConfirmAntDesign54origins';
import { getMe } from '../projectFlow/selectors/selectors';
import { checkStringIsEmpty } from '../54origins/utils54origins';

import BaseButton from '../components/_ui/BaseButton/BaseButton';

const btnContextText = 'Очистка контекста может быть полезна в случаях, когда вы хотите начать '
  + 'генерацию текста с нового контекста или обновить контекст для следующего предсказания. '
  + 'Очищение контекста позволяет модели "забыть" предыдущий текст и начать с чистого листа.';

function NewOrEditChatForm({
  clearContext,
  defaultTitle,
  isNewMode,
  onRefreshCallBack,
  onRevertCallBack,
  removeChatCallback,
  selectedChat,
  sendNewChatCallback,
  sendTitleOfChat,
  onChangeTitleCallback,
}) {
  const {
    requestCreateNewChatForUserOnGPT,
    requestDeleteChatForUserOnGPT,
  } = useGptRequestsHook();

  const actorUUID = useSelector(getMe);

  const [titleOfChat, setTitleOfChat] = useState(defaultTitle);
  const [isEdit, setIsEdit] = useState(false);

  const {
    title = '',
    id: chatId,
  } = selectedChat || {};

  const sendNewChat = () => {
    requestCreateNewChatForUserOnGPT(titleOfChat).then((res) => {
      sendNewChatCallback?.(res);
    });
  };

  const removeChat = async () => {
    await requestDeleteChatForUserOnGPT(actorUUID, chatId);

    removeChatCallback?.();
  };

  const onChange = (e) => {
    setTitleOfChat(e?.target?.value);
    setIsEdit(true);

    onChangeTitleCallback?.(e?.target?.value);
  };

  const onSuccess = () => {
    setIsEdit(false);

    if (isNewMode) {
      sendNewChat();
    } else {
      sendTitleOfChat(titleOfChat);
    }
  };

  const onRevert = () => {
    if (!isNewMode) {
      setIsEdit(false);
      setTitleOfChat(title);
    }

    onRevertCallBack?.();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !checkStringIsEmpty(titleOfChat)) {
      onSuccess();
    }
  };

  useEffect(() => {
    setTitleOfChat(title);
  }, [title]);

  useEffect(() => {
    if (defaultTitle) {
      setTitleOfChat(defaultTitle);
    }
  }, [defaultTitle]);

  return (
    <Row
      className="chat-gpt-new-chat"
    >
      <Col className="flex" span={24}>
        <Input
          autoFocus
          onChange={onChange}
          placeholder={title ? `Edit chat: ${title}` : 'Enter a new chat title'}
          value={titleOfChat}
          maxLength={50}
          onKeyPress={handleKeyPress}
        />
        {(isEdit || isNewMode) ? (
          <>
            <BaseButton
              className="p-1 ml-1 btnWarning"
              onClick={onRevert}
            >
              <Icon path={mdiArrowULeftTop} size={0.9} />
            </BaseButton>
            <BaseButton
              className="p-1 mx-1 btnGreen"
              onClick={onSuccess}
              disabled={checkStringIsEmpty(titleOfChat)}
            >
              <Icon path={mdiCheckBold} size={0.9} />
            </BaseButton>
          </>
        ) : (
          <>
            <Tooltip title="Обновить сообщения чата">
              <BaseButton
                className="ml-1 p-1"
                type="primary"
                onClick={onRefreshCallBack}
                disabled={checkStringIsEmpty(titleOfChat)}
              >
                <Icon path={mdiCached} size={0.9} />
              </BaseButton>
            </Tooltip>
            <Tooltip title={btnContextText}>
              <ButtonWithPopConfirmAntDesign54origins
                onClick={clearContext}
                type="primary"
                className="ml-1 p-1"
                label={(
                  <Tooltip title={btnContextText}>
                    <Icon
                      size={0.8}
                      path={mdiBroom}
                    />
                  </Tooltip>
                )}
                popConfirmTitle="Are you sure?"
                popConfirmPlacement="left"
              />
            </Tooltip>
            <ButtonWithPopConfirmAntDesign54origins
              onClick={removeChat}
              type="primary"
              danger
              className="ml-1"
              pathForMdi={mdiTrashCanOutline}
              sizeMdi={0.8}
              popConfirmTitle="Are you sure?"
              popConfirmPlacement="topLeft"
            />
          </>
        )}
      </Col>
    </Row>
  );
}

export default NewOrEditChatForm;

NewOrEditChatForm.propTypes = {
  clearContext: PropTypes.func,
  isNewMode: PropTypes.bool,
  onRevertCallBack: PropTypes.func,
  removeChatCallback: PropTypes.func,
  selectedChat: PropTypes.any,
  sendNewChatCallback: PropTypes.func,
  onRefreshCallBack: PropTypes.func,
  sendTitleOfChat: PropTypes.func,
  onChangeTitleCallback: PropTypes.func,
  defaultTitle: PropTypes.string,
};

import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { get, head } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { getRootEntityPartition } from '../../../config/selectors/selectors';
import { getFavoriteUUID } from '../../../userFlow/store/selectors/selectors';
import { entityPartialRead } from '../../../entity/actions/entityActions';
import { updateFavoriteRequest } from '../../actions/projectFlowActions';
import AddProjectToFavorite from '../AddProjectToFavorite';
import BtnModal from '../BtnModalComponent';
import UpdateListFavoriteModal from './UpdateListFavoriteModal';
import { partitionNamesConfig } from '../../../api/appConfig';

export default function WrapperAddToFavorite({
  entityUUID,
  entityType = 'project',
  entityPartition,
  partitionType,
  onChangeFavoriteCallback,
}) {
  const dispatch = useDispatch();

  const favoriteSettingsUUID = useSelector(getFavoriteUUID);
  const partitionUUID = useSelector((state) => getRootEntityPartition(state, partitionType));

  const [dataForModalFavorite, setDataForModalFavorite] = useState(null);

  const handleCancelModal = () => setDataForModalFavorite(null);

  const getFavorite = () => {
    const data = {
      entity_type: 'favoriteSettings',
      entity_uuid: favoriteSettingsUUID,
      params_fields: {
        projects: 'projects',
        publicProjects: 'publicProjects',
        favorites: 'favorites',
      },
    };

    const constants = [
      'GET_FAVORITE_PROJECTS_REQUEST',
      'GET_FAVORITE_PROJECTS_SUCCESS',
      'GET_FAVORITE_PROJECTS_FAILURE',
    ];

    const options = {
      partition: partitionNamesConfig.partition2,
    };

    return dispatch(entityPartialRead({
      data,
      constants,
      options,
    }));
  };

  const updateFavorite = async (params) => {
    const favoriteParams = {
      entity_type: 'favoriteSettings',
      entity_uuid: favoriteSettingsUUID,
      params,
    };
    // console.log('isFavorite',isFavorite, 'partition',partition, 'favoriteParams',favoriteParams)
    await dispatch(updateFavoriteRequest(favoriteParams));
  };

  const addToFavoriteCallback = async (e, isFavorite) => {
    e.stopPropagation();
    // console.log('selectedUUID', entityUUID);

    const res = await getFavorite();
    const { favorites = [] } = head(res) || {};

    if (!isFavorite && favorites.length >= 15) {
      // TO DO???
      setDataForModalFavorite(res);
      return;
    }

    const updatedFavorites = isFavorite
      ? favorites.filter((favObj) => favObj?.uuid !== entityUUID)
      : [
        ...favorites,
        {
          uuid: entityUUID,
          partition: entityPartition,
          entityType,
        },
      ];

    const favoriteParams = {
      favorites: updatedFavorites,
    };

    await updateFavorite(favoriteParams);
    onChangeFavoriteCallback?.(isFavorite ? 'delete' : 'add', entityUUID);
  };

  return (
    <>
      <AddProjectToFavorite
        className="ml-1"
        projectUUID={entityUUID}
        addToFavoriteCallback={addToFavoriteCallback}
      />

      <BtnModal
        width="50%"
        title="Favorite projects"
        openFromOuterComponent={dataForModalFavorite}
        handleCancelCallbackFunc={handleCancelModal}
      >
        <UpdateListFavoriteModal
          dataFavorite={dataForModalFavorite}
          partition={partitionType}
          partitionUUID={partitionUUID}
          updateFavorite={updateFavorite}
        />
      </BtnModal>
    </>
  );
}

WrapperAddToFavorite.propTypes = {
  entityUUID: string,
  entityPartition: string,
  entityType: string,
  partitionType: string,
  onChangeFavoriteCallback: func,
};

import { Menu } from "antd";
import React from "react";
import PropTypes from "prop-types";
import './BaseMenu.scss';

function BaseMenu({inlineIndent, onClick, theme, mode, selectedKey, menuItems, defaultOpenKeys, ...props}) {
  return (
    <div className="base-menu">
      <Menu
        inlineIndent={inlineIndent}
        onClick={onClick}
        theme={theme}
        mode={mode}
        selectedKeys={selectedKey}
        items={menuItems}
        defaultOpenKeys={defaultOpenKeys}
        {...props}
      />
    </div>
  )
}

export default BaseMenu;

BaseMenu.propTypes = {
  inlineIndent: PropTypes.number,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  mode: PropTypes.string,
  selectedKeys: PropTypes.array,
  menuItems: PropTypes.array,
  defaultOpenKeys: PropTypes.array,
};


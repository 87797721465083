import { Modal } from 'antd';
import PropTypes, {
    bool, func,
    string
  } from 'prop-types';
import { head, isEmpty } from "ramda";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';
import { t } from 'i18next';
import { listOfRecurringIssuesFromStore } from '../../../reducers/recurringIssues/RecurringIssueSlicer';
import useURLParams from '../../../../hooks/useURLParams';
import { getProjectUUID } from '../../../selectors/selectors';
import useTableFilter from '../../../../components/_ui/hooks/useTableFilter';
import { defaultIssuesPagination, defaultOrderRules } from '../../issuesView/utils';
import { ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import { entityCreate, incrementAndGetCount } from '../../../../entity/actions/entityActions';
import { partitionNamesConfig } from '../../../../api/appConfig';
import { sendNotification } from '../../../../api/notificationsAPI';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { antNotification } from '../../../../MainUtils';
import ListOfIssuesAntDesignTable from '../../issuesView/ListOfIssuesAntDesignTable';
import { defaultIssueFilter } from '../../issuesView/constants/issueFilterOptions';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import { columnsFilterConfig } from '../../qaView/constants/qaFilterOptions';
import useMyRecurringIssues from '../hooks/useMyRecurringIssues';
import ListOfRecurringIssuesAntDesignTable from './ListOfRecurringIssuesAntDisignTable';
  
  function CollectRecurringIssuesModal({
    isModalVisible,
    setIsModalVisible,
    partitionType,

    isGlobalDisabled,
    onReloadSuggestedIssues,
    // countOfData,
    // activeIssue,
    // newCurrentPage,
    // getIssuesRequestSetting,
    // filterConfig,
    // filterCallback
  }) {
    const recurringIssues = useSelector(listOfRecurringIssuesFromStore);
    
    const recurringIssuesData = recurringIssues.map((issue) => {
      return {...issue.params, uuid: issue.uuid, key: issue.uuid}
    });

    const dispatch = useDispatch();
    
    const { getURLParams } = useURLParams();
    const { activeProject } = getURLParams() || {};
    const [loading, setLoading] = useState(true);

    const { requestCreateIssueFromRecurring } = useMyRecurringIssues()
    const [selectedRecurringIssues, setSelectedRecurringIssues] = useState([])
    const [limit, setLimit] = useState(25);
    const [countOfData, setCountOfData] = useState(0)
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [isCheckable, setIsCheckable] = useState(true);
  
    const projectUUID = useSelector(getProjectUUID);
    const partition = null;

    const {
      filterConfig: recurringIssueFilterConfig,
      changeFilterConfig: changeRecurringIssueFilterConfig,
    } = useTableFilter('recurring', defaultIssueFilter);

    const [recurringIssuesConfig, setRecurringIssuesConfig] = useState({
      ...defaultOrderRules,
      ...defaultIssuesPagination,
      ...recurringIssueFilterConfig,
    });

    const [totalCountRecurringIssues, setTotalCountRecurringIssues] = useState(0);
    const [configIsChanged, setConfigIsChanged] = useState(null);

    const { currentPage: currentRecurringIssuesPage } = recurringIssuesConfig;

    const abort = useRef();

    const { requestGetListOfRecurringIssues } = useMyRecurringIssues();    

    async function onChangeCallback() {
      setLoading(true);
      const getSelectedRecurringIssues = selectedRecurringIssues.map((uuid) => {
        return recurringIssues.find((issue) => issue.uuid == uuid)
      })
      
      getSelectedRecurringIssues.forEach(issue => {
        requestCreateIssueFromRecurring(issue, partitionType, partition)
      });

      setSelectedRecurringIssues([])

      //updateIssuesScoringData(res?.params?.priority, res?.params?.status);

      antNotification(
        "success",
        t(
          "notifications.text.success.new_issue_created",
          "New issue has been successfully added"
        )
      );

      // if (createIssueCallback) {
      //   createIssueCallback(res?.uuid, res);
      // }
    }
    
    function handleCreate() {
      if (!isEmpty(selectedRecurringIssues)) {
        onChangeCallback();
      }
      setIsModalVisible(false);
    }

    //отвечает за создание таски на экране

    // const newRecurringIssueSuccessCallback = (uuid) => {
    //   onChangeIssue(uuid);
    //   getIssues();
    // };

    const compareTwoConfigsAndSaveTypeOfChanges = (currentConfig, newConfig, typeOfChanges) => {
      const inputDataKeys = Object.keys(newConfig);
  
      for (let i = 0; i < inputDataKeys.length; i++) {
        const currentKey = inputDataKeys[i];
  
        if (newConfig[currentKey] !== currentConfig[currentKey]) {
          // console.log('CHANGED:', currentKey);
          setConfigIsChanged(typeOfChanges);
          break;
        }
      }
    };

    const saveIssuesConfig = (data) => {
      setRecurringIssuesConfig(
        (prevState) => ({
          ...prevState,
          ...data,
        }),
      );
  
      compareTwoConfigsAndSaveTypeOfChanges(recurringIssuesConfig, data, 'recurring');
    };

    const initFunc = async () => {
      requestGetListOfRecurringIssues(recurringIssuesConfig, setTotalCountRecurringIssues, abort);
    }

    useEffect(() => {
      saveIssuesConfig({ currentPage: 1, offset: 0 });
    }, [recurringIssueFilterConfig]);

    useEffect(() => {
      if (configIsChanged === 'recurring') requestGetListOfRecurringIssues(recurringIssuesConfig, setTotalCountRecurringIssues, abort);
      setConfigIsChanged(null);
    }, [configIsChanged]);

    useEffect(() => {
      initFunc();
    }, [isModalVisible]);

    return (
      <>
        <BaseButton
          id="computeRecurringIssuesButton"
          size="small"
          className="ml-2 border border-green-500 text-green-500"
          onClick={() => {setIsModalVisible(true)}}
          type="primary"
          ghost
          disabled={isGlobalDisabled}
        >
          {capitalize(t('wms.buttons.collect_recurring_issues'))}
        </BaseButton>
        <Modal
          visible={isModalVisible}
          title="Create issues from recurring"
          okText="Ok"
          onCancel={() => {
            setIsModalVisible(false);
          }}
          onOk={handleCreate}
          width={1200}
        >
          <ListOfRecurringIssuesAntDesignTable
            data={recurringIssuesData}
            limit={limit}
            totalCount={countOfData}
            selectedIssues={1} //посмотреть в планах
            newCurrentPage={1} //назначить самому
            setLimit={setLimit}
            getIssuesRequestSetting={() => {}}
            filterColumnsConfig={columnsFilterConfig}
            filterConfig={recurringIssueFilterConfig}
            filterCallback={changeRecurringIssueFilterConfig}

            // onChangeRowCallback={}

            selectedRecurringIssues={selectedRecurringIssues}
            setSelectedRecurringIssues={setSelectedRecurringIssues}
            isCollecting={true}
          />
        </Modal>
      </>
     
    );
  }
  
  export default CollectRecurringIssuesModal;

  CollectRecurringIssuesModal.propTypes = {
    isModalVisible: bool,
    setIsModalVisible: func,
    isGlobalDisabled: bool,
    partitionType: string,

    countOfData: PropTypes.number,
    getIssuesRequestSetting: PropTypes.any,
    activeIssue: PropTypes.any,
    newCurrentPage: PropTypes.number,
    filterConfig: PropTypes.object,
    filterCallback: PropTypes.func,
    onReloadSuggestedIssues: PropTypes.func,
  };
  
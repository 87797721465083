import React, { useContext } from 'react';
import {
  isEmpty, prop,
} from 'ramda';
import {
  mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import '../style/PermissionsStyle.scss';
import { PropTypes } from 'prop-types';

import { AutoSizer, List } from 'react-virtualized';
import {
  Col, Flex, Row, Tooltip,
} from 'antd';
import SwitchComponent from './SwitchComponent';
import { PermissionsContext } from '../../context/ProjectFlowListOfContexts';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import { actorIsRoot } from '../../../54origins/utils54origins';

function ListPermissions({
  removePermCallback,
  showButtonShowMore = false,
  onShowMoreCallback,
  wrapperStyle = { minHeight: 70 },
  dataTable,
  title,
  changeSwitchCallback,
  selectRow = null,
  rowEvents,
  globalDisabled,
  resetSwitchBool,
  onSelectRowCallback,
  hiddenData,
  cardName,
  expandRow,
  customId = 'actor_uuid',
  // headerClasses,
}) {
  const {
    getName,
  } = useContext(PermissionsContext);

  // const classesRow = cardName === 'expandCard' ? 'row-perm-expand-table' : 'row-perm';
  const classesRow = '';

  const rowOfSwitches = (col, row) => (
    <SwitchComponent
      item={row}
      globalDisabled={globalDisabled}
      changeSwitchCallback={changeSwitchCallback}
      resetSwitchBool={resetSwitchBool}
      cardName={cardName}
    />
  );

  const formatType = (col, row) => {
    const root = actorIsRoot(row);
    const admin = prop('is_admin', row);
    const group = col === 'group';
    // const user = col === 'classic_user' || col === 'user';
    const type = root ? 'root' : admin ? 'admin' : group ? 'group' : 'user';
    return (
      <EntityParamTag
        value={type}
        type="user"
      />
    );
  };

  const deleteButton = (col, row) => {
    if (
      // prop('perms_inherited', row) === false
      (prop('actor_type', row) === 'group' && prop('perm_uuid', row))
      || (prop('perm_uuid', row))
    ) {
      return (
        <div className="flex justify-center" onClick={(e) => e.stopPropagation()}>
          <Icon
            path={mdiTrashCanOutline}
            size={0.9}
            onClick={() => removePermCallback(row)}
            className="row-perm-delete "
          />
        </div>
      );
    }
  };

  const formatName = (col, row) => {
    const fullName = getName(row);
    if (fullName.length > 30) {
      return (
        <Tooltip
          title={fullName}
        >
          <div className="name-container pl-1">
            <div className="name-container__text">
              {fullName.slice(0, 30)}
              ...
            </div>
          </div>
        </Tooltip>
      );
    }
    return (
      <div className="name-container pl-1">
        <div className="name-container__text">{fullName}</div>
      </div>
    );
  };

  const formatEntityName = (col, row) => {
    const actor = prop('actor_type', row) === 'classic_user' ? 'user' : prop('actor_type', row);
    const entity = prop('entity_type', row) === 'project' ? 'current entity' : prop('entity_type', row);
    return (
      <div className="name-container pl-1">
        <span className="name-container__text">{`${actor} perm from ${entity}`}</span>
      </div>
    );
  };

  // const columns = [
  //   {
  //     dataIndex: 'p',
  //     title: 'Entity Name',
  //     render: formatEntityName,
  //     className: `${classesRow} ${(hiddenData && hiddenData.includes('columnEntityName')) && 'hidden'}`,
  //   },
  //   {
  //     dataIndex: 'a',
  //     title: 'Actor Name',
  //     render: formatName,
  //     className: `${classesRow} ${(hiddenData && hiddenData.includes('columnName')) && 'hidden'}`,
  //   },
  //   {
  //     dataIndex: 'actor_type',
  //     title: 'Type',
  //     render: formatType,
  //     className: `${classesRow} ${(hiddenData && hiddenData.includes('columnType')) && 'hidden'}`,
  //   },
  //   {
  //     dataIndex: 'perm',
  //     title: 'Permissions',
  //     className: classesRow,
  //     render: rowOfSwitches,
  //   },
  //   {
  //     dataIndex: 'actor_uuid',
  //     text: '',
  //     render: deleteButton,
  //     className: `${classesRow} ${(hiddenData && hiddenData.includes('columnDeletePerm')) && 'hidden'}`,
  //   },
  // ];

  const rowRenderer = ({
    index, style, key,
  }) => {
    const row = dataTable[index];

    const className = selectRow?.selectedRowKeys?.includes?.(row?.[customId]) ? selectRow.classes : '';
    const isButtonShowMore = showButtonShowMore && index === dataTable.length;

    return (
      <>
        <Flex
          key={key}
          style={{
            ...style,
            height: isButtonShowMore ? '40px' : '70px',
            cursor: 'pointer',
            background: isButtonShowMore && '#e6f4ff',
            color: isButtonShowMore && 'rgb(16, 142, 233)',
            marginBottom: isButtonShowMore && -30
          }}
          className={`w-full ${className}`}
          align="center"
          justify={isButtonShowMore ? 'center' : "space-between"}
          onClick={() => {
            if (row) {
              onSelectRowCallback?.(row)
            } else if (isButtonShowMore && onShowMoreCallback) {
              onShowMoreCallback?.();
            }
          }}
        >
          {row && <>
            <Flex
              className={`${classesRow} ${(hiddenData && hiddenData.includes('columnEntityName')) && 'hidden'}`}
            >
              {formatEntityName({}, row)}
            </Flex>
            <Flex
              className={`${classesRow} ${(hiddenData && hiddenData.includes('columnName')) && 'hidden'}`}
            >
              {formatName({}, row)}
            </Flex>
            <Flex
              className={`${classesRow} ${(hiddenData && hiddenData.includes('columnType')) && 'hidden'}`}
            >
              {formatType(row?.actor_type, row)}
            </Flex>
            <Flex
              className={`${classesRow} mb-3`}
            >
              {rowOfSwitches({}, row)}
            </Flex>
            <Flex
              className={`${classesRow} ${(hiddenData && hiddenData.includes('columnDeletePerm')) && 'hidden'}`}
            >
              {deleteButton({}, row)}
            </Flex>
          </>}
          {!row && isButtonShowMore && 'Show more permissions'}
        </Flex>
      </>
    );
  };

  const getRowHeight = ({ index }) => {
    if (dataTable.length === index) {
      return 40
    }
    return 70
  }

  const getHeight = () => {
    if (dataTable.length < 7) {
      return 70 * dataTable.length;
    }
    return 450;
  };

  return !isEmpty(dataTable)
    && (
      <div
        className="w-full h-full mb-1"
        style={wrapperStyle}
      >
        {title
          && (
            // <h6 className="text-center mt-4 gray-text">
            //   {title}
            //   <Badge
            //     className="color-blue ml-1"
            //     count={dataTable.length}
            //   />
            // </h6>
            <div className="header_table_title">{title}</div>
          )}
        <Row
          style={{
            height: getHeight(),
          }}
        >
          <AutoSizer className="h-full">
            {({ width, height }) => (
              <List
                height={height}
                rowHeight={getRowHeight}
                width={width}
                rowCount={showButtonShowMore ? dataTable?.length + 1 : dataTable?.length || 0}
                rowRenderer={rowRenderer}
                overscanRowCount={10}
              />
            )}
          </AutoSizer>
        </Row>
        {/* <BaseTableWithPagination
        onRow={rowEvents}
        rowSelection={selectRow}
        rowClassName={() => 'row-table-in-permissions'}
        rowKey={customId || 'actor_uuid'}
        showHeader={false}
        expandable={expandRow}
        columns={columns}
        data={dataTable}
        hidePaginationOnSinglePage
        disablePagination
      /> */}
      </div>
    );
}

ListPermissions.propTypes = {
  hiddenData: PropTypes.any,
  removePermCallback: PropTypes.func,
  dataTable: PropTypes.any,
  title: PropTypes.string,
  changeSwitchCallback: PropTypes.func,
  selectRow: PropTypes.object,
  rowEvents: PropTypes.object,
  resetSwitchBool: PropTypes.bool,
  cardName: PropTypes.string,
  expandRow: PropTypes.object,
  customId: PropTypes.string,
  globalDisabled: PropTypes.bool,
  showButtonShowMore: PropTypes.bool,
  onShowMoreCallback: PropTypes.func,
  // headerClasses: PropTypes.string,
};

export default ListPermissions;

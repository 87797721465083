import { useDispatch, useSelector } from "react-redux"
import { getPartitionPublicUUID } from "../../../../config/selectors/selectors"
import { 
  entityCreate, 
  entityRead, 
  entityUpdate,
  getListAndPartialReadEntities, 
  getListAndReadEntities, 
  getOrCreateSingle } from "../../../../entity/actions/entityActions"
import { partitionNamesConfig } from "../../../../api/appConfig"
import { useEffect, useState } from "react"
import { addDataToRelatedIssues, formatDate, getDayParams, getIssuesUUIDs, getNextDay, getUserIssuesUUIDS, isFutureDate} from "./utils"
import moment from "moment"
import { getMainPublicProject } from "../../../../userFlow/store/action-creators/userActions"
import { get } from "lodash"
import { getMD5HashFromString } from "../../../../MainUtils"
import { 
  GET_PLANNING_VECTOR_DAYS_FAILURE, 
  GET_PLANNING_VECTOR_DAYS_REQUEST, 
  GET_PLANNING_VECTOR_DAYS_SUCCESS } from "../../../../userFlow/store/reducers/slices/planingSlice"


const useWeekPlanning = ({customActor, getFetchBoards, today, day, projects}) => {
    const dispatch = useDispatch()
    const partitionPUBLIC = useSelector(getPartitionPublicUUID)

    const [todayVector, setTodayVector] = useState(null)

    const [relatedGeneralIssues, setRelatedGeneralIssues] = useState([])
    const [loadingWeekBoard, setLoadingWeekBoard] = useState(false)
    const [dayParams, setDayParams] = useState({})

    //add fn weekBoard
    const startDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
    const endDate = moment(startDate).endOf('isoWeek').format('YYYY-MM-DD');
  
    const [projectUserReportsUUID, setProjectUserReportsUUID] = useState(null)
    const [publicUserUUID, setPublicUserUUID] = useState(null)
   
    const getProjectUserReports = async (uuid) => {
      const projectPublicUUID = await dispatch(getMainPublicProject(uuid)).catch(
        () => {
          setProjectUserReportsUUID(null);
        },
      );
  
      const parent = get(projectPublicUUID, 'uuid');
  
      const config = {
        data: {
          entity_type: 'project',
          parent,
          params: {
            title: 'WEEKLY PLANNING',
            type: 'ACTIVITIES_USER',
            status: 'systemic',
            users: [
              {
                role: '',
                uuid: publicUserUUID,
              },
            ],
            usersSearch: [publicUserUUID],
          },
          key: getMD5HashFromString(`${parent}projectACTIVITIES_USER`),
        },
        partition: partitionNamesConfig.partition3,
      };
  
      dispatch(getOrCreateSingle(config))
        .then((res) => {
          setProjectUserReportsUUID(get(res, 'uuid', ''));
        })
        .catch(() => {
          setProjectUserReportsUUID(null);
        });
    };
  
    const createDayActivity = (day) => {
      const config = {
        data: {
          entity_type: 'vector',
          parent: projectUserReportsUUID,
          params: {
            title: `Day(${moment(day).format('DD-MM-YYYY')})`,
            date: moment(day).format('YYYY-MM-DD'),
            status: 'created',
          },
        },
        constants: ['CREATE_DAY_REQUEST', 'CREATE_DAY_SUCCESS', 'CREATE_DAY_FAILURE'],
        options: { partition: partitionNamesConfig.partition3 },
      };
      return dispatch(entityCreate(config));
    };
  
    const defaultTemplateWeek = (days) => {
      const week = [];
      for (let i = 0; i < days; i += 1) {
        week.push({
          date: moment(startDate, moment.defaultFormat)
            .add(i, 'days')
            .format('YYYY-MM-DD'),
          uuid: '',
        });
      }
      return week;
    };
    const getDaysActivity = () => {
      const config = {
        data: {
          entity_type: 'vector',
          parent: projectUserReportsUUID,
          params: {
            date__gte: moment(startDate).format('YYYY-MM-DD'),
            date__lte: moment(endDate).format('YYYY-MM-DD'),
          },
          params_fields: { date: 'date' },
        },
        constants: [
          GET_PLANNING_VECTOR_DAYS_REQUEST,
          GET_PLANNING_VECTOR_DAYS_SUCCESS,
          GET_PLANNING_VECTOR_DAYS_FAILURE,
        ],
        partition: partitionNamesConfig.partition3,
      };
      return dispatch(getListAndPartialReadEntities(config));
    };

    const createWeekActivity = async () => {
        if((day === today) && projectUserReportsUUID) {
          try {
            const diffDay = moment(endDate).diff(moment(startDate), 'day') + 1;
            const wholeWeek = defaultTemplateWeek(diffDay);
      
            const { data = [] } = await getDaysActivity();
      
            let days = [];
      
            for (let i = 0; i < wholeWeek.length; i += 1) {
              const isDayExists = data.find((p) => p.date === wholeWeek[i]?.date);
              let day = wholeWeek[i];
      
              if (isDayExists) {
                day = isDayExists;
              }
      
              if (!isDayExists ) {
                day = createDayActivity(day.date);
              }
      
              days.push(day);
            }
              days = await Promise.all(days);
              days = days.flat();
              days = days.map((p) => (p.params ? { uuid: p.uuid, date: p.params.date } : p));
          } catch(e) {
            console.log(e);
          }
        }
    };
  //


    const getPublicUser = async () => {
        const data = {
          entity_type: "user",
          parent: partitionPUBLIC,
          depth: 0,
          actor: customActor
        }
        const constants = [
          "GET_ENTITY_REQUEST",
          "GET_ENTITY_SUCCESS",
          "GET_ENTITY_FAILURE"
        ]
        const publicUser = await dispatch(getListAndReadEntities({
          data,
          constants,
         partition: partitionNamesConfig.partition3,  
        }))
        setPublicUserUUID(publicUser.data[0].uuid)
      }

      const getVector = async (day) => {
        const data = {
          entity_type: "vector",
          parent: publicUserUUID,
          depth: 0,
          params: {
            // date: day,
            title: formatDate(day)
          }
        }
        const constants = [
          "GET_VECTOR_REQUEST",
          "GET_VECTOR_SUCCESS",
          "GET_VECTOR_FAILURE"
        ]
        const vectorsData = await dispatch(getListAndReadEntities({
          data,
          constants,
         partition: partitionNamesConfig.partition3,  
        }))

          setTodayVector(vectorsData.data[0])
        console.log("FFF", vectorsData.data[0]);
        
       return vectorsData.data[0]
      }

      const updateVectorRelatedGeneralIssues = async (uuid, newParams) => {
            const data = {
                entity_uuid: uuid,
                entity_type: "vector",
                params: {
                    ...newParams
                },
            }
            const options = {
                partition: partitionNamesConfig.partition3
            }
            
       const res = await dispatch(entityUpdate({
                data,
                options,
                constants: [
                    "UPDATE_GENERAL_BOARD_REQUEST",
                    "UPDATE_GENERAL_BOARD_SUCCESS",
                    "UPDATE_GENERAL_BOARD_FAILURE",
                ],
            }))
              if(res[0].params.date === day) {
                setTodayVector(res[0])
              }
      }

      const getListRelatedGeneralIssues = async () => {     
        const issuesUUIDs = getIssuesUUIDs(todayVector)
          try {
            setLoadingWeekBoard(true)
            if(issuesUUIDs && issuesUUIDs.length > 0) {
              const data = {
                entity_uuids: issuesUUIDs,
                entity_type: "issue",
              }
               const options = {
                 partition: partitionNamesConfig.partition1
               }
      
               const constants = [
                "GET_ISSUES_REQUEST",
                "GET_ISSUES_SUCCESS",
                "GET_ISSUES_FAILURE"
              ]
      
               const issues = await dispatch(entityRead({
                data,
                constants,
                options 
              }))
              setRelatedGeneralIssues(addDataToRelatedIssues(issues, todayVector))
            } else {
              setRelatedGeneralIssues([])
            }
          } catch(e) {
           const vectorUUID = todayVector.uuid
            updateVectorRelatedGeneralIssues(vectorUUID, {relatedGeneralIssues: []})
          }
          finally {
            setLoadingWeekBoard(false)
          }
      }

      // add generalPlanboard fn 
      const updateGeneralPlanboardIssues = async () => {
        
          const fetchedBoards = await getFetchBoards()
          const newRelatedGeneralIssues = getUserIssuesUUIDS(fetchedBoards, day, projects)

          if(newRelatedGeneralIssues) {
            const vectorUUID = todayVector.uuid
            updateVectorRelatedGeneralIssues(vectorUUID, {relatedGeneralIssues: newRelatedGeneralIssues})
      }
    }

    const getNexDayVector = async (day) => {
      const data = {
        entity_type: "vector",
        parent: publicUserUUID,
        depth: 0,
        params: {
          // date: day,
          title: formatDate(getNextDay(day))
        }
      }
      const constants = [
        "GET_VECTOR_REQUEST",
        "GET_VECTOR_SUCCESS",
        "GET_VECTOR_FAILURE"
      ]
      const vectorsData = await dispatch(getListAndReadEntities({
        data,
        constants,
       partition: partitionNamesConfig.partition3,  
      }))
      
     return vectorsData.data[0]
    }

      useEffect(() => {
        if(partitionPUBLIC && customActor) {
            getPublicUser()
        }
      }, [partitionPUBLIC, customActor])

      useEffect(() => {
        const fetchData = async () => {
            try {
                if (publicUserUUID && day) {
                    if (day === today) {
                        const todayVector = await getVector(today);
                        if (!todayVector) {
                            await getProjectUserReports(publicUserUUID);
                        }
                    } else {
                        await getVector(day);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    }, [day, publicUserUUID, today]);

    useEffect(() => {
      const createWeekDays = async() => {
           await createWeekActivity()
            getVector(today)
      }
         if(projectUserReportsUUID) {
          createWeekDays()
         }
     }, [projectUserReportsUUID])

      useEffect(() => {
        if(todayVector) {
          getListRelatedGeneralIssues()
        } else {
          setRelatedGeneralIssues([])
        }
      }, [todayVector])

      useEffect(() => {
          setDayParams({hours: todayVector?.params?.workTime, totalDayHours: getDayParams(relatedGeneralIssues)})
      }, [relatedGeneralIssues, day])
      
      useEffect(() => {
        if(todayVector && !todayVector?.params?.relatedGeneralIssues && isFutureDate(day)) {
          updateVectorRelatedGeneralIssues(todayVector.uuid, {relatedGeneralIssues: []})
        }
      }, [todayVector, day])

    return {
        todayVector,
        relatedGeneralIssues,
        loadingWeekBoard,
        dayParams,
        updateVectorRelatedGeneralIssues,
        getListRelatedGeneralIssues,
        updateGeneralPlanboardIssues,
        getNexDayVector
    };
}

export default useWeekPlanning;

/* eslint-disable import/prefer-default-export */

import { partitionNamesConfig } from '../../../api/appConfig';
import { getOrCreateSingle } from '../../../entity/actions/entityActions';
import { mainUserProjectLogsActions } from '../reducers/slices/mainUserProjectLogsSlice';

export const getUserProjectLogsStorage = (uuid) => {
  const config = {
    data: {
      entity_type: 'userMetaLogsStorage',
      parent: uuid,
    },
    partition: partitionNamesConfig.partition3,
    constants: [
      mainUserProjectLogsActions.GET_OR_CREATE_MAIN_USER_PROJECT_LOGS_REQUEST,
      mainUserProjectLogsActions.GET_OR_CREATE_MAIN_USER_PROJECT_LOGS_SUCCESS,
      mainUserProjectLogsActions.GET_OR_CREATE_MAIN_USER_PROJECT_LOGS_FAILURE,
    ],
  };
  return getOrCreateSingle(config);
};

import {AuthConstants} from "../Constants";


const initialState = {
  error: ''
};


export default (state=initialState, action) => {
  const {type, payload } = action;
  switch (type) {
    case AuthConstants.ERROR_430:
      return {
        error: payload
      };

    case AuthConstants.CLEAR_ERROR_430:
      return {
        error: ''
      };

    default:  return state;
  }
};

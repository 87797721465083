import { string } from 'prop-types';
import useURLParams from './useURLParams';

function useMouseMiddleRedirect(pathname) {
  const { currentLocationPathname } = useURLParams();
  const locationPathname = pathname || currentLocationPathname;

  const onMouseMiddleClick = (event, searchParams) => {
    if (event.button === 1) {
      window.open(`${locationPathname}?${Object
        .entries(searchParams)
        .map(([key, value]) => `${key}=${value}`).join('&')}
      `);
    }
  };

  return { onMouseMiddleClick };
}

useMouseMiddleRedirect.propTypes = {
  pathname: string,
};

export default useMouseMiddleRedirect;

import { mdiChevronDown, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Card, Col,
  Spin,
} from 'antd';
import {
  head,
  isEmpty, pathOr, prop, propOr,
} from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import {
  allListEntityPerm, getActorsPermsForEntity, getAdminGroupUUID, getBanGroupUUID,
} from '../../../entity/selectors/selectors';
import { capitalize } from 'lodash';
import { deleteEntityPerm } from '../../../entity/actions/entityPermActions';
import { PermissionsContext } from '../../context/ProjectFlowListOfContexts';
import ExpandRowPermList from './ExpandRowPermList';
import ListPermissions from './ListPermissions';
import SaveAndResetBtn from './SaveAndResetBtn';
import useURLParams from '../../../hooks/useURLParams';
import { actorIsRoot } from '../../../54origins/utils54origins';
import { constant } from 'lodash';
import { getFetchingForPSP, getPSPAdminGroupActorUUID, getPSPBanGroupActorUUID, getPermsForPSP, getSelectedActorPermsForPSP, getTotalForPSP } from '../../../permissions/selectors/permissionsSelector';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";

const initialDataInInfo = {
  usersSelectedGroup: [],
  groupsInSelectedUser: [],
  usersWithoutGroup: [],
};

const initTreeData = {
  currentActor: [],
  treePerm: [],
};
const hiddenInSumPermInfoTable = ['columnType', 'columnName', 'columnEntityName', 'columnDeletePerm'];

const hiddenDataInfoListPerm = ['columnEntityName', 'columnDeletePerm'];

const disabledDeleteBut = new Set();

function InfoAndSetPermissionsCard({ notSavedPermissions }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    entityUUID,
    partitionType,
    getEntityPerms,
    notSavedDataCallback,
    getActorTreePermissions,
    updateSwitchDataCallback,
    resetFromMainContainer,
    saveTreePermissions,
    defaultPerms,
    checkIsUserType,
    getName,
    viewingAlert,
  } = useContext(PermissionsContext);

  const { clearSearchParams, setSearchParams, getURLParams } = useURLParams();

  const allListPerms = useSelector(getActorsPermsForEntity);
  const adminGroupUUID = useSelector(getPSPAdminGroupActorUUID);
  const banGroupUUID = useSelector(getPSPBanGroupActorUUID);

  const {
    isFetchingUsersInGroup,
  } = useSelector(getFetchingForPSP);
  const {
    usersInGroup,
  } = useSelector(getPermsForPSP)
  const {
    usersInGroupTotal,
  } = useSelector(getTotalForPSP)

  const actorPerm = useSelector(getSelectedActorPermsForPSP);

  const [usersInGroupConfig, setUsersInGroupConfig] = useState({
    actor_type: ['classic_user', 'user'],
    limit: 15,
    offset: 0,
  })

  // const [listPermInInfo, setListPermInInfo] = useState(initialDataInInfo);
  const [localReset, setLocalReset] = useState(false);
  const [treeAndCurrentActor, setTreePermsAndCurrentActor] = useState(initTreeData);
  const [newPerms, setNewPerms] = useState([]);

  const { activeActorPerm, typeActor, expandedActorPerm } = getURLParams();

  // const {
  //   usersSelectedGroup,
  //   groupsInSelectedUser,
  //   usersWithoutGroup,
  // } = listPermInInfo;

  const resetData = () => {
    setNewPerms([]);
    setLocalReset(true);
    notSavedDataCallback('infoCard', false);
  };

  const onChangeUserFunc = async () => {
    await getSelectedActor();
    await getSelectedActorTreePerms();
  }

  const removeEntityPerm = async (item) => {
    if (disabledDeleteBut?.has(item?.perm_uuid)) return;
    disabledDeleteBut.add(item?.perm_uuid)
    const data = {
      perm_uuid: prop('perm_uuid', item),
    };

    await dispatch(
      deleteEntityPerm({
        data,
        partitions: partitionType,
      }),
    );
    await onChangeUserFunc();
    disabledDeleteBut.delete(item?.perm_uuid)
  };

  // const initialFunc = () => {
  //   const isAdminGroup = activeActorPerm === adminGroupUUID;
  //   const isBanedGroup = activeActorPerm === banGroupUUID;
  //   const usersThisGroup = [];
  //   const withoutGroup = [];
  //   const groupsInSelectedActor = [];

  //   if (typeActor === 'group') {
  //     allListPerms.forEach((item) => {
  //       const { uinfo } = item;

  //       // if ADMIN group is selected
  //       if (isAdminGroup) {
  //         if (propOr([], 'groups', uinfo).some((i) => i === adminGroupUUID)) {
  //           usersThisGroup.push({ ...item, is_admin: true });
  //         }
  //         return;
  //       }
  //       // if BAN group is selected
  //       if (isBanedGroup) {
  //         if (propOr([], 'groups', uinfo).some((i) => i === banGroupUUID)) {
  //           usersThisGroup.push({ ...item, userInBan: true });
  //         }
  //         return;
  //       }

  //       // if group is selected
  //       if (propOr([], 'groups', uinfo).some((i) => i === activeActorPerm)) {
  //         usersThisGroup.push({ ...item, disabled: true });
  //         return;
  //       }

  //       // UsersWithoutGroup
  //       if (isEmpty(prop('groups', uinfo))) {
  //         withoutGroup.push({ ...item, disabled: true });
  //       }
  //     });
  //   }

  //   if (checkIsUserType(typeActor)) {
  //     const currentActor = allListPerms.find((item) => prop('actor_uuid', item) === activeActorPerm);
  //     const groupsCurrentUser = pathOr([], ['uinfo', 'groups'], currentActor);
  //     allListPerms.forEach((item) => {
  //       if (groupsCurrentUser.some((i) => i === prop('actor_uuid', item))) {
  //         groupsInSelectedActor.push({ ...item, disabled: true });
  //       }
  //     });
  //   }

  //   setListPermInInfo({
  //     usersSelectedGroup: usersThisGroup,
  //     groupsInSelectedUser: groupsInSelectedActor,
  //     usersWithoutGroup: withoutGroup,
  //   });
  // };

  const savePermissions = async () => {
    await saveTreePermissions(newPerms);
    await getSelectedActor();
    notSavedDataCallback('infoCard', false);
    setNewPerms([]);
  };

  // const clickHeaderColumn = (e) => e.stopPropagation();

  const closeInfoCard = () => {
    clearSearchParams(['activeActorPerm', 'typeActor', 'expandedActorPerm']);
    resetData();
  };

  const onExpandRow = (row) => {
    if (row.actor_uuid === expandedActorPerm) {
      clearSearchParams(['expandedActorPerm']);
      return;
    }
    setSearchParams({ expandedActorPerm: row.actor_uuid });
  };

  const expandRowActor = {
    expandedRowRender: (row) => (
      <ExpandRowPermList
        item={row}
        removeEntityPermCallback={removeEntityPerm}
        notSavedPermissions={notSavedPermissions}
      />
    ),
    showExpandColumn: true,
    expandedRowKeys: [expandedActorPerm],

    expandIcon: ({ expanded, rowKey, record }) => (
      <Icon
        path={mdiChevronDown}
        size={1}
        rotate={expanded ? 180 : 0}
      />
    ),
  };

  const rowEventsTable = (row) => ({
    onClick: () => { onExpandRow(row); },
  });

  const selectRowTable = {
    mode: 'radio',
    // clickToSelect: true,
    hideSelectColumn: true,
    // classes: activeActorPerm && 'row-perm-selected',
    selectedRowKeys: [expandedActorPerm],
  };

  const showListPermissions = (dataTable, title, wrapperStyle, {
    showButtonShowMore = false,
    onShowMoreCallback
  }) => (
    <ListPermissions
      rowEvents={rowEventsTable}
      dataTable={dataTable}
      wrapperStyle={wrapperStyle}
      cardName="infoCard"
      onShowMoreCallback={onShowMoreCallback}
      showButtonShowMore={showButtonShowMore}
      hiddenData={hiddenDataInfoListPerm}
      title={title}
      expandRow={expandRowActor}
      selectRow={selectRowTable}
    />
  );

  const getSelectedActorTreePerms = async () => {
    if (activeActorPerm === 'USER_WITHOUT_GROUP') {
      setTreePermsAndCurrentActor(initTreeData);
      return;
    }

    const res = await getActorTreePermissions(activeActorPerm);

    if (isEmpty(res) || !res.some((i) => i.actor_uuid === activeActorPerm
      && i.entity_uuid === entityUUID)
    ) {
      res.push({
        ...actorPerm,
        ...defaultPerms,
        entity_type: 'current entity',
        entity_uuid: entityUUID,
      });
    }
    const newData = res.map((item) => ({ ...item, customId: `${item.actor_uuid}${item.entity_uuid}` }));

    setTreePermsAndCurrentActor({
      currentActor: [{ ...actorPerm, disabled: true }],
      treePerm: newData,
    });
  };

  const changeSwitchTreePerm = (currentItem, newPermsData) => {
    const perms = updateSwitchDataCallback(currentItem, newPerms, newPermsData);
    setNewPerms(perms);
    notSavedDataCallback('infoCard', !isEmpty(perms));
  };

  const buttonCloseCard = (
    <BaseButton
      size="small"
      className="customAntOutOrangeBtn mr-1 mt-1"
      onClick={closeInfoCard}
    >
      <div className="flex align-item-center justify-center ">
        <Icon
          className="mr-1"
          path={mdiClose}
          size={0.8}
        />
        {capitalize(
          t('wms.buttons.close', 'close'),
        )}
      </div>
    </BaseButton>
  );

  const checkNameGroup = (name) => getName(head(prop('currentActor', treeAndCurrentActor))) === name;

  const getSelectedActor = async () => {
    const res = await getEntityPerms({
      params: {
        actors: [activeActorPerm],
      },
      type: 'selectedPerm'
    })

    // if (res?.data?.[0]) {
    //   setActorPerm(res?.data?.[0])
    // }
  }

  const getUsersInGroup = ({
    withClear = false,
    params = {},
  }) => {
    getEntityPerms({
      params: {
        uinfo: {
          groups: [activeActorPerm]
        },
        ...usersInGroupConfig,
        ...params,
      },
      type: 'usersInGroup',
      withClear: withClear,
    })
    setUsersInGroupConfig(prev => ({
      ...prev,
      ...params,
    }))
  }

  useEffect(() => {
    if (localReset) {
      setLocalReset(false);
    }

    if (resetFromMainContainer && notSavedPermissions.infoCard) {
      resetData();
    }
  }, [localReset, resetFromMainContainer]);

  useEffect(() => {
    if (activeActorPerm && actorPerm) {
      getSelectedActorTreePerms();
      // initialFunc();
    }
  }, [
    JSON.stringify(allListPerms),
    JSON.stringify(actorPerm),
    activeActorPerm,
    adminGroupUUID,
    banGroupUUID
  ]);

  useEffect(() => {
    if (activeActorPerm) {
      getSelectedActor()
    }
  }, [activeActorPerm])

  useEffect(() => {
    if (typeActor && activeActorPerm) {
      getUsersInGroup({
        withClear: true,
        params: {
          limit: 15,
          offset: 0,
        }
      });
    }
  }, [typeActor, activeActorPerm])

  if (actorIsRoot(head(prop('currentActor', treeAndCurrentActor)))) {
    return (
      <BaseCard className="dangerCard ml-4">
        {buttonCloseCard}
        {viewingAlert(
          'success',
          capitalize(t('wms.labels.this_is_root')),
          capitalize(t('wms.labels.Root_has_full_access_to_all_entities')),
        )}
      </BaseCard>
    );
  }

  return (
    <BaseCard className="dangerCard ml-4">
      <Col className="pb-2">
        {buttonCloseCard}

        {checkNameGroup('BAN') && viewingAlert(
          'error',
          capitalize(t('wms.labels.this_is_ban_group')),
          capitalize(t('wms.labels.users_of_this_group_do_not_have_access')),
        )}

        {checkNameGroup('ADMIN') && viewingAlert(
          'success',
          // capitalize(t('wms.noun.success')),
          capitalize(t('wms.labels.this_is_admin_group')),
          capitalize(t('wms.labels.users_of_this_group_has_full_access_to_all_entities')),
        )}

        {(activeActorPerm !== 'USER_WITHOUT_GROUP'
          && !checkNameGroup('ADMIN')
          && !checkNameGroup('BAN'))
          && (
            <>
              <div className="header_table_title orange_color mt-1">
                {getName(head(prop('currentActor', treeAndCurrentActor)))}
              </div>

              <ListPermissions
                dataTable={prop('currentActor', treeAndCurrentActor)}
                cardName="creatorCard"
                hiddenData={hiddenInSumPermInfoTable}
                headerClasses="hidden"
              />

              <div className="header_table_title orange_color justify-between">
                {typeActor === 'group'
                  ? capitalize(t('wms.labels.all_group_permissions'))
                  : capitalize(t('wms.labels.all_user_permissions'))}
                <SaveAndResetBtn
                  // withoutWrapper
                  disabledButtons={isEmpty(newPerms)}
                  saveCallback={savePermissions}
                  resetCallback={resetData}
                />
              </div>
              <ListPermissions
                dataTable={prop('treePerm', treeAndCurrentActor)}
                cardName="infoCard"
                customId="customId"
                resetSwitchBool={localReset}
                removePermCallback={removeEntityPerm}
                changeSwitchCallback={changeSwitchTreePerm}
              />
            </>
          )}
      </Col>

      {typeActor === 'group' && <Spin spinning={isFetchingUsersInGroup}>{
        showListPermissions(
          usersInGroup,
          // capitalize(t('wms.permissions.headers.users', 'Users')),
          capitalize(t('wms.labels.users_in_a_group')),
          null,
          {
            showButtonShowMore: usersInGroupTotal > usersInGroup.length,
            onShowMoreCallback: () => getUsersInGroup({
              params: {
                limit: 50,
                offset: usersInGroupConfig.offset + usersInGroupConfig.limit,
              }
            })
          }

        )}</Spin>}

      {/* {activeActorPerm === 'USER_WITHOUT_GROUP' && showListPermissions(
        usersWithoutGroup,
        // capitalize(t('wms.permissions.headers.users', 'Users')),
        capitalize(t('wms.labels.users_in_a_group')),
      )} */}

      {/* {!isEmpty(groupsInSelectedUser)
        && showListPermissions(
          groupsInSelectedUser,
          //  capitalize(t('wms.permissions.headers.Groups', 'Groups')),
          capitalize(t('wms.labels.groups_the_user_belongs_to')),
        )} */}
    </BaseCard>
  );
}

export default InfoAndSetPermissionsCard;

import PropTypes from 'prop-types';
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import _, { get, split, isEmpty } from 'lodash';

import { mdiFilter } from '@mdi/js';
import Icon from '@mdi/react';

import {
  Badge, Flex, Table, Tooltip,
} from 'antd';
import CustomTablePagination from '../BaseTablePagination/BaseTablePagination';
import EntityParamTag from '../../entityParamTag/EntityParamTag';

import { nameSort } from '../../../MainUtils';

import './BaseTable.scss';

function BaseTable({
  useCustomPagination,
  bordered = false,
  columns,
  size,
  components,
  currentPage = 1,
  data = [],
  disablePagination,
  expandable,
  headerRowClassName,
  hidePaginationOnSinglePage = false,
  loading,
  onChangePagination,
  onRow,
  onShowSizeChange,
  pageSizeOptions = [25, 50, 100],
  pagination,
  paginationPageSize = 25,
  paginationSize = 'default',
  rowClassName = '',
  rowKey = 'uuid',
  rowSelection,
  showHeader = true,
  total = data ? data.length : 0,
  showSizeChanger = true,
  currentSortCallback,
  filterCallback,
  hideListSizeOption,
  scroll,
  onSelectFilters,
  selectedFilters,
  addFilters,
  rowHoverable = true
}) {

  const defaultPagination = {
    current: +currentPage,
    pageSize: +paginationPageSize,
    hideOnSinglePage: hidePaginationOnSinglePage,
    onChange: onChangePagination,
    onShowSizeChange,
    pageSizeOptions,
    position: ['bottomLeft'],
    // showLessItems: paginationSize === 'small',
    showSizeChanger,
    showTitle: false,
    size: paginationSize,
    total,
    locale: {
      items_per_page: '',
    },
  };
    
 
  useEffect(() => {
      if(selectedFilters && onSelectFilters) {
        onSelectFilters(selectedFilters) 
      }
  }, [selectedFilters])

  const onChange = (pagination, filters, sorter) => {
      if(addFilters) {
        addFilters(filters)
      }
    //  console.log('!filters:', filters);
    if (filterCallback) {
      filterCallback(filters)
    }
    if (currentSortCallback) {
      // console.log('handleChange', get(sorter, 'columnKey'), nameSort[get(sorter, 'order')]);
      currentSortCallback({
        orderBy: get(sorter, 'columnKey'),
        order: nameSort[get(sorter, 'order')],
      });
    }
  };


  

  const onHeaderRow = (columns, index) => {
    const config = {};

    if (headerRowClassName) config.className = headerRowClassName;
    return config;
  };

  const getRowKey = (item) => {
    const toKey = (path) => _.get(item, path, '')?.toString()?.replaceAll(
      ' ',
      '_',
    );

    if (_.isArray(rowKey)) {
      return rowKey.map((rKey) => toKey(rKey)).join('_');
    }
    return toKey(_.toString(rowKey));
  };

  const getRowClassName = (data) => {
    if (!rowClassName) {
      if (rowSelection) {
        return 'table-selectable';
      }
      return '';
    }

    if (_.isFunction(rowClassName)) {
      return rowClassName(data);
    }
    return rowClassName;
  };

  const dynamicHideListSizeOption = total <= pageSizeOptions?.[0];

  // Add custom filter icon with badge
  const customFilterIconWithBadge = useCallback(
    (filtered, selectedFilters, filterType) => {
      const count = selectedFilters?.length;
      const filtersTooltip = count
        ? (
          <ul className="">
            <h6 className="mb-1 font-medium text-blue-600">Filtered:</h6>
            {/* {filters.map((el) => <div>{`- ${split(el, '_').join(' ')}`}</div>)} */}
            {selectedFilters.map((el) => (
              <li key={el} className="mb-1">
                <EntityParamTag
                  param="title"
                  value={el}
                  type={filterType}
                />
              </li>
            ))}
          </ul>
        )
        : 'Filter column';

      return (
        <Tooltip
          title={filtersTooltip}
          placement={filtered ? 'rightTop' : 'top'}
          color={filtered && '#ffffff'}
          mouseEnterDelay={filtered ? 0.5 : 0.1}
        >
          <Flex align="center">
            <Icon
              path={mdiFilter}
              size={0.75}
              color={filtered ? '#1677ff' : '#51a5ff'}
            />
            {count && (
              <Badge
                count={count}
                size="small"
                color="blue"
              />
            )}
          </Flex>
        </Tooltip>
      );
    },
    [],
  );

  const columnsWithFilter = useMemo(
    () => columns.map((col) => {
      const { defaultFilteredValue, filterType } = col || {};
      // console.log('!123:', defaultFilteredValue);
      return {
        ...col,
        filterIcon: (filtered) => customFilterIconWithBadge(
          filtered,
          defaultFilteredValue,
          filterType,
        ),
      };
    }),
    [columns],
  );

  // console.log('!render:');

  return (
    <>
      <Table
        bordered={bordered}
        columns={columnsWithFilter}
        components={components}
        dataSource={data}
        expandable={expandable}
        loading={loading}
        onRow={onRow}
        onShowSizeChange={onShowSizeChange}
        pagination={(disablePagination || useCustomPagination)
          ? false
          : (pagination || defaultPagination
          )}
        rowSelection={rowSelection}
        showHeader={showHeader}
        showSorterTooltip={false}
        size={size}
        onHeaderRow={onHeaderRow}
        rowClassName={getRowClassName}
        rowKey={getRowKey}
        onChange={onChange}
        scroll={scroll}
        rowHoverable={rowHoverable}
      />

      {!disablePagination && useCustomPagination
      && (
        <CustomTablePagination
          total={total}
          currentPage={currentPage}
          pageLimit={paginationPageSize}
          pageSizeOptions={pageSizeOptions}
          hideListSizeOption={hideListSizeOption || dynamicHideListSizeOption}
          onShowSizeChange={onShowSizeChange}
          onChangePage={onChangePagination}
        />
      )}
    </>
  );
}

export default BaseTable;

BaseTable.propTypes = {
  bordered: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  components: PropTypes.object,
  currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.array.isRequired,
  disablePagination: PropTypes.bool,
  expandable: PropTypes.object,
  headerRowClassName: PropTypes.string,
  hidePaginationOnSinglePage: PropTypes.bool,
  loading: PropTypes.bool,
  pageSizeOptions: PropTypes.array,
  pagination: PropTypes.object,
  paginationPageSize: PropTypes.number,
  paginationSize: PropTypes.string,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowKey: PropTypes.string,
  rowSelection: PropTypes.object,
  showHeader: PropTypes.bool,
  size: PropTypes.string,
  total: PropTypes.any,
  hideListSizeOption: PropTypes.bool,
  useCustomPagination: PropTypes.bool,
  showSizeChanger: PropTypes.bool,
  scroll: PropTypes.object,
  onChangePagination: PropTypes.any,
  onRow: PropTypes.func,
  onShowSizeChange: PropTypes.any,
  filterCallback: PropTypes.func,
  currentSortCallback: PropTypes.func,
  onSelectFilters: PropTypes.func,
  setDefaultFilters: PropTypes.func,
  addFilters: PropTypes.func,
  selectedFilters: PropTypes.array,
  rowHoverable: PropTypes.bool
};

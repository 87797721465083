import {
  array, bool, func, number, object, string,
} from 'prop-types';
import React, {
  useEffect, useState,
} from 'react';
import {
  Checkbox,
  Col, Flex, Form, Input, Modal, Row, Spin, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';

import '../css/modalNewIssue.scss';

import Icon from '@mdi/react';
import {
  mdiClose, mdiHelpCircleOutline,
} from '@mdi/js';
import {
  capitalize, get, isEmpty, isNil,
} from 'lodash';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import IssueStatusCollapse from './components/IssueStatusCollapse';
import SelectAssignedProject from './SelectAssignedProject';
import UploaderWrapperInView from '../../../../components/uploader/UploaderWrapperInView';
import ListOfVectorsDropDownForIssueInfo from '../ListOfVectorsDropDownForIssueInfo';
import LineWithTextButton from '../../commonComponents/LineWithTextButton';
import AntUploaderFile from '../../../../components/uploader/AntUploaderFile';
import { IssuePriorityRadioButtons, IssueTypeRadioButtons } from '../IssueRadioButtons';
import ListOfProjectTagsForIssue from '../ListOfProjectTagsForIssue';
import IssueRecordVoiceDescriptionBtn from '../IssueRecordVoiceDescriptionBtn';
import NewIssueStatus from './components/NewIssueStatus';
import DateLeftEdit from '../../../../components/DateLeftEdit/DateLeftEdit';
import IssueEstimatedTimeDropDown from '../IssueEstimatedTimeDropDown';

import { getExpirationDate } from '../issueInfo/utils';
import { checkStringIsEmpty, stopPropagation } from '../../../../54origins/utils54origins';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { getUuid } from '../../../../assignUsersModal/utils';
import useURLParams from '../../../../hooks/useURLParams';
import useFocus from "../../../../54origins/hooks/useFocus54origins";

const { Item } = Form;
const { TextArea } = Input;

const hideViewInUploadFile = ['dragDropAndPaste', 'descriptionDragAndPaste'];
const hideExpirationDate = ['created', 'reopened'];

export default function NewIssueForm({
  bugTrackedTitle,
  testSteps = [],
  testCaseTitle = '',
  isAssignOpen,
  issueTitle,
  handleTitleChange,
  assignedProject,
  assignedVector,
  audioFiles,
  description,
  disabledView,
  estimated_time,
  getTitle,
  files,
  getInputValueAndChangeField,
  handleFormSubmit,
  hideView,
  loading,
  onChangeField,
  onChangeFiles,
  partitionType,
  priority,
  projectUsers = [],
  selectedProjectCallback,
  selectedVectorCallback,
  selectedVectorData,
  setLoading,
  status,
  lifeTime,
  tracker,
  usersSearch,
  isRecurring,
  getTextAreaBugValue,
}) {
  const { t } = useTranslation();

  const [issueDescriptionRef] = useFocus();

  const [isShowMoreStatuses, changeShowMoreStatuses] = useState(false);
  const [isExpandedDescription, changeExpandedDescription] = useState(true);
  const [overFlag, setOver] = useState(false);
  const [currentListAudio, setCurrentListAudio] = useState([]);
  const [titleValidateStatus, setTitleValidateStatus] = useState('');

  const [selectedProjectUsers, setSelectedProjectUsers] = useState([]);

  const selectedVectorTitle = get(selectedVectorData, 'title', 'empty');
  const [title, setTitle] = useState('empty')

  const {currentLocationPathname} = useURLParams()
  const [openUsersModal, setOpenUsersModal] = useState(false)

  useEffect(() => {
    setTitle(selectedVectorTitle)
  }, [selectedVectorTitle])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && e.ctrlKey && !checkStringIsEmpty(description) && !loading) {
      setLoading(true);
      e.preventDefault();
      handleFormSubmit();
    }
  };

  const onClickTag = (tags) => {
    onChangeField({ tags });
  };

  const saveAudioFileCallback = (customAudio) => {
    if (customAudio?.length === 0) {
      changeExpandedDescription(true);
    }
    if (audioFiles.length === 0 && customAudio?.length === 1) {
      changeExpandedDescription(false);
    }
    onChangeFiles({ audioFiles: customAudio });
  };

  const getAudioFileCallback = (newAudio) => {
    const newAudioList = [...currentListAudio, newAudio];
    saveAudioFileCallback(newAudioList);
  };

  const deleteFile = async (id) => {
    const newBase64List = [...currentListAudio];

    newBase64List.splice(id, 1);

    setCurrentListAudio(newBase64List);
    saveAudioFileCallback(newBase64List);
  };

  const showConfirmDeleteAudio = (id) => Modal.confirm({
    title: capitalize(
      t('wms.modals.headers.removing_process', 'removing process'),
    ),
    width: '600px',
    content: capitalize(t(
      'wms.modals.body.removing_process',
      'you\'re going to remove this file. Are you sure?',
    )),
    okText: capitalize(
      t('wms.buttons.removing_process.yes', 'yes, I am'),
    ),
    okType: 'danger',
    cancelText: capitalize(
      t('wms.buttons.removing_process.no', 'no, I\'m not'),
    ),
    open: false,
    centered: true,
    maskClosable: true,
    okButtonProps: { className: 'dangerButton' },

    onOk() {
      deleteFile(id);
    },
  });

  const customUpdateList = async () => {
    if (audioFiles?.length !== currentListAudio.length) {
      setCurrentListAudio(audioFiles);
    }
  };

  const onFinish = (values) => {
    if (checkStringIsEmpty(description) && isEmpty(testSteps)) {
      setTitleValidateStatus('error');
      return;
    }

    handleFormSubmit(values);
  };

  const onChangeFileCallback = (e) => {
    e?.files?.forEach((file) => {
      file.url = URL.createObjectURL(file);
      file.status = 'done';
    });
    onChangeFiles(e);
  };

  // const getTextAreaBugValue = () => {
  //   let result = ''
  //   for(let item of testSteps){
  //     result += `STEP ${item.step.substring(1)}\nDESCRIPTION: ${item.description}\nTEST DATA: ${item.test_data}\n\n`
  //     if(item.description === bugTrackedTitle) break;
  //   }
  //   return result
  // }

  useEffect(() => {
    if (!checkStringIsEmpty(description)) {
      setTitleValidateStatus('success');
    }
  }, [description]);

  useEffect(() => {
    customUpdateList();
  }, [JSON.stringify(audioFiles)]);

  useEffect(() => {
    setSelectedProjectUsers(projectUsers);
  }, [JSON.stringify(projectUsers)]);

  return (
    <Spin spinning={loading}>
      <UploaderWrapperInView
        files={files}
        onChangeFileCallback={onChangeFileCallback}
      >
        <Form
          onKeyPress={handleKeyPress}
          layout="vertical"
          id="create_new_issue_component"
        // onFinish={onFinishForm}
        >
          <Item
            label={(
              <Flex
                gap={1}
                wrap="wrap"
                align="center"
              >
                <span className="mr-2">
                  {
                    isRecurring ?
                    (capitalize(t('wms.labels.new_recurring_issue_title', 'New recurring issue title'))) :
                    (capitalize(t('wms.labels.new_issue_title', 'new_issue_title')))
                  }
                  /
                  {t('wms.labels.tags', 'tags')}
                </span>
                <ListOfProjectTagsForIssue
                  onClickCallback={onClickTag}
                />
              </Flex>
            )}
            className="label-modal-issue"
          >
            <Input
              id="title"
              maxLength={128}
              placeholder={`The${isRecurring ? ' recurring' : ''} issue title will be filled in automatically based on description`}
              showCount
              size="large"
              value={handleTitleChange ? issueTitle : getTitle}
              onChange={(e) => onChangeField({
                title: e.target.value,
                handleTitleChange: true,
              })}
            />
          </Item>

          <div
            className="label-modal-issue mt-1 mb-2"
          >
            <Flex
              className="mb-1"
              style={{
                color: '#6b6b6b',
              }}
            >
              {capitalize(t('wms.labels.need_done', 'what needs to be done'))}
              <IssueRecordVoiceDescriptionBtn
                getAudioFileCallback={getAudioFileCallback}
                currentListAudioLength={currentListAudio.length}
              />
            </Flex>
            {(!isNil(currentListAudio) && !isEmpty(currentListAudio)) && (
              <Flex
                wrap="wrap"
                className="mt-2 w-full"
                onMouseOver={() => { setOver(true); }}
                onMouseLeave={() => { setOver(false); }}
              >
                {currentListAudio.map((item, i) => {
                  const dataAudio = get(item, 'type')
                    ? URL.createObjectURL(item)
                    : `data:${item.content_type};base64, ${item.file}`;
                  return (
                    <Col
                      span={11}
                      className="audio-box w-full"
                      key={`${item.slice(-40)}${i}`}
                    >
                      {(overFlag) && (
                        <div className="audio-box__remove">
                          <Icon
                            path={mdiClose}
                            size={0.8}
                            onClick={() => showConfirmDeleteAudio(i)}
                          // className="audio-box__remove"
                          />

                        </div>
                      )}
                      <div>
                        <div className="audio-box__label">{get(item, 'filename', 'audio-' + (i + 1))}</div>
                        <audio
                          controls
                          key={i}
                        >
                          <source
                            src={dataAudio}
                            type={item?.content_type}
                          />
                          <source
                            src={dataAudio}
                            type="audio/mpeg"
                          />
                          {t('wms.audio.no_support', 'Your browser does not support the audio element')}
                        </audio>
                      </div>
                    </Col>
                  );
                })}
              </Flex>
            )}
            {/* {isExpandedDescription ? ( */}
            <Item
              name="description"
              validateStatus={titleValidateStatus}
              help={titleValidateStatus === 'error' ? 'Should have something' : null}
            >
              <TextArea
                autoSize={{ minRows: 4, maxRows: 7 }}
                id="description"
                onChange={getInputValueAndChangeField}
                placeholder={capitalize(t('wms.placeholders.description', 'enter description'))}
                size="large"
                defaultValue={getTextAreaBugValue && getTextAreaBugValue(testSteps, bugTrackedTitle, testCaseTitle)}
                ref={issueDescriptionRef}
                className="mb-2"
              />
            </Item>
            {/* ) : null} */}
          </div>

          {/* {currentListAudio.length > 0
            ? (
              <LineWithTextButton
                defaultMessage="Show text area"
                expandedMessage="Hide text area"
                isExpanded={isExpandedDescription}
                onClickCallback={() => {
                  changeExpandedDescription((prev) => !prev);
                }}
                wrapperStyle={{
                  marginTop: -20,
                }}
              />

            ) : null} */}
          <Row>
            <Col span={9}>
              <Item
                label={capitalize(t('wms.tracker.type', 'type'))}
                className="label-modal-issue"
              >
                <IssueTypeRadioButtons
                  defaultValue={tracker}
                  onChange={(value) => onChangeField({ tracker: value })}
                />
              </Item>
            </Col>
            <Col span={9}>
              <Item
                label={`${capitalize(t('wms.priority.title', 'priority'))}`}
                className="label-modal-issue"
              >
                <IssuePriorityRadioButtons
                  defaultValue={priority}
                  onChange={(value) => onChangeField({ priority: value, ...getExpirationDate(value) })}
                />
              </Item>
            </Col>

            {!hideView.includes('assignedVector') && (
              <Col span={6}>
                <Item
                  className="label-modal-issue"
                >
                  <ListOfVectorsDropDownForIssueInfo
                    vectorUuid={assignedVector}
                    vectorTitle={title}
                    projectUuid={assignedProject}
                    projectUsers={projectUsers}
                    onChangeFieldCallback={(vectorData) => {
                      selectedVectorCallback({
                        assignedVector: vectorData.vector,
                        selectedVectorData: vectorData.selectedVectorData,
                      })
                    }}
                    partitionType={partitionType}
                  />
                </Item>
              </Col>
            )}
          </Row>

          <Item
            className="label-modal-issue mb-4"
          >
            <Row className="flex items-center" gutter={16}>
              <Col span={23}>
                <Flex>
                  <AssigningUsersRoot
                    header="Assigning users"
                    // isOpen={isAssignOpen}
                    // setIsOpen={setIsAssignOpen}
                    isOpen={openUsersModal}
                    setIsOpen={() => setOpenUsersModal(prev => !prev)}
                    onUpdateUsers={(data) => {
                      setSelectedProjectUsers(data?.allUsers ?? []);
                      onChangeField({
                        users: data?.users?.map((el) => ({ uuid: el?.uuid })),
                        usersSearch: data?.users?.map((el) => el?.uuid),
                      });
                    }}
                    globalOptions={{
                      uuids: selectedProjectUsers?.map((el) => getUuid(el)) ?? [],
                      roles: selectedProjectUsers ?? [],
                      defaultColumn: selectedVectorData?.usersSearch?.length ? 'vector' : 'project',
                    }}
                    needUpdateProjectUsers
                    placement="left"
                    isPopover
                    tabs={{
                      allConfig: {
                        label: 'All',
                        value: 'all',
                        textSelectButton: 'Add to project/issue',
                        showSections: ['button-add-user'],
                      },
                      projectConfig: {
                        label: 'Project',
                        value: 'project',
                        textSelectButton: 'Add to issue',
                        defaultItems: selectedProjectUsers ?? [],
                        showSections: ['string-role', 'button-add-user'],
                      },
                      vectorConfig: {
                        label: 'Vector',
                        value: 'vector',
                        textSelectButton: 'Add to issue',
                        defaultItems: selectedVectorData?.usersSearch ?? [],
                        showSections: ['string-role', 'button-add-user'],
                      },
                    }}
                    usersConfig={{
                      label: 'Issue users',
                      showSections: ['string-role', 'button-remove-user'],
                      textDeleteButton: 'Remove users',
                      defaultItems: [...(usersSearch ?? [])],
                    }}
                    popoverText="Assigned users"
                    projectUuid={assignedProject}
                    partition={partitionType}
                  />

                  <WrapperAvatarsGroupForProjectWithHook
                    actors={usersSearch || []}
                    avatarSize={28}
                    popoverTrigger="click"
                    popoverPlacement="right"
                    checkProjectRole
                  />
                </Flex>
              </Col>
              <Col span={4}>
                {hideExpirationDate.includes(status) || (
                  <IssueEstimatedTimeDropDown
                    currentSelect={estimated_time}
                    dropDownCallback={(value) => onChangeField(
                      { estimated_time: value.estimated_time },
                    )}
                  />
                )}
              </Col>
            </Row>
          </Item>

          <Item
            label={`${capitalize(t('wms.status.title', 'status'))}:`}
            style={{ marginTop: -18 }}
            className="label-modal-issue mb-1"
          >
            {isShowMoreStatuses ? (
              <IssueStatusCollapse
                hideCollapse
                onChangeField={onChangeField}
                ticket={{ status }}
                partitionType={partitionType}
                hiddenModalAssigned
              />
            ) : (
              <NewIssueStatus
                onChangeFieldCallback={onChangeField}
                value={status}
              />
            )}
            <LineWithTextButton
              defaultMessage="All statuses"
              expandedMessage="Hide statuses"
              isExpanded={isShowMoreStatuses}
              onClickCallback={() => changeShowMoreStatuses((prev) => !prev)}
              wrapperStyle={{
                marginTop: -10,
              }}
            />
          </Item>
          <Item
            label={(
              <>
                {`${capitalize(t('wms.verb.upload'))}:`}

                <div
                  id='uploadFileInfoButton'
                  role="button"
                  tabIndex="0"
                  className="description-drag-and-paste ml-2"
                  style={{ color: 'rgb(81, 165, 255)' }}
                  onClick={stopPropagation}
                >
                  <Tooltip
                    color="rgb(81, 165, 255)"
                    title={t('wms.labels.you_can_drag_or_click')}
                  >
                    <Icon
                      path={mdiHelpCircleOutline}
                      size={1}
                    />
                  </Tooltip>
                </div>
              </>
            )}
            className="label-modal-issue"
            style={{ marginTop: 0 }}
          >
            <AntUploaderFile
              sizeBtn="small"
              customFiles={files}
              customAudio={audioFiles}
              onChangeFileCallback={onChangeFiles}
              hideView={hideViewInUploadFile}
              customButtonName="Attach files"
            />

          </Item>

          {!hideView.includes('assignedProject') && (
            <Item
              label={capitalize(t('wms.labels.projects', 'projects'))}
              className="label-modal-issue w-full"
            >
              <SelectAssignedProject
                disabled={disabledView.includes('assignedProject')}
                partitionType={partitionType}
                customSelect={assignedProject}
                selectedProjectCallback={selectedProjectCallback}
              />
            </Item>
          )}
          <Flex align="center" justify="space-between">
            {hideExpirationDate.includes(status) ? <span />
              : (
                <Item
                  label="Dev exp."
                  className="label-modal-issue"
                >
                  <DateLeftEdit
                    id="lifeTime"
                    expirationDate={lifeTime}
                    onChangeFieldCallback={({ lifeTime }) => onChangeField({
                      lifeTime,
                    })}
                  />
                </Item>
              )}
            <BaseButton
              id='submitFormButton'
              htmlType="submit"
              onClick={onFinish}
              className="btnPrimary-outline"
            >
              {capitalize(t('wms.buttons.submit', 'submit'))}
            </BaseButton>
          </Flex>
        </Form>
      </UploaderWrapperInView>
    </Spin>
  );
}

NewIssueForm.propTypes = {
  isAssignOpen: bool,
  setIsAssignOpen: func,
  onChangeField: func,
  handleFormSubmit: func,
  hideView: array,
  partitionType: string,
  description: string,
  status: string,
  tracker: string,
  priority: string,
  estimated_time: number,
  assignedProject: string,
  assignedVector: string,
  onChangeFiles: func,
  getInputValueAndChangeField: func,
  selectedVectorCallback: func,
  selectedProjectCallback: func,
  disabledView: array,
  files: array,
  loading: bool,
  setLoading: func,
  audioFiles: array,
  tags: array,
  selectedVectorData: object,
  projectUsers: array,
  isRecurring: bool
};

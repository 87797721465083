import DiffMatchPatch from 'diff-match-patch';
import sizeof from 'object-sizeof';

import {
  updateDocumentStateRequest,
  updateDocumentPatchRequest,
} from '../../../../actions/DocumentsActions';

import {
  createNewStateID,
  createNewPatchID,
  getStateID,
  getPatchNumber,
} from './idHelpers';

const ACTION = {
  PATCH: 'PATCH',
  STATE: 'STATE',
  DEFAULT_STATE: 'DEFAULT_STATE',
  NONE: 'NONE',
};

const dmp = new DiffMatchPatch();

export function getPatchingAction(oldContent, newContent, actualState, type) {
  const hasActualState = actualState && actualState !== '0';

  // console.log('actualState:', actualState);
  // console.log('hasActualState:', hasActualState);

  if (hasActualState) {
    const patch = dmp.patch_make(oldContent, newContent);
    // console.log('PATCH:', patch);

    // Patch has data
    if (patch.length) {
      // Calculate patch size (too big -> new state, small -> new patch)
      const sizeOfPatch = (sizeof(patch) / 1024).toFixed(2);
      // console.log(`Patch size: ${sizeOfPatch} kB`);
      const sizeOfContent = (sizeof(oldContent) / 1024).toFixed(2);
      // console.log(`Text size: ${sizeOfContent} kB`);

      const patchNumber = getPatchNumber(actualState);
      const isPatchLimitExceeded = patchNumber + 1 > 20;

      const isNeedCreateState = (sizeOfContent < 20 && sizeOfPatch > 20)
      || (sizeOfContent > 20 && sizeOfPatch > (0.5 * sizeOfContent).toFixed(2))
      || isPatchLimitExceeded;

      // Need create new state
      if (isNeedCreateState) {
        return { action: ACTION.STATE };
      }

      // Need create new patch
      return { action: ACTION.PATCH, patch };
    }

    // No changes
    return { action: ACTION.NONE };
  }

  // Needs create first default state
  return { action: ACTION.DEFAULT_STATE };
}

// eslint-disable-next-line object-curly-newline
export function getPatchingRequest(action, { docUUID, actualState, newContent = '', patch, createdBy = '', partition }) {
  switch (action) {
    case ACTION.PATCH: {
      // console.log('CASE PATCH');
      const newPatchID = createNewPatchID(actualState);

      const optionalDataConfig = {
        uuid: docUUID,
        patchID: newPatchID,
        stateID: getStateID(actualState),
        patchData: {
          patch,
          createdBy,
          timestamp: new Date().getTime(),
        },
        partition,
      };

      return {
        request: updateDocumentPatchRequest(optionalDataConfig),
        updatedActualState: newPatchID,
      };
    }

    case ACTION.STATE: {
      // console.log('CASE STATE');

      const newStateID = createNewStateID(actualState);

      const optionalDataConfig = {
        uuid: docUUID,
        stateID: newStateID,
        stateData: {
          createdBy,
          timestamp: new Date().getTime(),
          text: newContent,
        },
        partition,
      };

      return {
        request: updateDocumentStateRequest(optionalDataConfig),
        updatedActualState: newStateID,
      };
    }

    case ACTION.DEFAULT_STATE: {
      // console.log('CASE DEFAULT STATE');

      const optionalDataConfig = {
        uuid: docUUID,
        stateID: '1',
        stateData: {
          createdBy,
          timestamp: new Date().getTime(),
          text: newContent,
        },
        partition,
      };

      return {
        request: updateDocumentStateRequest(optionalDataConfig),
        updatedActualState: '1',
      };
    }

    case ACTION.NONE:
    default: {
      // console.log('CASE NONE');

      return {
        request: null,
        updatedActualState: actualState,
      };
    }
  }
}

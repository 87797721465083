import React, { useState } from 'react';
import { prop } from 'ramda';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { mdiPlusCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { capitalizeAndTranslateMsg } from '../../../MainUtils';
import { capitalize } from 'lodash';
import '../style/ListProjectCookbook.scss';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import CreateProjectOrVectorFromCommon from './CreateProjectOrVectorFromCommon';
import BaseModal from '../../../components/_ui/BaseModal/BaseModal';
import useActorsW54ModalStyle from '../../../actors/hooks/useActorsW54ModalStyle';

function CreateProjectOrVectorWrapper({
  entityType,
  disabledBtn,
  createEntities,
  toResetArea,
  parentProject,
  cookBookState,
  setCookBookState,
  defaultPartition,
}) {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const [isAssignOpen, setIsAssignOpen, styleModal] = useActorsW54ModalStyle();

  const handleCancelModalCreate = () => {
    setOpenModal(false);
  };

  const nameCreateBtn = () => {
    if (entityType === 'project') {
      const name = prop('entityType', cookBookState) !== 'vector'
        ? `${capitalizeAndTranslateMsg('wms.verb.create', 'Create')} ${t('wms.noun.project.0', 'project')}`
        : capitalize(t('wms.buttons.insert_project', 'insert to project'));

      return name;
    }
    return capitalize(t('wms.buttons.insert_project', 'insert to project'));
  };

  const toggleBtn = () => {
    if (entityType === 'project') {
      setOpenModal(true);
    } else {
      createEntities(defaultPartition, parentProject.uuid);
      toResetArea();
    }
  };

  return (
    <>
      <BaseButton
        className="btnPrimary"
        size="small"
        onClick={toggleBtn}
        disabled={disabledBtn}
      >
        <Icon
          className="mr-1"
          path={mdiPlusCircleOutline}
          size={0.8}
        />

        {nameCreateBtn()}
      </BaseButton>

      {openModal
        && (
          <BaseModal
            open={openModal}
            width={800}
            style={styleModal}
            blockPropagation
            centered
            footer={null}
            handleCancel={handleCancelModalCreate}
          >

            <CreateProjectOrVectorFromCommon
              isAssignOpen={isAssignOpen}
              setIsAssignOpen={setIsAssignOpen}
              createEntities={createEntities}
              cookBookState={cookBookState}
              setCookBookState={setCookBookState}
              toResetArea={toResetArea}
              handleCancelModalCallback={handleCancelModalCreate}
            />

          </BaseModal>
        )}
    </>
  );
}

CreateProjectOrVectorWrapper.propTypes = {
  entityType: PropTypes.string,
  disabledBtn: PropTypes.bool,
  createEntities: PropTypes.func,
  parentProject: PropTypes.object,
  cookBookState: PropTypes.object,
  setCookBookState: PropTypes.func,
  defaultPartition: PropTypes.string,
  toResetArea: PropTypes.func,
};

export default CreateProjectOrVectorWrapper;

import React from 'react';
import { Card } from 'antd';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './BaseCard.scss';

function BaseCard({ className, children, ...props }) {
  return (
    <Card
      className={cn('base-card', className)}
      {...props}
    >
      {children}
    </Card>
  );
}

export default BaseCard;

BaseCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

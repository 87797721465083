import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { mdiChevronUp } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { capitalizeAndTranslateMsg } from '../../../MainUtils';

function QaCollapseControlBtn({
  toggleCollapse,
  collapse,
}) {
  const { t } = useTranslation();

  return (
    <div className="wrapper_horizontal_collapse_btn absolute_in_main_card">
      <div
        className="flex items-center show_more_info_btn"
        onClick={toggleCollapse}
      >
        {capitalizeAndTranslateMsg(`wms.verb.${collapse ? 'hide' : 'show'}`, 'Hide')}
        {' '}
        {t('wms.noun.info.1', 'info')}
        <Icon
          path={mdiChevronUp}
          size={1}
          rotate={!collapse ? 540 : 0}
        />
      </div>
    </div>
  );
}

export default QaCollapseControlBtn;

QaCollapseControlBtn.propTypes = {
  collapse: PropTypes.bool,
  toggleCollapse: PropTypes.func,
};

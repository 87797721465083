import { ProjectTypeCommonConstants } from '../../constants/Constants';

const initialState = {
  fetching: false,
  fetchingSharedTickets: false,
  tickets: [],
  sharedTickets: [],
  error: '',
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ProjectTypeCommonConstants.FETCH_LIST_OF_PUBLIC_TICKETS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.FETCH_LIST_OF_PUBLIC_TICKETS_SUCCESS:
      return {
        ...state,
        fetching: false,
        tickets: payload,
      };
    case ProjectTypeCommonConstants.FETCH_LIST_OF_PUBLIC_TICKETS_FAILURE:
      return {
        ...state,
        fetching: false,
        tickets: [],
        error: '',
      };

    case ProjectTypeCommonConstants.CLEAR_LIST_OF_PUBLIC_TICKETS:
      return {
        ...state,
        tickets: [],
      };
    case ProjectTypeCommonConstants.FETCH_LIST_OF_PRIVATE_TICKETS_REQUEST:
      return {
        ...state,
        fetchingSharedTickets: true,
      };
    case ProjectTypeCommonConstants.FETCH_LIST_OF_PRIVATE_TICKETS_SUCCESS:
      return {
        ...state,
        fetchingSharedTickets: false,
        sharedTickets: payload,
      };
    case ProjectTypeCommonConstants.FETCH_LIST_OF_PRIVATE_TICKETS_FAILURE:
      return {
        ...state,
        fetchingSharedTickets: false,
        sharedTickets: [],
        error: '',
      };
    case ProjectTypeCommonConstants.CLEAR_LIST_OF_PRIVATE_TICKETS:
      return {
        ...state,
        sharedTickets: [],
      };

    default: return state;
  }
};

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  path,
  prop,
} from 'ramda';
import { useTranslation } from 'react-i18next';
import { mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';

import './style/BreadCrumbs.scss';

import { capitalize } from 'lodash';

function BreadCrumbsNEW({
  breadCrumbsConfig = {},
  menuCallback,
  onClickCallback,
  hideBreadcrumbs,
}) {
  const { t } = useTranslation();

  const location = useLocation();

  const locationPath = location.pathname.split('/');

  const crumbsConfig = {
    cookbook: {
      crumbs: [
        {
          label: capitalize(
            t('wms.sidemenu.projectflow.header.full', 'project flow'),
          ),
        },
        {
          label: capitalize(
            t('wms.sidemenu.projectflow.cookbook', 'cookbook'),
          ),
        },
      ],
    },
    projects: {
      crumbs: [
        {
          label: capitalize(
            t('wms.sidemenu.projectflow.header.full', 'project flow'),
          ),
        },
        {
          label: capitalize(
            t('wms.sidemenu.projectflow.projects', 'projects'),
          ),
          link: 'pm/projects',
        },
      ],
    },
    permissions: {
      crumbs: [
        {
          label: capitalize(
            t('wms.sidemenu.tools.header', 'tools'),
          ),
        },
        {
          label: capitalize(
            t('wms.sidemenu.tools.permissions', 'permissions'),
          ),
        },
      ],
    },
    users: {
      crumbs: [
        {
          label: capitalize(
            t('wms.sidemenu.tools.header', 'tools'),
          ),
        },
        {
          label: t('wms.sidemenu.tools.users', 'Users'),
        },
      ],
    },
    reports: {
      crumbs: [
        {
          label: capitalize(
            t('wms.sidemenu.tools.header', 'tools'),
          ),
        },
        {
          label: capitalize(
            t('wms.sidemenu.tools.reports', 'reports'),
          ),
        },
      ],
    },
    team: {
      crumbs: [
        {
          label: capitalize(
            t('wms.sidemenu.teamflow.header.full', 'team flow'),
          ),
        },
      ],
      teamboards: {
        crumbs: [
          {
            label: capitalize(
              t('wms.sidemenu.teamflow.boards', 'team boards'),
            ),
            link: 'pm/team/teamboards',
          },
        ],
      },
    },
    user: {
      crumbs: [
        {
          label: capitalize(
            t('wms.sidemenu.userflow.header.full', 'user flow'),
          ),
        },
      ],
      dashboard: {
        crumbs: [
          {
            label: capitalize(
              t('wms.projectflow.breadcrumbs.dashboard', 'dashboard'),
            ),
          },
        ],
      },
      public: {
        crumbs: [
          {
            label: capitalize(
              t('wms.sidemenu.userflow.public_board', 'public board'),
            ),
          },
        ],
      },
      private: {
        crumbs: [
          {
            label: capitalize(
              t('wms.sidemenu.userflow.private_board', 'private board'),
            ),
          },
        ],
      },
    },
    ...breadCrumbsConfig,
  };

  const getTitles = (crumb) => {
    const onClickValue = prop('onClickValue', crumb);
    if (prop('link', crumb)) {
      return (
        <div className="breadCrumbs_crumb">
          {crumb.title && (
            <p className="breadCrumbs_crumb-title">{crumb.title}</p>
          )}
          <div className="breadCrumbs_crumb-card">
            <Link to={`/${crumb.link}`}>
              {crumb.label}
            </Link>
            {crumb.menu ? crumb.menu : null}
          </div>
        </div>
      );
    }

    return (
      <div className="breadCrumbs_crumb">
        {crumb.title && (
          <p className="breadCrumbs_crumb-title">{crumb.title}</p>
        )}
        <div className="breadCrumbs_crumb-card">
          <p
            className={onClickValue ? 'clickable' : ''}
            onClick={onClickValue ? () => onClickCallback(onClickValue) : null}
          >
            {crumb.label}
          </p>
          {crumb.menu ? crumb.menu(menuCallback) : null}
        </div>
      </div>
    );
  };

  const getCrumbs = () => {
    const structure = locationPath.reduce(
      (acc, item) => (path([...acc, item], crumbsConfig)
        ? [...acc, item]
        : [...acc]),
      [],
    );

    const allCrumbs = structure.reduce((acc, item, id) => {
      const crumbs = path([...structure.slice(0, id + 1), 'crumbs'], crumbsConfig);
      return crumbs ? [...acc, ...crumbs] : [...acc];
    }, []);

    const arrow = (
      <div className="breadCrumbs_wrapper-arrow">
        <Icon path={mdiArrowRight} size={0.75} />
      </div>
    );
    // console.log('locationPath',locationPath);
    return allCrumbs.map((crumb, crumbID) => (
      <div
        key={`breadcrumbs_${crumb.label}_${crumbID}`}
        className="breadCrumbs_wrapper"
      >
        {getTitles(crumb)}
        {crumbID !== allCrumbs.length - 1 && arrow}
      </div>
    ));
  };

  return (
    <>
      {!hideBreadcrumbs && (
        <div className="breadCrumbs mb-3">
          {getCrumbs()}
        </div>
      )}
    </>
  );
}

export default BreadCrumbsNEW;

BreadCrumbsNEW.propTypes = {
  breadCrumbsConfig: PropTypes.object,
  menuCallback: PropTypes.func,
  onClickCallback: PropTypes.func,
};

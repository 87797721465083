import Icon from '@mdi/react';
import {
  mdiInformationOutline,
} from '@mdi/js';
import { Tooltip } from 'antd';
import './index.scss';
import { UI54Icon } from "@agpl/ui54kit";

export default function InfoIcon({ title, tooltipText }) {
  return (
    <div
      style={{
        backgroundColor: '#f4f9ffd0', borderRadius: '4px', userSelect: 'none', whiteSpace: 'nowrap',
      }}
      className="flex items-center justify-center"
    >
      {title}
      <Tooltip
        placement="top"
        mouseEnterDelay={0.5}
        title={tooltipText}
      >
        <UI54Icon
          className="ml-1 cursor-pointer infoIcon"
          path={mdiInformationOutline}
          size={0.9}
          color="#51a4fc77"
        />
      </Tooltip>
    </div>

  );
}

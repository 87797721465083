import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from "reselect";

const initialState = {
  milestoneFetching: false,
  fetching: true,
  newMilestoneFetching: false,
  listOfMilestones: [],
  newMilestoneData: [],
  milestoneData: [],
  totalMilestones: 0,
};

const projectMilestonesSlicer = createSlice({
  name: 'PROJECT_MILESTONES',
  initialState,
  reducers: {
    LIST_OF_PROJECT_MILESTONES_FETCHING(state) {
      state.fetching = true;
    },
    LIST_OF_PROJECT_MILESTONES_SUCCESS(state, action) {
      state.fetching = false;
      state.listOfMilestones = action.payload;
      state.totalMilestones = action.total;
    },
    LIST_OF_PROJECT_MILESTONES_FAILURE(state) {
      state.fetching = false;
      state.listOfMilestones = [];
      state.listOfMilestones = 0;
    },

    CREATE_PROJECT_MILESTONES_FETCHING(state) {
      state.milestoneFetching = true;
    },
    CREATE_PROJECT_MILESTONES_SUCCESS(state, action) {
      state.milestoneFetching = false;
      state.newMilestoneData = action.payload;
    },
    CREATE_PROJECT_MILESTONES_FAILURE(state) {
      state.milestoneFetching = false;
      state.newMilestoneData = [];
    },

    PROJECT_MILESTONE_FETCHING(state) {
      state.milestoneFetching = true;
    },
    PROJECT_MILESTONE_SUCCESS(state, action) {
      state.milestoneFetching = false;
      state.milestoneData = action.payload;
    },
    PROJECT_MILESTONE_FAILURE(state) {
      state.milestoneFetching = false;
      state.milestoneData = [];
    },

    CLEAR_ALL_PROJECT_MILESTONES(state) {
      state.milestoneFetching = false;
      state.fetching = false;
      state.newMilestoneFetching = false;
      state.listOfMilestones = [];
      state.newMilestoneData = [];
      state.milestoneData = [];
      state.totalMilestones = 0;
    },
    CLEAR_PROJECT_MILESTONE(state) {
      state.milestoneFetching = false;
      state.milestoneData = [];
    },
  },
});

const storeProjectPath = (state) => state?.projectManagementService?.project;

export const getProjectMilestonesFetching = (state) => storeProjectPath(state)?.milestones?.fetching;
export const getProjectMilestones = (state) => storeProjectPath(state)?.milestones?.listOfMilestones || [];
export const getNewProjectMilestoneFetching = (state) => storeProjectPath(state)?.milestones?.newMilestoneFetching;
export const getTotalMilestones = (state) => storeProjectPath(state)?.milestones?.totalMilestones;

export const getProjectMilestoneFetching = (state) => storeProjectPath(state)?.milestones?.milestoneFetching;
export const getProjectMilestone = (state) => storeProjectPath(state)?.milestones?.milestoneData || [];
export const getProjectMilestoneUUID = (state) => storeProjectPath(state)?.milestones?.milestoneData[0]?.uuid;
// export const selectLastCreatedMilestone = (state) => {
//   const milestones = getProjectMilestones(state);
//   if (milestones.length === 0) return {};
//   return milestones[0];
// };

export const selectLastCreatedMilestone = createSelector(getProjectMilestones, (milestones) => milestones?.length === 0 ? {} : milestones[0])


export const {
  LIST_OF_PROJECT_MILESTONES_FETCHING,
  LIST_OF_PROJECT_MILESTONES_SUCCESS,
  LIST_OF_PROJECT_MILESTONES_FAILURE,
  CREATE_PROJECT_MILESTONES_FETCHING,
  CREATE_PROJECT_MILESTONES_SUCCESS,
  CREATE_PROJECT_MILESTONES_FAILURE,
  PROJECT_MILESTONE_FETCHING,
  PROJECT_MILESTONE_SUCCESS,
  PROJECT_MILESTONE_FAILURE,
  CLEAR_ALL_PROJECT_MILESTONES,
  CLEAR_PROJECT_MILESTONE,
} = projectMilestonesSlicer.actions;

export default projectMilestonesSlicer.reducer;

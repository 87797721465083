import Icon from '@mdi/react';
import { Button, Modal, Radio } from 'antd';
import React, { useReducer, useState } from 'react';
import { mdiPencil } from '@mdi/js';
import { func, object, string } from 'prop-types';

import {
  getNameForActor,
  stopPropagation,
} from '../../54origins/utils54origins';
import useAuthActorsRequests from '../hooks/useAuthActorsRequests';
import useAuthSessionHook from '../hooks/useAuthSessionHook';
import { antNotification } from '../../MainUtils';

export function InternalModalBtn({
  btnClassName,
  btnSize,
  actor,
  onSaveCallBack,
}) {
  const [modalVisible, changeModalVisible] = useReducer((state) => !state, false);

  const { checkAuthSessionAndRunFuncForRestart } = useAuthSessionHook(changeModalVisible);

  const {
    actor_type,
    uinfo,
    actor: actorUUID,
  } = actor;

  const showModal = async () => {
    checkAuthSessionAndRunFuncForRestart(true);
  };

  const onCancel = () => {
    changeModalVisible();
  };

  const { requestUpdateActorOnAuth, requestGetActorOnAuth } = useAuthActorsRequests();

  const [currentValue, setCurrentValue] = useState(uinfo?.internal_user || false);
  const [isChanged, setIsChanged] = useState(false);

  const onChange = ({ target: { value } }) => {
    setCurrentValue(value);
    if (!isChanged) {
      setIsChanged(true);
    }
  };

  const onSave = () => {
    requestGetActorOnAuth({
      uuid: actorUUID,
    }).then((resActor) => {
      const {
        uinfo: {
          email,
          login,
          phone_number,
          first_name,
          last_name,
          password,
        } = {},
      } = resActor?.actor || {};

      requestUpdateActorOnAuth({
        actor_type,
        uinfo: {
          first_name,
          last_name,
          password,
          internal_user: currentValue,
          ...(email ? { email } : {}),
          ...(login ? { login } : {}),
          ...(phone_number ? { phone_number } : {}),
        },
        uuid: actorUUID,
      }).then(() => {
        antNotification('success', 'Success');

        onCancel();
        onSaveCallBack();
      }).catch((err) => {
        // eslint-disable-next-line no-use-before-define
        checkRequestErrorAndGetAuthSession(err);
      });
    });
  };

  const { checkRequestErrorAndGetAuthSession } = useAuthSessionHook(onSave);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      role="cell"
      onClick={stopPropagation}
      onDoubleClick={stopPropagation}
    >
      <Modal
        title={getNameForActor(actor)}
        open={modalVisible}
        onCancel={onCancel}
        destroyOnClose
        width="500px"
        centered
        footer={null}
      >
        <div>
          <div className="flex items-center">
            <h6 className="mb-0">Change type actor to:</h6>
            <Radio.Group
              className="ml-2"
              value={currentValue}
              buttonStyle="solid"
              onChange={onChange}
            >
              <Radio.Button value>Internal</Radio.Button>
              <Radio.Button value={false}>External</Radio.Button>
            </Radio.Group>
          </div>
          <hr className="mb-2" />
          <div className="flex justify-end">
            <Button
              key="submit"
              type="primary"
              disabled={!isChanged}
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <Button
        onClick={showModal}
        size={btnSize}
        className={`${btnClassName} btnPrimary-outline actorsModalBtn`}
      >
        <Icon
          size={0.8}
          className="mr-1"
          path={mdiPencil}
        />
        <span>Internal</span>
      </Button>
    </div>
  );
}

InternalModalBtn.propTypes = {
  btnClassName: string,
  btnSize: string,
  actor: object,
  onSaveCallBack: func,
};

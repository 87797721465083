import { ProjectTypeCommonConstants } from "../../constants/Constants";

const initialState = {
  fetching: false,
  recurringIssues: [],
  error: '',
  uuidsFromMultiSelect: [],
  flagForMultiSelect: false,
  allProjectIssues: [],
  scoringData: {
    total: 0,
    avgVal: '-',
    sum: 0,
    background: '',
  },
  outdatedScoring: '-',
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ProjectTypeCommonConstants.LIST_OF_RECURRING_ISSUES_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.LIST_OF_RECURRING_ISSUES_SUCCESS:
      return {
        ...state,
        fetching: false,
        recurringIssues: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_RECURRING_ISSUES_FAILURE:
      return {
        ...state,
        fetching: false,
        recurringIssues: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.SET_UUIDS_FOR_MULTI_SELECT:
      return {
        ...state,
        uuidsFromMultiSelect: payload,
      };
    case ProjectTypeCommonConstants.SET_FLAG_FOR_MULTI_SELECT:
      return {
        ...state,
        flagForMultiSelect: payload,
      };

    case ProjectTypeCommonConstants.NEW_RECURRING_ISSUE_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.NEW_RECURRING_ISSUE_SUCCESS:
      return {
        ...state,
        fetching: false,
        recurringIssues: state.recurringIssues,
      };
    case ProjectTypeCommonConstants.NEW_RECURRING_ISSUE_FAILURE:
      return {
        ...state,
        fetching: false,
        recurringIssues: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.CLEAR_LIST_OF_ISSUES:
      return {
        ...state,
        fetching: false,
        recurringIssues: [],
        error: '',
      };

    case ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_REQUEST:
      return {
        ...state,
        // fetching: true,
      };
    case ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_SUCCESS:
      return {
        ...state,
        // fetching: false,
        allProjectIssues: payload,
      };
    case ProjectTypeCommonConstants.GET_ALL_PROJECT_ISSUES_FAILURE:
      return {
        ...state,
        // fetching: false,
        allProjectIssues: [],
        error: payload,
      };
    case ProjectTypeCommonConstants.UPDATE_ISSUES_PRIORITY_INDEXES_DATA:
      const {
        total, avgVal, sum, background,
      } = payload;
      // console.log('UPDATE_ISSUES_PRIORITY_INDEXES_DATA', sum, total, avgVal);
      const finalAvgVal = avgVal !== '-' ? Number(avgVal) : avgVal;
      return {
        ...state,
        allProjectIssues: [],
        scoringData: {
          total,
          avgVal: finalAvgVal,
          sum,
          background,
        },
      };
    case ProjectTypeCommonConstants.CLEAR_ISSUES_SCORING_DATA:
      return {
        ...state,
        allProjectIssues: [],
        outdatedScoring: '-',
        scoringData: {
          total: 0,
          avgVal: '-',
          sum: 0,
          background: '',
        },
      };

    case ProjectTypeCommonConstants.UPDATE_ISSUES_OUTDATED_INDEXES_DATA: {
      // console.log('GET_CURRENT_PROJECT_ISSUES_SCORING payload', payload);
      return { ...state, outdatedScoring: payload };
    }

    // case ProjectTypeCommonConstants.GET_CURRENT_PROJECT_ISSUES_SCORING: {
    //   // console.log('GET_CURRENT_PROJECT_ISSUES_SCORING payload', payload);
    //   return { ...state };
    // }
    // case ProjectTypeCommonConstants.UPDATE_CURRENT_PROJECT_ISSUES_SCORING: {
    //   // console.log('UPDATE_CURRENT_PROJECT_ISSUES_SCORING payload', payload);
    //   return { ...state };
    // }

    default:
      return state;
  }
};

export const listOfRecurringIssuesFromStore = (state) => state?.projectManagementService?.project?.recurringIssues?.recurringIssues || [];

export const listOfRecurringIssuesFetching = (state) => state?.projectManagementService?.project?.recurringIssues?.fetching || false;

export const uuidsSelectedRecurringIssuesFromStore = (state) => state?.projectManagementService?.project?.recurringIssues?.uuidsFromMultiSelect || [];

export const flagMultiEditRecurringIssue = (state) => state?.projectManagementService?.project?.recurringIssues?.flagForMultiSelect || false;

export const getRecurringIssuesScoringData = (state) => state?.projectManagementService?.project?.recurringIssues?.scoringData || null;

export const getRecurringIssuesOutdatedData = (state) => state?.projectManagementService?.project?.recurringIssues?.outdatedScoring;

// export const issuesScoringData = (state) =>   state?.projectManagementService?.project?.issues?.

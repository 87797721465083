import { EntityConstants } from '../constants/Constants';

export const clearEntityPermissions = { type: EntityConstants.CLEAR_ENTITY_PERMS };

export const clearActorsFetching = { type: EntityConstants.CLEAR_ACTORS_FETCHING };

export const clearActorsWithBanedUsers = { type: EntityConstants.CLEAR_USERS_WITH_BANED_USERS };

export const viewNotificationTrue = { type: EntityConstants.VIEW_NOTIFICATION_TRUE };
export const viewNotificationFalse = { type: EntityConstants.VIEW_NOTIFICATION_FALSE };

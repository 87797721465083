import { useReducer } from 'react';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const fetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'FETCH_SUCCESS':
      return {
        data: action.payload,
        loading: false,
        error: null,
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'CLEAR_FETCH':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const useFetchReducerHook = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const setLoading = () => dispatch({ type: 'FETCH_START' });
  const setData = (data) => dispatch({ type: 'FETCH_SUCCESS', payload: data });
  const setError = (error) => dispatch({ type: 'FETCH_ERROR', payload: error?.message });
  const setClearData = () => dispatch({ type: 'CLEAR_FETCH' });

  return ({
    ...state, dispatch, setLoading, setData, setError, setClearData
  });
};

export default useFetchReducerHook;

import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { string } from 'prop-types';
import { capitalize } from 'lodash';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import { getRootEntityPartition } from '../../../config/selectors/selectors';
import { getListAndPartialReadEntities } from '../../../entity/actions/entityActions';
import useURLParams from '../../../hooks/useURLParams';

const defaultConfigRequest = {
  params: {},
  limit: 50,
  offset: 0,
};

export default function ListOfProjectsForReports({ defaultPartition, title }) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams()

  const partition = useSelector((state) => getRootEntityPartition(state, defaultPartition));

  const [config, setConfig] = useState(defaultConfigRequest);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchData, setSearchData] = useState();

  const [dataTable, setDataTable] = useState([]);

  const {
    project,
  } = getURLParams();

  const columns = [
    {
      dataIndex: 'id',
      render: (cell) => (
        <div className="whitespace-nowrap text-xs">
          {cell ? `P${cell}` : ''}
        </div>
      ),
    },
    {
      dataIndex: 'title',
      render: (cell, row) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
          tooltip={get(row, 'uuid', '')}
        />
      ),
    },
  ];

  const rowClassName = (row) => {
    const data = `universal_table_row ${get(row, 'uuid') === project
      ? 'border'
      : ''
      }`;

    return data;
  };

  const onRow = (record) => ({
    onClick: () => {
      const { uuid } = record || {};
      if (project === uuid) {
        clearSearchParams(['project', 'partition']);
      } else {
        setSearchParams({ project: uuid, partition: defaultPartition });
      }
    },
  });

  const onSizePerPageTable = (data) => {
    setSizePerPage(data);
    setCurrentPage(1);
  };

  const changePaginationOptions = ({
    pageLimit,
    currentPage: page,
    offset,
  }) => {
    const data = {
      ...config,
      offset,
      limit: pageLimit,
    };
    setConfig(data);
    setCurrentPage(page);
  };

  const getListOfProjects = async () => {
    const configProjects = {
      data: {
        entity_type: 'project',
        parent: partition,
        depth: 0,
        params_fields: {
          id: 'id',
          title: 'title',
        },
        ...config,
        params: {
          type__not: ['ACTIVITIES_USER'],
        },
      },
      constants: ['GET_LIST_OF_PROJECTS_REQUEST', 'GET_LIST_OF_PROJECTS_SUCCESS', 'GET_LIST_OF_PROJECTS_FAILURE'],
      partition: defaultPartition,
    };

    if (searchData) {
      configProjects.data.search_data = {
        value: searchData,
        ignore_case: true,
        fields: {
          params: ['title', 'id'],
        },
      };
    }
    const res = await dispatch(getListAndPartialReadEntities(configProjects));
    setTotalCount(get(res, 'total', 0));
    setDataTable(get(res, 'data', []));
  };

  useEffect(() => {
    if (partition) {
      getListOfProjects();
    }
  }, [partition, defaultConfigRequest, searchData, config]);

  return (
    <div className="h-full overflowHidden pb-40">
      <div className="head-table-project-report">
        <h6 className="m-0 pb-1 whitespace-nowrap">
          {capitalize(title)}
        </h6>
        <div className="wrapper-search pl-1">
          <AntDesignSearchBox54origins
            onSearch={setSearchData}
            isFocus={true}
          />
        </div>
      </div>
      <div className="y-scroll scroll-style h-full px-2">
        <BaseTableWithPagination
          pageLimit={sizePerPage}
          useCustomPagination
          columns={columns}
          data={dataTable}
          total={totalCount}
          rowClasses="row_table_in_time_log"
          rowKey="uuid"
          newCurrentPage={currentPage}
          defaultPageLimit={100}
          paginationSize="small"
          headerClasses="header_table_blue"
          headerRowClassName="hidden"
          hideListSizeOption
          onRow={onRow}
          rowClassName={rowClassName}
          changePageLimit={onSizePerPageTable}
          getPaginationOptions={changePaginationOptions}
        />
      </div>
    </div>
  );
}

ListOfProjectsForReports.propTypes = {
  defaultPartition: string,
  title: string,
};

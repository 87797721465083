/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import {
  string, func, oneOf, object,
  bool,
} from 'prop-types';
import { get } from 'lodash';

import { mdiHelp, mdiDotsHorizontal } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Dropdown } from 'antd';

import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';

import { docStatusOptions } from '../constants/docsStatuses';
import { COMMENT_TYPE } from '../../comment/commentType';

import useURLParams from '../../../../hooks/useURLParams';
import useUpdateDocumentParameter from '../helpers/useUpdateDocumentParameter';

import './ActionsForDocumentsTable.scss';
import { partitionNamesConfig } from '../../../../api/appConfig';

const getDocumentType = (type) => {
  switch (type) {
    case 'projectSpreadsheet': return 'spreadsheet';
    case 'projectIdea': return 'note';
    case 'projectDocument':
    default: return 'document';
  }
};

function ActionsForDocumentsTable({
  entityUUID,
  entityInfo,
  partitionType,
  deleteCallback,
  refreshTableCallback,
  isNoUpdatePerm,
  isNoDeletePerm
}) {
  const { getURLParams } = useURLParams();
  const {
    activeDoc,
    activeNote,
    activeSheet,
  } = getURLParams();

  const noReduxRequest = entityUUID !== activeDoc
    && entityUUID !== activeNote
    && entityUUID !== activeSheet;

  const entityType = get(entityInfo, 'entity_type');
  const status = get(entityInfo, 'status');
  const documentLabel = getDocumentType(entityType);

  const { updateDocumentParameter } = useUpdateDocumentParameter({
    entityUUID,
    entityType,
    commentType: COMMENT_TYPE.TOTAL,
    partition: partitionType,
    noReduxRequest,
  });

  const handleSetStatus = (statusValue) => {
    updateDocumentParameter({ status: statusValue }, refreshTableCallback);
  };

  const items = [
    ...docStatusOptions().map((item) => ({
      key: item?.value,
      label: (
        <span className={`action-${item?.value}`}>{`Set status: ${item?.value}`}</span>
      ),
      icon: <Icon className={`action-icon-${item?.value}`} path={item?.icon || mdiHelp} size={0.9} />,      
      disabled: status === item?.value || isNoUpdatePerm,
      onClick: () => handleSetStatus(item?.value),
    })),
    {
      key: 'remove',
      label: (
        <HardDeleteEntityContainer
          disabled={isNoDeletePerm}
          className="ant-btn-flex btnDanger w-full"
          sizeBtn="small"
          type="entity"
          iconSize={0.8}
          name={documentLabel}
          entityType={entityType}
          entityUUID={entityUUID}
          checkHardDelete={false}
          partitionType={partitionType}
          // hideLabel
          hideTooltip
          actionForEntity={() => deleteCallback?.()}
        />),
      // danger: true,
    },
  ];

  return (
    <div
      role="button"
      onClick={(e) => e.stopPropagation()}
    >
      <Dropdown
        overlayClassName="document-actions-dropdown"
        menu={{ items }}
        trigger={['click']}
      >
        <Button
          className="rounded-2xl"
          size="small"
          disabled={false}
        >
          <Icon path={mdiDotsHorizontal} size={0.7} />
        </Button>
      </Dropdown>
    </div>
  );
}

ActionsForDocumentsTable.propTypes = {
  entityUUID: string,
  entityInfo: object,
  partitionType: oneOf([partitionNamesConfig.partition1, partitionNamesConfig.partition3]),
  deleteCallback: func,
  refreshTableCallback: func,
  isNoUpdatePerm: bool,
  isNoDeletePerm: bool,
};

export default ActionsForDocumentsTable;

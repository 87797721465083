import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Flex, Space, Tooltip } from "antd";
import { capitalize, get } from 'lodash';
import Icon from '@mdi/react';
import { mdiBookmarkMultipleOutline, mdiHelpCircleOutline, mdiMenu, mdiMenuOpen, mdiMenuClose } from "@mdi/js";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import "./Header.scss"

import FlatHeaderSidebar from '../FlatHeaderSidebar/FlatHeaderSidebar';
import ReportInfoMessage from '../ReportInfoMessage';
import MasqueradingTurnOffButton from '../../admin/components/MasqueradingTurnOffButton/MasqueradingTurnOffButton';
// import NotificationsDropDownContainer from '../../notifications/NotificationsDropDownContainer';
import LocaleSwitcher from '../localeSwitcher/LocaleSwitcher';
import UserDropdownPanel from '../userDropdownPanel/UserDropdownPanel';
import InterfacesListModal54Origins from '../../54origins/components/publicInterface54Origins/InterfacesListModal54Origins';
import ProjectName from '../ProjectName';
import BaseButton from "../_ui/BaseButton/BaseButton";
import BaseHeader from "../_ui/BaseHeader/BaseHeader";

import { appLabel, getSessionTokenFor } from '../../api/appConfig';
import { getOtherUserPublicProfileUUID } from '../../userFlow/store/selectors/selectors';
import { getMe } from '../../projectFlow/selectors/selectors';
import useURLParams from '../../hooks/useURLParams';
import { entityBackendURL } from '../../api/api';
import { AppModeSwitcher, useAppMode } from "../../context/AppModeProvider";


function Header({
  toggleMenu,
  menuOpen,
  fixed,
  headerHeight,
  className,
  sidebarWidth
}) {
  const { t } = useTranslation();

  const { navigate } = useURLParams();
  const { debug, changeDebug } = useAppMode();

  const getMeUUID = useSelector(getMe);
  const otherUserPublicProfileUUID = useSelector(getOtherUserPublicProfileUUID);


  const selectedWmsHelper = () => navigate('/startpage');
  const redirectToBookmark = () => navigate('/user/bookmarks');
  const redirectToProject = () => navigate('/pm/projects');

  const changeDebugAndRedirect = (value) => {
    changeDebug(value)
    redirectToProject()
  }

  return (
    <BaseHeader
      fixed={fixed}
      headerHeight={headerHeight}
      className={className}
    >
      <Flex
        align="center"
        className="h-full"
      >
        <FlatHeaderSidebar
          name={appLabel}
          link="/"
          minWidth={100}
          sidebarWidth={sidebarWidth}
          headerHeight={headerHeight}
          menuOpen={menuOpen}
        />

        <BaseButton
          id="menuToggleButton"
          icon={<Icon
            path={menuOpen ? mdiMenuClose : mdiMenuOpen}
            size={1.2}
          />}
          onClick={toggleMenu}
          style={{
            width: `${headerHeight}px`,
            height: `${headerHeight}px`,
          }}
          className="shrink-0"
        />

        <ProjectName />

        <Space size={0} className="ml-auto h-full header-space shrink-0">
          {getMeUUID && (
            <ReportInfoMessage />
          )}

          <MasqueradingTurnOffButton />

          <div
            onClick={selectedWmsHelper}
            id="wmsHelperButton"
            className="header-dropdown-wrapper"
          >
            <Tooltip
              // title={t('wms.buttons.WMS_helper', 'WMS helper')}
              title={`${appLabel} helper`}
            >
              <Icon
                path={mdiHelpCircleOutline}
                size={1}
              />
            </Tooltip>
          </div>

          <div
            onClick={redirectToBookmark}
            id="bookmarkRedirectionButton"
            className="header-dropdown-wrapper"
          >
            <Tooltip
              title={capitalize(t('wms.labels.redirect_to_bookmark'))}
            >
              <Icon
                path={mdiBookmarkMultipleOutline}
                size={1}
              />
            </Tooltip>
          </div>

          {/*{getMeUUID && <NotificationsDropDownContainer />}*/}

          <AppModeSwitcher value={debug} onChange={changeDebugAndRedirect} />

          <InterfacesListModal54Origins
            url={entityBackendURL()}
            sessionTokenName="Entity"
          />

          <LocaleSwitcher />

          {getSessionTokenFor.entity() && <UserDropdownPanel />}
        </Space>
      </Flex>
    </BaseHeader>
  );
}

export default memo(Header);

Header.propTypes = {
  menuOpen: PropTypes.bool,
  toggleMenu: PropTypes.func,
  fixed: PropTypes.bool,
  headerHeight: PropTypes.number,
  className: PropTypes.string,
};


import {projectFlowTypeFilterOptions, 
        projectFlowStatusFilterOptions, 
        projectFlowUsersFilterOptions,
        projectFlowMetricFilterOptions} from "../../../../constants/allFilterOptions";


const typeColumnFilter = {
    filters: projectFlowTypeFilterOptions,
    filterMode: 'tree',
    filterType: 'badge',
}

const statusColumnFilter = {
    filters: projectFlowStatusFilterOptions,
    filterMode: 'tree',
    filterType: 'badge',
  };

const usersColumnFilter = {
    filters: projectFlowUsersFilterOptions,
    filterMode: 'tree',
    filterType: 'badge',
  };

const metricColumnFilter = {
    filters: projectFlowMetricFilterOptions,
    filterMode: 'tree',
    filterType: 'badge',
  }; 

export const defaultFilterConfig = {
    partition: ['general'], 
    users: ['member'],
    status: null,
    metric: null,
}

export const columnsFilterConfig = {
    type: typeColumnFilter,
    status: statusColumnFilter,
    users: usersColumnFilter,
    metric: metricColumnFilter,
}
import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { capitalize, take } from 'lodash';

import {
  Card, Flex, Tag, Tooltip,
} from 'antd';

import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import GoToButton from '../../../../components/buttons/GoToButton';

import { getListAndPartialReadAffix } from '../../../../affix/affixActions';
import useFetchReducerHook from '../../../../hooks/useFetchReducerHook';
import { getNameForActor } from '../../../../54origins/utils54origins';
import { getColorForActor } from '../../../../54origins/components/CircleActorAvatar54origins';
import {
  defaultDate,
  dateDiff,
  dateToFormat2,
  dateToFormat10,
  transformDate,
  dateGetStartOf,
} from '../../../../54origins/dateFormats54origins';
import useURLParams from '../../../../hooks/useURLParams';
import BaseCard from "../../../../components/_ui/BaseCard/BaseCard";
import BaseTag from "../../../../components/_ui/BaseTag/BaseTag";

const defaultPaginationOptions = {
  currentPage: 1,
  pageLimit: 5,
  defaultPageLimit: 5,
  pageLimitList: [25],
};

function ProjectRecentTimeLogs({
  parent,
  partitionType,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getURLParams } = useURLParams();
  const { activeProject } = getURLParams();

  const {
    data: timeLogs,
    loading: loadingTimeLogs,
    setLoading,
    setData,
    setError,
  } = useFetchReducerHook();

  const [paginationState, setPaginationState] = useState(defaultPaginationOptions);

  const {
    currentPage,
    pageLimit,
    pageLimitList,
    defaultPageLimit,
  } = paginationState || {};

  const formatUser = (userInfo, actorUUID, maxLength = 20) => {
    const userName = getNameForActor(userInfo);
    const showTooltip = maxLength && userName.length > maxLength;

    return (
      <Tooltip
        title={userName}
        placement="right"
        trigger={showTooltip ? 'hover' : ''}
      >
        <BaseTag
          className="rounded-2xl"
          color={getColorForActor(actorUUID)}
          bordered={false}
        >
          <strong style={{ textShadow: '0 0 2px rgba(0, 0, 0, 0.4)' }}>
            {showTooltip ? `${take(userName, maxLength).join('')}...` : userName}
          </strong>
        </BaseTag>
      </Tooltip>
    );
  };

  const dateFormatter = (cell) => (
    <Tooltip title={dateToFormat10(cell)}>
      <div className="whitespace-nowrap flex items-center text-xs">
        <span className="inline-flex mr-1 color-blue">{dateDiff(transformDate(cell), 'days', defaultDate())}</span>
        <span>
          {t('wms.labels.days_ago')}
        </span>
      </div>
    </Tooltip>
  );

  const columns = [
    {
      dataIndex: 'uinfo',
      title: capitalize(t('wms.table.headers.name')),
      render: (cell, row) => {
        const { actor: actorUUID = '' } = row || {};
        return cell && formatUser(cell, actorUUID);
      },
    },
    {
      dataIndex: 'trackerTime',
      title: capitalize(t('wms.labels.tracker_time', 'tracker time')),
      render: (cell) => cell && (
        <BaseTag color="blue">{cell}</BaseTag>
      ),
    },
    {
      dataIndex: 'description',
      title: capitalize(t('wms.labels.description')),
      render: (cell) => cell && (
        <EntityParamTag
          value={capitalize(cell)}
          type="text"
          param="title"
          maxLength={15}
        />
      ),
    },
    {
      dataIndex: 'type',
      title: capitalize(t('wms.labels.created_in', 'created in')),
      render: (cell) => cell && (
        <EntityParamTag
          value={cell}
          type="badge"
          param="entity"
        />
      ),
    },
    {
      dataIndex: 'title',
      title: capitalize(t('wms.labels.entity_title', 'entity title')),
      render: (cell) => cell && (
        <EntityParamTag
          value={capitalize(cell)}
          type="value"
          param="title"
          maxLength={15}
        />
      ),
    },
    {
      dataIndex: 'created',
      title: capitalize(t('wms.table.headers.created', 'created')),
      render: dateFormatter,
    },
  ];

  const changePaginationState = (params) => {
    setPaginationState((prev) => ({
      ...prev,
      ...params,
    }));
  };

  const onChangePaginationHandler = (page) => {
    changePaginationState({ currentPage: page });
  };

  const fetchProjectTimeLogs = async () => {
    // const startDate = defaultDate().startOf('week').format('YYYY-MM-DD HH:mm:ss');
    // const endDate = defaultDate().endOf('week').format('YYYY-MM-DD HH:mm:ss');

    const fieldsConfig = {
      fields: {
        actor_uuid: 'actor',
        'actor.uinfo': 'uinfo',
        'entity.created': 'created',
      },
      params_fields: {
        title: 'title',
        type: 'type',
        description: 'description',
        trackerTime: 'trackerTime',
      },
    };

    const paginationConfig = {
      limit: pageLimit,
      offset: pageLimit * (currentPage - 1),
    };

    const sortConfig = {
      order_by: 'created',
      order: 'desc',
    };

    const config = {
      data: {
        entity_uuid: parent,
        affix_type: 'timeLog',
        depth: 0,
        // modified__gte: startDate,
        // modified__lte: endDate,
        ...paginationConfig,
        ...sortConfig,
        ...fieldsConfig,
      },
      partition: partitionType,
    };

    return dispatch(getListAndPartialReadAffix(config));
  };

  const fetchAndSetTimeLogs = async () => {
    setLoading();
    fetchProjectTimeLogs()
      .then((response) => setData(response))
      .catch((error) => setError(error));
  };

  useEffect(() => {
    if (parent) fetchAndSetTimeLogs();
  }, [parent, currentPage, pageLimit]);

  return (
    <BaseCard
      className="mb-3 borderR10 primaryCard"
      title={(
        <Flex gap="small" align="center" justify="space-between">
          <h6 className="m-0">Recent time logs:</h6>
          <GoToButton
            id="toProjectReportButton"
            label="Go to project report"
            pathToRedirect={`/tools/projectReports?startDate=${dateToFormat2(dateGetStartOf('month'))}&period=months&project=${activeProject}&partition=${partitionType}`}
          />
        </Flex>
      )}
    >
      <BaseTableWithPagination
        rowClassName="universal_table_row"
        headerRowClassName="universal_header_table_row"
        columns={columns}
        data={timeLogs?.data}
        total={timeLogs?.total}
        loading={loadingTimeLogs}
        useCustomPagination
        hideListSizeOption
        newCurrentPage={currentPage}
        pageLimit={pageLimit}
        defaultPageLimit={defaultPageLimit}
        pageSizeOptions={pageLimitList}
        // changePageLimit={}
        onChangePagination={onChangePaginationHandler}
      />
    </BaseCard>
  );
}

ProjectRecentTimeLogs.propTypes = {
  parent: string,
  partitionType: string,
};

export default ProjectRecentTimeLogs;

import { get, isNil } from 'lodash';
import { axiosRequestPost } from '../../api/apiAxios';
import {
  GET_ACTORS_W54_FAILURE, GET_ACTORS_W54_REQUEST, GET_ACTORS_W54_SUCCESS, SET_ACTORS_W54_FETCHING,
} from '../slices/actorsW54Slice';
import { getArrayForItem } from '../../MainUtils';
// import { getMyInfo } from '../../userFlow/store/action-creators/userActions';
import { actorIsRootOrAdmin } from '../../54origins/utils54origins';
import { useDispatch, useSelector } from 'react-redux';
import { getMyInfo } from '../../projectFlow/selectors/selectors';

export default function useActorsW54Api() {
  const dispatch = useDispatch();
  const myInfo = useSelector(getMyInfo)


  async function getActorsW54ByUUID({
    uuids = [],
    ...options
  }) {
    const data = {
      ...options,
    };

    const filteredUuid = getArrayForItem(uuids).filter((el) => !isNil(el));

    data.uuids = filteredUuid;

    return new Promise(() => {
      dispatch(SET_ACTORS_W54_FETCHING({ payload: filteredUuid }));
      dispatch(axiosRequestPost(
        'utility/actor/get',
        [
          GET_ACTORS_W54_REQUEST,
          GET_ACTORS_W54_SUCCESS,
          GET_ACTORS_W54_FAILURE,
        ],
        data,
      ));
    });
  }

  async function getListOfActorsW54ForAdmin(config, constants) {
    return new Promise(
      (resolve) => {
        dispatch(axiosRequestPost(
          'utility/actor/list',
          constants || [
            'GET_LIST_OF_ACTORSW54_FOR_ADMIN_REQUEST',
            {
              type: 'GET_LIST_OF_ACTORSW54_FOR_ADMIN_SUCCESS',
              payload: (res) => {
                resolve(res);
                return res;
              },
            },
            'GET_LIST_OF_ACTORSW54_FOR_ADMIN_FAILURE',
          ],
          {
            actor_type: ['classic_user', 'user'],
            ...config,
          },
        ));
      },
    );
  }

  async function getListOfActorsW54(config, constants = []) {
    // const myInfo = await dispatch(getMyInfo());

    const defaultConfig = {
      actor_type: ['classic_user', 'user'],
    }

    if (actorIsRootOrAdmin(myInfo)) {
      return new Promise((resolve, reject) => {
        dispatch(axiosRequestPost(
          'utility/actor/list',
          constants?.length || [
            'FETCH_LIST_OF_ACTORSW54_FOR_ADMIN_REQUEST',
            {
              type: 'FETCH_LIST_OF_ACTORSW54_FOR_ADMIN_SUCCESS',
              payload: (res) => {
                resolve(res);
                return res;
              },
            },
            'FETCH_LIST_OF_ACTORSW54_FOR_ADMIN_FAILURE',
          ],
          {
            ...defaultConfig,
            ...config,
          },
        ));
      });
    }

    if (!config?.uuids) {
      defaultConfig.group_uuids = get(myInfo, ['uinfo', 'groups'], []);
    }

    return new Promise((resolve, reject) => {
      dispatch(axiosRequestPost(
        'utility/actor/get',
        constants?.length || [
          'FETCH_LIST_OF_ACTORSW54_FOR_USER_REQUEST',
          {
            type: 'FETCH_LIST_OF_ACTORSW54_FOR_USER_SUCCESS',
            payload: (res) => {
              resolve(res);
              return res;
            },
          },
          'FETCH_LIST_OF_ACTORSW54_FOR_USER_FAILURE',
        ],
        {
          ...defaultConfig,
          ...config,
        },
      ));
    });
  };

  return {
    getActorsW54ByUUID,
    getListOfActorsW54ForAdmin,
    getListOfActorsW54,
  }
}

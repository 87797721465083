/* eslint-disable max-len */
import DiffMatchPatch from 'diff-match-patch';
import { isEmpty } from 'lodash';

import { getPatchNumber } from './idHelpers';
import { PATCHING_RESULT } from './patchingResultConstants';

const dmp = new DiffMatchPatch();

function compareNumbers(a, b) {
  return getPatchNumber(a[0]) - getPatchNumber(b[0]);
}

function preparePatches(patches) {
  if (typeof patches === 'object' && !Array.isArray(patches) && patches !== null) {
    // console.log('OBJECT');
    return Object.entries(patches).sort(compareNumbers);
  }
  if (Array.isArray(patches)) {
    // console.log('ARRAY');
    const resultArray = patches.map((element) => [element?.patch_id, element]);
    return resultArray.sort(compareNumbers);
  }
  return patches;
}

function buildDocumentContent(text, patches) {
  if (typeof text === 'string') {
    if (!isEmpty(patches)) {
      // console.log('has patches');
      // console.log('patches', patches);

      const patchEntities = preparePatches(patches);
      // console.log('patchEntities', patchEntities);

      let resultContent = text;
      const patchingStatuses = [];
      patchEntities.forEach((patchEntity) => {
        const [patchID, patchObj] = patchEntity;
        const { patch } = patchObj;

        const [patchedContent, patchingResult] = dmp.patch_apply(patch, resultContent);
        // console.log('patched content: ', patchedContent);
        resultContent = patchedContent;
        // console.log('result text: ', resultText);
        // console.log('patchingResult:', patchingResult);

        const isPatchAppliedSuccess = patchingResult.every((applied) => applied === true) && PATCHING_RESULT.SUCCESS;
        const isPatchAppliedFail = patchingResult.every((applied) => applied === false) && PATCHING_RESULT.FAIL;
        const isPatchAppliedPartial = (!isPatchAppliedSuccess && !isPatchAppliedFail) && PATCHING_RESULT.PARTIAL;
        const patchAppliedStatus = isPatchAppliedSuccess
        || isPatchAppliedFail
        || isPatchAppliedPartial;

        patchingStatuses.push(patchAppliedStatus);

        // Console log apply result og every patch
        // eslint-disable-next-line default-case
        switch (patchAppliedStatus) {
          case PATCHING_RESULT.SUCCESS: {
            console.log(`Patch ${patchID} was applied`);
            break;
          }
          case PATCHING_RESULT.FAIL: {
            console.log(`Patch ${patchID} was not applied`);
            break;
          }
          case PATCHING_RESULT.PARTIAL: {
            console.log(`Patch ${patchID} was applied partially`);
            break;
          }
        }
      });

      // console.log('patchingStatuses', patchingStatuses);

      // Get result of whole patching process
      const isPatchingResultSuccess = patchingStatuses.every((result) => result === PATCHING_RESULT.SUCCESS) && PATCHING_RESULT.SUCCESS;
      const isPatchingResultFail = patchingStatuses.every((result) => result === PATCHING_RESULT.FAIL) && PATCHING_RESULT.FAIL;
      const isPatchingResultPartial = (!isPatchingResultSuccess && !isPatchingResultFail) && PATCHING_RESULT.PARTIAL;
      const resultOfPatching = isPatchingResultSuccess || isPatchingResultFail || isPatchingResultPartial;

      return [resultContent, resultOfPatching];
    }

    // console.log('no patches');
    return [text, null];
  }

  // console.log('no initial text');
  return ['', null];
}

function buildSpreadsheetContent() {}

// eslint-disable-next-line consistent-return
function buildContent(text, patches, entityType) {
  if (entityType === 'projectDocument' || entityType === 'projectIdea') {
    return buildDocumentContent(text, patches);
  }
  if (entityType === 'projectSpreadsheet') {
    buildSpreadsheetContent();
  }
}

export default buildContent;

import { mdiBellOutline } from '@mdi/js';
import { string } from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { assignedToMeTag } from '../../../../../constants/commonConstants';
import { capitalizeAndTranslateMsg } from '../../../../../MainUtils';
import { getMe } from '../../../../../projectFlow/selectors/selectors';
import { getOtherUserPublicProfileActor } from '../../../../../userFlow/store/selectors/selectors';
import PersonalTaskManagementComponent from '../../../../../userFlow/components/PersonalTask/PersonalTaskManagementComponent';

function DashboardIssuesTableWrapper({
  otherUser,
}) {
  const myUuid = useSelector(getMe);
  const otheUserPublicActor = useSelector(getOtherUserPublicProfileActor);

  const resultActor = otherUser ? otheUserPublicActor : myUuid;

  const customDefaultSelectedTags = useMemo(() => (
    [
      {
        key: 'status',
        value: 'created',
        label: 'wms.status.created',
        className: 'switch-validation-section',
        icon: mdiBellOutline,
        keySection: 'validation',
      },
      {
        key: 'partition',
        value: 'project',
        label: capitalizeAndTranslateMsg('wms.noun.project.3', 'project'),
      },
      ...assignedToMeTag.map((i) => ({ ...i, value: resultActor })),
    ]), [resultActor]);
  return (
    <PersonalTaskManagementComponent
      view="userDashboard"
      customDefaultSelectedTags={customDefaultSelectedTags}
      customActorUuid={otherUser && otheUserPublicActor}
    />
  );
}

DashboardIssuesTableWrapper.propTypes = {
  otherUser: string,
};

export default DashboardIssuesTableWrapper;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spin, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { get, isEmpty } from 'lodash';
import NewTestCycleExecutionForm from './NewTestCycleExecutionForm';
import ExistTestCycleExecutionForm from './ExistTestCycleExecutionForm';

import { capitalize } from 'lodash';
import { getProjectUUID, getTestCycleListForSelect } from '../../../selectors/selectors';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { createTestCaseForCycle, getListOfTestCyclesFunc } from '../../../actions/QaActions';
import { getCurrentArchiveStateUUID } from '../../../selectors/archiveStateSelectors';
import { getListAndReadEntities, incrementAndGetCount } from '../../../../entity/actions/entityActions';
import NewTestCycleExecutionFormQa from "./NewTestCycleExecutionFormQa";

const { TabPane } = Tabs;

function NewExecutionForm({
  customTitle,
  handleOk,
  typeOfParent,
  customTestCases,
  parentUuid,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const testCycleOptions = useSelector(getTestCycleListForSelect);
  const projectUUID = useSelector(getProjectUUID);
  const archiveStateUUID = useSelector(getCurrentArchiveStateUUID);
  // console.log(testCycleOptions)
  const { selectTestCycle, partitionType,  getListOfTestCycles } = useContext(BugTrackerContext);

  const [newTestCycleExecutionContainerData, setNewTestCycleExecutionContainerData] = useState('');
  const [existTestCycleExecutionContainerData, setExistTestCycleExecutionContainerData] = useState('');
  const [adHocTestCycleExecutionContainerData, setAdHocTestCycleExecutionContainerData] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [customListCases, setCustomListCases] = useState({ option: [], cases: [] });

  const finalParentForQA = archiveStateUUID || projectUUID;

  const getListCases = () => {
    const data = {
      entity_type: 'testCase',
      parent: parentUuid,
      params: {},
    };

    if (customTestCases && !isEmpty(customTestCases)) {
      data.entities = customTestCases;
    }
    // console.log(data)
    return dispatch(getListAndReadEntities({
      data,
      partition: partitionType,
      constants: ['LIST_CASE_REQUEST', 'LIST_CASE_SUCCESS', 'LIST_CASE_FAILURE'],
    }));
  };

  const createNewTestCaseForCycle = async (listCase, testCycle, assignUsers) => {
    // console.log('listCase', listCase);
    const listCases = await Promise.all(
      listCase?.map(async (item) => {
        const { increment } = await dispatch(incrementAndGetCount({
          entity_uuid: get(testCycle, 'uuid'),
          field_name: 'case',
        }, partitionType));

        return ({
          ...item.params,
          status: 'unexecuted',
          cycle: get(testCycle, 'uuid'),
          assignToUser: assignUsers,
          steps: item.params.steps.map(
            (step) => ({ ...step, status: 'unexecuted', actual: null }),
          ),
          id: `${get(testCycle, 'params.id', 0)}C${increment}`,
          order: increment,
          closedCase: false,
          template: false,
          executed_by: null,
          executed_on: null,
          testCaseUuidFromSuite: [item.uuid],
          uuidFromSuite: item.parent,
          counterBug: 0,
        });
      }),
    );

    const createParams = {
      parent: get(testCycle, 'uuid'),
      params: listCases,
      partition: partitionType,
    };

    return dispatch(createTestCaseForCycle(createParams));
  };

  useEffect(() => {
    const data = {
      parent: finalParentForQA,
      params: { archive: false },
      partition: partitionType,
      constants: [
        ProjectTypeCommonConstants.ALL_LIST_OF_TEST_CYCLES_REQUEST,
        ProjectTypeCommonConstants.ALL_LIST_OF_TEST_CYCLES_SUCCESS,
        ProjectTypeCommonConstants.ALL_LIST_OF_TEST_CYCLES_FAILURE,
      ],
    };
    dispatch(getListOfTestCyclesFunc(data));
    return () => {
      dispatch({
        type: ProjectTypeCommonConstants.CLEAR_ALL_LIST_OF_TEST_CYCLES,
        payload: [],
      });
      dispatch({
        type: ProjectTypeCommonConstants.CLEAR_LIST_OF_ALL_TEST_CASES,
        payload: [],
      });
    };
  }, []);

  const initFunc = async () => {
    setSpinner(true);
    const { data } = await getListCases();

    setCustomListCases({
      option: data.map((i) => ({
        label: get(i, 'params.title', 'No title'),
        value: get(i, 'uuid', ''),
      })),
      cases: data,
    });

    setSpinner(false);
  };

  const defaultList = (customTestCases && !isEmpty(customTestCases)) ? customListCases?.option : [];

  useEffect(() => {
    if (!customTestCases || (customTestCases && !isEmpty(customTestCases))) {
      
      initFunc();
    }
  }, [JSON.stringify(customTestCases)]);

  // console.log('customListCases', customListCases);

  return (
    <Spin spinning={spinner} wrapperClassName="w-full">
      <Tabs type="card">
        <TabPane
          tab={capitalize(t('wms.tabs.new_cycle', 'new'))}
          key="1"
        >
          <NewTestCycleExecutionForm
            customTitle={customTitle}
            label={t('wms.projectflow.qa.execution.create_new_cycle', 'Create new test cycles')}
            labelSubmit={capitalize(t('wms.buttons.add_a_cycle'))}
            handleOk={handleOk}
            selectTestCycle={selectTestCycle}
            newTestCycleExecutionContainerData={newTestCycleExecutionContainerData}
            defaultSelectedCase={defaultList}
            customListCases={customListCases}
            setNewTestCycleExecutionContainerData={setNewTestCycleExecutionContainerData}
            createNewTestCaseForCycle={createNewTestCaseForCycle}
          />
        </TabPane>

        <TabPane
          tab={capitalize(t('wms.tabs.existing', 'existing'))}
          key="2"
        >
          <ExistTestCycleExecutionForm
            handleOk={handleOk}
            typeOfParent={typeOfParent}
            selectTestCycle={selectTestCycle}
            existTestCycleExecutionContainerData={existTestCycleExecutionContainerData}
            defaultSelectedCase={defaultList}
            customListCases={customListCases}
            setExistTestCycleExecutionContainerData={setExistTestCycleExecutionContainerData}
            createNewTestCaseForCycle={createNewTestCaseForCycle}
          />
        </TabPane>
        <TabPane
          tab={capitalize(t('wms.tabs.ad-hoc_cycle', 'ad-hoc cycle'))}
          key="3"
        >
          <NewTestCycleExecutionForm
            label={capitalize(t('wms.buttons.add_to_ad_hoc'))}
            labelSubmit={capitalize(t('wms.buttons.add_to_ad_hoc'))}
            customTitle="Ad hoc cycle"
            handleOk={handleOk}
            selectTestCycle={selectTestCycle}
            newTestCycleExecutionContainerData={adHocTestCycleExecutionContainerData}
            defaultSelectedCase={defaultList}
            customListCases={customListCases}
            setNewTestCycleExecutionContainerData={setAdHocTestCycleExecutionContainerData}
            createNewTestCaseForCycle={createNewTestCaseForCycle}
          />
        </TabPane>

      </Tabs>
    </Spin>
  );
}

NewExecutionForm.propTypes = {
  handleOk: PropTypes.func,
  typeOfParent: PropTypes.string,
  parentUuid: PropTypes.string,
  customTestCases: PropTypes.array,
};

export default NewExecutionForm;

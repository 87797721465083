import React from 'react';
import { useTranslation } from 'react-i18next/';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import TagsSettingList from '../../../../components/filter/TagsSettingList';

function WrapperEntityFilterTestSuite({ onSelectTagsCallback }) {
  const { t } = useTranslation();

  const defaultLabelCheckbox = [
    { tags: [{ value: 'all', label: capitalize(t('wms.filters.all', 'all')) }], isReset: true },
    {
      tags: [
        { value: 'favorite', label: capitalize(t('wms.filters.favorites', 'favorites')) },
      ],
    }];

  const onSelectTags = (data = []) => onSelectTagsCallback(data.map((item) => item.value));

  const defaultTag = [];

  return (
    <TagsSettingList
      onSelectTags={onSelectTags}
      allTags={defaultLabelCheckbox}
      defaultSelectedTags={defaultTag}
      // customClassName="flex items-center justify-end"
      size="small"
      id="ListSuite"
    />
  );
}
WrapperEntityFilterTestSuite.propTypes = {
  onSelectTagsCallback: PropTypes.func,
};

export default WrapperEntityFilterTestSuite;

import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get, head } from 'lodash';

import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';

import useURLParams from '../../../hooks/useURLParams';
import {
  actorIsRootOrAdmin,
  stopPropagation,
} from '../../../54origins/utils54origins';
import { firstName, getUuid, lastName } from '../../../assignUsersModal/utils';
import { getMyInfo } from '../../../projectFlow/selectors/selectors';
import UserList from '../../../actors/components/UserList';
import useActorsW54Api from '../../../actors/actions/useActorsW54Api';

let needClearUserList = false;

function UsersTable({
  setUsers,
  selectedUser,
  setSelectedUser,
  setSelectedFullUser
}) {
  const dispatch = useDispatch();

  const { setSearchParams, clearSearchParams } = useURLParams();

  const myInfo = useSelector(getMyInfo);

  const [allActors, setAllActors] = useState([]);
  const [total, setTotal] = useState(0);

  const [config, setConfig] = useState({
    limit: 100,
    offset: 0,
    not_banned: true,
  });

  const ref = useRef();

  const { getListOfActorsW54 } = useActorsW54Api();

  const transformActorsToLocalView = (data = []) => {
    const transformActors = data.map((item) => ({
      actor_type: 'user',
      actor: item?.uuid,
      ...item,
      startWork: '',
      finishWork: '',
    }));

    return transformActors;
  };
  const onSelectUser = (actor, label) => {
    if(setSelectedFullUser) {
      setSelectedFullUser({actor, label})
      setSearchParams({ activeActor: actor });
    }
    if (head(selectedUser) !== actor && setSelectedUser) {
      setSelectedUser([actor]);
      setSearchParams({ activeActor: actor });
    } else if(setSelectedUser) {
      setSelectedUser([]);
      clearSearchParams(['activeActor']);
    }
  };

  const W54Users = useMemo(() => allActors
    .map((item) => ({
      value: getUuid(item),
      label: `${firstName(item)} ${lastName(item)}`,
      isWMSAdmin: actorIsRootOrAdmin(item),
    })), [
    JSON.stringify(allActors),
  ]);

  const getUsers = async () => {
    const res = await getListOfActorsW54({
      ...config,
      actor_type: ['classic_user', 'user'],
      group_uuids: get(myInfo, ['uinfo', 'groups'], []),
      not_banned: true,
    });

    setTotal(res.total);

    if (needClearUserList) {
      setAllActors([...res.data]);
      setUsers(transformActorsToLocalView(res.data));
    } else {
      setAllActors((prev) => ([...prev, ...res.data]));
      setUsers((prev) => [...prev, ...transformActorsToLocalView(res.data)]);
    }

    needClearUserList = false;
  };

  const onChangeValueSearch = (value) => {
    needClearUserList = true;
    setConfig((prev) => ({
      ...prev,
      limit: 100,
      offset: 0,
      search_data: {
        fields: {
          uinfo: {
            first_name: 'first_name',
            last_name: 'last_name',
          },
        },
        value,
      },
    }));
  };

  useEffect(() => {
    getUsers();
  }, [JSON.stringify(config), JSON.stringify(myInfo)]);

  return (
    <>
      {/* <InternalFilter setConfig={setConfig} /> */}
      <div className="h-full mt-2" ref={ref}>
        <div
          onClick={stopPropagation}
          className="flex align-item-end minWidth130 w-full mb-2"
          role="none"
        >
          <AntDesignSearchBox54origins
            onSearch={onChangeValueSearch}
          />
        </div>
        <UserList
          selectedUUIDs={selectedUser}
          total={total}
          config={config}
          setConfig={setConfig}
          items={W54Users}
          onClickCallback={onSelectUser}
        />
      </div>
    </>
  );
}

UsersTable.propTypes = {
  setUsers: PropTypes.func,
  selectedUser: PropTypes.array,
  setSelectedUser: PropTypes.func,
  setSelectedFullUser: PropTypes.func,
  users: PropTypes.array,
};

export default UsersTable;

import { useDispatch, useSelector } from "react-redux";
import {
  entityCreate,
  entityUpdate,
  getListAndPartialReadEntities
} from "../actions/entityActions";
import {
  CREATE_PROJECT_GOAL_FAILURE,
  CREATE_PROJECT_GOAL_FETCHING, CREATE_PROJECT_GOAL_SUCCESS,
  LIST_OF_PROJECT_GOALS_FAILURE,
  LIST_OF_PROJECT_GOALS_FETCHING, LIST_OF_PROJECT_GOALS_SUCCESS,
  PROJECT_GOAL_FAILURE, PROJECT_GOAL_FETCHING, PROJECT_GOAL_SUCCESS
} from "../../projectFlow/reducers/slicers/projectGoalsSlicer";
import { getArrayForItem } from "../../MainUtils";


export const useGoalsRequestHook = (partition) => {
  const dispatch = useDispatch();

  const getProjectGoalsScopeRequest = (param) => {
    const data = {
      ...param,
      entity_type: 'projectGoal',
      depth: 0,
      fields: {
        'actor.uinfo': 'uinfo',
        modified: 'modified',
        'actor.actor_type': 'actor_type',
        params: 'params'
      },
      order_by_params: 'handleSort',
      order: 'desc'
    };

    const constants = [
      'GET_PROJECT_SCOPE_GOALS_FETCHING',
      'GET_PROJECT_SCOPE_GOALS_SUCCESS',
      'GET_PROJECT_SCOPE_GOALS_FAILURE',
    ];

    return dispatch(getListAndPartialReadEntities({
      data,
      partition,
      constants,
    }));
  };

  const updateProjectGoalRequest = ({
     uuid,
     params,
   }) => {
    const data = {
      entity_type: 'projectGoal',
      entity_uuids: getArrayForItem(uuid),
      params,
    };

    const constants = [
      PROJECT_GOAL_FETCHING,
      PROJECT_GOAL_SUCCESS,
      PROJECT_GOAL_FAILURE,
    ];


    return new Promise((onSuccess, onFailure) => dispatch(entityUpdate({
      data,
      constants,
      options: {
        partition,
        onSuccess,
        onFailure
      },
    })));
  }

  const getListOfProjectGoalsRequest = (param) => {
    const { orderBy, order } = param || {};

    const data = {
      ...param,
      entity_type: 'projectGoal',
      depth: 0,
      fields: {
        'actor.uinfo': 'uinfo',
        modified: 'modified',
        'actor.actor_type': 'actor_type',
      },
      params_fields: {
        id: 'id',
        title: 'title',
        status: 'status',
        responsible: 'responsible',
      },
    };

    if (orderBy) {
      data[(orderBy === 'modified' || orderBy === 'created') ? 'order_by' : 'order_by_params'] = orderBy;
      data.order = order;
    }

    const constants = [
      LIST_OF_PROJECT_GOALS_FETCHING,
      LIST_OF_PROJECT_GOALS_SUCCESS,
      LIST_OF_PROJECT_GOALS_FAILURE,
    ];

    return dispatch(getListAndPartialReadEntities({
      data,
      partition,
      constants,
    }));
  };

  const createProjectGoalRequest = ({
     parent,
     params,
   }) => {

    const data = {
      entity_type: 'projectGoal',
      parent,
      params: {
        ...params,
      },
    };

    const constants = [
      CREATE_PROJECT_GOAL_FETCHING,
      CREATE_PROJECT_GOAL_SUCCESS,
      CREATE_PROJECT_GOAL_FAILURE,
    ];

    const options = {
      partition,
    };

    return dispatch(entityCreate({
      data,
      constants,
      options,
    }));
  };

  return ({
    getProjectGoalsScopeRequest,
    updateProjectGoalRequest,
    getListOfProjectGoalsRequest,
    createProjectGoalRequest,
  });
};

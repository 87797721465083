import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Col, Input, Row, Spin, DatePicker
} from 'antd';
import Select from 'react-select';
import { isNil } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { get, head } from 'lodash';
import SelectActorsComponent from '../../../../entity/components/SelectActorsComponent';
import ListOfVectorsDropDown from '../componentsForMultipleUse/ListOfVectorsDropDown';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

import {
  antNotification,
  capitalizeFirstChar,
} from '../../../../MainUtils';
import { BugTrackerContext, MainCollapseSectionContext } from '../../../context/ProjectFlowListOfContexts';
import {
  getProjectUUID,
} from '../../../selectors/selectors';
import { getLocale } from '../../../../locale/selectors';
import { incrementAndGetCount } from '../../../../entity/actions/entityActions';
import { capitalize } from 'lodash';


const { TextArea } = Input;
const { RangePicker } = DatePicker;

function NewTestCycleExecutionFormQa({
  customTitle,
  defaultSelectedCase,
  handleOk,
  newTestCycleExecutionContainerData,
  selectTestCycle,
  setNewTestCycleExecutionContainerData,
  label,
  labelSubmit,
  customListCases,
  createNewTestCaseForCycle,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getListOfTestCasesInCycle,
    createPromiseNewTestCycle,
    partitionType,
    getListOfTestCycles,
  } = useContext(BugTrackerContext);

  const locale = useSelector(getLocale);
  const projectUUID = useSelector(getProjectUUID);

  const defaultState = {
    title: customTitle || '',
    description: '',
    start_date: '',
    end_date: '',
    selectedCases: defaultSelectedCase,
    assignToUsersOption: [],
    selectVector: '',
  };

  const [state, setState] = useState(defaultState);
  const [loading, setLoading] = useState(false);

  const {
    title,
    description,
    start_date,
    end_date,
    selectedCases,
    assignToUsersOption,
    selectVector,
    selectedCycle,
  } = state;

  const changeState = (data) => {
    setState({
      ...state,
      ...data,
    });
  };

  const checkSelectedColorCase = (isNil(selectedCases) || selectedCases.length === 0) ? 'red' : 'green';

  const onFormChange = (event) => {
    const { name, value } = event.target;

    changeState({ [name]: value });
  };

  const onDaysChange = (dates) => {
    let checkedDates = ['', ''];
    const checkIsArr = Array.isArray(dates);
    if (checkIsArr) {
      checkedDates = dates;
    }
    const [date1, date2] = checkedDates;
    setState((prev) => ({ ...prev, start_date: date1 || '', end_date: date2 || '' }));
  };

  const assignToFunc = (value) => {
    changeState({
      assignToUsersOption: value,
    });
  };

  const selectVectorCollback = (item) => {
    changeState({ selectVector: item });
  };

  const onSelectTestCase = (e) => changeState({ selectedCases: isNil(e) ? [] : e });

  const submitNewTestCycle = async () => {
    setLoading(true);

    const { increment } = await dispatch(incrementAndGetCount({
      entity_uuid: projectUUID,
      field_name: 'cycle',
    }, partitionType));

    const createParams = {
      title,
      description,
      start_date: start_date ? start_date.format('YYYY-MM-DD') : '',
      end_date: end_date ? end_date.format('YYYY-MM-DD') : '',
      status: 'in progress',
      executed_by: null,
      executed_on: null,
      cases_count: selectedCases.length,
      assignToUser: assignToUsersOption,
      vector: selectVector,
      archive: false,
      id: `C${increment}`,
      progress: {
        pass: 0,
        block: 0,
        fail: 0,
        unexecuted: selectedCases.length,
        progress: 0,
        quantityCase: selectedCases.length,
      },
    };

    const newTestCycle = await createPromiseNewTestCycle(createParams);

    const listSelected = customListCases?.cases?.filter(
      (i) => selectedCases.some((p) => p.value === i.uuid),
    );

    await createNewTestCaseForCycle(listSelected, head(newTestCycle), assignToUsersOption);

    getListOfTestCycles();

    getListOfTestCasesInCycle(get(newTestCycle, '[0].uuid'));
    selectTestCycle(get(newTestCycle, '[0].uuid'));

    setLoading(false);

    antNotification('success', t('wms.noun.success', 'Success'));

    if (handleOk) {
      handleOk();
    }
  };


  const submitEditTestCycle = async () => {
    setSpinner(true);

    const editableTestCycle = testCycles.find(({ uuid }) => selectedCycle.value === uuid);

    const listSelected = customListCases?.cases?.filter(
      (i) => selectedCases.some((p) => p.value === i?.uuid),
    );

    await createNewTestCaseForCycle(
      listSelected,
      { uuid: editableTestCycle?.uuid, params: { ...editableTestCycle } },
      assignToUsersOption,
    );

    updateTestCyclesData(editableTestCycle);

    getListOfTestCasesInCycle(selectedCycle.value);

    antNotification('success', t('wms.noun.success', 'Success'));

    setSpinner(false);

    changeMainCollapseFromOuterComponent('mainCycleCollapse', true);
    if (handleOk) {
      handleOk();
    }
  };


  useEffect(() => () => {
    setNewTestCycleExecutionContainerData({ ...state });
  }, []);

  useEffect(() => {
    if (newTestCycleExecutionContainerData) {
      setState(newTestCycleExecutionContainerData);
    }

    if (!newTestCycleExecutionContainerData && defaultSelectedCase?.length) {
      onSelectTestCase(defaultSelectedCase);
    }
  }, [defaultSelectedCase?.length]);

  return (
    <div
      style={{
        minHeight: '580px',
      }}
      className="d-flex flex-column justify-content-between"
    >
      <div className="px-4 w-100">
        <Spin spinning={loading}>
          <Row>
            <Col span={24}>
              <h5>
                {label}
              </h5>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col span={24} style={{ color: title.trim().length === 0 ? 'red' : 'green' }}>
              {capitalizeFirstChar(t('wms.labels.title', 'title'))}
              <span>*</span>

              <Input name="title" value={title} onChange={onFormChange} disabled={customTitle} showCount maxLength={60} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col span={24}>
              <span>
                {capitalizeFirstChar(t('wms.labels.description', 'description'))}
                :
              </span>

              <TextArea
                row={4}
                value={description}
                name="description"
                onChange={onFormChange}
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col span={24}>
              <Col style={{ color: checkSelectedColorCase }}>
                <span>
                  {capitalizeFirstChar(t('wms.labels.test_cases', 'Test cases'))}
                  {' '}
                  *
                </span>
              </Col>

              <Select
                className="w-100"
                isMulti
                noOptionsMessage={() => capitalizeFirstChar(t('wms.select.no_options', 'no options'))}
                placeholder={capitalizeFirstChar(t('wms.placeholders.select_cases', 'select cases'))}
                onChange={onSelectTestCase}
                options={customListCases?.option}
                value={selectedCases}
                closeMenuOnSelect={false}
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col span={12}>
              <ListOfVectorsDropDown
                partition={partitionType}
                selectVectorCallback={selectVectorCollback}
                selectVectorDefault={selectVector}
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col span={12}>
              {/* <SelectActorsComponent
                allowClear
                // hideSelected
                partitionType={partitionType}
                listOfActors={['projectActors']}
                customInputLabel={capitalizeFirstChar(t('wms.labels.assign_to', 'assign to'))}
                saveActorsCallback={assignToFunc}
                customSelectedActors={assignToUsersOption}
              /> */}
            
            </Col>
          </Row>

          <Row type="flex" gutter={16} className="mt-2">
            <Col span={12}>
              <label className=" m-0 p-0">
                {t('wms.table.headers.period')}
                :
              </label>
              <RangePicker
                lang={locale}
                format="YYYY-MM-DD"
                popupClassName="z-1001"
                className="w-full"
                onChange={onDaysChange}
                value={[start_date || '', end_date || '']}
                getPopupContainer={() => document.getElementById('RangePicker')}
                placeholder={[
                  t('wms.table.headers.start_date', 'Start date'),
                  t('wms.table.headers.end_date', 'End date'),
                ]}
              />
            </Col>
              
          </Row>
        </Spin>
      </div>

      <div className="d-flex justify-content-end">
        <BaseButton
          type="primary"
          className="ml-auto"
          onClick={submitEditTestCycle}
          disabled={!selectedCycle || (typeOfParent === 'suite' && (!selectedCases || lengthOfSelectedCases === 0))}
        >
          {labelSubmit}

        </BaseButton>
      </div>
    </div>
  );
}

NewTestCycleExecutionFormQa.propTypes = {
  handleOk: PropTypes.func,
  newTestCycleExecutionContainerData: PropTypes.func,
  selectTestCycle: PropTypes.func,
  customListCases: PropTypes.array,
  setNewTestCycleExecutionContainerData: PropTypes.func,
  label: PropTypes.string,
  labelSubmit: PropTypes.string,
  customTitle: PropTypes.string,
  defaultSelectedCase: PropTypes.array,
  createNewTestCaseForCycle: PropTypes.func,
};

export default NewTestCycleExecutionFormQa;

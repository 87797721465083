import { mdiCardPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  bool, func, string,
} from 'prop-types';
import React from 'react';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import useMyRecurringIssues from './hooks/useMyRecurringIssues';
import { useSelector } from 'react-redux';
import { getRecurringIssue } from '../../selectors/selectors';
import { antNotification } from '../../../MainUtils';
import { t } from 'i18next';

export default function NewIssueFromRecurringBtn({
  disabled,
  setDefaultRecurringIssueState,
  buttonClassName = '',
  getStartData,
  partitionType,
}) {
    const partition = null;
    const recurringIssue = useSelector(getRecurringIssue);

    const { requestCreateIssueFromRecurring } = useMyRecurringIssues()

    const createIssueFromRecurring = async (e) => {
        e.stopPropagation();
        requestCreateIssueFromRecurring(recurringIssue, partitionType, partition);
        antNotification(
            "success",
            t(
              "notifications.text.success.new_issue_created",
              "New issue has been successfully added"
            )
        );
    } 

    return (
        <BaseButton
            id="createIssueFromRecurringButton"
            size="small"
            type="primary"
            className={`${buttonClassName} btnWarning-outline`}
            ghost
            onClick={createIssueFromRecurring}
            disabled={disabled}
            >
            <Icon path={mdiCardPlusOutline} size={0.7} className="mr-1" />
                Create issue
        </BaseButton>
    );
}

NewIssueFromRecurringBtn.propTypes = {
  disabled: bool,
  issueUUID: string,
  partitionType: string,
  buttonClassName: string,
  setDefaultRecurringIssueState: func,
  getStartData: func,
};

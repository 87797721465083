/* eslint-disable import/prefer-default-export */
import {
  mdiBellOutline,
  mdiCalendarClock,
  mdiAlertCircleOutline,
  mdiCog,
} from '@mdi/js';

export const docStatusOptions = (isSystem = false) => ([
  {
    value: 'system',
    label: 'wms.status.system',
    className: 'radio-gray',
    icon: mdiCog,
  },
  {
    value: 'created',
    label: 'wms.status.created',
    className: 'radio-lightBlue',
    icon: mdiBellOutline,
  },
  {
    value: 'important',
    label: 'wms.status.important',
    className: 'radio-red',
    icon: mdiAlertCircleOutline,
  },
  {
    value: 'outdated',
    label: 'wms.status.outdated',
    className: 'radio-gray',
    icon: mdiCalendarClock,
  },
].filter((i) => (isSystem ? i.value === 'system' : i.value !== 'system')));

// export const docsStatusesDropdown = ['created', 'important', 'outdated'];

// export const docsStatusesOptions = {
//     created: 'createdBlue',
//     important: 'priorityRed',
//     outdated: 'blockedGrey',
// }

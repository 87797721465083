import React from 'react';
import PropTypes from 'prop-types';
import { mdiMenu, mdiMenuOpen } from '@mdi/js';
import Icon from '@mdi/react';
import './FlatHeaderSidebar.scss';

function FlatHeaderSidebar({
  name,
  link,
  minWidth,
  sidebarWidth,
  headerHeight,
  menuOpen,
}) {
  return (
    <div
      style={{
        minWidth: `${minWidth}px`,
        width: sidebarWidth ? menuOpen ? '0' : `${sidebarWidth}px` : `${minWidth}px`,
      }}
      className="flex aling-items-center justify-between header-logo-block"
    >
      <a
        href={link}
        style={{
          lineHeight: `${headerHeight}px`,
        }}
      >
        {name}
      </a>
    </div>
  );
}

FlatHeaderSidebar.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  minWidth: PropTypes.number,
  sidebarWidth: PropTypes.number,
  headerHeight: PropTypes.number,
  menuOpen: PropTypes.bool,
};

export default FlatHeaderSidebar;

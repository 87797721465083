import { get, isUndefined } from "lodash";

const trackerValue = {
    bug: 1,
    ticket: 2,
    feature: 5,
}

const priorityValue = {
    low: 1,
    medium: 2,
    high: 3,
    urgent: 4,
}

export const computeIssueChunks = (issue = {}) => {
    const defaultChunk = get(issue, 'boardData.chunk', null);
    let chunk = trackerValue?.[issue?.tracker] + priorityValue?.[issue?.priority];
    return {
        uuid: get(issue, 'uuid', ''),
        chunk: defaultChunk || chunk,
    }
};

export const computeIssuesChunks = (issues = []) => issues.map(obj => {
    const defaultChunk = get(obj, 'boardData.chunk', null);
    let chunk = trackerValue?.[obj?.tracker] + priorityValue?.[obj?.priority];
    return {
        uuid: get(obj, 'uuid', ''),
        chunk: defaultChunk || chunk,
    };
})

export const computePlan = (issues = [], maxChunksCount = 16) => {
    let result = [];
    let currentHoursSum = 0;

    let items = computeIssuesChunks([...issues]);
    let chunks = [...items];

    while (items.length > 0) {
        let randomIndex = Math.floor(Math.random() * items.length);

        let randomItem = items[randomIndex];

        if ((!result.length && currentHoursSum + randomItem.chunk <= maxChunksCount)
            || (result.length && currentHoursSum + randomItem.chunk + 1 <= maxChunksCount)) {
            const currentChunk = chunks.find(el => el.uuid === randomItem?.uuid)
            if (result.length) {
                // currentChunk.switch = 1;
                currentChunk.key = result.length;
                currentHoursSum += randomItem.chunk + 1;
            } else {
                currentChunk.key = 0;
                currentHoursSum += randomItem.chunk;
            }
            result.push(randomItem?.uuid);
        }

        items.splice(randomIndex, 1);
    }

    chunks = chunks.filter(ch => result?.includes(ch.uuid) && !isUndefined(ch.key))

    return { uuids: result, data: chunks };
}

export const isDifferentDayAndBefore = (date1, date2) => {
    return date1.isBefore(date2, 'day') && !date1.isSame(date2, 'day');
};
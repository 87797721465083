import React from "react";
import Icon from "@mdi/react";
import {
  // mdiFilePdfBox,
  // mdiFileWordBox,
  mdiMinus
} from "@mdi/js";

export const toolbarFontSizeArr = ['10px','12px','14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px'];


  export const toolbarOptions = [
    [
      {
        type: "select",
        className: "ql-font",
        title: "Font family",
        width: "110px",
      },
    ],
    [
      {
        type: "select",
        className: "ql-size",
        title: "Font size",
        value: toolbarFontSizeArr,
        width: "110px",
      },
    ],

    [
      { type: "button", className: "ql-bold", title: "Bold (Ctrl + B)" },
      { type: "button", className: "ql-italic", title: "Italic (Ctrl + I)" },
      {
        type: "button",
        className: "ql-underline",
        title: "Underline (Ctrl + U)",
      },
      { type: "button", className: "ql-strike", title: "Strikethrough" },
    ],
    [
      {
        type: "select",
        className: "ql-color",
        title: "Font color",
        width: "32px",
      },
      {
        type: "select",
        className: "ql-background",
        title: "Font bg color",
        width: "32px",
      },
    ],
    [
      {
        type: "button",
        className: "ql-script",
        title: "Subscript",
        value: "sub",
      },
      {
        type: "button",
        className: "ql-script",
        title: "Superscript",
        value: "super",
      },
    ],
    [
      {
        type: "button",
        className: "ql-header",
        title: "Heading 1",
        value: "1",
      },
      {
        type: "button",
        className: "ql-header",
        title: "Heading 2",
        value: "2",
      },
      { type: "button", className: "ql-blockquote", title: "Block quote" },
      { type: "button", className: "ql-code-block", title: "Code" },
    ],
    [
      {
        type: "button",
        className: "ql-list",
        title: "Ordered list",
        value: "ordered",
      },
      {
        type: "button",
        className: "ql-list",
        title: "Bulleted list",
        value: "bullet",
      },
    ],
    [
      {
        type: "button",
        className: "ql-indent",
        title: "Increase indent",
        value: "-1",
      },
      {
        type: "button",
        className: "ql-indent",
        title: "Descrease indent",
        value: "+1",
      },
    ],
    [
      {
        type: "select",
        width: "32px",
        className: "ql-align",
        title: "Text align",
      },
    ],
    [
      { type: "button", className: "ql-link", title: "Link (Ctrl + K)" },
      { type: "button", className: "ql-image", title: "Insert image" },
      // { type: "button", className: "ql-video", title: "Insert video" },
      // { type: "button", className: "ql-formula", title: "Insert formula" },
    ],
    [{ type: "button", className: "ql-divider", title: "Divider (Ctrl + Alt + L)", icon: <Icon path={mdiMinus} size={1} />  }],
    [{type: 'button', className: "ql-table", title: "Insert table"}],
    [{ type: "button", className: "ql-clean", title: "Remove format" }],
    // [
    //   {
    //     type: "button",
    //     className: "ql-pdf",
    //     title: "Export to pdf",
    //     icon: <Icon path={mdiFilePdfBox} size={1} />,
    //     padding: "0 0",
    //   },
    //   {
    //     type: "button",
    //     className: "ql-docx",
    //     title: "Export to docx",
    //     icon: <Icon path={mdiFileWordBox} size={1} />,
    //     padding: "0 0",
    //   },
    // ],
  ];
import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AreasManagement from '../areas/AreasManagement';
import CheckEntityPerm from '../../containers/permissions/CheckEntityPerm';

import { getMainAreaUUID } from '../../../entity/selectors/selectors';
import { getAreas } from '../../selectors/selectors';

const listForCheckAreaPerm = ['update'];
const listForCheckPMPerm = ['set'];

export default function ProjectAreaAndManagement({ partitionUUID, partition }) {
  const { t } = useTranslation();

  const allAreas = useSelector(getAreas);
  const mainAreaUUID = useSelector(getMainAreaUUID);

  const [viewTitle, setViewTitle] = useState(false);

  return (
    <>
      {mainAreaUUID && (
        <CheckEntityPerm
          entityUUID={mainAreaUUID}
          permission={listForCheckAreaPerm}
          isDisplaySectionCallback={setViewTitle}
        >
          <AreasManagement
            areas={allAreas}
            partition={partition}
            classNameButton="cursor-pointer mt-2"
          />
        </CheckEntityPerm>
      )}
    </>
  );
}

ProjectAreaAndManagement.propTypes = {
  partitionUUID: string,
};

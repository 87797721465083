/* eslint-disable no-shadow */
import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import _, { get } from 'lodash';
import {
  path,
} from 'ramda';
import moment from 'moment';

import { Col, Row, Card } from 'antd';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import UnSaveDataConfirmNew from '../../../components/un-save-data-confirm/UnSaveDataConfirmNew';
import HardDeleteEntityContainer from '../../../entity/containers/HardDeleteEntityContainer';
import ProjectInfoFromSettings from '../project/projectInfo/ProjectInfoFromSettings';

import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';

import {
  getProjectUUID,
  getProjectParams,
  getProjectMembers,
} from '../../selectors/selectors';
import { getPartitionPMUUID } from '../../../config/selectors/selectors';

import { getAreasRequest, getMainEntityAreaRequest } from '../../actions/areasActions';

import {
  updateProjectRequest,
} from '../../actions/projectFlowActions';

import {
  antNotification,
  getUuidsfromParamsUsers,
} from '../../../MainUtils';
import { capitalize } from 'lodash';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";

function ProjectSettings({ }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const partitionPM = useSelector(getPartitionPMUUID);
  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);

  const {
    defaultPartition,
    initialPathForHistory,
    getClearProject,
  } = useContext(ProjectMainLayerContext);

  const navigate = useNavigate();

  const titleRef = useRef(null);

  const [formData, setFormData] = useState({});
  const [mainEntityArea, setMainEntityArea] = useState({});


  const defaultProjectData = useMemo(() => ({
    releasePolicy: projectParams?.releasePolicy,
  }), [projectUUID]);

  const isFormEdited = !_.isEqual(defaultProjectData, formData);

  const onSuccessUpdate = (params, data) => {
    antNotification('success', t('wms.noun.success', 'Success'));

    if (params.status === 'archive') {
      getClearProject(projectUUID);
    } else {
      const members = path(['params', 'users'], data);
      if (members && getProjectMembers) {
        getProjectMembers(getUuidsfromParamsUsers(members));
      }
    }
  };

  const updateProject = async (params) => {
    // dispatch(setFlagNotSaveData(false));

    const {
      lifeTime,
      status,
      areas,
    } = params;

    const config = {
      uuid: projectUUID,
      params: { ...params },
      partition: defaultPartition,
    };

    if (lifeTime) {
      config.params.lifeTime = moment(lifeTime).format('YYYY-MM-DD');
    }

    if (status === 'archive') {
      config.params.archive = true;
      config.params.status = 'archive';
    } else {
      config.params.archive = false;
    }

    if (areas) {
      config.params.areas = areas.map(({ label }) => label);
    }

    delete config.params.visibleUsers;
    delete config.params.adminsUsers;
    delete config.params.roles;

    dispatch(updateProjectRequest(config))
      .then(async () => {
        onSuccessUpdate(params, config);

        getClearProject(projectUUID);
      })
      .catch(() => antNotification('error', 'Project update error'));
  };

  const updateProjectWithCustomsParams = (params) => {
    const data = {
      uuid: projectUUID,
      params: { ...params },
      partition: defaultPartition,
    };

    return dispatch(updateProjectRequest(data))
      .catch(() => antNotification('error', 'Access denied!'));
  };

  const initFunc = async () => {
    let areasData;

    try {
      areasData = await dispatch(getMainEntityAreaRequest(partitionPM));
    } catch (error) {
      console.log(error);
    }

    setMainEntityArea({ uuid: get(areasData, 'uuid', '') });
  };

  useEffect(() => {
    initFunc();
  }, [partitionPM]);

  useEffect(() => {
    const {
      uuid,
    } = mainEntityArea;

    if (uuid) {
      dispatch(getAreasRequest(uuid));
    }
  }, [mainEntityArea]);

  useEffect(() => {
    setFormData(defaultProjectData);
  }, [defaultProjectData]);

  return (
    <Col span={24}>
      <BaseCard className="dangerCard">
        <Row type="flex">
          <Col className="mb-4 flex-auto">
            <h5 className="m-0">
              {capitalize(t('wms.projectflow.settings.header', 'project settings'))}
            </h5>
          </Col>
          <Col className="flex justify-end flex-wrap customPopconfirm">
            {isFormEdited
              && (
                <UnSaveDataConfirmNew dataIsNotSave={isFormEdited}>
                  <BaseButton
                    id="saveBtn"
                    className="mr-1"
                    type="primary"
                    size="small"
                    onClick={() => updateProject(formData)}
                  >
                    {capitalize(t('wms.verb.save', 'save'))}
                  </BaseButton>
                </UnSaveDataConfirmNew>
              )}
            <HardDeleteEntityContainer
              partitionType={defaultPartition}
              name="project"
              typeInLabel="project"
              entityType="project"
              type="entity"
              doubleConfirm
              labelMode
              checkHardDelete
              sizeBtn="small"
              entityUUID={projectUUID}
              actionForEntity={() => {
                navigate({
                  pathname: initialPathForHistory,
                  search: '',
                });
              }}
            />
          </Col>
        </Row>
        <form onSubmit={() => updateProject(formData)}>
          <ProjectInfoFromSettings
            titleRef={titleRef}
            setFormData={setFormData}
            formData={formData}
            partitionType={defaultPartition}
            updateProjectWithCustomsParams={updateProjectWithCustomsParams}
          />
        </form>
      </BaseCard>
    </Col>
  );
}

export default ProjectSettings;

import { propOr } from 'ramda';
import { getAffixRead, getListAndReadAffix } from '../../affix/affixActions';
import { ProjectTypeCommonConstants } from '../constants/Constants';
import { getArrayForItem } from '../../MainUtils';
import {
  entityRead,
  entityUpdate,
  entityPartialRead,
  getListAndPartialReadEntities,
  entityOptionalDataRead,
  entityOptionalDataStateLatest,
  entityOptionalDataStateRead,
  entityOptionalDataStateUpdate,
  entityOptionalDataPatchRead,
  entityOptionalDataPatchUpdate,
  entityOptionalDataStateFullRewrite
} from '../../entity/actions/entityActions';

import { COMMENT_TYPE } from '../components/comment/commentType';
import { partitionNamesConfig } from '../../api/appConfig';

const DEFAULT_PTC_PARTITION = partitionNamesConfig.partition1;

export function getDocumentCopy({
  uuid,
  partition = DEFAULT_PTC_PARTITION,
  signal,
}) {
  const data = {
    affix_type: 'projectDocument',
    affix_uuids: getArrayForItem(uuid),
  };

  const constants = [
    ProjectTypeCommonConstants.GET_DOCUMENT_COPY_REQUEST,
    ProjectTypeCommonConstants.GET_DOCUMENT_COPY_SUCCESS,
    ProjectTypeCommonConstants.GET_DOCUMENT_COPY_FAILURE,
  ];

  return getAffixRead({
    data,
    partition,
    constants,
    signal,
  });
}

export const getListOfHistoryDocuments = (params) => {
  const config = {
    data: {
      ...propOr({}, 'data', params),
      with_files: false,
    },
    partition: propOr(partitionNamesConfig.partition1, 'partition', params),
    constants: [
      ProjectTypeCommonConstants.FETCH_HISTORY_OF_DOCUMENT_REQUEST,
      ProjectTypeCommonConstants.FETCH_HISTORY_OF_DOCUMENT_SUCCESS,
      ProjectTypeCommonConstants.FETCH_HISTORY_OF_DOCUMENT_FAILURE,
    ],
  };
  return getListAndReadAffix(config);
};

export const updateDocument = (config) => {
  // console.log('CONFIG', config);

  const constants = [
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_REQUEST,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_SUCCESS,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_FAILURE,
  ];

  const options = {
    partition: propOr(DEFAULT_PTC_PARTITION, 'partition', config),
  };

  return entityUpdate({
    data: config.params,
    constants,
    options,
  });
};

export const updateDocumentRequest = (data, partition, callback) => {
  const constants = [
    ProjectTypeCommonConstants.EDIT_DOCUMENT_REQUEST,
    ProjectTypeCommonConstants.EDIT_DOCUMENT_SUCCESS,
    ProjectTypeCommonConstants.EDIT_DOCUMENT_FAILURE,
  ];

  const options = {
    partition,
    onSuccess: callback,
    onFailure: () => callback(false),
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
};


export function updateDocumentsRequest({
  uuid,
  params,
  partition = DEFAULT_PTC_PARTITION,
  signal,
}) {
  const data = {
    entity_type: 'projectDocument',
    entity_uuids: getArrayForItem(uuid),
    params: { ...params },
  };

  const constants = [
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_REQUEST,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_SUCCESS,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_FAILURE,
  ];

  const options = {
    signal,
    partition,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}

export const getListDocumentsOrIdea = (param, partition, constants) => {
  const { entity_type, orderBy } = param || {};

  const data = {
    ...param,
    depth: 0,
    params_fields: {
      id: 'id',
      type: 'type',
      status: 'status',
      title: 'title',
      lifeTime: 'lifeTime',
      updated: 'updated',
      [COMMENT_TYPE.TOTAL]: COMMENT_TYPE.TOTAL,
      // commentsCount: 'commentsCount',
    },
    fields: {
      entity_type: 'entity_type',
      'actor.uinfo': 'uinfo',
      actor: 'actor',
    },
  };

  if (!entity_type) {
    data.entity_type = ['projectDocument', 'projectIdea', 'projectSpreadsheet']
  }

  if (orderBy) {
    data[(orderBy === 'modified'
      || orderBy === 'created'
      || orderBy === 'entity_type'
    ) ? 'order_by' : 'order_by_params'] = orderBy;
    delete data.orderBy;
  }

  // console.log('docs list config:', data);

  return getListAndPartialReadEntities({
    data,
    partition,
    constants: constants || [
      ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_REQUEST,
      ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_SUCCESS,
      ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_FAILURE,
    ],
    with_files: false,
  });
};

export const getOneDocument = (uuid, entityType, defaultPartition = partitionNamesConfig.partition1) => {
  const data = {
    entity_type: entityType || 'projectDocument',
    entity_uuid: uuid,
  };

  const constants = [
    ProjectTypeCommonConstants.GET_DOCUMENT_REQUEST,
    ProjectTypeCommonConstants.GET_DOCUMENT_SUCCESS,
    ProjectTypeCommonConstants.GET_DOCUMENT_FAILURE,
  ];

  const options = {
    partition: defaultPartition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
};

export const getDocumentOrIdea = ({
  uuid,
  entityType,
  partition = DEFAULT_PTC_PARTITION,
}) => {
  const data = {
    entity_type: entityType || 'projectDocument',
    entity_uuid: uuid,
  };

  const constants = [
    ProjectTypeCommonConstants.GET_DOCUMENT_REQUEST,
    ProjectTypeCommonConstants.GET_DOCUMENT_SUCCESS,
    ProjectTypeCommonConstants.GET_DOCUMENT_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
};

export function setDocumentContent(content) {
  return (dispatch) => dispatch({
    type: ProjectTypeCommonConstants.SET_DOCUMENT_CONTENT,
    payload: content,
  });
}

export const checkActualStateRequest = ({
  uuid,
  partition = DEFAULT_PTC_PARTITION,
}) => {
  const data = {
    entity_uuid: uuid,
    params_fields: { actualState: 'actualState' },
  };

  const constants = [
    ProjectTypeCommonConstants.CHECK_DOCUMENT_ACTUAL_STATE_REQUEST,
    ProjectTypeCommonConstants.CHECK_DOCUMENT_ACTUAL_STATE_SUCCESS,
    ProjectTypeCommonConstants.CHECK_DOCUMENT_ACTUAL_STATE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityPartialRead({
    data,
    constants,
    options,
  });
};

export const updateActualStateRequest = (data, partition = DEFAULT_PTC_PARTITION) => {
  const constants = [
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_REQUEST,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_SUCCESS,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
};

export const updateDocumentPartiallyRequest = (
  data,
  partition = DEFAULT_PTC_PARTITION,
  constants = [
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_REQUEST,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_SUCCESS,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_FAILURE,
  ],
) => {
  const options = {
    partition,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
};

export const getAllDocumentStatesRequest = ({
  uuid,
  partition = DEFAULT_PTC_PARTITION,
}) => {
  const data = {
    entity_uuid: uuid,
    keys: ['states'],
  };

  const constants = [
    ProjectTypeCommonConstants.GET_ALL_DOCUMENT_STATES_REQUEST,
    ProjectTypeCommonConstants.GET_ALL_DOCUMENT_STATES_SUCCESS,
    ProjectTypeCommonConstants.GET_ALL_DOCUMENT_STATES_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityOptionalDataRead({
    data,
    constants,
    options,
  });
};

export const getDocumentStateRequest = ({
  uuid,
  stateID,
  partition = DEFAULT_PTC_PARTITION,
}) => {
  const data = {
    entity_uuid: uuid,
    state_id: stateID,
    keys: ['text', 'patches', 'timestamp', 'createdBy'],
  };

  const constants = [
    ProjectTypeCommonConstants.GET_DOCUMENT_STATE_REQUEST,
    ProjectTypeCommonConstants.GET_DOCUMENT_STATE_SUCCESS,
    ProjectTypeCommonConstants.GET_DOCUMENT_STATE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityOptionalDataStateRead({
    data,
    constants,
    options,
  });
};

export const updateDocumentStateRequest = ({
  uuid,
  stateID,
  stateData,
  partition = DEFAULT_PTC_PARTITION,
}) => {
  const data = {
    entity_uuid: uuid,
    state_id: stateID,
    data: stateData,
  };
  console.log("udpatedData", data);
  
  const constants = [
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_STATE_REQUEST,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_STATE_SUCCESS,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_STATE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityOptionalDataStateUpdate({
    data,
    constants,
    options,
  });
};

export const getDocumentPatchRequest = ({
  uuid,
  stateID,
  patchID,
  partition = DEFAULT_PTC_PARTITION,
}) => {
  const data = {
    entity_uuid: uuid,
    state_id: stateID,
    patch_id: patchID, // string or string[]
    keys: ['timestamp', 'patch', 'createdBy'],
  };

  const constants = [
    ProjectTypeCommonConstants.GET_DOCUMENT_PATCH_REQUEST,
    ProjectTypeCommonConstants.GET_DOCUMENT_PATCH_SUCCESS,
    ProjectTypeCommonConstants.GET_DOCUMENT_PATCH_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityOptionalDataPatchRead({
    data,
    constants,
    options,
  });
};

export const updateDocumentPatchRequest = ({
  uuid,
  stateID,
  patchID,
  patchData,
  partition = DEFAULT_PTC_PARTITION,
}) => {
  const data = {
    entity_uuid: uuid,
    state_id: stateID,
    patch_id: patchID,
    data: patchData,
  };

  const constants = [
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_PATCH_REQUEST,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_PATCH_SUCCESS,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_PATCH_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityOptionalDataPatchUpdate({
    data,
    constants,
    options,
  });
};

export const getDocumentStateLatestRequest = ({
  uuid,
  partition = DEFAULT_PTC_PARTITION,
}) => {
  const data = {
    entity_uuid: uuid,
  };

  const constants = [
    ProjectTypeCommonConstants.GET_DOCUMENT_STATE_LATEST_REQUEST,
    ProjectTypeCommonConstants.GET_DOCUMENT_STATE_LATEST_SUCCESS,
    ProjectTypeCommonConstants.GET_DOCUMENT_STATE_LATEST_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityOptionalDataStateLatest({
    data,
    constants,
    options,
  });
};


export const documentStateFullRewriteRequest = ({
  uuid,
  states,
  partition = DEFAULT_PTC_PARTITION,
}) => {
  const data = {
    entity_uuid: uuid,
    data: { states: states}
  };

  const constants = [
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_STATE_FULLREWRITE_REQUEST,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_STATE_FULLREWRITE_SUCCESS,
    ProjectTypeCommonConstants.UPDATE_DOCUMENT_STATE_FULLREWRITE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityOptionalDataStateFullRewrite({
    data,
    constants,
    options,
  });
};
import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import {
  mdiBellOffOutline,
  mdiBellOutline,
  mdiCommentTextMultipleOutline,
  mdiPencil,
  mdiArrowULeftTop,
  mdiDotsVertical,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Dropdown,
  Space, Tooltip,
} from 'antd';
import {
  array, bool, func, string,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import cn from 'classnames';
import BaseButton from '../../../../../components/_ui/BaseButton/BaseButton';
import GoToButton from '../../../../../components/buttons/GoToButton';
import CopyURL from '../../../../../components/CopyURL';
import HardDeleteEntityContainer from '../../../../../entity/containers/HardDeleteEntityContainer';
import AddToBookmark from '../../../AddToBookmark';
import RestoreEntityType from '../../../archiveState/RestoreEntityTypeBtn';

import { antNotification, getUuidsfromParamsUsers } from '../../../../../MainUtils';
import { capitalize } from 'lodash';
import { getIssue, getMe, getProject, getRecurringIssue, getVectorInfo } from '../../../../selectors/selectors';
import { getUserPublicProfileUUID } from '../../../../../userFlow/store/selectors/selectors';
import CloneIssueBtn from './CloneIssueBtn';
import HeaderCard from '../../../commonComponents/HeaderCard';
import CommonInfo from '../../../commonComponents/CommonInfo';
import UnSaveDataConfirmNew from '../../../../../components/un-save-data-confirm/UnSaveDataConfirmNew';
import BtnModal from '../../../BtnModalComponent';
import NewIssueComponents from '../../newIssue/NewIssueComponents';
import { partitionNamesConfig } from '../../../../../api/appConfig';
import CreateRecurringBtn from './CreateRecurringBtn';
import useURLParams from '../../../../../hooks/useURLParams';
import { get, head, omit } from 'lodash';
import { getIssueNew, getVector } from '../../../../actions/projectFlowActions';
import { convertB64ToBlob } from '../../../../../components/uploader/utils';
import useMyRecurringIssues from '../../../recurringIssue/hooks/useMyRecurringIssues';
import NewIssueFromRecurringBtn from '../../../recurringIssue/NewIssueFromRecurringBtn';

function HeaderSectionButtons({
  hiddenView,
  partitionType,
  resetIssueCallback,
  afterDeleteABookmark,
  restoreEntityCallback,
  deleteIssueCallback,
  createIssueCallback,
  openSidePanel,
  onSaveAllEdit,
  onEditAll,
  onCloseAllEdit,
  isEditAllMode,
  disabledSaveAll,
  disabledCloneIssue,
  isRecurring = false,
  customProject
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let selectedIssue;

  if (isRecurring) {
    selectedIssue = useSelector(getRecurringIssue);
  } else {
    selectedIssue = useSelector(getIssue);
  }

  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);

  const [defaultIssueState, setDefaultIssueState] = useState(null);
  const [defaultRecurringIssueState, setDefaultRecurringIssueState] = useState(null);

  const {
    uuid, params, created, uinfo,
  } = selectedIssue;

  // cloneBtn functions
  const { requestCreateRecurringIssue } = useMyRecurringIssues();

  const { getURLParams } = useURLParams();

  const projectData = head(useSelector(getProject));
  const vectorData = head(useSelector(getVectorInfo));

  const { activeVector, activeProject } = getURLParams();
  
  const getListUsers = () => {
    if (activeProject && !activeVector) {
      const projectUUIDs = getUuidsfromParamsUsers(projectData?.params?.users);
      return projectUUIDs;
    }
    if (activeVector) {
      return getUuidsfromParamsUsers(vectorData?.params?.users);
    }
    return [];
  };

  const getIssueForClone = () => dispatch(getIssueNew({
    uuid: uuid,
    params: {},
    constants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    withFiles: true,
    partition: partitionType,
  }));

  const getFiles = async (res) => {
    const resFiles = get(res, '[0].files', []);
    const list = resFiles.map((i) => `data:${i.content_type};base64, ${i.file}`);

    const blobs = await convertB64ToBlob(list);

    const files = blobs.map((i, index) => new File(
      [i],
      resFiles[index].filename,
      { type: resFiles[index].content_type },
    ));

    return files;
  };

  const getStartData = async (setDefaultState) => {
    const res = await getIssueForClone();
    let issueVector = vectorData ?? null;
    if (res?.[0].params?.vector && !activeVector) {
      const resVector = await dispatch(getVector({
        uuid: res?.[0].params?.vector,
        partition: partitionType,
        constants: [
          'GET_VECTOR_FOR_CLONE_ISSUE_REQUEST',
          'GET_VECTOR_FOR_CLONE_ISSUE_SUCCESS',
          'GET_VECTOR_FOR_CLONE_ISSUE_FAILURE',
        ],
      }));
      issueVector = resVector?.[0] || null;
    }
    const files = await getFiles(res);

    const isClosed = get(res, '[0].params.status') === 'closed'
    || get(res, '[0].params.status') === 'deployed'
    || get(res, '[0].params.status') === 'abandoned';

    const isValidEstimatedTime = ['easy', 'normal', 'difficult', 'very_complicated'].includes(get(res, '[0].params.estimated_time'));
    const cloneStartData = {
      usersUuidsForAssigned: getListUsers(),
      assignedProject: activeProject || '',
      assignedVector: issueVector?.uuid || activeVector || '',
      selectedVectorData: { ...omit(issueVector, ['params']), ...(issueVector?.params ?? {}) } || {},
      selectedProjectData: projectData || {},
      isGlobalCreateModal: false,
      ...omit(get(res, '[0].params'), ['id', 'lifeTime']),
      estimated_time: isValidEstimatedTime ? get(res, '[0].params.estimated_time') : 'easy',
      title: `${get(res, '[0].params.title')}`,
      description: `${get(res, '[0].params.description', '')}`,
      status: isClosed ? 'created' : get(res, '[0].params.status'),
      files,
    };
    setDefaultState(cloneStartData);
  };

  //

  const getURForGoToBtn = () => {
    const { project, vector } = params || {};

    const search = [];

    if (project) {
      search.push(`?activeProject=${project}`);
    }

    if (uuid) {
      search.push(`&activeIssue=${uuid}`);
    }

    if (vector) {
      search.push(`&activeVector=${vector}`);
    }

    if (partitionType === partitionNamesConfig.partition3) {
      return {
        pathname: `/public/user/${myPublicEntityUUID}/projects/issuesnew`,
        search: search.join(''),
      };
    }

    return (
      {
        pathname: '/pm/projects/issuesnew',
        search: search.join(''),
      }
    );
  };

  const createdBy = `${capitalize(get(uinfo, 'first_name', ''))} ${get(uinfo, 'last_name', '')}`;

  const {
    hideCopyLinkBtn,
    hideBookmarkBtn,
    hideDeleteIssueBtn,
    hiddenCloneBtn,
    hideGoButton,
    hideActivitiesBtn,
    hideEditAllSwitcherBtn,
  } = useMemo(() => ({
    hideCopyLinkBtn: hiddenView.includes('copyLinkBtn'),
    hideBookmarkBtn: hiddenView.includes('bookmarkBtn'),
    hideDeleteIssueBtn: hiddenView.includes('deleteIssueBtn'),
    hiddenCloneBtn: hiddenView.includes('cloneBtn'),
    hideGoButton: hiddenView.includes('goButton'),
    hideActivitiesBtn: hiddenView.includes('activities'),
    hideEditAllSwitcherBtn: hiddenView.includes('editAll'),
  }), [JSON.stringify(hiddenView)]);

  const subTitle = (
    <div className="flex items-center w-full gap5">
      <CommonInfo
        creator={createdBy}
        createdDate={created}
        lastActivity={get(params, 'lastActivityTimestamp')}
        firstActivity={get(params, 'firstActivityTimestamp')}
      />
      {!hideCopyLinkBtn && (
        <CopyURL
          partition={partitionType}
          entityType="issue"
          entityUUID={uuid}
        />
      )}
      {!hideGoButton && (
        <GoToButton
          iconSize={0.7}
          pathToRedirect={getURForGoToBtn()}
          pathForLabelTranslate="wms.buttons.go_to_task"
        />
      )}
    </div>

  );

  const items = [{
    key: '1',
    label: !hideBookmarkBtn ? (
      <AddToBookmark
        afterDeleteABookmark={afterDeleteABookmark}
        entityUUID={uuid}
        partition={partitionType}
        type="outline"
        className="w-full"
      />
    ) : null,
  }, {
    key: '2',
    label: !hiddenCloneBtn ? (
      <CreateRecurringBtn
        issueUUID={uuid}
        partitionType={partitionType}
        createIssueCallback={createIssueCallback}
        buttonClassName="w-full flex items-center justify-center"
        disabled={disabledCloneIssue}
        setDefaultRecurringIssueState={setDefaultRecurringIssueState}
        defaultRecurringIssueState={defaultRecurringIssueState}
        getStartData={getStartData}
      />
    ) : null,
  }, {
    key: '3',
    label: !hiddenCloneBtn ? (
      <CloneIssueBtn
        issueUUID={uuid}
        partitionType={partitionType}
        createIssueCallback={createIssueCallback}
        buttonClassName="w-full flex items-center justify-center"
        disabled={disabledCloneIssue}
        setDefaultIssueState={setDefaultIssueState}
        getStartData={getStartData}
        customProject={customProject}
      />
    ) : null,
  },
   {
    key: '4',
    label: !hideDeleteIssueBtn ? (
      <HardDeleteEntityContainer
        name="remove"
        typeInLabel="issue"
        entityType="issue"
        labelMode
        entityUUID={uuid}
        sizeBtn="small"
        block
        className="ant-btn-flex btnDanger-outline w-full"
        actionForEntity={deleteIssueCallback}
        partitionType={partitionType}
      />
    ) : null,
  },
  ];

  const headerButtons = (
    <Space
      align="center"
      size={[5, 5]}
      className="flex-wrap justify-end"
    >
      <RestoreEntityType
        archiveEntityUUID={uuid}
        callbackAction={restoreEntityCallback}
      />

      {!hideEditAllSwitcherBtn && (isEditAllMode ? (
        <>
          <Tooltip
            title={capitalize(t('wms.verb.cancel'))}
            color="#E28000"
          >
            <BaseButton
              size="small"
              shape="default"
              className={cn('view_btn_link__orange border')}
              onClick={onCloseAllEdit}
            >
              <Icon path={mdiArrowULeftTop} size={0.8} />
            </BaseButton>
          </Tooltip>

          <UnSaveDataConfirmNew
            dataIsNotSave={isEditAllMode}
            placement="bottom"
          >
            <BaseButton
              id="saveButton"
              // className="btnWarning-outline"
              size="small"
              onClick={onSaveAllEdit}
              className={cn({ btnGreen: isEditAllMode })}
              disabled={disabledSaveAll}
            >
              {capitalize(t('', 'save'))}
            </BaseButton>
          </UnSaveDataConfirmNew>
        </>
      )
        : (
          <BaseButton
            id='issuesButton'
            className="btnWarning-outline"
            size="small"
            onClick={onEditAll}
            disabled={false}
          >
            <Icon path={mdiPencil} size={0.7} className="mr-1" />
            {capitalize(t('', 'edit'))}
          </BaseButton>
        )
      )}

      {!hideActivitiesBtn && (
        isRecurring ?
        <>
          <NewIssueFromRecurringBtn
            partitionType={partitionType}
            createIssueCallback={createIssueCallback}
          />
          <HardDeleteEntityContainer
            name="remove"
            hideLabel={true}
            noHideBtn={true}
            isAllowDelete={true}
            entityType="recurring"
            labelMode
            entityUUID={uuid}
            sizeBtn="small"
            block
            className="ant-btn-flex btnDanger-outline w-full"
            actionForEntity={deleteIssueCallback}
            partitionType={partitionType}
          />
        </> : 
        <BaseButton
          id='activitesButton'
          className="btnPrimary-outline"
          size="small"
          onClick={openSidePanel}
          disabled={false}
        >
          <Icon path={mdiCommentTextMultipleOutline} size={0.7} className="mr-1" />
          {capitalize(t('', 'activities'))}
        </BaseButton>
      )}
      {
        isRecurring ?
        <></> : 
        <Dropdown
        menu={{
          items,
        }}
      >
        <BaseButton
          id='dotsVerticalButton'
          className="btnPrimary-outline"
          size="small"
        // onClick={openSidePanel}
        // disabled={false}
        >
          <Icon path={mdiDotsVertical} size={1} />
          {/* <Icon path={mdiChevronDown} size={0.7} className="ml-1" /> */}
        </BaseButton>
      </Dropdown>
      }
      
    </Space>
  );

  const closeModalCallback = () => {
    setDefaultIssueState(null);
  };

  const confirmDescription = capitalize(
    t('wms.modals.body.close_modal_new_issue', 'the entered data will be lost, are you sure?'),
  );

  useEffect(() => {
    if (defaultRecurringIssueState) {
      requestCreateRecurringIssue(defaultRecurringIssueState, defaultRecurringIssueState.title, partitionType);
      createIssueCallback()
    }
  }, [defaultRecurringIssueState])

  return (
    <>
      <HeaderCard
        title={isRecurring ? capitalize(t('wms.labels.recurring_issue')) : capitalize(t('wms.labels.issue'))}
        spanTitleSection={10}
        spanDescriptionSection={14}
        subTitle={subTitle}
        extra={headerButtons}
        backCallback={resetIssueCallback}
      />
      <BtnModal
        // title={title}
        width={800}
        openFromOuterComponent={!!defaultIssueState}
        confirmOnClose
        confirmDescription={confirmDescription}
        centered
        footer={null}
        afterClose={closeModalCallback}
      >
        <NewIssueComponents
          hideView={[]}
          disabledView={[]}
          partitionType={partitionType}
          defaultIssueConfig={defaultIssueState}
          createIssueCallback={createIssueCallback}
          customParentIssue={customProject}
        />
      </BtnModal>
    </>

  );
}
export default memo(HeaderSectionButtons);

HeaderSectionButtons.propTypes = {
  hiddenView: array,
  partitionType: string,
  resetIssueCallback: func,
  afterDeleteABookmark: func,
  restoreEntityCallback: func,
  deleteIssueCallback: func,
  createIssueCallback: func,
  openSidePanel: func,
  isEditAllMode: bool,
  disabledSaveAll: bool,
  onSaveAllEdit: func,
  onEditAll: func,
  onCloseAllEdit: func,
  disabledCloneIssue: bool,
  isRecurring: bool,
  customProject: string
};

import React, {useEffect, useState } from "react";
import {Col, Flex} from "antd";
import BaseCard from "../../../../components/_ui/BaseCard/BaseCard";
import { useDispatch, useSelector } from "react-redux";
import {getListAndPartialReadEntities} from "../../../../entity/actions/entityActions"
import {getProjectParams, getProjectUUID } from "../../../selectors/selectors";
import { string } from "prop-types";
import  { DownOutlined } from '@ant-design/icons';
import { Tooltip } from "antd/lib";
import EntityParamTag from "../../../../components/entityParamTag/EntityParamTag";
import { capitalize, get } from "lodash";
import {defaultOrderRules, defaultVectorsPagination, priorityWeight} from "../../issuesView/utils";
import { useTranslation } from "react-i18next";
import AntDesignSearchBox54origins from "../../../../54origins/components/AntDesignSearchBox54origins";
import { mdiAccountMultipleOutline, mdiChevronDown, mdiIdentifier, mdiPriorityLow, mdiTimerSand } from "@mdi/js";
import DateLeft from "../../../../components/DateLeft";
import Icon from '@mdi/react';
import useActorsW54 from "../../../../actors/hooks/useActorsW54";
import WrapperAvatarsGroupForProject from "../actorAvatarsGroup/WrapperAvatarsGroupForProject";
import WrapperAddVector from "../../../containers/vector/WrapperAddVector";
import BaseTableWithPagination from "../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination";
import IssuesTableForLaunchPad from "./IssuesTableForLaunchPad";
import useURLParams from "../../../../hooks/useURLParams";
import useTableFilter from "../../../../components/_ui/hooks/useTableFilter";
import { columnsFilterConfig, defaultVectorFilter } from "../../issuesView/constants/vectorFilterOptions";
import AssigningUsersRoot from "../../../../assignUsersModal/AssigningUsersRoot";
import { getUuid } from "../../../../assignUsersModal/utils";
import { changeSortVectors } from "./utils";

import './LaunchPad.scss';


function LaunchPad({defaultPartition}) {

const {t} = useTranslation()
const dispatch = useDispatch()
const projectUUID = useSelector(getProjectUUID)

const {addAndRemoveSearchParams} = useURLParams();

const projectParams = useSelector(getProjectParams);

const { users: allActors } = useActorsW54({ actors: projectParams?.usersSearch });
const hideElements = ['versionNumber']

const [launchVectors, setLaunchVectors] = useState(null)
const [loadingVectors, setLoadingVectors] = useState(null)
const [totalCountVectors, setTotalCountVectors] = useState(0)
const [hideVectors, setHideVectors] = useState(false)
const [activeVector, setActiveVector] = useState(null)
const [valueSearch, setValueSearch] = useState('')
const [openUsersFilter, setOpenUsersFilter] = useState(false)
const [usersFilter, setUsersFilter] = useState([])
const [changeIssue, setChangeIssue] = useState(false)
const [limit, setLimit] = useState(10);

const [paginationOptions, changePaginationOptions] = useState(defaultVectorsPagination);
const [orderRules, changeOrderRules] = useState(defaultOrderRules);

const { currentPage: newCurrentPage} = paginationOptions;


const {
  filterConfig: vectorFilterConfig,
  changeFilterConfig: changeVectorFilterConfig,
} = useTableFilter('vector', {...defaultVectorFilter, type: ['launch']});


const filterByUsers = (usersData) => {
  const newUsers = usersData?.users?.map((el) => getUuid(el));
  if(newUsers.length > 0) {
    getLaunchVectors({ usersSearch: newUsers })
  } else {
    getLaunchVectors()
  }
  setUsersFilter(newUsers);
}  

const changeLimit = (size) => {
  if (size !== limit) {
    setLimit(size);
  }
};

const onSearchData = (value) => {
  changePaginationOptions(defaultVectorsPagination)
  setHideVectors(false)
  setValueSearch(value);
};

const currentSortCallback = (dataSort) => {
  if (!get(dataSort, 'order')) {
    changeOrderRules({
      orderBy: 'created',
      order: 'desc',
    });
  } else {
    changeOrderRules(dataSort);
  }
};

const getLaunchVectors = async (additionalParams) => {
  setLoadingVectors(true)
  const data = {
      entity_type: "vector",
      depth: 1,
      parent: projectUUID,
      ...paginationOptions,
      ...orderRules,
      params: {
        ...vectorFilterConfig,
        ...additionalParams
      },
      search_data: {
        value: valueSearch,
        ignore_case: true,
        fields: {
          params: ['title'],
        },
      },
      params_fields: {
        id: "id",
        title: 'title',
        status: "status",
        users: "users",
        usersSearch: "usersSearch",
        priority: "priority",
        type: 'type',
        lifeTime: "lifeTime"
      },
  };
  const constants = [
    "GET_PROJECT_VECTORS_REQUEST",
    "GET_PROJECT_VECTORS_SUCCESS",
    "GET_PROJECT_VECTORS_FAILURE"
  ]

    const vectors = await dispatch(getListAndPartialReadEntities({
      data,
      constants,
      partition: defaultPartition,
    }))
    setLaunchVectors(vectors.data)
    setTotalCountVectors(vectors.total)
    setLoadingVectors(false)
}

useEffect(() => {
  getLaunchVectors()
}, [valueSearch, paginationOptions, vectorFilterConfig, orderRules])

const onRow = (record) => ({
  onClick: () => {
      addAndRemoveSearchParams({ activeVector: record.uuid })
      setActiveVector(record)
      setHideVectors(true)
      setChangeIssue(false)
  },
})

const columns = [
  {
    dataIndex: 'id',
    title: (
      <Tooltip placement="top" title="vID">
          <Icon path={mdiIdentifier} size={1} className="text-secondary" />
      </Tooltip>
    ),
    className: 'wordBreakOneLine',
    key: 'id',
    render: (cell, row) => <span className="whitespace-nowrap text-xs">{row?.id}</span>,
  },
  {
    dataIndex: 'title',
    title: (
      <AntDesignSearchBox54origins onSearch={onSearchData} />
    ),
    render: (cell, row) => <EntityParamTag param="title" value={row?.title} type="value" maxLength={80} />,
  },
  {
    dataIndex: 'type',
    title: capitalize(t('wms.table.headers.type', 'Type')),
    sorter: (row1, row2) => changeSortVectors(row1, row2, 'type'),
    ...(columnsFilterConfig && { ...columnsFilterConfig?.type }),
    ...(vectorFilterConfig?.type && { defaultFilteredValue: vectorFilterConfig?.type }),
    className: hideElements?.includes('type') ? 'hidden' : '',
    key: 'type',
    render: (cell, row) =>  <EntityParamTag param="type" value={row?.type} type="badge" />,
  },
  {
    dataIndex: 'priority',
    ...(columnsFilterConfig && { ...columnsFilterConfig?.priority }),
    ...(vectorFilterConfig?.priority && { defaultFilteredValue: vectorFilterConfig?.priority }),
    title: (
      <Tooltip
        placement="top"
        title={capitalize(t('wms.table.headers.priority', 'Priority'))}
      >
        <Icon path={mdiPriorityLow} size={1} className="text-secondary" />
      </Tooltip>
    ),
    key: 'priority',
    sorter: (row1, row2) => priorityWeight(row1?.priority) - priorityWeight(row2?.priority),
    defaultSortOrder: 'descend', 
    render: (cell, row) => <EntityParamTag param="priority" value={row?.priority} type="char" />,
  },
  {
    dataIndex: 'status',
    title: capitalize(t('wms.table.headers.status', 'Status')),
    ...(columnsFilterConfig && { ...columnsFilterConfig?.status }),
    ...(vectorFilterConfig?.status && { defaultFilteredValue: vectorFilterConfig?.status }),
    key: 'status',
    sorter: (row1, row2) => changeSortVectors(row1, row2, 'status'),
    render: (cell, row) =>  <EntityParamTag param="status" value={row?.status} type="iconWithStatus" />,
  },
  {
    dataIndex: 'usersSearch',
    key: "usersSearch",
    title:  (
      <AssigningUsersRoot
        header="User filter"
        isOpen={openUsersFilter}
        setIsOpen={setOpenUsersFilter}
        isCheckWindowWidthMode={false}
        isClosePopoverOnLeaveMode
        isOneColumnMode={true}
        size={'extra small'}
        data={{
          uuids: projectParams?.usersSearch,
        }}
        isPopover
        onUpdateUsers={(usersData) => filterByUsers(usersData)}
        globalOptions={{
          uuids: projectParams?.usersSearch,
          isOneColumnMode: true,
          size: 'extra small',
        }}
        usersConfig={{
          label: 'Users',
          showSections: ['string-role'],
          textSelectButton: 'Select',
          textDeleteButton: 'Deselect',
          defaultItems: usersFilter,
        }}
        popoverChild={(
          <Flex className="ml-1 min-width-60px" align="center">
          <span className="mr-1">{capitalize(t('Users'))}</span>
            <Tooltip
              placement="top"
              onClick={() => setOpenUsersFilter(true)}
              style={{
                cursor: 'pointer',
              }}
              title={capitalize(t('wms.table.headers.assigned', 'Assigned'))}
            >
              <Icon
                path={mdiAccountMultipleOutline}
                role="button"
                size={1}
                className="text-secondary"
              />
              <Icon
                path={mdiChevronDown}
                role="button"
                className="text-secondary ml-1"
                size={1}
              />
            </Tooltip>
            <WrapperAvatarsGroupForProject
              actors={usersFilter || []}
              maxCount={3}
              avatarSize={22}
              popoverTrigger="click"
              popoverPlacement="right"
              checkProjectRole
            />
          </Flex>
        )}
      />
    ),
    render: (cell) => {
      const users = allActors.filter(user => cell.includes(user.uuid));
      return <WrapperAvatarsGroupForProject
      actors={users}
      avatarSize="small"
      avatarGap={4}
      avatarStyles={{ cursor: 'pointer' }}
      groupButtonSize="small"
      checkProjectRole
      />;
    }
  },
  {
    dataIndex: 'lifeTime',
    title: (
      <Tooltip placement="top" title={capitalize(t('wms.adjective.expired'))}>
        <Icon path={mdiTimerSand} size={1} className="text-secondary" />
      </Tooltip>
    ),
    key: 'lifeTime',
    sorter: true,
    render: (cell, row) =>  <DateLeft minus date={row?.lifeTime} type="tag" customStyle="text-neutral-600" />,
  },

];

return (
    <Col span={24}>
    <BaseCard className="w-100 padding-16 vector-card-issues-view">
          <div className="flex justify-between flex-wrap mb-1">
        <div className="flex">
          <h6 className="mt-1 mr-2">
            {capitalize(t(`Launch Vectors`))}
          </h6>
          <WrapperAddVector
            vectorType="general"
            hideBtnAddVector={false}
            disabled={false}
            partitionType={defaultPartition}
            parentUUID={projectUUID}
            newVectorSuccessCallback={() => getLaunchVectors()}
            titleButton={(t(`Add Vector`))}
          />
        </div>
      </div>
    <div>
        <BaseTableWithPagination
        pageLimit={limit}
        loading={loadingVectors}
        newCurrentPage={newCurrentPage}
        useCustomPagination
        columns={columns}
        data={hideVectors && launchVectors.length > 1 ? 
        launchVectors.filter(vector => vector.uuid.includes(activeVector.uuid)) : launchVectors}
        disablePagination={hideVectors}
        rowKey="uuid"
        size="small"
        headerRowClassName="universal_header_table_row color-gray"
        total={totalCountVectors}
        pageSizeOptions={['5', '10', "25"]}
        onRow={onRow}
        changePageLimit={changeLimit}
        getPaginationOptions={changePaginationOptions}
        filterCallback={changeVectorFilterConfig}
        currentSortCallback={currentSortCallback}
      />
        {
        (activeVector &&
          <div className="hide-collapse" 
          onClick={() => setHideVectors(prev => !prev)}>
              <DownOutlined className="hide-arrow" rotate={hideVectors ? 0 : 180} />
              {hideVectors ? <p>Show vectors</p> : <p>Hide vectors</p>}
          </div>
        )
        }
            </div>
    </BaseCard>
  {activeVector && <>
    <IssuesTableForLaunchPad
      key={`mandatatory${activeVector.uuid}`}
      issuesType="mandatory"
      defaultPartition={defaultPartition}
      allActors={allActors}
      projectUUID={projectUUID}
      activeVector={activeVector}
      changeIssue={changeIssue}
      setChangeIssue={setChangeIssue}
    />
    <IssuesTableForLaunchPad
      key={`connected${activeVector.uuid}`}
      issuesType="connected"
      defaultPartition={defaultPartition}
      allActors={allActors}
      projectUUID={projectUUID}
      activeVector={activeVector}
      changeIssue={changeIssue}
      setChangeIssue={setChangeIssue}
    />
      </>}
    </Col>
)
}

LaunchPad.propTypes = {
    defaultPartition: string
}

export default LaunchPad
import React, { useEffect, useRef } from 'react'
import * as Y from 'yjs'
import { QuillBinding } from 'y-quill'
import Quill from 'quill'
import { handleDivider } from "./customModules/blots/DividerBlot";
import { handleQuillImage } from "./customModules/blots/ImageBlot";
import { handleTable } from "./customModules/blots/TableBlot";

const QuillEditorForHistory = ({ defaultDocContetnt }) => {
  const editorRef = useRef(null)  // Для привязки DOM элемента к Quill
  const documentRef = useRef(null)  // Для хранения экземпляра Yjs-документа
  const quillRef = useRef(null)  // Для хранения экземпляра Quill

  useEffect(() => {
    // Создаем новый Yjs-документ только один раз
    const ydoc = new Y.Doc()
    documentRef.current = ydoc

    // Создаем объект текста Yjs
    const yText = ydoc.getText('quill')

    // Инициализируем Quill редактор только один раз
    if (!quillRef.current) {
      const quill = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          enable: false,
          table: true,
          tableUI: true,
          cursors: false, // Активировать модуль курсоров
          toolbar: false, // Отключаем панель инструментов
          imageResize: {
            disabled: true, // Отключаем изменение размера изображения
          },
          clipboard: true,
          history: {
            delay: 1000,
            maxStack: 500,
            userOnly: true,
          },
        },
        readOnly: true,  // Включаем режим только для чтения
      })
      quillRef.current = quill
    }

    // Связываем Quill с Yjs
    new QuillBinding(yText, quillRef.current)

    // Применяем обновление, если оно есть
    if (defaultDocContetnt) {
      Y.applyUpdate(ydoc, defaultDocContetnt)
    }

    // Очистка при размонтировании компонента
    return () => {
      quillRef.current = null
      documentRef.current = null
    }
  }, [defaultDocContetnt]) // Эффект срабатывает только при первом рендере компонента


  return <div ref={editorRef} />
}

export default QuillEditorForHistory

import React from 'react';

export default function ShowMoreBtn({
  show,
  toggleShowMoreCallback,
}) {
  return show && (
  <div className="w-full flex justify-center">
    <div
      onClick={toggleShowMoreCallback}
      className="wrapper_show_more_btn_comments"
      role="button"
      tabIndex="0"
    >
      <div className="wrapper_show_more_btn_comments__line" />
      <div className="wrapper_show_more_btn_comments__word"> Show more</div>
    </div>
  </div>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';

import { bool } from 'prop-types';
import PermissionsStartPage from '../../../../../projectFlow/containers/permissions/PermissionsStartPage';

import { capitalizeAndTranslateMsg } from '../../../../../MainUtils';
import { getOtherUserPublicProfileUUID, getUserPublicProfileUUID } from '../../../../../userFlow/store/selectors/selectors';

function DashboardPermsCardRoot({
  otherUser,
}) {
  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);
  const otherUserPublicEntityUUID = useSelector(getOtherUserPublicProfileUUID);

  const entityUUID = otherUser ? otherUserPublicEntityUUID : myPublicEntityUUID;

  return (
    <div className="py-3 w-full">
      <PermissionsStartPage
        entityUUID={entityUUID}
        partitionType="PUBLIC"
        nameSectionPermissions={capitalizeAndTranslateMsg('wms.permissions.headers.project_permissions', 'project permissions')}
      />
    </div>
  );
}

DashboardPermsCardRoot.propTypes = {
  otherUser: bool,
};

export default DashboardPermsCardRoot;

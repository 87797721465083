import React, { useState } from 'react';
import {
  Col, Row,
} from 'antd';
import { func, string, number } from 'prop-types';
import Icon from '@mdi/react';
import {
  mdiInformationOutline,
  mdiRefresh,
  mdiLoading,
} from '@mdi/js';
import InfoComponent from '../infoComponent/InfoComponent';
import BaseButton from '../../../../../../components/_ui/BaseButton/BaseButton';
import './index.scss';
import BaseTag from "../../../../../../components/_ui/BaseTag/BaseTag";
import { UI54Button, UI54Icon } from "@agpl/ui54kit";

export default function ScoringStepBlock(props) {
  const {
    score,
    title,
    buttonClassname,
    // parent,
    // partitionType,
    refreshCallback,
    tooltipInfoText,
  } = props;

  const [loading, setLoading] = useState(false);

  const handleRefreshScore = async () => {
    try {
      setLoading(true);
      await refreshCallback();
    } catch (e) {
      console.log('handleRefreshScore error', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row gutter={[4, 0]} className="items-center" style={{ position: 'relative', top: '-3px' }}>
      <Col span={11}>
        {' '}
        <span className="text-secondary">
          {title}
          :
        </span>
      </Col>
      <Col span={10}>
        <Row className="flex-nowrap">
          <UI54Button
            id={`${title.split(' ').join('')}` + 'Button'}
            className={`scoringBtn ${buttonClassname} flex items-center justify-between`}
            disabled={loading}
            onClick={handleRefreshScore}
          >
            <span className="text-sm">{score !== '-' ? score : <>&mdash;</> }</span>
            {/* <Divider type="vertical" style={{ width: '2px', height: '100%' }} className="scoringBtnDivider" /> */}
            {/* {loading ? <Icon path={mdiLoading} size={0.8} className="rotateAnimation" /> : <Icon path={mdiRefresh} size={0.8} />} */}
            <div className="backgroundRefreshIcon">
              {loading ? <UI54Icon path={mdiLoading} size={1.2} className="rotateAnimation" style={{ color: 'white' }} /> : <UI54Icon path={mdiRefresh} size={1.2} style={{ color: 'white' }} />}
              {/* <Icon path={mdiRefresh} size={1} style={{ color: 'white' }} /> */}
            </div>

          </UI54Button>
          <div className="ml-1">
            <InfoComponent
              tooltipPlacement="topLeft"
              tooltipTitle={tooltipInfoText}
            />
          </div>
        </Row>

      </Col>

    </Row>

  // <div className="flex items-center" style={{ position: 'relative', top: '-2px' }}>

    // {/* <BaseTag
    //   className="fontSize15 p-1"
    //   style={{ fontWeight: '500' }}
    //   color={buttonClassname}
    // >
    // </BaseTag> */}

  // </div>

  );
}

ScoringStepBlock.propTypes = {
  score: number,
  title: string,
  buttonClassname: string,
  parent: string,
  partitionType: string,
  refreshCallback: func,
  tooltipInfoText: string,
};

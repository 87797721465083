// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.08.01 //Format YYYY|MM|DD

import React from 'react';
import {
  string, number, arrayOf, object, oneOf, oneOfType, element,
} from 'prop-types';
import { Avatar, Tooltip } from 'antd';

export const getColorForActor = (userUUID = '') => {
  const userColorNum = (Number.parseInt(userUUID[userUUID.length - 1], 16)
      + Number.parseInt(userUUID[0], 16))
    % 10;

  switch (userColorNum) {
    case 0:
      return '#f5222dbe';
    case 1:
      return '#fa531cb6';
    case 2:
      return '#fa8c16c2';
    case 3:
      return '#faad14be';
    case 4:
      return '#fadb14b7';
    case 5:
      return '#a0d911b6';
    case 6:
      return '#53c41ab2';
    case 7:
      return '#722ed1bb';
    case 8:
      return '#eb2f96c4';
    case 9:
      return '#13c2c2c4';
    default:
      return '#2f55ebbe';
  }
};

function CircleActorAvatar54origins({
  name,
  actorUUID,
  tooltip = '',
  tooltipPlacement,
  gap,
  size = 'large',
  styles = {},
}) {
  return (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <Avatar
        style={{
          ...styles,
          backgroundColor: getColorForActor(actorUUID),
          verticalAlign: 'middle',
        }}
        size={size}
        gap={gap}
      >
        {name}
      </Avatar>
    </Tooltip>
  );
}
CircleActorAvatar54origins.propTypes = {
  name: string,
  actorUUID: string,
  tooltip: oneOfType([string, element]),
  tooltipPlacement: string,
  gap: number,
  size: oneOfType([number, oneOf(['small', 'default', 'large']), object]),
  styles: object,
};

export function CircleActorAvatarsGroup54origins({
  actorAvatars = [],
  maxCount = 3,
  groupSize = 'default',
  avatarSize = 'default',
  avatarGap,
  avatarStyles,
  tooltipPlacement = 'top',
}) {
  return (
    <Avatar.Group
      max={{
        count: maxCount,
        popover: "hover",
        style: {
          color: '#f56a00',
          backgroundColor: '#fde3cf',
          cursor: 'pointer'
        }
      }}
      size={groupSize}
    >
      {actorAvatars.map((actor) => (
        <CircleActorAvatar54origins
          key={actor?.value}
          actorUUID={actor?.value}
          name={actor?.initials}
          tooltip={actor?.tooltip || actor?.fullName}
          tooltipPlacement={tooltipPlacement}
          size={avatarSize}
          gap={avatarGap}
          styles={avatarStyles}
        />
      ))}
    </Avatar.Group>
  );
}
CircleActorAvatarsGroup54origins.propTypes = {
  actorAvatars: arrayOf(object),
  maxCount: number,
  groupSize: oneOfType([number, oneOf(['small', 'default', 'large']), object]),
  avatarSize: oneOfType([number, oneOf(['small', 'default', 'large']), object]),
  avatarGap: number,
  avatarStyles: object,
  tooltipPlacement: string,
};

export default CircleActorAvatar54origins;

import Quill from "quill";

export class CustomImageResize {
  constructor(quill, options = {}) {
    this.quill = quill;
    this.activeImage = null;
    this.originalDimensions = null;
    this.overlay = null;
    this.disabled = options.disabled || false;
    this.highlightColor = options.highlightColor || '#1890ff';
    this.initialize();
  }

  initialize() {
    if (this.disabled) {
      return;
    }

    this.quill.root.addEventListener('click', (evt) => {
      if (evt.target && evt.target.tagName === 'IMG') {
        if (this.activeImage === evt.target) return;
        this.showOverlay(evt.target);
      } else {
        this.applyChanges();     
      }
    });

    this.quill.root.addEventListener('mouseover', (evt) => {
      if (evt.target && evt.target.tagName === 'IMG') {
        evt.target.style.outline = `2px solid ${this.highlightColor}`;
      }
    });

    this.quill.root.addEventListener('mouseout', (evt) => {
      if (evt.target && evt.target.tagName === 'IMG') {
        evt.target.style.outline = '';
      }
    });   

    document.addEventListener('keydown', (evt) => {
      if (this.activeImage && this.overlay) {
        evt.preventDefault();
        if (evt.key === 'Enter') {
          this.applyChanges();      
        } else if (evt.key === 'Escape') {
          this.cancelChanges();
          this.hideOverlay();
        } else if (evt.key === 'Delete' || evt.key === 'Backspace') {
          this.deleteImage();
          this.hideOverlay();
        }
      }
    });
  }

  showOverlay(image) {
    if (this.disabled) {
      return;
    }

    if (this.overlay) {
      this.hideOverlay();
    }

    this.activeImage = image;
    this.originalDimensions = {
      width: image.offsetWidth,
      height: image.offsetHeight,
    };

    this.overlay = document.createElement('div');
    this.overlay.style.position = 'absolute';
    this.overlay.style.border = `2px dashed ${this.highlightColor}`;

    ['nw', 'ne', 'sw', 'se'].forEach(corner => {
      const handle = document.createElement('div');
      handle.classList.add('resize-handle', corner);
      handle.style.position = 'absolute';
      handle.style.width = '16px';
      handle.style.height = '16px';
      handle.style.background = '#fff';
      handle.style.border = `1px solid ${this.highlightColor}`;
      handle.style.cursor = `${corner}-resize`;
      handle.style.zIndex = 10;

      if (corner.includes('n')) {
        handle.style.top = '-8px';
      } else {
        handle.style.bottom = '-8px';
      }

      if (corner.includes('w')) {
        handle.style.left = '-8px';
      } else {
        handle.style.right = '-8px';
      }

      this.overlay.appendChild(handle);
      this.addResizeHandler(handle, image);
      return handle;
    });

    this.sizeDisplay = document.createElement('div');
    this.sizeDisplay.style.position = 'absolute';
    this.sizeDisplay.style.backgroundColor = '#fff';
    this.sizeDisplay.style.border = '1px solid #777';
    this.sizeDisplay.style.padding = '2px 5px';
    this.sizeDisplay.style.fontSize = '12px';
    this.sizeDisplay.style.bottom = '-28px';
    this.sizeDisplay.style.right = '9px';
    
    this.overlayhelper = document.createElement('div');
    this.overlayhelper.style.position = 'absolute';
    this.overlayhelper.style.backgroundColor = '#fff';
    this.overlayhelper.style.border = '1px solid #777';
    this.overlayhelper.style.padding = '2px 5px';
    this.overlayhelper.style.fontSize = '12px';
    this.overlayhelper.style.bottom = '2px';
    this.overlayhelper.style.right = '9px';  
    this.overlayhelper.innerHTML = '<span><span>Save: <b>Enter</b> / <b>Click outside area</b></span> <span>Cancel: <b>Esc</b></span></span>'

    this.overlay.appendChild(this.sizeDisplay);
    this.overlay.appendChild(this.overlayhelper);


    this.quill.root.parentNode.appendChild(this.overlay);
    this.positionOverlay(image);
    this.updateSizeDisplay(image);
    this.quill.root.style.overflowY = 'hidden';
    const classNameContainer = this.quill?.container?.className;
    const checkIsVertical = classNameContainer ? classNameContainer.includes('vertical') : false ;
    this.quill.root.style.paddingRight = checkIsVertical ? '35px' : '15px';
    this.quill.blur();
  }

  positionOverlay(image) {
    const rect = image.getBoundingClientRect();
    const containerRect = this.quill.root.parentNode.getBoundingClientRect();

    this.overlay.style.top = `${rect.top - containerRect.top}px`;
    this.overlay.style.left = `${rect.left - containerRect.left}px`;
    this.overlay.style.width = `${rect.width}px`;
    this.overlay.style.height = `${rect.height}px`;
  }

  addResizeHandler(handle, image) {
    const aspectRatio = image.naturalWidth / image.naturalHeight;
    let startX, startWidth;
    let startY, startHeight;
    const onDrag = (evt) => {
      const deltaX = evt.clientX - startX;
      const deltaY = evt.clientY - startY;

      let newWidth, newHeight;
      if (handle.classList.contains('nw') || handle.classList.contains('sw')) {
        newWidth = startWidth - deltaX;
        newHeight = newWidth / aspectRatio;
      } else {
        newWidth = startWidth + deltaX;
        newHeight = newWidth / aspectRatio;
      }

      this.overlay.style.width = `${newWidth}px`;
      this.overlay.style.height = `${newHeight}px`;

      this.updateSizeDisplay({ width: newWidth, height: newHeight });
    };

    handle.addEventListener('mousedown', (evt) => {
      evt.preventDefault();

      startX = evt.clientX;
      startY = evt.clientY;
      startWidth = this.overlay.offsetWidth;
      startHeight = this.overlay.offsetHeight;

      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', onDrag);
      }, { once: true });
    });
  }

  updateSizeDisplay(dimensions) {
    this.sizeDisplay.textContent = `${Math.round(dimensions.width)} x ${Math.round(dimensions.height)}`;
  }

  checkIsChanged() {
    if (this.activeImage && this.overlay) {
      const currentWidth =  this.activeImage.style.width || this.activeImage.width;
      const currentHeight = this.activeImage.style.height || this.activeImage.height;
       const newWidth = parseInt(this.overlay.style.width, 10);
       const newHeight = parseInt(this.overlay.style.height, 10);
      //  console.log('crdt currentWidth', currentWidth)
      //  console.log('crdt newWidth', newWidth)
       return (currentWidth !== newWidth) || (currentHeight !== newHeight)
    }
  }

  applyChanges() {
    const checkChanges = this.checkIsChanged();
    // console.log('crdt checkChanges', checkChanges)
    if (this.activeImage && this.overlay && checkChanges) {
      const newWidth = parseInt(this.overlay.style.width, 10);
      const newHeight = parseInt(this.overlay.style.height, 10);
      // this.activeImage.style = JSON.stringify({
      //   width: `${newWidth}px`,
      //   height: `${newHeight}px`
      // });
      this.activeImage.setAttribute('width', `${newWidth}`);
      this.activeImage.setAttribute('height', `${newHeight}`);
     
      const imageBlot = Quill.find(this.activeImage);
      const index = this.quill.getIndex(imageBlot);
      if (index !== -1) {
        this.quill.formatText(index, 1, { width: `${newWidth}px`, height: `${newHeight}px` });
      }
      // if (index >= 0) {
      //   // Применяем свойства форматирования квиллу, ваш Quill может не поддерживать атрибуты width/height напрямую
      //   console.log('crdt newWidth', newWidth)
      //   this.quill.formatText(index, 1, {
      //     height: `${newHeight}px`,
      //     width: `${newWidth}px`         
      //   });
      // }   
    }
    this.hideOverlay();
    this.quill.focus();
  }

  cancelChanges() {
    if (this.activeImage && this.originalDimensions) {
      this.activeImage.style.width = `${this.originalDimensions.width}px`;
      this.activeImage.style.height = `${this.originalDimensions.height}px`;
    }
    this.quill.focus();
  }
  
  deleteImage() {
    if (this.activeImage) {
      const blot = Quill.find(this.activeImage);
  
      if (blot) {
        const index = this.quill.getIndex(blot);
        const length = 1;
  
        // Удаляем изображение из Quill
        const delta = new Quill.imports.delta()
          .retain(index)
          .delete(length);
  
        this.quill.updateContents(delta);
  
        // Сбрасываем текущее активное изображение
        this.activeImage = null;
        this.originalDimensions = null;
      }
    }
    this.hideOverlay();
  }

  hideOverlay() {
    if (this.overlay) {
      this.quill.root.style.overflowY = 'auto';
      this.quill.root.style.paddingRight = '15px';
      this.overlay.parentNode.removeChild(this.overlay);
      this.overlay = null;
    }
    this.activeImage = null;
    this.originalDimensions = null;
  }
}
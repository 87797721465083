import { prop } from 'ramda';
import { get, isEmpty } from 'lodash';

import { ProjectTypeCommonConstants } from '../constants/Constants';
import { ErrorConstants } from '../../constants/types';
import { EntityConstants } from '../../entity/constants/Constants';
import axiosRequest, { axiosRequestPost } from '../../api/apiAxios';
import {
  entitySet,
  entityUpdate,
  entityRead,
  getListAndPartialReadEntities,
  getListOfEntities, getOrCreateSingle,
  getListAndReadEntities,
} from '../../entity/actions/entityActions';
import { affixDelete, getListAndReadAffix, getListOfAffix } from '../../affix/affixActions';
import { antNotification, getArrayForItem } from '../../MainUtils';
import { UsersPartitionConstants } from '../../userFlow/constants/Constants';
import { COMMENT_TYPE } from '../components/comment/commentType';
import { createEntityPermissions, deleteEntityPerm } from '../../entity/actions/entityPermActions';
import { partitionNamesConfig } from '../../api/appConfig';

const DEFAULT_PTC_PARTITION = partitionNamesConfig.partition1;

export function reverseComments() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.ISSUE_COMMENTS_REVERSE,
      data: '',
    });
  };
}

export function clearTimelogs() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_OF_TIMELOGS,
    });
  };
}

export function clearTestSuiteData() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_TEST_SUITE,
    });
  };
}

export function clearTestCycleData() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_TEST_CYCLE,
    });
  };
}

export function clearTestCaseData() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_TEST_CASE,
    });
  };
}

export function clearError() {
  return (dispatch) => {
    dispatch({
      type: ErrorConstants.SERVER_RESPONSE_FAILURE,
      status: '',
    });
  };
}

export function clearHistoryOfDocument() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_HISTORY_OF_DOCUMENT,
    });
  };
}

export function clearDocuments() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_PROJECT_DOCUMENTS,
    });
  };
}
export function clearDocument() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_DOCUMENT,
    });
  };
}

export function clearAllComments() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_ALL_COMMENTS,
    });
  };
}

export const resetArchiveState = () => ({ type: ProjectTypeCommonConstants.CLEAR_ARCHIVE_ENTITY });

export function clearAuditData() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_AUDIT_DATA,
    });
  };
}

export function clearListOfIssues() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_LIST_OF_ISSUES,
    });
  };
}

export const clearVector = () => ({ type: ProjectTypeCommonConstants.CLEAR_VECTOR });

export function clearListOfVectors() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_LIST_OF_VECTORS,
    });
  };
}

export const clearListOfIdeas = () => (dispatch) => {
  dispatch({
    type: ProjectTypeCommonConstants.CLEAR_LIST_OF_IDEAS,
  });
};

export function storeDataForAudit(type, payload, criteria) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants[`STORE_${criteria.toUpperCase()}_DATA_FOR_AUDIT_${type.toUpperCase()}`],
      payload,
    });
  };
}

export function storeActivityForAudit(parentEntity, payload, criteria) {
  return (dispatch) => {
    dispatch({
      type:
        ProjectTypeCommonConstants[`STORE_${criteria.toUpperCase()}_ACTIVITY_FOR_AUDIT_${parentEntity.toUpperCase()}`],
      payload,
    });
  };
}


export function clearCassesInSuit() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.GET_TEST_SUITE_SUCCESS,
      payload: [],
    });
  };
}

export function clearCassesInCycle() {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.GET_TEST_CYCLE_SUCCESS,
      payload: [],
    });
  };
}

export function updateListOfTestCaseInCycle(data) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.UPDATE_LIST_OF_TEST_CASES_IN_CYCLE,
      payload: data,
    });
  };
}

export function updateListOfTestCaseInSuite(data) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.UPDATE_LIST_OF_TEST_CASES_IN_SUITE,
      payload: data,
    });
  };
}

export function addTestCaseInListTestCase(data) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.ADD_TEST_CASES_OF_LIST_IN_SUITE,
      payload: data,
    });
  };
}

export function updateOneTestCycleInStore(data) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE,
      payload: data,
    });
  };
}

export const getProjectRequest = (uuid, callback, partition = partitionNamesConfig.partition1, customConstants) => {
  const data = {
    entity_type: 'project',
    entity_uuid: uuid,
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.PROJECT_REQUEST,
    ProjectTypeCommonConstants.PROJECT_SUCCESS,
    ProjectTypeCommonConstants.PROJECT_FAILURE,
  ];

  const options = {
    partition,
    onSuccess: callback,
    // onFailure: () => history.push({ pathname: '/projects', search: `activeProject=${uuid}` }),
  };

  return entityRead({
    data,
    constants,
    options,
  });
};

export const getProjectScoring = (uuid, callback, partition = partitionNamesConfig.partition1) => {
  const data = {
    entity_type: 'project',
    entity_uuid: uuid,
    params: {
      title: 'title',
      status: 'status',
      scoringData: 'scoringData',
    },
  };
  const constants = [ProjectTypeCommonConstants.GET_CURRENT_PROJECT_ISSUES_SCORING];
  const options = {
    partition,
    onSuccess: callback,
    // onFailure: () => history.push({ pathname: '/projects', search: `activeProject=${uuid}` }),
  };

  return entityRead({
    data,
    constants,
    options,
  });
};

export function updateProjectScoring({
  uuid,
  params,
  partition = partitionNamesConfig.partition1,
}) {
  const data = {
    entity_type: 'project',
    entity_uuids: getArrayForItem(uuid),
    params,
  };
  const constants = [ProjectTypeCommonConstants.UPDATE_CURRENT_PROJECT_ISSUES_SCORING];
  const options = {
    partition,
  };
  return entityUpdate({
    data,
    constants,
    options,
  });
}

export function updateProjectRequest({
  uuid,
  params,
  partition = partitionNamesConfig.partition1,
  signal,
  constants: customConstants,
}) {
  const data = {
    entity_type: 'project',
    entity_uuids: getArrayForItem(uuid),
    params: { ...params },
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.EDIT_PROJECT_REQUEST,
    ProjectTypeCommonConstants.EDIT_PROJECT_SUCCESS,
    ProjectTypeCommonConstants.EDIT_PROJECT_FAILURE,
  ];

  const options = {
    signal,
    partition,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}

export function updateProjectUsersAndPerms({
  dispatch, data, partition, projectUUID,
}) {
  const {
    usersSearch = [], // uuids
    users = [], // uuid, role
    newUsers = [], // uuid, perms
    deleteUsers = [], // uuids
  } = data;

  if (newUsers.length > 0) {
    const newData = {
      entity_uuid: projectUUID,
      data: [
        ...newUsers.map(({ uuid, perms }) => ({
          actor: uuid,
          ...perms,
        })),
      ],
    };

    dispatch(
      createEntityPermissions({
        data: newData,
        partition,
      }),
    );
  }

  if (deleteUsers.length > 0) {
    const newData = {
      actors: deleteUsers,
      entity_uuid: projectUUID,
    };

    dispatch(
      deleteEntityPerm({
        data: newData,
        partition,
      }),
    );
  }

  const newData = {
    uuid: projectUUID,
    params: { users, usersSearch },
    partition,
  };

  dispatch(updateProjectRequest(newData))
    .then(() => {
      antNotification('success', 'Success');

      dispatch(getProjectRequest(projectUUID, null, partition));
    })
    .catch(() => antNotification('error', 'Access denied!'));
}

export function updateProjectOwnerOrCreator({
  entity_uuid,
  partition = partitionNamesConfig.partition1,
  signal,
  owner,
  actor,
}) {
  const data = {
    entity_type: 'project',
    entity_uuid,
    owner,
    actor,
  };

  const constants = [
    ProjectTypeCommonConstants.EDIT_PROJECT_REQUEST,
    ProjectTypeCommonConstants.EDIT_PROJECT_SUCCESS,
    ProjectTypeCommonConstants.EDIT_PROJECT_FAILURE,
  ];
  const options = {
    signal,
    partition,
  };

  return entitySet({
    data,
    constants,
    options,
  });
}

export function getListOfProjectUuids(parent, params, partition) {
  const data = {
    entity_type: 'project',
    parent,
    params,
  };

  const constants = [
    ProjectTypeCommonConstants.GET_LIST_OF_PROJECT_UUIDS_REQUEST,
    ProjectTypeCommonConstants.GET_LIST_OF_PROJECT_UUIDS_SUCCESS,
    ProjectTypeCommonConstants.GET_LIST_OF_PROJECT_UUIDS_FAILURE,
  ];

  const options = {
    partition,
  };

  return getListOfEntities({
    data,
    constants,
    options,
  });
}

export function getListOfProjectRequest(parent, params, partition = partitionNamesConfig.partition1) {
  return async (dispatch) => {
    const res = await dispatch(getListOfProjectUuids(parent, params, partition));

    return new Promise((resolve) => {
      if (res.entity_uuids.length === 0) {
        dispatch({
          type: ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_SUCCESS,
          payload: [],
        });
        resolve([]);
        return;
      }

      const constants = [
        ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_REQUEST,
        ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_SUCCESS,
        ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_FAILURE,
      ];

      const options = {
        partition,
      };

      dispatch(entityRead({
        data: res,
        constants,
        options,
      }));
    });
  };
}

export function clearListProjectFromQuickIssueModal() {
  return (dispatch) => {
    dispatch(
      { type: ProjectTypeCommonConstants.CLEAR_LIST_OF_PROJECT_FROM_QUICK_ISSUE_MODAL },
    );
  };
}

export function getListOfVectors({
  parent,
  partition,
  depth,
  params,
  entity_type__not,
  entities,
  limit,
  offset,
  searchData,
  order,
  signal,
  order_by,
  order_by_params,
  with_files = false,
  constants,
  fields,
  params_fields,
}) {
  const config = {
    data: {
      entity_type: 'vector',
      entities,
      parent,
      depth,
      params,
      limit,
      entity_type__not,
      offset,
      search_data: searchData,
      order,
      order_by,
      order_by_params,
      fields,
      params_fields,
    },
    with_files,
    signal,
    partition,
    constants: constants || [
      ProjectTypeCommonConstants.GET_PROJECT_VECTORS_REQUEST,
      ProjectTypeCommonConstants.GET_PROJECT_VECTORS_SUCCESS,
      ProjectTypeCommonConstants.GET_PROJECT_VECTORS_FAILURE,
    ],
  };

  return getListAndPartialReadEntities(config);
}

export function getListOfComments(param, partition = partitionNamesConfig.partition1) {
  const config = {
    data: {
      affix_type: 'comment',
      with_files: true,
      ...param,
    },
    partition,
    aborting: true,
    constants: [
      ProjectTypeCommonConstants.ISSUE_COMMENTS_REQUEST,
      ProjectTypeCommonConstants.ISSUE_COMMENTS_SUCCESS,
      ProjectTypeCommonConstants.ISSUE_COMMENTS_FAILURE,
    ],
  };
  return getListAndReadAffix(config);
}

export function getCommentsCountRequest(param, partition = partitionNamesConfig.partition1) {
  const config = {
    data: {
      affix_type: 'comment',
      ...param,
    },
    partition,
    aborting: true,
    constants: [
      ProjectTypeCommonConstants.GET_COMMENTS_COUNT_REQUEST,
      ProjectTypeCommonConstants.GET_COMMENTS_COUNT_SUCCESS,
      ProjectTypeCommonConstants.GET_COMMENTS_COUNT_FAILURE,
    ],
  };

  return getListOfAffix(config);
}

export function updateCommentsCountRequest(param, partition = partitionNamesConfig.partition1) {
  const data = { ...param };

  const constants = [
    ProjectTypeCommonConstants.UPDATE_COMMENTS_COUNT_REQUEST,
    ProjectTypeCommonConstants.UPDATE_COMMENTS_COUNT_SUCCESS,
    ProjectTypeCommonConstants.UPDATE_COMMENTS_COUNT_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}

export const updateListComment = (uuid) => (dispatch) => dispatch({
  type: ProjectTypeCommonConstants.NEED_UPDATE_LIST_COMMENT,
  payload: uuid,
});

export const updateCommentsCountStore = (commentType, count) => (dispatch) => {
  let actionType;

  switch (commentType) {
    case COMMENT_TYPE.USER: {
      actionType = ProjectTypeCommonConstants.UPDATE_USER_COMMENTS_STORE;
      break;
    }
    case COMMENT_TYPE.SYSTEM: {
      actionType = ProjectTypeCommonConstants.UPDATE_SYSTEM_COMMENTS_STORE;
      break;
    }
    case COMMENT_TYPE.TOTAL:
    default: {
      actionType = ProjectTypeCommonConstants.UPDATE_TOTAL_COMMENTS_STORE;
      break;
    }
  }

  return dispatch({
    type: actionType,
    payload: count,
  });
};

export const getCommentsAndUpdateStore = ({ commentType, parent, partition }) => (dispatch) => {
  const config = { entity_uuid: parent, params: {} };

  switch (commentType) {
    case COMMENT_TYPE.USER: {
      config.params.system = false;
      break;
    }
    case COMMENT_TYPE.SYSTEM: {
      config.params.system = true;
      break;
    }
    default: break;
  }

  try {
    dispatch(getCommentsCountRequest(config, partition))
      .then((res) => {
        const { total = 0 } = res;
        dispatch(updateCommentsCountStore(commentType, total));
      });
  } catch (err) {
    console.error(err.message, 'Can\'t get the number of comments.');
  }
};

export function getListOfCustomIssues(params) {
  const {
    data, customConstants, customPartition, requestOptions, with_files,
  } = params;
  const partition = customPartition || partitionNamesConfig.partition1;
  const constants = customConstants || [
    ProjectTypeCommonConstants.LIST_OF_ISSUES_REQUEST,
    ProjectTypeCommonConstants.LIST_OF_ISSUES_SUCCESS,
    ProjectTypeCommonConstants.LIST_OF_ISSUES_FAILURE,
  ];

  const defaultConstants = [
    constants[0], '', constants[2],
  ];

  axiosRequest.abort('', constants[0]);

  const config = {
    partition,
    data: {
      ...data,
      params_fields: {
        id: 'id',
        title: 'title',
        status: 'status',
        users: 'users',
        tracker: 'tracker',
        priority: 'priority',
        lifeTime: 'lifeTime',
        partition: 'partition',
        project: 'project',
        usersSearch: 'usersSearch',
      },
      // fields: {
      //   entity_type: 'entity_type',
      //   'actor.created': 'created',
      //   modified: 'modified',
      // },
    },
    constants: defaultConstants,
    with_files,
    requestOptions,
  };

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(getListAndPartialReadEntities(config))
      .then((res) => {
        const response = prop('data', res);
        if (response) {
          dispatch({
            type: constants[1],
            payload: response.map((item) => ({ ...item, partition })),
          });
        }
        resolve(res);
      })
      .catch(reject);
  });
}

export const sendSystemComment = (
  entityUUID,
  comment,
  partition,
  systemType,
) => {
  const params = {
    entity_uuid: entityUUID,
    affix_type: 'comment',
    params: {
      comment,
      system: true,
    },
  };

  if (systemType) {
    params.params.system_type = systemType;
  }
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'entity/affix/create',
        [
          ProjectTypeCommonConstants.NEW_COMMENT_REQUEST,
          ProjectTypeCommonConstants.NEW_COMMENT_SUCCESS,
          ProjectTypeCommonConstants.NEW_COMMENT_FAILURE,
        ],
        params,
        {
          partition: partition || partitionNamesConfig.partition1,
          onSuccess: resolve,
          onFailure: reject,
        },
      ),
    );
  });
};

export const createComment = ({
  parent,
  params,
  files,
  onSuccess,
  onFailure,
  partition,
}) => {
  const allParams = new FormData();

  if (!isEmpty(files)) {
    files.forEach(file => allParams.append('file', file));
  }

  allParams.append('data', JSON.stringify({
    entity_uuid: parent,
    affix_type: 'comment',
    params: {
      system: false,
      ...params,
    },
  }));

  return (
    axiosRequestPost(
      'entity/affix/create',
      [
        ProjectTypeCommonConstants.NEW_COMMENT_REQUEST,
        ProjectTypeCommonConstants.NEW_COMMENT_SUCCESS,
        ProjectTypeCommonConstants.NEW_COMMENT_FAILURE,
      ],
      allParams,
      {
        onSuccess,
        onFailure,
        partition,
      },
    )
  );
};

export const resetTags = (id) => {
  let resetID = [];

  if (id) {
    resetID = Array.isArray(id) ? id : [id];
  }

  return (dispatch) => {
    dispatch({
      type: EntityConstants.FILTER_RESET_TAGS,
      payload: resetID,
    });
  };
};

export const clearIssue = () => ({ type: ProjectTypeCommonConstants.CLEAR_ISSUE });

export const clearRecurringIssue = () => ({ type: ProjectTypeCommonConstants.CLEAR_RECURRING_ISSUE });

export const clearProject = () => ({ type: ProjectTypeCommonConstants.CLEAR_PROJECT });

export const addUnseenNotifications = (data) => (dispatch) => {
  dispatch({
    type: ProjectTypeCommonConstants.ADD_UNSEEN_NOTIFICATIONS,
    payload: JSON.parse(data),
  });
};

export const markAsViewedNotification = (uuid) => (dispatch) => {
  dispatch({
    type: ProjectTypeCommonConstants.MARK_AS_VIEWED_NOTIFICATION,
    payload: uuid,
  });
};

export const markAsViewedAllNotifications = () => (dispatch) => {
  dispatch({
    type: ProjectTypeCommonConstants.MARK_AS_VIEWED_ALL_NOTIFICATIONS,
  });
};

export const setFetchingForActorList = (bool) => (dispatch) => {
  dispatch({
    type: EntityConstants.SET_FETCHING_FOR_ACTORS_LIST,
    payload: bool,
  });
};

export function updateIssueRequestNew({
  uuid,
  params,
  partition = partitionNamesConfig.partition1,
  signal,
}) {
  const data = {
    entity_type: 'issue',
    entity_uuids: getArrayForItem(uuid),
    params: { ...params },
  };

  const constants = [
    ProjectTypeCommonConstants.EDIT_ISSUE_REQUEST,
    ProjectTypeCommonConstants.EDIT_ISSUE_SUCCESS,
    ProjectTypeCommonConstants.EDIT_ISSUE_FAILURE,
  ];

  const options = {
    partition,
    signal,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}

export const getListOfProjects = ({
  actor,
  parent,
  partition = partitionNamesConfig.partition1,
  entities__not,
  owner__not,
  params,
  depth,
  offset,
  limit,
  entities,
  owner,
  search_data,
  order,
  order_by,
  order_by_params,
  fields,
  constants = [
    ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_REQUEST,
    ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_SUCCESS,
    ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_FAILURE,
  ],
  params_fields,
}) => {
  const data = {
    entity_type: 'project',
    actor,
    entities__not,
    owner__not,
    search_data,
    depth,
    parent,
    params,
    offset,
    limit,
    entities,
    owner,
    order,
    order_by,
    order_by_params,
    fields,
    params_fields,
  };

  const config = {
    data,
    partition,
    constants,
  };

  // console.log('config for request', config.data);

  return getListAndPartialReadEntities(config);
};

export const getListOfProjectsForRead = ({
  partition = DEFAULT_PTC_PARTITION,
  entity_uuids,
  params,
  offset,
  limit,
}) => {
  const data = {
    entity_type: 'project',
    entity_uuids,
    params,
    offset,
    limit,
  };

  const constants = [
    ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_REQUEST,
    ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_SUCCESS,
    ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
};

export const getCookBookEntityFromPM = (parent) => (
  getOrCreateSingle({
    data: {
      entity_type: 'cookBookTemplateEntity',
      parent,
    },
    constants: [
      ProjectTypeCommonConstants.GET_COOK_BOOK_ENTITY_REQUEST,
      ProjectTypeCommonConstants.GET_COOK_BOOK_ENTITY_SUCCESS,
      ProjectTypeCommonConstants.GET_COOK_BOOK_ENTITY_FAILURE,
    ],
    doNotCheckError: true,
    partition: partitionNamesConfig.partition1,
  }));

export function getMainEntityArea({
  parent,
  partition = partitionNamesConfig.partition1,
}) {
  const data = {
    entity_type: 'mainAreaEntity',
    parent,
    params: {},
  };

  const constants = [
    ProjectTypeCommonConstants.GET_MAIN_AREA_REQUEST,
    ProjectTypeCommonConstants.GET_MAIN_AREA_SUCCESS,
    ProjectTypeCommonConstants.GET_MAIN_AREA_FAILURE,
  ];

  return getOrCreateSingle({
    data,
    constants,
    partition,
    doNotCheckError: true,
  });
}

export function getVector({
  uuid,
  constants: customConstants,
  params,
  partition = DEFAULT_PTC_PARTITION,
  signal,
}) {
  const data = {
    entity_type: 'vector',
    entity_uuid: uuid,
    params: { ...params },
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.GET_VECTOR_REQUEST,
    ProjectTypeCommonConstants.GET_VECTOR_SUCCESS,
    ProjectTypeCommonConstants.GET_VECTOR_FAILURE,
  ];

  const options = {
    signal,
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

export function updateVectorRequest({
  uuid,
  params,
  partition = DEFAULT_PTC_PARTITION,
  signal,
  constants: customConstants,
}) {
  const data = {
    entity_type: 'vector',
    entity_uuids: getArrayForItem(uuid),
    params: { ...params },
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.EDIT_VECTOR_REQUEST,
    ProjectTypeCommonConstants.EDIT_VECTOR_SUCCESS,
    ProjectTypeCommonConstants.EDIT_VECTOR_FAILURE,
  ];

  const options = {
    partition,
    signal,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}

// ISSUES SECTION

export function getIssueNew({
  uuid,
  constants: customConstants,
  params,
  partition = DEFAULT_PTC_PARTITION,
  withFiles,
  signal,
}) {
  const data = {
    entity_type: 'issue',
    entity_uuid: uuid,
    with_files: withFiles,
    params: { ...params },
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.ISSUE_ENTITY_REQUEST,
    ProjectTypeCommonConstants.ISSUE_ENTITY_SUCCESS,
    ProjectTypeCommonConstants.ISSUE_ENTITY_FAILURE,
  ];

  const options = {
    signal,
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

export function getRecurringIssueNew({
  uuid,
  constants: customConstants,
  params,
  partition = DEFAULT_PTC_PARTITION,
  withFiles,
  signal,
}) {
  const data = {
    entity_type: 'recurring',
    entity_uuid: uuid,
    with_files: withFiles,
    params: { ...params },
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.RECURRING_ISSUE_ENTITY_REQUEST,
    ProjectTypeCommonConstants.RECURRING_ISSUE_ENTITY_SUCCESS,
    ProjectTypeCommonConstants.RECURRING_ISSUE_ENTITY_FAILURE,
  ];

  const options = {
    signal,
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

export function getIssue({
  partition = DEFAULT_PTC_PARTITION,
  signal,
  params,
  uuid,
  constants: customConstants,
  withFiles,
}) {
  const data = {
    entity_type: 'issue',
    entity_uuid: uuid,
    with_files: withFiles,
    params: { ...params },
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.ISSUE_ENTITY_REQUEST,
    {
      type: ProjectTypeCommonConstants.ISSUE_ENTITY_SUCCESS,
      payload: (res) => [{ ...res[0], partition }],
    },
    ProjectTypeCommonConstants.ISSUE_ENTITY_FAILURE,
  ];

  const options = {
    signal,
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

export function getListOfIssues({
  constants,
  depth,
  entity_type__not,
  entity_uuids__not,
  limit,
  offset,
  order,
  order_by_params,
  params,
  parent,
  partition,
  searchData,
  signal,
  withFiles = false,
  fields,
  params_fields,
  created__gte,
  created__lte,
}) {
  const data = {
    entity_type: 'issue',
    entity_type__not,
    entity_uuids__not,
    depth,
    limit,
    offset,
    order,
    order_by_params,
    params: { ...params },
    parent,
    search_data: searchData,
    created__gte,
    created__lte,
    fields: {
      modified: 'modified',
      ...fields,
    },
    params_fields: {
      id: 'id',
      title: 'title',
      description: 'description',
      result: 'result',
      tracker: 'tracker',
      priority: 'priority',
      status: 'status',
      completed: 'completed',
      boardData: 'boardData',
      assigned_users: 'assigned_users',
      estimated_time: 'estimated_time',
      lifeTime: 'lifeTime',
      users: 'users',
      usersSearch: 'usersSearch',
      tags: 'tags',
      expirationDateDev: 'expirationDateDev',
      expirationDateQa: 'expirationDateQa',
      ...params_fields
    },
  };

  const currentConstants = constants || [
    ProjectTypeCommonConstants.GET_LIST_OF_ISSUES_REQUEST,
    ProjectTypeCommonConstants.GET_LIST_OF_ISSUES_SUCCESS,
    ProjectTypeCommonConstants.GET_LIST_OF_ISSUES_FAILURE,
  ];

  return getListAndPartialReadEntities({
    data,
    partition,
    constants: currentConstants,
    signal,
    with_files: withFiles,
  });
}

export function getListOfCombinedIssues({
  constants,
  depth,
  entity_type__not,
  entity_uuids__not,
  limit,
  offset,
  order,
  order_by_params,
  params,
  parent,
  partition,
  searchData,
  signal,
  withFiles = false,
  fields,
  params_fields,
  created__gte,
  created__lte,
}) {
  const data = {
    entity_type: ['issue', 'recurring'],
    entity_type__not,
    entity_uuids__not,
    depth,
    limit,
    offset,
    order,
    order_by_params,
    params: { ...params },
    parent,
    search_data: searchData,
    created__gte,
    created__lte,
    fields: {
      modified: 'modified',
      ...fields,
    },
    params_fields: {
      id: 'id',
      title: 'title',
      description: 'description',
      result: 'result',
      tracker: 'tracker',
      priority: 'priority',
      status: 'status',
      completed: 'completed',
      boardData: 'boardData',
      assigned_users: 'assigned_users',
      estimated_time: 'estimated_time',
      lifeTime: 'lifeTime',
      users: 'users',
      usersSearch: 'usersSearch',
      tags: 'tags',
      expirationDateDev: 'expirationDateDev',
      expirationDateQa: 'expirationDateQa',
      ...params_fields
    },
  };

  const currentConstants = constants || [
    ProjectTypeCommonConstants.GET_LIST_OF_ISSUES_REQUEST,
    ProjectTypeCommonConstants.GET_LIST_OF_ISSUES_SUCCESS,
    ProjectTypeCommonConstants.GET_LIST_OF_ISSUES_FAILURE,
  ];

  return getListAndPartialReadEntities({
    data,
    partition,
    constants: currentConstants,
    signal,
    with_files: withFiles,
  });
}

export function getListOfRecurringIssues({
  constants,
  depth,
  entity_type__not,
  entity_uuids__not,
  limit,
  offset,
  order,
  order_by_params,
  params,
  parent,
  partition,
  searchData,
  signal,
  withFiles = false,
  fields,
  params_fields,
  created__gte,
  created__lte,
}) {
  const data = {
    entity_type: 'recurring',
    entity_type__not,
    entity_uuids__not,
    depth,
    limit,
    offset,
    order,
    order_by_params,
    params: { ...params },
    parent,
    search_data: searchData,
    created__gte,
    created__lte,
    fields: {
      modified: 'modified',
      ...fields,
    },
    params_fields: {
      title: 'title',
      description: 'description',
      result: 'result',
      tracker: 'tracker',
      priority: 'priority',
      status: 'status',
      completed: 'completed',
      boardData: 'boardData',
      assigned_users: 'assigned_users',
      estimated_time: 'estimated_time',
      lifeTime: 'lifeTime',
      users: 'users',
      usersSearch: 'usersSearch',
      tags: 'tags',
      expirationDateDev: 'expirationDateDev',
      expirationDateQa: 'expirationDateQa',
      ...params_fields
    },
  };

  return getListAndReadEntities({
    data,
    partition,
    constants,
    signal,
    with_files: withFiles,
  });
}

export const createTimeLog = ({
  entityUUID,
  actor,
  params,
  partition = DEFAULT_PTC_PARTITION,
}) => {
  const config = {
    actor,
    entity_uuid: entityUUID,
    affix_type: 'timeLog',
    params,
  };

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'entity/affix/create',
        [
          ProjectTypeCommonConstants.CREATE_TIME_LOG_REQUEST,
          ProjectTypeCommonConstants.CREATE_TIME_LOG_SUCCESS,
          ProjectTypeCommonConstants.CREATE_TIME_LOG_FAILURE,
        ],
        config,
        {
          partition,
          onSuccess: resolve,
          onFailure: reject,
        },
      ),
    );
  });
};

export const getAffixTimeLog = (customConfig = {}, partition) => {
  const config = {
    data: {
      affix_type: 'timeLog',
      ...customConfig,
    },
    partition,
    constants: [
      ProjectTypeCommonConstants.LIST_TIME_LOG_REQUEST,
      ProjectTypeCommonConstants.LIST_TIME_LOG_SUCCESS,
      ProjectTypeCommonConstants.LIST_TIME_LOG_FAILURE,
    ],
  };
  return (dispatch) => dispatch(getListAndReadAffix(config));
};

export const deleteAffixTimelog = (uuids, partition) => {
  const config = {
    data: {
      affix_uuids: uuids,
    },
    partition,
    constants: [
      EntityConstants.HARD_DELETE_PERM_REQUEST,
      EntityConstants.HARD_DELETE_PERM_SUCCESS,
      EntityConstants.HARD_DELETE_PERM_FAILURE,
    ],
  };
  return (dispatch) => dispatch(affixDelete(config));
};

export const updateFavoriteRequest = (favoriteParams) => {
  const constants = [
    UsersPartitionConstants.UPDATE_FAVORITE_SETTINGS_REQUEST,
    {
      type: UsersPartitionConstants.UPDATE_FAVORITE_SETTINGS_SUCCESS,
      payload: () => {
        const data = {};

        if (favoriteParams?.params?.favorites) {
          data.favorites = get(favoriteParams, 'params.favorites', []);
        }

        return data;
      },
    },
    UsersPartitionConstants.UPDATE_FAVORITE_SETTINGS_FAILURE,
  ];

  const options = {
    partition: partitionNamesConfig.partition2,
  };

  return entityUpdate({
    data: favoriteParams,
    constants,
    options,
  });
};

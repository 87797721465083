import { UsersPartitionConstants } from "../../constants/Constants";

const initialState = {
  dragAndDropElement: {
    date: "",
    item: "",
    type: "",
    delete: false,
  },
  dublicateActivities: [],
  syncData: [],
};

export default function dailyReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    case UsersPartitionConstants.SET_DRAG_AND_DROP_ELEMENT:
      return {
        ...state,
        dragAndDropElement: payload,
      };
    case UsersPartitionConstants.CLEAR_DRAG_AND_DROP_ELEMENT:
      return {
        ...state,
        dragAndDropElement: {},
      };
    case UsersPartitionConstants.SET_DUPLICATE_ACTIVITY:
      return {
        ...state,
        dublicateActivities: payload,
      };
    case UsersPartitionConstants.CLEAR_DUPLICATE_ACTIVITY:
      return {
        ...state,
        dublicateActivities: [],
      };
    case UsersPartitionConstants.SYNC_DATA:
      return {
        ...state,
        syncData: payload,
      };
    case UsersPartitionConstants.CLEAR_SYNC_DATA:
      return {
        ...state,
        syncData: [],
      };

    default:
      return state;
  }
}

import { getListAndReadAffix } from '../../affix/affixActions';
import { partitionNamesConfig } from '../../api/appConfig';
import { getListAndPartialReadEntities } from '../../entity/actions/entityActions';
import { ProjectTypeCommonConstants } from '../constants/Constants';

export const getListOfAllEntitiesByTypes = (data, defaultPartition = 'PM') => {
  const config = {
    data: {
      ...data,
      params_fields: {
        title: 'title',
        status: 'status',
        users: 'users',
        assignToUser: 'assignToUser',
        lifeTime: 'lifeTime',
        usersSearch: 'usersSearch',
        responsible: 'responsible',
      },
      fields: {
        entity_type: 'entity_type',
        'entity.created': 'created',
        modified: 'modified',
      },
    },
    partition: defaultPartition,
    constants: [
      ProjectTypeCommonConstants.GET_ALL_ENTITIES_AUDIT_REQUEST,
      ProjectTypeCommonConstants.GET_ALL_ENTITIES_AUDIT_SUCCESS,
      ProjectTypeCommonConstants.GET_ALL_ENTITIES_AUDIT_FAILURE,
    ],
  };

  return getListAndPartialReadEntities(config);
};

export const getListOfAllAffixByTypes = (data, defaultPartition = partitionNamesConfig.partition1) => {
  const config = {
    data: {
      ...data,
      // with_files: false,
    },
    partition: defaultPartition,
    constants: [
      ProjectTypeCommonConstants.GET_ALL_AFFIX_AUDIT_REQUEST,
      ProjectTypeCommonConstants.GET_ALL_AFFIX_AUDIT_SUCCESS,
      ProjectTypeCommonConstants.GET_ALL_AFFIX_AUDIT_FAILURE,
    ],
  };

  return getListAndReadAffix(config);
};

import { Col } from 'antd';
import { bool } from 'prop-types';
import React from 'react';

import CookBookStartPage from '../../../../../projectFlow/components/cookbook/CookBookStartPage';
import { partitionNamesConfig } from '../../../../../api/appConfig';

function CookbooksCardRoot({
  otherUser,
}) {
  return (
    <Col span={24} className="mt-3">
      <CookBookStartPage
        currentPartition={partitionNamesConfig.partition3}
        entityType="project"
        otherUser={otherUser}
      />
    </Col>
  );
}

CookbooksCardRoot.propTypes = {
  otherUser: bool,
};

export default CookbooksCardRoot;

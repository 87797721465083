import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { ProjectTypeCommonConstants } from '../projectFlow/constants/Constants';
import { getUserPublicProfileUUID } from '../userFlow/store/selectors/selectors';
import useProjectURL from './useProjectURL';
import {
  getMainPublicProject,
  getUserCookBookTemplateEntity,
} from '../userFlow/store/action-creators/userActions';
import { axiosRequestPost } from '../api/apiAxios';
import { getOrCreateSingle } from '../entity/actions/entityActions';
import { getMD5HashFromString } from '../MainUtils';
import { UsersPartitionConstants } from '../userFlow/constants/Constants';
import { getUserUuid } from '../projectFlow/selectors/selectors';
import { partitionNamesConfig } from '../api/appConfig';

const useUserPublicPartition = (customUUID, mode) => {
  const dispatch = useDispatch();

  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);
  const myUUID = useSelector(getUserUuid);
  const [{ userUUIDFromUrl }] = useProjectURL();
  const myPublicEntityUuidOrFromUrl = myPublicEntityUUID || userUUIDFromUrl;

  const [actorMainPublicProjectUUID, setActorMainPublicProjectUUID] = useState('');
  const [projectUserReportsUUID, setProjectUserReportsUUID] = useState('');

  const [mainPublicCookBookUUID, setMainPublicCookBookUUID] = useState('');
  const [otherUserPublicCookBookUUID, setOtherUserPublicCookBookUUID] = useState('');

  const getCookBookEntity = (uuid, isMe) => {
    const constants = isMe ? 'ENTITY_PUBLIC' : 'ENTITY_PUBLIC_USER';

    return getOrCreateSingle({
      data: {
        entity_type: 'cookBookTemplateEntity',
        parent: uuid,
      },
      constants: [
        ProjectTypeCommonConstants[`GET_COOK_BOOK_${constants}_REQUEST`],
        ProjectTypeCommonConstants[`GET_COOK_BOOK_${constants}_SUCCESS`],
        ProjectTypeCommonConstants[`GET_COOK_BOOK_${constants}_FAILURE`],
      ],
      doNotCheckError: true,
      partition: partitionNamesConfig.partition3,
    });
  };

  const getProjectUserReports = async (uuid) => {
    const projectPublicUUID = await dispatch(getMainPublicProject(uuid)).catch(
      () => {
        setProjectUserReportsUUID('');
        setActorMainPublicProjectUUID('');
      },
    );

    const parent = get(projectPublicUUID, 'uuid');

    const config = {
      data: {
        entity_type: 'project',
        parent,
        params: {
          title: 'WEEKLY PLANNING',
          type: 'ACTIVITIES_USER',
          status: 'systemic',
          users: [
            {
              role: '',
              uuid: myUUID,
            },
          ],
          usersSearch: [myUUID],
        },
        key: getMD5HashFromString(`${parent}projectACTIVITIES_USER`),
      },
      partition: partitionNamesConfig.partition3,
    };

    dispatch(getOrCreateSingle(config))
      .then((res) => {
        setProjectUserReportsUUID(get(res, 'uuid', ''));
        setActorMainPublicProjectUUID(parent);
      })
      .catch(() => {
        setProjectUserReportsUUID('');
        setActorMainPublicProjectUUID('');
      });
  };

  const getCookBookEntityOrCreate = async (uuid, isMe) => {
    const cookBookEntity = await getCookBookEntity(uuid, isMe);
    const cookBookEntityUUID = get(cookBookEntity, 'uuid');
    return cookBookEntityUUID;
  };

  const getMyAndOtherUserCookBookEntityUuid = async (uuid) => {
    // my user CookBook entity uuid
    const myCookBookPublicUUID = await dispatch(getUserCookBookTemplateEntity(uuid));
    // console.log("myCookBookPublicUUID", myCookBookPublicUUID);

    dispatch({
      type: ProjectTypeCommonConstants.GET_COOK_BOOK_ENTITY_PUBLIC_SUCCESS,
      payload: { uuid: myCookBookPublicUUID?.uuid },
    });

    setMainPublicCookBookUUID(myCookBookPublicUUID?.uuid);

    if (myPublicEntityUuidOrFromUrl !== customUUID) {
      // other user CookBook entity uuid
      const otherUserCookBookPublicUUID = await getCookBookEntityOrCreate(
        customUUID,
        false,
      );
      setOtherUserPublicCookBookUUID(otherUserCookBookPublicUUID);
    }
  };

  const getEntity = async (uuid) => {
    switch (mode) {
      case 'mainPublicProjectUUID': {
        if (!uuid) break;

        const projectPublicUUID = await dispatch(getMainPublicProject(uuid));
        // console.log('projectPublicUUID', projectPublicUUID)
        setActorMainPublicProjectUUID(get(projectPublicUUID, 'uuid'));
        break;
      }
      case 'publicCookBookUUID':
        getMyAndOtherUserCookBookEntityUuid(uuid);
        break;
      case 'projectUserReportsUUID':
        if (!uuid) break;
        getProjectUserReports(uuid);
        break;
      default:
        break;
    }
  };

  const initFunc = async () => {
    if (customUUID && myPublicEntityUuidOrFromUrl) {
      getEntity(customUUID);
    }
  };

  useEffect(() => {
    initFunc();
  }, [customUUID, myPublicEntityUuidOrFromUrl]);

  const data = useMemo(
    () => ({
      actorMainPublicProjectUUID,
      mainPublicCookBookUUID,
      otherUserPublicCookBookUUID,
      projectUserReportsUUID,
    }),
    [
      actorMainPublicProjectUUID,
      mainPublicCookBookUUID,
      otherUserPublicCookBookUUID,
      projectUserReportsUUID,
    ],
  );

  return data;
};

export default useUserPublicPartition;

/* eslint-disable import/prefer-default-export */

import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getPartitionPMUUID } from '../../../config/selectors/selectors';
import { getListDocumentsOrIdea } from '../../../projectFlow/actions/DocumentsActions';
import { getProjectsEntityUuids } from '../../../projectFlow/selectors/selectors';
import { partitionNamesConfig } from '../../../api/appConfig';

const defaultConfigDoc = {
  // entity_type: ['projectDocument', 'projectIdea'],
  depth: 0,
  // parent: parentUUID,
  limit: 10,
  offset: 0,
};

export const useGetProjectsDocsOrIdeas = (type, config) => {
  const dispatch = useDispatch();

  const myProjects = useSelector(getProjectsEntityUuids);
  const partitionUUID = useSelector(getPartitionPMUUID);

  const getDocumentsOrIdeaOrBoth = async () => {
    const newConfig = {
      entity_type: type,
      parent: partitionUUID,
      entities: myProjects,
      ...defaultConfigDoc,
      ...config,
    };

    return dispatch(getListDocumentsOrIdea(newConfig, partitionNamesConfig.partition1));
  };

  useEffect(() => {
    if (type && !isEmpty(myProjects) && !isEmpty(config)) {
      getDocumentsOrIdeaOrBoth();
    }
  }, [type, config, myProjects]);
};

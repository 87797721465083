import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Tree } from 'antd';
import { isEmpty, prop } from 'ramda';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';

import '../style/CookBookTree.scss';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import EditDataCookBookTree from './EditDataCookBookTree';
import { capitalize } from 'lodash';

function CookBookTree({
  jsObject,
  customParent,
  selectedEntity,
  activeCookBook,
}) {
  const { t } = useTranslation();
  const parentType = prop('entity_type', customParent) || 'project';

  const { vectors, issues } = jsObject;

  const getParams = (params, type, id) => ({
    title: (
      <EditDataCookBookTree
        params={params}
        type={type === 'vectorIssue' ? 'issue' : type}
      />
    ),
    key: `{"type": "${type}", "id": ${id}}`,
    selectable: type === 'project' || type === 'vector',
  });

  const jsonToTree = useCallback(() => {
    if (!jsObject || isEmpty(jsObject)) {
      return [{}];
    }

    const projectObject = getParams(get(customParent, 'params') || get(jsObject, 'params'), parentType, 0);

    const resultObject = {
      ...projectObject,
      children: [],
    };

    if (vectors && !isEmpty(vectors)) {
      resultObject.children.push(
        ...vectors.map((i, id) => {
          if (i.issues && !isEmpty(i.issues)) {
            return {
              ...getParams(get(i, 'params'), 'vector', `${get(i, 'params.title')}-${id}`),
              children: [
                ...i.issues.map((issue, key) => getParams(get(issue, 'params'), 'vectorIssue', `${get(issue, 'params.title')}-${key}`)),
              ],
            };
          }
          return getParams(get(i, 'params'), 'vector', id);
        }),
      );
    }

    if (issues && !isEmpty(issues)) {
      resultObject.children.push(
        ...issues.map((item, id) => getParams(get(item, 'params'), 'issue', `${get(item, 'params.title')}-${id}`)),
      );
    }

    return [resultObject];
  }, [customParent, jsObject, parentType, activeCookBook]);

  if (!jsObject || isEmpty(jsObject)) {
    return (
      <h4
        className="flex items-center justify-center m-0"
        style={{ height: 220 }}
      >
        {`${capitalize(t('wms.labels.cookbook_not_selected'))}...`}
      </h4>
    );
  }

  return (
    <div>
      <Tree
        className="CookBook_Tree w-full mt-3"
        defaultExpandAll
        autoExpandParent
        defaultSelectedKeys={[`{"type": "${parentType}", "id": 0}`]}
        selectedKeys={
          selectedEntity && [`{"type": "${selectedEntity.type}", "id": ${selectedEntity.id}}`]
        }
        switcherIcon={<Icon path={mdiChevronRight} size={1} />}
        treeData={jsonToTree()}
      />
    </div>
  );
}

export default CookBookTree;

CookBookTree.propTypes = {
  jsObject: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  activeCookBook: PropTypes.string,
  customParent: PropTypes.object,
  selectedEntity: PropTypes.object,
};

import { capitalize, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import { getListAndPartialReadEntities } from '../../../entity/actions/entityActions';
import { partitionNamesConfig } from '../../../api/appConfig';

const defaultEntityTypes = ['issue', 'vector', 'projectDocument', 'projectIdea', 'testSuite', 'testCycle', 'project'];

export default function EntityData({ user, partitionPM, defaultConfig }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [entityConfig, setEntityConfig] = useState(defaultConfig);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [data, setData] = useState([]);

  const columns = [
    {
      dataIndex: ['title'],
      title: capitalize(t('wms.table.headers.title', 'title')),
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
          maxLength={30}
        />
      ),
    },
    {
      dataIndex: 'entity_type',
      title: capitalize(t('wms.table.headers.type', 'type')),
      render: (cell) => cell && (
        <EntityParamTag
          value={cell}
          type="badge"
          param="entity"
        />
      ),
    },
    {
      dataIndex: ['status'],
      title: capitalize(t('wms.table.headers.status', 'status')),
      render: (cell) => cell && (
        <EntityParamTag
          param="status"
          value={cell}
          type="icon"
        />
      ),
    },
  ];

  const rowClassName = () => 'universal_table_row';

  const onPageChange = ({ pageLimit: newPageLimit, currentPage: newCurrentPage, offset }) => {
    setEntityConfig((prev) => ({
      ...prev,
      offset,
      limit: newPageLimit,
    }));
    setCurrentPage(newCurrentPage);
  };

  const remotePagination = {
    sizePerPageList: [10, 50, 100],
    defaultPageLimit: 10,
    totalSize: totalCount,
    page: currentPage,
    onChange: onPageChange,
    // onSizePerPage: onSizePerPageTable,
  };

  const getUserEntities = () => {
    const config = {
      data: {
        entity_type: defaultEntityTypes,
        actor: user?.actor,
        parent: partitionPM,
        depth: 0,
        ...entityConfig,
        params_fields: {
          title: 'title',
          status: 'status',
        },
        fields: { entity_type: 'entity_type' },
      },
      partition: partitionNamesConfig.partition1,
      constants: ['ENTITY_REQUEST', 'ENTITY_SUCCESS', 'ENTITY_FAILURE'],
    };

    return dispatch(getListAndPartialReadEntities(config));
  };
  const getUserData = async () => {
    const entity = await getUserEntities();
    // console.log('entity', entity);
    setData(get(entity, 'data'));
    const total = get(entity, 'total');
    setTotalCount(total);
    setLoading(false);
  };

  useEffect(() => {
    if (partitionPM) {
      getUserData();
    }
  }, [partitionPM, entityConfig]);

  return (
    <div>
      <h6>User entities</h6>
      <BaseTableWithPagination
        pageLimit={pageLimit}
        changePageLimit={setPageLimit}
        useCustomPagination
        //   onRow={onRow}
        columns={columns}
        data={data}
        //   rowSelection={selectRow}
        loading={loading}
        total={get(remotePagination, 'totalSize')}
        pageSizeOptions={get(remotePagination, 'sizePerPageList')}
        newCurrentPage={get(remotePagination, 'page')}
        defaultPageLimit={get(remotePagination, 'defaultPageLimit')}
        getPaginationOptions={get(remotePagination, 'onChange')}
        rowClassName={rowClassName}
        paginationSize="small"
        headerRowClassName="universal_header_table_row"
      />

    </div>
  );
}

import {
  entityCreate, entityUpdate, entityRead, getListAndPartialReadEntities,
} from '../../entity/actions/entityActions';
import {
  LIST_OF_PROJECT_MILESTONES_FETCHING,
  LIST_OF_PROJECT_MILESTONES_SUCCESS,
  LIST_OF_PROJECT_MILESTONES_FAILURE,
  CREATE_PROJECT_MILESTONES_FETCHING,
  CREATE_PROJECT_MILESTONES_SUCCESS,
  CREATE_PROJECT_MILESTONES_FAILURE,
  PROJECT_MILESTONE_FETCHING,
  PROJECT_MILESTONE_SUCCESS,
  PROJECT_MILESTONE_FAILURE,
  CLEAR_ALL_PROJECT_MILESTONES,
  CLEAR_PROJECT_MILESTONE,
} from '../reducers/slicers/projectMilestonesSlicer';
import { getArrayForItem } from '../../MainUtils';
import { partitionNamesConfig } from '../../api/appConfig';

const defaultPartition = partitionNamesConfig.partition1;

export const getListOfProjectMilestonesRequest = (param, partition = defaultPartition) => {
  const { orderBy } = param || {};

  const data = {
    ...param,
    entity_type: 'projectMilestone',
    depth: 0,
    fields: {
      'actor.uinfo': 'uinfo',
      modified: 'modified',
      'actor.actor_type': 'actor_type',
    },
    params_fields: {
      id: 'id',
      title: 'title',
      status: 'status',
      importance: 'importance',
      responsible: 'responsible',
      lifeTime: 'lifeTime',
      startDate: 'startDate',
    },
  };

  if (orderBy) {
    data[(orderBy === 'modified' || orderBy === 'created') ? 'order_by' : 'order_by_params'] = orderBy;
    delete data.orderBy;
  }

  // console.log('!!!! data', data);

  const constants = [
    LIST_OF_PROJECT_MILESTONES_FETCHING,
    LIST_OF_PROJECT_MILESTONES_SUCCESS,
    LIST_OF_PROJECT_MILESTONES_FAILURE,
  ];

  return getListAndPartialReadEntities({
    data,
    partition,
    constants,
  });
};

export const getListOfMilestonesForScoreRequest = (param, partition = defaultPartition) => {
  const data = {
    ...param,
    entity_type: 'projectMilestone',
    depth: 0,
    params_fields: {
      status: 'status',
      importance: 'importance',
      lifeTime: 'lifeTime',
      startDate: 'startDate',
    },
  };

  const constants = [
    LIST_OF_PROJECT_MILESTONES_FETCHING,
    LIST_OF_PROJECT_MILESTONES_SUCCESS,
    LIST_OF_PROJECT_MILESTONES_FAILURE,
  ];

  return getListAndPartialReadEntities({
    data,
    partition,
    constants,
  });
};

export const clearProjectsMilestones = { type: CLEAR_ALL_PROJECT_MILESTONES.type };

export const clearProjectMilestone = { type: CLEAR_PROJECT_MILESTONE.type };

export const createProjectMilestoneRequest = ({
  parent,
  params,
  partition = defaultPartition,
}) => {
  const data = {
    entity_type: 'projectMilestone',
    parent,
    params: {
      ...params,
    },
  };

  const constants = [
    CREATE_PROJECT_MILESTONES_FETCHING,
    CREATE_PROJECT_MILESTONES_SUCCESS,
    CREATE_PROJECT_MILESTONES_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityCreate({
    data,
    constants,
    options,
  });
};

export function getProjectMilestoneRequest({
  uuid,
  partition = defaultPartition,
}) {
  const data = {
    entity_type: 'projectMilestone',
    entity_uuids: getArrayForItem(uuid),
  };

  const constants = [
    PROJECT_MILESTONE_FETCHING,
    PROJECT_MILESTONE_SUCCESS,
    PROJECT_MILESTONE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

export function updateProjectMilestoneRequest({
  uuid,
  params,
  partition,
}) {
  const data = {
    entity_type: 'projectMilestone',
    entity_uuids: getArrayForItem(uuid),
    params,
  };

  const constants = [
    PROJECT_MILESTONE_FETCHING,
    PROJECT_MILESTONE_SUCCESS,
    PROJECT_MILESTONE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}

import React from 'react';
import {
  mdiShieldKeyOutline,
  mdiTimerSyncOutline,
  mdiCogs,
  mdiCalendarToday, mdiToolboxOutline,
} from '@mdi/js';

import UserDashboardForAdmin from '../components/UserDashboardForAdmin';
import MainAreasManagementTemplate from '../../projectFlow/components/areas/MainAreasManagementTemplate';
import PermissionsWrapperInRoute from '../../projectFlow/containers/permissions/PermissionsWrapperInRoute';
import GeneralManagement from '../components/GeneralManagement';
import SessionsManagementMain from '../components/SessionsManagement';

export const galleryItemList = [
  {
    id: 1,
    link: 'generalmanagement',
    title: 'General management',
    icon: mdiToolboxOutline,
    component: () => <GeneralManagement />,
  },
  {
    id: 3,
    link: 'areasmanagment',
    title: 'Areas management',
    icon: mdiCogs,
    component: (props) => <MainAreasManagementTemplate {...props} />,
  },
  {
    id: 4,
    link: 'permissions',
    title: 'Permissions management',
    icon: mdiShieldKeyOutline,
    component: (props) => <PermissionsWrapperInRoute {...props} />,
  },
];

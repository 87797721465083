import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Flex, Row, Spin,
} from 'antd';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';

import { get } from 'lodash';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import {
  getTestCycleListForSelect,
  getAllTestCycles,
  getProjectUUID,
  getProjectParams,
} from '../../../selectors/selectors';

import {
  antNotification,
} from '../../../../MainUtils';
import { capitalize } from 'lodash';

import {
  updateTestCycle,
} from '../../../actions/QaActions';
import { BugTrackerContext, MainCollapseSectionContext } from '../../../context/ProjectFlowListOfContexts';

import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';

function ExistTestCycleExecutionForm({
  existTestCycleExecutionContainerData,
  handleOk,
  setExistTestCycleExecutionContainerData,
  typeOfParent,
  customListCases,
  defaultSelectedCase,
  createNewTestCaseForCycle,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const testCycles = useSelector(getAllTestCycles);
  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);

  const [spinner, setSpinner] = useState(false);

  const [isAssignOpen, setIsAssignOpen] = useState(false);
  const [testCycleOptions, setTestCycleOptions] = useState([])

  const {
    partitionType,
    getListOfTestCycles,
    getListOfTestCasesInCycle,
  } = useContext(BugTrackerContext);
  const { changeMainCollapseFromOuterComponent } = useContext(MainCollapseSectionContext);

  const defaultState = {
    selectedCycle: null,
    selectedCases: defaultSelectedCase,
    assignToUsersOption: [],
  };

  const [state, setState] = useState(defaultState);

  const {
    selectedCycle,
    selectedCases,
    assignToUsersOption,
  } = state;

  const lengthOfSelectedCases = selectedCases.length;

  const changeState = (data) => {
    setState({
      ...state,
      ...data,
    });
  };

  const onSelectTestCase = (e) => changeState({ selectedCases: isNil(e) ? [] : e });

  const assignToFunc = (value) => {
    changeState({
      assignToUsersOption: value,
    });
  };

  const updateTestCyclesData = async (editableTestCycle) => {
    const data = {
      uuid: editableTestCycle?.uuid,
      partition: partitionType,
      params: {
        cases_count: get(editableTestCycle, 'cases_count', 0) + selectedCases.length,
        status: 'in progress',
        archive: false,
        helperInStatus: false,
        progress: {
          block: editableTestCycle.progress.block,
          fail: editableTestCycle.progress.fail,
          pass: editableTestCycle.progress.pass,
          progress: editableTestCycle.progress.progress,
          quantityCase: +get(editableTestCycle, 'progress.quantityCase', 0) + selectedCases.length,
          unexecuted: +get(editableTestCycle, 'progress.unexecuted', 0) + selectedCases.length,

        },
      },
      customConstants: ['REQUEST', ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE, 'FAILURE'],
    };

    await dispatch(updateTestCycle(data));
    getListOfTestCycles();
  };

  const submitEditTestCycle = () => {
    setSpinner(true);

    getListOfTestCycles().then(testCycles => {
      const editableTestCycle = testCycles.find(({ uuid }) => selectedCycle.value === uuid);

      const listSelected = customListCases?.cases?.filter(
        (i) => selectedCases.some((p) => p.value === i?.uuid),
      );

      createNewTestCaseForCycle(
        listSelected,
        { uuid: editableTestCycle?.uuid, params: { ...editableTestCycle } },
        assignToUsersOption,
      );

      updateTestCyclesData(editableTestCycle);

      getListOfTestCasesInCycle(selectedCycle.value);

      antNotification('success', t('wms.noun.success', 'Success'));

      setSpinner(false);

      changeMainCollapseFromOuterComponent('mainCycleCollapse', true);
      if (handleOk) {
        handleOk();
      }
    })
    
  };

  const onSelectTestCycle = async (e) => {
    changeState({
      selectedCycle: e,
    });
  };

  useEffect(() => {
    getListOfTestCycles().then((data) => {
      let arrayForOptions = [];
      data.map(item => {
        arrayForOptions.push({label: item.title, value: item.uuid});
      });
      setTestCycleOptions(arrayForOptions);
    });
  }, []);

  useEffect(() => () => {    
    setExistTestCycleExecutionContainerData({ ...state });
  }, []);

  useEffect(() => {
    if (existTestCycleExecutionContainerData) {
      setState(existTestCycleExecutionContainerData);
    }
    if (!existTestCycleExecutionContainerData && defaultSelectedCase?.length) {
      onSelectTestCase(defaultSelectedCase);
    }
  }, [defaultSelectedCase?.length]);

  return (
    <Spin spinning={spinner} wrapperClassName="w-full">
      <div
        style={{
          minHeight: '580px',
        }}
        className="flex flex-col justify-between"
      >
        <div className="px-4 w-full">
          <Row className="mt-2">
            <Col span={24}>
              <h5>
                {capitalize(t('wms.buttons.add_to_cycle'))}
              </h5>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col span={24}>
              {capitalize(t('wms.labels.test_cycles', 'Test cycles'))}
              <span style={{ color: 'red' }}>*</span>
              <Select
                className="w-full mt-1"
                noOptionsMessage={() => capitalize(t('wms.select.no_options', 'no options'))}
                placeholder={capitalize(t('wms.placeholders.select_cycle', 'select cycles'))}
                onChange={onSelectTestCycle}
                options={testCycleOptions}
                value={selectedCycle}
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col span={24}>
              {capitalize(t('wms.labels.test_cases', 'Test cases'))}
              <span style={{ color: 'red' }}>*</span>

              <Select
                className="w-full"
                isMulti
                noOptionsMessage={() => capitalize(
                  t('wms.select.no_options', 'no options'),
                )}
                placeholder={capitalize(
                  t('wms.placeholders.select_cases', 'select cases'),
                )}
                onChange={onSelectTestCase}
                options={customListCases?.option}
                value={selectedCases}
                closeMenuOnSelect={false}
              />
            </Col>
          </Row>

          <Row>
            <Col span={12} className="mt-2">
              <Flex className="w-full">
                <AssigningUsersRoot
                  header="Assigning users"
                  needUpdateProjectUsers
                  isOpen={isAssignOpen}
                  setIsOpen={setIsAssignOpen}
                  onUpdateUsers={(data) => assignToFunc(data?.users?.map((el) => el?.uuid))}
                  globalOptions={{
                    uuids: projectParams?.usersSearch ?? [],
                    defaultColumn: 'project',
                  }}
                  placement="left"
                  isPopover
                  tabs={{
                    allConfig: {
                      label: 'All',
                      value: 'all',
                      textSelectButton: 'Add to cycle',
                      showSections: ['button-add-user'],
                    },
                    projectConfig: {
                      label: 'Project',
                      value: 'project',
                      textSelectButton: 'Add to cycle',
                      defaultItems: projectParams?.users ?? [],
                      showSections: ['string-role', 'button-add-user'],
                    },
                  }}
                  usersConfig={{
                    label: 'Cycle users',
                    showSections: ['button-remove-user', 'string-role'],
                    textDeleteButton: 'Delete users',
                    defaultItems: assignToUsersOption ?? [],
                  }}
                  wrapperProps={{ autoAdjustOverflow: true }}
                  partition={partitionType}
                  projectUuid={projectUUID}
                  popoverText={(<span style={{ color: 'black' }}>Assigned users:</span>)}
                />
                <WrapperAvatarsGroupForProjectWithHook
                  actors={assignToUsersOption || []}
                  avatarSize={28}
                  popoverTrigger="click"
                  popoverPlacement="right"
                />
              </Flex>
            </Col>
          </Row>
        </div>

        <div className="flex justify-end">
          <BaseButton
            type="primary"
            className="ml-auto"
            onClick={submitEditTestCycle}
            disabled={!selectedCycle || (typeOfParent === 'suite' && (!selectedCases || lengthOfSelectedCases === 0))}
          >
            {capitalize(t('wms.buttons.add_to_cycle'))}
          </BaseButton>
        </div>
      </div>
    </Spin>
  );
}

ExistTestCycleExecutionForm.propTypes = {
  existTestCycleExecutionContainerData: PropTypes.any,
  handleOk: PropTypes.func,
  defaultSelectedCase: PropTypes.array,
  createNewTestCaseForCycle: PropTypes.func,
  setExistTestCycleExecutionContainerData: PropTypes.any,
  customListCases: PropTypes.object,
  typeOfParent: PropTypes.string,
};

export default ExistTestCycleExecutionForm;

import { ProjectTypeCommonConstants } from '../constants/Constants';

const initialState = {
  fetching: true,
  projectData: [],
  subProjectData: [],
  selectedUsers: [],
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ProjectTypeCommonConstants.PROJECT_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case ProjectTypeCommonConstants.PROJECT_SUCCESS:
      return {
        ...state,
        fetching: false,
        projectData: action.payload,
      };

    case ProjectTypeCommonConstants.PROJECT_FAILURE:
      return {
        ...state,
        fetching: false,
        projectData: [],
        selectedUsers: [],
        error: action.payload,
      };

    case ProjectTypeCommonConstants.CLEAR_PROJECT:
      return {
        fetching: true,
        projectData: [],
        subProjectData: [],
        selectedUsers: [],
        error: '',
      };

    case ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_SUCCESS:
      return {
        ...state,
        projectData: action.payload,
      };

    case ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case ProjectTypeCommonConstants.EDIT_PROJECT_REQUEST:
      return {
        ...state,
      };

    case ProjectTypeCommonConstants.EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        projectData: action.payload,
      };

    case ProjectTypeCommonConstants.EDIT_PROJECT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default: return state;
  }
};

import Quill from "quill";
import { handleQuillFiles } from "./utils";
const Clipboard = Quill.import("modules/clipboard");

export class CustomClipboard extends Clipboard {
  async onCapturePaste(e) {
    // console.log('crdt onCapturePaste')
    if (e.defaultPrevented || !this.quill.isEnabled()) return;
    e.preventDefault();
    const range = this.quill.getSelection(true);
    if (range == null) return;
    const html = e.clipboardData?.getData("text/html");
    let text = e.clipboardData?.getData("text/plain");
    if (!html && !text) {
      const urlList = e.clipboardData?.getData("text/uri-list");
      if (urlList) {
        text = this.normalizeURIList(urlList);
      }
    }
    const files = Array.from(e.clipboardData?.files || []);
    if (!html && files.length > 0) {
      // console.log("crdt 1", files);
      const newFiles = await handleQuillFiles(files);
      // console.log("crdt 1 newFiles", newFiles);
      this.quill.uploader.upload(range, newFiles);
      return;
    }
    if (html && files.length > 0) {
      const doc = new DOMParser().parseFromString(html, "text/html");
      if (
        doc.body.childElementCount === 1 &&
        doc.body.firstElementChild?.tagName === "IMG"
      ) {
        // console.log("crdt 2", files);
        const newFiles = await handleQuillFiles(files);
        // console.log("crdt 2 newFiles", newFiles);
        this.quill.uploader.upload(range, newFiles);
        return;
      }
    }
    this.onPaste(range || 0, {
      html,
      text,
    });
  }
}

import {
  bool, func, number, string, object,
  array,
} from 'prop-types';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

import { Flex, Spin } from "antd";
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import ListOfIssuesAntDesignTable from './ListOfIssuesAntDesignTable';
import MultiEditIssueView from '../multiEdit/MultiEditIssueView';
import WrapperAddIssue from '../issues/newIssue/WrapperAddIssue';

import { listOfIssuesFetching, listOfIssuesFromStore } from '../../reducers/issues/IssuesSlicer';
// import { getIssueUUID } from '../../selectors/selectors';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { columnsFilterConfig } from './constants/issueFilterOptions';

// import { useResize } from '../../../hooks/useResize';
import useURLParams from '../../../hooks/useURLParams';
import WrapperAddRecurringIssue from '../recurringIssue/newRecurringIssue/WrapperAddRecurringIssue';

function ListOfIssuesForIssueView({
  isGlobalDisabled,
  newCurrentPage,
  countOfData,
  partitionType,
  filterConfig,
  getIssues,
  getIssue,
  disabledMultiEdit,
  getIssuesRequestSetting,
  filterCallback,
  onChangeRowCallback,
  newIssueSuccessCallback,
  hiddenElements = [],
  hasRecurring = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const issues = useSelector(listOfIssuesFromStore);
  
  const issuesFetching = useSelector(listOfIssuesFetching);

  // console.log('listOfIssuesFromStore issues', issues);

  const { getURLParams } = useURLParams();
  const { activeIssue, activeRecurring } = getURLParams() || {};

    
  const [isMultiEdit, setIsMultiEdit] = useState(false);
  const [limit, setLimit] = useState(25);

  const toggleMultiEdit = () => {
    setIsMultiEdit((prev) => {
      dispatch({ type: ProjectTypeCommonConstants.SET_FLAG_FOR_MULTI_SELECT, payload: !prev });
      return !prev;
    });
  };

  const backIssueCallback = () => {
    toggleMultiEdit();
    getIssues();
    if (activeIssue) {
      getIssue(activeIssue);
    }
  };

  const hideViewCreateIssueModal = ['assignedProject'];
  
  return (
    <>
      <Flex>
        <h6 className="mb-0 mr-2">{capitalize(t('wms.issues.issues'))}</h6>
        {!hiddenElements.includes('addBtns') && (
          <>
            <WrapperAddIssue
              partitionType={partitionType}
              createIssueCallback={newIssueSuccessCallback}
              hideView={hideViewCreateIssueModal}
              disableBtn={isGlobalDisabled}
              defaultIssueState={{
                partition: partitionType,
              }}
            />
            {
              hasRecurring ? 
              <WrapperAddRecurringIssue
                partitionType={partitionType}
                hideView={['assignedProject']}
                disableBtn={false}
                defaultRecurringIssueState={{
                  partitionType,
                }}
                createRecurringIssueCallback={newIssueSuccessCallback}
              /> : 
              <></>
            }
            <BaseButton
              id="multiEditIssuesButton"
              size="small"
              className="ml-1 mr-1"
              onClick={toggleMultiEdit}
              type="primary"
              ghost
              disabled={disabledMultiEdit || isGlobalDisabled || !issues.length}
            >
              {capitalize(t('wms.buttons.multi_edit'))}
            </BaseButton>
          </>
        )}
      </Flex>
      <>
        <Spin spinning={issuesFetching}>
          {!isMultiEdit ? (
            <ListOfIssuesAntDesignTable
              data={issues}
              limit={limit}
              totalCount={countOfData}
              selectedIssues={activeIssue || activeRecurring}
              newCurrentPage={newCurrentPage}
              setLimit={setLimit}
              getIssuesRequestSetting={getIssuesRequestSetting}
              filterColumnsConfig={columnsFilterConfig}
              filterConfig={filterConfig}
              filterCallback={filterCallback}
              onChangeRowCallback={onChangeRowCallback}
            />
          ) : (
            <MultiEditIssueView
              currentPage={newCurrentPage}
              totalCount={countOfData}
              defaultData={issues}
              pageLimit={limit}
              partitionType={partitionType}
              getIssuesRequestSetting={getIssuesRequestSetting}
              backIssueCallback={backIssueCallback}
            />
          )}
        </Spin>
      </>
    </>
  );
}

export default ListOfIssuesForIssueView;

ListOfIssuesForIssueView.propTypes = {
  countOfData: number,
  getIssuesRequestSetting: func,
  newIssueSuccessCallback: func,
  onChangeRowCallback: func,
  filterConfig: object,
  filterCallback: func,
  partitionType: string,
  getIssues: func,
  isGlobalDisabled: bool,
  newCurrentPage: number,
  getIssue: func,
  disabledMultiEdit: bool,
  hiddenElements: array,
  hasRecurring: bool,
};

import {
  bool, func, number, string,
} from 'prop-types';
import React from 'react';
import { getColorForActor } from '../../54origins/components/CircleActorAvatar54origins';
import { capitalize } from 'lodash';
import './UserAvatar.scss';

function UserAvatar({
  onClickCallback = () => {},
  fullName,
  showFullName = false,
  isSelected = true,
  userFirstName,
  userLastName,
  userUUID,
  size = 40,
  className = 'avatar-user',
  customInitials,
  fontSize,
}) {
  const initials = customInitials || `${capitalize(
    userFirstName[0],
  )}${capitalize(userLastName[0])}`;

  return (
    <div
      onClick={() => onClickCallback(userUUID)}
      className={className}
      style={{
        width: showFullName ? 100 : size,
        height: size,
        backgroundColor: isSelected ? getColorForActor(userUUID) : '#d8d8d8',
        borderRadius: showFullName && '50px',
      }}
    >
      <p
        className="text-center color"
        style={{ fontSize }}
      >
        {showFullName
          ? fullName.length > 15
            ? `${fullName.slice(0, 11)}...` : fullName
          : initials}
      </p>
    </div>
  );
}

UserAvatar.propTypes = {
  fullName: string,
  size: number,
  userFirstName: string,
  userLastName: string,
  className: string,
  userUUID: string,
  customInitials: string,
  isSelected: bool,
  onClickCallback: func,
  showFullName: bool,
};

export default UserAvatar;

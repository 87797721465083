import React, { memo } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import BaseButton from '../components/_ui/BaseButton/BaseButton';

import { updatePrivateSettingsRequest } from '../userFlow/store/action-creators/userActions';
import { getPrivateUserSettings } from '../userFlow/store/selectors/selectors';
import { appLabel } from '../api/appConfig';

function GptRules({
  cancelCallBack,
}) {
  const dispatch = useDispatch();

  const privateUserSettings = useSelector(getPrivateUserSettings);

  const acceptWithHelperRules = () => {
    dispatch(updatePrivateSettingsRequest(privateUserSettings?.uuid, {
      gptTermsOfUse: true,
    })).then(() => {}).catch(() => {});
  };

  const cancelFunc = () => {
    cancelCallBack?.();
  };

  return (
    <>
      <h5>
        При использовании
        {' '}
        {appLabel}
        {' '}
        Helper важно соблюдать следующие правила:
      </h5>
      <p>
        Ограничьте доступ к конфиденциальной информации. Избегайте обмена личной или
        конфиденциальной информацией в разговорах с
        {' '}
        {appLabel}
        {' '}
        Helper.
      </p>
      <p>
        {appLabel}
        {' '}
        Helper регистрирует каждый разговор, включая любые личные данные, которыми вы делитесь,
        и будет использовать их в качестве обучающих данных.
      </p>
      <Row>
        <Col>
          <BaseButton onClick={acceptWithHelperRules} type="primary">Согласен</BaseButton>
          <BaseButton className="ml-2" onClick={cancelFunc}>Отмена</BaseButton>
        </Col>
      </Row>
    </>
  );
}

export default memo(GptRules);

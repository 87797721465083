import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalize, concat, difference, get, isNil, pick, toString, uniq,
} from 'lodash';
import {
  array, bool, func, number, object, string,
} from 'prop-types';
import {
  Button, Flex,
  Spin,
} from 'antd';
import {
  firstName,
  fullName,
  getUuid,
  lastName,
  permsAdmin,
  permsNormalUser,
} from './utils';
import { actorIsRootOrAdmin, stopPropagation } from '../54origins/utils54origins';
import UserListForAssignList from './commonComponent/UserListForAssignList';
import AssignUsersHeader from './commonComponent/AssignUsersHeader';
import AssignUsersTabs from './commonComponent/AssignUsersTabs';

import './assignUsers.scss';
import { getEntityPermission } from '../entity/actions/entityPermActions';
import useActorsW54Api from '../actors/actions/useActorsW54Api';
import { getActorsW54 } from '../actors/selectors/actorsW54Selector';
import AssignUsersInfoSearch from './commonComponent/AssignUsersInfoSearch';
import { getStyle } from 'antd/es/checkbox/style';
import cn from 'classnames';
import { UI54Button, UI54Colors } from "@agpl/ui54kit";

const keyPerms = ['create', 'read', 'update', 'delete', 'list', 'set'];

let needClearUserList = false;

export default function AssigningUsersComponent({
  headerText = '',
  onFinish,
  tabs = [],
  onClose,
  isGroupMode = false,
  usersConfig = {},
  projectUuid = '',
  globalOptions = {},
  addAction,
  actions = 0,
  hidePerms = false,
  partition = '',
  disabledSave = false,
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const actorsW54 = useSelector(getActorsW54);

  const [selectedTab, setSelectedTab] = useState('all');

  const [actualPerms, setActualPerms] = useState([]);

  const [allActors, setAllActors] = useState([]);

  const [projectUsers, setProjectUsers] = useState([]);
  const [vectorUsers, setVectorUsers] = useState([]);

  const [isFetching, setIsFetching] = useState(false)
  const [isCustomUsersFetching, setIsCustomUsersFetching] = useState(false)

  const [selectedActorsData, setSelectedActorsData] = useState([]);

  const [valueSearch, setValueSearch] = useState('');

  const [total, setTotal] = useState(0);

  const [config, setConfig] = useState({
    limit: 100,
    offset: 0,
    not_banned: true,
  });

  const [allChanges, setAllChanges] = useState({});

  const actorTypes = isGroupMode ? ["group"] : ['classic_user', 'user'];

  const {
    defaultColumn = 'all',
    isOneColumnMode = false,
  } = globalOptions

  // Переделать
  const [changedRoles, setChangedRoles] = useState([]);

  const { getListOfActorsW54 } = useActorsW54Api();

  const { allConfig = null, projectConfig = null, vectorConfig = null } = tabs;

  const filterUsersBySearch = (users = []) => users
    .filter((user) => user?.label?.toLowerCase()
      .includes(valueSearch.toLowerCase()));

  const W54Users = useMemo(() => allActors
    .map((item) => ({
      value: getUuid(item),
      label: fullName(item, isGroupMode ? 'group' : 'user'),
      isWMSAdmin: actorIsRootOrAdmin(item),
    })), [
    JSON.stringify(allActors),
  ]);

  const allUsersAfterSearch = useMemo(() => W54Users.filter((user) => ![...projectUsers,
  ...selectedActorsData]
    .some((projectUser) => projectUser?.value === user?.value)), [
    JSON.stringify(W54Users),
    JSON.stringify(projectUsers),
    JSON.stringify(selectedActorsData),
  ]);

  const assignUsersAfterSearch = useMemo(
    () => {
      let filteredUsers = [...selectedActorsData].filter(el => !isNil(el));

      filteredUsers = filteredUsers?.map((el) => {
        const actualPerm = [...actualPerms].find((p) => p?.uuid === el?.value);
        return {
          ...el,
          perms: actualPerm?.perms || el?.perms || {},
          className: valueSearch && el?.label?.toLowerCase()
            .includes(valueSearch.toLowerCase()) ? 'bg-sky-100 text-blue-500' : ''
        };
      });

      if (valueSearch) {
        filteredUsers?.sort((a, b) => {
          if (a?.label?.toLowerCase()
            .includes(valueSearch.toLowerCase()) && !b?.label?.toLowerCase()
              .includes(valueSearch.toLowerCase())) {
            return -1;
          } else if (!a?.label?.toLowerCase()
            .includes(valueSearch.toLowerCase()) && b?.label?.toLowerCase()
              .includes(valueSearch.toLowerCase())) {
            return 1;
          } else {
            return 0;
          }
        })
      }

      return filteredUsers ?? [];
    },
    [
      valueSearch,
      JSON.stringify(selectedActorsData),
      JSON.stringify(actualPerms)
    ],
  );

  const assignVectorUsersAfterSearch = useMemo(
    () => filterUsersBySearch(vectorUsers),
    [valueSearch, JSON.stringify(vectorUsers)],
  );

  const assignProjectUsersAfterSearch = useMemo(
    () => filterUsersBySearch(projectUsers),
    [valueSearch, JSON.stringify(projectUsers)],
  );

  const toggleSave = () => {
    let allUuids;
    let allUsers;
    let deleteUsers;

    if (projectConfig) {
      deleteUsers = difference(
        projectConfig?.defaultItems?.map((i) => getUuid(i)),
        projectUsers.map((el) => el?.value),
      );
      allUsers = projectUsers.map((item) => ({
        uuid: item?.value,
        role: item?.role,
      }));
      allUuids = projectUsers.map((el) => el?.value);
    } else {
      deleteUsers = difference(
        usersConfig?.defaultItems,
        selectedActorsData.map((el) => el?.value),
      );

      allUsers = isGroupMode ? selectedActorsData : selectedActorsData.map((item) => ({
        uuid: item?.value,
        role: item?.role,
      }));

      allUuids = selectedActorsData.map((el) => el?.value);
    }

    onFinish({
      allUuids,
      allUsers,
      deleteUsers,
      newPerms: actualPerms,
      users: selectedActorsData?.map((el) => ({
        uuid: el?.value,
        role: el?.role,
      })),
    });
  };

  const checkSections = (sections) => {
    if (hidePerms) {
      const hiddenSections = ['perms', 'admin'];
      return sections.filter((el) => !hiddenSections.includes(el));
    }
    return sections;
  };

  const onDeleteUserFromEntity = (uuid) => {
    addAction();
    setActualPerms((prev) => [...prev.filter((el) => el?.uuid !== uuid)]);
    setSelectedActorsData((prev) => prev
      ?.filter((el) => uuid !== getUuid(el)));
  };

  const onAddUserToEntity = (uuid) => {
    addAction();
    const user = projectUsers?.find((el) => uuid === getUuid(el));
    setAllChanges(prev => ({
      ...prev,
      [uuid]: {
        ...get(prev, uuid, {}),
        added: true,
      }
    }))
    setSelectedActorsData((prev) => [{
      ...user,
    }, ...(prev || [])
    ]);
  };

  const onAddUserToProjectAndEntity = (uuid) => {
    addAction();
    const user = W54Users?.find((el) => uuid === getUuid(el));
    const newUser = { ...user, perms: permsNormalUser, role: '' };
    setAllChanges(prev => ({
      ...prev,
      [uuid]: {
        ...get(prev, uuid, {}),
        added: true,
      }
    }))

    if (globalOptions?.isOwner) {
      setSelectedActorsData([newUser]);
      return;
    }

    if (globalOptions?.isAdminMode) {
      setActualPerms((prev) => [{ uuid, perms: permsAdmin }, ...prev]);
      setSelectedActorsData((prev) => [newUser, ...(prev || [])]);
      setProjectUsers((prev) => [newUser, ...(prev || [])]);
      return;
    }

    setActualPerms((prev) => [{ uuid, perms: permsNormalUser }, ...prev]);
    setSelectedActorsData((prev) => [newUser, ...(prev || [])]);
    setProjectUsers((prev) => [newUser, ...(prev || [])]);
  };

  const onClickSingleColumn = (uuid) => {
    addAction();
    if (selectedActorsData?.some((el) => getUuid(el) === uuid)) {
      setSelectedActorsData((prev) => prev.filter((el) => getUuid(el) !== uuid));
    } else {
      setSelectedActorsData((prev) => [...prev,
      assignProjectUsersAfterSearch?.find((el) => getUuid(el) === uuid)]);
    }
  };

  const getUserPerms = (perms = [], uuid = '') => {
    if (!perms?.length || !uuid) return {};
    const perm = perms.find((p) => p?.actor_uuid === uuid);
    if (!perm) return {};
    return pick(perm, keyPerms);
  };

  const setDefaultUsers = ({
    roles = [], perms = [], uuids = [], type, users = [],
  }) => {
    const uniqUuids = uniq(uuids);

    const selectedUserInfo = uniqUuids.map((uuid) => {
      const item = users.find((user) => getUuid(user) === uuid);
      return {
        value: uuid,
        label: fullName(item, isGroupMode ? 'group' : 'user'),
        isWMSAdmin: actorIsRootOrAdmin(item),
        perms: getUserPerms(perms, uuid) || {},
        role: get(roles?.find((i) => i?.uuid === uuid), 'role', ''),
      };
    });

    switch (type) {
      case 'assign': setSelectedActorsData(selectedUserInfo); break;
      case 'vector': setVectorUsers(selectedUserInfo); break;
      case 'project': setProjectUsers(selectedUserInfo); break;
      default: break;
    }
  };

  const getUsersWithPerms = async (uuids = []) => {
    const perms = await new Promise((resolve) => dispatch(
      getEntityPermission({
        partition,
        params: {
          entity_uuid: projectUuid,
          actors: uuids,
        },
      }, (res) => { resolve(res.data); }),
    )).catch((e) => console.log(e));

    return perms;
  };

  const getListOfUsers = async (uuids) => {
    let users = []
    const uuidsForFetching = uuids.filter(uuid => {
      if (actorsW54[uuid]) {
        users.push(actorsW54[uuid]);
        return false;
      }
      return true;
    })
    if (uuidsForFetching.length) {

      const { data: usersList = [] } = await getListOfActorsW54({
        actor_type: actorTypes,
        uuids: uuidsForFetching,
      });
      users = concat(users, usersList)
    }
    return users;
  }

  const initFunc = async () => {
    const uuids = globalOptions?.uuids ?? [];
    const roles = globalOptions?.roles ?? [];
    let users = [];
    let perms = [];

    if (!uuids.length) return;

    if (globalOptions?.perms) setActualPerms(globalOptions?.perms);

    setIsCustomUsersFetching(true)
    users = await getListOfUsers(uuids);

    if (globalOptions?.needLoadPerms && projectUuid && !hidePerms) {
      perms = await getUsersWithPerms(uuids);
    }
    setIsCustomUsersFetching(false)

    if (projectConfig) {
      const projectUserUUIDs = projectConfig?.defaultItems
        ?.map((el) => el?.uuid);
      setDefaultUsers({
        roles,
        perms,
        uuids: projectUserUUIDs,
        type: 'project',
        users,
      });
    }

    if (vectorConfig?.defaultItems) {
      const vectorUserUUIDs = vectorConfig?.defaultItems
        ?.filter((uuid) => uuids.includes(uuid));
      setDefaultUsers({
        roles,
        perms,
        uuids: vectorUserUUIDs,
        type: 'vector',
        users,
      });
    }

    if (usersConfig?.defaultItems) {
      const assignUserUUIDs = usersConfig?.defaultItems
        ?.filter((uuid) => uuids.includes(uuid));
      setDefaultUsers({
        roles,
        perms,
        uuids: assignUserUUIDs,
        type: 'assign',
        users,
      });
    }

    if (isOneColumnMode) {
      setDefaultUsers({
        roles,
        perms,
        uuids,
        type: 'project',
        users,
      });
    }
  };

  const getUsers = async () => {
    setIsFetching(true)
    const res = await getListOfActorsW54({
      ...config,
      actor_type: actorTypes,
      not_banned: true,
    })

    setTotal(res.total);
    if (needClearUserList) {
      setAllActors([...res.data]);
    } else {
      setAllActors((prev) => ([...prev, ...res.data]));
    }

    needClearUserList = false;
    setIsFetching(false)
  };

  const onChangeValueSearch = (value) => {
    needClearUserList = true;
    setValueSearch(value);
    setConfig((prev) => ({
      ...prev,
      offset: 0,
      search_data: {
        fields: {
          uinfo: isGroupMode ? {
            group_name: 'group_name'
          } : {
            first_name: 'first_name',
            last_name: 'last_name',
          },
        },
        value,
      },
    }));
  };

  const onUserChange = (key, value, userUUID, userData) => {
    switch (key) {
      case 'isAdmin':
        addAction();
        if (!get(allChanges, [userUUID, 'isAdmin', 'wasChanged'], false)) {
          setAllChanges(prev => ({
            ...prev,
            [userUUID]: {
              ...get(prev, userUUID, {}),
              isAdmin: { value: !value, wasChanged: true },
            }
          }))
        }
        setActualPerms((prev) => [
          ...(prev?.filter((user) => user?.uuid !== userUUID) ?? []),
          {
            uuid: userUUID,
            perms: value ? permsAdmin : permsNormalUser,
          },
        ]);
        break;
      case 'role':
        if (!changedRoles?.includes(userUUID)) {
          addAction();
          setChangedRoles((prev) => [...prev, userUUID]);
        }

        setSelectedActorsData((prev) => prev
          .map((item) => {
            const user = { ...item };
            if (getUuid(item) === userUUID) {
              if (!get(allChanges, [userUUID, 'role', 'wasChanged'], false)) {
                setAllChanges(prev => ({
                  ...prev,
                  [userUUID]: {
                    ...get(prev, userUUID, {}),
                    role: { value: toString(user.role), wasChanged: true },
                  }
                }))
              }
              return { ...user, role: value };
            }
            return user;
          }));
        break;
      case 'perms':
        addAction();
        setActualPerms((prev) => {
          const newPerms = prev.filter((el) => el?.uuid !== userUUID);

          if (!get(allChanges, [userUUID, 'perms', 'wasChanged'], false)) {
            setAllChanges(prev => ({
              ...prev,
              [userUUID]: {
                ...get(prev, userUUID, {}),
                perms: { value: userData?.perms, wasChanged: true },
              }
            }))
          }
          return [...newPerms, { uuid: userUUID, perms: value }];
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    initFunc();
  }, [
    JSON.stringify(tabs),
    JSON.stringify(usersConfig),
  ]);

  useEffect(() => {
    if (!isFetching) {
      getUsers();
    }
  }, [JSON.stringify(config)]);

  useEffect(() => {
    setSelectedTab(defaultColumn);
  }, [defaultColumn]);

  const getListStyle = () => {
    switch (globalOptions.size) {
      case 'large': {
        return {
          height: 520,
          width: 350,
        };
      }
      case 'middle': {
        return {
          height: 400,
          width: 310,
        };
      }
      case 'small': {
        return {
          height: 300,
          width: 260,
        };
      }
      case 'extra small': {
        return {
          height: 300,
          width: 230,
        };
      }
      default: {
        return {
          height: 520,
          width: 350,
        };
      }
    }
  }

  return (
    <Flex
      vertical
      className="h-full"
      onClick={stopPropagation}
    >
      <AssignUsersHeader
        header={(
          <Flex className="w-full" align='center'>
            <span>
              {headerText || 'Assigning users'}
            </span>
            {!globalOptions?.isOneColumnMode ? (
              <span
                style={{
                  fontSize: '13px',
                  color: '#808080',
                  marginLeft: 6,
                }}
              >
                {selectedActorsData?.length}
                /
                {total}
              </span>
            ) : null}
          </Flex>
        )}
        valueSearch={valueSearch}
        setValueSearch={onChangeValueSearch}
      />
      {!globalOptions?.isOneColumnMode ? (
        <>
          <Flex
            justify="space-between"
            className="w-full mt-1"
          >
            <Spin spinning={isFetching}>
            <Flex
              vertical
              className="users-block"
              style={getListStyle()}
            >
              <AssignUsersTabs
                items={[allConfig, projectConfig, vectorConfig].filter((el) => el)}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
              {selectedTab === 'all' && allConfig
                ? (
                  <UserListForAssignList
                    total={total}
                    config={config}
                    setConfig={setConfig}
                    itemHeight={50}
                    items={allUsersAfterSearch}
                    showLoadingAfterLastElement={isFetching}
                    onClickCallback={onAddUserToProjectAndEntity}
                    itemOptions={{
                      textSelectButton: allConfig?.textSelectButton ?? 'Add',
                      showSections: allConfig?.showSections,
                      size: globalOptions?.size,
                    }}
                  />
                ) : null}
              {selectedTab === 'project'
                ? (
                  <UserListForAssignList
                    items={assignProjectUsersAfterSearch}
                    disabledList={assignUsersAfterSearch?.map((el) => el?.value)}
                    onClickCallback={onAddUserToEntity}
                    itemHeight={50}
                    itemOptions={{
                      textSelectButton: projectConfig?.textSelectButton ?? 'Add',
                      showSections: projectConfig?.showSections,
                      size: globalOptions?.size,
                    }}
                  />
                ) : null}
              {selectedTab === 'vector'
                ? (
                  <UserListForAssignList
                    items={assignVectorUsersAfterSearch}
                    disabledList={assignUsersAfterSearch?.map((el) => el?.value)}
                    onClickCallback={onAddUserToEntity}
                    itemHeight={50}
                    itemOptions={{
                      textSelectButton: vectorConfig?.textSelectButton ?? 'Add',
                      showSections: vectorConfig?.showSections,
                      size: globalOptions?.size,
                    }}
                  />
                ) : null}
            </Flex>
            </Spin>
            <Flex
              vertical
              className="users-block"
              style={getListStyle()}
            >
              <AssignUsersTabs
                items={[usersConfig]}
              />
              <UserListForAssignList
                items={assignUsersAfterSearch}
                showLoadingAfterLastElement={isCustomUsersFetching}
                onClickCallback={onDeleteUserFromEntity}
                itemHeight={50}
                allChanges={allChanges}
                onUserChangeCallback={onUserChange}
                itemOptions={{
                  size: globalOptions?.size,
                  showSections: checkSections(usersConfig?.showSections),
                  textDeleteButton: usersConfig?.textDeleteButton,
                }}
              />
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex
          vertical
          className="users-block w-full mt-2"
          style={{
            width: 230,
            height: 300,
          }}
        >
          <AssignUsersTabs
            items={[usersConfig]}
          />
          <UserListForAssignList
            items={assignProjectUsersAfterSearch}
            selectedList={selectedActorsData?.map((el) => getUuid(el))}
            onClickCallback={onClickSingleColumn}
            itemHeight={50}
            itemOptions={{
              size: globalOptions?.size,
              isOneColumnMode: globalOptions?.isOneColumnMode,
              showSections: usersConfig?.showSections,
              textDeleteButton: usersConfig?.textDeleteButton,
              textSelectButton: usersConfig?.textSelectButton,
            }}
          />
        </Flex>
      )
      }
      <Flex
        justify="flex-end"
        align='center'
        className="mt-2 w-full"
      >

        {actions > 0 && !globalOptions?.isOneColumnMode ? (
          <Flex
            justify="flex-start"
            className='ml-5 text-base text-red-500'
          >
            *You have
            {' '}
            {actions}
            {' '}
            unsaved actions
          </Flex>
        ) : null}
        <UI54Button
          id="cancelButton"
          className="mx-2"
          variant="outlined"
          color={UI54Colors.red[500]}
          onClick={() => onClose()}
        >
          {capitalize(t('wms.verb.cancel', 'cancel'))}
        </UI54Button>
        <UI54Button
          id="saveButton"
          type="primary"
          color={UI54Colors.green[500]}
          disabled={disabledSave}
          onClick={toggleSave}
        >
          {capitalize(t('wms.verb.save', 'save'))}
        </UI54Button>
      </Flex>
    </Flex >
  );
}

AssigningUsersComponent.propTypes = {
  headerText: string,
  hidePerms: bool,
  onFinish: func,
  addAction: func,
  onClose: func,
  actions: number,
  tabs: array,
  usersConfig: object,
  projectUuid: string,
  partition: string,
  globalOptions: object,
  isGroupMode: bool,
};

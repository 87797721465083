import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import "./AreaEditor.scss"

const { TextArea } = Input;

class AreaEditor extends Component {
  inputRef = createRef();

  componentDidMount() {
    this.inputRef.current.focus();
  }

  handleChange = e =>  {
    const { onChange } = this.props;

    onChange(e.target.value);
  }

  render() {
    const { value, onKeyDown } = this.props;

    return (
      <TextArea
        ref={this.inputRef}
        className="data-editor"
        value={value}
        onChange={this.handleChange}
        onKeyDown={onKeyDown}
      />
    );
  }
}

AreaEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default AreaEditor;

import { useState } from 'react';
import { object, string } from 'prop-types';
import { isEmpty, isEqual, snakeCase } from 'lodash';
import useURLParams from '../../../hooks/useURLParams';
import { excludeSectionFilterResult } from '../helpers/filterHelper';

const getFilterPrefix = (target) => {
  switch (target) {
    case 'issue': return 'fi_';
    case 'vector': return 'fv_';
    case 'milestone': return 'fm_';
    case 'goal': return 'fg_';
    case 'document': return 'fd_';
    case 'project': return 'fp_';
    case 'testSuite': return 'ts_';
    case 'testCycle': return 'tcy_';
    case 'testCase': return 'tc_';
    default: return target;
  }
};

const prepareFilterConfig = (config) => {
  const fixedConfig = { ...config };  
  if (!isEmpty(fixedConfig)) {
    const filterKeys = Object.keys(fixedConfig);
    filterKeys.forEach(
      (key) => fixedConfig[key] = excludeSectionFilterResult(fixedConfig[key]),
    );
  }
  return fixedConfig;
};

const getSelectedFiltersFromParams = (target, searchParams) => {
  if (isEmpty(searchParams)) return null;

  const filterPrefix = getFilterPrefix(target);
  const configFromParams = {};

  Object.entries(searchParams).forEach((item) => {
    const [key, value] = item;
    if (key.match(`^${filterPrefix}`)) {
      const parameter = snakeCase(key.match(`^${filterPrefix}(\\w+)`)[1]);
      configFromParams[parameter] = value.split(';');
    }
  });

  return !isEmpty(configFromParams) ? configFromParams : null;
};

const initializeFilter = (target, searchParams, defaultFilters) => {
  const filtersFromParams = getSelectedFiltersFromParams(target, searchParams);
  // console.log('filtersFromParams:', filtersFromParams);
  return {
    ...(!isEmpty(defaultFilters) && defaultFilters),
    ...(!isEmpty(filtersFromParams) && filtersFromParams),
  };
};

// --------------------------------------------------------
function useTableFilter(target, defaultFilters) {
  // console.log('!? target:', target);

  const {
    getURLParams,
    setSearchParams,
    clearSearchParams,
  } = useURLParams();
  const allURLParams = getURLParams();
  // console.log('! allURLParams:', allURLParams);

  // TODO: add is changed filter state?
  const [filterConfig, setFilterConfig] = useState(
    () => initializeFilter(target, allURLParams, defaultFilters),
  );
  
  const addFiltersToSearchParams = (config) => {
    if (!isEmpty(config)) {
      const filterPrefix = getFilterPrefix(target);
      const filterKeys = Object.keys(config);

      filterKeys.forEach((key) => {
        const paramName = `${filterPrefix}${snakeCase(key)}`;
        if (Array.isArray(config[key])) {
          const values = config[key].join(';');          
          setSearchParams({ [paramName]: values });
        } else clearSearchParams([paramName]);
      });
    }
  };

  const changeFilterConfig = (config) => {
    const fixedConfig = prepareFilterConfig(config);
    if (!isEqual(filterConfig, fixedConfig)) {
      addFiltersToSearchParams(fixedConfig);
      setFilterConfig(fixedConfig);
    };
  };

  return {
    filterConfig,
    setFilterConfig,
    changeFilterConfig,
  };
}

useTableFilter.propTypes = {
  target: string,
  defaultFilters: object,
};

export default useTableFilter;

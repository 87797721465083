import { EntityConstants } from "../constants/Constants";

const initialState= {
  fetching: false
};

export default (state=initialState, action) => {
  switch(action.type) {
    case  EntityConstants.GET_USERS_ENTITIES_REQUEST: 
      return {
        ...state, fetching: true 
      };
    case  EntityConstants.GET_USERS_ENTITIES_SUCCESS: 
      return {
        ...state, fetching: false 
      };
    case  EntityConstants.GET_USERS_ENTITIES_FAILURE: 
      return {
        ...state, fetching: false 
      };
    default: return state;
  }
};

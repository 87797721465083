import React, { useState, useMemo, useLayoutEffect, useEffect } from "react";
import { useSelector } from "react-redux";
import { isArray, isEmpty } from "lodash";

import { Col, Flex, Divider } from "antd";
import { UI54Button, UI54Modal, UI54Icon } from "@agpl/ui54kit";
import AddUsersPanel from "./AddUsersPanel";
import SelectedActorsList from "./SelectedActorsList";

import { mdiAccountPlus, mdiContentSave } from '@mdi/js';

import { getMe, getProjectParams } from "../../../../../selectors/selectors";
import { getActorsW54 } from '../../../../../../actors/selectors/actorsW54Selector';

import { USER_TABS } from "./utils";

const checkTabs = (tabs) => {
  console.log('useMemo fn:');
  const options = [];
  tabs.forEach((el) => {
    if (USER_TABS[el]) options.push(USER_TABS[el]);
  });
  return options;
}


function NewAssignUsers({
  isOpen,
  setIsOpen,
  assignedUsers = [],
  tabs = [],
}) {
  console.log('assignedUsers: ', assignedUsers);

  const actorsW54 = useSelector(getActorsW54);
  console.log('actorsW54:', actorsW54);
  const { usersSearch } = useSelector(getProjectParams);
  console.log('usersSearch:', usersSearch);

  const [selectedActors, setSelectedActors] = useState([]);

  const [showTabs, setShowTabs] = useState(false);
  // const [modalWidth, setModalWidth] = useState(450);

  const availableTabs = useMemo(
    () => checkTabs(tabs),
    [JSON.stringify(tabs)],
  );

  // const availableTabs = useMemo(() => {
  //   const options = [];
  //   tabs.forEach((el) => {
  //     if (USER_TABS[el]) options.push(USER_TABS[el]);
  //   });
  //   return options;
  // }, [JSON.stringify(tabs)]);

  const disableAddUser = tabs?.length === 0;

  const toggleShowTabs = () => {
    setShowTabs(prev => !prev);
  };

  const handleCancel = () => {
    setIsOpen?.(false)
  }

  const checkActor = (uuid) => {
    const isW54Actor = !isEmpty(actorsW54[uuid]);
    const isProjectActor = usersSearch.includes(uuid);
    return isW54Actor && isProjectActor;
  };

  useLayoutEffect(() => {
    if (isArray(assignedUsers) && assignedUsers?.length > 0) {
      const actorsToShow = assignedUsers.filter((el) => checkActor(el));
      console.log('actorsToShow:', actorsToShow);
      setSelectedActors(actorsToShow);
    }
  }, [JSON.stringify(assignedUsers)]);

  useEffect(() => {
    if (!isOpen) {
      console.log('Destroy modal');
      setShowTabs(false);
    }
  }, [isOpen]);

  return (
    <UI54Modal
      title="Assign users"
      open={isOpen}
      width='max-content'
      centered
      closable
      destroyOnClose
      footer={null}
      onOk={() => { }}
      onCancel={handleCancel}
    >
      <Flex
        style={{ height: '500px' }}
        gap={5}
        flex='1 0 100%'
      >
        {showTabs && (
          <Flex vertical style={{ width: '300px' }}>
            <AddUsersPanel
              tabs={availableTabs}
              selectedActors={selectedActors}
              setSelectedActors={setSelectedActors}
            />
          </Flex>
        )}

        <Flex vertical style={{ width: '420px' }}>
          <SelectedActorsList
            initialActors={assignedUsers}
            selectedActors={selectedActors}
            setSelectedActors={setSelectedActors}
          />
          <Flex className='mt-2' gap={4} justify="space-between">
            <UI54Button
              className="btnPrimary-outline"
              icon={<UI54Icon path={mdiAccountPlus} size={1} />}
              disabled={disableAddUser}
              onClick={toggleShowTabs}
            >
              Add user
            </UI54Button>
            <UI54Button
              className="btnGreen-outline"
              variant="outlined"
              disabled={true}
              icon={<UI54Icon path={mdiContentSave} size={1} />}
            >
              Save
            </UI54Button>
          </Flex>
        </Flex>
      </Flex>
    </UI54Modal>
  );
}

export default NewAssignUsers;

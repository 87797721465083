import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row } from 'antd';
import { isEmpty, isNil } from 'ramda';

import { useTranslation } from 'react-i18next';
import NewArchiveStateComponent from './NewArchiveStateComponent';
import RestoreFromCurrentArchiveStateBtn
  from './RestoreFromCurrentArchiveStateBtn';
import ListOfArchiveState from './ListOfArchiveStateComponent';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import ArchiveStateInfo from './ArchiveStateInfoComponent';

import {
  getChildFromCurrentArchiveState,
  getCurrentArchiveStateUUID,
  getMainArchiveUUID,
} from '../../selectors/archiveStateSelectors';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
// import { isAdmin } from '../../../entity/selectors/selectors';
import { resetArchiveState } from '../../actions/PtcActionsForHook';
import { capitalize } from 'lodash';
import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
import { getListAndPartialReadEntities } from '../../../entity/actions/entityActions';
import CheckEntityPerm from '../../containers/permissions/CheckEntityPerm';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";

function MainArchiveStateTemplate({ entityUUID }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    defaultPartition,
  } = useContext(ProjectMainLayerContext);

  const curArchiveStateUUID = useSelector(getCurrentArchiveStateUUID);
  const curChildsFromArchiveState = useSelector(getChildFromCurrentArchiveState);
  const mainArchiveUUID = useSelector(getMainArchiveUUID);

  const [currentConfig, setCurrentConfig] = useState({
    limit: 25,
    offset: 0,
  });

  const getListOfArchiveStateEntity = (newConfig) => {
    const config = {
      data: {
        entity_type: 'archiveEntity',
        parent: mainArchiveUUID,
        depth: 1,
        ...newConfig,
        params_fields: {
          name: 'name',
        },
      },
      partition: defaultPartition,
      constants: [
        ProjectTypeCommonConstants.LIST_OF_ARCHIVE_ENTITY_REQUEST,
        ProjectTypeCommonConstants.LIST_OF_ARCHIVE_ENTITY_SUCCESS,
        ProjectTypeCommonConstants.LIST_OF_ARCHIVE_ENTITY_FAILURE,
      ],
    };
    return dispatch(getListAndPartialReadEntities(config));
  };

  useEffect(() => {
    if (!isEmpty(mainArchiveUUID) && !isNil(mainArchiveUUID) && !isEmpty(currentConfig)) {
      getListOfArchiveStateEntity(currentConfig);
    }
  }, [mainArchiveUUID, currentConfig]);

  return (
    <BaseCard className="primaryCard">
      <Row type="flex">

        <CheckEntityPerm
          partitionType={defaultPartition}
          entityUUID={entityUUID}
          permission={['set', 'create', 'read', 'list', 'update']}
        >
          <>
            <Col>
              <NewArchiveStateComponent
                newArchiveStateCallback={getListOfArchiveStateEntity}
              />

            </Col>
            <Col className="ml-2">
              {!isEmpty(curArchiveStateUUID)
                && curChildsFromArchiveState.length !== 0
                && <RestoreFromCurrentArchiveStateBtn />}
            </Col>
          </>
        </CheckEntityPerm>
        <Col className="ml-2">
          {!isEmpty(curArchiveStateUUID)
            && (
              <BaseButton
                className="customAntOutWarningBtn"
                onClick={() => dispatch(resetArchiveState)}
              >
                {capitalize(t('wms.buttons.reset', 'reset'))}
              </BaseButton>
            )}
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <ListOfArchiveState setCurrentConfig={setCurrentConfig} />
        </Col>
        <Col span={12}>
          {!isEmpty(curArchiveStateUUID)
            && <ArchiveStateInfo />}
        </Col>
      </Row>
    </BaseCard>
  );
}

export default MainArchiveStateTemplate;

import { concat } from 'lodash';
import { ProjectTypeCommonConstants } from '../constants/Constants';

const initialState = {
  fetching: true,
  listOfDocs: [],
  error: '',
  fetchingDoc: false,
  singleDoc: [],
  documentContent: null,
  errorDoc: [],
  fetchingNewDoc: false,
  listDocsAndIdea: [],
  totalDocsAndIdea: 0,
};

export default (state = initialState, action) => {
  const { type, payload, total } = action;
  switch (type) {
    case ProjectTypeCommonConstants.FETCH_PROJECT_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.FETCH_PROJECT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        listOfDocs: payload,
      };

    case ProjectTypeCommonConstants.FETCH_PROJECT_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetching: false,
        listOfDocs: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_REQUEST:
      return {
        ...state,
        fetchingNewDoc: true,
      };
    case ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        listOfDocs: state.listOfDocs.concat(payload),
        fetchingNewDoc: false,
      };
    case ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_FAILURE:
      return {
        ...state,
        fetchingNewDoc: false,
        data: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.GET_DOCUMENT_REQUEST:
    case ProjectTypeCommonConstants.UPDATE_DOCUMENT_REQUEST:
      return {
        ...state,
        fetchingDoc: true,
      };

    case ProjectTypeCommonConstants.GET_DOCUMENT_SUCCESS:
    case ProjectTypeCommonConstants.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        fetchingDoc: false,
        singleDoc: payload,
      };
    case ProjectTypeCommonConstants.GET_DOCUMENT_FAILURE:
    case ProjectTypeCommonConstants.UPDATE_DOCUMENT_FAILURE:
      return {
        ...state,
        fetchingDoc: false,
        singleDoc: [],
        errorDoc: payload,
      };
    case ProjectTypeCommonConstants.SET_DOCUMENT_CONTENT:
      return {
        ...state,
        documentContent: payload,
      };
    case ProjectTypeCommonConstants.CLEAR_DOCUMENT:
      return {
        ...state,
        fetchingDoc: false,
        singleDoc: [],
        errorDoc: '',
      };
    case ProjectTypeCommonConstants.CLEAR_PROJECT_DOCUMENTS:
      return initialState;

    case ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_SUCCESS:
      return {
        ...state,
        listDocsAndIdea: payload,
        totalDocsAndIdea: total,
        fetching: false,
      };
    case ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_FAILURE:
      return {
        ...state,
        fetching: false,
        listDocsAndIdea: [],
        totalDocsAndIdea: 0,
        error: payload,
      };
    case ProjectTypeCommonConstants.FETCH_AND_PUSH_DOCUMENTS_AND_IDEA_SUCCESS:
      return {
        ...state,
        listDocsAndIdea: concat(state.listDocsAndIdea, payload),
        totalDocsAndIdea: state.totalDocsAndIdea + total,
        fetching: false,
      }
    default: return state;
  }
};

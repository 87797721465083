import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Col, Dropdown, Row,
} from 'antd';
import Icon from '@mdi/react';
import {
  mdiAccountCircleOutline,
  mdiGhostOutline,
  mdiLogoutVariant,
  mdiMenuDown,
} from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { propOr } from 'ramda';
import { isEmpty } from 'lodash';
import axios from 'axios';

import CircleActorAvatar54origins from '../../54origins/components/CircleActorAvatar54origins';

import { capitalizeAndTranslateMsg } from '../../MainUtils';
import { getUser, getUserName } from '../../entity/selectors/selectors';
import { AuthConstants } from '../../auth/Constants';
import { EntityConstants } from '../../entity/constants/Constants';
import { getInitialsNameOfActor, logoutAndClearAllCookies } from '../../54origins/utils54origins';
import useSendMetaToLocalStorage from '../../userFlow/hooks/useSendMetaData';
import { getSessionTokenFor } from '../../api/appConfig';
import { entityBackendURL } from '../../api/api';

function UserDropdownPanel() {
  const dispatch = useDispatch();

  const username = useSelector(getUserName);
  const user = useSelector(getUser);

  const [allowMasquerading, setAllowMasquerading] = useState(false);

  const { parseLocalStorageAndSaveData } = useSendMetaToLocalStorage();

  const clearError = () => ({ type: AuthConstants.CLEAR_ERROR_430 });

  const clearConfigAndPartition = () => {
    dispatch({ type: EntityConstants.CLEAR_PARTITION });
    dispatch({ type: EntityConstants.CLEAR_CONFIG });
  };

  const logoutUserAndClearAllCookies = () => {
    parseLocalStorageAndSaveData();
    logoutAndClearAllCookies();
    clearConfigAndPartition();
    dispatch(clearError());
    // Socket.disconnect();
  };

  const getMasqueradingUsers = () => {
    axios({
      method: 'post',
      url: `${entityBackendURL()}/masquerade/get_actor_info/`,
      headers: {
        'Content-Type': 'application/json',
        'Session-Token': getSessionTokenFor.entity(),
      },
      data: {},
    }).then(({ data }) => {
      const { allowed } = data || {};

      setAllowMasquerading(allowed);
    })
      .catch(() => {
        setAllowMasquerading(false);
      });
  };

  const items = [
    {
      key: 'item-1',
      icon: <Icon path={mdiAccountCircleOutline} size={1} />,
      className: 'header-dropdown-item',
      label: (
        <Link to="/public/user/profile">
          {capitalizeAndTranslateMsg('wms.profile.header', 'profile')}
        </Link>
      ),
    },
    allowMasquerading && {
      key: 'item-2',
      icon: <Icon path={mdiGhostOutline} size={1} />,
      className: 'header-dropdown-item',
      label: (
        <Link to="/user/masquerading">
          masquerading
        </Link>
      ),
    },
    {
      key: 'item-3',
      icon: <Icon path={mdiLogoutVariant} size={1} />,
      className: 'header-dropdown-item item-red',
      onClick: () => logoutUserAndClearAllCookies(),
      label: (
        <Link to="/login">
          {capitalizeAndTranslateMsg('wms.sidemenu.logout', 'log out')}
        </Link>
      ),
    },
  ];

  return (
    <div className="header-dropdown-wrapper h-full">
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        className="h-full"
        menu={{ items }}
        onOpenChange={(isOpen) => isOpen && getMasqueradingUsers()}
      >
        <Row style={{ cursor: 'pointer' }}>
          {!isEmpty(user) && (
            <Col className="flex items-center">
              <CircleActorAvatar54origins
                name={getInitialsNameOfActor(user)}
                actorUUID={propOr('', 'uuid', user)}
              />
            </Col>
          )}
          <Col className="flex items-center">
            <span className="m-0 ml-2 text-ellipsis" style={{ userSelect: 'none', maxWidth: '250px' }}>
              {username}
            </span>
          </Col>
          <Col className="flex items-center">
            <Icon path={mdiMenuDown} size={1} />
          </Col>
        </Row>
      </Dropdown>
    </div>
  );
}

export default UserDropdownPanel;

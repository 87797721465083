import { mdiBookmarkOffOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Card, Col, Row, Tooltip,
} from 'antd';
import { capitalize, get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { prop } from 'ramda';
import cn from 'classnames';

import './BookmarksStartPage.scss';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import ExpandModeLayout from '../../../../projectFlow/components/commonComponents/expandModeLayout/ExpandModeLayout';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import ProjectInfo from '../../../../projectFlow/components/project/projectInfo/ProjectInfo';
import WrapperVectorInfoCardWithComments from '../../../../projectFlow/components/vector/vectorInfo/WrapperVectorInfoCardWithComments';
import IssueInfoRoot from '../../../../projectFlow/components/issues/issueInfo/IssueInfoRoot';
import DocWraperForBookmark from '../DocWraperForBookmark';
import CustomCardComponent from '../../../../projectFlow/components/commonComponents/CustomCardComponent';
import useActivitiesDashboard from '../../../../projectFlow/components/commonComponents/activities/useActivitiesDashboard';

import { getIssue, getProjectRequest } from '../../../../projectFlow/actions/projectFlowActions';
import { entityPartialRead, entityRead } from '../../../../entity/actions/entityActions';
import { getUsersByUUID, deleteBookMark, getListBookMark } from '../../../store/action-creators/userActions';
import axiosRequest from '../../../../api/apiAxios';
import { ProjectTypeCommonConstants } from '../../../../projectFlow/constants/Constants';
import { fetchingChildrenParent } from '../../../../projectFlow/selectors/selectors';
import { getUuidsfromParamsUsers } from '../../../../MainUtils';
import { getOneDocument } from '../../../../projectFlow/actions/DocumentsActions';
import { clearDocCopy } from '../../../../projectFlow/actions/PtcActionsForHook';
import useActorsW54 from '../../../../actors/hooks/useActorsW54';
import WrapperAvatarsGroupForProject from '../../../../projectFlow/components/commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';
import BaseCard from "../../../../components/_ui/BaseCard/BaseCard";
import { partitionNamesConfig } from '../../../../api/appConfig';

const defaultNotSavedData = {
  notSaved: false,
  confirmNotSaved: false,
};

export default function BookmarksStartPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetching = useSelector(fetchingChildrenParent);

  const defaultConfigRequest = {
    // parent: actorMainPublicProjectUUID,
    limit: 15,
    offset: 0,
    search_query: '',
  };

  const [params, setParams] = useState(defaultConfigRequest);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [valueSearch, setValueSearch] = useState('');
  const [page, setCurrentPage] = useState(1);
  const [pageLimit, changePageLimit] = useState(15);
  const [fetchEmpty, setFetchEmpty] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentSelected, setCurrentSelected] = useState({});
  const [listChildren, setListChildren] = useState([]);
  const [notSavedData, setNotSavedData] = useState(defaultNotSavedData);
  // const [isDocument, setIsDocument] = useState(false);
  const [showEditor, setShowEditor] = useState(false);

  // Получение bookmarks пользователей
  const userUUIDs = listChildren?.flatMap((item) => getUuidsfromParamsUsers(item?.users));
  useActorsW54({ actors: userUUIDs });

  const {
    isSidePanelOpen,
  } = useActivitiesDashboard();

  const {
    entity_type: selectedEntityType = '',
    uuid: selectedUUID = '',
    partition: selectedPartition = '',
  } = selectedEntity || {};

  const setIsNotSavedDataCallback = (bool) => {
    setNotSavedData({
      ...notSavedData,
      notSaved: bool,
    });
  };

  const getChildrenBookMark = (uuids, partition) => {
    const data = {
      entity_uuids: uuids,
      params_fields: {
        id: 'id',
        title: 'title',
        type: 'type',
        status: 'status',
        users: 'users',
        project: 'project',
      },
      fields: {
        entity_type: 'entity_type',
        parent: 'parent',
      },
    };

    const constants = [
      ProjectTypeCommonConstants.LIST_CHILDREN_REQUEST,
      ProjectTypeCommonConstants.LIST_CHILDREN_SUCCESS,
      ProjectTypeCommonConstants.LIST_CHILDREN_FAILURE,
    ];

    const options = {
      partition,
      aborting: true,
      doNotCheckError: true,
    };

    return dispatch(entityPartialRead({
      data,
      constants,
      options,
    }));
  };

  const getBookmarks = async () => {
    const dataPartitionPM = await dispatch(getListBookMark(params, partitionNamesConfig.partition1));
    const dataPartitionPublic = await dispatch(getListBookMark(params, partitionNamesConfig.partition3));

    setTotalCount(get(dataPartitionPM, 'total'));
    // const affix_uuids = get(data, 'affix_data', [])
    let list1 = [];
    let list2 = [];
    const entityUUIDsPM = get(dataPartitionPM, 'entity_data', []).map((i) => i.uuid);
    const entityUUIDsPUBLIC = get(dataPartitionPublic, 'entity_data', []).map((i) => i.uuid);

    if (entityUUIDsPM.length) {
      setFetchEmpty(true);
      const response1 = await getChildrenBookMark(entityUUIDsPM, partitionNamesConfig.partition1)
        .catch((res) => {
          if (res.status === 404) return list1 = [];
        });

      list1 = response1.map((el) => ({ ...el, partition: partitionNamesConfig.partition1 }));

      if (entityUUIDsPM?.length > list1?.length) {
        list1 = [...list1,
          ...entityUUIDsPM.reduce((prev, cur) => {
            if (list1.every(({ uuid }) => cur !== uuid)) {
              return ([...prev, { title: 'Entity removed', uuid: cur, partition: partitionNamesConfig.partition1 }]);
            }
            return prev;
          }, []),
        ];
      }
    } else {
      setFetchEmpty(false);
    }

    if (entityUUIDsPUBLIC.length) {
      setFetchEmpty(true);
      const response2 = await getChildrenBookMark(entityUUIDsPUBLIC, partitionNamesConfig.partition3)
        .catch((res) => {
          if (res.status === 404) return list2 = [];
        });
      list2 = response2?.map((el) => ({ ...el, partition: partitionNamesConfig.partition3 }));

      if (entityUUIDsPUBLIC?.length > list2?.length) {
        list2 = [...list2,
          ...entityUUIDsPUBLIC.reduce((prev, cur) => {
            if (list2.every(({ uuid }) => cur !== uuid)) {
              return ([...prev, { title: 'Entity removed', uuid: cur, partition: partitionNamesConfig.partition3 }]);
            }
            return prev;
          }, []),
        ];
      }
    } else {
      setFetchEmpty(false);
    }

    setListChildren([...list1, ...list2]);
  };

  const afterDeleteABookmark = () => {
    setCurrentSelected({});
    setSelectedEntity({});
    getBookmarks();
    dispatch({ type: ProjectTypeCommonConstants.CLEAR_LIST_CHILDREN });
  };

  const deleteItem = async (e, uuid, partition) => {
    e.stopPropagation();
    dispatch(deleteBookMark({ entity_uuid: uuid }, partition)).then(() => {
      afterDeleteABookmark();
    });
  };

  const onSearchBookmark = (value) => {
    setValueSearch(value);
    setParams({
      // ...params,
      limit: 15,
      offset: 0,
      search_query: value,
    });
    setCurrentPage(1);
  };

  const searchInTitle = (
    <AntDesignSearchBox54origins
      onSearch={onSearchBookmark}
      valueSearch={valueSearch}
    />
  );

  const columns = [
    {
      dataIndex: ['title'],
      title: searchInTitle,
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
          maxLength={30}
        />
      ),
    },
    {
      dataIndex: 'entity_type',
      title: capitalize(t('wms.table.headers.type', 'type')),
      render: (cell) => cell && (
        <EntityParamTag
          value={cell}
          type="badge"
          param="entity"
        />
      ),
    },
    {
      dataIndex: ['status'],
      title: capitalize(t('wms.table.headers.status', 'status')),
      render: (cell) => cell && (
        <EntityParamTag
          param="status"
          value={cell}
          type="icon"
        />
      ),
    },
    {
      dataIndex: ['assigned_users'],
      title: capitalize(t('wms.table.headers.assigned', 'assigned')),
      render: (cell, row) => (
        <WrapperAvatarsGroupForProject
          checkInProject={false}
          actors={getUuidsfromParamsUsers(row?.users || [])}
          avatarSize="small"
          avatarGap={4}
          avatarStyles={{ cursor: 'pointer' }}
          groupButtonSize="small"
          checkProjectRole
        />
      ),
    },
    {
      dataIndex: 'uuid',
      title: capitalize(t('wms.table.headers.action', 'action')),
      render: (cell, row) => cell && (
        <Tooltip
          placement="top"
          title="Remove from bookmark"
        >

          <BaseButton
            id="link"
            shape="circle"
            className="view_btn_link__red"
            onClick={(e) => deleteItem(e, cell, get(row, 'partition'))}
          >
            <Icon
              path={mdiBookmarkOffOutline}
              size={0.8}
            />
          </BaseButton>

        </Tooltip>
      ),
    },
  ].filter((el) => !el.hidden);

  const rowClassName = (row) => {
    const data = `universal_table_row ${get(row, 'uuid') === selectedUUID
      ? 'selected'
      : ''
    }`;

    return data;
  };

  const rowSelection = {
    selectedRowKeys: [selectedUUID],
    hideSelectAll: true,
    renderCell: () => { },
  };

  const getClearVector = (uuid, partition) => {
    axiosRequest.abort('', ProjectTypeCommonConstants.GET_LIST_OF_ISSUES_REQUEST);
    axiosRequest.abort('', ProjectTypeCommonConstants.GET_VECTOR_REQUEST);

    const data = {
      entity_type: 'vector',
      entity_uuid: uuid,
    };

    const constants = [
      ProjectTypeCommonConstants.GET_VECTOR_REQUEST,
      ProjectTypeCommonConstants.GET_VECTOR_SUCCESS,
      ProjectTypeCommonConstants.GET_VECTOR_FAILURE,
    ];

    const options = {
      partition,
      aborting: true,
    };

    return dispatch(entityRead({
      data,
      constants,
      options,
    }));
  };

  const getDocument = (uuid, entityType, partition) => {
    dispatch(getOneDocument(uuid, entityType, partition));
  };

  const getClearProject = (uuid, defaultPartition) => new Promise((resolve) => {
    dispatch(
      getProjectRequest(
        uuid,
        (data) => resolve(data),
        defaultPartition,
      ),
    );
  });

  const getSelectEntity = (row) => {
    const {
      entity_type: entityTypeRow,
      uuid,
      partition: partitionRow,
    } = row || {};

    switch (entityTypeRow) {
      case 'project': {
        getClearProject(uuid, partitionRow);
        break;
      }
      case 'issue': {
        dispatch(getIssue({
          uuid,
          partition: partitionRow,
        }));
        break;
      }
      case 'vector': {
        getClearVector(uuid, partitionRow);
        break;
      }
      case 'projectIdea': {
        getDocument(uuid, 'projectIdea', partitionRow);
        break;
      }
      case 'projectDocument': {
        getDocument(uuid, 'projectDocument', partitionRow);
        break;
      }
      case 'projectSpreadsheet': {
        getDocument(uuid, 'projectSpreadsheet', partitionRow);
        break;
      }
      default:
        break;
    }
  };

  const onChangeTable = ({ pageLimit, currentPage, offset }) => {
    const newConfig = {
      ...params,
      limit: pageLimit,
      offset,
    };
    setParams(newConfig);

    if (page !== currentPage) {
      setCurrentPage(currentPage);
    }
  };

  const remotePagination = {
    sizePerPageList: [15, 50, 100],
    defaultPageLimit: 15,
    totalSize: totalCount,
    page,
    onChange: onChangeTable,
  };

  const onRow = (record) => ({
    onClick: async () => {
      setShowEditor(false);
      dispatch(clearDocCopy);
      setCurrentSelected(record);
      getSelectEntity(record);
      setSelectedEntity(record);
    },
  });

  const resetSelectedAndGetList = () => {
    setSelectedEntity({});
    getBookmarks();
  };

  const getProjectMembers = (uuid) => {
    dispatch(getUsersByUUID({
      uuid,
      userType: 'project',
    }));
  };

  const goToPermissionsCallback = () => navigate(`/pm/projects/settings?activeProject=${selectedUUID}`);

  const resetProjectCallback = () => setSelectedEntity({});

  useEffect(() => {
    getBookmarks();
    return () => {
      dispatch({ type: ProjectTypeCommonConstants.CLEAR_LIST_CHILDREN });
    };
  }, [JSON.stringify(params)]);

  return (
    <Col span={24} className="bookmarks-wrapper">
      <Row>
        <h4>{capitalize(t('wms.sidemenu.userflow.bookmarks', 'Bookmarks'))}</h4>
      </Row>
      <Row className="mt-2 bookmarks-content">
        <ExpandModeLayout localStorageTarget="bookmarks">
          <ExpandModeLayout.Table>
            <BaseCard className="primaryCard">
              <BaseTableWithPagination
                pageLimit={pageLimit}
                changePageLimit={changePageLimit}
                useCustomPagination
                columns={columns}
                data={listChildren}
                onRow={onRow}
                loading={fetchEmpty && fetching}
                total={prop('totalSize', remotePagination)}
                pageSizeOptions={prop('sizePerPageList', remotePagination)}
                newCurrentPage={prop('page', remotePagination)}
                getPaginationOptions={prop('onChange', remotePagination)}
                rowClassName={rowClassName}
                paginationSize="small"
                headerRowClassName="universal_header_table_row blue-calor"
              />
            </BaseCard>
          </ExpandModeLayout.Table>
          <ExpandModeLayout.Card>
            {selectedEntityType === 'project' && (
              <BaseCard className="p-0 borderR10 dangerCard">
                <ProjectInfo
                  notSavedData={notSavedData}
                  setNotSavedData={setNotSavedData}
                  setIsNotSavedDataCallback={setIsNotSavedDataCallback}
                  afterDeleteABookmark={afterDeleteABookmark}
                  partitionType={selectedPartition}
                  view="bookmark"
                  getProjectMembers={getProjectMembers}
                  deleteEntityCallback={resetSelectedAndGetList}
                  goToPermissionsCallback={goToPermissionsCallback}
                  resetProjectCallback={resetProjectCallback}
                />
                {/* <Col>
                  {!infoFetching
                  && (
                  <CommentsStartPage
                    partitionType={selectedPartition}
                    parentUUID={selectedUUID}
                  />
                  )}
                </Col> */}
              </BaseCard>
            )}

            {selectedEntityType === 'vector' && (
              <CustomCardComponent
                type="vector"
                style={{ minHeight: isSidePanelOpen ? '72vh' : 'unset' }}
              >
                <WrapperVectorInfoCardWithComments
                  afterDeleteABookmark={afterDeleteABookmark}
                  defaultTemplateState={getBookmarks}
                  viewMode="vector"
                  hiddenView={['close']}
                  restoreActionCallback={resetSelectedAndGetList}
                  deleteVectorCallback={resetSelectedAndGetList}
                  partitionType={selectedPartition}
                  customVectorUuid={get(currentSelected, 'uuid', '')}
                />
              </CustomCardComponent>
            )}

            {selectedEntityType === 'issue' && (
              <CustomCardComponent type="issue">
                <IssueInfoRoot
                  afterDeleteABookmark={afterDeleteABookmark}
                  hiddenView={[
                    'resetIssueBtn',
                    'linkToProject',
                  ]}
                  deleteCallback={resetSelectedAndGetList}
                  //   switchViewFunc={switchViewFunc}
                  updateCallback={getBookmarks}
                  restoreEntityCallback={resetSelectedAndGetList}
                  partitionType={selectedPartition}
                />
              </CustomCardComponent>
            )}

            {(selectedEntityType === 'projectIdea' || selectedEntityType === 'projectDocument' || selectedEntityType === 'projectSpreadsheet') && (
              <DocWraperForBookmark
                currentSelected={currentSelected}
                defaultPartition={selectedPartition}
                showEditor={showEditor}
                setShowEditor={setShowEditor}
              />
            )}
          </ExpandModeLayout.Card>
        </ExpandModeLayout>
      </Row>
    </Col>
  );
}

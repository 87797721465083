/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  error: '',
};

const mainUserProjectLogsSlice = createSlice({
  name: 'mainUserProjectLogs',
  initialState,
  reducers: {
    GET_OR_CREATE_MAIN_USER_PROJECT_LOGS_REQUEST: (state) => {
      state.isLoading = true;
    },
    GET_OR_CREATE_MAIN_USER_PROJECT_LOGS_SUCCESS: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = '';
    },
    GET_OR_CREATE_MAIN_USER_PROJECT_LOGS_FAILURE: (state, action) => {
      state.isLoading = false;
      state.data = '';
      state.error = action.payload;
    },
  },
});

export const mainUserProjectLogsActions = mainUserProjectLogsSlice.actions;

export default mainUserProjectLogsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from "reselect";

const initialState = {
  goalFetching: false,
  fetching: true,
  newGoalFetching: false,
  listOfGoals: [],
  newGoalData: [],
  goalData: [],
  totalGoals: 0,
};

const projectGoalsSlicer = createSlice({
  name: 'PROJECT_GOALS',
  initialState,
  reducers: {
    LIST_OF_PROJECT_GOALS_FETCHING(state) {
      state.fetching = true;
    },
    LIST_OF_PROJECT_GOALS_SUCCESS(state, action) {
      state.fetching = false;
      state.listOfGoals = action.payload;
      state.totalGoals = action.total;
    },
    LIST_OF_PROJECT_GOALS_FAILURE(state) {
      state.fetching = false;
      state.listOfGoals = [];
      state.listOfGoals = 0;
    },

    CREATE_PROJECT_GOAL_FETCHING(state) {
      state.newGoalFetching = true;
    },
    CREATE_PROJECT_GOAL_SUCCESS(state, action) {
      state.newGoalFetching = false;
      state.newGoalData = action.payload;
    },
    CREATE_PROJECT_GOAL_FAILURE(state) {
      state.newGoalFetching = false;
      state.newGoalData = [];
    },

    PROJECT_GOAL_FETCHING(state) {
      state.goalFetching = true;
    },
    PROJECT_GOAL_SUCCESS(state, action) {
      state.goalFetching = false;
      state.goalData = action.payload;
    },
    PROJECT_GOAL_FAILURE(state) {
      state.goalFetching = false;
      state.goalData = [];
    },

    CLEAR_ALL_PROJECT_GOALS(state) {
      state.goalFetching = false;
      state.fetching = true;
      state.newGoalFetching = false;
      state.listOfGoals = [];
      state.newGoalData = [];
      state.goalData = [];
      state.totalGoals = 0;
    },
    CLEAR_PROJECT_GOAL(state) {
      state.goalFetching = false;
      state.goalData = [];
    },
  },
});

const storeProjectPath = (state) => state?.projectManagementService?.project;

export const getProjectGoalsFetching = (state) => storeProjectPath(state)?.goals?.fetching;
export const getProjectGoals = (state) => storeProjectPath(state)?.goals?.listOfGoals || [];
export const getNewProjectGoalFetching = (state) => storeProjectPath(state)?.goals?.newGoalFetching;
export const getTotalGoals = (state) => storeProjectPath(state)?.goals?.totalGoals;

export const getProjectGoalFetching = (state) => storeProjectPath(state)?.goals?.goalFetching;
export const getProjectGoal = (state) => storeProjectPath(state)?.goals?.goalData || [];
export const getProjectGoalUUID = (state) => storeProjectPath(state)?.goals?.goalData[0]?.uuid;
// export const selectLastCreatedGoal = (state) => {
//   const goals = getProjectGoals(state);
//   if (goals.length === 0) return {};
//   return goals[0];
// };

export const selectLastCreatedGoal = createSelector(getProjectGoals, (goals) => goals?.length === 0 ? {} : goals[0])

export const {
  LIST_OF_PROJECT_GOALS_FETCHING,
  LIST_OF_PROJECT_GOALS_SUCCESS,
  LIST_OF_PROJECT_GOALS_FAILURE,
  CREATE_PROJECT_GOAL_FETCHING,
  CREATE_PROJECT_GOAL_SUCCESS,
  CREATE_PROJECT_GOAL_FAILURE,
  PROJECT_GOAL_FETCHING,
  PROJECT_GOAL_SUCCESS,
  PROJECT_GOAL_FAILURE,
  CLEAR_ALL_PROJECT_GOALS,
  CLEAR_PROJECT_GOAL,
} = projectGoalsSlicer.actions;

export default projectGoalsSlicer.reducer;

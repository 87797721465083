import React, { useContext, useRef, useState } from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { capitalize, difference, get } from 'lodash';
import cn from 'classnames';

import { Space, Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiArrowCollapseLeft, mdiArrowCollapseRight } from '@mdi/js';
import { useSelector, useDispatch } from 'react-redux';
import RemoveTestSuite from './RemoveTestSuite';
import RestoreEntityTypeBtn from '../../archiveState/RestoreEntityTypeBtn';
import QaCollapseControlBtn from '../QaCollapseControlBtn';

import { BugTrackerContext, MainCollapseSectionContext, ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import HeaderCard from '../../commonComponents/HeaderCard';
import CommonInfo from '../../commonComponents/CommonInfo';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import EditButton from '../../commonComponents/EditButton';
import SpecialTextArea from '../../commonComponents/SpecialTextArea/SpecialTextArea';
import { defaultStateBtn } from '../../issuesView/utils';
import { getProject } from '../../../selectors/selectors';
import { setGlobalUpdateEntity } from '../../../reducers/slicers/breadcrumbsSlicer';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import ExpandButton from '../../../../components/ExpandButton';
import CopyURL from '../../../../components/CopyURL';
import { partitionNamesConfig } from '../../../../api/appConfig';

function MainTestSuites({
  testSuite, 
  partition,
  entityType = 'testSuite',
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    sendAssignedNotifications,
  } = useContext(ProjectMainLayerContext);

  const {
    changeQAHistory,
    partitionType,
    updateTestSuitePromise,
    getListOfTestSuites,
    qaEntityParams
  } = useContext(BugTrackerContext);

  const {
    toggleReset,
    toggleHorizontal,
    getCurrentHorizontalCollapseState,
  } = useContext(MainCollapseSectionContext);

  const currentHorizontalCollapseState = getCurrentHorizontalCollapseState('mainSuiteCollapse');

  const selectedProject = useSelector(getProject);

  const refDescription = useRef({});
  const refTitle = useRef({});

  const [collapse, setCollapse] = useState(false);

  const [stateBtnTitle, setStateBtnTitle] = useState(defaultStateBtn);
  const [stateBtnDescription, setStateBtnDescription] = useState(defaultStateBtn);

  const [isEditAssignUsers, setEditAssignUsers] = useState(false);

  const onChangeData = (newParams) => {
    const data = {
      uuid: get(testSuite, 'uuid', ''),
      params: newParams,
    };

    updateTestSuitePromise(data).then((res) => {
      // console.log('res', res)
      const assignedUsers = get(data, 'params.assignToUser');

      if (assignedUsers) {
        const receiver = difference(
          assignedUsers,
          get(testSuite, 'params.assignToUser', []),
        );
        sendAssignedNotifications({
          entity_type: 'testSuite',
          assignedUsers: receiver,
          eventName: 'assigned_test_suite',
          entityUUID: get(res, '[0].uuid'),
          message: get(res, '[0].params.title', ''),
          entityUUIDsFields: {
            testSuiteUUID: get(res, '[0].uuid'),
            projectUUID: get(selectedProject, '[0].uuid'),
          },
        });
      }
      if (getListOfTestSuites) {
        getListOfTestSuites();
      }
    });
  };

  const setIsEdit = (e, closeEdit) => {
    const { id } = e.currentTarget;
    switch (id) {
      case 'description':
        if (stateBtnDescription.isEdit && !closeEdit) {
          onChangeData({ description: refDescription.current?.trim() });
        }
        refDescription.current = get(testSuite, 'params.description', '');
        setStateBtnDescription((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'title':
        if (stateBtnTitle.isEdit && !closeEdit) {
          onChangeData({ title: refTitle.current?.trim() });
        }
        refTitle.current = get(testSuite, 'params.title', '');
        setStateBtnTitle((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      default: break;
    }
  };

  const onChangeTextArea = ({ id, value }) => {
    switch (id) {
      case 'title':
        refTitle.current = value;
        if (!stateBtnTitle?.isValid || !value.trim()?.length) {
          setStateBtnTitle((prev) => ({ ...prev, isValid: value.trim()?.length }));
        }
        break;
      case 'description':
        refDescription.current = value;
        if (!stateBtnDescription?.isValid) {
          setStateBtnDescription((prev) => ({ ...prev, isValid: true }));
        }
        break;
      default: break;
    }
  };

  const onChangeAssignedUsers = (data) => {
    const { users } = data;
    onChangeData({ assignToUser: users?.map((el) => el?.uuid) });
  };

  const deleteTestSuite = () => {
    changeQAHistory({ entity: 'clearSuite', clearCases: true });
    dispatch(setGlobalUpdateEntity({ entityType: 'testSuite', action: 'delete', uuid: get(testSuite, 'uuid', '') }));
  };

  const toggleCollapse = () => setCollapse((prev) => !prev);

  const createdBy = `${capitalize(get(testSuite, 'uinfo.first_name', ''))} ${get(testSuite, 'uinfo.last_name', '')}`;

  const subTitle = (
    <div className="flex items-center w-full gap5">
      <CommonInfo
        creator={createdBy}
        createdDate={get(testSuite, 'created', '')}
      />

      <CopyURL
        partition={partitionType}
        entityType={entityType}
        entityUUID={get(testSuite, 'uuid', '')}
        qaEntityParent={get(selectedProject, '[0].uuid')}
      />

      <Tooltip title="Resize">
        <BaseButton
          size="small"
          type="primary"
          ghost
          onClick={toggleHorizontal}
        >
          <Icon
            size={0.9}
            path={currentHorizontalCollapseState
              ? mdiArrowCollapseRight
              : mdiArrowCollapseLeft}
          />
        </BaseButton>
      </Tooltip>
    </div>
  );

  const beforeTitle = (
    <ExpandButton
      toggleExpandedMode={toggleHorizontal}
      isExpanded={currentHorizontalCollapseState}
    />
  );

  const headerButtons = (
    <Space
      align="center"
      size={[5, 5]}
    >
      <RestoreEntityTypeBtn
        className="px-2 ml-1"
        archiveEntityUUID={get(testSuite, 'uuid', '')}
      />

      <RemoveTestSuite
        className="ml-1 btnDanger-outline"
        entityUUID={get(testSuite, 'uuid', '')}
        actionForEntityCallback={deleteTestSuite}
      />
    </Space>
  );

  return (
    <div className="relative">
      <HeaderCard
        title={capitalize(t('wms.projectflow.qa.test_suite'))}
        spanTitleSection={15}
        spanDescriptionSection={9}
        subTitle={subTitle}
        extra={headerButtons}
        backCallback={toggleReset}
        beforeTitle={beforeTitle}
      />
      <hr className="mt-1 mb-2" />

      <div className="pl-1">

        <div className="flex items-center">
          <div className="wrapper-title pb-0 flex items-center">
            <EditButton
              id="title"
              isEditFlag={stateBtnTitle.isEdit}
              disabled={!stateBtnTitle.isValid && stateBtnTitle.isEdit}
              onClick={setIsEdit}
            />
            {`${capitalize(t('wms.labels.title', 'Title'))}:`}
          </div>
          <div className="grow wrapper-description pt-0 pl-0 ml-2">
            <SpecialTextArea
              id="title"
              placeholder="Enter title"
              maxLength={128}
              defaultValue={get(testSuite, 'params.title', '-')}
              isEdit={stateBtnTitle.isEdit}
              onChange={onChangeTextArea}
            />
          </div>
        </div>

        {collapse && (
          <>
            <hr className="my-2" />

            <div className="flex items-center w-full">
              <div className="wrapper-title pb-0 flex items-center">
                {/* <AssignedUsersRoot
                // disabled={disabledFull}
                projectUUID={get(selectedProject, '[0].uuid')}
                partitionType={partitionType}
                onChangeData={onChangeAssignedUsers}
                defaultUsers={get(testSuite, 'params.assignToUser', [])}
              /> */}
                <EditButton
                  onClick={() => setEditAssignUsers(true)}
                />
                <AssigningUsersRoot
                  header="Assigning users"
                  isOpen={isEditAssignUsers}
                  setIsOpen={setEditAssignUsers}
                  onUpdateUsers={onChangeAssignedUsers}
                  needUpdateProjectUsers
                  placement="left"
                  isModal
                  globalOptions={{
                    uuids: get(selectedProject, '[0].params.usersSearch', []),
                    defaultColumn: 'project',
                  }}
                  tabs={{
                    allConfig: {
                      label: 'All',
                      value: 'all',
                      textSelectButton: 'Add to project/suite',
                      showSections: ['button-add-user'],
                    },
                    projectConfig: {
                      label: 'Project',
                      value: 'project',
                      textSelectButton: 'Add to suite',
                      defaultItems: get(selectedProject, '[0].params.users', []),
                      showSections: ['string-role', 'button-add-user'],
                    },
                  }}
                  usersConfig={{
                    label: 'Suite users',
                    showSections: ['string-role', 'button-remove-user'],
                    textDeleteButton: 'Remove users',
                    defaultItems: get(testSuite, 'params.assignToUser', []),
                  }}
                  projectUuid={get(selectedProject, '[0].uuid', '')}
                  partition={partitionType}
                />
                {`${capitalize(t('wms.labels.assigned_users'))}:`}
              </div>
              <div className="grow wrapper-description ml-2">
                <WrapperAvatarsGroupForProjectWithHook
                  actors={get(testSuite, 'params.assignToUser', [])}
                  avatarSize={28}
                  popoverTrigger="click"
                  popoverPlacement="right"
                />
              </div>
            </div>

            <hr className="my-2" />

            <div className="flex  flex-col items-start w-full mb-2">
              <div className="wrapper-title pb-0 flex items-center">
                <EditButton
                  id="description"
                  isEditFlag={stateBtnDescription.isEdit}
                  disabled={!stateBtnDescription.isValid && stateBtnDescription.isEdit}
                  onClick={setIsEdit}
                />
                {`${capitalize(t('wms.labels.description', 'description'))}:`}
              </div>
              <div className={cn('grow wrapper-description pl-0 w-full', { 'mt-1': stateBtnDescription.isEdit })}>
                <SpecialTextArea
                  id="description"
                  className={cn({ 'min-height-50': stateBtnDescription.isEdit, 'min-h-0': !stateBtnDescription.isEdit })}
                  defaultValue={get(testSuite, 'params.description', '-')}
                  isEdit={stateBtnDescription.isEdit}
                  onChange={onChangeTextArea}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <QaCollapseControlBtn
        toggleCollapse={toggleCollapse}
        collapse={collapse}
      />
    </div>
  );
}

MainTestSuites.propTypes = {
  testSuite: object,
};

export default MainTestSuites;

import React from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { mdiLogout } from '@mdi/js';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import { getCurrentArchiveStateUUID } from '../../selectors/archiveStateSelectors';
import { ProjectTypeCommonConstants } from '../../constants/Constants';

function ArchiveStateAlert() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const curArchiveStateUUID = useSelector((state) => getCurrentArchiveStateUUID(state));
  const resetArchiveState = () => dispatch({ type: ProjectTypeCommonConstants.CLEAR_ARCHIVE_ENTITY });

  const styleForAlert = {
    backgroundColor: '#fffbe6',
    border: '1px solid #ffe58f',
    borderRadius: '4px',
  };

  return curArchiveStateUUID
      && (
      <Col span={24} className="mb-4" style={{ flex: "0 0 auto" }}>
        <Row
          style={styleForAlert}
          type="flex"
          className="p-3"
          justify="space-between"
          align="middle"
        >
          <Col>
            <span>{t('wms.labels.archive_alert')}</span>
          </Col>
          <Col>
            <BaseButton
              className="ml-auto customAntOutWarningBtn"
              onClick={resetArchiveState}
            >
              <Icon
                path={mdiLogout}
                size={0.8}
              />
              <span className="ml-1">
                {t('wms.noun.exit')}
              </span>
            </BaseButton>
          </Col>
        </Row>
      </Col>
      );
}

export default ArchiveStateAlert;

import { assoc } from 'ramda';
import { ProjectTypeCommonConstants } from '../constants/Constants';

const initialState = {
  fetching: true,
  fetchingDefault: false,
  data: [],
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ProjectTypeCommonConstants.GET_VECTOR_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchingDefault: true,
      };
    case ProjectTypeCommonConstants.GET_VECTOR_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetchingDefault: false,
        data: action.payload,
      };
    case ProjectTypeCommonConstants.GET_VECTOR_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchingDefault: false,
        data: [],
        error: action.payload,
      };
    case ProjectTypeCommonConstants.EDIT_VECTOR_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchingDefault: true,
      };
    case ProjectTypeCommonConstants.EDIT_VECTOR_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetchingDefault: false,
        data: action.payload,
      };
    case ProjectTypeCommonConstants.EDIT_VECTOR_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchingDefault: false,
        data: [],
        error: action.payload,
      };
    case ProjectTypeCommonConstants.CLEAR_VECTOR:
      return {
        ...state,
        data: [],
        fetching: true,
        fetchingDefault: false,
        // ...initialState
      };

    default: return state;
  }
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

import './RadioButtons.scss';

import Icon from '@mdi/react';
import { mdiInfinity } from '@mdi/js';
import { capitalize } from 'lodash';

function RadioButtons({
  className,
  defaultValue,
  onChange,
  options,
  value,
  flexStyle,
  setIcons,
  disabled = false,
}) {
  const { t } = useTranslation();
  const [currentValue, changeCurrentValue] = useState(value || defaultValue);
  // const currentValue = options.some(item => item.value === value) ? value : defaultValue;
  const setStateAndReturnCallback = (e) => {
    const { value: newValue } = e.target;
    e.stopPropagation();
    changeCurrentValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  const getAfter = (infoAfter) => {
    if (!infoAfter) {
      return null;
    }
    if (infoAfter === '0') {
      return (
        <span
          className="ml-1"
          style={{
            // fontSize: '25px',
          }}
        >
          (
          <Icon path={mdiInfinity} size={0.8} />
          )
        </span>
      );
    }
    return (
      <span className="ml-1">
        (
        {infoAfter}
        )
      </span>
    );
  };

  useEffect(() => {
    changeCurrentValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="radioButtons">
      <Radio.Group
        size="small"
        style={flexStyle || {}}
        disabled={disabled}
        onChange={setStateAndReturnCallback}
        value={value || currentValue}
      >
        {options.map((item) => (
          <Radio
            id={item.value[0] + item.value.split(' ').map(el => el[0].toUpperCase() + el.slice(1)).join('').slice(1) + "Button"}
            key={item.label}
            value={item.value}
            className={className || item.className || ''}
          >
            {setIcons
              && (
                <Icon
                  className="mr-1"
                  path={item.icon}
                  size={0.8}
                />
              )}
            {capitalize(t(item.label))}
            {getAfter(item?.after)}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
}

export default RadioButtons;

RadioButtons.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string,
  flexStyle: PropTypes.object,
  setIcons: PropTypes.bool,
  disabled: PropTypes.bool,
};

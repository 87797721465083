// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2024.04.19 || YYYY/MM/DD

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Typography,
  Descriptions,
  Row,
  Skeleton,
  Spin,
} from 'antd';
import { capitalize } from 'lodash';
import {
  mdiAccountDetailsOutline,
  mdiAccountGroupOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';
import axios from 'axios';

import TagsGroupNames54origins, { ActorListOfTags } from './TagsGroupNames54origins';

import { getNameForActor } from '../utils54origins';
import { getCookie } from '../cookies54origins';
import useLocalTranslateForComponent from '../hooks/useLocalTranslateForComponent';
import BaseCard from "../../components/_ui/BaseCard/BaseCard";

const { Title } = Typography;

function InfoBlock({
  children,
  title = '',
  iconPath,
  className = '',
}) {
  return (
    <Row gutter={[8, 8]} className={className}>
      <Col>
        <Icon
          path={iconPath}
          size={1}
        />
      </Col>
      <Col flex="auto">
        <p>
          {title}
          :
        </p>
        <div>
          {children}
        </div>
      </Col>
    </Row>
  );
}

const descriptionColor = {
  color: '#1890ff',
};

const mainDivStyle = {
  width: '100%',
  padding: '25px',
};

const profileLabelColor = {
  color: '#595959',
};

const actorsNameStyle = {
  width: '50%',
  style: {
    margin: 0,
    height: 24,
  },
};

const translateForProfilePage = {
  userInfo: ['user info', 'информация о пользователе'],
  missing: ['missing', 'отсутствует'],
  userDetail: ['user detail', 'сведения о пользователе'],
  firstName: ['first name', 'имя'],
  lastName: ['last name', 'фамилия'],
  email: ['email', 'эл-почта'],
  birthday: ['birthday', 'день рождения'],
  created: ['account created', 'аккаунт создан'],
  userGroups: ['user groups', 'группы юзера'],
  login: ['login', 'логин'],
  phone: ['phone', 'номер телефона'],
};

function ProfilePage54origins({ url }) {
  const { checkLangAndGetTranslate } = useLocalTranslateForComponent(translateForProfilePage);

  const [actorInfo, setActorInfo] = useState({});

  const {
    uuid,
    group_names: userGroups,
    created,
    uinfo: {
      birthday,
      email,
      first_name,
      internal_user,
      last_name,
      login,
      phone_number,
    } = {},
  } = actorInfo || {};

  const getDescriptionText = (text) => (
    text ? (
      <span style={descriptionColor}>
        {text}
      </span>
    ) : checkLangAndGetTranslate('missing')
  );

  const getActorInfo = () => {
    axios({
      method: 'post',
      url: `${url}/utility/actor/me`,
      headers: {
        'Session-Token': getCookie('Entity'),
      },
    })
      .then(({ data }) => {
        setActorInfo(data);
      });
  };

  const descriptionItem = (descriptionText, keyForTranslate) => (
    <Descriptions.Item
      label={checkLangAndGetTranslate(keyForTranslate)}
      labelStyle={{ width: '33%' }}
    >
      {getDescriptionText(descriptionText)}
    </Descriptions.Item>
  );

  useEffect(() => {
    getActorInfo();
  }, []);

  return (
    <div
      style={mainDivStyle}
    >
      <Row>
        <Col
          span={24}
        >
          <h4
            style={profileLabelColor}
          >
            Profile
          </h4>
        </Col>
      </Row>
      <Row gutter={[24, 16]} className="mt-3">
        <Col span={14}>
          <Spin
            spinning={false}
          >
            <BaseCard>
              <Row gutter={[16, 0]}>
                <Col>
                  <h5>
                    {checkLangAndGetTranslate('userInfo')}
                  </h5>
                </Col>
              </Row>
              <hr className="my-4" />
              <Row gutter={[8, 8]}>
                <Col>
                  <Skeleton
                    loading={false}
                    paragraph={false}
                    title={actorsNameStyle}
                    active
                    round
                  >
                    <Title level={5}>
                      {capitalize(getNameForActor(actorInfo))}
                    </Title>
                  </Skeleton>
                </Col>
                <Col>
                  <ActorListOfTags actor={actorInfo} />
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col>
                  UUID:
                </Col>
                <Col>
                  <Title
                    copyable
                    level={5}
                    type="secondary"
                  >
                    {uuid}
                  </Title>
                </Col>
              </Row>
              <hr />
              <InfoBlock
                iconPath={mdiAccountDetailsOutline}
                title={checkLangAndGetTranslate('userDetail')}
              >
                <Row>
                  <Col span={24}>
                    <Descriptions
                      bordered
                      column={1}
                    >
                      {descriptionItem(first_name, 'firstName')}
                      {descriptionItem(last_name, 'lastName')}
                      {descriptionItem(login, 'login')}
                      {descriptionItem(email, 'email')}
                      {descriptionItem(birthday
                        ? moment(birthday).format('DD-MM-YYYY')
                        : checkLangAndGetTranslate('missing', false), 'birthday')}
                      {descriptionItem(phone_number, 'phone')}
                      {descriptionItem(moment(created).format('DD-MM-YYYY | HH:mm'), 'created')}
                    </Descriptions>
                  </Col>
                </Row>
              </InfoBlock>
              {userGroups && (
                <InfoBlock
                  title={checkLangAndGetTranslate('userGroups')}
                  iconPath={mdiAccountGroupOutline}
                  className="mt-2"
                >
                  <TagsGroupNames54origins groups={userGroups} />
                </InfoBlock>
              )}
            </BaseCard>
          </Spin>
        </Col>
      </Row>
    </div>
  );
}

export default ProfilePage54origins;

InfoBlock.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  iconPath: PropTypes.any,
  title: PropTypes.string,
};

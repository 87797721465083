import { Layout } from "antd";
import cn from "classnames";
import PropTypes from "prop-types";
import './BaseHeader.scss';

const { Header} = Layout;

function BaseHeader({children, className, fixed, headerHeight, ...props}) {
  return (
    <Header
      className={cn('base-header', className, fixed? 'fixed' : '',)}
      style={{
        height: `${headerHeight}px`
      }}
      {...props}
    >
      {children}
    </Header>
  )
}

export default BaseHeader;

BaseHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  headerHeight: PropTypes.number,
  fixed: PropTypes.bool,
};


import { EntityConstants } from '../constants/Constants';

const initialState = {
  fetching: true,
  user: [],
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EntityConstants.FETCH_USER_INFO_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case EntityConstants.FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: action.payload,
      };
    case EntityConstants.FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        fetching: false,
        user: [],
        error: action.payload,
      };

    default: return state;
  }
};

import React, { useRef, useState } from 'react';
import { Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiMicrophoneOutline, mdiRecord } from '@mdi/js';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

let timerRecord;

function IssueRecordVoiceDescriptionBtn({
  getAudioFileCallback,
  currentListAudioLength,
  disabled,
}) {
  const { t } = useTranslation();

  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);

  const [startRecord, setStartRecord] = useState(false);

  const titleTooltip = startRecord
    ? t('wms.tooltips.audio.stop', 'Release to stop recording')
    : t('wms.tooltips.audio.start', 'Click and hold to record');

  const titleRecord = startRecord
    ? `${capitalize(t('wms.audio.record.recording', 'recording'))}...`
    : capitalize(t('wms.audio.record.start', 'start record'));

  const onRecordMouseDown = () => {
    timerRecord = setTimeout(() => {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          setStartRecord(true);

          const options = {
            audioBitsPerSecond: 32000,
            bitsPerSecond: 32000,
          };

          mediaRecorder.current = new MediaRecorder(stream, options);
          mediaRecorder.current.start();

          mediaRecorder.current.ondataavailable = async (e) => {
            audioChunks.current.push(e.data);

            if (mediaRecorder.current.state === 'inactive') {
              const newAudio = new File(
                audioChunks.current,
                `audio-${currentListAudioLength + 1}.${mediaRecorder.current.mimeType.match(/\/([\w\d]+);?/)?.[1] || 'webm'}`,
                { type: mediaRecorder.current.mimeType || ' audio/webm;codecs=opus' },
              );

              // const newAudioList = [...currentListAudio, newAudio];
              getAudioFileCallback(newAudio);

              audioChunks.current = [];
            }
          };
        });
    }, 100);
  };

  const onRecordFinish = () => {
    mediaRecorder.current?.stop();
    mediaRecorder.current?.stream?.getTracks()?.forEach((track) => track?.stop());
    clearTimeout(timerRecord);
    setStartRecord(false);
  };

  return (
    <BaseButton
      id="recordVoiceButton"
      size="small"
      className={`ml-2 ${startRecord ? 'btnDanger-outline' : 'btnPrimary-outline'}`}
      onMouseDown={onRecordMouseDown}
      onMouseUp={onRecordFinish}
      onMouseLeave={onRecordFinish}
      disabled={disabled}
    >

      <Tooltip title={titleTooltip} placement="right">
        <Icon
          className={startRecord && 'blink'}
          path={startRecord ? mdiRecord : mdiMicrophoneOutline}
          size={0.8}
        />
        <span className="pl-1">
          {startRecord ? 'Recording..' : 'Record voice' || titleRecord}
        </span>
      </Tooltip>
    </BaseButton>
  );
}

export default IssueRecordVoiceDescriptionBtn;

import React, {
  useLayoutEffect, useRef, useState,
} from 'react';
import Icon from '@mdi/react';
import {
  mdiArrowLeftDropCircleOutline, mdiFullscreen, mdiFullscreenExit, mdiRestart, mdiSquareEditOutline,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { Button, Pagination, Spin } from 'antd';
import {
  get, isEmpty, isNil, uniq,
} from 'lodash';
import cn from 'classnames';

import './multiEdit.scss';

import { useDispatch, useSelector } from 'react-redux';
import {
  array, func, number, string,
} from 'prop-types';

import moment from 'moment';
import {
  antNotification, getUuidsfromParamsUsers,
} from '../../../MainUtils';
import { capitalize } from 'lodash';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import JSpreadsheet from '../../../../../JSpreadsheet/JSpreadsheet';
import { entityUpdate } from '../../../entity/actions/entityActions';
import {
  columnsMultiEditVectors,
  indexesSheet,
  prepereUsersForRequest,
  priorityKeys,
  typeKeys,
  vectorStatusKeys,
  entityScoringMultiUpdate,
} from './utils';
import { getProjectParams, getVectorsScoringData } from '../../selectors/selectors';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import FullscreenButton from '../../../components/fullscreenButton/FullscreenButton';
import useActorsW54 from '../../../actors/hooks/useActorsW54';
import { fullName } from '../../../assignUsersModal/utils';

export default function MultiEditVectorView({
  currentPage,
  totalCount,
  defaultData,
  pageLimit,
  partitionType,
  backEditCallback,
  getIssuesRequestSetting,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectParams = useSelector(getProjectParams);
  const vectorsScoringData = useSelector(getVectorsScoringData);

  const rowsIsEdited = useRef(new Map([]));

  const [isFullscreen, setFullscreen] = useState(false);

  const [dataSheet, setDataSheet] = useState([]);
  const [columns, setColumns] = useState([]);
  const [styleCells, setStyleCells] = useState({});

  const [loading, setLoading] = useState(false);

  const { users: allActors } = useActorsW54({ actors: projectParams?.usersSearch });

  const onChangePage = (page) => {
    const offset = pageLimit * (page - 1);
    getIssuesRequestSetting?.({
      pageLimit,
      currentPage: page,
      offset,
    });
  };

  const prepareData = () => {
    setDataSheet(defaultData.map(({
      id, title, description, priority, type, status, users, lifeTime, uuid,
    }) => {
      const currentUsers = getUuidsfromParamsUsers(users);
      return {
        id,
        title,
        description,
        priority,
        type,
        status,
        users: currentUsers,
        lifeTime,
        uuid,
      };
    }));
  };

  const nameColumn = {
    1: 'title',
    2: 'description',
    3: 'priority',
    4: 'type',
    5: 'status',
    6: 'users',
    7: 'lifeTime',
  };

  const onBeforeChangeCallback = (instance, x, y, value) => {
    // ======== VALIDATION DATA =========
    const row = instance.getJson()[y];
    const prevDataCell = row[nameColumn[x]];

    if (!row?.uuid) {
      return '';
    }

    if (nameColumn[x] === 'title') {
      return value?.slice(0, 128);
    }

    if (nameColumn[x] === 'priority' && !priorityKeys.includes(value)) {
      return prevDataCell;
    }

    if (nameColumn[x] === 'type' && !typeKeys.includes(value)) {
      return prevDataCell;
    }

    if (nameColumn[x] === 'status' && !vectorStatusKeys.includes(value)) {
      return prevDataCell;
    }

    if (nameColumn[x] === 'users') {
      const usersUUIDS = typeof value === 'string' ? value.split(';') : value;
      return get(projectParams, 'usersSearch', []).some((i) => usersUUIDS.includes(i)) ? value : prevDataCell;
    }

    if (nameColumn[x] === 'lifeTime' && !moment(value, 'YYYY-MM-DD').isValid()) {
      return prevDataCell;
    }

    if (!nameColumn[x]) {
      return '';
    }

    return value;
  };

  const changeSheetCallback = (data, columnIndex, rowIndex) => {
    if (rowsIsEdited.current.has(+rowIndex)) {
      const prevColumn = rowsIsEdited.current.get(+rowIndex);
      rowsIsEdited.current.set(+rowIndex, uniq([...prevColumn, +columnIndex]));
    } else {
      rowsIsEdited.current.set(+rowIndex, [+columnIndex]);
    }

    setStyleCells((prev) => ({ ...prev, [`${indexesSheet[columnIndex]}${+rowIndex + 1}`]: 'background-color: #ffe7ba;' }));
    setDataSheet(data);
  };

  const updateRequest = (data) => {
    const constants = [
      'MULTI_EDIT_VECTOR_UPDATE_REQUEST',
      'MULTI_EDIT_VECTOR_UPDATE_SUCCESS',
      'MULTI_EDIT_VECTOR_UPDATE_FAILURE',
    ];

    const options = {
      partition: partitionType,
    };

    return dispatch(entityUpdate({
      data,
      constants,
      options,
    }));
  };

  const getDataForParams = (row, indexRow) => {
    const columsNeedUpdate = rowsIsEdited.current.get(indexRow);
    const data = {};

    columsNeedUpdate.forEach((idx) => {
      switch (idx) {
        case 1: data.title = row?.title; break;
        case 2: data.description = row?.description; break;
        case 3: data.priority = row?.priority; break;
        case 4: data.type = row?.type; break;
        case 5: data.status = row?.status; break;
        case 6: {
          const { usersData, usersSearch } = prepereUsersForRequest(row?.users);
          data.users = usersData;
          data.usersSearch = usersSearch;
        }
          break;
        case 7: data.lifeTime = row?.lifeTime; break;
        default: break;
      }
    });

    return data;
  };

  const updateVectorsScoring = (newData, defaultData) => {
    try {
      const oldEntities = defaultData
        .filter((defEntity) => newData?.find((newEntity) => newEntity?.uuid === defEntity?.uuid));
      const newScoringData = entityScoringMultiUpdate(oldEntities, newData, vectorsScoringData);
      if (newScoringData) {
        dispatch({
          type: ProjectTypeCommonConstants.UPDATE_VECTORS_PRIORITY_INDEXES_DATA,
          payload: newScoringData,
        });
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const toggleSave = async () => {
    setLoading(true);
    try {
      const vectorsForUpdate = [];
      dataSheet.forEach((item, index) => {
        if (rowsIsEdited.current.has(+index)) {
          const newParams = getDataForParams(item, index);
          const preparedBody = {
            entity_type: 'vector',
            entity_uuid: item?.uuid,
            params: newParams,
          };
          if (item?.uuid && !isEmpty(newParams)) {
            vectorsForUpdate.push(updateRequest(preparedBody));
          }
        }
      });

      const results = await Promise.allSettled(vectorsForUpdate);
      const isReject = results.find((result) => result.status === 'rejected');
      if (isReject) {
        antNotification('error', isReject.value);
      }

      rowsIsEdited.current = new Map([]);
      const newEntitiesData = results
        .map((item) => item?.value[0]).map((item) => ({ uuid: item?.uuid, ...item?.params }));
      updateVectorsScoring(newEntitiesData, defaultData);
      backEditCallback();
    } catch (e) {
      antNotification('error', e);
    }
    setLoading(false);
  };

  const initFunc = () => {
    rowsIsEdited.current = new Map([]);
    setStyleCells({});

    if (!isEmpty(defaultData)) {
      prepareData();
    } else {
      setDataSheet([]);
    }
  };

  useLayoutEffect(() => {
    if (!isEmpty(allActors) && !isEmpty(projectParams)) {
      const actors = projectParams?.usersSearch?.map((uuid) => {
        const actor = allActors?.find((el) => el?.uuid === uuid);
        if (actor) {
          return {
            id: actor?.uuid,
            name: fullName(actor),
          };
        }
        return null;
      }).filter((el) => !isNil(el));

      setColumns(
        columnsMultiEditVectors.map((i) => {
          if (i?.name === 'users') {
            return {
              ...i,
              type: 'dropdown',
              source: actors,
            };
          }
          return i;
        }),
      );
    }
  }, [columnsMultiEditVectors, allActors, projectParams?.usersSearch]);

  useLayoutEffect(() => {
    initFunc();
  }, [defaultData]);

  return (
    <Spin spinning={loading} className="h-full w-full">
      <div className="w-full relative">
        <hr />
        <div className={cn({ 'multi-edit-full-screen': isFullscreen })}>
          <div className={cn('flex items-center justify-between mb-2', { 'py-3': isFullscreen })}>
            <div className="flex items-center flex-wrap ">
              <BaseButton
                size="small"
                className="btnWarning-outline mr-1"
                onClick={backEditCallback}
                type="link"
              >
                <div className="flex items-center justify-center ">
                  <Icon
                    className="mr-1"
                    path={mdiArrowLeftDropCircleOutline}
                    size={0.8}
                  />
                  {capitalize(t('wms.buttons.back', 'back'))}
                </div>
              </BaseButton>
              <span className="m-0 title-info-card mx-1">
                Multi edit vectors:
              </span>
            </div>
            <div className="flex items-center">
              <FullscreenButton
                isFullscreen={isFullscreen}
                setFullscreen={setFullscreen}
              />

              <BaseButton
                size="small"
                className="btnWarning-outline mr-1 ml-1"
                onClick={initFunc}
                disabled={isEmpty(styleCells)}
                type="link"
              >
                <div className="flex items-center justify-center ">
                  <Icon
                    className="mr-1"
                    path={mdiRestart}
                    size={0.8}
                  />
                  {capitalize(t('wms.buttons.reset', 'reset'))}
                </div>
              </BaseButton>

              <BaseButton
                id="saveIssueBtn"
                size="small"
                onClick={toggleSave}
                type="primary"
                disabled={isEmpty(styleCells)}
              >
                <Icon path={mdiSquareEditOutline} size={0.7} />
                <span className="pl-1">
                  {capitalize(t('wms.verb.save', 'save'))}
                </span>
              </BaseButton>

            </div>

          </div>
          <div className="wrapper-sheet-multi-edit mb-2">
            {!isEmpty(columns) && (
              <JSpreadsheet
                disabledContextMenu
                columns={columns}
                className="spread-sheet-multi-edit"
                height="400px"
                defaultTextDescription={dataSheet}
                autoIncrement={false}
                isFullscreen={isFullscreen}
                styleCells={styleCells}
                changeDescriptionCallback={changeSheetCallback}
                onBeforeChangeCallback={onBeforeChangeCallback}
              />
            )}
          </div>

          <Pagination
            size="small"
            className="mt-1"
            pageSize={pageLimit}
            current={currentPage}
            total={totalCount}
            onChange={onChangePage}
          />
        </div>
      </div>
    </Spin>
  );
}
MultiEditVectorView.propTypes = {
  currentPage: number,
  totalCount: number,
  defaultData: array,
  pageLimit: number,
  partitionType: string,
  backEditCallback: func,
  getIssuesRequestSetting: func,
};

import {ProjectTypeCommonConstants} from "../../constants/Constants";

const initialState = {
  fetching: false,
  data: [],
  error: ''
};

export default (state=initialState, action) => {
  const {payload, type} = action;
  switch (type) {
    case ProjectTypeCommonConstants.GET_MAIN_ARCHIVE_ENTITY_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case ProjectTypeCommonConstants.GET_MAIN_ARCHIVE_ENTITY_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload
      };
    case ProjectTypeCommonConstants.GET_MAIN_ARCHIVE_ENTITY_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        error: payload
      };

    default:  return state;
  }
};

import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Col, Row } from 'antd';
import {any, element, func, number, string} from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { UI54Button, UI54Icon } from "@agpl/ui54kit";

export default function HeaderCard({
  title,
  subTitle,
  className,
  type,
  extra,
  backCallback,
  disabledBack,
  spanTitleSection,
  spanDescriptionSection,
  beforeTitle = null,
}) {

  return (
    <Row gutter={[5, 5]} className="flex-nowrap">
      <Col className={cn('flex flex-auto items-center', className)}>
        <UI54Button
          id='backIssueCardButton'
          size="small"
          type={type || 'text'}
          className="flex items-center pl-0 pr-1 py-0 hover-btn-color-blue"
          disabled={disabledBack}
          onClick={backCallback}
        >
          <UI54Icon path={mdiArrowLeft} size={0.8} />
          <span className="inline-flex ml-1 head-font">{title}</span>
        </UI54Button>

        <div className="flex grow">
          {subTitle}
        </div>
      </Col>
      <Col className="flex flex-wrap items-center">{extra}</Col>
    </Row>
  );
}

HeaderCard.propTypes = {
  type: string,
  title: string,
  className: string,
  subTitle: any,
  extra: any,
  backCallback: func,
  disabledBack: func,
  spanTitleSection: number,
  spanDescriptionSection: number,
  beforeTitle: element,
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prevIsOpen: false,
  isOpen: false,
  isOpenGPT: false,
};

const getAuthSessionModalSlicer = createSlice({
  name: 'ModalGetAuth54',
  initialState,
  reducers: {
    TOGGLE_MODAL_GET_AUTH_SESSION(state) {
      state.prevIsOpen = state.isOpen;
      state.isOpen = !state.isOpen;
    },
    CHANGE_MODAL_GET_AUTH_SESSION(state, action) {
      state.prevIsOpen = !action.payload;
      state.isOpen = action.payload;
    },
    CHANGE_MODAL_GET_GPT_SESSION(state, action) {
      state.isOpenGPT = action.payload;
    },
  },
});

export default getAuthSessionModalSlicer.reducer;

export const {
  TOGGLE_MODAL_GET_AUTH_SESSION,
  CHANGE_MODAL_GET_AUTH_SESSION,
  CHANGE_MODAL_GET_GPT_SESSION,
} = getAuthSessionModalSlicer.actions;

export const getModalAuthSessionFlag = (state) => state.Admin.getAuthSessionModalSlicer.isOpen;
export const getPrevModalAuthSessionFlag = (state) => state.Admin.getAuthSessionModalSlicer.prevIsOpen;

export const getModalGPTSessionFlag = (state) => state.Admin.getAuthSessionModalSlicer.isOpenGPT;

import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getExpTypeAndValue } from '../projectFlow/components/issues/issueInfo/utils';
import BaseTag from "./_ui/BaseTag/BaseTag";

const issueStatusesForInfinity = ['acquainted'];

export default function DateLeftForExp({
  status,
  expirationDateDev,
  expirationDateQa,
  customStyle = '',
}) {
  const { t } = useTranslation();

  const [expTime, setExpTime] = useState('');
  const [expType, setExpType] = useState(null);

  const getDate = () => {
    if (!expType) {
      return null;
    }
    return expType === 'expirationDateDev' ? expirationDateDev : expirationDateQa;
  };

  const getStyle = () => {
    if (expType === 'expirationDateDev') {
      return {
        border: '1px solid #5676f3',
        borderRadius: '5px',
        color: '#5676f3',
        backgroundColor: '#cbdaf7a6',
        // width: '28px',
        height: '23px',
        fontSize: '10px',
      };
    }
    if (expType === 'expirationDateQa') {
      return {
        color: '#f89451',
        borderRadius: '5px',
        border: '1px solid #f89451',
        backgroundColor: '#ffefd0',
        // width: '28px',
        height: '23px',
        fontSize: '10px',
      };
    }
    return {};
  };

  const getDayLeft = () => {
    if (issueStatusesForInfinity?.includes(status)) {
      return <span style={{ fontSize: '20px' }}>{'\u221E'}</span>;
    }
    return expTime;
  };

  useEffect(() => {
    if (status) {
      const { type } = getExpTypeAndValue(status);
      setExpType(type);
      if (!type) {
        setExpTime('');
      } else {
        const currentDate = type === 'expirationDateDev' ? expirationDateDev : expirationDateQa;
        const current = moment(currentDate, 'YYYY-MM-DD').diff(moment(moment(), 'YYYY-MM-DD'), 'days');
        setExpTime(current);
      }
    }
  }, [
    status,
    expirationDateDev,
    expirationDateQa,
  ]);

  return (
    <div className="flex">
      <Tooltip
        placement="right"
        title={getDate()}
      >
        <div
          className={`${customStyle} flex items-center justify-center`}
        >
          <BaseTag
            style={getStyle()}
          >
            {getDayLeft()}
          </BaseTag>
        </div>
      </Tooltip>
    </div>
  );
}
DateLeftForExp.propTypes = {
  status: string,
  expirationDateDev: string,
  expirationDateQa: string,
  customStyle: string,
};

import { Col, Row } from "antd";
import React from "react";
import CircleActorAvatar54origins from "../../../../54origins/components/CircleActorAvatar54origins";
import { getInitialsNameOfActor } from "../../../../54origins/utils54origins";
import { propOr } from 'ramda';
import { getActorLabel } from "./utils";
import { func, object } from "prop-types";


const UserCard = ({user, changeUser}) => {
    const username = getActorLabel(user)
    return(
    <Row 
    className="mr-2 p-2 pl-6"  
    style={{ cursor: 'pointer', backgroundColor: "white" }}
    onClick={() => changeUser({actor: user.uuid, label: username})}
    >
    {(
        <Col className="flex items-center">
        <CircleActorAvatar54origins
            name={getInitialsNameOfActor(user)}
            actorUUID={propOr('', 'uuid', user)}
        />
        </Col>
    )}
    <Col className="flex items-center">
        <span className="m-0 ml-2 text-ellipsis" style={{ userSelect: 'none', maxWidth: '250px' }}>
        {username}
        </span>
    </Col>
    </Row>
    )
}
UserCard.propTypes = {
    user: object,
    changeUser: func
    }

export default UserCard;



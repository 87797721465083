import { Col } from 'antd';
import React from 'react';

import ViewReportByProject from './ViewReportByProject.jsx';
import ListOfProjectsForReports from './ListOfProjectsForReports.jsx';
import { partitionNamesConfig } from '../../../api/appConfig.js';

export default function ProjectReportsRoot() {
  return (
    <div className="relative h-full w-full">
      <div className="wrapper-user-report">
        <Col className="transition07 h-full flex flex-col mr-2 overflowHidden" span={5}>
          <div className="wrapper-table-projects">
            <ListOfProjectsForReports
              title="General"
              defaultPartition={partitionNamesConfig.partition1}
            />
          </div>
          <div className="wrapper-table-projects mt-2 ">
            <ListOfProjectsForReports
              title="Personal projects"
              defaultPartition={partitionNamesConfig.partition3}
            />
          </div>
        </Col>

        <ViewReportByProject />
      </div>
    </div>
  );
}

import { Col, Spin } from 'antd';
import { bool, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProjectMainLayer from '../../projectFlow/components/project/ProjectMainLayer';
import ProjectFlowProjectPage from '../../projectFlow/components/project/ProjectFlowProjectPage';

import useProjectURL from '../../hooks/useProjectURL';
import useUserPublicPartition from '../../hooks/useUserPublicPartition';
import { getOtherUserPublicProfileUUID, getUserPublicFetching, getUserPublicProfileUUID } from '../store/selectors/selectors';
import { partitionNamesConfig } from '../../api/appConfig';

export default function WrapperPublicProject({
  hideAddProjectBtn,
  doNotMakeProjectRequest,
  initialPathForHistory,
  otherUser = false,
}) {
  const { t } = useTranslation();

  const { activeTab } = useParams();
  const [{ userUUIDFromUrl }] = useProjectURL();

  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const otherUserPublicProfileUUID = useSelector(getOtherUserPublicProfileUUID);
  const userPublicFetching = useSelector(getUserPublicFetching);
  const myUserPublicProfileUUID = myPublicUUID || userUUIDFromUrl;

  const finalPublicUserUUID = otherUser ? otherUserPublicProfileUUID : myUserPublicProfileUUID;

  const { actorMainPublicProjectUUID } = useUserPublicPartition(finalPublicUserUUID, 'mainPublicProjectUUID');

  const location = 'dashboard';

  return (
    <Spin wrapperClassName="project-content" spinning={userPublicFetching}>
      {activeTab ? (
        <Col span={24} className="pt-2 ml-1 h-full">
          <ProjectMainLayer
            initialPathForHistory={initialPathForHistory}
            defaultPartition={partitionNamesConfig.partition3}
            otherUser={otherUser}
          />
        </Col>
      ) : (
        <ProjectFlowProjectPage
          key={location}
          location={location}
          hideAddProjectBtn={hideAddProjectBtn}
          defaultPartition={partitionNamesConfig.partition3}
          customParent={actorMainPublicProjectUUID}
          doNotMakeProjectRequest={doNotMakeProjectRequest}
          initialPathForHistory={initialPathForHistory}
        />
      )}
    </Spin>
  );
}

WrapperPublicProject.propTypes = {
  initialPathForHistory: string,
  otherUser: bool,
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quillDocReady: false,
  quillInstance: null,
  deletingDocUuid: null,
  deletedDocHtml: "",
  allDocPerms: null,
  currentDocPerms: null
};

export const quillSlicer = createSlice({
  name: "quillSlicer",
  initialState,
  reducers: {
    setQuillDocReady: (state, action) => {
      state.quillDocReady = action.payload;
    },
    setCurrentQuillInstance: (state, action) => {
      state.quillInstance = action.payload;
    },
    setDeletingDocUuid: (state, action) => {
      state.deletingDocUuid = action.payload;
    },
    setDeletedDocHtml: (state, action) => {
      state.deletedDocHtml = action.payload;
    },    
    setAllDocPerms: (state, action) => {
      state.allDocPerms = action.payload;
    }, 
    setCurrentDocPerms: (state, action) => {
      state.currentDocPerms = action.payload;
    }, 
    clearCurrentQuillInstance: (state) => {
      state.quillInstance = null;
    },
    clearDeletetingDocUuid: (state) => {
      state.deletingDocUuid = null;
    },
  },
});

const storeQuillPath = (state) => state?.Quill?.Quill;
export const getQuillDocReady = (state) =>
  storeQuillPath(state)?.quillDocReady;
export const getCurrentQuillInstance = (state) =>
  storeQuillPath(state)?.quillInstance;
export const getDeletingDocUuid = (state) =>
  storeQuillPath(state)?.deletingDocUuid;
export const getDeletedDocHtml = (state) =>
  storeQuillPath(state)?.deletedDocHtml;
export const getAllDocPerms = (state) =>
  storeQuillPath(state)?.allDocPerms;
export const getCurrentDocPerms = (state) =>
  storeQuillPath(state)?.currentDocPerms;

export const {
  setQuillDocReady,
  setCurrentQuillInstance,
  setDeletingDocUuid,
  setDeletedDocHtml,
  setCurrentDocPerms,
  setAllDocPerms,
  clearCurrentQuillInstance,
  clearDeletetingDocUuid,
} = quillSlicer.actions;

export default quillSlicer.reducer;

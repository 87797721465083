import { get } from "lodash";

export const getTodayDaily = (state) =>
  get(state, "ProfileService.dailyReducer.data");

export const getTodayDailyFetching = (state) =>
  get(state, "ProfileService.dailyReducer.fetching");

export const draggableElementActivity = (state) =>
  get(state, "ProfileService.dailyReducer.dragAndDropElement", {});

export const needDublicateActivity = (state) =>
  get(state, "ProfileService.dailyReducer.dublicateActivities", []);
export const getSyncData = (state) =>
  get(state, "ProfileService.dailyReducer.syncData", null);

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Row } from 'antd';
import { head, isEmpty, prop } from 'ramda';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BaseModal from '../../../components/_ui/BaseModal/BaseModal';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { antNotification } from '../../../MainUtils';
import { capitalize } from 'lodash';
import { getProjectUUID } from '../../selectors/selectors';
import { getMainArchiveUUID } from '../../selectors/archiveStateSelectors';
import { resetArchiveState } from '../../actions/projectFlowActions';
import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
import { getLocale } from '../../../locale/selectors';
import { entityCreate, entityUpdate, getListAndPartialReadEntities } from '../../../entity/actions/entityActions';

function NewArchiveState({
  newArchiveStateCallback,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    defaultPartition,
  } = useContext(ProjectMainLayerContext);

  const projectUUID = useSelector(getProjectUUID);
  const mainArchiveUUID = useSelector(getMainArchiveUUID);
  const locale = useSelector(getLocale);

  const [visibleModal, changeModal] = useState(false);
  const [archiveName, changeArchiveName] = useState(moment().locale(locale).format('MMMM DD YYYY, hh:mm:ss'));

  const toggleModal = () => changeModal(!visibleModal);

  const updateParentForEntities = (oldUUID, newParent) => {
    const constants = [
      ProjectTypeCommonConstants.UPDATE_PARENT_FOR_ARCHIVE_ENTITY_REQUEST,
      ProjectTypeCommonConstants.UPDATE_PARENT_FOR_ARCHIVE_ENTITY_SUCCESS,
      ProjectTypeCommonConstants.UPDATE_PARENT_FOR_ARCHIVE_ENTITY_FAILURE,
    ];

    const data = {
      entity_uuids: oldUUID,
      parent: newParent,
    };

    const options = {
      partition: defaultPartition,
      onSuccess: (response) => {
        // antNotification('success', response.message);
        toggleModal();
      },
    };

    dispatch(entityUpdate({
      data,
      constants,
      options,
    }));
  };

  const getAllDataForArchiveState = () => {
    const config = {
      data: {
        depth: 1,
        params: {},
        parent: projectUUID,
        entity_type__not: ['mainArchiveEntity'],
        fields: { entity_type: 'entity_type' },
      },
      partition: defaultPartition,
      constants: [
        ProjectTypeCommonConstants.ALL_DATA_FOR_ARCHIVE_ENTITY_REQUEST,
        ProjectTypeCommonConstants.ALL_DATA_FOR_ARCHIVE_ENTITY_SUCCESS,
        ProjectTypeCommonConstants.ALL_DATA_FOR_ARCHIVE_ENTITY_FAILURE,
      ],
      doNotCheckError: true,
    };
    return dispatch(getListAndPartialReadEntities(config));
  };

  const createArchiveState = () => {
    const data = {
      entity_type: 'archiveEntity',
      params: {
        name: archiveName,
      },
      parent: mainArchiveUUID,
    };

    const constants = [
      ProjectTypeCommonConstants.CREATE_ARCHIVE_ENTITY_REQUEST,
      ProjectTypeCommonConstants.CREATE_ARCHIVE_ENTITY_SUCCESS,
      ProjectTypeCommonConstants.CREATE_ARCHIVE_ENTITY_FAILURE,
    ];

    const options = {
      partition: defaultPartition,
    };

    return dispatch(entityCreate({
      data,
      constants,
      options,
    }));
  };

  const moveEntities = async () => {
    const entities = await getAllDataForArchiveState();
    const res = prop('data', entities);
    const entitiesUUID = res.reduce((prev, item) => {
      if (prop('entity_type', item) !== 'project') {
        return [...prev, item.uuid];
      }
      return prev;
    }, []);

    if (!isEmpty(entitiesUUID)) {
      const archiveStateUUID = prop('uuid', head(await createArchiveState()));

      updateParentForEntities(entitiesUUID, archiveStateUUID);
      dispatch(resetArchiveState);
      antNotification('success', t('wms.noun.success', 'Success'));
      changeModal(false);

      if (newArchiveStateCallback) {
        newArchiveStateCallback();
      }
    } else {
      antNotification('warning', 'Project is empty');
      changeModal(false);
    }
  };

  return (
    <div>
      <BaseButton
        onClick={toggleModal}
        type="primary"
      >
        {capitalize(
          t('wms.buttons.new.archive_state', 'new archive state'),
        )}
      </BaseButton>
      <BaseModal
        title={capitalize(
          t('wms.buttons.new.archive_state', 'new archive state'),
        )}
        open={visibleModal}
        handleOk={toggleModal}
        handleCancel={toggleModal}
        footer={(
          <Row type="flex" justify="end">
            <BaseButton
              onClick={toggleModal}
              type="primary"
            >
              {capitalize(t('wms.buttons.remove.no', 'no'))}
            </BaseButton>
            <BaseButton
              disabled={!archiveName?.trim()}
              onClick={moveEntities}
              type="primary"
            >
              {capitalize(t('wms.buttons.remove.yes', 'yes'))}
            </BaseButton>
          </Row>
        )}
        width="30%"
      >
        {visibleModal && (
          <div>
            <Input
              showCount
              maxLength={60}
              defaultValue={archiveName}
              onChange={(e) => changeArchiveName(e.target.value)}
            />
            <p className="mt-3">
              {capitalize(t(
                'wms.modals.body.archive_state',
                'all entities that are under the project will be transferred to the archive state. Are you sure?',
              ))}
            </p>
          </div>
        )}
      </BaseModal>
    </div>
  );
}

export default NewArchiveState;

NewArchiveState.propTypes = {
  newArchiveStateCallback: PropTypes.func,
};

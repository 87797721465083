/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const initialState = {
  projectFilterTags: null,
  otherFilterTags: [{
    key: 'otherTags',
    value: 'modified_today_by_me',
    label: 'Modified today by me',
  }],
  currentSelectedTags: null,
  isActiveActivities: true,
  isActiveIssues: false,
};

const dailyFilterSlice = createSlice({
  name: 'dailyFilterSlice',
  initialState,
  reducers: {
    setProjectFilterTags: (state, action) => {
      state.projectFilterTags = action.payload;
    },
    setOtherFilterTags: (state, action) => {
      state.otherFilterTags = action.payload;
    },
    setCurrentSelectedTags: (state, action) => {
      state.currentSelectedTags = action.payload;
    },
    setIsActiveActivities: (state) => {
      state.isActiveActivities = !state.isActiveActivities;
    },
    setIsActiveIssues: (state) => {
      state.isActiveIssues = !state.isActiveIssues;
    },
    setDefaultState: (state) => {
      state.tagConfig = null;
      state.projectFilterTags = null;
      state.otherFilterTags = [{
        key: 'otherTags',
        value: 'modified_today_by_me',
        label: 'Modified today by me',
      }];
      state.currentSelectedTags = null;
      state.isActiveActivities = true;
      state.isActiveIssues = false;
    },
  },
});

export const useDetailedReportFilterActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(dailyFilterSlice.actions, dispatch);
};

export default dailyFilterSlice.reducer;

import { useMemo } from 'react';

const sortByEntityType = (entityList, sortValue) => {
  const collection = {
    issue: 1,
    vector: 2,
    projectIdea: 3,
    projectDocument: 4,
    projectSpreadsheet: 5,
    projectGoal: 6,
    projectMilestone: 7,
    testSuite: 8,
    testCycle: 9,
  };

  switch (sortValue) {
    case 'ascend': {
      const sortedArr = entityList.sort((a, b) => {
        if (a.entity_type in collection && b.entity_type in collection) {
          if (collection[a.entity_type] > collection[b.entity_type]) {
            return -1;
          }
          return 1;
        }
      });
      return sortedArr;
    }

    case 'descend': {
      const sortedArr = entityList.sort((a, b) => {
        if (a.entity_type in collection && b.entity_type in collection) {
          if (collection[a.entity_type] > collection[b.entity_type]) {
            return 1;
          }
          return -1;
        }
      });
      return sortedArr;
    }
    default: {
      return entityList;
    }
  }
};

const sortByLifeTime = (entityList, sortValue) => {
  switch (sortValue) {
    case 'ascend': {
      const sortedArr = entityList.sort((a, b) => {
        const dateA = new Date(a?.lifeTime);
        const dateB = new Date(b?.lifeTime);
        if (dateA > dateB) {
          return -1;
        }

        return 1;
      });
      return sortedArr;
    }

    case 'descend': {
      const sortedArr = entityList.sort((a, b) => {
        const dateA = new Date(a?.lifeTime);
        const dateB = new Date(b?.lifeTime);

        if (dateA > dateB) {
          return 1;
        }

        return -1;
      });
      return sortedArr;
    }
    default: {
      return entityList;
    }
  }
};

const sortByCreated = (entityList, sortValue) => {
  switch (sortValue) {
    case 'ascend': {
      const sortedArr = entityList.sort((a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);
        if (dateA > dateB) {
          return -1;
        }

        return 1;
      });
      return sortedArr;
    }

    case 'descend': {
      const sortedArr = entityList.sort((a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);

        if (dateA > dateB) {
          return 1;
        }

        return -1;
      });
      return sortedArr;
    }
    default: {
      return entityList;
    }
  }
};

const sortByModified = (entityList, sortValue) => {
  switch (sortValue) {
    case 'ascend': {
      const sortedArr = entityList.sort((a, b) => {
        const dateA = new Date(a.modified);
        const dateB = new Date(b.modified);
        if (dateA > dateB) {
          return -1;
        }

        return 1;
      });
      return sortedArr;
    }

    case 'descend': {
      const sortedArr = entityList.sort((a, b) => {
        const dateA = new Date(a.modified);
        const dateB = new Date(b.modified);

        if (dateA > dateB) {
          return 1;
        }

        return -1;
      });
      return sortedArr;
    }
    default: {
      return entityList;
    }
  }
};

const sortSplitHandler = (entityList, sortSettings) => {
  const {
    sortValue,
    sortType,
  } = sortSettings;

  switch (sortType) {
    case 'entity_type': {
      const sortEntitiesByEntityType = sortByEntityType(entityList, sortValue);
      return sortEntitiesByEntityType;
    }
    case 'lifeTime': {
      const sortEntitiesByLifeTime = sortByLifeTime(entityList, sortValue);
      return sortEntitiesByLifeTime;
    }
    case 'created': {
      const sortEntitiesByCreated = sortByCreated(entityList, sortValue);
      return sortEntitiesByCreated;
    }
    case 'modified': {
      const sortEntitiesByModified = sortByModified(entityList, sortValue);
      return sortEntitiesByModified;
    }
    default: {
      return entityList;
    }
  }
};

export default function useEntityListSort(entityList, sortSettings) {
  const sortedEntities = useMemo(() => {
    const sortedEntitiesArr = sortSplitHandler(entityList, sortSettings);
    return sortedEntitiesArr;
  }, [entityList, sortSettings]);

  return sortedEntities;
}

import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Badge, Row, Col, Checkbox, Spin, Flex,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import WrapperSelectComponent from './WrapperSelectComponent';
import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';

import { capitalize, isEmpty } from 'lodash';
import {
  allCasesFetching, getProjectParams, getProjectUUID,
} from '../../../selectors/selectors';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';

function ListOfAllTestCases({
  selectedValues,
  setSelectedValues,
  saveDataCallback,
  checkedCheckbox,
  getSelectedCases,
  listAllTestCases,
  handleChangeSuites,
  onChangeCheckbox,
  selectedUUID = [],
  changeSelectedUUID,
  loading,
  assignToUsersOption,
  changeAssignToUsersOption,
  setConfig,
  config,
  buttonTitle = '',
  cycleState = {},
  setCycleState,
  isClearDisabled = false
}) {
  const { t } = useTranslation();

  const {
    partitionType,
  } = useContext(BugTrackerContext);

  const allCasesFetch = useSelector(allCasesFetching);

  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);

  const [pageLimit, setPageLimit] = useState(5);

  const [isAssignOpen, setIsAssignOpen] = useState(false);

  const submitFunc = () => {
    saveDataCallback();
  };

  const assignToFunc = (value) => {
    changeAssignToUsersOption(value);
  };

  const stepsFormatter = (cell) => (
    <Badge
      count={cell ? cell.length : 0}
      showZero
      style={{ backgroundColor: '#038fde' }}
    />
  );

  const keyFormatter = (cell, row) => <span>{`${row?.id || 0}`}</span>;

  const columns = [
    {
      dataIndex: 'uuid',
      title: 'ID',
      render: keyFormatter,
    },
    {
      dataIndex: ['title'],
      title: capitalize(t('wms.table.headers.title', 'Title')),
      render: (cell) => (
      <> {cell.length > 20 ?
        <Tooltip
          title={cell}
          placement='topLeft'
        >
          <div className="overflow-hidden truncate ">
          {`${cell.slice(0, 20)}`}
          <span>{'...'}</span>
          </div>
        </Tooltip>
        :
          <div className="overflow-hidden truncate ">
            {cell}
          </div>
         }
        </>
      ),
    },
    {
      dataIndex: 'description',
      title: capitalize(t('wms.table.headers.description', 'Description')),
    },
    {
      dataIndex: 'created',
      title: capitalize(t('wms.table.headers.created', 'Created')),
      render: (cell) => (
        <div className="flex justify-start">
          {moment(cell).format('DD/MM/YYYY HH:mm')}
        </div>
      ),
    },
    {
      dataIndex: 'uinfo',
      title: capitalize(t('wms.table.headers.created_by', 'Created by')),
      render: (cell) => (
        <div className="flex justify-start">
          {`${cell.first_name} ${cell.last_name}`}
        </div>
      ),
    },
    {
      dataIndex: ['steps'],
      title: capitalize(t('wms.table.headers.steps', 'Steps')),
      render: stepsFormatter,
    },
  ];

  const onBlurSelectCallback = () => { };

  const selectRow = {
    mode: 'checkbox',
    selectedRowKeys: selectedUUID,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      changeSelectedUUID(selectedRowKeys);
      getSelectedCases(selectedRows);
    },
  };
  const pageSizeOptions = useMemo(() => ([5, 25, 50, 100]), []);

  const clearFormFunc = () => {
    if(setCycleState){
      setCycleState({
        ...cycleState,
        'existing cycle form': {
          selectedCycle: {},
          selectedSuites: [],
          suitesOptions: [],
          selectedCases: []
        }
      })
    };
    
    setSelectedValues([])
    changeSelectedUUID([]);
    changeAssignToUsersOption([]);
    handleChangeSuites([]);
  }

  // console.log('listAllTestCases',listAllTestCases)
  return (
    <>
      <Row type="flex">
        <Col span={24}>
          <Col>
            {capitalize(t('wms.projectflow.qa.suite.list.header', 'test suite list'))}
            <span style={{ color: 'red' }}>*</span>
          </Col>
          <WrapperSelectComponent
            selectedValues={selectedValues}
            handleChangeCallback={handleChangeSuites}
            onBlurSelectCallback={onBlurSelectCallback}
            valueSuites={cycleState ? cycleState['existing cycle form']?.suitesOptions : []}
          />
        </Col>
      </Row>
      <Flex className="w-full mt-3">
        <AssigningUsersRoot
          header="Assigning users"
          needUpdateProjectUsers
          isOpen={isAssignOpen}
          setIsOpen={setIsAssignOpen}
          onUpdateUsers={(data) => assignToFunc(data?.users?.map((el) => el?.uuid))}
          globalOptions={{
            uuids: projectParams?.usersSearch ?? [],
            defaultColumn: 'project',
          }}
          placement="left"
          isPopover
          tabs={{
            allConfig: {
              label: 'All',
              value: 'all',
              textSelectButton: 'Add to case',
              showSections: ['button-add-user'],
            },
            projectConfig: {
              label: 'Project',
              value: 'project',
              textSelectButton: 'Add to case',
              defaultItems: projectParams?.users ?? [],
              showSections: ['string-role', 'button-add-user'],
            },
          }}
          usersConfig={{
            label: 'Case users',
            showSections: ['button-remove-user', 'string-role'],
            textDeleteButton: 'Delete users',
            defaultItems: assignToUsersOption ?? [],
          }}
          wrapperProps={{ autoAdjustOverflow: true }}
          partition={partitionType}
          projectUuid={projectUUID}
          popoverText={(<span style={{ color: 'black' }}>Assigned users:</span>)}
        />
        <WrapperAvatarsGroupForProjectWithHook
          actors={assignToUsersOption || []}
          avatarSize={28}
          popoverTrigger="click"
          popoverPlacement="right"
        />
      </Flex>

      <Spin spinning={allCasesFetch}>
        <Row type="flex" className="mt-3">
          <Col span={24}>
            <span>
              {capitalize(t('wms.projectflow.qa.case.list.header'), 'test case list')}
              :
            </span>
            <BaseTableWithPagination
              loading={loading}
              pageLimit={pageLimit}
              changePageLimit={setPageLimit}
              columns={columns}
              data={listAllTestCases}
              pageSizeOptions={pageSizeOptions}
              paginationSize="small"
              rowClassName="universal_table_row not-hide-checkbox"
              headerRowClassName="universal_header_table_row not-hide-checkbox"
              rowSelection={selectRow}
            />
          </Col>
        </Row>
      </Spin>
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Col className="w-full mr-5 flex items-center justify-between">
          <BaseButton
            className="btnDanger mr-1"
            onClick={clearFormFunc}
            disabled={isClearDisabled['existing cycle form']}
          >
            {capitalize(t('wms.buttons.Clear_form', 'Clear form'))}
          </BaseButton>

          <Flex className='flex flex-row'>
            <div className="mr-2 mt-1">
              {capitalize(t('wms.modals.body.hide_added_test_cases', 'don\'t display already added test cases'))}
            </div>

            <Checkbox
              className='mr-2'
              onChange={(e) => onChangeCheckbox(e.target.checked)}
              checked={checkedCheckbox}
              disabled={cycleState ? isEmpty(cycleState['existing cycle form']?.selectedCycle) : false}
            />

            <BaseButton
              type="primary"
              className='mt-2'
              onClick={submitFunc}
              disabled={selectedUUID.length === 0}
            >
              {capitalize(t('wms.buttons.import.test_cases', 'import test cases'))}
            </BaseButton>
          </Flex>
        </Col>
      </Row>
    </>
  );
}

ListOfAllTestCases.propTypes = {
  onChangeCheckbox: PropTypes.func,
  getSelectedCases: PropTypes.func,
  saveDataCallback: PropTypes.func,
  checkedCheckbox: PropTypes.bool,
  listAllTestCases: PropTypes.array,
  handleChangeSuites: PropTypes.func,
  selectedUUID: PropTypes.string,
  changeSelectedUUID: PropTypes.func,
  loading: PropTypes.bool,
  assignToUsersOption: PropTypes.array,
  changeAssignToUsersOption: PropTypes.func,
  setConfig: PropTypes.func,
  config: PropTypes.object,
  buttonTitle: PropTypes.string,
};

export default ListOfAllTestCases;

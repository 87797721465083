import {
  Col, Input, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { head, isNil, propOr } from 'ramda';
import moment from 'moment';
import { get, isEmpty, omit } from 'lodash';
import PropTypes from 'prop-types';
import { getProjectUUID, getTestCycleUUID } from '../../../selectors/selectors';
import { BugTrackerContext, ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import {
  clearListTestCaseInCycleInStore, createTestCaseForCycle, getTestCycleFunc,
  updateTestCycle,
} from '../../../actions/QaActions';
import { getListAndPartialReadEntities, incrementAndGetCount } from '../../../../entity/actions/entityActions';
import { antNotification, capitalizeFirstChar, getHeadUUID } from '../../../../MainUtils';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import ListOfAllTestCases from '../case/ListOfAllTestCases';
import ListOfAllTestCasesForRun from "../case/ListOfAllTestCasesForRun";

const { TextArea } = Input;

function TestCycleFormForRun({
  typeOfTesting,
  selectTestCycleCallback,
  importTestCaseCallback,
  toggleModal,
  partition = 'PM',
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const disabledSumbit = useRef(false);

  const testCycleUUID = useSelector(getTestCycleUUID);
  const projectUUID = useSelector(getProjectUUID);

  const {
    sendAssignedNotifications,
  } = useContext(ProjectMainLayerContext);

  const {
    createPromiseNewTestCycle,
    partitionType,
    getListOfTestCasesInCycle,
    getListOfTestCycles,
    getListOfTestSuites,
  } = useContext(BugTrackerContext);

  const defaultConfig = {
    entity_type: 'testCase',
    depth: 0,
    params: {
      typeOfTesting: [typeOfTesting]
    },
    parent: projectUUID,
  };

  const defaultState = {
    title: '',
    description: '',
    start_date: moment(),
    end_date: moment(),
    selectVector: [],
    casesCount: 0,
    assignToUsersOption: [],
  };

  const [state, changeState] = useState(defaultState);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState(defaultConfig);
  const [listAllTestCases, setListAllTestCases] = useState([]);
  const [checkedCheckbox, changeCheckedCheckbox] = useState(false);
  const [selectedUUID, changeSelectedUUID] = useState([]);
  const [assignUsers, changeAssignToUsersOption] = useState([]);
  const [valueSuites, setValueSuites] = useState([]);

  const {
    title,
    description,
    start_date,
    end_date,
    assignToUsersOption,
    casesCount,
    selectVector,
  } = state;

  const setNewState = (param) => {
    changeState({
      ...state,
      ...param,
    });
  };

  const onFormChange = (event) => {
    const { name, value } = event.target;

    setNewState({ [name]: value });
  };

  const submitNewTestCycle = async (customCountCase) => {
    dispatch(clearListTestCaseInCycleInStore());

    const { increment } = await dispatch(incrementAndGetCount({
      entity_uuid: projectUUID,
      field_name: 'suite',
    }, partitionType));

    const params = {
      title,
      description,
      start_date: start_date ? moment(start_date).format('YYYY-MM-DD') : '',
      end_date: end_date ? moment(end_date).format('YYYY-MM-DD') : '',
      status: 'in progress',
      executed_by: null,
      executed_on: null,
      cases_count: customCountCase || casesCount,
      order: [],
      helperInStatus: false,
      archive: false,
      assignToUser: assignToUsersOption,
      id: `C${increment}`,
      vector: selectVector,
      progress: {
        pass: 0,
        block: 0,
        fail: 0,
        unexecuted: customCountCase || 0,
        progress: 0,
        quantityCase: 0,
      },
    };

    const newTestCycle = await createPromiseNewTestCycle(params);
    const newTestCycleUUID = getHeadUUID(newTestCycle);

    const assignedUsers = get(params, 'assignToUser');

    sendAssignedNotifications({
      entity_type: 'testCycle',
      assignedUsers,
      eventName: 'assigned_test_cycle',
      entityUUID: get(newTestCycle, '[0].uuid'),
      message: get(newTestCycle, '[0].params.title', ''),
      entityUUIDsFields: {
        testCycleUUID: get(newTestCycle, '[0].uuid'),
        projectUUID,
      },
    });

    if (newTestCycleUUID) {
      setNewState(defaultState);

      getListOfTestCycles();
    }

    if (selectTestCycleCallback) {
      selectTestCycleCallback(newTestCycleUUID, false, true);
    }

    return Promise.resolve(newTestCycle);
  };

  const submitNewTestCycleAndCloseModal = async () => {
    const newCycles = await submitNewTestCycle();
    disabledSumbit.current = false;

    return newCycles[0];
  };

  function getListOfTestCasesFunc(customConfig) {
    const configData = customConfig || config;
    return dispatch(getListAndPartialReadEntities({
      data: {
        ...configData,
        params_fields: {
          id: 'id',
          title: 'title',
          description: 'description',
          testCaseUuidFromSuite: 'testCaseUuidFromSuite',
          steps: 'steps',
          comment: 'comment',
          suite: 'suite',
          typeOfTesting: 'typeOfTesting',
          casePriority: 'casePriority',
          status: 'status',
          tags: 'tags',
        },
        fields: {
          'actor.created': 'created',
          'actor.uinfo': 'uinfo',
        },
      },
      partition: partitionType,
      constants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    }));
  }

  const getSelectedCases = () => listAllTestCases
    .filter((i) => selectedUUID.some((p) => p === i?.uuid))
    .map((item) => ({
      title: propOr('', 'title', item),
      comment: propOr('', 'comment', item),
      suite: propOr('', 'suite', item),
      description: propOr('', 'description', item),
      typeOfTesting: propOr([], 'typeOfTesting', item),
      casePriority: propOr('', 'casePriority', item),
      template: true,
      tags: propOr([], 'tags', item),
      status: propOr('unexecuted', 'status', item),
      cycle: testCycleUUID,
      executed_by: null,
      executed_on: null,
      steps: get(item, 'steps', []).map((i) => ({
        ...i,
        action: i.action = '',
        status: 'unexecuted',
      })),
      testCaseUuidFromSuite: [item.uuid],
      uuidFromSuite: item.parent,
      assignToUser: assignUsers,
    }));

  const attachTestCasesToCycle = async (selectedTestCases, newCycle) => {
    const list = await Promise.all(selectedTestCases.map(async (i) => {
      const { increment } = await dispatch(incrementAndGetCount({
        entity_uuid: get(newCycle, 'uuid'),
        field_name: 'case',
      }, partitionType));

      return ({
        ...i,
        id: `${get(newCycle, 'params.id', 0)}C${increment}`,
        closedCase: false,
        cycle: testCycleUUID,
        template: false,
        order: increment,
      });
    }));

    const newConfig = {
      parent: get(newCycle, 'uuid'),
      partition: partitionType,
      params: list,
    };

    return dispatch(createTestCaseForCycle(newConfig));
  };

  const saveDataCallback = async (newCycle) => {
    const selectedTestCases = getSelectedCases();

    let response = [];

    response = await attachTestCasesToCycle(selectedTestCases, newCycle);

    antNotification('success', t('wms.noun.success', 'Success'));

    for (let i = 0; i < response.length; i++) {
      sendAssignedNotifications({
        entity_type: 'testCase',
        eventName: 'assigned_test_case',
        assignedUsers: assignUsers,
        entityUUID: get(response, `[${i}].uuid`),
        message: get(response, `[${i}].params.title`),
        entityUUIDsFields: {
          testCycleUUID: get(response, '[0].parent'),
          testCaseUUID: get(response, `[${i}].uuid`),
          projectUUID,
        },
      });
    }

    const data = {
      uuid: get(newCycle, 'uuid'),
      partition: partitionType,
    };

    dispatch(getTestCycleFunc(data))
      .then((currentCycle) => {
        const prevCasesCount = get(currentCycle, '[0].params.cases_count', 0);

        const progress = (!isNil(currentCycle[0].params.progress)
            && currentCycle[0].params.progress);

        const dataCycle = {
          uuid: get(newCycle, 'uuid'),
          params: {
            cases_count: prevCasesCount + selectedUUID.length,
            helperInStatus: false,
            archive: false,
            progress: !isNil(progress)
              ? {
                ...progress,
                unexecuted: !isNil(progress) && progress.unexecuted + selectedUUID.length,
                quantityCase: progress.quantityCase + selectedUUID.length,
              } : {
                pass: 0,
                block: 0,
                fail: 0,
                unexecuted: selectedUUID.length,
                progress: 0,
                quantityCase: selectedUUID.length,
              },
          },
          partition: partitionType,
          customConstants: ['REQUEST', ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE, 'FAILURE'],
        };

        dispatch(updateTestCycle(dataCycle)).then(() => {
          getListOfTestCasesInCycle(get(newCycle, 'uuid'));
          getListOfTestCycles();
        });
      });

    if (importTestCaseCallback) {
      importTestCaseCallback();
    }

    toggleModal(null);
  };

  const handleChangeSuites = async (option) => {
    // console.log('selectedOption', selectedOption);
    if (!option || isEmpty(option)) {
      setListAllTestCases([]);
      return setConfig((prev) => (omit(prev, 'entities')));
    }
    const uuids = option.map((m) => m.value);
    setValueSuites(option);
    setConfig((prev) => ({
      ...prev,
      entities: uuids,
    }));
  };

  // const getTestCasesCurrentEntity = async () => {
  //   setLoading(true);
  //
  //   const data = {
  //     entity_type: 'testCase',
  //     depth: 0,
  //     params: {},
  //     parent: get(parentData, 'uuid'),
  //   };
  //
  //   const res = await getListOfTestCasesFunc(data);
  //   // console.log('res', res);
  //   const getData = get(res, 'data').reduce((list, i) => {
  //     if (Array.isArray(get(i, 'testCaseUuidFromSuite', ''))) {
  //       return [...list, head(i.testCaseUuidFromSuite)];
  //     }
  //     return list;
  //   }, []);
  //
  //   setConfig((prev) => ({
  //     ...prev,
  //     entities__not: getData,
  //   }));
  //   setLoading(false);
  // };

  const getListCases = async () => {
    setLoading(true);
    const res = await getListOfTestCasesFunc();
    setListAllTestCases(get(res, 'data'));
    changeSelectedUUID((prev) => prev.filter((i) => get(res, 'data').some((p) => get(p, 'uuid', '') === i)));
    setLoading(false);
  };

  const initFunc = async () => {
    setLoading(true);
    const res = await getListOfTestCasesFunc();
    setListAllTestCases(get(res, 'data'));
    changeSelectedUUID(get(res, 'data').map((caseItem) => caseItem?.uuid));
    setLoading(false);
  };

  const finalSubmitFunc = async () => {
    if (!disabledSumbit.current) {
      disabledSumbit.current = true;
      const newCycle = await submitNewTestCycleAndCloseModal();
      await saveDataCallback(newCycle);
    }
  };

  useEffect(() => {
    if (checkedCheckbox) {
      // console.log('checkedCheckbox', checkedCheckbox);
      // getTestCasesCurrentEntity();
    } else {
      setConfig((prev) => ({
        ...omit(prev, 'entities__not'),
      }));
    }
  }, [checkedCheckbox]);

  useEffect(() => {
    if (typeOfTesting) {
      getListOfTestSuites({
        parent: projectUUID,
        partition,
        params: {
          typeOfTesting: [typeOfTesting],
        },
      }).then((res) => handleChangeSuites(res.map((el) => ({
        value: el?.uuid, label: el?.title,
      }))));
    }
  }, [typeOfTesting]);

  useEffect(() => {
    if (!isEmpty(get(config, 'entities'))) {
      getListCases();
    }
  }, [JSON.stringify(config)]);

  useEffect(() => {
    initFunc();
  }, [typeOfTesting]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {capitalizeFirstChar(t('wms.labels.title', 'title'))}
          <span style={{ color: 'red' }}>*</span>
          <Input
            maxLength={128}
            showCount
            name="title"
            onChange={onFormChange}
          />
        </Col>
        <Col span={24} style={{ marginBottom: 16 }}>
          {capitalizeFirstChar(t('wms.labels.description', 'description'))}
          :
          <TextArea
            row={10}
            name="description"
            onChange={onFormChange}
          />
        </Col>
      </Row>
      <ListOfAllTestCasesForRun
        loading={loading}
        checkedCheckbox={checkedCheckbox}
        listAllTestCases={listAllTestCases}
        selectedUUID={selectedUUID}
        assignToUsersOption={assignUsers}
        handleChangeSuites={handleChangeSuites}
        onChangeCheckbox={changeCheckedCheckbox}
        changeSelectedUUID={changeSelectedUUID}
        saveDataCallback={finalSubmitFunc}
        getSelectedCases={getSelectedCases}
        getTitle={title}
        changeAssignToUsersOption={changeAssignToUsersOption}
        setConfig={setConfig}
        config={config}
        valueSuites={valueSuites}
        buttonTitle="Создать"
      />
    </>
  );
}

TestCycleFormForRun.propTypes = {
  selectTestCycleCallback: PropTypes.func,
  importTestCaseCallback: PropTypes.func,
  toggleModal: PropTypes.func,
  typeOfTesting: PropTypes.object,
  partition: PropTypes.string,
};

export default TestCycleFormForRun;

import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { QaConstants } from '../../constants/qaConstants';

const initialState = {
  fetching: false,
  data: [],
  dataSuite: [],
  dataCycle: [],
  allCases: [],
  error: '',
  fetchingCase: false,
  allCasesFetching: false,
  fetchingTestCaseCycle: false,
  fetchingTestCaseSuite: false,
  singleCase: [],
  singleCaseStatus: [],
  errorCase: [],
  filterData: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ProjectTypeCommonConstants.UPDATE_FILTER_LIST_TEST_CASE:
      return {
        ...state,
        filterData: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_SUITE_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchingTestCaseSuite: true,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_SUITE_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetchingTestCaseSuite: false,
        dataSuite: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_SUITE_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchingTestCaseSuite: false,
        dataSuite: [],
        error: payload,
      };

    case QaConstants.REMOVE_TEST_CASE_FROM_SUITE_REQUEST:
      return {
        ...state,
        fetchingTestCaseSuite: true,
      };
    case QaConstants.REMOVE_TEST_CASE_FROM_SUITE_SUCCESS:
      return {
        ...state,
        fetchingTestCaseSuite: false,
      };
    case QaConstants.REMOVE_TEST_CASE_FROM_SUITE_FAILURE:
      return {
        ...state,
        fetchingTestCaseSuite: false,
      };

    case QaConstants.REMOVE_TEST_CASE_FROM_CYCLE_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchingTestCaseCycle: true,
      };
    case QaConstants.REMOVE_TEST_CASE_FROM_CYCLE_SUCCESS:
      return {
        ...state,
        fetchingTestCaseCycle: false,
      };
    case QaConstants.REMOVE_TEST_CASE_FROM_CYCLE_FAILURE:
      return {
        ...state,
        fetchingTestCaseCycle: false,
      };

    case ProjectTypeCommonConstants.CLEAR_LIST_OF_TEST_CASES_IN_SUITE:
      return {
        ...state,
        dataSuite: [],
      };

    case ProjectTypeCommonConstants.UPDATE_LIST_OF_TEST_CASES_IN_SUITE:
      return {
        ...state,
        dataSuite: payload,
      };

    case ProjectTypeCommonConstants.ADD_TEST_CASES_OF_LIST_IN_SUITE:
      return {
        ...state,
        dataSuite: [...state.dataSuite, payload],
      };

    case ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchingTestCaseCycle: true,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetchingTestCaseCycle: false,
        dataCycle: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchingTestCaseCycle: false,
        dataCycle: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.CLEAR_LIST_OF_TEST_CASES_IN_CYCLE:
      return {
        ...state,
        dataCycle: [],
      };

    case ProjectTypeCommonConstants.UPDATE_LIST_OF_TEST_CASES_IN_CYCLE:
      return {
        ...state,
        dataCycle: payload,
      };

    case ProjectTypeCommonConstants.LIST_OF_ALL_TEST_CASES_REQUEST:
      return {
        ...state,
        allCasesFetching: true,
      };
    case ProjectTypeCommonConstants.LIST_OF_ALL_TEST_CASES_SUCCESS:
      return {
        ...state,
        allCasesFetching: false,
        allCases: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_ALL_TEST_CASES_FAILURE:
    case ProjectTypeCommonConstants.CLEAR_LIST_OF_ALL_TEST_CASES:
      return {
        ...state,
        allCasesFetching: false,
        allCases: [],
      };

    case ProjectTypeCommonConstants.NEW_TEST_CASE_IN_SUITE_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.NEW_TEST_CASE_IN_SUITE_SUCCESS:
      return {
        ...state,
        fetching: false,
        // data: state.data.concat(payload)
      };
    case ProjectTypeCommonConstants.NEW_TEST_CASE_IN_SUITE_FAILURE:
      return {
        ...state,
        fetching: false,
        dataSuite: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.NEW_TEST_CASE_IN_CYCLE_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.NEW_TEST_CASE_IN_CYCLE_SUCCESS:
      return {
        ...state,
        fetching: false,
        // data: state.data.concat(payload)
      };
    case ProjectTypeCommonConstants.NEW_TEST_CASE_IN_CYCLE_FAILURE:
      return {
        ...state,
        fetching: false,
        dataCycle: [],
        error: payload,
      };

    case ProjectTypeCommonConstants.GET_TEST_CASE_REQUEST:
      return {
        ...state,
        fetchingCase: true,
      };
    case ProjectTypeCommonConstants.GET_TEST_CASE_SUCCESS:
      return {
        ...state,
        fetchingCase: false,
        singleCase: payload,
      };
    case ProjectTypeCommonConstants.GET_TEST_CASE_FAILURE:
      return {
        ...state,
        fetchingCase: false,
        singleCase: [],
        errorCase: payload,
      };

    case ProjectTypeCommonConstants.UPDATE_ONE_TEST_CASE_IN_STORE:
      return {
        ...state,
        singleCase: payload,
      };
    case ProjectTypeCommonConstants.CLEAR_SINGLE_CASE:
      return {
        ...state,
        singleCase: [],
      };
    case ProjectTypeCommonConstants.EDIT_TEST_CASE_DATA_GRID_REQUEST:
      return {
        ...state,
      };
    case ProjectTypeCommonConstants.EDIT_TEST_CASE_DATA_GRID_SUCCESS:
      return {
        ...state,
        singleCaseStatus: payload,
      };
    case ProjectTypeCommonConstants.EDIT_TEST_CASE_DATA_GRID_FAILURE:
      return {
        ...state,
        singleCaseStatus: [],
        errorCase: payload,
      };

    case ProjectTypeCommonConstants.EDIT_TEST_CASE_IN_SUITE_REQUEST:
      return {
        ...state,
      };
    case ProjectTypeCommonConstants.EDIT_TEST_CASE_IN_SUITE_SUCCESS:
      return {
        ...state,
        dataSuite: payload,
      };
    case ProjectTypeCommonConstants.EDIT_TEST_CASE_IN_SUITE_FAILURE:
      return {
        ...state,
        errorCase: payload,
      };

    case ProjectTypeCommonConstants.CLEAR_TEST_CASE:
      return initialState;

    default: return state;
  }
};

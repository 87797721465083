import React, { useState } from 'react';
import { array, bool, func, number, object, string } from 'prop-types';

import { capitalize, get } from 'lodash';
import cn from 'classnames';

import { ConfigProvider, Dropdown, Flex, Segmented, Space, Spin } from 'antd';

import Icon from '@mdi/react';
import { mdiDotsVertical } from '@mdi/js';

import { stopPropagation } from '../../../54origins/utils54origins';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import SuggestedDayListOfIssues from './SuggestedDayListOfIssues';
import { getClassBtn, modeOptions, plansModeOptions, plansMods } from '../project/generalPlanboard/utils';
import TotalDayHoursButtons from './plansForDayComponents/TotalDayHoursButtons';
import WrapperAddRecurringIssue from '../recurringIssue/newRecurringIssue/WrapperAddRecurringIssue';
import CollectRecurringIssuesModal from '../recurringIssue/recurringIssueView/CollectRecurringIssuesModal';
import RecurringIssuesCollectTable from '../recurringIssue/recurringIssueView/RecurringIssueCollectTable';
import { t } from 'i18next';
import { antNotification } from '../../../MainUtils';
import { defaultIssuesPagination, defaultOrderRules } from '../issuesView/utils';
import useTableFilter from '../../../components/_ui/hooks/useTableFilter';
import { defaultIssueFilter } from '../issuesView/constants/issueFilterOptions';
import { useDispatch, useSelector } from 'react-redux';
import { listOfRecurringIssuesFromStore } from '../../reducers/recurringIssues/RecurringIssueSlicer';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { entityCreate, incrementAndGetCount } from '../../../entity/actions/entityActions';

import { head, isEmpty } from "ramda";
import { partitionNamesConfig } from '../../../api/appConfig';
import useMyRecurringIssues from '../recurringIssue/hooks/useMyRecurringIssues';

function SuggestedPlan({
  issues,
  loading,
  onActionCallback,
  paginationSettings = {},
  mode,
  changeMode,
  showClosed,
  setShowClosed,
  hideElements = [],
  isMainBoard = false,
  projects = [],
  sheduleHours = 0,
  selectedProjects = [],
  setSelectedProjects,
  isGeneralPlanboard = false,
  currentDay
}) {
  const {
    limit,
    total,
    setLimit,
    setPartOfConfig,
    setNewPage,
    newCurrentPage,
    issueFilterConfig,
    changeIssueFilterConfig,
  } = paginationSettings;
  

  const dispatch = useDispatch();

  const [plansMode, setPlansMode] = useState(plansMods.ISSUES_MODE);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedRecurringIssues, setSelectedRecurringIssues] = useState([]);
  const [recurringloading, setRecurringLoading] = useState(true);

  const recurringIssues = useSelector(listOfRecurringIssuesFromStore);
  const partition = null;

  const { requestCreateIssueFromRecurring } = useMyRecurringIssues()

  const {
    filterConfig: recurringIssueFilterConfig,
    changeFilterConfig: changeRecurringIssueFilterConfig,
  } = useTableFilter('recurring', defaultIssueFilter);

  const [recurringIssuesConfig, setRecurringIssuesConfig] = useState({
    ...defaultOrderRules,
    ...defaultIssuesPagination,
    ...recurringIssueFilterConfig,
  });

  const [isCollecting, setIsCollecting] = useState(false)

  const btnBlue = "border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white";
  const btnGreen = "border border-green-500 text-green-500 hover:bg-green-500 hover:text-white";
  const btnRed = "border border-red-500 text-red-500 hover:bg-red-500 hover:text-white";

  const btnDropdownStyle = { height: 25 };

  async function onChangeCallback() {
    setRecurringLoading(true);
    const getSelectedRecurringIssues = selectedRecurringIssues.map((uuid) => {
      return recurringIssues.find((issue) => issue.uuid == uuid)
    })

    getSelectedRecurringIssues.forEach(issue => {
      requestCreateIssueFromRecurring(issue, partitionType, partition)
    });

    setSelectedRecurringIssues([]);

    //updateIssuesScoringData(res?.params?.priority, res?.params?.status);

    antNotification(
      "success",
      t(
        "notifications.text.success.new_issues_created",
        "New issues has been successfully added"
      )
    );

    // if (createIssueCallback) {
    //   createIssueCallback(res?.uuid, res);
    // }
  }

  function handleCreate() {
    if (!isEmpty(selectedRecurringIssues)) {
      onChangeCallback();
    }
    setIsCollecting(false);
  }

  const DropDownBtn = () => {
    return <Dropdown
      menu={{
        items: [{
          key: '0',
          label: <BaseButton
            className={cn(`w-full`, {
              [btnGreen]: !showClosed,
              [btnRed]: showClosed,
            })}
            size="small"
            style={btnDropdownStyle}
            onClick={() => setShowClosed(prev => !prev)}
          >
            {showClosed ? 'Hide completed issues' : 'Show completed issues'}
          </BaseButton>
        },
        ].filter(el => !el.hidden),
      }}
    >
      <BaseButton
        className={cn(`${btnBlue}`)}
        size="small"
        onClick={(e) => stopPropagation(e)}
      >
        <Icon
          path={mdiDotsVertical}
          className='-mx-1'
          size={0.8}
        />
      </BaseButton>
    </Dropdown>
  }

  const titleRender = () => {
    const totalHours = issues.reduce((acc, el) => acc + get(el, 'data.chunk'), 0) / 2;

    const isUserMode = 'user' === mode;
    const isAllMode = 'all' === mode;

    return (
      <Flex justify='space-between' align='center' className='my-2'>
        <Flex align='center'>
          <h6 className='mt-2 mr-1'>
            {isUserMode && 'My unplanned issues'}
            {isAllMode && 'All unplanned issues'}
          </h6>
          <Space.Compact>
            {!hideElements.includes("showAllBtn") &&
                <BaseButton
                id="showMyIssuesButton"
                className="btnPrimary-outline ml-2"
                size="small"
                onClick={() => {
                  if (isUserMode) changeMode?.('all');
                  if (isAllMode) changeMode?.('user');
                }}
              >
                {isUserMode && 'Show all issues'}
                {isAllMode && 'Show my issues'}
              </BaseButton>
            }
            
           {!hideElements.includes('showCompletedBtn') &&  <DropDownBtn />}
          </Space.Compact>
        </Flex>
        {totalHours >= 0 ? <span className='text-sm text-blue-500'>
          These issues will be completed in {totalHours} hours (~{Math.ceil(totalHours / 8)} days)
        </span> : null}
      </Flex>
    )

  }

  return (
    <BaseCard
      title={titleRender()}
      className={"issue-card-issues-view"}
    >
      <Spin spinning={loading}>
        <SuggestedDayListOfIssues
          data={issues}
          onChangeRowCallback={(uuid) => onActionCallback(uuid, 'q-view')}
          onActionCallback={onActionCallback}
          totalCount={total}
          newCurrentPage={newCurrentPage}
          onChangePageCallback={setNewPage}
          limit={limit}
          setLimit={setLimit}
          getIssuesRequestSetting={setPartOfConfig}
          issueFilterConfig={issueFilterConfig}
          changeIssueFilterConfig={changeIssueFilterConfig}
          isMainBoard={isMainBoard}
          projects={projects}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          isGeneralPlanboard={isGeneralPlanboard}
          currentDay={currentDay}
        />
      </Spin>
    </BaseCard >

  );
}

SuggestedPlan.propTypes = {
  issues: array,
  loading: bool,
  mode: string,
  changeMode: func,
  onRowClickCallback: func,
  onActionCallback: func,
  paginationSettings: object,
  showClosed: bool,
  setShowClosed: func,
  hideElements: array,
  isMainBoard: bool,
  projects: array,
  sheduleHours: number,
  setSelectedProjects: func,
  isGeneralPlanboard: bool,
  currentDay: string
}

export default SuggestedPlan;

// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2024.03.29 || YYYY/MM/DD
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

function Login54Origins({
  url,
  doNotMakeRedirect = false,
  serviceName = '',
  afterSaveSession,
  hiddenElements = [],
  reloadJsAfterLoadHTML = true,
}) {
  const valueRef = useRef([]);

  const initHTML = '<div style=\'display: flex;align-items: center;justify-content: center;height: 100%;\'>Loading...</div>';

  const [serverHtml, changeServerHtml] = useState(initHTML);

  const appendScript = (typeOfElement, scriptToAppend, id) => new Promise((resolve) => {
    const script = document.createElement('script');
    const scriptID = `customID${id}`;
    script.async = '';
    script.id = scriptID;

    valueRef.current = [...valueRef.current, scriptID];

    if (typeOfElement === 'scriptSrc') {
      script.src = scriptToAppend;
    } else {
      script.innerHTML = scriptToAppend;
    }

    if (!document.getElementById(scriptID)) {
      try {
        document.body.appendChild(script);
      } catch (e) {
        console.log('eeee', e);
      }
    }

    resolve();
  });

  const makePromiseStyle = (href) => new Promise((resolve, reject) => {
    const link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';
    link.onload = resolve;

    document.body.appendChild(link);
  });

  const appendScriptsToDocument = async (form) => {
    const listOfScripts = form.match(/<script[\s\S]*?>[\s\S]*?<\/script>/gi);

    for (let i = 0; i < listOfScripts.length; i++) {
      const singleScript = listOfScripts[i];

      if (singleScript.includes('src=')) {
        await appendScript('scriptSrc', singleScript.split('"')[1], i);
      } else {
        await appendScript('scriptBody', singleScript.substring(singleScript.indexOf('<script>') + 8, singleScript.indexOf('</script>')), i);
      }
    }
  };

  const appendStylesToDocument = async (form) => {
    const findAllStyles = form.match(/<link(.*)>/g);

    for (let i = 0; i < findAllStyles.length; i++) {
      await makePromiseStyle(findAllStyles[i].split('"')[1]);
    }
  };

  const removeRedirect = () => new Promise(((resolve) => {
    setTimeout((() => {
      // console.log('afterSaveSession 222', window.hasOwnProperty('afterSaveSession'));
      if (window.hasOwnProperty('afterSaveSession')) {
        window.afterSaveSession = () => {
          afterSaveSession?.();
        };
      }
      resolve();
    }), 1000);
  }));

  const reloadJsForLoginForm = () => new Promise((resolve, reject) => {
    setTimeout(() => {
      window.initFuncDefaultAuthorization?.();
      window.initQrCodeAuthorization?.();
      window.checkTemporarySessionCookie?.();

      resolve();
    }, 1500);
  });

  const getLoginForm = async (loginForm) => {
    let changedLoginForm = loginForm
      .substring(loginForm.indexOf('<!-- Start embed -->'), loginForm.indexOf('<!-- JS -->'))
      .replace('Logo', serviceName);

    const hideElemIntoLoginForm = (startText, endText) => {
      changedLoginForm = changedLoginForm.substring(0, changedLoginForm.indexOf(startText))
        + changedLoginForm.substring(changedLoginForm.indexOf(endText));
    };

    if (hiddenElements.includes('signUpSection')) {
      hideElemIntoLoginForm('<!-- Tab SignUp -->', '<!-- Button Help me 3 -->');
    }

    if (hiddenElements.includes('helpMeBtn')) {
      hideElemIntoLoginForm('<input class="a-collapse-open', '<div class="a-collapse-painel">');
    }

    if (hiddenElements.includes('signInBtn')) {
      hideElemIntoLoginForm('<label for="tabSignIn"', '<!-- Button Help me 3 -->');
    }

    await appendStylesToDocument(loginForm);
    await appendScriptsToDocument(loginForm);
    await changeServerHtml(changedLoginForm);

    if (reloadJsAfterLoadHTML) {
      await reloadJsForLoginForm();
    }

    if (doNotMakeRedirect) {
      await removeRedirect();
    }
  };

  const getLoginFormRequest = () => (
    axios({
      method: 'get',
      url: `${url}/authorization/`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(({ data }) => {
        getLoginForm(data);
      })
  );

  useEffect(() => {
    // localStorage.clear();

    getLoginFormRequest();
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: serverHtml,
      }}
    />
  );
}

export default Login54Origins;

Login54Origins.propTypes = {
  doNotMakeRedirect: PropTypes.bool,
  afterSaveSession: PropTypes.func,
  serviceName: PropTypes.string,
  url: PropTypes.string.isRequired,
  hiddenElements: PropTypes.array,
  reloadJsAfterLoadHTML: PropTypes.bool,
};

import { Col, Row } from 'antd';
import { bool, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExpandModeLayout from '../../../../../projectFlow/components/commonComponents/expandModeLayout/ExpandModeLayout';
import ListOfDocuments from '../../../../../projectFlow/components/docView&ideas/ListOfDocuments';
import DashboardDocOrIdeaView from './DashboardDocOrIdeaView';

import { useGetProjectsDocsOrIdeas } from '../../../../../userFlow/hooks/personalDashboardHooks/useGetProjectsDocsOrIdeas';
import { getDocumentOrIdea } from '../../../../../projectFlow/actions/DocumentsActions';
import { areFetchingProjDocs } from '../../../../../projectFlow/selectors/selectors';
import { clearDocument } from '../../../../../projectFlow/actions/projectFlowActions';
import useURLParams from '../../../../../hooks/useURLParams';
import useMouseMiddleRedirect from '../../../../../hooks/useMouseMiddleRedirect';
import { partitionNamesConfig } from '../../../../../api/appConfig';

const hiddenElements = ['createBtns', 'docsFilter', 'deleteIcon'];

function DashboardDocsTableWrapper({
  defaultPartition = partitionNamesConfig.partition1,
  readOnlyComments = false,
}) {
  const dispatch = useDispatch();

  const { getURLParams, setSearchParams, clearSearchParams } = useURLParams();
  const { onMouseMiddleClick } = useMouseMiddleRedirect();

  const { activeDoc } = getURLParams();
  const docsOfIdeasFetching = useSelector(areFetchingProjDocs);

  const [docsConfig, setDocsConfig] = useState({});
  const [showEditor, setShowEditor] = useState(false);

  useGetProjectsDocsOrIdeas('projectDocument', docsConfig);

  const onMouseMiddleClickHandler = (e, record) => {
    const { uuid: documentUUID } = record;
    const searchParams = { activeCard: 'documents', activeDoc: documentUUID };
    onMouseMiddleClick(e, searchParams);
  };

  const selectedDocument = (uuid) => {
    if (activeDoc === uuid) {
      clearSearchParams(['activeDoc']);
    } else {
      setSearchParams({ activeDoc: uuid });
    }
  };

  // useEffect(() => {
  //   if (activeDoc) {
  //     setShowEditor(false);
  //     dispatch(getDocumentOrIdea({ uuid: activeDoc }));
  //   } else {
  //     dispatch(clearDocument());
  //   }
  // }, [activeDoc]);

  return (
    <Row className="w-full h-full">
      <Col span={24} className="flex">
        <ExpandModeLayout localStorageTarget="documents">
          <ExpandModeLayout.Table>
            <ListOfDocuments
              fetching={docsOfIdeasFetching}
              partitionType={defaultPartition}
              hiddenElements={hiddenElements}
              onMouseMiddleClick={onMouseMiddleClickHandler}
              setCurrentConfigAndGetRequest={setDocsConfig}
              selectDocumentCallback={selectedDocument}
            />
          </ExpandModeLayout.Table>

          <ExpandModeLayout.Card>
            {activeDoc && (
              <DashboardDocOrIdeaView
                readOnlyComments={readOnlyComments}
                showEditor={showEditor}
                setShowEditor={setShowEditor}
                defaultPartition={defaultPartition}
                docType='projectDocument'
                activeDoc={activeDoc}
              />
            )}
          </ExpandModeLayout.Card>
        </ExpandModeLayout>
      </Col>
    </Row>
  );
}

DashboardDocsTableWrapper.propTypes = {
  defaultPartition: string,
  readOnlyComments: bool,
};

export default DashboardDocsTableWrapper;

import axiosRequest from '../../../api/apiAxios';
import { partitionNamesConfig } from '../../../api/appConfig';


export const createProjectLogRequest = (data) => {
  const config = {
    affix_type: 'metaLog',
    ...data,
  };

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'entity/affix/create',
        [
          'CREATE_PROJECT_LOG_REQUEST',
          'CREATE_PROJECT_LOG_SUCCESS',
          'CREATE_PROJECT_LOG_FAILURE',
        ],
        config,
        {
          partition: partitionNamesConfig.partition3,
          onSuccess: resolve,
          onFailure: reject,
        },
      ),
    );
  });
};

import React, {
    useRef,
    useState,
  } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { array, bool, func, object, string } from 'prop-types';
  import {
    Row,
    Col,
    Modal,
  } from 'antd';
  import dayjs from 'dayjs';
  
import IssueInfoRoot from '../../issues/issueInfo/IssueInfoRoot';
import useURLParams from '../../../../hooks/useURLParams';
import {
getIssueEntityFetching,
} from '../../../selectors/selectors';
import { antNotification } from '../../../../MainUtils';
import { stopPropagation } from '../../../../54origins/utils54origins';
import { computeIssueChunks, computePlan } from '../../../../myBoard/utils';
import { cloneDeep, concat, get, head, isEqual } from 'lodash';
import { entityUpdate } from '../../../../entity/actions/entityActions';
import { updateUserBoard } from '../../../../myBoard/actions/userBoardActions';
import SuggestedPlan from '../../myBoard/SuggestedPlan';
import PlansForDay from '../../myBoard/plansForDayComponents/PlansForDay';
import useMyBoardPlanInGeneralPlanboard from './useMyBoardPlanInGeneralPlanboard';
import useSuggestedIssuesInGeneralPlanboard from './useSuggestedIssuesInGeneralPlanboard';
import { isFutureDate } from './utils';

  function MyBoardForGeneralPlanboard({
    partition = "PM",
    customActor,
    onChangePlanCallback,
    projectUUID,
    userBoard,
    getClearIssue,
    isFetchingBoardIssues = false,
    projectUsers,
    projectTitle,
    // add new week planning props
    updateVectorRelatedGeneralIssues,
    todayVector,
    getListRelatedGeneralIssues,
    currentDay,
    renderedBoard,
    renderedIssues
  }) {
    const dispatch = useDispatch();
  
    const abort = useRef();
  
    const issueFetching = useSelector(getIssueEntityFetching);
    const [selectedIssue, setSelectedIssue] = useState(null)
  
    const { parent: userBoardStorageUUID, uuid: userBoardUUID } = userBoard;
    const actor = customActor;

    const todayVectorUUID = get(todayVector, 'uuid');
    const todayVectorIssues = get(todayVector, 'params.relatedGeneralIssues') || []
  
    const {
      getURLParams,
      clearSearchParams,
      addAndRemoveSearchParams,
    } = useURLParams();
  
    const { activeIssue } = getURLParams() || {};
    
    const {
      isFetchingSuggestedIssues,
      suggestedIssues,
      onUpdateSuggestedIssue,
      onReloadSuggestedIssues,
      onClearSuggestedIssues,
      suggestedOptions,
      paginationSettings,
    } = useSuggestedIssuesInGeneralPlanboard({
      actor: actor,
      partition: partition,
      projectUUID: projectUUID,
      userBoard: userBoard,
      renderedIssues,
    });
    
    const {
      myBoardPlans,
      isFetchingMyBoardIssues,
      onReloadMyBoardIssues,
      onUpdateIssueInMyBoard,
      onClearMyBoardPlan,
    } = useMyBoardPlanInGeneralPlanboard({
      actor: actor,
      partition: partition,
      projectUUID: projectUUID,
      userBoard: userBoard,
      renderedBoard,
      currentDay
    });
  
    const today = dayjs().format('YYYY-MM-DD');
    const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
  
    const onReloadAll = async () => {
      onClearMyBoardPlan();
      onClearSuggestedIssues();
      onReloadMyBoardIssues();
      onReloadSuggestedIssues();
    }
  
    const requestUpdateIssue = (data, callback) => {
      const constants = [
        'UPDATE_ISSUE_FOR_MY_BOARD_REQUEST',
        'UPDATE_ISSUE_FOR_MY_BOARD_SUCCESS',
        'UPDATE_ISSUE_FOR_MY_BOARD_FAILURE',
      ];
  
      const options = {
        partition,
        onSuccess: callback,
        onFailure: () => {
          antNotification('error', 'Failed to update issue');
          onReloadAll();
        },
      };
  
      return entityUpdate({
        data,
        constants,
        options,
      });
    };
    
    const requestUpdateUserBoard = (newParams = {}, callback) => {
      if (userBoardUUID && userBoardStorageUUID && partition && actor) {
        dispatch(updateUserBoard({
          entity_uuid: userBoardUUID,
          actor: actor,
          entity_type: 'userBoard',
          params: {
            ...newParams,
          },
          parent: userBoardStorageUUID
        }, {
          partition,
          onSuccess: callback,
          onFailure: () => {
            antNotification('error', 'Failed to update your Plan board');
            onReloadAll();
          }
        }))
      }
    }
  
    const {
      issueSignal,
    } = abort?.currnet || {};
  
  
    const changeAbortingState = (data) => {
      abort.currnet = {
        ...abort?.currnet || {},
        ...data,
      };
    };
  
    const getNewSignalAndChangeAbortingState = (paramFromState) => {
      const newController = new AbortController();
      const { signal } = newController;
  
      changeAbortingState({ [paramFromState]: newController });
      return signal;
    };
  
    const changeMyBoardHistory = (key, uuid) => {
      switch (key) {
        case 'issue':
          addAndRemoveSearchParams({ activeIssue: uuid });
          break;
        case 'clearIssue':
          clearSearchParams(['activeIssue']);
          break;
  
        default:
          break;
      }
    };
  
    const onChangeIssue = async (uuid) => {
      if (!issueFetching) {
        if (issueSignal) {
          issueSignal.abort();
        }
  
        // changeMyBoardHistory('issue', uuid);
        setSelectedIssue(uuid)
  
        await getClearIssue(uuid, getNewSignalAndChangeAbortingState('issueSignal'))
          .catch((error) => {
            if (error.message) {
              antNotification('error', error.message);
            }
            if (error?.message !== 'canceled') {
              changeMyBoardHistory('clearIssue');
            }
          });
      }
    };
  
    const resetIssueCallback = () => {
      setSelectedIssue(null)
      onReloadMyBoardIssues();
      onReloadSuggestedIssues();
      getListRelatedGeneralIssues()
      // changeMyBoardHistory('clearIssue')

    };
  
    const onDeleteIssueCallback = async (data) => {
      const uuid = get(data, 'uuid', '');
      const prevDayUUIDs = get(myBoardPlans, [`${currentDay}__uuids`], []);
      const prevDayData = get(myBoardPlans, [`${currentDay}__data`], []);
      const newDayUUIDs =  prevDayUUIDs.filter(el => el !== uuid);
      const newDayData = prevDayData.filter(el => newDayUUIDs.includes(el.uuid));
      

      await requestUpdateUserBoard({
        [currentDay]: newDayUUIDs,
        [`${currentDay}__data`]: newDayData,
      }, (res) => {
        if (onChangePlanCallback) {
          onChangePlanCallback(head(res))
        }
        onReloadMyBoardIssues(head(res));
      })
      
      
      const updateRelatedGeneralIssues = (vectorIssues, vectorUUID, uuid) => {
        const newRelatedGeneralIssues = vectorIssues.filter(el => el.uuid !== uuid);
        updateVectorRelatedGeneralIssues(vectorUUID, { relatedGeneralIssues: newRelatedGeneralIssues });
      };
      
        updateRelatedGeneralIssues(todayVectorIssues, todayVectorUUID, uuid);
      
      changeMyBoardHistory('clearIssue');
      resetIssueCallback()
    }
  
    const onActionCallback = async (value, type, e, day) => {
      stopPropagation(e);
      switch (type) {
        case 'q-view': {
          if (value === activeIssue) {
            resetIssueCallback();
          } else {
            onChangeIssue(value);
          }
          break;
        }
        case 'issue-create-and-add': {
          const prevData = get(myBoardPlans, [`${currentDay}__data`], []);
          const prevUUIDs = get(myBoardPlans, [`${currentDay}__uuids`], []);
          const prevSettings = get(myBoardPlans, [`${currentDay}__settings`], {});
  
          const uuid = get(value, 'uuid', '');
          const data = computeIssueChunks(value);
            
          requestUpdateUserBoard({
            [currentDay]: [...prevUUIDs, uuid],
            [`${currentDay}__settings`]: {
              ...prevSettings,
              last_key: prevSettings.last_key + 1,
            },
            [`${currentDay}__data`]: [
              ...prevData,
              {
                ...data,
                uuid: uuid,
                key: prevSettings.last_key + 1,
              }
            ]
          }, async (res) => {
            if (onChangePlanCallback) {
              onChangePlanCallback(head(res));
            }
            onReloadMyBoardIssues(head(res));
            onReloadSuggestedIssues(head(res));
          });
         
           const newIssue = {
            uuid: value.uuid,
            userBoardUUID: userBoardUUID,
            projectTitle: projectTitle, 
            projectUsers: projectUsers,
            data: {chunk: data.chunk}
          }
          const updateRelatedIssues = (vectorIssues, vectorUUID) => {
            updateVectorRelatedGeneralIssues(vectorUUID, {
              relatedGeneralIssues: [...vectorIssues, newIssue],
            });
          };
          if (isFutureDate(currentDay)) {
            updateRelatedIssues(todayVectorIssues, todayVectorUUID);
          } 
          break;
        }
        case 'issue-add': {
          const prevData = get(myBoardPlans, [`${currentDay}__data`], []);
          const prevUUIDs = get(myBoardPlans, [`${currentDay}__uuids`], []);
          const prevSettings = get(myBoardPlans, [`${currentDay}__settings`], {});
  
          const uuid = get(value, 'uuid', '');
          const data = get(value, 'data', {});

          requestUpdateUserBoard({
            [currentDay]: [...prevUUIDs, uuid],
            [`${currentDay}__settings`]: {
              ...prevSettings,
              last_key: prevSettings.last_key + 1,
            },
            [`${currentDay}__data`]: [
              ...prevData,
              {
                ...data,
                uuid: uuid,
                key: prevSettings.last_key + 1,
              }
            ]
          }, async (res) => {
            if (onChangePlanCallback) {
              onChangePlanCallback(head(res));
            }
            onReloadMyBoardIssues(head(res));
            onReloadSuggestedIssues(head(res));
          });
          const newIssue = {
            uuid: value.uuid,
            userBoardUUID: userBoardUUID,
            projectTitle: projectTitle, 
            projectUsers: projectUsers,
            data
          }
          const updateRelatedIssues = (vectorIssues, vectorUUID) => {
            updateVectorRelatedGeneralIssues(vectorUUID, {
              relatedGeneralIssues: [...vectorIssues, newIssue],
            });
          };
          if (isFutureDate(currentDay)) {
            updateRelatedIssues(todayVectorIssues, todayVectorUUID);
          } 
          break;
        }
        case 'issue-remove': {
          const prevUUIDs = get(myBoardPlans, [`${day}__uuids`], []);
          const prevData = get(myBoardPlans, [`${day}__data`], []);
  
          const newUUIDs = prevUUIDs.filter(uuid => uuid !== value);
          const newData = prevData.filter(el => newUUIDs.includes(el.uuid));

          requestUpdateUserBoard({
            [day]: newUUIDs,
            [`${day}__data`]: newData
          }, (res) => {
            if (onChangePlanCallback) {
              onChangePlanCallback(head(res))
            }
            onReloadMyBoardIssues(head(res));
            onReloadSuggestedIssues(head(res));
          });

          const updateRelatedGeneralIssues = (vectorIssues, vectorUUID, value) => {
            const newRelatedGeneralIssues = vectorIssues.filter(el => el.uuid !== value);
            updateVectorRelatedGeneralIssues(vectorUUID, { relatedGeneralIssues: newRelatedGeneralIssues });
          };
          if (isFutureDate(currentDay)) {
            updateRelatedGeneralIssues(todayVectorIssues, todayVectorUUID, value);
          }
          break;
        }
        case 'issue-time-update': {
          const uuid = get(value, 'uuid', '');
          const newTime = get(value, 'value', '');
  
          const currentDayData = get(myBoardPlans, [`${currentDay}__data`], []);
  
          // const todayUUIDs = get(myBoardPlans, [`${today}__uuids`], []);
          // const tomorrowUUIDs = get(myBoardPlans, [`${tomorrow}__uuids`], []);
          // const todayData = get(myBoardPlans, [`${today}__data`], []);
          // const tomorrowData = get(myBoardPlans, [`${tomorrow}__data`], []);

  
          const newCurrentDayData = currentDayData.map(el => {
            if (el.uuid === uuid) {
              return {
                ...el,
                chunk: newTime,
              }
            }
            return el
          });

          const newVectorIssues = todayVectorIssues.map(el => {
            if (el.uuid === uuid) {
              return {
                ...el,
                data: {chunk: newTime},
              }
            }
            return el
          });

          onUpdateIssueInMyBoard({
            day,
            data: newCurrentDayData,
          });
          
          requestUpdateUserBoard({ [`${currentDay}__data`]: newCurrentDayData }, (res) => {
            if (!isEqual(get(res, ['0', 'params', `${currentDay}__data`], []), newCurrentDayData)) {
              onReloadMyBoardIssues(head(res));
              onReloadSuggestedIssues(head(res));
            }
          });

          updateVectorRelatedGeneralIssues(todayVectorUUID, {relatedGeneralIssues: newVectorIssues})
          // if (todayUUIDs.includes(uuid) || tomorrowUUIDs.includes(uuid)) {
          //   const todayIssuePart = todayData.find(el => el.uuid === uuid);
          //   const tomorrowIssuePart = tomorrowData.find(el => el.uuid === uuid);
  
          //   const todayChunk = day === today ? newTime : get(todayIssuePart, 'chunk', 0);
          //   const tomorrowChunk = day === tomorrow ? newTime : get(tomorrowIssuePart, 'chunk', 0);
  
          //   const issueBoardData = get(value, 'issue.boardData', {});
  
          //  dispatch(requestUpdateIssue({
          //     entity_uuid: uuid,
          //     params: {
          //       boardData: {
          //         ...issueBoardData,
          //         chunk: newTime,
          //       }
          //     }
          //   },() => getListRelatedGeneralIssues()))
         
          // }
          break;
        }
        case 'issue-users-update': {
          const uuid = get(value, ['uuid'], '');
          const users = get(value, ['users'], []);

         await dispatch(requestUpdateIssue({
            entity_uuid: uuid,
            params: {
              users: users.map(el => ({ uuid: el })),
              usersSearch: users,
            }
          }, () => {
            onReloadMyBoardIssues()
            getListRelatedGeneralIssues()
          }));
          break;
        }
        case 'issue-complete': {
          const uuid = get(value, ['issue', 'uuid'], '');
          const newStatus = get(value, ['newStatus'], '');
          const completed = get(value, ['issue', 'completed'], []);
          const needComplete = get(value, ['complete'], false);
      
          const newCompleted = needComplete ? [...completed, actor] : completed
  
          dispatch(requestUpdateIssue({
            entity_uuid: uuid,
            params: {
              status: newStatus,
              completed: newCompleted,
              done: true
            }
          }, () => {
            onReloadMyBoardIssues()
            getListRelatedGeneralIssues()
          }));
          break;
        }
        case 'issue-reopen': {
          const uuid = get(value, ['uuid'], '');
          const completed = get(value, ['completed'], []);
          dispatch(requestUpdateIssue({
            entity_uuid: uuid,
            params: {
              completed: completed.filter(el => el !== actor),
              status: 'reopened',
              done: false
            }
          }, () => {
            onReloadMyBoardIssues()
            getListRelatedGeneralIssues()
          }));      
          break;
        }
        case 'issue-update-total-time': {
          const uuid = get(value, 'uuid', '');
          const prevboardData = get(value, 'issue.boardData', {})
          const newBoardData = {
            ...prevboardData,
            chunk: get(value, 'value', ''),
          }
  
          onUpdateSuggestedIssue({
            uuid,
            boardData: newBoardData
          });
          dispatch(requestUpdateIssue({
            entity_uuid: uuid,
            params: {
              boardData: newBoardData
            }
          }));
          break;
        }
        case 'day-remove-all': {
          const newTodayVectorIssues = todayVectorIssues.filter(el => el.userBoardUUID !== userBoardUUID)
          updateVectorRelatedGeneralIssues(todayVectorUUID, {relatedGeneralIssues: newTodayVectorIssues})

          requestUpdateUserBoard({
            [value]: [],
            [`${value}__data`]: [],
            [`${value}__settings`]: {},
          }, (res) => {
            if (onChangePlanCallback) {
              onChangePlanCallback(head(res))
            }
            onReloadMyBoardIssues(head(res));
            onReloadSuggestedIssues(head(res));
          });
          break;
        }
        case 'day-move-overtime-issues': {
          const nextDay = dayjs(day).add(1, 'day').format('YYYY-MM-DD');
          let newDayData = cloneDeep(get(myBoardPlans, [`${day}__data`], []));
          let newDayUUIDs = cloneDeep(get(myBoardPlans, [`${day}__uuids`], []));
          let newNextDayData = cloneDeep(get(myBoardPlans, [`${nextDay}__data`], []));
          let newNextDayUUIDs = cloneDeep(get(myBoardPlans, [`${nextDay}__uuids`], []));
          let newNextDaySettings = get(myBoardPlans, [`${nextDay}__settings`], {});
          let last_key = get(newNextDaySettings, 'last_key', 0);
          let newOvertime = get(value, 'overtime', 0) * 2;
  
          let prevKey = 0;
  
          while (newOvertime > 0) {
            const issue = newDayData?.[newDayData?.length - 1];
            if (newOvertime >= issue?.chunk) {
              prevKey--;
              newDayData.pop();
              newDayUUIDs = newDayUUIDs?.filter(el => el !== issue?.uuid)
              newNextDayUUIDs.push(issue?.uuid)
              newNextDayData.push({ ...issue, key: prevKey });
              newOvertime -= issue.chunk;
            } else {
              prevKey--;
              issue.chunk = issue?.chunk - newOvertime;
              newNextDayData.push({ ...issue, chunk: newOvertime, key: prevKey });
              newNextDayUUIDs.push(issue?.uuid)
              newOvertime = 0;
            }
          }
  
          newNextDayData?.sort((a, b) => get(a, ['key'], 0) - get(b, ['key'], 0))
          last_key = newNextDayData?.length;
  
          requestUpdateUserBoard({
            [day]: newDayUUIDs,
            [nextDay]: newNextDayUUIDs,
            [`${nextDay}__settings`]: {
              ...newNextDaySettings,
              last_key: last_key,
            },
            [`${day}__data`]: newDayData,
            [`${nextDay}__data`]: newNextDayData?.map((el, index) => ({ ...el, key: index })),
          }, (res) => {
            if (onChangePlanCallback) {
              onChangePlanCallback(head(res))
            }
            onReloadMyBoardIssues(head(res));
            onReloadSuggestedIssues(head(res))
          });
          break;
        }
        case 'day-available-time-update': {
          const prevDaySettings = get(myBoardPlans, [`${day}__settings`], {});
          onUpdateIssueInMyBoard({
            day,
            settings: {
              ...prevDaySettings,
              wasChangedTime: true,
              hours: value,
            }
          });
          requestUpdateUserBoard({
            [`${day}__settings`]: {
              ...prevDaySettings,
              wasChangedTime: true,
              hours: value,
            }
          });
          break;
        }
        case 'day-change-order-of-issues': {
          const { prevKey, newKey } = value;
          const prevData = get(myBoardPlans, [`${day}__data`], []);
  
          const newData = prevData.map(issue => {
            const key = get(issue, ['key'], 0);
            if (prevKey === key) {
              return { ...issue, key: newKey }
            }
            if (newKey === key) {
              return { ...issue, key: prevKey }
            }
            return issue;
          });
  
          onUpdateIssueInMyBoard({
            day,
            data: newData,
          });
  
          requestUpdateUserBoard({
            [`${day}__data`]: newData,
          });
  
          break;
        }
        default: return;
      }
    };
  
    const onComputePlan = async (day) => {
      const daySettings = get(myBoardPlans, [`${day}__settings`], {});
      const hours = get(daySettings, ['hours'], 8);
      const dayData = get(myBoardPlans, [`${day}`], [])
        .map(el => ({
          ...el,
          ...el.params,
        }))
        .filter(el => !el?.done);
  
      const uncompletedIssue = suggestedIssues?.filter(el => !el?.done)
      const { data, uuids } = computePlan(concat(uncompletedIssue, dayData), hours * 2);

      const newTodayVectorIssues = uuids.map(el => {
        return {
          uuid: el,
          userBoardUUID: userBoardUUID,
          projectTitle: projectTitle,
          projectUsers: projectUsers
        }
      })

      const filteredTodayVectorIssues = todayVectorIssues.filter(el => el.userBoardUUID !== userBoardUUID)
      
      updateVectorRelatedGeneralIssues(todayVectorUUID, 
        {
          relatedGeneralIssues: [...filteredTodayVectorIssues, ...newTodayVectorIssues]
      })

      requestUpdateUserBoard({
        [day]: uuids,
        [`${day}__data`]: data,
        [`${day}__settings`]: {
          ...daySettings,
          last_key: data.length - 1,
        },
      }, (res) => {
        if (onChangePlanCallback) {
          onChangePlanCallback(head(res))
        }
        onReloadMyBoardIssues(head(res));
        onReloadSuggestedIssues(head(res));
      })
    };
  
    return (
      <Row className='mt-2' gutter={[16, 16]}>
        <Col span={12}>
          {[today].map(day => {
            const dayPlan = get(myBoardPlans, [currentDay], []);
            const dayParams = get(myBoardPlans, [`${currentDay}__params`], {});
            
            return (<PlansForDay
              key={day}
              day={day}
              issues={dayPlan}
              dayParams={dayParams}
              partition={partition}
              loading={isFetchingMyBoardIssues || isFetchingBoardIssues}
              onReloadMyBoardIssues={onReloadMyBoardIssues}
              onActionCallback={onActionCallback}
              onComputePlanCallback={onComputePlan}
              customParentIssue={projectUUID}
              actorUUID={customActor}
              projectUsers={projectUsers}
              showMoveIssues={false}
              isGeneralPlanboard={true}
              currentDay={currentDay}
            />)
          })}
        </Col>
        <Col span={12}>
          <SuggestedPlan
            issues={suggestedIssues}
            loading={isFetchingSuggestedIssues}
            onActionCallback={onActionCallback}
            paginationSettings={paginationSettings}
            isGeneralPlanboard={true}
            currentDay={currentDay}
            {...suggestedOptions}
          />
        </Col>
         {selectedIssue && (
          <Modal
            open
            footer={null}
            destroyOnClose
            closable={false}
            onCancel={resetIssueCallback}
            width={820}
          >
            {selectedIssue && <IssueInfoRoot
              // hiddenView={hiddenInIssueInfo}
              // disabledView={disabledInIssueInfo}
              partitionType={partition}
              resetIssueCallback={resetIssueCallback}
              deleteCallback={onDeleteIssueCallback}
              onChangeRowCallback={(uuid) => onActionCallback(uuid, 'q-view')}
              customProject={projectUUID}
            />}
          </Modal>
        )}
      </Row>
    );
  }
  
  MyBoardForGeneralPlanboard.propTypes = {
    partition: string,
    customActor: string,
    onChangePlanCallback: func,
    userBoard: object,
    projectUUID: string,
    getClearIssue: func,
    projectUsers: array,
    projectTitle: string,
    day: string,
    updateVectorRelatedGeneralIssues: func,
    todayVector: object,
    relatedGeneralIssues: array,
    getListRelatedGeneralIssues: func,
    currentDay: string,
    isFetchingBoardIssues: bool,
    renderedBoard: object,
    renderedIssues: object
  };
  
  export default MyBoardForGeneralPlanboard;
  
import { omit } from 'lodash';
import { prop } from 'ramda';
import { ProjectTypeCommonConstants } from '../constants/Constants';
import {
  entityRead,
  getListAndReadEntities,
  entityUpdate,
  entityCreate,
  getListAndPartialReadEntities,
  entityDelete,
  getListOfEntities,
} from '../../entity/actions/entityActions';
import { antNotification, capitalizeFirstChar, getArrayForItem } from '../../MainUtils';
import { axiosRequestPost } from '../../api/apiAxios';
import { getOrCreateSingle } from '../../entity/actions/entityActions';

export const getOrCreateQAEntity= ({ parent, partition, ...options }) => (dispatch) => dispatch(getOrCreateSingle({
  data: {
      entity_type: 'QA',
      parent: parent,
      ...options,
  },
  partition: partition,
  constants: [
      ProjectTypeCommonConstants.GET_OR_CREATE_QA_ENTITY_REQUEST,
      ProjectTypeCommonConstants.GET_OR_CREATE_QA_ENTITY_SUCCESS,
      ProjectTypeCommonConstants.GET_OR_CREATE_QA_ENTITY_FAILURE,
  ],
}));

export const updateQAEntity = ({data, partition}) => (dispatch) => dispatch(entityUpdate({
  data: data, //params{}, entity_uuid
  constants: [
    ProjectTypeCommonConstants.UPDATE_QA_ENTITY_REQUEST,
    ProjectTypeCommonConstants.UPDATE_QA_ENTITY_SUCCESS,
    ProjectTypeCommonConstants.UPDATE_QA_ENTITY_FAILURE,
  ],
  options: {partition: partition}
}));

export const clearSuite = { type: ProjectTypeCommonConstants.CLEAR_TEST_SUITE };

export const clearSingleCase = { type: ProjectTypeCommonConstants.CLEAR_SINGLE_CASE };

export const clearOneTestCaseinTestSuite = { type: ProjectTypeCommonConstants.CLEAR_ONE_TEST_CASE_IN_SUITE_IN_STORE };

export const clearOneTestCaseinTestCycle = { type: ProjectTypeCommonConstants.CLEAR_ONE_TEST_CASE_IN_CYCLE_IN_STORE };

export const clearSingleCaseForClass = () => clearSingleCase;

export const clearOneTestCaseinTestSuiteForClass = () => clearOneTestCaseinTestSuite;

export const clearOneTestCaseinTestCycleForClass = () => clearOneTestCaseinTestCycle;

export const clearTestCycle = { type: ProjectTypeCommonConstants.CLEAR_TEST_CYCLE };

export const clearArchivedTestCycle = { type: ProjectTypeCommonConstants.CLEAR_ARCHIVED_TEST_CYCLE };

export const clearListOfTestCasesInCycle = { type: ProjectTypeCommonConstants.CLEAR_LIST_OF_TEST_CASES_IN_CYCLE };

export const clearListOfTestCasesInArchiveCycle = { type: ProjectTypeCommonConstants.CLEAR_LIST_OF_TEST_CASES_IN_ARCHIVE_IN_CYCLE };

export const saveUUIDForComment = (uuid) => ({
  type: ProjectTypeCommonConstants.SAVE_UUID_FOR_COMMENT,
  payload: uuid,
});

export const clearArchivedTestCycleActionForClass = () => clearArchivedTestCycle;

export const clearTestCycleActionForClass = () => clearTestCycle;

export const clearTestSuiteActionForClass = () => clearSuite;

export function updateFilterListTestCase(params) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.UPDATE_FILTER_LIST_TEST_CASE,
      payload: params,
    });
  };
}

export function updateFilterListTestCaseInArchive(params) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.UPDATE_FILTER_LIST_TEST_CASE_IN_ARCHIVE,
      payload: params,
    });
  };
}

export function updateOneTestCaseinTestSuite(data) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.UPDATE_ONE_TEST_CASE_IN_SUITE_IN_STORE,
      payload: data,
    });
  };
}

export function updateOneTestCaseinTestCycle(data) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.UPDATE_ONE_TEST_CASE_IN_CYCLE_IN_STORE,
      payload: data,
    });
  };
}

export function clearListTestCycleInStore(data) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_LIST_CYCLE_IN_STORE,
      payload: data,
    });
  };
}

export const updateListOfTestCaseInCycle = (data) => ({
  type: ProjectTypeCommonConstants.UPDATE_LIST_OF_TEST_CASES_IN_CYCLE,
  payload: data,
});

export function clearListTestCaseInCycleInStore(data) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_LIST_OF_TEST_CASES_IN_CYCLE,
      payload: data,
    });
  };
}

export function clearListTestCaseInArchiveInCycleInStore(data) {
  return (dispatch) => {
    dispatch({
      type: ProjectTypeCommonConstants.CLEAR_LIST_OF_TEST_CASES_IN_ARCHIVE_IN_CYCLE,
      payload: data,
    });
  };
}

export const clearListTestCaseInSuiteInStore = { type: ProjectTypeCommonConstants.CLEAR_LIST_OF_TEST_CASES_IN_SUITE };

// START SUITE SECTION

export function createTestSuite({
  parent,
  params,
  partition,
  customConstants,
}) {
  const data = {
    entity_type: 'testSuite',
    parent,
    params: {
      ...params,
    },
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.NEW_TEST_SUITE_REQUEST,
    ProjectTypeCommonConstants.NEW_TEST_SUITE_SUCCESS,
    ProjectTypeCommonConstants.NEW_TEST_SUITE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityCreate({
    data,
    constants,
    options,
  });
}

export function updateTestSuite({
  uuid,
  params,
  partition,
  customConstants,
}) {
  const data = {
    entity_type: 'testSuite',
    entity_uuids: getArrayForItem(uuid),
    params: { ...params },
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.EDIT_TEST_SUITE_REQUEST,
    ProjectTypeCommonConstants.EDIT_TEST_SUITE_SUCCESS,
    ProjectTypeCommonConstants.EDIT_TEST_SUITE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}

export function getTestSuiteFunc({
  uuid,
  partition,
  signal,
}) {
  const data = {
    entity_type: 'testSuite',
    entity_uuids: getArrayForItem(uuid),
  };

  const constants = [
    ProjectTypeCommonConstants.GET_TEST_SUITE_REQUEST,
    ProjectTypeCommonConstants.GET_TEST_SUITE_SUCCESS,
    ProjectTypeCommonConstants.GET_TEST_SUITE_FAILURE,
  ];

  const options = {
    partition,
    signal,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

// END SUITE SECTION
// START CYCLE SECTION

export function createTestCycle({
  parent,
  params,
  partition,
  customConstants,
}) {
  const data = {
    entity_type: 'testCycle',
    parent,
    params: {
      ...params,
    },
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.NEW_TEST_CYCLE_REQUEST,
    ProjectTypeCommonConstants.NEW_TEST_CYCLE_SUCCESS,
    ProjectTypeCommonConstants.NEW_TEST_CYCLE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityCreate({
    data,
    constants,
    options,
  });
}

export function getTestCycleFunc({
  uuid,
  partition,
  cancelToken,
  customConstants,
}) {
  const data = {
    entity_type: 'testCycle',
    entity_uuids: getArrayForItem(uuid),
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.GET_TEST_CYCLE_REQUEST,
    ProjectTypeCommonConstants.GET_TEST_CYCLE_SUCCESS,
    ProjectTypeCommonConstants.GET_TEST_CYCLE_FAILURE,
  ];

  const options = {
    aborting: true,
    cancelToken,
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

export function updateTestCycle({
  uuid,
  params,
  partition,
  customConstants,
}) {
  const data = {
    entity_type: 'testCycle',
    entity_uuids: getArrayForItem(uuid),
    params,
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.EDIT_TEST_CYCLE_REQUEST,
    ProjectTypeCommonConstants.EDIT_TEST_CYCLE_SUCCESS,
    ProjectTypeCommonConstants.EDIT_TEST_CYCLE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}
export function updateChecklist({
  uuid,
  params,
  partition,
  customConstants,
}) {
  const data = {
    entity_type: 'checklist',
    entity_uuids: getArrayForItem(uuid),
    params,
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.EDIT_CHECKLIST_REQUEST,
    ProjectTypeCommonConstants.EDIT_CHECKLIST_SUCCESS,
    ProjectTypeCommonConstants.EDIT_CHECKLIST_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}

export function getListOfTestCyclesFunc({
  parent,
  params,
  partition,
  limit,
  offset,
  search_data,
  constants,
  orderBy,
  order,
}) {
  const data = {
    entity_type: 'testCycle',
    parent,
    params: { ...params },
    limit,
    offset,
    search_data,
    params_fields: {
      id: 'id',
      title: 'title',
      status: 'status',
      progress: 'progress',
      assignToUser: 'assignToUser',
      helperInStatus: 'helperInStatus',
      cases_count: 'cases_count',
      vector: 'vector',
      description: 'description',
      start_date: 'start_date',
      end_date: 'end_date',
      counterBug: 'counterBug',
      typeOfTesting: 'typeOfTesting',
      modified_by: 'modified_by',
      modified_on: 'modified_on',
      executed_by: 'executed_by',
      executed_on: 'executed_on',
      clonedFrom: 'clonedFrom',
      archive: 'archive',
    },
  };

  if (orderBy) {
    data[orderBy === 'created' ? 'order_by' : 'order_by_params'] = orderBy;
    data.order = order;
  }

  const currentConstants = constants || [
    ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_REQUEST,
    ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_SUCCESS,
    ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_FAILURE,
  ];

  return getListAndPartialReadEntities({
    data,
    partition,
    constants: currentConstants,
  });
}

export function getArchivedTestCycleFunc({
  uuid,
  partition,
}) {
  const data = {
    entity_type: 'testCycle',
    entity_uuids: getArrayForItem(uuid),
  };

  const constants = [
    ProjectTypeCommonConstants.GET_TEST_CYCLE_IN_ARCHIVE_REQUEST,
    ProjectTypeCommonConstants.GET_TEST_CYCLE_IN_ARCHIVE_SUCCESS,
    ProjectTypeCommonConstants.GET_TEST_CYCLE_IN_ARCHIVE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

export function getListOfArchivedTestCyclesFunc({
  parent,
  params,
  partition,
  limit,
  offset,
  search_data,
  orderBy,
  order,
}) {
  const data = {
    entity_type: 'testCycle',
    parent,
    params,
    limit,
    offset,
    search_data,
    params_fields: {
      id: 'id',
      title: 'title',
      status: 'status',
      progress: 'progress',
      assignToUser: 'assignToUser',
      helperInStatus: 'helperInStatus',
      cases_count: 'cases_count',
      vector: 'vector',
      description: 'description',
      start_date: 'start_date',
      end_date: 'end_date',
      counterBug: 'counterBug',
      typeOfTesting: 'typeOfTesting',
      modified_by: 'modified_by',
      modified_on: 'modified_on',
      executed_by: 'executed_by',
      executed_on: 'executed_on',
      clonedFrom: 'clonedFrom',
      archive: 'archive',
    },
  };

  if (orderBy) {
    data[orderBy === 'created' ? 'order_by' : 'order_by_params'] = orderBy;
    data.order = order;
  }

  const constants = [
    ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_IN_ARCHIVE_REQUEST,
    ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_IN_ARCHIVE_SUCCESS,
    ProjectTypeCommonConstants.LIST_OF_TEST_CYCLES_IN_ARCHIVE_FAILURE,
  ];

  return getListAndPartialReadEntities({
    data,
    partition,
    constants,
  });
}

// END CYCLE SECTION
// START CASE SECTION

export function getTestCase({
  uuid,
  partition,
  customConstants,
}) {
  const data = {
    entity_type: 'testCase',
    entity_uuids: getArrayForItem(uuid),
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.GET_TEST_CASE_REQUEST,
    ProjectTypeCommonConstants.GET_TEST_CASE_SUCCESS,
    ProjectTypeCommonConstants.GET_TEST_CASE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

export function createTestCase({
  parent,
  params,
  partition,
  constants: customConstants,
}) {
  const data = {
    entity_type: 'testCase',
    parent,
    params,
  };

  const constants = customConstants || [
    'NEW_TEST_CASE_REQUEST',
    'NEW_TEST_CASE_SUCCESS',
    'NEW_TEST_CASE_FAILURE',
  ];

  const options = {
    partition,
  };

  return entityCreate({
    data,
    constants,
    options,
  });
}

export function createTestCaseForCycle(data) {
  const constants = data?.customConstants || [
    ProjectTypeCommonConstants.NEW_TEST_CASE_IN_CYCLE_REQUEST,
    ProjectTypeCommonConstants.NEW_TEST_CASE_IN_CYCLE_SUCCESS,
    ProjectTypeCommonConstants.NEW_TEST_CASE_IN_CYCLE_FAILURE,
  ];

  return createTestCase({
    ...omit(data, ['customConstants']),
    constants,
  });
}

export function createTestCaseForSuite({
  parent,
  params,
  partition,
}) {
  const constants = [
    ProjectTypeCommonConstants.NEW_TEST_CASE_IN_SUITE_REQUEST,
    ProjectTypeCommonConstants.NEW_TEST_CASE_IN_SUITE_SUCCESS,
    ProjectTypeCommonConstants.NEW_TEST_CASE_IN_SUITE_FAILURE,
  ];

  const data = {
    entity_type: 'testCase',
    parent,
    params,

  };

  const options = {
    partition,
  };

  return entityCreate({
    data,
    constants,
    options,
  });
}
export function getListOfTestCasesInCycleFunc({
  parent,
  params,
  partition,
  limit,
  offset,
  search_data,
  orderBy,
  order,
  customConstants,
  params_fields,
  fields,
}) {
  const data = {
    entity_type: 'testCase',
    parent,
    params: { ...params },
    limit,
    offset,
    search_data,
  };

  if (orderBy) {
    data[orderBy === 'created' ? 'order_by' : 'order_by_params'] = orderBy;
    data.order = order;
  }

  const constants = customConstants || [
    ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_REQUEST,
    ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_SUCCESS,
    ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_FAILURE,
  ];

  if (params_fields) {
    data.params_fields = params_fields;

    if (fields) {
      data.fields = fields;
    }

    return getListAndPartialReadEntities({
      data,
      partition,
      constants,
    });
  }

  return getListAndReadEntities({
    data,
    partition,
    constants,
  });
}

export function getListOfTestCasesInSuiteFunc({
  parent,
  params,
  partition,
  limit,
  offset,
  search_data,
  with_files,
  orderBy,
  order,
  customConstants,
}) {
  const data = {
    entity_type: 'testCase',
    parent,
    params: { ...params },
    limit,
    offset,
    search_data,
    params_fields: {
      id: 'id',
      title: 'title',
      description: 'description',
      comment: 'comment',
      typeOfTesting: 'typeOfTesting',
      order: 'order',
      steps: 'steps',
      casePriority: 'casePriority',
      assignToUser: 'assignToUser',
      status: 'status',
      template: 'template',
      counterBug: 'counterBug',
      executed_by: 'executed_by',
      executed_on: 'executed_on',
      tags: 'tags',
    },
    fields: {
      parent: 'parent',
      entity_type: 'entity_type',
      actor: 'actor',
      'actor.created': 'created',
      'actor.uinfo': 'uinfo',
    },
  };

  if (orderBy) {
    data.order_by_params = orderBy;
    data.order = order;
  }

  const constants = customConstants || [
    ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_SUITE_REQUEST,
    ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_SUITE_SUCCESS,
    ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_SUITE_FAILURE,
  ];

  return getListAndPartialReadEntities({
    data,
    partition,
    constants,
    with_files,
  });
}

export function getArchivedTestCaseFunc({
  uuid,
  partition,
}) {
  const data = {
    entity_type: 'testCase',
    entity_uuids: getArrayForItem(uuid),
  };

  const constants = [
    ProjectTypeCommonConstants.GET_TEST_CASE_IN_CYCLE_FROM_ARCHIVE_REQUEST,
    ProjectTypeCommonConstants.GET_TEST_CASE_IN_CYCLE_FROM_ARCHIVE_SUCCESS,
    ProjectTypeCommonConstants.GET_TEST_CASE_IN_CYCLE_FROM_ARCHIVE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

export function getListOfArchivedTestCasesFunc({
  parent,
  params,
  partition,
  limit,
  offset,
  search_data,
  orderBy,
  order,
}) {
  const data = {
    entity_type: 'testCase',
    parent,
    params: {
      ...params,
    },
    limit,
    offset,
    search_data,
    params_fields: {
      id: 'id',
      title: 'title',
      description: 'description',
      comment: 'comment',
      typeOfTesting: 'typeOfTesting',
      order: 'order',
      steps: 'steps',
      casePriority: 'casePriority',
      assignToUser: 'assignToUser',
      status: 'status',
      template: 'template',
      tags: 'tags',
      counterBug: 'counterBug',
      executed_by: 'executed_by',
      executed_on: 'executed_on',
    },
    fields: {
      parent: 'parent',
      entity_type: 'entity_type',
      actor: 'actor',
      'actor.created': 'created',
      'actor.uinfo': 'uinfo',
    },
  };

  if (orderBy) {
    data[orderBy === 'created' ? 'order_by' : 'order_by_params'] = orderBy;
    data.order = order;
  }

  const constants = [
    ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_FROM_ARCHIVE_REQUEST,
    ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_FROM_ARCHIVE_SUCCESS,
    ProjectTypeCommonConstants.LIST_OF_TEST_CASES_IN_CYCLE_FROM_ARCHIVE_FAILURE,
  ];

  return getListAndPartialReadEntities({
    data,
    partition,
    constants,
  });
}

export function updateListOfTestCase({
  parent,
  params,
  entity_uuids,
  partition,
}) {
  const data = {
    entity_type: 'testCase',
    entity_uuids,
    parent,
    params: {
      ...params,
    },
  };

  const constants = [
    ProjectTypeCommonConstants.UPDATE_LIST_TEST_CASE_REQUEST,
    ProjectTypeCommonConstants.UPDATE_LIST_TEST_CASE_SUCCESS,
    ProjectTypeCommonConstants.UPDATE_LIST_TEST_CASE_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}

export function updateTestCaseFunc({
  uuid,
  parent,
  params,
  partition,
  constants: customConstants,
  signal,
}) {
  const data = {
    entity_type: 'testCase',
    entity_uuids: getArrayForItem(uuid),
    parent,
    params: {
      ...params,
    },
  };

  const constants = customConstants || [
    ProjectTypeCommonConstants.EDIT_TEST_CASE_DATA_GRID_REQUEST,
    ProjectTypeCommonConstants.EDIT_TEST_CASE_DATA_GRID_SUCCESS,
    ProjectTypeCommonConstants.EDIT_TEST_CASE_DATA_GRID_FAILURE,
  ];

  const options = {
    partition,
    signal,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}

// END CASE  SECTION

export function updateDataIssueFunc({
  uuid,
  parent,
  params,
  partition,
}) {
  const data = {
    entity_type: 'issue',
    entity_uuids: getArrayForItem(uuid),
    parent,
    params: {
      ...params,
    },
  };

  const constants = [
    ProjectTypeCommonConstants.UPDATE_DATA_ON_CASE_IN_ISSUES_REQUEST,
    ProjectTypeCommonConstants.UPDATE_DATA_ON_CASE_IN_ISSUES_SUCCESS,
    ProjectTypeCommonConstants.UPDATE_DATA_ON_CASE_IN_ISSUES_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
}

// CHECK LIST SECTION

export function createChecklist({
  parent,
  params,
  partition,
  customConstants,
}) {
  const data = {
    entity_type: 'checklist',
    parent,
    params: {
      ...params,
    },
  };

  const constants = customConstants; /* || [ */
  //   ProjectTypeCommonConstants.NEW_TEST_CYCLE_REQUEST,
  //   ProjectTypeCommonConstants.NEW_TEST_CYCLE_SUCCESS,
  //   ProjectTypeCommonConstants.NEW_TEST_CYCLE_FAILURE,
  // ];

  const options = {
    partition,
  };

  return entityCreate({
    data,
    constants,
    options,
  });
}

export function getChecklistFunc({
  uuid,
  partition,
  signal,
}) {
  const data = {
    entity_type: 'checklist',
    entity_uuids: getArrayForItem(uuid),
  };

  const constants = [
    ProjectTypeCommonConstants.GET_CHECKLIST_REQUEST,
    ProjectTypeCommonConstants.GET_CHECKLIST_SUCCESS,
    ProjectTypeCommonConstants.GET_CHECKLIST_FAILURE,
  ];

  const options = {
    partition,
    signal,
  };

  return entityRead({
    data,
    constants,
    options,
  });
}

export function requestGetAllQaReports({
  projectUUID, entityUUID, customConstants, partition,
}) {
  const data = {
    entity_type: 'reportQA',
    parent: projectUUID,
    params: {
      founder: entityUUID,
    },
  };

  const constants = [
    ProjectTypeCommonConstants.GET_ALL_REPORTS_QA_REQUEST,
    ProjectTypeCommonConstants.GET_ALL_REPORTS_QA_SUCCESS,
    ProjectTypeCommonConstants.GET_ALL_REPORTS_QA_FAILURE,
  ];

  return getListAndReadEntities({
    data,
    partition,
    constants,
  });
}

export function requestDeleteQaReport({ entityUUID, partition }) {
  const data = {
    entity_type: 'reportQA',
    entity_uuids: entityUUID,
  };

  const constants = [
    'DELETE_REPORT_QA_REQUEST',
    'DELETE_REPORT_QA_SUCCESS',
    'DELETE_REPORT_QA_FAILURE',
  ];

  return entityDelete({
    data,
    partition,
    constants,
  });
}

export const requestCreateReport = ({ params, partition, parent }) => {
  const constants = [
    'CREATE_REPORT_REQUEST',
    'CREATE_REPORT_SUCCESS',
    'CREATE_REPORT_FAILURE',
  ];
  const options = {
    partition,
  };
  const data = {
    entity_type: 'reportQA',
    parent,
    params,
  };

  return entityCreate({
    data,
    constants,
    options,
  });
};

import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLocale } from '../../../locale/selectors';

import { capitalize } from 'lodash';

function DateRange({
  typePeriod,
  rangePickerValue,
  currentPeriod,
  disablePeriod,
}) {
  const locale = useSelector(getLocale);

  return (
    <h5 className="h5InDateBtn mb-0">
      <div className="flex  justify-center items-center">
        {typePeriod === 'rangePicker'
          ? (
            <div>
              {moment(rangePickerValue[0])
                .format('YYYY-MM-DD')}
              {' / '}
              {moment(rangePickerValue[1])
                .format('YYYY-MM-DD')}
            </div>
          ) : (
            <>
              <div className="mr-2">
                {moment(currentPeriod)
                  .locale(locale)
                  .startOf(typePeriod)
                  .format('DD')}
                {!disablePeriod && ` - ${(
                  moment(currentPeriod)
                    .locale(locale)
                    .endOf(typePeriod)
                    .format('DD'))}`}
              </div>
              <div>
                {capitalize(moment(currentPeriod)
                  .locale(locale)
                  .startOf(typePeriod)
                  .format('MMMM YYYY'))}
              </div>
            </>
          )}
      </div>
    </h5>
  );
}

export default DateRange;

import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { getPartitionPMUUID } from '../../../config/selectors/selectors';
import AffixData from './AffixData';
import EntityData from './EntityData';

export default function AllUserData({ user }) {
  const defaultConfig = {
    offset: 0,
    limit: 10,
    params: {},
  };

  const partitionPM = useSelector(getPartitionPMUUID);

  return (
    <Row gutter={[20, 20]} className="mt-3">
      <Col span={12}>
        <EntityData
          user={user}
          partitionPM={partitionPM}
          defaultConfig={defaultConfig}
        />
      </Col>
      <Col span={12}>
        <AffixData
          user={user}
          partitionPM={partitionPM}
          defaultConfig={defaultConfig}
        />
      </Col>
    </Row>
  );
}

import { partitionNamesConfig } from '../../../../api/appConfig';
import { entityCreate } from '../../../../entity/actions/entityActions';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';

const defaultPartition = partitionNamesConfig.partition1;

export const createNewDocumentRequest = ({
  parent,
  params,
  partition = defaultPartition,
}) => {
  const data = {
    entity_type: 'projectDocument',
    parent,
    params: {
      ...params,
    },
  };

  const constants = [
    ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_REQUEST,
    ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_SUCCESS,
    ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityCreate({
    data,
    constants,
    options,
  });
};

export const createNewIdeaRequest = ({
  parent,
  params,
  partition = defaultPartition,
}) => {
  const data = {
    entity_type: 'projectDocument',
    parent,
    params: {
      ...params,
    },
  };

  const constants = [
    ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_REQUEST,
    ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_SUCCESS,
    ProjectTypeCommonConstants.NEW_PROJECT_DOCUMENT_FAILURE,
  ];

  const options = {
    partition,
  };

  return entityCreate({
    data,
    constants,
    options,
  });
};

import { isNil } from 'lodash';

export const parseScoringColorForAudit = (score) => {
  if (score >= 3) {
    return 'error';
  }
  if (score >= 2) {
    return 'warning';
  }
  if (score >= 1) {
    return 'processing';
  }
  if (score >= 0 && score < 1) {
    return 'default';
  }
};

export const parseOutdatedColorForAudit = (score) => {
  if (score >= 100) {
    return 'error';
  }
  if (score >= 50) {
    return 'warning';
  }
  if (score > 0) {
    return 'processing';
  }
  if (score === 0) {
    return 'success';
  }
};

export const sortNumValues = (val1, val2) => {
  // console.log('val1, val2', val1, val2);
  const a = Number(val1);
  const b = Number(val2);
  const checkA = isNil(val1) || typeof val1 !== 'number';
  const checkB = isNil(val2) || typeof val2 !== 'number';
  if (checkA && checkB) {
    return 0;
  }
  if (!checkA && checkB) {
    return 1;
  }
  if (checkA && !checkB) {
    return -1;
  }
  if (!checkA && !checkB) {
    return a - b;
  }
};

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  isEmpty, isNil, pathOr, prop, propOr,
} from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import {
  Result, Input, Row, Flex,
} from 'antd';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { capitalize } from 'lodash';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
// import SelectActorsComponent from '../../../entity/components/SelectActorsComponent';
import UniGeneralButtons from '../uniGeneralButtons/UniGeneralButtons';
import AuthLoader from '../../../auth/components/AuthLoader';

import { listVectorsFetching, getListOfIssuesFetching } from '../../selectors/selectors';
import { getMainPublicProjectUUID, getUserPublicProfileUUID } from '../../../userFlow/store/selectors/selectors';
import { getPartitionPMUUID } from '../../../config/selectors/selectors';
import { getListAndPartialReadEntities } from '../../../entity/actions/entityActions';
import AssigningUsersRoot from '../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { partitionNamesConfig } from '../../../api/appConfig';

function CreateProjectOrVectorFromCommon({
  handleCancelModalCallback,
  setCookBookState,
  createEntities,
  cookBookState,
  toResetArea,
  isAssignOpen,
  setIsAssignOpen,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const vectorsFetching = useSelector(listVectorsFetching);
  const issuesFetching = useSelector(getListOfIssuesFetching);
  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);
  const pmPartition = useSelector(getPartitionPMUUID);
  const myPublicProjectEntityUUID = useSelector(getMainPublicProjectUUID);

  const [newDataProject, setNewDataProject] = useState([]);
  const [successVector, setSuccessVector] = useState([]);
  const [viewMode, setViewMode] = useState('');
  const [listCommonProjects, setListCommonProjects] = useState([]);
  const [listPublicProjects, setListPublicProjects] = useState([]);
  const [selectProjectFromInsert, setSelectProjectFromInsert] = useState({});
  const [typePartitionVector, setTypePartitionVector] = useState('');

  const linkInsert = {
    pathname: typePartitionVector === partitionNamesConfig.partition3
      ? `/public/user/${myPublicEntityUUID}/projects/issuesnew`
      : '/pm/projects/issuesnew',
    search: `?activeProject=${prop('uuid', selectProjectFromInsert)}`,
  };

  const getListProjects = (type = partitionNamesConfig.partition1) => {
    const parent = type === partitionNamesConfig.partition1
      ? pmPartition
      : myPublicProjectEntityUUID;

    const config = {
      data: {
        entity_type: 'project',
        parent,
        params_fields: {
          title: 'title',
        },
      },
      partition: type,
      constants: [
        'GET_LIST_PROJECTS_REQUEST',
        'GET_LIST_PROJECTS_SUCCESS',
        'GET_LIST_PROJECTS_FAILURE',
      ],
    };

    return dispatch(getListAndPartialReadEntities(config));
  };

  const handleCancelModalCreate = () => {
    setNewDataProject([]);
    setSuccessVector([]);
    handleCancelModalCallback();
    setSelectProjectFromInsert({});
    toResetArea();
    setTypePartitionVector('');
  };

  const createProject = (type) => {
    createEntities(type, null, (data) => {
      setNewDataProject([data, type]);
    });
  };

  const changeTitleProject = (e) => {
    setCookBookState((prev) => ({
      ...prev,
      jsObject: {
        ...prev.jsObject,
        params: {
          ...prev.jsObject.params,
          title: e.target.value,
        },
      },
    }));
  };

  const getUsersInProject = (data) => {
    setCookBookState((prev) => ({
      ...prev,
      jsObject: {
        ...prev.jsObject,
        params: {
          ...prev.jsObject.params,
          usersSearch: data,
        },
      },
    }));
  };

  const templateSuccessCreate = (link, type) => (
    <>
      {(!vectorsFetching && !issuesFetching) ? (

        <div>
          <Result
            status="success"
            title={`${type === 'project' ? 'Project' : 'Data'} successfully created!`}
          />
          <div className="flex justify-start items-start">
            <span className="text-base whitespace-nowrap flex justify-start items-start">
              {`${capitalize(t('wms.labels.go_to_project', 'go to project'))}:`}
            </span>
            <div>
              <Link
                className="ml-2"
                to={link}
              >
                {`${window.location.origin}${link.pathname}`}
              </Link>
            </div>

          </div>

          <div className="mt-4 flex justify-end">
            <BaseButton
              className="btnPrimary"
              onClick={handleCancelModalCreate}
            >
              {capitalize(t('wms.buttons.close', 'close'))}
            </BaseButton>
          </div>
        </div>
      ) : <AuthLoader />}
    </>
  );

  const viewCreateProject = () => {
    const [project, type] = newDataProject;

    const link = {
      pathname: type === partitionNamesConfig.partition3
        ? `/public/user/${myPublicEntityUUID}/projects/issuesnew`
        : '/pm/projects/issuesnew',
      search: `?activeProject=${propOr('', 'uuid', project)}`,
    };

    const title = pathOr('', ['jsObject', 'params', 'title'], cookBookState);
    return ((!isNil(project))
      ? templateSuccessCreate(link, 'project') : (

        <div>
          <h5 className="mt-3">
            {`${capitalize(t('wms.modals.body.create_project_in', 'create project in'))}:`}
          </h5>
          <hr />

          <div>
            <label>
              {`${capitalize(t('wms.labels.title_project', 'title project'))}:`}
              <span style={{
                color: title.length === 0 ? 'red' : 'green',
                fontSize: '16px',
                marginLeft: '3px',
              }}
              >
                *
              </span>
            </label>
            <Input
              value={title}
              onChange={changeTitleProject}
            />
          </div>

          <Flex className="mt-4 w-full">

            <AssigningUsersRoot
              header="Assigning users"
              isOpen={isAssignOpen}
              setIsOpen={setIsAssignOpen}
              onUpdateUsers={({ users }) => getUsersInProject(users?.map((el) => el?.uuid))}
              globalOptions={{
                uuids: pathOr('', ['jsObject', 'params', 'usersSearch'], cookBookState) ?? [],
                needLoadPerms: true,
                defaultColumn: 'all',
              }}
              placement="left"
              isPopover
              tabs={{
                allConfig: {
                  label: 'All',
                  value: 'all',
                  textSelectButton: 'Add user',
                  showSections: ['button-add-user'],
                },
              }}
              usersConfig={{
                label: 'Users',
                showSections: ['button-delete-user'],
                defaultItems: pathOr('', ['jsObject', 'params', 'usersSearch'], cookBookState) ?? [],
              }}
              partition={get(newDataProject, 'type', partitionNamesConfig.partition1)}
              popoverText="Assigning users"
            />
            <WrapperAvatarsGroupForProjectWithHook
              actors={pathOr('', ['jsObject', 'params', 'usersSearch'], cookBookState) || []}
              avatarSize={28}
              popoverTrigger="click"
              popoverPlacement="right"
            />
          </Flex>

          <div className="flex mt-4 w-full">
            <div className="user-flow-container-bth">
              <span className="p-3">
                {capitalize(t(
                  'wms.modals.body.description_creation_Public_Project',
                  ' You can create a project as a personal one. Only administrators and people to whom you grant rights will have access to it.',
                ))}

              </span>

              <div className="mt-4 flex justify-center">
                <BaseButton
                  className="btnPrimary"
                  onClick={() => createProject(partitionNamesConfig.partition3)}
                  disabled={title.length === 0}
                >
                  {capitalize(t('wms.labels.create_as_public_project', 'Create as my public project'))}
                </BaseButton>
              </div>

            </div>

            <div className="project-flow-container-bth">
              <span className="p-3">
                {capitalize(t(
                  'wms.modals.body.description_creation_common_Project',
                  ' create as a common cookbook',
                ))}

              </span>

              <div className="mt-4 flex justify-center">
                <BaseButton
                  className="btnPrimary"
                  onClick={() => createProject(partitionNamesConfig.partition1)}
                  disabled={title.length === 0}
                >
                  {capitalize(t('wms.labels.create_as_common_project', 'Create as a common project'))}
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const templateVectorInsertToProject = (typePartition) => {
    const listProject = typePartition === partitionNamesConfig.partition1
      ? listCommonProjects
      : listPublicProjects;

    const submitVectorInProject = () => {
      createEntities(typePartition, selectProjectFromInsert.uuid, (data) => {
        setSuccessVector([data]);
      });
    };

    if (typePartitionVector !== typePartition) {
      setTypePartitionVector(typePartition);
    }
    return (
      <div className="list-project-cookBook">
        <label className="text-base">
          {`Projects from ${typePartition === partitionNamesConfig.partition1 ? 'Project flow' : 'User flow'}:`}
        </label>
        <div className="list-project-cookBook_wrapper">
          {listProject.map((item) => {
            if (item === 'not perms') {
              return capitalize(t(
                'wms.modals.body.not_perm_in_project',
                'You do not have permission to access this list of projects. Please contact your administrator...',
              ));
            }

            return (
              <div
                className={
                  `list-project-cookBook_item
                    ${(
                    !isNil(selectProjectFromInsert.uuid)
                    && selectProjectFromInsert.uuid === item.uuid) ? 'active' : ''}
                    `
                }
                key={item.uuid}
                onClick={() => setSelectProjectFromInsert(item)}
              >
                {item.title}
              </div>
            );
          })}
        </div>

        <div className="btn-submit-wrapper">
          <BaseButton
            type="primary"
            onClick={submitVectorInProject}
            className="m-1"
            disabled={isEmpty(selectProjectFromInsert)}
          >
            {capitalize(t('wms.buttons.insert_project', 'insert to project'))}
          </BaseButton>
        </div>
      </div>
    );
  };

  const toggleSwitchView = async (data) => {
    setViewMode(data);
    if (data === 'my public projects' && listPublicProjects.length === 0) {
      const data = await getListProjects(partitionNamesConfig.partition3);
      setListPublicProjects(prop('data', data));
    }
  };

  const initFunc = async () => {
    if (prop('entityType', cookBookState) === 'vector') {
      const data = await getListProjects();

      if (prop('data', data) !== 'not perms') {
        setListCommonProjects(prop('data', data));
      } else {
        setListCommonProjects([prop('data', data)]);
      }
    }

    setViewMode('common projects');
  };

  useEffect(() => {
    initFunc();
  }, []);

  return (
    <>
      {(!isNil(prop('entityType', cookBookState)) && prop('entityType', cookBookState) !== 'vector')
        ? viewCreateProject()
        : (
          <>
            {!isEmpty(successVector)
              ? templateSuccessCreate(linkInsert, 'vector')
              : (
                <>
                  <Row style={{ borderBottom: '1px solid rgba(208, 200, 200, 0.344)' }}>
                    <UniGeneralButtons
                      viewMode={viewMode}
                      switchViewFunc={toggleSwitchView}
                      tabs={[
                        { name: 'Common projects' },
                        { name: 'My public projects' },
                      ]}
                    />
                  </Row>

                  {viewMode === 'common projects' && templateVectorInsertToProject('PM')}
                  {viewMode === 'my public projects' && templateVectorInsertToProject('PUBLIC')}

                </>
              )}
          </>
        )}
    </>
  );
}

CreateProjectOrVectorFromCommon.propTypes = {
  handleCancelModalCallback: PropTypes.func,
  setCookBookState: PropTypes.func,
  createEntities: PropTypes.func,
  cookBookState: PropTypes.object,
  toResetArea: PropTypes.func,
  entityType: PropTypes.string,
  isAssignOpen: PropTypes.bool,
  setIsAssignOpen: PropTypes.func,
};

export default CreateProjectOrVectorFromCommon;

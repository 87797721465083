import {
  equals, filter, find, isEmpty, map, path, pathOr, prop, propOr,
} from 'ramda';
import { createSelector } from 'reselect';
import { getDefaultActorsAndBanedUsers } from '../../selectors/selectors';
import {
  actorIsAdmin,
  actorIsRoot,
  actorIsRootOrAdmin,
  getNameForActor,
} from '../../54origins/utils54origins';

export const getRootEntityUuid = (state) => path(['EntityService', 'mainUuid', 'message'], state);

// export const getPartitionEntityType = (state) => path(['EntityService', 'partition', 'type'], state);
//
// export const getPartitionEntityUuid = (state) => path(['EntityService', 'partition', 'uuid'], state);
//
// export const isPartitionFetching = (state) => path(['EntityService', 'partition', 'fetching'], state);

export const getMainAreaUUID = (state) => pathOr(null, ['projectManagementService', 'area', 'mainArea', 'data', 'uuid'], state);

// export const getAreaPartitionFetching = (state) => path(['EntityService', 'areaPartition', 'fetching'], state);

// export const getAllPermissions = (state) => path(['EntityService', 'listOfPermissions', 'data'], state);

export const getListOfActors = (state) => path(['EntityService', 'actorList', 'message'], state);

export const getUsersFromGroups = (state) => path(['EntityService', 'actorList', 'actorsFromGroups'], state);

export const getListOfActorsFetching = (state) => path(['EntityService', 'actorList', 'fetching'], state);

export const getListOfActorsFetchingFromPerm = (state) => path(['EntityService', 'actorList', 'listOfActorsFetching'], state);

export const actorsByName = (state) => pathOr([], ['EntityService', 'actorList', 'ActorsByName'], state);

export const fetchingGroupInActors = (state) => path(['EntityService', 'actorList', 'fetchingActorsName'], state);

export const getUser = (state) => path(['EntityService', 'userInfo', 'user'], state);

export const getActorsPermsForEntity = (state) => pathOr([], ['EntityService', 'entityPerms', 'data'], state);

export const actorsPermsForEntityInMapByUuid = (state) => pathOr(new Map([]), ['EntityService', 'entityPerms', 'dataInMapByUuid'], state);

export const fetchingGetEntityPerm = (state) => pathOr('', ['EntityService', 'entityPerms', 'fetching'], state);

export const getUserName = (state) => getNameForActor(getUser(state));

export const allListEntityPerm = createSelector(
  getDefaultActorsAndBanedUsers,
  actorsByName,
  getActorsPermsForEntity,
  actorsPermsForEntityInMapByUuid,
  (defaultActors, actorsPermByName, actorsPermsForEntity, entityPermInMapByUuid) => {
    const templatePerms = {
      create: false,
      delete: false,
      list: false,
      read: false,
      set: false,
      update: false,
    };

    const defaultList = [...actorsPermByName, ...defaultActors];
    const newData = [];

    const checkActorAndSet = (item) => {
      const isRoot = actorIsRoot(item);

      if (!entityPermInMapByUuid.has(prop('uuid', item))) {
        const data = {
          actor_type: item.actor_type,
          actor_uuid: item.uuid,
          entity_uuid: '',
          perm_uuid: '',
          uinfo: item.uinfo,
          root: isRoot,
          ...templatePerms,
        };
        // console.log('data',data)
        newData.push(data);
        return;
      }
      const actorFromDefaultList = entityPermInMapByUuid.get(prop('uuid', item));
      newData.push({ ...actorFromDefaultList, root: isRoot });
    };

    for (let i = 0; i < defaultList.length; i++) {
      checkActorAndSet(defaultList[i]);
    }
    // console.log('SELECTOR-RESELECT', newData)
    return newData;
  },
);

export const getUsersEntity = (state) => filter(
  (actor) => actor.actor_type === 'user' && prop('first_name', actor.uinfo)
      || actor.actor_type === 'classic_user' && prop('first_name', actor.uinfo)
      || actor.actor_type === 'phantom' && prop('first_name', actor.uinfo),
  getListOfActors(state),
);

export const getGroupsEntity = (state) => filter((actor) => actor.actor_type === 'group', getListOfActors(state));

export const isAdminOrRoot = createSelector(
  getUser,
  (item) => actorIsRootOrAdmin(item),
);

export const getAdminGroupUUID = (state) => prop('uuid', getListOfActors(state).find((item) => path(['uinfo', 'group_name'], item) === 'ADMIN'));

export const getBanGroupUUID = (state) => prop('uuid', getListOfActors(state).find((item) => path(['uinfo', 'group_name'], item) === 'BAN'));

export const transformActorsToSelect = (actors) => {
  const firstName = (actor) => pathOr('name', ['uinfo', 'first_name'], actor);
  const lastName = (actor) => pathOr('last_name', ['uinfo', 'last_name'], actor);
  const getInitial = (string) => string?.charAt(0).toUpperCase();

  const getActorName = (item) => {
    let name = 'name not found';

    switch (item?.actor_type) {
      case 'user':
      case 'classic_user':
      case 'phantom':
        name = `${firstName(item)} ${lastName(item)}`;
        break;
      case 'group':
        name = pathOr('name', ['uinfo', 'group_name'], item);
        break;
      default:
        return name;
    }
    return name;
  };

  return actors.map((item) => ({
    value: prop('uuid', item) || prop('actor_uuid', item) || prop('value', item),
    label: getActorName(item),
    entityType: prop('actor_type', item),
    initials: `${getInitial(firstName(item))}${getInitial(lastName(item))}`,
    role: prop('role', item) || '',
    isAdmin: prop('isAdmin', item) || false,
  }));
};

export const getFilteredEntity = (state, id) => pathOr([], ['EntityService', 'entityFilter', 'filteredData', id], state);

export const isResetTags = (state) => path(['EntityService', 'entityFilter', 'resetTags'], state);

export const viewNotification = (state) => path(['EntityService', 'viewNotification', 'data'], state);

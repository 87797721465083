import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { head, prop } from 'ramda';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
/* eslint-disable camelcase */

import {
  Col, Flex, Form, Input, Row,
} from 'antd';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import RadioButtons from '../../../components/RadioButtons/RadioButtons';

import {
  antNotification,
  capitalizeAndTranslateMsg,
} from '../../../MainUtils';
import { capitalize } from 'lodash';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import {
  listVectorsFetching,
  getCommonFetching,
  getProjectParams,
  getVectorsScoringData,
  getProjectUUID,
} from '../../selectors/selectors';
import { getUserPublicProfileUUID } from '../../../userFlow/store/selectors/selectors';
import { incrementAndGetCount } from '../../../entity/actions/entityActions';
import { axiosRequestPost } from '../../../api/apiAxios';
import { sendNotification } from '../../../api/notificationsAPI';
import { vectorTypes } from '../../../entity/constants/EntityTypesConstants';
import EntityWorkTime from '../../../components/entityWorkTime/EntityWorkTime';
import { updateEntityScoring } from '../issuesView/utils';
import WrapperAvatarsGroupForProjectWithHook from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import AssigningUsersRoot from '../../../assignUsersModal/AssigningUsersRoot';
// import { getProjectRequest } from '../commonComponents/AssignedUsers/actions';
import { getUuid } from '../../../assignUsersModal/utils';
import { useResize } from '../../../hooks/useResize';
import { partitionNamesConfig } from '../../../api/appConfig';
import useFocus from "../../../54origins/hooks/useFocus54origins";

const priorityOptions = [
  {
    value: 'low',
    label: capitalizeAndTranslateMsg('wms.priority.low', 'low'),
    className: 'radio-blue',
  },
  {
    value: 'medium',
    label: capitalizeAndTranslateMsg('wms.priority.medium', 'medium'),
    className: 'radio-orange',
  },
  {
    value: 'high',
    label: capitalizeAndTranslateMsg('wms.priority.high', 'high'),
    className: 'radio-red',
  },
  {
    value: 'urgent',
    label: capitalizeAndTranslateMsg('wms.priority.urgent', 'urgent'),
    className: 'radio-red',
  },
];

const statusOptions = [
  {
    value: 'created',
    label: capitalizeAndTranslateMsg('wms.status.created', 'created'),
    className: 'radio-blue',
  },
  {
    value: 'active',
    label: capitalizeAndTranslateMsg('wms.status.active', 'active'),
    className: 'radio-green',
  },
  {
    value: 'in progress',
    label: capitalizeAndTranslateMsg('wms.status.in progress', 'in progress'),
    className: 'radio-darkBlue',
  },
  {
    value: 'planning',
    label: capitalizeAndTranslateMsg('wms.status.planning', 'planning'),
    className: 'radio-darkBlue',
  },
  {
    value: 'protected',
    label: capitalizeAndTranslateMsg('wms.status.protected', 'protected'),
    className: 'radio-orange',
  },
  {
    value: 'backlog',
    label: capitalizeAndTranslateMsg('wms.status.backlog', 'backlog'),
    className: 'radio-gray',
  },
  {
    value: 'blocked',
    label: capitalizeAndTranslateMsg('wms.status.blocked', 'blocked'),
    className: 'radio-gray',
  },
  {
    value: 'paused',
    label: capitalizeAndTranslateMsg('wms.status.paused', 'paused'),
    className: 'radio-gray',
  },
  {
    value: 'closed',
    label: capitalizeAndTranslateMsg('wms.status.closed', 'closed'),
    className: 'radio-red',
  },
];

function NewVectorComponent(props) {
  const {
    vectorType,
    currentTypeCallback,
    customDescription,
    customTitle,
    partitionType,
    parentUUID,
    newVectorSuccessCallback,
    getVectors,
    getNewVector,
    toogleProjectCollapse,
    toggleModal,
    cancelBtnCallbackInChildren,
    hideVectorType = false,
    isAssignOpen,
    setIsAssignOpen,
  } = props;

  const [vectorTitleRef] = useFocus();

  const { TextArea } = Input;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const commonFetching = useSelector(getCommonFetching);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const vectorFetching = useSelector(listVectorsFetching);
  const projectParams = useSelector(getProjectParams);
  const projectUUID = useSelector(getProjectUUID);
  const vectorsScoringData = useSelector(getVectorsScoringData);

  const defaultState = {
    visibleUsers: [],
    title: customTitle || '',
    description: customDescription || '',
    priority: 'low',
    status: 'active',
    vectorTypeValue: vectorType,
    lifeTime: moment(new Date()).add(1, 'M').format('YYYY-MM-DD'),
    versionNumber: '',
    deployVectors: [],
    incrementFetching: false,
  };

  const [selectedProjectUsers, setSelectedProjectUsers] = useState([]);
  const [openUsersModal, setOpenUsersModal] = useState(false)

  const [state, setState] = useState(defaultState);

  const {
    visibleUsers,
    title,
    description,
    priority,
    status,
    vectorTypeValue,
    lifeTime,
    versionNumber,
  } = state;

  const changeState = (data) => {
    setState((state) => ({
      ...state,
      ...data,
    }));
  };

  const onChangeVisibleUsers = (users) => {
    changeState({ visibleUsers: users });
    // dispatch(getProjectRequest(projectUUID, partitionType));
  };

  const sendAssignNotifications = (vectorData, receiver) => {
    const { uuid, parent, params } = vectorData;

    const entityData = {
      projectUUID: parent,
      vectorUUID: uuid,
      entity_type: 'vector',
      partition: partitionType,

      project_uuid: parent,
      project_id: projectParams?.id,
      project_title: projectParams?.title,
      assigned_entity_type: 'vector',
      assigned_entity_title: params?.title,
      assigned_entity_id: params?.id,
      assigned_entity_uuid: uuid,
    };

    if (partitionType === partitionNamesConfig.partition3) {
      entityData.user = myPublicUUID || '';
    }

    if (!isEmpty(receiver)) {
      dispatch(sendNotification('assigned_vector', receiver, params?.title, {
        entity: entityData,
      }));
    }
  };

  const updateScoringData = (priorityVal, statusVal) => {
    // actionType, total, prevSum, currentPriority, oldPriority
    const data = {
      actionType: 'create', sum: vectorsScoringData?.sum, total: vectorsScoringData?.total, currentPriority: priorityVal, currentStatus: statusVal,
    };
    // console.log('updateScoringData', data);
    const newScoringData = updateEntityScoring(data);
    if (newScoringData) {
      dispatch({
        type: ProjectTypeCommonConstants.UPDATE_VECTORS_PRIORITY_INDEXES_DATA,
        payload: newScoringData,
      });
    }
  };

  const handleFormSubmit = async () => {
    if (vectorFetching) return;

    const newLifeTime = lifeTime
      ? moment(lifeTime).format('YYYY-MM-DD')
      : moment().add('1', 'month').format('YYYY-MM-DD');

    changeState({ incrementFetching: true });

    const count = await dispatch(incrementAndGetCount({
      entity_uuid: parentUUID,
      field_name: 'vector',
    }, partitionType));
    changeState({ incrementFetching: false });

    const createParams = {
      entity_type: 'vector',
      parent: parentUUID,
      params: {
        id: `V${+get(count, 'increment')}`,
        title,
        description,
        priority,
        status,
        users: visibleUsers?.map((uuid) => ({ uuid })),
        usersSearch: visibleUsers,
        type: vectorTypeValue,
        lifeTime: newLifeTime,
      },
    };

    if (vectorTypeValue === 'version') {
      createParams.params = {
        ...createParams.params,
        versionNumber,
      };
    }

    dispatch(axiosRequestPost(
      'entity/create',
      [
        ProjectTypeCommonConstants.NEW_VECTOR_REQUEST,
        ProjectTypeCommonConstants.NEW_VECTOR_SUCCESS,
        ProjectTypeCommonConstants.NEW_VECTOR_FAILURE,
      ],
      createParams,
      {
        partition: partitionType,
        onSuccess: (response) => {
          if (!response) {
            antNotification('error', t('notifications.text.error.base', 'Error'));
          }
          const res = head(response);
          if (res && newVectorSuccessCallback) {
            newVectorSuccessCallback(res, res.uuid);
          }

          if (res) {
            sendAssignNotifications(res, createParams?.params?.usersSearch);
          }

          if (getNewVector) {
            getNewVector(res);
          }

          changeState({
            title: '',
            description: '',
            lifeTime: lifeTime || moment(new Date()).add(1, 'm').format('YYYY-MM-DD'),
            priority: 'low',
            status: 'active',
          });

          if (getVectors) {
            getVectors(prop('parent', res), prop('params', res));
          }

          antNotification('success', t('wms.noun.success', 'Success'));

          if (toogleProjectCollapse) {
            toogleProjectCollapse('newVector');
          }

          if (toggleModal) {
            toggleModal();
          }
          // console.log('handleFormSubmit res', res);
          updateScoringData(res?.params?.priority, res?.params?.status);
        },
        onFailure: () => {
          if (toggleModal) {
            toggleModal();
          }
        },
      },
    ));
  };

  const changePriority = (priority) => changeState({ priority });

  const changeStatus = (status) => changeState({ status });

  const changeVectorType = (type) => {
    if (currentTypeCallback) {
      currentTypeCallback(type);
    }
    changeState({ vectorTypeValue: type });
  };

  const callbackSetLifeTime = (date) => changeState({ lifeTime: date.lifeTime });

  const submitDisabled = title.replace(/\s/g, '').length === 0 || commonFetching || vectorFetching || state.incrementFetching;

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && e.ctrlKey && !submitDisabled) {
      e.preventDefault();
      handleFormSubmit();
    }
  };

  const typeOptions = vectorTypes.map((item) => ({
    value: item.value,
    label: item.translate,
    className: item.className,
  }));

  const titleModal = () => {
    switch (vectorTypeValue) {
      case 'version': return capitalize(t('wms.modals.headers.new_version'));
      case 'deploy': return capitalize(t('wms.modals.headers.new_deploy'));
      case 'workblock': return capitalize(t('wms.modals.headers.workblock'));
      case 'general': return t('wms.adjective.general', { before: capitalize(t('wms.adjective.new')) });
      default: return t(`wms.noun.${vectorTypeValue}`, { before: capitalize(t('wms.adjective.new')) });
    }
  };

  useEffect(() => {
    setSelectedProjectUsers(projectParams?.users);
  }, [JSON.stringify(projectParams?.users)]);

  return (
    <Form onKeyPress={handleKeyPress} id="form_create_vector">
      <Row gutter={30}>
        <Col span={24}>
          <h5 className="mb-2">
            {titleModal()}
          </h5>
          {!hideVectorType && (
            <Form.Item className="mb-2">
              {`${capitalize(t('wms.table.headers.type', 'type'))}:`}
              <RadioButtons
                options={typeOptions}
                onChange={changeVectorType}
                value={vectorTypeValue}
              />
            </Form.Item>
          )}
          {(vectorTypeValue === 'version') && (
            <Form.Item className="mb-2">
              {`${capitalize(t('wms.labels.version_number'))}:`}
              <Input
                maxLength={25}
                showCount
                autoSize
                type="text"
                placeholder="Enter number"
                value={versionNumber}
                onChange={(e) => changeState({ versionNumber: e.target.value.replace(/[a-zа-яё]/gi, '') })}
              />
            </Form.Item>
          )}
          <Form.Item className="mb-3">
            {capitalize(t('wms.labels.title', 'title'))}
            :
            <Input
              onChange={(e) => changeState({ title: e.target.value })}
              showCount
              placeholder={capitalize(t('wms.placeholders.validation.numbers_and_alphabet', 'only A-Za-z0-9_'))}
              maxLength={128}
              type="text"
              name="title"
              id="title"
              value={title}
              autoComplete="off"
              ref={vectorTitleRef}
            />
          </Form.Item>
          <Form.Item className="mb-3">

            {capitalize(t('wms.labels.need_done', 'what needs to be'
              + ' done'))}
            :
            <TextArea
              rows={3}
              // onChange={(e) => setDescription(e.target.value)}
              onChange={(e) => changeState({ description: e.target.value })}
              value={description}
              placeholder={capitalize(t('wms.placeholders.description', 'enter description'))}
              type="textarea"
              name="description"
              id="description"
            />
          </Form.Item>

          <Form.Item className="mb-3">
            <Flex className="w-full">
              <AssigningUsersRoot
                header="Assigning users"
                // isOpen={isAssignOpen}
                // setIsOpen={setIsAssignOpen}
                isOpen={openUsersModal}
                setIsOpen={() => setOpenUsersModal(prev => !prev)}
                onUpdateUsers={(data) => {
                  setSelectedProjectUsers(data?.allUsers ?? []);
                  onChangeVisibleUsers(data?.users?.map((el) => el?.uuid));
                }}
                needUpdateProjectUsers
                placement="left"
                isPopover={true}
                isModal={false}
                globalOptions={{
                  uuids: selectedProjectUsers?.map((el) => getUuid(el)),
                  roles: selectedProjectUsers,
                  defaultColumn: 'project',
                }}
                tabs={{
                  allConfig: {
                    label: 'All',
                    value: 'all',
                    textSelectButton: 'Add to project/vector',
                    showSections: ['button-add-user'],
                  },
                  projectConfig: {
                    label: 'Project',
                    value: 'project',
                    textSelectButton: 'Add to vector',
                    defaultItems: selectedProjectUsers ?? [],
                    showSections: ['string-role', 'button-add-user'],
                  },
                }}
                usersConfig={{
                  label: 'Vector users',
                  showSections: ['string-role', 'button-remove-user'],
                  textDeleteButton: 'Remove users',
                  defaultItems: [...(visibleUsers ?? [])],
                }}
                popoverText="Assigned users"
                projectUuid={projectUUID}
                partition={partitionType}
              />

              <WrapperAvatarsGroupForProjectWithHook
                actors={visibleUsers || []}
                avatarSize={28}
                popoverTrigger="click"
                popoverPlacement="right"
                checkProjectRole
              />
            </Flex>
          </Form.Item>

          <Form.Item className="ml-2">
            <Col span={24} className=" mt-2 flex items-center justify-between">
              <EntityWorkTime
                onChangeFieldCallback={callbackSetLifeTime}
                createdDate={moment()}
                expirationDate={lifeTime}
                isEdit
              />
            </Col>
          </Form.Item>
          <Form.Item className="mb-3">
            {capitalize(t('wms.status.title', 'status'))}
            :
            <RadioButtons
              options={statusOptions}
              value={status}
              onChange={(value) => changeStatus(value)}
            />
          </Form.Item>

          <Form.Item className="mb-3">
            {capitalize(t('wms.vectors.priority.title', 'title'))}
            :
            <RadioButtons
              options={priorityOptions}
              onChange={changePriority}
              value={priority}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Row type="flex" justify="end" className="mt-3">
          <BaseButton
            id="cancelFormButton"
            className="mr-2"
            type="info"
            onClick={() => cancelBtnCallbackInChildren()}
          >
            {capitalize(t('wms.verb.cancel', 'cancel'))}
          </BaseButton>
          <BaseButton
            id='submitFormButton'
            onClick={handleFormSubmit}
            type="primary"
            disabled={submitDisabled}
          >
            {capitalize(t(`wms.${vectorTypeValue === 'general' ? 'adjective' : 'noun'}.${vectorTypeValue}`, { before: t('wms.verb.create') }, 'create'))}
          </BaseButton>
        </Row>
      </Form.Item>
    </Form>
  );
}

NewVectorComponent.propTypes = {
  getVectors: PropTypes.func.isRequired,
  newVectorSuccessCallback: PropTypes.func,
  parentUUID: PropTypes.string.isRequired,
  toogleProjectCollapse: PropTypes.func,
  vectorType: PropTypes.string,
  customTitle: PropTypes.string,
  customDescription: PropTypes.string,
  currentTypeCallback: PropTypes.func,
  partitionType: PropTypes.string,
  getNewVector: PropTypes.func,
  toggleModal: PropTypes.func,
  cancelBtnCallbackInChildren: PropTypes.func,
  hideVectorType: PropTypes.bool,
  isAssignOpen: PropTypes.bool,
  setIsAssignOpen: PropTypes.func,
};

export default NewVectorComponent;

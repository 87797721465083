import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoTip from '../../components/infoTip/InfoTip';

function ToolsFlowInfoTip({ title }) {
  return (
    <InfoTip
      title={title}
    />
  );
}

export default ToolsFlowInfoTip;

import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const initialState = {
  isOpenConfirm: false,
  isNotSaveData: false,
  popConfirmID: null,
  savedFunction: null,
};

export const unSaveDataSlicer = createSlice({
  name: 'unSaveDataSlicer',
  initialState,
  reducers: {
    setFlagOpenConfirm: (state, action) => {
      state.isOpenConfirm = action.payload;
    },
    setFlagNotSaveData: (state, action) => {
      state.isNotSaveData = action.payload;
    },
    setPopConfirmID: (state, action) => {
      state.popConfirmID = action.payload;
    },
    setGlobalSavedFunction: (state, action) => {
      state.savedFunction = action.payload;
    },
  },
});

export const {
  setFlagOpenConfirm,
  setFlagNotSaveData,
  setPopConfirmID,
  setGlobalSavedFunction,
} = unSaveDataSlicer.actions;

const unSavedSlicerPath = 'projectManagementService.project.unSaveData';
export const globalDataNotSaved = (state) => get(state, `${unSavedSlicerPath}.isNotSaveData`, false);
export const globalPopConfirmID = (state) => get(state, `${unSavedSlicerPath}.popConfirmID`, null);
export const globalSavedFunction = (state) => get(state, `${unSavedSlicerPath}.savedFunction`, null);

export default unSaveDataSlicer.reducer;

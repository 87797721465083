import React from 'react';
import { Col, Row } from 'antd';
import { node, string } from 'prop-types';
import Icon from '@mdi/react';
import { mdiArrowLeftDropCircleOutline } from '@mdi/js';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

import BaseButton from '../../_ui/BaseButton/BaseButton';

import { stopPropagation } from '../../../54origins/utils54origins';
import useURLParams from '../../../hooks/useURLParams';

function DashboardActiveCardWrapper({
  children,
  title,
}) {
  const { t } = useTranslation();
  const { clearSearchParams } = useURLParams();

  const clickHandler = (e) => {
    stopPropagation(e);
    clearSearchParams(['activeCard', 'startDate', 'period', 'goal', 'activeDoc', 'activeNote', 'activeSheet']);
  };

  return (
    <Row className="w-full flex flex-col">
      <Col className="flex flex-col" span={24}>
        <Row className="dashboardActiveCardHeader">
          <Col span={2} className="flex items-center">
            <BaseButton
              // size="small"
              className="btnWarning-outline"
              // style={{
              //   width: 150,
              // }}
              onClick={clickHandler}
            >
              <Icon className="mr-1" path={mdiArrowLeftDropCircleOutline} size={0.7} />
              {capitalize(t('wms.buttons.back', 'back'))}
            </BaseButton>
          </Col>
          <Col span={18}>
            {title}
          </Col>
        </Row>
        {children}
      </Col>
    </Row>
  );
}

DashboardActiveCardWrapper.propTypes = {
  title: string,
  children: node,
};

export default DashboardActiveCardWrapper;

import React, { memo } from 'react';

import Login54Origins from '../54origins/components/Login54Origins';
import { gptUrl } from '../api/appConfig';
import BaseCard from "../components/_ui/BaseCard/BaseCard";

function GetGPTSessionTokenCard({ successCallback }) {
  return (
    <BaseCard>
      <Login54Origins
        url={gptUrl}
        doNotMakeRedirect
        serviceName="GPT54"
        hiddenElements={['signUpSection', 'helpMeBtn', 'signInBtn']}
        afterSaveSession={successCallback}
        reloadJsAfterLoadHTML
      />
    </BaseCard>
  );
}

export default memo(GetGPTSessionTokenCard);

async function compressAndResizeImage(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
  
          // Maintain aspect ratio
          const maxSide = 1200;
          let width = img.width;
          let height = img.height;
  
          if (width > height && width > maxSide) {
            height *= maxSide / width;
            width = maxSide;
          } else if (height > maxSide) {
            width *= maxSide / height;
            height = maxSide;
          }
  
          canvas.width = width;
          canvas.height = height;
  
          ctx.drawImage(img, 0, 0, width, height);
  
          // Compression logic to ensure size below 300KB
          let quality = 0.9;
          function tryCompress() {
            const dataUrl = canvas.toDataURL("image/jpeg", quality);
            const bytes = dataUrl.split(",")[1].length * (3 / 4);
            const sizeKB = bytes / 1024;
  
            if (sizeKB <= 300 || quality <= 0.1) {
              resolve(dataUrl);
            } else {
              quality -= 0.05;
              tryCompress();
            }
          }
          tryCompress();
        };
  
        img.src = event.target.result;
      };
  
      reader.readAsDataURL(file);
    });
  }
  
  function dataURLtoFile(dataUrl, filename) {
    // Split the base64 string in data:image/jpeg;base64, and get the base64 string
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    const file = new File([u8arr], filename, { type: mime });
    return file;
  }
  
  export async function handleQuillFiles(files) {
    let newArr = [];
    try {
      for (let i = 0; i < files.length; i++) {
        await compressAndResizeImage(files[i]).then((dataUrl) => {
          const file = dataURLtoFile(dataUrl, files[i].name);
          // console.log('crdt file', file);
          newArr.push(file);
          // Вы можете здесь использовать новый File объект, например, отправить его на сервер или сохранить.
        });
      }
      return newArr;
    } catch (e) {
      console.log("error when parsing image", e);
      return [];
    }
  }
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Col, Input, Row, Spin, DatePicker, Flex,
  Tooltip, Typography
} from 'antd';
import Select from 'react-select';
import { isNil } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { get, head } from 'lodash';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import ListOfVectorsDropDown from '../componentsForMultipleUse/ListOfVectorsDropDown';

import {
  antNotification,
} from '../../../../MainUtils';
import { capitalize } from 'lodash';
import { BugTrackerContext, MainCollapseSectionContext } from '../../../context/ProjectFlowListOfContexts';
import {
  getProjectParams,
  getProjectUUID,
} from '../../../selectors/selectors';
import { getLocale } from '../../../../locale/selectors';
import { incrementAndGetCount } from '../../../../entity/actions/entityActions';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import dayjs from 'dayjs';
import { mdiCalendarClock } from '@mdi/js';
import Icon from '@mdi/react';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const {
  Paragraph
} = Typography;

function NewTestCycleExecutionForm({
  customTitle,
  defaultSelectedCase,
  handleOk,
  newTestCycleExecutionContainerData,
  selectTestCycle,
  setNewTestCycleExecutionContainerData,
  label,
  labelSubmit,
  customListCases,
  createNewTestCaseForCycle,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getListOfTestCasesInCycle,
    createPromiseNewTestCycle,
    partitionType,
    getListOfTestCycles,
  } = useContext(BugTrackerContext);
  const { changeMainCollapseFromOuterComponent } = useContext(MainCollapseSectionContext);

  const locale = useSelector(getLocale);
  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);
  const currentDate = new Date();

  const defaultState = {
    title: customTitle || '',
    description: '',
    start_date: new Date(),
    end_date: currentDate.setDate(currentDate.getDate() + 3),
    selectedCases: defaultSelectedCase,
    assignToUsersOption: [],
    selectVector: '',
    difference: 3,
  };

  const [state, setState] = useState(defaultState);
  const [loading, setLoading] = useState(false);

  const [isAssignOpen, setIsAssignOpen] = useState(false);

  const {
    title,
    description,
    start_date,
    end_date,
    selectedCases,
    assignToUsersOption,
    selectVector,
    difference,
  } = state;

  const changeState = (data) => {
    setState({
      ...state,
      ...data,
    });
  };

  const checkSelectedColorCase = (isNil(selectedCases) || selectedCases.length === 0) ? 'red' : 'green';

  const onFormChange = (event) => {
    const { name, value } = event.target;

    changeState({ [name]: value });
  };

  const assignToFunc = (value) => {
    changeState({
      assignToUsersOption: value,
    });
  };

  const onDaysChange = (param, day) => {    
    const newState = { [param]: isNil(day) ? '' : day };
  
    if (param === 'start_date' && !isNil(day)) {
      const startDate = new Date(day);
      startDate.setDate(startDate.getDate() + difference + 1);
      newState.end_date = startDate.toISOString().split('T')[0];
    }
    if (param === 'end_date' && !isNil(day)) {
      const startDate = new Date(state.start_date);
      const endDate = new Date(day);
      const diff = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
      newState.difference = diff;
    }
  
    changeState(newState);
  };

  const changeLifeTime = () => {
    difference += 1
  }

  const selectVectorCollback = (item) => {
    changeState({ selectVector: item });
  };

  const onSelectTestCase = (e) => changeState({ selectedCases: isNil(e) ? [] : e });

  const submitNewTestCycle = async () => {
    setLoading(true);

    const { increment } = await dispatch(incrementAndGetCount({
      entity_uuid: projectUUID,
      field_name: 'cycle',
    }, partitionType));

    const createParams = {
      title,
      description,
      start_date: start_date ? dayjs(start_date).format('YYYY-MM-DD') : '',
      end_date: end_date ? dayjs(end_date).format('YYYY-MM-DD') : '',
      status: 'in progress',
      executed_by: null,
      executed_on: null,
      cases_count: selectedCases.length,
      assignToUser: assignToUsersOption,
      vector: selectVector,
      archive: false,
      id: `C${increment}`,
      progress: {
        pass: 0,
        block: 0,
        fail: 0,
        unexecuted: selectedCases.length,
        progress: 0,
        quantityCase: selectedCases.length,
      },
    };

    const newTestCycle = await createPromiseNewTestCycle(createParams);

    const listSelected = customListCases?.cases?.filter(
      (i) => selectedCases.some((p) => p.value === i.uuid),
    );

    await createNewTestCaseForCycle(listSelected, head(newTestCycle), assignToUsersOption);

    getListOfTestCycles();

    getListOfTestCasesInCycle(get(newTestCycle, '[0].uuid'));
    selectTestCycle(get(newTestCycle, '[0].uuid'));

    setLoading(false);

    antNotification('success', t('wms.noun.success', 'Success'));

    changeMainCollapseFromOuterComponent('mainCycleCollapse', true);

    if (handleOk) {
      handleOk();
    }
  };

  const disabledDate = (current) => current && current < dayjs().startOf('day');
  const disabledEndDate = (current) => current && current < start_date;

  useEffect(() => () => {
    setNewTestCycleExecutionContainerData({ ...state });
  }, []);

  useEffect(() => {
    if (newTestCycleExecutionContainerData) {
      setState(newTestCycleExecutionContainerData);
    }

    if (!newTestCycleExecutionContainerData && defaultSelectedCase?.length) {
      onSelectTestCase(defaultSelectedCase);
    }
  }, [defaultSelectedCase?.length]);

  const onChangeRangePicker = (dates) => {
    let checkedDates = ['', ''];
    const checkIsArr = Array.isArray(dates);
    if (checkIsArr) {
      checkedDates = dates;
    }
    const [date1, date2] = checkedDates;
    setState((prev) => ({ ...prev, start_date: date1 || '', end_date: date2 || '' }));
  };

  return (
    <div
      style={{
        minHeight: '580px',
      }}
      className="flex flex-col justify-between"
    >
      <div className="px-4 w-full">
        <Spin spinning={loading}>
          <Row>
            <Col span={24}>
              <h5>
                {label}
              </h5>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col span={24} style={{ color: title.trim().length === 0 ? 'red' : 'green' }}>
              {capitalize(t('wms.labels.title', 'title'))}
              <span>*</span>
              :

              <Input name="title" value={title} onChange={onFormChange} disabled={customTitle === 'Ad hoc cycle'} showCount maxLength={60} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col span={24}>
              <span>
                {capitalize(t('wms.labels.description', 'description'))}
                :
              </span>

              <TextArea
                row={4}
                value={description}
                name="description"
                onChange={onFormChange}
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col span={24}>
              <Col style={{ color: checkSelectedColorCase }}>
                <span>
                  {capitalize(t('wms.labels.test_cases', 'Test cases'))}
                  *
                </span>
                :
              </Col>

              <Select
                className="w-full"
                isMulti
                noOptionsMessage={() => capitalize(t('wms.select.no_options', 'no options'))}
                placeholder={capitalize(t('wms.placeholders.select_cases', 'select cases'))}
                onChange={onSelectTestCase}
                options={customListCases?.option}
                value={selectedCases}
                closeMenuOnSelect={false}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col span={12}>
              <Flex className="w-full">
                <AssigningUsersRoot
                  header="Assigning users"
                  isOpen={isAssignOpen}
                  needUpdateProjectUsers
                  setIsOpen={setIsAssignOpen}
                  onUpdateUsers={(data) => assignToFunc(data?.users?.map((el) => el?.uuid))}
                  globalOptions={{
                    uuids: projectParams?.usersSearch ?? [],
                    defaultColumn: 'project',
                  }}
                  placement="left"
                  isPopover
                  tabs={{
                    allConfig: {
                      label: 'All',
                      value: 'all',
                      textSelectButton: 'Add to cycle',
                      showSections: ['button-add-user'],
                    },
                    projectConfig: {
                      label: 'Project',
                      value: 'project',
                      textSelectButton: 'Add to cycle',
                      defaultItems: projectParams?.users ?? [],
                      showSections: ['string-role', 'button-add-user'],
                    },
                  }}
                  usersConfig={{
                    label: 'Cycle users',
                    showSections: ['button-remove-user', 'string-role'],
                    textDeleteButton: 'Delete users',
                    defaultItems: assignToUsersOption ?? [],
                  }}
                  wrapperProps={{ autoAdjustOverflow: true }}
                  partition={partitionType}
                  projectUuid={projectUUID}
                  popoverText={(<span style={{ color: 'black' }}>Assigned users:</span>)}
                />
                <WrapperAvatarsGroupForProjectWithHook
                  actors={assignToUsersOption || []}
                  avatarSize={28}
                  popoverTrigger="click"
                  popoverPlacement="right"
                />
              </Flex>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col span={12}>
              <ListOfVectorsDropDown
                partition={partitionType}
                selectVectorCallback={selectVectorCollback}
                selectVectorDefault={selectVector}
              />
            </Col>
          </Row>

          <Row className="mt-2 w-full">
            <Col className='flex flex-row items-center' span={24}>
              <Col span={8} className="flex flex-col">
                <span>{`${capitalize(t('wms.labels.date.start', 'start date'))}:`}</span>
                <DatePicker
                  format="YYYY-MM-DD"
                  value={dayjs(start_date).toString() === 'Invalid Date' ? '' : dayjs(start_date)}
                  placeholder={t('wms.placeholders.date.format')}
                  disabledDate={disabledDate}
                  onChange={(day) => {onDaysChange('start_date', day)}}
                />
              </Col>
              <Flex className='flex flex-row ml-4 mr-4 top-[10px] relative'>
                <Tooltip title={title}>
                  <Icon path={mdiCalendarClock} size={1} className="text-danger" />
                </Tooltip>
                <Paragraph className='m-0 ml-1'>
                  {difference}
                  {' '}
                  days
                </Paragraph>
              </Flex>
              <Col span={8} className="flex flex-col">
                <span>
                  {capitalize(t('wms.labels.date.end', 'end date'))}
                  :
                </span>
                <DatePicker
                  format="YYYY-MM-DD"
                  value={dayjs(end_date).toString() === 'Invalid Date' ? '' : dayjs(end_date)}
                  placeholder={t('wms.placeholders.date.format')}
                  disabledDate={disabledEndDate}
                  onChange={(day) => onDaysChange('end_date', day)}
                />
              </Col>
            </Col>
          </Row>
        </Spin>
      </div>

      <div className="flex justify-end">
        <BaseButton
          type="primary"
          onClick={submitNewTestCycle}
          className="ml-auto"
          disabled={
            selectedCases.length === 0
            || title.trim().length === 0
          }
        >
          {labelSubmit}
        </BaseButton>
      </div>
    </div>
  );
}

NewTestCycleExecutionForm.propTypes = {
  handleOk: PropTypes.func,
  newTestCycleExecutionContainerData: PropTypes.func,
  selectTestCycle: PropTypes.func,
  customListCases: PropTypes.array,
  setNewTestCycleExecutionContainerData: PropTypes.func,
  label: PropTypes.string,
  labelSubmit: PropTypes.string,
  customTitle: PropTypes.string,
  defaultSelectedCase: PropTypes.array,
  createNewTestCaseForCycle: PropTypes.func,
};

export default NewTestCycleExecutionForm;

// This hook also used on another biome service
// All query names must be in this format "request[request name]OnAuth".

import { useDispatch } from 'react-redux';

import axiosRequest from '../../api/apiAxios';
import { configUrlAuth, sessionNameFor } from '../../api/appConfig';

const useAuthActorsRequests = () => {
  const dispatch = useDispatch();

  const optionsForAuthRequest = (resolve, reject) => ({
    onFailure: reject,
    onSuccess: resolve,
    tokenName: sessionNameFor.auth,
    url: configUrlAuth,
    doNotCheckError: true,
  });

  const requestGetListOfActorsOnAuth = (data, types) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'get/actors',
        [...types],
        data,
        optionsForAuthRequest(resolve, reject),
      ),
    );
  });

  const requestUpdateActorOnAuth = (data) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'update/actor',
        [
          'UPDATE_ACTOR_ON_AUTH_REQUEST',
          'UPDATE_ACTOR_ON_AUTH_SUCCESS',
          'UPDATE_ACTOR_ON_AUTH_FAILURE',
        ],
        data,
        optionsForAuthRequest(resolve, reject),
      ),
    );
  });

  const requestGetActorOnAuth = ({
    uuid,
    constants,
  }) => {
    const finalConstants = constants || [
      'GET_ACTORS_DATA_REQUEST',
      'GET_ACTORS_DATA_SUCCESS',
      'GET_ACTORS_DATA_FAILURE',
    ];

    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          `get/actor/${uuid}`,
          finalConstants,
          {},
          optionsForAuthRequest(resolve, reject),
        ),
      );
    });
  };

  const requestCreateActorOnAuth = (data) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'create/actor',
        [
          'CREATE_ACTOR_REQUEST',
          'CREATE_ACTOR_SUCCESS',
          'CREATE_ACTOR_FAILURE',
        ],
        {
          actor_type: 'classic_user',
          ...data,
        },
        optionsForAuthRequest(resolve, reject),
      ),
    );
  });

  // send_actor_on_service

  const requestSyncActorForServiceOnAuth = (actorUUID, serviceUUID) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'synchronization/send_actor_on_service',
        [
          'SYNC_ACTOR_ON_SERVICE_REQUEST',
          'SYNC_ACTOR_ON_SERVICE_SUCCESS',
          'SYNC_ACTOR_ON_SERVICE_FAILURE',
        ],
        {
          actor_uuid: actorUUID,
          service_uuid: serviceUUID,
        },
        optionsForAuthRequest(resolve, reject),
      ),
    );
  });

  return ({
    requestGetListOfActorsOnAuth,
    requestUpdateActorOnAuth,
    requestGetActorOnAuth,
    requestCreateActorOnAuth,
    requestSyncActorForServiceOnAuth,
  });
};

export default useAuthActorsRequests;

import { ProjectTypeCommonConstants } from '../../constants/Constants';

const initialState = {
  fetching: true,
  dataPublic: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ProjectTypeCommonConstants.CREATE_COOK_BOOK_ENTITY_PUBLIC_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case ProjectTypeCommonConstants.CREATE_COOK_BOOK_ENTITY_PUBLIC_SUCCESS:
      return {
        ...state,
        fetching: true,
        dataPublic: payload,
      };
    case ProjectTypeCommonConstants.CREATE_COOK_BOOK_ENTITY_PUBLIC_FAILURE:
      return {
        ...state,
        dataPublic: payload,
        fetching: false,
      };
    default: return state;
  }
};

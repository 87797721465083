import React, { useEffect, useState, useCallback } from "react";
import { Tooltip, Tag, Divider } from "antd";
import PropTypes from "prop-types";
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { useExpandModeContext } from "../../commonComponents/expandModeLayout/ExpandModeLayout";
import WrapperAvatarsGroupForProjectWithHook from "../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook";
import { toolbarOptions } from "./toolbarOptions";
import { HelperText } from "./ToolbarHelper/blocks/SyncStatusText";
import ToolbarHelper from "./ToolbarHelper/ToolbarHelper";

// Мемоизированный компонент Button
const Button = React.memo(({ className, title, value, icon, padding, idx }) => {
  const buttonContent = icon || "";
  const buttonStyle = { padding: padding || "auto" };
  const button = (
    <button className={className} value={value || null} style={buttonStyle}>
      {buttonContent}
    </button>
  );
  return title ? (
    <Tooltip title={title} key={`${title}_${idx}`} mouseEnterDelay={0.5}>
      {button}
    </Tooltip>
  ) : button;
});

// Мемоизированный компонент Select
const Select = React.memo(({ className, title, value, width, idx }) => {
  const selectOptions = Array.isArray(value) && className === "ql-size"
    ? value.map((size) => (
      <option key={size} value={size} selected={size === "14px"}>
        {size}
      </option>
    ))
    : null;

  const selectElement = (
    <select className={className} style={{ padding: "0 0" }}>
      {selectOptions}
    </select>
  );

  return title ? (
    <Tooltip title={title} key={`${title}_${idx}`} mouseEnterDelay={0.5}>
      <div style={{ display: "inline-block", width: width || "auto" }} className={idx === 0 ? "quill_toolbar_first" : ""}>
        {selectElement}
      </div>
    </Tooltip>
  ) : selectElement;
});

const QuillToolbar = ({ toolbarRef, isConnected, isSave, allUsers }) => {
  const {
    isWideMode,
    isVerticalMode,
    isFullscreenMode,
  } = useExpandModeContext();

  const [currentHeaderHeight, setCurrentHeaderHeight] = useState("50px");

  useEffect(() => {
    const headerElement = document.querySelector("header.ant-layout-header");
    const height = headerElement ? `${headerElement.offsetHeight}px` : "50px";
    setCurrentHeaderHeight(height);
  }, []);

  // Мемоизация для рендера кнопок и селектов
  const renderToolbarOption = useCallback((optProps, idx) => {
    const { type } = optProps;
    if (type === "button") {
      return <Button {...optProps} idx={idx} />;
    }
    return <Select {...optProps} idx={idx} />;
  }, []);

  const renderSyncStatus = () => {
    if (isConnected) {
      return isSave ? (
        <Tag icon={<CheckCircleOutlined />} color="success" className="quill-toolbar-sync-status">
          Saved
        </Tag>
      ) : (
        <Tag icon={<SyncOutlined spin />} color="processing" className="quill-toolbar-sync-status">
          Saving
        </Tag>
      );
    }
    return (
      <Tag icon={<CloseCircleOutlined />} color="error" className="quill-toolbar-sync-status">
        Unsync
      </Tag>
    );
  };

  const renderEditors = () => (
    <WrapperAvatarsGroupForProjectWithHook
      actors={allUsers}
      avatarSize="small"
      avatarGap={4}
      avatarStyles={{ cursor: "pointer" }}
      groupButtonSize="small"
      checkProjectRole
    />
  );

  return (
    <div
      id="quill_toolbar"
      ref={toolbarRef}
      style={{
        top: isFullscreenMode ? '0' : isWideMode ? currentHeaderHeight : '0',
      }}
    >
      <div style={{ position: "relative", width: "100%", display: "flex" }}>
        <div className="quill-toolbar-helper" style={{ paddingRight: "8px", borderRight: "1px solid #ccced1" }}>
          <ToolbarHelper content={<HelperText />} />
        </div>
        <div className="wrapper-title" style={{ display: "flex", alignItems: "center", gap: "4px", padding: "0 8px", borderRight: "1px solid #ccced1" }}>
          Sync status: {renderSyncStatus()}
        </div>
        <div className="wrapper-title" style={{ display: "flex", alignItems: "center", gap: "4px", paddingLeft: "8px" }}>
          Editors: {renderEditors()}
        </div>
      </div>
      <Divider style={{ margin: "0 -8px", width: "calc(100% + 16px)" }} />
      {toolbarOptions.map((optArr, idx) => (
        <span key={`ql-formats-${idx}`} className="ql-formats">
          {optArr.map((optProps) => renderToolbarOption(optProps, idx))}
        </span>
      ))}
    </div>
  );
};

QuillToolbar.propTypes = {
  toolbarRef: PropTypes.any,
  isConnected: PropTypes.bool,
  isSave: PropTypes.bool,
  allUsers: PropTypes.array,
};

export default QuillToolbar;

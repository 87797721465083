import React from 'react';
import { number, string, oneOfType } from 'prop-types';
import { Progress } from 'antd';

import { prepareProgressTimeBar } from './helpers/milestoneUtils';

function MilestoneProgressBar({
  size = 'default',
  startDate,
  endDate,
}) {
  const progressBar = prepareProgressTimeBar(startDate, endDate);

  return progressBar && (
    <Progress
      size={size}
      steps={10}
      trailColor="#d6d6d6"
      percent={progressBar?.progress}
      format={(percent) => `${100 - percent}%`}
      status={progressBar?.status}
      strokeColor={progressBar?.color}
      // showInfo={progressBar?.status === 'exception'}
    />
  );
}

MilestoneProgressBar.propTypes = {
  size: oneOfType([number, string]),
  startDate: string,
  endDate: string,
};

export default MilestoneProgressBar;

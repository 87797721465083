import { Checkbox, Dropdown, Flex, Modal } from "antd";
import React, { useState } from "react";
import EntityParamTag from "../../../../components/entityParamTag/EntityParamTag";
import { func, object } from "prop-types";
import { issuesCompletedStatuses } from "./utils";
import { Input } from 'antd';

const { TextArea } = Input;

const CompletedIssueModal = ({
  selectedIssue, 
  updateIssue
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("deployed")
    const [resultValue, setResultValue] = useState('')

    const changeCheckboxValue = (completed) => {
        if(completed) {
          updateIssue(selectedIssue.uuid, {completedIssue: false})
        } else {
          setIsModalOpen(true);
        }
        
    };

    const handleOk = () => {
        updateIssue(selectedIssue.uuid, {result: resultValue, status: selectedStatus, completedIssue: true})
        setResultValue('')
        setSelectedStatus("deployed")
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setResultValue('')
        setSelectedStatus("deployed")
    };

const items = issuesCompletedStatuses
  .filter(({ value }) => value)
  .map(({ value }) => ({
    key: value,
    label: (
      <div   
        onClick={() => setSelectedStatus(value)}>
        <EntityParamTag
        value={value}
        param="status"
        type="iconWithStatus"
      />
    </div>
    ),
  }));
    return <>
     <Checkbox 
              checked={selectedIssue?.completedIssue}
              onChange={() => changeCheckboxValue(selectedIssue?.completedIssue)}
              />
            <Modal title="Completing issue" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Flex>
            <h6 className="mr-2 mt-2">Result:</h6>
            <TextArea
            placeholder="Enter result"
             rows={4} 
             onChange={(e) => setResultValue(e.target.value)}
             value={resultValue}
             />
            </Flex>
            <Flex className="mt-2" align="center">
              <h6 className="mr-2 mt-2">Status:</h6>
            <Dropdown
              menu={{items}}
              trigger={['click']}
              overlayStyle={{
                 boxShadow: "0px 0px 11px -2px rgba(34, 60, 80, 0.2)"}}
              arrow
              >
              <div>
                <EntityParamTag
                value={selectedStatus}
                param="status"
                type={'valueAndIconWithArrow'}
                size={'small'}
                isOpen
              />
              </div>
            </Dropdown>
            </Flex>
        </Modal>
    </>
}

export default CompletedIssueModal;

CompletedIssueModal.propTypes = {
    selectedIssue: object,
    updateIssue: func
}
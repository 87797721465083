import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { Flex, List, Tag } from 'antd';
import { UI54Button, UI54Icon } from "@agpl/ui54kit";
import ListContainer from './components/ListContainer';
import ListHeaderWithSearch from './components/ListHeaderWithSearch';
// import VirtualList from 'rc-virtual-list';

import { mdiAccountRemove } from '@mdi/js';

import { getMe, getProjectParams } from "../../../../../selectors/selectors";
import { getActorsW54 } from '../../../../../../actors/selectors/actorsW54Selector';

import { actorIsRootOrAdmin } from '../../../../../../54origins/utils54origins';
import { fullName, sortByAdmin, sortByLabel } from './utils';

const ContainerHeight = 400;

function SelectedActorsList({
  initialActors = [],
  selectedActors = [],
  setSelectedActors,
}) {
  // const searchInputRef = useRef(null);

  const actorsW54 = useSelector(getActorsW54);
  console.log('actorsW54:', actorsW54);

  const projectParams = useSelector(getProjectParams);
  console.log('projectParams:', projectParams);

  const [searchMode, setSearchMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const getRoleFromProject = (uuid) => {
    const { users = [] } = projectParams;
    return users.find((user) => user.uuid === uuid)?.role || '';
  };

  const actorsFinalList = useMemo(() => {
    console.log('useMemo');
    let actorsData = [];
    selectedActors.forEach((uuid) => {
      const actorW54 = actorsW54[uuid];
      if (!isEmpty(actorW54)) {
        actorsData.push({
          uuid: actorW54?.uuid,
          actorType: actorW54?.actor_type,
          isAdmin: actorIsRootOrAdmin(actorW54),
          label: fullName(actorW54),
          role: getRoleFromProject(actorW54?.uuid),
        });
      }
    });

    console.log('actorsData:', actorsData);

    if (actorsData.length > 1) {
      actorsData.sort(sortByLabel);
      actorsData.sort(sortByAdmin);
    }
    console.log('sorted actorsData:', actorsData);

    if (searchValue) {
      actorsData = actorsData
        .filter((el) => el?.label.toLowerCase().includes(searchValue));
    }

    return actorsData;
  }, [JSON.stringify(selectedActors), searchValue]);

  // const onScroll = (e) => {
  //   if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
  //     // appendData();
  //   }
  // };

  // const getListOfUsers = async (uuids) => {
  //   let users = []
  //   const uuidsForFetching = uuids.filter(uuid => {
  //     if (actorsW54[uuid]) {
  //       users.push(actorsW54[uuid]);
  //       return false;
  //     }
  //     return true;
  //   })
  //   if (uuidsForFetching.length) {

  //     const { data: usersList = [] } = await getListOfActorsW54({
  //       actor_type: actorTypes,
  //       uuids: uuidsForFetching,
  //     });
  //     users = concat(users, usersList)
  //   }
  //   return users;
  // }

  const isRecentlyAddedActor = (uuid) => {
    const isNewlyAdded = initialActors.indexOf(uuid) === -1;
    if (isNewlyAdded) return 'bg-lime-100';
    return '';
  }

  const handleRemoveActor = (uuid) => {
    if (uuid) setSelectedActors?.(prev => prev.filter(el => el !== uuid));
  }

  return (
    // <List>
    //   <VirtualList
    //     data={data}
    //     height={ContainerHeight}
    //     itemHeight={47}
    //     itemKey="email"
    //     onScroll={onScroll}
    //   >
    //     {(item) => (
    //       <List.Item key={item.email}>
    //         <List.Item.Meta
    //           title={<a href="https://ant.design">{item.name.last}</a>}
    //           description={item.email}
    //         />
    //         <div>Content</div>
    //       </List.Item>
    //     )}
    //   </VirtualList>
    // </List>

    <ListContainer>
      <ListHeaderWithSearch
        title="Selected users"
        usersCount={actorsFinalList?.length}
        searchMode={searchMode}
        setSearchMode={setSearchMode}
        setSearchValue={setSearchValue}
        searchFunction={setSearchValue}
      />
      <List
        style={{ overflowY: 'auto' }}
        dataSource={actorsFinalList}
        rowKey="uuid"
        size="small"
        split={true}
        // bordered
        renderItem={(actor, index) => (
          <List.Item
            key={actor?.uuid}
            className={isRecentlyAddedActor(actor?.uuid)}
            actions={[
              <UI54Button
                key='removeUser'
                className='btnDanger-outline'
                style={{ width: '50px' }}
                // type='default'
                // variant='outlined'
                size='middle'
                // danger
                icon={<UI54Icon path={mdiAccountRemove} size={1} />}
                onClick={() => handleRemoveActor(actor?.uuid)}
              />,
            ]}
          // extra={item?.isAdmin ? "admin" : ""}
          >
            <List.Item.Meta
              title={actor?.label}
              // title={
              //   <Flex gap={4}>
              //     {item?.isAdmin && (
              //       <Tag bordered={false} color="blue">
              //         Admin
              //       </Tag>
              //     )}
              //     <span>{item?.label}</span>
              //   </Flex>
              // }
              description={
                <Flex gap={4}>
                  {actor?.isAdmin && (
                    <Tag bordered={true} color="blue">
                      Admin
                    </Tag>
                  )}
                  {/* <span>{item?.role}</span> */}
                  {actor?.role && <Tag bordered={true}>{actor?.role}</Tag>}
                </Flex>
              }
            />
          </List.Item>
        )}
      />
    </ListContainer>
  );
}

export default SelectedActorsList;

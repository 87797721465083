import buildContent from "../../../helpers/statesAndPatches/buildContent";

export function isValidHTML(htmlString) {
  // Создаем временной элемент
  const doc = document.createElement("div");
  // Присваиваем содержимое нашего элемента переданной HTML-строке
  doc.innerHTML = htmlString;
  // Если присваивание прошло успешно и браузер смог создать элементы,
  // то это означает, что строка является допустимой HTML-разметкой
  return doc.innerHTML === htmlString;
}

export const parseOldDocumentStructure = (htmlString, patchesData, entityType) => {
  try {
    const [buildedContent] = buildContent(htmlString, patchesData, entityType);
    const parser = new DOMParser();
    const doc = parser.parseFromString(buildedContent, "text/html");
  
    const figures = doc.querySelectorAll("figure");
    figures.forEach((figure) => {
      const img = figure.querySelector("img");
      if (img) {
        figure.replaceWith(img);
      }
    });
  
    const resultString = doc.body.innerHTML;
    // console.log('resultString', resultString)
    return resultString;
  } catch(e) {
    console.log('parseOldDocumentStructure error', e);
    return '';
  }  
};
import { assign, find, get, includes, join, keyBy, map } from "lodash";
import cn from 'classnames';
import dayjs from "dayjs";

export const getActorLabel = (myInfo) => {
    return join([get(myInfo, 'uinfo.first_name'), get(myInfo, 'uinfo.last_name')], ' ');
}

export const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return `Day(${day}-${month}-${year})`;
}

export const isFutureDate = (day) => {
  const inputDate = dayjs(day);
  const today = dayjs()
  return inputDate.isAfter(today) || inputDate.isSame(today, 'day');
}

export const getDaysUntilSunday = () => {
  const days = [];
  const today = dayjs();
  const endOfWeek = today.day(7); 
  let currentDay = today;
  
  while (currentDay.isBefore(endOfWeek) || currentDay.isSame(endOfWeek)) {
      days.push(currentDay.format('YYYY-MM-DD'));
      currentDay = currentDay.add(1, 'day');
  }

  return days;
}

export const getNextDay = (currentDay) => {
  const date = dayjs(currentDay);
  const tomorrow = date.add(1, 'day');
  return tomorrow.format('YYYY-MM-DD');
}

export const getIssuesUUIDs = (vector) => {
  return map(vector?.params?.relatedGeneralIssues, 'uuid');
}

export const addDataToRelatedIssues = (issues, vector) => {
  const relatedIssuesMap = keyBy(vector?.params?.relatedGeneralIssues, 'uuid');
  
  const priorityToChunkMap = {
    low: 3,
    medium: 4,
    high: 5,
    urgent: 6
  };

  return issues.map(issue => {
    const findData = relatedIssuesMap[issue.uuid] || {};
    if (!issue.params.boardData) {
      const chunk = priorityToChunkMap[issue.params.priority] || 3; 
      issue.params.boardData = { chunk };
    }
    return {
      ...issue,
      data: findData.data,
      params: {
        ...issue.params,
        ...findData,
      }
    };
  });
}

export const mods = {
  PLANNING_MODE: 'Planning',
  WORK_MODE: 'Work'
}

export const plansMods = {
  ISSUES_MODE: 'Show issues',
  RECURRING_MODE: 'Show recurring issues'
}

export const modeOptions = ['Planning', 'Work']

export const plansModeOptions = ['Show issues', 'Show recurring issues']

export const btnInfoClass = "pl-0.3 mt-1 rounded-full cursor-pointer border border-sky-500 hover:bg-sky-500 hover:text-white";
export const btnInfoStyle = {
  width: 20,
  height: 20,
};

export const getClassBtn = (type) => {
  if (type === "save") {
    return cn('border border-green-500 text-green-500', {
      '!border-stone-500 !text-stone-500': false,
      'hover:bg-green-500 hover:text-white': !false,
    })
  }
  if (type === 'get') {
    return cn('border border-blue-500 text-blue-500', {
      '!border-stone-500 !text-stone-500': false,
      'hover:bg-blue-500 hover:text-white': !false,
    })
  }
  if (type === 'disabled') {
    return cn('border border-grey-500 text-grey-500')
  }
}

// export const getUserIssuesUUIDS = (boards, day) => {
//    return boards.flatMap(board => get(board, [`params`, `${day}__data`], []).map(issue => get(issue, 'uuid')));
// }

export const getUserIssuesUUIDS = (boards, day, projects) => {
   const boardsIssues = boards.flatMap(board => get(board, [`params`, `${day}__data`], [])
  .map(issue => {
     return {
      uuid: get(issue, 'uuid'), 
      userBoardUUID: board.uuid,
      projectTitle: projects.find(project => project.uuid === board.params.project).title,
      projectUsers: projects.find(project => project.uuid === board.params.project).usersSearch,
    }}));
    return boardsIssues
    
  
}

export const addDataToIssues = (issues, boards, day) => {
  const dataMap = new Map();
  boards.forEach(board => {
    const todayData = board.params[`${day}__data`];
      if(todayData) {
        todayData.forEach(el => {
          dataMap.set(el.uuid, {
            ...el, userBoardUUID: board.uuid, 
            // todayData: todayData, 
            projectTitle: board.projectTitle,
            projectUsers: board.projectUsers
          });
        });
      }
  });

      return issues.map(issue => {
        const data = dataMap.get(issue.uuid);
        if (data) {
          return { ...issue, data }; 
        }
        return issue; 
      }).filter(issue => issue.data); 
};

// export const getDayParams = (todayIssues) => {
//   if(todayIssues) {
//     return todayIssues.reduce((acc, el) => acc += el?.params?.boardData?.chunk, 0) / 2
//   }  
// }

export const getDayParams = (todayIssues) => {
  if(todayIssues) {
    return todayIssues.reduce((acc, el) => acc += el?.params?.data?.chunk, 0) / 2
  }  
}

export const addDataToUnplannedIssues = (issues, projects) => {

    return issues.map(el => {
      const data = el.boardData ? el.boardData : { chunk: 3 };
      const project = find(projects, project => includes(project.uuid, el.project));

      return assign({}, el, {
        data,
        projectTitle: project ? project.title : null
      });
    });
}

// update issue params 

export const updateTodayParam = (data, param, projectUUID, projectsSet) => {

    if(!projectsSet.has(projectUUID)) {
      return [param]
    }
 return  data.some(el => el === param) ? data : [...data, param];
}

export const updateTodayDataParam = (data, param, projectUUID, projectsSet) => {

  if(!projectsSet.has(projectUUID)) {
    return [param]
  } 
  else {
    const findParam = find(data, { uuid: param.uuid });
    if (!findParam) {
      return [...data, param];
    }
  return map(data, el => 
    el.uuid === findParam.uuid 
      ? { key: el.key, uuid: param.uuid, chunk: param.chunk } 
      : el
  );
  }
 
};
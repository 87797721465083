import { mdiBugOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Badge, Tooltip } from 'antd';
import { isNil } from 'lodash';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import { capitalize } from 'lodash';
import BtnModal from '../BtnModalComponent';
import ListOfBugsModalBtn from './ListOfBugsModalBtn';

export default function BugTrackerModalWrapper({ uuid, counterBug, typeOfParent = 'testCycle', buttonStyle= {} }) {
  const { t } = useTranslation();
  const ref = useRef(null);
  return (
    <div style={{ position: 'relative' }} ref={ref}>
      <div
        style={{
          position: 'absolute',
          bottom: '17px',
          right: '17px',
          zIndex: '1',
        }}
      >
        <Badge
          showZero
          count={!isNil(counterBug)
            ? counterBug : 0}
        />
      </div>
      <BtnModal
        className="flex justify-end "
        title={capitalize(t('wms.tooltips.bug_tracker', 'Bug tracker'))}
        width="50%"
        blockPropagation
        btnComponent={(
          <Tooltip
            zIndex={2}
            title={capitalize(t('wms.tooltips.bug_tracker', 'Bug tracker'))}
            getPopupContainer={() => ref?.current}
          >
            <BaseButton
              className="customAntOutWarningBtn "
              size="small"
            >
              <Icon
                path={mdiBugOutline}
                size={0.7}
                className="cursor-pointer"
              />
            </BaseButton>
          </Tooltip>
        )}
      >
        <ListOfBugsModalBtn
          typeOfParent={typeOfParent}
          parentUUID={uuid}
        />
      </BtnModal>
    </div>
  );
}

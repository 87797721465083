import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState,
  useCallback,
  memo,
} from 'react';
import {
  isEmpty,
  isNil,
  prop,
  propOr,
} from 'ramda';
import { useSelector } from 'react-redux';

import DataGridComponentHook from './DataGridComponentHook';
import NewOrderFormater from '../componentsForMultipleUse/NewOrderFormater';
import AreaEditor from '../../../containers/qaView/dataGridInStepСases/AreaEditor';
import ListOfStatuses from '../status/ListOfStatuses';
import AddOneRowInStep from './AddOneRowInStep';
import CloneStepComponent from './CloneStepComponent';
import RemoveStepComponent from './RemoveStepComponent';

import { getTestCycleParams } from '../../../selectors/selectors';

function DataGridWrapperWithControls({
  caseIsClosed = false,
  defaultGridFlag = false,
  typeOfParent,
  newEmptyRows = 0,
  onCellsChangedCallback,
  steps = [],
  type,
  updateStatusCallback,
}) {
  const testCycleParams = useSelector(getTestCycleParams);

  const [grid, changeGrid] = useState([]);
  const [changesOfStatus, updateChangesOfStatus] = useState([]);
  const [changesOfStepOrder, updateChangesOfStepOrder] = useState([]);
  const [changesOfStepActions, updateChangesOfStepActions] = useState([]);

  const currentCycleStatus = prop('status', testCycleParams);
  const caseStatusWhenDisable = ['pass', 'fail', 'block'];
  const disableCaseFromCycleStatus = caseStatusWhenDisable.includes(currentCycleStatus);

  const changeGridAndReturnCallback = (changes) => {
    const finalGrid = [...grid].map((row) => [...row]);

    if (changes) {
      changes.forEach(({ row, col, value }) => {
        finalGrid[row][col] = { ...finalGrid[row][col], value };
      });
    }
    changeGrid(finalGrid);

    onCellsChangedCallback(finalGrid, changes);
  };

  const onChangeStatusCallback = useCallback((status, id) => {
    const changes = [{
      row: id,
      col: 5,
      value: status,
    }];

    updateChangesOfStatus(changes);
  }, []);

  const onMoveFuncCallback = useCallback((param, id) => {
    updateChangesOfStepOrder({
      param,
      id,
    });
  }, []);

  const stepActionsFuncCallback = useCallback((param, id) => {
    updateChangesOfStepActions({
      param,
      id,
    });
  }, []);

  const singleRowForCycleTestCase = (params) => {
    const {
      id,
      step = '',
      description = '',
      test_data = '',
      expected = '',
      status = 'unexecuted',
      isLastStep = false,
    } = params;

    const arr = [
      {
        value: '', readOnly: true, key: `${id}-${0}`, row: id, col: 1, valueViewer: ({ value }) => viewOrderComponent(id, value.length),
      },
      {
        value: step, readOnly: true, key: `${id}-${1}`, row: id, col: 2,
      },
      {
        value: description, readOnly: typeOfParent === 'cycleArchive', key: `${id}-${2}`, row: id, col: 3, dataEditor: AreaEditor,
      },
      {
        value: test_data, readOnly: typeOfParent === 'cycleArchive', key: `${id}-${3}`, row: id, col: 4, dataEditor: AreaEditor,
      },
      {
        value: expected, readOnly: typeOfParent === 'cycleArchive', key: `${id}-${4}`, row: id, col: 5, dataEditor: AreaEditor,
      },
      {
        forceComponent: true,
        component: (
          <ListOfStatuses
            key={`1${id}-${5}`}
            disabled={
              propOr(false, 'disableStatus', params)
              || caseIsClosed
              || disableCaseFromCycleStatus
            }
            currentStatus={propOr('unexecuted', 'status', params)}
            onChangeStatusCallback={(data) => onChangeStatusCallback(data, id)}
          />
        ),
        value: status,
        key: `${id}-${5}`,
        row: id,
        col: 6,
      },
      {
        value: '',
        readOnly: true,
        valueViewer: () => viewActionComponent(id, isLastStep),
        key: `${id}-${6}`,
        row: id,
        col: 7,
      },
    ];

    return arr;
  };

  const viewOrderComponent = (index, length) => (
    <div className="flex">
      <NewOrderFormater
        allListLength={length}
        onMoveFuncCallback={onMoveFuncCallback}
        currentKey={index}
      />
    </div>
  );

  const viewActionComponent = (index, isLastStep) => (
    <div className="flex items-center justify-center">
      <AddOneRowInStep
        addLineDownCollback={(idx) => stepActionsFuncCallback('lineDown', idx)}
        addLineTopCollback={(idx) => stepActionsFuncCallback('lineTop', idx)}
        type={type}
        idx={index}
        caseIsClosed={caseIsClosed}
      />
      <CloneStepComponent
        caseIsClosed={caseIsClosed}
        onClone={() => { stepActionsFuncCallback('clone', index); }}
      />
      <RemoveStepComponent
        onRemove={(idx) => { stepActionsFuncCallback('remove', idx); }}
        step={index}
        disabled={isLastStep || caseIsClosed}
      />
    </div>
  );

  const singleRowForSuiteTestCase = (params) => {
    const {
      id,
      step = '',
      description = '',
      testData = '',
      expected = '',
      isLastStep = false,
    } = params;
  
    const arr = [
      {
        value: '',
        readOnly: true,
        key: `${id}-${0}`,
        row: id,
        col: 1,
        valueViewer: ({ value }) => viewOrderComponent(id, value.length),
      },
      {
        value: step,
        readOnly: true,
        key: `${id}-${1}`,
        row: id,
        col: 1,
      },
      {
        value: description,
        readOnly: false,
        key: `${id}-${2}`,
        row: id,
        col: 2,
        dataEditor: AreaEditor,
      },
      {
        value: testData,
        readOnly: false,
        key: `${id}-${3}`,
        row: id,
        col: 3,
        dataEditor: AreaEditor,
      },
      {
        value: expected,
        readOnly: false,
        key: `${id}-${4}`,
        row: id,
        col: 4,
        dataEditor: AreaEditor,
      },
      {
        value: '',
        readOnly: true,
        valueViewer: () => viewActionComponent(id, isLastStep),
        key: `${id}-${6}`,
        row: id,
        col: 6,
      },
    ];

    return arr;
  };

  const generateDataGrid = () => {
    let arr = [];
    switch (typeOfParent) {
      case 'suite':
        arr = steps.map((item, i) => {
          const id = i;
          const step = propOr(`#${i + 1}`, 'step', item);
          const description = propOr('', 'description', item);
          const testData = propOr('', 'test_data', item);
          const expected = propOr('', 'expected', item);

          return singleRowForSuiteTestCase({
            id,
            step,
            description,
            testData,
            expected,
            isLastStep: steps.length === 1,
          });
        });

        return arr;
      case 'cycle':
        arr = steps.map((item, i) => {
          const params = {
            id: i,
            ...item,
            step: `#${i + 1}`,
            disableStatus: ['unexecuted', 'block'].includes(prop('status', steps[i - 1])),
          };
          
          return singleRowForCycleTestCase(params);
        });

        return arr;
      case 'cycleArchive':
        arr = steps.map((item, i) => {
          const params = {
            id: i,
            ...item,
            step: `#${i + 1}`,
            disableStatus: true,
          };

          return singleRowForCycleTestCase(params, i);
        });

        return arr;
      default:
        return [...new Array(steps.length)].map((item, i) => singleRowForCycleTestCase([], i + 1));
    }
  };

  const updateGridWhenStatusUpdated = () => {
    const finalGrid = [...grid].map((row) => [...row]);
    // add new changes to grid
    changesOfStatus.forEach(({ row, col, value }) => {
      finalGrid[row][col] = {
        ...finalGrid[row][col],
        value,
        component: (
          <ListOfStatuses
            currentStatus={value}
            onChangeStatusCallback={(data) => onChangeStatusCallback(data, 0)}
          />
        ),
      };
    });

    // check and changes all grid statuses
    for (let i = 0; i < finalGrid.length; i++) {
      const prevRow = finalGrid[i - 1];
      const curRowValue = finalGrid[i][5].value;

      // check previous status row
      if (!isNil(prevRow) && ['unexecuted', 'block'].includes(prevRow[5].value)) {
        // if row is unexucted, make other rows is disabled
        for (let k = 0; k < finalGrid.length - i; k++) {
          const newIndex = i + k;
          finalGrid[newIndex][5] = {
            ...finalGrid[newIndex][5],
            value: 'unexecuted',
            component: (
              <ListOfStatuses
                disabled
                currentStatus="unexecuted"
                onChangeStatusCallback={(data) => onChangeStatusCallback(data, i)}
              />
            ),
          };
        }
        break;
      } else {
        finalGrid[i][5] = {
          ...finalGrid[i][5],
          component: (
            <ListOfStatuses
              disabled={false}
              currentStatus={curRowValue}
              onChangeStatusCallback={(data) => onChangeStatusCallback(data, i)}
            />
          ),
        };
      }
    }

    changeGrid(finalGrid);

    onCellsChangedCallback(finalGrid);
  };

  const updateGridWhenOrderUpdated = () => {
    const { param, id } = changesOfStepOrder;

    const arraymove = (arr, fromIndex, toIndex) => {
      let fromElement = arr[fromIndex];
      let toElement = arr[toIndex];

      fromElement = fromElement.map((item, i) => {
        const newStep = {
          ...item,
          row: toIndex,
          key: item.key.replace(item.key.charAt(0), toIndex),
          value: i === 1 ? `#${toIndex + 1}` : item.value,
        };

        if (i === 0) {
          newStep.valueViewer = ({ value }) => viewOrderComponent(toIndex, value.length);
        }

        if (i === fromElement.length-1) {
          newStep.valueViewer = () => viewActionComponent(toIndex);
        }
        return newStep;
      });

      toElement = toElement.map((item, i) => {
        const newStep = {
          ...item,
          row: fromIndex,
          key: item.key.replace(item.key.charAt(0), fromIndex),
          value: i === 1 ? `#${fromIndex + 1}` : item.value,
        };

        if (i === 0) {
          newStep.valueViewer = ({ value }) => viewOrderComponent(fromIndex, value.length);
        }

        if (i === toElement.length-1) {
          newStep.valueViewer = () => viewActionComponent(fromIndex);
        }

        return newStep;
      });

      if (param === 'up') {
        arr.splice(toIndex, 2, fromElement, toElement);
      }

      if (param === 'down') {
        arr.splice(fromIndex, 2, toElement, fromElement);
      }

      return arr;
    };

    let newGrid = [...grid];

    if (param === 'up') {
      newGrid = arraymove(newGrid, id, id - 1);
    }

    if (param === 'down') {
      newGrid = arraymove(newGrid, id, id + 1);
    }

    if(typeOfParent === 'suite'){
      changeGrid(reorderDataGridForTestSuiteCase(newGrid));
    }else{
      changeGrid(reorderDataGridForTestCycleCase(newGrid));
    }

    onCellsChangedCallback(newGrid);
  };

  const reorderDataGridForTestSuiteCase = (data) => data.map((item, i) => {
    const id = i;
    const step = `#${i + 1}`;
    const description = item[2].value;
    const testData = item[3].value;
    const expected = item[4].value;
    
    return singleRowForSuiteTestCase({
      id,
      step,
      description,
      testData,
      expected,
      isLastStep: data.length === 1,
    });
  });

  const reorderDataGridForTestCycleCase = (data) => data.map((item, i) => {    
    const id = i;
    const step = `#${i + 1}`;
    const description = item[2].value;
    const test_data = item[3].value;
    const expected = item[4].value;
    const status = item[5].value;
    return singleRowForCycleTestCase({
      id,
      step,
      description,
      test_data,
      expected,
      status,
      isLastStep: data.length === 1,
    });
  });

  const updateGridWhenActionsUpdated = () => {
    const { param, id } = changesOfStepActions;
    
    let newGrid = [...grid];

    if(typeOfParent === 'suite'){
      switch (param) {
        case 'remove':
          newGrid.splice(id, 1);
          newGrid = reorderDataGridForTestSuiteCase(newGrid);
          break;
        case 'lineTop':
            newGrid.splice(id, 0, singleRowForSuiteTestCase(id));
            newGrid = reorderDataGridForTestSuiteCase(newGrid);
          break;
        case 'lineDown':
            newGrid.splice(id + 1, 0, singleRowForSuiteTestCase(id));
            newGrid = reorderDataGridForTestSuiteCase(newGrid);
          break;
        case 'clone':
          newGrid.splice(id + 1, 0, grid[id]);
          newGrid = reorderDataGridForTestSuiteCase(newGrid);
          break;
        default: break;
      }
    }
    else{
      switch (param) {
        case 'remove':
          newGrid.splice(id, 1);
          newGrid = reorderDataGridForTestCycleCase(newGrid);
          break;
        case 'lineTop':
          newGrid.splice(id, 0, singleRowForCycleTestCase(id));
          newGrid = reorderDataGridForTestCycleCase(newGrid);
          break;
        case 'lineDown':
          newGrid.splice(id + 1, 0, singleRowForCycleTestCase(id));
          newGrid = reorderDataGridForTestCycleCase(newGrid);
          break;
        case 'clone':
          newGrid.splice(id + 1, 0, grid[id]);
          newGrid = reorderDataGridForTestCycleCase(newGrid);
          break;
        default: break;
      }
    }

    changeGrid(newGrid);
    
    onCellsChangedCallback(newGrid);
  };

  useEffect(() => {
    // console.log('useeffect 0');
    // set initial grid
    if (grid.length === 0) {
      const newGrid = generateDataGrid(steps);

      // console.log('useeffect 0', newGrid);

      changeGrid(newGrid);
    }
  }, [JSON.stringify(steps)]);

  useEffect(() => {
    // console.log('useeffect 1');

    const newGrid = generateDataGrid(steps);
    changeGrid(newGrid);
  }, [
    defaultGridFlag,
    caseIsClosed,
    currentCycleStatus,
  ]);

  useEffect(() => {    
    // console.log('useeffect 2');
    if (!isNil(changesOfStatus) && !isEmpty(changesOfStatus)) {
      updateGridWhenStatusUpdated();

      if (updateStatusCallback) {
        updateStatusCallback(changesOfStatus);
      }
    }
  }, [changesOfStatus]);

  useEffect(() => {    
    // console.log('useeffect 3');
    if (!isNil(changesOfStepOrder) && !isEmpty(changesOfStepOrder)) {
      updateGridWhenOrderUpdated();
    }
  }, [changesOfStepOrder]);

  useEffect(() => {
    // console.log('useeffect 4');
    if (!isNil(changesOfStepActions) && !isEmpty(changesOfStepActions)) {
      updateGridWhenActionsUpdated();
    }
  }, [changesOfStepActions]);

  useEffect(() => {
    // console.log('useeffect 5');
    if (newEmptyRows !== 0) {
      const newGrid = [...grid];
      let checkLengthGrid = newGrid.length === 0 ? 0 : newGrid.length;

      for (let i = 0; i < newEmptyRows; i += 1) {
        const id = checkLengthGrid;
        const step = `#${checkLengthGrid + 1}`;
        if(typeOfParent === "suite"){
          newGrid.push(singleRowForSuiteTestCase({ id, step, isLastStep: newGrid.length === 1 }));
        }else{
          newGrid.push(singleRowForCycleTestCase({id, step, isLastStep: newGrid.length === 1 }))
        }

        checkLengthGrid += 1;
      }

      changeGrid(newGrid);

      onCellsChangedCallback(newGrid);
    }
  }, [newEmptyRows]);
  // console.log('grid', grid);
  return (
    <DataGridComponentHook
      onCellsChangedCallback={changeGridAndReturnCallback}
      grid={grid}
      typeOfParent={typeOfParent}
    />
  );
}

DataGridWrapperWithControls.propTypes = {
  caseIsClosed: PropTypes.bool,
  defaultGridFlag: PropTypes.bool,
  typeOfParent: PropTypes.string,
  newEmptyRows: PropTypes.number,
  onCellsChangedCallback: PropTypes.func,
  steps: PropTypes.array,
  type: PropTypes.string,
  updateStatusCallback: PropTypes.func,
};

export default memo(DataGridWrapperWithControls);
  
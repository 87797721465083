import { useDispatch, useSelector } from 'react-redux';

import { propOr } from 'ramda';
import { axiosRequestPost } from '../../api/apiAxios';
import { EntityConstants } from '../../entity/constants/Constants';

export const useUtilityActors = () => {
  const dispatch = useDispatch();

  function getActorsFromPagination(data, constants = []) {
    const finalData = {
      not_banned: true,
      ...data,
    };

    const defaultConstants = [
      EntityConstants.FETCH_ACTORS_REQUEST,
      EntityConstants.FETCH_ACTORS_SUCCESS,
      EntityConstants.FETCH_ACTORS_FAILURE,
    ];

    return new Promise((resolve) => {
      dispatch(axiosRequestPost(
        'utility/actor/list',
        [
          constants[0] || defaultConstants[0],
          {
            type: constants[1] || defaultConstants[1],
            payload: (res) => {
              resolve(res);
              return propOr(res, 'data', res);
            },
          },
          constants[2] || defaultConstants[2],
        ],
        finalData,
      ));
    });
  }

  return {
    getActorsFromPagination,
  };
};

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { string } from 'prop-types';

import ListOfIssuesTable from '../issues/ListOfIssuesTable';

import { getProjectUUID } from '../../selectors/selectors';
import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';

function ListOfBugsModalBtn({
  parentUUID,
  typeOfParent,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const projectUUID = useSelector(getProjectUUID);

  const {
    initialPathForHistory,
    getClearListOfIssues,
  } = useContext(ProjectMainLayerContext);

  const redirectToIssue = (uuid) => {
    navigate(`${initialPathForHistory}issuesnew?activeProject=${projectUUID}&activeIssue=${uuid}`)
  };

  const getIssues = () => {
    const data = {
      parent: projectUUID,
      params: {},
      depth: 2,
    };

    if (typeOfParent === 'testCycle') {
      data.params.testCycleUuid = parentUUID;
    } else {
      data.params.testCaseUuid = parentUUID;
    }
    getClearListOfIssues(data);
  };

  useEffect(() => getIssues(), []);

  return (
    <ListOfIssuesTable
      changeIssueCallback={redirectToIssue}
      headerRowClassName="universal_header_table_row"
      noDataIndication={t('wms.no_issue_data', 'Sorry, no issue data')}
    />
  );
}

ListOfBugsModalBtn.propTypes = {
  parentUUID: string,
  typeOfParent: string,
};

export default ListOfBugsModalBtn;

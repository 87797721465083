import React from 'react';
import Icon from '@mdi/react';
import {
  mdiCheck,
  mdiDeleteOutline,
  mdiPlusCircleMultipleOutline,
  mdiTimelineClockOutline,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { bool, func, string } from 'prop-types';
import { stopPropagation } from '../../../54origins/utils54origins';
import { capitalize } from 'lodash';
import { UsersPartitionConstants } from '../../constants/Constants';

export default function ActionsActivity({
  isComplete,
  uuid,
  date,
  title,
  closePorover,
  updateActivityCallback,
  deleteActivityCallback,
  toggleOpenModalTimeLogCallback,
  syncChanges,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const IsSunday = moment(date).locale('en').format('dddd') === 'Sunday';
  const isFridayOrWeekend = moment(date).locale('en').format('dddd') === 'Friday'
    || moment(date).locale('en').format('dddd') === 'Saturday'
    || IsSunday;

  const toggleComplete = () => {
    updateActivityCallback(uuid, { completed: !isComplete }, true);
    const req_body = {
      action_type: 'update',
      parent_date: date,
      data: [
        {
          uuid,
          date,
          title,
          completed: !isComplete,
        },
      ],
    };
    syncChanges(req_body);

    closePorover();
  };

  const onKeyDownComplete = (e) => {
    if (e.key === 'Enter') {
      toggleComplete();
    }
  };

  const deleteCurrentActivity = () => {
    const req_body = {
      action_type: 'delete',
      parent_date: date,
      data: [
        {
          uuid,
          date,
        },
      ],
    };
    syncChanges(req_body);
    // console.log("delete activity", req_body);
    deleteActivityCallback(uuid);
    closePorover();
  };

  const onKeyDownDelete = (e) => {
    if (e.key === 'Enter') {
      deleteCurrentActivity();
    }
  };
  const toggleDuplicateTomorrow = () => {
    if (!IsSunday) {
      const tomorow = moment(date).add(1, 'days').format('YYYY-MM-DD');
      dispatch({
        type: UsersPartitionConstants.SET_DUPLICATE_ACTIVITY,
        payload: [{ date: tomorow, title }],
      });
      const req_body = {
        action_type: 'duplicate',
        data: [
          {
            uuid,
            tomorow,
          },
        ],
      };
      syncChanges(req_body);
      closePorover();
      // console.log("toggleDuplicateTomorrow arr", [{ date: tomorow, title }]);
    }
  };

  const onKeyDownDuplicateTomorrow = (e) => {
    if (e.key === 'Enter') {
      toggleDuplicateTomorrow();
    }
  };
  const toggleDuplicateForWeek = () => {
    if (!isFridayOrWeekend) {
      const amountDays = 7 - moment(date).day();
      const newData = [];
      for (let i = 1; i <= amountDays; i++) {
        const newDate = moment(date).add(i, 'day');
        const dayName = newDate.locale('en').format('dddd');

        if (dayName !== 'Sunday' && dayName !== 'Saturday') {
          newData.push({
            date: newDate.format('YYYY-MM-DD'),
            title,
          });
        }
      }
      // console.log("toggleDuplicateForWeek arr", newData);
      dispatch({
        type: UsersPartitionConstants.SET_DUPLICATE_ACTIVITY,
        payload: newData,
      });
      closePorover();
    }
  };

  const onKeyDownDuplicateForWeek = (e) => {
    if (e.key === 'Enter') {
      toggleDuplicateForWeek();
    }
  };

  const toggleOpenModalTimeLog = () => {
    toggleOpenModalTimeLogCallback();
    closePorover();
  };

  const onKeyDownOpenModalTimeLog = (e) => {
    if (e.key === 'Enter') {
      toggleOpenModalTimeLog();
    }
  };

  return (
    <div className="wrapper_select_actions_activity" onClick={stopPropagation}>
      <div
      id='toggleCompleteButton'
        onClick={toggleComplete}
        onKeyDown={onKeyDownComplete}
        className="action-activity"
        role="button"
        tabIndex="0"
      >
        <Icon path={mdiCheck} size={0.8} className="mr-2" />
        {isComplete
          ? capitalize(t('wms.adjective.uncomplete', 'uncomplete'))
          : capitalize(t('wms.verb.complete', 'complete'))}
      </div>

      <div
        id='toggleOpenModalTimeLogButton'
        className="action-activity"
        role="button"
        tabIndex="0"
        onClick={toggleOpenModalTimeLog}
        onKeyDown={onKeyDownOpenModalTimeLog}
      >
        <Icon path={mdiTimelineClockOutline} size={0.8} className="mr-2" />
        Create & View timelogs
      </div>

      <div
      id='toggleDuplicateTomorrowButton'
        onClick={toggleDuplicateTomorrow}
        onKeyDown={onKeyDownDuplicateTomorrow}
        className={`action-activity ${IsSunday && 'disabled'}`}
        role="button"
        tabIndex="0"
      >
        <Icon path={mdiPlusCircleMultipleOutline} size={0.8} className="mr-2" />
        {capitalize(t('wms.labels.duplicate_for_tomorrow'))}
      </div>
      <div
       id='toggleDuplicateForWeekButton'
        onClick={toggleDuplicateForWeek}
        onKeyDown={onKeyDownDuplicateForWeek}
        className={`action-activity ${isFridayOrWeekend && 'disabled'}`}
        role="button"
        tabIndex="0"
        disabled={isFridayOrWeekend}
      >
        <Icon path={mdiPlusCircleMultipleOutline} size={0.8} className="mr-2" />
        <div className="action-activity__text">
          {capitalize(t('wms.labels.duplicate_till_end_of_the_week'))}
        </div>
      </div>

      <div
      id='deleteCurrentActivityButton'
        onClick={deleteCurrentActivity}
        onKeyDown={onKeyDownDelete}
        className="action-delete-activity"
        role="button"
        tabIndex="0"
      >
        <Icon path={mdiDeleteOutline} size={0.8} className="mr-2" />
        {capitalize(t('wms.verb.delete'))}
      </div>
    </div>
  );
}

ActionsActivity.propTypes = {
  isComplete: bool,
  uuid: string,
  date: string,
  title: string,
  closePorover: func,
  updateActivityCallback: func,
  deleteActivityCallback: func,
  toggleOpenModalTimeLogCallback: func,
};

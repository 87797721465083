import React, {
  useContext, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { capitalize, get } from 'lodash';
import cn from 'classnames';
import { Card, Row, Space, Spin } from 'antd';
import moment from 'moment';
import HeaderCard from '../../commonComponents/HeaderCard';
import EditButton from '../../commonComponents/EditButton';
import SpecialTextArea from '../../commonComponents/SpecialTextArea/SpecialTextArea';
import { antNotification, capitalizeFirstChar } from '../../../../MainUtils';
import QaCollapseControlBtn from '../QaCollapseControlBtn';
import DateLeftEdit from '../../../../components/DateLeftEdit/DateLeftEdit';
import CommonInfo from '../../commonComponents/CommonInfo';
import CopyURL from '../../../../components/CopyURL';
import {
  getChecklist, getChecklistParams, getChecklistUUID, getIsUpdatingChecklist,
} from '../../../selectors/selectors';
import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';
import { defaultStateBtn } from '../../issuesView/utils';
import { updateChecklist } from '../../../actions/QaActions';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { getProject } from '../../../selectors/selectors';

function MainChecklist({
  partition,
  toggleReset,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const refTitle = useRef({});
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const {
    getListOfChecklists,
    deleteChecklist,
  } = useContext(BugTrackerContext);

  const checklist = useSelector(getChecklist);
  const checklistParams = useSelector(getChecklistParams);
  const checklistUUID = useSelector(getChecklistUUID);
  const updatingChecklist = useSelector(getIsUpdatingChecklist);
  const selectedProject = useSelector(getProject);

  const [stateBtnTitle, setStateBtnTitle] = useState(defaultStateBtn);
  const [startDate, setStartDate] = useState(defaultStateBtn);
  const [endDate, setEndDate] = useState(defaultStateBtn);

  const [collapse, setCollapse] = useState(false);

  const toggleCollapse = () => setCollapse((prev) => !prev);

  const createdBy = `${capitalizeFirstChar(get(checklist, 'uinfo.first_name', ''))} ${get(checklist, 'uinfo.last_name', '')}`;

  const subTitle = (
    <div className="flex items-center w-full gap5">
      <CommonInfo
        creator={createdBy}
        createdDate={get(checklist, 'created', '')}
      />
      <CopyURL
        partition={partition}
        entityType="checklist"
        entityUUID={checklistUUID ?? 0}
        qaEntityParent={get(selectedProject, '[0].uuid')}
      />
    </div>
  );

  const onChangeData = async (newData) => {
    const data = {
      uuid: checklistUUID,
      partition,
      params: {
        ...newData,
      },
    };

    dispatch(updateChecklist(data)).then((response) => {
      if (response) {
        antNotification('success', t('wms.noun.success', 'Success'));
        dispatch({
          type: ProjectTypeCommonConstants.UPDATE_CHECKLIST_IN_STORE,
          payload: response,
        });
      } else {
        antNotification('error', t('notifications.text.error.base', 'Error'));
      }
      getListOfChecklists();
    });
  };

  const setIsEdit = (e, closeEdit) => {
    const { id } = e.currentTarget;
    switch (id) {
      case 'title':
        if (stateBtnTitle.isEdit && !closeEdit) {
          onChangeData({ title: refTitle.current?.trim() });
        }
        refTitle.current = get(checklistParams, 'title', '');
        setStateBtnTitle((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'startDate':
        if (startDate.isEdit && !closeEdit) {
          onChangeData({ start_date: startDateRef.current });
        }
        startDateRef.current = get(checklistParams, 'start_date', '')
          ? get(checklistParams, 'start_date', '')
          : moment().format('YYYY-MM-DD');
        setStartDate((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'endDate':
        if (endDate.isEdit && !closeEdit) {
          onChangeData({ end_date: endDateRef.current });
        }
        endDateRef.current = get(checklistParams, 'end_date', '')
          ? get(checklistParams, 'end_date', '')
          : moment().format('YYYY-MM-DD');
        setEndDate((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      default: break;
    }
  };

  const onChangeTextArea = ({ id, value }) => {
    switch (id) {
      case 'title':
        refTitle.current = value;
        if (!stateBtnTitle?.isValid || !value.trim()?.length) {
          setStateBtnTitle((prev) => ({ ...prev, isValid: value.trim()?.length }));
        }
        break;
      default: break;
    }
  };

  const onChangeStartDate = (data) => {
    const { lifeTime: time } = data;

    if (moment(time).isAfter(moment(endDateRef?.current), 'day')) {
      antNotification('warning', t('notifications.text.warning.beginning_after_end', 'beginning_after_end'));
      setStartDate((prev) => ({ ...prev, isValid: false }));
    } else {
      if (startDateRef?.current
        && endDateRef?.current
        && moment(startDateRef?.current).isSameOrBefore(moment(endDateRef?.current))) {
        setEndDate((prev) => ({ ...prev, isValid: true }));
        setStartDate((prev) => ({ ...prev, isValid: true }));
      }
      startDateRef.current = time;
      if (!startDate?.isValid) {
        setStartDate((prev) => ({ ...prev, isValid: true }));
      }
    }
  };

  const onChangeEndDate = (data) => {
    const { lifeTime: time } = data;

    if (moment(time).isBefore((startDateRef?.current ? moment(startDateRef?.current) : moment()), 'day')) {
      antNotification('warning', t('notifications.text.warning.end_before_beginning', 'end_before_beginning'));
      setEndDate((prev) => ({ ...prev, isValid: false }));
    } else {
      if (startDateRef?.current
        && endDateRef?.current
        && moment(startDateRef?.current).isSameOrBefore(moment(endDateRef?.current))) {
        setEndDate((prev) => ({ ...prev, isValid: true }));
        setStartDate((prev) => ({ ...prev, isValid: true }));
      }
      endDateRef.current = time;
      if (!endDate?.isValid) {
        setEndDate((prev) => ({ ...prev, isValid: true }));
      }
    }
  };

  const headerButtons = (
    <Space
      align="center"
      size={[5, 5]}
    >
      <HardDeleteEntityContainer
        name="checklist"
        labelMode
        entityUUID={checklistUUID ?? ''}
        className="ant-btn customAntOutDangerBtn ant-btn-default"
        styleBtn={{
          borderRadius: '4px',
        }}
        actionForEntity={() => deleteChecklist(checklistUUID)}
        checkHardDelete={false}
        entityType="checklist"
        type="entity"
        sizeBtn="small"
        antNotificationSuccess
        partitionType={partition}
      />
    </Space>
  );

  return (
        <div className="relative">
          <HeaderCard
            title="Checklist"
            spanTitleSection={15}
            spanDescriptionSection={9}
            subTitle={subTitle}
            extra={headerButtons}
            backCallback={toggleReset}
          />
          <hr className="mt-1 mb-2" />

          <div className="pl-1">
            <div className="flex items-center">
              <div className="wrapper-title pb-0 flex items-center">
                <EditButton
                  id="title"
                  isEditFlag={stateBtnTitle.isEdit}
                  disabled={!stateBtnTitle.isValid && stateBtnTitle.isEdit}
                  onClick={setIsEdit}
                />
                {`${capitalize(t('wms.labels.title', 'Title'))}:`}
              </div>
              <div className="flex-grow-1 wrapper-description pt-0 pl-0 ml-2">
                <SpecialTextArea
                  id="title"
                  placeholder="Enter title"
                  maxLength={128}
                  defaultValue={get(checklistParams, 'title', '-')}
                  isEdit={stateBtnTitle.isEdit}
                  onChange={onChangeTextArea}
                />
              </div>
            </div>

            {collapse && (
            <>
              <hr className="my-2" />
              <div className="flex items-center">
                <div className="wrapper-title pb-0 flex items-center">
                  <EditButton
                    id="startDate"
                    isEditFlag={startDate.isEdit}
                    disabled={!startDate.isValid && startDate.isEdit}
                    onClick={setIsEdit}
                  />
                  <span className="mr-1">
                    {`${capitalizeFirstChar(t('wms.labels.date.start', 'start date'))}:`}
                  </span>
                </div>
                <div className={cn('flex-grow-1 wrapper-description pt-0 pl-0', { 'pt-1': startDate.isEdit })}>
                  {startDate.isEdit ? (
                    <DateLeftEdit
                      regularFormat
                      expirationDate={startDateRef.current}
                      onChangeFieldCallback={onChangeStartDate}
                    />
                  ) : (
                    <span className="ml-2">
                    {get(checklistParams, 'start_date', '-')}
                  </span>
                  )}
                </div>
              </div>
              <hr className="my-2" />
              <div className="flex items-center">
                <div className="wrapper-title pb-0 flex items-center">
                  <EditButton
                    id="endDate"
                    isEditFlag={endDate.isEdit}
                    disabled={!endDate.isValid && endDate.isEdit}
                    onClick={setIsEdit}
                  />
                  <span className="mr-1">
                    {`${capitalizeFirstChar(t('wms.labels.date.end', 'end date'))}:`}
                  </span>
                </div>
                <div className={cn('flex-grow-1 wrapper-description pt-0 pl-0', { 'pt-1': endDate.isEdit })}>
                  {endDate.isEdit ? (
                    <DateLeftEdit
                      regularFormat
                      expirationDate={endDateRef?.current}
                      onChangeFieldCallback={onChangeEndDate}
                    />
                  ) : (
                    <span className="ml-2">
                    {get(checklistParams, 'end_date', '-')}
                  </span>
                  )}
                </div>
              </div>
            </>
            )}
          </div>

          <QaCollapseControlBtn
            toggleCollapse={toggleCollapse}
            collapse={collapse}
          />
        </div>

  );
}

MainChecklist.propTypes = {
  toggleReset: PropTypes.func,
  partition: PropTypes.string,
};

export default MainChecklist;

import React, { useEffect, useState } from 'react';
import { string, number, arrayOf, func, object, bool, array, } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { get, capitalize, isEqual, isEmpty, concat } from 'lodash';
import cn from 'classnames';

import { mdiChatProcessingOutline, mdiTimerSand } from '@mdi/js';
import { Tooltip, Badge} from 'antd';
import { prop } from 'ramda';
import Icon from '@mdi/react';
import { columnsFilterConfig, defaultDocumentFilter } from '../docView&ideas/docView/constants/documentFilterOptions';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import CustomAvatar from "../../../components/CustomAvatar/CustomAvatar";
import DateLeft from '../../../components/DateLeft';

import {
  allProjDocsAndIdea,
  areFetchingProjDocs,
  getProjectUUID,
  getTotalDocsAndIdea,
} from '../../selectors/selectors';

import { useResize } from '../../../hooks/useResize';
import { dateWithTooltipTime, renderTableID } from '../../../MainUtils';
import { COMMENT_TYPE } from '../comment/commentType';
import { getListDocumentsOrIdea } from '../../actions/DocumentsActions';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import useTableFilter from '../../../components/_ui/hooks/useTableFilter';
import { partitionNamesConfig } from '../../../api/appConfig';

function NewDocsTable({
  setWithComments,
  listDocumentsRelated,
  partitionType = partitionNamesConfig.partition1,
  multiListDocuments,
  setMultiListDocuments
}) {

  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { width } = useResize();

  const parentUUID = useSelector(getProjectUUID)
  const projDocsAndIdea = useSelector(allProjDocsAndIdea);
  const total = useSelector(getTotalDocsAndIdea);
  const [totalCountDocs, setTotalCountDocs] = useState(0);
;

  //add fetch 
  const docListFetching = useSelector(areFetchingProjDocs);
  const defaultDocumentOrder = {
    orderBy: 'created',
    order: 'desc',
  };
  
  const defaultDocumentPagination = {
    currentPage: 1,
    limit: 10,
    offset: 0,
  };

  const {
    filterConfig: filterConfig,
    changeFilterConfig: changeDocumentFilterConfig,
  } = useTableFilter('document', defaultDocumentFilter);

  const defaultDocumentsConfig = {
    ...defaultDocumentPagination,
    ...defaultDocumentOrder,
    ...filterConfig,
  };

  const [documentsConfig, setDocumentsConfig] = useState(defaultDocumentsConfig)
  
  const loadSystemDocuments = async (config) => {
    const requestConfig = {
      ...config,
      offset: 0,
      params: {
        ...config?.params,
        status: ['system'],
      }
    };
    console.log('system docs:', requestConfig);

    return dispatch(getListDocumentsOrIdea(
      requestConfig,
      partitionType,
    ));
  };

  const loadOmitSystemDocuments = async (config, constants) => {
    const requestConfig = {
      ...config,
      params: {
        ...get(config, 'params', {}),
        status__not: concat(get(config, 'params.status__not', []), ['system']),
      },
    };
    // console.log('other docs:', requestConfig);

    return dispatch(getListDocumentsOrIdea(
      requestConfig,
      partitionType,
      constants,
    ));
  };

  const loadAllDocuments = async (properties) => {
    const {
      entity_type,
      status,
      search,
      orderBy,
      order,
      offset,
      limit,
    } = properties || {};

    const config = {
      parent: parentUUID,
      entity_type,
      params: {},
      orderBy,
      order,
      offset,
      limit,
    };

    if (status && status?.length) {
      config.params.status = status;
    }

    if (search) {
      config.search_data = {
        value: search,
        ignore_case: true,
        fields: {
          params: ['title'],
        },
      };
    }

    if (!parentUUID) return;
    // console.log('docs config:', config);

    let data = {
      data: [],
      total: 0,
    };

    const needLoadSystemDocuments = !entity_type || entity_type?.includes('projectDocument');

    if (needLoadSystemDocuments) {
     
      const otherDocuments = await loadOmitSystemDocuments(
        config,
        [
          ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_REQUEST,
          ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_SUCCESS,
          ProjectTypeCommonConstants.FETCH_DOCUMENTS_AND_IDEA_FAILURE,
        ],
      );

      data = {
        data: otherDocuments.data,
        total: otherDocuments.total,
      }
      setTotalCountDocs(prop('total', data));
    } else data = await loadOmitSystemDocuments(config);

    // console.log(data)
    setTotalCountDocs(prop('total', data));
    return prop('data', data);
  };


  const [config, setConfig] = useState({
    ...defaultDocumentOrder,
    ...defaultDocumentPagination,
    ...filterConfig,
    search: '',
  });
  const [isConfigChanged, setIsConfigChanged] = useState(true);

  const changeConfig = (newConfig) => {
    const updatedConfig = {
      ...config,
      ...newConfig,
    }

    if (!isEqual(config, updatedConfig)) {
      setConfig(updatedConfig);
      setIsConfigChanged(true);
    }
  };

  const {
    currentPage,
    limit,
  } = config || {};

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    style: { background: '#e5f4fc' },
    selected: [],
  };

  const changePageLimit = (size) => {
    changeConfig({ currentPage: 1, limit: size, offset: 0 });
  };

  const onSearchData = (value) => {
    changeConfig({
      currentPage: 1,
      offset: 0,
      limit,
      search: value,
    });
  };

  const currentSortCallback = (dataSort) => {
    // const orderBy = get(dataSort, 'orderBy');
    const order = get(dataSort, 'order');

    if (!order) changeConfig(defaultDocumentOrder);
    else changeConfig(dataSort);
  };

  const commentsCountFormatter = (cell) => (cell >= 0) && (
    <Badge count={cell} showZero color="#fc676a" className="flex justify-center" />
  );

  const userFormatter = (cell) => {
    const fullName = `${prop('first_name', cell)} ${prop('last_name', cell)}`;
    return (
      <div className="flex flex-wrap items-center">
        <CustomAvatar
          fullName={fullName}
        />
      </div>
    );
  };

  const lifeTimeFormatter = (cell, row) => (cell && row.status !== 'important') && <DateLeft minus date={cell} type="tag" customStyle="text-neutral-600" />;

  const rowClassName = (record) => 
    cn(
      'item_from_list_items',
         {
           selected:  multiListDocuments.includes(record.uuid),
         },
      )
  

  const handleChangeData = (selectedOption) => {
    if (multiListDocuments.includes(selectedOption)) {
      setMultiListDocuments(prev => prev.filter(el => el !== selectedOption))
    } else {
      setMultiListDocuments(prev => [...prev, selectedOption])   
    }
  };

  const onRow = (record) => ({
    onClick: () => handleChangeData(record.uuid)
    // onMouseDown: (e) => onMouseMiddleClick(e, record),
  });

  useEffect(() => {
    setMultiListDocuments(listDocumentsRelated)
  }, [])

  const docsColumns = [
    {
      dataIndex: 'id',
      title: 'ID',
      key: 'id',
      sorter: true,
      render: (cell, row) => {
        let letter;
        const { entity_type } = row;

        switch (entity_type) {
          case 'projectIdea': {
            letter = 'N';
            break;
          }
          case 'projectDocument': {
            letter = 'D';
            break;
          }
          case 'projectSpreadsheet': {
            letter = 'S';
            break;
          }
        }
        return (cell && renderTableID(cell, letter));
      },
    },
    {
      dataIndex: 'title',
      key: 'title',
      title: <AntDesignSearchBox54origins onSearch={onSearchData} />,
      render: (docName) => (
        <EntityParamTag
          value={docName}
          type="value"
          param="title"
          maxLength={32}
        />
      ),
    },
    {
      dataIndex: 'entity_type',
      key: 'entity_type',
      title: capitalize(t('wms.table.headers.type', 'type')),
      sorter: true,
      ...(columnsFilterConfig && { ...columnsFilterConfig?.type }),
      ...(filterConfig?.entity_type && { defaultFilteredValue: filterConfig?.entity_type }),
      render: (cell, row) => cell && (
        <EntityParamTag
          param='type'
          value={get(row, ['params', 'type']) || cell}
          type='badge'
        />
      ),
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: capitalize(t('wms.table.headers.status', 'status')),
      sorter: true,
      ...(columnsFilterConfig && { ...columnsFilterConfig?.status }),
      ...(filterConfig?.status && { defaultFilteredValue: filterConfig?.status }),
      filterResetToDefaultFilteredValue: true,
      render: (cell, row) => (
        <EntityParamTag
          value={get(row, ['params', 'status']) || cell}
          param="status"
          type={width > 1500 ? 'iconWithStatus' : 'icon'}
        />
      ),
    },
    {
      dataIndex: COMMENT_TYPE.TOTAL,
      key: COMMENT_TYPE.TOTAL,
      hidden: width < 1700,
      title: (
        <Tooltip title={capitalize(t('wms.table.headers.comments', 'comments'))}>
          <Icon path={mdiChatProcessingOutline} size={1} />
        </Tooltip>
      ),
      render: commentsCountFormatter,
    },
    {
      dataIndex: 'uinfo',
      title: capitalize(t('wms.table.headers.owner', 'owner')),
      render: userFormatter,
    },
    {
      dataIndex: 'updated',
      key: 'modified',
      hidden: width < 1600,
      title: capitalize(t('wms.table.headers.modified', 'modified')),
      sorter: true,
      render: (cell) => dateWithTooltipTime(cell, 'DD-MM-YY'),
    },
    {
      dataIndex: 'lifeTime',
      key: 'lifeTime',
      hidden: width < 1420,
      sorter: true,
      title: (
        <Tooltip title={capitalize(t('wms.adjective.expired', 'expired'))}>
          <Icon path={mdiTimerSand} size={1} />
        </Tooltip>
      ),
      render: lifeTimeFormatter,
    },
  ]
    .map((el) => ({
      ...el,
      onCell: () => ({
        onClick: () => setWithComments?.(el.dataIndex === COMMENT_TYPE.TOTAL),
      }),
    }))
    .filter((el) => !el.hidden);

    const setCurrentConfigAndGetRequest = (newConfig) => {
      // console.log('docsConfig newConfig', newConfig);
      setDocumentsConfig(newConfig);
      loadAllDocuments(newConfig);
    };

  useEffect(() => {
    // console.log('effect 1 document');
    if (!isEmpty(filterConfig)) {
      changeConfig({
        ...filterConfig,
        currentPage: 1,
        offset: 0,
        limit,
      });
    }
  }, [filterConfig]);

  useEffect(() => {
    if (isConfigChanged) {
      // console.log('effect 2 document');
      setCurrentConfigAndGetRequest?.(config);
      setIsConfigChanged(false);
    }
  }, [isConfigChanged]);

  return (
          <BaseTableWithPagination
            headerRowClassName="universal_header_table_row"
            rowSelection={selectRow}
            rowClassName={rowClassName}
            columns={docsColumns}
            data={projDocsAndIdea}
            total={totalCountDocs || total}
            loading={docListFetching}
            useCustomPagination
            paginationSize="small"
            newCurrentPage={currentPage}
            pageLimit={limit}
            pageSizeOptions={[5, 10]}
            onRow={onRow}
            changePageLimit={changePageLimit}
            getPaginationOptions={changeConfig}
            currentSortCallback={currentSortCallback}
            filterCallback={changeDocumentFilterConfig}
            rowHoverable={false}
            size="small"
          />
  );
};

NewDocsTable.propTypes = {
  className: string,
  hiddenElements: arrayOf(string),
  fetching: bool,
  partitionType: string,
  totalCount: number,
  filterColumnsConfig: object,
  onMouseMiddleClick: func,
  setWithComments: func,
  setCurrentConfigAndGetRequest: func,
  deleteDocumentCallback: func,
  filterCallback: func,
  newDocumentCallback: func,
  selectDocumentCallback: func,
  updateDocumentsTableCallback: func,
  listDocumentsRelated: array,
  multiListDocuments: array,
  setMultiListDocuments: func,
};

export default NewDocsTable;
import { useLocation } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash';

const useProjectURL = () => {
  const location = useLocation();

  const splitPath = location.pathname.split('/');

  const findWord = (word) => splitPath.indexOf(word);
  const checkEmpty = (param) => !isEmpty(param) && !isNil(param);

  // PROJECT section

  const findProjectsWordIndex = findWord('projects') !== -1 ? findWord('projects') : findWord('publicprojects');
  const findProjectsWord = findProjectsWordIndex !== -1;
  const findVectorWordIndex = findWord('vector');
  const findVectorWord = findWord('vector') !== -1;
  const findIssueWordIndex = findWord('issue');
  const findIssueWord = findWord('issue') !== -1;
  const findSuiteWordIndex = findWord('suite');
  const findCycleWordIndex = findWord('cycle');
  const findCaseSuiteWordIndex = findWord('caseSuite');
  const findCaseCycleWordIndex = findWord('caseCycle');

  const findProjectUUID = splitPath[findProjectsWordIndex + 1];
  const findVectorUUID = splitPath[findVectorWordIndex + 1];
  const findIssueUUID = splitPath[findIssueWordIndex + 1];
  const findSuiteUUID = splitPath[findSuiteWordIndex + 1];
  const findCycleUUID = splitPath[findCycleWordIndex + 1];
  const findCaseSuiteUUID = splitPath[findCaseSuiteWordIndex + 1];
  const findCaseCycleUUID = splitPath[findCaseCycleWordIndex + 1];

  const findCurrentProjectTab = splitPath[findProjectsWordIndex + 1];
  const firstChildEntity = splitPath[findProjectsWordIndex + 3];
  const firstChildEntityUUID = splitPath[findProjectsWordIndex + 4];
  const secondChildEntity = splitPath[findProjectsWordIndex + 5];
  const secondChildEntityUUID = splitPath[findProjectsWordIndex + 6];

  const firstChildEntityIsExist = checkEmpty(firstChildEntity);
  const secondChildEntityIsExist = checkEmpty(secondChildEntity);

  // USER section

  const userUUIDFromUrl = splitPath[splitPath.indexOf('user') + 1];

  return [{
    findCaseCycleUUID,
    findCaseCycleWordIndex,
    findCaseSuiteUUID,
    findCaseSuiteWordIndex,
    findCurrentProjectTab,
    findCycleUUID,
    findCycleWordIndex,
    findIssueUUID,
    findIssueWord,
    findProjectUUID,
    findProjectsWord,
    findSuiteUUID,
    findSuiteWordIndex,
    findVectorUUID,
    findVectorWord,
    firstChildEntity,
    firstChildEntityIsExist,
    firstChildEntityUUID,
    secondChildEntity,
    secondChildEntityIsExist,
    secondChildEntityUUID,
    splitPath,
    userUUIDFromUrl,
  }];
};

export default useProjectURL;

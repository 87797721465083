import {ProjectTypeCommonConstants} from "../../constants/Constants";

const initialState = {
  fetching: false,
  data: [],
  error: '',
  entitiesFromArchiveState: [],
  entitiesFromArchiveStateFetching: false,
  entitiesFromArchiveStateError: ''
};

export default (state=initialState, action) => {
  const {payload, type} = action;
  switch (type) {
    case ProjectTypeCommonConstants.GET_ARCHIVE_ENTITY_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case ProjectTypeCommonConstants.GET_ARCHIVE_ENTITY_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload
      };
    case ProjectTypeCommonConstants.GET_ARCHIVE_ENTITY_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        error: payload
      };

    case ProjectTypeCommonConstants.ALL_DATA_FROM_ARCHIVE_ENTITY_REQUEST:
      return {
        ...state,
        entitiesFromArchiveStateFetching: true
      };
    case ProjectTypeCommonConstants.ALL_DATA_FROM_ARCHIVE_ENTITY_SUCCESS:
      return {
        ...state,
        entitiesFromArchiveStateFetching: false,
        entitiesFromArchiveState: payload
      };
    case ProjectTypeCommonConstants.ALL_DATA_FROM_ARCHIVE_ENTITY_FAILURE:
      return {
        ...state,
        entitiesFromArchiveStateFetching: false,
        entitiesFromArchiveState: [],
        entitiesFromArchiveStateError: payload
      };

    case ProjectTypeCommonConstants.CLEAR_ARCHIVE_ENTITY:
      return {
        ...state,
        fetching: false,
        data: [],
        error: '',
        entitiesFromArchiveStateFetching: false,
        entitiesFromArchiveState: [],
        entitiesFromArchiveStateError: payload
      };

    default:  return state;
  }
};

import { ProjectTypeCommonConstants } from "../constants/Constants";

const initialState = {
  fetching: false,
  viewed: [],
  unseen: [],
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ProjectTypeCommonConstants.ADD_UNSEEN_NOTIFICATIONS:
      const { payload } = action;

      return {
        ...state,
        unseen: Array.isArray(payload)
          ? [...payload]
          : [...state.unseen, payload],
      };

    case ProjectTypeCommonConstants.GET_ALL_VIEWED_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        viewed: [],
        fetching: true,
        error: "",
      };

    case ProjectTypeCommonConstants.GET_ALL_VIEWED_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        viewed: action.payload,
        fetching: false,
        error: "",
      };

    case ProjectTypeCommonConstants.GET_ALL_VIEWED_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        viewed: [],
        fetching: false,
        error: action.payload,
      };

    case ProjectTypeCommonConstants.MARK_AS_VIEWED_NOTIFICATION:
      return {
        ...state,
        viewed: [
          ...state.viewed,
          {
            ...state.unseen.find((item) => item.uuid === action.payload),
            viewed: true,
          },
        ],
        unseen: [
          ...state.unseen.filter((item) => item.uuid !== action.payload),
        ],
      };

    case ProjectTypeCommonConstants.RESET_ALL_VIEWED_NOTIFICATIONS:
      return {
        ...state,
        viewed: [],
        fetching: false,
        error: "",
      };

    case ProjectTypeCommonConstants.MARK_AS_VIEWED_ALL_NOTIFICATIONS:
      return {
        ...state,
        viewed: [
          ...state.viewed,
          ...state.unseen.map((item) => ({ ...item, viewed: true })),
        ],
        unseen: [],
      };
    case ProjectTypeCommonConstants.GET_MENTIONED_PROJECTS_REQUEST:
      return {
        ...state,
        // fetching: true,
      };
    case ProjectTypeCommonConstants.GET_MENTIONED_PROJECTS_SUCCESS:
      return {
        ...state,
        // fetching: false,
        mentioned_projects: Array.isArray(action?.payload?.data)
          ? action?.payload?.data?.slice(0)
          : [],
      };
    case ProjectTypeCommonConstants.GET_MENTIONED_PROJECTS_FAILURE:
      return {
        ...state,
        // fetching: false,
        mentioned_projects: [],
      };
    case ProjectTypeCommonConstants.GET_SENDERS_REQUEST:
      return {
        ...state,
        // fetching: true,
      };
    case ProjectTypeCommonConstants.GET_SENDERS_SUCCESS:
      return {
        ...state,
        // fetching: false,
        senders: Array.isArray(action?.payload?.data)
          ? action?.payload?.data?.slice(0)
          : [],
      };
    case ProjectTypeCommonConstants.GET_SENDERS_FAILURE:
      return {
        ...state,
        // fetching: false,
        senders: [],
      };
    case ProjectTypeCommonConstants.SET_SENDER_FILTER:
      return {
        ...state,
        filter_by_sender: action.payload,
      };
    default:
      return state;
  }
};

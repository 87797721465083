import React from "react";
import { bool, func, object, string } from "prop-types";

import dayjs from "dayjs";

import Icon from "@mdi/react";
import { mdiHelp } from "@mdi/js";

import { Badge, Flex, Tooltip } from "antd";

import BaseButton from "../../../../components/_ui/BaseButton/BaseButton";
import TotalDayHoursButtons from "./TotalDayHoursButtons";

export default function PlanForDayInfoSection({
  day,
  dayParams = {},
  onActionCallback,
  isMainBoard = false,
  showMoveIssues = true
}) {
  const {
    hours: availableHours = 8,
    totalDayHours = 0,
  } = dayParams;

  const isToday = dayjs().isSame(dayjs(day), 'day');
  const isOvertime = availableHours - totalDayHours < 0;

  const btnRed = 'border border-red-500 text-red-500 hover:bg-red-500 hover:text-white';
  const btnInfoClass = "pl-0.3 rounded-full cursor-pointer border border-sky-500 hover:bg-sky-500 hover:text-white";
  const btnInfoStyle = {
    width: 20,
    height: 20,
  };

  const onMoveOvertimeClick = (e) => {
    onActionCallback({
      availableHours,
      overtime: totalDayHours - availableHours
    }, 'day-move-overtime-issues', e);
  }

  return <Flex>
    <Flex
      align="center"
      className="text-base text-sky-500"
    >
      <Tooltip
        title={"This is the time you have allocated for today to complete issues."}
        color="rgb(45, 183, 245)"
      >
        <Flex
          align="center"
          justify="center"
          style={btnInfoStyle}
          className={btnInfoClass}
        >
          <Icon path={mdiHelp} size={0.6} />
        </Flex>
      </Tooltip>
      <span className="mx-1">
        Your work time:
      </span>
      <TotalDayHoursButtons
        onActionCallback={onActionCallback}
        availableHours={availableHours}
      />
      <span className="ml-1">
        hours
      </span>
    </Flex>
    <Flex
      align="center"
      className="ml-6 text-base text-sky-500"
    >
      <Tooltip
        color="rgb(45, 183, 245)"
        title={"The remaining time is the time available to perform tasks. Overtime is the time by which you have exceeded your available time for the day."}
      >
        <Flex
          align="center"
          justify="center"
          style={btnInfoStyle}
          className={btnInfoClass}
        >
          <Icon path={mdiHelp} size={0.6} />
        </Flex>
      </Tooltip>
      {isOvertime ? (
        <span className="ml-1 text-red-500">
          Your overtime:
          <Badge
            showZero
            count={totalDayHours - availableHours}
            className="ml-1 -mt-1"
          />
          {((availableHours - totalDayHours === 1) ? ' hour' : ' hours')}
        </span>
      ) : (
        <span className="ml-1">
          Remaining time:
          <Badge
            showZero
            count={availableHours - totalDayHours}
            color="rgb(45, 183, 245)"
            className="ml-1 -mt-1"
          />
          {((availableHours - totalDayHours === 1) ? ' hour' : ' hours')}
        </span>
      )}


      {/* {isOvertime && isToday && !isMainBoard && showMoveIssues && 
      <BaseButton
        className={`ml-2 text-xs ${btnRed}`}
        size="small"
        onClick={onMoveOvertimeClick}
      >
        Move overtime issues
      </BaseButton>} */}
    </Flex>
  </Flex>
}

PlanForDayInfoSection.propTypes = {
  day: string,
  dayParams: object,
  onActionCallback: func,
  isMainBoard: bool,
  showMoveIssues: bool
}
import { get } from "lodash";

export const getColorByStatus = (data) => {
    const status = typeof data === 'string' ? data : get(data, 'status', '');
    switch (status) {
        case 'created':
        case 'acquainted':
        case 'accepted':
        case 'rejected':
        case 'reopened':
            return '#51a5ff';
        case 'in progress':
        case 'review':
        case 'ready for deploy':
            return '#5676f3';
        case 'test':
        case 'testing':
        case 'not reproducible':
            return '#f89451';
        case 'backlog':
        case 'blocked':
        case 'paused':
        case 'discuss':
        case 'not enough info':
            return '#888888';
        case 'closed':
        case 'abandoned':
        case 'deployed':
            return '#4d4d4d'
        default: return '#51a5ff';
    }
};

export const getColorByPriority = (data) => {
    const priority = typeof data === 'string' ? data : get(data, 'priority', '');
    switch (priority) {
        case 'low':
            return '#51a5ff';
        case 'medium':
            return '#f89451';
        case 'high':
        case 'urgent':
            return '#f5222d';
        default: return '#51a5ff';
    }
}
import { mdiArrowLeftBold, mdiArrowRightBold, mdiCalendarMonthOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Col, DatePicker } from 'antd';
import dayjs from 'dayjs';
import {
  array, bool, func, string,
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getLocale } from '../../../locale/selectors';
import DateRange from './DateRange';
import {
  dateToFormat3,
  endOfWeek,
  startOfWeek,
} from '../../../54origins/dateFormats54origins';

export default function SwitcherOfDate({
  changePeriodCallback,
  typePeriod,
  classNameWrapper = '',
  disabled,
  rangePickerValue,
  defaultDate = dayjs().startOf(typePeriod).format('YYYY-MM-DD'),
  typeView,
}) {
  const locale = useSelector(getLocale);

  const [currentPeriod, changeCurrentPeriod] = useState(null);

  const savePeriod = (date) => {
    changeCurrentPeriod(date);
    changePeriodCallback?.(date);
  };

  const changeDate = async (param) => {
    let newWeekIndex = 0;

    if (param === 'up') {
      newWeekIndex += 1;
    } else {
      newWeekIndex -= 1;
    }

    switch (typePeriod) {
      case 'isoWeek':
        const startWeek = dayjs(currentPeriod)
          .startOf('isoWeek')
          .add(newWeekIndex, 'week').format('YYYY-MM-DD');

        savePeriod(startWeek);
        break;
      case 'day':
        const startDay = dayjs(currentPeriod)
          .startOf('day')
          .add(newWeekIndex, 'day').format('YYYY-MM-DD');

        savePeriod(startDay);
        break;
      case 'months':
        const startMonths = dayjs(currentPeriod)
          .startOf('months')
          .add(newWeekIndex, 'months').format('YYYY-MM-DD');

        savePeriod(startMonths);
        break;
      default:
        break;
    }
  };

  const customWeekStartEndFormat = (value) => `
  ${dateToFormat3(startOfWeek(value))} - ${dateToFormat3(endOfWeek(value))}
  ${dayjs(value)
    .locale(locale)
    .startOf(typePeriod)
    .format('MMMM YYYY')}`;

  const onChangeDatePicker = (value) => {
    const startDate = dayjs(value)
      .startOf('isoWeek').format('YYYY-MM-DD');

    savePeriod(startDate);
  };

  useEffect(() => {
    if (typePeriod !== 'rangePicker') {
      changeCurrentPeriod?.(defaultDate);
    }
  }, [defaultDate, typePeriod]);

  return (
    <div className={classNameWrapper}>
      <div className="wrapper-switcher-period w-full">
        <div
          className="flex justify-between"
        >
          <div
          id='changeDateDownButton'
            onClick={() => changeDate('down')}
            className={`arrow-wrapper arrow-wrapper__arrow ${disabled ? 'cursor-not-allowed ' : ''}`}
            role="button"
            tabIndex="0"
          >
            <Icon
              path={mdiArrowLeftBold}
              className="arrow-wrapper__icon"
              size={1.3}
            />
          </div>
          <Col
            id="TooltipDateBtn"
            className="flex items-center cursor-pointer p-3"
          >
            {typeView === 'picker' ? (
              currentPeriod && (
                <DatePicker
                  onChange={onChangeDatePicker}
                  allowClear={false}
                  format={customWeekStartEndFormat}
                  value={dayjs(currentPeriod)}
                  picker="week"
                  className="time_picker_in_switcher_of_date"
                />
              )

            ) : (
              defaultDate && (
              <>
                <DateRange
                  typePeriod={typePeriod}
                  currentPeriod={currentPeriod}
                  rangePickerValue={rangePickerValue}
                />
                <Icon
                  path={mdiCalendarMonthOutline}
                  className="ml-1"
                  size={1.3}
                />
              </>
              )
            )}
          </Col>

          <div
            id='changeDateUpButton'
            onClick={() => changeDate('up')}
            className={`arrow-wrapper arrow-wrapper__arrow ${disabled ? 'cursor-not-allowed ' : ''}`}
            role="button"
            tabIndex="0"
          >
            <Icon
              path={mdiArrowRightBold}
              className="arrow-wrapper__icon"
              size={1.3}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

SwitcherOfDate.propTypes = {
  changePeriodCallback: func,
  defaultDate: string,
  classNameWrapper: string,
  typePeriod: string,
  disabled: bool,
  rangePickerValue: array,
  typeView: string,
};

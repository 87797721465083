import { get } from "lodash";
import { prop } from "ramda";

export const compare = (fileA, fileB) => prop('content_length', fileA) - prop('content_length', fileB);

export const imageFiles = (item) => get(item, 'files', [])
  .filter(
    ({ content_type }) => content_type && content_type.includes('image'),
  )
  .sort(compare);

export const otherFiles = (item) => get(item, 'files', [])
  .filter(
    ({ content_type }) => content_type
          && !content_type.includes('audio')
          && !content_type.includes('image'),
  )
  .sort(compare);

export const audioFiles = (item) => get(item, 'files', [])
  .filter(
    ({ content_type }) => content_type && content_type.includes('audio'),
  )
  .sort(compare);

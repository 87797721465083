import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';

import {
  mdiBullseyeArrow,
  mdiAccountGroup,
  mdiTimerOutline,
} from '@mdi/js';
import { capitalize } from 'lodash';

import Icon from '@mdi/react';
import {
  Card,
  Tooltip,
  Flex,
} from 'antd';

import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import DateLeft from '../../../../components/DateLeft';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import GoToButton from '../../../../components/buttons/GoToButton';
import MilestoneProgressBar from '../../milestones/MilestoneProgressBar';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import InfoComponent from '../projectInfo/components/infoComponent/InfoComponent';

import {
  getProjectMilestonesFetching,
  CLEAR_ALL_PROJECT_MILESTONES,
} from '../../../reducers/slicers/projectMilestonesSlicer';

import { getListOfProjectMilestonesRequest } from '../../../actions/projectMilestonesActions';
import { getUserPublicProfileUUID } from '../../../../userFlow/store/selectors/selectors';

import { getProjectParams } from '../../../selectors/selectors';

import { useResize } from '../../../../hooks/useResize';
import useFetchReducerHook from '../../../../hooks/useFetchReducerHook';

import { renderTableID } from '../../../../MainUtils';
import { kpmInfo } from '../../milestones/helpers/milestoneUtils';
import { dataWithMilestoneScore, renderMilestoneScore } from '../../milestones/helpers/computeScore';
import { defaultDate, dateDiff } from '../../../../54origins/dateFormats54origins';
import BaseCard from "../../../../components/_ui/BaseCard/BaseCard";
import { partitionNamesConfig } from '../../../../api/appConfig';

const defaultPaginationOptions = {
  currentPage: 1,
  pageLimit: 5,
  defaultPageLimit: 5,
  pageLimitList: [5, 10, 25],
};

const sliceMilestones = (data, page, pageSize) => {
  const copyData = Array.isArray(data) ? [...data] : [];
  const start = pageSize * (page - 1);
  const range = pageSize;

  const filteredData = copyData.splice(start, range);

  return filteredData;
};

const sortByLeftDays = (a, b) => {
  const aDays = dateDiff(a.lifeTime, 'days', defaultDate());
  const bDays = dateDiff(b.lifeTime, 'days', defaultDate());
  return bDays - aDays;
};

function ProjectMilestonesAtWork({
  parent,
  partitionType,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { width } = useResize();

  const projectMilestonesFetching = useSelector(getProjectMilestonesFetching);
  const projectParams = useSelector(getProjectParams);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);

  const [paginationState, setPaginationState] = useState(() => defaultPaginationOptions);

  const {
    currentPage,
    pageLimit,
    pageLimitList,
    defaultPageLimit,
  } = paginationState || {};

  const {
    data: milestones,
    setData,
    setError,
  } = useFetchReducerHook();

  const planScore = projectParams?.gmPlanScore?.planScoreValue;
  const finalMilestones = sliceMilestones(milestones, currentPage, pageLimit);
  const totalMilestones = milestones?.length || 0;

  const lifeTimeFormatter = (cell) => cell && <DateLeft minus date={cell} type="tag" customStyle="text-neutral-600" />;

  const columns = [
    {
      dataIndex: 'id',
      title: 'ID',
      key: 'id',
      render: (cell) => cell && renderTableID(cell, 'M'),
    },
    {
      dataIndex: 'title',
      key: 'title',
      // title: <AntDesignSearchBox54origins onSearch={onSearchData} />,
      title: capitalize(t('wms.table.headers.title', 'title')),
      render: (cell, row) => {
        const { status, startDate, lifeTime } = row;

        return (
          <div className="inline-flex flex-col">
            {cell && (
              <EntityParamTag
                param="title"
                value={cell}
                type="value"
                maxLength={35}
              />
            )}
            {status === 'in progress' && <MilestoneProgressBar startDate={startDate} endDate={lifeTime} />}
          </div>
        );
      },
    },
    {
      dataIndex: 'mScore',
      key: 'mScore',
      title: (
        <Tooltip title={capitalize(t('wms.labels.score', 'score'))}>
          <Icon path={mdiBullseyeArrow} size={0.9} />
        </Tooltip>
      ),
      render: (cell) => renderMilestoneScore(cell),
    },
    {
      dataIndex: 'importance',
      key: 'importance',
      title: capitalize(t('wms.table.headers.importance', 'importance')),
      render: (cell) => cell && (
        <EntityParamTag
          value={cell}
          param="importance"
          type={width < 1500 ? 'char' : 'charNoCut'}
        />
      ),
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: capitalize(t('wms.table.headers.status', 'status')),
      render: (cell) => cell && (
        <EntityParamTag
          value={cell}
          param="status"
          type={width < 1800 ? 'icon' : 'iconWithStatus'}
        />
      ),
    },
    {
      dataIndex: 'responsible',
      key: 'responsible',
      title: (
        <Tooltip title={capitalize(t('wms.adjective.responsible', 'responsible'))}>
          <Icon path={mdiAccountGroup} size={1} />
        </Tooltip>
      ),
      render: (cell) => cell?.length > 0 && (
        <WrapperAvatarsGroupForProjectWithHook
          actors={cell}
          avatarSize="small"
          avatarGap={4}
          avatarStyles={{ cursor: 'pointer' }}
          groupButtonSize="small"
          checkProjectRole
        />
      ),
    },
    {
      dataIndex: 'lifeTime',
      key: 'lifeTime',
      title: (
        <Tooltip title={capitalize(t('wms.adjective.days_left', 'days left'))}>
          <Icon path={mdiTimerOutline} size={0.9} />
        </Tooltip>
      ),
      render: (cell, row) => {
        const { status } = row;
        if (status !== 'in progress') return '';
        return lifeTimeFormatter(cell, row);
      },
    },
  ].filter((el) => !el.hidden);

  const pathToRedirect = {
    pathname: partitionType === partitionNamesConfig.partition3
      ? `/public/user/${myPublicUUID}/projects/planning`
      : '/pm/projects/planning',
    search: `?activeProject=${parent}`,
  };

  const changePaginationHandler = useCallback((page) => {
    setPaginationState((prev) => ({
      ...prev,
      currentPage: page,
    }));
  }, []);

  const changePageLimitHandler = useCallback((limit) => {
    setPaginationState((prev) => ({
      ...prev,
      pageLimit: limit,
      currentPage: 1,
    }));
  }, []);

  const loadMilestones = async () => {
    const config = {
      parent,
      params: {
        status: ['in progress'],
      },
      offset: 0,
    };

    return dispatch(getListOfProjectMilestonesRequest(config, partitionType));
  };

  const fetchMilestonesData = async () => {
    loadMilestones()
      .then((response) => {
        const { data: milestonesData } = response || {};
        const milestonesDataWithScore = dataWithMilestoneScore(milestonesData);
        const sortedMilestones = milestonesDataWithScore.toSorted(sortByLeftDays);
        setData(sortedMilestones);
      })
      .catch((error) => setError(error));
  };

  //----------------------------------------------------------------
  useEffect(() => {
    if (parent) fetchMilestonesData();
  }, [parent]);

  useEffect(() => () => dispatch(CLEAR_ALL_PROJECT_MILESTONES()), []);

  return (
    <BaseCard
      className="mb-3 borderR10 primaryCard"
      title={(
        <Flex className="my-3" vertical gap="small">
          <Flex align="center">
            <h6 className="m-0 mr-2">Plan score:</h6>
            <Flex gap="small" align="center">
              {renderMilestoneScore(planScore, true)}
              <InfoComponent
                tooltipPlacement="topLeft"
                tooltipTitle={kpmInfo}
              />
            </Flex>
          </Flex>

          <hr className="m-0 w-full" />

          <Flex gap="small" align="center" justify="space-between">
            <h6 className="m-0">Milestones at work:</h6>
            <GoToButton
              id="toAllMilestonesButton"
              pathForLabelTranslate="wms.buttons.go_to_all_milestones"
              pathToRedirect={pathToRedirect}
            />
          </Flex>
        </Flex>
      )}
    >
      <BaseTableWithPagination
        rowClassName="universal_table_row"
        headerRowClassName="universal_header_table_row"
        columns={columns}
        data={finalMilestones}
        loading={projectMilestonesFetching}
        total={totalMilestones}
        useCustomPagination
        currentPage={currentPage}
        defaultPageLimit={defaultPageLimit}
        paginationPageSize={pageLimit}
        pageSizeOptions={pageLimitList}
        // hideListSizeOption
        onChangePagination={changePaginationHandler}
        changePageLimit={changePageLimitHandler}
      />
    </BaseCard>
  );
}

ProjectMilestonesAtWork.propTypes = {
  parent: string,
  partitionType: string,
};

export default ProjectMilestonesAtWork;

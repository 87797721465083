/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import TagsSettingList from '../../../components/filter/TagsSettingList';

import { getPrivateListOfTickets, getPublicListOfTickets } from '../../../projectFlow/selectors/selectors';
import { listOfIssuesFromStore } from '../../../projectFlow/reducers/issues/IssuesSlicer';
import { setFilteredData } from '../../../projectFlow/actions/PtcActionsForHook';

function PersonalDashboardTagsWithRequests({
  tags,
  onSelectTagsAndGetIssues,
  customSelectedTags,
  customDefaultPartition,
}) {
  const componentID = 'personalDashboardIssues';
  const dispatch = useDispatch();
  const listOfIssues = useSelector(listOfIssuesFromStore);
  const privateListOfTickets = useSelector(getPrivateListOfTickets);
  const publicListOfTickets = useSelector(getPublicListOfTickets);
  const defaultPartition = customDefaultPartition || ['project'];

  const [partitions, changePartitions] = useState(defaultPartition);

  const onSelectTags = (data) => {
    onSelectTagsAndGetIssues(data);
    const partitionTypes = data.filter(({ key }) => key === 'partition')
      .map(({ value }) => value);

    changePartitions(partitionTypes);
  };

  const filterByTags = (data) => {
    const userTags = customSelectedTags.filter(
      ({ key }) => key === 'actor' || key === 'assigned',
    );

    let newData = [...data];

    userTags.forEach(({ key, value }) => {
      if (key === 'actor' && value) {
        newData = newData.filter((item) => item.actor === value);
      }

      if (key === 'assigned' && value) {
        newData = newData.filter(
          (item) => get(item, 'usersSearch', []).includes(value),
        );
      }
    });

    return newData;
  };

  useEffect(() => {
    const issues = [];
    if (partitions.length === 0) {
      issues.push(
        ...listOfIssues,
        ...publicListOfTickets,
        ...privateListOfTickets,
      );
    } else {
      partitions.forEach((el) => {
        switch (el) {
          case 'project':
            issues.push(...listOfIssues);
            break;
          case 'public':
            issues.push(...publicListOfTickets);
            break;
          case 'private':
            issues.push(...privateListOfTickets);
            break;
          default:
            break;
        }
      });
    }

    dispatch(setFilteredData(componentID, filterByTags(issues)));
  }, [
    listOfIssues,
    publicListOfTickets,
    privateListOfTickets,
    customSelectedTags.length,
  ]);

  return (
    <TagsSettingList
      onSelectTags={onSelectTags}
      allTags={tags}
      size="extraSmall"
      id="dashboardFilter"
      defaultSelectedTags={customSelectedTags}
    />
  );
}

export default PersonalDashboardTagsWithRequests;

PersonalDashboardTagsWithRequests.propTypes = {
  tags: PropTypes.array,
};

import { mdiCardPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  bool, func, string,
} from 'prop-types';
import React from 'react';

import BaseButton from '../../../../../components/_ui/BaseButton/BaseButton';

export default function CreateRecurringBtn({
  disabled,
  setDefaultRecurringIssueState,
  buttonClassName = '',
  getStartData,
}) {

  const createRecurringFromIssue = async (e) => {
    e.stopPropagation();
    await getStartData(setDefaultRecurringIssueState);
  } 

  return (
      <BaseButton
        id="createRecurringButton"
        size="small"
        type="primary"
        className={`${buttonClassName} btnWarning-outline`}
        ghost
        onClick={createRecurringFromIssue}
        disabled={disabled}
      >
      <Icon path={mdiCardPlusOutline} size={0.7} className="mr-1" />
          Create recurring
      </BaseButton>
  );
}
CreateRecurringBtn.propTypes = {
  disabled: bool,
  issueUUID: string,
  partitionType: string,
  buttonClassName: string,
  setDefaultRecurringIssueState: func,
  getStartData: func,
};

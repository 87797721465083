import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { Row, Modal } from 'antd';
import { mdiTrashCanOutline } from '@mdi/js';

import { capitalize } from 'lodash';
// import BaseButton from '../../../../components/_ui/BaseButton';
import DataGridActionButton from './DataGridActionButton';

function RemoveStepComponent({
  onRemove,
  step,
  disabled = false,
  // template,
}) {
  const { t } = useTranslation();
  // const [isModalVisible, setIsModalVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  //   onRemove(step);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  return (
    // template ? (
    <DataGridActionButton
      disabled={disabled}
      iconPath={mdiTrashCanOutline}
      className="btnDanger-outline"
      borderRadius="0 4px 4px 0"
      toolTipText={capitalize(t('wms.tooltips.remove_step', 'remove step'))}
      onClick={() => onRemove(step)}
    />
    // ) : (
    //   <Row className="flex h-full items-center justify-center ">
    //     <DataGridActionButton
    //       iconPath={mdiTrashCanOutline}
    //       className="btnDanger-outline"
    //       borderRadius="0 4px 4px 0"
    //       toolTipText={capitalize(t('wms.tooltips.remove_step', 'remove step'))}
    //       onClick={showModal}
    //     />
    //     <Modal
    //       title={capitalize(t('wms.tooltips.remove_step', 'remove step'))}
    //       open={isModalVisible}
    //       onOk={handleOk}
    //       onCancel={handleCancel}
    //       footer={[
    //         <div
    //           key="containerButton"
    //           className="flex justify-between w-full"
    //         >
    //           <BaseButton key="back" className="ant-btn customAntOutGrinBtn ant-btn-default mr-auto" onClick={handleCancel}>
    //             {capitalize(t('wms.buttons.removing_process.no', 'no, I\'m not'))}
    //           </BaseButton>
    //           <BaseButton key="submit" className="ant-btn customAntOutDangerBtn ant-btn-default " onClick={handleOk}>
    //             {capitalize(t('wms.buttons.removing_process.yes', 'yes, I am'))}
    //           </BaseButton>
    //         </div>,
    //       ]}
    //     >
    //       <p>{t('wms.modals.body.removing_process', 'You\'re going to remove this entity. Are you sure?')}</p>
    //     </Modal>
    //   </Row>
    // );
  );
}

RemoveStepComponent.propTypes = {
  step: PropTypes.number.isRequired,
  // t: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  // template: PropTypes.bool,
};

export default RemoveStepComponent;

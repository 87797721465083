import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityPermForUser } from '../actions/permissionsActions';
import { getUserPermissions } from '../selectors/permissionsSelector';
import dayjs from 'dayjs';

const fetchList = new Set();

export default function useGetUserPerm({
  entityUUID = '',
  partitionType = '',
  needCheckPerm = true,
}) {
  const dispatch = useDispatch();

  const permissions = useSelector(getUserPermissions);

  const [perm, setPerm] = useState({});

  const checkPerm = async (options = {}) => {
    try {
      if (fetchList.has(entityUUID)) return;
      fetchList.add(entityUUID)
      const data = {
        entity_uuid: entityUUID,
        partition: partitionType,
        returnPermsList: true,
        ...options,
      };

      const res = await dispatch(getEntityPermForUser(data));

      return res;
    } catch (e) {
      console.log(e);
      return {};
    } finally {
      fetchList.delete(entityUUID)
    }
  };

  const reloadPerm = async (time) => {
    const prevPerm = permissions?.[entityUUID];
    if (prevPerm && Math.abs(prevPerm?.time?.diff(dayjs(), 'minutes')) <= time) {
      return;
    }
    checkPerm();
  }

  const getPerms = async (options) => {
    try {
      if (permissions[entityUUID]) {
        return permissions[entityUUID];
      }
      const data = {
        entity_uuid: entityUUID,
        partition: partitionType,
        returnPermsList: true,
        ...options,
      };
      const perms = await dispatch(getEntityPermForUser(data));
      return perms;
    }
    catch (e) {
      console.log(e);
      return {};
    }
  };

  useEffect(() => {
    if (entityUUID && permissions[entityUUID]) {
      setPerm({
        ...permissions[entityUUID],
      });
    }
  }, [JSON.stringify(permissions), entityUUID]);

  useEffect(() => {
    if (
      entityUUID
      && !fetchList.has(entityUUID)
      && partitionType
      && !permissions[entityUUID]
      && needCheckPerm
    ) {
      checkPerm();
    }
  }, [entityUUID, partitionType]);

  return { perm, getPerms, reloadPerm };
}

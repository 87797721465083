import moment from 'moment';
import React from 'react';
import { notification, Tooltip } from 'antd';
import {
  head, isNil, pathOr, prop,
} from 'ramda';
import i18n from 'i18next';
import {
  capitalize, get, has,
} from 'lodash';

import humanizeDuration from 'humanize-duration';
import MD5 from 'crypto-js/md5';
import { store } from './AppComponent';
import {
  dateGetEndOfAndFormat, dateStartOfAndFormat, dateToLocalTimezone, FORMAT1, FORMAT13
} from "./54origins/dateFormats54origins";

const DAY = '86400000';
const HOUR = '3600000';

export const defaultWmsSortDirections = ['ascend', 'descend', 'ascend'];

export const nameSort = {
  ascend: 'asc',
  descend: 'desc',
  default: 'default',
};

export const getStartDate = (param) => dateStartOfAndFormat(param, FORMAT13);

export const getEndDate = (param) => dateGetEndOfAndFormat(param, FORMAT13);

export const formatDateTimeForWms = (date) => moment(date || moment(), ['YYYY-MM-DD HH:mm', 'MMMM YYYY']).format('YYYY-MM-DD HH:mm');

export const getHumanizeDuration = ({ date, isShort = false }) => {
  const diffDate = moment().diff(date, 'milliseconds');

  const config = {
    language: i18n.language,
    units: ['d'],
    round: true,
  };

  if (isShort) {
    config.languages = {
      en: {
        m: () => 'min',
        d: () => 'd',
        h: () => 'h',
      },
      ru: {
        m: () => 'мин',
        d: () => 'д',
        h: () => 'ч',
      },
    };
  }

  if (diffDate > DAY) {
    return humanizeDuration(diffDate, { ...config });
  }
  if (diffDate > HOUR) {
    return humanizeDuration(diffDate, { ...config, units: ['h'] });
  }

  return humanizeDuration(diffDate, { ...config, units: ['m'] });
};

export const dateWithTooltipTime = (cell, format = null) => (
  <Tooltip title={dateToLocalTimezone(cell, 'HH:mm')}>
    <span className="text-xs">
      {!!format && typeof format === 'string' ? dateToLocalTimezone(cell, format) :  dateToLocalTimezone(cell, FORMAT1)}
    </span>
  </Tooltip>
);

export const renderTableID = (cell, letter) => (<span className="text-nowrap">{`${letter || ''}${cell}`}</span>);

export const antNotification = (type, message, description) => {
  notification[type]({
    message,
    description,
  });
};

export const antNotificationExtended = ({
  description,
  duration,
  key,
  message,
  onClose,
  type,
  placement,
  style,
}) => {
  notification[type]({
    description,
    duration,
    key,
    message,
    onClose,
    placement,
    style,
  });
};

export const defaultSuccessNotify = () => antNotification('success', i18n.t('wms.noun.success', 'Success'));

export const handleFormSubmitByKeyPress = (event, handler) => {
  if (event.key === 'Enter' && event.ctrlKey) {
    event.preventDefault();

    handler();
  }
};

export const capitalizeFirstChar = (string) => (string ? `${string?.charAt(0)?.toUpperCase()}${string?.slice(1)}` : '');

// eslint-disable-next-line max-len
export const getFromStore = (storePath) => (store ? pathOr(null, storePath, store.getState()) : null);

export const timeToCalendar = (time, withTime = false) => {
  const yesterday = `[${capitalize(i18n.t('wms.time.yesterday', 'yesterday'))}]`;
  const today = `[${capitalize(i18n.t('wms.time.today', 'today'))}]`;

  const calendar = {
    lastDay: withTime ? `${yesterday}, LT` : yesterday,
    sameDay: withTime ? `${today}, LT` : today,
    nextDay: 'LL',
    lastWeek: 'LL',
    nextWeek: 'LL',
    sameElse: 'LL',
  };

  switch (getFromStore(['locales', 'language'])) {
    case 'ru':
      moment.locale('ru', { calendar });
      return moment(time).calendar();

    case 'en':
      moment.locale('en', { calendar });
      return moment(time).local().calendar();

    default: return '';
  }
};

export const convertEntitiesForSelect = (entities) => {
  const mapItem = (item) => ({
    value: get(item, 'uuid', ''),
    label: get(item, 'title', ''),
    actors: get(item, 'usersSearch', []),
  });

  if (Array.isArray(entities)) {
    return entities.map(mapItem);
  }
  return mapItem(entities);
};

class WorkTimerClass {
  constructor() {
    this.t0 = 0;
    this.t1 = 0;
  }

  start() {
    this.t0 = performance.now();
    // console.time('Time start.');
  }

  stop() {
    this.t1 = performance.now();
    // console.timeEnd('Time end: ', this.t1 - this.t0);
    this.t0 = 0;
    this.t1 = 0;
  }
}

export const workTimer = new WorkTimerClass();

export const validTime = (data, messageAboutInvalid = false, checkNotMore24 = true) => {
  const check = /^\d\dh\d\dm$/.test(data)
    || /^\dh\d\dm$/.test(data)
    || /^\d\dh\dm$/.test(data)
    || /^\d\d\dh\d\dm$/.test(data)
    || /^\d\d\dh\dm$/.test(data)
    || /^\dh\dm$/.test(data)
    || (+data === 0 && data !== '00' && data !== '');

  if (check && +data !== 0) {
    // console.log('data',data)
    const splitData = data.split('h');
    const hours = splitData[0];
    const minute = splitData[1].slice(0, splitData[1].length - 1);
    // console.log('hours', hours, 'minute', minute);
    const isValid = (checkNotMore24 ? +hours <= 23 : true) && minute <= 59;
    return messageAboutInvalid
      ? 'need no more than 23h59m'
      : isValid;
  }
  return messageAboutInvalid
    ? 'need "00h00m" or "0h0m"'
    : check;
};

export const timeConvertForView = (time) => {
  // console.log('time',time)
  if (isNil(time) || time === 0) {
    return '00h00m';
  }
  const allTime = time.split(':');
  return `${+allTime[0] < 10 ? `0${+allTime[0]}` : +allTime[0]}h${allTime[1]}m`;
};

export const timeConvertForMoment = (time, checkNotMore24) => {
  // конвертирует 00h00m в 00:00 что бы в моменте можно было с ним работать
  if (!isNil(time) && validTime(time, false, checkNotMore24) && +time !== 0) {
    const allTime = time.split('h');
    const hours = allTime[0];
    const min = allTime[1].replace('m', '');
    return `${hours}:${min}`;
  }
  return '00:00';
};

export const convertHoursToMinute = (time) => {
  const splitTime = time ? time.split(':') : ['00', '00'];
  const convertHours = +splitTime[0] * 60;
  const minute = +splitTime[1];
  return convertHours + minute;
};

export const sumAllTimeWorked = (oldTime, newTime) => {
  const splitOldTime = oldTime ? oldTime.split(':') : ['00', '00'];
  const splitNewTime = newTime ? newTime.split(':') : ['00', '00'];

  const oldHours = splitOldTime[0];
  const newHours = splitNewTime[0];

  const oldMinute = splitOldTime[1];
  const convertNewMinuteToHoursAndMinute = moment('00:00', 'HH:mm')
    .add(splitNewTime[1], 'm')
    .add(oldMinute, 'm')
    .format('HH:mm');

  const splitNewMinuteAntHours = convertNewMinuteToHoursAndMinute.split(':');

  const newAllHours = +oldHours + +newHours + +splitNewMinuteAntHours[0];

  return `${newAllHours < 10 ? `0${newAllHours}` : newAllHours}:${splitNewMinuteAntHours[1]}`;
};

export const takeTimeAwayFrom = (oldTime, takeAway) => {
  const currentTime = moment(oldTime, 'HH:mm').format('YYYY-MM-DD');
  const subtractTime = moment(oldTime, 'HH:mm').subtract(takeAway, 'HH:mm');
  const newTime = moment(subtractTime).format('YYYY-MM-DD');

  if (moment(newTime).isBefore(currentTime)) {
    // console.log('currentTime', moment(newTime).isBefore(currentTime));
    return '00:00';
  }
  return moment(subtractTime).format('HH:mm');
};

export const parseLinks = (text, disabled) => {
  if (!text) {
    return '';
  }

  const linkRegEx = /(www|http:|https:)+[^\s]+[\w]/gi;
  let data = text.split(/[\t\v\r\f\u00a0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u2028\u2029\u3000\u0020]/);

  // console.log('data', data);
  data = data.map((word, key) => {
    const isLink = word.match(linkRegEx);
    const arrLink = word.split(/\n/);
    return (
      <span key={key}>
        {isLink ? (
          <>
            {arrLink.map((item, i) => {
              const url = (isLink && item.match(/(http:|https:)+[^\s]+[\w]/gi))
                ? item
                : `https://${item}`;

              if (item.match(linkRegEx)) {
                return (
                  <span key={i}>
                    <a
                      disabled={disabled}
                      // onClick возможно не нужен
                      onClick={(e) => {
                        if (disabled) e.preventDefault();
                      }}
                      href={url}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {`${item} `}
                    </a>
                  </span>
                );
              }
              // console.log('ggggg', word.length === '\n')
              return <span key={i}>{i !== 0 ? `\n${item}` : item}</span>;
            })}
          </>
        ) : (
          `${word} `
        )}
      </span>
    );
  });
  return data;
};

export const downloadDataAsJSON = (data, fileName) => {
  // const fileName = `${title}${moment(new Date()).format('YYYY-MM-DD_hh-mm-ss')}.json`;

  const a = document.createElement('a');
  a.href = URL.createObjectURL(new Blob([JSON.stringify(data, null, 2)], {
    type: 'text/plain',
  }));
  a.setAttribute('download', `${fileName}.json`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getHeadUUID = (item) => prop('uuid', head(item));

export const getArrayForItem = (item) => (typeof item === 'string' ? [item] : item);

export const dateStartAndCreatedDateValidator = (created, dateStart) => {
  if (moment(dateStart || created).toDate() < created) {
    return false;
  }
  return true;
};

export const getUuidsfromParamsUsers = (users) => {
  if (users) {
    return users.map((el) => el.uuid);
  }
  return [];
};

export const filterUuidsAndGetParamsUsers = (users, key, value = true) => {
  if (users) {
    return getUuidsfromParamsUsers(users.filter((el) => el[key] === value));
  }
  return [];
};

export const filterUsersAndGetUuidsWithRole = (users, key, value = true) => {
  if (users) {
    return users
      .filter((item) => item[key] === value)
      .map((item) => ({
        uuid: item?.uuid,
        ...(item?.role && { role: item.role }),
      }));
  }
  return [];
};

export const compareUsersWithProjectUsers = (users, projectUsers) => users
  .filter((el) => projectUsers.includes(el));

export const createParamsUsersFromUuids = (users, params) => {
  if (users) {
    return users.map((el) => ({
      uuid: el,
      ...params,
    }));
  }
  return [];
};

export const getProjectUUIDFromTarget = (entityType, target) => {
  switch (entityType) {
    case 'project':
      return target.uuid || '';
    case 'vector':
      return target.parent || '';
    case 'issue':
      return target?.project || '';
    default: break;
  }
};

export const createParamsUsersList = (type, visibleUUIDs = [], arrOfRoles = [], maintainer = []) => {
  const rolesUUIDs = arrOfRoles.length ? arrOfRoles.map((el) => el.uuid) : [];
  const maintainerUser = maintainer[0];
  let finallyUsers = [];

  switch (type) {
    case 'project':
      finallyUsers = visibleUUIDs.map((el) => ({
        uuid: el,
        role: '',
      }));
      break;
    case 'vector':
      finallyUsers = visibleUUIDs.map((el) => ({
        uuid: el,
        isMaintainer: false,
      }));
      break;
    default: break;
  }

  if (rolesUUIDs?.length) {
    return finallyUsers.map((el) => (el.role ? el : { ...el, role: arrOfRoles[rolesUUIDs?.indexOf(el.uuid)]?.role || '' }));
  }
  if (maintainerUser) {
    if (visibleUUIDs.includes(maintainerUser)) {
      return finallyUsers.map((el) => (el.uuid === maintainerUser ? { ...el, isMaintainer: true } : { ...el, isMaintainer: false }));
    }
    return finallyUsers.length
      ? [...finallyUsers.map((e) => ({
        ...e,
        isMaintainer: false,
      })), {
        uuid: maintainerUser,
        isMaintainer: true,
      }]
      : [{ uuid: maintainerUser, isMaintainer: true }];
  }
  return finallyUsers;
};

export const capitalizeAndTranslateMsg = (pathForTranslate, defaultLabel) => capitalize(i18n.t(pathForTranslate, defaultLabel || pathForTranslate));

export const saveMetaDataToLocaleStorage = (action = '', newData = {}) => {
  const oldData = JSON.parse(localStorage.getItem(action)) || [];

  oldData.push(newData);

  localStorage.setItem(action, JSON.stringify(oldData));
};

export const checkUrlForSaveMetaDataAndReturnAction = (url = '', type) => {
  switch (type) {
    case 'request':
      if (url.includes('entity/update') || url.includes('entity/affix/update')) {
        return 'updated';
      }
      if (url.includes('entity/delete') || url.includes('entity/affix/delete')) {
        return 'removed';
      }
      return '';
    case 'response':
      if (url.includes('entity/create') || url.includes('entity/affix/create')) {
        return 'created';
      }
      return '';

    default: return '';
  }
};

export const includesTypes = (data) => {
  const entityTypes = [
    'issue',
    'project',
    'vector',
    'projectDocument',
    'projectIdea',
    'projectGoal',
    'testCase',
    'testSuite',
    'testCycle',
    'daily',
    'workConfig',
    'area',
    'archiveEntity',
    'CookBookTemplate',
    'favoriteSettings',
  ];
  const affixTypes = [
    'comment',
    'historyOfIdeaChanges',
    'projectDocument',
    'userTimelog',
    'timeLog',
    'reportAdjustments',
    'workConfigComment',
  ];

  let resultData = {};

  if (data instanceof FormData) {
    resultData = JSON.parse(data.get('data') || {});
  } else {
    resultData = Array.isArray(data) ? data[0] : data;
  }

  if (has(resultData, 'entity_type')) {
    return entityTypes.some((e) => e === get(resultData, 'entity_type'));
  }
  return affixTypes.some((e) => e === get(resultData, 'affix_type'));
};

export const prepareAndSaveMetaData = (data, url, type, partition) => {
  const action = checkUrlForSaveMetaDataAndReturnAction(url, type);

  if (action && includesTypes(data)) {
    let resultData = {
      time: new Date().toUTCString(),
      partitionUuid: partition,
    };

    if (data instanceof FormData) {
      resultData = { ...resultData, ...JSON.parse(data.get('data') || {}) };
    } else {
      resultData = Array.isArray(data)
        ? { ...resultData, ...data[0] }
        : { ...resultData, ...data };
    }

    saveMetaDataToLocaleStorage(action, resultData);
  }
};

export const getMD5HashFromString = (str) => MD5(str).toString();

export const camelCaseToFlatString = (camel) => {
  const flatString = camel.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
  return flatString;
};

export const getFirstStringOfText = (text, firstStringLength) => {
  const first = text?.substring(0, Math.min(text?.length, firstStringLength));
  const indexOfNewLine = first?.indexOf('\n');
  const result = indexOfNewLine >= 0 ? first?.substring(0, indexOfNewLine) : first;

  return result;
};

import { ProjectTypeCommonConstants } from '../../constants/Constants';

const initialState = {
  dataSuite: '',
  fetchingCycle: false,
  fetchingSuite: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ProjectTypeCommonConstants.CREATE_TEST_STEPS_FILE_REQUEST:
      return {
        ...state,
      };
    case ProjectTypeCommonConstants.CREATE_TEST_STEPS_FILE_SUCCESS:
      return {
        ...state,
        dataSuite: payload,
      };
    case ProjectTypeCommonConstants.CREATE_TEST_STEPS_FILE_FAILURE:
      return {
        ...state,
      };

    case ProjectTypeCommonConstants.GET_TEST_STEPS_FILE_IN_SUITE_REQUEST:
      return {
        ...state,
        fetchingSuite: true,
      };
    case ProjectTypeCommonConstants.GET_TEST_STEPS_FILE_IN_SUITE_SUCCESS:
      return {
        ...state,
        dataSuite: payload,
        fetchingSuite: false,
      };
    case ProjectTypeCommonConstants.GET_TEST_STEPS_FILE_IN_SUITE_FAILURE:
      return {
        ...state,
        fetchingSuite: false,
      };
    case ProjectTypeCommonConstants.UPDATE_TEST_STEPS_FILE_REQUEST:
      return {
        ...state,
        fetchingSuite: true,
      };
    case ProjectTypeCommonConstants.UPDATE_TEST_STEPS_FILE_SUCCESS:
      return {
        ...state,
        data: payload,
        fetchingSuite: false,
      };
    case ProjectTypeCommonConstants.UPDATE_TEST_STEPS_FILE_FAILURE:
      return {
        ...state,
        fetchingSuite: false,
      };

    default: return state;
  }
};

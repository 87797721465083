import {
  any, array, bool, func, number, string, object,
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import { capitalize, get } from 'lodash';

import {
  mdiAccountMultipleOutline,
  mdiWeight,
  mdiIdentifier,
  mdiPriorityLow,
  mdiTimerSand,
  mdiChevronDown,
} from '@mdi/js';
import Icon from '@mdi/react';

import { Col, Flex, Tooltip } from 'antd';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import AssigningUsersRoot from '../../../assignUsersModal/AssigningUsersRoot';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import DateLeft from '../../../components/DateLeft';
import WrapperAvatarsGroupForProject from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';
import WrapperAvatarsGroupForProjectWithHook from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';

import useActorsW54 from '../../../actors/hooks/useActorsW54';
import useURLParams from '../../../hooks/useURLParams';

import {
  flagMultiEditVector,
  uuidsMultiEditVector,
  vectorsFetching,
  getVectorsScoringData,
  getProjectParams,
} from '../../selectors/selectors';

import { priorityWeightFormatter, entityProjectWeight, defaultOrderRules, priorityWeight } from '../issuesView/utils';
import { getUuid } from '../../../assignUsersModal/utils';
import { ProjectTypeCommonConstants } from '../../constants/Constants';

function ListOfVectorsAntDesignTable({
  data,
  disablePagination,
  newCurrentPage,
  limit,
  setLimit,
  selectedVector,
  totalCount,
  pageSizeOptions,
  hideVectors,
  hideElements = [],
  filterColumnsConfig,
  filterConfig,
  getVectorsRequestSetting,
  onChangeRowCallback,
  filterCallback,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getURLParams } = useURLParams();

  const projectParams = useSelector(getProjectParams);
  const fetching = useSelector(vectorsFetching);
  const multiEditVector = useSelector(flagMultiEditVector);
  const uuidsMultiEdit = useSelector(uuidsMultiEditVector);

  const { activeVector } = getURLParams();
  const vectorsScoringData = useSelector(getVectorsScoringData);

  const [valueSearch, setValueSearch] = useState('');
  const [selectedRowKeys, changeSelectedRowKeys] = useState([]);

  const [paginationOptions, changePaginationOptions] = useState({});
  const [orderRules, changeOrderRules] = useState(defaultOrderRules);

  const [isEditAssignUsers, setEditAssignUsers] = useState(false);

  const [usersFilter, setUsersFilter] = useState([]);

  // Получение vector пользователей
  const userUUIDs = data?.flatMap((item) => item?.users);
  useActorsW54({ actors: userUUIDs });

  const changeLimit = (size) => {
    if (size !== limit) {
      setLimit?.(size);
      getVectorsRequestSetting({ currentPage: 1, limit: size, offset: 0 });
    }
  };

  const onSearchData = (value) => {
    getVectorsRequestSetting({ search: value, offset: 0, currentPage: 1 });

    setValueSearch(value);
  };

  const assignedFormatter = (cell) => {
    const assigned = cell?.map((el) => el?.uuid) ?? [];

    return !isNil(assigned) && !isEmpty(assigned) && (
      <WrapperAvatarsGroupForProject
        actors={assigned}
        avatarSize="small"
        avatarGap={4}
        avatarStyles={{ cursor: 'pointer' }}
        groupButtonSize="small"
        checkProjectRole
      />
    );
  };

  const searchInTitle = (
    <Flex align="flex-end" className="minWidth130" span={24}>
      <AntDesignSearchBox54origins valueSearch={valueSearch} onSearch={onSearchData} />
    </Flex>
  );

  const columns = [
    {
      dataIndex: 'id',
      title: (
        <Tooltip placement="top" title="vID">
          <Icon path={mdiIdentifier} size={1} className="text-secondary" />
        </Tooltip>
      ),
      className: 'wordBreakOneLine',
      key: 'id',
      sorter: true,
      render: (cell) => <span className="whitespace-nowrap text-xs">{cell}</span>,
    },
    {
      dataIndex: 'versionNumber',
      className: hideElements?.includes('versionNumber') ? 'hidden' : '',
      title: capitalize(t('wms.labels.version_number')),
      render: (cell) => cell && <EntityParamTag param="title" value={cell} type="value" maxLength={25} />,
    },
    {
      dataIndex: 'title',
      title: searchInTitle,
      render: (cell) => cell && <EntityParamTag param="title" value={cell} type="value" maxLength={80} />,
    },
    {
      dataIndex: 'type',
      title: capitalize(t('wms.table.headers.type', 'Type')),
      className: hideElements?.includes('type') ? 'hidden' : '',
      key: 'type',
      sorter: true,
      ...(filterColumnsConfig && { ...filterColumnsConfig?.type }),
      ...(filterConfig?.type && { defaultFilteredValue: filterConfig?.type }),
      render: (cell) => cell && <EntityParamTag param="type" value={cell} type="badge" />,
    },
    {
      dataIndex: 'priority',
      title: (
        <Tooltip
          placement="top"
          title={capitalize(t('wms.table.headers.priority', 'Priority'))}
        >
          <Icon path={mdiPriorityLow} size={1} className="text-secondary" />
        </Tooltip>
      ),
      key: 'priority',
      sorter: (row1, row2) => priorityWeight(row1?.priority) - priorityWeight(row2?.priority),
      defaultSortOrder: 'descend', 
      ...(filterColumnsConfig && { ...filterColumnsConfig?.priority }),
      ...(filterConfig?.priority && { defaultFilteredValue: filterConfig?.priority }),
      render: (cell) => cell && <EntityParamTag param="priority" value={cell} type="char" />,
    },
    {
      dataIndex: 'status',
      title: capitalize(t('wms.table.headers.status', 'Status')),
      key: 'status',
      sorter: true,
      ...(filterColumnsConfig && { ...filterColumnsConfig?.status }),
      ...(filterConfig?.status && { defaultFilteredValue: filterConfig?.status }),
      render: (cell) => cell && <EntityParamTag param="status" value={cell} type="iconWithStatus" />,
    },
    {
      dataIndex: 'weight',
      key: 'weight',
      sorter: (row1, row2) => priorityWeightFormatter(row1?.priority, row1?.status)
        - priorityWeightFormatter(row2?.priority, row1?.status),
      title: (
        <Tooltip
          placement="top"
          title={(
            <span>
              Weight in the project
              {' '}
              <br />
              {' '}
              (vector priority index / the sum of the indexes of all
              vector)
            </span>
          )}
        >
          <Icon path={mdiWeight} size={1} className="text-secondary" />
        </Tooltip>
      ),
      render: (cell, row) => (
        <span className="whitespace-nowrap text-sm">
          {vectorsScoringData?.sum ? entityProjectWeight(row?.priority, vectorsScoringData?.sum, row?.status) : '-'}
        </span>
      ),
    },
    {
      dataIndex: ['users'],
      title: (
        <AssigningUsersRoot
          header="User filter"
          isOpen={isEditAssignUsers}
          setIsOpen={setEditAssignUsers}
          isPopover
          isCheckWindowWidthMode={false}
          isOneColumnMode={true}
          isClosePopoverOnLeaveMode
          size={'extra small'}
          data={{
            uuids: projectParams?.usersSearch,
          }}
          onUpdateUsers={(usersData) => {
            const newUsers = usersData?.users?.map((el) => getUuid(el));
            getVectorsRequestSetting({ usersSearch: newUsers });
            setUsersFilter(newUsers);
          }}
          globalOptions={{
            uuids: projectParams?.usersSearch,
            isOneColumnMode: true,
            size: 'extra small',
          }}
          usersConfig={{
            label: 'Users',
            showSections: ['string-role'],
            textSelectButton: 'Select',
            textDeleteButton: 'Deselect',
            defaultItems: usersFilter,
          }}
          popoverChild={(
            <Flex className="ml-1" align="center">
              <Tooltip
                placement="top"
                onClick={() => setEditAssignUsers(true)}
                style={{
                  cursor: 'pointer',
                }}
                title={capitalize(t('wms.table.headers.assigned', 'Assigned'))}
              >
                <Icon
                  path={mdiAccountMultipleOutline}
                  role="button"
                  size={1}
                  className="text-secondary"
                />
                <Icon
                  path={mdiChevronDown}
                  role="button"
                  className="text-secondary ml-1"
                  size={1}
                />
              </Tooltip>
              <WrapperAvatarsGroupForProjectWithHook
                actors={usersFilter || []}
                maxCount={3}
                avatarSize={22}
                popoverTrigger="click"
                popoverPlacement="right"
                checkProjectRole
              />
            </Flex>
          )}
        />
      ),
      render: (cell, row) => assignedFormatter(cell, row),
    },
    {
      dataIndex: ['lifeTime'],
      title: (
        <Tooltip placement="top" title={capitalize(t('wms.adjective.expired'))}>
          <Icon path={mdiTimerSand} size={1} className="text-secondary" />
        </Tooltip>
      ),
      key: 'lifeTime',
      sorter: true,
      render: (cell) => cell && <DateLeft minus date={cell} type="tag" customStyle="text-neutral-600" />,
    },
  ];

  const onRow = (record) => ({
    onClick: () => {
      const { uuid } = record || {};

      if (onChangeRowCallback) {
        onChangeRowCallback(uuid, record);
      }
    },
  });

  const rowClassName = (row) => `
  ${multiEditVector ? 'not-hide-checkbox' : ''}
  ${get(row, 'params.priority') === 'urgent' ? 'red_table_row' : ''}
  select_table_row ${selectedRowKeys.some((i) => i === get(row, 'uuid')) ? 'selected' : ''} `;

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: uuidsMultiEdit,
    onChange: (selectedKeys) => {
      dispatch({
        type: ProjectTypeCommonConstants.SET_UUIDS_FOR_MULTI_EDIT_VECTORS,
        payload: selectedKeys,
      });
    },
  };

  const currentSortCallback = (dataSort) => {
    if (!get(dataSort, 'order')) {
      changeOrderRules({
        orderBy: 'created',
        order: 'desc',
      });
    } else {
      changeOrderRules(dataSort);
    }
  };

  useEffect(() => {
    if (activeVector || selectedVector) changeSelectedRowKeys(
      [selectedVector !== null
        ? selectedVector
        : activeVector]
    );
    else changeSelectedRowKeys([]);

    return () => changeSelectedRowKeys([]);
  }, [activeVector, selectedVector]);

  useEffect(() => {
    if (getVectorsRequestSetting) {
        getVectorsRequestSetting({
          ...paginationOptions,
          ...filterConfig,
          ...orderRules,
        });
    }
  }, [paginationOptions, orderRules, filterConfig]);

  return (
    <Col span={24} className="collapsedTable-table">
      <BaseTableWithPagination
        pageLimit={limit}
        loading={fetching}
        newCurrentPage={newCurrentPage}
        useCustomPagination
        columns={columns}
        showHeader={!hideVectors}
        data={data}
        disablePagination={disablePagination}
        rowKey="uuid"
        rowSelection={rowSelection}
        size="small"
        headerRowClassName="universal_header_table_row color-gray"
        total={totalCount}
        pageSizeOptions={pageSizeOptions}
        onRow={onRow}
        changePageLimit={changeLimit}
        rowClassName={rowClassName}
        filterCallback={filterCallback}
        currentSortCallback={currentSortCallback}
        getPaginationOptions={changePaginationOptions}
      />
    </Col>
  );
}

export default ListOfVectorsAntDesignTable;

ListOfVectorsAntDesignTable.propTypes = {
  data: array,
  disablePagination: bool,
  pageSizeOptions: any,
  selectedVector: string,
  totalCount: number,
  limit: number,
  setLimit: func,
  newCurrentPage: number,
  hideElements: array,
  filterColumnsConfig: object,
  filterConfig: object,
  getVectorsRequestSetting: func,
  onChangeRowCallback: func,
  filterCallback: func,
};

import { createSlice } from '@reduxjs/toolkit';

const planningSlice = createSlice({
  name: 'PLANING_SLICE',
  initialState: {
    data: [],
    isLoading: false,
    error: '',
  },
  reducers: {
    GET_PLANNING_VECTOR_DAYS_REQUEST: (state) => {
      state.isLoading = true;
    },
    GET_PLANNING_VECTOR_DAYS_SUCCESS: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = '';
    },
    GET_PLANNING_VECTOR_DAYS_FAILURE: (state, action) => {
      state.isLoading = false;
      state.data = '';
      state.error = action.payload;
    },
  },
  selectors: {
    selectPlanningVectorDays: (sliceState) => sliceState?.PublicPartition?.planningSlice?.data || [],
    fetchingPlanningVectorDays: (sliceState) => sliceState?.PublicPartition?.planningSlice?.isLoading || false,
  },
});

export const {
  GET_PLANNING_VECTOR_DAYS_REQUEST,
  GET_PLANNING_VECTOR_DAYS_SUCCESS,
  GET_PLANNING_VECTOR_DAYS_FAILURE,
} = planningSlice.actions;

export default planningSlice.reducer;

export const { selectPlanningVectorDays, fetchingPlanningVectorDays } = planningSlice.getSelectors();

import React from 'react';
import { useSelector } from 'react-redux';
import { Badge } from 'antd';
import {
  pathOr, head, groupBy, isEmpty,
} from 'ramda';

import { useTranslation } from 'react-i18next';
import {
  getChildFromCurrentArchiveState,
  getCurrentArchiveState,
} from '../../selectors/archiveStateSelectors';
import { capitalize } from 'lodash';

function ArchiveStateInfo() {
  const { t } = useTranslation();
  const curArchiveState = useSelector(getCurrentArchiveState);
  const childsFromCurrentArchiveState = useSelector(getChildFromCurrentArchiveState);

  const getName = (value) => {
    if (value && typeof (value) !== 'object') {
      let newValue;

      switch (value) {
        case 'projectDocument': newValue = 'document'; break;
        case 'projectIdea': newValue = 'idea'; break;
        default: newValue = value;
      }
      newValue = newValue.toString().toLowerCase();

      return capitalize(t(`wms.labels.${newValue}`, newValue));
    }
  }

  const getContainEntities = () => {
    const entities = groupBy((item) => item.entity_type, childsFromCurrentArchiveState);
    const arr = [];
 
    for (const item in entities) {
        if(item !== 'mainEnvironment') {
          arr.push(
            <p className="fontDescription">
              {getName(item)}
              {' '}
              <Badge count={entities[item].length} />
            </p>,
          );
        }

    }
    return arr;
  };

  return (
    <>
      <h5>{capitalize(t('wms.labels.archive_state_info', { noun: t('wms.noun.info.0') }))}</h5>
      <h6>{pathOr('', ['params', 'name'], head(curArchiveState))}</h6>
      <h6 className="mt-3 mb-3">
        {!isEmpty(childsFromCurrentArchiveState)
          ? `${capitalize(t('wms.labels.contains'))}:`
          : capitalize(t('wms.labels.archive_state_empty'))}

      </h6>
      {getContainEntities()}
    </>
  );
}

export default ArchiveStateInfo;

import {
  head, path, pathOr,
} from 'ramda';
import { createSelector } from 'reselect';
import { partitionNamesConfig } from '../../../api/appConfig';

export const getUserPublicProfileUUID = (state) => path(['ProfileService', 'userProfile', 'publicEntity', 'uuid'], state);

export const getUserPublicProfile = (state) => path(['ProfileService', 'userProfile', 'publicEntity'], state);

export const getUserPublicFetching = (state) => path(['ProfileService', 'userProfile', 'publicFetching'], state);

export const getOtherUserPublicProfileUUID = (state) => path(['ProfileService', 'userProfile', 'otherUserPublicEntity', 'uuid'], state);

export const getOtherUserPublicProfileActor = (state) => path(['ProfileService', 'userProfile', 'otherUserPublicEntity', 'actor'], state);

export const getOtherUserPublicEntity = (state) => path(['ProfileService', 'userProfile', 'otherUserPublicEntity'], state);

export const getOtherUserPublicFetching = (state) => path(['ProfileService', 'userProfile', 'otherUserFetching'], state);

export const getPublicPersonalSettings = (state) => head(pathOr([{}], ['ProfileService', 'publicSettings', 'publicData'], state));

export const getMainPrivatePlanningUUID = (state) => head(pathOr([], ['ProfileService', 'profileSection', 'parentPrivatePlanning', 'entity_uuids'], state));

export const fetchingMainPrivatePlanning = (state) => path(['ProfileService', 'profileSection', 'fetchingMainPrivatePlanning'], state);

export const getMainIssuePrivateEntityUUID = (state) => pathOr('', ['ProfileService', 'profileSection', 'mainPrivateIssue', 'uuid'], state);

export const getMainPublicProjectUUID = (state) => pathOr('', ['ProfileService', 'mainPublicIssue', 'mainPublicProjectUUID', 'uuid'], state);

export const getPrivateUserSettings = (state) => pathOr({}, ['privatePartition', 'privateSettings'], state);

export const getPrivateUserSettingsError = (state) => path(['privatePartition', 'error'], state);

export const getPrivateUserProfileUUID = (state) => pathOr('', ['privatePartition', 'userEntityUUID'], state);

export const getFavoriteUUID = (state) => pathOr('', [
  'privatePartition',
  'privateSettings',
  'favoriteSettings',
  'uuid',
], state);

export const getFavoriteSettings = (state) => pathOr([], [
  'privatePartition',
  'privateSettings',
  'favoriteSettings',
  'params',
], state);

export const getFavoriteProjects = createSelector(
  getFavoriteSettings,
  (settings) => settings?.favorites || [],
);

export const getFavoriteProjectsByPartition = (state, partition) => pathOr([], [
  'privatePartition',
  'privateSettings',
  'favoriteSettings',
  'params',
  partition === partitionNamesConfig.partition1 ? 'projects' : 'publicProjects',
], state);

export const getFavoriteSuites = (state, id) => {
  const list = pathOr([], [
    'privatePartition',
    'privateSettings',
    'favoriteSettings',
    'params',
    'testSuites',
  ], state);

  return list.filter((item) => item?.uuid === id);
};

export const getFavoriteFetching = (state) => pathOr('', ['privatePartition', 'favoriteFetching'], state);

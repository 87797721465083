import { head, prop, propOr } from 'ramda';
import { ProjectTypeCommonConstants } from '../constants/Constants';

const initialState = {
  fetching: false,

  personalFetching: true,
  personalProjects: [],
  personalCount: 0,

  projectsFetching: true,
  projects: [],
  projectsCount: 0,

  error: '',
  projectsInQuickIssueModal: [],
  projectsUuids: [],
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ProjectTypeCommonConstants.SET_PROJECTS_FETCHING:
      return {
        ...state,
        projectsFetching: true,
        personalFetching: true,
      };
    case ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_REQUEST:
      return {
        ...state,
        personalFetching: true,
      };
    case ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_SUCCESS:
      return {
        ...state,
        personalFetching: false,
        personalProjects: payload,
        personalCount: propOr(0, 'total', payload),
      };
    case ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_FAILURE:
      return {
        ...state,
        personalFetching: false,
        personalProjects: [],
        personalCount: 0,
        error: '',
      };

    case ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_REQUEST:
      return {
        ...state,
        projectsFetching: true,
      };
    case ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_SUCCESS:
      return {
        ...state,
        projectsFetching: false,
        projects: payload,
        projectsCount: propOr(0, 'total', payload),
      };
    case ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_FAILURE:
      return {
        ...state,
        projectsFetching: false,
        projects: [],
        projectsCount: 0,
        error: '',
      };

    case ProjectTypeCommonConstants.GET_LIST_OF_PROJECT_UUIDS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case ProjectTypeCommonConstants.GET_LIST_OF_PROJECT_UUIDS_SUCCESS:
      return {
        ...state,
        fetching: false,
        projectsUuids: payload,
      };
    case ProjectTypeCommonConstants.GET_LIST_OF_PROJECT_UUIDS_FAILURE:
      return {
        ...state,
        fetching: false,
        projectsUuids: [],
      };

    case ProjectTypeCommonConstants.LIST_OF_PROJECT_IN_QUICK_ISSUE_MODAL_REQUEST:
      return {
        ...state,
      };
    case ProjectTypeCommonConstants.LIST_OF_PROJECT_IN_QUICK_ISSUE_MODAL_SUCCESS:
      return {
        ...state,
        projectsInQuickIssueModal: payload,
      };
    case ProjectTypeCommonConstants.LIST_OF_PROJECT_IN_QUICK_ISSUE_MODAL_FAILURE:
      return {
        ...state,
        projectsInQuickIssueModal: [],
        error: '',
      };

    case ProjectTypeCommonConstants.CLEAR_LIST_OF_PROJECT_FROM_QUICK_ISSUE_MODAL:
      return {
        ...state,
        projectsInQuickIssueModal: [],
        error: '',
      };
    case ProjectTypeCommonConstants.CLEAR_LIST_OF_PROJECTS:
      return {
        ...state,
        projectsFetching: false,
        projects: [],
        projectsUuids: [],
        projectsCount: 0,
        error: '',
      };
    case ProjectTypeCommonConstants.CLEAR_LIST_OF_PERSONAL_PROJECTS:
      return {
        ...state,
        personalFetching: false,
        personalProjects: [],
        personalCount: 0,
        error: '',
      };

    case ProjectTypeCommonConstants.EDIT_PROJECT_SUCCESS: {
      const transformProject = (item) => {
        if (prop('uuid', item) === prop('uuid', head(payload))) {
          return ({
            ...item,
            params: {
              ...prop('params', item),
              ...prop('params', head(payload)),
            },
          });
        }
        return item;
      };
      return {
        ...state,
        projects: state.projects.map((item) => transformProject(item)),
      };
    }

    case ProjectTypeCommonConstants.NEW_PROJECT_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ProjectTypeCommonConstants.NEW_PROJECT_SUCCESS:
      return {
        ...state,
        fetching: false,
        projects: state.projects,
      };
    case ProjectTypeCommonConstants.NEW_PROJECT_FAILURE:
      return {
        ...state,
        fetching: false,
        projects: state.projects,
        projectsCount: 0,
        error: payload,
      };
    default: return state;
  }
};

import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Dropdown,
} from 'antd';
import {
  FilePdfOutlined,
  FileWordOutlined,
  InsertRowLeftOutlined,
  InsertRowAboveOutlined,
  FileExcelOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { utils, writeFileXLSX } from 'xlsx';
import { capitalize, get } from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import cn from 'classnames';

import ModalsCreateIssues from '../ModalsCreateIssues';
import WrapperAddIssue from '../../issues/newIssue/WrapperAddIssue';
import WrapperAddVector from '../../../containers/vector/WrapperAddVector';


import { antNotification } from '../../../../MainUtils';
import { getProjectUUID } from '../../../selectors/selectors';
import { sendSystemComment, getCommentsAndUpdateStore } from '../../../actions/projectFlowActions';
import { ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import { getCurrentQuillInstance } from '../../../reducers/slicers/quillSlicer';
import { useQuill } from '../QuillEditor/useQuill/useQuill';
import { parseHTMLString } from './utils';

import './DocsOptions.scss';


const disabledViewCreateIssueModal = ['assignedProject'];

const PAGE_A4_WIDTH_PT = 565.27;
const PAGE_A4_HEIGHT_PT = 841.89;
// const PX_TO_PT = 0.75;
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

function DocsOptions({
  entityUUID,
  // ckeditor,
  commentType,
  bulkTitles = [''],
  defaultPartition,
  disabledElements = [],
  entityType,
  // hideElements = [],
  isDisabled = false,
  isFullscreen = false,
  spreadsheetEditor,
  title = '',
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const projectUUID = useSelector(getProjectUUID);

  const quillInstance = useSelector(getCurrentQuillInstance);

  const {
    getClearVectors = () => {},
  } = useContext(ProjectMainLayerContext) || {};

  const {handleExportDocx} = useQuill();

  const defaultConfigVectors = {
    parent: projectUUID,
    params: { status: ['active', 'created', 'closed'] },
    params_fields: {
      id: 'id',
      title: 'title',
      type: 'type',
      priority: 'priority',
      status: 'status',
      users: 'users',
    },
    limit: 5,
    offset: 0,
  };

  const [addIssuesModal, setAddIssuesModal] = useState(false);
  const [addIssueModal, setAddIssueModal] = useState(false);
  const [addVectorModal, setAddVectorModal] = useState(false);

  const [selectedTitleArray, setSelectedTitleArray] = useState(['']);
  const [selectedDescriptionsArr, setSelectedDescriptionsArr] = useState([]);
  const [customTitle, setCustomTitle] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const changeAddIssuesModal = () => setAddIssuesModal(true);
  const changeAddIssueModal = () => setAddIssueModal(true);
  const changeAddVectorModal = () => setAddVectorModal(true);
  // const changeAddVectorForDocumentModal = () => setAddVectorForDocumentModal(true)
  const handleCancelIssueModal = () => setAddIssueModal(false);
  const handleCancelVectorModal = () => setAddVectorModal(false);

  const checkImageParams = ({naturalWidth, naturalHeight, width , height}) => {
    // Проверка aspect ratio у картинки: модуль в Quill может некорректно менять высоту изображения
    // naturalWidth, naturalHeight - исходные значения при вставке в редактор
    if (!naturalWidth || !naturalHeight) {
      return {width, height}
    }
    const aspectRatioDefault = Number(naturalWidth / naturalHeight).toFixed(2);
      const aspectRatioCurrent = width && height ? Number(width / height).toFixed(2) : null;
      if (aspectRatioDefault !== aspectRatioCurrent)  {
        const newWidth = width ? width : naturalWidth;
        const newHeight = newWidth / aspectRatioDefault;
        return {width: newWidth, height: newHeight}
      }
      return {width, height}
  }

  const resizeImage = (width, height, containerWidth, containerHeight) => {
    const aspectRatio = width / height;

    const widthRatio = width / containerWidth;
    const heightRatio = height / containerHeight;

    let newWidth = width;
    let newHeight = height;

    if (widthRatio > 1 || heightRatio > 1) {
      if (widthRatio > heightRatio) {
        newWidth = containerWidth;
        newHeight = newWidth / aspectRatio;
      }
      if (heightRatio > widthRatio) {
        newHeight = containerHeight;
        newWidth = newHeight * aspectRatio;
      }
    }
    // console.log('crdt [Math.floor(newWidth), Math.floor(newHeight)]', [Math.floor(newWidth), Math.floor(newHeight)])
    return [Math.floor(newWidth), Math.floor(newHeight)];
  };

  const addRow = () => {
    spreadsheetEditor?.insertRow();
  };

  const addColumn = () => {
    spreadsheetEditor?.insertColumn();
  };

  function convertQuillHtmlToNested(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const elements = Array.from(doc.body.children);
    let rootList = null;

    elements.forEach((element) => {
      if (
        element.tagName.toLowerCase() === "ul" ||
        element.tagName.toLowerCase() === "ol"
      ) {
        const items = Array.from(element.querySelectorAll("li"));
        let lastLevel = 0;
        let parentLists = [
          createListForLevel(doc, element.tagName.toLowerCase(), 0),
        ];
        if (!rootList) rootList = parentLists[0];

        items.forEach((item) => {
          const match = item.className.match(/ql-indent-(\d+)/);
          const level = match ? parseInt(match[1], 10) : 0;

          item.classList.remove(...item.classList);

          if (level > lastLevel) {
            const newList = createListForLevel(
              doc,
              element.tagName.toLowerCase(),
              level
            );
            parentLists[parentLists.length - 1].lastElementChild.appendChild(
              newList
            );
            parentLists.push(newList);
          } else if (level < lastLevel) {
            parentLists = parentLists.slice(0, level + 1);
          }

          parentLists[parentLists.length - 1].appendChild(item);
          lastLevel = level;
        });

        // Заменяем текущий список на переработанный
        element.replaceWith(parentLists[0]);
      }
    });

    return doc.body.innerHTML;
  }

  function createListForLevel(doc, listType, level) {
    const isOrderedList = listType === "ol";
    const isNestedOrderedList = level !== 0 && level % 4 != 0;
    const list = doc.createElement(
      isOrderedList || isNestedOrderedList ? "ol" : "ul"
    );

    if (isOrderedList || isNestedOrderedList) {
      switch (level % 4) {
        case 1:
          list.style.listStyleType = "lower-alpha";
          break;
        case 2:
          list.style.listStyleType = "lower-roman";
          break;
        case 3:
          list.style.listStyleType = "decimal";
          break;
        // Очередной уровень использует стандартную нумерацию
      }
    }
    return list;
  }

  const saveAsEditablePdf = () => {
    try {
      setIsLoading(true);
      const pageMarginPT = 15;
      const pageInnerWidth = Math.floor(PAGE_A4_WIDTH_PT - 2 * pageMarginPT);
      const pageInnerHeight = Math.floor(PAGE_A4_HEIGHT_PT - 2 * pageMarginPT);

      const htmlString = quillInstance.root.innerHTML;
      if (!htmlString) {
        antNotification(
          "warning",
          t("notifications.text.warning.empty_file", "empty_file")
        );
        return setIsLoading(false);
      }

      // Создание временного элемента для обработки HTML
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlString;

      // Обработка изображений
      const images = tempElement.getElementsByTagName("img");
      Array.from(images).forEach((img) => {
        const { width, height, naturalWidth, naturalHeight } = img;
        const { width: newWidth, height: newHeight } = checkImageParams({
          width,
          height,
          naturalWidth,
          naturalHeight,
        });
        const [currentWidth, currentHeight] = resizeImage(
          newWidth,
          newHeight,
          pageInnerWidth,
          pageInnerHeight
        );
        img.width = currentWidth;
        img.height = currentHeight;
      });

      // Преобразование HTML для pdfmake
      const preparedHTML = convertQuillHtmlToNested(tempElement.innerHTML);
      const htmlToPdfmakeOptions = { tableAutoSize: true, imagesByReference: true };
      const pdfContent = htmlToPdfmake(preparedHTML, htmlToPdfmakeOptions);

      // Обработка изображений в pdfmake содержимом
      const preparedPdfContent = pdfContent.content.map((node) => {
        if (node.nodeName === "FIGURE") {
          const newFigureStack = node.stack.map((stack) => {
            if (stack.nodeName === "IMG") {
              const { width, height } = stack;
              const [newWidth, newHeight] = resizeImage(
                width,
                height,
                pageInnerWidth,
                pageInnerHeight
              );
              return { ...stack, width: newWidth, height: newHeight };
            }
            return stack;
          });
          return { ...node, stack: newFigureStack };
        }
        return node;
      });

      // Конфигурация для pdfmake
      const docDefinitions = {
        content: preparedPdfContent,
        images: pdfContent.images,
        pageSize: "A4",
        pageOrientation: "portrait",
        pageMargins: [pageMarginPT, pageMarginPT, pageMarginPT, pageMarginPT],
        info: { title },
        compress: true,
      };

      // Генерация PDF и скачивание
      pdfMake.createPdf(docDefinitions).download(`${title}.pdf`);

    } catch (error) {
      console.log("Error:", error);
    } finally {
      // Очистка и установка состояния
      setIsLoading(false);
    }
  };


  const saveAsDocx = async () => {
    try {
      setIsLoading(true);

      // Определение размеров страницы и внутренних отступов
      const pageMarginPT = 15;
      const pageInnerWidth = Math.floor(PAGE_A4_WIDTH_PT - 2 * pageMarginPT);
      const pageInnerHeight = Math.floor(PAGE_A4_HEIGHT_PT - 2 * pageMarginPT);

      // Получение HTML контента из Quill
      const htmlString = quillInstance.root.innerHTML;
      if (!htmlString) {
        antNotification('warning', t('notifications.text.warning.empty_file', 'empty_file'));
        return setIsLoading(false);
      }

      // Создание временного элемента для обработки изображений
      const tempElement = document.createElement('div');
      tempElement.innerHTML = htmlString;

      // Обработка всех изображений
      const images = tempElement.getElementsByTagName('img');
      Array.from(images).forEach((img) => {
        const { width, height, naturalWidth, naturalHeight } = img;
        const { width: newWidth, height: newHeight } = checkImageParams({
          width,
          height,
          naturalWidth,
          naturalHeight
        });

        const [currentWidth, currentHeight] = resizeImage(newWidth, newHeight, pageInnerWidth, pageInnerHeight);
        img.width = currentWidth;
        img.height = currentHeight;
      });

      // Преобразование HTML для экспорта
      const preparedHTML = convertQuillHtmlToNested(tempElement.innerHTML);

      // Экспорт в DOCX
      handleExportDocx(preparedHTML, title);

    } catch (error) {
      console.log('Error while saving DOCX:', error);
    } finally {
      // Завершаем процесс, независимо от успеха или ошибки
      setIsLoading(false);
    }
  };


  const saveAsCSV = () => {
    if (spreadsheetEditor) {

      spreadsheetEditor.options.csvFileName = title || 'New Spreadsheet';
      spreadsheetEditor.download(true, false);
    }
  };

  const saveAsExcel = () => {
    const tableDataJson = spreadsheetEditor?.getJson();
    // console.log('tableDataJson', tableDataJson);

    const workSheet = utils.json_to_sheet(tableDataJson, { skipHeader: true });
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, 'Exported data');

    writeFileXLSX(workBook, `${title}.xlsx`);
  };

  const sendCustomComment = async (uuid, commentTitle, action) => {
    const comment = {
      entity: entityType,
      action,
      param: commentTitle,
      value: uuid,
    };

    if (entityUUID) {
      await dispatch(sendSystemComment(entityUUID, comment, defaultPartition));

      if (commentType) {
        dispatch(getCommentsAndUpdateStore({
          commentType,
          parent: entityUUID,
          partition: defaultPartition,
        }));
      }
    }
  };

  const newSuccessCallback = (uuid, data, action) => {
    if (action === 'created') {
      handleCancelIssueModal();
    } else {
      getClearVectors(defaultConfigVectors);
      handleCancelVectorModal();
    }

    sendCustomComment(uuid, data.params.title, action);
  };

  const menuItems = () => {
    switch (entityType) {
      case 'projectSpreadsheet': {
        return [
          {
            key: '1',
            label: capitalize(t('wms.buttons.add_row')),
            icon: <InsertRowAboveOutlined />,
            disabled: disabledElements.includes('addRow'),
            onClick: () => addRow(),
          },
          {
            key: '2',
            label: capitalize(t('wms.buttons.add_column')),
            icon: <InsertRowLeftOutlined />,
            disabled: disabledElements.includes('addColumn'),
            onClick: () => addColumn(),
          },
          {
            key: '3',
            label: capitalize(t('wms.buttons.export_csv')),
            icon: <FileTextOutlined />,
            disabled: disabledElements.includes('exportCSV'),
            onClick: () => saveAsCSV(),
          },
          {
            key: '4',
            label: capitalize(t('wms.buttons.export_excel')),
            icon: <FileExcelOutlined />,
            disabled: disabledElements.includes('exportExcel'),
            onClick: () => saveAsExcel(),
          },
        ];
      }
      case 'projectDocument':
      case 'projectIdea':
      case 'historyOfIdeaChanges':
      case 'projectGoal':
      case 'projectMilestone':
      default: {
        return [
          {
            key: '1',
            label: capitalize(t('Create issue')),
            disabled: disabledElements.includes('addIssue'),
            onClick: changeAddIssueModal,
          },
          {
            key: '2',
            label: capitalize(t('Create bulk issues')),
            disabled: disabledElements.includes('addBalk'),
            onClick: changeAddIssuesModal,
          },
          {
            key: '3',
            label: capitalize(t('Create vector')),
            disabled: disabledElements.includes('addVector'),
            onClick: changeAddVectorModal,
          },
          {
            key: '4',
            label: capitalize(t('wms.buttons.export_editable_pdf')),
            icon: <FilePdfOutlined />,
            disabled: disabledElements.includes('exportPDF'),
            onClick: () => saveAsEditablePdf(),
          },
          {
            key: '5',
            label: capitalize(t('wms.buttons.export_doc')),
            icon: <FileWordOutlined />,
            disabled: disabledElements.includes('exportDocx'),
            onClick: () => saveAsDocx(),
          },
        ];
      }
    }
  };

  const menuProps = {
    items: menuItems(),
  };

  useEffect(() => {
    if (!quillInstance && bulkTitles) {
      setSelectedTitleArray(bulkTitles);
    }
  }, [addIssueModal, addVectorModal, addIssuesModal]);


  const handleDropdownClick = () => {
    if (quillInstance) {
      const range = quillInstance.getSelection();
      // console.log('crdt range', quillInstance);
      if (range) {
        if (range.length === 0) {
          // console.log('crdt Ничего не выделено');
        } else {
          const selectedText = `<html><body>${quillInstance.getSemanticHTML(
            range.index,
            range.length
          )}</body></html>`;
          const parsedList = parseHTMLString(selectedText);
          // console.log('crdt parsedList', parsedList)
          if (Array.isArray(parsedList)) {
            const titleArr = parsedList.map((item) =>
              String(item?.title).trim()
                ? item?.title?.slice(0, 128)
                : "new Issue"
            );
            const descriptionsArr = parsedList.map((item) => {
              if (item?.content) {
                return item?.content.join("\n");
              }
              return "";
            });
            // console.log('crdt titleArr', titleArr)
            setSelectedTitleArray(titleArr);
            setSelectedDescriptionsArr(descriptionsArr);
            setCustomTitle(titleArr.join(" ")?.slice(0, 128));
          }
        }
      } else {
        // console.log('crdt Редактор не в фокусе');
      }
    }
  };


  const handleCancelCallback = () => {
    setAddIssuesModal(false);
    setSelectedTitleArray(['']);
    setSelectedDescriptionsArr([]);
    setCustomTitle('');
  }

  return (
    <>
      <div className={cn('p-0', { 'options _fullscreen': isFullscreen })}>
        <Dropdown
          className="flex justify-center items-center custom-dropdown-btn"
          overlayClassName="custom-dropdown-list"
          loading={isLoading}
          menu={menuProps}
          placement="bottomRight"
          disabled={disabledElements.includes('options') || isDisabled || isLoading}
          trigger={['click']}
        >
          <Button
            id='openDropdownButton'
            className="btnPrimary-outline"
            size="small"
            onClick={handleDropdownClick}
            disabled={isDisabled}
          >
            <Icon path={mdiDotsHorizontal} size={1} />
          </Button>
        </Dropdown>
      </div>

      {addIssuesModal && (
      <ModalsCreateIssues
        entityUUID={entityUUID}
        commentType={commentType}
        customTitles={selectedTitleArray}
        customDescriptionsArr={selectedDescriptionsArr}
        customDescription={`${capitalize(t('wms.projectflow.created_based_on', 'created based on'))}: ${window.location.href.trim()}`}
        showModal={addIssuesModal}
        partitionType={defaultPartition}
        parent={projectUUID}
        handleCancelCallback={handleCancelCallback}
      />
      )}

      {addIssueModal
        && (
          <WrapperAddIssue
            partitionType={defaultPartition}
            disabledView={disabledViewCreateIssueModal}
            defaultIssueState={{
              description: `${capitalize(t('wms.projectflow.created_based_on', 'created based on'))}: ${window.location.href.trim()}`,
              title: customTitle,
            }}
            openFromOuterComponent={addIssueModal}
            hideAddIssueBtn
            closeModalCallback={handleCancelIssueModal}
            createIssueCallback={(uuid, data) => newSuccessCallback(uuid, data, 'created')}
          />
        )}

      {addVectorModal
        && (
          <WrapperAddVector
              //  zIndex={9999}
            vectorType="general"
            partitionType={defaultPartition}
            parentUUID={projectUUID}
            customTitle={customTitle}
            customDescription={
              `${capitalize(t('wms.projectflow.created_based_on', 'created based on'))}: ${window.location.href.trim()}`
            }
            projectRecipients={[]}
            hideBtnAddVector
            openFromOuterComponent={addVectorModal}
            handleCancelCallbackFunc={handleCancelVectorModal}
            newVectorSuccessCallback={(data, uuid) => newSuccessCallback(uuid, data, 'created vector')}
          />
        )}
    </>

  );
}

DocsOptions.propTypes = {
  commentType: PropTypes.string,
  entityUUID: PropTypes.string,
  bulkTitles: PropTypes.array,
  // ckeditor: PropTypes.object || null,
  defaultPartition: PropTypes.string,
  disabledElements: PropTypes.array,
  entityType: PropTypes.string,
  hideElements: PropTypes.array,
  isDisabled: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  spreadsheetEditor: PropTypes.object || null,
  title: PropTypes.string,
};

export default DocsOptions;

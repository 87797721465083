import { Button, Modal, Result } from 'antd';
import { isNil } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPublicInterfaces } from '../api/api';
import { fetchingConfigAuth } from '../config/selectors/selectors';

function DidNotReceiveServiceDomain() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchingConfig = useSelector(fetchingConfigAuth);

  const styleBodyModal = {
    padding: '0px',
    // backgroundColor: '#faf2d7a6',
  };

  return (
    <>
      {(!isNil(fetchingConfig) && !fetchingConfig) && (
      <Modal
        centered
        open
        footer={null}
        bodyStyle={styleBodyModal}
      >
        <>
          <Result
            status="warning"
            title="Could not find backend service, please contact your administrator"
            extra={(
              <div className="w-full flex justify-center">
                <Button
                  className="btnWarning-outline"
                  size="small"
                  key="console"
                  onClick={() => {
                    dispatch(getPublicInterfaces(() => {}));
                  }}
                >
                  get one more time
                </Button>
              </div>
      )}
          />
        </>
      </Modal>
      )}
    </>
  );
}
export default DidNotReceiveServiceDomain;

import { get } from 'lodash';
import moment from 'moment';

const arrSortUsersByStartWork = (users = [], reports = []) => (users.map((e) => {
  const report = reports.find((el) => el.actor === e.actor);

  return { ...e, startWork: get(report, ['startWork'], null) };
}));

const arrSortUsersByFinishWork = (users = [], reports = []) => (users.map((e) => {
  const report = reports.find((el) => el.actor === e.actor);

  return { ...e, finishWork: get(report, ['finishWork'], null) };
}));

const arrSortUsersByOnWorkPlace = (users = [], reports = []) => (users.map((e) => {
  const report = reports.find((el) => el.actor === e.actor);

  const finishWorkFromReport = get(report, ['localeFinishWork'], '-');

  const currentFinishWork = finishWorkFromReport === '-' ? '-' : moment(finishWorkFromReport).format('HH[h]mm[m]');

  const validWorkTime = currentFinishWork !== '-' && new Date() < new Date(finishWorkFromReport);

  return { ...e, onWorkPlace: validWorkTime ? 1 : -1 };
}));

const arrSortUsersByHavingPlan = (users = [], reports = []) => (users.map((e) => {
  const report = reports.find((el) => el.actor === e.actor);

  const planForToday = get(report, ['dailyPlanningText'], '');

  return { ...e, dailyPlanningText: planForToday ? 1 : -1 };
}));

const arrSortUsersByDayType = (users = [], reports = []) => (users.map((e) => {
  const report = reports.find((el) => el.actor === e.actor);

  const planForToday = get(report, ['kindOfDay'], '');

  const collection = {
    holiday: 3,
    'day off': 4,
    'sick day': 5,
  };

  return { ...e, kindOfDay: collection[planForToday] || -1 };
}));

const arrSortUsersByAlerts = (users = [], reports = []) => (users.map((e) => {
  const report = reports.find((el) => el.actor === e.actor);

  const haveWorkQuestions = get(report, ['haveWorkQuestions'], false);
  const dontHaveEnoughTasks = get(report, ['dontHaveEnoughTasks'], false);
  const feelSickGoingHome = get(report, ['feelSickGoingHome'], false);

  let priority = 0;

  if (haveWorkQuestions && dontHaveEnoughTasks && feelSickGoingHome) {
    priority = 2;
  } else if (haveWorkQuestions || dontHaveEnoughTasks || feelSickGoingHome) {
    priority = 1;
  } else {
    priority = 0;
  }

  return { ...e, alert: priority };
}));

const arrSortUsersByShowingReports = (users = []) => (users.map((user) => {
  const showStartWorkReport = get(user, ['showBaseReport']) ?? true;
  const showDetailedReport = get(user, ['showDetailedReport']) ?? true;

  let priority = 0;

  if (showStartWorkReport && showDetailedReport) {
    priority = 4;
  } else if (showStartWorkReport && !showDetailedReport) {
    priority = 3;
  } else if (!showStartWorkReport && showDetailedReport) {
    priority = 2;
  } else {
    priority = 1;
  }

  return { ...user, showReports: priority };
}));

export {
  arrSortUsersByShowingReports,
  arrSortUsersByAlerts,
  arrSortUsersByDayType,
  arrSortUsersByFinishWork,
  arrSortUsersByHavingPlan,
  arrSortUsersByOnWorkPlace,
  arrSortUsersByStartWork,
};

import PropTypes, { func, string } from 'prop-types';
import React, { useContext } from 'react';
import {
  prop,
} from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { get, omit } from 'lodash';
import TestCaseForm from './TestCaseForm';

import { ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import {
  getProjectUUID,
  getTestSuiteParams,
  getTestSuiteUUID,
} from '../../../selectors/selectors';
import { addTestCaseInListTestCase, sendSystemComment } from '../../../actions/projectFlowActions';
import { getHeadUUID } from '../../../../MainUtils';
import { createTestCaseForSuite, updateTestSuite } from '../../../actions/QaActions';
import { qaNotify } from '../utilsQa';
import { incrementAndGetCount } from '../../../../entity/actions/entityActions';
import { setGlobalUpdateEntity } from '../../../reducers/slicers/breadcrumbsSlicer';

function NewTestCase({
  toggleModal,
  partition,
  getListOfTestSuites,
}) {
  const dispatch = useDispatch();

  const {
    sendAssignedNotifications,
  } = useContext(ProjectMainLayerContext);

  const testSuiteUUID = useSelector(getTestSuiteUUID);
  const testSuiteParams = useSelector(getTestSuiteParams);
  const projectUUID = useSelector(getProjectUUID);

  const createPromiseNewTestCaseForSuite = (createParams) => {
    const data = {
      ...createParams,
      partition,
    };

    const uniqKey = `testCaseForSuite${Date.now()}`;
    qaNotify({
      param: 'info',
      key: uniqKey,
      message: 'Creating new test case...',
    });

    return dispatch(createTestCaseForSuite(data))
      .then((response) => {
        const assignedUsers = get(createParams, 'params.assignToUser');

        qaNotify({
          param: 'success',
          key: uniqKey,
          message: 'Test case created',
        });

        sendAssignedNotifications({
          entity_type: 'testCase',
          eventName: 'assigned_test_case',
          assignedUsers,
          entityUUID: getHeadUUID(response),
          message: get(response, '[0].params.title', ''),
          entityUUIDsFields: {
            testSuiteUUID,
            testCaseUUID: getHeadUUID(response),
            projectUUID,
          },
        });

        return ({ response });
      })
      .catch(() => {
        qaNotify({
          param: 'error',
          key: uniqKey,
          message: 'Test case error',
        });
      });
  };

  const updateTestSuitePromise = ({ uuid, params }) => {
    const data = {
      uuid: uuid || testSuiteUUID,
      params: { ...params },
      partition,
    };

    console.log('new');
    

    const uniqKey = `testSuiteUpd${Date.now()}`;
    qaNotify({
      param: 'info',
      key: uniqKey,
      message: 'Updating test suite...',
    });
    return dispatch(updateTestSuite(data)).then((res) => {
      qaNotify({
        param: 'success',
        key: uniqKey,
        message: 'Test suite updated',
      });
      return res;
    });
  };

  const AddCommentsCase = (comment, caseUUID) => {
    const data = {
      action: 'changed',
      entity: 'testCase',
      param: 'preconditions',
      value: '',
      descriptionComment: comment,
    };

    dispatch(sendSystemComment(caseUUID, data, partition));
  };

  const updateTestSuiteData = (data) => {
    const { cases_count, typeOfTesting } = testSuiteParams;

    const prevCasesCount = cases_count || 0;
    const prevTypeOfTesting = typeOfTesting;
    let listOfTestingTypes = new Set()
    
    prevTypeOfTesting?.forEach(listOfTestingTypes.add, listOfTestingTypes);
    [...data.typeOfTesting].forEach(listOfTestingTypes.add, listOfTestingTypes);

    const editSuiteParams = {
      cases_count: prevCasesCount + 1,
      typeOfTesting: Array.from(listOfTestingTypes),
    };
    
    updateTestSuitePromise({ params: editSuiteParams }).then(() => {
      if (getListOfTestSuites) {
        getListOfTestSuites();
      } else {
        dispatch(setGlobalUpdateEntity({ entityType: 'testSuite', action: 'update' }));
      }
    });
  };

  const submitNewTestCase = async (data) => {
    const {
      title,
      description,
      comment,
      grid,
      typeOfTesting,
      assignToUsersOption,
      statusPriority,
      tags,
    } = data;

    const { increment } = await dispatch(incrementAndGetCount({
      entity_uuid: projectUUID,
      field_name: 'suite',
    }, partition));

    const getValue = (item) => prop('value', item);

    const rawData = grid.map((item) => ({
      actual: null,
      description: getValue(item[2]),
      expected: getValue(item[4]),
      step: getValue(item[1]),
      test_data: getValue(item[3]),
    }));

    const createParams = {
      parent: testSuiteUUID,
      params: {
        title,
        comment,
        description,
        typeOfTesting,
        status: 'unexecuted',
        steps: rawData,
        suite: testSuiteUUID,
        assignToUser: assignToUsersOption,
        template: true,
        executed_by: null,
        executed_on: null,
        id: `${get(testSuiteParams, 'id', 0)}C${increment}`,
        order: increment,
        casePriority: statusPriority,
        tags,
      },
    };
    const res = await createPromiseNewTestCaseForSuite(createParams);
    // updateTestSuiteData();
    dispatch(addTestCaseInListTestCase({
      ...omit(get(res, 'response[0]', {}), ['params']),
      ...get(res, 'response[0].params', {}),
    }));

    return res.response;
  };

  const createTestCaseFunc = async (data) => {
    const { comment } = data;

    // console.log(comment);

    const newTestCase = await submitNewTestCase(data);
    const newTestCaseUUID = getHeadUUID(newTestCase);

    if (comment.trim().length !== 0 && newTestCaseUUID) {
      AddCommentsCase(comment, newTestCaseUUID);
    }

    updateTestSuiteData(data);
  };

  const submitCallback = (data) => {
    createTestCaseFunc(data);

    if (toggleModal) {
      toggleModal();
    }
  };

  return (
    <TestCaseForm
      formType='new'
      partitionType={partition}
      submitCallback={submitCallback}
      submitAntherOneCallback={createTestCaseFunc}
    />
  );
}

export default NewTestCase;

NewTestCase.propTypes = {
  toggleModal: PropTypes.func,
  partition: string,
  getListOfTestSuites: func,
};

/* eslint-disable import/prefer-default-export */

import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPartitionPMUUID } from '../../../config/selectors/selectors';

import { getListOfProjectGoalsRequest } from '../../../projectFlow/actions/projectGoalsActions';
import { getProjectsEntityUuids } from '../../../projectFlow/selectors/selectors';
import { partitionNamesConfig } from '../../../api/appConfig';

export const useGetProjectsGoals = (config) => {
  const dispatch = useDispatch();
  const myProjects = useSelector(getProjectsEntityUuids);
  const partitionUUID = useSelector(getPartitionPMUUID);

  const getProjectsGoals = () => {
    const {
      offset,
      pageLimit,
      searchData,
      orderBy,
      order,
      params,
    } = config || {};

    const fields = {
      'actor.uinfo': 'uinfo',
      modified: 'modified',
      'actor.actor_type': 'actor_type',
    };

    const paramsFields = {
      id: 'id',
      title: 'title',
      status: 'status',
      responsible: 'responsible',
      lifeTime: 'lifeTime',
    };

    const partitionType = partitionNamesConfig.partition1;

    return dispatch(getListOfProjectGoalsRequest({
      entities: myProjects,
      parent: partitionUUID,
      limit: pageLimit,
      params,
      offset,
      searchData,
      orderBy,
      order,
      fields,
      params_fields: paramsFields,
    }, partitionType));
  };

  useEffect(() => {
    if (!isEmpty(myProjects) && partitionUUID && !isEmpty(config)) {
      getProjectsGoals();
    }
  }, [myProjects, config, partitionUUID]);
};

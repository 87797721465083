import React, { useState, useCallback } from 'react';
import { array, string, func } from 'prop-types';

import CompletedModal from './statusModals/CompletedModal';
import FailedModal from './statusModals/FailedModal';
import InProgressModal from './statusModals/InProgressModal';
import EntityParamsPopover from '../commonComponents/EntityParamsPopover';
import PartiallyCompletedModal from './statusModals/PartiallyCompletedModal';

import { baseMilestoneConfig } from './constants/baseMilestoneConfig';
import { milestoneStatusOptions } from './constants/milestoneOptions';

const MODAL_TYPE = {
  NONE: 'NONE',
  STATUS_IN_PROGRESS: 'STATUS_IN_PROGRESS',
  STATUS_COMPLETED: 'STATUS_COMPLETED',
  STATUS_PARTIALLY_COMPLETED: 'STATUS_PARTIALLY_COMPLETED',
  STATUS_FAILED: 'STATUS_FAILED',
};

function MilestoneStatus({
  partition,
  projectUUID,
  currentStatus,
  currentStartDate,
  milestoneDescription,
  disabledElements = [],
  statusPreviewType = 'iconWithArrow',
  updateMilestoneParameter,
  createProjectMilestoneCallback,
}) {
  const [modalType, setModalType] = useState(MODAL_TYPE.NONE);
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = useCallback(() => setModalOpen(false), [setModalOpen]);
  const resetModalType = useCallback(() => setModalType(MODAL_TYPE.NONE), [setModalType]);

  //----------------------------------------------------------------
  const changeStatus = (statusValue) => {
    switch (statusValue) {
      case 'in progress': {
        setModalType(MODAL_TYPE.STATUS_IN_PROGRESS);
        setModalOpen(true);
        break;
      }

      case 'failed': {
        setModalType(MODAL_TYPE.STATUS_FAILED);
        setModalOpen(true);
        break;
      }

      case 'completed': {
        setModalType(MODAL_TYPE.STATUS_COMPLETED);
        setModalOpen(true);
        break;
      }

      case 'partially completed': {
        setModalType(MODAL_TYPE.STATUS_PARTIALLY_COMPLETED);
        setModalOpen(true);
        break;
      }

      case 'created':
      case 'draft': {
        updateMilestoneParameter({
          ...baseMilestoneConfig,
          status: statusValue,
        });
        break;
      }

      default: {
        updateMilestoneParameter({ status: statusValue });
        break;
      }
    }
  };

  return (
    <div id="status-modal">
      <EntityParamsPopover
        className="p-0 flex flex-col flex-shrink-0"
        param="status"
        currentValue={currentStatus}
        options={milestoneStatusOptions}
        previewType={statusPreviewType}
        tooltipPlacement="bottomLeft"
        withBorder
        withIcons
        disableCollapse={disabledElements.includes('status')}
        onChangeValue={changeStatus}
      />

      {modalType === MODAL_TYPE.STATUS_IN_PROGRESS && (
        <InProgressModal
          modalOpen={modalOpen}
          closeModal={closeModal}
          updateMilestoneParameter={updateMilestoneParameter}
          closeModalCallback={resetModalType}
        />
      )}

      {modalType === MODAL_TYPE.STATUS_COMPLETED && (
        <CompletedModal
          modalOpen={modalOpen}
          currentStartDate={currentStartDate}
          closeModal={closeModal}
          updateMilestoneParameter={updateMilestoneParameter}
          closeModalCallback={resetModalType}
        />
      )}

      {modalType === MODAL_TYPE.STATUS_PARTIALLY_COMPLETED && (
        <PartiallyCompletedModal
          partition={partition}
          projectUUID={projectUUID}
          modalOpen={modalOpen}
          defaultDescription={milestoneDescription}
          currentStartDate={currentStartDate}
          closeModal={closeModal}
          updateMilestoneParameter={updateMilestoneParameter}
          closeModalCallback={resetModalType}
          createProjectMilestoneCallback={createProjectMilestoneCallback}
        />
      )}

      {modalType === MODAL_TYPE.STATUS_FAILED && (
        <FailedModal
          modalOpen={modalOpen}
          currentStartDate={currentStartDate}
          closeModal={closeModal}
          updateMilestoneParameter={updateMilestoneParameter}
          closeModalCallback={resetModalType}
        />
      )}
    </div>
  );
}

MilestoneStatus.propTypes = {
  partition: string,
  projectUUID: string,
  currentStatus: string,
  currentStartDate: string,
  milestoneDescription: string,
  disabledElements: array,
  statusPreviewType: string,
  updateMilestoneParameter: func,
  createProjectMilestoneCallback: func,
};

export default MilestoneStatus;

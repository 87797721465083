import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const initialState = {
  perms: [],
};

const myProjectPermsSlicer = createSlice({
  name: 'myProjectPermsSlicer',
  initialState,
  reducers: {
    setPerms: (state, action) => {
      state.perms = action.payload;
    },
  },
});

export const {
  setPerms,
} = myProjectPermsSlicer.actions;

export const myPerms = (state) => get(state, 'projectManagementService.project.myProjectPerms.perms', []);

export default myProjectPermsSlicer.reducer;

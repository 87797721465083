import { capitalize, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import RenderAudio from '../../../projectFlow/components/activityView/listAffix/RenderAudio';
import RenderImg from '../../../projectFlow/components/activityView/listAffix/RenderImg';
import RenderTimeLog from '../../../projectFlow/components/activityView/listAffix/RenderTimeLog';
import ViewComment from '../../../projectFlow/components/activityView/listAffix/ViewComment';

import { getListAndReadAffix } from '../../../affix/affixActions';
import { audioFiles, imageFiles, otherFiles } from '../../../projectFlow/components/activityView/listAffix/utilsAffix';
import { getNameForActor } from '../../../54origins/utils54origins';
import { partitionNamesConfig } from '../../../api/appConfig';

export default function AffixData({ user, partitionPM, defaultConfig }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [affixConfig, setAffixConfig] = useState(defaultConfig);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalCount, setTotalCount] = useState([]);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const formatUser = (cell) => (
    <span className="whitespace-nowrap color-user-activity">{getNameForActor(cell)}</span>
  );

  const renderComment = (row) => {
    const image = imageFiles(row);
    const other = otherFiles(row);
    const audio = audioFiles(row);
    return (
      <div className="w-full">
        <ViewComment item={row} projectUUID="" />
        <div className="flex flex-wrap w-full">
          {(image.length + other.length) > 0 && <RenderImg imageFiles={image} otherFiles={other} />}
          {/* {otherFiles.length > 0 && renderFiles} */}
          {audio.length > 0 && <RenderAudio audioFiles={audio} />}
        </div>
      </div>
    );
  };

  const formatDescription = (row) => (get(row, 'affix_type') === 'comment'
    ? renderComment(row)
    : <RenderTimeLog item={row} />);

  const formatEntity = (row) => {
    const cell = get(row, 'affix_type') === 'timeLog'
      ? get(row, 'params.type', 'issue')
      : get(row, 'params.comment.entity', 'issue');
    return (
      <EntityParamTag
        value={cell}
        type="badge"
        param="entity"
      />
    );
  };

  const columns = [
    {
      dataIndex: ['uinfo'],
      title: capitalize(t('wms.table.headers.name')),
      render: (cell, row) => cell && formatUser(cell),
    },
    {
      dataIndex: 'affix_type',
      title: capitalize(t('wms.table.headers.affix_type')),
      // sorter: (a, b, c) => setSortSettings({ sortValue: c, sortType: 'entity_type' }),
      render: (cell) => cell && (
        <EntityParamTag
          value={cell}
          type="badge"
          param="affix"
        />
      ),
    },
    {
      dataIndex: 'entity_type',
      title: capitalize(t('wms.labels.entity_type')),
      // sorter: (a, b, c) => setSortSettings({ sortValue: c, sortType: 'entity_type' }),
      render: (cell, row) => formatEntity(row),
    },
    {
      dataIndex: ['params'],
      className: 'activity-affix-description',
      title: capitalize(t('wms.table.headers.description')),
      render: (cell, row) => cell && formatDescription(row),
    },
  ];

  const rowClassName = () => 'universal_table_row';

  const getUserAffix = () => {
    const config = {
      data: {
        actor: user?.actor,
        affix_type: ['comment', 'timeLog'],
        depth: 0,
        entity_uuid: partitionPM,
        ...affixConfig,
      },
      partition: partitionNamesConfig.partition1,
      constants: ['AFFIX_REQUEST', 'AFFIX_SUCCESS', 'AFFIX_FAILURE'],
    };

    return dispatch(getListAndReadAffix(config));
  };

  const getUserData = async () => {
    try {
      const affix = await getUserAffix();
      // console.log('affix', affix);
      setData(get(affix, 'data'));
      setTotalCount(get(affix, 'total'));
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const onPageChange = async ({ pageLimit: pl, currentPage: cp, offset: offs }) => {
    setCurrentPage(cp);
    setPageLimit(pl);
    // setOffset(offs);

    setAffixConfig((prev) => ({
      ...prev,
      limit: pl,
      offset: offs,
    }));
  };

  const remotePagination = {
    sizePerPageList: [10, 50, 100],
    defaultPageLimit: 10,
    totalSize: totalCount,
    page: currentPage,
    onChange: onPageChange,
    // onSizePerPage: onSizePerPageTable,
  };

  useEffect(() => {
    if (partitionPM) {
      getUserData();
    }
  }, [partitionPM, affixConfig]);

  //   console.log('data', data);
  return (
    <div>
      <h6>User affixes</h6>
      <BaseTableWithPagination
        pageLimit={pageLimit}
        changePageLimit={setPageLimit}
        useCustomPagination
    //   onRow={onRow}
        columns={columns}
        data={data}
    //   rowSelection={selectRow}
        loading={loading}
        total={get(remotePagination, 'totalSize')}
        pageSizeOptions={get(remotePagination, 'sizePerPageList')}
        newCurrentPage={get(remotePagination, 'page')}
        defaultPageLimit={get(remotePagination, 'defaultPageLimit')}
        getPaginationOptions={get(remotePagination, 'onChange')}
        rowClassName={rowClassName}
        paginationSize="small"
        headerRowClassName="universal_header_table_row"
      />
    </div>
  );
}

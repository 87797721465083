import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { head, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Col,
  Flex,
  Input,
  Row,
} from 'antd';
import { capitalize, get } from 'lodash';

import TagsSettingList from '../../../../components/filter/TagsSettingList';
import RadioButtons from '../../../../components/RadioButtons/RadioButtons';
import statusOptions from '../constants/ProjectStatusOptions';
import RelatedProjects from './components/RelatedProjects';
import ProjectTagsSettings from '../ProjectTagsSettings';

import {
  getAreasForSelect,
  getProject,
  getProjectParams,
} from '../../../selectors/selectors';
import {
  antNotification,
} from '../../../../MainUtils';
import { setFlagNotSaveData, setFlagOpenConfirm } from '../../../../components/un-save-data-confirm/unSaveDataSlicer';
import DescriptionDocument from './components/DescriptionDocument';
import InfrastructureDocument from './components/InfrastructureDocument';
import EditButton from '../../commonComponents/EditButton';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { createEntityPermissions, deleteEntityPerm } from '../../../../entity/actions/entityPermActions';
import { updateProjectRequest } from '../../../actions/projectFlowActions';
import { isAdminOrRoot } from '../../../../entity/selectors/selectors';
import useGetUserPerm from '../../../../permissions/hooks/useGetUserPerm';

function ProjectInfoFromSettings({
  titleRef,
  partitionType,
  formData,
  setFormData,
  updateProjectWithCustomsParams,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isEditMembers, setEditMembers] = useState(false);

  const project = head(useSelector(getProject));
  const projectParams = useSelector(getProjectParams);
  const areasForSelect = useSelector(getAreasForSelect);
  const adminOrRoot = useSelector(isAdminOrRoot);

  const {
    isNotSaveData,
  } = useSelector((state) => get(state, 'projectManagementService.project.unSaveData', {}));

  const {
    title,
    areas = [],
    status,
    relatedProjects,
    usersSearch,
  } = projectParams;

  const {
    uuid: projectUUID = '',
  } = project;

  const { perm: myPerms } = useGetUserPerm({
    entityUUID: projectUUID,
    partitionType,
  });

  const setDataIsEdited = () => !isNotSaveData && dispatch(setFlagNotSaveData(true));

  const transformAreasForTags = (data) => data.map((item) => ({
    label: prop('label', item) || item,
    value: prop('label', item) || item,
  }));
  const tags = [{ tags: transformAreasForTags([...areasForSelect]) }];
  const defaultSelectedTags = transformAreasForTags(areas);

  const addPermissionsToProject = (usersWithPerms = []) => {
    const data = {
      entity_uuid: projectUUID,
      data: [
        ...usersWithPerms.map(({ uuid, perms }) => ({
          actor: uuid,
          ...perms,
        })),
      ],
    };

    dispatch(
      createEntityPermissions({
        data,
        partition: partitionType,
      }),
    );
  };

  const deletePermissionsFromProject = (usersForDelete) => {
    const data = {
      actors: usersForDelete,
      entity_uuid: projectUUID,
    };

    dispatch(
      deleteEntityPerm({
        data,
        partition: partitionType,
      }),
    );
  };

  const updateProject = async (params) => {
    const data = {
      uuid: projectUUID,
      params: { ...params },
      partition: partitionType,
    };

    dispatch(updateProjectRequest(data))
      .then(() => {
        antNotification('success', t('wms.noun.success', 'Success'));

        dispatch(setFlagNotSaveData(false));
      })
      .catch(() => antNotification('error', 'Access denied!'));
  };

  const onChangeUsers = async (usersData) => {
    const {
      allUuids, allUsers,
      newPerms,
      deleteUsers,
    } = usersData;

    const params = { users: allUsers, usersSearch: allUuids };

    await updateProject(params);

    if (newPerms.length > 0) {
      addPermissionsToProject(newPerms);
    }

    if (deleteUsers.length > 0) {
      deletePermissionsFromProject(deleteUsers);
    }
  };

  useEffect(() => () => {
    dispatch(setFlagNotSaveData(false));
    dispatch(setFlagOpenConfirm(false));
  }, []);

  return (
    <>
      <Row className="mb-2">
        <Col span={24}>
          <div>
            <span className="text-secondary">
              {`${capitalize(t('wms.projectflow.project.info.title', 'title'))}:`}
            </span>
            <Input
              maxLength={128}
              showCount
              name="title"
              defaultValue={title}
              ref={titleRef}
              onChange={(e) => {
                setFormData({ ...formData, title: e.target.value });
                setDataIsEdited();
              }}
            />
          </div>
        </Col>
      </Row>
      <hr className="my-3" />
      <Row>
        <span className="text-secondary">
          {`${capitalize(t('wms.status.title', 'status'))}:`}
        </span>
        <Col span={24} className="flex flex-wrap">

          <RadioButtons
            flexStyle={{ flexDirection: 'row' }}
            options={statusOptions(t)}
            defaultValue={formData?.status || status}
            onChange={(e) => {
              setFormData({ ...formData, status: e });
              setDataIsEdited();
            }}
          />

        </Col>
      </Row>
      <hr className="my-3" />
      <Flex align="center">
        <EditButton
          disabled={!myPerms.set && !adminOrRoot}
          onClick={() => setEditMembers(true)}
        />
        <AssigningUsersRoot
          header="Assigning users"
          isOpen={isEditMembers}
          setIsOpen={setEditMembers}
          onUpdateUsers={onChangeUsers}
          globalOptions={{
            uuids: projectParams?.usersSearch ?? [],
            roles: projectParams?.users ?? [],
            needLoadPerms: true,
            defaultColumn: 'all',
          }}
          placement="left"
          isModal
          tabs={{
            allConfig: {
              label: 'All',
              value: 'all',
              textSelectButton: 'Add to project',
              showSections: ['button-add-user'],
            },
          }}
          usersConfig={{
            label: 'Project users',
            showSections: ['perms', 'admin', 'input-role', 'button-delete-user', 'string-role'],
            defaultItems: projectParams?.usersSearch ?? [],
          }}
          projectUuid={projectUUID}
          partition={partitionType}
        />

        <Row
          onClick={() => setEditMembers(true)}
          style={{ cursor: 'pointer' }}
          className="mr-2"
        >
          {`${capitalize(t('wms.projectflow.project.info.members', 'members'))} / Admins:`}
        </Row>
        <WrapperAvatarsGroupForProjectWithHook
          actors={usersSearch}
          avatarSize={28}
          maxCount={'infinity'}
          popoverTrigger="click"
          popoverPlacement="right"
        />
      </Flex>

      <hr className="my-3" />
      <Row className="mt-3">
        <Col span={24}>
          <span className="text-secondary">
            Basic project documentation:
          </span>
          <Alert
            description={(
              <div className="flex">
                <DescriptionDocument
                  partitionType={partitionType}
                />
                <div className="ml-3">
                  <InfrastructureDocument partitionType={partitionType} />
                </div>

              </div>
            )}
            type="info"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={24}>
          <span className="text-secondary">
            Special features:
          </span>
          <Alert
            description={(
              <>
                <ProjectTagsSettings
                  partitionType={partitionType}
                />
              </>
            )}
            type="info"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={24}>
          <span className="text-secondary">
            Related projects:
          </span>
          <Alert
            description={(
              <RelatedProjects
                isEdit
                customParent={get(project, 'parent')}
                relatedProjects={relatedProjects}
                partitionType={partitionType}
                updateProjectWithCustomsParams={updateProjectWithCustomsParams}
              />
            )}
            type="info"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={24}>
          <span className="text-secondary">
            {`${capitalize(t('wms.labels.tags', 'tags'))}:`}
          </span>
          <Alert
            description={(
              <TagsSettingList
                onSelectTags={(e) => {
                  setFormData({
                    ...formData, areas: [...e.filter((el) => el.label)],
                  });
                  setDataIsEdited();
                }}
                allTags={tags}
                defaultSelectedTags={defaultSelectedTags}
                size="small"
              />
            )}
            type="info"
          />
        </Col>
      </Row>
    </>
  );
}

ProjectInfoFromSettings.propTypes = {
  partitionType: PropTypes.string,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};

export default ProjectInfoFromSettings;
